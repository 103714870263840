import React from 'react';
import styled from 'styled-components';
import { SpaceConfig } from './spaceConfig';
import BuildingsTable from './BuildingsTable';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export default (props) => {
    return (
        <Wrapper>
            <SpaceConfig />
            <BuildingsTable />
        </Wrapper>
    );
};
