import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import SideMenuSection from '../../common/side-drawer/SideMenuSection';
import { Select, Skeleton, Switch, SwitchLabel as Label, Checkbox, Dialog, DialogContent, DialogContentText, DialogActions, Button, CircularPreloader } from '../../common/themed';
import {
    selectMappingAreLanesShown,
    selectMappingSelectedLaneId,
    selectMappingSelectedMapId,
    selectMappingSelectedMapperId,
    setSelectedMapperId,
    setAreLanesShown,
    selectMappingIsShowingEqualizedMap,
    selectMappingLaneIds,
    setSelectedLaneIds,
    selectMappingSelectedLanesMapperName,
    setSelectedLanesMapperId,
    setSelectedLanesMapperName,
} from '../../../state-management/user-inputs/mappingSlice';
import { useDispatch, useSelector } from 'react-redux';
import useLanes from '../../common/hooks/data-fetching/useLanes';
import { FormControlLabel, IconButton, Typography } from '@material-ui/core';
import LaneListEntry from './LaneListEntry';
import { FixedSizeList as List } from 'react-window';
import FetchError from '../../common/error-pages/FetchError';
import moment from 'moment';
import { FlexRow } from '../../common/layout';
import { MdDelete } from 'react-icons/md';
import { deleteLane } from '../../../state-management/mapping/lane/laneActions';
import { isFulfilled, isRejected } from '@reduxjs/toolkit';
import { showErrorNotification, showSuccessNotification } from '../../../state-management/notification/notificationReducer';
import { useScrollToElement } from '../../../hooks/useScrollToElement';
import { useMappers } from '../../../hooks/useMappers';
import { formatDateToYYYYMMDD } from '../../../utils/general';

Array.prototype.customIndexOf = function (item, predicate) {
    for (let i = 0; i < this.length; i++) {
        if (predicate(this[i], item)) {
            return i;
        }
    }
    return null;
}
const LaneListWrapper = styled(List)`
    margin-block-start: 5px;
    padding: 0;

    & > ul {
        margin: 0;
        padding: 0;
    }
`;

const NoLanesMessage = styled(Typography)`
    color: #909090;
    text-align: center;
    margin-block-start: 10px;
    font-style: italic;
`;

export default function MappingSideMenuLanesSection({ selfHealingEnabled }) {
    const dispatch = useDispatch();
    const [selectAll, setSelectAll] = useState(false);
    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const listRef = useRef(null);
    const selectRef = useRef(null);
    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const selectedMapperId = useSelector(selectMappingSelectedMapperId);
    const selectedMapperName = useSelector(selectMappingSelectedLanesMapperName);
    const selectedLaneId = useSelector(selectMappingSelectedLaneId);
    const selectedLaneIds = useSelector(selectMappingLaneIds)
    const areLanesShown = useSelector(selectMappingAreLanesShown);
    const isShowingEqualizedMap = useSelector(selectMappingIsShowingEqualizedMap);


    const {
        data: lanes = [],
        isLoading,
        hasError,
    } = useLanes({
        mapId: selectedMapId,
        useEqualizedMap: isShowingEqualizedMap,
    });


    useEffect(() => {
        if (selectRef.current) {
            selectRef.current.select.clearValue();
        }
    }, [isDeleting])

    const { mappers, customFilterOption } = useMappers({ list: lanes })


    const lanesToDisplay = useMemo(
        () =>
            lanes
                .filter(
                    ({ laneId, createdAt, mapperName }) => {
                        let value = `${mapperName},${formatDateToYYYYMMDD(new Date(createdAt))}`;
                        return (!selectedMapperName || selectedMapperName === value);
                        // (!selectedMapperId || mapperId === selectedMapperId) &&


                    }
                )
                .sort((laneA, laneB) => {
                    const aCreateTime = moment(laneB.createdAt);
                    const bCreateTime = moment(laneA.createdAt);
                    if (aCreateTime.isBefore(bCreateTime)) {
                        return -1;
                    }
                    if (aCreateTime.isAfter(bCreateTime)) {
                        return 1;
                    }
                    return 0;
                }),
        [lanes, selectedLaneId, selectedMapperId, selectedLaneIds]
    );

    useScrollToElement({ ref: listRef, selectedIds: selectedLaneIds, items: lanesToDisplay, predicate: (lane, lastLaneId) => lane.laneId === lastLaneId })

    const onLanesDeleted = async () => {
        setIsDeleting(true);
        const result = await Promise.all(selectedLaneIds.map(laneId => {
            return dispatch(deleteLane({ mapId: selectedMapId, laneId }))
        }));
        const atLeastOneFailed = result.some(result => isRejected(result));
        if (atLeastOneFailed) {
            dispatch(showErrorNotification(`Failed to delete lanes`))
        } else {
            setIsDeleteWarningOpen(false);
            setSelectAll(false);
            dispatch(setSelectedLanesMapperName(null));
            dispatch(setSelectedLaneIds([]));
            dispatch(showSuccessNotification(`The lanes were successfully deleted.`));
        }
        setIsDeleting(false);

    }

    const onSelectAll = () => {
        const allAreSelected = selectedLaneIds.length === lanesToDisplay.length
        if (allAreSelected) {
            dispatch(setSelectedLaneIds([]));
            setSelectAll(false);
        } else {
            dispatch(setSelectedLaneIds(lanesToDisplay.map(({ laneId }) => laneId)));
            setSelectAll(true);
        }
    }


    if (!selectedMapId && !isLoading) {
        return null;
    }

    return (
        <SideMenuSection title={'Lanes'}>
            {hasError ? (
                <FetchError />
            ) : (
                <>
                    <FormControlLabel
                        label={<Label>Show lanes</Label>}
                        disabled={isLoading}
                        control={
                            <Switch
                                checked={areLanesShown}
                                onChange={() => dispatch(setAreLanesShown(!areLanesShown))}
                            />
                        }
                    />

                    {isLoading ? (
                        Array.from({ length: 4 }, (_, index) => (
                            <Skeleton key={`lane-list-entry-skeleton-${index}`} height={70} width={230} />
                        ))
                    ) : lanes.length === 0 ? (
                        <NoLanesMessage>There are no lanes in this map</NoLanesMessage>
                    ) : (
                        <>
                            {isDeleteWarningOpen && (
                                <Dialog open closeable={false}>
                                    <DialogContent>
                                        <DialogContentText>
                                            You are about to delete the selected lanes. Are you sure?
                                        </DialogContentText>
                                    </DialogContent>

                                    <DialogActions>
                                        <Button variant={'text'} onClick={() => setIsDeleteWarningOpen(false)}>
                                            Cancel
                                        </Button>

                                        {isDeleting ? <CircularPreloader style={{ width: '25px', height: '25px' }} /> : <Button onClick={onLanesDeleted}>Accept</Button>}
                                    </DialogActions>
                                </Dialog>
                            )}
                            <FlexRow width='100%' alignItems='center' style={{ columnGap: '10px' }}>
                                <Checkbox
                                    disabled={!!selfHealingEnabled}
                                    checked={selectAll && selectedLaneIds.length === lanesToDisplay.length}
                                    onChange={onSelectAll}
                                    style={{ padding: 5 }}
                                />
                                <IconButton onClick={() => setIsDeleteWarningOpen(true)} disabled={!selectedLaneIds.length} style={{ padding: 0 }}>
                                    <MdDelete />
                                </IconButton>
                                <Select
                                    ref={selectRef}
                                    placeholder={'Filter...'}
                                    options={mappers}
                                    customFilterOption={customFilterOption}
                                    onChange={(m) => dispatch(setSelectedLanesMapperName(m?.value ?? null))}
                                    value={mappers.find((m) => m.value === selectedMapperName)}
                                    isDisabled={isLoading}
                                    isClearable
                                    width={'75%'}
                                />
                            </FlexRow>

                            <LaneListWrapper
                                ref={listRef}
                                height={Math.min(lanes.length * 70 + 5, 300)}
                                itemCount={lanesToDisplay.length}
                                itemSize={76}
                                innerElementType={'ul'}
                                style={{ overflowX: 'hidden' }}
                            >
                                {({ index, style }) => {
                                    return (
                                        <LaneListEntry
                                            disabled={!!selfHealingEnabled}
                                            key={lanesToDisplay[index].laneId}
                                            lane={lanesToDisplay[index]}
                                            style={{ ...style }} />
                                    );
                                }}
                            </LaneListWrapper>
                        </>
                    )}
                </>
            )}
        </SideMenuSection>
    );
}
