import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCoordinatesConversionInMap } from '../../../../state-management/coordinates-conversion/coordinatesConversionActions';
import {
    selectAllSelectedCoordinates,
    selectHighlightedCoordinate,
} from '../../../../state-management/coordinates-conversion/coordinatesConversionReducer';
import { selectCoordinatesConversionsById } from '../../../../state-management/coordinates-conversion/coordinatesConversionSelectors';
import { selectHasError, selectIsLoading } from '../../../../state-management/status/statusSelectors';
import {
    selectBuildingsSelectedBuildingId,
    selectBuildingsSelectedFloorId,
    selectBuildingsSelectedMapId,
} from '../../../../state-management/user-inputs/buildingsSlice';

export const useCoordinatesConversion = (mapId) => {
    const selectedMapId = useSelector(selectBuildingsSelectedMapId);
    const buildingId = useSelector(selectBuildingsSelectedBuildingId);
    const floorId = useSelector(selectBuildingsSelectedFloorId);
    const dispatch = useDispatch();
    const isLoading = useSelector(
        selectIsLoading({ type: fetchAllCoordinatesConversionInMap.typePrefix, arg: buildingId })
    );
    const hasError = useSelector(
        selectHasError({ type: fetchAllCoordinatesConversionInMap.typePrefix, arg: buildingId })
    );

    const { coordinates, translation, rotation, scale } =
        useSelector((state) => selectCoordinatesConversionsById(state, selectedMapId)) || {};
    const highlightedCoordinate = useSelector(selectHighlightedCoordinate);
    const selectedCoordinates = useSelector(selectAllSelectedCoordinates);
    useEffect(() => {
        if (buildingId && floorId && !isLoading && !hasError) {
            dispatch(fetchAllCoordinatesConversionInMap({ buildingId, floorId, mapId: selectedMapId }));
        }
    }, [buildingId, floorId]);
    return {
        data: coordinates,
        selectedCoordinates: selectedCoordinates || [],
        highlightedCoordinate: highlightedCoordinate,
        translation,
        rotation,
        scale,
        isLoading,
        hasError,
    };
};
