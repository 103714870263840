import Api from './Api';
import http from '../utils/httpClient';

class ApiKeysApi extends Api {
    async fetchAllApiKeysInAttachedSpaces() {
        return await this.callApi((params) => http.v1.get(`/api-key`, { params }));
    }

    async createApiKey(apiKeyData) {
        return await this.callApi((params) => http.v1.post(`/api-key`, apiKeyData, { params }));
    }

    async updateApiKey(apiKeyId, apiKeyData) {
        return await this.callApi((params) => http.v1.post(`/api-key/${apiKeyId}`, apiKeyData, { params }));
    }

    async deleteApiKey(apiKeyId) {
        return await this.callApi((params) => http.v1.delete(`/api-key/${apiKeyId}`, { params }));
    }

    async fetchTermsDataForAllApiKeys(apiKeyId) {
        return await this.callApi((params) =>
            http.v4.get(`/api-key/${apiKeyId}/terms-and-conditions`, { params })
        );
    }

    async excludeApiKeyFromTermsAcceptance(apiKeyId) {
        return await this.callApi((params) =>
            http.v4.patch(`/api-key/${apiKeyId}/terms-and-conditions/exclude`, {}, { params })
        );
    }

    async unexcludeApiKeyFromTermsAcceptance(apiKeyId) {
        return await this.callApi((params) =>
            http.v4.patch(`/api-key/${apiKeyId}/terms-and-conditions/unexclude`, {}, { params })
        );
    }

    async suspendApiKey(apiKeyId) {
        return await this.callApi((params) => http.v4.patch(`/api-key/${apiKeyId}/suspend`, {}, { params }));
    }

    async unsuspendApiKey(apiKeyId) {
        return await this.callApi((params) =>
            http.v4.patch(`/api-key/${apiKeyId}/unsuspend`, {}, { params })
        );
    }
}

export default new ApiKeysApi();
