import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    highlightInternalPlacement,
    openRegionEditor,
    selectMapContentHighlightedInternalPlacementIds,
    selectMapContentIsCreatingRegionInternalCircularPlacement,
    selectMapContentIsCreatingRegionInternalPolygonPlacement,
    selectMapContentIsCreatingRegionInternalRectangularPlacement,
    selectMapContentIsRegionEditorOpen,
    selectMapContentIsSideDrawerOpen,
    selectMapContentLockRegionsInPlace,
    selectMapContentPlacementIdsShownInBack,
    selectMapContentPlacementIdsShownInFront,
    selectMapContentSelectedBuildingId,
    selectMapContentSelectedFloorId,
    selectMapContentSelectedInternalPlacementId,
    selectMapContentSelectedMapId,
    sendPlacementToBack,
    setIsCreatingRegionInternalCircularPlacement,
    setIsCreatingRegionInternalPolygonPlacement,
    setIsCreatingRegionInternalRectangularPlacement,
    setSelectedInternalPlacementId,
    unhighlightInternalPlacement,
} from '../../state-management/user-inputs/mapContentSlice';
import useAllRegionsInSpace from '../common/hooks/data-fetching/useAllRegionsInSpace';
import { selectCanEditRegions } from '../../state-management/auth/authSelectors';
import { selectCommonSelectedSpaceId } from '../../state-management/user-inputs/commonSlice';
import { getRegionsSectionId } from './MapContent.selectors';
import { getSideDrawerMenuContentId } from '../common/side-drawer/SideDrawer.selectors';
import MapViewer from '../common/map-viewer';
import MapViewerRegionsOverlay from '../common/map-viewer/overlays/regions';
import MapViewerLoadingIndicatorOverlay from '../common/map-viewer/overlays/loading-indicators';
import { getSelectAllRegionsWithInternalPlacementsInMap } from '../../state-management/map/mapSelectors';
import {
    BiShapeSquare as RectangleIcon,
    BiShapeCircle as CircleIcon,
    BiShapePolygon as PolygonIcon,
} from 'react-icons/bi';
import MapViewerCreateNewItemOverlay from '../common/map-viewer/overlays/create-new-item';
import MapViewerRegionInternalRectangularPlacementCreationOverlay from '../common/map-viewer/overlays/region-internal-rectangular-placement-creation';
import MapViewerRegionInternalCircularPlacementCreationOverlay from '../common/map-viewer/overlays/region-internal-circular-placement-creation';
import { MapViewerRegionInternalPolygonPlacementCreationOverlay } from '../common/map-viewer/overlays/region-internal-polygon-placement-creation';
import MapViewerMouseCoordinatesAndZoomOverlay from '../common/map-viewer/overlays/mouse-coordinates-and-zoom';
import MapViewerScaleOverlay from '../common/map-viewer/overlays/scale';
import { DuplicateWithOffsetDialog } from './dialogs/duplicate-offset';
import { useState } from 'react';
import { MapViewerContext } from '../common/map-viewer/MapViewerContext';

function CreateNewItemOverlay() {
    const dispatch = useDispatch();

    const canEditRegions = useSelector(selectCanEditRegions);
    const isCreatingRectangularPlacement = useSelector(
        selectMapContentIsCreatingRegionInternalRectangularPlacement
    );
    const isCreatingCircularPlacement = useSelector(
        selectMapContentIsCreatingRegionInternalCircularPlacement
    );
    const isCreatingPolygonPlacement = useSelector(selectMapContentIsCreatingRegionInternalPolygonPlacement);
    const isSideDrawerOpen = useSelector(selectMapContentIsSideDrawerOpen);

    const actions = useMemo(() => {
        const actions = [];

        if (canEditRegions) {
            actions.push(
                {
                    label: 'Rectangular Region',
                    icon: <RectangleIcon size={25} />,
                    onClick: () => dispatch(setIsCreatingRegionInternalRectangularPlacement(true)),
                },
                {
                    label: 'Circular Region',
                    icon: <CircleIcon size={25} />,
                    onClick: () => dispatch(setIsCreatingRegionInternalCircularPlacement(true)),
                },
                {
                    label: 'Polygon Region',
                    icon: <PolygonIcon size={25} />,
                    onClick: () => dispatch(setIsCreatingRegionInternalPolygonPlacement(true)),
                }
            );
        }

        return actions;
    }, [canEditRegions, dispatch]);

    return (
        <MapViewerCreateNewItemOverlay
            actions={actions}
            isHidden={
                isCreatingRectangularPlacement || isCreatingCircularPlacement || isCreatingPolygonPlacement
            }
            isPushed={isSideDrawerOpen}
        />
    );
}

function PolygonPlacementCreationOverlay() {
    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const selectedMapId = useSelector(selectMapContentSelectedMapId);
    const isCreatingPlacement = useSelector(selectMapContentIsCreatingRegionInternalPolygonPlacement);

    return (
        isCreatingPlacement && (
            <MapViewerRegionInternalPolygonPlacementCreationOverlay
                spaceId={selectedSpaceId}
                mapId={selectedMapId}
                onSubmitted={() => dispatch(setIsCreatingRegionInternalPolygonPlacement(false))}
                onCancel={() => dispatch(setIsCreatingRegionInternalPolygonPlacement(false))}
            />
        )
    );
}

function RectangularPlacementCreationOverlay() {
    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const selectedMapId = useSelector(selectMapContentSelectedMapId);
    const isCreatingPlacement = useSelector(selectMapContentIsCreatingRegionInternalRectangularPlacement);

    return (
        isCreatingPlacement && (
            <MapViewerRegionInternalRectangularPlacementCreationOverlay
                spaceId={selectedSpaceId}
                mapId={selectedMapId}
                onSubmitted={() => dispatch(setIsCreatingRegionInternalRectangularPlacement(false))}
                onCancel={() => dispatch(setIsCreatingRegionInternalRectangularPlacement(false))}
            />
        )
    );
}

function CircularPlacementCreationOverlay() {
    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const selectedMapId = useSelector(selectMapContentSelectedMapId);
    const isCreatingPlacement = useSelector(selectMapContentIsCreatingRegionInternalCircularPlacement);

    return (
        isCreatingPlacement && (
            <MapViewerRegionInternalCircularPlacementCreationOverlay
                spaceId={selectedSpaceId}
                mapId={selectedMapId}
                onSubmitted={() => dispatch(setIsCreatingRegionInternalCircularPlacement(false))}
                onCancel={() => dispatch(setIsCreatingRegionInternalCircularPlacement(false))}
            />
        )
    );
}

function RegionsOverlay() {
    const dispatch = useDispatch();

    const canEditRegions = useSelector(selectCanEditRegions);
    const selectedMapId = useSelector(selectMapContentSelectedMapId);
    const selectedPlacementId = useSelector(selectMapContentSelectedInternalPlacementId);
    const highlightedPlacementIds = useSelector(selectMapContentHighlightedInternalPlacementIds);
    const placementIdsShownInBack = useSelector(selectMapContentPlacementIdsShownInBack);
    const placementIdsShownInFront = useSelector(selectMapContentPlacementIdsShownInFront);
    const lockRegionsInPlace = useSelector(selectMapContentLockRegionsInPlace);
    const isRegionEditorOpen = useSelector(selectMapContentIsRegionEditorOpen);
    const { isLoading } = useAllRegionsInSpace();
    const [open, setOpen] = useState(false);
    const { MapViewerOptionsRef } = useContext(MapViewerContext);
    const selectRegionsWithPlacements = useMemo(
        () => getSelectAllRegionsWithInternalPlacementsInMap(selectedMapId),
        [selectedMapId]
    );
    const [placementsToDuplicate, setPlacementsToDuplicate] = useState([]);

    const { asArray: placements = [] } = useSelector(selectRegionsWithPlacements);
    const placementsToDisplay = useMemo(
        () =>
            placements.sort((p1, p2) => {
                const l1BackIndex = placementIdsShownInBack.indexOf(p1.placementId);
                const l2BackIndex = placementIdsShownInBack.indexOf(p2.placementId);
                const l1FrontIndex = placementIdsShownInFront.indexOf(p1.placementId);
                const l2FrontIndex = placementIdsShownInFront.indexOf(p2.placementId);

                // Assuming there won't be more than 100000 lanes, give a big weight for being in the back or front.
                // Based on this calculation, if l1 and l2 are both in back/front, they win by their indexes.
                // If neither is in the back or front, their order doesn't matter.
                const l1TotalIndex = -100000 * (l1BackIndex + 1) + 100000 * (l1FrontIndex + 1);
                const l2TotalIndex = -100000 * (l2BackIndex + 1) + 100000 * (l2FrontIndex + 1);

                return l1TotalIndex - l2TotalIndex;
            }),
        [placementIdsShownInBack, placementIdsShownInFront, placements]
    );

    const handleClick = useCallback(
        ({ regionId, placementId }) => {
            if (selectedPlacementId !== placementId) {
                dispatch(setSelectedInternalPlacementId({ regionId, placementId }));
            }
        },
        [dispatch, selectedPlacementId]
    );

    const handleContextMenu = useCallback(
        ({ regionId, placementId }) => {
            dispatch(openRegionEditor({ regionId, placementId }));
        },
        [dispatch]
    );

    const handlePointerEnter = useCallback(
        ({ placementId }) => {
            if (selectedPlacementId !== placementId && !highlightedPlacementIds.includes(placementId)) {
                dispatch(highlightInternalPlacement(placementId));
            }
        },
        [dispatch, highlightedPlacementIds, selectedPlacementId]
    );

    const handlePointerLeave = useCallback(
        ({ placementId }) => {
            if (selectedPlacementId !== placementId && highlightedPlacementIds.includes(placementId)) {
                dispatch(unhighlightInternalPlacement(placementId));
            }
        },
        [dispatch, highlightedPlacementIds, selectedPlacementId]
    );

    const handleCancel = useCallback(() => {
        dispatch(setSelectedInternalPlacementId({ regionId: null, placementId: null }));
    }, [dispatch]);

    const handleSendToBack = useCallback(
        ({ placementId }) => {
            dispatch(sendPlacementToBack(placementId));
        },
        [dispatch]
    );
    const handleDuplicate = useCallback(
        ({ placementId }) => {
            setOpen(true);
        },
        [dispatch]
    );
    // fix the bug when you click on the close button in the dialog the panning is still going.
    useEffect(() => {
        if (MapViewerOptionsRef) {
            MapViewerOptionsRef.current.disablePanning = open;
        }
    }, [open]);
    if (isLoading) {
        return <MapViewerLoadingIndicatorOverlay label={'Loading regions...'} />;
    }

    return (
        <>
            <MapViewerRegionsOverlay
                placements={placementsToDisplay.concat(placementsToDuplicate)}
                isEditable={canEditRegions && !lockRegionsInPlace}
                onClick={handleClick}
                onContextMenu={handleContextMenu}
                onPointerEnter={handlePointerEnter}
                onPointerLeave={handlePointerLeave}
                onCancel={handleCancel}
                onSendToBack={handleSendToBack}
                onDuplicate={handleDuplicate}
                selectedPlacementIds={selectedPlacementId ? [selectedPlacementId] : []}
                highlightedPlacementIds={highlightedPlacementIds}
                isDuplicating={
                    placementsToDisplay.concat(placementsToDuplicate).some((p) => p.isDraft) ||
                    open ||
                    isRegionEditorOpen
                }
            />
            <DuplicateWithOffsetDialog
                open={open}
                onClose={() => setOpen(false)}
                setPlacementsToDuplicate={setPlacementsToDuplicate}
                placmentsToDuplicate={placementsToDuplicate}
                placment={
                    selectedPlacementId &&
                    placementsToDisplay.find((p) => p.placementId === selectedPlacementId)
                }
            />
        </>
    );
}

export default function MapContentMapViewer() {
    const selectedBuildingId = useSelector(selectMapContentSelectedBuildingId);
    const selectedFloorId = useSelector(selectMapContentSelectedFloorId);
    const selectedMapId = useSelector(selectMapContentSelectedMapId);

    const sideDrawerMenu = document.getElementById(getSideDrawerMenuContentId());
    const regionsSection = document.getElementById(getRegionsSectionId());

    useEffect(() => {
        // Scroll the side menu to the regions section once a map is selected
        if (selectedMapId && sideDrawerMenu && regionsSection) {
            sideDrawerMenu.scrollTo(0, regionsSection.offsetTop);
        }
    }, [regionsSection, selectedMapId, sideDrawerMenu]);

    return (
        <>
            <MapViewer buildingId={selectedBuildingId} floorId={selectedFloorId} mapId={selectedMapId}>
                <CreateNewItemOverlay />
                <RegionsOverlay />
                <RectangularPlacementCreationOverlay />
                <CircularPlacementCreationOverlay />
                <PolygonPlacementCreationOverlay />
                <MapViewerMouseCoordinatesAndZoomOverlay />
                <MapViewerScaleOverlay />
            </MapViewer>
        </>
    );
}
