import styled from 'styled-components';
import { IoAlertCircle } from 'react-icons/io5';
import { store } from '../../state-management/store';
import { Button } from '../common/themed';
import { showDialog } from '../../state-management/general/generalReducer';
import { parseIsoDateToNormalDate } from '../../utils/general';
import { setIsGenerateMapGridOpen } from '../../state-management/user-inputs/mappingSlice';

export const BANNER_IDS = {
    publish: 'mapping/publish',
    reset: 'mapping/reset',
    noLatest: 'mapping/noLatest',
};

const AlertIcon = styled(IoAlertCircle)`
    font-size: 24px;
`;

const iconsObject = {
    error: {
        icon: <AlertIcon color="red" />,
        bgColor: '#fd060629',
    },
    warning: {
        icon: <AlertIcon color="#fd560e" />,
        bgColor: '#ffd39e7a',
    },
};

export const banners = {
    publish: ({ onClick, mapId, id = BANNER_IDS.publish, onFulfilled, type, onConfirm, ...props }) => {
        const onClickHandler = () => {
            store.dispatch(
                showDialog({
                    title: 'Publish Grid',
                    content: `Are you sure you'd like to publish the latest grid? This action cannot be undone.`,
                    confirmText: 'Publish',
                    onConfirm,
                })
            );
        };
        return {
            id,
            type: 'error',
            content: 'There is a mismatch between the latest map grid and public grid',
            noSpace: true,
            bannerStyle: iconsObject.error,
            components: (
                <Button
                    style={{ width: '100px', backgroundColor: '#ff4d17', color: '#fff' }}
                    onClick={onClickHandler}
                >
                    PUBLISH
                </Button>
            ),
            ...props,
        };
    },
    reset: ({
        onClick,
        buildingId,
        floorId,
        mapId,
        lastHealedDate,
        id = BANNER_IDS.reset,
        type,
        onConfirm,
        ...props
    }) => {
        const onClickHandler = () => {
            store.dispatch(
                showDialog({
                    title: 'Reset Mode',
                    content: `Are you sure you’d like to reset back to the original lane based map? This action cannot be undone, and if the building’s map was set to self validation and healing it will be now disabled.`,
                    confirmText: 'Reset',
                    onConfirm,
                })
            );
        };
        const healedDateDisplayed = lastHealedDate ? `on: ${parseIsoDateToNormalDate(lastHealedDate)}` : '';
        return {
            id,
            type: 'warning',
            content: `The map was set to self healing and cannot be manually edited`,
            noSpace: true,
            bannerStyle: iconsObject.warning,
            components: (
                <Button
                    style={{ width: '100px', backgroundColor: 'white', color: '#111' }}
                    onClick={onClickHandler}
                >
                    RESET
                </Button>
            ),
            ...props,
        };
    },
    hasNoLatest: ({ onClick, buttonProps = {}, ...props }) => {
        return {
            id: BANNER_IDS.noLatest,
            type: 'warning',
            content: `Missing latest grid. Did you forget to generate?`,
            noSpace: true,
            bannerStyle: iconsObject.warning,
            components: (
                <Button style={{ width: '100px', color: '#fff' }} onClick={onClick} {...buttonProps}>
                    Generate
                </Button>
            ),
            ...props,
        };
    },
};

export const getBannerByType = (bannerType, bannerData) => {
    return banners[bannerType](bannerData);
};
