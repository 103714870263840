import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import {
    selectHasError as getSelectHasError,
    selectIsLoading as getSelectIsLoading,
} from '../../../../state-management/status/statusSelectors';
import {
    getSelectUsersAttachedToApiKey,
    selectAllUsersAttachedToAllApiKeys,
    selectIsFetched,
} from '../../../../state-management/users/userSelectors';
import { fetchAllUsers, fetchAllUsersAttachedToApiKey } from '../../../../state-management/users/userActions';

/**
 * Hook that fetches users into the Redux store and returns them.
 * @returns {{isLoading, data, hasError}}
 */
export default function useUsers(options = {}) {
    const { asObject = false, apiKeyId } = options;

    const dispatch = useDispatch();

    const actions = useMemo(
        () => (apiKeyId ? fetchAllUsersAttachedToApiKey.typePrefix : fetchAllUsers.typePrefix),
        [apiKeyId]
    );

    const selectUsers = useMemo(
        () => (apiKeyId ? getSelectUsersAttachedToApiKey(apiKeyId) : selectAllUsersAttachedToAllApiKeys),
        [apiKeyId]
    );
    const selectIsLoading = useMemo(() => getSelectIsLoading(actions), [actions]);
    const selectHasError = useMemo(() => getSelectHasError(actions), [actions]);

    const { asArray: usersArray, asObject: usersObject } = useSelector(selectUsers);
    const isFetching = useSelector(selectIsLoading);
    const isFetched = useSelector(selectIsFetched);
    const hasError = useSelector(selectHasError);

    useEffect(() => {
        // If the users haven't been fetched yet, fetch them
        if (!apiKeyId && !isFetched && !isFetching && !hasError) {
            dispatch(fetchAllUsers());
        }
    }, [dispatch, isFetching, hasError, apiKeyId, isFetched]);

    useEffect(() => {
        // If the users of the specific API key haven't been fetched yet, fetch them
        if (apiKeyId && !isFetched && !usersArray && !isFetching && !hasError) {
            dispatch(fetchAllUsersAttachedToApiKey(apiKeyId));
        }
    }, [dispatch, usersArray, isFetching, hasError, apiKeyId, isFetched]);

    return { data: asObject ? usersObject : usersArray, isLoading: isFetching, hasError };
}
