import { useMemo } from 'react';
import { formatDateToYYYYMMDD, isUndefinedOrEmpty } from '../utils/general';

Array.prototype.distinct = function () {
    const distinctArray = [];
    for (let i = 0; i < this.length; i++) {
        const doesExist = distinctArray.some((item) => {
            return Object.keys(item).every((key) => item[key] === this[i][key]);
        });
        if (!doesExist) {
            distinctArray.push(this[i]);
        }
    }
    return distinctArray;
};

export const useMappers = ({ list }) => {
    const mappers = useMemo(() => {
        return list
            ?.flatMap(({ mapperName, createdAt }) => {
                if (!mapperName) return [];
                let value = mapperName;
                if (createdAt) {
                    const dateFormatted = formatDateToYYYYMMDD(new Date(createdAt));
                    value = `${value},${dateFormatted}`;
                }
                return { value, label: value };
            })
            .distinct();
    }, [list]);

    const customFilterOption = (candidate, input) => {
        if (!candidate.value) return false;
        const [searchedMapper, searchedDate] = input.toLowerCase().split(',');
        const [candidateMapper, candidateDate] = candidate.value.toLowerCase().split(',');
        const matchedMapper = isUndefinedOrEmpty(searchedMapper) || candidateMapper.includes(searchedMapper);
        const matchedDate = isUndefinedOrEmpty(searchedDate) || candidateDate.includes(searchedDate);
        return matchedMapper && matchedDate;
    };

    return { mappers, customFilterOption };
};
