import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import {
    Button,
    CircularPreloader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '../../../common/themed';
import { useDispatch } from 'react-redux';
import useAllFloorsInBuilding from '../../../common/hooks/data-fetching/useAllFloorsInBuilding';
import { createFloor } from '../../../../state-management/floor/floorActions';
import { isFulfilled } from '../../../../state-management/utils';
import { FormProvider, useForm } from 'react-hook-form';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { setSelectedFloorId } from '../../../../state-management/user-inputs/buildingsSlice';
import { FaMapMarkedAlt as MapsIcon } from 'react-icons/fa';
import { Divider, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import { getCreateFloorDialogId, getCreateFloorFormErrorId } from './CreateFloorDialog.selectors';
import styled from 'styled-components';
import CreateFloorStepOne from './CreateFloorStepOne';
import { FaLayerGroup as FloorsIcon } from 'react-icons/fa';
import CreateFloorStepTwo from './CreateFloorStepTwo';

const StepContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 250px;
`;

const IconWrapper = styled.div`
    background-color: #ff6200; // TODO color should come from theme
    width: 100%;
    height: 100%;
    color: white; // TODO color should come from theme
    padding: 0 25px;
    box-sizing: border-box;

    & > svg {
        width: 100%;
        height: 100%;
    }
`;

const stepTitles = ['Define the new floor', "Upload the floor's maps"];

export default function CreateFloorDialog(props) {
    const { onClose, buildingId } = props;

    const [currentStep, setCurrentStep] = useState(1);

    const dispatch = useDispatch();

    const { data: floors } = useAllFloorsInBuilding({
        buildingId,
    });

    const floorIndexes = floors?.map((f) => f.floorIndex);

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'onChange',
        defaultValues: {
            floorName: floorIndexes ? `Floor ${Math.max(...floorIndexes) + 1}` : `Untitled Floor`,
            floorShortName: floorIndexes ? `F${Math.max(...floorIndexes) + 1}` : ``,
            floorIndex: floorIndexes ? Math.max(...floorIndexes) + 1 : 0,
            height: 0,
            rotation: 0,
            primary: false,
            maps: [],
        },
    });
    const {
        handleSubmit,
        formState: { isValid, isSubmitting },
        errors,
        clearErrors,
    } = formMethods;

    const handleBack = () => {
        clearErrors('custom');
        setCurrentStep(Math.max(currentStep - 1, 0));
    };

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const submitForm = async (data) => {
        const result = await dispatch(
            createFloor({
                buildingId,
                floorData: {
                    floorName: data.floorName,
                    floorShortName: data.floorShortName,
                    floorIndex: data.floorIndex,
                    height: data.height,
                    rotation: data.rotation,
                    primary: data.primary,
                    maps: data.maps,
                },
            })
        );

        if (isFulfilled(result)) {
            await Promise.all([
                dispatch(showSuccessNotification('Floor created successfully.')),
                dispatch(setSelectedFloorId(result?.payload?.floorId)),
            ]);
            onClose();
        } else {
            dispatch(showErrorNotification('Failed to create floor.'));
        }
    };

    const getStep = () => {
        switch (currentStep) {
            case 1:
                return {
                    form: <CreateFloorStepOne buildingId={buildingId} />,
                    icon: <FloorsIcon />,
                };
            case 2:
                return {
                    form: <CreateFloorStepTwo buildingId={buildingId} />,
                    icon: <MapsIcon />,
                };
            default:
                return null;
        }
    };

    const { form, icon } = getStep();

    return (
        <Dialog id={getCreateFloorDialogId()} open fullWidth maxWidth={'lg'} onClose={onClose}>
            <DialogTitle>Create a new floor</DialogTitle>

            <StepContentWrapper>
                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Stepper activeStep={currentStep - 1}>
                            {stepTitles.map((title) => (
                                <Step key={title}>
                                    <StepLabel>{title}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        <DialogContent>{form}</DialogContent>

                        <Divider />

                        <DialogActions>
                            {errors?.custom && (
                                <Typography id={getCreateFloorFormErrorId()} color={'error'}>
                                    {errors?.custom?.message}
                                </Typography>
                            )}

                            {currentStep > 1 && (
                                <Button variant={'text'} onClick={handleBack}>
                                    Back
                                </Button>
                            )}

                            {currentStep === 2 ? (
                                isSubmitting ? (
                                    <CircularPreloader />
                                ) : (
                                    <Button type={'submit'} disabled={!isValid}>
                                        Submit
                                    </Button>
                                )
                            ) : (
                                <Button onClick={handleNext} disabled={!isValid}>
                                    Next
                                </Button>
                            )}
                        </DialogActions>
                    </form>
                </FormProvider>

                <IconWrapper>{icon}</IconWrapper>
            </StepContentWrapper>
        </Dialog>
    );
}

CreateFloorDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
};
