import React, { memo, useContext } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    getGoogleMapsViewToggleId,
    getMapViewerGoogleMapsViewToggleOverlayId,
    getMapViewToggleId,
} from './MapViewerGoogleMapsViewToggleOverlay.selectors';
import { Portal } from '@material-ui/core';
import { MapViewerContext } from '../../MapViewerContext';
import { Toggle, ToggleGroup as ThemedToggleGroup, Tooltip } from '../../../themed';
import { IoMapSharp as MapIcon } from 'react-icons/io5';
import { SiGooglemaps as GMapsIcon } from 'react-icons/si';

const OverlayWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;

    pointer-events: none; // Prevent the whole div from capturing pointer events

    * {
        pointer-events: auto;
    }
`;

const ToggleGroup = styled(ThemedToggleGroup)`
    position: absolute;
    top: 20px;
    left: 20px;
    transition: all 200ms ease;
`;

function MapViewerGoogleMapsViewToggleOverlay(props) {
    const { isGoogleMapsViewOpen, isDisabled = false, onChange } = props;

    const { containerRef } = useContext(MapViewerContext);

    return (
        <Portal container={containerRef.current}>
            <OverlayWrapper id={getMapViewerGoogleMapsViewToggleOverlayId()}>
                <ToggleGroup>
                    <Tooltip content={'Map view'} useWrapper={false}>
                        <Toggle
                            id={getMapViewToggleId()}
                            isSelected={!isGoogleMapsViewOpen}
                            isDisabled={isDisabled}
                            onClick={() => onChange(false)}
                        >
                            <MapIcon size={'1.5rem'} />
                        </Toggle>
                    </Tooltip>

                    <Tooltip content={'Google Maps view'} useWrapper={false}>
                        <Toggle
                            id={getGoogleMapsViewToggleId()}
                            isSelected={isGoogleMapsViewOpen}
                            isDisabled={isDisabled}
                            onClick={() => onChange(true)}
                        >
                            <GMapsIcon size={'1.5rem'} />
                        </Toggle>
                    </Tooltip>
                </ToggleGroup>
            </OverlayWrapper>
        </Portal>
    );
}

MapViewerGoogleMapsViewToggleOverlay.propTypes = {
    isGoogleMapsViewOpen: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

export default memo(MapViewerGoogleMapsViewToggleOverlay);
