import uuid from 'uuid/v4';
import settings from '../../clientSettings';
import log from '../../utils/logger';
import eventsHandler from './eventsManagerMessageHandler';
import { store } from '../../state-management/store';
import { selectUserApiKeyId, selectUserId, selectUserName } from '../../state-management/auth/authSelectors';

const { eventsManagerUrl } = settings;

const INITIATE_CLOSE_CODE = 4555;

class EventsManagerWebSocket {
    constructor() {
        this.ws = null;
        this.reconnectOnClose = false;
        this.subscribedTopics = new Set();
    }

    getReadyState() {
        return this.ws?.readyState ?? WebSocket.CLOSED;
    }

    async connect({ subscribeToTopics = [], reconnectOnClose = false } = {}) {
        return new Promise((resolve, reject) => {
            this.ws = new WebSocket(eventsManagerUrl);
            this.reconnectOnClose = reconnectOnClose;

            this.ws.onopen = () => {
                if (this.onReadyStateChange) {
                    this.onReadyStateChange(this.ws.readyState);
                }

                log.info(`Successfully connected to the events manager at: ${eventsManagerUrl}`);

                Promise.all(subscribeToTopics.map((topic) => this.subscribe(topic, true))).then(() => {
                    resolve();
                });
            };

            this.ws.onclose = (ev) => {
                if (this.onReadyStateChange) {
                    this.onReadyStateChange(this.ws.readyState);
                }

                log.warn(`Lost connection to events manager: ${ev.code} ${ev.reason}`);

                if (reconnectOnClose && ev.code !== INITIATE_CLOSE_CODE) {
                    this.reconnect();
                }
            };

            this.ws.onerror = (error) => {
                if (this.onReadyStateChange) {
                    this.onReadyStateChange(this.ws.readyState);
                }

                log.error(error.message);
                reject(error);
            };

            this.ws.onmessage = async (message) => {
                try {
                    const jsonMessage = JSON.parse(message?.data);

                    if (jsonMessage?.type === 'v1/event') {
                        const userId = selectUserId(store.getState());
                        await eventsHandler.handleMessage(jsonMessage, jsonMessage?.senderId === userId);
                    }
                } catch (e) {
                    console.error(`Failed to parse message: ${message}`);
                }
            };
        });
    }

    disconnect() {
        if (this.ws?.readyState === WebSocket.OPEN || this.ws?.readyState === WebSocket.CONNECTING) {
            this.reconnectOnClose = false;
            /*
                INITIATE_CLOSE_CODE:
                Prevent the event manager to reconnect again if it did not caused by connection lost.
            */
            this.ws.close(INITIATE_CLOSE_CODE);
        }
    }

    async reconnect() {
        let i;
        const maxTries = 300;
        const sleep = async (ms) => await new Promise((resolve) => setTimeout(resolve, ms));

        for (i = 1; i <= maxTries; i++) {
            try {
                await sleep(2000);

                log.info(`Attempt #${i} to re-connect...`);
                await this.connect();

                for (const topic of this.subscribedTopics) {
                    await this.subscribe(topic);
                }

                break;
            } catch (e) {
                log.error(`Re-connection attempt #${i} failed: ${e}`);
            }
        }

        if (i === maxTries) {
            throw new Error(`Failed to connect to the events manager websocket`);
        }
    }

    async send(message) {
        log.debug(`Sending message: ${JSON.stringify(message)}`);
        this.ws.send(JSON.stringify(message));
    }

    async sendAndWaitForAck(message) {
        return new Promise((resolve, reject) => {
            // Add a temporary listener to wait for the ack message
            const ackListener = (receivedMessage) => {
                const { type, requestId: receivedRequestId } = JSON.parse(receivedMessage.data);

                if (type === 'v1/ack' && message?.requestId === receivedRequestId) {
                    this.ws.removeEventListener('message', ackListener);
                    resolve();
                }
            };

            this.ws.addEventListener('message', ackListener);

            // Send the message
            log.debug(`Sending message: ${JSON.stringify(message)}`);
            this.ws.send(JSON.stringify(message));

            setTimeout(
                () => reject({ message: `Failed to receive ack message for ${JSON.stringify(message)}` }),
                5000
            );
        });
    }

    async subscribe(topic, waitForAck = false) {
        return new Promise((resolve, reject) => {
            const requestId = uuid();
            const state = store.getState();
            const apiKeyId = selectUserApiKeyId(state);
            const username = selectUserName(state);
            const userId = selectUserId(state);

            log.info(`Subscribing to ${topic}, requestId: ${requestId}...`);

            const message = {
                type: 'v1/subscribe',
                topic,
                apiKeyId,
                requestId,
                senderId: userId,
                senderName: `Dashboard user ${username} (${apiKeyId})`,
            };

            (waitForAck ? this.sendAndWaitForAck(message) : this.send(message))
                .then(() => {
                    log.debug(`Successfully subscribed to: ${topic}`);
                    this.subscribedTopics.add(topic);
                    resolve();
                })
                .catch((e) => reject(e.message));
        });
    }

    async unsubscribe(topic, waitForAck = false) {
        return new Promise((resolve, reject) => {
            if (this.ws?.readyState !== WebSocket.OPEN) {
                this.subscribedTopics.delete(topic);
                // If the websocket is disconnected, we're unsubscribed anyway
                return resolve();
            }

            const requestId = uuid();
            const state = store.getState();
            const apiKeyId = selectUserApiKeyId(state);
            const username = selectUserName(state);
            const userId = selectUserId(state);

            log.info(`Unsubscribing from ${topic}, requestId: ${requestId}...`);

            const message = {
                type: 'v1/unsubscribe',
                topic,
                apiKeyId,
                requestId,
                senderId: userId,
                senderName: `Dashboard user ${username} (${apiKeyId})`,
            };

            (waitForAck ? this.sendAndWaitForAck(message) : this.send(message))
                .then(() => {
                    log.debug(`Successfully unsubscribed from: ${topic}`);
                    this.subscribedTopics.delete(topic);
                    resolve();
                })
                .catch((e) => reject(e.message));
        });
    }

    async publish(topic, parameters, data, waitForAck = false) {
        return new Promise((resolve, reject) => {
            const requestId = uuid();
            const state = store.getState();
            const apiKeyId = selectUserApiKeyId(state);
            const username = selectUserName(state);
            const userId = selectUserId(state);

            log.info(
                `Publishing to ${topic}, parameters: ${JSON.stringify(parameters)}, data: ${JSON.stringify(
                    data
                )}, requestId: ${requestId}...`
            );

            const message = {
                type: 'v1/publish',
                topic,
                payload: { parameters, data },
                apiKeyId,
                requestId,
                senderId: userId,
                senderName: `Dashboard user ${username} (${apiKeyId})`,
            };

            (waitForAck ? this.sendAndWaitForAck(message) : this.send(message))
                .then(() => {
                    log.info(`Successfully published: ${JSON.stringify(data)}`);
                    resolve();
                })
                .catch((e) => reject(e.message));
        });
    }

    addEventHandler(eventTopic, handler, { handleOwnMessages = false } = {}) {
        eventsHandler.addEventHandler(eventTopic, handler, { handleOwnMessages });
    }

    setOnReadyStateChange(onReadyStateChange) {
        this.onReadyStateChange = onReadyStateChange;
    }
}

export default new EventsManagerWebSocket();
