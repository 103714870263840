import { createSlice } from "@reduxjs/toolkit";


export const {
    actions: {attachBanner, detachBanner, resetBanners },
    reducer,
} = createSlice({
    name: 'banner',
    initialState: {
        banners: [],
    },
    reducers: {
        attachBanner: (state, {payload}) => {
            const doesExist = state.banners.find(banner => banner.id === payload.id);
            if(!doesExist) {
                state.banners.push(payload);
            }
        },
        detachBanner: (state, {payload: id}) => {
            state.banners = state.banners.filter(banner => banner.id !== id);
        },
        resetBanners: (state) => {
            state.banners = [];
        },
    },
});