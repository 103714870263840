import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './exitRegionsEntityAdapter';
import { expireSession, logout } from '../../auth/authActions';
import { exitRegionUpdated, exitRegionCreated, exitRegionDeleted } from '../mappingEventsActions';
import { deleteExitRegion, fetchAllExitRegionsInMap, upsertExitRegion } from './exitRegionsActions';

const { getInitialState, upsertOne, setAll, removeOne } = entityAdapter;

export const { reducer } = createSlice({
    name: 'exitRegion',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchAllExitRegionsInMap.fulfilled]: (state, action) => {
            setAll(
                state,
                action.payload.map(({ exitRegionId, shapeType, center, radius, ...exitRegion }) => {
                    let topRight = exitRegion.topRight;
                    let bottomLeft = exitRegion.bottomLeft;
                    if (shapeType === 'rectangular') {
                        topRight = { x: topRight[0], y: topRight[1] };
                        bottomLeft = { x: bottomLeft[0], y: bottomLeft[1] };
                    }
                    return {
                        areaId: exitRegionId,
                        topRight,
                        bottomLeft,
                        type: 'exitRegion',
                        shapeType,
                        radius,
                        center,
                    };
                })
            );
        },
        [upsertExitRegion.fulfilled]: (state, action) => {
            const { exitRegionId, shapeType, center, radius, points } = action.payload;
            let topRight = action.payload.topRight;
            let bottomLeft = action.payload.bottomLeft;
            if (shapeType === 'rectangular') {
                topRight = { x: topRight[0], y: topRight[1] };
                bottomLeft = { x: bottomLeft[0], y: bottomLeft[1] };
            }
            upsertOne(state, {
                areaId: exitRegionId,
                topRight,
                bottomLeft,
                radius,
                center,
                points,
                type: 'exitRegion',
                shapeType,
            });
        },
        [deleteExitRegion.fulfilled]: (state, action) => {
            const { exitRegionId } = action.meta.arg;
            removeOne(state, exitRegionId);
        },

        [exitRegionCreated]: (state, action) => {
            const { exitRegionId, topRight, bottomLeft, shapeType, title } = action.payload.data;
            upsertOne(state, {
                areaId: exitRegionId,
                topRight,
                bottomLeft,
                type: 'exitRegion',
                shapeType,
                title,
            });
        },
        [exitRegionUpdated]: (state, action) => {
            const { exitRegionId, topRight, bottomLeft, title, shapeType } = action.payload.data;
            upsertOne(state, {
                areaId: exitRegionId,
                topRight,
                bottomLeft,
                type: 'exitRegion',
                shapeType,
                title,
            });
        },
        [exitRegionDeleted]: (state, action) => {
            const { exitRegionId } = action.payload.data;
            removeOne(state, exitRegionId);
        },
        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
    },
});
