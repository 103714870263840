import React, { useState } from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-dracula';
import {
    getMetadataEditorCategoryExpansionPanelId,
    getMetadataEditorFieldId,
    getMetadataEditorJsonEditorId,
    getMetadataEditorSwitchEditorWarningAcceptId,
    getMetadataEditorSwitchEditorWarningCancelId,
    getMetadataEditorSwitchEditorWarningId,
    getMetadataEditorSwitchToJsonEditorId,
    getMetadataEditorSwitchToOriientFormatId,
} from './MetadataEditor.selectors';
import { Button } from '../../../../common/themed';
import MetadataEditorField from './MetadataEditorField';
import { MdExpandMore as ExpandMoreIcon } from 'react-icons/md';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import metadataFieldTypes from './MetadataEditorFieldTypes';
import {
    getMetadataDefaultValues,
    setDeepProp,
    isOriientFormat,
    getDeepProp,
} from '../../../../../utils/MetadataUtils';

import excludeJsonFields from './excludeJsonFields';
import { useDispatch } from 'react-redux';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../../state-management/notification/notificationReducer';

const ORIIENT_FORMAT = 'oriientFormat';
const JSON_EDITOR = 'jsonEditor';

const EditorWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const FieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:last-child) {
        margin-block-end: 10px;
    }
`;

const SwitchEditorButton = styled(Button)`
    margin-block-start: 10px;
    align-self: flex-end;
`;

export default function MetadataEditor(props) {
    const { value, onChange = (json) => { }, oriientFormat, enableSwitching = true } = props;

    const [displayedEditor, setDisplayedEditor] = useState(
        isOriientFormat(oriientFormat, value) ? ORIIENT_FORMAT : JSON_EDITOR
    );
    const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);

    const dispatch = useDispatch();
    let jsonValue;
    let displayedJson = value;

    try {
        jsonValue = JSON.parse(value);
        displayedJson = JSON.stringify(JSON.parse(value), null, '\t');
    } catch (e) { }

    const handleChange = (newValue) => {
        try {
            const oldValue = value && value !== '' ? value : '{}';
            const newJson = JSON.parse(oldValue);

            for (const [key, value] of Object.entries(newValue ?? {})) {
                if (value !== undefined && value !== null) {
                    setDeepProp(newJson, value, key);
                } else {
                    delete newJson[key];
                }
            }

            onChange(JSON.stringify(newJson));
        } catch (e) { }
    };

    const handleSwitchToOriientFormatClick = () => {
        if (isOriientFormat(oriientFormat, value)) {
            setDisplayedEditor(ORIIENT_FORMAT);
        } else {
            setIsWarningDialogOpen(true);
        }
    };

    const handleResetToOriientFormat = () => {
        onChange(JSON.stringify(getMetadataDefaultValues(oriientFormat)));
        setDisplayedEditor(ORIIENT_FORMAT);
        setIsWarningDialogOpen(false);
    };

    const renderOriientFormat = (fields) => {
        return Object.entries(fields).map(([fieldName, { type, label, fields, ...otherProps } = {}]) => {
            if (!type) {
                return null;
            } else if (type === metadataFieldTypes.CATEGORY) {
                return (
                    <Accordion key={fieldName} id={getMetadataEditorCategoryExpansionPanelId(fieldName)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{label}</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <FieldsWrapper> {renderOriientFormat(fields)}</FieldsWrapper>
                        </AccordionDetails>
                    </Accordion>
                );
            } else {
                return (
                    <MetadataEditorField
                        key={fieldName}
                        id={getMetadataEditorFieldId(fieldName)}
                        type={type ?? metadataFieldTypes.STRING}
                        name={fieldName}
                        label={label ?? ''}
                        onChange={handleChange}
                        value={getDeepProp(jsonValue || {}, fieldName)}
                        {...otherProps}
                    />
                );
            }
        });
    };

    const renderJsonEditor = () => {
        let parsedDisplayedJson = {};
        let modifiedDisplayedJson;
        try {
            parsedDisplayedJson = JSON.parse(displayedJson);
            excludeJsonFields.forEach((field) => {
                if (parsedDisplayedJson.hasOwnProperty(field)) {
                    delete parsedDisplayedJson[field];
                }
            });
            modifiedDisplayedJson = JSON.stringify(parsedDisplayedJson, null, '\t');
            dispatch(showSuccessNotification('JSON is valid'));
        } catch (error) {
            dispatch(showErrorNotification(`JSON is invalid:${error.message}`));
        }

        return (
            <>
                <AceEditor
                    name={getMetadataEditorJsonEditorId()}
                    mode={'json'}
                    theme={'dracula'}
                    onChange={onChange}
                    width={'100%'}
                    height={'300px'}
                    value={modifiedDisplayedJson}
                    setOptions={{
                        showLineNumbers: true,
                        useWorker: false,
                        tabSize: 4,
                    }}
                />
            </>
        );
    };

    const renderEditor = () => {
        switch (displayedEditor) {
            case ORIIENT_FORMAT:
                return (
                    <>
                        {renderOriientFormat(oriientFormat)}
                        {enableSwitching && <SwitchEditorButton
                            id={getMetadataEditorSwitchToJsonEditorId()}
                            variant={'outlined'}
                            onClick={() => setDisplayedEditor(JSON_EDITOR)}
                        >
                            Switch to JSON editor
                        </SwitchEditorButton>}
                    </>
                );
            case JSON_EDITOR:
                return (
                    <>
                        {renderJsonEditor()}
                        {enableSwitching && <SwitchEditorButton
                            id={getMetadataEditorSwitchToOriientFormatId()}
                            variant={'outlined'}
                            onClick={handleSwitchToOriientFormatClick}
                        >
                            Switch to Oriient format
                        </SwitchEditorButton>}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <EditorWrapper>{renderEditor()}</EditorWrapper>

            <Dialog id={getMetadataEditorSwitchEditorWarningId()} open={isWarningDialogOpen}>
                <DialogTitle>Warning</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        This region's metadata doesn't seem to be a proper JSON in the Oriient format.
                        <br />
                        Switching to the Oriient format will erase the current metadata and reset it to
                        default values.
                        <br />
                        Are you sure?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        id={getMetadataEditorSwitchEditorWarningCancelId()}
                        variant={'text'}
                        onClick={() => setIsWarningDialogOpen(false)}
                    >
                        Cancel
                    </Button>

                    <Button
                        id={getMetadataEditorSwitchEditorWarningAcceptId()}
                        onClick={handleResetToOriientFormat}
                    >
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

MetadataEditor.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    oriientFormat: PropTypes.objectOf((propValue, key) => {
        if (!propValue[key]?.type) {
            return new Error(`Invalid Oriient format: The '${key}' field doesn't have a 'type' field.`);
        } else if (propValue[key].type === metadataFieldTypes.CATEGORY && !propValue[key]?.fields) {
            return new Error(`Invalid Oriient format: The '${key}' category doesn't have a 'fields' field.`);
        }
    }).isRequired,
};
