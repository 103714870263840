import { createSelector } from '@reduxjs/toolkit';
import entityAdapter from './triggerEntityAdapter';
import { selectAllRegions, selectRegionById } from '../region/regionSelectors';
import { selectMapById } from '../map/mapSelectors';
import { selectRegionPlacementEntities } from '../region-placement/regionPlacementSelectors';

export const {
    selectById: selectTriggerById,
    selectIds: selectTriggerIds,
    selectEntities: selectTriggerEntities,
    selectAll: selectAllTriggers,
    selectTotal: selectTotalTriggers,
} = entityAdapter.getSelectors((state) => state.triggers);

export const getSelectAllTriggerIdsAttachedToRegion = (regionId) =>
    createSelector(
        (state) => selectRegionById(state, regionId),
        (region) => region?.triggerIds
    );

export const getSelectAllTriggersAttachedToRegion = (regionId) =>
    createSelector(
        (state) => selectRegionById(state, regionId),
        selectTriggerEntities,
        (region, triggers) => region?.triggerIds?.map((id) => triggers?.[id])
    );

export const getSelectAllTriggersAttachedToMap = (mapId) =>
    createSelector(
        (state) => selectMapById(state, mapId),
        selectRegionPlacementEntities,
        selectAllRegions,
        selectTriggerEntities,
        (map, placements, regions, triggers) => {
            const placementIdToRegion = regions
                ?.filter(({ placementIds }) => placementIds?.length > 0)
                ?.reduce(
                    (result, { placementIds = [], ...region }) => ({
                        ...result,
                        ...placementIds.reduce((res, placementId) => ({ ...res, [placementId]: region }), {}),
                    }),
                    {}
                );

            const triggerIds = [
                ...new Set(
                    map?.regionPlacementIds?.reduce(
                        (result, placementId) => [
                            ...result,
                            ...(placementIdToRegion?.[placementId]?.triggerIds ?? []),
                        ],
                        []
                    )
                ),
            ];

            return triggerIds?.map((triggerId) => triggers?.[triggerId]);
        }
    );
