import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Collapse as MuiCollapse, Typography } from '@material-ui/core';
import { MdExpandLess as ExpandLessIcon, MdExpandMore as ExpandMoreIcon } from 'react-icons/md';

const ExpandableSectionTitle = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px 16px;
    color: #777777; // TODO color should come from theme

    :hover {
        background-color: #e2e2e2;
    }
`;

const Collapse = styled(MuiCollapse)`
    padding: ${(props) => (props.in ? '16px 24px' : '')};
`;

export default function ExpandableSection(props) {
    const { title, defaultIsExpanded = false, children } = props;

    const [isExpanded, setIsExpanded] = useState(defaultIsExpanded);

    return (
        <>
            <ExpandableSectionTitle onClick={() => setIsExpanded(!isExpanded)}>
                <Typography variant={'subtitle1'} color={'inherit'}>
                    {title}
                </Typography>

                {isExpanded ? <ExpandLessIcon size={25} /> : <ExpandMoreIcon size={25} />}
            </ExpandableSectionTitle>

            <Collapse in={isExpanded}>{children}</Collapse>
        </>
    );
}

ExpandableSection.propTypes = {
    title: PropTypes.string.isRequired,
    defaultIsExpanded: PropTypes.bool,
};
