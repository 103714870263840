import React, { useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '../../../common/themed';
import styled from 'styled-components';

export const CustomItemRenderer = ({ checked, option, onClick, disabled }) => {
    return (
        <FormControlLabel
            label={option.label}
            control={
                <Checkbox
                    checked={checked}
                    onClick={onClick}
                    value={option.value}
                    disabled={disabled}
                />
            }
        />
    );
};

export const MultiDropdown = styled(MultiSelect)`
    min-width: 160px;
    max-width: ${(props) => props.styles.maxWidth};
    margin-top: ${(props) => props.styles.marginTop};
    @media (max-width: 1600px) {
        margin-top: 12px;
    }
    .selected {
        --rmsc-selected: white !important;
    }
`;
