import styled from 'styled-components';
import { SpaceSettingsTable } from './SpaceSettingsTable';

const Container = styled.div``;

export const AnalyticsSettings = () => {
    return (
        <Container>
            <SpaceSettingsTable />
        </Container>
    );
};
