import { Grid, Typography } from '@material-ui/core';
import { useState } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Button } from '../common/themed';
import { CustomCellComponent } from '../common/themed/CustomCellComponent';

const defaultCellStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
};
const NOT_AVAILABLE = 'N/A';

const notAvailableInput = (input) => [null, undefined].includes(input);

export const columns = [
    {
        title: 'Building Name',
        description: 'Building Name',
        'Csv header': '',
        field: 'buildingName',
        type: 'string',
        index: 0,
        placeholder: 'Free text',
        defaultOperatorId: 6,
        cellStyle: {
            ...defaultCellStyle,
        },
    },
    {
        title: 'Map ID',
        description: 'Map ID',
        'Csv header': '',
        field: 'mapId',
        type: 'string',
        format: 'uuid',
        placeholder: 'UUID',
        index: 1,
        cellStyle: {
            ...defaultCellStyle,
        },
        render: ({ mapId }) =>
            CustomCellComponent({
                text: mapId,
                textStyle: { ...defaultCellStyle, width: '50px' },
                copyIcon: true,
            }),
    },
    {
        title: 'Building ID',
        description: 'Building ID',
        'Csv header': '',
        field: 'buildingId',
        format: 'uuid',
        type: 'string',
        align: 'left',
        placeholder: 'UUID',
        index: 2,
        cellStyle: {
            ...defaultCellStyle,
        },
        render: ({ buildingId }) =>
            CustomCellComponent({
                text: buildingId,
                textStyle: { ...defaultCellStyle, width: '50px' },
                copyIcon: true,
            }),
    },
    {
        title: 'Tracking Score',
        description: 'Tracking Score',
        'Csv header': '',
        field: 'trackingScore',
        type: 'numeric',
        align: 'left',
        defaultSort: 'desc',
        sorting: true,
        placeholder: '0.00',
        format: 'percentage',
        index: 3,
        cellStyle: {
            ...defaultCellStyle,
        },
        render: ({ trackingScore }) => {
            if (notAvailableInput(trackingScore)) return NOT_AVAILABLE;
            return `${(trackingScore * 100).toFixed(2)}%`;
        },
    },
    {
        title: 'WoW Trend',
        description: 'WoW Tracking Score Change',
        'Csv header': '',
        field: 'wowTrackingScoreChange',
        type: 'numeric',
        align: 'left',
        placeholder: '0.00',
        format: 'percentage',
        index: 4,
        cellStyle: {
            ...defaultCellStyle,
        },
        render: ({ wowTrackingScoreChange }) => {
            if (notAvailableInput(wowTrackingScoreChange)) return NOT_AVAILABLE;
            const isPositive = wowTrackingScoreChange > 0;
            const color = isPositive ? 'green' : wowTrackingScoreChange < 0 ? 'red' : 'black';
            const icon = isPositive ? <FaArrowUp /> : wowTrackingScoreChange < 0 ? <FaArrowDown /> : null;
            return (
                <Grid style={{ color, columnGap: '4px' }} container alignItems="center">
                    {icon}
                    <Typography>{Math.abs(wowTrackingScoreChange * 100).toFixed(2)}%</Typography>
                </Grid>
            );
        },
    },
    {
        title: 'Magnetic Alignment',
        description: 'Magnetic Alignment',
        'Csv header': '',
        field: 'magneticAlignment',
        type: 'numeric',
        align: 'left',
        placeholder: '0.00',
        format: 'percentage',
        index: 5,
        cellStyle: {
            ...defaultCellStyle,
        },
        render: ({ magneticAlignment }) => {
            if (notAvailableInput(magneticAlignment)) return NOT_AVAILABLE;
            return `${(magneticAlignment * 100).toFixed(2)}%`;
        },
    },
    {
        title: 'Obstacles Alignment',
        description: 'Obstacles Alignment',
        'Csv header': '',
        field: 'obstaclesAlignmentPercentage',
        type: 'numeric',
        align: 'left',
        placeholder: '0.00',
        format: 'percentage',
        index: 6,
        cellStyle: {
            ...defaultCellStyle,
        },
        render: ({ obstaclesAlignmentPercentage }) => {
            if (notAvailableInput(obstaclesAlignmentPercentage)) return NOT_AVAILABLE;
            return `${(obstaclesAlignmentPercentage * 100).toFixed(2)}%`;
        },
    },
    {
        title: 'Lanes Alignment',
        description: 'Lanes Alignment',
        'Csv header': '',
        field: 'lanesAlignmentPercentage',
        type: 'numeric',
        align: 'left',
        placeholder: '0.00',
        format: 'percentage',
        index: 7,
        cellStyle: {
            ...defaultCellStyle,
        },
        render: ({ lanesAlignmentPercentage }) => {
            if (notAvailableInput(lanesAlignmentPercentage)) return NOT_AVAILABLE;
            return `${(lanesAlignmentPercentage * 100).toFixed(2)}%`;
        },
    },
    {
        title: 'Days Based on',
        description: 'Days Based On',
        'Csv header': '',
        field: 'daysBasedOn',
        type: 'numeric',
        align: 'left',
        index: 8,
        placeholder: '0',
        format: 'ceil',
        cellStyle: {
            ...defaultCellStyle,
        },
        render: ({ daysBasedOn }) =>
            notAvailableInput(daysBasedOn) ? NOT_AVAILABLE : Math.ceil(daysBasedOn),
    },
    {
        title: '# Of Sessions',
        description: 'Number of sessions',
        'Csv header': '',
        field: 'sessionsNum',
        type: 'numeric',
        placeholder: '0',
        align: 'left',
        index: 9,
        cellStyle: {
            ...defaultCellStyle,
        },
        render: ({ sessionsNum }) => (notAvailableInput(sessionsNum) ? NOT_AVAILABLE : sessionsNum),
    },
    {
        title: 'Magnetic Map',
        description: 'Last magnetic map update',
        'Csv header': '',
        field: 'gridLastModifiedTime',
        type: 'date',
        placeholder: 'YYYY-MM-DD',
        align: 'left',
        index: 10,
        cellStyle: {
            ...defaultCellStyle,
        },
        render: ({ gridLastModifiedTime }) => {
            if (!gridLastModifiedTime) return NOT_AVAILABLE;
            const text = gridLastModifiedTime.slice(0, 10);
            return text;
        },
    },
    {
        title: 'Validation Visuals',
        description: 'Last validation visuals update',
        'Csv header': '',
        field: 'createdOn',
        type: 'date',
        align: 'left',
        placeholder: 'YYYY-MM-DD',
        index: 11,
        cellStyle: {
            ...defaultCellStyle,
        },
        render: ({ createdOn }) => {
            if (!createdOn) return NOT_AVAILABLE;
            const text = createdOn.slice(0, 10);
            return text;
        },
    },
    {
        title: 'Spaces',
        description: 'Spaces',
        'Csv header': '',
        field: 'spaces',
        type: 'string',
        align: 'left',
        placeholder: 'value1,value2, etc...',
        index: 12,
        cellStyle: {
            ...defaultCellStyle,
        },
        render: ({ spaces }) =>
            CustomCellComponent({
                tooltipContent: (
                    <ul>
                        {spaces.split(',').map((space) => (
                            <li>{space}</li>
                        ))}
                    </ul>
                ),
                text: spaces,
                textStyle: { ...defaultCellStyle, width: '150px' },
            }),
    },
    {
        title: 'Publish',
        field: 'publish',
        description: 'Publish',
        index: 13,
    },
];
