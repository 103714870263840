import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MapViewer from '../common/map-viewer';
import {
    selectMappingAreLinesOfInterestShown,
    selectMappingIsMapInGrayscale,
    selectMappingIsSettingPixelToMeter,
    selectMappingSelectedBuildingId,
    selectMappingSelectedFloorId,
    selectMappingSelectedMapId,
    setIsSettingPixelToMeter,
} from '../../state-management/user-inputs/mappingSlice';
import MapViewerPixelToMeterMeasureOverlay from '../common/map-viewer/overlays/pixel-to-meter-measure';
import { updateMap } from '../../state-management/map/mapActions';
import { selectMapById } from '../../state-management/map/mapSelectors';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../state-management/notification/notificationReducer';
import { isFulfilled } from '../../state-management/utils';
import { JunctionMismatchesOverlay } from './overlays/JunctionMismatchesOverlay';
import { LineOfInterestCreationOverlay } from './overlays/LineOfInterestCreationOverlay';
import { MapValidationsOverlay } from './overlays/MapValidationsOverlay';
import { LanesOverlay } from './overlays/LanesOverlay';
import { WebSocketStatusOverlay } from './overlays/WebSocketStatusOverlay';
import { MappersOverlay } from './overlays/MappersOverlay';
import { AreasOfInterestOverlay } from './overlays/AreasOfInterestOverlay';
import { LinesOfInterestOverlay } from './overlays/LinesOfInterestOverlay';
import { CreateNewItemOverlay } from './overlays/CreateNewItemOverlay';
import MapViewerMouseCoordinatesAndZoomOverlay from '../common/map-viewer/overlays/mouse-coordinates-and-zoom';
import { AreaOfInterestCreationOverlay } from './overlays/AreaOfInterestCreationOverlay';
import { ElementsCreationOverlay } from './overlays/ElementsCreationOverlay';
import { getDefaultMapImage } from '../../utils/mapImages.helper';
import { MapCroppingOverlay } from './overlays/MapCroppingOverlay';
import { DeletedLanesWarningOverlay } from './overlays/DeletedLanesWarningOverlay';
import { SignMarkCreationOverlay } from './overlays/SignMarkCreationOverlay';
import { SignMarksOverlay } from './overlays/SignMarkOverlay';

export default function MappingMapViewer({ selfHealingEnabled }) {
    const dispatch = useDispatch();
    const selectedBuildingId = useSelector(selectMappingSelectedBuildingId);
    const selectedFloorId = useSelector(selectMappingSelectedFloorId);
    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const selectedMap = useSelector((state) => selectMapById(state, selectedMapId));
    const defaultMapImage = getDefaultMapImage(selectedMap?.mapImages);
    const isMapInGrayscale = useSelector(selectMappingIsMapInGrayscale);
    const isSettingPixelToMeter = useSelector(selectMappingIsSettingPixelToMeter);
    const areLinesOfInterestShown = useSelector(selectMappingAreLinesOfInterestShown);
    const handlePixelToMeterChange = async (pixelToMeter) => {
        const result = await dispatch(
            updateMap({
                buildingId: selectedBuildingId,
                floorId: selectedFloorId,
                mapId: selectedMapId,
                mapData: {
                    mapName: selectedMap.mapName,
                    imgBase64: '',
                    mapOffset: selectedMap.mapOffset,
                    mapUsage: defaultMapImage?.mapUsage || selectedMap.mapUsage,
                    pixelToMeter,
                    ...(selectedMap.mapComponentImageRotation !== null && {
                        mapComponentImageRotation: selectedMap.mapComponentImageRotation,
                    }),
                },
            })
        );

        if (isFulfilled(result)) {
            await dispatch(showSuccessNotification('Pixel to meter ratio updated successfully'));
        } else {
            await dispatch(showErrorNotification('Failed to update pixel to meter ratio'));
        }

        await dispatch(setIsSettingPixelToMeter(false));
    };
    return (
        <MapViewer
            buildingId={selectedBuildingId}
            floorId={selectedFloorId}
            mapId={selectedMapId}
            isGrayscale={isMapInGrayscale}
        >
            {isSettingPixelToMeter && (
                <MapViewerPixelToMeterMeasureOverlay
                    onSubmit={handlePixelToMeterChange}
                    onCancel={() => dispatch(setIsSettingPixelToMeter(false))}
                />
            )}

            <LanesOverlay disabled={selfHealingEnabled} />
            <MapValidationsOverlay />
            <WebSocketStatusOverlay />
            <DeletedLanesWarningOverlay />
            <JunctionMismatchesOverlay />
            <MappersOverlay />
            <AreasOfInterestOverlay type="normal" />
            <AreasOfInterestOverlay type="element" />
            <LinesOfInterestOverlay areLinesOfInterestShown={areLinesOfInterestShown} />
            <AreaOfInterestCreationOverlay />
            <ElementsCreationOverlay />
            <LineOfInterestCreationOverlay />
            <CreateNewItemOverlay />
            <MapViewerMouseCoordinatesAndZoomOverlay />
            <MapCroppingOverlay />
            <SignMarkCreationOverlay />
            <SignMarksOverlay
                buildingId={selectedBuildingId}
                floorId={selectedFloorId}
                mapId={selectedMapId}
            />
        </MapViewer>
    );
}
