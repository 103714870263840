import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { selectHasError, selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { getSelectJunctionMismatchesInMap } from '../../../../state-management/map/mapSelectors';
import { fetchAllJunctionMismatchesInMap } from '../../../../state-management/mapping/junction-mismatch/junctionMismatchActions';
import {
    selectIsEqualizedFetched,
    selectIsUnequalizedFetched,
    selectJunctionMismatchById,
} from '../../../../state-management/mapping/junction-mismatch/junctionMismatchSelectors';

/**
 * Hook that fetches junction mismatches to the Redux store and returns either all or one of them, based on the provided options.
 *
 * @param asObject - Return all entities as an object, with the IDs as the keys.
 * @param mapId - Map ID to fetch all entities from.
 * @param junctionId- Specific junction ID to return.
 * @param useEqualizedMap - Whether or not to get junctions based on the equalized form of the map
 * @param junctionMismatchToggled - Whether or not fetch the junctions
 * @return {{isLoading: undefined, data: (undefined|*), hasError: undefined}}
 */
export default function useJunctionMismatches({
    mapId,
    junctionId,
    useEqualizedMap = true,
    junctionMismatchToggle = false,
} = {}) {
    const dispatch = useDispatch();
    const selectIsLoadingJunctions = useMemo(
        () => selectIsLoading({ type: fetchAllJunctionMismatchesInMap.typePrefix, arg: mapId }),
        [mapId]
    );
    const selectHasErrorInJunctions = useMemo(
        () => selectHasError({ type: fetchAllJunctionMismatchesInMap.typePrefix, arg: mapId }),
        [mapId]
    );
    const selectJunctionMismatchesInMap = useMemo(
        () => getSelectJunctionMismatchesInMap(mapId, useEqualizedMap),
        [mapId, useEqualizedMap]
    );

    const junctionsArray = useSelector(selectJunctionMismatchesInMap);
    const junction = useSelector((state) => selectJunctionMismatchById(state, junctionId));
    const isEqualizedFetched = useSelector(selectIsEqualizedFetched);
    const isUnequalizedFetched = useSelector(selectIsUnequalizedFetched);
    const isLoading = useSelector(selectIsLoadingJunctions);
    const hasError = useSelector(selectHasErrorInJunctions);

    useEffect(() => {
        // If there is a provided map ID, and we haven't fetched all of its junction mismatches yet,
        // and we are not currently fetching them, fetch them

        if (
            mapId &&
            !isLoading &&
            !hasError &&
            junctionMismatchToggle &&
            ((useEqualizedMap && !isEqualizedFetched) || (!useEqualizedMap && !isUnequalizedFetched))
        ) {
            dispatch(fetchAllJunctionMismatchesInMap({ mapId, useEqualizedMap }));
        }
    }, [
        dispatch,
        isLoading,
        hasError,
        mapId,
        useEqualizedMap,
        isEqualizedFetched,
        isUnequalizedFetched,
        junctionMismatchToggle,
    ]);

    return {
        data: junctionId ? junction : junctionsArray,
        isLoading,
        hasError,
    };
}
