import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { RootRef, Paper, Typography } from '@material-ui/core';
import useMapsFullData from '../hooks/data-fetching/useMapsFullData';
import ErrorGeneral from '../error-pages/ErrorGeneral';
import { Button, PulsePreloader, Skeleton } from '../themed';
import { FaMap } from 'react-icons/fa';
import { MapViewerContext } from './MapViewerContext';
import {
    getMapViewerContainerId,
    getMapViewerMapImageId,
    getMapViewerMapImageWrapperId,
} from './MapViewer.selectors';
import clsx from 'clsx';
import {
    BOUNDARY_MAX_X,
    BOUNDARY_MAX_Y,
    BOUNDARY_MIN_X,
    BOUNDARY_MIN_Y,
    CURSOR,
    IMAGE_NATURAL_HEIGHT,
    IMAGE_NATURAL_WIDTH,
    IS_PANNING,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    PREV_POINTER_X,
    PREV_POINTER_Y,
    SCALE,
    TRANSLATE_X,
    TRANSLATE_Y,
} from '../../../constants/mapViewerVariables';
import { useSelector } from 'react-redux';
import { selectShowGrid } from '../../../state-management/user-inputs/preferencesSlice';
import { getDefaultMapImage } from '../../../utils/mapImages.helper';
const defaultImagePadding = 30;
const zoomChangeFactor = 0.1;
const maxScale = 100;
const minScale = 0.05;

const MapViewerContainer = styled(Paper)`
    height: 100%;
    overflow: hidden;
    position: relative;
`;

const Grid = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    ${(p) =>
        p.showGrid &&
        `
 background-image: linear-gradient(#d1d1d18c .1em, transparent .1em), linear-gradient(90deg, #d1d1d18c .1em, transparent .1em);
 background-size: calc(var(${SCALE})*3em) calc(var(${SCALE})*3em);
`}
`;
const MapImageWrapper = styled.div`
    transform: translate(calc(var(${TRANSLATE_X}) * 1px), calc(var(${TRANSLATE_Y}) * 1px))
        scale(var(${SCALE}));
    transform-origin: 0 0 0;
    cursor: var(${CURSOR});
    display: inline-flex;
    position: absolute;
    user-select: none;
`;

const MapImage = styled.img`
    border: 1px solid #ff6200; // TODO color should come from theme
    &.grayscale {
        filter: grayscale(1);
    }
`;

const NoMapSelectedMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #cccccc; // TODO color should come from theme
`;

const MapIcon = styled(FaMap).attrs(() => ({
    size: 80,
}))`
    fill: #a2a2a2; // TODO color should come from theme
`;

const MapImageSkeletonWrapper = styled.div`
    width: 100%;
    height: 100%;

    & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
`;

export default function MapViewer(props) {
    const { mapId, buildingId, floorId, base64, isGrayscale = false, children, ...otherProps } = props;

    const [SvgImage, setSvgImage] = useState(null);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [areBoundariesSet, setAreBoundariesSet] = useState(false);
    const showGrid = useSelector(selectShowGrid);
    const MapViewerOptionsRef = useRef({
        disablePanning: false,
        disableZooming: false,
    });

    const {
        data: { [mapId]: map },
        isLoading,
        hasError,
    } = useMapsFullData({ buildingId, floorId, mapIds: [mapId], asObject: true });
    const { mapOffset, pixelToMeter, mapUrl } = map ?? {};
    const defaultMapImage = getDefaultMapImage(map?.mapImages);
    const imageUrl = !!base64
        ? `data:image/jpeg;base64,${base64}`
        : defaultMapImage && defaultMapImage.mapUrl;

    const containerRef = useRef();
    const imageWrapperRef = useRef();
    const imageRef = useRef();

    const getCSSVariable = useCallback(
        (name) => getComputedStyle(containerRef.current)?.getPropertyValue(name),
        []
    );
    const setCSSVariable = useCallback(
        (name, value) => containerRef.current?.style?.setProperty(name, value),
        []
    );

    const handleMouseMove = useCallback(
        (event) => {
            event.preventDefault();
            const { clientX, clientY } = event;

            const translateX = parseFloat(getCSSVariable(TRANSLATE_X));
            const translateY = parseFloat(getCSSVariable(TRANSLATE_Y));
            const prevPointerX = parseFloat(getCSSVariable(PREV_POINTER_X));
            const prevPointerY = parseFloat(getCSSVariable(PREV_POINTER_Y));

            setCSSVariable(TRANSLATE_X, translateX + (clientX - prevPointerX));
            setCSSVariable(TRANSLATE_Y, translateY + (clientY - prevPointerY));
            setCSSVariable(PREV_POINTER_X, clientX);
            setCSSVariable(PREV_POINTER_Y, clientY);
            setCSSVariable(IS_PANNING, 'true');
        },
        [getCSSVariable, setCSSVariable]
    );

    const handleMouseUp = useCallback(() => {
        containerRef.current.removeEventListener('mouseleave', handleMouseUp);
        containerRef.current.removeEventListener('mouseup', handleMouseUp);
        containerRef.current.removeEventListener('mousemove', handleMouseMove);

        setCSSVariable(IS_PANNING, 'false');
        setCSSVariable(CURSOR, 'grab');
    }, [handleMouseMove, setCSSVariable]);

    const handleMouseDown = useCallback(
        (event) => {
            const { clientX, clientY } = event;

            // Left click
            if (!MapViewerOptionsRef.current.disablePanning && event.button === 0) {
                setCSSVariable(PREV_POINTER_X, clientX);
                setCSSVariable(PREV_POINTER_Y, clientY);
                setCSSVariable(CURSOR, 'grabbing');

                containerRef.current.addEventListener('mouseleave', handleMouseUp);
                containerRef.current.addEventListener('mouseup', handleMouseUp);
                containerRef.current.addEventListener('mousemove', handleMouseMove);
            }
        },
        [setCSSVariable, handleMouseUp, handleMouseMove]
    );

    const handleWheel = useCallback(
        (event) => {
            const { clientX, clientY, deltaY } = event;
            event.stopPropagation();
            event.preventDefault();

            if (!MapViewerOptionsRef.current.disableZooming && deltaY !== 0 && containerRef.current) {
                const containerRect = containerRef.current.getBoundingClientRect();

                const zoomFactor = event.deltaY < 0 ? 1 + zoomChangeFactor : 1 - zoomChangeFactor;
                const x = clientX - containerRect.left;
                const y = clientY - containerRect.top;

                const scale = parseFloat(getCSSVariable(SCALE));
                const translateX = parseFloat(getCSSVariable(TRANSLATE_X));
                const translateY = parseFloat(getCSSVariable(TRANSLATE_Y));

                const newScale = Math.round((scale * zoomFactor + Number.EPSILON) * 1000000) / 1000000; // Round the scale up to the 6th decimal point (CSS truncates after that point)

                if (newScale > minScale && newScale < maxScale) {
                    setCSSVariable(SCALE, newScale);
                    setCSSVariable(TRANSLATE_X, x - zoomFactor * (x - translateX));
                    setCSSVariable(TRANSLATE_Y, y - zoomFactor * (y - translateY));
                }
            }
        },
        [getCSSVariable, setCSSVariable]
    );

    useEffect(() => {
        const ref = containerRef.current;
        if (ref) {
            ref.addEventListener('wheel', handleWheel);

            return () => {
                ref.removeEventListener('wheel', handleWheel);
            };
        }
    }, [handleWheel]);

    useEffect(() => {
        if (map) {
            setCSSVariable(OFFSET_X, mapOffset?.x ?? 0);
            setCSSVariable(OFFSET_Y, mapOffset?.y ?? 0);
            setCSSVariable(PIXEL_TO_METER, pixelToMeter ?? 1);
        }
    }, [map, mapOffset, pixelToMeter, setCSSVariable]);

    useEffect(() => {
        if (imageUrl) {
            setIsImageLoaded(false);
            setCSSVariable(TRANSLATE_X, 0);
            setCSSVariable(TRANSLATE_Y, 0);
            setCSSVariable(PREV_POINTER_X, 0);
            setCSSVariable(PREV_POINTER_Y, 0);
            setCSSVariable(SCALE, 1);
            setCSSVariable(IS_PANNING, 'false');
            setCSSVariable(IMAGE_NATURAL_HEIGHT, 0);
            setCSSVariable(IMAGE_NATURAL_WIDTH, 0);

            let image = new Image();
            image.src = imageUrl;
            image.onload = () => {
                const { clientHeight: containerHeight, clientWidth: containerWidth } =
                    containerRef.current ?? {};

                const defaultScale = Math.min(
                    (containerHeight - defaultImagePadding * 2) / image.height,
                    (containerWidth - defaultImagePadding * 2) / image.width
                );

                setCSSVariable(SCALE, Math.round((defaultScale + Number.EPSILON) * 1000000) / 1000000); // Round the scale up to the 6th decimal point (CSS truncates after that point)
                setCSSVariable(TRANSLATE_X, containerWidth / 2 - (defaultScale * image.width) / 2);
                setCSSVariable(TRANSLATE_Y, containerHeight / 2 - (defaultScale * image.height) / 2);
                setCSSVariable(IMAGE_NATURAL_HEIGHT, image.height);
                setCSSVariable(IMAGE_NATURAL_WIDTH, image.width);

                setIsImageLoaded(true);
            };
        }
    }, [imageUrl, setCSSVariable]);

    useEffect(() => {
        if (pixelToMeter && mapOffset) {
            let image = new Image();
            image.src = imageUrl;
            image.onload = () => {
                // Set the boundaries of the map image
                setCSSVariable(BOUNDARY_MAX_X, image.width / pixelToMeter + mapOffset.x);
                setCSSVariable(BOUNDARY_MAX_Y, image.height / pixelToMeter + mapOffset.y);
                setCSSVariable(BOUNDARY_MIN_X, mapOffset.x);
                setCSSVariable(BOUNDARY_MIN_Y, mapOffset.y);

                setAreBoundariesSet(true);
            };
        }
    }, [imageUrl, mapOffset, pixelToMeter, setCSSVariable]);

    const contextValue = useMemo(
        () => ({
            imageWrapperRef,
            imageRef,
            containerRef,
            getCSSVariable,
            setCSSVariable,
            MapViewerOptionsRef,
            handleWheel,
        }),
        [getCSSVariable, setCSSVariable]
    );

    const renderMapImage = () => {
        const areBoundariesRequired = !!pixelToMeter && !!mapOffset;

        if (!mapId && !base64) {
            return (
                <NoMapSelectedMessageWrapper>
                    <MapIcon />
                    <Typography variant={'subtitle1'}>Please select a map to view</Typography>
                </NoMapSelectedMessageWrapper>
            );
        }

        if (hasError) {
            return <ErrorGeneral />;
        }

        if (isLoading) {
            return <PulsePreloader />;
        }

        return (
            (defaultMapImage || base64) &&
            (isImageLoaded && (!areBoundariesRequired || areBoundariesSet) ? (
                <MapImageWrapper
                    id={getMapViewerMapImageWrapperId()}
                    className={clsx({ panning: getCSSVariable(IS_PANNING) === 'true' })}
                    ref={imageWrapperRef}
                >
                    <>
                        {/* <Grid showGrid={showGrid}></Grid>*/}
                        <MapImage
                            id={getMapViewerMapImageId()}
                            className={clsx({ grayscale: isGrayscale })}
                            ref={imageRef}
                            alt={'map-image'}
                            src={base64 ? `data:image/jpeg;base64,${base64}` : imageUrl}
                            onLoad={() => setIsImageLoaded(true)}
                        />

                        {children && (
                            <MapViewerContext.Provider value={contextValue}>
                                {children}
                            </MapViewerContext.Provider>
                        )}
                    </>
                </MapImageWrapper>
            ) : (
                <MapImageSkeletonWrapper>
                    <Skeleton width={'calc(100% - 800px)'} height={'calc(100% - 50px)'} />
                </MapImageSkeletonWrapper>
            ))
        );
    };

    useEffect(() => {
        const container = containerRef.current;
        const handleSelectStart = (event) => {
            event.preventDefault(); // Prevent text selection
        };

        const handleDragStart = (event) => {
            event.preventDefault(); // Prevent drag-and-drop
        };

        container.addEventListener('selectstart', handleSelectStart);
        container.addEventListener('dragstart', handleDragStart);

        return () => {
            container.removeEventListener('selectstart', handleSelectStart);
            container.removeEventListener('dragstart', handleDragStart);
        };
    }, []);
    return (
        <RootRef rootRef={containerRef}>
            <MapViewerContainer
                showGrid={showGrid}
                id={getMapViewerContainerId()}
                {...otherProps}
                onMouseDown={handleMouseDown}
            >
                {renderMapImage()}
            </MapViewerContainer>
        </RootRef>
    );
}

MapViewer.propTypes = {
    mapId: PropTypes.string,
    buildingId: PropTypes.string,
    floorId: PropTypes.string,
    base64: PropTypes.string,
};

// MapViewer.whyDidYouRender = true;
