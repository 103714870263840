import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './entranceEntityAdapter';
import {
    createEntrance,
    deleteEntrance,
    fetchAllEntrancesInBuilding,
    updateEntrance,
} from './entranceActions';
import { expireSession, logout } from '../auth/authActions';

const { getInitialState, upsertMany, upsertOne, addOne, removeOne } = entityAdapter;

export const { reducer } = createSlice({
    name: 'entrances',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchAllEntrancesInBuilding.fulfilled]: (state, action) => {
            upsertMany(
                state,
                action.payload.entrances.map((e) => ({
                    entranceId: e.entranceId,
                    description: e.description,
                    isMain: e.isMain,
                    coordinates: e.coordinates,
                    spaceId: action.payload.spaceId,
                }))
            );
        },

        [createEntrance.fulfilled]: (state, action) => {
            const { entranceId, description, isMain, coordinates } = action.payload;
            addOne(state, { entranceId, description, isMain, coordinates });
        },

        [updateEntrance.fulfilled]: (state, action) => {
            const { entranceId, description, isMain, coordinates } = action.payload;
            upsertOne(state, { entranceId, description, isMain, coordinates });
        },

        [deleteEntrance.fulfilled]: (state, action) => {
            const { entranceId } = action.meta.arg;
            removeOne(state, entranceId);
        },

        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
    },
});
