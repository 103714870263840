import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { MapViewerContext } from '../../MapViewerContext';
import { Portal, Snackbar as MuiSnackbar, SnackbarContent, Typography } from '@material-ui/core';
import { Button, TextField, CircularPreloader } from '../../../themed';
import { useDispatch, useSelector } from 'react-redux';
import { createAreaOfInterest } from '../../../../../state-management/mapping/area-of-interest/areaOfInterestActions';
import { createElement } from '../../../../../state-management/mapping/elements/elementsActions';
import {
    getAreaOfInterestCreationInstructionSnackbarId,
    getAreaOfInterestNewCreation,
    getMapViewerAreasOfInterestCreationOverlayId,
} from './MapViewerAreasOfInterestCreationOverlay.selectors';
import {
    IMAGE_NATURAL_HEIGHT,
    IMAGE_NATURAL_WIDTH,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../../state-management/notification/notificationReducer';
import { isFulfilled } from '../../../../../state-management/utils';
import { selectUserName } from '../../../../../state-management/auth/authSelectors';
import { upsertExitRegion } from '../../../../../state-management/mapping/exitRegion/exitRegionsActions';
import { selectBuildingsSelectedBuildingId, selectBuildingsSelectedFloorId } from '../../../../../state-management/user-inputs/buildingsSlice';

const CreationSpace = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
`;

const Rectangle = styled.rect.attrs((props) => ({
    // We use inline styles instead of CSS class to avoid styled-components from
    // generating a new class for every update, which is a major performance issue
    style: {
        width: props.$width,
        height: props.$height,
    },
}))`
    fill: ${(p) => (p.$color)}; // TODO color should come from theme
    fill-opacity: 0.3;
    stroke: ${(p) => (p.$color)};

    stroke-width: calc(1.5px / var(${SCALE}));
`;

const Snackbar = styled(MuiSnackbar)`
    position: absolute;
`;

const Instruction = styled(Typography)`
    color: #ffffff; //TODO color should come from theme
`;

const SnackbarTextField = styled(TextField)`
    &&& .themed-underline {
        ::before {
            border-bottom-color: white; // TODO color should come from theme
        }
        :hover::before {
            border-bottom-color: white; // TODO color should come from theme
        }
    }

    & .themed-input-label {
        color: white; // TODO color should come from theme
    }

    input {
        color: white;
    }
`;

const SnackbarButton = styled(Button)`
    color: white;
`;

const INITIAL = 'INITIAL';
const FIRST_CORNER_SET = 'FIRST_CORNER_SET';
const SECOND_CORNER_SET = 'SECOND_CORNER_SET';
const COMMENT_SET = 'COMMENT_SET';
const ADD_TITLE = 'ADD_TITLE';
const MOVE_TO_SUBMIT = 'MOVE_TO_SUBMIT';

const ACTIONS = {
    element: {
        create: ({ mapId }, areaData) => createElement({ mapId, areaData }),
        name: 'element',
        creationStep: ADD_TITLE,
        color: '#8A6748'
    },
    normal: {
        create: ({ mapId }, areaData) => createAreaOfInterest({ mapId, areaData }),
        name: 'area of interest',
        creationStep: SECOND_CORNER_SET,
        color: '#00CEF1'
    },
    exitRegion: {
        create: ({ buildingId, floorId, mapId }, createdExitRegion) => {
            const topRight = [createdExitRegion.topRight.x, createdExitRegion.topRight.y];
            const bottomLeft = [createdExitRegion.bottomLeft.x, createdExitRegion.bottomLeft.y];
            return upsertExitRegion({ buildingId, floorId, mapId, exitRegion: { ...createdExitRegion, shapeType: 'rectangular', topRight, bottomLeft } })
        },
        name: 'Exit region',
        creationStep: MOVE_TO_SUBMIT,
        title: 'Exit region',
        color: 'red'
    }
}

export default function MapViewerAreaOfInterestCreationOverlay(props) {
    const { mapId, onSubmitted, onCancel } = props;

    const type = props.type || 'normal';
    const action = ACTIONS[type];
    const mapperName = useSelector(selectUserName)

    const { imageRef, containerRef, getCSSVariable } = useContext(MapViewerContext);
    const buildingId = useSelector(selectBuildingsSelectedBuildingId);
    const floorId = useSelector(selectBuildingsSelectedFloorId);
    const dispatch = useDispatch();

    const [isCreating, setIsCreating] = useState(false);
    const [{ creationStep, firstCorner, secondCorner, comment, title }, setCreation] = useImmer({
        creationStep: INITIAL,
        firstCorner: null,
        secondCorner: null,
        comment: null,
        type,
        title: null,
    });

    const handleCommentChange = (event) => {
        const { value } = event.target;
        setCreation((state) => {
            state.comment = value?.trim() === '' ? null : value;
        });
    };
    const handleTitleChange = (event) => {
        const { value } = event.target;
        setCreation((state) => {
            state.title = value?.trim() === '' ? null : value;
        });
    };
    const handleReset = (event) => {
        event.stopPropagation();
        setCreation((state) => {
            state.creationStep = INITIAL;
            state.firstCorner = null;
            state.secondCorner = null;
            state.comment = null;
        });
    };

    const handleClick = (event) => {
        const { clientX, clientY } = event;
        const refRect = imageRef.current.getBoundingClientRect();
        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const scale = parseFloat(getCSSVariable(SCALE));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        if (creationStep === INITIAL) {
            setCreation((state) => {
                state.creationStep = FIRST_CORNER_SET;
                state.firstCorner = {
                    x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                    y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
                };
                state.secondCorner = null;
                state.comment = null;
            });
        } else if (creationStep === FIRST_CORNER_SET) {
            setCreation((state) => {
                state.secondCorner = {
                    x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                    y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
                };
                state.comment = null;

                state.creationStep = action.creationStep;
            });
        } else if (creationStep === SECOND_CORNER_SET) {
            setCreation((state) => {
                state.creationStep = ADD_TITLE;
            });
        }
    };

    const handlePointerMove = useCallback(
        (event) => {
            const { clientX, clientY } = event;

            const refRect = imageRef.current.getBoundingClientRect();
            const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
            const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
            const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

            setCreation((state) => {
                if (creationStep === INITIAL) {
                    state.firstCorner = {
                        x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                        y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
                    };
                } else if (creationStep === FIRST_CORNER_SET) {
                    state.secondCorner = {
                        x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                        y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
                    };
                }
            });
        },
        [creationStep, getCSSVariable, imageRef, setCreation]
    );

    const handleCancel = (event) => {
        event.stopPropagation();
        onCancel();
    };

    const handleSubmit = async () => {
        setIsCreating(true);
        const result = await dispatch(
            action.create({ buildingId, floorId, mapId }, { topRight, bottomLeft, comment, attachment: null, title: action.title || title, type, mapperName })
        );
        setIsCreating(false);

        const typeName = action.name;
        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`${typeName} created successfully`));
        } else {
            dispatch(showErrorNotification(`Failed to create ${typeName}`));
        }

        onSubmitted();
    };

    useEffect(() => {
        if (creationStep === MOVE_TO_SUBMIT) {
            setCreation((state) => {
                state.creationStep = INITIAL;
            })
            onCancel();
            handleSubmit()
        }
    }, [creationStep])

    useEffect(() => {
        if (creationStep === INITIAL || creationStep === FIRST_CORNER_SET) {
            window.addEventListener('pointermove', handlePointerMove);

            return () => {
                window.removeEventListener('pointermove', handlePointerMove);
            };
        }
    }, [creationStep, handlePointerMove]);

    const boundaries = useMemo(() => {
        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));
        const imageNaturalWidth = parseFloat(getCSSVariable(IMAGE_NATURAL_WIDTH));

        return {
            maxX: imageNaturalWidth / pixelToMeter + mapOffsetX,
            minX: mapOffsetX,
            maxY: imageNaturalHeight / pixelToMeter + mapOffsetY,
            minY: mapOffsetY,
        };
    }, [getCSSVariable]);

    const { x, y, width, height, bottomLeft, topRight } = useMemo(() => {
        if (!firstCorner || !secondCorner) {
            return { x: 0, y: 0, width: 0, height: 0, bottomLeft: null, topRight: null };
        }

        const { minX, minY, maxX, maxY } = boundaries;

        const bottomLeft = {
            x: Math.min(firstCorner?.x, secondCorner?.x),
            y: Math.min(firstCorner?.y, secondCorner?.y),
        };
        const topRight = {
            x: Math.max(firstCorner?.x, secondCorner?.x),
            y: Math.max(firstCorner?.y, secondCorner?.y),
        };

        // Limit the X and Y coordinates to the boundaries of the map image
        bottomLeft.x = Math.min(Math.max(bottomLeft.x, minX), maxX);
        bottomLeft.y = Math.min(Math.max(bottomLeft.y, minY), maxY);
        topRight.x = Math.min(Math.max(topRight.x, minX), maxX);
        topRight.y = Math.min(Math.max(topRight.y, minY), maxY);

        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        return {
            x: (bottomLeft.x - mapOffsetX) * pixelToMeter,
            y: (imageNaturalHeight / pixelToMeter - (topRight.y - mapOffsetY)) * pixelToMeter,
            width: (topRight.x - bottomLeft.x) * pixelToMeter,
            height: (topRight.y - bottomLeft.y) * pixelToMeter,
            bottomLeft,
            topRight,
        };
    }, [boundaries, firstCorner, getCSSVariable, secondCorner]);
    const typeName = action.name;
    const instructions = {
        [INITIAL]: (
            <Instruction>Click anywhere on the map to set the first corner of the {typeName}</Instruction>
        ),
        [FIRST_CORNER_SET]: (
            <Instruction>Click once more to set the second corner of the {typeName}</Instruction>
        ),
        [SECOND_CORNER_SET]: (
            <>
                <Instruction>Write a comment on this {typeName} for the mapper to see (optional)</Instruction>
                <SnackbarTextField onChange={handleCommentChange} value={comment || ''} />
            </>
        ),
        [ADD_TITLE]: (
            <>
                <Instruction>Write a title on this {typeName} for the mapper to see (optional)</Instruction>
                <SnackbarTextField onChange={handleTitleChange} value={title || ''} />
            </>
        ),
    };
    return (
        <CreationSpace id={getMapViewerAreasOfInterestCreationOverlayId(type)} onClick={handleClick}>
            <Portal container={containerRef.current}>
                <Snackbar
                    id={getAreaOfInterestCreationInstructionSnackbarId(type)}
                    open
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <SnackbarContent
                        message={instructions[creationStep]}
                        action={
                            <>
                                <SnackbarButton variant={'text'} onClick={handleCancel}>
                                    Cancel
                                </SnackbarButton>

                                {creationStep !== INITIAL && (
                                    <SnackbarButton variant={'text'} onClick={handleReset}>
                                        Reset
                                    </SnackbarButton>
                                )}

                                {creationStep === SECOND_CORNER_SET &&
                                    (isCreating ? (
                                        <CircularPreloader />
                                    ) : type === 'normal' ? (
                                        <SnackbarButton variant={'text'} onClick={handleSubmit}>
                                            {comment ? 'Submit' : 'Skip'}
                                        </SnackbarButton>
                                    ) : (
                                        <SnackbarButton variant={'text'} onClick={handleClick}>
                                            {comment ? 'Continue' : 'Skip'}
                                        </SnackbarButton>
                                    ))}
                                {creationStep === ADD_TITLE &&
                                    type === 'element' &&
                                    (isCreating ? (
                                        <CircularPreloader />
                                    ) : (
                                        <SnackbarButton variant={'text'} onClick={handleSubmit}>
                                            {title ? 'Submit' : 'Skip'}
                                        </SnackbarButton>
                                    ))}
                            </>
                        }
                    />
                </Snackbar>
            </Portal>

            {firstCorner && secondCorner && (
                <Rectangle
                    id={getAreaOfInterestNewCreation(type)}
                    x={x}
                    y={y}
                    $width={width}
                    $height={height}
                    $color={action.color}
                    type={type}
                />
            )}
        </CreationSpace>
    );
}

MapViewerAreaOfInterestCreationOverlay.propTypes = {
    mapId: PropTypes.string.isRequired,
    onSubmitted: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['normal', 'element', 'exitRegion']),
};
