import { createSelector } from 'reselect';

export const getAnalyticsFilters = (state) => state.userInputs.analytics.userActivity;
export const getAnalyticsFetchOnSubmit = (state) => state.analytics.sessionAnalytics.fetchOnSubmit;

export const getSessionAggregationsRawData = (state) =>
    state.analytics.sessionAnalytics.sessionAggregations.rawData;

export const getAllPositionsObject = (state) => state.analytics.sessionAnalytics.positions.byId;

export const getAllSessions = (state) =>
    state.analytics.sessionAnalytics.sessions.allIds.map(
        (id) => state.analytics.sessionAnalytics.sessions.byId[id]
    );

export const getAllSessionsObject = (state) => state.analytics.sessionAnalytics.sessions.byId;

export const getAllSessionsWithPositions = createSelector(
    getAllSessions,
    getAllPositionsObject,
    (sessions, positions) =>
        sessions.map(({ positionIds, ...session }) => ({
            ...session,
            positionIds,
            positions: positionIds.map((id) => positions[id]),
        }))
);

export const getSessionWithPositions = (state, sessionId) => {
    const session = state.analytics.sessionAnalytics.sessions.byId[sessionId];

    return {
        ...session,
        positions: session.positionIds.map((id) => state.analytics.sessionAnalytics.positions.byId[id]),
    };
};

export const getSession = (state, sessionId) => state.analytics.sessionAnalytics.sessions.byId[sessionId];

export const getSessionAggregations = (state) =>
    state.analytics.sessionAnalytics.sessionAggregations.allIds.map(
        (id) => state.analytics.sessionAnalytics.sessionAggregations.byId[id]
    );

export const getSessionsAggregationsWithSessionsObject = createSelector(
    getSessionAggregations,
    getAllSessionsObject,
    (aggregated, processed) =>
        aggregated.reduce(
            (result, { sessionIds, aggregationId, ...aggProps }) => ({
                ...result,
                [aggregationId]: {
                    ...aggProps,
                    aggregationId,
                    sessions: sessionIds.map((id) => processed[id]),
                },
            }),
            {}
        )
);

export const getSessionsAggregationType = (state) =>
    state.analytics.sessionAnalytics.sessionAggregations.aggregationType;

export const getSelectedSession = (state) => state.analytics.sessionAnalytics.sessions.selectedSession;

export const getSelectedSessionWithPositions = createSelector(
    getSelectedSession,
    getAllPositionsObject,
    (session, positions) => {
        if (!session) {
            return null;
        }

        return {
            ...session,
            positions: session.positionIds.map((id) => positions[id]),
        };
    }
);
