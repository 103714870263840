export const getCreateFloorDialogId = () => `create-floor-dialog`;
export const getCreateFloorFormErrorId = () => `create-floor-form-error`;

export const getFloorNameId = (floorId) => `create-building-floor-${floorId}-name`;
export const getFloorShortNameId = (floorId) => `create-building-floor-${floorId}-short-name`;
export const getFloorIndexId = (floorId) => `create-building-floor-${floorId}-index`;
export const getFloorHeightId = (floorId) => `create-building-floor-${floorId}-height`;
export const getFloorRotationId = (floorId) => `create-building-floor-${floorId}-rotation`;
export const getMarkFloorAsPrimaryId = (floorId) => `create-floor-${floorId}-mark-as-primary`;

export const getFloorToggleId = (floorId) => `create-floor-floor-toggle-${floorId}`;

export const getMapNameId = (mapIndex) => `create-floor-map-${mapIndex}-name`;
export const getMapUsageId = (mapIndex) => `create-floor-map-${mapIndex}-usage`;
export const getPixelToMeterId = (mapIndex) => `create-floor-map-${mapIndex}-p2m`;
export const getChangePixelToMeterId = (mapIndex) => `create-floor-map-${mapIndex}-change-p2m`;
export const getOffsetXId = (mapIndex) => `create-floor-map-${mapIndex}-offset-x`;
export const getOffsetYId = (mapIndex) => `create-floor-map-${mapIndex}-offset-y`;
export const getChangeOffsetId = (mapIndex) => `create-floor-map-${mapIndex}-change-offset`;

export const getOffsetDialogAcceptId = () => `create-floor-offset-dialog-accept`;
