import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAllBuildingsInAllSpaces,
    selectIsAllBuildingsInAllSpacesFetched,
} from '../../../../state-management/space/spaceSelectors';
import useSpaces from './useSpaces';
import { fetchAllBuildingsInAllSpaces } from '../../../../state-management/building/buildingActions';
import {
    selectHasError as getSelectHasError,
    selectIsLoading as getSelectIsLoading,
} from '../../../../state-management/status/statusSelectors';

/**
 * Hook that fetches all buildings in all spaces of the user API key to the Redux store.
 * @param options
 * @return {{isLoading: *, data: (*), hasError: *}}
 */
export default function useAllBuildingsInAllSpaces(options = {}) {
    const { asObject = false } = options;

    const allSpaces = useSpaces();

    const dispatch = useDispatch();

    const selectIsLoading = useMemo(() => getSelectIsLoading(fetchAllBuildingsInAllSpaces.typePrefix), []);
    const selectHasError = useMemo(() => getSelectHasError(fetchAllBuildingsInAllSpaces.typePrefix), []);

    const { asArray: buildingsArray, asObject: buildingObject } = useSelector(selectAllBuildingsInAllSpaces);
    const isFetched = useSelector(selectIsAllBuildingsInAllSpacesFetched);
    const isFetching = useSelector(selectIsLoading) || allSpaces.isLoading;
    const hasError = useSelector(selectHasError) || allSpaces.hasError;

    useEffect(() => {
        // If some of the space don't have their buildings fetched yet, fetch them
        if (!isFetched && !isFetching && !hasError) {
            dispatch(fetchAllBuildingsInAllSpaces());
        }
    }, [dispatch, isFetching, hasError, isFetched]);

    return { data: asObject ? buildingObject : buildingsArray, isLoading: isFetching, hasError };
}
