import React from 'react';
import SideMenuSection from '../../../common/side-drawer/SideMenuSection';
import FetchError from '../../../common/error-pages/FetchError';
import { Skeleton, SwitchLabel as Label, Switch, TextField } from '../../../common/themed';
import NoItemsMessage from '../../../common/side-drawer/NoItemsMessage';
import { FormControlLabel } from '@material-ui/core';
import { setIsCoordinatesConverstionOpen } from '../../../../state-management/user-inputs/buildingsSlice';
import {
    selectMappingSelectedBuildingId,
    selectMappingSelectedFloorId,
    selectMappingSelectedMapId,
} from '../../../../state-management/user-inputs/mappingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useCoordinatesConversion } from '../../../common/hooks/data-fetching/useCoordinatesConversions';
import styled from 'styled-components';
import { deleteCoordinatesConversion } from '../../../../state-management/coordinates-conversion/coordinatesConversionActions';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    & > *:not(last-child) {
        margin-bottom: 10px;
    }
`;
export function BuildingsSideMenuCoordinatesConversion() {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const buildingId = useSelector(selectMappingSelectedBuildingId);
    const floorId = useSelector(selectMappingSelectedFloorId);
    const { translation, rotation, scale, isLoading, hasError } = useCoordinatesConversion();

    if (!selectedMapId && !isLoading) {
        return null;
    }

    const onDelete = async () => {
        const result = await dispatch(
            deleteCoordinatesConversion({ buildingId, floorId, mapId: selectedMapId })
        );

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Coordinates conversion deleted.'));
        } else {
            dispatch(showErrorNotification('something went wrong'));
        }
        dispatch(setIsCoordinatesConverstionOpen(false));
    };
    return (
        <>
            <SideMenuSection
                title={'Coordinates'}
                showAdd
                showDelete={translation || rotation || scale}
                onAdd={() => dispatch(setIsCoordinatesConverstionOpen(true))}
                onDelete={onDelete}
            >
                {hasError ? (
                    <FetchError />
                ) : isLoading ? (
                    <Skeleton height={50} width={230} />
                ) : translation || rotation || scale ? (
                    <Container>
                        <TextField
                            disabled
                            value={`${scale.x?.toFixed(2)},${scale.y?.toFixed(2)}`}
                            label="Scale (x,y)"
                        />
                        <TextField disabled value={rotation?.toFixed(2)} label="Rotation" />
                        <TextField
                            disabled
                            value={`${translation.x?.toFixed(2)},${translation.y?.toFixed(2)}`}
                            label="Translation"
                        />
                    </Container>
                ) : (
                    <NoItemsMessage>There are no coordinates in this map</NoItemsMessage>
                )}
            </SideMenuSection>
        </>
    );
}
