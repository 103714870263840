import React, { useContext, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { MapViewerContext } from '../../MapViewerContext';
import { Portal } from '@material-ui/core';
import { CircularPreloader } from '../../../themed';
import { useDispatch } from 'react-redux';
import {
    getEntranceCreationInstructionSnackbarId,
    getEntranceNewCreationId,
    getMapViewerEntranceCreationOverlayId,
} from './MapViewerEntranceCreationOverlay.selectors';
import {
    IMAGE_NATURAL_HEIGHT,
    IS_PANNING,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import {
    InstructionSnackbar,
    InstructionSnackbarTextField,
    InstructionSnackbarButton,
    InstructionText,
} from '../../common/InstructionSnackbar';
import { isFulfilled } from '../../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../../state-management/notification/notificationReducer';
import { createEntrance } from '../../../../../state-management/entrance/entranceActions';
import { GiEntryDoor } from 'react-icons/gi';

const CreationSpace = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
`;

const EntranceIcon = styled(GiEntryDoor)`
    fill: #ff6200; // TODO color should come from theme
    stroke: #ffffff;
    stroke-width: 10px;
    overflow: visible;

    & > path {
        transform: translate(-50%, -50%) scale(calc(3 / var(${SCALE})));
        transform-origin: center;
    }
`;

const INITIAL = 'INITIAL';
const COORDINATES_SET = 'COORDINATES_SET';

export default function MapViewerEntranceCreationOverlay(props) {
    const { buildingId, floorId, isMain = false, onSubmitted, onCancel } = props;

    const { imageRef, containerRef, getCSSVariable } = useContext(MapViewerContext);

    const dispatch = useDispatch();

    const [isCreating, setIsCreating] = useState(false);
    const [{ creationStep, coordinates, description }, setCreation] = useImmer({
        creationStep: INITIAL,
        coordinates: null,
        description: null,
        isMain,
    });

    const handleSubmit = async () => {
        setIsCreating(true);
        const result = await dispatch(
            createEntrance({ buildingId, floorId, entranceData: { coordinates, description, isMain } })
        );
        setIsCreating(false);

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Entrance created successfully'));
        } else {
            dispatch(showErrorNotification(`Failed to create entrance`));
        }

        onSubmitted();
    };

    const handleDescriptionChange = (event) => {
        const { value } = event.target;
        setCreation((state) => {
            state.description = value?.trim() === '' ? null : value;
        });
    };

    const handleReset = (event) => {
        event.stopPropagation();
        setCreation((state) => {
            state.creationStep = INITIAL;
            state.coordinates = null;
            state.description = null;
        });
    };

    const handleCreationSpacePointerUp = (event) => {
        const isPanning = getCSSVariable(IS_PANNING) === 'true';

        // Ignore clicks coming from panning the map
        if (!isPanning) {
            const { clientX, clientY } = event;

            if (creationStep === INITIAL) {
                const refRect = imageRef.current.getBoundingClientRect();
                const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
                const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
                const scale = parseFloat(getCSSVariable(SCALE));
                const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
                const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

                setCreation((state) => {
                    state.creationStep = COORDINATES_SET;
                    state.coordinates = {
                        x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                        y:
                            (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter -
                            Math.abs(mapOffsetY),
                    };
                    state.description = null;
                });
            }
        }
    };

    const handleCancel = (event) => {
        event.stopPropagation();
        onCancel();
    };

    const instructions = {
        [INITIAL]: (
            <InstructionText>Click anywhere on the map to set coordinates for the entrance</InstructionText>
        ),
        [COORDINATES_SET]: (
            <>
                <InstructionText>Provide a description for this entrance:</InstructionText>
                <InstructionSnackbarTextField onChange={handleDescriptionChange} />
            </>
        ),
    };

    const actions = {
        [INITIAL]: null,
        [COORDINATES_SET]: (
            <>
                <InstructionSnackbarButton variant={'text'} onClick={handleReset}>
                    Reset
                </InstructionSnackbarButton>

                {isCreating ? (
                    <CircularPreloader />
                ) : (
                    <InstructionSnackbarButton
                        variant={'text'}
                        onClick={handleSubmit}
                        disabled={!description}
                    >
                        Submit
                    </InstructionSnackbarButton>
                )}
            </>
        ),
    };

    const { x, y } = useMemo(() => {
        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        return {
            x: (coordinates?.x - mapOffsetX) * pixelToMeter,
            y: (imageNaturalHeight / pixelToMeter - (coordinates?.y + mapOffsetY)) * pixelToMeter,
        };
    }, [coordinates, getCSSVariable]);

    return (
        <CreationSpace
            id={getMapViewerEntranceCreationOverlayId()}
            onPointerUp={handleCreationSpacePointerUp}
        >
            <Portal container={containerRef.current}>
                <InstructionSnackbar
                    id={getEntranceCreationInstructionSnackbarId()}
                    message={instructions[creationStep]}
                    action={
                        <>
                            <InstructionSnackbarButton variant={'text'} onClick={handleCancel}>
                                Cancel
                            </InstructionSnackbarButton>

                            {actions[creationStep]}
                        </>
                    }
                />
            </Portal>

            {coordinates && <EntranceIcon id={getEntranceNewCreationId()} x={x} y={y} />}
        </CreationSpace>
    );
}

MapViewerEntranceCreationOverlay.propTypes = {
    buildingId: PropTypes.string.isRequired,
    floorId: PropTypes.string.isRequired,
    isMain: PropTypes.bool.isRequired,
    onSubmitted: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
