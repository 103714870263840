import TimeResolution from './TimeResolution';
import moment from 'moment';

class OneDay extends TimeResolution {
    get inMinutes() {
        return 60 * 24;
    }

    get label() {
        return 'Day';
    }

    getTimeIntervalString(start, end) {
        return `${moment(start).format('MMM D')}`;
    }

    get unitType() {
        return 'day';
    }

    get unitCount() {
        return 1;
    }

    getFittingTimeInterval(utcTimestamp) {
        const start = moment(utcTimestamp).startOf('day');
        const end = moment(utcTimestamp).endOf('day');

        // if (this.startHourUtc) {
        //     start.hour(this.startHourUtc);
        // }
        //
        // if (this.endHourUtc) {
        //     end.hour(this.endHourUtc - 1);
        // }

        return {
            start: start.toISOString(),
            end: end.toISOString(),
        };
    }

    getTimeIntervalsInRange(start, end, options) {
        return super.getTimeIntervalsInRange(start, end);
    }
}

export default new OneDay();
