import entityAdapter from './mapEntityAdapter';
import { createSelector } from '@reduxjs/toolkit';
import { selectBuildingById, selectBuildingEntities } from '../building/buildingSelectors';
import { selectFloorEntities } from '../floor/floorSelectors';
import { selectAllRegions } from '../region/regionSelectors';
import { selectMapValidationEntities } from '../mapping/map-validation/mapValidationSelectors';
import { selectLaneEntities } from '../mapping/lane/laneSelectors';
import { selectAreaOfInterestEntities } from '../mapping/area-of-interest/areaOfInterestSelectors';
import { selectJunctionMismatchEntities } from '../mapping/junction-mismatch/junctionMismatchSelectors';
import { selectMapperEntities } from '../mapping/mapper/mapperSelectors';
import { selectRegionPlacementEntities } from '../region-placement/regionPlacementSelectors';
import { selectLineOfInterestEntities } from '../mapping/line-of-interest/lineOfInterestSelectors';
import { selectElementEntities } from '../mapping/elements/elementsSelectors';
import { selectExitRegionEntities } from '../mapping/exitRegion/exitRegionSelectors';

export const {
    selectById: selectMapById,
    selectIds: selectMapIds,
    selectEntities: selectMapEntities,
    selectAll: selectAllMaps,
    selectTotal: selectTotalMaps,
} = entityAdapter.getSelectors((state) => state.maps);

export const getSelectDefaultReferenceMap = (buildingId) =>
    createSelector(
        selectBuildingEntities,
        selectFloorEntities,
        selectMapEntities,
        (buildings, floors, maps) => {
            if (!buildings?.[buildingId]?.primaryFloorId) {
                return null;
            }

            const primaryFloor = floors?.[buildings?.[buildingId]?.primaryFloorId];
            return maps?.[primaryFloor?.mapIds?.[0]];
        }
    );

export const getSelectDefaultReferenceMapId = (buildingId) =>
    createSelector(
        selectBuildingEntities,
        selectFloorEntities,
        selectMapEntities,
        (buildings, floors, maps) => {
            if (!buildings?.[buildingId]?.primaryFloorId) {
                return null;
            }

            const primaryFloor = floors?.[buildings?.[buildingId]?.primaryFloorId];
            return primaryFloor?.mapIds?.[0];
        }
    );

export const getSelectAllFloorsInBuilding = (buildingId) =>
    createSelector(
        (state) => selectBuildingById(state, buildingId),
        selectFloorEntities,
        (building, floors) => {
            return building?.floorIds?.map((id) => floors?.[id]);
        }
    );

export const getSelectAllMapIdsInBuilding = (buildingId) =>
    createSelector(
        (state) => selectBuildingById(state, buildingId),
        selectFloorEntities,
        selectMapEntities,
        (building, floors, maps) => {
            const buildingFloors = building?.floorIds?.map((id) => floors?.[id]);
            return buildingFloors?.reduce((result, { mapIds }) => [...result, ...mapIds], []);
        }
    );

export const getSelectAllRegionsWithInternalPlacementsInMap = (mapId) =>
    createSelector(
        (state) => selectMapById(state, mapId),
        selectAllRegions,
        selectRegionPlacementEntities,
        (map, regions, placements) => {
            const placementIdToRegion = regions
                ?.filter(({ placementIds }) => placementIds?.length > 0)
                ?.reduce(
                    (
                        result,
                        {
                            regionId,
                            regionName,
                            regionUsePlai,
                            regionUseTriggers,
                            regionUseMapPresentation,
                            regionUseAnalytics,
                            regionMetadata,
                            placementIds = [],
                        }
                    ) => ({
                        ...result,
                        ...placementIds.reduce(
                            (res, placementId) => ({
                                ...res,
                                [placementId]: {
                                    regionId,
                                    regionName,
                                    regionMetadata,
                                    regionUsePlai,
                                    regionUseTriggers,
                                    regionUseMapPresentation,
                                    regionUseAnalytics,
                                },
                            }),
                            {}
                        ),
                    }),
                    {}
                );

            const placementsWithRegionData = map?.regionPlacementIds
                ?.filter((placementId) => !!placementIdToRegion?.[placementId] && !!placements?.[placementId])
                ?.map((placementId) => ({
                    ...placementIdToRegion?.[placementId],
                    ...placements[placementId],
                    regionType: placements[placementId].regionType,
                }));

            return {
                asArray: placementsWithRegionData,
                asObject: placementsWithRegionData?.reduce(
                    (result, r) => ({ ...result, [r.placementId]: r }),
                    {}
                ),
            };
        }
    );

export const getSelectLanesInMap = (mapId) =>
    createSelector(
        (state) => selectMapById(state, mapId),
        selectLaneEntities,
        (map, lanes = {}) => ({
            asArray: map?.laneIds?.map((id) => lanes?.[id]).filter((lane) => lane),
            asObject: map?.laneIds?.reduce((res, id) => ({ ...res, [id]: lanes?.[id] }), {}),
        })
    );

export const getSelectMappersInMap = (mapId) =>
    createSelector(
        (state) => selectMapById(state, mapId),
        selectMapperEntities,
        (map, mappers = {}) => ({
            asArray: map?.mapperIds?.map((id) => mappers?.[id]),
            asObject: map?.mapperIds?.reduce((res, id) => ({ ...res, [id]: mappers?.[id] }), {}),
        })
    );

export const getSelectValidationsInMap = (mapId) =>
    createSelector(
        (state) => selectMapById(state, mapId),
        selectMapValidationEntities,
        (map, mapValidations = {}) => ({
            asArray: map?.mapValidationIds?.map((id) => mapValidations?.[id]),
            asObject: map?.mapValidationIds?.reduce(
                (res, id) => ({ ...res, [id]: mapValidations?.[id] }),
                {}
            ),
        })
    );

export const getSelectAreasOfInterestInMap = (mapId) =>
    createSelector(
        (state) => selectMapById(state, mapId),
        selectAreaOfInterestEntities,
        (map, areasOfInterest = {}) => ({
            asArray: map?.areaOfInterestIds?.map((id) => areasOfInterest?.[id]),
            asObject: map?.areaOfInterestIds?.reduce(
                (res, id) => ({ ...res, [id]: areasOfInterest?.[id] }),
                {}
            ),
        })
    );
export const getSelectElementsInMap = (mapId) =>
    createSelector(
        (state) => selectMapById(state, mapId),
        selectElementEntities,
        (map, elements = {}) => ({
            asArray: map?.elementsIds?.map((id) => elements?.[id]),
            asObject: map?.elementsIds?.reduce((res, id) => ({ ...res, [id]: elements?.[id] }), {}),
        })
    );

export const getSelectExitRegionsInMap = (mapId) =>
    createSelector(
        (state) => selectMapById(state, mapId),
        selectExitRegionEntities,
        (map, exitRegions = {}) => {
            const result = {
                asArray: map?.exitRegionsIds?.map((id) => exitRegions?.[id]),
            };
            return result;
        }
    );

export const getSelectLinesOfInterestInMap = (mapId) =>
    createSelector(
        (state) => selectMapById(state, mapId),
        selectLineOfInterestEntities,
        (map, linesOfInterest = {}) => ({
            asArray: map?.lineOfInterestIds?.map((id) => linesOfInterest?.[id]),
            asObject: map?.lineOfInterestIds?.reduce(
                (res, id) => ({ ...res, [id]: linesOfInterest?.[id] }),
                {}
            ),
        })
    );

export const getSelectJunctionMismatchesInMap = (mapId, equalized = true) =>
    createSelector(
        (state) => selectMapById(state, mapId),
        selectJunctionMismatchEntities,
        (map, junctionMismatches = {}) => {
            const jmIdsKey = equalized ? 'equalizedJunctionMismatchIds' : 'unequalizedJunctionMismatchIds';
            return map?.[jmIdsKey]
                ?.map((id) => junctionMismatches?.[id])
                ?.filter((jm) => jm && jm.isEqualized === equalized);
        }
    );
