import { assertError, errorAction, requestAction, successAction } from '../../actionUtils';
import AnalyticsManager from '../../../api/AnalyticsManager';

export const FETCH_REGION_OCCUPANCY_DATA_REQUEST = 'FETCH_REGION_OCCUPANCY_DATA_REQUEST';
export const FETCH_REGION_OCCUPANCY_DATA_SUCCESS = 'FETCH_REGION_OCCUPANCY_DATA_SUCCESS';
export const FETCH_REGION_OCCUPANCY_DATA_ERROR = 'FETCH_REGION_OCCUPANCY_DATA_ERROR';

export const fetchOccupancyData = (
    buildingId,
    floorId,
    timeResolutions,
    startTimestamp,
    endTimestamp,
    timeZoneOffset,
    workingHoursOnly,
    spaceId
) => async (dispatch) => {
    dispatch(requestAction(FETCH_REGION_OCCUPANCY_DATA_REQUEST));

    try {
        const response = await AnalyticsManager.getOccupancyData(
            buildingId,
            floorId,
            timeResolutions,
            startTimestamp,
            endTimestamp,
            timeZoneOffset,
            workingHoursOnly,
            spaceId
        );
        assertError(response, `Failed to fetch occupancy data`);

        dispatch(
            successAction(FETCH_REGION_OCCUPANCY_DATA_SUCCESS, {
                data: response.data,
                startTimestamp,
                endTimestamp,
                timeZoneOffset,
                workingHoursOnly,
                submittedFilters: {
                    selectedBuildingId: buildingId,
                    selectedFloorId: floorId,
                    startTimestamp,
                    endTimestamp,
                    timeZoneOffset,
                    workingHoursOnly,
                },
            })
        );
    } catch (e) {
        dispatch(errorAction(FETCH_REGION_OCCUPANCY_DATA_ERROR, e.message));
    }
};
