import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { BsXLg } from "react-icons/bs";
import { Circle, OverlayView } from '@react-google-maps/api';
import { Button, TextField, Toggle, ToggleGroup } from '../../../common/themed';
import {
    getBuildingOriginGMapsMarkerId,
    getChangeBuildingOriginButtonId,
    getChangeGeofenceOriginButtonId,
    getCreateBuildingStepThreeFormId,
    getCreateBuildingStepThreeGMapsId,
    getFloorToggleId,
    getGeofenceOriginGMapsMarkerId,
    getGeofenceRadiusGMapsCircleId,
    getMapThumbnailId,
    getSetBuildingOriginButtonId,
    getGeofenceRadiusFieldId,
    getSetGeofenceOriginButtonId,
    getCopyBuildingOriginToGeofenceOriginButtonId,
    getBuildingOriginLatitudeId,
    getBuildingOriginLongitudeId,
    getGeofenceOriginLatitudeId,
    getGeofenceOriginLongitudeId,
} from './CreateBuildingDialog.selectors';
import {
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    InputAdornment,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import MuiSlider from '@material-ui/core/Slider';
import { useImmer } from 'use-immer';
import GoogleMapsBuildingPlacement from '../common/google-maps-building-placement';
import Thumbnail from '../common/Thumbnail';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import { IoLocationSharp } from 'react-icons/io5';
import { getBuildingRotationId } from '../change-building-placement/ChangeBuildingPlacement.selectors';

const markerCommonStyles = css`
    transform: translateY(-100%) translateX(-50%);
    height: 2rem;
    width: 2rem;
`;

const BuildingOriginMarker = styled(IoLocationSharp)`
    fill: dodgerblue;
    ${markerCommonStyles};
`;

const GeofenceOriginMarker = styled(IoLocationSharp)`
    fill: darkred;
    ${markerCommonStyles};
`;

const ThumbnailsWrapper = styled.div`
    display: flex;
    overflow-y: auto;
    max-height: 35rem;
    margin-block-start: 15px;

    ${Thumbnail}:not(:first-child) {
        margin-inline-start: 20px;
    }
`;

const BuildingDetailsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 40px 60px;
    grid-template-areas:
        'opacity building-origin geofence-origin'
        'rotation building-origin geofence-origin';
    column-gap: 40px;
    margin-block-start: 20px;
`;

const OpacityWrapper = styled.div`
    grid-area: opacity;
`;

const RotationWrapper = styled.div`
    grid-area: rotation;
`;

const BuildingOriginWrapper = styled.div`
    grid-area: building-origin;
`;

const GeofenceOriginWrapper = styled.div`
    grid-area: geofence-origin;
`;

const ChangeButton = styled(Button)`
    margin-inline-start: 20px;
`;
const OutlinedButton = styled(Button)`
    padding: 5px 8px;
`;
const Slider = styled(MuiSlider)`
    margin-block-start: 10px;
    padding: 0px !important;
`;

const FlexRow = styled.div`
display: flex;
align-items: ${({ alignItems }) => alignItems || 'flex-start'};
column-gap: ${({ columnGap }) => `${columnGap}px` || 0};
`

export default function CreateBuildingStepThree() {
    const { errors, register, setValue, watch, setError, clearErrors } = useFormContext();

    const {
        floors,
        primaryFloorArrayIndex,
        buildingOrigin,
        geofenceOrigin,
        geofenceRadius,
        buildingToEnuRotation,
    } = watch([
        'floors',
        'primaryFloorArrayIndex',
        'buildingOrigin',
        'geofenceOrigin',
        'geofenceRadius',
        'buildingToEnuRotation',
    ]);

    const [{ selectedFloorArrayIndex, selectedMapArrayIndex, isSelectMapCardExpanded }, setStepState] =
        useImmer({
            selectedFloorArrayIndex: primaryFloorArrayIndex ?? 0,
            selectedMapArrayIndex: 0,
            isSelectMapCardExpanded: !floors?.[primaryFloorArrayIndex ?? 0]?.maps?.[0],
        });

    const [isSettingBuildingOrigin, setIsSettingBuildingOrigin] = useState(false);
    const [isSettingGeofenceOrigin, setIsSettingGeofenceOrigin] = useState(false);

    const isBuildingOriginSet =
        buildingOrigin && (buildingOrigin?.latitude !== 0 || buildingOrigin?.longitude !== 0);
    const isGeofenceOriginSet =
        geofenceOrigin && (geofenceOrigin?.latitude !== 0 || geofenceOrigin?.longitude !== 0);
    const isSettingLocation = isSettingBuildingOrigin || isSettingGeofenceOrigin;
    const selectedFloor = floors?.[selectedFloorArrayIndex];
    const selectedMap = selectedFloor?.maps?.[selectedMapArrayIndex];
    const [rotation, setRotation] = useState(0);


    const [opacity, setOpacity] = useState(0.5);

    const handleLocationChange = ({ lat, lng }) => {
        if (isSettingBuildingOrigin) {
            setValue('buildingOrigin.latitude', lat);
            setValue('buildingOrigin.longitude', lng);
            setIsSettingBuildingOrigin(false);
        } else if (isSettingGeofenceOrigin) {
            setValue('geofenceOrigin.latitude', lat);
            setValue('geofenceOrigin.longitude', lng);
            setIsSettingGeofenceOrigin(false);
        }
    };

    const copyBuildingOriginToGeofenceOrigin = () => {
        setValue('geofenceOrigin.latitude', buildingOrigin?.latitude);
        setValue('geofenceOrigin.longitude', buildingOrigin?.longitude);
    };

    useEffect(() => {
        const selectMapMessage = `Please select a map`;
        const setBuildingOriginMessage = `Please set the building's origin`;
        const setGeofenceOriginMessage = `Please set the building's geofence origin`;
        if (isSelectMapCardExpanded && errors?.custom?.message !== selectMapMessage) {
            setError('custom', { message: selectMapMessage });
        } else if (!isBuildingOriginSet) {
            setError('custom', { message: setBuildingOriginMessage });
        } else if (!isGeofenceOriginSet) {
            setError('custom', { message: setGeofenceOriginMessage });
        } else if (errors?.custom) {
            clearErrors('custom');
        }
    }, [clearErrors, setError, errors, isSelectMapCardExpanded, isBuildingOriginSet, isGeofenceOriginSet]);

    const handleRotationChanged = (floatValue) => {
        const minValue = 0;
        const maxValue = 360;
        const clampedValue = Math.min(Math.max(floatValue, minValue), maxValue);
        setRotation(clampedValue)
    }

    return (
        <>
            <input
                hidden
                name={'buildingOrigin.latitude'}
                ref={register({ valueAsNumber: true })}
                defaultValue={0}
            />
            <input
                hidden
                name={'buildingOrigin.longitude'}
                ref={register({ valueAsNumber: true })}
                defaultValue={0}
            />
            <input
                hidden
                name={'geofenceOrigin.latitude'}
                ref={register({ valueAsNumber: true })}
                defaultValue={0}
            />
            <input
                hidden
                name={'geofenceOrigin.longitude'}
                ref={register({ valueAsNumber: true })}
                defaultValue={0}
            />
            <input
                hidden
                name={'geofenceRadius'}
                ref={register({ valueAsNumber: true })}
                defaultValue={100}
            />
            <input
                hidden
                name={'buildingToEnuRotation'}
                ref={register({ valueAsNumber: true })}
                defaultValue={0}
            />

            <div id={getCreateBuildingStepThreeFormId()}>
                <Accordion expanded={isSelectMapCardExpanded}>
                    <AccordionSummary>
                        <Grid container justifyContent={'space-between'}>
                            <Grid item>
                                <Typography variant={'h6'}>
                                    {isSelectMapCardExpanded
                                        ? `Select a map`
                                        : `Selected floor: ${selectedFloor?.floorName}, selected map: ${selectedMap?.mapName}`}
                                </Typography>
                            </Grid>

                            {!isSelectMapCardExpanded && (
                                <Grid item>
                                    <Button
                                        variant={'outlined'}
                                        onClick={() =>
                                            setStepState((state) => {
                                                state.isSelectMapCardExpanded = true;
                                            })
                                        }
                                    >
                                        Change map
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Grid container direction={'column'}>
                            <ToggleGroup>
                                {floors?.map(({ floorName, floorIndex }, index) => (
                                    <Toggle
                                        key={floorIndex}
                                        id={getFloorToggleId(floorIndex)}
                                        onClick={() =>
                                            setStepState((state) => {
                                                state.selectedFloorArrayIndex = index;
                                                state.selectedMapArrayIndex = 0;
                                            })
                                        }
                                        isSelected={index === selectedFloorArrayIndex}
                                    >
                                        {floorName}
                                    </Toggle>
                                ))}
                            </ToggleGroup>

                            <ThumbnailsWrapper>
                                {selectedFloor?.maps
                                    ?.filter((m) => m.floorArrayIndex === selectedFloorArrayIndex)
                                    ?.map(({ imgBase64 }, index) => (
                                        <Thumbnail
                                            key={`${selectedFloorArrayIndex}-${index}-map`}
                                            id={getMapThumbnailId(selectedFloor?.floorIndex, index)}
                                            className={clsx({ selected: index === selectedMapArrayIndex })}
                                            onClick={() =>
                                                setStepState((state) => {
                                                    state.selectedMapArrayIndex = index;
                                                    state.isSelectMapCardExpanded = false;
                                                })
                                            }
                                        >
                                            <img
                                                alt={'thumbnail'}
                                                src={`data:image/jpeg;base64,${imgBase64}`}
                                            />
                                        </Thumbnail>
                                    ))}
                            </ThumbnailsWrapper>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <GoogleMapsBuildingPlacement
                    id={getCreateBuildingStepThreeGMapsId()}
                    mapUrl={`data:image/jpeg;base64,${selectedMap?.imgBase64}`}
                    location={{
                        lat: buildingOrigin?.latitude ?? 0,
                        lng: buildingOrigin?.longitude ?? 0,
                    }}
                    defaultPixelToMeter={selectedMap?.pixelToMeter}
                    defaultOffset={selectedMap?.mapOffset}
                    onLocationChange={handleLocationChange}
                    opacity={opacity}
                    rotation={buildingToEnuRotation}
                    isSettingBuildingOrigin={isSettingBuildingOrigin}
                >
                    {({ onMapClick }) => (
                        <>
                            {isBuildingOriginSet && (
                                <OverlayView
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    position={{
                                        lat: buildingOrigin?.latitude,
                                        lng: buildingOrigin?.longitude,
                                    }}
                                >
                                    <BuildingOriginMarker id={getBuildingOriginGMapsMarkerId()} />
                                </OverlayView>
                            )}

                            {isGeofenceOriginSet && (
                                <>
                                    <OverlayView
                                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                        position={{
                                            lat: geofenceOrigin?.latitude,
                                            lng: geofenceOrigin?.longitude,
                                        }}
                                    >
                                        <GeofenceOriginMarker id={getGeofenceOriginGMapsMarkerId()} />
                                    </OverlayView>

                                    <Circle
                                        id={getGeofenceRadiusGMapsCircleId()}
                                        center={{
                                            lat: geofenceOrigin?.latitude,
                                            lng: geofenceOrigin?.longitude,
                                        }}
                                        radius={geofenceRadius}
                                        onClick={onMapClick}
                                        options={{
                                            strokeColor: '#ff6c3c',
                                            strokeOpacity: 0.8,
                                            strokeWeight: 2,
                                            fillColor: '#ff6200', // TODO color should come from theme
                                            fillOpacity: 0.35,
                                        }}
                                    />
                                </>
                            )}
                        </>
                    )}
                </GoogleMapsBuildingPlacement>

                <BuildingDetailsWrapper>
                    <OpacityWrapper>
                        <label>Map opacity: {opacity * 100}%</label>
                        <Slider
                            value={opacity}
                            disabled={buildingOrigin?.latitude === 0 && buildingOrigin?.longitude === 0}
                            aria-labelledby={'map opacity'}
                            onChange={(e, value) => setOpacity(value.toFixed(1))}
                            min={0}
                            max={1}
                            step={0.1}
                        />
                    </OpacityWrapper>

                    <Controller
                        name={'buildingToEnuRotation'}
                        defaultValue={0}
                        render={({ onChange, value }) => (
                            <RotationWrapper>
                                <NumberFormat
                                    id={getBuildingRotationId()}
                                    allowNegative={false}
                                    disabled={isSettingLocation}
                                    customInput={TextField}
                                    onValueChange={({ floatValue }) => handleRotationChanged(floatValue)
                                    }
                                    value={rotation}
                                    defaultValue={rotation}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Rotation:</InputAdornment>,
                                    }}
                                />
                                <Slider
                                    value={rotation}
                                    disabled={!isBuildingOriginSet}
                                    aria-labelledby="rotation"
                                    onChange={(e, value) => handleRotationChanged(value)}
                                    min={0}
                                    max={360}
                                    step={0.01}
                                />
                            </RotationWrapper>
                        )}
                    />

                    <BuildingOriginWrapper>
                        {isBuildingOriginSet ? (
                            <>
                                <FlexRow alignItems='center' columnGap={5}>
                                    <Typography display="inline" variant={'subtitle1'} inline={'true'}>
                                        Building origin:
                                    </Typography>

                                    <ChangeButton
                                        id={getChangeBuildingOriginButtonId()}
                                        size={'small'}
                                        variant={'outlined'}
                                        disabled={isSettingLocation}
                                        onClick={() => setIsSettingBuildingOrigin(true)}
                                    >
                                        Change
                                    </ChangeButton>

                                    <FlexRow>
                                        {isSettingBuildingOrigin && <Button onClick={() => setIsSettingBuildingOrigin(false)} style={{ minWidth: 40 }} variant={'outlined'}>
                                            <BsXLg fontSize={20} />
                                        </Button>}
                                    </FlexRow>

                                </FlexRow>

                                <Controller
                                    name={'buildingOrigin.latitude'}
                                    defaultValue={null}
                                    rules={{ required: 'This is required', valueAsNumber: true }}
                                    render={({ onChange }) => (
                                        <NumberFormat
                                            id={getBuildingOriginLatitudeId()}
                                            disabled={isSettingLocation}
                                            error={!!errors?.buildingOrigin?.latitude}
                                            customInput={TextField}
                                            onValueChange={({ floatValue }) => onChange(floatValue)}
                                            value={buildingOrigin?.latitude}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        Latitude:
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name={'buildingOrigin.longitude'}
                                    defaultValue={null}
                                    rules={{ required: 'This is required', valueAsNumber: true }}
                                    render={({ onChange }) => (
                                        <NumberFormat
                                            id={getBuildingOriginLongitudeId()}
                                            disabled={isSettingLocation}
                                            error={!!errors?.buildingOrigin?.longitude}
                                            customInput={TextField}
                                            onValueChange={({ floatValue }) => onChange(floatValue)}
                                            value={buildingOrigin?.longitude}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        Longitude:
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </>
                        ) : (
                            <Grid container alignItems={'center'} justifyContent={'center'}>
                                <Button
                                    id={getSetBuildingOriginButtonId()}
                                    disabled={isSettingLocation}
                                    onClick={() => setIsSettingBuildingOrigin(true)}
                                >
                                    Set the building's origin
                                </Button>
                            </Grid>
                        )}
                    </BuildingOriginWrapper>

                    <GeofenceOriginWrapper>
                        {isGeofenceOriginSet ? (
                            <>
                                <FlexRow alignItems='center' columnGap={5}>

                                    <Typography variant={'subtitle1'} inline>
                                        Geofence origin:
                                    </Typography>

                                    <ChangeButton
                                        id={getChangeGeofenceOriginButtonId()}
                                        size={'small'}
                                        variant={'outlined'}
                                        disabled={isSettingLocation}
                                        onClick={() => setIsSettingGeofenceOrigin(true)}
                                    >
                                        Change
                                    </ChangeButton>

                                    <FlexRow>
                                        {isSettingGeofenceOrigin && <Button onClick={() => setIsSettingGeofenceOrigin(false)} style={{ minWidth: 40 }} variant={'outlined'}>
                                            <BsXLg fontSize={20} />
                                        </Button>}
                                    </FlexRow>
                                </FlexRow>

                                <Controller
                                    name={'geofenceOrigin.latitude'}
                                    defaultValue={null}
                                    rules={{ required: 'This is required', valueAsNumber: true }}
                                    render={({ onChange }) => (
                                        <NumberFormat
                                            id={getGeofenceOriginLatitudeId()}
                                            disabled={isSettingLocation}
                                            error={!!errors?.geofenceOrigin?.latitude}
                                            customInput={TextField}
                                            onValueChange={({ floatValue }) => onChange(floatValue)}
                                            value={geofenceOrigin?.latitude}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        Latitude:
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name={'geofenceOrigin.longitude'}
                                    defaultValue={null}
                                    rules={{ required: 'This is required', valueAsNumber: true }}
                                    render={({ onChange }) => (
                                        <NumberFormat
                                            id={getGeofenceOriginLongitudeId()}
                                            disabled={isSettingLocation}
                                            error={!!errors?.geofenceOrigin?.longitude}
                                            customInput={TextField}
                                            onValueChange={({ floatValue }) => onChange(floatValue)}
                                            value={geofenceOrigin?.longitude}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        Longitude:
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name={`geofenceRadius`}
                                    defaultValue={geofenceRadius ?? 100}
                                    rules={{ required: 'This is required', valueAsNumber: true }}
                                    render={({ onChange, value }) => (
                                        <NumberFormat
                                            id={getGeofenceRadiusFieldId()}
                                            disabled={!isGeofenceOriginSet}
                                            error={!!errors?.geofenceRadius}
                                            helperText={errors?.geofenceRadius?.message}
                                            customInput={TextField}
                                            onValueChange={({ floatValue }) => {
                                                onChange(floatValue);
                                            }}
                                            value={value}
                                            suffix={' m'}
                                            decimalScale={0}
                                            allowNegative={false}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">Radius:</InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </>
                        ) : (
                            <Grid
                                container
                                direction={'column'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                spacing={1}
                            >
                                <Grid item>
                                    <Button
                                        id={getSetGeofenceOriginButtonId()}
                                        disabled={!isBuildingOriginSet || isSettingLocation}
                                        onClick={() => setIsSettingGeofenceOrigin(true)}
                                    >
                                        Set the geofence origin
                                    </Button>
                                </Grid>

                                {isBuildingOriginSet && (
                                    <>
                                        <Grid item>
                                            <Typography variant={'subtitle2'}>OR</Typography>
                                        </Grid>

                                        <Grid item>
                                            <OutlinedButton
                                                id={getCopyBuildingOriginToGeofenceOriginButtonId()}
                                                disabled={isSettingLocation}
                                                variant={'outlined'}
                                                onClick={copyBuildingOriginToGeofenceOrigin}
                                            >
                                                Use coordinates of building origin
                                            </OutlinedButton>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        )}
                    </GeofenceOriginWrapper>
                </BuildingDetailsWrapper>
            </div>
        </>
    );
}
