import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './mapQualityEntityAdapter';
import { fetchedMapQualityData, fetchedRecordsCount } from './mapQualityActions';

const { getInitialState, setAll } = entityAdapter;

export const { reducer } = createSlice({
    name: 'mapQuality',
    initialState: getInitialState({ recordsCount: 0 }),
    reducers: {},
    extraReducers: {
        [fetchedMapQualityData.fulfilled]: (state, action) => {
            setAll(state, action.payload);
        },
        [fetchedRecordsCount.fulfilled]: (state, action) => {
            state.recordsCount = action.payload.count;
        },
    },
});
