import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import TextField from '../TextField';
import Select from '../Select';

export const Wrapper = styled.div`
    display: flex;
    padding: 0 18px;
    margin-bottom: 20px;
    column-gap: 20px;
    align-items: center;
    & > *:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const Row = styled.div`
    display: flex;
`;

export const BadgeContainer = styled(Row)`
    flex-wrap: wrap;
    width: 40%;
`;

export const Header = styled(Typography).attrs({
    variant: 'subtitle1',
})``;

export const FilterSelect = styled(Select)`
    min-width: 175px;
    z-index: 900;
    margin-right: 10px;
`;
export const FilterTextField = styled(TextField)`
    height: 36px;
    margin-top: ${(props) => (props.type === 'date' ? '0' : '-12px')};
    margin-right: 10px;
`;
