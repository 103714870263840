import settings from '../clientSettings';

let logLevel = {
    DEBUG: 10,
    INFO:  20,
    WARN:  30,
    ERROR: 40,
    OFF:   80
};

let levelToConsole = {
    10: { desc: 'DEBUG', f: console.log },
    20: { desc: 'INFO', f: console.info || console.log },
    30: { desc: 'WARN', f: console.warn || console.log },
    40: { desc: 'ERROR', f: console.error || console.log }
};

class ModuleLogger {
    constructor(moduleName) {
        this._moduleName = moduleName;
    }

    info(msg) {
        this._log(msg, logLevel.INFO)
    }

    warn(msg) {
        this._log(msg, logLevel.WARN);
    }

    debug(msg) {
        this._log(msg, logLevel.DEBUG);
    }

    error(msg, ex) {
        this._log(msg, logLevel.ERROR, ex);
    }

    _log(msg, level, ex) {
        if (level >= settings.logLevel) {
            let logMsg = { msg: msg, level: levelToConsole[level].desc, module: this._moduleName };
            if (ex && ex.stack) {
                logMsg.stack = ex.stack.toString();
            }
            levelToConsole[level].f(JSON.stringify(msg));
        }
    }
}

function generateLogger(moduleName) {
    return new ModuleLogger(moduleName);
}

let logger = {
    getLogger: generateLogger
};


export default logger;
