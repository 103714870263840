import styled from 'styled-components';

export const EnableConfigWrapper = styled.div`
    display: flex;
    margin-top: 10px;
    & > *:not(:last-child) {
        margin-right: 20px;
    }
    @media (max-width: 1600px) {
        display: flex;
    }
`;
