import { createSelector } from '@reduxjs/toolkit';
import entityAdapter from './regionPlacementEntityAdapter';
import { selectEntranceEntities } from '../entrance/entranceSelectors';

export const {
    selectById: selectRegionPlacementById,
    selectIds: selectRegionPlacementIds,
    selectEntities: selectRegionPlacementEntities,
    selectAll: selectAllRegionPlacements,
    selectTotal: selectTotalRegionPlacements,
} = entityAdapter.getSelectors((state) => state.regionPlacements);

export const getSelectRegionPlacementWithAttachedEntrances = (placementId) =>
    createSelector(
        (state) => selectRegionPlacementById(state, placementId),
        selectEntranceEntities,
        (placement, entrances) => {
            if (!placement) {
                return null;
            }

            const { entranceIds, ...regionPlacement } = placement;
            return {
                ...regionPlacement,
                entrances: entranceIds?.map((id) => entrances?.[id]),
            };
        }
    );
