import entityAdapter from './mapperEntityAdapter';

export const {
    selectById: selectMapperById,
    selectIds: selectMapperIds,
    selectEntities: selectMapperEntities,
    selectAll: selectAllMappers,
    selectTotal: selectTotalMappers,
} = entityAdapter.getSelectors((state) => state.mapping.mappers);

export const selectMapperHelpRequest = (state) => state.mapping.mappers.helpRequest;
