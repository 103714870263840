export const getMapViewerSignMarkOverlayId = () => `map-viewer-sign-mark-overlay`;
export const getMapViewerSignMarkId = (signMarkId) => `map-viewer-sign-mark-overlay-sign-mark-${signMarkId}`;
export const getSignMarkEditorTextId = () => `sign-mark-editor-text-id`;

export const getSignMarkPlacementTopResizeBulletId = (placementId) =>
    `map-viewer-sign-mark-placement-${placementId}-top-resize-bullet`;
export const getSignMarkPlacementBottomResizeBulletId = (placementId) =>
    `map-viewer-sign-mark-placement-${placementId}-bottom-resize-bullet`;
export const getSignMarkPlacementLeftResizeBulletId = (placementId) =>
    `map-viewer-sign-mark-placement-${placementId}-left-resize-bullet`;
export const getSignMarkPlacementRightResizeBulletId = (placementId) =>
    `map-viewer-sign-mark-placement-${placementId}-right-resize-bullet`;
