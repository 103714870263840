import metadataFieldTypes from '../common/metadata-editor/MetadataEditorFieldTypes';

export default ({ triggerId, triggers, regions }) => {
    let trigger = triggers.find((t) => t.triggerId === triggerId);
    const isConsumableExist = trigger?.triggerMetadata
        ? JSON.parse(trigger.triggerMetadata).hasOwnProperty('isConsumable')
        : false;

    let fields = {
        enabled: {
            type: metadataFieldTypes.BOOLEAN,
            label: 'Enable trigger',
            defaultValue: true,
        },
        icon: {
            type: metadataFieldTypes.CATEGORY,
            label: 'Icon',
            fields: {
                'icon.url': {
                    type: metadataFieldTypes.STRING,
                    label: 'Icon URL',
                    defaultValue: '',
                },
                'icon.asset': {
                    type: metadataFieldTypes.SELECT,
                    label: 'Attached asset',
                    defaultValue: '',
                    options: [
                        {
                            label: '',
                            value: '',
                        },
                        {
                            label: 'star_icon',
                            value: 'star_icon',
                        },
                    ],
                },
                'icon.visibleInside': {
                    type: metadataFieldTypes.BOOLEAN,
                    label: 'Visible inside',
                    defaultValue: true,
                },
            },
        },
        messageToUser: {
            type: metadataFieldTypes.CATEGORY,
            label: 'Message to user',
            fields: {
                'message.imageUrl': {
                    type: metadataFieldTypes.STRING,
                    label: 'Message image URL',
                    defaultValue: '',
                },
                'message.title': {
                    type: metadataFieldTypes.STRING,
                    label: 'Message title',
                    defaultValue: '',
                },
                'message.description': {
                    type: metadataFieldTypes.STRING,
                    label: 'Message to user',
                    defaultValue: '',
                },
            },
        },
        triggersToEnable: {
            type: metadataFieldTypes.SELECT,
            label: 'Triggers to enable',
            defaultValue: [],
            options: triggers
                .filter((t) => t.triggerId !== triggerId)
                .map((t) => ({ value: t.triggerId, label: t.triggerName })),
            isMulti: true,
        },
        regionsToShow: {
            type: metadataFieldTypes.SELECT,
            label: 'Regions to show',
            defaultValue: [],
            options: regions.map((r) => ({ value: r.regionName, label: r.regionName })),
            isMulti: true,
        },
    };

    /*
        'showConsumable' will decide under which conditions to expose the 'isConsumable' field:
        1. 'isConsumable' is exists in the trigger metadata.
        2. Creating new trigger from now on (!triggerId).
        We will add it to the format fields object right before the 'enable' field.
    */

    const showConsumable = isConsumableExist || !triggerId;

    if (showConsumable) {
        fields = {
            enabled: fields.enabled,
            isConsumable: {
                type: metadataFieldTypes.BOOLEAN,
                label: 'Runs once per visit',
                defaultValue: true,
            },
            ...fields,
        };
    }

    return fields;
};
