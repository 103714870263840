import React from 'react';
import * as PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { MdSave, MdCancel, MdFlipToBack, MdDelete, MdEdit, MdContentCopy } from 'react-icons/md';
import { FaMapMarkerAlt } from "react-icons/fa";
import { CircularPreloader, Tooltip } from '../../../themed';
import { FlexRow } from '../../../layout';

export default function RegionActions(props) {
    const { onCancel, onSave, onEdit, onSendToBack, onDelete, onDuplicate, anchorData = {}, isSaving, isDeleting } = props;
    const { isSelectedAnchorRegion, onEditAnchor } = anchorData;
    return (
        <FlexRow>
            {!!onDuplicate && (
                <Tooltip content={'Duplicate with offset'}>
                    <IconButton onClick={onDuplicate} disabled={isSelectedAnchorRegion}>
                        <MdContentCopy />
                    </IconButton>
                </Tooltip>
            )}

            <Tooltip content={'Save'}>
                <IconButton onClick={onSave}>
                    {isSaving ? <CircularPreloader size={20} /> : <MdSave />}
                </IconButton>
            </Tooltip>

            <Tooltip content={'Edit'}>
                <IconButton onClick={onEdit} disabled={isSelectedAnchorRegion}>
                    <MdEdit />
                </IconButton>
            </Tooltip>

            {!!onSendToBack && <Tooltip content={'Send to Back'}>
                <IconButton onClick={onSendToBack} disabled={isSelectedAnchorRegion}>
                    <MdFlipToBack />
                </IconButton>
            </Tooltip>}

            {onEditAnchor && <Tooltip content={isSelectedAnchorRegion ? 'Back' : 'Set Anchor'}>
                <IconButton onClick={onEditAnchor}>
                    <FaMapMarkerAlt color={isSelectedAnchorRegion ? '#ff6200' : 'rgba(0, 0, 0, 0.54)'} />
                </IconButton>
            </Tooltip>}

            <Tooltip content={'Delete'}>
                <IconButton onClick={onDelete} disabled={isSelectedAnchorRegion}>
                    {isDeleting ? <CircularPreloader size={20} /> : <MdDelete />}
                </IconButton>
            </Tooltip>
            <Tooltip content={'Cancel'}>
                <IconButton onClick={onCancel}>
                    <MdCancel color='red' />
                </IconButton>
            </Tooltip>
        </FlexRow>
    );
}

RegionActions.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onSendToBack: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onDuplicate: PropTypes.func.isRequired,
};
