import React, { useContext, useEffect } from 'react';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import * as PropTypes from 'prop-types';
import { MapViewerContext } from '../../MapViewerContext';
import { Portal, Snackbar, SnackbarContent } from '@material-ui/core';
import styled from 'styled-components';
import {
    getMapViewerTooltipMapOffsetId,
    getMapViewerPickerOverlayWrapperId,
    getMapViewerMapImageId,
} from '../../MapViewer.selectors';
import { Button } from '../../../themed';
import {
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import { useCallback } from 'react';

const X = 'data-x';
const Y = 'data-y';

const MapOriginPickerOverlayWrapper = styled.div`
    cursor: crosshair;
    position: absolute;
    width: 100%;
    height: 100%;
`;

const ExplanationSnackbar = styled(Snackbar)`
    position: absolute;
`;

const TooltipContent = styled.div`
    position: absolute;
    width: max-content;
    ::before {
        content: 'X:' attr(${X}) ' Y:' attr(${Y}) '';
        background: #ff6200;
        border-radius: 10px;
        padding: 6px;
        box-shadow: 3px 2px 0px 2px #00000029;
        color: white;
    }
`;

const SnackbarButton = styled(Button)`
    color: white;
`;

export default function MapViewerMapOriginPickerOverlay(props) {
    const { onSubmit, onCancel, tooltip = false } = props;

    const { imageRef, containerRef, getCSSVariable } = useContext(MapViewerContext);
    const cloneRef = document.getElementById(getMapViewerMapImageId());

    const calculateMouseCoordinates = useCallback(
        (x, y) => {
            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
            const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
            const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
            const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

            const calculatedX = Number(x / pixelToMeter / scale + mapOffsetX);
            const calculatedY = Number(
                imageNaturalHeight / pixelToMeter - y / pixelToMeter / scale + mapOffsetY
            );
            return {
                x: calculatedX,
                y: calculatedY,
            };
        },
        [getCSSVariable]
    );

    const handleClick = (event) => {
        const refRect = imageRef.current.getBoundingClientRect();
        const { x, y } = calculateMouseCoordinates(event.clientX - refRect.left, event.clientY - refRect.top);
        onSubmit({ x, y });
    };

    const handleCancel = (event) => {
        event.stopPropagation();
        onCancel();
    };

    const handleMouseMove = (event) => {
        const refRect = event.currentTarget.getBoundingClientRect();
        const { x, y } = calculateMouseCoordinates(event.clientX - refRect.left, event.clientY - refRect.top);
        const tooltipMapOffsetElement = document.getElementById(getMapViewerTooltipMapOffsetId());
        if (tooltipMapOffsetElement) {
            tooltipMapOffsetElement.setAttribute(X, x.toFixed(3));
            tooltipMapOffsetElement.setAttribute(Y, y.toFixed(3));
        }
    };

    useEffect(() => {
        const ref = cloneRef.current;
        if (ref) {
            ref.addEventListener('mousemove', handleMouseMove);
            return () => {
                ref.removeEventListener('mousemove', handleMouseMove);
            };
        }
    }, [cloneRef, handleMouseMove]);

    const dataAttributes = { [X]: 0, [Y]: 0 };

    return (
        <>
            <MapOriginPickerOverlayWrapper
                ref={cloneRef}
                id={getMapViewerPickerOverlayWrapperId()}
                onClick={handleClick}
            >
                <Portal container={containerRef.current}>
                    <ExplanationSnackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <SnackbarContent
                            action={
                                <>
                                    <SnackbarButton variant={'text'} onClick={handleCancel}>
                                        Cancel
                                    </SnackbarButton>
                                </>
                            }
                            message={`Click anywhere on the map image to pick its axis origin point.`}
                        />
                    </ExplanationSnackbar>
                    <MouseTooltip visible={tooltip} offsetX={15} offsetY={10}>
                        <TooltipContent {...dataAttributes} id={getMapViewerTooltipMapOffsetId()} />
                    </MouseTooltip>
                </Portal>
            </MapOriginPickerOverlayWrapper>
        </>
    );
}

MapViewerMapOriginPickerOverlay.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};
