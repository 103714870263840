import Api from './Api';
import http from '../utils/httpClient';
import settings from '../clientSettings';
import { GET } from '../constants/httpMethods';

const { mappingRestApiUrl } = settings;

class LanesApi extends Api {
    async fetchAllLanesInMap(mapId, onData) {
        return await this.callStreamApi(GET, `${mappingRestApiUrl}/api/v1/maps/${mapId}/lanes`, 50, onData);
    }

    async fetchLane(mapId, laneId) {
        return await this.callApi(() => http.mapping.v1.get(`/maps/${mapId}/lanes/${laneId}`));
    }

    async fetchAllLaneStatusesInMap(mapId, useEqualizedMap) {
        return await this.callApi(() =>
            http.mapping.v1.get(`/maps/${mapId}/lane-statuses`, { params: { useEqualizedMap } })
        );
    }

    async fetchLaneAttachments(mapId, laneId) {
        return await this.callApi(() => http.mapping.v1.get(`/maps/${mapId}/lanes/${laneId}/attachments`));
    }

    async updateLane(mapId, laneId, laneData) {
        return await this.callApi((params) =>
            http.mapping.v1.patch(`/maps/${mapId}/lanes/${laneId}`, laneData, {
                params,
            })
        );
    }

    async transformLane(mapId, laneId, transform) {
        return await this.callApi((params) =>
            http.mapping.v1.post(`/maps/${mapId}/lanes/${laneId}`, { transform }, { params })
        );
    }

    async deleteLane(mapId, laneId) {
        return await this.callApi((params) =>
            http.mapping.v1.delete(`/maps/${mapId}/lanes/${laneId}`, { params })
        );
    }

    async cropLanes(mapId, areaData) {
        return await this.callApi(() => http.mapping.v1.post(`/maps/${mapId}/cropLane`, areaData));
    }
}

export default new LanesApi();
