export const getMismatchValueColor = (value) => {
    if (value <= 3) {
        return '#32EC81';
    } else if (value <= 5) {
        return '#ffd430';
    } else {
        return '#FF351E';
    }
};

export const getMismatchValueMarkerId = (value) => {
    if (value <= 3) {
        return 'jm-point-green';
    } else if (value <= 5) {
        return 'jm-point-yellow';
    } else {
        return 'jm-point-red';
    }
};
