import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { IconButton } from '@material-ui/core';

const Banner = styled.div`
    background-color: #f64747; // TODO color should come from theme
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    flex: 0 0 auto;
`;

const AlertIconWrapper = styled.div`
    background-color: #f95861;
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const LabelWrapper = styled.div`
    flex: 1;
    padding: 10px;
`;

const CloseIcon = styled(MdClose)`
    fill: white;
    height: 2rem;
    width: 2rem;
`;

export default function AlertBanner(props) {
    const { label, icon: Icon } = props;

    const [isOpen, setIsOpen] = useState(true);

    return (
        isOpen && (
            <Banner>
                <AlertIconWrapper>
                    <Icon size={'2rem'} />
                </AlertIconWrapper>

                <LabelWrapper>{label}</LabelWrapper>

                <IconButton onClick={() => setIsOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </Banner>
        )
    );
}

AlertBanner.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
};
