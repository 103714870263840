import { createSlice } from '@reduxjs/toolkit';
import {
    deleteCoordinatesConversion,
    fetchAllCoordinatesConversionInMap,
    updateCoordinatesConversion,
    calculateCoordinatesConversion,
    saveCoordinatesConversion,
} from './coordinatesConversionActions';
import entityAdapter from './coordinatesConversionAdapter';

const { getInitialState, getSelectors, upsertOne, removeOne } = entityAdapter;

export const {
    reducer,
    actions: {
        createCoordinatesConversionPoints,
        selectCoordinate,
        unselectCoordinate,
        highlightCoordinate,
        unhighlightCoordinate,
        clearAll: clearAllCoordinates,
        deleteCoordinate,
        updateCoordinate,
        clearCalculate,
    },
} = createSlice({
    name: 'coordinatesConversions',
    initialState: { ...getInitialState(), selected: [], highlighted: null },
    reducers: {
        createCoordinatesConversionPoints: (state, action) => {
            const { mapId, points } = action.payload;
            const { coordinates = [] } = getSelectors().selectById(state, mapId) || {};
            upsertOne(state, { mapId, coordinates: [...coordinates, { ...points }] });
        },
        clearAll: (state, action) => {
            const { mapId } = action.payload;
            const { coordinates = [], ...rest } = getSelectors().selectById(state, mapId) || {};
            upsertOne(state, { mapId, ...rest, coordinates: [] });
        },
        clearCalculate: (state, action) => {
            const { mapId } = action.payload;
            state.selected = [];
            upsertOne(state, { mapId, scale: null, rotation: null, translation: null });
        },
        deleteCoordinate: (state, action) => {
            const { mapId, coordinateId } = action.payload;
            const { coordinates = [] } = getSelectors().selectById(state, mapId) || {};
            state.selected = state.selected.filter((c) => c !== coordinateId);
            upsertOne(state, {
                mapId,
                coordinates: coordinates.filter((c) => c.coordinateId !== coordinateId),
            });
        },
        updateCoordinate: (state, action) => {
            const { mapId, coordinateId, newData } = action.payload;
            const { coordinates = [] } = getSelectors().selectById(state, mapId) || {};
            const oldCoordinate = coordinates.find((c) => c.coordinateId === coordinateId);

            upsertOne(state, {
                mapId,
                coordinates: [
                    ...coordinates.filter((c) => c.coordinateId !== coordinateId),
                    { ...oldCoordinate, ...newData },
                ],
            });
        },
        selectCoordinate: (state, action) => {
            state.selected = [...new Set([...state.selected, action.payload])];
        },
        unselectCoordinate: (state, action) => {
            state.selected = state.selected.filter((c) => c !== action.payload);
        },
        highlightCoordinate: (state, action) => {
            state.highlighted = action.payload;
        },
        unhighlightCoordinate: (state, action) => {
            state.highlighted = null;
        },
    },
    extraReducers: {
        [fetchAllCoordinatesConversionInMap.fulfilled]: (state, action) => {
            upsertOne(state, { ...action.payload });
        },
        [updateCoordinatesConversion.fulfilled]: (state, action) => {},
        [deleteCoordinatesConversion.fulfilled]: (state, action) => {
            const mapId = action.payload;

            removeOne(state, mapId);
        },
        [calculateCoordinatesConversion.fulfilled]: (state, action) => {
            const { mapId, points, ...rest } = action.payload;
            const { coordinates = [] } = getSelectors().selectById(state, mapId) || {};
            upsertOne(state, {
                mapId,
                coordinates: coordinates.map((c) => {
                    const coordinate = points.find((p) => c.coordinateId === p.id) || {};
                    return { ...coordinate, ...c };
                }),
                ...rest,
            });
        },
        [saveCoordinatesConversion.fulfilled]: (state, action) => {
            const mapId = action.payload;
            const { coordinates = [], ...rest } = getSelectors().selectById(state, mapId) || {};
            upsertOne(state, { mapId, ...rest, coordinates: [] });
        },
    },
});

export const selectHighlightedCoordinate = (state) => state.coordinatesConversions.highlighted;
export const selectAllSelectedCoordinates = (state) => state.coordinatesConversions.selected;
