import chartColors from '../chartColors';

let chart;

export default (data, callbacks) => ({
    data,
    innerRadius: '40%',
    events: {
        beforedatavalidated: event => {
            const chartData = [];
            const { regions } = event.target.data;

            regions.forEach(region => {
                chartData.push({
                    regionName: region.regionName,
                    regionId: region.regionId,
                    regionCategoryLevel: region.regionCategoryLevel,
                    totalDuration: region.totalDuration,
                    totalDurationDesc: region.totalDurationDesc,
                    visitsCount: region.visitsCount,
                    slicesConfig: {
                        fill: chartColors.getFill(region.regionName),
                        stroke: chartColors.getStroke(region.regionName),
                        strokeWidth: 1,
                    },
                    tooltipConfig: {
                        label: {
                            fill: chartColors.getLabelfill(region.regionName),
                        },
                        background: {
                            fill: chartColors.getTooltipFill(region.regionName),
                        },
                    },
                });
            });

            if (!chart) {
                chart = event.target;
            }

            event.target.data = chartData;
        },
    },
    series: [
        {
            type: 'PieSeries',
            dataFields: {
                value: 'totalDuration',
                category: 'regionName',
            },
            slices: {
                configField: 'slicesConfig',
                tooltipText:
                    "{category}:\n{totalDurationDesc}, {value.percent.formatNumber('###.00')}%\nVisits Count: {visitsCount}",
                propertyFields: {
                    fill: 'color',
                },
                stroke: '#fff',
                strokeWidth: 2,
                strokeOpacity: 1,
                clickable: true,
                events: {
                    hit: callbacks.onChartPieceClicked || (() => {}),
                    ready: callbacks.onChartPieceReady || (() => {}),
                },
            },
            ticks: { disabled: true },
            labels: { disabled: true },
            tooltip: {
                getFillFromObject: false,
                configField: 'tooltipConfig',
            },
        },
    ],
});
