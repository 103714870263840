import Api from './Api';
import http from '../utils/httpClient';

class TriggersApi extends Api {
    async fetchAllTriggersInSpace(spaceId, withRegionIds = false) {
        return await this.callApi(params =>
            http.v2.get(`/proximity-triggers`, { params: { ...params, spaceId, withRegionIds } })
        );
    }

    async createTrigger(triggerData, spaceId) {
        return await this.callApi(params =>
            http.v1.post(`/proximity-triggers`, triggerData, { params: { ...params, spaceId } })
        );
    }

    async updateTrigger(triggerId, triggerData) {
        return await this.callApi(params =>
            http.v1.post(`/proximity-triggers/${triggerId}`, triggerData, { params })
        );
    }

    async deleteTrigger(triggerId) {
        return await this.callApi(params => http.v1.delete(`/proximity-triggers/${triggerId}`, { params }));
    }

    async attachTriggerToRegion(triggerId, regionId) {
        return await this.callApi(params =>
            http.v1.post(`/proximity-trigger/${triggerId}/attach-to-region`, { regionId }, { params })
        );
    }

    async detachTriggerFromRegion(triggerId, regionId) {
        return await this.callApi(params =>
            http.v1.post(`/proximity-trigger/${triggerId}/detach-from-region`, { regionId }, { params })
        );
    }
}

export default new TriggersApi();
