import React, { forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const actionsToggleCommonStyles = css`
    fill: #ffffff;
    width: 2rem;
    height: 2rem;
`;

const OpenMenuIcon = styled(BsChevronLeft)`
    ${actionsToggleCommonStyles}
`;

const CloseMenuIcon = styled(BsChevronRight)`
    ${actionsToggleCommonStyles}
`;

const ListEntryContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    width: 100%;
`;

const ActionsWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
`;

const ActionsMenuToggle = styled.button`
    display: flex;
    align-items: center;
    background-color: #ff6200; // TODO color should come from theme
    width: 35px;
    cursor: pointer;
    border: none;
    outline: none;

    :hover {
        background-color: #ff4d17; // TODO color should come from theme
    }
`;

const ActionsMenu = styled.div`
    display: flex;
    position: absolute;
    right: 5%;
    height: 100%;
    width: 100%;
    background-color: #ffd6b3; // TODO color should come from theme
    transition: all 300ms ease;
`;

const ListEntryWrapper = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border: 1px solid #cccccc;
    border-radius: 2px;
    border-left: 3px solid #ff6200; // TODO color should come from theme
    background-color: white;
    overflow-x: hidden;
    /* width: 98% !important; */

    ${ActionsMenu}.closed {
        visibility: hidden;
        right: -100%;
    }

    ${ActionsMenu}.open {
        visibility: visible;
        right: 0;
    }

    &.clickable {
        cursor: pointer;
    }

    &.draggable {
        cursor: grab;
    }

    &.highlighted {
        background-color: #ffd6b3; // TODO color should come from theme
    }

    &.dragged-over,
    &.selected {
        background-color: #ff6200; // TODO color should come from theme

        ${ActionsMenu}.closed ${ActionsMenuToggle} {
            background-color: #ff4d17; // TODO color should come from theme
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #ffffff;
        }

        ${ListEntryContent} svg {
            fill: #ffffff;
        }
    }

    &.dragged-over {
        * {
            pointer-events: none;
        }
    }

    :hover {
        ${ActionsMenu}.closed {
            visibility: visible;
            right: calc(35px - 100%);
        }
    }
`;

const ItemsListEntry = forwardRef((props, ref) => {
    const {
        id,
        content,
        actions,
        isClickable = false,
        isDraggable = false,
        isHighlighted = false,
        isSelected = false,
        enableActions = true,
        acceptableDragDataTypes = [],
        onDragEnter = (e) => { },
        onDragLeave = (e) => { },
        onDrop = (e) => { },
        listEntryContentStyle = {},
        ...otherProps
    } = props;

    const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
    const [isDraggedOver, setIsDraggedOver] = useState(false);

    const handleDragEnter = (e) => {
        if (enableActions && e.dataTransfer.types.some((type) => acceptableDragDataTypes.includes(type))) {
            e.persist();
            setIsDraggedOver(true);
            onDragEnter(e);
        }
    };

    const handleDragLeave = (e) => {
        if (
            isDraggedOver &&
            e.target.id === id &&
            enableActions &&
            e.dataTransfer.types.some((type) => acceptableDragDataTypes.includes(type))
        ) {
            e.persist();
            setIsDraggedOver(false);
            onDragLeave(e);
        }
    };

    const handleDrop = (e) => {
        if (enableActions && e.dataTransfer.types.some((type) => acceptableDragDataTypes.includes(type))) {
            e.persist();
            setIsDraggedOver(false);
            onDrop(e);
        }
    };

    const handleClick = (e) => {
        e.persist();
        e.preventDefault();
        e.stopPropagation();

        setIsActionsMenuOpen(!isActionsMenuOpen);
    };

    return (
        <ListEntryWrapper
            id={id}
            ref={ref}
            className={clsx({
                clickable: isClickable,
                draggable: isDraggable,
                'dragged-over': isDraggedOver,
                highlighted: isHighlighted || isDraggedOver,
                selected: isSelected,
            })}
            draggable={isDraggable}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
            {...otherProps}
        >
            <ListEntryContent {...listEntryContentStyle}>{content}</ListEntryContent>

            {actions && enableActions && (
                <ActionsMenu
                    className={clsx({ open: isActionsMenuOpen, closed: !isActionsMenuOpen })}
                    onClick={(e) => e.stopPropagation()}
                >
                    <ActionsMenuToggle onClick={handleClick}>
                        {isActionsMenuOpen ? <CloseMenuIcon /> : <OpenMenuIcon />}
                    </ActionsMenuToggle>

                    <ActionsWrapper>{actions}</ActionsWrapper>
                </ActionsMenu>
            )}
        </ListEntryWrapper>
    );
});

ItemsListEntry.propTypes = {
    id: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    actions: PropTypes.node,
    isClickable: PropTypes.bool,
    isDraggable: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    isSelected: PropTypes.bool,
    enableActions: PropTypes.bool,
    acceptableDragDataTypes: PropTypes.arrayOf(PropTypes.string),
    onDragEnter: PropTypes.func,
    onDragLeave: PropTypes.func,
    onDrop: PropTypes.func,
};

export default ItemsListEntry;
