import { Button } from '@material-ui/core';
import styled from 'styled-components';
export default styled(Button).attrs(({ variant = 'contained', ...otherProps }) => ({
    classes: {
        contained: 'themed-button-contained',
        disabled: 'themed-button-disabled',
        outlined: 'themed-button-outlined',
    },
    variant,
    ...otherProps,
}))`
    &.themed-button-outlined {
        padding: 5px 8px;
    }
    &.themed-button-contained {
        background-color: #ff6200; // TODO color should come from theme
        color: white;

        :hover {
            background-color: #ff4d17;
        }

        &.themed-button-disabled {
            color: #7b7b7b;
            box-shadow: none;
            background-color: #cacaca;
        }
    }
`;
