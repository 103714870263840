import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideDrawer from '../common/side-drawer';
import MapContentSideMenuBuildingSection from './side-menu/buildings';
import Index from './side-menu/floors';
import MapContentSideMenuMapSection from './side-menu/maps';
import MapContentSideMenuRegionsSection from './side-menu/regions';
import MapContentSideMenuTriggersSection from './side-menu/triggers';
import {
    selectMapContentIsSideDrawerOpen,
    selectMapContentSelectedMapId,
    setIsSideDrawerOpen,
} from '../../state-management/user-inputs/mapContentSlice';
import { getRegionsSectionId } from './MapContent.selectors';
import { getSideDrawerMenuContentId } from '../common/side-drawer/SideDrawer.selectors';

export default function MapContentSideDrawer() {
    const dispatch = useDispatch();

    const isSideDrawerOpen = useSelector(selectMapContentIsSideDrawerOpen);
    const selectedMapId = useSelector(selectMapContentSelectedMapId);

    const sideDrawerMenu = document.getElementById(getSideDrawerMenuContentId());
    const regionsSection = document.getElementById(getRegionsSectionId());

    useEffect(() => {
        // Scroll the side menu to the regions section once a map is selected
        if (selectedMapId && sideDrawerMenu && regionsSection) {
            sideDrawerMenu.scrollTo(0, regionsSection.offsetTop);
        }
    }, [regionsSection, selectedMapId, sideDrawerMenu]);

    return (
        <>
            <SideDrawer
                isOpen={isSideDrawerOpen}
                onToggle={() => dispatch(setIsSideDrawerOpen(!isSideDrawerOpen))}
            >
                <MapContentSideMenuBuildingSection />
                <Index />
                <MapContentSideMenuMapSection />
                <MapContentSideMenuRegionsSection />
                <MapContentSideMenuTriggersSection />
            </SideDrawer>

            <div className="context-menu-holder" />
        </>
    );
}
