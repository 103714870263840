import Api from './Api';
import http from '../utils/httpClient';

class JunctionMismatchesApi extends Api {
    async fetchAllJunctionMismatchesInMap(mapId, useEqualizedMap) {
        return await this.callApi(() =>
            http.mapping.v1.get(`/maps/${mapId}/junction-mismatches`, {
                params: { useEqualizedMap },
            })
        );
    }
}

export default new JunctionMismatchesApi();
