import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './apiKeyEntityAdapter';
import {
    fetchAllApiKeysInAttachedSpaces,
    createApiKey,
    updateApiKey,
    deleteApiKey,
    excludeApiKeyFromTermsAcceptance,
    unexcludeApiKeyFromTermsAcceptance,
    suspendApiKey,
    unsuspendApiKey,
} from './apiKeyActions';
import { createUser, deleteUser, fetchAllUsers, fetchAllUsersAttachedToApiKey } from '../users/userActions';
import { expireSession, logout } from '../auth/authActions';

const { getInitialState, getSelectors, upsertMany, upsertOne, addOne, removeOne } = entityAdapter;

export const { reducer } = createSlice({
    name: 'apiKeys',
    initialState: getInitialState({ isFetched: false }),
    reducers: {},
    extraReducers: {
        [fetchAllApiKeysInAttachedSpaces.fulfilled]: (state, action) => {
            upsertMany(
                state,
                action.payload.map(
                    ({
                        apiKeyId,
                        apiKeyDesc,
                        permissions,
                        spaces,
                        isSuspended,
                        hasAcceptedLatestTermsAndConditions,
                        isExcludedFromTermsAcceptance,
                    }) => ({
                        apiKeyId,
                        apiKeyDesc,
                        permissions,
                        spaces,
                        ...(isSuspended !== undefined && { isSuspended }),
                        ...(hasAcceptedLatestTermsAndConditions !== undefined && {
                            hasAcceptedLatestTermsAndConditions,
                        }),
                        ...(isExcludedFromTermsAcceptance !== undefined && { isExcludedFromTermsAcceptance }),
                    })
                )
            );
            state.isFetched = true;
        },

        [createApiKey.fulfilled]: (state, action) => {
            const { apiKeyId, apiKeyDesc, permissions, spaces } = action.payload;
            addOne(state, { apiKeyId, apiKeyDesc, permissions, spaces });
        },

        [updateApiKey.fulfilled]: (state, action) => {
            const { apiKeyId } = action.meta.arg;
            const { apiKeyDesc, permissions, spaces } = action.payload;
            upsertOne(state, { apiKeyId, apiKeyDesc, permissions, spaces });
        },

        [deleteApiKey.fulfilled]: (state, action) => {
            removeOne(state, action.meta.arg);
        },

        [excludeApiKeyFromTermsAcceptance.fulfilled]: (state, action) => {
            upsertOne(state, { apiKeyId: action.meta.arg, isExcludedFromTermsAcceptance: true });
        },

        [unexcludeApiKeyFromTermsAcceptance.fulfilled]: (state, action) => {
            upsertOne(state, { apiKeyId: action.meta.arg, isExcludedFromTermsAcceptance: false });
        },

        [suspendApiKey.fulfilled]: (state, action) => {
            upsertOne(state, { apiKeyId: action.meta.arg, isSuspended: true });
        },

        [unsuspendApiKey.fulfilled]: (state, action) => {
            upsertOne(state, { apiKeyId: action.meta.arg, isSuspended: false });
        },

        [fetchAllUsers.fulfilled]: (state, action) => {
            upsertMany(
                state,
                action.payload.map(({ apiKeyId, users }) => ({
                    apiKeyId,
                    userIds: users.map((u) => u.userId),
                }))
            );
        },

        [fetchAllUsersAttachedToApiKey.fulfilled]: (state, action) => {
            const apiKeyId = action.meta.arg;
            upsertOne(state, { apiKeyId, userIds: action.payload.map((u) => u.userId) });
        },

        [createUser.fulfilled]: (state, action) => {
            const { apiKeyId } = action.meta.arg;
            const { userId } = action.payload;
            const { userIds } = getSelectors().selectById(state, apiKeyId);

            if (!(userIds ?? []).includes(userId)) {
                upsertOne(state, { apiKeyId, userIds: [...(userIds ?? []), userId] });
            }
        },

        [deleteUser.fulfilled]: (state, action) => {
            const userId = action.meta.arg;
            const allApiKeys = getSelectors().selectAll(state);

            upsertMany(
                state,
                allApiKeys.map((ak) => ({ ...ak, userIds: (ak.userIds ?? []).filter((id) => id !== userId) }))
            );
        },

        [expireSession.fulfilled]: () => getInitialState({ isFetched: false }),
        [logout.fulfilled]: () => getInitialState({ isFetched: false }),
    },
});
