import Api from './Api';
import http from '../utils/httpClient';

class linesOfInterestApi extends Api {
    async fetchAllLinesOfInterestInMap(mapId) {
        return await this.callApi((params) =>
            http.mapping.v1.get(`/maps/${mapId}/lines-of-interest`, { params })
        );
    }

    async createLineOfInterest(mapId, lineData) {
        return await this.callApi((params) =>
            http.mapping.v1.post(`/maps/${mapId}/lines-of-interest`, lineData, { params })
        );
    }

    async updateLineOfInterest(mapId, lineId, lineData) {
        return await this.callApi((params) =>
            http.mapping.v1.patch(`/maps/${mapId}/lines-of-interest/${lineId}`, lineData, {
                params,
            })
        );
    }

    async deleteLineOfInterest(mapId, lineId) {
        return await this.callApi((params) =>
            http.mapping.v1.delete(`/maps/${mapId}/lines-of-interest/${lineId}`, {
                params,
            })
        );
    }
}

export default new linesOfInterestApi();
