import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectJunctionMismatchToggled } from '../../../state-management/mapping/junction-mismatch/junctionMismatchSelectors';
import {
    selectMappingIsShowingEqualizedMap,
    selectMappingSelectedMapId,
    selectMappingShowJunctionsAboveMismatchThreshold,
    selectMappingShowJunctionsBelowMismatchThreshold,
} from '../../../state-management/user-inputs/mappingSlice';
import useJunctionMismatches from '../../common/hooks/data-fetching/useJunctionMismatches';
import MapViewerJunctionMismatchesOverlay from '../../common/map-viewer/overlays/junction-mismatches';
import MapViewerLoadingIndicatorOverlay from '../../common/map-viewer/overlays/loading-indicators';

export const JunctionMismatchesOverlay = () => {
    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const showJunctionsBelowMismatchThreshold = useSelector(selectMappingShowJunctionsBelowMismatchThreshold);
    const showJunctionsAboveMismatchThreshold = useSelector(selectMappingShowJunctionsAboveMismatchThreshold);
    const isShowingEqualizedMap = useSelector(selectMappingIsShowingEqualizedMap);
    const junctionMismatchToggle = useSelector(selectJunctionMismatchToggled);

    const { data: junctions = [], isLoading } = useJunctionMismatches({
        mapId: selectedMapId,
        useEqualizedMap: isShowingEqualizedMap,
        junctionMismatchToggle,
    });

    const junctionToDisplay = useMemo(
        () =>
            junctions.filter((j) =>
                j?.mismatchValue < 3
                    ? showJunctionsBelowMismatchThreshold
                    : showJunctionsAboveMismatchThreshold
            ),
        [junctions, showJunctionsAboveMismatchThreshold, showJunctionsBelowMismatchThreshold]
    );

    return (
        (showJunctionsBelowMismatchThreshold || showJunctionsAboveMismatchThreshold) &&
        junctionToDisplay?.length > 0 && (
            <>
                <MapViewerJunctionMismatchesOverlay junctions={junctionToDisplay} />
                {isLoading && <MapViewerLoadingIndicatorOverlay label={'Loading junction mismatches...'} />}
            </>
        )
    );
};
