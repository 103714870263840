import React, { Component } from 'react';

class OnlineClient extends Component{

    //noinspection JSDuplicatedDeclaration
    lastAccuratePosition = null;

    getArrowPath = (len, side, theta, x0, y0, phi) =>
    {
        function getArrowCoords(len, side, theta)
        {
            let t_rad = theta * Math.PI / 180;
            return [[0,len], [-side * Math.sin(t_rad), len - side * Math.cos(t_rad)],
                [0,0], [side * Math.sin(t_rad), len - side * Math.cos(t_rad)]];
        }
        function transformSVG(vertex_list, svg_matrix)
        {
            let l = vertex_list.length;
            let retval = [];

            for (let i = 0; i < l; i++)
            {
                let x = svg_matrix.a * vertex_list[i][0] + svg_matrix.c * vertex_list[i][1] + svg_matrix.e;
                let y = svg_matrix.b * vertex_list[i][0] + svg_matrix.d * vertex_list[i][1] + svg_matrix.f;
                retval.push([x,y]);
            }
            return retval;
        }
        //noinspection JSUnresolvedFunction
        let transformation = document.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGMatrix();
        // the arrow is created pointing down instead of to the right (positive X), so i shift the given rotation by +90
        transformation = transformation.translate(x0, y0).rotate(-phi - 90);

        //noinspection JSUnresolvedFunction
        let arrow = new Path2D();
        let x = getArrowCoords(len,side,theta);
        x = transformSVG(x, transformation);

        arrow.moveTo(x[0][0], x[0][1]);
        arrow.lineTo(x[1][0], x[1][1]);
        arrow.lineTo(x[2][0], x[2][1]);
        arrow.lineTo(x[3][0], x[3][1]);
        arrow.lineTo(x[0][0], x[0][1]);

        return `M${x0},${y0-theta-len} L${x0-side},${y0-theta} L${x0+side},${y0-theta} z`;
    };
    //noinspection JSDuplicatedDeclaration
    componentDidMount() {
        this.cursorAgingTimer = setInterval(this.cursorAging, 5000);
    };
    cursorAging = () => {
        const { props } = this;
        let { position, clientSessionId } = props;

        if (position.lifeRemaining === 0) {
            clearInterval(this.cursorAgingTimer);
            props.onClientDelete(clientSessionId);
            return;
        }
        position.lifeRemaining -= 1;
    };

    render() {
        const { props } = this;
        const { pixelToMeter } = props || 1;
        const { position: currentPosition, color, stageSize, showLocationAccuracy, mapOffset, acceptedAccuracy } = props;

        const isAccurate = currentPosition.locationAccuracy<=acceptedAccuracy;

        if(isAccurate){
            // Clone the position object to avoid simply saving the ref to props.position, which could change
            // even when we're not accurate and not entering this 'if' statement
            this.lastAccuratePosition = {...currentPosition};
        }

        if(!this.lastAccuratePosition){
            return null;
        }

        const position = isAccurate ? currentPosition : this.lastAccuratePosition;

        const x =( position.location[0] - mapOffset.x) * pixelToMeter;
        const y = ((stageSize.height / pixelToMeter) - position.location[1] + mapOffset.y) * pixelToMeter;
        const r = 10 * (1/stageSize.scale);

        const a = position.locationAccuracy * pixelToMeter;

        let currentHeading = Math.atan2(position.heading[1], position.heading[0]) * (180 / Math.PI);
        //getArrowPath = (len, side, theta, x0, y0, phi)
        let arrow = this.getArrowPath(10 * (1/stageSize.scale), 10 * (1/stageSize.scale), 12 * (1/stageSize.scale), x, y, currentHeading);


        return (
            <g
                className={'component OnlineClient'}
                transform={`translate(0,0) scale(${stageSize.scale})`}
                >
                {showLocationAccuracy ?
                <circle
                    ref={'cyan'}
                    fill={color}
                    opacity="0.25"
                    cx={x}
                    cy={y}
                    r={a}>
                </circle> : null }
                <circle
                    ref={color}
                    fill={color}
                    opacity={isAccurate ? '1' : '0.5'}
                    cx={x}
                    cy={y}
                    r={r}/>
                {isAccurate && <path opacity={'1'} d={arrow} transform={`rotate(${-currentHeading + 90} ${x} ${y})`} fill={color} />}
            </g>
        );
    }
}

export default OnlineClient;
