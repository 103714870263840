import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import SideMenuSection from '../../../common/side-drawer/SideMenuSection';
import { deleteBuilding} from '../../../../state-management/building/buildingActions';
import {
    PulsePreloader,
    Select,

} from '../../../common/themed';
import {
    selectBuildingsSelectedBuildingId,
    setSelectedBuildingId,
} from '../../../../state-management/user-inputs/buildingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import useAllBuildingsInSpace from '../../../common/hooks/data-fetching/useAllBuildingsInSpace';
import { selectIsLoading } from '../../../../state-management/status/statusSelectors';
import CreateBuildingDialog from '../../dialogs/create-building';
import { Collapse, IconButton} from '@material-ui/core';
import { MdEdit as EditIcon } from 'react-icons/md';
import { selectCanEditBuildings } from '../../../../state-management/auth/authSelectors';
import { getBuildingSelectId } from '../../buildings.selectors';
import FetchError from '../../../common/error-pages/FetchError';
import BuildingDetailsForm from './BuildingDetailsForm';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';

const ExpandToggle = styled(IconButton)`
    width: 100%;
    padding: 0;
    border-radius: 0;
    margin-block-start: 10px;
`;

export default function BuildingsSideMenuBuildingSection() {
    const [isCreateBuildingDialogOpen, setIsCreateBuildingDialogOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const dispatch = useDispatch();

    const selectIsDeletingBuilding = useMemo(() => selectIsLoading(deleteBuilding.typePrefix), []);

    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const isDeletingBuilding = useSelector(selectIsDeletingBuilding);
    const canEditBuildings = useSelector(selectCanEditBuildings);

    const { data: buildings, isLoading, hasError } = useAllBuildingsInSpace({
        asObject: true,
    });

    const selectedBuilding = buildings?.[selectedBuildingId];

    const handleBuildingSelect = (building) => {
        if (building.buildingId !== selectedBuildingId) {
            dispatch(setSelectedBuildingId(building.buildingId));
        }
    };

    const handleDelete = async () => {
        const result = await dispatch(deleteBuilding(selectedBuildingId));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Building deleted successfully.'));
        } else {
            dispatch(showErrorNotification(`Failed to delete building`));
        }
    };

    useEffect(() => {
        // If the selected space has changed, select the first building by default
        if (!selectedBuildingId && buildings && Object.values(buildings).length === 1) {
            dispatch(setSelectedBuildingId(Object.keys(buildings)[0]));
        }
    }, [buildings, dispatch, selectedBuildingId]);

    return (
        <>
            <SideMenuSection
                title={'Building'}
                showAdd={canEditBuildings}
                showDelete={canEditBuildings && !!selectedBuildingId}
                onAdd={() => setIsCreateBuildingDialogOpen(true)}
                onDelete={handleDelete}
                isDeleting={isDeletingBuilding}
            >
                {hasError ? (
                    <FetchError />
                ) : isLoading ? (
                    <PulsePreloader fullHeight={false} />
                ) : (
                    <>
                        <Select
                            id={getBuildingSelectId()}
                            options={buildings ? Object.values(buildings) : []}
                            placeholder={'Select a building...'}
                            getOptionValue={(b) => b.buildingId}
                            getOptionLabel={(b) => b.buildingName}
                            onChange={handleBuildingSelect}
                            value={buildings?.[selectedBuildingId] || null}
                        />

                        {selectedBuilding && (
                            <>
                                <ExpandToggle onClick={() => setIsExpanded(!isExpanded)}>
                                    <EditIcon />
                                </ExpandToggle>

                                <Collapse in={isExpanded}>
                                    <BuildingDetailsForm building={selectedBuilding} />
                                </Collapse>
                            </>
                        )}
                    </>
                )}
            </SideMenuSection>

            {isCreateBuildingDialogOpen && (
                <CreateBuildingDialog onClose={() => setIsCreateBuildingDialogOpen(false)} />
            )}
        </>
    );
}
