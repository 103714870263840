import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Button, CircularPreloader } from '../themed';
import { Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { MdAdd, MdDelete as DeleteIcon } from 'react-icons/md';
import styled from 'styled-components';
import Tooltip from '../themed/Tooltip';

const Section = styled.div`
    padding: 1.5rem;
`;

const SectionTitleWrapper = styled(Grid).attrs(() => ({
    container: true,
    justifyContent: 'space-between',
    alignItems: 'center',
}))``;

const SectionTitle = styled(Typography).attrs(() => ({
    variant: 'subtitle1',
}))`
    color: #ff6200; // todo color should come from theme
`;

const SectionControlsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const AddIcon = styled(MdAdd).attrs(() => ({
    size: 25,
}))`
    fill: #ff6200; // todo color should come from theme
`;

const DeleteWarning = styled.div`
    padding: 6px;
    display: flex;
    flex-direction: column;
`;

const ButtonsWrapper = styled.div`
    margin-block-start: 5px;

    ${Button} {
        min-width: 50px;
        padding-top: 2px;
        padding-bottom: 2px;

        :not(:last-child) {
            margin-inline-end: 8px;
        }
    }
`;

export default function SideMenuSection(props) {
    const {
        title,
        onAdd = () => {},
        onDelete = () => {},
        showAdd = false,
        showDelete = false,
        isDeleting = false,
        isDeleteDisabled = false,
        deleteTooltipContent = '',
        isDeleteTooltipEnabled = false,
        children,
        ...otherProps
    } = props;

    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isDeleteWarningDisplayed, setIsDeleteWarningDisplayed] = useState(false);

    const tooltipProps = isDeleteWarningDisplayed
        ? {
              content: (
                  <DeleteWarning>
                      <span>Are you sure?</span>

                      <ButtonsWrapper>
                          <Button
                              size={'small'}
                              onClick={() => setIsTooltipVisible(false)}
                              variant={'outlined'}
                          >
                              No
                          </Button>

                          <Button size={'small'} onClick={onDelete}>
                              Yes
                          </Button>
                      </ButtonsWrapper>
                  </DeleteWarning>
              ),
              enabled: true,
              trigger: 'manual',
              visible: isTooltipVisible,
              interactive: true,
              onHidden: () => {
                  setIsDeleteWarningDisplayed(false);
                  setIsTooltipVisible(undefined);
              },
          }
        : { content: deleteTooltipContent, trigger: 'mouseenter focus', enabled: isDeleteTooltipEnabled };

    return (
        <>
            <Section {...otherProps}>
                <SectionTitleWrapper>
                    <SectionTitle>{title}</SectionTitle>

                    <SectionControlsWrapper>
                        {showDelete &&
                            (isDeleting ? (
                                <CircularPreloader size={20} />
                            ) : (
                                <Tooltip {...tooltipProps}>
                                    <IconButton
                                        onClick={() => {
                                            setIsDeleteWarningDisplayed(true);
                                            setIsTooltipVisible(true);
                                        }}
                                        disabled={isDeleteDisabled}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            ))}

                        {showAdd && (
                            <IconButton onClick={onAdd}>
                                <AddIcon />
                            </IconButton>
                        )}
                    </SectionControlsWrapper>
                </SectionTitleWrapper>

                {children}
            </Section>

            <Divider />
        </>
    );
}

SideMenuSection.propTypes = {
    title: PropTypes.string.isRequired,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    showAdd: PropTypes.bool,
    showDelete: PropTypes.bool,
    isDeleting: PropTypes.bool,
    isDeleteDisabled: PropTypes.bool,
    deleteTooltipContent: PropTypes.string,
    isDeleteTooltipEnabled: PropTypes.bool,
};
