import { createAction } from '@reduxjs/toolkit';

export const laneCreated = createAction('lanes/event/created');
export const laneUpdated = createAction('lanes/event/updated');
export const laneDeleted = createAction('lanes/event/deleted');

export const mapperAlive = createAction('mappers/event/alive');
export const superMapperToggled = createAction('mappers/event/superMapperToggled');

export const mapperExpired = createAction('mappers/event/expired');
export const mapperPositionUpdated = createAction('mappers/event/positionUpdated');
export const mapperHelpRequested = createAction('mappers/event/helpRequested');
export const mapperHelpRequestAcknowledged = createAction('mappers/event/helpRequestAcknowledged');

export const areaOfInterestCreated = createAction('areasOfInterest/event/created');
export const areaOfInterestUpdated = createAction('areasOfInterest/event/updated');
export const areaOfInterestDeleted = createAction('areasOfInterest/event/deleted');

export const elementsUpdated = createAction('elements/event/updated');
export const elementsCreated = createAction('elements/event/created');
export const elementsDeleted = createAction('elements/event/deleted');

export const lineOfInterestCreated = createAction('linesOfInterest/event/created');
export const lineOfInterestUpdated = createAction('linesOfInterest/event/updated');
export const lineOfInterestDeleted = createAction('linesOfInterest/event/deleted');

export const mapValidationsSomeUpdated = createAction('mapValidations/event/someUpdated');
export const mapValidationsDeleted = createAction('mapValidations/event/deleted');
export const mapValidationsAllUpdated = createAction('mapValidations/event/allUpdated');
export const mapEqualizationStarted = createAction('maps/event/equalizationStarted');
export const mapEqualizationFinished = createAction('maps/event/equalizationFinished');

export const exitRegionUpdated = createAction('exitRegion/event/updated');
export const exitRegionCreated = createAction('exitRegion/event/created');
export const exitRegionDeleted = createAction('exitRegion/event/deleted');

export const signMarkCreated = createAction('signMark/event/created');
export const signMarkUpdated = createAction('signMark/event/update');
export const signMarkDeleted = createAction('signMark/event/deleted');
