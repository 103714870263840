export const reducer = (state, action) => {
    switch (action.type) {
        case 'setGrouping':
            return { ...state, isGrouping: action.payload };
        case 'setFiltering':
            return { ...state, isFiltering: action.payload };
        case 'setShowColumns':
            return { ...state, isShowColumns: action.payload };
        case 'setSearching':
            return { ...state, isSearching: action.payload };
        case 'setExport':
            return { ...state, isExporting: action.payload };
        case 'setColumns':
            return { ...state, table: { ...state.table, columns: action.payload } };
        case 'setFilters':
            return { ...state, table: { ...state.table, filters: action.payload } };
        case 'setPaginate':
            return { ...state, table: { ...state.table, paginate: action.payload } };
        case 'setSort':
            return { ...state, table: { ...state.table, sort: action.payload } };
        default:
            return { ...state };
    }
};
