import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '../Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { TableHead } from './TableHead';
import { stableSort, getSorting } from './utils';

import styled from 'styled-components';
import TableCellFactory from './TableCellFactory';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 2 * 3,
    },
    table: {
        minWidth: 1020,
        tableLayout: 'fixed',
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));

const Form = styled.form``;
/**
 *
 * @param {{
 * columns: [{id:string , numeric:boolean,disablePadding:boolean,label:string,type:"text"|"input"|"checkbox"}],
 * showCheckboxColumn:boolean,
 * paginate:{page:number,rowsPerPage:number,rowsPerPageOptions:[number]},
 * data:any[],
 * order:"asc" | "dsc",
 * orderBy:string,
 * onRowSelected:(row)=>{},
 * isLoading:boolean,
 * hasError:any,
 * selectedRows:[any],
 * setSelectedRows:(rows)=>{},
 * style:object,
 * disableSelecting:boolean,
 * onPropertyChanged:(id,name,value)=>{},
 * key:string
 * }} props
 *
 */
function EnhancedTable(props) {
    const {
        showCheckboxColumn = false,
        style,
        paginate,
        columns,
        onRowSelected,
        disableSelecting = false,
        data: rows = [],
        uniqueKey,
        isLoading,
        hasError,
        selectedRows = [],
        setSelectedRows,
        onPropertyChanged: _onPropertyChanged,
    } = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState(props.order || 'asc');
    const [orderBy, setOrderBy] = React.useState(props.orderBy || columns?.[0].id);
    const [page, setPage] = React.useState(paginate?.page || 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(paginate?.rowsPerPage || 5);
    const [data, setData] = React.useState(rows);

    useEffect(() => {
        setData(rows);
    }, [rows]);

    const onPropertyChanged = (id, name, value) => {
        setData((prevState) => {
            return prevState.map((s) => {
                if (s[uniqueKey] === id) {
                    return { ...s, [name]: value };
                }
                return s;
            });
        });
        if (_onPropertyChanged) {
            _onPropertyChanged(id, name, value);
        }
    };
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n[uniqueKey]);
            setSelectedRows(newSelecteds);
            return;
        }
        setSelectedRows([]);
    }

    function handleClick(event, id) {
        const selectedIndex = selectedRows.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }

        if (!disableSelecting) {
            setSelectedRows(newSelected);
        }

        onRowSelected(data.find((r) => r[uniqueKey] === id));
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
    }

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    const preperData = () => {
        const sortedData = stableSort(data, getSorting(order, orderBy));
        return paginate ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedData;
    };
    return (
        <Paper className={classes.root} style={style} elevation={2}>
            <Form className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                    <TableHead
                        numSelected={selectedRows.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                        showCheckboxHeader={showCheckboxColumn}
                        columns={columns}
                    />
                    <TableBody>
                        {preperData().map((row) => {
                            const isItemSelected = isSelected(row[uniqueKey]);

                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row[uniqueKey])}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row[uniqueKey]}
                                    selected={isItemSelected}
                                >
                                    {showCheckboxColumn && (
                                        <TableCell padding="checkbox">
                                            <Checkbox checked={isItemSelected} />
                                        </TableCell>
                                    )}

                                    <TableCellFactory
                                        className={classes.ellipsis}
                                        row={row}
                                        uniqueKey={uniqueKey}
                                        onPropertyChanged={onPropertyChanged}
                                        columns={columns}
                                    />
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 49 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Form>
            {paginate && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </Paper>
    );
}

export default EnhancedTable;
