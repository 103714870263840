import { useDispatch, useSelector } from 'react-redux';
import {
    selectHasError as getSelectHasError,
    selectIsLoading as getSelectIsLoading,
} from '../../../../state-management/status/statusSelectors';
import { useEffect, useMemo } from 'react';
import { fetchMapFullData } from '../../../../state-management/map/mapActions';
import {
    getSelectAllFloorsInBuilding,
    getSelectAllMapIdsInBuilding,
    selectMapEntities,
} from '../../../../state-management/map/mapSelectors';
import { selectFloorById } from '../../../../state-management/floor/floorSelectors';

const isMapContainingFullData = (map) => {
    return !!map?.mapOffset; // TODO need a more reliable way of knowing if a map's full data is fetched or not
};

/**
 * Hook that fetches the full data for maps in the selected space to the Redux store
 * (meaning giving an image URL and so on).
 * @param options
 * @returns {{isLoading: *, data: {spaces: *, selectedSpace}, hasError}}
 */
export default function useMapsFullData(options = {}) {
    const { asObject = false, buildingId, floorId, mapIds = [] } = options;

    const dispatch = useDispatch();

    const floor = useSelector((state) => selectFloorById(state, floorId));

    const actions = useMemo(
        () => [
            ...(!!buildingId && !floorId && mapIds.length === 0
                ? [{ type: fetchMapFullData.typePrefix }]
                : []),
            ...(!!buildingId && !!floorId && mapIds.length === 0
                ? floor?.mapIds.map((mapId) => ({
                      type: fetchMapFullData.typePrefix,
                      arg: { buildingId, floorId, mapId },
                  }))
                : []),
            ...(!!buildingId && !!floorId && mapIds.length > 0
                ? mapIds.map((mapId) => ({
                      type: fetchMapFullData.typePrefix,
                      arg: { buildingId, floorId, mapId },
                  }))
                : []),
        ],
        [floor, buildingId, floorId, mapIds]
    );

    const selectIsLoading = useMemo(() => getSelectIsLoading(actions), [actions]);
    const selectHasError = useMemo(() => getSelectHasError(actions), [actions]);
    const selectAllFloors = useMemo(() => getSelectAllFloorsInBuilding(buildingId), [buildingId]);
    const selectAllMapIds = useMemo(() => getSelectAllMapIdsInBuilding(buildingId), [buildingId]);

    const mapsObject = useSelector(selectMapEntities);
    const isFetching = useSelector(selectIsLoading);
    const hasError = useSelector(selectHasError);
    const allFloorsInBuilding = useSelector(selectAllFloors);
    const allMapIdsInBuilding = useSelector(selectAllMapIds);

    useEffect(() => {
        // If you need to fetch all maps in the entire building
        if (!isFetching && !hasError && !!buildingId && !floorId && mapIds?.length === 0) {
            Promise.all(
                allFloorsInBuilding.reduce(
                    (result, { floorId, mapIds }) => [
                        ...result,
                        ...mapIds
                            // Filter out the map IDs that are null/undefined
                            // or already have their full data fetched to avoid redundant requests
                            .filter((mapId) => !!mapId && !isMapContainingFullData(mapsObject[mapId]))
                            .map((mapId) =>
                                dispatch(
                                    fetchMapFullData({
                                        buildingId,
                                        floorId,
                                        mapId,
                                    })
                                )
                            ),
                    ],
                    []
                )
            );
        }
    }, [allFloorsInBuilding, buildingId, floorId, hasError, isFetching, mapIds, mapsObject]);

    useEffect(() => {
        // If you need to fetch all maps in the entire floor
        if (!isFetching && !hasError && !!buildingId && !!floorId && mapIds?.length === 0) {
            Promise.all(
                floor?.mapIds
                    // Filter out the map IDs that are null/undefined
                    // or already have their full data fetched to avoid redundant requests
                    ?.filter((mapId) => !!mapId && !isMapContainingFullData(mapsObject[mapId]))
                    ?.map((mapId) =>
                        dispatch(
                            fetchMapFullData({
                                buildingId,
                                floorId,
                                mapId,
                            })
                        )
                    )
            );
        }
    }, [buildingId, dispatch, floor, floorId, hasError, isFetching, mapIds, mapsObject]);

    useEffect(() => {
        // If we need to fetch specific maps in a floor
        if (!isFetching && !hasError && !!buildingId && !!floorId && mapIds?.length > 0) {
            // Fetch any maps' full data that need fetching
            Promise.all(
                mapIds
                    // Filter out the map IDs that are null/undefined
                    // or already have their full data fetched to avoid redundant requests
                    .filter((mapId) => !!mapId && !isMapContainingFullData(mapsObject[mapId]))
                    .map((mapId) =>
                        dispatch(
                            fetchMapFullData({
                                buildingId,
                                floorId,
                                mapId,
                            })
                        )
                    )
            );
        }
    }, [dispatch, buildingId, floorId, hasError, isFetching, mapIds, mapsObject]);

    const mapIdsToReturn =
        !!buildingId && !floorId && mapIds.length === 0
            ? allMapIdsInBuilding
            : !!buildingId && !!floorId && mapIds.length === 0
            ? floor?.mapIds
            : mapIds;

    return {
        data: asObject
            ? mapIdsToReturn.reduce(
                  (result, id) => ({
                      ...result,
                      [id]: mapsObject[id],
                  }),
                  {}
              )
            : mapIdsToReturn.map((id) => mapsObject[id]),
        isLoading: isFetching,
        hasError,
    };
}
