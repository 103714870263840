export const checkIfCanPublishMapping = (metadata, selfHealingEnabled) => {
    const [latestV1, latestV2] = metadata.filter((m) => m.gridType === 'latest');
    const [publicV1, publicV2] = metadata.filter((m) => m.gridType === 'public');
    const hasNoLatest = !latestV1 || !latestV2;
    const createdByMapping = [latestV1?.createdBy, latestV2?.createdBy].includes('mapping');
    const canPublish =
        hasNoLatest ||
        (!publicV1 && latestV1) ||
        publicV1?.mapGridVersion < latestV1?.mapGridVersion ||
        (!publicV2 && latestV2) ||
        publicV2?.mapGridVersion < latestV2?.mapGridVersion;
    const lastHealedDate = createdByMapping && (latestV2?.updateDate || latestV1?.updateDate);

    return {
        canPublish: canPublish && !selfHealingEnabled && createdByMapping,
        lastHealedDate,
        hasNoLatest: !latestV1 && !latestV2,
    };
};
