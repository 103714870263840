export const getBuildingsSelectId = () => `map-content-side-menu-buildings-select`;
export const getFloorsSelectId = () => `map-content-side-menu-floors-select`;
export const getMapsSelectId = () => `map-content-side-menu-maps-select`;

export const getRegionsSectionId = () => `map-content-side-menu-regions-section`;
export const getRegionsFilterId = () => `map-content-side-menu-regions-filter`;
export const getRegionsListId = () => `map-content-side-menu-regions-list`;
export const getRegionsListEntryId = (regionId) => `map-content-side-menu-regions-list-entry-${regionId}`;
export const getRegionsListEntryNameId = (regionId) =>
    `map-content-side-menu-regions-list-entry-${regionId}-name`;
export const getRegionsListEntryEditButtonId = (regionId) =>
    `map-content-side-menu-regions-list-entry-${regionId}-edit-button`;
export const getRegionsListEntryDeleteButtonId = (regionId) =>
    `map-content-side-menu-regions-list-entry-${regionId}-delete-button`;
export const getRegionsListShowInSelectedMapOnlyId = () =>
    `map-content-side-menu-regions-list-show-in-selected-map-only`;
export const getRegionsListLockRegionsSwitchId = () => `map-content-side-menu-regions-list-lock-regions`;

export const getTriggersSectionId = () => `map-content-side-menu-regions-section`;
export const getTriggersFilterId = () => `map-content-side-menu-triggers-filter`;
export const getTriggersListId = () => `map-content-side-menu-triggers-list`;
export const getTriggersListEntryId = (triggerId) => `map-content-side-menu-triggers-list-entry-${triggerId}`;
export const getTriggersListEntryDragImageId = (triggerId) =>
    `map-content-side-menu-triggers-list-entry-drag-image-${triggerId}`;
export const getTriggersListEntryNameId = (triggerId) =>
    `map-content-side-menu-triggers-list-entry-${triggerId}-name`;
export const getTriggersListEntryEditButtonId = (triggerId) =>
    `map-content-side-menu-triggers-list-entry-${triggerId}-edit-button`;
export const getTriggersListEntryDeleteButtonId = (triggerId) =>
    `map-content-side-menu-triggers-list-entry-${triggerId}-delete-button`;
export const getTriggersListEntryDetachFromRegionButtonId = (triggerId) =>
    `map-content-side-menu-triggers-list-entry-${triggerId}-detach-from-region-button`;
export const getTriggersListEntryDetachFromRegionButtonClassName = () =>
    `map-content-side-menu-triggers-list-entry-detach-from-region-button`;
export const getTriggersListEntryAttachToRegionButtonId = (triggerId) =>
    `map-content-side-menu-triggers-list-entry-${triggerId}-attach-to-region-button`;
export const getTriggersListEntryAttachToRegionButtonClassName = () =>
    `map-content-side-menu-triggers-list-entry-attach-to-region-button`;
export const getTriggersListShowInSelectedMapOnlyId = () =>
    `map-content-side-menu-triggers-list-show-in-selected-map-only`;
