import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Radio,
    CircularPreloader,
    Skeleton,
    Tooltip,
    SwitchLabel as Label,
    Checkbox,
} from '../../../common/themed';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import {
    RadioGroup,
    FormControlLabel,
    Grid,
    Typography,
    Divider,
    List,
    InputAdornment,
    IconButton,
    FormGroup,
} from '@material-ui/core';
import useTriggers from '../../../common/hooks/data-fetching/useTriggers';
import { selectRegionPlacementById } from '../../../../state-management/region-placement/regionPlacementSelectors';
import MetadataEditor from '../common/metadata-editor/MetadataEditor';
import { boolean, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers';
import ExpandableSection from '../common/ExpandableSection';
import regionMetadataOriientFormat from './RegionMetadataOriientFormat';
import { getMetadataDefaultValues } from '../../../../utils/MetadataUtils';
import uuid from 'uuid/v4';
import { createRegion, updateRegion } from '../../../../state-management/region/regionActions';
import {
    createRegionInternalPlacement,
    updateRegionInternalPlacement,
} from '../../../../state-management/region-placement/regionPlacementActions';
import {
    selectMapContentSelectedBuildingId,
    selectMapContentSelectedFloorId,
    selectMapContentSelectedMapId,
} from '../../../../state-management/user-inputs/mapContentSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
    getRegionEditorDetachTriggerId,
    getRegionEditorPlacementShapeCircularId,
    getRegionEditorPlacementShapeRectangularId,
    getRegionEditorRegionIdId,
    getRegionEditorRegionNameId,
    getRegionEditorUseInAnalytics,
    getRegionEditorUseInMapPresentation,
    getRegionEditorUseInPLAI,
    getRegionEditorUseTriggers,
} from './RegionEditorDialog.selectors';
import { getSelectAllTriggersAttachedToRegion } from '../../../../state-management/trigger/triggerSelectors';
import AttachedTrigger from './AttachedTrigger';
import { selectRegionById } from '../../../../state-management/region/regionSelectors';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showInfoNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy as CopyIcon } from 'react-icons/fa';

const Section = styled.div`
    margin-block-end: 10px;
`;

const AttachedTriggersList = styled(List)`
    padding: 4px 16px;
`;

const schema = object().shape({
    regionName: string().trim().required('This is required'),
    regionType: string().oneOf(['rectangular', 'circular', 'polygon']).required(),
    regionUsePlai: boolean().notRequired(),
    regionUseAnalytics: boolean().notRequired(),
    regionUseMapPresentation: boolean().notRequired(),
    regionMetadata: string(),
});

export default function RegionEditorDialog(props) {
    const { regionId, placementId, onClose } = props;

    const dispatch = useDispatch();

    const selectAttachedTriggers = useMemo(() => getSelectAllTriggersAttachedToRegion(regionId), [regionId]);

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const selectedBuildingId = useSelector(selectMapContentSelectedBuildingId);
    const selectedFloorId = useSelector(selectMapContentSelectedFloorId);
    const selectedMapId = useSelector(selectMapContentSelectedMapId);
    const triggers = useSelector(selectAttachedTriggers) ?? [];
    const region = useSelector((state) => selectRegionById(state, regionId)) ?? null;
    const placement = useSelector((state) => selectRegionPlacementById(state, placementId)) ?? null;
    const isPolygonShape = placement?.regionType === 'polygon';
    const { isLoading: isLoadingTriggers } = useTriggers({
        spaceId: selectedSpaceId,
    });

    const { register, handleSubmit, control, formState, errors, setValue, watch } = useForm({
        defaultValues: {
            regionName: region?.regionName ?? `newRegion_${uuid().substring(0, 7)}`,
            regionMetadata:
                region?.regionMetadata && region?.regionMetadata !== ''
                    ? region.regionMetadata
                    : JSON.stringify(getMetadataDefaultValues(regionMetadataOriientFormat)),
            regionType: placement?.regionType ?? 'rectangular',
            regionUseAnalytics: region?.regionUseAnalytics ?? true,
            regionUseMapPresentation: region?.regionUseMapPresentation ?? false,
            regionUsePlai: region?.regionUsePlai ?? false,
            regionUseTriggers: region?.regionUseTriggers ?? false,
        },
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const watchMapPresentation = watch('regionUseMapPresentation');
    useEffect(() => {
        if (isPolygonShape) {
            setValue('regionType', 'polygon');
        }
    }, [isPolygonShape]);

    useEffect(() => {
        if (watchMapPresentation) {
            setValue('regionUseTriggers', true);
        }
    }, [watchMapPresentation]);
    const submitForm = async (data) => {
        const {
            regionName,
            regionMetadata,
            regionType,
            regionUseAnalytics,
            regionUsePlai,
            regionUseMapPresentation,
            regionUseTriggers,
        } = data;
        let placementData;
        let updateRegionResult;
        let updatePlacementResult;

        // If we have a region ID, then we're updating an existing region
        if (regionId) {
            // If the user changed the shape type
            if (placementId && regionType !== placement?.regionType) {
                if (regionType === 'circular') {
                    // Calculate the new circular shape based on the previous rectangular shape
                    placementData = {
                        regionType,
                        center: [
                            placement.bottomLeft.x + (placement.topRight.x - placement.bottomLeft.x) / 2,
                            placement.bottomLeft.y + (placement.topRight.y - placement.bottomLeft.y) / 2,
                        ],
                        radius: (placement.topRight.x - placement.bottomLeft.x) / 2,
                    };
                } else {
                    // Calculate the new rectangular shape based on the previous circular shape
                    placementData = {
                        regionType,
                        topRight: [
                            placement.center.x + placement.radius,
                            placement.center.y + placement.radius,
                        ],
                        bottomLeft: [
                            placement.center.x - placement.radius,
                            placement.center.y - placement.radius,
                        ],
                    };
                }
            }

            updateRegionResult = await dispatch(
                updateRegion({
                    regionId,
                    regionData: {
                        regionName,
                        regionMetadata,
                        regionUseAnalytics,
                        regionUsePlai,
                        regionUseMapPresentation,
                        regionUseTriggers,
                    },
                })
            );

            if (placementId && placementData) {
                updatePlacementResult = await dispatch(
                    updateRegionInternalPlacement({
                        buildingId: selectedBuildingId,
                        floorId: selectedFloorId,
                        mapId: selectedMapId,
                        regionId,
                        placementData,
                    })
                );
            }

            if (
                isFulfilled(updateRegionResult) &&
                (!updatePlacementResult || isFulfilled(updatePlacementResult))
            ) {
                dispatch(showSuccessNotification(`Region updated successfully.`));
            } else {
                dispatch(showErrorNotification(`Failed to update region`));
            }
        } else {
            // If we don't have a region ID, we're creating a new region
            const { topRight, bottomLeft, center, radius } = placement;

            const createRegionResult = await dispatch(
                createRegion({
                    regionData: {
                        regionName,
                        regionMetadata,
                        regionUseAnalytics,
                        regionUsePlai,
                        regionUseMapPresentation,
                    },
                    spaceId: selectedSpaceId,
                })
            );

            if (isFulfilled(createRegionResult)) {
                const { regionId: newRegionId } = await unwrapResult(createRegionResult);

                const createPlacementResult = await dispatch(
                    createRegionInternalPlacement({
                        mapId: selectedMapId,
                        regionId: newRegionId,
                        placementData:
                            regionType === 'rectangular'
                                ? { regionType, topRight, bottomLeft }
                                : { regionType, radius, center },
                    })
                );

                if (isFulfilled(createPlacementResult)) {
                    dispatch(showSuccessNotification(`Region created successfully.`));
                } else {
                    dispatch(showErrorNotification(`Failed to create region.`));
                }
            } else {
                dispatch(showErrorNotification(`Failed to create region.`));
            }
        }

        onClose();
    };

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth={'md'}>
            <DialogTitle>Region Editor</DialogTitle>

            <form onSubmit={handleSubmit(submitForm)}>
                <DialogContent>
                    <Grid container alignItems={'center'} component={Section} spacing={5}>
                        <Grid item>
                            <TextField
                                id={getRegionEditorRegionIdId()}
                                label={'ID'}
                                value={region?.regionId}
                                disabled
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CopyToClipboard
                                                text={region?.regionId}
                                                onCopy={() =>
                                                    dispatch(showInfoNotification(`Region ID copied`))
                                                }
                                            >
                                                <IconButton onClick={(e) => e.stopPropagation()}>
                                                    <CopyIcon size={20} />
                                                </IconButton>
                                            </CopyToClipboard>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                id={getRegionEditorRegionNameId()}
                                name={'regionName'}
                                label={'Name'}
                                inputRef={register}
                                defaultValue={region?.regionName}
                                error={!!errors?.regionName}
                                helperText={errors?.regionName?.message}
                            />
                        </Grid>
                    </Grid>

                    <>
                        <Grid container alignItems={'center'} component={Section} spacing={2}>
                            <Grid item>
                                <Typography variant={'subtitle1'}>Shape Type:</Typography>
                            </Grid>

                            <Grid item>
                                <Tooltip
                                    content={"Please select a map to change a placement's shape"}
                                    enabled={!selectedMapId}
                                >
                                    <Controller
                                        name={'regionType'}
                                        control={control}
                                        as={
                                            <RadioGroup aria-label={'shape type'} name={'regionType'} row>
                                                <FormControlLabel
                                                    id={getRegionEditorPlacementShapeRectangularId()}
                                                    value={'rectangular'}
                                                    control={<Radio />}
                                                    label={<Label>Rectangular</Label>}
                                                    disabled={!selectedMapId || isPolygonShape}
                                                />

                                                <FormControlLabel
                                                    id={getRegionEditorPlacementShapeCircularId()}
                                                    value={'circular'}
                                                    control={<Radio />}
                                                    label={<Label>Circular</Label>}
                                                    disabled={!selectedMapId || isPolygonShape}
                                                />
                                                {isPolygonShape && (
                                                    <FormControlLabel
                                                        id={getRegionEditorPlacementShapeCircularId()}
                                                        value={'polygon'}
                                                        control={<Radio />}
                                                        label={<Label>Polygon</Label>}
                                                        disabled={!selectedMapId || isPolygonShape}
                                                    />
                                                )}
                                            </RadioGroup>
                                        }
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>

                        <Grid container alignItems={'center'} component={Section} spacing={2}>
                            <Grid style={{ width: 110 }} item>
                                <Typography variant={'subtitle1'}>Use For:</Typography>
                            </Grid>
                            <Controller
                                name={'regionUseMapPresentation'}
                                control={control}
                                render={({ onChange, value }) => (
                                    <FormControlLabel
                                        id={getRegionEditorUseInMapPresentation()}
                                        label={'Map Presentation'}
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={(e) => onChange(e.target.checked)}
                                            />
                                        }
                                        disabled={!selectedMapId}
                                    />
                                )}
                            />
                            <Controller
                                name={'regionUseAnalytics'}
                                control={control}
                                render={({ onChange, value }) => (
                                    <FormControlLabel
                                        id={getRegionEditorUseInAnalytics()}
                                        label={'Analytics'}
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={(e) => onChange(e.target.checked)}
                                            />
                                        }
                                        disabled={!selectedMapId}
                                    />
                                )}
                            />
                            <Controller
                                name={'regionUsePlai'}
                                control={control}
                                render={({ onChange, value }) => (
                                    <FormControlLabel
                                        id={getRegionEditorUseInPLAI()}
                                        label={'PLAI'}
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={(e) => onChange(e.target.checked)}
                                            />
                                        }
                                        disabled={!selectedMapId}
                                    />
                                )}
                            />
                            <Controller
                                name={'regionUseTriggers'}
                                control={control}
                                render={({ onChange, value }) => (
                                    <FormControlLabel
                                        id={getRegionEditorUseTriggers()}
                                        label={'Triggers'}
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={(e) => onChange(e.target.checked)}
                                            />
                                        }
                                        disabled={!selectedMapId}
                                    />
                                )}
                            />
                        </Grid>
                    </>

                    <Divider />

                    <ExpandableSection title={'Metadata'}>
                        <Controller
                            name={'regionMetadata'}
                            control={control}
                            render={({ onChange, value }) => (
                                <MetadataEditor
                                    value={value}
                                    onChange={onChange}
                                    oriientFormat={regionMetadataOriientFormat}
                                />
                            )}
                        />
                    </ExpandableSection>

                    <Divider />

                    <ExpandableSection title={'Attached Triggers'} defaultIsExpanded>
                        <AttachedTriggersList>
                            {isLoadingTriggers
                                ? Array.from({ length: 3 }, (_, index) => (
                                      <Grid
                                          container
                                          justifyContent={'space-between'}
                                          alignItems={'center'}
                                          key={`attached-trigger-skeleton-${index}`}
                                      >
                                          <Skeleton height={15} width={230} />
                                          <Skeleton height={40} width={40} circle />
                                      </Grid>
                                  ))
                                : triggers.map(({ triggerId, triggerName }) => (
                                      <AttachedTrigger
                                          id={getRegionEditorDetachTriggerId(triggerId)}
                                          key={triggerId}
                                          regionId={regionId}
                                          triggerId={triggerId}
                                          triggerName={triggerName}
                                      />
                                  ))}
                        </AttachedTriggersList>
                    </ExpandableSection>
                </DialogContent>

                <Divider />

                <DialogActions>
                    <Button variant={'text'} onClick={onClose}>
                        Cancel
                    </Button>

                    {formState.isSubmitting ? (
                        <CircularPreloader />
                    ) : (
                        <Button type={'submit'} disabled={!formState.isDirty}>
                            Save
                        </Button>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
}

RegionEditorDialog.propTypes = {
    regionId: PropTypes.string,
    placementId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};
