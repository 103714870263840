import React, { memo, useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Portal } from '@material-ui/core';
import { MapViewerContext } from '../../MapViewerContext';
import {
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import { getMapViewerMouseCoordinatesAndZoomOverlayId } from './MapVieweMouseCoordinatesAndZoomOverlay.selectors';
import { useDispatch } from 'react-redux';
import { setAreElementsTitlesShown } from '../../../../../state-management/user-inputs/mappingSlice';

const X = 'data-x';
const Y = 'data-y';
const ZOOM = 'data-zoom';
const TITLE_TRESHOLD = 40;

const OverlayWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-block-end: 15px;
    margin-inline-start: 15px;
    pointer-events: none; // Prevent the whole wrapper from capturing pointer events
    position: absolute;
    bottom: 0;

    ::before {
        content: 'X: ' attr(${X}) ', Y: ' attr(${Y}) ', Zoom: ' attr(${ZOOM});
    }
`;

function MapViewerMouseCoordinatesAndZoomOverlay() {
    const dispatch = useDispatch();
    const { containerRef, imageWrapperRef, getCSSVariable } = useContext(MapViewerContext);

    const handleMouseMove = useCallback(
        (event) => {
            const rect = event.currentTarget.getBoundingClientRect();

            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
            const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
            const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
            const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

            let x = Number((event.clientX - rect.left) / pixelToMeter / scale + mapOffsetX).toFixed(3);
            let y = Number(
                imageNaturalHeight / pixelToMeter -
                    (event.clientY - rect.top) / pixelToMeter / scale +
                    mapOffsetY
            ).toFixed(3);

            const overlayContainer = document.getElementById(getMapViewerMouseCoordinatesAndZoomOverlayId());

            if (overlayContainer) {
                overlayContainer.setAttribute(X, x);
                overlayContainer.setAttribute(Y, y);
            }
        },
        [getCSSVariable]
    );

    const handleWheel = useCallback(() => {
        const scale = getCSSVariable(SCALE);
        const overlayContainer = document.getElementById(getMapViewerMouseCoordinatesAndZoomOverlayId());

        if (overlayContainer) {
            overlayContainer.setAttribute(ZOOM, `${Number(scale * 100).toFixed(0)}%`);
            dispatch(setAreElementsTitlesShown(Number(scale * 100) > TITLE_TRESHOLD));
        }
    }, [getCSSVariable]);

    useEffect(() => {
        const ref = containerRef.current;

        if (ref) {
            ref.addEventListener('wheel', handleWheel);

            return () => {
                ref.removeEventListener('wheel', handleWheel);
            };
        }
    }, [containerRef, handleWheel]);

    useEffect(() => {
        const ref = imageWrapperRef.current;

        if (ref) {
            ref.addEventListener('pointermove', handleMouseMove);

            return () => {
                ref.removeEventListener('pointermove', handleMouseMove);
            };
        }
    }, [imageWrapperRef, handleMouseMove]);

    const scale = getCSSVariable(SCALE);
    const dataAttributes = { [X]: 0, [Y]: 0, [ZOOM]: `${Number(scale * 100).toFixed(0)}%` };

    return (
        <Portal container={containerRef.current}>
            <OverlayWrapper id={getMapViewerMouseCoordinatesAndZoomOverlayId()} {...dataAttributes} />
        </Portal>
    );
}

export default memo(MapViewerMouseCoordinatesAndZoomOverlay);
