import React, { useEffect, useRef, useState } from 'react'
import { validate as validateUUID } from 'uuid'
import ReactJson from 'react-json-view'
import styled from 'styled-components'
import { MdOutlineDoneOutline } from "react-icons/md";
import { Controller, useFormContext } from "react-hook-form";
import { Button, CircularPreloader, DatePicker, Dialog, DialogContent, DialogTitle, Select, Switch, TextField, Tooltip } from "../../common/themed";
import {
    getFiltersBarTimeSpanDialogBuildingId,
    getFiltersBarTimeSpanDialogEndPickerId,
    getFiltersBarTimeSpanDialogReprocessing,
    getFiltersBarTimeSpanDialogSpaceId,
    getFiltersBarTimeSpanDialogStartPickerId,
} from './AnalyticsUserActivity.selectors';
import { FormControlLabel, Grid, InputAdornment, Typography } from '@material-ui/core';
import { formatDateToISO, isValidUUID } from '../../../utils/general';
import { UUIDRegex } from '../../../constants/general';
import { useDispatch } from 'react-redux';
import { showErrorNotification, showInfoNotification } from '../../../state-management/notification/notificationReducer';
import clientSettings from '../../../clientSettings';
import AnalyticsManager from '../../../api/AnalyticsManager';
import { FlexRow, FlexColumn } from '../../common/layout'
export const CustomDialog = styled(Dialog).attrs({
    maxWidth: false,
    closeIconStyle: {
        style: {
            filter: 'invert(100%)',
        },
    },
})`
    padding: 30px;
`;

export const CustomDialogTitle = styled(DialogTitle)`
    padding: 24px 16px 8px;
`;

export const CustomDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    width: 500px;
`;

const flatPickrCommonConfig = {
    placeholder: 'Select a date...',
    mode: 'single',
    enableTime: true,
};



const SessionsViewer = ({ sessions, completedSessions, isSubmitted }) => {
    if (!sessions.length) return null
    return (
        <Grid>
            <div>
                <h3>Sessions:</h3>
                <ol>
                    {sessions.map((session, index) => {
                        const isSessionComplete = completedSessions.has(session.sessionId);
                        const icon = isSessionComplete ? <MdOutlineDoneOutline color='#ff6200' />
                            : <CircularPreloader style={{ color: "#ff6200" }} size={20} />

                        return (
                            <span style={{ display: 'flex', marginBottom: 5, width: '95%' }} key={index}>
                                <li>
                                    <ReactJson name={session.sessionId} collapsed={true} src={session} />
                                </li>
                                {isSubmitted && icon}
                            </span>

                        );
                    })}
                </ol>
            </div>
        </Grid>
    );
};


export const UserActivityModal = ({ onClose, open, headerName, spaceId, handleSubmit, buildings = {}, control }) => {
    const dispatch = useDispatch();
    const [totalSessions, setTotalSessions] = useState([]);
    const [totalSessionIds, setTotalSessionIds] = useState([]);
    const [timeoutReached, setTimeoutReached] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [intervalId, setIntervalId] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [completedSessions, setCompletedSessions] = useState(new Set())
    const [selectedBuildingId, setSelectedBuildingId] = useState(null);
    const [errorBuilding, setErrorBuilding] = useState('');
    const [reprocessing, setReprocessing] = useState(false);
    const [sessionIds, setSessionIds] = useState('');
    const [errorSessionIds, setErrorSessionIds] = useState('')
    const { register, errors, reset, setValue, getValues } = useFormContext();

    const completedSessionsRef = useRef([]);
    completedSessionsRef.current = completedSessions;

    const pollForCompletedSessions = () => {
        const intervalId = setInterval(async function () {
            const nonCompletedSessions = totalSessionIds.filter(sessionId => !completedSessionsRef.current.has(sessionId))
            const { data, error, message } = await AnalyticsManager.checkForCompletedSessions(nonCompletedSessions);
            if (error) {
                setIsSubmitted(false);
                dispatch(showErrorNotification(message));
                clearTimeout(timeoutId);
                return clearInterval(intervalId);
            }
            if (data.length) {
                setCompletedSessions(prevCompSessions => new Set([...prevCompSessions, ...data]))
            }
        }, clientSettings.pollingUserActivityInterval)
        return intervalId;
    }

    const onSessionsTimeout = () => {
        const timeoutId = setTimeout(() => {
            setTimeoutReached(true);
        }, clientSettings.sessionsTimeoutDuration)
        return timeoutId;
    }

    const onSessionFinished = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
        setTimeoutReached(false);
        setIsSubmitted(false);
    }

    useEffect(() => {
        setValue('fromDate', formatDateToISO(new Date()));
        setValue('toDate', formatDateToISO(new Date()));
    }, [])

    useEffect(() => {
        const sessionsFinished = totalSessions.length === completedSessions.size;
        if (sessionsFinished) {
            clearInterval(intervalId);
            return onSessionFinished();
        }
    }, [completedSessions])

    useEffect(() => {
        return () => reset()
    }, [])

    useEffect(() => {
        if (isSubmitted) {
            const timeoutId = onSessionsTimeout();
            const intervalId = pollForCompletedSessions();
            setTimeoutId(timeoutId);
            setIntervalId(intervalId);
            return () => {
                if (intervalId) {
                    clearInterval(intervalId);
                }
            }
        }
    }, [isSubmitted])

    const onSessionsStop = () => {
        setIsSubmitted(false);
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
            setTimeoutReached(false);
        }
    }

    const formValidation = ({ buildingId }) => {
        if (!buildingId && reprocessing) {
            setErrorBuilding('Building is required');
            return false;
        }

        if (sessionIds) {
            const sessionIdsArray = sessionIds.split(',');
            const isValid = sessionIdsArray.every(isValidUUID);
            if (!isValid) {
                setErrorSessionIds('SessionIds are not in the correct format. (sessionId1, sessionId2,...)')
            }
            return false;
        }

        return true
    }

    const submitForm = async (formData) => {
        const buildingId = buildings?.[selectedBuildingId];
        const isValid = formValidation({ buildingId });
        if (!isValid) {
            return;
        }

        setErrorBuilding(null)
        setLoading(true);
        const { sessions, error, message } = await AnalyticsManager.executeSessionAnalysis({ ...formData, spaceId, buildingId, reprocessing, sessionIds: sessionIds ? sessionIds.split(',') : undefined });
        setLoading(false);
        if (error) {
            return dispatch(showErrorNotification(message));
        }
        if (!sessions.length) {
            return dispatch(showInfoNotification('There are no sessions between these dates'))
        }
        setTotalSessions(sessions);
        setTotalSessionIds(sessions.map(({ sessionId }) => sessionId))
        setIsSubmitted(true);
    }


    return (<CustomDialog open={open} onClose={onClose}>
        <CustomDialogTitle>
            {headerName}
        </CustomDialogTitle>
        <CustomDialogContent>
            <form onSubmit={handleSubmit(submitForm)}>
                <Grid container direction='column' style={{ rowGap: '10px' }}>
                    <DatePicker
                        options={{ enableTime: true, hourIncrement: 1, minuteIncrement: 1 }}
                        onChange={([timestamp]) => setValue('fromDate', formatDateToISO(timestamp))}
                        inputProps={
                            {
                                defaultValue: formatDateToISO(new Date()),
                                id: getFiltersBarTimeSpanDialogStartPickerId(),
                                name: 'fromDate',
                                variant: 'outlined',
                                InputProps: {
                                    startAdornment: <InputAdornment position="start">From:</InputAdornment>,
                                },
                                error: !!errors?.fromDate || getValues('fromDate').trim() === '',
                                helperText: errors?.fromDate?.message,
                            }
                        }
                    />

                    <DatePicker
                        options={{ enableTime: true, hourIncrement: 1, minuteIncrement: 1 }}
                        onChange={([timestamp]) => setValue('toDate', formatDateToISO(timestamp))}
                        inputProps={{
                            id: getFiltersBarTimeSpanDialogEndPickerId(),
                            name: 'toDate',
                            variant: 'outlined',
                            InputProps: {
                                startAdornment: <InputAdornment position="start">To:</InputAdornment>,
                            },
                            defaultValue: formatDateToISO(new Date()),
                            error: !!errors?.toDate || getValues('toDate').trim() === '',
                            helperText: errors?.toDate?.message,
                        }
                        }
                    />

                    <TextField
                        id={getFiltersBarTimeSpanDialogSpaceId()}
                        name='spaceId'
                        variant='outlined'
                        value={spaceId}
                        disabled={true}
                        label={'Space Id'}
                        inputRef={register({
                            required: true,
                            pattern: {
                                value: UUIDRegex,
                                message: 'Invalid UUID',
                            },

                        })}
                        error={!!errors?.spaceId || getValues('spaceId').trim() === ''}
                        helperText={errors?.spaceId?.message}
                    />

                    <FlexRow alignItems='center' justifyContent='space-between' style={{ marginBottom: 15 }} >
                        <FlexColumn style={{ width: '60%' }}>
                            <Select
                                id={getFiltersBarTimeSpanDialogBuildingId()}
                                placeholder={'Select a Building...'}
                                textFieldProps={{
                                    label: 'Building',
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                }}
                                inputRef={register({
                                    required: true,
                                })}
                                options={Object.values(buildings)}
                                getOptionLabel={(b) => b.buildingName}
                                getOptionValue={(b) => b.buildingId}
                                isClearable
                                value={buildings[selectedBuildingId]}
                                onChange={(b) => setSelectedBuildingId(b.buildingId)}
                                isDisabled={!reprocessing}

                            />
                            {errorBuilding && <Typography style={{ color: '#7c0606' }}>{errorBuilding}</Typography>}
                        </FlexColumn>

                        <Tooltip content={'If reprocessing is enabled, building must be specified.'}>
                            <FormControlLabel
                                id={getFiltersBarTimeSpanDialogReprocessing()}
                                name='reprocessing'
                                label={'Reprocessing'}
                                control={
                                    <Switch
                                        checked={reprocessing}
                                        onChange={(e) => setReprocessing(e.target.checked)}
                                    />
                                }
                            />
                        </Tooltip>

                    </FlexRow>

                    <FlexColumn>
                        <TextField
                            id={getFiltersBarTimeSpanDialogSpaceId()}
                            name='sessionIds'
                            variant='outlined'
                            value={sessionIds}
                            label={'Session Ids'}
                            placeholder='sessionId1,sessionId2,...'
                            onChange={(e) => setSessionIds(e.target.value)}
                        />
                        {errorSessionIds && <Typography style={{ color: '#7c0606' }}>{errorSessionIds}</Typography>}
                    </FlexColumn>



                    <Button disabled={loading || isSubmitted} type='submit'>
                        {loading ? <CircularPreloader style={{ color: "white" }} size={20} /> : 'Submit'}
                    </Button>
                </Grid>
            </form>
            {
                timeoutReached && <Grid>
                    <Typography>The sessions have reached their timeout. Do you want to stop? (The sessions are still running in the background, anyway.)</Typography>
                    <Button classes='contained' onClick={onSessionsStop}>Stop</Button>
                </Grid>
            }
            <SessionsViewer sessions={totalSessions} completedSessions={completedSessions} isSubmitted={isSubmitted} />
        </CustomDialogContent>
    </CustomDialog >)
}