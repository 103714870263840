import styled from 'styled-components';
import {
    DialogTitle,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    CircularPreloader,
} from '../../../common/themed';
import React, { useState } from 'react';

const FullScreenDialog = styled(Dialog)`
    margin: 3rem;
`;
const BigRedButton = styled.button`
    background: red;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    border: 2px solid black;
    box-shadow: 2px 10px 3px 2px #0000006b;
    transition: all 0.4s ease;
    cursor: pointer;
    &:hover {
        transform: scale(1.2);
    }
    &:active {
        transform: scale(0.9);
    }
`;

const Content = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
`;
const Header = styled.h1`
    font-size: 40px;
`;
export default function RegenerateMapDialog(props) {
    const [status, setStatus] = useState('idle');

    const onClick = async () => {
        setStatus('loading');
        const result = await props.onRegenerate();
        setStatus(result ? 'success' : 'error');
    };
    return (
        <FullScreenDialog open onClose={props.onClose} fullScreen maxWidth={'xl'}>
            <DialogTitle>Warning</DialogTitle>
            <Content>
                {status === 'loading' ? (
                    <CircularPreloader />
                ) : status === 'idle' ? (
                    <>
                        <Header>Are you sure you want to REGENERATE THIS MAP???????</Header>
                        <img src="https://www.meme-arsenal.com/memes/3eb4a684c990bd7a9c4226f96be120a4.jpg" />
                        <BigRedButton onClick={onClick} />
                    </>
                ) : (
                    <>
                        <Header>{status}</Header>
                        <Button onClick={props.onClose}>close</Button>
                    </>
                )}
            </Content>
        </FullScreenDialog>
    );
}
