import { createAsyncThunk as originalCreateAsyncThunk } from '@reduxjs/toolkit';
import { selectIsLoading } from './status/statusSelectors';
import { selectUserApiKeyId, selectUserName } from './auth/authSelectors';
import uuid from 'uuid/v4';

export const getActionTypePrefix = (type) =>
    type?.replace('/pending', '')?.replace('/fulfilled', '')?.replace('/rejected', '');

export const getActionKey = (type, arg = '(null)') => {
    let argString;
    if (typeof arg === 'object' && arg !== null) {
        // Return the a string made of the values of the object, sorted based on the keys alphabetically,
        // so that the lack of order inside objects won't affect the result.
        const sortedKeys = Object.keys(arg).sort((a, b) => a.localeCompare(b));

        argString = sortedKeys.map((key) => arg[key]).join('--');
    } else {
        argString = arg;
    }

    return `${type}:${argString}`;
};

export const isPending = (action) => action.type.endsWith('/pending');
export const isFulfilled = (action) => action.type.endsWith('/fulfilled');
export const isRejected = (action) => action.type.endsWith('/rejected');
export const isFulfilledOrRejected = (action) => isFulfilled(action) || isRejected(action);

export const createAsyncThunk = (typePrefix, payloadCreator, options = {}) =>
    originalCreateAsyncThunk(typePrefix, payloadCreator, {
        ...options,
        condition: (arg, { getState }) => {
            return !selectIsLoading({ type: typePrefix, arg })(getState());
        },
    });

const senderId = uuid();

export const getWebSocketMessageDefaults = (state) => {
    const apiKeyId = selectUserApiKeyId(state);
    const username = selectUserName(state);

    return { apiKeyId, senderId, senderName: `Dashboard user ${username} (${apiKeyId})` };
};

export const isNumberString = (str) => {
    return !isNaN(parseFloat(str)) && isFinite(str);
};
