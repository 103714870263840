import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchedMapQualityData,
    fetchedRecordsCount,
} from '../../../../state-management/map-quality/mapQualityActions';
import { selectIsLoading as getSelectIsLoading } from '../../../../state-management/status/statusSelectors';
import {
    selectMapQualityData,
    selectRecordsCount,
} from '../../../../state-management/map-quality/mapQualitySelectors';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import { showErrorNotification } from '../../../../state-management/notification/notificationReducer';
import { parsedTableRecord } from '../../../map-quality/utils';
import { usePublishMapGrid } from '../../../mapping/hooks/usePublishMapGrid';
import styled from 'styled-components';
import { Button } from '../../themed';
import { convertFiltersForRequest, getTableState } from '../../themed/Table/utils';
import { isFulfilled, isRejected } from '../../../../state-management/utils';

const PublishButton = styled(Button)`
    font-size: 12px;
    max-height: 50px;
    width: 100%;
`;

export default function useMapQuality(options = {}) {
    const { asObject = false, columns } = options;
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);

    const selectIsLoading = useMemo(
        () =>
            getSelectIsLoading({
                type: fetchedMapQualityData.typePrefix,
            }),
        []
    );
    const records = useSelector(selectMapQualityData);
    const count = useSelector(selectRecordsCount);
    const isLoading = useSelector(selectIsLoading);
    const hasError = false;

    const getData = async ({ filter, paginate, sort } = {}) => {
        let storageFilters = filter;
        let storagePaginate = paginate;
        let storageSort = sort;
        if (!filter || !paginate || !sort) {
            ({
                filters: storageFilters,
                paginate: storagePaginate,
                sort: storageSort,
            } = getTableState(columns, 'mapquality').table);
            storageFilters = convertFiltersForRequest(storageFilters);
        }
        if (!isLoading) {
            const result = await dispatch(
                fetchedMapQualityData({
                    filter: storageFilters,
                    paginate: storagePaginate,
                    sort: storageSort,
                })
            );
            if (result.meta.rejectedWithValue && result.payload) {
                const errorMessage = result.payload?.message || 'Something went wrong';
                dispatch(showErrorNotification(errorMessage));
            }
            return result;
        }
    };

    useEffect(() => {
        const data = records.map((record) => {
            return parsedTableRecord(record, {
                publish: renderPublishButton({
                    mapId: record?.mapId,
                    canPublish: record.gridMetadata?.canPublish,
                }),
            });
        });
        setData(data);
    }, [records]);

    const { createPublishGrid, publishLoading } = usePublishMapGrid(getData);

    const createPublishGridHandler = async (mapId) => {
        await createPublishGrid({ mapId });
    };

    const renderPublishButton = ({ mapId, canPublish }) => {
        return (
            <PublishButton disabled={!canPublish} onClick={() => createPublishGridHandler(mapId)}>
                Publish
            </PublishButton>
        );
    };

    const getCount = (filter) => {
        dispatch(fetchedRecordsCount({ filter }));
    };

    return {
        records: asObject ? {} : data,
        setData,
        count,
        isLoading,
        hasError,
        selectedSpaceId,
        getData,
        getCount,
        publishLoading,
    };
}
