import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './buildingEntityAdapter';
import {
    createBuilding,
    deleteBuilding,
    fetchAllBuildingsInAllSpaces,
    fetchAllBuildingsInSpace,
    fetchBuildingFullData,
    updateBuilding,
    updateBuildingState,
} from './buildingActions';
import { createFloor, deleteFloor, updateFloor } from '../floor/floorActions';
import { expireSession, logout } from '../auth/authActions';
import {
    createRegionExternalPlacement,
    deleteRegionExternalPlacement,
} from '../region-placement/regionPlacementActions';

const { getInitialState, getSelectors, upsertMany, upsertOne, addOne, removeOne } = entityAdapter;

export const {
    reducer,
    actions: { setNavgraphErrors },
} = createSlice({
    name: 'buildings',
    initialState: { ...getInitialState(), navgraphErrors: {} },

    reducers: {
        setNavgraphErrors: (state, action) => {
            const { mapId, errors } = action.payload;
            if (mapId) {
                state.navgraphErrors[mapId] = errors;
            }
        },
    },
    extraReducers: {
        [fetchAllBuildingsInSpace.fulfilled]: (state, action) => {
            upsertMany(state, action.payload);
        },

        [fetchAllBuildingsInAllSpaces.fulfilled]: (state, action) => {
            upsertMany(
                state,
                action.payload.reduce((result, { buildings }) => [...result, ...buildings], [])
            );
        },

        [fetchBuildingFullData.fulfilled]: (state, action) => {
            upsertOne(state, {
                buildingId: action.payload.buildingId,
                clientBuildingId: action.payload.clientBuildingId,
                buildingName: action.payload.buildingName,
                displayName: action.payload.displayName,
                buildingToEnuRotation: action.payload.buildingToEnuRotation,
                geofenceOrigin: action.payload.geofenceOrigin,
                geofenceRadius: action.payload.geofenceRadius,
                buildingOrigin: action.payload.buildingOrigin,
                floorIds: action.payload.floors.map((f) => f.floorId),
                regionPlacementIds: action.payload?.externalRegions?.reduce(
                    (result, { placements }) => [...result, ...placements.map((p) => p.placementId)],
                    []
                ),
                spaceId: action.payload.spaceId,
            });
        },

        [createBuilding.fulfilled]: (state, action) => {
            const { floors, ...building } = action.payload;
            addOne(state, { ...building, floorIds: floors.map((f) => f.floorId) });
        },

        [updateBuilding.fulfilled]: (state, action) => {
            upsertOne(state, action.payload);
        },

        [updateBuildingState.fulfilled]: (state, action) => {
            upsertOne(state, action.payload);
        },

        [deleteBuilding.fulfilled]: (state, action) => {
            removeOne(state, action.meta.arg);
        },

        [createFloor.fulfilled]: (state, action) => {
            const { buildingId } = action.meta.arg;
            const { floors, primaryFloorId } = action.payload;
            upsertOne(state, {
                buildingId,
                primaryFloorId,
                floorIds: floors.map((f) => f.floorId),
            });
        },

        [updateFloor.fulfilled]: (state, action) => {
            const { buildingId } = action.meta.arg;
            const { primaryFloorId } = action.payload;
            upsertOne(state, { buildingId, primaryFloorId });
        },

        [deleteFloor.fulfilled]: (state, action) => {
            const { buildingId, floorId } = action.meta.arg;
            const { floorIds } = getSelectors().selectById(state, buildingId);
            upsertOne(state, { buildingId, floorIds: floorIds.filter((id) => id !== floorId) });
        },

        [createRegionExternalPlacement.fulfilled]: (state, action) => {
            const { buildingId } = action.meta.arg;
            const { placementId } = action.payload;
            const { regionPlacementIds = [] } = getSelectors().selectById(state, buildingId);
            upsertOne(state, { buildingId, regionPlacementIds: [...regionPlacementIds, placementId] });
        },

        [deleteRegionExternalPlacement.fulfilled]: (state, action) => {
            const { placementId } = action.meta.arg;
            const allBuildings = getSelectors().selectAll(state);
            upsertMany(
                state,
                allBuildings.map((b) => ({
                    buildingId: b?.buildingId,
                    regionPlacementIds: b?.regionPlacementIds?.filter((id) => id !== placementId),
                }))
            );
        },

        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
    },
});

export const selectNavgraphErrorsById = (state, buildingId) => state.buildings.navgraphErrors?.[buildingId];
