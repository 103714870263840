import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter,
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import MapContent from '../map-content';
import SessionAnalytics from '../analytics/user-activity';
import RegionOccupancy from '../analytics/region-occupancy';
import MainLayout from './MainLayout';
import Error404 from '../common/error-pages/Error404';
import {
    selectCanAccessAccountManagement,
    selectCanAccessAnalytics,
    selectCanAccessBuildings,
    selectCanAccessMonitor,
    selectCanAccessAnalyticsBuildingUsage,
    selectCanAccessAnalyticsUserActivity,
    selectCanAccessMapContent,
    selectCanEditApiKeys,
    selectCanEditSpaces,
    selectIsOmk,
    selectCanAccessMapping,
    selectIsLoggedIn,
    selectHasLoggedOut,
    selectCanAccessShiftManagement,
    selectCanAccessPlai,
    selectCanAccessInternalPlai,
    selectCanAccessMapQuality,
} from '../../state-management/auth/authSelectors';
import ApiKeysManagement from '../account-management/api-keys';
import SpacesManagement from '../account-management/spaces';
import TermsAndConditionsManagement from '../account-management/terms-and-conditions';
import BrowserIncompatibilityAlertBanner from '../alert-banners/BrowserIncompatibilityAlertBanner';
import Buildings from '../buildings';
import Notification from './notification';
import Mapping from '../mapping';
import { attemptLoginSessionRehydration } from '../../state-management/auth/authActions';
import { selectIsLoading } from '../../state-management/status/statusSelectors';
import LoadingScreen from './loading-screen';
import Monitor from '../monitor';
import ShiftManagement from '../shift-management';
import BuildingsManagement from '../account-management/buildings';
import ProductLocations from '../productLocations';
import MapQuality from '../map-quality';
import settings from '../../clientSettings';
import { BannerOverlay } from '../mapping/overlays/BannerOverlay';
import { AnalyticsSettings } from '../analytics/settings';

const { enableProductLocations } = settings;

const ProtectedRoute = ({ isAllowed, ...otherProps }) => isAllowed && <Route {...otherProps} />;

const LayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

export default function Router() {
    const dispatch = useDispatch();

    const selectIsRehydratingLoginSession = useMemo(
        () => selectIsLoading(attemptLoginSessionRehydration.typePrefix),
        []
    );

    const isRehydratingLoginSession = useSelector(selectIsRehydratingLoginSession);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const hasLoggedOut = useSelector(selectHasLoggedOut);
    const canAccessMapContent = useSelector(selectCanAccessMapContent);
    const canAccessBuildings = useSelector(selectCanAccessBuildings);
    const canAccessMonitor = useSelector(selectCanAccessMonitor);
    const canAccessMapping = useSelector(selectCanAccessMapping);
    const canAccessAccountManagement = useSelector(selectCanAccessAccountManagement);
    const canEditApiKeys = useSelector(selectCanEditApiKeys);
    const canEditSpaces = useSelector(selectCanEditSpaces);
    const canAccessAnalytics = useSelector(selectCanAccessAnalytics);
    const canAccessAnalyticsUserActivity = useSelector(selectCanAccessAnalyticsUserActivity);
    const canAccessAnalyticsBuildingUsage = useSelector(selectCanAccessAnalyticsBuildingUsage);
    const canAccessShiftManagement = useSelector(selectCanAccessShiftManagement);
    const canAccessPlai = useSelector(selectCanAccessPlai);
    const canAccessInternalPlai = useSelector(selectCanAccessInternalPlai);
    const canAccessMapQuality = useSelector(selectCanAccessMapQuality);

    const isOmk = useSelector(selectIsOmk);

    useEffect(() => {
        if (!isLoggedIn && !hasLoggedOut) {
            dispatch(attemptLoginSessionRehydration());
        }
    }, [dispatch, hasLoggedOut, isLoggedIn]);

    if (isRehydratingLoginSession) {
        return <LoadingScreen />;
    }

    return (
        <BrowserRouter>
            <LayoutWrapper>
                <BrowserIncompatibilityAlertBanner />

                <MainLayout>
                    <BannerOverlay />
                    <Switch>
                        <Route exact path={'/'} render={() => <Redirect to={'/buildings'} />} />

                        <ProtectedRoute isAllowed={canAccessBuildings} path={'/buildings'}>
                            <Buildings />
                        </ProtectedRoute>

                        <ProtectedRoute isAllowed={canAccessBuildings} path={'/maps'}>
                            <Redirect to={'/buildings'} push />
                        </ProtectedRoute>

                        <ProtectedRoute isAllowed={canAccessMapContent} path={'/map-content'}>
                            <MapContent />
                        </ProtectedRoute>

                        <ProtectedRoute isAllowed={canAccessMapContent} path={'/triggers-and-regions'}>
                            <Redirect to={'/map-content'} push />
                        </ProtectedRoute>

                        <ProtectedRoute isAllowed={canAccessMonitor} path={'/monitor'}>
                            <Monitor />
                            {/*<MonitorMainContainer />*/}
                        </ProtectedRoute>

                        <ProtectedRoute isAllowed={canAccessMapping} path={'/mapping-center/mapping'}>
                            <Mapping />
                        </ProtectedRoute>

                        <ProtectedRoute
                            isAllowed={canAccessAnalyticsUserActivity}
                            path={'/analytics/user-activity'}
                        >
                            <SessionAnalytics />
                        </ProtectedRoute>

                        <ProtectedRoute
                            isAllowed={canAccessAnalyticsUserActivity}
                            path={'/analytics/settings'}
                        >
                            <AnalyticsSettings />
                        </ProtectedRoute>
                        {/* <ProtectedRoute
                            isAllowed={canAccessAnalyticsBuildingUsage}
                            path={'/analytics/building-usage'}
                        >
                            <RegionOccupancy />
                        </ProtectedRoute> */}

                        <ProtectedRoute
                            isAllowed={canAccessInternalPlai && enableProductLocations}
                            path={'/analytics/internal-plai'}
                        >
                            <ProductLocations type="internal" />
                        </ProtectedRoute>

                        <ProtectedRoute
                            isAllowed={canAccessPlai && enableProductLocations}
                            path={'/analytics/external-plai'}
                        >
                            <ProductLocations type="external" />
                        </ProtectedRoute>

                        <ProtectedRoute isAllowed={canAccessAnalytics} path={'/analytics'}>
                            <Redirect to={'/analytics/user-activity'} push />
                        </ProtectedRoute>

                        <ProtectedRoute isAllowed={canEditApiKeys} path={'/account-management/api-keys'}>
                            <ApiKeysManagement />
                        </ProtectedRoute>

                        <ProtectedRoute isAllowed={canEditSpaces} path={'/account-management/spaces'}>
                            <SpacesManagement />
                        </ProtectedRoute>

                        <ProtectedRoute isAllowed={canEditSpaces} path={'/account-management/buildings'}>
                            <BuildingsManagement />
                        </ProtectedRoute>

                        <ProtectedRoute isAllowed={isOmk} path={'/account-management/terms-and-conditions'}>
                            <TermsAndConditionsManagement />
                        </ProtectedRoute>

                        <ProtectedRoute isAllowed={canAccessAccountManagement} path={'/account-management'}>
                            <Redirect to={'/account-management/api-keys'} push />
                        </ProtectedRoute>

                        <ProtectedRoute
                            isAllowed={canAccessShiftManagement}
                            path={'/mapping-center/shift-management'}
                        >
                            <ShiftManagement />
                        </ProtectedRoute>

                        <ProtectedRoute isAllowed={canAccessMapQuality} path={'/mapping-center/map-quality'}>
                            <MapQuality />
                        </ProtectedRoute>

                        {/* When none of the above match, this route will be rendered */}
                        <Route component={Error404} />
                    </Switch>
                </MainLayout>

                <Notification />
            </LayoutWrapper>
        </BrowserRouter>
    );
}
