// @ts-nocheck
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { selectHasError, selectIsLoading } from '../../../../state-management/status/statusSelectors';
import {
    getSelectAreasOfInterestInMap,
    getSelectElementsInMap,
    getSelectExitRegionsInMap,
} from '../../../../state-management/map/mapSelectors';
import {
    fetchAllAreasOfInterestInMap,
    fetchAreaOfInterestAttachments,
} from '../../../../state-management/mapping/area-of-interest/areaOfInterestActions';
import { selectAreaOfInterestById } from '../../../../state-management/mapping/area-of-interest/areaOfInterestSelectors';
import {
    fetchAllElementsInMap,
    fetchElementAttachments,
} from '../../../../state-management/mapping/elements/elementsActions';
import { selectElementById } from '../../../../state-management/mapping/elements/elementsSelectors';
import { selectExitRegionById } from '../../../../state-management/mapping/exitRegion/exitRegionSelectors';
import { fetchAllExitRegionsInMap } from '../../../../state-management/mapping/exitRegion/exitRegionsActions';

/**
 * Hook that fetches areas of interest to the Redux store and returns either all or one of them, based on the provided options.
 * @param asObject - Return all entities as an object, with the IDs as the keys.
 * @param mapId - Map ID to fetch all entities from.
 * @param areaId - Specific area ID to return.
 * @param withAttachments - Whether or not to fetch area attachments
 * @return {{isLoading: undefined, data: (undefined|*), hasError: undefined}}
 */

const ACTIONS = {
    normal: {
        getSelectAllSelector: getSelectAreasOfInterestInMap,
        getByAreaId: selectAreaOfInterestById,
        fetchDataAction: fetchAllAreasOfInterestInMap,
        fetchAttachments: fetchAreaOfInterestAttachments,
    },
    element: {
        getSelectAllSelector: getSelectElementsInMap,
        getByAreaId: selectElementById,
        fetchDataAction: fetchAllElementsInMap,
        fetchAttachments: fetchElementAttachments,
    },
};

export default function useAreasOfInterest({
    asObject = false,
    mapId,
    areaId,
    withAttachments = false,
    type = 'normal',
} = {}) {
    const dispatch = useDispatch();
    const action = ACTIONS[type];

    const actions = useMemo(
        () => [
            ...(mapId ? [{ type: action.fetchDataAction.typePrefix, arg: { mapId, type } }] : []),
            ...(mapId && areaId && withAttachments
                ? [{ type: action.fetchDataAction.typePrefix, arg: { mapId, areaId, type } }]
                : []),
        ],
        [areaId, mapId, withAttachments]
    );
    const selectIsLoadingAreas = useMemo(() => selectIsLoading(actions), [actions]);
    const selectHasErrorInAreas = useMemo(() => selectHasError(actions), [actions]);
    // const selectAreasOfInterestInMap = useMemo(() => getSelectAreasOfInterestInMap(mapId), [mapId]);
    // const selectElementsInMap = useMemo(() => getSelectElementsInMap(mapId), [mapId]);

    // const { asArray: areasArray, asObject: areasObject } = useSelector(selectAreasOfInterestInMap);
    // const { asArray: elementsArray, asObject: elementsObject } = useSelector(selectElementsInMap);
    const selectAllAreas = useMemo(() => action.getSelectAllSelector(mapId), [mapId]);
    const { asArray: areasArray, asObject: areasObject } = useSelector(selectAllAreas);
    const area = useSelector((state) => {
        return action.getByAreaId(state, areaId);
    });

    const isLoading = useSelector(selectIsLoadingAreas);
    const hasError = useSelector(selectHasErrorInAreas);

    useEffect(() => {
        // If there is a provided map ID, and we haven't fetched all of its validations yet,
        // and we are not currently fetching them, fetch them
        if (mapId && !isLoading && !hasError && (!areasArray || areasArray.some((m) => !m))) {
            dispatch(action.fetchDataAction(mapId));
        }
    }, [dispatch, isLoading, hasError, mapId, areasArray]);

    useEffect(() => {
        // If a single area's attachments are needed and they haven't been fetched yet, fetch them
        if (
            mapId &&
            areaId &&
            withAttachments &&
            !isLoading &&
            !hasError &&
            area?.hasAttachments &&
            !area?.attachments
        ) {
            dispatch(action.fetchAttachments({ mapId, areaId }));
        }
    }, [dispatch, isLoading, hasError, mapId, areaId, area, withAttachments]);

    const data = useMemo(() => {
        let data = [];
        if (areaId) {
            data = area;
        } else if (asObject) {
            data = areasObject;
        } else {
            data = areasArray;
        }
        return data;
    }, [areaId, asObject, areasArray, areasObject]);

    return {
        data,
        isLoading,
        hasError,
    };
}
