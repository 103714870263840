import MapViewerMapCroppingCreationOverlay from '../../common/map-viewer/overlays/map-cropping'
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectMappingSelectedMapId,
    setIsMapCroppingCreationOpen,
    selectMapCroppingCreationOpen,
} from '../../../state-management/user-inputs/mappingSlice';

export const MapCroppingOverlay = () => {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const isMapCreationOpen = useSelector(selectMapCroppingCreationOpen);

    return (
        isMapCreationOpen && (
            <MapViewerMapCroppingCreationOverlay
                mapId={selectedMapId}
                onCropped={() => dispatch(setIsMapCroppingCreationOpen(false))}
                onCancel={() => dispatch(setIsMapCroppingCreationOpen(false))}
                type="crop"
            />
        )
    );
};
