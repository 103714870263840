import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectAllMonitorClients } from '../../../../state-management/monitor/client/monitorClientSelectors';
import SideMenuSection from "../../../common/side-drawer/SideMenuSection";
import NoItemsMessage from "../../../common/side-drawer/NoItemsMessage";
import MonitorClientListEntry from "./MonitorClientListEntry";

const List = styled.ul`
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-block-start: 5px;
    padding: 0;
`;

export default function MonitorSideMenuClientsSection() {
    const clients = useSelector(selectAllMonitorClients);

    if (!clients) {
        return null;
    }

    return (
        <SideMenuSection title={'Clients'}>
            {clients.length === 0 ? (
                <NoItemsMessage>There are no live clients in this map.</NoItemsMessage>
            ) : (
                <List>
                    {clients.map((client) => (
                        <MonitorClientListEntry key={client?.clientId} client={client} />
                    ))}
                </List>
            )}
        </SideMenuSection>
    );
}
