import Api from './Api';
import http from '../utils/httpClient';

class MapFeaturesApi extends Api {
    async fetchAllMapFeatures(spaceId) {
        return await this.callApi((params) =>
            http.v3.get(`/space/${spaceId}/mapFeatures`, { params: { ...params } })
        );
    }

    async upsertMapFeature(spaceId, mapFeature) {
        return await this.callApi((params) =>
            http.v3.post(`/space/${spaceId}/mapFeatures`, mapFeature, { params })
        );
    }

    async deleteMapFeature(spaceId, mapId) {
        return await this.callApi((params) =>
            http.v3.delete(`/space/${spaceId}/mapFeatures/${mapId}`, { params })
        );
    }
}

export default new MapFeaturesApi();
