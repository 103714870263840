import { FETCH_REGION_OCCUPANCY_DATA_SUCCESS } from './regionOccupancyActions';

const INITIAL_STATE = {
    byTimeResolution: {},
    timeResolutions: [],
    submittedFilters: {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_REGION_OCCUPANCY_DATA_SUCCESS:
            return {
                ...state,
                startTimestamp: action.payload.startTimestamp,
                endTimestamp: action.payload.endTimestamp,
                timeZoneOffset: action.payload.timeZoneOffset,
                workingHoursOnly: action.payload.workingHoursOnly,
                byTimeResolution: action.payload.data.reduce(
                    (result, { timeResolution, regions }) => ({
                        ...result,
                        [timeResolution]: {
                            timeResolution,
                            byRegion: regions.reduce(
                                (res, { regionId, ...region }) => ({
                                    ...res,
                                    [regionId]: { regionId, ...region },
                                }),
                                {}
                            ),
                        },
                    }),
                    {}
                ),
                timeResolutions: action.payload.data.map(({ timeResolution }) => timeResolution),
                submittedFilters: action.payload.submittedFilters,
            };

        default:
            return state;
    }
};
