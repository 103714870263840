import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoSvg } from '../../../assets/oriientLogo.svg';
import { ScalePreloader } from '../../common/themed';

const LoadingScreenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    ${({fullScreen}) => fullScreen && 'height: 100%'}
`;

const Logo = styled(LogoSvg)`
    height: 100px;
    margin-block-end: 30px;
`;

export default function LoadingScreen({fullScreen= false}) {
    return (
        <LoadingScreenWrapper fullScreen>
            <Logo />
            <ScalePreloader height={60} width={13} fullHeight={false} />
        </LoadingScreenWrapper>
    );
}
