import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

export const TableHead = (props) => {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        showCheckboxHeader = false,
        columns = [],
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <MuiTableHead>
            <TableRow>
                {showCheckboxHeader && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                )}

                {columns.map(
                    (col) => (
                        <TableCell
                            key={col.id}
                            numeric={col.numeric}
                            padding={col.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === col.id ? order : false}
                        >
                            <Tooltip
                                title="Sort"
                                placement={col.numeric ? 'bottom-end' : 'bottom-start'}
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={orderBy === col.id}
                                    direction={order}
                                    onClick={createSortHandler(col.id)}
                                >
                                    {col.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ),
                    this
                )}
            </TableRow>
        </MuiTableHead>
    );
};

TableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
