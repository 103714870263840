import metadataFieldTypes from '../components/map-content/dialogs/common/metadata-editor/MetadataEditorFieldTypes';

/**
 * Gets the value of a nested key inside `object`.
 * @param object - The object to get the nested value from
 * @param propPath - The path of the keys inside the object (separated by '.')
 * @return {void|*}
 */
export const getDeepProp = (object, propPath) => {
    const [currentKeyToken, ...otherKeyTokens] = propPath.split('.');

    if (!otherKeyTokens.length) {
        return object[currentKeyToken];
    } else {
        return getDeepProp(object[currentKeyToken], otherKeyTokens.join('.'));
    }
};

/**
 * Sets a nested key inside `object` with `value`
 * @param object - The object to set the value in
 * @param value - The value to set
 * @param propPath - The path to the key of the value, separated by '.'
 */
export const setDeepProp = (object, value, propPath) => {
    const [currentKeyToken, ...otherKeyTokens] = propPath.split('.');

    if (!otherKeyTokens.length) {
        object[currentKeyToken] = value;
    } else {
        if (!object[currentKeyToken]) {
            object[currentKeyToken] = {};
        }

        setDeepProp(object[currentKeyToken], value, otherKeyTokens.join('.'));
    }
};

/**
 * Receives an Oriient format and returns an object where each key of the format hold the `defaultValue` as the value.
 * @param format - Oriient format
 * @return {*|{}}
 */
export const getMetadataDefaultValues = (format) => {
    return Object.entries(format).reduce((result, [key, { type, defaultValue, fields }]) => {
        if (type === metadataFieldTypes.CATEGORY) {
            return { ...result, ...getMetadataDefaultValues(fields) };
        }

        setDeepProp(result, defaultValue, key);
        return result;
    }, {});
};

/**
 * Receives an Oriient format and returns an array with all the format keys in it.
 * @param format
 * @return {[string, unknown]}
 */
export const getFormatKeys = (format) => {
    return Object.entries(format).reduce(
        (result, [key, value]) => [
            ...result,
            ...(value.type === metadataFieldTypes.CATEGORY ? getFormatKeys(value.fields) : [key]),
        ],
        []
    );
};

/**
 * Receives a JSON metadata and returns an array with all the metadata keys in it, including nested ones (separated by '.').
 * @param metadata - JSON metadata
 * @param currentKey
 * @return {[string, unknown]}
 */
export const getMetadataKeys = (metadata, currentKey = null) => {
    return Object.entries(metadata).reduce((result, [key, value]) => {
        const newKey = currentKey ? `${currentKey}.${key}` : key;
        return [
            ...result,
            ...(typeof value === 'object' && !Array.isArray(value) && value !== null
                ? getMetadataKeys(value, newKey)
                : [newKey]),
        ];
    }, []);
};

/**
 * Receives an Oriient format and a string-form metadata and returns whether or not the string is in the Oriient format
 * @param metadataFormat - Oriient format
 * @param stringMetadata - Stringified JSON metadata
 * @return {boolean|boolean}
 */
export const isOriientFormat = (metadataFormat, stringMetadata) => {
    try {
        const jsonMetadata = JSON.parse(stringMetadata);
        const jsonKeys = getMetadataKeys(jsonMetadata);
        const formatKeys = getFormatKeys(metadataFormat);

        return (
            jsonKeys.every((key) => formatKeys.includes(key)) &&
            formatKeys.every((key) => jsonKeys.includes(key))
        );
    } catch (e) {
        return false;
    }
};
