import { createAsyncThunk } from '../../utils';
import areasOfInterestApi from '../../../api/AreasOfInterestApi';

export const fetchAllElementsInMap = createAsyncThunk('elements/fetchAllInMap', async (mapId) => {
    return (await areasOfInterestApi.fetchAllAreasOfInterestInMap(mapId, 'element')).data;
});

export const fetchElementAttachments = createAsyncThunk(
    'elements/fetchAttachments',
    async ({ mapId, areaId }) => {
        return (await areasOfInterestApi.fetchAreaOfInterestAttachments(mapId, areaId)).data;
    }
);

export const updateElement = createAsyncThunk('elements/update', async ({ mapId, areaId, areaData }) => {
    return (await areasOfInterestApi.updateAreaOfInterest(mapId, areaId, areaData)).data;
});

export const createElement = createAsyncThunk('elements/create', async ({ mapId, areaData }) => {
    return (await areasOfInterestApi.createAreaOfInterest(mapId, areaData)).data;
});

export const deleteElement = createAsyncThunk('elements/delete', async ({ mapId, areaId }) => {
    await areasOfInterestApi.deleteAreaOfInterest(mapId, areaId, 'element');
});
