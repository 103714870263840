import { store } from '../state-management/store';
import { expireSession, renewAccessToken } from '../state-management/auth/authActions';
import { isFulfilled } from '../state-management/utils';
import { selectAccessToken, selectAccessTokenExpiry } from '../state-management/auth/authSelectors';
import { showErrorNotification } from '../state-management/notification/notificationReducer';

// How much time before the token expires are we to make the first renewal request
const initialRenewalTime = 1000 * 30; // 30 seconds

// How much interval time to retry renewal if the previous attempts failed
const retryRenewalInterval = 1000 * 10; // 10 seconds

// How many attempts to renew the token before giving up and showing a notification about imminent logout
const maxAttempts = 3;

class TokenManager {
    constructor() {
        this.accessTokenStartRenewalTimeout = null;
        this.accessTokenRenewalInterval = null;
        this.accessTokenExpirationTimeout = null;
        this.failedRenewalAttemptsCount = 0;
    }

    handleNewToken(tokenExpiry) {
        this.clear();

        const accessTokenExpiry = tokenExpiry ?? selectAccessTokenExpiry(store.getState());
        this.startAccessTokenExpirationTimeout(accessTokenExpiry);
        this.startAccessTokenRenewalInterval(accessTokenExpiry);
    }

    startAccessTokenExpirationTimeout(tokenExpiry) {
        if (process.env.NODE_ENV === 'production') {
            this.accessTokenExpirationTimeout = setTimeout(() => {
                store.dispatch(expireSession());
            }, tokenExpiry - Date.now());
        }
    }

    startAccessTokenRenewalInterval(tokenExpiry) {
        this.accessTokenStartRenewalTimeout = setTimeout(() => {
            this.accessTokenRenewalInterval = setInterval(async () => {
                const result = await store.dispatch(renewAccessToken());

                if (isFulfilled(result)) {
                    // If the renewal succeeded
                    this.handleNewToken();
                } else {
                    // If the renewal failed
                    this.failedRenewalAttemptsCount++;

                    // If we're out of attempts, display a notification and logout
                    if (this.failedRenewalAttemptsCount >= maxAttempts) {
                        store.dispatch(
                            showErrorNotification(
                                `An issue occurred while trying to renew your session. You will be logged out in ${(
                                    (tokenExpiry - Date.now()) /
                                    1000
                                ).toFixed(0)} seconds.`
                            )
                        );

                        clearInterval(this.accessTokenRenewalInterval);
                        this.accessTokenRenewalInterval = null;
                    }
                }
            }, retryRenewalInterval);
        }, tokenExpiry - initialRenewalTime - Date.now());
    }

    clear() {
        if (this.accessTokenStartRenewalTimeout) {
            clearTimeout(this.accessTokenStartRenewalTimeout);
            this.accessTokenStartRenewalTimeout = null;
        }

        if (this.accessTokenRenewalInterval) {
            clearInterval(this.accessTokenRenewalInterval);
            this.accessTokenRenewalInterval = null;
        }

        if (this.accessTokenExpirationTimeout) {
            clearTimeout(this.accessTokenExpirationTimeout);
            this.accessTokenExpirationTimeout = null;
        }

        this.failedRenewalAttemptsCount = 0;
    }
}

export default new TokenManager();
