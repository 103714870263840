import styled from 'styled-components';
import { TextField } from '../../../common/themed';

export const TimePicker = styled(TextField)`
    input {
        height: 0 !important;
        opacity: 0.7;
    }
    margin-top: 10px;
    width: 72px;
    @media (max-width: 1600px) {
        margin-top: 12px;
    }
`;
