import React, { useContext, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { MapViewerContext } from '../../MapViewerContext';
import { Portal } from '@material-ui/core';
import styled from 'styled-components';
import { getMapViewerGoogleMapsOverlayId } from './MapViewerGoogleMapsOverlay.selectors';
import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import ErrorGeneral from '../../../error-pages/ErrorGeneral';
import settings from '../../../../../clientSettings';
import {
    GMAPS_BUILDING_ORIGIN_ALT,
    GMAPS_BUILDING_ORIGIN_LAT,
    GMAPS_BUILDING_ORIGIN_LNG,
    GMAPS_BUILDING_TO_ENU_ROTATION,
    GMAPS_MAP_OPACITY,
    GMAPS_METERS_PER_PIXEL,
    IMAGE_NATURAL_HEIGHT,
    IMAGE_NATURAL_WIDTH,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
} from '../../../../../constants/mapViewerVariables';

const { googleMapsApiKey } = settings;

const libraries = ['places'];

const MapImageWrapper = styled.div`
    width: calc(
        var(${IMAGE_NATURAL_WIDTH}) / (var(${PIXEL_TO_METER}) * var(${GMAPS_METERS_PER_PIXEL})) * 1px
    );
    height: calc(
        var(${IMAGE_NATURAL_HEIGHT}) / (var(${PIXEL_TO_METER}) * var(${GMAPS_METERS_PER_PIXEL})) * 1px
    );
    transform: translate(
            calc((var(${OFFSET_X}) / var(${GMAPS_METERS_PER_PIXEL})) * 1px),
            calc(-100% - (var(${OFFSET_Y}) / var(${GMAPS_METERS_PER_PIXEL})) * 1px)
        )
        rotate(calc(var(${GMAPS_BUILDING_TO_ENU_ROTATION}) * 1deg));
    transform-origin-x: calc((var(${OFFSET_X}) / var(${GMAPS_METERS_PER_PIXEL})) * -1px);
    transform-origin-y: calc((var(${OFFSET_Y}) / var(${GMAPS_METERS_PER_PIXEL})) * 1px + 100%);
`;

const MapImage = styled.img`
    opacity: var(${GMAPS_MAP_OPACITY}, 0.5);
    width: 100%;
    height: 100%;
    transition: all 200ms ease;

    &.draggable {
        cursor: grab;
    }
`;

export default function MapViewerGoogleMapsOverlay(props) {
    const {
        mapUrl,
        center,
        buildingOrigin,
        buildingToEnuRotation = 0,
        onMapClick = () => {},
        children,
        mapOverlays,
        ...otherProps
    } = props;

    const { isLoaded, loadError } = useJsApiLoader({ googleMapsApiKey, libraries });

    const mapRef = useRef();

    const { setCSSVariable, containerRef, MapViewerOptionsRef } = useContext(MapViewerContext);

    // Calculate the number of meters per pixel in GMaps
    const calculateMetersPerPixel = () => {
        if (center && mapRef.current) {
            // https://groups.google.com/g/google-maps-js-api-v3/c/hDRO4oHVSeM/m/osOYQYXg2oUJ
            const metersPerPixel =
                (156543.03392 * Math.cos((center?.lat * Math.PI) / 180)) /
                Math.pow(2, mapRef.current.getZoom());
            setCSSVariable(GMAPS_METERS_PER_PIXEL, metersPerPixel);
        }
    };

    const handleMapLoad = (map) => {
        mapRef.current = map;
        calculateMetersPerPixel();
    };

    const handleMapClick = (clc) => {
        onMapClick(clc.latLng.lat(), clc.latLng.lng());
    };

    useEffect(() => {
        const optionsRef = MapViewerOptionsRef.current;

        optionsRef.disablePanning = true;
        optionsRef.disableZooming = true;

        return () => {
            optionsRef.disablePanning = false;
            optionsRef.disableZooming = false;
        };
    }, [MapViewerOptionsRef]);

    useEffect(() => {
        setCSSVariable(GMAPS_BUILDING_TO_ENU_ROTATION, buildingToEnuRotation ?? 0);
    }, [buildingToEnuRotation, setCSSVariable]);

    useEffect(() => {
        setCSSVariable(GMAPS_BUILDING_ORIGIN_LAT, buildingOrigin?.latitude);
        setCSSVariable(GMAPS_BUILDING_ORIGIN_LNG, buildingOrigin?.longitude);
        setCSSVariable(GMAPS_BUILDING_ORIGIN_ALT, buildingOrigin?.altitude);
    }, [buildingOrigin, setCSSVariable]);

    if (loadError) {
        return <ErrorGeneral />;
    }

    if (!isLoaded) {
        return null;
    }

    return (
        <Portal container={containerRef.current}>
            <GoogleMap
                id={getMapViewerGoogleMapsOverlayId()}
                onLoad={handleMapLoad}
                onClick={handleMapClick}
                mapContainerStyle={{ height: '100%' }}
                zoom={17}
                center={center}
                onZoomChanged={calculateMetersPerPixel}
                onCenterChanged={calculateMetersPerPixel}
                options={{
                    streetViewControl: false,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    zoomControl: false,
                }}
                {...otherProps}
            >
                {mapUrl && center && (
                    <OverlayView
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        position={{ lat: buildingOrigin?.latitude, lng: buildingOrigin?.longitude }}
                    >
                        <MapImageWrapper>
                            <MapImage alt={'map'} src={mapUrl} />
                            {mapOverlays}
                        </MapImageWrapper>
                    </OverlayView>
                )}

                {children}
            </GoogleMap>
        </Portal>
    );
}

MapViewerGoogleMapsOverlay.propTypes = {
    mapUrl: PropTypes.string.isRequired,
    center: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }).isRequired,
    buildingOrigin: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }).isRequired,
    buildingToEnuRotation: PropTypes.number,
    opacity: PropTypes.number,
    onMapClick: PropTypes.func,
};
