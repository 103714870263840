import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import useShiftManagement from '../common/hooks/data-fetching/useShiftManagement';
import ErrorGeneral from '../common/error-pages/ErrorGeneral';
import eventsManagerWs from '../../ws/events-manager/eventsManagerWs';
import {
    getMapperHelpRequestedTopic,
    getMapperHelpRequestAcknowledgedTopic,
} from '../../constants/eventTopics';
import { showErrorNotification } from '../../state-management/notification/notificationReducer';
import StickyHeadTable from '../common/themed/Table/index';
import { columns } from './tableColumns';
import { Checkbox, Skeleton } from '../common/themed';
import ExpirationStorage from '../../utils/expirationStorage';
import { Button } from '../common/themed';
import styled from 'styled-components';
import { Paper, RootRef } from '@material-ui/core';

const ActionPanelWrapper = styled(Paper)`
    display: flex;
    padding: 1rem;
    margin-bottom: 1rem;
    justify-content: space-between;
`;
const ThresholdWrapper = styled.div``;
const ThresholdContent = styled.span``;
const UpdateWrapper = styled.div``;
const LastUpdateContent = styled.small`
    margin-right: 1rem;
    color: rgba(0, 0, 0, 0.54);
`;

export default function ShiftManagement() {
    const dispatch = useDispatch();

    const targetRef = useRef();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const [selectedRows, setSelectedRows] = useState([]);
    const {
        data,
        isLoading,
        hasError,
        isFetched,
        isThreshold,
        toggleThreshold,
        getAnalytics: onRefresh,
        lastUpdate,
    } = useShiftManagement();

    const mappersHelpRequestedTopic = getMapperHelpRequestedTopic();
    const mapperHelpRequestAcknowledgedTopic = getMapperHelpRequestAcknowledgedTopic();

    const onRowSelected = (id) => {};

    const onPropertyChanged = (id, name, value) => {
        const currentValue = ExpirationStorage.getItem(`shiftManagementId:${id}`) || {};
        ExpirationStorage.setItem(`shiftManagementId:${id}`, { ...currentValue, id, [name]: value });
    };

    useLayoutEffect(() => {
        if (targetRef.current) {
            setWidth(targetRef.current.offsetWidth);
            setHeight(targetRef.current.offsetHeight);
        }
    }, []);

    useEffect(() => {
        eventsManagerWs
            .connect({
                subscribeToTopics: [mappersHelpRequestedTopic, mapperHelpRequestAcknowledgedTopic],
                reconnectOnClose: true,
            })
            .catch(() => {
                dispatch(showErrorNotification('Failed to connect to Event Manager'));
            });

        return () => {
            console.warn('DISCONNECT');
            eventsManagerWs.disconnect();
        };
    }, [dispatch]);

    if (hasError) {
        return <ErrorGeneral />;
    }

    if (isLoading && !isFetched) {
        return <Skeleton height={height} width={width} />;
    }

    return (
        <>
            <ActionPanelWrapper>
                <ThresholdWrapper>
                    <ThresholdContent>One Day Threshold</ThresholdContent>
                    <Checkbox onClick={toggleThreshold} checked={isThreshold} />
                </ThresholdWrapper>
                <UpdateWrapper>
                    <LastUpdateContent>Last update {lastUpdate.toLocaleTimeString()}</LastUpdateContent>
                    <Button onClick={onRefresh}>Refresh</Button>
                </UpdateWrapper>
            </ActionPanelWrapper>

            <RootRef rootRef={targetRef}>
                <StickyHeadTable
                    onRowSelected={onRowSelected}
                    onPropertyChanged={onPropertyChanged}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    uniqueKey="mapId"
                    data={data}
                    columns={columns}
                    disableSelecting
                    style={{ minWidth: 1600 }}
                />
            </RootRef>
        </>
    );
}
