import React from 'react';
import Bowser from 'bowser';
import AlertBanner from './AlertBanner';
import { FiAlertTriangle } from 'react-icons/fi';

const browser = Bowser.getParser(window.navigator.userAgent);
const isValidBrowser = browser.satisfies({
    desktop: {
        chrome: '>=80',
        edge: '>=79',
        firefox: '>=73',
    },
});

export default function BrowserIncompatibilityAlertBanner() {
    if (isValidBrowser) {
        return null;
    }

    return (
        <AlertBanner
            label={
                'Your browser is not supported. Please access the dashboard using the latest desktop versions of Google Chrome, Mozilla Firefox and Microsoft Edge (chromium-based).'
            }
            icon={FiAlertTriangle}
        />
    );
}
