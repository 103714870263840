import React, { useState } from 'react';
import styled from 'styled-components';
import { SpaceConfig } from './spaceConfig';
import MapFeaturesTable from './MapFeaturesTable';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export default (props) => {
    const [selectedSpace, setSelectedSpace] = useState();
    return (
        <Wrapper>
            <SpaceConfig setSelectedSpace={setSelectedSpace} selectedSpace={selectedSpace} />
            <MapFeaturesTable selectedSpace={selectedSpace} />
        </Wrapper>
    );
};
