import { createAsyncThunk } from '@reduxjs/toolkit';
import ccAPI from '../../api/CoordinatesConversion';

export const fetchAllCoordinatesConversionInMap = createAsyncThunk(
    'coordinates-conversion/fetch',
    async ({ buildingId, floorId, mapId }) => {
        return { ...(await ccAPI.fetchAllCoordinatesConversionsInMap({ buildingId, floorId })).data, mapId };
    }
);
export const saveCoordinatesConversion = createAsyncThunk(
    'coordinates-conversion/save',
    async ({ buildingId, floorId, mapId, data }) => {
        await ccAPI.saveCoordinatesConversion({ buildingId, floorId, data });
        return mapId;
    }
);

export const calculateCoordinatesConversion = createAsyncThunk(
    'coordinates-conversion/calculate',
    async ({ buildingId, floorId, mapId, data }) => {
        return (await ccAPI.calculateCoordinatesConversion({ buildingId, floorId, mapId, data })).data;
    }
);

export const updateCoordinatesConversion = createAsyncThunk(
    'coordinates-conversion/update',
    async ({ buildingId, floorId, data }) => {
        return (await ccAPI.updateCoordinatesConversion({ buildingId, floorId, data })).data;
    }
);

export const deleteCoordinatesConversion = createAsyncThunk(
    'coordinates-conversion/delete',
    async ({ buildingId, floorId, mapId }) => {
        await ccAPI.deleteCoordinatesConversion({ buildingId, floorId });
        return mapId;
    }
);
