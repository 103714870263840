import React from 'react';
import styled from 'styled-components';
import { Tabs, Tab, Grid, Typography } from '@material-ui/core';
import AnalyticsFiltersBar from './UserActivityFiltersBar';
import {
    getSessionAggregations,
    getSessionsAggregationType,
} from '../../../state-management/analytics/session-analytics/sessionAnalyticsSelectors';
import {
    AGG_TYPE_BY_CLIENT,
    AGG_TYPE_BY_BUILDING,
    calculateSessionAggregations,
    FETCH_RAW_SESSIONS_REQUEST,
} from '../../../state-management/analytics/session-analytics/sessionAnalyticsActions';
import { getIsLoading } from '../../../state-management/status/statusSelectors';
import ErrorBoundary from './ErrorBoundary';
import SessionsGroupCard from './SessionsGroupCard';
import SessionsGroupTable from './SessionsGroupTable';
import { ScalePreloader } from '../../common/themed';
import { useDispatch, useSelector } from 'react-redux';

const aggregationTypes = [AGG_TYPE_BY_CLIENT, AGG_TYPE_BY_BUILDING];

const AnalyticsWrapper = styled.div`
    height: 100%;
    flex-wrap: nowrap;
`;

const TabContentWrapper = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 10px;
    }

    & > :not(:last-child) {
        margin-bottom: 10px;
    }
`;

const AlertMessage = styled.div`
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
`;

const noRecords = (
    <AlertMessage>
        No records to display.
        <br />
        Please try choosing different filter parameters.
    </AlertMessage>
);

export default function UserActivity() {
    const dispatch = useDispatch();

    const aggregations = useSelector(getSessionAggregations);
    const aggregationType = useSelector(getSessionsAggregationType);
    const isFetching = useSelector(getIsLoading(FETCH_RAW_SESSIONS_REQUEST));

    const handleTabChange = (event, selectedTabIndex) => {
        if (aggregationType !== aggregationTypes[selectedTabIndex]) {
            dispatch(calculateSessionAggregations(aggregationTypes[selectedTabIndex]));
        }
    };

    const renderAnalyticsByClient = () =>
        aggregations.map(({ aggregationId, ipsClientId, sessionIds }) => (
            <ErrorBoundary key={aggregationId}>
                <SessionsGroupCard
                    aggregationId={aggregationId}
                    header={<Typography variant={'h6'}>Client ID: {ipsClientId}</Typography>}
                    content={
                        <SessionsGroupTable
                            sessionIds={sessionIds}
                            headers={['From', 'To', 'Duration', 'Maps']}
                            valuesFunc={(session) => [
                                session.earliestTime,
                                session.latestTime,
                                session.duration,
                                session.maps.map((m) => m.mapName).join(', '),
                            ]}
                        />
                    }
                />
            </ErrorBoundary>
        ));

    const renderAnalyticsByBuilding = () =>
        aggregations.map(({ aggregationId, buildingName, sessionIds }) => (
            <ErrorBoundary key={aggregationId}>
                <SessionsGroupCard
                    aggregationId={aggregationId}
                    header={<Typography variant={'h6'}>Building Name: {buildingName}</Typography>}
                    content={
                        <SessionsGroupTable
                            sessionIds={sessionIds}
                            headers={['From', 'To', 'Duration', 'Client ID']}
                            valuesFunc={(session) => [
                                session.earliestTime,
                                session.latestTime,
                                session.duration,
                                session.ipsClientId,
                            ]}
                        />
                    }
                />
            </ErrorBoundary>
        ));

    const renderTabContent = () => {
        if (!aggregations || aggregations.length === 0) {
            return noRecords;
        }

        switch (aggregationType) {
            case AGG_TYPE_BY_CLIENT:
                return renderAnalyticsByClient();
            case AGG_TYPE_BY_BUILDING:
                return renderAnalyticsByBuilding();
            default:
                return null;
        }
    };

    return (
        <Grid container direction={'column'} component={AnalyticsWrapper}>
            <AnalyticsFiltersBar />

            <Tabs value={aggregationTypes.indexOf(aggregationType)} onChange={handleTabChange}>
                <Tab label="By Client" />
                <Tab label="By Building" />
            </Tabs>

            {isFetching ? (
                <ScalePreloader height={65} width={10} margin={'4px'} />
            ) : (
                <TabContentWrapper>{renderTabContent()}</TabContentWrapper>
            )}
        </Grid>
    );
}
