import chartColors from '../chartColors';
import { IDLE, MOVING } from '../chartConstants';

export default data => ({
    data,
    events: {
        beforedatavalidated: event => {
            const {
                movements: {
                    total: { idle, moving },
                },
            } = event.target.data;
            const totalDuration = idle.duration + moving.duration;

            event.target.data = [
                {
                    type: 'idle',
                    typeDesc: 'Dwell time',
                    durationIdle: idle.duration,
                    durationDesc: idle.durationDesc,
                    percent: (idle.duration / totalDuration) * 100,
                    columnsConfig: {
                        fill: chartColors.getBarFill(IDLE),
                        stroke: chartColors.getBarStroke(IDLE),
                    },
                    tooltipConfig: {
                        label: { fill: chartColors.getLabelfill(IDLE) },
                        background: {
                            fill: chartColors.getTooltipFill(IDLE),
                        },
                    },
                },
                {
                    type: 'moving',
                    typeDesc: 'On-the-move time',
                    durationMoving: moving.duration,
                    durationDesc: moving.durationDesc,
                    percent: (moving.duration / totalDuration) * 100,
                    columnsConfig: {
                        fill: chartColors.getBarFill(MOVING),
                        stroke: chartColors.getBarStroke(MOVING),
                    },
                    tooltipConfig: {
                        label: { fill: chartColors.getLabelfill(MOVING) },
                        background: {
                            fill: chartColors.getTooltipFill(MOVING),
                        },
                    },
                },
            ];
        },
        datavalidated: event => {
            // event.target.feedLegend()
        },
    },
    yAxes: [
        {
            type: 'DurationAxis',
            baseUnit: 'millisecond', // All durations are in milliseconds
            durationFormatter: {
                durationFormat: "h'h' mm'm'",
            },
            min: 0, // Start at 00:00 duration
            strictMinMax: false,
            fontSize: 12,
        },
    ],
    xAxes: [
        {
            type: 'CategoryAxis',
            dataFields: {
                category: 'type',
            },
            renderer: {
                maxLabelPosition: 0, // Don't render the labels beneath the columns
                grid: {
                    disabled: true, // Don't render vertical grid lines
                },
            },
        },
    ],
    series: [
        {
            type: 'ColumnSeries',
            name: 'Dwell time',
            clustered: false,
            columns: {
                fill: chartColors.getBarFill(IDLE),
                stroke: chartColors.getBarStroke(IDLE),
                strokeOpacity: 1,
                tooltipText: '{typeDesc}\n{durationDesc}',
                width: '30%',
                column: {
                    cornerRadiusTopLeft: 10,
                    cornerRadiusTopRight: 10,
                },
            },
            dataFields: {
                valueY: 'durationIdle',
                categoryX: 'type',
            },
            tooltip: {
                getFillFromObject: false,
                configField: 'tooltipConfig',
            },
            bullets: [
                {
                    type: 'LabelBullet',
                    label: {
                        text: "{percent.formatNumber('##.00')}%",
                        fontSize: 12,
                        hideOversized: false,
                        truncate: false,
                        dy: -10,
                    },
                },
            ],
            legendSettings: {
                labelText: '{name}:',
                valueText: "{valueY.close.formatDuration('hh:mm:ss')}",
            },
        },
        {
            type: 'ColumnSeries',
            name: 'On-the-move time',
            clustered: false,
            columns: {
                fill: chartColors.getBarFill(MOVING),
                stroke: chartColors.getBarStroke(MOVING),
                strokeOpacity: 1,
                tooltipText: '{typeDesc}\n{durationDesc}',
                width: '30%',
                column: {
                    cornerRadiusTopLeft: 10,
                    cornerRadiusTopRight: 10,
                },
            },
            dataFields: {
                valueY: 'durationMoving',
                categoryX: 'type',
            },
            tooltip: {
                getFillFromObject: false,
                configField: 'tooltipConfig',
            },
            bullets: [
                {
                    type: 'LabelBullet',
                    label: {
                        text: "{percent.formatNumber('##.0')}%",
                        fontSize: 12,
                        hideOversized: false,
                        truncate: false,
                        dy: -10,
                    },
                },
            ],
            legendSettings: {
                labelText: '{name}:',
                valueText: "{valueY.close.formatDuration('hh:mm:ss')}",
            },
        },
    ],
    legend: {
        // useDefaultMarker: false,
        markers: {
            width: 15,
            height: 15,
        },
        fontSize: 12,
        marginTop: 20,
        itemContainers: { paddingTop: 5, paddingBottom: 5 },
    },
});
