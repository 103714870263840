export const columns = [
    { title: 'Space Name', field: 'spaceDesc', index: 0, fieldName: 'spaceDesc' },
    { title: 'Space ID', field: 'spaceId', index: 1, fieldName: 'spaceId' },
    { title: 'Customer Data', field: 'exportPositionData', index: 2 },
    { title: 'Region Analytics', field: 'regionAnalyticsEnabled', index: 3 },
    {
        title: 'Positions Analytics',
        field: 'positionAnalyticsEnabled',
        index: 4,
    },
];
