import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { MapViewerContext } from './../../MapViewerContext';
import { useDispatch, useSelector } from 'react-redux';

import { isFulfilled } from '../../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../../state-management/notification/notificationReducer';
import { Tooltip } from '../../../themed';
import { useImmer } from 'use-immer';
import { deleteRegion, updateRegion } from '../../../../../state-management/region/regionActions';
import useKeypress from 'react-use-keypress';
import { getSelectAllTriggerIdsAttachedToRegion } from '../../../../../state-management/trigger/triggerSelectors';
import {
    openRegionEditor,
    selectMapContentLockRegionsInPlace,
    selectMapContentSelectedBuildingId,
    selectMapContentSelectedFloorId,
    selectMapContentSelectedMapId,
} from '../../../../../state-management/user-inputs/mapContentSlice';
import {
    BOUNDARY_MAX_X,
    BOUNDARY_MAX_Y,
    BOUNDARY_MIN_X,
    BOUNDARY_MIN_Y,
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import clsx from 'clsx';
import { getPlacementId } from './MapViewerRegionsOverlay.selectors';
import RegionActions from './RegionActions';
import { selectCanEditRegions } from '../../../../../state-management/auth/authSelectors';
import { updateRegionInternalPlacement } from '../../../../../state-management/region-placement/regionPlacementActions';
import { attachTriggerToRegion } from '../../../../../state-management/trigger/triggerActions';
import PolygonDrawer from '../region-internal-polygon-placement-creation/PolygonDrawer';
import { convertToMeter, convertToMeterFromEvent, convertToPixel, getMinAndMaxPoints } from './utils';
const dragOverAnimation = keyframes`
    from {
        fill: #7a007a; // TODO color should come from theme
    }
    to {
        fill: #530053;
    }
`;

const Polygon = styled.polyline`
    fill: ${({ color }) => {
        return color;
    }}; // TODO color should come from theme
    fill-opacity: 0.4;
    stroke: #ff4e1d;
    stroke-width: calc(1.5 * (1 / var(${SCALE})));
    cursor: pointer;
    transition: all 0.4s ease;
    &.highlighted,
    &.selected {
        fill: #7a007a; // TODO color should come from theme
        stroke: #530053;
    }
    &.disabled {
        fill: rgba(0, 0, 0, 0.54);
        stroke: rgba(0, 0, 0, 0.54);
        pointer-events: none;
    }

    &.dragged-over {
        stroke: #530053;
        cursor: not-allowed;

        &:not(.allowed) {
            cursor: not-allowed;
        }

        &.allowed {
            animation: 0.3s ${dragOverAnimation} infinite alternate;
        }
    }
    &.draft {
        fill-opacity: 0.2;
        fill: #ca4d4d7d;
    }
`;

export function PolygonRegionPlacement(props) {
    const {
        region,
        placement,
        isSelected = false,
        isHighlighted = false,
        isEditable = false,
        isDraft = false,
        isDuplicating = false,
        onClick = ({ regionId, placementId }) => { },
        onContextMenu = ({ regionId, placementId }) => { },
        onPointerEnter = ({ regionId, placementId }) => { },
        onPointerLeave = ({ regionId, placementId }) => { },
        onCancel = ({ regionId, placementId }) => { },
        onSendToBack = ({ regionId, placementId }) => { },
        onDuplicate = ({ regionId, placementId }) => { },
        anchorPoint,
        setAnchorPoint,
        disabled,
        lockRegionsInPlace
    } = props;
    const { regionName, regionId, regionColor, regionMetadata } = region ?? {};
    const { placementId, points = [], center, radius } = placement ?? {};

    const dispatch = useDispatch();

    // TODO these selectors should not be here, since they limit usability of the regions overlay
    //  in other tabs than "map content", this can be fixed by having REST endpoints that can handle placements
    //  using placementId instead of building, floor and map IDs
    const buildingId = useSelector(selectMapContentSelectedBuildingId);
    const floorId = useSelector(selectMapContentSelectedFloorId);
    const mapId = useSelector(selectMapContentSelectedMapId);
    const selectAttachedTriggerIds = useMemo(
        () => getSelectAllTriggerIdsAttachedToRegion(regionId),
        [regionId]
    );

    const canEditRegions = useSelector(selectCanEditRegions);
    const triggerIds = useSelector(selectAttachedTriggerIds) ?? [];
    const { containerRef, imageRef, getCSSVariable } = useContext(MapViewerContext);
    const refRect = imageRef.current?.getBoundingClientRect();
    const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
    const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

    const [{ displayedPoints, isDragging }, setShapeState] = useImmer({
        displayedPoints: points,
        originalPoints: points,
        isDragging: false,
    });


    const [{ isDraggedOver, isAllowed }, setDragState] = useImmer({
        isDraggedOver: false,
        isAllowed: false,
    });

    const { boundingBox } = useMemo(() => {
        const boundingBox = {
            maxX: Math.max(...displayedPoints.map((point) => point.x)),
            minX: Math.min(...displayedPoints.map((point) => point.x)),
            maxY: Math.max(...displayedPoints.map((point) => point.y)),
            minY: Math.min(...displayedPoints.map((point) => point.y))
        }
        return {
            boundingBox
        }
    }, [displayedPoints])

    useKeypress(['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'], (event) => {
        const isShiftPressed = event.shiftKey;
        const nextValue = isShiftPressed ? 1 : 0.1;

        if (!isSelected || isDraft || isDuplicating || lockRegionsInPlace) return;

        switch (event.key) {
            case 'ArrowLeft':
                setShapeState((state) => {
                    state.displayedPoints = state.displayedPoints.map((p) => ({ ...p, x: p.x - nextValue }));
                });
                break;
            case 'ArrowRight':
                setShapeState((state) => {
                    state.displayedPoints = state.displayedPoints.map((p) => ({ ...p, x: p.x + nextValue }));
                });
                break;
            case 'ArrowUp':
                setShapeState((state) => {
                    state.displayedPoints = state.displayedPoints.map((p) => ({ ...p, y: p.y + nextValue }));
                });
                break;
            case 'ArrowDown':
                setShapeState((state) => {
                    state.displayedPoints = state.displayedPoints.map((p) => ({ ...p, y: p.y - nextValue }));
                });
                break;
            default:
                break;
        }
    });
    const handleSave = async () => {
        const result = await dispatch(
            updateRegionInternalPlacement({
                buildingId,
                floorId,
                mapId,
                regionId,
                placementData: {
                    regionType: 'polygon',
                    points: displayedPoints
                },
            })
        );

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`Region updated successfully`));
            setShapeState((state) => {
                state.originalPoints = state.displayedPoints
            })
            onCancel();
        } else {
            dispatch(showErrorNotification(`Failed to updated region`));
        }

        props.onUpdateRegion(boundingBox);

    };

    const handleDelete = async () => {
        const result = await dispatch(deleteRegion(regionId));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`Region has been deleted successfully`));
        } else {
            dispatch(showErrorNotification(`Failed to delete region`));
        }
    };

    const resetShape = useCallback(() => {
        setShapeState((state) => {
            state.isDragging = false;
            state.displayedPoints = state.originalPoints;
        });
    }, [setShapeState]);

    const handleDragEnter = useCallback(
        (event) => {
            if (event.dataTransfer.types.includes('oriient/trigger')) {
                const triggerId = event.dataTransfer.types
                    .find((t) => t.startsWith('oriient/trigger_id'))
                    ?.replace('oriient/trigger_id:', '');

                setDragState((state) => {
                    state.isDraggedOver = true;
                    state.isAllowed = canEditRegions && !triggerIds?.includes(triggerId); // If the region is editable, and the dragged trigger isn't attached to it yet
                });
            }
        },
        [canEditRegions, setDragState, triggerIds]
    );

    const handleDragLeave = useCallback(
        (event) => {
            if (event.dataTransfer.types.includes('oriient/trigger')) {
                setDragState((state) => {
                    state.isDraggedOver = false;
                    state.isAllowed = false;
                });
            }
        },
        [setDragState]
    );

    const handleDrop = useCallback(
        async (event) => {
            if (event.dataTransfer.types.includes('oriient/trigger')) {
                const trigger = JSON.parse(event.dataTransfer.getData('oriient/trigger'));
                const { triggerId } = trigger ?? {};

                setDragState((state) => {
                    state.isDraggedOver = false;
                    state.isAllowed = false;
                });

                if (triggerId && canEditRegions && !triggerIds?.includes(triggerId)) {
                    const result = await dispatch(attachTriggerToRegion({ triggerId, regionId }));

                    if (isFulfilled(result)) {
                        dispatch(showSuccessNotification(`Trigger was successfully attached to region.`));
                    } else {
                        dispatch(showErrorNotification(`Failed to attach trigger to region.`));
                    }
                }
            }
        },
        [dispatch, canEditRegions, regionId, setDragState, triggerIds]
    );

    useEffect(() => {
        if (!isSelected) {
            // If the placement gets updated for real, or the user selects a different region,
            // reset the region's shape
            resetShape();
        }
    }, [isSelected, resetShape]);

    useEffect(() => {
        if (anchorPoint && !isDuplicating) {
            const xCenter = (boundingBox.maxX + boundingBox.minX) / 2;
            const yCenter = (boundingBox.maxY + boundingBox.minY) / 2;
            setAnchorPoint(prevState => {
                return ({ ...prevState, x: xCenter + prevState.distanceFromCenterX, y: yCenter + prevState.distanceFromCenterY });
            })
        }
    }, [displayedPoints])


    const pointsInPixel = useMemo(() => {
        return displayedPoints.map(point => convertToPixel(point, getCSSVariable))
    }, [displayedPoints])

    if (!region || !placement) {
        return null;
    }


    return (
        <>
            {anchorPoint && <circle cx={anchorPoint.x} cy={anchorPoint.y} r="15" fill="red" />}

            <Tooltip
                component={'g'}
                wrapperProps={{ id: getPlacementId(placementId) }}
                placement={'bottom'}
                visible={!isDraft && isSelected}
                sticky
                interactive
                appendTo={containerRef.current}
                zIndex={1000} // Fix conflict with dialogs, which by default have a z-index of 1300
                content={
                    <RegionActions
                        onSave={handleSave}
                        onEdit={() => dispatch(openRegionEditor({ regionId, placementId }))}
                        onCancel={() => onCancel({ regionId, placementId })}
                        onDelete={handleDelete}
                        onSendToBack={() => onSendToBack({ regionId, placementId })}
                        anchorData={props.anchorData}
                    />
                }
            >
                {isSelected && isEditable ? (
                    <PolygonDrawer
                        points={displayedPoints}
                        setPoints={(newPoints) =>
                            setShapeState((state) => {
                                state.displayedPoints = newPoints;
                            })}
                        editMode={true}
                        onSubmitted={(newPoints) =>
                            setShapeState((state) => {
                                state.displayedPoints = newPoints;
                            })
                        }
                    />
                ) : (
                    <Tooltip
                        placement={'top'}
                        visible={!isDraft && isDraggedOver && !isAllowed}
                        sticky
                        appendTo={containerRef.current}
                        content={'This trigger is already attached to this region.'}
                        useWrapper={false}
                    >
                        <>

                            <Polygon
                                className={clsx({
                                    highlighted: !isDraft ? isHighlighted : false,
                                    selected: !isDraft ? isSelected : false,
                                    disabled,
                                    draft: isDraft,
                                })}
                                onClick={(event) => props.onRegionClicked(event, boundingBox)}
                                points={pointsInPixel.map((point) => `${point.x},${point.y}`).join(' ')}
                                onContextMenu={(e) => {
                                    e.preventDefault();
                                    onContextMenu({ regionId, placementId });
                                }}
                                onPointerEnter={() => onPointerEnter({ regionId, placementId })}
                                onPointerLeave={() => onPointerLeave({ regionId, placementId })}
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={handleDrop}
                                color={regionColor}
                            >
                            </Polygon>
                        </>


                    </Tooltip>
                )}
            </Tooltip>
        </>
    );
}
