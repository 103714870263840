import styled from 'styled-components';
import { Coordinate } from './Coordinate';
import { v4 as uuid } from 'uuid';
const OverlayWrapper = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none; // Prevent the whole SVG from capturing pointer events

    * {
        pointer-events: auto;
    }
`;

export const CoordinatesOverlay = (props) => {
    const { coordinates = [], highlightedCoordinate, selectedCoordinates } = props;

    return (
        <OverlayWrapper>
            {coordinates.map((c) => (
                <Coordinate
                    key={c.coordinateId}
                    {...c}
                    isHighlighted={highlightedCoordinate === c.coordinateId}
                    isSelected={selectedCoordinates.includes(c.coordinateId)}
                />
            ))}
        </OverlayWrapper>
    );
};
