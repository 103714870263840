import { useSelector } from 'react-redux';
import React from 'react';

import {
    selectMappingSelectedMapId,
    selectMappingAreMappersShown,
} from '../../../state-management/user-inputs/mappingSlice';
import MapViewerMappersOverlay from '../../common/map-viewer/overlays/mappers';
import useMappers from '../../common/hooks/data-fetching/useMappers';

export const MappersOverlay = () => {
    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const areMappersShown = useSelector(selectMappingAreMappersShown);

    const { data: mappers = [] } = useMappers({ mapId: selectedMapId });

    return (
        areMappersShown &&
        mappers?.length > 0 && <MapViewerMappersOverlay mappers={mappers} mapId={selectedMapId} />
    );
};
