import React, { useContext, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { MapViewerContext } from '../../MapViewerContext';
import { useDispatch, useSelector } from 'react-redux';
import {
    getMapViewerJunctionMismatchBorderId,
    getMapViewerJunctionMismatchId,
} from './MapViewerJunctionMismatchesOverlay.selectors';
import {
    getSelectIsLaneHighlighted,
    highlightJunction,
    unhighlightJunction,
} from '../../../../../state-management/user-inputs/mappingSlice';
import {
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import { getMismatchValueColor, getMismatchValueMarkerId } from '../../../../../utils/junctionMismatchUtils';

const LineBorder = styled.polyline`
    fill: none;
    stroke: #ff6200;
    stroke-width: calc(5px / var(${SCALE}));
    marker-start: url(#jm-point-border);
    marker-end: url(#jm-point-border);
`;

const Line = styled.polyline`
    fill: none;
    stroke: ${(props) => getMismatchValueColor(props.$mismatchValue)};
    stroke-width: calc(3px / var(${SCALE}));
    stroke-dasharray: calc(12px / var(${SCALE}));
    marker-start: url(#${(props) => getMismatchValueMarkerId(props.$mismatchValue)});
    marker-end: url(#${(props) => getMismatchValueMarkerId(props.$mismatchValue)});
`;

export default function JunctionMismatch(props) {
    const { junction } = props;
    const { junctionId, firstPointCoordinates, secondPointCoordinates, mismatchValue } = junction ?? {};

    const dispatch = useDispatch();

    const { getCSSVariable } = useContext(MapViewerContext);

    const selectIsJunctionHighlighted = useMemo(() => getSelectIsLaneHighlighted(junctionId), [junctionId]);

    const isHighlighted = useSelector(selectIsJunctionHighlighted);

    const polylinePoints = useMemo(() => {
        if (!firstPointCoordinates || !secondPointCoordinates) {
            return '';
        }

        const mapOffsetX = getCSSVariable(OFFSET_X);
        const mapOffsetY = getCSSVariable(OFFSET_Y);
        const pixelToMeter = getCSSVariable(PIXEL_TO_METER);
        const imageNaturalHeight = getCSSVariable(IMAGE_NATURAL_HEIGHT);

        return [firstPointCoordinates, secondPointCoordinates]
            .map((point) => {
                const x = (point.x - mapOffsetX) * pixelToMeter;
                const y = (imageNaturalHeight / pixelToMeter - (point.y - mapOffsetY)) * pixelToMeter;
                return `${x},${y}`;
            })
            .join(' ');
    }, [firstPointCoordinates, secondPointCoordinates, getCSSVariable]);

    if (!junction) {
        return null;
    }

    return (
        <>
            {isHighlighted && (
                <LineBorder id={getMapViewerJunctionMismatchBorderId(junctionId)} points={polylinePoints} />
            )}

            <Line
                id={getMapViewerJunctionMismatchId(junctionId)}
                points={polylinePoints}
                $mismatchValue={mismatchValue}
                onPointerEnter={() => dispatch(highlightJunction(junctionId))}
                onPointerLeave={() => dispatch(unhighlightJunction(junctionId))}
            />
        </>
    );
}

JunctionMismatch.propTypes = {
    junction: PropTypes.object.isRequired,
};
