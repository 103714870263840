import http from '../utils/httpClient';
import Api from './Api';

class AuthApi extends Api {
    async loginUsingCredentials(username, password) {
        return await this.callApi(() =>
            http.auth.v1.post('/login', { username, password }, { withCredentials: true })
        );
    }

    async loginUsingApiKey(apiKeyId) {
        return await this.callApi(() => http.auth.v1.post('/login', { apiKeyId }, { withCredentials: true }));
    }

    async renewAccessToken() {
        return await this.callApi(() => http.auth.v1.post('/refresh-token', {}, { withCredentials: true }));
    }

    async logout() {
        return await this.callApi(() => http.auth.v1.post('/logout', {}, { withCredentials: true }));
    }
    async verifyCAPTCHA(response) {
        return await this.callApi(() =>
            http.auth.v1.post('/verify', { response }, { withCredentials: true })
        );
    }
}

export default new AuthApi();
