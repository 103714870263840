import { createAsyncThunk } from '../utils';
import floorsApi from '../../api/FloorsApi';

export const createFloor = createAsyncThunk('floors/create', async ({ buildingId, floorData }) => {
    return (await floorsApi.createFloor(buildingId, floorData)).data;
});

export const updateFloor = createAsyncThunk('floors/update', async ({ buildingId, floorId, floorData }) => {
    return (await floorsApi.updateFloor(buildingId, floorId, floorData)).data;
});

export const deleteFloor = createAsyncThunk('floors/delete', async ({ buildingId, floorId }) => {
    return (await floorsApi.deleteFloor(buildingId, floorId)).data;
});
