import Api from './Api';
import http from '../utils/httpClient';

class AreasOfInterestApi extends Api {
    async fetchAllAreasOfInterestInMap(mapId, type) {
        return await this.callApi(() =>
            http.mapping.v1.get(`/maps/${mapId}/areas-of-interest`, { params: { type } })
        );
    }

    async fetchAreaOfInterestAttachments(mapId, areaId) {
        return await this.callApi(() =>
            http.mapping.v1.get(`/maps/${mapId}/areas-of-interest/${areaId}/attachments`)
        );
    }

    async createAreaOfInterest(mapId, areaData) {
        return await this.callApi((params) =>
            http.mapping.v1.post(`/maps/${mapId}/areas-of-interest`, areaData, { params })
        );
    }

    async updateAreaOfInterest(mapId, areaId, areaData) {
        return await this.callApi((params) =>
            http.mapping.v1.patch(`/maps/${mapId}/areas-of-interest/${areaId}`, areaData, {
                params,
            })
        );
    }

    async deleteAreaOfInterest(mapId, areaId, type) {
        return await this.callApi(({ spaceId }) =>
            http.mapping.v1.delete(`/maps/${mapId}/areas-of-interest/${areaId}`, {
                params: { spaceId, type },
            })
        );
    }
}
const areaOfInterestApi = new AreasOfInterestApi();
export default areaOfInterestApi;
