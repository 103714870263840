import { createAsyncThunk } from '../utils';
import spacesApi from '../../api/SpacesApi';

export const fetchAllSpaces = createAsyncThunk('spaces/fetchAll', async () => {
    return (await spacesApi.fetchAllSpaces()).data;
});

export const createSpace = createAsyncThunk('spaces/create', async spaceData => {
    return (await spacesApi.createSpace(spaceData)).data;
});

export const updateSpace = createAsyncThunk('spaces/update', async ({ spaceId, spaceData }) => {
    return (await spacesApi.updateSpace(spaceId, spaceData)).data;
});

export const deleteSpace = createAsyncThunk('spaces/delete', async spaceId => {
    return (await spacesApi.deleteSpace(spaceId)).data;
});

export const attachBuildingToSpace = createAsyncThunk(
    'spaces/attachToBuilding',
    async ({ spaceId, buildingId }) => {
        return (await spacesApi.attachBuildingToSpace(spaceId, buildingId)).data;
    }
);

export const detachBuildingFromSpace = createAsyncThunk(
    'spaces/detachFromBuilding',
    async ({ spaceId, buildingId }) => {
        return (await spacesApi.detachBuildingFromSpace(spaceId, buildingId)).data;
    }
);
