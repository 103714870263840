import React, { forwardRef, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { MdMessage } from "react-icons/md";
import { CgAttachment } from "react-icons/cg";
import { IconButton, RootRef, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectCanEditLanes } from '../../../state-management/auth/authSelectors';
import Tooltip from '../../common/themed/Tooltip';
import * as PropTypes from 'prop-types';
import { Badge, Checkbox, Dialog } from '../../common/themed';
import { getAreaOfInterestListEntryCreatedAt, getAreaOfInterestListEntryId, getAreaOfInterestShowInfoId, getLaneListEntryId, getLaneListEntryNameId, getLaneListEntryShowInfoId } from '../Mapping.selectors';
import {
    getSelectIsAreaOfInterestHighlighted,
    getSelectIsLaneHighlighted,
    highLightAreaOfInterest,
    highlightLane,
    selectMappingHighlightedAreaOfInterestId,
    selectMappingIsShowingEqualizedMap,
    selectMappingSelectedAreaId,
    selectMappingSelectedLaneId,
    selectMappingSelectedMapId,
    setAreAreasOfInterestActionsShown,
    setSelectedAreaId,
    setSelectedAreaIds,
    setSelectedAreaOfInterestIds,
    setSelectedElementId,
    setSelectedElementIds,
    setSelectedLineId,
    setSelectedLineOfInterestIds,
    unhighlightLane,
    unhilightAreaOfInterest,
} from '../../../state-management/user-inputs/mappingSlice';
import laneStatus from '../../../constants/laneStatuses';
import { FaImage as ImageIcon } from 'react-icons/fa';
import { getLaneColor, getLaneStatusName } from '../../../utils/laneUtils';
import { AiOutlineInfoCircle as InfoIcon } from 'react-icons/ai';
import ItemsListEntry from '../../common/side-drawer/ItemsListEntry';
import { formatDateToISO } from '../../../utils/general';
import AreaOfInterestActions from '../area-of-interest/AreaOfInterestActions';
import { FlexColumn, FlexRow } from '../../common/layout';
import LineOfInterestActions from '../line-of-interest/LineOfInterestActions';
import LoadingScreen from '../../main-layout/loading-screen';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';

const Title = styled(Typography).attrs(() => ({ variant: 'subtitle1', noWrap: true }))`
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 200px;
`;

const Subtitle = styled(Typography).attrs(() => ({ variant: 'subtitle2', noWrap: true }))`
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 200px;
    display: flex;
    align-items: center;
`;

const StatusColor = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background-color: ${(props) => props.$color};
    margin-inline-start: 5px;
    margin-inline-end: 5px;
`;

const IconWrapper = forwardRef((props, ref) => (
    <RootRef rootRef={ref}>
        <IconButton {...props} />
    </RootRef>
));

const IconButtonCustom = styled(IconButton)`
    font-size: 16px;
    padding: 0;

`

export const CustomDialog = styled(Dialog).attrs({
    maxWidth: false,
    fullScreen: true,
    closeIconStyle: {
        style: {
            filter: 'invert(100%)',
        },
    },
})`
    padding: 30px;
    z-index: 1000;
`;

export const Image = styled.img`
    object-fit: contain;
    width: 100%;
    cursor: zoom-in;
    max-height: 60vh;
    height: 100%;
    ${({ disabled }) =>
        disabled &&
        `
        cursor: not-allowed;
        pointer-events: none;
    `}
`;

const ZoomImage = styled(Image)`
    height: 90vh;
    margin: 0 auto;
    cursor: default;
    max-height: unset;
`;

const ACTIONS = {
    normal: {
        setSelectedId: setSelectedAreaId,
        setSelectedIds: setSelectedAreaOfInterestIds,
        id: 'areaId'
    },
    element: {
        setSelectedId: setSelectedElementId,
        setSelectedIds: setSelectedElementIds,
        id: 'areaId',
    },
    line: {
        setSelectedId: setSelectedLineId,
        setSelectedIds: setSelectedLineOfInterestIds,
        id: 'lineId'
    }
}

export default function AreaListEntry(props) {
    const { area, isSelected, type, onItemSelected, htmlElementId, ...otherProps } = props;
    const action = ACTIONS[type] || ACTIONS.normal;
    const { [action.id]: id, mapperName, createdAt, equalizedStatus, unequalizedStatus, comments, hasAttachments, title, attachment } = area ?? {};
    const { disabled = false } = otherProps;
    const [showAttachment, setShowAttachment] = useState(false);
    const hasOnlyAttachments = comments?.length === 0 && hasAttachments;
    const date = createdAt ? formatDateToISO(new Date(createdAt), { withSeconds: true }) : 'N/A';
    const dispatch = useDispatch();

    const highlightedId = useSelector(selectMappingHighlightedAreaOfInterestId);
    const canEditLanes = useSelector(selectCanEditLanes);
    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const isHighLighted = id === highlightedId || isSelected;
    const isShowingEqualizedMap = useSelector(selectMappingIsShowingEqualizedMap);

    const status = isShowingEqualizedMap ? equalizedStatus : unequalizedStatus;
    const hasComments = !!comments?.length;
    const showTitle = type === 'element' && title;

    const onShowAttachment = () => {
        setShowAttachment(true);
        dispatch(setAreAreasOfInterestActionsShown(false));
    }

    const closeZoomModal = () => {
        setShowAttachment(false);
        dispatch(setAreAreasOfInterestActionsShown(true));
    }

    const onDrawerDetailsClick = () => {
        dispatch(action.setSelectedId(id));
        dispatch(action.setSelectedIds([id]))
    }


    const getActionsRenderer = () => {
        if (type === 'line') {
            return <LineOfInterestActions mapId={selectedMapId} lineId={id} />
        }
        return <AreaOfInterestActions

            mapId={selectedMapId}
            areaId={id}
            type={type}
            additionalActions={
                <Tooltip content={'Show Info'} placement={'bottom'} useWrapper={false}>
                    <IconWrapper
                        id={getAreaOfInterestShowInfoId(id)}
                        aria-label={'Show Info'}
                        onClick={onDrawerDetailsClick}
                    >
                        <Badge badgeContent={hasOnlyAttachments ? <ImageIcon /> : comments?.length}>
                            <InfoIcon />
                        </Badge>
                    </IconWrapper>
                </Tooltip>
            }
        />
    }

    if (!area) {
        return null;
    }

    return (
        <>
            <CustomDialog open={showAttachment} onClose={closeZoomModal}>
                <ZoomImage src={attachment} alt={'zoom image'} />
            </CustomDialog>
            <ItemsListEntry
                id={htmlElementId}
                onPointerEnter={() => dispatch(highLightAreaOfInterest(id))}
                onPointerLeave={() => dispatch(unhilightAreaOfInterest(id))}
                content={
                    <FlexRow style={{ width: '100%' }}>
                        <Checkbox
                            disabled={disabled}
                            checked={isSelected}
                            onChange={(e) => onItemSelected(id)}
                            style={{ marginTop: '-18px' }}
                        />
                        <FlexColumn>
                            <Title id={getAreaOfInterestListEntryCreatedAt(id)}>{date}</Title>
                            <FlexRow justifyContent='space-between' style={{ columnGap: '5px' }}>
                                <span style={{ display: 'flex' }}>
                                    {showTitle && <Typography style={{ fontSize: '13px' }}>{title} |&nbsp;</Typography>}
                                    <Typography style={{ fontSize: '13px', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 110 }}>{mapperName}</Typography>
                                </span>
                                {type !== 'line' && <span style={{ display: 'flex', columnGap: '4px' }}>
                                    <IconButtonCustom disabled={!hasComments}><MdMessage color={hasComments ? '#00d300' : 'gray'} /></IconButtonCustom>
                                    <IconButtonCustom disabled={!hasAttachments} onClick={onShowAttachment}><CgAttachment color={hasAttachments ? '#00d300' : 'gray'} /></IconButtonCustom>
                                </span>}
                            </FlexRow>
                        </FlexColumn>
                    </FlexRow>
                }
                actions={getActionsRenderer()}
                isHighlighted={isHighLighted}
                enableActions={canEditLanes}
                {...otherProps}
            />
        </>
    );
}

AreaListEntry.propTypes = {
    lane: PropTypes.object,
};
