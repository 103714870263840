export const operators = [
    {
        id: 1,
        sign: '=',
        type: ['string', 'numeric', 'date'],
    },
    {
        id: 2,
        sign: '>=',
        type: ['numeric', 'date'],
    },
    {
        id: 3,
        sign: '<=',
        type: ['numeric', 'date'],
    },
    {
        id: 4,
        sign: '!=',
        type: ['numeric', 'date'],
    },
    {
        id: 5,
        sign: 'In',
        type: ['string', 'numeric', 'date'],
    },
    {
        id: 6,
        sign: 'like',
        displaySign: 'Contains',
        type: ['string'],
    },
];

export const getFilterType = (filter) => {
    switch (filter?.type) {
        case 'numeric':
            return 'number';
        case 'date':
            return 'date';
        default:
            return 'text';
    }
};

export const getLabelByFilter = (filter) => {
    switch (filter?.type) {
        case 'date':
            return '';
        default:
            return 'Value';
    }
};

export const desc = (a, b, orderBy) => {
    let aValue = a[orderBy]?.value || a[orderBy];
    let bValue = b[orderBy]?.value || b[orderBy];

    // Check if the value is a number represent by string, and convert it to integer in order to sort properly.
    aValue = !isNaN(aValue) ? +aValue : aValue;
    bValue = !isNaN(bValue) ? +bValue : bValue;

    if (bValue < aValue) {
        return -1;
    }

    if (bValue > aValue) {
        return 1;
    }

    return 0;
};

export const getFilteredColumns = (columns, filters) => {
    return columns.filter((c) => !filters.some((f) => f.column.field === c.field));
};

export const getFilteredOperators = (selectedFilter) => {
    if (!selectedFilter) return [];

    return operators.filter((o) => o.type.includes(selectedFilter.type || 'string'));
};

export const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export const getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};

export const initialState = {
    isGrouping: false,
    isFiltering: true,
    // isSearching: true,
    isShowColumns: true,
    isExporting: true,
    table: {
        columns: [],
        filters: [],
        paginate: { page: 0, pageSize: 10 },
        sort: null,
    },
};

export const menuOptions = [
    {
        label: 'Grouping',
        name: 'isGrouping',
        action: 'setGrouping',
    },
    {
        label: 'Filter',
        name: 'isFiltering',
        action: 'setFiltering',
    },
    // {
    //     label: 'Search',
    //     name: 'isSearching',
    //     action: 'setSearching',
    // },
    {
        label: 'Show Columns',
        name: 'isShowColumns',
        action: 'setShowColumns',
    },
    {
        label: 'Show export',
        name: 'isExporting',
        action: 'setExport',
    },
];

export const checkForExpandedAllColumn = (detailPanel, columns) => {
    const [firstCol] = columns;
    if (!detailPanel || firstCol?.expandedAll) return columns;
    const expandedColumn = {
        isExternal: true,
        type: 'string',
        index: -1,
        expandedAll: true,
        sorting: false,
        draggable: false,
    };
    const expandedColumns = [expandedColumn, ...columns];
    return expandedColumns;
};

export function getTableState(columns, storageKey) {
    const savedState = JSON.parse(localStorage.getItem(storageKey));
    if (!savedState) return initialState;
    savedState.table.columns = columns.map((col, index) => {
        const savedColumn = savedState.table.columns[index] || {};
        return {
            ...col,
            ...savedColumn,
        };
    });

    return savedState;
}

export function isFilterNeedCast(filters = []) {
    return filters.some((f) => f.hasOwnProperty('column'));
}

export function convertFiltersForRequest(filters) {
    if (isFilterNeedCast(filters)) {
        return filters.map((filter) => {
            return {
                key: filter.column.field,
                operator: filter.operator.sign,
                value: filter.value,
                format: filter.column.format,
            };
        });
    }
    return filters;
}
