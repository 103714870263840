export const states = {
    INTERNAL: 'Internal',
    EXTERNAL: 'External',
    EXCLUDED: 'Excluded',
    ALL_STATES: 'All states'
}

export const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const timeframeOptions = [
    '1 Day ago',
    '1 Week ago',
    '2 Weeks ago',
    '1 Month ago',
    '3 Months ago',
    '6 Months ago',
    '1 Year ago',
    'All',
];

export const productLocationOptions = [
    'Single product location',
    'Multi product locations'
]

export const buildingStates = [states.INTERNAL, states.EXTERNAL, states.EXCLUDED];
export const buildingTableSearch = [states.ALL_STATES, states.INTERNAL, states.EXTERNAL, states.EXCLUDED];
