import React, { useState } from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { DialogContent, Stepper, Step, StepLabel, Typography, Divider } from '@material-ui/core';
import { Button, CircularPreloader, Dialog, DialogActions, DialogTitle } from '../../../common/themed';
import { getCreateBuildingDialogId, getCreateBuildingFormErrorId } from './CreateBuildingDialog.selectors';
import CreateBuildingStepThree from './CreateBuildingStepThree';
import CreateBuildingStepTwo from './CreateBuildingStepTwo';
import CreateBuildingStepOne from './CreateBuildingStepOne';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import { BsBuilding as BuildingsIcon } from 'react-icons/bs';
import { FaMapMarkedAlt as MapsIcon, FaSearchLocation as LocationIcon } from 'react-icons/fa';
import { FormProvider, useForm } from 'react-hook-form';
import { createBuilding } from '../../../../state-management/building/buildingActions';
import { isFulfilled } from '../../../../state-management/utils';
import { setSelectedBuildingId } from '../../../../state-management/user-inputs/buildingsSlice';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';

const StepContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 250px;
`;

const IconWrapper = styled.div`
    background-color: #ff6200; // TODO color should come from theme
    width: 100%;
    height: 100%;
    color: white; // TODO color should come from theme
    padding: 0 25px;
    box-sizing: border-box;

    & > svg {
        width: 100%;
        height: 100%;
    }
`;

const stepTitles = ['Define the building and floors', 'Upload the maps', 'Place the building'];

export default function CreateBuildingDialog(props) {
    const { onClose } = props;

    const [currentStep, setCurrentStep] = useState(1);

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'onChange',
        defaultValues: {
            buildingName: '',
            displayName: '',
            floors: [
                {
                    floorName: `Floor 0`,
                    floorShortName: `F0`,
                    floorIndex: 0,
                    height: 0,
                    rotation: 0,
                    maps: [],
                },
            ],
            primaryFloorArrayIndex: 0,
        },
    });
    const {
        handleSubmit,
        formState: { isValid, isSubmitting },
        errors,
        clearErrors,
    } = formMethods;

    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);

    const handleBack = () => {
        clearErrors('custom');
        setCurrentStep(Math.max(currentStep - 1, 0));
    };

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const submitForm = async (data) => {
        const result = await dispatch(
            createBuilding({
                spaceId: selectedSpaceId,
                buildingData: {
                    buildingName: data.buildingName,
                    displayName: data.displayName,
                    buildingToEnuRotation: data.buildingToEnuRotation,
                    geofenceOrigin: data.geofenceOrigin,
                    geofenceRadius: data.geofenceRadius,
                    buildingOrigin: {
                        ...data.buildingOrigin,
                        altitude: 0,
                    },
                    floors: data.floors.map((f, index) => ({
                        floorName: f.floorName,
                        floorShortName: f.floorShortName,
                        floorIndex: f.floorIndex,
                        height: f.height,
                        primary: index === data.primaryFloorArrayIndex,
                        maps: f.maps.map((m) => ({
                            mapName: m.mapName,
                            pixelToMeter: m.pixelToMeter,
                            imgBase64: m.imgBase64,
                            mapOffset: m.mapOffset,
                            mapUsage: m.mapUsage,
                        })),
                    })),
                },
            })
        );

        if (isFulfilled(result)) {
            await Promise.all([
                dispatch(showSuccessNotification('Building created successfully.')),
                dispatch(setSelectedBuildingId(result?.payload?.buildingId)),
            ]);
            onClose();
        } else {
            dispatch(showErrorNotification('Failed to create building.'));
        }
    };

    const getStep = () => {
        switch (currentStep) {
            case 1:
                return {
                    form: <CreateBuildingStepOne />,
                    icon: <BuildingsIcon />,
                };
            case 2:
                return {
                    form: <CreateBuildingStepTwo />,
                    icon: <MapsIcon />,
                };
            case 3:
                return {
                    form: <CreateBuildingStepThree />,
                    icon: <LocationIcon />,
                };
            default:
                return null;
        }
    };

    const { form, icon } = getStep();

    return (
        <Dialog id={getCreateBuildingDialogId()} open fullWidth maxWidth={'lg'} onClose={onClose}>
            <DialogTitle>Create a new building</DialogTitle>

            <StepContentWrapper>
                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Stepper activeStep={currentStep - 1}>
                            {stepTitles.map((title) => (
                                <Step key={title}>
                                    <StepLabel>{title}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        <DialogContent>{form}</DialogContent>

                        <Divider />

                        <DialogActions>
                            {errors?.custom && (
                                <Typography id={getCreateBuildingFormErrorId()} color={'error'}>
                                    {errors?.custom?.message}
                                </Typography>
                            )}

                            {currentStep > 1 && (
                                <Button variant={'text'} onClick={handleBack}>
                                    Back
                                </Button>
                            )}

                            {currentStep === 3 ? (
                                isSubmitting ? (
                                    <CircularPreloader />
                                ) : (
                                    <Button type={'submit'} disabled={!isValid}>
                                        Submit
                                    </Button>
                                )
                            ) : (
                                <Button onClick={handleNext} disabled={!isValid}>
                                    Next
                                </Button>
                            )}
                        </DialogActions>
                    </form>
                </FormProvider>

                <IconWrapper>{icon}</IconWrapper>
            </StepContentWrapper>
        </Dialog>
    );
}

CreateBuildingDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
};
