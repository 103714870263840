export const getRegionEditorRegionIdId = () => `region-editor-region-id`;
export const getRegionEditorRegionNameId = () => `region-editor-region-name`;
export const getRegionEditorPlacementShapeRectangularId = () => `region-editor-placement-shape-rectangular`;
export const getRegionEditorPlacementShapeCircularId = () => `region-editor-placement-shape-circular`;
export const getRegionEditorAttachedTriggerId = (triggerId) => `region-editor-attached-trigger-${triggerId}`;
export const getRegionEditorAttachedTriggerNameId = (triggerId) =>
    `region-editor-attached-trigger-name-${triggerId}`;
export const getRegionEditorDetachTriggerId = (triggerId) => `region-editor-detach-trigger-${triggerId}`;
export const getRegionEditorUseInAnalytics = () => `region-editor-use-in-analytics`;
export const getRegionEditorUseInMapPresentation = () => `region-editor-use-in-map-presentation`;
export const getRegionEditorUseInPLAI = () => `region-editor-use-in-plai`;
export const getRegionEditorUseTriggers = () => `region-editor-use-in-triggers`;
