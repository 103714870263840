import Api from './Api';
import http from '../utils/httpClient';
import settings from '../clientSettings';

const { ipsDomainForAuthService } = settings;
const ipsDomain = ipsDomainForAuthService.replace('wss://', '').replace('ws://', '');

class UsersApi extends Api {
    async fetchAllUsers() {
        return await this.callApi(() => http.auth.v1.get(`/users`, { params: { ipsDomain } }));
    }

    async fetchAllUsersAttachedToApiKey(apiKeyId) {
        return await this.callApi(() => http.auth.v1.get(`/api-keys/${apiKeyId}/users`));
    }

    async createUser(userData) {
        return await this.callApi(() => http.auth.v1.post(`/register`, userData));
    }

    async deleteUser(userId) {
        return await this.callApi(() => http.auth.v1.delete(`/users/${userId}`));
    }
}

export default new UsersApi();
