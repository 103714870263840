export const permissions = {
    READ_SPACES: {
        name: 'READ_SPACES',
        desc: 'Read spaces',
        canBeGranted: false,
        category: 'Account Management',
        indexInCategory: 0,
    },
    EDIT_SPACES: {
        name: 'EDIT_SPACES',
        desc: 'Account Management - Spaces',
        canBeGranted: true,
        category: 'Account Management',
        indexInCategory: 1,
    },
    EDIT_API_KEYS: {
        name: 'EDIT_API_KEYS',
        desc: 'Account Management - API Keys',
        canBeGranted: true,
        category: 'Account Management',
        indexInCategory: 2,
    },
    CREATE_AM: {
        name: 'CREATE_AM',
        desc: 'Create AM user',
        canBeGranted: false,
        category: 'Account Management',
        indexInCategory: 3,
    },
    READ_BUILDINGS: {
        name: 'READ_BUILDINGS',
        desc: 'Read buildings',
        canBeGranted: false,
        category: 'Buildings',
        indexInCategory: 0,
    },
    EDIT_BUILDINGS: {
        name: 'EDIT_BUILDINGS',
        desc: 'Create and edit buildings',
        canBeGranted: true,
        category: 'Buildings',
        indexInCategory: 1,
    },
    MAPPING: {
        name: 'MAPPING',
        desc: 'Mapping buildings',
        canBeGranted: true,
        category: 'Buildings',
        indexInCategory: 2,
    },
    REGENERATE_MAPS: {
        name: 'REGENERATE_MAPS',
        desc: 'Regenerate maps',
        canBeGranted: true,
        category: 'Buildings',
        indexInCategory: 3,
    },
    POSITIONING: {
        name: 'POSITIONING',
        desc: 'Positioning on Map',
        canBeGranted: true,
        category: 'Features',
        indexInCategory: 0,
    },
    INVOKE_TRIGGERS: {
        name: 'INVOKE_TRIGGERS',
        desc: 'Invoke triggers',
        canBeGranted: true,
        category: 'Features',
        indexInCategory: 1,
    },
    MONITORING: {
        name: 'MONITORING',
        desc: 'Enable Monitoring users',
        canBeGranted: true,
        category: 'Features',
        indexInCategory: 2,
    },
    ACCESS_DASHBOARD: {
        name: 'ACCESS_DASHBOARD',
        desc: 'Access Dashboard',
        canBeGranted: true,
        category: 'Features',
        indexInCategory: 3,
    },
    READ_REGIONS: {
        name: 'READ_REGIONS',
        desc: 'Read regions',
        canBeGranted: false,
        category: 'Map Content',
        indexInCategory: 0,
    },
    EDIT_REGIONS: {
        name: 'EDIT_REGIONS',
        desc: 'Edit regions',
        canBeGranted: true,
        category: 'Map Content',
        indexInCategory: 1,
    },
    READ_TRIGGERS: {
        name: 'READ_TRIGGERS',
        desc: 'Read triggers',
        canBeGranted: false,
        category: 'Map Content',
        indexInCategory: 2,
    },
    EDIT_TRIGGERS: {
        name: 'EDIT_TRIGGERS',
        desc: 'Edit triggers',
        canBeGranted: true,
        category: 'Map Content',
        indexInCategory: 3,
    },
    READ_LOCATION_TAGS: {
        name: 'READ_LOCATION_TAGS',
        desc: 'Read location tags',
        canBeGranted: false,
        category: 'Map Content',
        indexInCategory: 4,
    },
    EDIT_LOCATION_TAGS: {
        name: 'EDIT_LOCATION_TAGS',
        desc: 'Edit location tags',
        canBeGranted: true,
        category: 'Map Content',
        indexInCategory: 5,
    },
    READ_ANALYTICS_USER_ACTIVITY: {
        name: 'READ_ANALYTICS_USER_ACTIVITY',
        desc: 'Analytics - User Activity',
        canBeGranted: true,
        category: 'Analytics',
        indexInCategory: 0,
    },
    READ_ANALYTICS_BUILDING_USAGE: {
        name: 'READ_ANALYTICS_BUILDING_USAGE',
        desc: 'Analytics - Building Usage',
        canBeGranted: true,
        category: 'Analytics',
        indexInCategory: 1,
    },
    ACCESS_INTERNAL_PLAI_SERVICE: {
        name: 'ACCESS_INTERNAL_PLAI_SERVICE',
        desc: 'Analytics - Access PLAI',
        canBeGranted: true,
        category: 'Analytics',
        indexInCategory: 2,
    },
    TRIGGER_POSITION_ANALYTICS_PROCESSING: {
        name: 'TRIGGER_POSITION_ANALYTICS_PROCESSING',
        desc: 'Access Position Analytics Processing',
        canBeGranted: true,
        category: 'Analytics',
        indexInCategory: 1,
    },
    READ_KNOWLEDGE_BASE_REAL_TIME: {
        name: 'READ_KNOWLEDGE_BASE_REAL_TIME',
        desc: 'Knowledge Base - Real-Time SDK',
        canBeGranted: true,
        category: 'Knowledge Base',
        indexInCategory: 0,
    },
    READ_KNOWLEDGE_BASE_TRACKING: {
        name: 'READ_KNOWLEDGE_BASE_TRACKING',
        desc: 'Knowledge Base - Tracking SDK',
        canBeGranted: true,
        category: 'Knowledge Base',
        indexInCategory: 1,
    },
    READ_KNOWLEDGE_BASE_MAPPING: {
        name: 'READ_KNOWLEDGE_BASE_MAPPING',
        desc: 'Knowledge Base - Mapping App',
        canBeGranted: true,
        category: 'Knowledge Base',
        indexInCategory: 2,
    },
    READ_LANES: {
        name: 'READ_LANES',
        desc: 'Read lanes',
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 0,
    },
    EDIT_LANES: {
        name: 'EDIT_LANES',
        desc: 'Edit lanes',
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 1,
    },
    READ_MAP_VALIDATIONS: {
        name: 'READ_MAP_VALIDATIONS',
        desc: 'Read map validations',
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 2,
    },
    EDIT_MAP_VALIDATIONS: {
        name: 'EDIT_MAP_VALIDATIONS',
        desc: 'Edit map validations',
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 3,
    },
    SUPERVISE_MAPPING: {
        name: 'SUPERVISE_MAPPING',
        desc: 'Supervise mapping',
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 4,
    },
    ACCESS_SHIFT_MANAGEMENT: {
        name: 'ACCESS_SHIFT_MANAGEMENT',
        desc: 'Access shift management',
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 5,
    },
    CROP_LANE: {
        name: 'CROP_LANE',
        desc: 'Delete map area',
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 6,
    },
    CONTINUOUS_MAPPING: {
        name: 'CONTINUOUS_MAPPING',
        desc: 'Enable continuous mapping',
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 7,
    },
    ACCESS_MAP_QUALITY: {
        name: 'ACCESS_MAP_QUALITY',
        desc: 'Access map quality',
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 8,
    },
    PUBLISH_GRID: {
        name: 'PUBLISH_GRID',
        desc: 'Publish map grids',
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 9,
    },
    RESET_MAP_TO_MANUAL: {
        name: 'RESET_MAP_TO_MANUAL',
        desc: "Reset building's map back to manual",
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 10,
    },
    ENABLE_MULTI_SELECT: {
        name: 'ENABLE_MULTI_SELECT',
        desc: 'Delete multiple lanes/elements/areaOfInterest/lineOfInterest',
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 11,
    },
    OUTDOOR_POSITIONING: {
        name: 'OUTDOOR_POSITIONING',
        desc: 'Outdoor positioning',
        canBeGranted: true,
        category: 'AT&T',
        indexInCategory: 0,
    },
    INDOOR_POSITIONING: {
        name: 'INDOOR_POSITIONING',
        desc: 'Indoor positioning',
        canBeGranted: true,
        category: 'AT&T',
        indexInCategory: 1,
    },
    NEXT_NAV: {
        name: 'NEXT_NAV',
        desc: 'Next nav features',
        canBeGranted: true,
        category: 'AT&T',
        indexInCategory: 2,
    },
    EDIT_USERS: {
        name: 'EDIT_USERS',
        desc: 'Create and Delete users',
        canBeGranted: true,
        category: 'Users',
        indexInCategory: 0,
    },
    READ_USERS: {
        name: 'READ_USERS',
        desc: 'Display Users',
        canBeGranted: true,
        category: 'Users',
        indexInCategory: 1,
    },
    ACCESS_TAGGING: {
        name: 'ACCESS_TAGGING',
        desc: 'Access Tagging App',
        canBeGranted: true,
        category: 'Users',
        indexInCategory: 1,
    },
    EDIT_SIGN_MARKS: {
        name: 'EDIT_SIGN_MARKS',
        desc: 'Edit Sign Marks',
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 1,
    },
    READ_SIGN_MARKS: {
        name: 'READ_SIGN_MARKS',
        desc: 'Read Sign Marks',
        canBeGranted: true,
        category: 'Mapping',
        indexInCategory: 1,
    },
};

export const permissionNames = Object.entries(permissions).reduce(
    (result, [key, { name }]) => ({
        ...result,
        [key]: name,
    }),
    {}
);

export const permissionDescriptions = Object.entries(permissions).reduce(
    (result, [key, { desc }]) => ({
        ...result,
        [key]: desc,
    }),
    {}
);

const categories = [...new Set(Object.values(permissions).map((p) => p.category))];

const orderedCategories = [
    'Features',
    'Map Content',
    'Analytics',
    'Knowledge Base',
    'Account Management',
    'Buildings',
    'Mapping',
];

export const permissionsThatCanBeGrantedByCategory = categories
    .map((category) => ({
        category,
        permissions: Object.values(permissions)
            .filter((p) => p.category === category && p.canBeGranted)
            .sort((a, b) => a.indexInCategory - b.indexInCategory),
    }))
    .sort((a, b) => orderedCategories.indexOf(a.category) - orderedCategories.indexOf(b.category));
