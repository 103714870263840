import entityAdapter from './junctionMismatchEntityAdapter';

export const {
    selectById: selectJunctionMismatchById,
    selectIds: selectJunctionMismatchIds,
    selectEntities: selectJunctionMismatchEntities,
    selectAll: selectAllJunctionMismatches,
    selectTotal: selectTotalJunctionMismatches,
} = entityAdapter.getSelectors((state) => state.mapping.junctionMismatches);

export const selectIsEqualizedFetched = (state) => state.mapping.junctionMismatches.isEqualizedFetched;

export const selectIsUnequalizedFetched = (state) => state.mapping.junctionMismatches.isUnequalizedFetched;
export const selectJunctionMismatchToggled = (state) =>
    state.mapping.junctionMismatches.junctionMismatchToggled;
