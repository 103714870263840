import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import {
    getCircularPlacementBottomResizeBulletId,
    getCircularPlacementLeftResizeBulletId,
    getCircularPlacementRightResizeBulletId,
    getCircularPlacementTopResizeBulletId,
    getPlacementId,
} from './MapViewerRegionsOverlay.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { MapViewerContext } from '../../MapViewerContext';
import { useImmer } from 'use-immer';
import { updateRegionInternalPlacement } from '../../../../../state-management/region-placement/regionPlacementActions';
import { isFulfilled } from '../../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../../state-management/notification/notificationReducer';
import { deleteRegion, updateRegion } from '../../../../../state-management/region/regionActions';
import {
    openRegionEditor,
    selectMapContentLockRegionsInPlace,
    selectMapContentSelectedBuildingId,
    selectMapContentSelectedFloorId,
    selectMapContentSelectedMapId,
} from '../../../../../state-management/user-inputs/mapContentSlice';
import {
    BOUNDARY_MAX_X,
    BOUNDARY_MAX_Y,
    BOUNDARY_MIN_X,
    BOUNDARY_MIN_Y,
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import RegionPlacementResizeBullet from './RegionPlacementResizeBullet';
import Tooltip from '../../../themed/Tooltip';
import RegionActions from './RegionActions';
import clsx from 'clsx';
import { attachTriggerToRegion } from '../../../../../state-management/trigger/triggerActions';
import { getSelectAllTriggerIdsAttachedToRegion } from '../../../../../state-management/trigger/triggerSelectors';
import { selectCanEditRegions } from '../../../../../state-management/auth/authSelectors';
import useKeypress from 'react-use-keypress';

const minRadius = 0.1;

const dragOverAnimation = keyframes`
    from {
        fill: #7a007a; // TODO color should come from theme
    }
    to {
        fill: #530053;
    }
`;

const Circle = styled.circle`
    fill: ${({ color }) => {
        return color;
    }}; // TODO color should come from theme
    fill-opacity: 0.4;
    stroke: #ff4e1d;
    stroke-width: calc(1.5 * (1 / var(${SCALE})));
    cursor: pointer;
    transition: all 0.4s ease;
    &.highlighted,
    &.selected {
        fill: #7a007a; // TODO color should come from theme
        stroke: #530053;
    }

    &.disabled {
        fill: rgba(0, 0, 0, 0.54);
        stroke: rgba(0, 0, 0, 0.54);
        pointer-events: none;
    }

    &.dragged-over {
        stroke: #530053;
        cursor: not-allowed;

        &:not(.allowed) {
            cursor: not-allowed;
        }

        &.allowed {
            animation: 0.3s ${dragOverAnimation} infinite alternate;
        }
    }
    &.draft {
        fill-opacity: 0.2;
        fill: #ca4d4d7d;
    }
`;

function CircularRegionPlacement(props) {
    const {
        region,
        placement,
        isSelected = false,
        isHighlighted = false,
        isEditable = false,
        isDraft = false,
        isDuplicating = false,
        onClick = ({ regionId, placementId }) => { },
        onContextMenu = ({ regionId, placementId }) => { },
        onPointerEnter = ({ regionId, placementId }) => { },
        onPointerLeave = ({ regionId, placementId }) => { },
        onCancel = ({ regionId, placementId }) => { },
        onSendToBack = ({ regionId, placementId }) => { },
        onDuplicate = ({ regionId, placementId }) => { },
        anchorPoint,
        setAnchorPoint,
        parsedRegionMetadata,
        lockRegionsInPlace
    } = props;
    const { regionName, regionId, regionColor } = region ?? {};
    const { placementId, center, radius } = placement ?? {};

    const dispatch = useDispatch();


    // TODO these selectors should not be here, since they limit usability of the regions overlay
    //  in other tabs than "map content", this can be fixed by having REST endpoints that can handle placements
    //  using placementId instead of building, floor and map IDs
    const buildingId = useSelector(selectMapContentSelectedBuildingId);
    const floorId = useSelector(selectMapContentSelectedFloorId);
    const mapId = useSelector(selectMapContentSelectedMapId);

    const selectAttachedTriggerIds = useMemo(
        () => getSelectAllTriggerIdsAttachedToRegion(regionId),
        [regionId]
    );

    const canEditRegions = useSelector(selectCanEditRegions);
    const triggerIds = useSelector(selectAttachedTriggerIds) ?? [];

    const { containerRef, getCSSVariable } = useContext(MapViewerContext);
    const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));

    const [{ isResizing, pointerOrigin, displayedCenter, displayedRadius, isDragging }, setShapeState] =
        useImmer({
            isResizing: { leftSide: false, rightSide: false, topSide: false, bottomSide: false },
            pointerOrigin: { x: 0, y: 0 },
            displayedCenter: { x: center?.x, y: center?.y },
            displayedRadius: radius,
            originalCenter: { x: center?.x, y: center?.y },
            originalRadius: radius,
            isDragging: false,
        });

    const [{ isDraggedOver, isAllowed }, setDragState] = useImmer({
        isDraggedOver: false,
        isAllowed: false,
    });

    const boundingBox = {
        maxX: displayedCenter.x + displayedRadius,
        maxY: displayedCenter.y + displayedRadius,
        minX: displayedCenter.x - displayedRadius,
        minY: displayedCenter.y - displayedRadius
    }

    useKeypress(['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'], (event) => {
        const isShiftPressed = event.shiftKey;
        const nextValue = isShiftPressed ? 1 : 0.1;
        if (!isSelected || isDraft || isDuplicating || lockRegionsInPlace) return;
        switch (event.key) {
            case 'ArrowLeft':
                setShapeState((state) => {
                    state.displayedCenter.x -= nextValue;
                });
                break;
            case 'ArrowRight':
                setShapeState((state) => {
                    state.displayedCenter.x += nextValue;
                });
                break;
            case 'ArrowUp':
                setShapeState((state) => {
                    state.displayedCenter.y += nextValue;
                });

                break;
            case 'ArrowDown':
                setShapeState((state) => {
                    state.displayedCenter.y -= nextValue;
                });

                break;
            default:
                break;
        }
    });
    const handleSave = async () => {
        const result = await dispatch(
            updateRegionInternalPlacement({
                buildingId,
                floorId,
                mapId,
                regionId,
                placementData: {
                    regionType: 'circular',
                    center: [displayedCenter.x, displayedCenter.y],
                    radius: displayedRadius,
                },
            })
        );

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`Region updated successfully`));
            onCancel();
        } else {
            dispatch(showErrorNotification(`Failed to updated region`));
        }

        props.onUpdateRegion(boundingBox);

    };

    const handleDelete = async () => {
        const result = await dispatch(deleteRegion(regionId));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`Region has been deleted successfully`));
        } else {
            dispatch(showErrorNotification(`Failed to delete region`));
        }
    };

    const resetShape = useCallback(() => {
        setShapeState((state) => {
            state.isResizing.leftSide = false;
            state.isResizing.rightSide = false;
            state.isResizing.topSide = false;
            state.isResizing.bottomSide = false;
            state.pointerOrigin.x = 0;
            state.pointerOrigin.y = 0;
            state.displayedCenter.x = center?.x;
            state.displayedCenter.y = center?.y;
            state.displayedRadius = radius;
            state.originalCenter.x = center?.x;
            state.originalCenter.y = center?.y;
            state.originalRadius = radius;
            state.isDragging = false;
        });
    }, [center, radius, setShapeState]);

    const { x, y, r } = useMemo(() => {
        if (!displayedCenter || !displayedRadius) {
            return { x: 0, y: 0, r: 0 };
        }

        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        return {
            x: (displayedCenter.x - mapOffsetX) * pixelToMeter,
            y: (imageNaturalHeight / pixelToMeter - (displayedCenter.y - mapOffsetY)) * pixelToMeter,
            r: displayedRadius * pixelToMeter,
        };
    }, [displayedCenter, displayedRadius, getCSSVariable]);

    const resizeHandles = [
        { id: getCircularPlacementTopResizeBulletId(placementId), x, y: y - r, cursor: 'nesw-resize' },
        { id: getCircularPlacementBottomResizeBulletId(placementId), x, y: y + r, cursor: 'nwse-resize' },
        { id: getCircularPlacementLeftResizeBulletId(placementId), x: x - r, y, cursor: 'nwse-resize' },
        { id: getCircularPlacementRightResizeBulletId(placementId), x: x + r, y, cursor: 'nesw-resize' },
    ];

    const handleDragEnter = useCallback(
        (event) => {
            if (event.dataTransfer.types.includes('oriient/trigger')) {
                const triggerId = event.dataTransfer.types
                    .find((t) => t.startsWith('oriient/trigger_id'))
                    ?.replace('oriient/trigger_id:', '');

                setDragState((state) => {
                    state.isDraggedOver = true;
                    state.isAllowed = canEditRegions && !triggerIds?.includes(triggerId); // If the region is editable, and the dragged trigger isn't attached to it yet
                });
            }
        },
        [canEditRegions, setDragState, triggerIds]
    );

    const handleDragLeave = useCallback(
        (event) => {
            if (event.dataTransfer.types.includes('oriient/trigger')) {
                setDragState((state) => {
                    state.isDraggedOver = false;
                    state.isAllowed = false;
                });
            }
        },
        [setDragState]
    );

    const handleDrop = useCallback(
        async (event) => {
            if (event.dataTransfer.types.includes('oriient/trigger')) {
                const trigger = JSON.parse(event.dataTransfer.getData('oriient/trigger'));
                const { triggerId } = trigger ?? {};

                setDragState((state) => {
                    state.isDraggedOver = false;
                    state.isAllowed = false;
                });

                if (triggerId && canEditRegions && !triggerIds?.includes(triggerId)) {
                    const result = await dispatch(attachTriggerToRegion({ triggerId, regionId }));

                    if (isFulfilled(result)) {
                        dispatch(showSuccessNotification(`Trigger was successfully attached to region.`));
                    } else {
                        dispatch(showErrorNotification(`Failed to attach trigger to region.`));
                    }
                }
            }
        },
        [dispatch, canEditRegions, regionId, setDragState, triggerIds]
    );

    const handleCirclePointerDown = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();
            const { clientX, clientY } = event;

            if (isEditable) {
                setShapeState((state) => {
                    state.isDragging = true;
                    state.pointerOrigin.x = clientX;
                    state.pointerOrigin.y = clientY;
                    state.originalCenter.x = state.displayedCenter.x;
                    state.originalCenter.y = state.displayedCenter.y;
                });
            }

            onClick({ regionId, placementId });
        },
        [isEditable, onClick, placementId, regionId, setShapeState]
    );

    const handleCirclePointerMove = useCallback(
        (event) => {
            const { clientX, clientY } = event;

            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
            const maxX = parseFloat(getCSSVariable(BOUNDARY_MAX_X));
            const minX = parseFloat(getCSSVariable(BOUNDARY_MIN_X));
            const maxY = parseFloat(getCSSVariable(BOUNDARY_MAX_Y));
            const minY = parseFloat(getCSSVariable(BOUNDARY_MIN_Y));

            const xDifference = (clientX - pointerOrigin?.x) / scale / pixelToMeter;
            const yDifference = (clientY - pointerOrigin?.y) / scale / pixelToMeter;

            setShapeState((state) => {
                // Limit the X/Y differences so that they won't make the region go outside the map image boundaries
                const limitedXDiff = Math.min(
                    Math.max(xDifference, minX - state.originalCenter.x + state.originalRadius),
                    maxX - state.originalCenter.x - state.originalRadius
                );
                const limitedYDiff = Math.min(
                    Math.max(yDifference, state.originalCenter.y + state.originalRadius - maxY),
                    state.originalCenter.y - state.originalRadius - minY
                );

                state.displayedCenter.x = state.originalCenter.x + limitedXDiff;
                state.displayedCenter.y = state.originalCenter.y - limitedYDiff;
            });
        },
        [getCSSVariable, pointerOrigin, setShapeState]
    );

    const handleCirclePointerUp = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();

            setShapeState((state) => {
                state.isDragging = false;
                state.pointerOrigin.x = 0;
                state.pointerOrigin.y = 0;
                state.originalCenter.x = state.displayedCenter.x;
                state.originalCenter.y = state.displayedCenter.y;
            });

        },
        [setShapeState]
    );

    const handleResizeBulletPointerDown = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();

            if (isEditable) {
                const { clientX, clientY } = event;
                const resizeBulletId = event.target.id;

                setShapeState((state) => {
                    // TODO use a more clever way to finding the resizing sides (maybe className or other data attribute)
                    state.isResizing.leftSide = resizeBulletId.includes('left');
                    state.isResizing.rightSide = resizeBulletId.includes('right');
                    state.isResizing.topSide = resizeBulletId.includes('top');
                    state.isResizing.bottomSide = resizeBulletId.includes('bottom');
                    state.pointerOrigin.x = clientX;
                    state.pointerOrigin.y = clientY;
                });
            }
        },
        [isEditable, setShapeState]
    );

    const handleResizeBulletPointerMove = useCallback(
        (event) => {
            event.stopPropagation();
            const { clientX, clientY } = event;

            const scale = parseFloat(getCSSVariable(SCALE));
            const maxX = parseFloat(getCSSVariable(BOUNDARY_MAX_X));
            const minX = parseFloat(getCSSVariable(BOUNDARY_MIN_X));
            const maxY = parseFloat(getCSSVariable(BOUNDARY_MAX_Y));
            const minY = parseFloat(getCSSVariable(BOUNDARY_MIN_Y));

            const xDifference = (clientX - pointerOrigin?.x) / scale / pixelToMeter;
            const yDifference = (clientY - pointerOrigin?.y) / scale / pixelToMeter;

            // Calculate new radius of the circle based on the pointer position
            // Limit resizing so that it will maintain minimum height/width
            setShapeState((state) => {
                let newRadius;

                if (isResizing.rightSide) {
                    newRadius = state.originalRadius + xDifference;
                }

                if (isResizing.leftSide) {
                    newRadius = state.originalRadius - xDifference;
                }

                if (isResizing.topSide) {
                    newRadius = state.originalRadius - yDifference;
                }

                if (isResizing.bottomSide) {
                    newRadius = state.originalRadius + yDifference;
                }

                state.displayedRadius = Math.min(
                    Math.max(newRadius, minRadius),
                    maxX - state.displayedCenter.x,
                    state.displayedCenter.x - minX,
                    maxY - state.displayedCenter.y,
                    state.displayedCenter.y - minY
                );
            });
        },
        [
            getCSSVariable,
            isResizing.bottomSide,
            isResizing.leftSide,
            isResizing.rightSide,
            isResizing.topSide,
            pointerOrigin,
            setShapeState,
        ]
    );

    const handleResizeBulletPointerUp = useCallback(
        (event) => {
            event.stopPropagation();

            setShapeState((state) => {
                state.isResizing.leftSide = false;
                state.isResizing.rightSide = false;
                state.isResizing.topSide = false;
                state.isResizing.bottomSide = false;
                state.pointerOrigin.x = 0;
                state.pointerOrigin.y = 0;
                state.originalCenter.x = displayedCenter.x;
                state.originalCenter.y = displayedCenter.y;
                state.originalRadius = displayedRadius;
            });
        },
        [displayedCenter.x, displayedCenter.y, displayedRadius, setShapeState]
    );

    useEffect(() => {
        if (!isSelected) {
            // If the placement gets updated for real, or the user selects a different region,
            // reset the region's shape
            resetShape();
        }
    }, [isSelected, resetShape]);

    useEffect(() => {
        if (anchorPoint && !isDuplicating) {
            setAnchorPoint(prevState => {
                return ({ ...prevState, x: displayedCenter.x + prevState.distanceFromCenterX, y: displayedCenter.y + prevState.distanceFromCenterY });
            })
        }
    }, [displayedCenter])

    useEffect(() => {
        // Attach event listeners for rectangle dragging
        if (isDragging) {
            window.addEventListener('pointermove', handleCirclePointerMove);
            window.addEventListener('pointerup', handleCirclePointerUp);

            return () => {
                window.removeEventListener('pointermove', handleCirclePointerMove);
                window.removeEventListener('pointerup', handleCirclePointerUp);
            };
        }
    }, [handleCirclePointerMove, handleCirclePointerUp, isDragging]);

    useEffect(() => {
        // Attach event listeners for resize bullet
        if (isResizing.leftSide || isResizing.rightSide || isResizing.topSide || isResizing.bottomSide) {
            window.addEventListener('pointermove', handleResizeBulletPointerMove);
            window.addEventListener('pointerup', handleResizeBulletPointerUp);

            return () => {
                window.removeEventListener('pointermove', handleResizeBulletPointerMove);
                window.removeEventListener('pointerup', handleResizeBulletPointerUp);
            };
        }
    }, [isResizing, handleResizeBulletPointerMove, handleResizeBulletPointerUp]);

    if (!region || !placement) {
        return null;
    }

    return (
        <>
            <Tooltip
                component={'g'}
                wrapperProps={{ id: getPlacementId(placementId) }}
                placement={'bottom'}
                visible={!isDraft && isSelected}
                sticky
                interactive
                appendTo={containerRef.current}
                zIndex={1000} // Fix conflict with dialogs, which by default have a z-index of 1300
                content={
                    <RegionActions
                        onSave={handleSave}
                        onEdit={() => dispatch(openRegionEditor({ regionId, placementId }))}
                        onCancel={() => onCancel({ regionId, placementId })}
                        onDelete={handleDelete}
                        onSendToBack={() => onSendToBack({ regionId, placementId })}
                        onDuplicate={() => {
                            onDuplicate({ regionId, placementId });
                        }}
                        anchorData={props.anchorData}

                    />
                }
            >
                <Tooltip
                    placement={'top'}
                    visible={!isDraft && isDraggedOver && !isAllowed}
                    sticky
                    appendTo={containerRef.current}
                    content={'This trigger is already attached to this region.'}
                    useWrapper={false}
                >
                    <>
                        {anchorPoint && <circle cx={anchorPoint.x} cy={anchorPoint.y} r="15" fill="red" />}
                        <Circle
                            className={clsx({
                                highlighted: !isDraft ? isHighlighted : false,
                                selected: !isDraft ? isSelected : false,
                                'dragged-over': isDraggedOver,
                                allowed: isAllowed,
                                draft: isDraft,
                                disabled: props.disabled
                            })}
                            cx={x}
                            cy={y}
                            r={r}
                            onContextMenu={(e) => {
                                e.preventDefault();
                                onContextMenu({ regionId, placementId });
                            }}
                            onClick={(event) => props.onRegionClicked(event, boundingBox)}
                            onPointerDown={handleCirclePointerDown}
                            onPointerEnter={() => onPointerEnter({ regionId, placementId })}
                            onPointerLeave={() => onPointerLeave({ regionId, placementId })}
                            onDragEnter={handleDragEnter}
                            onDragLeave={handleDragLeave}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleDrop}
                            color={regionColor}
                        />
                    </>
                </Tooltip>
            </Tooltip>

            {isSelected &&
                isEditable &&
                resizeHandles.map(({ id, x, y, cursor }) => (
                    <RegionPlacementResizeBullet
                        key={id}
                        id={id}
                        cx={x}
                        cy={y}
                        cursor={cursor}
                        onPointerDown={handleResizeBulletPointerDown}
                    />
                ))}
        </>
    );
}

CircularRegionPlacement.propTypes = {
    region: PropTypes.object.isRequired,
    placement: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    isEditable: PropTypes.bool,
    onClick: PropTypes.func,
    onPointerEnter: PropTypes.func,
    onPointerLeave: PropTypes.func,
    onCancel: PropTypes.func,
};

export default memo(CircularRegionPlacement);
