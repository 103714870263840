// TODO remove this file, all new Redux-toolkit related utils are now at utils.js
export const requestAction = actionType => ({
    type: actionType,
});

export const errorAction = (actionType, errorMessage) => ({
    type: actionType,
    error: errorMessage,
});

export const successAction = (actionType, payload) => ({
    type: actionType,
    payload,
});

export const assertError = (response, errorMessage) => {
    if (response.error) {
        throw new Error(`${errorMessage}: ${response.message}`);
    }
};

export const actionResult = (data, successMessage, errorMessage) => ({
    isActionResult: true,
    data,
    successMessage,
    errorMessage,
});
