import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
} from '@material-ui/core';
import { Button, Skeleton, Toggle, ToggleGroup } from '../../../common/themed';
import { getFloorToggleId, getMapThumbnailId } from '../create-building/CreateBuildingDialog.selectors';
import Thumbnail from './Thumbnail';
import clsx from 'clsx';
import useAllFloorsInBuilding from '../../../common/hooks/data-fetching/useAllFloorsInBuilding';
import useMapsFullData from '../../../common/hooks/data-fetching/useMapsFullData';
import styled from 'styled-components';

const ThumbnailsWrapper = styled.div`
    display: flex;
    overflow-y: auto;
    max-height: 35rem;
    margin-block-start: 15px;

    ${Thumbnail}:not(:first-child) {
        margin-inline-start: 20px;
    }
`;

export default function FloorAndMapSelect(props) {
    const { buildingId, defaultSelectedFloorId = null, defaultSelectedMapId = null, onMapChange } = props;

    const { data: floors, isLoading: isLoadingFloors } = useAllFloorsInBuilding({
        buildingId,
    });

    const [selectedFloorId, setSelectedFloorId] = useState(defaultSelectedFloorId);
    const selectedFloor = floors?.find((f) => f.floorId === selectedFloorId);
    const [selectedMapId, setSelectedMapId] = useState(defaultSelectedMapId ?? selectedFloor?.mapIds?.[0]);
    const [isOpen, setIsOpen] = useState(!selectedMapId);

    const { data: maps, isLoading: isLoadingMaps } = useMapsFullData({
        buildingId,
        floorId: selectedFloorId,
        mapIds: selectedFloor?.mapIds,
    });

    const selectedMap = maps?.find((m) => m.mapId === selectedMapId);

    const handleFloorChange = (floorId) => {
        setSelectedMapId(floors?.find((f) => f.floorId === selectedFloorId)?.mapIds?.[0]);
        setSelectedFloorId(floorId);
    };

    const handleMapChange = (mapId) => {
        setSelectedMapId(mapId);
        setIsOpen(false);
    };

    useEffect(() => {
        onMapChange(selectedMap);
    }, [onMapChange, selectedMap]);

    if (isLoadingFloors || isLoadingMaps) {
        return <Skeleton width={'100%'} height={100} />;
    }

    return (
        <Accordion expanded={isOpen}>
            <AccordionSummary>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <Typography variant={'h6'}>
                            {isOpen
                                ? `Select a map`
                                : `Selected floor: ${selectedFloor?.floorName}, selected map: ${selectedMap?.mapName}`}
                        </Typography>
                    </Grid>

                    {!isOpen && (
                        <Grid item>
                            <Button variant={'outlined'} onClick={() => setIsOpen(true)}>
                                Change map
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container direction={'column'}>
                    <ToggleGroup>
                        {isLoadingFloors
                            ? Array.from({ length: 3 }, (_, index) => (
                                  <Skeleton
                                      key={`floor-toggle-${index}`}
                                      height={40}
                                      width={70}
                                      style={{ marginInlineEnd: 5 }}
                                  />
                              ))
                            : floors?.map(({ floorId, floorName }) => (
                                  <Toggle
                                      key={floorId}
                                      id={getFloorToggleId(floorId)}
                                      onClick={() => handleFloorChange(floorId)}
                                      isSelected={floorId === selectedFloorId}
                                  >
                                      {floorName}
                                  </Toggle>
                              ))}
                    </ToggleGroup>

                    <ThumbnailsWrapper>
                        {isLoadingMaps
                            ? Array.from({ length: 3 }, (_, index) => (
                                  <Skeleton
                                      key={`map-thumbnail-${index}`}
                                      height={'10rem'}
                                      width={'10rem'}
                                      style={{ marginInlineEnd: 20 }}
                                  />
                              ))
                            : maps.map(({ mapId, mapUrl }) => (
                                  <Thumbnail
                                      key={`map-thumbnail-${mapId}`}
                                      id={getMapThumbnailId(selectedFloorId, mapId)}
                                      className={clsx({ selected: mapId === selectedMapId })}
                                      onClick={() => handleMapChange(mapId)}
                                  >
                                      <img alt={'thumbnail'} src={mapUrl} />
                                  </Thumbnail>
                              ))}
                    </ThumbnailsWrapper>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
