import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    selectBuildingIdToFloorsArray,
    selectBuildingIdToFloorsObject,
} from '../../../../state-management/building/buildingSelectors';
import { fetchBuildingFullData } from '../../../../state-management/building/buildingActions';
import { selectHasError, selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';

/**
 * Hook that fetches all floors in the given building ID.
 * @param options
 * @return {{isLoading, data: (*), hasError}}
 */
export default function useAllFloorsInBuilding(options = {}) {
    const { asObject = false, buildingId } = options;

    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const buildingIdToFloorsArray = useSelector(selectBuildingIdToFloorsArray);
    const buildingIdToFloorsObject = useSelector(selectBuildingIdToFloorsObject);
    const isFetching = useSelector(
        selectIsLoading({ type: fetchBuildingFullData.typePrefix, arg: buildingId })
    );
    const hasError = useSelector(selectHasError({ type: fetchBuildingFullData.typePrefix, arg: buildingId }));

    const floorsArray = buildingIdToFloorsArray[buildingId];
    const floorsObject = buildingIdToFloorsObject[buildingId];

    useEffect(() => {
        // If there is a provided building ID, and we haven't fetched all of its floors yet,
        // and we are not currently fetching them, fetch them
        if (buildingId && !isFetching && (!floorsArray || floorsArray.some((f) => !f)) && !hasError) {
            dispatch(fetchBuildingFullData({ spaceId: selectedSpaceId, buildingId }));
        }
    }, [dispatch, isFetching, hasError, buildingId, floorsArray]);

    return { data: asObject ? floorsObject : floorsArray, isLoading: isFetching, hasError };
}
