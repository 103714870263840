import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, FormControlLabel } from '@material-ui/core';
import moment from 'moment';
import { Select, Switch, DatePicker, Button, Skeleton, SwitchLabel as Label } from '../../common/themed';
import countriesAndTimezones from 'countries-and-timezones';
import {
    getBuildingFilterId,
    getFiltersSubmitButtonId,
    getFloorFilterId,
    getTimePeriodFilterId,
    getTimeSpanFilterId,
    getTimeZoneFilterId,
    getWorkingHoursOnlyFilterId,
} from './RegionOccupancy.selectors';
import { getTimePeriodByName, getTimePeriods } from './time-periods';
import useAllBuildingsInSpace from '../../common/hooks/data-fetching/useAllBuildingsInSpace';
import useAllFloorsInBuilding from '../../common/hooks/data-fetching/useAllFloorsInBuilding';
import {
    selectAnalyticsBuildingUsageFiltersUserInputs,
    setSelectedBuildingId,
    setSelectedFloorId,
    setSelectedTimePeriod,
    setTimestamps,
    setTimeZone,
    setWorkingHoursOnly,
} from '../../../state-management/user-inputs/analyticsBuildingUsageSlice';
import { getTimeResolutionsByTimeSpan } from './time-resolutions';
import { fetchOccupancyData } from '../../../state-management/analytics/region-occupancy/regionOccupancyActions';
import { selectCommonSelectedSpaceId } from '../../../state-management/user-inputs/commonSlice';

const timeZones = countriesAndTimezones.getAllTimezones();
const timePeriods = getTimePeriods();

let flatPickrInstance = null;

export default function RegionOccupancyFilters() {
    const dispatch = useDispatch();

    const {
        selectedBuildingId,
        selectedFloorId,
        selectedTimePeriod: selectedTimePeriodName,
        startTimestamp,
        endTimestamp,
        workingHoursOnly,
        timeZone,
    } = useSelector(selectAnalyticsBuildingUsageFiltersUserInputs);
    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);

    const selectedTimePeriod = getTimePeriodByName(selectedTimePeriodName);

    const [isDatePickerShown, setIsDatePickerShown] = useState(selectedTimePeriod?.isPickable);

    const { data: buildings = {}, isLoading: isLoadingBuildings } = useAllBuildingsInSpace({
        asObject: true,
    });

    const { data: floors = {}, isLoading: isLoadingFloors } = useAllFloorsInBuilding({
        buildingId: selectedBuildingId,
        asObject: true,
    });

    const clearDate = useCallback(() => {
        if (flatPickrInstance) {
            try {
                flatPickrInstance.clear();
            } catch (e) {}
        }
    }, []);

    const handleTimePeriodChange = (timePeriod) => {
        const period = getTimePeriodByName(timePeriod);

        if (period.isPickable) {
            setIsDatePickerShown(true);
            clearDate();
        } else {
            setIsDatePickerShown(false);
        }

        const { start, end } = period.getTimeSpan();

        dispatch(
            setSelectedTimePeriod({
                timePeriod: period.value,
                start,
                end,
            })
        );
    };

    const handleTimestampsChange = (dates) => {
        const start = moment(dates[0]);
        const end = moment(selectedTimePeriod.isRange ? dates[1] : dates[0]);

        dispatch(
            setTimestamps({
                start: moment
                    .utc({
                        year: start.year(),
                        month: start.month(),
                        day: start.date(),
                    })
                    .startOf('day')
                    .toISOString(),
                end: moment
                    .utc({
                        year: end.year(),
                        month: end.month(),
                        day: end.date(),
                    })
                    .endOf('day')
                    .toISOString(),
            })
        );
    };

    const handleSubmit = () => {
        const timeResolutions = getTimeResolutionsByTimeSpan(startTimestamp, endTimestamp);

        dispatch(
            fetchOccupancyData(
                selectedBuildingId,
                selectedFloorId,
                timeResolutions,
                moment(startTimestamp).subtract(timeZone.utcOffset, 'minutes').toISOString(),
                moment(endTimestamp).subtract(timeZone.utcOffset, 'minutes').toISOString(),
                timeZone.utcOffset,
                workingHoursOnly,
                selectedSpaceId
            )
        );
    };

    useEffect(() => {
        // If there's only one building in the selected space, select that building by default
        if (!selectedBuildingId && Object.keys(buildings).length === 1) {
            dispatch(setSelectedBuildingId(Object.keys(buildings)[0]));
        }
    }, [dispatch, buildings, selectedBuildingId]);

    useEffect(() => {
        // If there's only one floor in the selected building, select that floor by default
        if (selectedBuildingId && !selectedFloorId && Object.keys(floors).length === 1) {
            dispatch(setSelectedFloorId(Object.keys(floors)[0]));
        }
    }, [dispatch, floors, selectedBuildingId, selectedFloorId]);

    return (
        <Grid container spacing={3} alignItems={'center'}>
            <Grid item lg md={4} sm={6} xs={12}>
                {isLoadingBuildings ? (
                    <Skeleton height={35} width={180} />
                ) : (
                    <Select
                        id={getBuildingFilterId()}
                        placeholder={'Select a Building...'}
                        textFieldProps={{
                            label: 'Building',
                            InputLabelProps: {
                                shrink: true,
                            },
                        }}
                        options={Object.values(buildings)}
                        getOptionLabel={(b) => b.buildingName}
                        getOptionValue={(b) => b.buildingId}
                        isClearable
                        value={buildings?.[selectedBuildingId] ?? null}
                        onChange={(b) => dispatch(setSelectedBuildingId(b?.buildingId ?? null))}
                    />
                )}
            </Grid>

            <Grid item lg md={4} sm={6} xs={12}>
                {isLoadingFloors ? (
                    <Skeleton height={35} width={180} />
                ) : (
                    <Select
                        id={getFloorFilterId()}
                        placeholder={'Select a Floor...'}
                        options={Object.values(floors)}
                        textFieldProps={{
                            label: 'Floor',
                            InputLabelProps: {
                                shrink: true,
                            },
                        }}
                        getOptionLabel={(f) => f.floorName}
                        getOptionValue={(f) => f.floorId}
                        isClearable
                        isDisabled={Object.values(floors).length === 0}
                        value={floors?.[selectedFloorId] ?? null}
                        onChange={(f) => dispatch(setSelectedFloorId(f?.floorId ?? null))}
                    />
                )}
            </Grid>

            <Grid item lg md={4} sm={6} xs={12}>
                <Select
                    id={getTimePeriodFilterId()}
                    placeholder={'Pick a Time Period...'}
                    options={timePeriods.map((tp) => ({
                        value: tp.value,
                        label: tp.label,
                    }))}
                    textFieldProps={{
                        label: 'Time Period',
                        InputLabelProps: {
                            shrink: true,
                        },
                    }}
                    value={{
                        value: selectedTimePeriod.value,
                        label: selectedTimePeriod.label,
                    }}
                    onChange={(tp) => handleTimePeriodChange(tp.value)}
                />
            </Grid>

            {isDatePickerShown && (
                <Grid item lg md={4} sm={6} xs={12}>
                    <DatePicker
                        id={getTimeSpanFilterId()}
                        onReady={(dates, dateStr, instance) => {
                            flatPickrInstance = instance;
                        }}
                        options={{
                            mode: selectedTimePeriod.isRange ? 'range' : 'single',
                            defaultDate: selectedTimePeriod.isRange
                                ? [startTimestamp, endTimestamp]
                                : startTimestamp,
                            dateFormat: 'Y-M-d',
                            maxDate: moment().endOf('day').toDate(),
                            minDate: moment().subtract(3, 'months').startOf('day').toDate(),
                            showMonths: 2,
                        }}
                        inputProps={{
                            style: { marginTop: 5 },
                            placeholder: selectedTimePeriod.isRange ? 'Pick a Date Range' : 'Pick a Day',
                            label: 'Date(s)',
                            InputLabelProps: {
                                shrink: true,
                            },
                        }}
                        onChange={handleTimestampsChange}
                    />
                </Grid>
            )}

            <Grid item lg md={4} sm={6} xs={12}>
                <Select
                    id={getTimeZoneFilterId()}
                    placeholder={'Select a Time Zone...'}
                    options={Object.values(timeZones)
                        .filter((tz) => tz.utcOffset % 60 === 0)
                        .sort((a, b) => a.utcOffset - b.utcOffset)}
                    textFieldProps={{
                        label: 'Time Zone',
                        InputLabelProps: {
                            shrink: true,
                        },
                    }}
                    getOptionLabel={(timeZone) => `(UTC${timeZone.offsetStr}) ${timeZone.name}`}
                    getOptionValue={(timeZone) => timeZone.name}
                    value={timeZone}
                    onChange={(timeZone) => dispatch(setTimeZone(timeZone))}
                />
            </Grid>

            <Grid item lg md={4} sm={6} xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            id={getWorkingHoursOnlyFilterId()}
                            checked={workingHoursOnly}
                            onChange={(e) => dispatch(setWorkingHoursOnly(e.target.checked))}
                            value={'workingHoursOnly'}
                        />
                    }
                    label={<Label>Working Hours Only</Label>}
                />
            </Grid>

            <Grid item xs={1}>
                <Button
                    id={getFiltersSubmitButtonId()}
                    type={'submit'}
                    disabled={!selectedBuildingId || !startTimestamp || !endTimestamp || !timeZone}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Grid>
        </Grid>
    );
}
