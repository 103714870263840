import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './junctionMismatchEntityAdapter';
import { expireSession, logout } from '../../auth/authActions';
import { fetchAllJunctionMismatchesInMap, toggleJunctionMismatchesInMap } from './junctionMismatchActions';
import { deleteLane } from '../lane/laneActions';
import { laneDeleted } from '../mappingEventsActions';

const { getInitialState, getSelectors, upsertMany, removeMany } = entityAdapter;

export const { reducer } = createSlice({
    name: 'junctionMismatches',
    initialState: {
        ...getInitialState(),
        isEqualizedFetched: false,
        isUnequalizedFetched: false,
        junctionMismatchToggled: false,
    },
    reducers: {},
    extraReducers: {
        [fetchAllJunctionMismatchesInMap.fulfilled]: (state, action) => {
            const { useEqualizedMap } = action.meta.arg;

            const allJunctions = getSelectors().selectAll(state);
            const deletedIds = allJunctions
                .filter((j) => j.isEqualized === useEqualizedMap)
                .map((j) => j.junctionId);

            removeMany(state, deletedIds);

            upsertMany(
                state,
                action.payload.map((j) => ({
                    junctionId: j.junctionId,
                    firstPointLaneId: j.firstPointLaneId,
                    firstPointCoordinates: j.firstPointCoordinates,
                    secondPointLaneId: j.secondPointLaneId,
                    secondPointCoordinates: j.secondPointCoordinates,
                    mismatchValue: j.mismatchValue,
                    isEqualized: useEqualizedMap,
                }))
            );

            if (useEqualizedMap) {
                state.isEqualizedFetched = true;
            } else {
                state.isUnequalizedFetched = true;
            }
        },

        [deleteLane.fulfilled]: (state, action) => {
            const { junctionIds } = action.payload;
            removeMany(state, junctionIds);
        },

        [laneDeleted]: (state, action) => {
            const { laneId } = action.payload.data;

            const allJunctions = getSelectors().selectAll(state);
            const deletedIds = allJunctions
                .filter((j) => j.firstPointLaneId === laneId || j.secondPointLaneId === laneId)
                .map((j) => j.junctionId);

            removeMany(state, deletedIds);
        },

        [expireSession.fulfilled]: () => ({
            ...getInitialState(),
            isEqualizedFetched: false,
            isUnequalizedFetched: false,
        }),
        [logout.fulfilled]: () => ({
            ...getInitialState(),
            isEqualizedFetched: false,
            isUnequalizedFetched: false,
        }),
        [toggleJunctionMismatchesInMap]: (state) => {
            state.junctionMismatchToggled = !state.junctionMismatchToggled;
        },
    },
});
