import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { PulseLoader } from 'react-spinners';

const PreloaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    height: ${(props) => (props.fullHeight ? '100%' : 'auto')};
`;

// TODO loader color should come from theme
export default function PulsePreloader(props) {
    const { fullHeight = true, ...otherProps } = props;

    return (
        <PreloaderWrapper fullHeight={fullHeight}>
            <PulseLoader color={'#ff6200'} {...otherProps} />
        </PreloaderWrapper>
    );
}

PulsePreloader.propTypes = {
    fullHeight: PropTypes.bool,
};
