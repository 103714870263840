import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MapViewerLoadingIndicatorOverlay from '../../common/map-viewer/overlays/loading-indicators';
import {
    selectMappingAreaIdsShownInBack,
    selectMappingAreaIdsShownInFront,
    selectMappingAreaOfInterestIds,
    selectMappingAreAreasOfInterestShown,
    selectMappingAreElementsShown,
    selectMappingAreExitRegionsShown,
    selectMappingElementIds,
    selectMappingSelectedAreaId,
    selectMappingSelectedAreaOfInterestsMapperName,
    selectMappingSelectedElementsMapperName,
    selectMappingSelectedMapId,
    setSelectedAreaId,
    setSelectedAreaOfInterestIds,
    setSelectedElementId,
    setSelectedElementIds,
} from '../../../state-management/user-inputs/mappingSlice';
import { selectMonitorSelectedMapId } from '../../../state-management/user-inputs/monitorSlice';
import {
    deleteAreaOfInterest,
    updateAreaOfInterest,
} from '../../../state-management/mapping/area-of-interest/areaOfInterestActions';
import MapViewerAreasOfInterestOverlay from '../../common/map-viewer/overlays/areas-of-interest';
import useAreasOfInterest from '../../common/hooks/data-fetching/useAreasOfInterest';
import { deleteElement, updateElement } from '../../../state-management/mapping/elements/elementsActions';
import { deleteExitRegion, upsertExitRegion } from '../../../state-management/mapping/exitRegion/exitRegionsActions';
import { getAreaOfInterestListEntryId, getElementListEntryId } from '../Mapping.selectors';
import { getExitRegionListEntryId } from '../../buildings/buildings.selectors';
import { selectBuildingAreExitRegionsShown, selectBuildingSelectedExitRegionIds, setSelectBuildingSelectedExitRegionId, setSelectedBuildingExitRegionIds } from '../../../state-management/user-inputs/buildingsSlice';
import { useExitRegions } from '../../common/hooks/data-fetching/useExitRegions';
import { selectExitRegionIds } from '../../../state-management/mapping/exitRegion/exitRegionSelectors';
import { selectUserName } from '../../../state-management/auth/authSelectors';

const ACTIONS = {
    normal: {
        selectAreAreasShown: selectMappingAreAreasOfInterestShown,
        setSelectedAreaIds: setSelectedAreaOfInterestIds,
        selectMappingAreaIds: selectMappingAreaOfInterestIds,
        update: updateAreaOfInterest,
        delete: deleteAreaOfInterest,
        getId: getAreaOfInterestListEntryId,
        useFetch: useAreasOfInterest,
        selectedMapperName: selectMappingSelectedAreaOfInterestsMapperName,
        setSelectedId: setSelectedAreaId,

    },
    element: {
        selectAreAreasShown: selectMappingAreElementsShown,
        setSelectedAreaIds: setSelectedElementIds,
        selectMappingAreaIds: selectMappingElementIds,
        update: updateElement,
        delete: deleteElement,
        getId: getElementListEntryId,
        selectedMapperName: selectMappingSelectedElementsMapperName,
        useFetch: useAreasOfInterest,
        setSelectedId: setSelectedElementId,

    },
    exitRegion: {
        selectAreAreasShown: selectBuildingAreExitRegionsShown,
        setSelectedAreaIds: setSelectedBuildingExitRegionIds,
        selectMappingAreaIds: selectBuildingSelectedExitRegionIds,
        update: ({ areaData: exitRegion, ...params }) => {
            return upsertExitRegion({ ...params, exitRegion });
        },
        delete: ({ areaId: exitRegionId, ...params }) => deleteExitRegion({ ...params, exitRegionId }),
        getId: getExitRegionListEntryId,
        useFetch: useExitRegions,
        setSelectedId: setSelectBuildingSelectedExitRegionId,
        selectedMapperName: (state) => null,
    },

}

export const AreasOfInterestOverlay = ({ type = 'normal', readOnly = false, mode = 'mapping', disabled, buildingId, floorId, buildingMapId }) => {
    const dispatch = useDispatch();
    const action = ACTIONS[type];
    const selectedMapId = useSelector(
        mode === 'mapping' ? selectMappingSelectedMapId : selectMonitorSelectedMapId
    );
    const mapId = buildingMapId || selectedMapId;
    const selectedAreaIds = useSelector(action.selectMappingAreaIds)
    const areaIdsShownInBack = useSelector(selectMappingAreaIdsShownInBack);
    const areaIdsShownInFront = useSelector(selectMappingAreaIdsShownInFront);
    const { data: areas = [], isLoading } = action.useFetch({ mapId, type });
    const showItems = useSelector(action.selectAreAreasShown);
    const currentUserName = useSelector(selectUserName);
    const selectedMapperName = useSelector(action.selectedMapperName);

    const areasToDisplay = useMemo(
        () =>
            areas.filter(
                ({ mapperName }) => !selectedMapperName || selectedMapperName.includes(mapperName)
            ).sort((a1, a2) => {
                const l1BackIndex = areaIdsShownInBack.indexOf(a1.areaId);
                const l2BackIndex = areaIdsShownInBack.indexOf(a2.areaId);
                const l1FrontIndex = areaIdsShownInFront.indexOf(a1.areaId);
                const l2FrontIndex = areaIdsShownInFront.indexOf(a2.areaId);

                // Assuming there won't be more than 100000 areas, give a big weight for being in the back or front.
                // Based on this calculation, if l1 and l2 are both in back/front, they win by their indexes.
                // If neither is in the back or front, their order doesn't matter.
                const l1TotalIndex = -100000 * (l1BackIndex + 1) + 100000 * (l1FrontIndex + 1);
                const l2TotalIndex = -100000 * (l2BackIndex + 1) + 100000 * (l2FrontIndex + 1);

                return l1TotalIndex - l2TotalIndex;
            }),
        [areaIdsShownInBack, areaIdsShownInFront, areas, selectedMapperName]
    );

    const handleScrollingToSelectedElement = (selectedIds) => {
        if (selectedIds.length === 1) {
            const [id] = selectedIds
            const htmlElementId = action.getId(id);
            const element = document.getElementById(htmlElementId);
            if (element) {
                element.scrollIntoView()
            }
        }
    }

    const handleAreaClick = (areaId, isShiftSelected) => {
        const isAreaIdSelected = selectedAreaIds.includes(areaId);
        let updatedAreaIds;
        if (isShiftSelected) { // For handling multiple ids
            updatedAreaIds = [...selectedAreaIds, areaId]
            if (isAreaIdSelected) {
                updatedAreaIds = selectedAreaIds.filter(id => id !== areaId);
                dispatch(action.setSelectedId(null))
            }

        } else {
            dispatch(action.setSelectedId(areaId));
            updatedAreaIds = [areaId];
        }
        dispatch(action.setSelectedAreaIds(updatedAreaIds));

        handleScrollingToSelectedElement(updatedAreaIds);
    };

    const handleAreaSave = async ({ areaId, ...areaData }) => {
        await dispatch(action.update({ buildingId, floorId, mapId, areaId, areaData: { ...areaData } }));
    };

    const handleAreaCancel = () => {
        dispatch(action.setSelectedAreaIds([]));
        dispatch(action.setSelectedId(null));
    };

    const handleAreaDelete = async (areaId) => {
        await dispatch(action.delete({ mapId, areaId }));
    };

    return (
        showItems && (
            <>
                <MapViewerAreasOfInterestOverlay
                    mapId={mapId}
                    areas={areasToDisplay}
                    onClick={handleAreaClick}
                    onSave={handleAreaSave}
                    onCancel={handleAreaCancel}
                    onDelete={handleAreaDelete}
                    selectedAreaIds={selectedAreaIds}
                    enableDragging={selectedAreaIds.length === 1}
                    enableResizing
                    readOnly={readOnly}
                    disabled={disabled}
                    type={type}
                />
                {isLoading && <MapViewerLoadingIndicatorOverlay label={'Loading areas of interest...'} />}
            </>
        )
    );

};
