import Api from './Api';
import http from '../utils/httpClient';

class ExitRegionsApi extends Api {
    fetchAllExitRegionsByMap({ buildingId, floorId, mapId }) {
        return this.callApi((params) => {
            return http.v1.get(`/buildings/${buildingId}/floors/${floorId}/maps/${mapId}/exitRegions`, {
                params,
            });
        });
    }

    upsertExitRegions({ buildingId, floorId, mapId }, upsertedExitRegion) {
        return this.callApi((params) =>
            http.v1.post(
                `/buildings/${buildingId}/floors/${floorId}/maps/${mapId}/exitRegions`,
                upsertedExitRegion,
                { params }
            )
        );
    }

    deleteExitRegion({ buildingId, floorId, mapId, exitRegionId }) {
        return this.callApi((params) =>
            http.v1.delete(
                `/buildings/${buildingId}/floors/${floorId}/maps/${mapId}/exitRegions/${exitRegionId}`,
                {
                    params,
                }
            )
        );
    }
}
const exitRegionsApi = new ExitRegionsApi();
export default exitRegionsApi;
