import React, { useState } from 'react';
import styled from 'styled-components';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@material-ui/core';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';
import { ScalePreloader, Tooltip } from '../../common/themed';
import { MdCheck, MdClose, MdSpeakerNotesOff, MdSpeakerNotes, MdPause, MdPlayArrow } from 'react-icons/md';
import ApiKeyExclusionDialog from './ApiKeyExclusionDialog';
import ActionsBar from './ActionsBar';
import ApiKeySuspensionDialog from './ApiKeySuspensionDialog';
import { useDispatch } from 'react-redux';
import useApiKeys from '../../common/hooks/data-fetching/useApiKeys';
import {
    unexcludeApiKeyFromTermsAcceptance,
    unsuspendApiKey,
} from '../../../state-management/api-key/apiKeyActions';
import { showSuccessNotification } from '../../../state-management/notification/notificationReducer';

const TrueIcon = styled(MdCheck)`
    fill: #75d13f; // TODO color should come from theme
    width: 40px;
    height: 40px;
`;

const FalseIcon = styled(MdClose)`
    fill: #f64747; // TODO color should come from theme
    width: 40px;
    height: 40px;
`;

const ExcludeIcon = styled(MdSpeakerNotesOff)`
    fill: coral; // TODO color should come from theme
`;

const UnexcludeIcon = styled(MdSpeakerNotes)`
    fill: deepskyblue;
`;

const SuspendIcon = styled(MdPause)`
    fill: #f64747; // TODO color should come from theme
`;

const UnsuspendIcon = styled(MdPlayArrow)`
    fill: deepskyblue;
`;

export default function TermsAndConditionsManagement() {
    const [selectedApiKeyId, setSelectedApiKeyId] = useState(null);
    const [selectedApiKeyDesc, setSelectedApiKeyDesc] = useState(null);
    const [isExclusionDialogShown, setIsExclusionDialogShown] = useState(false);
    const [isSuspensionDialogShown, setIsSuspensionDialogShown] = useState(false);
    const [filter, setFilter] = useState('');
    const [hideAccepted, setHideAccepted] = useState(false);
    const [hideExcluded, setHideExcluded] = useState(false);
    const [hideSuspended, setHideSuspended] = useState(false);

    const dispatch = useDispatch();

    const { data: apiKeys, isLoading, hasError } = useApiKeys();

    const onDialogClose = () => {
        setSelectedApiKeyId(null);
        setSelectedApiKeyDesc(null);
        setIsExclusionDialogShown(false);
        setIsSuspensionDialogShown(false);
    };

    const handleUnexclude = async (apiKeyId) => {
        await dispatch(unexcludeApiKeyFromTermsAcceptance(apiKeyId));
        dispatch(showSuccessNotification('API key has been unexcluded from terms acceptance.'));
    };

    const handleUnsuspend = async (apiKeyId) => {
        await dispatch(unsuspendApiKey(apiKeyId));
        dispatch(showSuccessNotification('API key has been unsuspended.'));
    };

    if (hasError) {
        return <ErrorGeneral />;
    }

    if (isLoading) {
        return <ScalePreloader height={65} width={10} margin={'4px'} />;
    }

    return (
        <>
            <ActionsBar
                filter={filter}
                onFilterChange={setFilter}
                onHideAcceptedChange={setHideAccepted}
                onHideExcludedChange={setHideExcluded}
                onHideSuspendedChange={setHideSuspended}
            />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>API key ID</TableCell>

                        <TableCell>Description</TableCell>

                        <TableCell>Accepted latest terms and conditions?</TableCell>

                        <TableCell>Excluded from terms acceptance?</TableCell>

                        <TableCell>Suspended?</TableCell>

                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {apiKeys
                        .filter(
                            (apiKey) =>
                                (apiKey.apiKeyId.includes(filter) || apiKey.apiKeyDesc.includes(filter)) &&
                                (!hideAccepted || !apiKey.hasAcceptedLatestTermsAndConditions) &&
                                (!hideExcluded || !apiKey.isExcludedFromTermsAcceptance) &&
                                (!hideSuspended || !apiKey.isSuspended)
                        )
                        .sort((a, b) => a.apiKeyDesc.localeCompare(b.apiKeyDesc))
                        .map((apiKey) => (
                            <TableRow key={apiKey.apiKeyId}>
                                <TableCell component="th" scope="row">
                                    {apiKey.apiKeyId}
                                </TableCell>

                                <TableCell>{apiKey.apiKeyDesc}</TableCell>

                                <TableCell align={'center'}>
                                    {apiKey.hasAcceptedLatestTermsAndConditions ? (
                                        <TrueIcon />
                                    ) : (
                                        <FalseIcon />
                                    )}
                                </TableCell>

                                <TableCell align={'center'}>
                                    {apiKey.isExcludedFromTermsAcceptance ? <TrueIcon /> : <FalseIcon />}
                                </TableCell>

                                <TableCell align={'center'}>
                                    {apiKey.isSuspended ? <TrueIcon /> : <FalseIcon />}
                                </TableCell>

                                <TableCell>
                                    {apiKey.isExcludedFromTermsAcceptance ? (
                                        <Tooltip content={'Unexclude from terms acceptance'}>
                                            <IconButton onClick={() => handleUnexclude(apiKey.apiKeyId)}>
                                                <UnexcludeIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip content={'Exclude from terms acceptance'}>
                                            <IconButton
                                                onClick={() => {
                                                    setSelectedApiKeyId(apiKey.apiKeyId);
                                                    setSelectedApiKeyDesc(apiKey.apiKeyDesc);
                                                    setIsExclusionDialogShown(true);
                                                }}
                                            >
                                                <ExcludeIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {apiKey.isSuspended ? (
                                        <Tooltip content={'Unsuspend'}>
                                            <IconButton onClick={() => handleUnsuspend(apiKey.apiKeyId)}>
                                                <UnsuspendIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip content={'Suspend'}>
                                            <IconButton
                                                onClick={() => {
                                                    setSelectedApiKeyId(apiKey.apiKeyId);
                                                    setSelectedApiKeyDesc(apiKey.apiKeyDesc);
                                                    setIsSuspensionDialogShown(true);
                                                }}
                                            >
                                                <SuspendIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>

            <ApiKeyExclusionDialog
                isOpen={isExclusionDialogShown}
                apiKeyId={selectedApiKeyId}
                apiKeyDesc={selectedApiKeyDesc}
                onClose={onDialogClose}
            />

            <ApiKeySuspensionDialog
                isOpen={isSuspensionDialogShown}
                apiKeyId={selectedApiKeyId}
                apiKeyDesc={selectedApiKeyDesc}
                onClose={onDialogClose}
            />
        </>
    );
}
