import React, { forwardRef, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
    Tooltip,
    CircularPreloader,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '../../../common/themed';
import { MdDelete as DeleteIcon, MdEdit as EditIcon } from 'react-icons/md';
import { IconButton, RootRef, Grid } from '@material-ui/core';
import { selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { selectCanEditRegions } from '../../../../state-management/auth/authSelectors';
import {
    getRegionsListEntryDeleteButtonId,
    getRegionsListEntryEditButtonId,
} from '../../MapContent.selectors';
import { deleteRegion } from '../../../../state-management/region/regionActions';
import { openRegionEditor } from '../../../../state-management/user-inputs/mapContentSlice';

const IconWrapper = forwardRef((props, ref) => (
    <RootRef rootRef={ref}>
        <IconButton {...props} />
    </RootRef>
));

export default function RegionActions(props) {
    const { regionId, regionName, placementId } = props;

    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

    const dispatch = useDispatch();

    const selectIsDeleting = useMemo(
        () =>
            selectIsLoading({
                type: deleteRegion.typePrefix,
                arg: regionId,
            }),
        [regionId]
    );

    const canEditRegions = useSelector(selectCanEditRegions);
    const isDeleting = useSelector(selectIsDeleting);

    const handleEdit = (e) => {
        e.stopPropagation();
        dispatch(openRegionEditor({ regionId, placementId }));
    };

    const handleDelete = async () => {
        const result = await dispatch(deleteRegion(regionId));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Region deleted successfully.'));
        } else {
            dispatch(showErrorNotification('Failed to delete region.'));
        }

        setIsDeleteWarningOpen(false);
    };

    return (
        <>
            <Grid container alignItems={'center'} justifyContent={'space-evenly'}>
                <Tooltip content={'Delete region'} placement={'bottom'} useWrapper={false}>
                    <IconWrapper
                        id={getRegionsListEntryDeleteButtonId(regionId)}
                        aria-label={'Delete'}
                        disabled={!canEditRegions}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsDeleteWarningOpen(true);
                        }}
                    >
                        <DeleteIcon />
                    </IconWrapper>
                </Tooltip>

                <Tooltip content={'Edit region'} placement={'bottom'} useWrapper={false}>
                    <IconWrapper
                        id={getRegionsListEntryEditButtonId(regionId)}
                        aria-label={'Edit'}
                        disabled={!canEditRegions}
                        onClick={handleEdit}
                    >
                        <EditIcon />
                    </IconWrapper>
                </Tooltip>
            </Grid>

            {isDeleteWarningOpen && (
                <Dialog open closeable={false}>
                    <DialogContent>
                        <DialogContentText>
                            You are about to delete the region "{regionName}". Are you sure?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button variant={'text'} onClick={() => setIsDeleteWarningOpen(false)}>
                            Cancel
                        </Button>

                        {isDeleting ? <CircularPreloader /> : <Button onClick={handleDelete}>Accept</Button>}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

RegionActions.propTypes = {
    regionId: PropTypes.string,
    regionName: PropTypes.string,
    placementId: PropTypes.string,
};
