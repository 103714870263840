import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getMapViewerSignMarkId, getMapViewerSignMarkOverlayId } from './MapViewerSignMarkOverlay.selectors';
import SignMark from './signMark';
import { selectMappingIsSignMarkLockInMap } from '../../../../../state-management/user-inputs/mappingSlice';


const OverlayWrapper = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none; // Prevent the whole SVG from capturing pointer events

    * {
        pointer-events: auto;
    }
`;

export default function MapViewerSignMarkOverlay(props) {
    const {
        mapId,
        signMarks,
        onClick,
        onCancel,
        onSave,
        onSendToBack,
        enableDragging = true,
        enableResizing = false,
        readOnly,
        disabled,
        selectedSignMarkIds = []
    } = props;

    const isSignMarkLockInMap = useSelector(selectMappingIsSignMarkLockInMap);

    return (
        <OverlayWrapper id={getMapViewerSignMarkOverlayId()}>
            {signMarks.map((signMark) => (
                <SignMark
                    id={getMapViewerSignMarkId(signMark.signMarkId)}
                    key={signMark?.signMarkId}
                    signMark={signMark}
                    mapId={mapId}
                    onSave={onSave}
                    onSendToBack={onSendToBack}
                    onClick={() => onClick(signMark.signMarkId)}
                    onCancel={onCancel}
                    isDraggable={enableDragging && !isSignMarkLockInMap}
                    isResizeable={enableResizing}
                    isSelected={selectedSignMarkIds.includes(signMark?.signMarkId)}
                    readOnly={isSignMarkLockInMap || readOnly}
                    disabled={disabled}
                />
            ))}
        </OverlayWrapper>
    );
}

MapViewerSignMarkOverlay.propTypes = {
    areas: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
    selectedAreaIds: PropTypes.arrayOf(PropTypes.string),
    enableDragging: PropTypes.bool,
    enableResizing: PropTypes.bool,
};
