import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Select, Skeleton } from '../common/themed';
import useSpaces from '../common/hooks/data-fetching/useSpaces';
import ErrorGeneral from '../common/error-pages/ErrorGeneral';
import {
    selectCommonSelectedSpaceId,
    setSelectedSpaceId,
} from '../../state-management/user-inputs/commonSlice';

const SpaceSelectBox = styled(Select)`
    display: inline-block;
    min-width: 250px;
    margin-right: 10px;
    color: #333333;
`;

export default function SpaceSelect(props) {
    const { disabled = false } = props;

    const { data: spaces, isLoading, hasError } = useSpaces({ asObject: true });
    const spacesArray = Object.values(spaces);

    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);

    const selectedSpace = spaces?.[selectedSpaceId];

    const handleChange = (space) => {
        if (selectedSpaceId !== space.spaceId) {
            dispatch(setSelectedSpaceId(space.spaceId));
        }
    };

    useEffect(() => {
        // if the spaces have been fetched, and no space is selected, select the first space by default
        if (!selectedSpace && spacesArray && spacesArray.length > 0) {
            dispatch(setSelectedSpaceId(spacesArray[0].spaceId));
        }
    }, [dispatch, selectedSpace, spacesArray]);

    if (hasError) {
        return <ErrorGeneral />;
    }

    if (isLoading) {
        return <Skeleton height={30} width={250} />;
    }

    return (
        <SpaceSelectBox
            value={selectedSpace}
            onChange={handleChange}
            isDisabled={disabled || spaces.length === 1}
            placeholder={'Select a space...'}
            options={spacesArray}
            getOptionLabel={(s) => s.spaceDesc}
            getOptionValue={(s) => s.spaceId}
        />
    );
}
