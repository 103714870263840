import React from 'react';
import fallback from '../../../assets/fallback_image.jpeg';
import { FiDownload } from 'react-icons/fi';
import styled, { css } from 'styled-components';
import {
    DialogTitle,
    DialogContent,
    Divider,
    Grid,
    Typography,
    FormControlLabel,
    Slider,
    Slide,
    IconButton,
    Tooltip as CustomTooltip,
} from '@material-ui/core';
import { NOT_AVAILABLE } from '../../../constants/general';
import { CircularPreloader, Dialog, Switch, Tooltip } from '../../common/themed';
import { falsyCheckedWithoutZero } from '../../../utils/general';
import { Button } from '../../common/themed';

export const COLORS = {
    lightGreen: '#30f830',
    green: 'green',
};

const PublishButton = styled(Button)`
    font-size: 12px;
    max-height: 50px;
    width: 100%;
`;

export const ColorContainer = styled.div``;

export const HeaderWrapper = styled(Grid).attrs({ container: true })`
    margin-bottom: 30px;
    justify-content: space-between;
    width: ${({ width }) => width || '45%'};
    column-gap: 5px;
    display: flex;
`;

const Rectangle = styled.div`
    height: 10px;
    width: 10px;
    background-color: ${({ $color }) => $color};
    margin-right: 5px;
`;

export const ColorItem = ({ color, desc }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Rectangle $color={color} />
            <Typography>{desc}</Typography>
        </div>
    );
};

export const MapDataItem = ({ value, title, divider = true, percentage = false, color, ...props }) => {
    let parsedValue = falsyCheckedWithoutZero(value) ? NaN : Number(value);
    const displayValue = isNaN(parsedValue)
        ? NOT_AVAILABLE
        : percentage
        ? `${(parsedValue * 100).toFixed(2)}%`
        : parsedValue;
    return (
        <>
            <Grid direction="column" style={{ flex: 1 }}>
                <Typography style={{ fontSize: '20px', color }} variant="h3" {...props}>
                    {displayValue}
                </Typography>
                <Typography style={{ fontSize: '14px' }} variant="span">
                    {title}
                </Typography>
            </Grid>
            {divider && <Divider orientation="vertical" />}
        </>
    );
};

export const ImageContainer = styled(Grid).attrs({ container: true })`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 25px;
    color: rgb(146 141 141);
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const CustomDialog = styled(Dialog).attrs({
    maxWidth: false,
    fullScreen: true,
    closeIconStyle: {
        style: {
            filter: 'invert(100%)',
        },
    },
})`
    padding: 30px;
`;

export const CustomDialogTitle = styled(DialogTitle)`
    padding: 24px 16px 8px;
`;

export const CustomDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
`;

export const CustomDivider = styled(Divider)`
    background-color: #ff6200;
    margin-bottom: 10px;
    margin: 0 16px;
`;
export const DownloadButton = styled(IconButton)`
    background-color: #ff6200;
    border-radius: 10px;
    &:hover {
        background-color: #a74202;
    }
`;

export const CustomSlider = styled(Slider)`
    color: #ff6200;
    position: relative;
    & .MuiSlider-rail,
    & .MuiSlider-track {
        height: 4px;
    }
    & .MuiSlider-mark:hover {
        &::before {
            content: attr(tooltip-value);
            position: absolute;
            word-wrap: wrap;
            display: flex;
            top: -43px;
            left: -40px;
            font-size: 10px;
            width: max-content;
            height: auto;
            background-color: #ff6200;
            color: white;
            border-radius: 5px;
            padding: 12px;
        }
    }
    &::before {
        content: '';
        left: ${({ offset, healingDays }) => `calc(${offset} - ${healingDays}%)`};
        width: ${({ healingDays }) => `${healingDays}%`};
        top: 60%;
        height: 3px;
        position: absolute;
        background-color: ${() => COLORS.lightGreen};
    }
    &::after {
        content: '';
        left: ${({ offset, validationDays }) => `calc(${offset} - ${validationDays}%)`};
        width: ${({ validationDays }) => `${validationDays}%`};
        height: 3px;
        top: 60%;
        position: absolute;
        background-color: ${() => COLORS.green};
    }
`;

export const Day = styled(Typography)`
    font-size: 14px;
`;

const ImageComponentWrapper = styled(Grid)`
    position: relative;
    max-width: 88vh;
    margin: 0 auto;
    display: ${({ isCurrentImg }) => (isCurrentImg ? 'flex' : 'none')};
    flex-direction: column;
    flex: 1;
    row-gap: 10px;
    & > img + span {
        position: absolute;
        left: 90%;
        top: 0%;
    }
    & > span > button {
        opacity: 0;
        transition: all 0.4s;
        pointer-events: none;
        transform: translateX(20px);
        &:hover {
            background-color: #d55506;
        }
    }
    &:hover > img + span > button {
        opacity: 100%;
        pointer-events: unset;
        cursor: pointer;
        transform: translateX(0);
    }
`;

export const Image = styled.img`
    object-fit: contain;
    width: 100%;
    cursor: zoom-in;
    max-height: 60vh;
    height: 100%;
    ${({ disabled }) =>
        disabled &&
        `
        cursor: not-allowed;
        pointer-events: none;
    `}
`;

export const ToggleControl = styled(FormControlLabel)`
    width: 30%;
    flex: 1;
`;

export const ToggleWrapper = ({ text, checked, disabled, name, onChange }) => {
    return (
        <ToggleControl
            control={<Switch disabled={disabled} checked={checked} name={name} onChange={onChange} />}
            label={
                <Typography variant="span" style={{ fontSize: '12px' }}>
                    {text}
                </Typography>
            }
        />
    );
};

export const ImageComponent = ({ label, fileName, url, imageOnClick, onDownload, isCurrentImg }) => {
    return (
        <ImageComponentWrapper isCurrentImg={isCurrentImg} container direction="column" alignItems="center">
            <Typography variant="span">{label}</Typography>
            <Image
                disabled={!url}
                onClick={() => imageOnClick(`${fileName}.png`)}
                src={url || fallback}
                alt="map"
            />
            <Tooltip content="Download as html">
                {url && (
                    <DownloadButton onClick={() => onDownload(`${fileName}.html`)} aria-label="download">
                        <FiDownload color="white" size={20} />
                    </DownloadButton>
                )}
            </Tooltip>
        </ImageComponentWrapper>
    );
};

function ValueLabelComponent(props) {
    const { children, value } = props;
    return (
        <CustomTooltip
            PopperComponent={() => <div>{value}</div>}
            placement="top"
            title={<div style={{ backgroundColor: 'orange' }}>{value}</div>}
        >
            {children}
        </CustomTooltip>
    );
}

export const SliderContainer = React.forwardRef(({ day, date, max, onSliderChanged, ...props }, ref) => {
    const step = 100 / (max - 1);
    const daysBasedOnValidation = Math.min(props.daysBasedOn, day);
    const daysBasedOnHealing = Math.min(props.daysBasedOnHealing, day);
    const computedValidationDays = daysBasedOnValidation * step;
    const totalValidationAndHealing = daysBasedOnHealing + daysBasedOnValidation;
    const computedHealingDays = Math.min(totalValidationAndHealing, day) * step;
    const activeStepOffset = `${day * step}%`;
    return (
        <Grid container>
            <CustomSlider
                ref={ref}
                offset={activeStepOffset}
                marks
                min={1}
                max={max}
                defaultValue={max}
                onChange={onSliderChanged}
                validationDays={computedValidationDays}
                healingDays={computedHealingDays}
                // ValueLabelComponent={ValueLabelComponent}
                {...props}
            />
            <Grid container direction="row" justifyContent="space-between">
                <Day>Day 1</Day>
                <Day>
                    Day {day} ({date})
                </Day>
                <Day>Day {max}</Day>
            </Grid>
        </Grid>
    );
});

export const ButtonWrapper = ({ lastUpdatedDate, onClick, loading, disabled }) => {
    const formatDate = lastUpdatedDate.slice(0, 10);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <PublishButton disabled={disabled} onClick={onClick}>
                {loading ? <CircularPreloader size={20} /> : 'Publish'}
            </PublishButton>
            <Typography style={{ fontSize: '12px' }}>{formatDate}</Typography>
            <Typography style={{ fontSize: '12px' }}>Last Grid Updated</Typography>
        </div>
    );
};
