export const getBuildingsSelectId = () => `mapping-side-menu-buildings-select`;
export const getFloorsSelectId = () => `mapping-side-menu-floors-select`;
export const getMapsSelectId = () => `mapping-side-menu-maps-select`;

export const getLaneListEntryId = (laneId) => `mapping-side-menu-lane-list-entry-${laneId}`;
export const getLaneListEntryNameId = (laneId) => `mapping-side-menu-lane-list-entry-name-${laneId}`;
export const getLaneListEntryMapperId = (laneId) => `mapping-side-menu-lane-list-entry-mapper-name-${laneId}`;
export const getLaneListEntryDeleteId = (laneId) => `mapping-side-menu-lane-list-entry-delete-${laneId}`;
export const getLaneListEntryResolveId = (laneId) => `mapping-side-menu-lane-list-entry-resolve-${laneId}`;
export const getLaneListEntryResolveMenuId = (laneId) =>
    `mapping-side-menu-lane-list-entry-resolve-menu-${laneId}`;
export const getLaneListEntryResolveMenuApproveId = (laneId) =>
    `mapping-side-menu-lane-list-entry-resolve-menu-approve-${laneId}`;
export const getLaneListEntryResolveMenuDeclineId = (laneId) =>
    `mapping-side-menu-lane-list-entry-resolve-menu-decline-${laneId}`;
export const getLaneListEntryChangeLayoutId = (laneId) =>
    `mapping-side-menu-lane-list-entry-change-layout-${laneId}`;
export const getLaneListEntryChangeLayoutMenuId = (laneId) =>
    `mapping-side-menu-lane-list-entry-change-layout-menu-${laneId}`;
export const getLaneListEntryChangeLayoutMenuSendToBackId = (laneId) =>
    `mapping-side-menu-lane-list-entry-change-layout-menu-send-to-back-${laneId}`;
export const getLaneListEntryChangeLayoutMenuBringToFrontId = (laneId) =>
    `mapping-side-menu-lane-list-entry-change-layout-menu-bring-to-front-${laneId}`;
export const getLaneListEntryShowInfoId = (laneId) => `mapping-side-menu-lane-list-entry-show-info-${laneId}`;

export const getMapperListEntryId = (mapperId) => `mapping-side-menu-mapper-list-entry-${mapperId}`;
export const getMapperListEntryNameId = (mapperId) => `mapping-side-menu-mapper-list-entry-name-${mapperId}`;
export const getMapperListEntryToggleSuperMapperId = (mapperId) =>
    `mapping-side-menu-mapper-list-entry-toogle-super-mapper-${mapperId}`;
export const getMapperListEntryToggleSuspendMapperId = (mapperId) =>
    `mapping-side-menu-mapper-list-entry-toogle-suspend-mapper-${mapperId}`;

export const getAreaOfInterestListEntryId = (areaId) =>
    `mapping-side-menu-area-of-interest-list-entry-${areaId}`;
export const getAreaOfInterestDeleteId = (areaId) => `aoi-delete-${areaId}`;
export const getAreaOfInterestShowInfoId = (areaId) => `aoi-show-info-${areaId}`;
export const getAreaOfInterestChangeLayoutId = (areaId) => `aoi-change-layout-${areaId}`;
export const getAreaOfInterestChangeLayoutMenuId = (areaId) => `aoi-change-layout-menu-${areaId}`;
export const getAreaOfInterestSendToBackId = (areaId) => `aoi-change-layout-menu-send-to-back-${areaId}`;
export const getAreaOfInterestBringToFrontId = (areaId) => `aoi-change-layout-menu-bring-to-front-${areaId}`;
export const getAreaOfInterestListEntryCreatedAt = (areaId) =>
    `mapping-side-menu-area-of-interest-list-entry-createdAt-${areaId}`;

export const getLineOfInterestDeleteId = (lineId) => `loi-delete-${lineId}`;

export const getElementListEntryId = (elementId) => `mapping-side-menu-element-list-entry-${elementId}`;

export const getLineOfInterestListEntryId = (elementId) =>
    `mapping-side-menu-line-of-interest-list-entry-${elementId}`;
