import Api from './Api';
import http from '../utils/httpClient';

class BuildingsApi extends Api {
    async fetchAllBuildingsInSpace(spaceId) {
        return await this.callApi((params) =>
            http.v4.get(`/space/${spaceId}/buildings`, { params: { ...params, spaceId } })
        );
    }

    async fetchBuildingFullData(buildingId) {
        return await this.callApi((params) =>
            http.v7.get(`/buildings/${buildingId}`, {
                params: { ...params, includeEntrances: true, includeExternalRegions: true },
            })
        );
    }

    async createBuilding(buildingData, spaceId) {
        return await this.callApi((params) =>
            http.v3.post(`/buildings`, buildingData, { params: { ...params, spaceId } })
        );
    }

    async updateBuilding(buildingId, buildingData) {
        return await this.callApi((params) =>
            http.v3.post(`/buildings/${buildingId}`, buildingData, { params })
        );
    }

    async updateBuildingState(buildingId, buildingState) {
        return await this.callApi((params) =>
            http.v3.post(`/buildings/${buildingId}/building-state`, { buildingState }, { params })
        );
    }

    async deleteBuilding(buildingId) {
        return await this.callApi((params) => http.v3.delete(`/buildings/${buildingId}`, { params }));
    }

    async attachBuildingToClient(buildingId, clientBuildingId) {
        return this.callApi((params) =>
            http.v1.post(`buildings/${buildingId}/client`, { clientBuildingId }, { params })
        );
    }

    async detachBuildingFromClient(buildingId) {
        return this.callApi((params) => http.v1.delete(`buildings/${buildingId}/client`, { params }));
    }
    async validateRouting(buildingId) {
        return this.callApi((params) =>
            http.v7.post(`/buildings/${buildingId}/validate-routing`, {}, { params })
        );
    }
}

export default new BuildingsApi();
