import React, { useEffect, useState } from 'react';
import MapViewerWebSocketStatusOverlay from '../../common/map-viewer/overlays/websocket-status';
import eventsManagerWs from '../../../ws/events-manager/eventsManagerWs';

export const WebSocketStatusOverlay = () => {
    const [readyState, setReadyState] = useState(eventsManagerWs.ws?.readyState ?? WebSocket.CLOSED);

    useEffect(() => {
        eventsManagerWs.setOnReadyStateChange(setReadyState);
    }, []);

    return <MapViewerWebSocketStatusOverlay readyState={readyState} />;
};
