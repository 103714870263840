import { Pool, spawn } from 'threads';
import AnalyticsManager from '../../../api/AnalyticsManager';
import {
    getSessionAggregationsRawData,
    getAnalyticsFilters,
    getSessionAggregations,
    getSessionsAggregationType,
    getAllSessionsWithPositions,
    getAllSessionsObject,
    getSession,
    getSelectedSessionWithPositions,
} from './sessionAnalyticsSelectors';
import { splitRawSessionsByClient, splitRawSessionsByBuilding } from './sessionAnalyticsUtils';
import { selectAnalyticsUserActivityUserInputs } from '../../user-inputs/analyticsUserActivitySlice';
import { selectBuildingIdToMapArray } from '../../building/buildingSelectors';
import { selectFloorIdToMapsArray } from '../../floor/floorSelectors';
import { selectCommonSelectedSpaceId } from '../../user-inputs/commonSlice';

let pool;

const processingScriptPath = `${document.location.origin}/sessionProcessing.js`;
const momentCdnUrl = 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/moment.min.js';

export const AGG_TYPE_BY_CLIENT = 'AGG_TYPE_BY_CLIENT';
export const AGG_TYPE_BY_BUILDING = 'AGG_TYPE_BY_BUILDING';

export const SET_FILTERS = 'SET_FILTERS';

export const CALCULATE_SESSION_AGGREGATIONS = 'CALCULATE_SESSION_AGGREGATIONS';

export const FETCH_RAW_SESSIONS_REQUEST = 'FETCH_RAW_SESSIONS_REQUEST';
export const FETCH_RAW_SESSIONS_SUCCESS = 'FETCH_RAW_SESSIONS_SUCCESS';
export const FETCH_RAW_SESSIONS_ERROR = 'FETCH_RAW_SESSIONS_ERROR';

export const PROCESS_ALL_RAW_SESSIONS_REQUEST = 'PROCESS_ALL_RAW_SESSIONS_REQUEST';
export const PROCESS_ALL_RAW_SESSIONS_SUCCESS = 'PROCESS_ALL_RAW_SESSIONS_SUCCESS';
export const PROCESS_ALL_RAW_SESSIONS_ERROR = 'PROCESS_ALL_RAW_SESSIONS_ERROR';

export const PROCESS_RAW_SESSION_REQUEST = 'PROCESS_RAW_SESSION_REQUEST';
export const PROCESS_RAW_SESSION_SUCCESS = 'PROCESS_RAW_SESSION_SUCCESS';
export const PROCESS_RAW_SESSION_ERROR = 'PROCESS_RAW_SESSION_ERROR';

export const SET_SELECTED_SESSION = 'SET_SELECTED_SESSION';

export const PROCESS_SELECTED_SESSION_REQUEST = 'PROCESS_SELECTED_SESSION_REQUEST';
export const PROCESS_SELECTED_SESSION_SUCCESS = 'PROCESS_SELECTED_SESSION_SUCCESS';
export const PROCESS_SELECTED_SESSION_ERROR = 'PROCESS_SELECTED_SESSION_ERROR';

export const AGGREGATE_PROCESSED_SESSIONS_REQUEST = 'AGGREGATE_PROCESSED_SESSIONS_REQUEST';
export const AGGREGATE_PROCESSED_SESSIONS_SUCCESS = 'AGGREGATE_PROCESSED_SESSIONS_SUCCESS';
export const AGGREGATE_PROCESSED_SESSIONS_ERROR = 'AGGREGATE_PROCESSED_SESSIONS_ERROR';

const JOB_TYPE_PROCESS = 'process';
const JOB_TYPE_AGGREGATE = 'aggregate';

const initializeWorkerPool = () => {
    pool = new Pool(window.navigator.hardwareConcurrency - 1);

    // Implement the job queue as sort of a priority queue, giving priority to aggregation jobs
    function priorityPush(job) {
        const { jobType } = job.sendArgs[0];
        if (jobType === JOB_TYPE_AGGREGATE) {
            pool.jobQueue.splice(0, 0, job);
        } else {
            Array.prototype.push.call(this, job);
        }
    }

    pool.jobQueue.push = priorityPush;
};

export const setFilters = (filters) => ({
    type: SET_FILTERS,
    payload: filters,
});

export const processAllRawSessions = () => async (dispatch, getState) => {
    dispatch({ type: PROCESS_ALL_RAW_SESSIONS_REQUEST });

    try {
        // If we're in the middle of processing sessions, cancel them all
        if (pool && pool.jobQueue.length > 0) {
            pool.killAll();
        }

        initializeWorkerPool();

        const rawSessions = getAllSessionsWithPositions(getState());
        const filters = getAnalyticsFilters(getState());
        const aggregations = getSessionAggregations(getState());

        const sessionIdToAggId = {};
        const aggIdToProcessedSessionIds = {};

        // Create a mapping between aggregation IDs and session IDs (with indication of whether or
        // not they're processed) and also a mapping between session IDs and aggregation IDs
        aggregations.forEach(({ aggregationId, sessionIds }) => {
            aggIdToProcessedSessionIds[aggregationId] = sessionIds.reduce(
                (result, sessionId) => ({
                    ...result,
                    [sessionId]: { isProcessed: false },
                }),
                {}
            );

            sessionIds.forEach((sessionId) => {
                sessionIdToAggId[sessionId] = aggregationId;
            });
        });

        const handleSessionProcessed = (result) => {
            dispatch({
                type: PROCESS_RAW_SESSION_SUCCESS,
                payload: result,
            });

            // Mark the session as processed
            const aggId = sessionIdToAggId[result.sessionId];
            aggIdToProcessedSessionIds[aggId][result.sessionId].isProcessed = true;

            const aggSessions = Object.values(aggIdToProcessedSessionIds[aggId]);

            // If the session's aggregation now has all his sessions processed
            if (aggSessions.every((s) => s.isProcessed)) {
                dispatch({
                    type: AGGREGATE_PROCESSED_SESSIONS_REQUEST,
                });

                // Get the aggregation's processed sessions
                const sessionIds = Object.keys(aggIdToProcessedSessionIds[aggId]);
                const processedSessions = getAllSessionsObject(getState());
                const sessions = sessionIds.map((id) => processedSessions[id]);

                // Send those sessions for aggregation
                pool.send({
                    jobType: JOB_TYPE_AGGREGATE,
                    args: { sessions, aggregationId: aggId },
                });
            }
        };

        const handleSessionsAggregated = (result) => {
            dispatch({
                type: AGGREGATE_PROCESSED_SESSIONS_SUCCESS,
                payload: result,
            });
        };

        pool.run(
            processingScriptPath,
            [momentCdnUrl] // dependencies; resolved using node's require() or the web workers importScript()
        )
            .on('done', (job, result) => {
                const { jobType } = job.sendArgs[0];

                switch (jobType) {
                    case JOB_TYPE_PROCESS:
                        handleSessionProcessed(result);
                        break;
                    case JOB_TYPE_AGGREGATE:
                        handleSessionsAggregated(result);
                        break;
                    default:
                        break;
                }
            })
            .on('error', (job, error) => {
                const { jobType } = job.sendArgs[0];

                switch (jobType) {
                    case JOB_TYPE_PROCESS:
                        dispatch({ type: PROCESS_RAW_SESSION_ERROR, error });
                        break;
                    case JOB_TYPE_AGGREGATE:
                        dispatch({
                            type: AGGREGATE_PROCESSED_SESSIONS_ERROR,
                            error,
                        });
                        break;
                    default:
                        break;
                }
            })
            .on('finished', () => {
                dispatch({
                    type: PROCESS_ALL_RAW_SESSIONS_SUCCESS,
                });
                pool.killAll();
            });

        rawSessions.forEach((session) => {
            pool.send({
                jobType: JOB_TYPE_PROCESS,
                args: {
                    session,
                    filters,
                    mapsRegionCategoryLevels: aggregations.find(
                        (a) => a.aggregationId === sessionIdToAggId[session.sessionId]
                    ).mapsRegionCategoryLevels,
                },
            });
        });
    } catch (error) {
        dispatch({ type: PROCESS_ALL_RAW_SESSIONS_ERROR, error });
        pool.killAll();
    }
};

export const calculateSessionAggregations = (aggregationType) => async (dispatch, getState) => {
    // If we're in the middle of processing sessions, cancel them all
    if (pool && pool.jobQueue.length > 0) {
        pool.killAll();
    }

    const data = getSessionAggregationsRawData(getState());

    let result;
    switch (aggregationType) {
        case AGG_TYPE_BY_CLIENT:
            result = splitRawSessionsByClient(data);
            break;
        case AGG_TYPE_BY_BUILDING:
            result = splitRawSessionsByBuilding(data);
            break;
        default:
            result = [];
            break;
    }

    dispatch({
        type: CALCULATE_SESSION_AGGREGATIONS,
        payload: { aggregationType, ...result },
    });

    if (Object.keys(result.sessionsById).length > 0) {
        dispatch(processAllRawSessions());
    }
};

export const fetchRawSessions =
    (startProcessing = true) =>
    async (dispatch, getState) => {
        dispatch({ type: FETCH_RAW_SESSIONS_REQUEST });

        try {
            // If we're in the middle of processing sessions, cancel them all
            if (pool && pool.jobQueue.length > 0) {
                pool.killAll();
            }

            const {
                timeSpanStart,
                timeSpanEnd,
                selectedBuildingId,
                selectedFloorId,
                selectedMapIds,
                timeZone,
                sessionId,
            } = selectAnalyticsUserActivityUserInputs(getState());

            const spaceId = selectCommonSelectedSpaceId(getState());
            const { [selectedBuildingId]: mapsInBuilding = [] } = selectBuildingIdToMapArray(getState());
            const { [selectedFloorId]: mapsInFloor = [] } = selectFloorIdToMapsArray(getState());

            let mapIds = [];
            if (selectedMapIds.length > 0) {
                mapIds = selectedMapIds;
            } else if (selectedFloorId) {
                mapIds = mapsInFloor.map((m) => m.mapId);
            } else if (selectedBuildingId) {
                mapIds = mapsInBuilding.map((m) => m.mapId);
            }

            const positions = await AnalyticsManager.getPositionsWithRegions({
                startTimestamp: timeSpanStart,
                endTimestamp: timeSpanEnd,
                spaceId,
                buildingId: selectedBuildingId,
                mapIds,
                desiredTimeZoneOffset: timeZone.utcOffset,
                sessionId,
            });

            if (positions.error) {
                throw new Error('Failed to fetch positions with regions');
            }

            dispatch({ type: FETCH_RAW_SESSIONS_SUCCESS, payload: positions.data });

            if (positions.data.length > 0 && startProcessing) {
                const aggType = getSessionsAggregationType(getState());
                dispatch(calculateSessionAggregations(aggType));
            }
        } catch (err) {
            dispatch({ type: FETCH_RAW_SESSIONS_ERROR, error: err.message });
        }
    };

export const setSelectedSession = (sessionId) => async (dispatch, getState) => {
    dispatch({
        type: SET_SELECTED_SESSION,
        payload: sessionId ? getSession(getState(), sessionId) : null,
    });
};

export const processSelectedSession = (filters) => async (dispatch, getState) => {
    dispatch({
        type: PROCESS_SELECTED_SESSION_REQUEST,
    });

    try {
        const session = getSelectedSessionWithPositions(getState());
        const processingFilters = filters || selectAnalyticsUserActivityUserInputs(getState());
        const aggregations = getSessionAggregations(getState());

        const thread = spawn(
            processingScriptPath,
            [momentCdnUrl] // dependencies; resolved using node's require() or the web workers importScript()
        )
            .send({
                jobType: JOB_TYPE_PROCESS,
                args: {
                    session,
                    filters: { ...processingFilters, mapIds: processingFilters.selectedMapIds },
                    mapsRegionCategoryLevels: aggregations.find((a) =>
                        a.sessionIds.includes(session.sessionId)
                    ).mapsRegionCategoryLevels,
                },
            })
            .on('done', (result) => {
                dispatch({
                    type: PROCESS_SELECTED_SESSION_SUCCESS,
                    payload: result,
                });
                thread.kill();
            })
            .on('error', (job, error) => {
                dispatch({ type: PROCESS_SELECTED_SESSION_ERROR, error });
                thread.kill();
            });
    } catch (error) {
        dispatch({ type: PROCESS_SELECTED_SESSION_ERROR, error });
    }
};
