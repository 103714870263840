import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './laneEntityAdapter';
import { expireSession, logout } from '../../auth/authActions';
import {
    fetchLane,
    fetchLaneAttachments,
    deleteLane,
    updateLane,
    lanesFetched,
    fetchAllLanesInMap,
    fetchAllLaneStatusesInMap,
    cropLanes,
} from './laneActions';
import { laneCreated, laneDeleted, laneUpdated, mapEqualizationFinished } from '../mappingEventsActions';

const { getInitialState, upsertMany, upsertOne, removeOne, setAll, removeMany } = entityAdapter;

export const { reducer } = createSlice({
    name: 'lanes',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchAllLanesInMap.pending]: (state) => {
            setAll(state, []);
        },

        [lanesFetched]: (state, action) => {
            upsertMany(
                state,
                action.payload.data.map((lane) => ({
                    laneId: lane.laneId,
                    points: lane.points,
                    equalizedStatus: lane.status,
                    unequalizedStatus: null,
                    comments: lane.comment ? [lane.comment] : [],
                    createdAt: lane.createdAt,
                    hasAttachments: lane.hasAttachments,
                    mapperId: lane.mapperId,
                    mapperName: lane.mapperName,
                    isMigrated: lane.migrated,
                    transform: lane.transform,
                }))
            );
        },

        [fetchLane.fulfilled]: (state, action) => {
            upsertOne(state, {
                laneId: action.payload.laneId,
                points: action.payload.points,
                equalizedStatus: action.payload.status,
                unequalizedStatus: null,
                comments: action.payload.comment ? [action.payload.comment] : [],
                attachments: [action.payload.attachment],
                createdAt: action.payload.createdAt,
                mapperId: action.payload.mapperId,
                mapperName: action.payload.mapperName,
                isMigrated: action.payload.migrated,
                transform: action.payload.transform,
            });
        },

        [fetchLaneAttachments.fulfilled]: (state, action) => {
            const { laneId } = action.meta.arg;
            upsertOne(state, { laneId, attachments: [action.payload.attachment] });
        },

        [updateLane.fulfilled]: (state, action) => {
            upsertOne(state, {
                laneId: action.payload.laneId,
                points: action.payload.points,
                equalizedStatus: action.payload.status,
                comments: action.payload.comment ? [action.payload.comment] : [],
                attachments: [action.payload.attachment],
                createdAt: action.payload.createdAt,
                mapperId: action.payload.mapperId,
                mapperName: action.payload.mapperName,
                isMigrated: action.payload.migrated,
                transform: action.payload.transform,
            });
        },

        [deleteLane.fulfilled]: (state, action) => {
            const { laneId } = action.meta.arg;
            removeOne(state, laneId);
        },

        [laneCreated]: (state, action) => {
            upsertOne(state, {
                laneId: action.payload.data.laneId,
                points: action.payload.data.points,
                equalizedStatus: action.payload.data.status,
                unequalizedStatus: null,
                comments: action.payload.data.comment ? [action.payload.data.comment] : [],
                attachments: action.payload.data.attachment ? [action.payload.data.attachment] : [],
                hasAttachments: !!action.payload.data.attachment,
                createdAt: action.payload.data.createdAt,
                mapperId: action.payload.data.mapperId,
                mapperName: action.payload.data.mapperName,
                isMigrated: action.payload.data.migrated,
                transform: action.payload.data.transform,
            });
        },

        [laneUpdated]: (state, action) => {
            upsertOne(state, {
                laneId: action.payload.data.laneId,
                points: action.payload.data.points,
                equalizedStatus: action.payload.data.status,
                comments: action.payload.data.comment ? [action.payload.data.comment] : [],
                attachments: action.payload.data.attachment ? [action.payload.data.attachment] : [],
                hasAttachments: !!action.payload.data.attachment,
                createdAt: action.payload.data.createdAt,
                mapperId: action.payload.data.mapperId,
                mapperName: action.payload.data.mapperName,
                isMigrated: action.payload.data.migrated,
                transform: action.payload.data.transform,
            });
        },

        [laneDeleted]: (state, action) => {
            const { laneId } = action.payload.data;
            removeOne(state, laneId);
        },

        [cropLanes.fulfilled]: (state, action) => {
            const { deletedLaneIds = [], lanes = [] } = action.payload;
            removeMany(state, deletedLaneIds);
            
            upsertMany(
                state,
                lanes.map((lane) => ({
                    laneId: lane.laneId,
                    points: lane.points,
                    equalizedStatus: lane.status,
                }))
            );
        },

        [fetchAllLaneStatusesInMap.fulfilled]: (state, action) => {
            const { useEqualizedMap } = action.meta.arg;
            upsertMany(
                state,
                action.payload.map(({ laneId, status }) =>
                    useEqualizedMap
                        ? { laneId, equalizedStatus: status }
                        : { laneId, unequalizedStatus: status }
                )
            );
        },

        [mapEqualizationFinished]: (state, action) => {
            const { lanes = [], error } = action.payload.data;

            if (!error) {
                upsertMany(
                    state,
                    lanes.map(({ laneId, status, transform }) => ({
                        laneId,
                        equalizedStatus: status,
                        unequalizedStatus: null,
                        transform,
                    }))
                );
            }
        },

        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
    },
});
