import React, { Fragment, memo, useContext, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { MapViewerContext } from '../../MapViewerContext';
import { useDispatch, useSelector } from 'react-redux';
import { getMapViewerLaneBorderId, getMapViewerLaneId } from './MapViewerLanesOverlay.selectors';
import {
    getSelectIsLaneHighlighted,
    getSelectIsLaneSelected,
    highlightLane,
    selectMappingLaneIds,
    selectMappingSelectedLaneId,
    setSelectedLaneId,
    unhighlightLane,
} from '../../../../../state-management/user-inputs/mappingSlice';
import { MdChat } from 'react-icons/md';
import { getLaneColor } from '../../../../../utils/laneUtils';
import {
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import clsx from 'clsx';

const commentIconSize = 40;

const CommentIcon = styled(MdChat)`
    fill: #ff6200; // TODO color should come from theme
    cursor: pointer;
    transition: fill 300ms ease;
    width: calc(${commentIconSize}px / var(${SCALE}));
    height: calc(${commentIconSize}px / var(${SCALE}));

    :hover {
        fill: #ff6200; // TODO color should come from theme
    }
`;

const CommentIconWrapper = styled.foreignObject`
    transform: translateY(calc(-${commentIconSize}px / var(${SCALE})));
    width: calc(50px / var(${SCALE}));
    height: calc(50px / var(${SCALE}));
    overflow: visible;
`;

const Line = styled.polyline`
    fill: none;
    stroke: ${(props) => props.$color};
    stroke-width: calc((1px * var(${PIXEL_TO_METER})) * 0.1); // The line should represent 0.1 meters
    stroke-linecap: round;
    stroke-linejoin: round;
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
    ${({ $disabled }) => {
        return css`
            &.highlighted {
                    opacity: 0.7;
                }
            &.selected {
                    stroke: #7a007a;
                }
        `;
    }}
`;

const LineBorder = styled.polyline`
    fill: none;
    stroke: ${(props) => props.$color};
    stroke-width: calc((1px * var(${PIXEL_TO_METER})) * 1.25); // The border should represent 1.4 meters
    stroke-linecap: round;
    stroke-linejoin: round;
    opacity: 0.35;
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

    ${({ $disabled }) => {
        if (!$disabled) {
            return css`
                &.highlighted {
                    opacity: 0.7;
                }
                &.selected {
                    stroke: #7a007a;
                }
            `;
        }
    }}
`;
function Lane(props) {
    const { lane, applyTransform = true, disabled, isSelected, onLaneSelected } = props;
    const { laneId, points = [], transform, comments, hasAttachments } = lane ?? {};
    const { scale = 1, angle = 0, dx = 0, dy = 0 } = transform ?? {};

    const dispatch = useDispatch();

    const { getCSSVariable } = useContext(MapViewerContext);

    const selectIsLaneHighlighted = useMemo(() => getSelectIsLaneHighlighted(laneId), [laneId]);

    const selectedLaneId = useSelector(selectMappingSelectedLaneId);

    const isHighlighted = useSelector(selectIsLaneHighlighted) || selectedLaneId === laneId;
    const isMultiLine = useMemo(() => points.some((p) => p.x == null || p.y == null), [points]);
    const polylinePoints = useMemo(() => {
        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));
        let polylineStringBuilder = '';
        const newPoints = points?.reduce((result, point, index, arr) => {
            let { x, y } = point;

            if (!x || !y) {
                result.push(polylineStringBuilder);
                polylineStringBuilder = '';
                return result;
            }

            if (applyTransform) {
                x = scale * (x * Math.cos(angle) - y * Math.sin(angle)) + dx;
                y = scale * (x * Math.sin(angle) + y * Math.cos(angle)) + dy;
            }

            x = (x - mapOffsetX) * pixelToMeter;
            y = (imageNaturalHeight / pixelToMeter - (y - mapOffsetY)) * pixelToMeter;

            polylineStringBuilder += `${x},${y}` + ' ';
            if (index === arr.length - 1) {
                result.push(polylineStringBuilder);
            }

            return result;
        }, []);

        return newPoints;
    }, [angle, applyTransform, dx, dy, getCSSVariable, points, scale]);

    const middlePoint = useMemo(() => {
        const midPoint = points?.[Math.floor(points.length / 2)];

        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        let { x, y } = midPoint || {};

        if (applyTransform) {
            x = scale * (x * Math.cos(angle) - y * Math.sin(angle)) + dx;
            y = scale * (x * Math.sin(angle) + y * Math.cos(angle)) + dy;
        }

        return {
            x: (midPoint?.x - mapOffsetX) * pixelToMeter,
            y: (imageNaturalHeight / pixelToMeter - (midPoint?.y - mapOffsetY)) * pixelToMeter,
        };
    }, [angle, applyTransform, dx, dy, getCSSVariable, points, scale]);

    const handlePointerEnter = () => {
        if (disabled) return;
        dispatch(highlightLane(laneId));
    };

    const handlePointerLeave = () => {
        if (disabled) return;
        dispatch(unhighlightLane(laneId));
    };

    const handlePointerDown = () => {

        // dispatch(setSelectedLaneId(selectedLaneId === laneId ? null : laneId));
    };

    if (!lane) {
        return null;
    }

    const events = {
        onPointerEnter: disabled ? undefined : handlePointerEnter,
        onPointerLeave: disabled ? undefined : handlePointerLeave,
        onPointerDown: disabled ? undefined : (e) => onLaneSelected(laneId, e.shiftKey),
        $disabled: disabled,
    };

    return (
        <g id={getMapViewerLaneId(laneId)}>
            {!isMultiLine && (
                <LineBorder
                    id={getMapViewerLaneBorderId(laneId)}
                    className={clsx({ highlighted: isHighlighted, selected: isSelected })}
                    points={polylinePoints}
                    $color={getLaneColor(lane, applyTransform, disabled)}
                    {...events}
                />
            )}
            {!isMultiLine ? (
                <Line
                    key={laneId}
                    className={clsx({ highlighted: isHighlighted, selected: isSelected })}
                    points={polylinePoints}
                    $color={getLaneColor(lane, applyTransform, disabled)}
                    {...events}
                />
            ) : (
                polylinePoints.map((points, i) => {
                    return (
                        <Fragment key={i}>
                            <LineBorder
                                id={getMapViewerLaneBorderId(laneId)}
                                className={clsx({ highlighted: isHighlighted, selected: isSelected })}
                                points={points}
                                $color={getLaneColor(lane, applyTransform, disabled)}
                                {...events}
                            />
                            <Line
                                key={i}
                                className={clsx({ highlighted: isHighlighted, selected: isSelected })}
                                points={points}
                                $color={getLaneColor(lane, applyTransform)}
                                {...events}
                            />
                        </Fragment>
                    );
                })
            )}
            {!disabled && (comments?.length > 0 || !!hasAttachments) && (
                <CommentIconWrapper x={middlePoint?.x} y={middlePoint?.y}>
                    <CommentIcon onClick={() => dispatch(setSelectedLaneId(laneId))} size={commentIconSize} />
                </CommentIconWrapper>
            )}
        </g>
    );
}

Lane.propTypes = {
    lane: PropTypes.object.isRequired,
    applyTransform: PropTypes.bool,
};

export default memo(Lane);
