import moment from 'moment';
import TimeResolution from './TimeResolution';

class OneHour extends TimeResolution {
    get inMinutes() {
        return 60;
    }

    get label() {
        return 'Hour';
    }

    getTimeIntervalString(start, end) {
        return `${moment(start)
            .utc()
            .add(this.timeZoneOffset, 'minutes')
            .format('MMM Do HH:mm')}-${moment(end)
            .utc()
            .add(1, 'millisecond')
            .add(this.timeZoneOffset, 'minutes')
            .format('HH:mm')}`;
    }

    get unitType() {
        return 'hour';
    }

    get unitCount() {
        return 1;
    }

    getFittingTimeInterval(utcTimestamp) {
        return {
            start: moment(utcTimestamp)
                .utc()
                .startOf('hour')
                .toISOString(),
            end: moment(utcTimestamp)
                .utc()
                .endOf('hour')
                .toISOString(),
        };
    }

    getTimeIntervalsInRange(start, end, options) {
        return super.getTimeIntervalsInRange(start, end).filter(
            ({ start, end }) =>
                (!this.startHourUtc ||
                    moment(start)
                        .utc()
                        .add(this.timeZoneOffset, 'minutes')
                        .hour() >= this.startHourUtc) &&
                (!this.endHourUtc ||
                    moment(end)
                        .utc()
                        .add(this.timeZoneOffset, 'minutes')
                        .hour() < this.endHourUtc)
        );
    }
}

export default new OneHour();
