import { createAsyncThunk } from '../utils';
import buildingsApi from '../../api/BuildingsApi';
import { selectUserSpaceIds } from '../auth/authSelectors';

export const fetchAllBuildingsInSpace = createAsyncThunk('buildings/fetchAllInSpace', async (spaceId) => {
    const result = (await buildingsApi.fetchAllBuildingsInSpace(spaceId)).data;
    return result.map((r) => ({ ...r, spaceId }));
});

export const fetchAllBuildingsInAllSpaces = createAsyncThunk(
    'buildings/fetchAllInAllSpaces',
    async (_, { getState, rejectWithValue }) => {
        const spaceIds = selectUserSpaceIds(getState());

        const results = await Promise.all(
            spaceIds.map(
                (spaceId) =>
                    new Promise((resolve) => {
                        buildingsApi.fetchAllBuildingsInSpace(spaceId).then((response) => {
                            resolve({ response, spaceId });
                        });
                    })
            )
        );

        const result = [];
        for (const { response, spaceId } of results) {
            // Check if each request failed or succeeded
            if (response.error) {
                rejectWithValue(`${response.message}`);
            } else {
                result.push({ spaceId, buildings: response.data });
            }
        }

        return result;
    }
);

export const fetchBuildingFullData = createAsyncThunk(
    'buildings/fetchFullData',
    async ({ spaceId, buildingId }) => {
        const result = (await buildingsApi.fetchBuildingFullData(buildingId)).data;
        return { ...result, spaceId };
    }
);

export const createBuilding = createAsyncThunk('buildings/create', async ({ buildingData, spaceId }) => {
    return (await buildingsApi.createBuilding(buildingData, spaceId)).data;
});

export const updateBuilding = createAsyncThunk('buildings/update', async ({ buildingId, buildingData }) => {
    return (await buildingsApi.updateBuilding(buildingId, buildingData)).data;
});

export const updateBuildingState = createAsyncThunk(
    'building/update-state',
    async ({ buildingId, buildingState }) => {
        return (await buildingsApi.updateBuildingState(buildingId, buildingState)).data;
    }
);

export const deleteBuilding = createAsyncThunk('buildings/delete', async (buildingId) => {
    return (await buildingsApi.deleteBuilding(buildingId)).data;
});

export const attachBuildingToClient = createAsyncThunk(
    'buildings/attach-client',
    async ({ buildingId, clientBuildingId }) => {
        return (await buildingsApi.attachBuildingToClient(buildingId, clientBuildingId)).data;
    }
);

export const detachBuildingFromClient = createAsyncThunk(
    'buildings/detach-client',
    async ({ buildingId }) => {
        return buildingsApi.detachBuildingFromClient(buildingId);
    }
);
export const validateBuildingRouting = createAsyncThunk(
    'buildings/validateRouting',
    async ({ buildingId }, { rejectWithValue }) => {
        try {
            await buildingsApi.validateRouting(buildingId);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
