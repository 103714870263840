import { falsyCheckedWithoutZero } from '../../utils/general';

export const mapQualityKeysOperations = {
    daysBasedOn: (value) => (falsyCheckedWithoutZero(value) ? null : Math.round(value)),
};

export const parsedTableRecord = (record, additionalData = {}) => {
    return {
        ...record,
        showDetailPanel: !!record.tableData?.showDetailPanel,
        daysBasedOn: mapQualityKeysOperations.daysBasedOn(record.daysBasedOn),
        ...additionalData,
    };
};
