export const columns = [
    {
        id: 'mapName',
        label: 'Map Name'
    },
    {
        id: 'mapperName',
        label: 'Mapper Name',
    },
    {
        id: 'lastLane',
        label: 'Last Lane',
    },
    {
        id: 'totalDistance',
        label: 'Total Distance',
        suffix: 'm',
    },
    {
        id: 'lastHourDistance',
        label: 'Last Hour Distance',
        suffix: 'm',
    },
    {
        id: 'calibTotalFailed',
        label: 'Total Failed Calibrations',
    },
    {
        id: 'calibLastHour',
        label: 'Faild Calibrations In Last Hour',
    },
    {
        id: 'totalRepeatedCalibration',
        label: 'Total Repeated Calibrations',
    },
    {
        id: 'lastHourRepeatedCalibrations',
        label: 'Last Hour Repeated Calibrations',
    },
    {
        id: 'redLanes',
        label: 'Red Lanes',
    },
    {
        id: 'callForHelpUnaswered',
        label: 'Call For Help Unanswered',
    },
    {
        id: 'supervisorName',
        label: 'Supervisor Name',
        type: 'input',
    },

    {
        id: 'comments',
        label: 'Comments',
        type: 'input',
    },
    {
        id: 'approved',
        label: 'Approved',
        type: 'checkbox',
    },
];

export const createMockData = (length) => {
    const randomDate = (start, end) => {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    };
    const randomNumber = () => (Math.random() * 100).toFixed();
    return [...Array(length).keys()].map((_, index) => {
        return {
            mapId: 'MapId-' + index,
            mapName: 'Map Name- ' + index,
            mapperName: 'Eli-' + index,
            lastLane: randomNumber(),
            totalDistance: randomNumber(),
            lastHourDistance: randomNumber(),
            calibTotalFailed: randomNumber(),
            calibLastHour: randomNumber(),
            totalRepeatedCalibration: randomNumber(),
            lastHourRepeatedCalibrations: randomNumber(),
            redLanes: randomNumber() > 20,
            callForHelpUnaswered: randomNumber(),
            supervisorName: '',
            comments: '',
            approved: randomNumber() > 20,
        };
    });
};
