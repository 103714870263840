import { combineReducers } from 'redux';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as notificationReducer } from './notification/notificationReducer';
import statusReducer, { reducer as statusReducer2 } from './status/statusReducer';
import analyticsReducer from './analytics/analyticsReducer';
import { reducer as authReducer } from './auth/authReducer';
import { reducer as buildingsReducer } from './building/buildingReducer';
import { reducer as floorsReducer } from './floor/floorReducer';
import { reducer as mapsReducer } from './map/mapReducer';
import { reducer as commonUserInputsReducer } from './user-inputs/commonSlice';
import { reducer as buildingsUserInputsReducer } from './user-inputs/buildingsSlice';
import { reducer as mapContentUserInputsReducer } from './user-inputs/mapContentSlice';
import { reducer as monitorUserInputsReducer } from './user-inputs/monitorSlice';
import { reducer as mappingUserInputsReducer } from './user-inputs/mappingSlice';
import { reducer as analyticsUserActivityUserInputsReducer } from './user-inputs/analyticsUserActivitySlice';
import { reducer as analyticsBuildingUsageUserInputsReducer } from './user-inputs/analyticsBuildingUsageSlice';
import { reducer as regionReducer } from './region/regionReducer';
import { reducer as regionPlacementsReducer } from './region-placement/regionPlacementReducer';
import { reducer as triggerReducer } from './trigger/triggerReducer';
import { reducer as spacesReducer } from './space/spaceReducer';
import { reducer as apiKeysReducer } from './api-key/apiKeyReducer';
import { reducer as areasOfInterestReducer } from './mapping/area-of-interest/areaOfInterestReducer';
import { reducer as linesOfInterestReducer } from './mapping/line-of-interest/lineOfInterestReducer';
import { reducer as junctionMismatchesReducer } from './mapping/junction-mismatch/junctionMismatchReducer';
import { reducer as lanesReducer } from './mapping/lane/laneReducer';
import { reducer as mapValidationsReducer } from './mapping/map-validation/mapValidationReducer';
import { reducer as mappersReducer } from './mapping/mapper/mapperReducer';
import { reducer as usersReducer } from './users/userReducer';
import { reducer as entrancesReducer } from './entrance/entranceReducer';
import { reducer as monitorClientsReducer } from './monitor/client/monitorClientReducer';
import { reducer as elementsReducer } from './mapping/elements/elementsReducer';
import { reducer as exitRegionReducer } from './mapping/exitRegion/exitRegionsReducer';
import { reducer as signMarkReducer } from './mapping/sign-mark/signMarkReducer';
import { reducer as preferencesReducer } from './user-inputs/preferencesSlice';
import { reducer as shiftManagementReducer } from './shift-management/shiftManagementReducer';
import { reducer as productLocationsReducer } from './productLocations/productLocationsReducer';
import { reducer as mapQualityReducer } from './map-quality/mapQualityRecucer';
import { reducer as bannerReducer } from './banner/bannerReducer';
import { reducer as mapFeatureReducer } from './map-features/mapFeaturesReducer';
import { reducer as generalReducer } from './general/generalReducer';
import { reducer as coordinatesConversionReducer } from './coordinates-conversion/coordinatesConversionReducer';
import httpClient from '../utils/httpClient';

const authPersistMigrations = {
    1: (state) => {
        return { ...state };
    },
    2: (state) => {
        return {
            userId: state.userId,
            apiKeyId: state.apiKeyId,
            lastLoggedUserApiKeyId: state.lastLoggedUserApiKeyId,
            sessionExpirationTime: state.sessionExpirationTime,
        };
    },
};

const authPersistConfig = {
    key: 'auth',
    storage,
    timeout: null,
    version: 2,
    whitelist: ['userId', 'apiKeyId', 'lastLoggedUserApiKeyId', 'sessionExpirationTime', 'spaceIds'],
    migrate: createMigrate(authPersistMigrations),
};

const commonUserInputsPersistConfig = {
    key: 'userInputs:common',
    storage,
    timeout: null,
    version: 1,
    blacklist: ['selectedBuildingId', 'selectedFloorId', 'selectedMapId'],
};
const coordinatesConversionPersistConfig = {
    key: 'userInputs:coordinatesConversions',
    storage,
    timeout: null,
    version: 1,
};
const buildingsUserInputsPersistConfig = {
    key: 'userInputs:buildings',
    storage,
    timeout: null,
    version: 1,
    whitelist: ['selectedBuildingId', 'selectedFloorId', 'selectedMapId'],
};

const mapContentUserInputsPersistConfig = {
    key: 'userInputs:mapContent',
    storage,
    timeout: null,
    version: 1,
    whitelist: ['selectedBuildingId', 'selectedFloorId', 'selectedMapId'],
};

const monitorUserInputsPersistConfig = {
    key: 'userInputs:monitor',
    storage,
    timeout: null,
    version: 1,
    whitelist: ['selectedBuildingId', 'selectedFloorId', 'selectedMapId'],
};

const mappingUserInputsPersistConfig = {
    key: 'userInputs:mapping',
    storage,
    timeout: null,
    version: 1,
    whitelist: ['selectedBuildingId', 'selectedFloorId', 'selectedMapId'],
};

const analyticsUserActivityUserInputsPersistConfig = {
    key: 'userInputs:analytics:userActivity',
    storage,
    timeout: null,
    version: 1,
};

const analyticsBuildingUsageUserInputsPersistConfig = {
    key: 'userInputs:analytics:buildingUsage',
    storage,
    timeout: null,
    version: 1,
    whitelist: ['filters'],
};

const productLocationsPersistConfig = {
    key: 'productLocations',
    storage,
    timeout: null,
    version: 1,
};

const mapQualityPersistConfig = {
    key: 'mapQuality',
    storage,
    timeout: null,
    version: 1,
};

const rootReducer = combineReducers({
    buildings: buildingsReducer,
    floors: floorsReducer,
    maps: mapsReducer,
    spaces: spacesReducer,
    regions: regionReducer,
    regionPlacements: regionPlacementsReducer,
    triggers: triggerReducer,
    apiKeys: apiKeysReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    notification: notificationReducer,
    banner: bannerReducer,
    general: generalReducer,
    status: statusReducer,
    status2: statusReducer2,
    analytics: analyticsReducer,
    users: usersReducer,
    entrances: entrancesReducer,
    mapping: combineReducers({
        areasOfInterest: areasOfInterestReducer,
        elements: elementsReducer,
        linesOfInterest: linesOfInterestReducer,
        junctionMismatches: junctionMismatchesReducer,
        lanes: lanesReducer,
        mapValidations: mapValidationsReducer,
        mappers: mappersReducer,
    }),
    monitor: combineReducers({
        clients: monitorClientsReducer,
    }),
    mapFeatures: mapFeatureReducer,
    preferences: preferencesReducer,
    userInputs: combineReducers({
        common: persistReducer(commonUserInputsPersistConfig, commonUserInputsReducer),
        buildings: persistReducer(buildingsUserInputsPersistConfig, buildingsUserInputsReducer),
        mapContent: persistReducer(mapContentUserInputsPersistConfig, mapContentUserInputsReducer),
        monitor: persistReducer(monitorUserInputsPersistConfig, monitorUserInputsReducer),
        mapping: persistReducer(mappingUserInputsPersistConfig, mappingUserInputsReducer),
        analytics: combineReducers({
            userActivity: persistReducer(
                analyticsUserActivityUserInputsPersistConfig,
                analyticsUserActivityUserInputsReducer
            ),
            buildingUsage: persistReducer(
                analyticsBuildingUsageUserInputsPersistConfig,
                analyticsBuildingUsageUserInputsReducer
            ),
        }),
    }),
    shiftManagement: shiftManagementReducer,
    productLocations: persistReducer(productLocationsPersistConfig, productLocationsReducer),
    mapQuality: persistReducer(mapQualityPersistConfig, mapQualityReducer),
    exitRegions: exitRegionReducer,
    signMarks: signMarkReducer,
    coordinatesConversions: persistReducer(coordinatesConversionPersistConfig, coordinatesConversionReducer),
});

const _rootReducer = (state, action) => {
    if (action.type.startsWith('userInputs') && action.type.endsWith('setSelectedMapId')) {
        state.userInputs.common.selectedMapId = action.payload;
        localStorage.setItem('selectedMapId', action.payload);
    }
    if (action.type.startsWith('userInputs') && action.type.endsWith('setSelectedFloorId')) {
        state.userInputs.common.selectedFloorId = action.payload;
        state.userInputs.common.selectedMapId = null;
        localStorage.setItem('selectedFloorId', action.payload);
        localStorage.removeItem('selectedMapId');
    }
    if (
        (action.type.startsWith('userInputs') && action.type.endsWith('setSelectedBuildingId')) ||
        action.type.startsWith('buildings/delete')
    ) {
        state.userInputs.common.selectedBuildingId = action.payload || null;
        state.userInputs.common.selectedMapId = null;
        state.userInputs.common.selectedFloorId = null;
        if (action.payload) {
            localStorage.setItem('selectedBuildingId', action.payload);
        } else {
            localStorage.removeItem('selectedBuildingId');
        }

        localStorage.removeItem('selectedMapId');
        localStorage.removeItem('selectedFloorId');
    }
    if (state.auth?.accessToken) {
        httpClient.addAccessToken(state.auth?.accessToken);
    }
    return rootReducer(state, action);
};
// Create enhanced combined reducers with persistence
export default _rootReducer;
