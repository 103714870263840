import React, { useEffect, useMemo, useState } from 'react';
import SideMenuSection from '../../../common/side-drawer/SideMenuSection';
import { PulsePreloader, Select } from '../../../common/themed';
import {
    selectBuildingsSelectedBuildingId,
    selectBuildingsSelectedFloorId,
    setSelectedFloorId,
} from '../../../../state-management/user-inputs/buildingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import useAllBuildingsInSpace from '../../../common/hooks/data-fetching/useAllBuildingsInSpace';
import { selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { deleteFloor } from '../../../../state-management/floor/floorActions';
import useAllFloorsInBuilding from '../../../common/hooks/data-fetching/useAllFloorsInBuilding';
import CreateFloorDialog from '../../dialogs/create-floor';
import { MdEdit as EditIcon } from 'react-icons/md';
import { Collapse, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { selectCanEditBuildings } from '../../../../state-management/auth/authSelectors';
import { getFloorSelectId } from '../../buildings.selectors';
import FetchError from '../../../common/error-pages/FetchError';
import FloorDetailsForm from './FloorDetailsForm';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';

const ExpandToggle = styled(IconButton)`
    width: 100%;
    padding: 0;
    border-radius: 0;
    margin-block-start: 10px;
`;

export default function BuildingsSideMenuFloorSection() {
    const [isCreateFloorDialogOpen, setIsCreateFloorDialogOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const dispatch = useDispatch();

    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const selectedFloorId = useSelector(selectBuildingsSelectedFloorId);
    const isDeletingFloor = useSelector(selectIsLoading(deleteFloor.typePrefix));
    const canEditBuildings = useSelector(selectCanEditBuildings);

    const { data: buildings } = useAllBuildingsInSpace({
        asObject: true,
    });

    const {
        data: floors,
        isLoading,
        hasError,
    } = useAllFloorsInBuilding({
        asObject: true,
        buildingId: selectedBuildingId,
    });

    const floorIds = useMemo(() => Object.values(floors || {}).map((f) => f.floorId), [floors]);
    const selectedBuilding = buildings?.[selectedBuildingId];
    const selectedFloor = floors?.[selectedFloorId];
    const primaryFloorId = selectedBuilding?.primaryFloorId;
    const isPrimaryFloor = selectedFloorId === selectedBuilding?.primaryFloorId;

    const handleDelete = async () => {
        const result = await dispatch(
            deleteFloor({ buildingId: selectedBuildingId, floorId: selectedFloorId })
        );

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Floor deleted successfully.'));
        } else {
            dispatch(showErrorNotification(`Failed to delete floor`));
        }
    };

    useEffect(() => {
        if (!selectedFloorId) {
            if (floorIds.length === 1) {
                // If the selected building has only one floor, select it by default
                dispatch(setSelectedFloorId(floorIds[0]));
            } else if (primaryFloorId) {
                // Otherwise, select the primary floor by default
                dispatch(setSelectedFloorId(primaryFloorId));
            }
        }
    }, [selectedFloorId, floorIds, primaryFloorId, dispatch]);

    if (!floors && !isLoading) {
        return null;
    }

    return (
        <>
            <SideMenuSection
                title={'Floor'}
                showAdd={canEditBuildings}
                showDelete={canEditBuildings && !!selectedFloorId}
                onAdd={() => setIsCreateFloorDialogOpen(true)}
                onDelete={handleDelete}
                isDeleting={isDeletingFloor}
                deleteTooltipContent={'Cannot delete the primary floor'}
                isDeleteTooltipEnabled={isPrimaryFloor}
                isDeleteDisabled={isPrimaryFloor}
            >
                {hasError ? (
                    <FetchError />
                ) : isLoading ? (
                    <PulsePreloader fullHeight={false} />
                ) : (
                    <>
                        <Select
                            id={getFloorSelectId()}
                            options={
                                floors
                                    ? Object.values(floors).sort((a, b) => a.floorIndex - b.floorIndex)
                                    : []
                            }
                            placeholder={'Select a floor...'}
                            getOptionValue={(f) => f.floorId}
                            getOptionLabel={(f) => f.floorName}
                            isDisabled={!floors}
                            onChange={(f) => dispatch(setSelectedFloorId(f.floorId))}
                            value={selectedFloor || null}
                        />

                        {selectedFloor && (
                            <>
                                <ExpandToggle onClick={() => setIsExpanded(!isExpanded)}>
                                    <EditIcon />
                                </ExpandToggle>

                                <Collapse in={isExpanded}>
                                    <FloorDetailsForm
                                        buildingId={selectedBuilding?.buildingId}
                                        floor={{
                                            ...selectedFloor,
                                            primary:
                                                selectedFloor?.floorId === selectedBuilding?.primaryFloorId,
                                        }}
                                    />
                                </Collapse>
                            </>
                        )}
                    </>
                )}
            </SideMenuSection>

            {isCreateFloorDialogOpen && (
                <CreateFloorDialog
                    onClose={() => setIsCreateFloorDialogOpen(false)}
                    buildingId={selectedBuildingId}
                />
            )}
        </>
    );
}
