import React, { memo, useContext } from 'react';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { MdCloud, MdCloudDone, MdCloudOff } from 'react-icons/md';
import { Portal } from '@material-ui/core';
import { MapViewerContext } from '../../MapViewerContext';

const OverlayWrapper = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    margin-block-start: 15px;
    margin-inline-start: 15px;
    pointer-events: none; // Prevent the whole SVG from capturing pointer events
`;

const commonIconStyles = css`
    margin-inline-end: 10px;
    width: 30px;
    height: 30px;
`;

const DisconnectedIcon = styled(MdCloudOff)`
    fill: #e74c3c; // TODO color should come from theme
    ${commonIconStyles}
`;

const ConnectingIcon = styled(MdCloud)`
    fill: #f1c40f; // TODO color should come from theme
    ${commonIconStyles}
`;

const ConnectedIcon = styled(MdCloudDone)`
    fill: #27ae60; // TODO color should come from theme
    ${commonIconStyles}
`;

const labels = {
    [WebSocket.CLOSED]: 'Disconnected',
    [WebSocket.CLOSING]: 'Disconnecting...',
    [WebSocket.CONNECTING]: 'Connecting...',
    [WebSocket.OPEN]: 'Connected',
};

const icons = {
    [WebSocket.CLOSED]: <DisconnectedIcon />,
    [WebSocket.CLOSING]: <ConnectingIcon />,
    [WebSocket.CONNECTING]: <ConnectingIcon />,
    [WebSocket.OPEN]: <ConnectedIcon />,
};

function MapViewerWebSocketStatusOverlay(props) {
    const { readyState = 0 } = props;

    const { containerRef } = useContext(MapViewerContext);

    return (
        <Portal container={containerRef.current}>
            <OverlayWrapper>
                {icons[readyState]}
                {labels[readyState]}
            </OverlayWrapper>
        </Portal>
    );
}

MapViewerWebSocketStatusOverlay.propTypes = {
    readyState: PropTypes.number,
};

export default memo(MapViewerWebSocketStatusOverlay);
