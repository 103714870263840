import { createAsyncThunk } from '../utils';
import MapQualityApi from '../../api/MapQualityApi';

export const fetchedMapQualityData = createAsyncThunk(
    'mapQuality/fetchedMapQualityData',
    async ({ filter, paginate, sort }, thunkAPI) => {
        try {
            const { data } = await MapQualityApi.fetchedMapQualityData({filter, paginate, sort});
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchedRecordsCount = createAsyncThunk(
    'mapQuality/fetchedRecordsCount',
    async ({ filter = [] }) => {
        return (await MapQualityApi.fetchedRecordsCount(filter)).data;
    }
);
