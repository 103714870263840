import React, { useEffect, useState } from 'react';
import { Switch, CircularPreloader } from '../../common/themed';
import { Form, Label, ConfigWrapper } from './components';
import { FormControlLabel, FormControl } from '@material-ui/core';
import { AiFillSave as SaveIcon } from 'react-icons/ai';
import { features } from '../constants';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { SpaceSelectBox, SpaceSelectedLabel, SaveButton } from './styles';
import { isFulfilled } from '../../../state-management/utils';
import {
    showSuccessNotification,
    showErrorNotification,
} from '../../../state-management/notification/notificationReducer';
import { updateSpace } from '../../../state-management/space/spaceActions';
import { useDispatch } from 'react-redux';
import ConfirmDialog from './components/ConfirmDialog';

export const SpaceForm = ({ setSelectedSpace, spaceIsLoading, spacesArray, selectedSpace, onSubmit }) => {
    const defaultValues = (selectedSpace) => ({
        selfValidationEnabled: selectedSpace?.features?.selfValidationEnabled ?? false,
        selfHealingEnabled: selectedSpace?.features?.selfHealingEnabled ?? false,
        autoPublishEnabled: selectedSpace?.features?.autoPublishEnabled ?? false,
        selectedSpace: selectedSpace ?? {},
    });
    const {
        handleSubmit,
        control,
        setValue,
        formState: { isSubmitting, dirtyFields },
        reset,
    } = useForm({
        defaultValues: defaultValues(selectedSpace),
    });

    const [formData, setFormData] = useState(null);
    const dispatch = useDispatch();
    const { selfValidationEnabled, selfHealingEnabled } = useWatch({
        control,
        name: [features.SELF_VALIDATION, features.SELF_HEALING],
    });

    useEffect(() => {
        if (!selfValidationEnabled) {
            setValue(features.SELF_HEALING, false, { shouldDirty: true });
            setValue(features.AUTO_PUBLISH, false, { shouldDirty: true });
        } else if (!selfHealingEnabled) {
            setValue(features.AUTO_PUBLISH, false, { shouldDirty: true });
        }

        return () => {};
    }, [selfValidationEnabled, selfHealingEnabled]);

    useEffect(() => {
        if (selectedSpace && selectedSpace.features) {
            reset(defaultValues(selectedSpace));
        }
        setSelectedSpace?.(selectedSpace);

        return () => {};
    }, [selectedSpace]);

    const onSave = async (formData) => {
        if (onSubmit) {
            setFormData(formData);
            return;
        }
        if (selectedSpace) {
            const result = await dispatch(
                updateSpace({
                    spaceId: selectedSpace.spaceId,
                    spaceData: {
                        ...selectedSpace,
                        features: formData,
                        bundleIds: selectedSpace.bundleIds || [],
                    },
                })
            );
            if (isFulfilled(result)) {
                dispatch(showSuccessNotification('Space updated.'));
                reset(defaultValues(result.payload));
            } else {
                dispatch(showErrorNotification('Something went wrong the the selected space..'));
            }
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit(onSave)}>
                <ConfigWrapper>
                    {setSelectedSpace && (
                        <Controller
                            name={'selectedSpace'}
                            control={control}
                            render={({ onChange, value }) => (
                                <FormControl>
                                    <SpaceSelectedLabel shrink id="selected-space-form-label">
                                        Selected Space
                                    </SpaceSelectedLabel>
                                    <FormControlLabel
                                        control={
                                            spaceIsLoading ? (
                                                <CircularPreloader />
                                            ) : (
                                                <SpaceSelectBox
                                                    labelId="selected-space-form-label"
                                                    value={value || selectedSpace}
                                                    onChange={(event) => {
                                                        onChange(event);
                                                        setSelectedSpace(event);
                                                    }}
                                                    placeholder={'Select a space...'}
                                                    options={spacesArray}
                                                    getOptionLabel={(s) => s.spaceDesc}
                                                    getOptionValue={(s) => s.spaceId}
                                                />
                                            )
                                        }
                                    />
                                </FormControl>
                            )}
                        />
                    )}

                    <Controller
                        name={features.SELF_VALIDATION}
                        control={control}
                        render={({ onChange, value }) => (
                            <FormControlLabel
                                control={
                                    <Switch checked={value} onChange={(e) => onChange(e.target.checked)} />
                                }
                                label={<Label>Enable self validation</Label>}
                            />
                        )}
                    />
                    <Controller
                        name={features.SELF_HEALING}
                        control={control}
                        render={({ onChange, value }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={value}
                                        disabled={!selfValidationEnabled}
                                        onChange={(e) => onChange(e.target.checked)}
                                    />
                                }
                                label={<Label>Enable self healing</Label>}
                            />
                        )}
                    />
                    <Controller
                        name={features.AUTO_PUBLISH}
                        control={control}
                        render={({ onChange, value }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={value}
                                        disabled={!selfValidationEnabled || !selfHealingEnabled}
                                        onChange={(e) => onChange(e.target.checked)}
                                    />
                                }
                                label={<Label>Enable auto publish</Label>}
                            />
                        )}
                    />

                    <SaveButton
                        disabled={
                            !onSubmit &&
                            !Object.keys(dirtyFields).filter((key) => key !== 'selectedSpace').length
                        }
                        type="submit"
                        startIcon={<SaveIcon />}
                    >
                        {isSubmitting ? <CircularPreloader /> : 'Save'}
                    </SaveButton>
                </ConfigWrapper>
            </Form>
            <ConfirmDialog
                open={formData !== null}
                onConfirm={async () => await onSubmit(formData)}
                onClose={() => setFormData(null)}
                message="Warning"
            />
        </>
    );
};
