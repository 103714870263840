import { createSelector } from 'reselect';
import entityAdapter from './floorEntityAdapter';
import { selectMapEntities } from '../map/mapSelectors';
import { selectRegionEntities } from '../region/regionSelectors';

export const {
    selectById: selectFloorById,
    selectIds: selectFloorIds,
    selectEntities: selectFloorEntities,
    selectAll: selectAllFloors,
    selectTotal: selectTotalFloors,
} = entityAdapter.getSelectors((state) => state.floors);

export const selectFloorIdToMapsArray = createSelector(selectAllFloors, selectMapEntities, (floors, maps) => {
    if (!floors || floors.length === 0) {
        return {};
    }

    return floors.reduce(
        (result, floor) => ({
            ...result,
            [floor.floorId]: floor.mapIds ? floor.mapIds.map((mapId) => maps[mapId]) : null,
        }),
        {}
    );
});

export const selectFloorIdToMapsObject = createSelector(
    selectAllFloors,
    selectMapEntities,
    (floors, maps) => {
        if (!floors || floors.length === 0) {
            return {};
        }

        return floors.reduce(
            (result, floor) => ({
                ...result,
                [floor.floorId]: floor.mapIds
                    ? floor.mapIds.reduce((res, mapId) => ({ ...res, [mapId]: maps[mapId] }), {})
                    : null,
            }),
            {}
        );
    }
);

export const selectFloorIdToRegionsArray = createSelector(
    selectAllFloors,
    selectMapEntities,
    selectRegionEntities,
    (floors, maps, regions) => {
        if (!floors || floors.length === 0) {
            return {};
        }

        return floors.reduce((result, { floorId, mapIds = [] }) => {
            const regionIds = [
                ...new Set(
                    mapIds.reduce(
                        (result, mapId) => [...result, ...(maps?.[mapId]?.regionPlacementIds || [])],
                        []
                    )
                ),
            ];

            return {
                ...result,
                [floorId]: regionIds.map((regionId) => {
                    const extractRegionId = regionId.split('::')[1];
                    return regions?.[extractRegionId];
                }),
            };
        }, {});
    }
);

export const selectFloorIdToRegionsObject = createSelector(
    selectAllFloors,
    selectMapEntities,
    selectRegionEntities,
    (floors, maps, regions) => {
        if (!floors || floors.length === 0) {
            return {};
        }

        return floors.reduce((result, { floorId, mapIds = [] }) => {
            const regionIds = [
                ...new Set(
                    mapIds.reduce((result, mapId) => [...result, ...(maps?.[mapId]?.regionIds || [])], [])
                ),
            ];

            return {
                ...result,
                [floorId]: regionIds.reduce(
                    (res, regionId) => ({
                        ...res,
                        [regionId]: regions?.[regionId],
                    }),
                    {}
                ),
            };
        }, {});
    }
);
