import React, { useEffect } from 'react';
import {
    Chip,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
} from '@material-ui/core';
import { MdExpandMore as ExpandMoreIcon } from 'react-icons/md';
import { Button, PulsePreloader, TextField } from '../../../common/themed';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import useAllFloorsInBuilding from '../../../common/hooks/data-fetching/useAllFloorsInBuilding';
import useAllBuildingsInSpace from '../../../common/hooks/data-fetching/useAllBuildingsInSpace';
import {
    getFloorHeightId,
    getFloorIndexId,
    getFloorNameId,
    getFloorRotationId,
    getFloorShortNameId,
    getMarkFloorAsPrimaryId,
} from './CreateFloorDialog.selectors';
import styled from 'styled-components';

const newFloorId = 'new-floor';

const FloorFormWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 40px;
`;

const PrimaryFloorChip = styled(Chip)`
    margin-inline-start: 10px;
    background-color: #ff6200; // TODO color should come from theme
    color: white;
`;

export default function CreateFloorStepOne({ buildingId }) {
    const { register, errors, setValue, watch, setError, clearErrors } = useFormContext();
    const {
        primary: isNewFloorPrimary,
        floorName: newFloorName,
        floorIndex: newFloorIndex,
        height: newFloorHeight,
    } = watch(['primary', 'floorName', 'floorIndex', 'height']);

    const {
        data: { [buildingId]: building },
    } = useAllBuildingsInSpace({ asObject: true });
    const { primaryFloorId: buildingPrimaryFloorId } = building ?? {};
    const primaryFloorId = isNewFloorPrimary ? newFloorId : buildingPrimaryFloorId;

    const { data: floors, isLoading } = useAllFloorsInBuilding({
        buildingId,
    });

    const handleMarkFloorAsPrimary = (e) => {
        e.stopPropagation();
        setValue('primary', !isNewFloorPrimary);
    };

    useEffect(() => {
        const isFloorIndexValid = floors?.every(({ floorIndex }) => floorIndex !== newFloorIndex);

        const isFloorHeightValid = floors?.every(
            ({ floorIndex, height }) =>
                (floorIndex < newFloorIndex && height < newFloorHeight) ||
                (floorIndex > newFloorIndex && height > newFloorHeight)
        );

        const invalidFloorIndexMessage = 'Floor indices must be unique';
        const invalidFloorHeightMessage =
            'Floor heights cannot be greater/smaller than floors with higher/lower indexes';

        if (!isFloorIndexValid && errors?.custom?.message !== invalidFloorIndexMessage) {
            setError('custom', { message: invalidFloorIndexMessage });
        } else if (!isFloorHeightValid && errors?.custom?.message !== invalidFloorHeightMessage) {
            setError('custom', { message: invalidFloorHeightMessage });
        } else if (isFloorIndexValid && isFloorHeightValid && errors?.custom) {
            clearErrors('custom');
        }
    }, [clearErrors, errors, floors, newFloorHeight, newFloorIndex, setError]);

    if (isLoading) {
        return <PulsePreloader />;
    }

    return [...(floors ?? []), { floorId: newFloorId }].map(
        ({ floorId, floorName, floorShortName, floorIndex, height, rotation }) => (
            <Accordion key={floorId} defaultExpanded={floorId === newFloorId}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ height: 70 }}>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>
                            <Typography inline={'true'} variant={'h6'} display="inline">
                                {floorId === newFloorId ? newFloorName : floorName}
                            </Typography>

                            {primaryFloorId === floorId && <PrimaryFloorChip label={'Primary'} />}
                        </Grid>

                        {floorId === newFloorId && (
                            <Grid item>
                                <Grid container alignItems={'center'}>
                                    <input hidden name={'primary'} ref={register} type={'checkbox'} />

                                    <Button
                                        id={getMarkFloorAsPrimaryId()}
                                        variant={'outlined'}
                                        onClick={handleMarkFloorAsPrimary}
                                    >
                                        {isNewFloorPrimary ? 'Unmark as primary' : 'Mark as primary'}
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <FloorFormWrapper>
                        {floorId === newFloorId ? (
                            <>
                                <TextField
                                    id={getFloorNameId(floorId)}
                                    label={'Floor Name'}
                                    defaultValue={''}
                                    name={'floorName'}
                                    inputRef={register({
                                        required: 'This is required',
                                        maxLength: {
                                            value: 50,
                                            message: 'Floor name must be under 50 characters',
                                        },
                                    })}
                                    error={!!errors?.floorName}
                                    helperText={!!errors?.floorName?.message}
                                />

                                <TextField
                                    id={getFloorShortNameId(floorId)}
                                    label={'Short Name'}
                                    defaultValue={''}
                                    name={'floorShortName'}
                                    inputRef={register({
                                        required: 'This is required',
                                        maxLength: {
                                            value: 10,
                                            message: 'Short name must be under 10 characters',
                                        },
                                    })}
                                    error={!!errors?.floorShortName}
                                    helperText={!!errors?.floorShortName?.message}
                                />

                                <Controller
                                    id={getFloorIndexId(floorId)}
                                    defaultValue={0}
                                    name={'floorIndex'}
                                    rules={register({ required: 'This is required' })}
                                    render={({ onChange, value }) => (
                                        <NumberFormat
                                            label={'Index'}
                                            error={!!errors?.floorIndex}
                                            helperText={
                                                errors?.floorIndex?.message ??
                                                'This serves as the floor number (0 = ground floor)'
                                            }
                                            customInput={TextField}
                                            onValueChange={({ floatValue }) => {
                                                onChange(floatValue);
                                            }}
                                            value={value}
                                            decimalScale={0}
                                        />
                                    )}
                                />

                                <Controller
                                    id={getFloorHeightId(floorId)}
                                    name={`height`}
                                    defaultValue={0}
                                    rules={{ required: 'This is required' }}
                                    render={({ onChange, value }) => (
                                        <NumberFormat
                                            label={'Height'}
                                            error={!!errors?.height}
                                            helperText={'Relative height from the ground floor'}
                                            customInput={TextField}
                                            onValueChange={({ floatValue }) => {
                                                onChange(floatValue);
                                            }}
                                            value={value}
                                            suffix={' m'}
                                        />
                                    )}
                                />

                                <Controller
                                    id={getFloorRotationId(floorId)}
                                    name={`rotation`}
                                    defaultValue={0}
                                    rules={{
                                        required: 'This is required',
                                        min: {
                                            value: -360,
                                            message: 'Please provide a value between 360 and -360 degrees',
                                        },
                                        max: {
                                            value: 360,
                                            message: 'Please provide a value between 360 and -360 degrees',
                                        },
                                    }}
                                    render={({ onChange, value }) => (
                                        <NumberFormat
                                            label={'Rotation'}
                                            error={!!errors?.rotation}
                                            helperText={
                                                errors?.rotation?.message ??
                                                'Relative rotation from the ground floor'
                                            }
                                            customInput={TextField}
                                            onValueChange={({ floatValue }) => {
                                                onChange(floatValue);
                                            }}
                                            value={value}
                                            suffix={'°'}
                                        />
                                    )}
                                />
                            </>
                        ) : (
                            <>
                                <TextField
                                    id={getFloorNameId(floorId)}
                                    label={'Floor Name'}
                                    defaultValue={floorName ?? ''}
                                    disabled
                                />

                                <TextField
                                    id={getFloorShortNameId(floorId)}
                                    label={'Short Name'}
                                    defaultValue={floorShortName ?? ''}
                                    disabled
                                />

                                <TextField
                                    id={getFloorIndexId(floorId)}
                                    label={'Index'}
                                    defaultValue={floorIndex}
                                    disabled
                                />

                                <NumberFormat
                                    id={getFloorHeightId(floorId)}
                                    label={'Height'}
                                    defaultValue={height}
                                    customInput={TextField}
                                    suffix={' m'}
                                    disabled
                                />

                                <NumberFormat
                                    id={getFloorRotationId(floorId)}
                                    label={'Rotation'}
                                    defaultValue={rotation}
                                    customInput={TextField}
                                    suffix={'°'}
                                    disabled
                                />
                            </>
                        )}
                    </FloorFormWrapper>
                </AccordionDetails>
            </Accordion>
        )
    );
}
