import React, { memo, useContext, useMemo } from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { MapViewerContext } from '../../MapViewerContext';
import { getStatusColor } from '../../../../../utils/mapValidationsUtils';
import {
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
} from '../../../../../constants/mapViewerVariables';
import { getMapViewerValidationId } from './MapViewerMapValidationsOverlay.selectors';

const Rectangle = styled.rect`
    height: ${(props) => props.$height}px;
    width: ${(props) => props.$width}px;
    fill: ${(props) => getStatusColor(props.$status)};
`;

function MapValidation(props) {
    const { validation } = props;

    const { x: pointX, y: pointY, status, resolution } = validation ?? {};
    const rectWidth = resolution;
    const rectHeight = resolution;

    const { getCSSVariable } = useContext(MapViewerContext);

    const { x, y, width, height } = useMemo(() => {
        const mapOffsetX = getCSSVariable(OFFSET_X);
        const mapOffsetY = getCSSVariable(OFFSET_Y);
        const pixelToMeter = getCSSVariable(PIXEL_TO_METER);
        const imageNaturalHeight = getCSSVariable(IMAGE_NATURAL_HEIGHT);

        return {
            x: (pointX - mapOffsetX) * pixelToMeter,
            y: (imageNaturalHeight / pixelToMeter - (pointY - mapOffsetY)) * pixelToMeter,
            width: rectWidth * pixelToMeter,
            height: rectHeight * pixelToMeter,
        };
    }, [getCSSVariable, pointX, pointY, rectHeight, rectWidth]);

    if (!validation) {
        return null;
    }

    return (
        <Rectangle
            id={getMapViewerValidationId(validation?.mapValidationId)}
            x={x}
            y={y}
            $width={width}
            $height={height}
            $status={status}
        />
    );
}

MapValidation.propTypes = {
    validation: PropTypes.object.isRequired,
};

export default memo(MapValidation);
