import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { Paper, RootRef, Typography } from '@material-ui/core';
import MapQualityApi from '../../../api/MapQualityApi';
import { showErrorNotification } from '../../../state-management/notification/notificationReducer';
import Skeleton from './Skeleton';

const ImageDetailPanelWrapper = styled(Paper)`
    display: flex;
    width: 100%;
    height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    ${({noImages}) => {
        if(noImages) {
            return css`
            align-items: center;
        `
        }
    }}
    
`;

const ImageList = styled.ul`
    list-style: none;
    justify-content: space-evenly;
    width: 100%;
    display: flex;
    max-width: 80vw;
    height: 80%;
    & *:not(:last-child) {
        margin-right: 10px;
    }
    overflow-x: auto;
`;

const ImageListItem = styled.li``;

const Image = styled.img`
    width: 100%;
    cursor: pointer;
    height: 230px;
`;

const Container = styled.div``;

export default function ImagesPanel({ data, onImageClicked }) {
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const targetRef = useRef();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const dispatch = useDispatch();

    // useLayoutEffect(() => {
    //     if (targetRef.current) {
    //         setWidth(targetRef.current.offsetWidth);
    //         setHeight(targetRef.current.offsetHeight);
    //     }
    // }, []);

    // TODO: use the isLoading and isError properly
    const fetchImages = async ({buildingId, mapId, createdOn}) => {
        try {
            setIsLoading(true)
            const result = await (
                await MapQualityApi.fetchMapQualityImages({buildingId, mapId, createdOn})
            ).data;
            if (!images?.length) {
                setImages(result);
            }
        } catch (err) {
            dispatch(showErrorNotification('Failed to load the images'));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const { buildingId, mapId, createdOn } = data;
        fetchImages({buildingId, mapId, createdOn});
    }, []);


    if(isLoading) return <Skeleton width={'100%'} height={'300px'}/>
    if (!images?.length) {
        return (
            <ImageDetailPanelWrapper noImages>
                <Typography style={{ fontWeight: '500' }}>No Images</Typography>
            </ImageDetailPanelWrapper>
        );
    }

    
    const [fixture, magnetic, trackingScore] = images;
    return (
        <Container ref={targetRef}>
            <ImageDetailPanelWrapper>
                <ImageList>
                    <ImageListItem onClick={onImageClicked}>
                        <Image src={trackingScore.image} alt={'image'} />
                    </ImageListItem>
                    <ImageListItem onClick={onImageClicked}>
                        <Image src={magnetic.image} alt={'image'} />
                    </ImageListItem>
                    <ImageListItem onClick={onImageClicked}>
                        <Image src={fixture.image} alt={'image'} />
                    </ImageListItem>
                </ImageList>
            </ImageDetailPanelWrapper>
        </Container>
    );
}
