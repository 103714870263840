import { createSelector, createSlice } from '@reduxjs/toolkit';
import { deleteBuilding } from '../building/buildingActions';
import { deleteFloor } from '../floor/floorActions';
import { deleteMap } from '../map/mapActions';
import { setSelectedSpaceId } from './commonSlice';
import { wipeAllUserInputs } from './userInputsActions';
import { deleteLane } from '../mapping/lane/laneActions';
import { deleteAreaOfInterest } from '../mapping/area-of-interest/areaOfInterestActions';
import {
    areaOfInterestDeleted,
    elementsDeleted,
    exitRegionDeleted,
    laneDeleted,
    lineOfInterestDeleted,
} from '../mapping/mappingEventsActions';
import { deleteLineOfInterest } from '../mapping/line-of-interest/lineOfInterestActions';
import { deleteElement } from '../mapping/elements/elementsActions';
import { deleteExitRegion } from '../mapping/exitRegion/exitRegionsActions';

const initialState = {
    isSideDrawerOpen: true,
    selectedBuildingId: null,
    selectedFloorId: null,
    selectedMapId: null,
    selectedMapperId: null,
    selectedAreaOfInterestsMapperName: null,
    selectedElementsMapperName: null,
    selectedLineOfInterestsMapperName: null,
    selectedLanesMapperId: null,
    selectedLanesMapperName: null,
    selectedLaneId: null,
    selectedAreaId: null,
    selectedLineId: null,
    highlightedLaneIds: [],
    highlightedJunctionIds: [],
    showJunctionsBelowMismatchThreshold: false,
    showJunctionsAboveMismatchThreshold: false,
    laneIdsShownInBack: [],
    laneIdsShownInFront: [],
    areaIdsShownInBack: [],
    areaIdsShownInFront: [],
    selectedAreaOfInterestIds: [],
    selectedLaneIds: [],
    selectedElementIds: [],
    selectedLineOfInterestIds: [],
    exitRegionIdsSelected: [],
    selectedSignMarkIds: [],
    signMarkIdsShownInBack: [],
    signMarkIdsShownInFront: [],
    laneIdForDisplayedComments: null,
    areLanesShown: true,
    areMappersShown: true,
    areElementsShown: true,
    areExitRegionsShown: true,
    areAreasOfInterestShown: true,
    areElementsTitlesShown: true,
    areExitRegionTitlesShown: true,
    areLinesOfInterestShown: true,
    areMapValidationsShown: false,
    areSignMarksShown: true,
    isSignMarkLockInMap: true,
    isAreaOfInterestCreationOpen: false,
    isLineOfInterestCreationOpen: false,
    isElementCreationOpen: false,
    isMapInGrayscale: false,
    isSettingPixelToMeter: false,
    isShowingEqualizedMap: true,
    isMapCroppingCreationOpen: false,
    isSignMarkCreationOpen: false,
    isGenerateMapGridOpen: false,
    highlightedAreaOfInterestId: '',
    areAreasOfInterestActionsShown: true,
    signMarkEditor: { isOpen: false, signMarkId: null },
};

export const {
    actions: {
        setIsSideDrawerOpen,
        setSelectedBuildingId,
        setSelectedFloorId,
        setSelectedMapId,
        setSelectedMapperId,
        setSelectedAreaOfInterestsMapperName,
        setSelectedElementsMapperName,
        setSelectedLineOfInterestsMapperName,
        setSelectedLanesMapperId,
        setSelectedLanesMapperName,
        setSelectedLaneId,
        setSelectedAreaId,
        setSelectedElementId,
        setSelectedAreaOfInterestIds,
        setSelectedLaneIds,
        setSelectedElementIds,
        setSelectedLineId,
        setSelectedLineOfInterestIds,
        highLightAreaOfInterest,
        unhilightAreaOfInterest,
        highlightLane,
        unhighlightLane,
        highlightJunction,
        unhighlightJunction,
        setShowJunctionsBelowMismatchThreshold,
        setShowJunctionsAboveMismatchThreshold,
        sendLaneToBack,
        bringLaneToFront,
        sendAreaToBack,
        bringAreaToFront,
        setAreLanesShown,
        setAreMappersShown,
        setAreAreasOfInterestShown,
        setAreElementsTitlesShown,
        setAreExitRegionsTitlesShown,
        setAreLinesOfInterestShown,
        setAreElementsShown,
        setAreMapValidationsShown,
        setAreSignMarksShown,
        setIsAreaOfInterestCreationOpen,
        setIsLineOfInterestCreationOpen,
        setIsElementCreationOpen,
        setIsMapInGrayscale,
        setIsSettingPixelToMeter,
        setIsShowingEqualizedMap,
        setIsMapCroppingCreationOpen,
        setIsSignMarkCreationOpen,
        setIsGenerateMapGridOpen,
        setAreAreasOfInterestActionsShown,
        setSelectedSignMarkIds,
        openSignMarkEditor,
        closeSignMarkEditor,
        setSignMarkLockInMap,
        sendSignMarkToBack,
        bringSignMarkToFront,
    },
    reducer,
} = createSlice({
    name: 'userInputs/mapping',
    initialState,
    reducers: {
        setIsSideDrawerOpen: (state, action) => {
            state.isSideDrawerOpen = action.payload;
        },

        setSelectedBuildingId: (state, action) => {
            state.selectedBuildingId = action.payload;
            state.selectedFloorId = null;
            state.selectedMapId = null;
            state.selectedMapperId = null;
            state.selectedLaneId = null;
            state.selectedLaneIds = [];
            state.selectedSignMarkIds = [];
            state.highlightedLaneIds = [];
            state.highlightedJunctionIds = [];
            state.isSettingPixelToMeter = false;
        },

        setSelectedFloorId: (state, action) => {
            state.selectedFloorId = action.payload;
            state.selectedMapId = null;
            state.selectedMapperId = null;
            state.selectedLaneId = null;
            state.selectedLaneIds = [];
            state.highlightedLaneIds = [];
            state.selectedSignMarkIds = [];
            state.highlightedJunctionIds = [];
            state.isSettingPixelToMeter = false;
        },
        setSelectedMapId: (state, action) => {
            state.selectedMapId = action.payload;
            state.selectedMapperId = null;
            state.selectedLaneId = null;
            state.selectedLaneIds = [];
            state.highlightedLaneIds = [];
            state.highlightedJunctionIds = [];
            state.laneIdsShownInBack = [];
            state.laneIdsShownInFront = [];
            state.isSettingPixelToMeter = false;
            state.selectedSignMarkIds = [];
        },

        setSelectedMapperId: (state, action) => {
            state.selectedMapperId = action.payload;
        },
        setSelectedAreaOfInterestsMapperName: (state, action) => {
            state.selectedAreaOfInterestsMapperName = action.payload;
        },
        setSelectedElementsMapperName: (state, action) => {
            state.selectedElementsMapperName = action.payload;
        },
        setSelectedLineOfInterestsMapperName: (state, action) => {
            state.selectedLineOfInterestsMapperName = action.payload;
        },
        setSelectedLanesMapperName: (state, action) => {
            state.selectedLanesMapperName = action.payload;
        },
        setSelectedLanesMapperId: (state, action) => {
            state.selectedMapperId = action.payload;
        },
        setSelectedLanesMapperName: (state, action) => {
            state.selectedLanesMapperName = action.payload;
        },
        setSelectedLaneId: (state, action) => {
            state.selectedLaneId = action.payload;
            state.selectedElementId = null;
            state.selectedElementIds = [];
            state.selectedAreaId = null;
            state.selectedAreaOfInterestIds = [];
            state.selectedLineId = null;
            state.selectedLineOfInterestIds = [];
            state.selectedSignMarkIds = [];
        },

        setSelectedAreaId: (state, action) => {
            state.selectedAreaId = action.payload;
            state.selectedElementId = null;
            state.selectedElementIds = [];
            state.selectedLaneId = null;
            state.selectedLaneIds = [];
            state.selectedLineId = null;
            state.selectedLineOfInterestIds = [];
            state.selectedSignMarkIds = [];
        },
        setSelectedElementId: (state, action) => {
            state.selectedElementId = action.payload;
            state.selectedLaneId = null;
            state.selectedLaneIds = [];
            state.selectedLineId = null;
            state.selectedLineOfInterestIds = [];
            state.selectedAreaId = null;
            state.selectedAreaOfInterestIds = [];
            state.selectedSignMarkIds = [];
        },

        setSelectedLineId: (state, action) => {
            state.selectedLineId = action.payload;
            state.selectedElementId = null;
            state.selectedElementIds = [];
            state.selectedAreaId = null;
            state.selectedAreaOfInterestIds = [];
            state.selectedLaneId = null;
            state.selectedLaneIds = [];
            state.selectedSignMarkIds = [];
        },
        setSelectedLaneIds: (state, action) => {
            state.selectedLaneIds = [...new Set([...action.payload])];
        },
        setSelectedAreaOfInterestIds: (state, action) => {
            state.selectedAreaOfInterestIds = [...new Set([...action.payload])];
        },
        setSelectedElementIds: (state, action) => {
            state.selectedElementIds = [...new Set([...action.payload])];
        },
        setSelectedLineOfInterestIds: (state, action) => {
            state.selectedLineOfInterestIds = [...new Set([...action.payload])];
        },
        highlightLane: (state, action) => {
            state.highlightedLaneIds = [...new Set([...state.highlightedLaneIds, action.payload])];
        },

        unhighlightLane: (state, action) => {
            state.highlightedLaneIds = state.highlightedLaneIds.filter((id) => id !== action.payload);
        },

        highLightAreaOfInterest: (state, action) => {
            state.highlightedAreaOfInterestId = action.payload;
        },

        unhilightAreaOfInterest: (state, action) => {
            state.highlightedAreaOfInterestId = null;
        },

        highlightJunction: (state, action) => {
            state.highlightedJunctionIds = [...new Set([...state.highlightedJunctionIds, action.payload])];
        },

        unhighlightJunction: (state, action) => {
            state.highlightedJunctionIds = state.highlightedJunctionIds.filter((id) => id !== action.payload);
        },

        setShowJunctionsBelowMismatchThreshold: (state, action) => {
            state.showJunctionsBelowMismatchThreshold = action.payload;
        },

        setShowJunctionsAboveMismatchThreshold: (state, action) => {
            state.showJunctionsAboveMismatchThreshold = action.payload;
        },

        sendLaneToBack: (state, action) => {
            state.laneIdsShownInBack = [
                ...state.laneIdsShownInBack.filter((id) => id !== action.payload),
                action.payload,
            ];
            state.laneIdsShownInFront = state.laneIdsShownInFront.filter((id) => id !== action.payload);
        },

        bringLaneToFront: (state, action) => {
            state.laneIdsShownInFront = [
                ...state.laneIdsShownInFront.filter((id) => id !== action.payload),
                action.payload,
            ];
            state.laneIdsShownInBack = state.laneIdsShownInBack.filter((id) => id !== action.payload);
        },

        sendAreaToBack: (state, action) => {
            state.areaIdsShownInBack = [
                ...state.areaIdsShownInBack.filter((id) => id !== action.payload),
                action.payload,
            ];
            state.areaIdsShownInFront = state.areaIdsShownInFront.filter((id) => id !== action.payload);
        },

        bringAreaToFront: (state, action) => {
            state.areaIdsShownInFront = [
                ...state.areaIdsShownInFront.filter((id) => id !== action.payload),
                action.payload,
            ];
            state.areaIdsShownInBack = state.areaIdsShownInBack.filter((id) => id !== action.payload);
        },

        setAreLanesShown: (state, action) => {
            state.areLanesShown = action.payload;
        },

        setAreMappersShown: (state, action) => {
            state.areMappersShown = action.payload;
        },

        setAreAreasOfInterestShown: (state, action) => {
            state.areAreasOfInterestShown = action.payload;
        },

        setAreLinesOfInterestShown: (state, action) => {
            state.areLinesOfInterestShown = action.payload;
        },
        setAreElementsShown: (state, action) => {
            state.areElementsShown = action.payload;
        },
        setAreMapValidationsShown: (state, action) => {
            state.areMapValidationsShown = action.payload;
        },
        setAreSignMarksShown: (state, action) => {
            state.areSignMarksShown = action.payload;
        },
        setSignMarkLockInMap: (state, action) => {
            state.isSignMarkLockInMap = action.payload;
        },
        setAreElementsTitlesShown: (state, action) => {
            state.areElementsTitlesShown = action.payload;
        },
        setAreExitRegionsTitlesShown: (state, action) => {
            state.areExitRegionTitlesShown = action.payload;
        },
        setIsAreaOfInterestCreationOpen: (state, action) => {
            state.isAreaOfInterestCreationOpen = action.payload;
        },

        setIsLineOfInterestCreationOpen: (state, action) => {
            state.isLineOfInterestCreationOpen = action.payload;
        },
        setIsElementCreationOpen: (state, action) => {
            state.isElementCreationOpen = action.payload;
        },
        setIsMapInGrayscale: (state, action) => {
            state.isMapInGrayscale = action.payload;
        },

        setIsSettingPixelToMeter: (state, action) => {
            state.isSettingPixelToMeter = action.payload;
        },

        setIsShowingEqualizedMap: (state, action) => {
            state.isShowingEqualizedMap = action.payload;
        },

        setIsMapCroppingCreationOpen: (state, action) => {
            state.isMapCroppingCreationOpen = action.payload;
        },
        setIsSignMarkCreationOpen: (state, action) => {
            state.isSignMarkCreationOpen = action.payload;
        },

        setIsGenerateMapGridOpen: (state, action) => {
            state.isGenerateMapGridOpen = action.payload;
        },
        setAreAreasOfInterestActionsShown: (state, action) => {
            state.areAreasOfInterestActionsShown = action.payload;
        },
        setSelectedSignMarkIds: (state, action) => {
            state.selectedSignMarkIds = action.payload;
            state.selectedElementId = null;
            state.selectedElementIds = [];
            state.selectedAreaId = null;
            state.selectedAreaOfInterestIds = [];
            state.selectedLaneId = null;
            state.selectedLaneIds = [];
            state.selectedLineId = null;
            state.selectedLineOfInterestIds = [];
        },
        openSignMarkEditor: (state, action) => {
            state.signMarkEditor.isOpen = true;
            state.signMarkEditor.signMarkId = action.payload;
        },
        closeSignMarkEditor: (state) => {
            state.signMarkEditor.isOpen = false;
            state.signMarkEditor.signMarkId = null;
        },
        bringSignMarkToFront: (state, action) => {
            state.signMarkIdsShownInFront = [
                ...state.signMarkIdsShownInFront.filter((id) => id !== action.payload),
                action.payload,
            ];
            state.signMarkIdsShownInBack = state.signMarkIdsShownInBack.filter((id) => id !== action.payload);
        },
        sendSignMarkToBack: (state, action) => {
            state.signMarkIdsShownInBack = [
                ...state.signMarkIdsShownInBack.filter((id) => id !== action.payload),
                action.payload,
            ];
            state.signMarkIdsShownInFront = state.signMarkIdsShownInFront.filter(
                (id) => id !== action.payload
            );
        },
    },
    extraReducers: {
        [setSelectedSpaceId]: (state) => {
            state.selectedBuildingId = null;
            state.selectedFloorId = null;
            state.selectedMapId = null;
            state.selectedMapperId = null;
            state.selectedLaneId = null;
            state.selectedAreaId = null;
            state.selectedLineId = null;
            state.isAreaOfInterestCreationOpen = false;
            state.isLineOfInterestCreationOpen = false;
            state.isElementCreationOpen = false;
            state.isSettingPixelToMeter = false;
            state.isShowingEqualizedMap = true;
            state.isMapCroppingOpen = false;
            state.selectedLaneIds = [];
            state.selectedAreaOfInterestIds = [];
            state.selectedElementIds = [];
            state.selectedLineOfInterestIds = [];
            state.selectedSignMarkIds = [];
        },

        [deleteLane.fulfilled]: (state, action) => {
            const { laneId } = action.meta.arg;

            state.laneIdsShownInBack = state.laneIdsShownInBack.filter((id) => id !== laneId);
            state.laneIdsShownInFront = state.laneIdsShownInFront.filter((id) => id !== laneId);

            if (state.selectedLaneId === laneId) {
                state.selectedLaneId = null;
            }
        },

        [laneDeleted]: (state, action) => {
            const { laneId } = action.payload.data;

            state.laneIdsShownInBack = state.laneIdsShownInBack.filter((id) => id !== laneId);
            state.laneIdsShownInFront = state.laneIdsShownInFront.filter((id) => id !== laneId);

            if (state.selectedLaneId === laneId) {
                state.selectedLaneId = null;
            }
        },

        [deleteAreaOfInterest.fulfilled]: (state, action) => {
            const { areaId } = action.meta.arg;

            if (state.selectedAreaId === areaId) {
                state.selectedAreaId = null;
            }
        },
        [deleteElement.fulfilled]: (state, action) => {
            const { areaId } = action.meta.arg;

            if (state.selectedAreaId === areaId) {
                state.selectedAreaId = null;
            }
        },
        [deleteExitRegion.fulfilled]: (state, action) => {
            const { areaId } = action.meta.arg;

            if (state.selectedAreaId === areaId) {
                state.selectedAreaId = null;
            }
        },
        [areaOfInterestDeleted]: (state, action) => {
            const { areaId } = action.payload.data;

            if (state.selectedAreaId === areaId) {
                state.selectedAreaId = null;
            }
        },
        [elementsDeleted]: (state, action) => {
            const { areaId } = action.payload.data;

            if (state.selectedAreaId === areaId) {
                state.selectedAreaId = null;
            }
        },
        [exitRegionDeleted]: (state, action) => {
            const { areaId } = action.payload.data;

            if (state.selectedAreaId === areaId) {
                state.selectedAreaId = null;
            }
        },
        [deleteLineOfInterest.fulfilled]: (state, action) => {
            const { lineId } = action.meta.arg;

            if (state.selectedLineId === lineId) {
                state.selectedLineId = null;
            }
        },

        [lineOfInterestDeleted]: (state, action) => {
            const { lineId } = action.payload.data;

            if (state.selectedLineId === lineId) {
                state.selectedLineId = null;
            }
        },

        [deleteBuilding.fulfilled]: (state, action) => {
            if (action.meta.arg === state.selectedBuildingId) {
                state.selectedBuildingId = action.payload;
                state.selectedFloorId = null;
                state.selectedMapId = null;
                state.selectedMapperId = null;
                state.selectedLaneId = null;
                state.selectedAreaId = null;
                state.selectedLineId = null;
                state.isAreaOfInterestCreationOpen = false;
                state.isLineOfInterestCreationOpen = false;
                state.isElementCreationOpen = false;
                state.isSettingPixelToMeter = false;
                state.isShowingEqualizedMap = true;
                state.selectedLaneIds = [];
                state.selectedAreaOfInterestIds = [];
                state.selectedElementIds = [];
                state.selectedLineOfInterestIds = [];
            }
        },

        [deleteFloor.fulfilled]: (state, action) => {
            if (action.meta.arg.floorId === state.selectedFloorId) {
                state.selectedFloorId = null;
                state.selectedMapId = null;
                state.selectedMapperId = null;
                state.selectedLaneId = null;
                state.selectedAreaId = null;
                state.selectedLineId = null;
                state.isAreaOfInterestCreationOpen = false;
                state.isElementCreationOpen = false;
                state.isLineOfInterestCreationOpen = false;
                state.isSettingPixelToMeter = false;
                state.isShowingEqualizedMap = true;
                state.selectedLaneIds = [];
                state.selectedAreaOfInterestIds = [];
                state.selectedElementIds = [];
                state.selectedLineOfInterestIds = [];
            }
        },

        [deleteMap.fulfilled]: (state, action) => {
            if (action.meta.arg.mapId === state.selectedMapId) {
                state.selectedMapId = null;
                state.selectedMapperId = null;
                state.selectedLaneId = null;
                state.selectedAreaId = null;
                state.selectedLineId = null;
                state.isAreaOfInterestCreationOpen = false;
                state.isLineOfInterestCreationOpen = false;
                state.isElementCreationOpen = false;
                state.isSettingPixelToMeter = false;
                state.isShowingEqualizedMap = true;
                state.selectedLaneIds = [];
                state.selectedAreaOfInterestIds = [];
                state.selectedElementIds = [];
                state.selectedLineOfInterestIds = [];
                state.selectedSignMarkIds = [];
            }
        },

        [wipeAllUserInputs]: () => initialState,
    },
});

export const selectMappingIsSideDrawerOpen = (state) => state.userInputs.mapping.isSideDrawerOpen;
export const selectMappingSelectedBuildingId = (state) => state.userInputs.common.selectedBuildingId;
export const selectMappingSelectedFloorId = (state) => state.userInputs.common.selectedFloorId;
export const selectMappingSelectedMapId = (state) => state.userInputs.common.selectedMapId;
export const selectMappingSelectedMapperId = (state) => state.userInputs.mapping.selectedMapperId;
export const selectMappingSelectedElementsMapperName = (state) =>
    state.userInputs.mapping.selectedElementsMapperName;
export const selectMappingSelectedLineOfInterestsMapperName = (state) =>
    state.userInputs.mapping.selectedLineOfInterestsMapperName;
export const selectMappingSelectedAreaOfInterestsMapperName = (state) =>
    state.userInputs.mapping.selectedAreaOfInterestsMapperName;
export const selectMappingSelectedLanesMapperId = (state) => state.userInputs.mapping.selectedLanesMapperId;
export const selectMappingSelectedLanesMapperName = (state) =>
    state.userInputs.mapping.selectedLanesMapperName;
export const selectMappingSelectedLaneId = (state) => state.userInputs.mapping.selectedLaneId;
export const selectMappingSelectedAreaId = (state) => state.userInputs.mapping.selectedAreaId;
export const selectMappingSelectedElementId = (state) => state.userInputs.mapping.selectedElementId;
export const selectMappingSelectedLineId = (state) => state.userInputs.mapping.selectedLineId;
export const selectMappingHighlightedAreaOfInterestId = (state) =>
    state.userInputs.mapping.highlightedAreaOfInterestId;
export const selectMappingHighlightedLaneIds = (state) => state.userInputs.mapping.highlightedLaneIds;
export const selectMappingHighlightedJunctionIds = (state) => state.userInputs.mapping.highlightedJunctionIds;
export const selectMappingShowJunctionsBelowMismatchThreshold = (state) =>
    state.userInputs.mapping.showJunctionsBelowMismatchThreshold;
export const selectMappingShowJunctionsAboveMismatchThreshold = (state) =>
    state.userInputs.mapping.showJunctionsAboveMismatchThreshold;
export const selectMappingLaneIdsShownInBack = (state) => state.userInputs.mapping.laneIdsShownInBack;
export const selectMappingLaneIdsShownInFront = (state) => state.userInputs.mapping.laneIdsShownInFront;
export const selectMappingAreaIdsShownInBack = (state) => state.userInputs.mapping.areaIdsShownInBack;
export const selectMappingAreaIdsShownInFront = (state) => state.userInputs.mapping.areaIdsShownInFront;
export const selectMappingLaneIds = (state) => state.userInputs.mapping.selectedLaneIds;
export const selectMappingAreaOfInterestIds = (state) => state.userInputs.mapping.selectedAreaOfInterestIds;
export const selectMappingElementIds = (state) => state.userInputs.mapping.selectedElementIds;
export const selectMappingLineOfInterestIds = (state) => state.userInputs.mapping.selectedLineOfInterestIds;
export const selectMappingAreLanesShown = (state) => state.userInputs.mapping.areLanesShown;
export const selectMappingAreElementsShown = (state) => state.userInputs.mapping.areElementsShown;
export const selectMappingAreExitRegionsShown = (state) => state.userInputs.mapping.areExitRegionsShown;
export const selectMappingAreElementsTitlesShown = (state) => state.userInputs.mapping.areElementsTitlesShown;
export const selectMappingAreExitRegionTitlesShown = (state) =>
    state.userInputs.mapping.areExitRegionTitlesShown;
export const selectMappingAreMappersShown = (state) => state.userInputs.mapping.areMappersShown;
export const selectMappingAreAreasOfInterestShown = (state) =>
    state.userInputs.mapping.areAreasOfInterestShown;
export const selectMappingAreLinesOfInterestShown = (state) =>
    state.userInputs.mapping.areLinesOfInterestShown;
export const selectMappingAreMapValidationsShown = (state) => state.userInputs.mapping.areMapValidationsShown;
export const selectMappingAreSignMarksShown = (state) => state.userInputs.mapping.areSignMarksShown;
export const selectMappingIsSignMarkLockInMap = (state) => state.userInputs.mapping.isSignMarkLockInMap;
export const selectMappingIsAreaOfInterestCreationOpen = (state) =>
    state.userInputs.mapping.isAreaOfInterestCreationOpen;
export const selectMappingIsLineOfInterestCreationOpen = (state) =>
    state.userInputs.mapping.isLineOfInterestCreationOpen;
export const selectMappingIsElementCreationOpen = (state) => state.userInputs.mapping.isElementCreationOpen;
export const selectMappingIsMapInGrayscale = (state) => state.userInputs.mapping.isMapInGrayscale;
export const selectMappingIsSettingPixelToMeter = (state) => state.userInputs.mapping.isSettingPixelToMeter;
export const selectMappingIsShowingEqualizedMap = (state) => state.userInputs.mapping.isShowingEqualizedMap;

export const getSelectIsLaneHighlighted = (laneId) =>
    createSelector(selectMappingHighlightedLaneIds, (laneIds) => laneIds.includes(laneId));

export const getSelectIsLaneSelected = (laneId) =>
    createSelector(selectMappingLaneIds, (laneIds) => laneIds.includes(laneId));

export const getSelectIsJunctionHighlighted = (junctionId) =>
    createSelector(selectMappingHighlightedJunctionIds, (junctionIds) => junctionIds.includes(junctionId));

export const selectMapCroppingCreationOpen = (state) => state.userInputs.mapping.isMapCroppingCreationOpen;
export const selectSignMarkCreationOpen = (state) => state.userInputs.mapping.isSignMarkCreationOpen;
export const selectMappingIsGenerateMapGridOpen = (state) => state.userInputs.mapping.isGenerateMapGridOpen;
export const selectMappingAreasOfInterestActionsShown = (state) =>
    state.userInputs.mapping.areAreasOfInterestActionsShown;
export const selectMappingSignMarkIds = (state) => state.userInputs.mapping.selectedSignMarkIds;
export const selectMappingSignMarkEditor = (state) => state.userInputs.mapping.signMarkEditor;
export const selectMappingSignMarkIdsShownInBack = (state) => state.userInputs.mapping.signMarkIdsShownInBack;
export const selectMappingSignMarkIdsShownInFront = (state) =>
    state.userInputs.mapping.signMarkIdsShownInFront;
