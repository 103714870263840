export default uniqueIdentifier => ({
    charts: {
        CARD_CONTAINER_ID: `analytics-sessions-group-card-${uniqueIdentifier}`,
        TIMELINE_REGIONS_CONTAINER_ID: `regions-timeline-container-${uniqueIdentifier}`,
        PIE_CONTAINER_ID: `regions-pie-container-${uniqueIdentifier}`,
        PIE_LEGEND_CONTAINER_ID: `regions-pie-legend-container-${uniqueIdentifier}`,
        BAR_CONTAINER_ID: `movement-bar-container-${uniqueIdentifier}`,
        REGION_VISITATION_CONTAINER_ID: `analytics-region-visitation-details-${uniqueIdentifier}`,
        LEVEL_SELECTOR_ID: `analytics-level-selector-${uniqueIdentifier}`,
    },
    filters: {
        FROM_DATE_PICKER_ID: 'analytics-filter-from-date',
        TO_DATE_PICKER_ID: 'analytics-filter-to-date',
        ACCEPTED_ACCURACY_ID: 'analytics-filter-accepted-accuracy',
        MAP_SELECTOR_ID: 'analytics-filter-map-select',
        GRANULARITY_SELECTOR_ID: 'analytics-filter-granularity',
        TIMEZONE_SELECTOR_ID: 'analytics-filter-timezone',
        SESSION_ID_FIELD_ID: 'analytics-filter-session-id',
    },
    heatMap: {
        STEP_SIZE_ID: 'analytics-step-size',
        MAX_DURATION_ID: 'analytics-max-duration'
    }
});
