import { Badge } from '@material-ui/core';
import styled from 'styled-components';

export default styled(Badge).attrs(({ classes, ...otherProps }) => ({
    classes: { badge: 'themed-badge', ...classes },
    ...otherProps,
}))`
    .themed-badge {
        background-color: #ff6200; // TODO color should come from theme
        color: #ffffff;
    }
`;
