import { createSelector } from 'reselect';
import entityAdapter from './regionEntityAdapter';
import { selectAllTriggers, selectTriggerEntities } from '../trigger/triggerSelectors';
import { selectBuildingById } from '../building/buildingSelectors';
import {
    selectRegionPlacementById,
    selectRegionPlacementEntities,
} from '../region-placement/regionPlacementSelectors';

export const {
    selectById: selectRegionById,
    selectIds: selectRegionIds,
    selectEntities: selectRegionEntities,
    selectAll: selectAllRegions,
    selectTotal: selectTotalRegions,
} = entityAdapter.getSelectors((state) => state.regions);

export const selectRegionIdToTriggersArray = createSelector(
    selectAllRegions,
    selectTriggerEntities,
    (regions, triggers) => {
        if (!regions || regions.length === 0) {
            return {};
        }

        return regions.reduce(
            (result, region) => ({
                ...result,
                [region.regionId]: region.triggerIds?.map((triggerId) => triggers[triggerId]),
            }),
            {}
        );
    }
);

export const selectRegionIdToTriggersObject = createSelector(
    selectAllRegions,
    selectTriggerEntities,
    (regions, triggers) => {
        if (!regions || regions.length === 0) {
            return {};
        }

        return regions.reduce(
            (result, region) => ({
                ...result,
                [region.regionId]: region.triggerIds?.reduce(
                    (res, triggerId) => ({ ...res, [triggerId]: triggers[triggerId] }),
                    {}
                ),
            }),
            {}
        );
    }
);

export const selectTriggerIdToRegionsArray = createSelector(
    selectAllTriggers,
    selectRegionEntities,
    (triggers, regions) => {
        if (!triggers || triggers.length === 0) {
            return {};
        }

        return triggers.reduce(
            (result, trigger) => ({
                ...result,
                [trigger.triggerId]: trigger.regionIds
                    ? trigger.regionIds.map((regionId) => regions[regionId])
                    : null,
            }),
            {}
        );
    }
);

export const selectTriggerIdToRegionsObject = createSelector(
    selectAllTriggers,
    selectRegionEntities,
    (triggers, regions) => {
        if (!triggers || triggers.length === 0) {
            return {};
        }

        return triggers.reduce(
            (result, trigger) => ({
                ...result,
                [trigger.triggerId]: trigger.regionIds
                    ? trigger.regionIds.reduce(
                          (res, regionId) => ({ ...res, [regionId]: regions[regionId] }),
                          {}
                      )
                    : null,
            }),
            {}
        );
    }
);

export const getSelectAllRegionsWithExternalPlacementsInBuilding = (buildingId) =>
    createSelector(
        (state) => selectBuildingById(state, buildingId),
        selectAllRegions,
        selectRegionPlacementEntities,
        (building, regions, placements) => {
            const regionPlacements = building?.regionPlacementIds?.map((id) => placements?.[id]);
            const placementIdToRegion = regions
                ?.filter(({ placementIds }) => placementIds?.length > 0)
                ?.reduce(
                    (result, { regionId, regionName, regionMetadata, placementIds = [] }) => ({
                        ...result,
                        ...placementIds.reduce(
                            (res, placementId) => ({
                                ...res,
                                [placementId]: { regionId, regionName, regionMetadata },
                            }),
                            {}
                        ),
                    }),
                    {}
                );

            return regionPlacements
                ?.filter((p) => !!placementIdToRegion?.[p.placementId])
                ?.map((p) => ({ ...placementIdToRegion?.[p.placementId], ...p }));
        }
    );

export const getSelectRegionWithPlacement = (placementId) =>
    createSelector(
        selectAllRegions,
        (state) => selectRegionPlacementById(state, placementId),
        (regions, placement) => {
            const region = regions?.filter(
                (r) => !!r?.placements?.find((p) => p.placementId === placementId)
            );

            if (!region) {
                return null;
            }

            const { regionId, regionName, regionMetadata } = region;
            return { regionId, regionName, regionMetadata, ...placement };
        }
    );
