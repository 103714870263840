import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import {
    selectHasError as getSelectHasError,
    selectIsLoading as getSelectIsLoading,
} from '../../../../state-management/status/statusSelectors';
import {
    selectAllSpaces,
    selectAreSpacesFetched,
    selectSpaceById,
    selectSpaceEntities,
} from '../../../../state-management/space/spaceSelectors';
import { fetchAllSpaces } from '../../../../state-management/space/spaceActions';

/**
 * Hook that fetches all spaces to the Redux store and returns either all or one of them, based on the provided options.
 *
 * @param asObject - Return all entities as an object, with the IDs as the keys.
 * @return {{isLoading: *, data: *, hasError: *}}
 */
export default function useSpaces({ asObject = false, spaceId } = {}) {
    const dispatch = useDispatch();

    const selectIsLoadingSpaces = useMemo(() => getSelectIsLoading(fetchAllSpaces.typePrefix), []);
    const selectHasErrorInSpaces = useMemo(() => getSelectHasError(fetchAllSpaces.typePrefix), []);

    const spacesArray = useSelector(selectAllSpaces);
    const spacesObject = useSelector(selectSpaceEntities);
    const space = useSelector((state) => selectSpaceById(state, spaceId));
    const isFetched = useSelector(selectAreSpacesFetched);
    const isFetching = useSelector(selectIsLoadingSpaces);
    const hasError = useSelector(selectHasErrorInSpaces);

    useEffect(() => {
        // If the spaces haven't been fetched yet, fetch them
        if (!isFetched && !isFetching && !hasError) {
            dispatch(fetchAllSpaces());
        }
    }, [dispatch, hasError, isFetched, isFetching]);

    return {
        data: spaceId ? space : asObject ? spacesObject : spacesArray,
        isLoading: isFetching,
        hasError,
    };
}
