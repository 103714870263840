import React, { forwardRef, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
    Tooltip,
    CircularPreloader,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '../../../common/themed';
import { MdCancel as CancelIcon, MdDelete as DeleteIcon } from 'react-icons/md';
import { BsArrowsMove as MoveIcon } from 'react-icons/bs';
import { IconButton, RootRef, Grid } from '@material-ui/core';
import { selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { deleteLane } from '../../../../state-management/mapping/lane/laneActions';
import { useDispatch, useSelector } from 'react-redux';
import {
    getEntranceListEntryDeleteId,
    getEntranceListEntryEditPositionId,
    getEntranceListEntryShowInfoId,
} from '../../buildings.selectors';
import { deleteEntrance } from '../../../../state-management/entrance/entranceActions';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import {
    selectBuildingsIsSelectedEntranceEditable,
    setIsSelectedEntranceEditable,
    setSelectedEntranceId,
} from '../../../../state-management/user-inputs/buildingsSlice';
import { AiOutlineInfoCircle as InfoIcon } from 'react-icons/ai';
import { selectCanEditEntrances } from '../../../../state-management/auth/authSelectors';

const IconWrapper = forwardRef((props, ref) => (
    <RootRef rootRef={ref}>
        <IconButton {...props} />
    </RootRef>
));

export default function EntranceActions(props) {
    const { buildingId, floorId, entranceId, showShowInfo = true, showEditPosition = true } = props;

    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

    const dispatch = useDispatch();

    const selectIsDeleting = useMemo(
        () => selectIsLoading({ type: deleteLane.typePrefix, arg: { buildingId, floorId, entranceId } }),
        [buildingId, floorId, entranceId]
    );

    const isDeleting = useSelector(selectIsDeleting);
    const isSelectedEntranceEditable = useSelector(selectBuildingsIsSelectedEntranceEditable);
    const canEditEntrances = useSelector(selectCanEditEntrances);

    const handleDelete = async () => {
        const result = await dispatch(deleteEntrance({ buildingId, floorId, entranceId }));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Entrance deleted successfully.'));
        } else {
            dispatch(showErrorNotification('Failed to delete building.'));
        }

        setIsDeleteWarningOpen(false);
    };

    return (
        <>
            <Grid container alignItems={'center'} justifyContent={'space-evenly'}>
                <Tooltip content={'Delete entrance'} placement={'bottom'} useWrapper={false}>
                    <IconWrapper
                        id={getEntranceListEntryDeleteId(entranceId)}
                        aria-label={'Delete'}
                        onClick={() => setIsDeleteWarningOpen(true)}
                        disabled={!canEditEntrances}
                    >
                        <DeleteIcon />
                    </IconWrapper>
                </Tooltip>

                {showShowInfo && (
                    <Tooltip content={'Show info'} placement={'bottom'} useWrapper={false}>
                        <IconWrapper
                            id={getEntranceListEntryShowInfoId(entranceId)}
                            aria-label={'Show info'}
                            onClick={() => dispatch(setSelectedEntranceId(entranceId))}
                        >
                            <InfoIcon />
                        </IconWrapper>
                    </Tooltip>
                )}

                {showEditPosition &&
                    (isSelectedEntranceEditable ? (
                        <Tooltip content={'Cancel edit'} placement={'bottom'} useWrapper={false}>
                            <IconWrapper
                                id={getEntranceListEntryEditPositionId(entranceId)}
                                aria-label={'Cancel edit'}
                                onClick={() => dispatch(setIsSelectedEntranceEditable(false))}
                                disabled={!canEditEntrances}
                            >
                                <CancelIcon />
                            </IconWrapper>
                        </Tooltip>
                    ) : (
                        <Tooltip content={'Edit position'} placement={'bottom'} useWrapper={false}>
                            <IconWrapper
                                id={getEntranceListEntryEditPositionId(entranceId)}
                                aria-label={'Edit position'}
                                onClick={() => dispatch(setIsSelectedEntranceEditable(true))}
                                disabled={!canEditEntrances}
                            >
                                <MoveIcon />
                            </IconWrapper>
                        </Tooltip>
                    ))}
            </Grid>

            {isDeleteWarningOpen && (
                <Dialog open closeable={false}>
                    <DialogContent>
                        <DialogContentText>
                            You are about to delete the entrance with the ID: "{entranceId}". Are you sure?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button variant={'text'} onClick={() => setIsDeleteWarningOpen(false)}>
                            Cancel
                        </Button>

                        {isDeleting ? <CircularPreloader /> : <Button onClick={handleDelete}>Accept</Button>}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

EntranceActions.propTypes = {
    buildingId: PropTypes.string,
    floorId: PropTypes.string,
    entranceId: PropTypes.string,
    showShowInfo: PropTypes.bool,
    showEditPosition: PropTypes.bool,
};
