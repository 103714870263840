import React, { useEffect } from 'react';
import { Button, TextField } from '../../../common/themed';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
    Grid,
    Chip,
    Typography,
    IconButton,
    Card,
    CardContent,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { MdAdd as AddIcon, MdDelete as DeleteIcon, MdExpandMore as ExpandMoreIcon } from 'react-icons/md';
import {
    getBuildingDisplayNameId,
    getBuildingNameId,
    getCreateNewFloorId,
    getFloorHeightId,
    getFloorIndexId,
    getFloorNameId,
    getFloorRotationId,
    getFloorShortNameId,
    getMarkFloorAsPrimaryId,
} from './CreateBuildingDialog.selectors';

const PrimaryFloorChip = styled(Chip)`
    margin-inline-start: 10px;
    background-color: #ff6200; // TODO color should come from theme
    color: white;
`;

const ClickableSquare = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cccccc;
    cursor: pointer;
    transition: background-color 200ms ease;
    padding: 15px;

    svg {
        margin-right: 5px;
        width: 25px;
        height: 25px;
    }

    :hover {
        background-color: #ff6200; // TODO color should come from theme;
        color: white;

        svg {
            fill: white;
        }
    }
`;

const FloorFormWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 40px;
`;

const floorsHeightValidation = (floors) => ({
    result: floors
        ?.filter((f) => f?.floorIndex !== undefined)
        ?.sort((a, b) => a.floorIndex - b.floorIndex)
        ?.every(
            (floor, index, arr) =>
                index === 0 ||
                (floor?.height !== undefined &&
                    arr[index - 1]?.height !== undefined &&
                    floor.height > arr[index - 1].height)
        ),
    errorMessage: 'Floor heights cannot be greater/smaller than floors with higher/lower indexes',
});

export default function CreateBuildingStepOne() {
    const { register, errors, setValue, watch, setError, clearErrors, getValues } = useFormContext();
    const { primaryFloorArrayIndex, floors } = watch(['primaryFloorArrayIndex', 'floors']);

    const { fields, append, remove } = useFieldArray({
        name: 'floors',
    });

    const floorIndexes = floors.map((f) => f.floorIndex);

    useEffect(() => {
        const heightValidation = floorsHeightValidation(floors);

        if (heightValidation.result && errors?.custom) {
            clearErrors('custom');
        } else if (!heightValidation.result && !errors?.custom) {
            setError('custom', {
                message: heightValidation.errorMessage,
            });
        }
    }, [clearErrors, setError, floors, errors]);

    return (
        <>
            <Card>
                <CardContent>
                    <Grid container justifyContent={'space-evenly'}>
                        <Grid item>
                            <TextField
                                id={getBuildingNameId()}
                                name={'buildingName'}
                                label={'Building Name'}
                                inputRef={register({
                                    required: 'This is required',
                                    maxLength: {
                                        value: 50,
                                        message: 'Building name must be under 50 characters',
                                    },
                                })}
                                error={!!errors?.buildingName || getValues('buildingName').trim() === ''}
                                helperText={errors?.buildingName?.message}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                id={getBuildingDisplayNameId()}
                                name={'displayName'}
                                label={'Display Name'}
                                inputRef={register({
                                    required: 'This is required',
                                    maxLength: {
                                        value: 50,
                                        message: 'Display name must be under 50 characters',
                                    },
                                })}
                                error={!!errors?.displayName || getValues('displayName').trim() === ''}
                                helperText={errors?.displayName?.message}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {fields?.map(
                ({ id, floorName, floorShortName, floorIndex, primary, height, rotation }, index) => (
                    <Accordion key={id} defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ height: 70 }}>
                            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                                <Grid item>
                                    <Typography inline={'true'} variant={'h6'}>
                                        {floorName}
                                    </Typography>

                                    {primaryFloorArrayIndex === index && (
                                        <PrimaryFloorChip label={'Primary'} />
                                    )}
                                </Grid>

                                {primaryFloorArrayIndex !== index && (
                                    <Grid item>
                                        <Grid container alignItems={'center'}>
                                            <IconButton onClick={() => remove(index)}>
                                                <DeleteIcon />
                                            </IconButton>

                                            <Button
                                                id={getMarkFloorAsPrimaryId()}
                                                variant={'outlined'}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setValue('primaryFloorArrayIndex', index);
                                                }}
                                            >
                                                Mark as primary
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </AccordionSummary>

                        <AccordionDetails>
                            <FloorFormWrapper>
                                <TextField
                                    id={getFloorNameId(index)}
                                    name={`floors[${index}].floorName`}
                                    label={'Floor Name'}
                                    inputRef={register({
                                        required: 'This is required',
                                        maxLength: {
                                            value: 50,
                                            message: 'Floor name must be under 50 characters',
                                        },
                                    })}
                                    error={!!errors?.floors?.[index]?.floorName}
                                    helperText={errors?.floors?.[index]?.floorName?.message}
                                    defaultValue={floorName ?? ''}
                                />

                                <TextField
                                    id={getFloorShortNameId(index)}
                                    name={`floors[${index}].floorShortName`}
                                    label={'Short Name'}
                                    inputRef={register({
                                        required: 'This is required',
                                        maxLength: {
                                            value: 10,
                                            message: 'Short name must be under 10 characters',
                                        },
                                    })}
                                    error={!!errors?.floors?.[index]?.floorShortName}
                                    helperText={errors?.floors?.[index]?.floorShortName?.message}
                                    defaultValue={floorShortName ?? ''}
                                />

                                <Controller
                                    id={getFloorIndexId(index)}
                                    name={`floors[${index}].floorIndex`}
                                    defaultValue={floorIndex ?? 0}
                                    rules={{ required: 'This is required' }}
                                    render={({ onChange, value }) => (
                                        <NumberFormat
                                            label={'Index'}
                                            error={!!errors?.floors?.[index]?.floorIndex}
                                            helperText={
                                                errors?.floors?.[index]?.floorIndex?.message ??
                                                'This serves as the floor number (0 = ground floor)'
                                            }
                                            customInput={TextField}
                                            onValueChange={({ floatValue }) => {
                                                onChange(floatValue);
                                            }}
                                            value={value}
                                            decimalScale={0}
                                        />
                                    )}
                                />

                                <Controller
                                    id={getFloorHeightId(index)}
                                    name={`floors[${index}].height`}
                                    defaultValue={height ?? 0}
                                    rules={{
                                        required: 'This is required',
                                    }}
                                    render={({ onChange, value }) => (
                                        <NumberFormat
                                            label={'Height'}
                                            error={!!errors?.floors?.[index]?.height}
                                            helperText={'Relative height from the ground floor'}
                                            customInput={TextField}
                                            onValueChange={({ floatValue }) => {
                                                onChange(floatValue);
                                            }}
                                            value={value}
                                            suffix={' m'}
                                        />
                                    )}
                                />

                                <Controller
                                    id={getFloorRotationId(index)}
                                    name={`floors[${index}].rotation`}
                                    defaultValue={rotation ?? 0}
                                    rules={{
                                        required: 'This is required',
                                        min: {
                                            value: -360,
                                            message: 'Please provide a value between 360 and -360 degrees',
                                        },
                                        max: {
                                            value: 360,
                                            message: 'Please provide a value between 360 and -360 degrees',
                                        },
                                    }}
                                    render={({ onChange, value }) => (
                                        <NumberFormat
                                            label={'Rotation'}
                                            error={!!errors?.floors?.[index]?.rotation}
                                            helperText={
                                                errors?.floors?.[index]?.rotation?.message ??
                                                'Relative rotation from the ground floor'
                                            }
                                            customInput={TextField}
                                            onValueChange={({ floatValue }) => {
                                                onChange(floatValue);
                                            }}
                                            value={value}
                                            suffix={'°'}
                                        />
                                    )}
                                />
                            </FloorFormWrapper>
                        </AccordionDetails>
                    </Accordion>
                )
            )}

            <ClickableSquare
                id={getCreateNewFloorId()}
                onClick={() =>
                    append({
                        floorName: `Floor ${Math.max(...floorIndexes) + 1}`,
                        floorShortName: `F${Math.max(...floorIndexes) + 1}`,
                        floorIndex: Math.max(...floorIndexes) + 1,
                        height: 0,
                        rotation: 0,
                        maps: [],
                    })
                }
            >
                <AddIcon />
                Add New Floor
            </ClickableSquare>

            <input hidden name={'primaryFloorArrayIndex'} ref={register({ valueAsNumber: true })} />
        </>
    );
}
