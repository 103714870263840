import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { calculatedColor, togglesConfig } from './utils';
import { useMapInvestigation } from '../../common/hooks/data-fetching/useMapInvestigation';
import LoadingScreen from '../../main-layout/loading-screen';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';
import {
    CustomDialog,
    Image,
    CustomDialogContent,
    CustomDialogTitle,
    CustomDivider,
    HeaderWrapper,
    ImageComponent,
    ImageContainer,
    MapDataItem,
    SliderContainer,
    ToggleWrapper,
    Transition,
    ButtonWrapper,
    ColorContainer,
    ColorItem,
    COLORS,
} from './styles.jsx';
import styled from 'styled-components';
import { useRef } from 'react';
import { useLayoutEffect } from 'react';

const ZoomImage = styled(Image)`
    height: 90vh;
    margin: 0 auto;
    cursor: default;
    max-height: unset;
`;



export const InvestigationModal = ({ open, handleClose, selectedRecord, fetchMapQuality }) => {
    const { mapId, buildingName, buildingId, gridMetadata = {} } = selectedRecord;
    const sliderRef = useRef();
    const {
        toggles,
        data: investData,
        isLoading,
        hasError,
        selectedDay,
        onSliderChanged,
        onToggleChanged,
        shouldDisableToggle,
        imageUrl,
        showZoomImage,
        openZoomModal,
        closeZoomModal,
        isImageLoading,
        hasImageError,
        getDownloadUrl,
        createPublishGrid,
        publishLoading,
        publishSubmitted,
    } = useMapInvestigation({ mapId, buildingId, fetchMapQuality });

    useEffect(() => {
        process.nextTick(() => {
            if (sliderRef.current && investData.length) {
                sliderRef.current.childNodes.forEach((child, index) => {
                    if (investData[index]) {
                        const { date } = investData[index];
                        child.setAttribute('tooltip-value', `Day ${index - 3} (${date})`);
                    }
                });
            }
        });
    }, [investData]);

    if (hasError) return <ErrorGeneral />;

    const yesterday = selectedDay - 1;
    const { date, mapData = {} } = investData[yesterday] || {};
    const {
        daysBasedOn,
        daysBasedOnHealing = 0,
        sessionsNum,
        trackingScore,
        magneticAlignment,
        lanesAlignmentPercentage = 0,
        obstaclesAlignmentPercentage = 0,
    } = mapData;
    const totalDays = investData.length;

    return (
        <>
            {
                <CustomDialog open={showZoomImage} fullScreen onClose={closeZoomModal}>
                    {isImageLoading ? (
                        <LoadingScreen fullScreen />
                    ) : hasImageError ? (
                        <ErrorGeneral />
                    ) : (
                        <ZoomImage src={imageUrl} alt={'zoom image'} />
                    )}
                </CustomDialog>
            }
            <CustomDialog TransitionComponent={Transition} open={open} onClose={handleClose}>
                <CustomDialogTitle>
                    {buildingName} | {mapId}
                </CustomDialogTitle>
                <CustomDivider variant="fullWidth"></CustomDivider>
                {isLoading ? (
                    <LoadingScreen fullScreen />
                ) : (
                    <CustomDialogContent>
                        <Grid container justifyContent="space-between" direction="row">
                            <HeaderWrapper width="50%">
                                <MapDataItem
                                    percentage
                                    value={trackingScore}
                                    title="tracking score"
                                    color={calculatedColor(trackingScore)}
                                />
                                <MapDataItem
                                    percentage
                                    value={magneticAlignment}
                                    title="magnetic alignment"
                                />
                                {/* <MapDataItem value={0.78} title="Trafic vs Obstacles diff" /> */}
                                <MapDataItem value={daysBasedOn} title="days based on" />
                                <MapDataItem
                                    value={obstaclesAlignmentPercentage}
                                    title="Obstacles Alignment"
                                    percentage
                                />
                                <MapDataItem
                                    value={lanesAlignmentPercentage}
                                    title="Lanes Alignment"
                                    percentage
                                />
                                <MapDataItem value={sessionsNum} title="sessions" divider={false} />
                                <ButtonWrapper
                                    onClick={() => createPublishGrid({ mapId })}
                                    loading={publishLoading}
                                    disabled={publishLoading || publishSubmitted || !gridMetadata.canPublish}
                                    lastUpdatedDate={gridMetadata.updatedDate || ''}
                                />
                            </HeaderWrapper>
                            <HeaderWrapper style={{ alignItems: 'baseline' }}>
                                {togglesConfig.map((tConfig) => {
                                    const checked = toggles[tConfig.name];
                                    const disabled = checked && shouldDisableToggle;
                                    return (
                                        <ToggleWrapper
                                            key={tConfig.name}
                                            disabled={disabled}
                                            checked={checked}
                                            onChange={onToggleChanged}
                                            {...tConfig}
                                        />
                                    );
                                })}
                            </HeaderWrapper>
                        </Grid>
                        <ImageContainer>
                            {investData.map(({ signedUrls }, index) => {
                                const [fixtureImg = '', magneticImg = '', trackingImg = ''] = signedUrls;
                                return (
                                    <>
                                        {toggles.trackingScore && (
                                            <ImageComponent
                                                url={trackingImg}
                                                label="Tracking Score Map"
                                                fileName="trackingScoreMap"
                                                imageOnClick={openZoomModal}
                                                onDownload={getDownloadUrl}
                                                isCurrentImg={index === yesterday}
                                            />
                                        )}
                                        {toggles.magnetic && (
                                            <ImageComponent
                                                url={magneticImg}
                                                label="Magnetic Differentiation Map"
                                                fileName="magneticDiffMap"
                                                imageOnClick={openZoomModal}
                                                onDownload={getDownloadUrl}
                                                isCurrentImg={index === yesterday}
                                            />
                                        )}
                                        {toggles.fixture && (
                                            <ImageComponent
                                                url={fixtureImg}
                                                fileName="fixtures"
                                                label="Fixture Validation Map"
                                                imageOnClick={openZoomModal}
                                                onDownload={getDownloadUrl}
                                                isCurrentImg={index === yesterday}
                                            />
                                        )}
                                    </>
                                );
                            })}
                        </ImageContainer>
                        <ColorContainer>
                            <ColorItem color={COLORS.lightGreen} desc="Healing" />
                            <ColorItem color={COLORS.green} desc="Validation" />
                        </ColorContainer>
                        <SliderContainer
                            ref={sliderRef}
                            date={date}
                            max={totalDays}
                            day={yesterday}
                            onSliderChanged={onSliderChanged}
                            daysBasedOnHealing={Math.round(daysBasedOnHealing || 0)}
                            daysBasedOn={Math.round(daysBasedOn || 0)}
                        />
                    </CustomDialogContent>
                )}
            </CustomDialog>
        </>
    );
};
