import React, { forwardRef, useMemo, useState } from 'react';
import { MdMessage } from "react-icons/md";
import { CgAttachment } from "react-icons/cg";

import styled from 'styled-components';
import { IconButton, RootRef, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectCanEditLanes } from '../../../state-management/auth/authSelectors';
import Tooltip from '../../common/themed/Tooltip';
import * as PropTypes from 'prop-types';
import { Badge, Checkbox, Dialog } from '../../common/themed';
import { getLaneListEntryId, getLaneListEntryMapperId, getLaneListEntryNameId, getLaneListEntryShowInfoId } from '../Mapping.selectors';
import {
    getSelectIsLaneHighlighted,
    getSelectIsLaneSelected,
    highlightLane,
    selectMappingIsShowingEqualizedMap,
    selectMappingLaneIds,
    selectMappingSelectedLaneId,
    selectMappingSelectedMapId,
    setSelectedLaneId,
    setSelectedLaneIds,
    unhighlightLane,
} from '../../../state-management/user-inputs/mappingSlice';
import laneStatus from '../../../constants/laneStatuses';
import { FaImage as ImageIcon } from 'react-icons/fa';
import { getLaneColor, getLaneStatusName } from '../../../utils/laneUtils';
import { AiOutlineInfoCircle as InfoIcon } from 'react-icons/ai';
import LaneActions from '../lane/LaneActions';
import ItemsListEntry from '../../common/side-drawer/ItemsListEntry';
import { formatDateToISO } from '../../../utils/general';
import { FlexColumn, FlexRow } from '../../common/layout';
import { deleteLane } from '../../../state-management/mapping/lane/laneActions';
import useLanes from '../../common/hooks/data-fetching/useLanes';

const Title = styled(Typography).attrs(() => ({ variant: 'subtitle1', noWrap: true }))`
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 200px;
`;

const Subtitle = styled(Typography).attrs(() => ({ variant: 'subtitle2', noWrap: true }))`
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 200px;
    display: flex;
    align-items: center;
`;

const StatusColor = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background-color: ${(props) => props.$color};
    margin-inline-start: 5px;
    margin-inline-end: 5px;
`;

const IconButtonCustom = styled(IconButton)`
    font-size: 16px;
    padding: 0;

`

export const CustomDialog = styled(Dialog).attrs({
    maxWidth: false,
    fullScreen: true,
    closeIconStyle: {
        style: {
            filter: 'invert(100%)',
        },
    },
})`
    padding: 30px;
    z-index: 1000;
`;

export const Image = styled.img`
    object-fit: contain;
    width: 100%;
    cursor: zoom-in;
    max-height: 60vh;
    height: 100%;
    ${({ disabled }) =>
        disabled &&
        `
        cursor: not-allowed;
        pointer-events: none;
    `}
`;


const ZoomImage = styled(Image)`
    height: 90vh;
    margin: 0 auto;
    cursor: default;
    max-height: unset;
`;


const IconWrapper = forwardRef((props, ref) => (
    <RootRef rootRef={ref}>
        <IconButton {...props} />
    </RootRef>
));

export default function LaneListEntry(props) {
    const { lane, ...otherProps } = props;
    const { laneId, equalizedStatus, unequalizedStatus, comments, hasAttachments, mapperName } = lane ?? {};
    const hasOnlyAttachments = comments?.length === 0 && hasAttachments;
    const hasComments = !!comments.length
    const { disabled = false } = otherProps;
    const dispatch = useDispatch();
    const [showAttachment, setShowAttachment] = useState(false);

    const selectIsLaneHighlighted = useMemo(() => getSelectIsLaneHighlighted(laneId), [laneId]);
    // const selectIsLaneSelected = useMemo(() => getSelectIsLaneSelected(laneId), [laneId])
    const selectedLaneIds = useSelector(selectMappingLaneIds)
    const isSelected = selectedLaneIds.includes(laneId)
    const canEditLanes = useSelector(selectCanEditLanes);
    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const selectedLaneId = useSelector(selectMappingSelectedLaneId);
    const isHighlighted = useSelector(selectIsLaneHighlighted) || selectedLaneId === laneId;
    const isShowingEqualizedMap = useSelector(selectMappingIsShowingEqualizedMap);

    const { data: { attachments = [] } = {} } = useLanes({
        asObject: true,
        mapId: selectedMapId,
        laneId,
        withAttachments: true,
        useEqualizedMap: isShowingEqualizedMap,
    });

    const status = isShowingEqualizedMap ? equalizedStatus : unequalizedStatus;
    const attachment = attachments;

    const onLaneSelected = (laneId) => {
        if (isSelected) {
            const filteredLanes = selectedLaneIds.filter(id => id !== laneId);
            return dispatch(setSelectedLaneIds(filteredLanes));
        }
        const updatedLanes = [...selectedLaneIds, laneId];
        dispatch(setSelectedLaneIds(updatedLanes));
    }

    const onShowAttachment = () => {
        setShowAttachment(true);
    }

    const closeZoomModal = () => {
        setShowAttachment(false);
    }

    if (!lane) {
        return null;
    }

    return (
        <>
            <CustomDialog open={showAttachment} onClose={closeZoomModal}>
                <ZoomImage src={attachment} alt={'zoom image'} />
            </CustomDialog>
            <ItemsListEntry
                id={getLaneListEntryId(laneId)}
                onPointerEnter={() => dispatch(highlightLane(laneId))}
                onPointerLeave={() => dispatch(unhighlightLane(laneId))}
                listEntryContentStyle={{ style: { padding: '10px 0' } }}
                content={

                    <FlexRow alignItems='center'>
                        <FlexColumn alignItems='center'>
                            <Checkbox
                                disabled={disabled}
                                checked={isSelected}
                                onChange={() => onLaneSelected(laneId)}
                                style={{ marginTop: '-15px' }}
                            />
                            <StatusColor $color={getLaneColor(lane, isShowingEqualizedMap)} />

                        </FlexColumn>

                        <FlexColumn>

                            <Title id={getLaneListEntryNameId(laneId)}>{formatDateToISO(new Date(lane.createdAt), { withSeconds: true })}</Title>
                            <FlexRow>
                                <Title id={getLaneListEntryMapperId(laneId)}>{mapperName}</Title>
                                <span style={{ display: 'flex', columnGap: '2px', marginLeft: '10px' }}>
                                    <IconButtonCustom disabled={!hasComments}><MdMessage color={hasComments ? '#00d300' : 'gray'} /></IconButtonCustom>
                                    <IconButtonCustom onClick={onShowAttachment} disabled={!hasAttachments}><CgAttachment color={hasAttachments ? '#00d300' : 'gray'} /></IconButtonCustom>
                                </span>
                            </FlexRow>

                        </FlexColumn>

                    </FlexRow>

                }
                actions={
                    <LaneActions
                        disabled={disabled}
                        mapId={selectedMapId}
                        laneId={laneId}
                        showResolve={status === laneStatus.PENDING_APPROVAL}
                        additionalActions={
                            <Tooltip content={'Show Info'} placement={'bottom'} useWrapper={false}>
                                <IconWrapper
                                    id={getLaneListEntryShowInfoId(laneId)}
                                    aria-label={'Show Info'}
                                    onClick={() => dispatch(setSelectedLaneId(laneId))}
                                >
                                    <Badge badgeContent={hasOnlyAttachments ? <ImageIcon /> : comments?.length}>
                                        <InfoIcon />
                                    </Badge>
                                </IconWrapper>
                            </Tooltip>
                        }
                    />
                }
                isHighlighted={isHighlighted}
                enableActions={canEditLanes}
                {...otherProps}
            />

        </>
    );
}

LaneListEntry.propTypes = {
    lane: PropTypes.object,
};
