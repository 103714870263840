import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as LogoSvg } from '../../assets/oriientLogo.svg';

const arrowComingFromBelowAnimation = keyframes`
    from {
        transform: translate(-2000px, 2000px);
        opacity: 0;
    }
    to {
        transform: translate(200px, 0);
        opacity: 1;
    }
`;

const arrowMovingAsideAnimation = keyframes`
    from {
        transform: translate(200px, 0);
        opacity: 1;
    }
    to {
        transform: translate(0px, 0);
        opacity: 1;
    }
`;

const titleClippingAnimation = keyframes`
    from {       
        transform: translate(850px,-300px) rotate(24deg)
    } to { 
        transform: translate(285px,-300px) rotate(24deg)
    }
`;

const titleTranslateAnimation = keyframes`
    from {
        transform: translateX(-370px);    
        opacity: 0;
    }
    to {
        transform: translateX(0);    
        opacity: 1;
    }
`;

const Logo = styled(LogoSvg)`
    #arrow {
        transform: translate(-2000px, 2000px);
        opacity: 0;
        animation: ${arrowComingFromBelowAnimation} 1000ms ease 500ms forwards,
            ${arrowMovingAsideAnimation} 1000ms ease 1500ms forwards;
    }

    #title-clip-path-rect {
        transform: translate(850px, -300px) rotate(24deg);
        animation: ${titleClippingAnimation} 1000ms ease 1500ms forwards;
        height: 500px;
    }

    #title {
        transform: translateX(-370px);
        opacity: 0;
        animation: ${titleTranslateAnimation} 1000ms ease 1500ms forwards;
    }
`;

export default function AnimatedLogo() {
    return <Logo width={250} height={130} />;
}
