import React, { Component } from 'react';

class MapImage extends Component {
    firstProps = true;
    //noinspection JSDuplicatedDeclaration,JSCheckFunctionSignatures
    componentWillUnmount() {
        window.removeEventListener('mousemove', this.startResizeFn, false);
        window.removeEventListener('mouseup', this.stopResizeFn, false);
        window.removeEventListener('click', this.preventClickFn, true);
    }

    //noinspection JSDuplicatedDeclaration,JSCheckFunctionSignatures
    componentWillReceiveProps(nextProps) {
        const { props } = this;
        const { mapId: newMapId, mapImgBase64: newImgBase64 } = nextProps;
        const { mapId: oldMapId, mapImgBase64: oldImgBase64 } = props;
        const { setImageScale, stageSize } = props;
        const { isResizable } = nextProps;

        if (this.firstProps || (newMapId !== oldMapId || newImgBase64 !== oldImgBase64)) {
            this.firstProps = false;
            let image = document.createElement('img');
            image.addEventListener('load', () => {
                let w = image.width;
                let h = image.height;

                setImageScale(w, h, stageSize);
                this.setState({
                    image: newImgBase64,
                    imageSize: {
                        top: 0,
                        left: 0,
                        width: w,
                        height: h,
                        ratio: w / h,
                    },
                });
                if (isResizable) {
                    setTimeout(() => {
                        let topRight = document.querySelector('.rectangle-map-bullet.top-right'),
                            topLeft = document.querySelector('.rectangle-map-bullet.top-left'),
                            bottomRight = document.querySelector('.rectangle-map-bullet.bottom-right'),
                            bottomLeft = document.querySelector('.rectangle-map-bullet.bottom-left');

                        topRight.addEventListener('mousedown', this.initialiseResize('topRight'), true);
                        topLeft.addEventListener('mousedown', this.initialiseResize('topLeft'), true);
                        bottomRight.addEventListener('mousedown', this.initialiseResize('bottomRight'), true);
                        bottomLeft.addEventListener('mousedown', this.initialiseResize('bottomLeft'), true);
                    }, 500);
                }
            });
            image.addEventListener('error', () => {
                this.setState({
                    image: '',
                    imageSize: {
                        top: 0,
                        left: 0,
                        width: 0,
                        height: 0,
                        ratio: 0,
                    },
                });
            });

            if(newImgBase64) {
                image.src =
                  newImgBase64.startsWith("https://") || newImgBase64.startsWith("http://")
                    ? newImgBase64
                    : `data:image/png;base64,${newImgBase64}`;
            }
        }
    }

    initialiseResize = context => {
        const { props } = this;
        const { stageSize } = props;

        return e => {
            /* must prevent right mouse down. otherwise you'll need twice to click in the dialog */
            if (e.buttons !== 1) return;

            this.startResizeFn = this.rectResizing(
                context,
                e.clientX,
                e.clientY,
                stageSize.left,
                stageSize.top,
                stageSize.width,
                stageSize.height
            );

            //noinspection JSUnresolvedVariable
            this.preventClickFn = this.preventClick.bind(this, new Date().valueOf());
            this.stopResizeFn = this.stopResizing.bind(this);

            window.addEventListener('mousemove', this.startResizeFn, false);
            window.addEventListener('mouseup', this.stopResizeFn, false);
            window.addEventListener('click', this.preventClickFn, true);

            e.stopPropagation();
            e.preventDefault();
        };
    };
    rectResizing = (context, startX, startY, originalLeft, originalTop, originalWidth, originalHeight) => {
        const { props, state } = this;
        const { stageSize, mapId, setStageSize } = props;
        const { imageSize } = state;

        return e => {
            const clientRect = document.getElementById(mapId).getBoundingClientRect();
            const offsetTop =
                clientRect.top +
                window.pageYOffset -
                document.documentElement.clientTop -
                document.documentElement.scrollTop;

            if (context === 'topRight' || context === 'bottomRight') {
                stageSize.width += (e.clientX - startX) / stageSize.scale;
            }
            if (context === 'topLeft' || context === 'bottomLeft') {
                stageSize.width -= (e.clientX - startX) / stageSize.scale;
                stageSize.left += e.clientX - startX;
            }
            if (context === 'topRight' || context === 'topLeft') {
                stageSize.height -= (e.clientY - startY) / stageSize.scale;
                stageSize.top += e.clientY - startY;
            }
            if (context === 'bottomRight' || context === 'bottomLeft') {
                stageSize.height += +(e.clientY - startY) / stageSize.scale;
            }

            switch (context) {
                case 'topRight':
                    if (
                        Math.abs(stageSize.height - originalHeight) >
                        Math.abs(stageSize.width - originalWidth)
                    ) {
                        let width = stageSize.width;
                        stageSize.width = stageSize.height * imageSize.ratio;
                        stageSize.left += (width - stageSize.width) * stageSize.scale;
                    } else {
                        stageSize.height = stageSize.width / imageSize.ratio;
                    }
                    break;
                case 'bottomRight':
                    if (
                        Math.abs(stageSize.height - originalHeight) >
                        Math.abs(stageSize.width - originalWidth)
                    ) {
                        let width = stageSize.width;
                        stageSize.width = stageSize.height * imageSize.ratio;
                        stageSize.left += (width - stageSize.width) * stageSize.scale;
                    }
                    stageSize.height = stageSize.width / imageSize.ratio;
                    stageSize.top -= offsetTop + stageSize.height * stageSize.scale - e.clientY;
                    break;
                case 'bottomLeft':
                    if (
                        Math.abs(stageSize.height - originalHeight) >
                        Math.abs(stageSize.width - originalWidth)
                    ) {
                        stageSize.width = stageSize.height * imageSize.ratio;
                    } else {
                        stageSize.height = stageSize.width / imageSize.ratio;
                    }
                    stageSize.top -= offsetTop + stageSize.height * stageSize.scale - e.clientY;
                    break;
                case 'topLeft':
                    if (
                        Math.abs(stageSize.height - originalHeight) >
                        Math.abs(stageSize.width - originalWidth)
                    ) {
                        stageSize.width = stageSize.height * imageSize.ratio;
                    }
                    stageSize.height = stageSize.width / imageSize.ratio;
                    break;
                default:
            }

            if (stageSize.height < 250) {
                stageSize.height = originalHeight;
                stageSize.width = originalWidth;
                stageSize.left = originalLeft;
                stageSize.top = originalTop;
            }
            originalLeft = stageSize.left;
            originalTop = stageSize.top;
            originalWidth = stageSize.width;
            originalHeight = stageSize.height;
            startX = e.clientX;
            startY = e.clientY;
            setStageSize(stageSize);
            //noinspection JSUnresolvedVariable
            this.setState({ imageSize: imageSize });
        };
    };
    stopResizing = mouseEvent => {
        window.removeEventListener('mousemove', this.startResizeFn, false);
        window.removeEventListener('mouseup', this.stopResizeFn, false);

        mouseEvent.stopPropagation();
        mouseEvent.preventDefault();
    };
    preventClick = (startTime, event) => {
        window.removeEventListener('click', this.preventClickFn, true);
        if (new Date().valueOf() - startTime < 333) {
            return;
        }
        event.stopPropagation();
        event.preventDefault();
    };

    render() {
        if (!this.state) return null;
        const { state, props } = this;
        const { mapImgBase64, mapId, stageSize, isResizable, opacity, borderColor } = props;

        if (!mapImgBase64) return null;
        let bulletWidth = 16;

        return state ? (
            <g transform={`translate(${0},${0})`}>
                <image
                    id={mapId}
                    preserveAspectRatio={'none'}
                    href={this.props.isBase64 ? `data:image/png;base64,${mapImgBase64}` : mapImgBase64}
                    width={stageSize.width}
                    height={stageSize.height}
                    opacity={opacity}
                    transform={`scale(${props.stageSize.scale})`}
                />
                <rect
                    strokeWidth="4"
                    stroke={borderColor}
                    fill="none"
                    x="-2"
                    y="-2"
                    width={stageSize.width * stageSize.scale + 4}
                    height={stageSize.height * stageSize.scale + 4}
                />
                {isResizable ? (
                    <rect
                        className="rectangle-map-bullet top-right"
                        x={stageSize.width * stageSize.scale - bulletWidth / 2}
                        y={-(bulletWidth / 2)}
                        width={bulletWidth}
                        height={bulletWidth}
                    />
                ) : null}
                {isResizable ? (
                    <rect
                        className="rectangle-map-bullet top-left"
                        x={-(bulletWidth / 2)}
                        y={-(bulletWidth / 2)}
                        width={bulletWidth}
                        height={bulletWidth}
                    />
                ) : null}
                {isResizable ? (
                    <rect
                        className="rectangle-map-bullet bottom-right"
                        x={stageSize.width * stageSize.scale - bulletWidth / 2}
                        y={stageSize.height * stageSize.scale - bulletWidth / 2}
                        width={bulletWidth}
                        height={bulletWidth}
                    />
                ) : null}
                {isResizable ? (
                    <rect
                        className="rectangle-map-bullet bottom-left"
                        x={-(bulletWidth / 2)}
                        y={stageSize.height * stageSize.scale - bulletWidth / 2}
                        width={bulletWidth}
                        height={bulletWidth}
                    />
                ) : null}
            </g>
        ) : null;
    }
}

export default MapImage;
