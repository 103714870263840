import { createAsyncThunk } from '../../utils';
import areasOfInterestApi from '../../../api/AreasOfInterestApi';

export const fetchAllAreasOfInterestInMap = createAsyncThunk(
    'areasOfInterest/fetchAllInMap',
    async (mapId) => {
        return (await areasOfInterestApi.fetchAllAreasOfInterestInMap(mapId, 'normal')).data;
    }
);

export const fetchAreaOfInterestAttachments = createAsyncThunk(
    'areasOfInterest/fetchAttachments',
    async ({ mapId, areaId }) => {
        return (await areasOfInterestApi.fetchAreaOfInterestAttachments(mapId, areaId)).data;
    }
);

export const createAreaOfInterest = createAsyncThunk(
    'areasOfInterest/create',
    async ({ mapId, areaData }) => {
        return (await areasOfInterestApi.createAreaOfInterest(mapId, areaData)).data;
    }
);

export const updateAreaOfInterest = createAsyncThunk(
    'areasOfInterest/update',
    async ({ mapId, areaId, areaData }) => {
        return (await areasOfInterestApi.updateAreaOfInterest(mapId, areaId, areaData)).data;
    }
);

export const deleteAreaOfInterest = createAsyncThunk('areasOfInterest/delete', async ({ mapId, areaId }) => {
    await areasOfInterestApi.deleteAreaOfInterest(mapId, areaId, 'normal');
});
