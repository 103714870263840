import { createSlice } from '@reduxjs/toolkit';
import {
    acceptTermsAndConditions,
    login,
    expireSession,
    logout,
    renewAccessToken,
    attemptLoginSessionRehydration,
    extendSession,
} from './authActions';
import { createSpace, deleteSpace } from '../space/spaceActions';
import httpClient from '../../utils/httpClient';

const sessionLength = 1000 * 60 * 60 * 24; // 24 Hours;

const handleLogout = (state) => {
    delete state.apiKeyId;
    delete state.apiKeyDesc;
    delete state.permissions;
    delete state.spaceIds;
    delete state.hasAcceptedLatestTermsAndConditions;
    delete state.needsToAcceptLatestTermsAndConditions;
    delete state.isSuspended;
    delete state.username;
    delete state.accessToken;
    delete state.accessTokenExpiry;
    delete state.sessionExpirationTime;

    state.hasLoggedOut = true;
};

export const { reducer } = createSlice({
    name: 'auth',
    initialState: {
        lastLoggedUserApiKeyId: null,
    },
    reducers: {},
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.loginType = action.payload.loginType;
            state.apiKeyId = action.payload.apiKeyId;
            state.userId = action.payload.userId;
            state.apiKeyDesc = action.payload.apiKeyDesc;
            state.permissions = action.payload.permissions;
            state.spaceIds = action.payload.spaceIds;
            state.needsToAcceptLatestTermsAndConditions =
                action.payload.needsToAcceptLatestTermsAndConditions;
            state.isSuspended = action.payload.isSuspended;
            state.envSuffix = action.payload.envSuffix;
            state.userName =
                action.payload.loginType === 'user' ? action.payload.userName : action.payload.apiKeyDesc;
            state.lastLoggedUserApiKeyId = action.payload.apiKeyId;
            state.accessToken = action.payload.accessToken;
            state.accessTokenExpiry = action.payload.accessTokenExpiry;
            state.sessionExpirationTime = Date.now() + sessionLength;
            httpClient.addAccessToken(action.payload.accessToken, true);
        },

        [attemptLoginSessionRehydration.fulfilled]: (state, action) => {
            if (action.payload.isLoginSuccessful) {
                state.loginType = action.payload.loginType;
                state.apiKeyId = action.payload.apiKeyId;
                state.userId = action.payload.userId;
                state.apiKeyDesc = action.payload.apiKeyDesc;
                state.permissions = action.payload.permissions;
                state.needsToAcceptLatestTermsAndConditions =
                    action.payload.needsToAcceptLatestTermsAndConditions;
                state.isSuspended = action.payload.isSuspended;
                state.envSuffix = action.payload.envSuffix;
                state.userName =
                    action.payload.loginType === 'user' ? action.payload.userName : action.payload.apiKeyDesc;
                state.lastLoggedUserApiKeyId = action.payload.apiKeyId;
                state.accessToken = action.payload.accessToken;
                state.accessTokenExpiry = action.payload.accessTokenExpiry;
            } else {
                delete state.sessionExpirationTime;
            }
        },

        [extendSession]: (state) => {
            state.sessionExpirationTime = Date.now() + sessionLength;
        },

        [renewAccessToken.fulfilled]: (state, action) => {
            state.accessToken = action.payload.accessToken;
            state.accessTokenExpiry = action.payload.accessTokenExpiry;
            httpClient.addAccessToken(state.accessToken, true);
        },

        [acceptTermsAndConditions]: (state) => {
            state.hasAcceptedLatestTermsAndConditions = true;
            state.needsToAcceptLatestTermsAndConditions = false;
        },

        [createSpace.fulfilled]: (state, action) => {
            const { spaceId } = action.payload;
            state.spaceIds = [...new Set([...state.spaceIds, spaceId])];
        },

        [deleteSpace.fulfilled]: (state, action) => {
            state.spaceIds = state.spaceIds.filter((s) => s.spaceId !== action.meta.arg);
        },

        [expireSession.fulfilled]: handleLogout,
        [logout.fulfilled]: handleLogout,
    },
});
