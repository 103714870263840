import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectIsLoading as getSelectIsLoading,
    selectHasError as getSelectHasError,
} from '../../../../state-management/status/statusSelectors';
import {
    fetchAllMapFeatures,
    upsertMapFeature,
} from '../../../../state-management/map-features/mapFeaturesActions';
import { selectMapFeatures } from '../../../../state-management/map-features/mapFeaturesSelectors';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';

export default function useMapFeatures(spaceId, message = 'Map feature') {
    const dispatch = useDispatch();
    const [mapFeatures, setMapFeatures] = useState([]);
    const selectIsLoading = useMemo(
        () =>
            getSelectIsLoading({
                type: fetchAllMapFeatures.typePrefix,
            }),
        [spaceId]
    );
    const selectHasError = useMemo(
        () => getSelectHasError({ type: fetchAllMapFeatures.typePrefix, arg: spaceId }),
        [spaceId]
    );
    const selectMapFeatureIsLoading = useMemo(
        () =>
            getSelectIsLoading({
                type: upsertMapFeature.typePrefix,
            }),
        [spaceId]
    );
    const selectMapFeatureHasError = useMemo(
        () => getSelectHasError({ type: upsertMapFeature.typePrefix, arg: spaceId }),
        [spaceId]
    );
    const data = useSelector(selectMapFeatures);
    const isLoading = useSelector(selectIsLoading);
    const hasError = useSelector(selectHasError);
    const mapFeatureStatus = {
        isLoading: useSelector(selectMapFeatureIsLoading),
        hasError: useSelector(selectMapFeatureHasError),
    };
    const handleMapFeature = async (mapFeature, invalidatedData) => {
        mapFeature = Array.isArray(mapFeature) ? mapFeature : [mapFeature];
        const result = await dispatch(upsertMapFeature({ spaceId, mapFeature, invalidatedData }));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`${message} updated`));
        } else {
            dispatch(showErrorNotification(`${message} failed to update`));
        }
    };
    useEffect(() => {
        if (spaceId && !isLoading && !hasError) {
            dispatch(fetchAllMapFeatures(spaceId));
        }

        return () => {};
    }, [spaceId]);

    useEffect(() => {
        if (data.length) {
            setMapFeatures(data);
        }
    }, [data]);

    return {
        mapFeatures,
        setMapFeatures,
        handleMapFeature,
        isLoading,
        hasError,
        mapFeatureStatus,
    };
}
