import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectBuildingsIsSideDrawerOpen,
    setIsSideDrawerOpen,
} from '../../state-management/user-inputs/buildingsSlice';
import SideDrawer from '../common/side-drawer';
import BuildingsSideMenuBuildingSection from './side-menu/buildings';
import BuildingsSideMenuFloorSection from './side-menu/floors';
import BuildingsSideMenuMapSection from './side-menu/maps';
import { getSideMenuDrawerId } from './buildings.selectors';
import BuildingsSideMenuEntrancesSection from './side-menu/entrances';
import BuildingsSideMenuExternalRegionsSection from './side-menu/external-regions';
import { BuildingsSideMenuExitRegionSection } from './BuildingsSideMenuExitRegionSection';
import { BuildingsSideMenuCoordinatesConversion } from './side-menu/coordinates-conversion/BuildingsSideMenuCoordinatesConversion';

export default function BuildingsSideDrawer() {
    const dispatch = useDispatch();

    const isSideDrawerOpen = useSelector(selectBuildingsIsSideDrawerOpen);

    return (
        <SideDrawer
            id={getSideMenuDrawerId()}
            isOpen={isSideDrawerOpen}
            onToggle={() => dispatch(setIsSideDrawerOpen(!isSideDrawerOpen))}
        >
            <BuildingsSideMenuBuildingSection />
            <BuildingsSideMenuFloorSection />
            <BuildingsSideMenuMapSection />
            <BuildingsSideMenuCoordinatesConversion />
            <BuildingsSideMenuEntrancesSection />
            <BuildingsSideMenuExternalRegionsSection />
            <BuildingsSideMenuExitRegionSection />
        </SideDrawer>
    );
}
