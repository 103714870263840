import React, { memo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { Polygon, Map } from '@react-google-maps/api';

function RegionExternalPlacement(props) {
    const {
        region,
        placement,
        isSelected = false,
        isHighlighted = false,
        isEditable = false,
        onClick = (regionId, placementId) => { },
        onEdit = (points) => { },
        order = 0,
        disabled
    } = props;

    const polygonRef = useRef(null);

    const handleLoad = (polygon) => {
        polygonRef.current = polygon;
    };

    const handleEdit = () => {
        if (polygonRef.current && isEditable) {
            const path = polygonRef.current.getPath().getArray();
            onEdit(path.map((p) => ({ latitude: p.lat(), longitude: p.lng() })));
        }
    };

    return (
        <Polygon
            onLoad={handleLoad}
            path={placement?.points?.map((p) => ({ lat: p.latitude, lng: p.longitude })) ?? []}
            draggable={isEditable}
            editable={isEditable}
            onClick={() => onClick(region?.regionId, placement?.placementId)}
            onDragEnd={handleEdit}
            onMouseUp={handleEdit}
            options={{
                fillColor: disabled ? '#666666' : isSelected || isHighlighted ? '#7a007a' : '#ff6200',
                strokeColor: isSelected || isHighlighted ? '#530053' : '#ff4e1d',
                strokeWeight: 2,
                zIndex: order * 100
            }}
        />
    );
}

RegionExternalPlacement.propTypes = {
    region: PropTypes.object.isRequired,
    placement: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    onEdit: PropTypes.func,
};

export default memo(RegionExternalPlacement);
