import { createSlice } from '@reduxjs/toolkit';
import { deleteMapFeature, fetchAllMapFeatures, upsertMapFeature } from './mapFeaturesActions';
import entityAdapter from './mapFeaturesEntityAdapter';

const { getInitialState, setAll, upsertOne, removeOne, upsertMany } = entityAdapter;

export const { reducer } = createSlice({
    name: 'mapFeatures',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchAllMapFeatures.fulfilled]: (state, action) => {
            setAll(state, action.payload);
        },

        [upsertMapFeature.fulfilled]: (state, action) => {
            const { updatedMapFeature } = action.payload;
            upsertMany(state, updatedMapFeature);
        },

        [deleteMapFeature.fulfilled]: (state, action) => {
            removeOne(state, action.meta.arg.mapId);
        },
    },
});
