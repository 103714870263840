export const getSideMenuDrawerId = () => `buildings-side-menu-drawer`;
export const getBuildingSelectId = () => `buildings-side-menu-building-select`;
export const getFloorSelectId = () => `buildings-side-menu-floor-select`;
export const getMapSelectId = () => `buildings-side-menu-map-select`;

export const getBuildingIdId = () => `buildings-side-menu-building-id`;
export const getBuildingNameId = () => `buildings-side-menu-building-name`;
export const getBuildingDisplayNameId = () => `buildings-side-menu-building-display-name`;
export const getBuildingClientId = () => `buildings-side-menu-building-client-id`;
export const getBuildingIdAutoFloorDetection = () => `buildings-side-menu-building-auto-floor-detection`;

export const getFloorIdId = () => `buildings-side-menu-floor-id`;
export const getFloorNameId = () => `buildings-side-menu-floor-name`;
export const getFloorShortNameId = () => `buildings-side-menu-floor-short-name`;
export const getFloorIndexId = () => `buildings-side-menu-floor-index`;
export const getFloorHeightId = () => `buildings-side-menu-floor-height`;
export const getFloorRotationId = () => `buildings-side-menu-floor-rotation`;
export const getMarkFloorAsPrimaryId = () => `buildings-side-menu-floor-mark-as-primary`;

export const getMapIdId = () => `buildings-side-menu-map-id`;
export const getMapNameId = () => `buildings-side-menu-map-name`;
export const getImageUsageListId = () => `buildings-side-menu-image-usage-list`;
export const getMapUsageId = () => `buildings-side-menu-map-usage`;
export const getMapPixelToMeterId = () => `buildings-side-menu-map-p2m`;
export const getMapOffsetId = () => `buildings-side-menu-map-offset`;
export const getChangePixelToMeterId = () => `buildings-side-menu-map-change-p2m`;

export const getExitRegionListEntryId = (id) => `buildings-side-menu-exit-region-list-entry-${id}`;

export const getEntranceListEntryId = (entranceId) => `buildings-side-menu-entrance-list-entry-${entranceId}`;
export const getEntranceListEntryNameId = (entranceId) =>
    `mapping-side-menu-entrance-list-entry-name-${entranceId}`;
export const getEntranceListEntryDeleteId = (entranceId) =>
    `mapping-side-menu-entrance-list-entry-delete-${entranceId}`;
export const getEntranceListEntryShowInfoId = (entranceId) =>
    `mapping-side-menu-entrance-list-entry-show-info-${entranceId}`;
export const getEntranceListEntryEditPositionId = (entranceId) =>
    `mapping-side-menu-entrance-list-entry-edit-position-${entranceId}`;
export const getEntranceListEntryDragImageId = (entranceId) =>
    `mapping-side-menu-entrance-list-entry-drag-image-${entranceId}`;

export const getDetachEntranceFromExternalRegionId = (entranceId) =>
    `mapping-side-menu-detach-entrance-${entranceId}`;

export const getEntranceDescriptionId = () => `mapping-side-menu-entrance-description`;
export const getEntranceIsMainId = () => `mapping-side-menu-entrance-is-main`;

export const getExternalPlacementListEntryId = (placementId) =>
    `buildings-side-menu-external-placement-list-entry-${placementId}`;
export const getExternalPlacementListEntryNameId = (placementId) =>
    `buildings-side-menu-external-placement-list-entry-name-${placementId}`;

export const getExternalPlacementDeleteId = (placementId) =>
    `mapping-side-menu-external-placement-actions-delete-${placementId}`;
export const getExternalPlacementEditShapeId = (placementId) =>
    `mapping-side-menu-external-placement-actions-edit-shape-${placementId}`;
export const getExternalPlacementCancelId = (placementId) =>
    `mapping-side-menu-external-placement-actions-cancel-${placementId}`;
export const getExternalPlacementSaveId = (placementId) =>
    `mapping-side-menu-external-placement-actions-save-${placementId}`;
export const getExternalPlacementShowInfoId = (placementId) =>
    `mapping-side-menu-external-placement-actions-show-info-${placementId}`;

export const getExternalPlacementRegionNameId = () => `mapping-side-menu-external-placement-region-name`;
export const getExternalPlacementGeoPositioningMargin = () =>
    `mapping-side-menu-external-placement-geo-positioning-margin`;
export const getExternalPlacementStopPositioningMargin = () =>
    `mapping-side-menu-external-placement-stop-positioning-margin`;
export const getExternalPlacementEnableStartPositioningId = () =>
    `mapping-side-menu-external-placement-start-positioning`;
export const getExternalPlacementEnableAutoStartId = () => `mapping-side-menu-external-placement-auto-start`;

export const getMapComponenetImageRotationId = () => 'buildings-side-menu-map-mapComponentImageRotation';
