import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField, Tooltip, Checkbox, Select as ThemedSelect } from '../../../../common/themed';
import metadataFieldTypes from './MetadataEditorFieldTypes';
import { FormControlLabel, IconButton, InputAdornment } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { SketchPicker as ColorPicker } from 'react-color';
import { MdColorize as ColorIcon } from 'react-icons/md';

const ColorPreview = styled.div`
    background-color: ${(props) => props.color};
    width: 30px;
    height: 20px;
    border-radius: 15px;
    border: 1px solid #cccccc;
    margin-inline-end: 5px;
`;

const Select = styled(ThemedSelect)`
    margin-block-start: 10px;
    margin-block-end: 10px;
`;

const StyledNumberFormat = styled(TextField)`
    padding-top: 10px;
`;

const decimalToHex = (alpha) => (alpha === 0 ? '00' : Math.round(255 * alpha).toString(16));

const argbToRgba = (argbHexColor) =>
    argbHexColor.length === 7
        ? `#${argbHexColor.slice(1, 7)}ff`
        : `#${argbHexColor.slice(3, 9)}${argbHexColor.slice(1, 3)}`;

const presetColors = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
];

export default function MetadataEditorField(props) {
    const { type, name, label, onChange, value, defaultValue, ...otherProps } = props;

    const [displayedColor, setDisplayedColor] = useState(
        type === metadataFieldTypes.COLOR && value ? argbToRgba(value) : null
    );

    const handleChange = (value) => {
        onChange({ [name]: value });
    };

    const renderField = () => {
        switch (type) {
            case metadataFieldTypes.STRING:
                return renderStringField();
            case metadataFieldTypes.BOOLEAN:
                return renderBooleanField();
            case metadataFieldTypes.NUMBER:
                return renderNumberField();
            case metadataFieldTypes.COLOR:
                return renderColorField();
            case metadataFieldTypes.SELECT:
                return renderSelectField();
            default:
                return null;
        }
    };

    const renderStringField = () => (
        <TextField
            name={name}
            label={label}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            {...otherProps}
        />
    );

    const renderBooleanField = () => (
        <FormControlLabel
            name={name}
            control={
                <Checkbox onChange={(e) => handleChange(e.target.checked)} checked={value} {...otherProps} />
            }
            label={label}
        />
    );

    const renderNumberField = () => {
        const { options, display = true } = otherProps;
        if (!display) return null; // Visibility in Oriient format only
        if (options?.hasConstraints) {
            return (
                <StyledNumberFormat
                    name={name}
                    label={label}
                    value={value}
                    onChange={(e) => handleChange(+e.target.value)}
                    type="number"
                    InputProps={{ inputProps: { ...options.range } }}
                    {...otherProps}
                />
            );
        }
        return (
            <NumberFormat
                customInput={TextField}
                label={label}
                {...otherProps}
                value={value}
                onValueChange={({ floatValue }) => handleChange(floatValue)}
                allowNegative={false}
            />
        );
    };

    const renderColorField = () => (
        <TextField
            label={label}
            value={value}
            disabled
            InputProps={{
                startAdornment: <ColorPreview color={argbToRgba(value)} />,
                endAdornment: (
                    <InputAdornment position="end">
                        <Tooltip
                            interactive
                            trigger={'click'}
                            content={
                                <ColorPicker
                                    color={displayedColor}
                                    onChange={(color) =>
                                        setDisplayedColor(`${color.hex}${decimalToHex(color.rgb.a)}`)
                                    }
                                    onChangeComplete={(color) =>
                                        handleChange(`#${decimalToHex(color.rgb.a)}${color.hex.slice(1, 7)}`)
                                    }
                                    styles={{ default: { picker: { padding: 5, boxShadow: 'none' } } }}
                                    presetColors={presetColors}
                                />
                            }
                        >
                            <IconButton aria-label="Toggle color picker">
                                <ColorIcon />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                ),
            }}
            {...otherProps}
        />
    );

    const renderSelectField = () =>
        otherProps.isMulti ? (
            <Select
                value={otherProps.options.filter((opt) => value.includes(opt.value))}
                onChange={(newValue) => handleChange((newValue ?? []).map((v) => v.value))}
                placeholder={label}
                {...otherProps}
            />
        ) : (
            <Select
                value={otherProps.options.find((opt) => opt.value === value)}
                onChange={(newValue) => handleChange(newValue.value)}
                placeholder={label}
                {...otherProps}
            />
        );

    return renderField();
}

MetadataEditorField.propTypes = {
    type: PropTypes.oneOf([
        metadataFieldTypes.BOOLEAN,
        metadataFieldTypes.COLOR,
        metadataFieldTypes.NUMBER,
        metadataFieldTypes.STRING,
        metadataFieldTypes.SELECT,
    ]),
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.any,
};
