import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import LineOfInterest from './LineOfInterest';
import { getMapViewerLinesOfInterestOverlayId } from './MapViewerLinesOfInterestOverlay.selectors';

const OverlayWrapper = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    pointer-events: none; // Prevent the whole SVG from capturing pointer events

    * {
        pointer-events: auto;
    }
`;

export default function MapViewerLinesOfInterestOverlay(props) {
    const { lines, onClick, selectedLineIds, disabled } = props;

    return (
        <OverlayWrapper id={getMapViewerLinesOfInterestOverlayId()}
        >
            <marker
                id={'loi-marker-dot'}
                viewBox="0 0 10 10"
                refX="5"
                refY="5"
                markerWidth="4"
                markerHeight="4"
            >
                <circle cx="5" cy="5" r="5" fill={'#00CEF1'} />
            </marker>

            <marker
                id={'loi-marker-arrow'}
                viewBox="0 0 10 10"
                refX="5"
                refY="5"
                markerWidth="6"
                markerHeight="6"
                orient="auto-start-reverse"
            >
                <path d="M 0 0 L 10 5 L 0 10 z" fill={'#00CEF1'} />
            </marker>
            <marker id="selected-marker-dot" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="4" markerHeight="4">
                <circle cx="5" cy="5" r="5" fill="#7a007a" />
            </marker>

            <marker id="selected-marker-arrow" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="6" markerHeight="6" orient="auto-start-reverse">
                <path d="M 0 0 L 10 5 L 0 10 z" fill="#7a007a" />
            </marker>

            <marker id="highlighted-marker-dot" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="4" markerHeight="4">
                <circle cx="5" cy="5" r="5" fill="#00b1f1" />
            </marker>

            <marker id="highlighted-marker-arrow" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="6" markerHeight="6" orient="auto-start-reverse">
                <path d="M 0 0 L 10 5 L 0 10 z" fill="#00b1f1" />
            </marker>

            <marker id="disabled-marker-arrow" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="6" markerHeight="6" orient="auto-start-reverse">
                <path d="M 0 0 L 10 5 L 0 10 z" fill="#7b7b7b" />
            </marker>

            {lines.map((line) => (
                <LineOfInterest
                    key={line?.lineId}
                    line={line}
                    onClick={onClick}
                    isSelected={selectedLineIds.includes(line?.lineId)}
                    disabled={disabled}
                />
            ))}
        </OverlayWrapper>
    );
}

MapViewerLinesOfInterestOverlay.propTypes = {
    lines: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
};
