import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './mapperEntityAdapter';
import { expireSession, logout } from '../../auth/authActions';
import { fetchAllMappersInMap, toggleSuperMapper, toggleSuspendMapper, updateMapper } from './mapperActions';
import {
    mapperAlive,
    mapperExpired,
    mapperHelpRequestAcknowledged,
    mapperHelpRequested,
    mapperPositionUpdated,
    superMapperToggled,
} from '../mappingEventsActions';

const availableColors = [
    'midnightblue',
    'darkgreen',
    'orangered',
    'orchid',
    'maroon',
    'steelblue',
    'purple',
    'navy',
    'lime',
    'lightsalmon',
    'hotpink',
    'gold',
    'cyan',
    'magenta',
    'gray',
    'black',
    'brown',
    'yellow',
    'red',
    'green',
    'blue',
];

const getMapperColor = () => {
    if (!availableColors.length) {
        // If we're out of colors, start returning random colors
        return Math.floor(Math.random() * 16777215).toString(16);
    }
    return availableColors.pop();
};

const { getInitialState, getSelectors, upsertOne, removeOne, setAll } = entityAdapter;

export const { reducer } = createSlice({
    name: 'mappers',
    initialState: {
        ...getInitialState(),
        helpRequest: { isActive: false, mapId: null, mapperId: null, mapperName: null },
    },
    reducers: {},
    extraReducers: {
        [fetchAllMappersInMap.fulfilled]: (state, action) => {
            setAll(
                state,
                action.payload.map(({ mapperId, mapperName, isSuperMapper, lastSeenAliveAt }) => ({
                    mapperId,
                    mapperName,
                    isSuperMapper,
                    lastSeenAliveAt,
                    isMapperSuspended: false,
                }))
            );
        },

        [updateMapper.fulfilled]: (state, action) => {
            const { mapperId, mapperName, isSuperMapper, lastSeenAliveAt } = action.payload;
            upsertOne(state, {
                mapperId,
                mapperName,
                isSuperMapper,
                lastSeenAliveAt,
            });
        },

        [toggleSuperMapper.fulfilled]: (state, action) => {
            const { mapperId } = action.meta.arg;
            const { isSuperMapper } = action.payload;
            upsertOne(state, { mapperId, isSuperMapper });
        },

        [toggleSuspendMapper.fulfilled]: (state, action) => {
            const { mapperId } = action.meta.arg;
            const { isMapperSuspended } = action.payload;
            upsertOne(state, { mapperId, isMapperSuspended });
        },

        [mapperAlive]: (state, action) => {
            const { mapperId, mapperName } = action.payload.data;
            upsertOne(state, { mapperId, mapperName, lastSeenAliveAt: new Date().toISOString() });
        },

        [superMapperToggled]: (state, action) => {
            const { mapperId } = action.payload.parameters;
            const { isSuperMapper } = action.payload.data;
            upsertOne(state, { mapperId, isSuperMapper });
        },

        [mapperExpired]: (state, action) => {
            const { mapperId } = action.payload.parameters;
            removeOne(state, mapperId);
        },

        [mapperPositionUpdated]: (state, action) => {
            const { mapId, mapperId } = action.payload.parameters;
            const { x, y, heading, source } = action.payload.data;
            const { mapperName, color } = getSelectors().selectById(state, mapperId);
            upsertOne(state, {
                mapperId,
                mapperName: mapperName ?? 'Unknown',
                position: { mapId, x, y, heading, source },
                color: color ?? getMapperColor(),
            });
        },

        [mapperHelpRequested]: (state, action) => {
            const { mapId, mapperId, mapperName } = action.payload.parameters;
            state.helpRequest = { isActive: true, mapId, mapperId, mapperName };
        },

        [mapperHelpRequestAcknowledged]: (state) => {
            state.helpRequest = { isActive: false, mapId: null, mapperId: null, mapperName: null };
        },

        [expireSession.fulfilled]: () => ({
            ...getInitialState(),
            helpRequest: { isActive: false, mapId: null, mapperId: null, mapperName: null },
        }),
        [logout.fulfilled]: () => ({
            ...getInitialState(),
            helpRequest: { isActive: false, mapId: null, mapperId: null, mapperName: null },
        }),
    },
});
