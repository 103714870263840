import moment from 'moment';

export default class TimeResolution {
    constructor() {
        this._timeZoneOffset = 0;
        this._startHourUtc = null;
        this._endHourUtc = null;
    }

    set timeZoneOffset(value) {
        this._timeZoneOffset = value;
    }

    get timeZoneOffset() {
        return this._timeZoneOffset;
    }

    get startHourUtc() {
        return this._startHourUtc;
    }

    set startHourUtc(value) {
        this._startHourUtc = value;
    }

    get endHourUtc() {
        return this._endHourUtc;
    }

    set endHourUtc(value) {
        this._endHourUtc = value;
    }

    get inMinutes() {
        return 0;
    }

    get inMilliseconds() {
        return this.inMinutes * 1000 * 60;
    }

    get label() {
        return '';
    }

    getTimeIntervalString(start, end) {
        return ``;
    }

    get unitType() {
        return '';
    }

    get unitCount() {
        return 0;
    }

    getFittingTimeInterval(utcTimestamp) {
        return {
            start: null,
            end: null,
        };
    }

    getTimeIntervalsInRange(start, end) {
        const result = [];
        const { unitType, unitCount } = this;

        const { start: rangeStart } = this.getFittingTimeInterval(start);
        const { end: rangeEnd } = this.getFittingTimeInterval(end);

        const range = moment.range(rangeStart, rangeEnd);

        for (const intervalStart of range.by(unitType, { step: unitCount })) {
            const intervalEnd = moment(this.getFittingTimeInterval(intervalStart).end);

            if (intervalStart.isSameOrAfter(start) && intervalEnd.isSameOrBefore(end)) {
                result.push({ start: intervalStart.toISOString(), end: intervalEnd.toISOString() });
            }
        }

        return result;
    }
}
