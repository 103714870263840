import { mapQualityKeysOperations } from '../../map-quality/utils';

export const defaultToggleStates = {
    trackingScore: true,
    magnetic: true,
    fixture: true,
};

export const togglesConfig = [
    {
        name: 'trackingScore',
        text: 'Tracking Score Map',
    },
    {
        name: 'magnetic',
        text: 'Magnetic Diff Map',
    },
    {
        name: 'fixture',
        text: 'Fixture Validation Map',
    },
];

export const calculatedColor = (trackingScore) => {
    const score = [undefined, null].includes(trackingScore) ? NaN : Number(trackingScore) * 100;
    if (isNaN(score)) return 'inherit';
    return score < 85 ? 'red' : 'inherit';
};

export const normalizedFetchedData = (data) => {
    const normalizedData = data.map(({ mapData, date, ...restData }) => {
        mapData.daysBasedOn = mapQualityKeysOperations.daysBasedOn(mapData.daysBasedOn);
        return {
            ...restData,
            mapData,
            date,
        };
    });
    return normalizedData;
};
