import Api from './Api';
import http from '../utils/httpClient';

class SignMarkApi extends Api {
    fetchAllSignMarkInMap({ buildingId, floorId, mapId }) {
        return this.callApi((params) => {
            return http.v1.get(`/buildings/${buildingId}/floors/${floorId}/maps/${mapId}/sign-marks`, {
                params,
            });
        });
    }

    createSignMark({ buildingId, floorId, mapId }, createdSignMark) {
        return this.callApi((params) => {
            return http.v1.post(
                `/buildings/${buildingId}/floors/${floorId}/maps/${mapId}/sign-marks`,
                createdSignMark,
                { params }
            );
        });
    }

    updateSignMark({ buildingId, floorId, mapId, signMarkId }, updatedSignMark) {
        return this.callApi((params) =>
            http.v1.patch(
                `/buildings/${buildingId}/floors/${floorId}/maps/${mapId}/sign-marks/${signMarkId}`,
                updatedSignMark,
                { params }
            )
        );
    }

    deleteSignMark({ buildingId, floorId, mapId, signMarkId }) {
        return this.callApi((params) =>
            http.v1.delete(
                `/buildings/${buildingId}/floors/${floorId}/maps/${mapId}/sign-marks/${signMarkId}`,
                {
                    params,
                }
            )
        );
    }
}
const signMarkApi = new SignMarkApi();
export default signMarkApi;
