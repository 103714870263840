import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography, Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import moment from 'moment';

const DayHeader = styled(ListItem).attrs({
    button: true,
})`
    border: 1px solid #ccc; // TODO color should come from theme
    border-radius: 4px;
`;

const VisitsList = styled(List)`
    display: flex;
    flex-flow: row wrap;
`;

const VisitEntry = styled(ListItem)`
    max-width: 260px;
    border-left: 1px solid #ccc; // TODO color should come from theme
`;

const AlertMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    height: 100%;
`;

export default class RegionVisitationDetails extends React.Component {
    state = { isDayOpen: {} };
    visitsByDay = {};

    componentDidMount() {
        this.initializeVisits();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { visits } = this.props;

        if (JSON.stringify(prevProps.visits) !== JSON.stringify(visits)) {
            this.initializeVisits();
        }
    }

    initializeVisits() {
        const { visits } = this.props;

        this.visitsByDay = visits.reduce((result, visit) => {
            const date = moment(visit.startTime).format('dddd, MMMM Do YYYY');
            return {
                ...result,
                [date]: [
                    ...(result[date] || []),
                    {
                        ...visit,
                        startTime: moment(visit.startTime).format('HH:mm:ss'),
                        endTime: moment(visit.endTime).format('HH:mm:ss'),
                    },
                ],
            };
        }, {});

        let isDayOpen = {};
        const hasOnlyOneDay = Object.keys(this.visitsByDay).length === 1;

        // If the region has only one day of visits, have it open by default
        // Otherwise, all days are closed by default
        Object.keys(this.visitsByDay).forEach((day) => {
            isDayOpen[day] = hasOnlyOneDay;
        });

        this.setState({ isDayOpen });
    }

    onClickDay = (day) => {
        const { isDayOpen } = this.state;
        this.setState({ isDayOpen: { ...isDayOpen, [day]: !isDayOpen[day] } });
    };

    renderDay(day) {
        const { isDayOpen } = this.state;

        return (
            <React.Fragment key={day}>
                <DayHeader onClick={() => this.onClickDay(day)}>
                    <ListItemText primary={day} />
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </DayHeader>
                <Collapse in={isDayOpen[day]} timeout="auto" unmountOnExit>
                    <VisitsList component="div" dense disablePadding>
                        {this.visitsByDay[day].map((visit, index) => {
                            const text = `${visit.startTime} - ${visit.endTime}   |   ${visit.durationDesc}`;

                            return (
                                <VisitEntry key={index}>
                                    <ListItemText primary={text} />
                                </VisitEntry>
                            );
                        })}
                    </VisitsList>
                </Collapse>
            </React.Fragment>
        );
    }

    render() {
        const { regionName } = this.props;

        if (!regionName) {
            return (
                <AlertMessage>
                    Select a region from the timeline or pie chart to view its visitation.
                </AlertMessage>
            );
        }

        return (
            <>
                <Typography variant="title">{regionName}</Typography>
                <List>{Object.keys(this.visitsByDay).map((day) => this.renderDay(day))}</List>
            </>
        );
    }
}

RegionVisitationDetails.propTypes = {
    regionName: PropTypes.string,
    visits: PropTypes.array,
};

RegionVisitationDetails.defaultProps = {
    regionName: null,
    visits: [],
};
