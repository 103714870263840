import Api from './Api';
import http from '../utils/httpClient';

class ShiftManagementApi extends Api {
    async fetchShiftManagementData(threshold) {
        return await this.callApi((params) =>
            http.mapping.v1.get(`/shiftManagement/analytics`,{ params: { ...params, threshold } })
        );
    }
}
export default new ShiftManagementApi();