import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { BuildingsTableWrapper } from './components';
import { RootRef } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { columns } from './columns';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';
import useMapFeatures from '../../common/hooks/data-fetching/useMapFeatures';
import { Switch } from '../../common/themed';
import { features } from '../constants';
import { SpaceForm } from './SpaceForm';

export default function MapFeaturesTable(props) {
    const targetRef = useRef();
    const { selectedSpace = {} } = props;
    const { mapFeatures, isLoading, hasError, handleMapFeature, mapFeatureStatus, setMapFeatures } =
        useMapFeatures(selectedSpace.spaceId);
    if (hasError) {
        return <ErrorGeneral />;
    }
    const onMapFeatureChange = async (mapFeature, key) => {
        if (key === features.SELF_HEALING && !mapFeature[features.SELF_HEALING]) {
            mapFeature[features.SELF_HEALING] = false;
        } else if (key === features.SELF_VALIDATION && !mapFeature[features.SELF_VALIDATION]) {
            mapFeature[features.SELF_HEALING] = false;
            mapFeature[features.AUTO_PUBLISH] = false;
        }
        await handleMapFeature(
            {
                selfValidationEnabled: mapFeature?.selfValidationEnabled ?? false,
                selfHealingEnabled: mapFeature?.selfHealingEnabled ?? false,
                autoPublishEnabled: mapFeature?.autoPublishEnabled ?? false,
                mapId: mapFeature?.mapId,
            },
            {
                buildingId: mapFeature?.buildingId,
                floorId: mapFeature?.floorId,
            }
        );
    };
    const renderSwitch = (mapFeature, key, disabledConstraints) => {
        if (mapFeatureStatus.hasError) return <ErrorGeneral />;
        return (
            <Switch
                checked={mapFeature[key] ?? false}
                onChange={(e) => onMapFeatureChange({ ...mapFeature, [key]: e.target.checked }, key)}
                disabled={disabledConstraints && disabledConstraints.some((c) => !mapFeature[c])}
            />
        );
    };

    const onSortHandler = (index, direction) => {
        const desiredColumn = columns[index];
        if (!desiredColumn) return;
        const { field } = desiredColumn;
        mapFeatures.sort((row1, row2) => {
            if (direction === 'asc') {
                return row1[field] - row2[field];
            }
            return row2[field] - row1[field];
        });

        setMapFeatures([...mapFeatures]);
    };

    const renderMapFeatures = (mapFeatures = []) => {
        return mapFeatures.map((m) => {
            return {
                ...m,
                selfValidationEnabled: renderSwitch(m, features.SELF_VALIDATION),
                selfHealingEnabled: renderSwitch(m, features.SELF_HEALING, [features.SELF_VALIDATION]),
                autoPublishEnabled: renderSwitch(m, features.AUTO_PUBLISH, [
                    features.SELF_VALIDATION,
                    features.SELF_HEALING,
                ]),
            };
        });
    };
    const onSelectionChange = async (data, rows) => {
        await handleMapFeature(
            rows.map((row) => {
                return {
                    autoPublishEnabled: data.autoPublishEnabled,
                    selfHealingEnabled: data.selfHealingEnabled,
                    selfValidationEnabled: data.selfValidationEnabled,
                    mapId: row.mapId,
                };
            })
        );
    };

    return (
        <RootRef rootRef={targetRef}>
            <BuildingsTableWrapper>
                <MaterialTable
                    isLoading={isLoading || mapFeatureStatus.isLoading}
                    title={'Buildings'}
                    options={{
                        search: true,
                        searchFieldAlignment: 'left',
                        paging: false,
                        headerStyle: {
                            borderBottom: '2px solid #ff6200',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                        },
                        maxBodyHeight: '50vh',
                        minBodyHeight: '50vh',
                        selection: true,
                        toolbarButtonAlignment: 'left',
                    }}
                    columns={columns}
                    data={renderMapFeatures(mapFeatures)}
                    onOrderChange={onSortHandler}
                    components={{
                        Actions: (props) => {
                            if (!props.data) return <></>;
                            return (
                                <SpaceForm onSubmit={(formData) => onSelectionChange(formData, props.data)} />
                            );
                        },
                    }}
                />
            </BuildingsTableWrapper>
        </RootRef>
    );
}
