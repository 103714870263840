import React, { useState } from 'react';
import { IoEye, IoEyeOff } from "react-icons/io5";
import { MdDone } from "react-icons/md";
import { BsXLg } from "react-icons/bs";
import {
    Button,
    CircularPreloader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    PulsePreloader,
    TextField,
} from '../../../common/themed';
import GoogleMapsBuildingPlacement from '../common/google-maps-building-placement';
import { Divider, InputAdornment, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import useAllBuildingsInSpace from '../../../common/hooks/data-fetching/useAllBuildingsInSpace';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import ErrorGeneral from '../../../common/error-pages/ErrorGeneral';
import styled, { css } from 'styled-components';
import MuiSlider from '@material-ui/core/Slider';
import { Circle, OverlayView } from '@react-google-maps/api';
import {
    getBuildingOriginGMapsMarkerId,
    getBuildingOriginLatitudeId,
    getBuildingOriginLongitudeId,
    getBuildingRotationId,
    getChangeBuildingOriginButtonId,
    getChangeGeofenceOriginButtonId,
    getGeofenceOriginGMapsMarkerId,
    getGeofenceOriginLatitudeId,
    getGeofenceOriginLongitudeId,
    getGeofenceRadiusFieldId,
    getGeofenceRadiusGMapsCircleId,
    getGoogleMapsId,
} from './ChangeBuildingPlacement.selectors';
import FloorAndMapSelect from '../common/FloorAndMapSelect';
import { IoLocationSharp } from 'react-icons/io5';
import { fetchBuildingFullData, updateBuilding } from '../../../../state-management/building/buildingActions';
import { isFulfilled } from '../../../../state-management/utils';
import { showErrorNotification, showSuccessNotification } from '../../../../state-management/notification/notificationReducer';

const BuildingDetailsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        'opacity building-origin geofence-origin'
        'rotation building-origin geofence-origin';
    column-gap: 40px;
    margin-block-start: 20px;
`;

const markerCommonStyles = css`
    transform: translateY(-100%) translateX(-50%);
    height: 2rem;
    width: 2rem;
`;

const BuildingOriginMarker = styled(IoLocationSharp)`
    fill: dodgerblue;
    ${markerCommonStyles};
`;

const GeofenceOriginMarker = styled(IoLocationSharp)`
    fill: darkred;
    ${markerCommonStyles};
`;

const OpacityWrapper = styled.div`
    grid-area: opacity;
`;

const RotationWrapper = styled.div`
    /* grid-area: rotation; */
`;

const BuildingOriginWrapper = styled.div`
    grid-area: building-origin;
`;

const GeofenceOriginWrapper = styled.div`
    grid-area: geofence-origin;
`;

const ChangeButton = styled(Button)`
    margin-inline-start: 20px;
`;

const Slider = styled(MuiSlider)`
    margin-block-start: 10px;
    padding: 0px !important;
`;

const FlexRow = styled.div`
    display: flex;
    align-items: center;
`

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`


export default function ChangeBuildingPlacementDialog(props) {
    const { onAccept, onClose, buildingId } = props;
    const dispatch = useDispatch();
    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);

    const {
        data: { [buildingId]: building },
        isLoading: isLoadingBuildings,
        hasError,
    } = useAllBuildingsInSpace({
        spaceId: selectedSpaceId,
        asObject: true,
    });

    const {
        buildingToEnuRotation: originalRotation,
        buildingOrigin: originalBuildingOrigin,
        geofenceOrigin: originalGeofenceOrigin,
        geofenceRadius: originalGeofenceRadius,
        primaryFloorId,
    } = building ?? {};

    const [loading, setLoading] = useState(false);
    const [opacity, setOpacity] = useState(0.5);
    const [rotation, setRotation] = useState(originalRotation || 0);
    const [showRadius, setShowRadius] = useState(true);
    const [isSettingBuildingOrigin, setIsSettingBuildingOrigin] = useState(false);
    const [isSettingGeofenceOrigin, setIsSettingGeofenceOrigin] = useState(false);
    const [buildingOrigin, setBuildingOrigin] = useState(originalBuildingOrigin);
    const [geofenceOrigin, setGeofenceOrigin] = useState(originalGeofenceOrigin);
    const [geofenceRadius, setGeofenceRadius] = useState(originalGeofenceRadius);
    const [selectedMap, setSelectedMap] = useState(null);

    const isSettingLocation = isSettingBuildingOrigin || isSettingGeofenceOrigin;
    const isValid =
        buildingOrigin?.latitude &&
        buildingOrigin?.longitude &&
        geofenceOrigin?.latitude &&
        geofenceOrigin?.longitude &&
        geofenceRadius;

    const handleLocationChange = ({ lat, lng }) => {
        if (isSettingBuildingOrigin) {
            setBuildingOrigin({ latitude: lat, longitude: lng });
            setIsSettingBuildingOrigin(false);
        } else if (isSettingGeofenceOrigin) {
            setGeofenceOrigin({ latitude: lat, longitude: lng });
            setIsSettingGeofenceOrigin(false);
        }
    };

    const handleRotationChanged = (floatValue) => {
        const minValue = 0;
        const maxValue = 360;
        const clampedValue = Math.min(Math.max(floatValue, minValue), maxValue);
        setRotation(clampedValue)
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        await onAccept({
            ...building,
            buildingToEnuRotation: rotation,
            buildingOrigin: {
                ...buildingOrigin,
                altitude: 0
            },
            geofenceOrigin,
            geofenceRadius,
        })
        setLoading(false);

    }

    if (hasError) {
        return <ErrorGeneral />;
    }

    if (isLoadingBuildings) {
        return <PulsePreloader />;
    }

    return (
        <form onSubmit={onSubmit}>
            <Dialog open onClose={onClose} fullScreen maxWidth={'lg'}>
                <DialogTitle>Change Building Placement</DialogTitle>

                <DialogContent id='dialogContentId'>
                    <FloorAndMapSelect
                        buildingId={buildingId}
                        defaultSelectedFloorId={primaryFloorId}
                        onMapChange={setSelectedMap}
                    />

                    <GoogleMapsBuildingPlacement
                        id={getGoogleMapsId()}
                        mapUrl={selectedMap?.mapUrl}
                        location={{
                            lat: buildingOrigin?.latitude ?? 0,
                            lng: buildingOrigin?.longitude ?? 0,
                        }}
                        defaultPixelToMeter={selectedMap?.pixelToMeter}
                        defaultOffset={selectedMap?.mapOffset}
                        onLocationChange={handleLocationChange}
                        opacity={opacity}
                        rotation={rotation}
                        floorId={primaryFloorId}
                        isSettingBuildingOrigin={isSettingBuildingOrigin}
                    >
                        {({ onMapClick }) => (
                            <>
                                <OverlayView
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    position={{
                                        lat: buildingOrigin?.latitude,
                                        lng: buildingOrigin?.longitude,
                                    }}
                                >
                                    <BuildingOriginMarker id={getBuildingOriginGMapsMarkerId()} />
                                </OverlayView>

                                <OverlayView
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    position={{
                                        lat: geofenceOrigin?.latitude,
                                        lng: geofenceOrigin?.longitude,
                                    }}
                                >
                                    <GeofenceOriginMarker id={getGeofenceOriginGMapsMarkerId()} />
                                </OverlayView>

                                {showRadius && <Circle
                                    id={getGeofenceRadiusGMapsCircleId()}
                                    center={{
                                        lat: geofenceOrigin?.latitude,
                                        lng: geofenceOrigin?.longitude,
                                    }}
                                    radius={geofenceRadius}
                                    onClick={onMapClick}
                                    options={{
                                        strokeColor: '#ff6c3c',
                                        strokeOpacity: 0.8,
                                        strokeWeight: 2,
                                        fillColor: '#ff6200', // TODO color should come from theme
                                        fillOpacity: 0.35,
                                    }}
                                />}
                            </>
                        )}
                    </GoogleMapsBuildingPlacement>

                    <BuildingDetailsWrapper id='buildingDetailsId'>
                        <OpacityWrapper>
                            <label>Map opacity: {opacity * 100}%</label>
                            <Slider
                                value={opacity}
                                disabled={isSettingLocation}
                                aria-labelledby={'map opacity'}
                                onChange={(e, value) => setOpacity(value.toFixed(1))}
                                min={0}
                                max={1}
                                step={0.1}
                            />
                        </OpacityWrapper>

                        <RotationWrapper>
                            <NumberFormat
                                id={getBuildingRotationId()}
                                allowNegative={false}
                                disabled={isSettingLocation}
                                customInput={TextField}
                                onValueChange={({ floatValue }) => handleRotationChanged(floatValue)
                                }
                                value={rotation}
                                defaultValue={rotation}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Rotation:</InputAdornment>,
                                }}
                            />
                            <Slider
                                value={rotation || 0}
                                disabled={isSettingLocation}
                                aria-labelledby="rotation"
                                onChange={(e, value) => handleRotationChanged(value)}
                                min={0}
                                max={360}
                                step={0.01}
                            />
                        </RotationWrapper>

                        <BuildingOriginWrapper>
                            <FlexRow style={{ columnGap: 5 }}>
                                <Typography variant={'subtitle1'} inline="true" display="inline">
                                    Building origin:
                                </Typography>

                                <ChangeButton
                                    id={getChangeBuildingOriginButtonId()}
                                    size={'small'}
                                    variant={'outlined'}
                                    disabled={isSettingLocation}
                                    onClick={() => setIsSettingBuildingOrigin(true)}
                                >
                                    Change
                                </ChangeButton>
                                <FlexRow>
                                    {isSettingBuildingOrigin && <Button onClick={() => setIsSettingBuildingOrigin(false)} style={{ minWidth: 40 }} variant={'outlined'}>
                                        <BsXLg fontSize={20} />
                                    </Button>}
                                </FlexRow>


                            </FlexRow>

                            <FlexColumn>
                                <NumberFormat
                                    id={getBuildingOriginLatitudeId()}
                                    disabled={isSettingLocation}
                                    error={
                                        buildingOrigin?.latitude === null || buildingOrigin?.latitude === undefined
                                    }
                                    customInput={TextField}
                                    onValueChange={({ floatValue }) =>
                                        setBuildingOrigin({
                                            latitude: floatValue,
                                            longitude: buildingOrigin?.longitude,
                                        })
                                    }
                                    value={buildingOrigin?.latitude}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Latitude:</InputAdornment>,
                                    }}
                                />

                                <NumberFormat
                                    id={getBuildingOriginLongitudeId()}
                                    disabled={isSettingLocation}
                                    error={
                                        buildingOrigin?.longitude === null || buildingOrigin?.longitude === undefined
                                    }
                                    customInput={TextField}
                                    onValueChange={({ floatValue }) =>
                                        setBuildingOrigin({
                                            latitude: buildingOrigin?.latitude,
                                            longitude: floatValue,
                                        })
                                    }
                                    value={buildingOrigin?.longitude}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Longitude:</InputAdornment>,
                                    }}
                                />
                            </FlexColumn>

                        </BuildingOriginWrapper>

                        <GeofenceOriginWrapper>
                            <FlexRow style={{ columnGap: 5 }}>
                                <Typography variant={'subtitle1'} inline="true" display="inline">
                                    Geofence origin:
                                </Typography>

                                <ChangeButton
                                    id={getChangeGeofenceOriginButtonId()}
                                    size={'small'}
                                    variant={'outlined'}
                                    disabled={isSettingLocation}
                                    onClick={() => setIsSettingGeofenceOrigin(true)}
                                >
                                    Change
                                </ChangeButton>
                                <FlexRow>
                                    {isSettingGeofenceOrigin && <Button onClick={() => setIsSettingGeofenceOrigin(false)} style={{ minWidth: 40 }} variant={'outlined'}>
                                        <BsXLg fontSize={20} />
                                    </Button>}
                                </FlexRow>
                            </FlexRow>

                            <FlexColumn>
                                <NumberFormat
                                    id={getGeofenceOriginLatitudeId()}
                                    disabled={isSettingLocation}
                                    error={
                                        geofenceOrigin?.latitude === null || geofenceOrigin?.latitude === undefined
                                    }
                                    customInput={TextField}
                                    onValueChange={({ floatValue }) =>
                                        setGeofenceOrigin({
                                            latitude: floatValue,
                                            longitude: geofenceOrigin?.longitude,
                                        })
                                    }
                                    value={geofenceOrigin?.latitude}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Latitude:</InputAdornment>,
                                    }}
                                />

                                <NumberFormat
                                    id={getGeofenceOriginLongitudeId()}
                                    disabled={isSettingLocation}
                                    error={
                                        geofenceOrigin?.longitude === null || geofenceOrigin?.longitude === undefined
                                    }
                                    customInput={TextField}
                                    onValueChange={({ floatValue }) =>
                                        setGeofenceOrigin({
                                            latitude: geofenceOrigin?.latitude,
                                            longitude: floatValue,
                                        })
                                    }
                                    value={geofenceOrigin?.longitude}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Longitude:</InputAdornment>,
                                    }}
                                />
                            </FlexColumn>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <NumberFormat
                                    id={getGeofenceRadiusFieldId()}
                                    disabled={isSettingLocation}
                                    error={geofenceRadius === null || geofenceRadius === undefined}
                                    customInput={TextField}
                                    onValueChange={({ floatValue }) => {
                                        setGeofenceRadius(floatValue);
                                    }}
                                    value={geofenceRadius}
                                    suffix={' m'}
                                    decimalScale={0}
                                    allowNegative={false}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Radius:</InputAdornment>,
                                    }}
                                />
                                {showRadius ? <IoEye style={{ cursor: 'pointer' }} onClick={() => setShowRadius(false)} /> : <IoEyeOff style={{ cursor: 'pointer' }} onClick={() => setShowRadius(true)} />}
                            </div>

                        </GeofenceOriginWrapper>
                    </BuildingDetailsWrapper>
                </DialogContent>

                <Divider />

                <DialogActions>
                    <Button variant={'text'} onClick={onClose}>
                        Cancel
                    </Button>

                    <Button type='submit' disabled={loading} onClick={onSubmit}>
                        {loading ? <CircularPreloader size={20} /> : 'Accept'}
                    </Button>

                </DialogActions>
            </Dialog>
        </form>
    );
}
