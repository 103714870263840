import Api from './Api';
import http from '../utils/httpClient';

class CoordinatesConversion extends Api {
    async fetchAllCoordinatesConversionsInMap({ buildingId, floorId }) {
        return await this.callApi((params) =>
            http.v4.get(`/buildings/${buildingId}/floors/${floorId}/coordinates-conversion`, { params })
        );
    }

    async saveCoordinatesConversion({ buildingId, floorId, mapId, data }) {
        return await this.callApi((params) =>
            http.v4.post(`/buildings/${buildingId}/floors/${floorId}/coordinates-conversion`, data, {
                params,
            })
        );
    }

    async calculateCoordinatesConversion({ buildingId, floorId, mapId, data }) {
        return await this.callApi((params) =>
            http.v4.post(
                `/buildings/${buildingId}/floors/${floorId}/coordinates-conversion/calculate`,
                data,
                { params }
            )
        );
    }
    async updateCoordinatesConversion({ buildingId, floorId, mapId, data }) {
        return await this.callApi((params) =>
            http.v4.post(`/buildings/${buildingId}/floors/${floorId}/coordinates-conversion`, data, {
                params,
            })
        );
    }

    async deleteCoordinatesConversion({ buildingId, floorId }) {
        return await this.callApi((params) =>
            http.v4.delete(`/buildings/${buildingId}/floors/${floorId}/coordinates-conversion`, { params })
        );
    }
}

export default new CoordinatesConversion();
