import entityAdapter from './entranceEntityAdapter';
import { createSelector } from '@reduxjs/toolkit';
import { selectFloorById, selectFloorEntities } from '../floor/floorSelectors';
import { selectBuildingById } from '../building/buildingSelectors';

export const {
    selectById: selectEntranceById,
    selectIds: selectEntranceIds,
    selectEntities: selectEntranceEntities,
    selectAll: selectAllEntrances,
    selectTotal: selectTotalEntrances,
} = entityAdapter.getSelectors((state) => state.entrances);

export const getSelectAllEntrancesInBuilding = (buildingId) =>
    createSelector(
        (state) => selectBuildingById(state, buildingId),
        selectFloorEntities,
        selectEntranceEntities,
        (building, floors, entrances = {}) => {
            const buildingFloors = building?.floorIds?.map((id) => floors?.[id]);
            const entranceIds = buildingFloors?.reduce(
                (result, { entranceIds = [] }) => [...result, ...entranceIds],
                []
            );
            return {
                asArray: entranceIds?.map((id) => entrances?.[id]),
                asObject: entranceIds?.reduce((res, id) => ({ ...res, [id]: entrances?.[id] }), {}),
                spaceId: building?.spaceId,
            };
        }
    );

export const getSelectAllEntrancesInFloor = (floorId) => {
    return createSelector(
        (state) => selectFloorById(state, floorId),
        selectEntranceEntities,
        (floor, entrances = {}) => ({
            asArray: floor?.entranceIds?.map((id) => entrances?.[id]),
            asObject: floor?.entranceIds?.reduce(
                (res, id) => ({
                    ...res,
                    [id]: entrances?.[id],
                }),
                {}
            ),
        })
    );
};

export const getSelectIsThereMainEntranceInFloor = (floorId) =>
    createSelector(
        (state) => selectFloorById(state, floorId),
        selectEntranceEntities,
        (floor, entrances = {}) => {
            const floorEntrances = floor?.entranceIds?.map((id) => entrances?.[id]);
            return floorEntrances?.some((e) => !!e?.isMain);
        }
    );

export const getSelectMainEntranceIdInFloor = (floorId) =>
    createSelector(
        (state) => selectFloorById(state, floorId),
        selectEntranceEntities,
        (floor, entrances = {}) => {
            const floorEntrances = floor?.entranceIds?.map((id) => entrances?.[id]);
            return floorEntrances?.find((e) => !!e?.isMain)?.entranceId ?? null;
        }
    );
