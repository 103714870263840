import styled from 'styled-components';

export default styled.div`
    width: 10rem;
    height: 10rem;
    border: 1px solid #cccccc; // TODO color should come from theme;
    cursor: pointer;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;

    &.selected {
        border: 2px solid #ff6200; // TODO color should come from theme;
    }

    :hover {
        border: 2px solid #ff873c; // TODO color should come from theme;
    }

    & img {
        max-height: 10rem;
    }
`;
