import React, { memo, useContext, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { MapViewerContext } from '../../../MapViewerContext';
import { useDispatch } from 'react-redux';
import { getGoogleMapsEntranceId } from './GoogleMapsEntrancesOverlay.selectors';
import { GiEntryDoor } from 'react-icons/gi';
import {
    GMAPS_BUILDING_ORIGIN_ALT,
    GMAPS_BUILDING_ORIGIN_LAT,
    GMAPS_BUILDING_ORIGIN_LNG,
    GMAPS_BUILDING_TO_ENU_ROTATION,
} from '../../../../../../constants/mapViewerVariables';
import clsx from 'clsx';
import {
    highlightEntrance,
    setSelectedEntranceId,
    unhighlightEntrance,
} from '../../../../../../state-management/user-inputs/buildingsSlice';
import { GMAPS_METERS_PER_PIXEL } from '../../../../../../constants/mapViewerVariables';
import { buildingCoordinatesToLatLng } from '@oriient/coordinates-convert';
import { OverlayView } from '@react-google-maps/api';

const EntranceIcon = styled(GiEntryDoor)`
    fill: #ff6200; // TODO color should come from theme
    stroke: #ffffff;
    stroke-width: 10px;
    overflow: visible;
    cursor: pointer;

    & > path {
        transform: translate(-50%, -50%) scale(min(max(calc(1 / (2 * var(${GMAPS_METERS_PER_PIXEL}))), 3), 6));
        transform-origin: center;
    }

    &.highlighted,
    &.selected {
        fill: #7a007a; // TODO color should come from theme
        stroke-width: 0;
    }
`;

function GoogleMapsEntrance(props) {
    const { entrance, isHighlighted = false, isSelected = false } = props;
    const { entranceId, coordinates } = entrance ?? {};

    const dispatch = useDispatch();

    const { getCSSVariable } = useContext(MapViewerContext);

    const { lat, lng } = useMemo(() => {
        const rotation = parseFloat(getCSSVariable(GMAPS_BUILDING_TO_ENU_ROTATION));
        const latitude = parseFloat(getCSSVariable(GMAPS_BUILDING_ORIGIN_LAT));
        const longitude = parseFloat(getCSSVariable(GMAPS_BUILDING_ORIGIN_LNG));
        const altitude = parseFloat(getCSSVariable(GMAPS_BUILDING_ORIGIN_ALT));
        const { x, y } = coordinates;

        return buildingCoordinatesToLatLng(rotation, latitude, longitude, altitude, { x, y, height: 0 });
    }, [coordinates, getCSSVariable]);

    const handlePointerEnter = () => {
        dispatch(highlightEntrance(entranceId));
    };

    const handlePointerLeave = () => {
        dispatch(unhighlightEntrance(entranceId));
    };

    const handleClick = () => {
        dispatch(setSelectedEntranceId(isSelected ? null : entranceId));
    };

    if (!entrance) {
        return null;
    }

    return (
        <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={{ lat, lng }}>
            <EntranceIcon
                id={getGoogleMapsEntranceId(entranceId)}
                className={clsx({ highlighted: isHighlighted, selected: isSelected })}
                onClick={handleClick}
                onPointerEnter={handlePointerEnter}
                onPointerLeave={handlePointerLeave}
            />
        </OverlayView>
    );
}

GoogleMapsEntrance.propTypes = {
    entrance: PropTypes.object.isRequired,
    isHighlighted: PropTypes.bool,
    isSelected: PropTypes.bool,
};

export default memo(GoogleMapsEntrance);
