import http from '../utils/httpClient';
import logger from '../logger/logger';
import authManager from './AuthManager';
import Manager from './Manager';
import timezoneUtils from '../utils/timezoneUtils';

const log = logger.getLogger('AnalyticsManager');

class AnalyticsManager extends Manager {
    constructor() {
        super(authManager);
        this._desiredTimeZoneOffset = 0;
    }

    updateTimezoneOffset(offset) {
        this._desiredTimeZoneOffset = offset;
    }

    async getPositions(
        startTimestamp,
        endTimestamp,
        spaceId,
        mapIds = [],
        ipsClientIds = [],
        sessionIds = [],
        responseFormat = 'json',
        downloadFile = false
    ) {
        try {
            const response = await http.analyticsRestApi.v1.get('/analytics/positions', {
                params: {
                    startTimestamp,
                    endTimestamp,
                    spaceId,
                    mapIds,
                    ipsClientIds,
                    sessionIds,
                    responseFormat,
                    downloadFile,
                },
            });

            return response.data;
        } catch (ex) {
            log.error(`failed to call /analytics/positions: ${ex.message}`, ex);
            return { error: true, message: ex };
        }
    }

    async getPositionsBySessionId({
        fromTime,
        toTime,
        sessionId,
        spaceId,
        desiredTimezoneOffset = this._desiredTimeZoneOffset,
    }) {
        try {
            const params = {
                // convert the times to utc
                startTimestamp: timezoneUtils.convertToUtcISOStr(desiredTimezoneOffset, fromTime),
                endTimestamp: timezoneUtils.convertToUtcISOStr(desiredTimezoneOffset, toTime),
                sessionIds: [sessionId],
                spaceId,
            };
            let positionsResponse = (
                await http.analyticsRestApi.v1.get(`/analytics/positions`, {
                    params: this.getParams(params),
                })
            ).data;
            const positions = positionsResponse.data;

            while (positionsResponse.pagination && positionsResponse.pagination.next) {
                positionsResponse = (await http.v2.get(positionsResponse.pagination.next),
                { timeout: 120000 }).data;
                positions.push(...positionsResponse.data);
            }
            return { data: positions };
        } catch (ex) {
            log.error(`failed to get /analytics/positions: ${ex.message}`, ex);
            return {
                error: true,
                message: 'Failed to get positions by sessionId',
            };
        }
    }

    async getPositionsWithRegions({
        startTimestamp,
        endTimestamp,
        spaceId,
        buildingId,
        mapIds,
        desiredTimeZoneOffset = this._desiredTimeZoneOffset,
        sessionId,
    }) {
        try {
            // Convert the timestamps into UTC since records in DB are saved in UTC
            const params = {
                startTimestamp: timezoneUtils.convertToUtcISOStr(desiredTimeZoneOffset, startTimestamp),
                endTimestamp: timezoneUtils.convertToUtcISOStr(desiredTimeZoneOffset, endTimestamp),
                spaceId,
                buildingId,
                mapIds,
                ...(sessionId && sessionId !== '' ? { sessionId } : {}),
            };
            const { data: clientsPositions } = await http.analyticsRestApi.v1.get(
                `/analytics/client-positions-with-region`,
                {
                    params: this.getParams(params),
                    timeout: 300000,
                }
            );
            // Adjust the positions' timestamps to match the desired timezone
            for (const client of clientsPositions) {
                for (const session of client.sessions) {
                    session.positions = session.positions.map((position) => ({
                        ...position,
                        t: timezoneUtils.convertToTimezoneISOStrFromUTC(desiredTimeZoneOffset, position.t),
                    }));
                }
            }

            return { data: clientsPositions };
        } catch (ex) {
            log.error(`failed to get /analytics/client-positions-with-region: ${ex.message}`, ex);
            return {
                error: true,
                message: 'Failed to get positions with regions',
            };
        }
    }

    async getOccupancyData(
        buildingId,
        floorId,
        timeResolutions,
        startTimestamp,
        endTimestamp,
        desiredTimeZoneOffset = this._desiredTimeZoneOffset,
        workingHoursOnly,
        spaceId
    ) {
        try {
            return await http.v4.get(`/analytics/occupancy`, {
                params: this.getParams({
                    buildingId,
                    floorId,
                    timeResolutions,
                    startTimestamp,
                    endTimestamp,
                    timeZoneOffset: desiredTimeZoneOffset,
                    workingHoursOnly,
                    spaceId,
                }),
            });
        } catch (e) {
            log.error(`Failed to get /analytics/occupancy: ${e.message}`, e);
            return {
                error: true,
                message: 'Failed to get occupancy data: ' + e.message,
            };
        }
    }

    async executeSessionAnalysis(data) {
        try {
            const { data: sessions } = await http.analyticsRestApi.v1.post(
                `/analytics/executeSessions`,
                data,
                {
                    params: this.getParams(),
                }
            );
            return { sessions };
        } catch (e) {
            log.error(`Failed to execute /analytics/executeSessions: ${e.message}`, e);
            return {
                error: true,
                message: 'Failed to execute sessions: ' + e.message,
            };
        }
    }

    async checkForCompletedSessions(sessionIds) {
        try {
            const { data } = await http.analyticsRestApi.v1.post(`/analytics/checkSessions`, sessionIds, {
                params: this.getParams(),
            });
            return {
                data: data.map(({ sessionId }) => sessionId),
            };
        } catch (e) {
            log.error(`Failed to get /analytics/checkSessions: ${e.message}`, e);
            return {
                error: true,
                message: 'Failed to fetch completed sessions: ' + e.message,
            };
        }
    }
}

export default new AnalyticsManager();
