import Api from './Api';
import http from '../utils/httpClient';

class RegionsApi extends Api {
    async fetchAllRegionsInSpace(spaceId, withPlacements = false, withTriggerIds = false) {
        return await this.callApi((params) =>
            http.v5.get(`/regions`, { params: { ...params, spaceId, withPlacements, withTriggerIds } })
        );
    }

    async fetchRegionColorsInSpace(spaceId, regionNames) {
        return await this.callApi((params) =>
            http.dashboard.post(
                '/space/region-colors',
                {
                    spaceId: params.spaceId,
                    regions: regionNames,
                },
                { params }
            )
        );
    }

    async createRegion(regionData, spaceId) {
        return await this.callApi((params) =>
            http.v2.post(`/regions`, regionData, { params: { ...params, spaceId } })
        );
    }

    async updateRegion(regionId, regionData) {
        return await this.callApi((params) => http.v2.post(`/regions/${regionId}`, regionData, { params }));
    }

    async deleteRegion(regionId) {
        return await this.callApi((params) => http.v2.delete(`/regions/${regionId}`, { params }));
    }
}

export default new RegionsApi();
