import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    Select,
    Switch,
    TextField,
    Toggle,
    ToggleGroup,
    SwitchLabel as Label,
} from '../../../common/themed';
import { MdAdd as AddIcon } from 'react-icons/md';
import MapViewerPixelToMeterMeasureOverlay from '../../../common/map-viewer/overlays/pixel-to-meter-measure';
import { FormControlLabel, InputAdornment, Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import MapViewerV1 from '../../../map-content/legacy/OldMapViewer';
import Map from '../../../common/map-viewer';
import Slider from '@material-ui/core/Slider';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import Thumbnail from '../common/Thumbnail';
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';
import useAllBuildingsInSpace from '../../../common/hooks/data-fetching/useAllBuildingsInSpace';
import useAllFloorsInBuilding from '../../../common/hooks/data-fetching/useAllFloorsInBuilding';
import useMapsFullData from '../../../common/hooks/data-fetching/useMapsFullData';
import {
    getChangeOffsetId,
    getChangePixelToMeterId,
    getFloorToggleId,
    getMapNameId,
    getMapUsageId,
    getOffsetDialogAcceptId,
    getOffsetXId,
    getOffsetYId,
    getPixelToMeterId,
} from './CreateFloorDialog.selectors';
import { useSelector } from 'react-redux';
import { getSelectDefaultReferenceMapId } from '../../../../state-management/map/mapSelectors';
import DropZone from '../common/DropZone';

const newFloorId = 'new-floor';

const OldMapViewer = styled(MapViewerV1)`
    height: 600px !important;
`;

const Form = styled.div`
    display: grid;
    grid-template-rows: 4rem 25rem 1fr;
    grid-template-columns: 12rem 1fr;
    grid-template-areas:
        'floor-select floor-select'
        'map-thumbnails map-viewer'
        'map-thumbnails map-details';
`;

const FloorsToggleGroup = styled(ToggleGroup)`
    grid-area: floor-select;
`;

const ThumbnailsWrapper = styled.div`
    grid-area: map-thumbnails;
    overflow-y: auto;
    max-height: 35rem;

    ${Thumbnail} {
        margin-block-start: 20px;
    }
`;

const MapViewer = styled(Map)`
    grid-area: map-viewer;
`;

const MapDetailsWrapper = styled.div`
    grid-area: map-details;
    margin-block-start: 15px;
    display: grid;
    grid-template-columns: 270px 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 50px;
    row-gap: 15px;
    align-items: center;
`;

const OpacityWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const OpacityTitle = styled(Typography)`
    margin-inline-end: 15px;
`;

const OpacitySlider = styled(Slider)`
    width: 300px;
`;

const MapUsageTextField = styled(TextField)`
    max-width: 300px;
`;

const PixelToMeterTextField = styled(NumberFormat)`
    margin-inline-end: 15px;
`;

const OffsetTextField = styled(NumberFormat)`
    max-width: 120px;
    margin-inline-end: 15px;
`;

const ControlsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-block-start: 10px;

    & > :not(:last-child) {
        margin-inline-end: 30px;
    }

    & > :last-child {
        margin-inline-start: auto;
    }
`;

export default function CreateFloorStepTwo({ buildingId }) {
    const [oldMapViewerRef, setOldMapViewerRef] = useState(null);
    const [selectedMapArrayIndex, setSelectedMapArrayIndex] = useState(0);
    const [lockStages, setLockStages] = useState(true);
    const [opacity, setOpacity] = useState(0.5);
    const [isMapOffsetDialogOpen, setIsMapOffsetDialogOpen] = useState(false);
    const [isSettingPixelToMeter, setIsSettingPixelToMeter] = useState(false);

    const {
        data: { [buildingId]: building },
    } = useAllBuildingsInSpace({ asObject: true });
    const { buildingName } = building ?? {};

    const { data: floors } = useAllFloorsInBuilding({ buildingId });

    const { data: allMaps } = useMapsFullData({ buildingId, asObject: true });

    const selectDefaultReferenceMapId = useMemo(
        () => getSelectDefaultReferenceMapId(buildingId),
        [buildingId]
    );

    const defaultReferenceMapId = useSelector(selectDefaultReferenceMapId);

    const [selectedReferenceMapId, setSelectedReferenceMapId] = useState(defaultReferenceMapId);

    const { errors, register, setValue, watch, setError, clearErrors } = useFormContext();
    const { maps, floorName } = watch(['maps', 'floorName']);

    const selectedMap = maps?.[selectedMapArrayIndex];
    const selectedMapFieldKey = `maps[${selectedMapArrayIndex}]`;
    const selectedMapErrors = errors?.maps?.[selectedMapArrayIndex];

    const handleFileAccepted = ([file]) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            setValue(`maps`, [
                ...maps,
                {
                    mapName: `${buildingName}_${floorName}_map${
                        maps?.length === 0 ? '' : `_${maps?.length + 1}`
                    }`,
                    pixelToMeter: maps?.[0]?.pixelToMeter ?? 1,
                    imgBase64: e.target.result.split(',')[1],
                    mapOffset: { x: 0, y: 0 },
                    mapUsage: maps?.length === 0 ? 'default' : '',
                    mapArrayIndex: maps?.length,
                },
            ]);

            setSelectedMapArrayIndex(maps?.length);
            setIsMapOffsetDialogOpen(true);
        };
        fileReader.readAsDataURL(file);
    };

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDropAccepted: handleFileAccepted,
        onFileDialogCancel: () => {},
        accept: 'image/jpeg, image/png',
        multiple: false,
    });

    const handlePixelToMeterChange = (pixelToMeter) => {
        setValue(`${selectedMapFieldKey}.pixelToMeter`, pixelToMeter);
        setIsSettingPixelToMeter(false);
    };

    const handleMapOffsetChange = () => {
        setValue(`${selectedMapFieldKey}.mapOffset`, oldMapViewerRef?.getMapsOffset());
        setValue(`${selectedMapFieldKey}.pixelToMeter`, oldMapViewerRef?.getPixelToMeter());

        setIsMapOffsetDialogOpen(false);
    };

    useEffect(() => {
        const isNewFloorEmpty = maps?.length === 0;

        const emptyFloorMessage = `Please upload at least one map to ${floorName}`;
        const finishPixelToMeterMessage = `Please finish setting the map's pixel to meter ratio`;

        if (isNewFloorEmpty && errors?.custom?.message !== emptyFloorMessage) {
            setError('custom', { message: emptyFloorMessage });
        } else if (isSettingPixelToMeter && errors?.custom?.message !== finishPixelToMeterMessage) {
            setError('custom', { message: finishPixelToMeterMessage });
        } else if (!isNewFloorEmpty && !isSettingPixelToMeter && errors?.custom) {
            clearErrors('custom');
        }
    }, [clearErrors, errors, floorName, isSettingPixelToMeter, maps, setError]);

    return (
        <>
            <Form>
                <FloorsToggleGroup>
                    {[...(floors ?? []), { floorId: newFloorId, floorName }]?.map(
                        ({ floorId, floorName }) => (
                            <Toggle
                                key={floorId}
                                id={getFloorToggleId(floorId)}
                                isSelected={floorId === newFloorId}
                                isDisabled={floorId !== newFloorId}
                            >
                                {floorName}
                            </Toggle>
                        )
                    )}
                </FloorsToggleGroup>

                <ThumbnailsWrapper>
                    <DropZone
                        isDragActive={isDragActive}
                        isDragAccept={isDragAccept}
                        isDragReject={isDragReject}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <AddIcon size={50} />
                        Upload files
                    </DropZone>

                    {maps?.map(({ imgBase64 }, index) => (
                        <Thumbnail
                            key={`map-thumbnail-${index}`}
                            className={clsx({ selected: index === selectedMapArrayIndex })}
                            onClick={() => setSelectedMapArrayIndex(index)}
                        >
                            <img alt={'thumbnail'} src={`data:image/jpeg;base64,${imgBase64}`} />
                        </Thumbnail>
                    ))}
                </ThumbnailsWrapper>

                <MapViewer base64={selectedMap?.imgBase64}>
                    {isSettingPixelToMeter && (
                        <MapViewerPixelToMeterMeasureOverlay
                            isCancelable={!!selectedMap?.pixelToMeter}
                            onSubmit={handlePixelToMeterChange}
                            onCancel={() => setIsSettingPixelToMeter(true)}
                        />
                    )}
                </MapViewer>

                {maps?.map(
                    (m, index) =>
                        index === selectedMapArrayIndex && (
                            <MapDetailsWrapper key={`map-${index}-details`}>
                                <TextField
                                    id={getMapNameId(index)}
                                    name={`${selectedMapFieldKey}.mapName`}
                                    inputRef={register({
                                        validate: (value) => /^(?![0-9])[^\s]+$/.test(value), // cannot start with a number must be at least 1 char and without white spaces
                                    })}
                                    label={'Name'}
                                    error={!!selectedMapErrors?.mapName}
                                    helperText={selectedMapErrors?.mapName?.message}
                                />

                                <MapUsageTextField
                                    id={getMapUsageId(index)}
                                    name={`${selectedMapFieldKey}.mapUsage`}
                                    inputRef={register}
                                    label={'Map Usage'}
                                    error={!!selectedMapErrors?.mapUsage}
                                    helperText={
                                        selectedMapErrors?.mapUsage?.message ??
                                        'This will describe the purpose of this specific map'
                                    }
                                />

                                {selectedMap?.pixelToMeter ? (
                                    <div>
                                        <Typography>Pixel to meter ratio:</Typography>

                                        <Controller
                                            name={`${selectedMapFieldKey}.pixelToMeter`}
                                            defaultValue={1}
                                            rules={{ required: 'This is required', valueAsNumber: true }}
                                            render={({ onChange }) => (
                                                <PixelToMeterTextField
                                                    id={getPixelToMeterId(index)}
                                                    disabled={isSettingPixelToMeter}
                                                    error={!!selectedMapErrors?.pixelToMeter}
                                                    helperText={selectedMapErrors?.pixelToMeter?.message}
                                                    customInput={TextField}
                                                    onValueChange={({ floatValue }) => onChange(floatValue)}
                                                    value={selectedMap?.pixelToMeter}
                                                    allowNegative={false}
                                                />
                                            )}
                                        />

                                        <Button
                                            id={getChangePixelToMeterId(index)}
                                            size={'small'}
                                            variant={'outlined'}
                                            onClick={() => setIsSettingPixelToMeter(true)}
                                        >
                                            Change
                                        </Button>
                                    </div>
                                ) : (
                                    <Button onClick={() => true}>Set the pixel to meter ratio</Button>
                                )}

                                <div>
                                    <Typography>Map offset:</Typography>

                                    <Grid container>
                                        <Grid item>
                                            <Controller
                                                name={`${selectedMapFieldKey}.mapOffset.x`}
                                                defaultValue={1}
                                                rules={{
                                                    required: 'This is required',
                                                    valueAsNumber: true,
                                                }}
                                                render={({ onChange }) => (
                                                    <OffsetTextField
                                                        id={getOffsetXId(index)}
                                                        disabled={isSettingPixelToMeter}
                                                        error={!!selectedMapErrors?.mapOffset?.x}
                                                        helperText={selectedMapErrors?.mapOffset?.x?.message}
                                                        customInput={TextField}
                                                        onValueChange={({ floatValue }) =>
                                                            onChange(floatValue)
                                                        }
                                                        value={selectedMap?.mapOffset?.x}
                                                        allowNegative={false}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    X:
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Controller
                                                name={`${selectedMapFieldKey}.mapOffset.y`}
                                                defaultValue={1}
                                                rules={{
                                                    required: 'This is required',
                                                    valueAsNumber: true,
                                                }}
                                                render={({ onChange }) => (
                                                    <OffsetTextField
                                                        id={getOffsetYId(index)}
                                                        disabled={isSettingPixelToMeter}
                                                        error={!!selectedMapErrors?.mapOffset?.y}
                                                        helperText={selectedMapErrors?.mapOffset?.y?.message}
                                                        customInput={TextField}
                                                        onValueChange={({ floatValue }) =>
                                                            onChange(floatValue)
                                                        }
                                                        value={selectedMap?.mapOffset?.y}
                                                        allowNegative={false}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    Y:
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Button
                                                id={getChangeOffsetId(index)}
                                                size={'small'}
                                                variant={'outlined'}
                                                onClick={() => setIsMapOffsetDialogOpen(true)}
                                            >
                                                Change
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </MapDetailsWrapper>
                        )
                )}
            </Form>

            <Dialog open={isMapOffsetDialogOpen} closeable={false} fullWidth maxWidth={'lg'}>
                <DialogContent>
                    <DialogContentText>
                        In order to proceed, please set the map's offset by aligning it over another map that
                        was previously uploaded.
                    </DialogContentText>

                    {/*// TODO move the map offset behavior into the new map viewer*/}
                    <OldMapViewer
                        selectedMap={allMaps?.[selectedReferenceMapId]}
                        selectedMapData={allMaps?.[selectedReferenceMapId]}
                        additionalMapData={selectedMap}
                        additionalMapOpacity={opacity}
                        onMount={setOldMapViewerRef}
                        lockStages={lockStages}
                    />

                    <ControlsWrapper>
                        {Object.values(allMaps ?? {}).length > 1 && (
                            <Select
                                options={Object.values(allMaps ?? {})}
                                getOptionLabel={(m) => m.mapName}
                                getOptionValue={(m) => m.mapId}
                                defaultValue={allMaps?.[selectedReferenceMapId] ?? null}
                                onChange={(m) => setSelectedReferenceMapId(m.mapId)}
                                menuPlacement={'top'}
                            />
                        )}

                        <FormControlLabel
                            control={
                                <Switch checked={lockStages} onChange={() => setLockStages(!lockStages)} />
                            }
                            label={<Label>Lock position</Label>}
                        />

                        <OpacityWrapper>
                            <OpacityTitle>Opacity:</OpacityTitle>
                            <OpacitySlider
                                min={0}
                                max={1}
                                step={0.05}
                                value={opacity}
                                onChange={(e, value) => setOpacity(value)}
                            />
                        </OpacityWrapper>

                        <Button
                            id={getOffsetDialogAcceptId()}
                            variant={'text'}
                            onClick={handleMapOffsetChange}
                            disabled={!selectedReferenceMapId}
                        >
                            Accept
                        </Button>
                    </ControlsWrapper>
                </DialogContent>
            </Dialog>
        </>
    );
}
