import cronTime from 'cron-time-generator';

export const deepEqual = (a, b) => {
    if (a && b && typeof a == 'object' && typeof b == 'object') {
        if (Object.keys(a).length != Object.keys(b).length) return false;
        for (var key in a) if (!deepEqual(a[key], b[key])) return false;
        return true;
    } else return a === b;
};

export const validateRequiredFields = (obj) => {
    return !Object.values(obj).every((item) => item === null || item === false || !item.length);
};

export const formatConfigSettings = (settings, spaceId) => {
    // Set CRON value
    const hour = settings.time.hours;
    const minutes = settings.time.minutes;

    const runAt = cronTime.onSpecificDaysAt(
        settings.days.map((d) => d.value.toLowerCase()),
        hour,
        minutes
    );

    //Set the time frame value
    const timeFrame = settings.timeframe.value.replace('ago', '').trim();

    return {
        spaceId,
        frequency: runAt,
        timezone: settings.timezone.name,
        timeframe: timeFrame,
        isMultiLocation: settings.productLocationSelect.value.includes('Multi'),
        usePlaiRegions: settings.usePlaiRegionsEnabled,
        singleProductPerRegion: settings.singleProductPerRegionEnabled,
    };
};
