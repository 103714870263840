import React, { forwardRef, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
    Tooltip,
    CircularPreloader,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '../../common/themed';
import {
    getLaneListEntryChangeLayoutId,
    getLaneListEntryChangeLayoutMenuBringToFrontId,
    getLaneListEntryChangeLayoutMenuId,
    getLaneListEntryChangeLayoutMenuSendToBackId,
    getLaneListEntryDeleteId,
    getLaneListEntryResolveId,
    getLaneListEntryResolveMenuApproveId,
    getLaneListEntryResolveMenuDeclineId,
    getLaneListEntryResolveMenuId,
} from '../Mapping.selectors';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { MdGavel as ResolveIcon } from 'react-icons/md';
import { BsLayersHalf as ChangeLayoutIcon } from 'react-icons/bs';
import { MdFlipToBack, MdFlipToFront } from 'react-icons/md';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import { IconButton, RootRef, Grid, Menu, MenuItem } from '@material-ui/core';
import { selectIsLoading } from '../../../state-management/status/statusSelectors';
import { deleteLane, updateLane } from '../../../state-management/mapping/lane/laneActions';
import laneStatus from '../../../constants/laneStatuses';
import { useDispatch, useSelector } from 'react-redux';
import { bringLaneToFront, sendLaneToBack } from '../../../state-management/user-inputs/mappingSlice';
import styled, { css } from 'styled-components';

const menuIconsCommonStyles = css`
    margin-inline-end: 10px;
    width: 1.5rem;
    height: 1.5rem;
`;

const ApproveIcon = styled(FaThumbsUp)`
    ${menuIconsCommonStyles}
`;

const DeclineIcon = styled(FaThumbsDown)`
    ${menuIconsCommonStyles}
`;

const SendToBackIcon = styled(MdFlipToBack)`
    ${menuIconsCommonStyles}
`;

const BringToFrontIcon = styled(MdFlipToFront)`
    ${menuIconsCommonStyles}
`;

const IconWrapper = forwardRef((props, ref) => (
    <RootRef rootRef={ref}>
        <IconButton {...props} />
    </RootRef>
));

export default function LaneActions(props) {
    const { mapId, laneId, additionalActions, disabled } = props;

    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
    const [resolveMenuAnchor, setResolveMenuAnchor] = useState(null);
    const [layoutMenuAnchor, setLayoutMenuAnchor] = useState(null);

    const dispatch = useDispatch();

    const selectIsDeleting = useMemo(
        () => selectIsLoading({ type: deleteLane.typePrefix, arg: { mapId, laneId } }),
        [laneId, mapId]
    );
    const selectIsResolving = useMemo(
        () =>
            selectIsLoading([
                {
                    type: updateLane.typePrefix,
                    arg: { mapId, laneId, laneData: { status: laneStatus.APPROVED } },
                },
                {
                    type: updateLane.typePrefix,
                    arg: { mapId, laneId, laneData: { status: laneStatus.DECLINED } },
                },
            ]),
        [laneId, mapId]
    );

    const isDeleting = useSelector(selectIsDeleting);
    const isResolving = useSelector(selectIsResolving);

    const handleDelete = () => {
        dispatch(deleteLane({ mapId, laneId }));
    };

    const handleApprove = () => {
        dispatch(updateLane({ mapId, laneId, laneData: { status: laneStatus.APPROVED } }));
        setResolveMenuAnchor(null);
    };

    const handleDecline = () => {
        dispatch(updateLane({ mapId, laneId, laneData: { status: laneStatus.DECLINED } }));
        setResolveMenuAnchor(null);
    };

    const handleSendToBack = () => {
        dispatch(sendLaneToBack(laneId));
        setLayoutMenuAnchor(null);
    };

    const handleBringToFront = () => {
        dispatch(bringLaneToFront(laneId));
        setLayoutMenuAnchor(null);
    };

    return (
        <>
            <Grid container alignItems={'center'} justifyContent={'space-evenly'}>
                <Tooltip content={'Delete lane'} placement={'bottom'} useWrapper={false}>
                    <IconWrapper
                        id={getLaneListEntryDeleteId(laneId)}
                        aria-label={'Delete'}
                        onClick={() => setIsDeleteWarningOpen(true)}
                        disabled={disabled}
                    >
                        <DeleteIcon />
                    </IconWrapper>
                </Tooltip>



                <Tooltip content={'Change layout'} placement={'bottom'} useWrapper={false}>
                    <IconWrapper
                        id={getLaneListEntryChangeLayoutId(laneId)}
                        aria-label={'Change layout'}
                        onClick={(e) => setLayoutMenuAnchor(e.target)}
                        disabled={disabled}
                    >
                        <ChangeLayoutIcon />
                    </IconWrapper>
                </Tooltip>

                <Menu
                    id={getLaneListEntryChangeLayoutMenuId(laneId)}
                    anchorEl={layoutMenuAnchor}
                    open={!!layoutMenuAnchor}
                    onClose={() => setLayoutMenuAnchor(null)}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <MenuItem
                        id={getLaneListEntryChangeLayoutMenuSendToBackId(laneId)}
                        onClick={handleSendToBack}
                    >
                        <SendToBackIcon />
                        Send to back
                    </MenuItem>

                    <MenuItem
                        id={getLaneListEntryChangeLayoutMenuBringToFrontId(laneId)}
                        onClick={handleBringToFront}
                    >
                        <BringToFrontIcon />
                        Bring to front
                    </MenuItem>
                </Menu>

                {additionalActions}
            </Grid>

            {isDeleteWarningOpen && (
                <Dialog open closeable={false}>
                    <DialogContent>
                        <DialogContentText>
                            You are about to delete the lane with the ID: "{laneId}". Are you sure?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button variant={'text'} onClick={() => setIsDeleteWarningOpen(false)}>
                            Cancel
                        </Button>

                        {isDeleting ? <CircularPreloader /> : <Button onClick={handleDelete}>Accept</Button>}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

LaneActions.propTypes = {
    mapId: PropTypes.string,
    laneId: PropTypes.string,
    additionalActions: PropTypes.node,
    showResolve: PropTypes.bool,
};
