import React, { useState } from 'react';
import styled from 'styled-components';
import BuildingDetailsCard from './BuildingsDetailsCard';
import ActionsToolbar from '../common/ActionsToolbar';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { MdClose } from 'react-icons/md';
import useSpaces from '../../common/hooks/data-fetching/useSpaces';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';
import { Skeleton } from '../../common/themed';
import BuildingEditor from './BuildingEditor';
import { fetchAllSpaces } from '../../../state-management/space/spaceActions';
import useAllBuildingsInAllSpaces from '../../common/hooks/data-fetching/useAllBuildingsInAllSpaces';

const DialogCloseButtonWrapper = styled(IconButton)`
    top: 8px;
    right: 8px;
    color: #9e9e9e;
    position: absolute;
`;

export default function BuildingsManagement() {
    const [filter, setFilter] = useState('');
    const [isNewSpaceDialogOpen, setIsNewSpaceDialogOpen] = useState(false);

    const dispatch = useDispatch();

    const { data: buildings, isLoading, hasError } = useAllBuildingsInAllSpaces();

    if (hasError) {
        return <ErrorGeneral />;
    }

    return (
        <>
            <ActionsToolbar
                onRefresh={() => dispatch(fetchAllSpaces())}
                filter={filter}
                onFilterChange={setFilter}
            />

            {isLoading
                ? Array.from({ length: 10 }, (_, index) => (
                      <Skeleton
                          key={`building-card-skeleton-${index}`}
                          height={70}
                          style={{ marginBlockEnd: 2 }}
                      />
                  ))
                : buildings
                      ?.filter(
                          (building) =>
                              building.buildingName.toLowerCase().includes(filter.toLowerCase()) ||
                              building.buildingId.toLowerCase().includes(filter.toLowerCase())
                      )
                      ?.sort((a, b) => a.buildingName.localeCompare(b.buildingName))
                      ?.map((building) => (
                          <BuildingDetailsCard key={building.buildingId} building={building} />
                      ))}

            <Dialog
                open={isNewSpaceDialogOpen}
                maxWidth={'lg'}
                fullWidth
                onClose={() => setIsNewSpaceDialogOpen(false)}
            >
                <DialogContent>
                    <DialogCloseButtonWrapper onClick={() => setIsNewSpaceDialogOpen(false)}>
                        <MdClose />
                    </DialogCloseButtonWrapper>

                    <BuildingEditor onFormSubmitted={() => setIsNewSpaceDialogOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    );
}
