import Api from './Api';
import http from '../utils/httpClient';

class RegionPlacementsApi extends Api {
    async createRegionInternalPlacement(regionId, mapId, placementData) {
        return await this.callApi((params) =>
            http.v2.post(`/regions/${regionId}/attach-to-map`, { ...placementData, mapId }, { params })
        );
    }

    async updateRegionInternalPlacement(buildingId, floorId, mapId, regionId, placementData) {
        return await this.callApi((params) =>
            http.v4.post(
                `buildings/${buildingId}/floors/${floorId}/maps/${mapId}/regions/${regionId}`,
                placementData,
                { params }
            )
        );
    }

    async deleteRegionInternalPlacement(regionId, mapId) {
        return await this.callApi((params) =>
            http.v2.post(`/regions/${regionId}/detach-from-map`, { mapId }, { params })
        );
    }

    async createRegionExternalPlacement(buildingId, regionId, placementData) {
        return await this.callApi((params) =>
            http.v5.post(
                `/regions/${regionId}/attach-to-building`,
                { ...placementData, buildingId },
                { params }
            )
        );
    }

    async updateRegionExternalPlacement(regionId, placementId, placementData) {
        return await this.callApi((params) =>
            http.v5.patch(`/regions/${regionId}/external-placements/${placementId}`, placementData, {
                params,
            })
        );
    }

    async deleteRegionExternalPlacement(regionId, placementId) {
        return await this.callApi((params) =>
            http.v5.post(`/regions/${regionId}/detach-from-building`, { placementId }, { params })
        );
    }

    async attachRegionExternalPlacementToEntrance(regionId, placementId, entranceId) {
        return await this.callApi((params) =>
            http.v5.post(
                `/regions/${regionId}/external-placements/${placementId}/entrances/${entranceId}`,
                {},
                { params }
            )
        );
    }

    async detachRegionExternalPlacementFromEntrance(regionId, placementId, entranceId) {
        return await this.callApi((params) =>
            http.v5.delete(
                `/regions/${regionId}/external-placements/${placementId}/entrances/${entranceId}`,
                { params }
            )
        );
    }
}

export default new RegionPlacementsApi();
