import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './shiftManagementEntityAdapter';
import {
    fetchShiftManagementData,
    activeHelpRequestCreated,
    activeHelpRequestAcknowledgeCreated,
    thresholdToggledCreated,
    isFetchedCreated,
} from './shiftManagementActions';
import timezoneUtils from '../../utils/timezoneUtils';

const { toDuration } = timezoneUtils;

const { getInitialState, setAll, upsertOne, getSelectors } = entityAdapter;

export const { reducer } = createSlice({
    name: 'shiftManagement',
    initialState: getInitialState({ isFetched: false, threshold: true, activeHelpRequests: {} }),
    reducers: {},
    extraReducers: {
        [fetchShiftManagementData.fulfilled]: (state, action) => {
            setAll(
                state,
                action.payload.map((map, i) => {
                    const {
                        mapId,
                        mapName,
                        mappedDistance = {},
                        isRedLanes = {},
                        lastNewLaneAdded = {},
                        calibrationData,
                    } = map;

                    const mapperName = lastNewLaneAdded?.mapperName || 'N/A';
                    const lastLane = {
                        formattedValue: lastNewLaneAdded?.createdAt
                            ? timezoneUtils.toDuration(lastNewLaneAdded?.createdAt, new Date())
                            : 'N/A',
                        value: lastNewLaneAdded?.createdAt
                            ? timezoneUtils.convertToTimeStamp(lastNewLaneAdded?.createdAt)
                            : timezoneUtils.convertToTimeStamp(),
                    };

                    const redLanes = {
                        formattedValue: isRedLanes?.isRed
                            ? timezoneUtils.toDuration(isRedLanes?.lastUpdate, new Date())
                            : 'No',
                        value: isRedLanes?.isRed
                            ? timezoneUtils.convertToTimeStamp(lastNewLaneAdded?.createdAt)
                            : timezoneUtils.convertToTimeStamp(),
                    };

                    const callForHelpUnaswered = state.activeHelpRequests[mapId]
                        ? toDuration(state.activeHelpRequests[mapId]?.createdAt, new Date(), true)
                        : '';

                    return {
                        mapId,
                        mapName,
                        mapperName,
                        lastLane,
                        totalDistance: mappedDistance?.totalLanesMapped?.toFixed(),
                        lastHourDistance: mappedDistance?.lanesMappedInPastHour?.toFixed(),
                        calibTotalFailed: calibrationData?.totalFailed,
                        calibLastHour: calibrationData?.failedInLastHour,
                        totalRepeatedCalibration: calibrationData?.totalRepeated,
                        lastHourRepeatedCalibrations: calibrationData?.repeatedInLastHour,
                        redLanes,
                        callForHelpUnaswered,
                        supervisorName: '',
                        comments: '',
                        approved: false,
                    };
                })
            );

            state.isFetched = true;
        },
        [activeHelpRequestCreated]: (state, action) => {
            const { mapId } = action.payload;
            const isMapIdExists = getSelectors().selectById(state, mapId);

            if (isMapIdExists) {
                state.activeHelpRequests[mapId] = {
                    createdAt: new Date().toISOString(),
                };

                upsertOne(state, {
                    mapId,
                    callForHelpUnaswered: toDuration(new Date(), new Date(), true),
                });
            }
        },
        [activeHelpRequestAcknowledgeCreated]: (state, action) => {
            const { mapId } = action.payload;
            const isMapIdExists = getSelectors().selectById(state, mapId);

            if (isMapIdExists) {
                upsertOne(state, {
                    mapId: action.payload.mapId,
                    callForHelpUnaswered: '',
                });

                delete state.activeHelpRequests[mapId];
            }
        },
        [thresholdToggledCreated]: (state, action) => {
            state.threshold = action.payload || false;
        },
        [isFetchedCreated]: (state, action) => {
            state.isFetched = false;
        },
    },
});
