import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { MapViewerContext } from '../../MapViewerContext';
import { Portal, Snackbar as MuiSnackbar, SnackbarContent, Typography } from '@material-ui/core';
import { Button, CircularPreloader } from '../../../themed';
import { useDispatch, useSelector } from 'react-redux';
import {
    getMapViewerLinesOfInterestCreationOverlayId,
    getLineOfInterestNewCreation,
    getLineOfInterestCreationInstructionSnackbarId,
} from './MapViewerLinesOfInterestCreationOverlay.selectors';
import {
    BOUNDARY_MAX_X,
    BOUNDARY_MAX_Y,
    BOUNDARY_MIN_X,
    BOUNDARY_MIN_Y,
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../../state-management/notification/notificationReducer';
import { isFulfilled } from '../../../../../state-management/utils';
import { createLineOfInterest } from '../../../../../state-management/mapping/line-of-interest/lineOfInterestActions';
import { selectUserName } from '../../../../../state-management/auth/authSelectors';

const CreationSpace = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
`;

const Line = styled.polyline`
    fill: none;
    stroke: #00CEF1; // TODO color should come from theme
    stroke-width: calc(2.5px / var(${SCALE}));
    stroke-linejoin: round;
    stroke-linecap: round;
    marker-start: url(#loi-marker-dot);
    marker-mid: url(#loi-marker-dot);
    marker-end: url(#loi-marker-arrow);
`;

const Snackbar = styled(MuiSnackbar)`
    position: absolute;
`;

const Instruction = styled(Typography)`
    color: #ffffff; //TODO color should come from theme
`;

const SnackbarButton = styled(Button)`
    color: white;
`;

const INITIAL = 'INITIAL';
const FIRST_POINT_SET = 'FIRST_POINT_SET';
const SECOND_POINT_SET = 'SECOND_POINT_SET';
const ALL_POINTS_SET = 'ALL_POINTS_SET';

export default function MapViewerLineOfInterestCreationOverlay(props) {
    const { mapId, onSubmitted, onCancel } = props;

    const { imageRef, containerRef, getCSSVariable } = useContext(MapViewerContext);
    const mapperName = useSelector(selectUserName)
    const dispatch = useDispatch();

    const [isCreating, setIsCreating] = useState(false);
    const [{ creationStep, points, currentPoint }, setCreation] = useImmer({
        creationStep: INITIAL,
        points: [],
        currentPoint: null,
    });

    const handleReset = (event) => {
        event.stopPropagation();
        setCreation((state) => {
            state.creationStep = INITIAL;
            state.points = [];
            state.currentPoint = null;
        });
    };

    const handleClick = (event) => {
        if (event.button === 0 && creationStep !== ALL_POINTS_SET) {
            const { clientX, clientY } = event;
            const refRect = imageRef.current.getBoundingClientRect();
            const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
            const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
            const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

            setCreation((state) => {
                state.points.push({
                    x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                    y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
                });
                state.currentPoint = null;

                if (state.points.length === 1) {
                    state.creationStep = FIRST_POINT_SET;
                } else if (state.points.length >= 2) {
                    state.creationStep = SECOND_POINT_SET;
                }
            });
        }
    };

    const handleContextMenu = (event) => {
        if (creationStep === SECOND_POINT_SET) {
            event.preventDefault();
            setCreation((state) => {
                state.creationStep = ALL_POINTS_SET;
                state.currentPoint = null;
            });
        }
    };

    const handlePointerMove = useCallback(
        (event) => {
            const { clientX, clientY } = event;

            const refRect = imageRef.current.getBoundingClientRect();
            const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
            const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
            const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

            setCreation((state) => {
                state.currentPoint = {
                    x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                    y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
                };
            });
        },
        [getCSSVariable, imageRef, setCreation]
    );

    const handleCancel = (event) => {
        event.stopPropagation();
        onCancel();
    };

    const handleSubmit = async () => {
        setIsCreating(true);
        const result = await dispatch(createLineOfInterest({ mapId, lineData: { points, mapperName } }));
        setIsCreating(false);

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Line of interest created successfully'));
        } else {
            dispatch(showErrorNotification(`Failed to create line of interest`));
        }

        onSubmitted();
    };

    useEffect(() => {
        if (creationStep === FIRST_POINT_SET || creationStep === SECOND_POINT_SET) {
            window.addEventListener('pointermove', handlePointerMove);

            return () => {
                window.removeEventListener('pointermove', handlePointerMove);
            };
        }
    }, [creationStep, handlePointerMove]);

    const polylinePoints = useMemo(() => {
        const maxX = parseFloat(getCSSVariable(BOUNDARY_MAX_X));
        const minX = parseFloat(getCSSVariable(BOUNDARY_MIN_X));
        const maxY = parseFloat(getCSSVariable(BOUNDARY_MAX_Y));
        const minY = parseFloat(getCSSVariable(BOUNDARY_MIN_Y));

        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        return [...points, currentPoint].reduce((result, point) => {
            // `currentPoint` could be null
            if (!point) {
                return result;
            }

            // Limit the X and Y coordinates to the boundaries of the map image
            const boundedX = Math.min(Math.max(point.x, minX), maxX);
            const boundedY = Math.min(Math.max(point.y, minY), maxY);
            const x = (boundedX - mapOffsetX) * pixelToMeter;
            const y = (imageNaturalHeight / pixelToMeter - (boundedY - mapOffsetY)) * pixelToMeter;

            return [...result, x, y];
        }, []);
    }, [currentPoint, getCSSVariable, points]);

    const instructions = {
        [INITIAL]: <Instruction>Click anywhere on the map to set the first point of the line</Instruction>,
        [FIRST_POINT_SET]: <Instruction>Click once more to set the second point of the line</Instruction>,
        [SECOND_POINT_SET]: (
            <Instruction>
                You can now click to add as many points as you wish. Right-click to stop adding points.
                <br />
                Once you're done, click "Submit" to finish.
            </Instruction>
        ),
        [ALL_POINTS_SET]: (
            <Instruction>
                You can now click to add as many points as you wish. Right-click to finish.
                <br />
                Once you're done, click "Submit" to finish.
            </Instruction>
        ),
    };

    return (
        <CreationSpace
            id={getMapViewerLinesOfInterestCreationOverlayId()}
            onClick={handleClick}
            onContextMenu={handleContextMenu}
        >
            <Portal container={containerRef.current}>
                <Snackbar
                    id={getLineOfInterestCreationInstructionSnackbarId()}
                    open
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <SnackbarContent
                        message={instructions[creationStep]}
                        action={
                            <>
                                <SnackbarButton variant={'text'} onClick={handleCancel}>
                                    Cancel
                                </SnackbarButton>

                                {creationStep !== INITIAL && (
                                    <SnackbarButton variant={'text'} onClick={handleReset}>
                                        Reset
                                    </SnackbarButton>
                                )}

                                {creationStep === ALL_POINTS_SET &&
                                    (isCreating ? (
                                        <CircularPreloader />
                                    ) : (
                                        <SnackbarButton variant={'text'} onClick={handleSubmit}>
                                            Submit
                                        </SnackbarButton>
                                    ))}
                            </>
                        }
                    />
                </Snackbar>
            </Portal>

            <marker
                id={'loi-marker-dot'}
                viewBox="0 0 10 10"
                refX="5"
                refY="5"
                markerWidth="4"
                markerHeight="4"
            >
                <circle cx="5" cy="5" r="5" fill={'#f53700'} />
            </marker>

            <marker
                id={'loi-marker-arrow'}
                viewBox="0 0 10 10"
                refX="5"
                refY="5"
                markerWidth="6"
                markerHeight="6"
                orient="auto-start-reverse"
            >
                <path d="M 0 0 L 10 5 L 0 10 z" fill={'#f53700'} />
            </marker>

            {points?.length >= 1 && <Line id={getLineOfInterestNewCreation()} points={polylinePoints} />}
        </CreationSpace>
    );
}

MapViewerLineOfInterestCreationOverlay.propTypes = {
    mapId: PropTypes.string.isRequired,
    onSubmitted: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
