import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectMappingSelectedMapId,
    setIsMapCroppingCreationOpen,
    selectMapCroppingCreationOpen,
    selectSignMarkCreationOpen,
    setIsSignMarkCreationOpen,
} from '../../../state-management/user-inputs/mappingSlice';
import MapViewerSignMarkCreationOverlay from '../../common/map-viewer/overlays/sign-mark-creation';

export const SignMarkCreationOverlay = () => {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const isMapCreationOpen = useSelector(selectSignMarkCreationOpen);
    return (
        isMapCreationOpen && (
            <MapViewerSignMarkCreationOverlay
                mapId={selectedMapId}
                onCancel={() => dispatch(setIsSignMarkCreationOpen(false))}
                onSubmitted={() => dispatch(setIsSignMarkCreationOpen(false))}
            />
        )
    );
};
