import React from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './state-management/store';
import settings from './clientSettings';
import axios from 'axios';
// import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/integration/react';
import JssProviderWrapper from './components/main-layout/JssProviderWrapper';
import { createGlobalStyle } from 'styled-components';
import Router from './components/main-layout/Router';

// These styles will be applied to the main root div element
const GlobalStyle = createGlobalStyle`
    html, body, #root {
        display: flex;
        margin: 0;
        padding: 0;
        font-family: Roboto, sans-serif;
        height: 100%;
        width: 100%;
        //overflow: hidden;
    }

    a, a:hover, a:focus, a:active {
        text-decoration: none;
        color: inherit;
   }
   
`;

axios.defaults.baseURL = settings.restApiServer;
if (module.hot) {
    module.hot.accept();
}
const theme = createTheme({
    palette: {
        primary: {
            main: '#ff6200',
        },
        secondary: {
            main: '#ff6200',
        },
    },
});

/*
if (process.env.NODE_ENV !== 'production') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        titleColor: 'green',
        diffNameColor: 'aqua',
        trackHooks: true,
        trackExtraHooks: [[require('react-redux/lib'), 'useSelector']],
    });
}*/

// PersistGate delays the rendering of the app's UI until your
// persisted state has been retrieved and saved to redux
const App = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <JssProviderWrapper>
                    <>
                        <GlobalStyle />
                        <Router />
                    </>
                </JssProviderWrapper>
            </ThemeProvider>
        </PersistGate>
    </Provider>
);

export default App;
