import React, { memo, useContext, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Portal, Typography } from '@material-ui/core';
import { MapViewerContext } from '../../MapViewerContext';
import { PulsePreloader } from '../../../themed';
import {
    getLoadingIndicatorsContainerId,
    getMapViewerLoadingIndicatorOverlayClassName,
} from './MapViewerLoadingIndicatorOverlay.selectors';

const Label = styled(Typography)``;

const OverlayWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-block-end: 15px;
    margin-inline-start: 15px;
    pointer-events: none; // Prevent the whole wrapper from capturing pointer events

    ${Label} {
        margin-inline-start: 15px;
    }
`;

function MapViewerLoadingIndicatorOverlay(props) {
    const { label } = props;

    const { containerRef } = useContext(MapViewerContext);

    const [loadingIndicatorsContainer, setLoadingIndicatorsContainer] = useState(null);

    useEffect(() => {
        if (containerRef.current && !loadingIndicatorsContainer) {
            let indicatorsContainer = containerRef.current.querySelector(
                `#${getLoadingIndicatorsContainerId()}`
            );

            if (!indicatorsContainer) {
                indicatorsContainer = document.createElement('div');
                indicatorsContainer.setAttribute('id', getLoadingIndicatorsContainerId());
                indicatorsContainer.style['position'] = 'absolute';
                indicatorsContainer.style['bottom'] = '0';
                indicatorsContainer.style['display'] = 'flex';
                indicatorsContainer.style['flex-direction'] = 'column';
                indicatorsContainer.style['align-items'] = 'flex-start';

                containerRef.current.appendChild(indicatorsContainer);
            }

            setLoadingIndicatorsContainer(indicatorsContainer);
        }
    }, [containerRef, loadingIndicatorsContainer]);

    if (!loadingIndicatorsContainer) {
        return null;
    }

    return (
        <Portal container={loadingIndicatorsContainer}>
            <OverlayWrapper className={getMapViewerLoadingIndicatorOverlayClassName()}>
                <PulsePreloader />
                <Label>{label}</Label>
            </OverlayWrapper>
        </Portal>
    );
}

MapViewerLoadingIndicatorOverlay.propTypes = {
    label: PropTypes.string.isRequired,
};

export default memo(MapViewerLoadingIndicatorOverlay);
