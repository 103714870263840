import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import BuildingsMapViewer from './BuildingsMapViewer';
import BuildingsSideDrawer from './BuildingsSideDrawer';
import EntranceDetailsSideDrawer from './EntranceDetailsSideDrawer';
import ExternalRegionDetailsSideDrawer from './ExternalRegionDetailsSideDrawer';
import {
    getAreaOfInterestCreatedTopic,
    getAreaOfInterestDeletedTopic,
    getAreaOfInterestUpdatedTopic,
    getExitRegionCreatedTopic,
    getExitRegionDeletedTopic,
    getExitRegionUpdatedTopic,
} from '../../constants/eventTopics';
import { selectBuildingsSelectedMapId } from '../../state-management/user-inputs/buildingsSlice';
import { useEventManagerWs } from '../../ws/hooks/useEventManagerWs';

export default function Buildings() {
    const mapId = useSelector(selectBuildingsSelectedMapId);

    const topics = useMemo(() => {
        if (!mapId) {
            return [];
        }

        return [
            getExitRegionCreatedTopic(mapId),
            getExitRegionUpdatedTopic(mapId),
            getExitRegionDeletedTopic(mapId),
        ];
    }, [mapId]);

    useEventManagerWs({ topics });

    return (
        <>
            <BuildingsMapViewer />
            <BuildingsSideDrawer />
            {/* <AreaOfInterestDetailsSideDrawer type='exitRegion' /> */}
            <EntranceDetailsSideDrawer />
            <ExternalRegionDetailsSideDrawer />
        </>
    );
}
