import React from 'react';
import {
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogContentText as MuiDialogContentText,
    IconButton,
} from '@material-ui/core';
import styled from 'styled-components';
import { MdClose as CloseIcon } from 'react-icons/md';

const DialogCloseButtonWrapper = styled(IconButton)`
    top: 8px;
    right: 8px;
    color: #9e9e9e;
    position: absolute;

    svg {
        fill: white; // TODO color should come from theme
    }
`;

// Overriding the default zIndex due to the MUI version upgrade
const style = {
    zIndex: 1500,
};

export const Dialog = ({
    open,
    children,
    closeable = true,
    closeIconStyle = { style: {} },
    onClose,
    ...otherProps
}) => {
    return (
        <MuiDialog style={style} open={open} {...otherProps}>
            {closeable && (
                <DialogCloseButtonWrapper onClick={onClose}>
                    <CloseIcon {...closeIconStyle} />
                </DialogCloseButtonWrapper>
            )}

            {children}
        </MuiDialog>
    );
};

export const DialogTitle = styled(MuiDialogTitle)`
    background-color: #ff6200; // TODO color should come from theme

    h6 {
        color: white;
    }
`;

export const DialogActions = MuiDialogActions;

export const DialogContent = styled(MuiDialogContent)`
    padding: 24px;
`;

export const DialogContentText = MuiDialogContentText;
