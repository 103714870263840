import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Message = styled(Typography)`
    color: #c10000; // TODO color should come from theme
    text-align: center;
    margin-block-start: 10px;
    font-style: italic;
`;

export default function FetchError() {
    return (
        <>
            <Message>An error has occurred.</Message>
            <Message>Please try to refresh the page.</Message>
        </>
    );
}
