import React from 'react';
import SideMenuSection from '../../../common/side-drawer/SideMenuSection';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel } from '@material-ui/core';
import { Switch, SwitchLabel as Label } from '../../../common/themed';
import { selectShowGrid, setShowGrid } from '../../../../state-management/user-inputs/preferencesSlice';
import {
    selectMonitorAreLinesOfInterestShown,
    setAreLinesOfInterestShown,
} from '../../../../state-management/user-inputs/monitorSlice';

export default function PreferencesSideMenuSection() {
    const dispatch = useDispatch();
    const showGrid = useSelector(selectShowGrid);
    const areLinesOfInterestShown = useSelector(selectMonitorAreLinesOfInterestShown);

    return (
        <SideMenuSection title={'Preferences'}>
            <FormControlLabel
                label={<Label>Show lines of interest</Label>}
                control={
                    <Switch
                        checked={areLinesOfInterestShown}
                        onChange={() => dispatch(setAreLinesOfInterestShown(!areLinesOfInterestShown))}
                    />
                }
            />
            <FormControlLabel
                label={<Label>Show grid</Label>}
                control={<Switch checked={showGrid} onChange={() => dispatch(setShowGrid(!showGrid))} />}
            />
        </SideMenuSection>
    );
}
