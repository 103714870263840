import { createAsyncThunk } from '../../utils';
import mappersApi from '../../../api/MappersApi';
import eventsManagerWs from '../../../ws/events-manager/eventsManagerWs';

export const fetchAllMappersInMap = createAsyncThunk('mappers/fetchAllInMap', async (mapId) => {
    return (await mappersApi.fetchAllMappersInMap(mapId)).data;
});

export const updateMapper = createAsyncThunk('mappers/update', async ({ mapId, mapperId, mapperData }) => {
    return (await mappersApi.updateMapper(mapId, mapperId, mapperData)).data;
});

export const toggleSuperMapper = createAsyncThunk(
    'mappers/toggleSuperMapper',
    async ({ mapId, mapperId, isSuperMapper }, { getState }) => {
        const response = (await mappersApi.updateMapper(mapId, mapperId, { isSuperMapper })).data;
        await eventsManagerWs.publish(
            `v1.mapping.${mapId}.mapper.${mapperId}.super-mapper-toggled`,
            { mapId, mapperId },
            { isSuperMapper },
            true
        );
        return response;
    }
);

let toggleSuspendMapperInterval;
export const toggleSuspendMapper = createAsyncThunk(
    'mappers/toggleSuspendMapper',
    async ({ mapId, mapperId, isMapperSuspended }, { getState }) => {
        if (isMapperSuspended && !toggleSuspendMapperInterval) {
            await eventsManagerWs.publish(
                `v1.mapping.${mapId}.mapper.${mapperId}.mapper-suspended`,
                { mapId, mapperId },
                { isMapperSuspended },
                true
            );
            toggleSuspendMapperInterval = setInterval(async () => {
                await eventsManagerWs.publish(
                    `v1.mapping.${mapId}.mapper.${mapperId}.mapper-suspended`,
                    { mapId, mapperId },
                    { isMapperSuspended },
                    true
                );
            }, 5000);
        } else if (!isMapperSuspended && toggleSuspendMapperInterval) {
            clearInterval(toggleSuspendMapperInterval);
            toggleSuspendMapperInterval = null;
        }
        return { isMapperSuspended };
    }
);
