import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MapViewerLoadingIndicatorOverlay from '../../common/map-viewer/overlays/loading-indicators';
import {
    selectMappingAreSignMarksShown,
    selectMappingSignMarkEditor,
    selectMappingSelectedMapId, selectMappingSignMarkIds, setSelectedSignMarkIds,
    closeSignMarkEditor,
    sendSignMarkToBack,
    selectMappingSignMarkIdsShownInFront,
    selectMappingSignMarkIdsShownInBack,
} from '../../../state-management/user-inputs/mappingSlice';
import { useSignMarks } from '../../common/hooks/data-fetching/useSignMarks';
import { deleteSignMark, updateSignMark } from '../../../state-management/mapping/sign-mark/signMarkActions';
import MapViewerSignMarkOverlay from '../../common/map-viewer/overlays/sign-mark';
import SignMarkEditorDialog from '../dialogs/sign-mark-editor';
import { selectCanAccessSignMark } from '../../../state-management/auth/authSelectors';
import { MapViewerContext } from '../../common/map-viewer/MapViewerContext';

export const SignMarksOverlay = ({ buildingId, floorId, mapId, readOnly, disabled }) => {
    const dispatch = useDispatch();

    const { data: signMarks = [], isLoading, hasError } = useSignMarks({ mapId });
    const selectedSignMarkIds = useSelector(selectMappingSignMarkIds)
    const showSignMarks = useSelector(selectMappingAreSignMarksShown);
    const signMarkIdsShownInBack = useSelector(selectMappingSignMarkIdsShownInBack)
    const signMarkIdsShownInFront = useSelector(selectMappingSignMarkIdsShownInFront)
    const canAccessSignMarks = useSelector(selectCanAccessSignMark)
    const { isOpen: isSignMarkEditorOpen, signMarkId: signMarkEditorId } = useSelector(selectMappingSignMarkEditor)
    const { MapViewerOptionsRef } = useContext(MapViewerContext);

    const signMarksToDisplay = useMemo(
        () =>
            signMarks.sort((s1, s2) => {
                const l1BackIndex = signMarkIdsShownInBack.indexOf(s1.signMarkId);
                const l2BackIndex = signMarkIdsShownInBack.indexOf(s2.signMarkId);
                const l1FrontIndex = signMarkIdsShownInFront.indexOf(s1.signMarkId);
                const l2FrontIndex = signMarkIdsShownInFront.indexOf(s2.signMarkId);

                // Assuming there won't be more than 100000 areas, give a big weight for being in the back or front.
                // Based on this calculation, if l1 and l2 are both in back/front, they win by their indexes.
                // If neither is in the back or front, their order doesn't matter.
                const l1TotalIndex = -100000 * (l1BackIndex + 1) + 100000 * (l1FrontIndex + 1);
                const l2TotalIndex = -100000 * (l2BackIndex + 1) + 100000 * (l2FrontIndex + 1);

                return l1TotalIndex - l2TotalIndex;
            }),
        [signMarkIdsShownInBack, signMarkIdsShownInFront, signMarks]
    );


    const handleSendToBack = (signMarkId) => {
        dispatch(sendSignMarkToBack(signMarkId));
    }

    const handleClick = (signMarkId) => {
        const isSelected = selectedSignMarkIds.includes(signMarkId);
        if (!isSelected) {
            dispatch(setSelectedSignMarkIds([signMarkId]))
        }
    }
    const handleSave = async ({ signMarkId, updatedSignMark }) => {
        await dispatch(updateSignMark({ buildingId, floorId, mapId, signMarkId, updatedSignMark }));
    };

    const handleCancel = () => {
        dispatch(setSelectedSignMarkIds([]));
    };

    const handleDelete = async (signMarkId) => {
        await dispatch(deleteSignMark({ buildingId, floorId, mapId, signMarkId }));
    };

    const onSignMarkEditorClose = () => {
        MapViewerOptionsRef.current.disablePanning = false;
        dispatch(closeSignMarkEditor());
    }

    if (!showSignMarks || !canAccessSignMarks) {
        return null;
    }

    return (
        <>
            {isSignMarkEditorOpen && (
                <SignMarkEditorDialog
                    signMarkId={signMarkEditorId}
                    onClose={onSignMarkEditorClose}
                />
            )}
            <MapViewerSignMarkOverlay
                mapId={mapId}
                signMarks={signMarksToDisplay}
                onClick={handleClick}
                onSave={handleSave}
                onCancel={handleCancel}
                onDelete={handleDelete}
                onSendToBack={handleSendToBack}
                selectedSignMarkIds={selectedSignMarkIds}
                readOnly={readOnly}
                disabled={disabled}
            />
            {isLoading && <MapViewerLoadingIndicatorOverlay label={'Loading areas of interest...'} />}
        </>
    );

};
