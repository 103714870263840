import { createSelector } from '@reduxjs/toolkit';
import { permissionNames, permissions } from '../../constants/permissions';

export const selectIsLoggedIn = (state) => !!state.auth.accessToken;
export const selectHasUserData = (state) =>
    !!state.auth.apiKeyId &&
    !!state.auth.username &&
    !!state.auth.permissions &&
    !!state.auth.spaceIds &&
    !!state.auth.userId &&
    'hasAcceptedLatestTermsAndConditions' in state.auth &&
    'needsToAcceptLatestTermsAndConditions' in state.auth &&
    'isSuspended' in state.auth;

export const selectAccessToken = (state) => state.auth.accessToken;
export const selectAccessTokenExpiry = (state) => state.auth.accessTokenExpiry;
export const selectHasAcceptedLatestTermsAndConditions = (state) =>
    !!state.auth.hasAcceptedLatestTermsAndConditions;
export const selectNeedsToAcceptLatestTermsAndConditions = (state) =>
    !!state.auth.needsToAcceptLatestTermsAndConditions;
export const selectIsSuspended = (state) => !!state.auth.isSuspended;
export const selectUserApiKeyId = (state) => state.auth.apiKeyId;
export const selectUserName = (state) => state.auth.userName;
export const selectUserPermissions = (state) => state.auth.permissions;
export const selectUserSpaceIds = (state) => state.auth.spaceIds;
export const selectLastLoggedUserApiKeyId = (state) => state.auth.lastLoggedUserApiKeyId;
export const selectUserId = (state) => state.auth.userId;
export const selectSessionExpirationTime = (state) => state.auth.sessionExpirationTime;
export const selectHasLoggedOut = (state) => !!state.auth.hasLoggedOut;

const {
    READ_REGIONS,
    EDIT_REGIONS,
    READ_TRIGGERS,
    EDIT_TRIGGERS,
    READ_LOCATION_TAGS,
    EDIT_LOCATION_TAGS,
    READ_BUILDINGS,
    EDIT_BUILDINGS,
    MONITORING,
    EDIT_API_KEYS,
    EDIT_SPACES,
    CREATE_AM,
    READ_ANALYTICS_USER_ACTIVITY,
    READ_ANALYTICS_BUILDING_USAGE,
    READ_KNOWLEDGE_BASE_REAL_TIME,
    READ_KNOWLEDGE_BASE_TRACKING,
    READ_KNOWLEDGE_BASE_MAPPING,
    EDIT_LANES,
    SUPERVISE_MAPPING,
    ACCESS_SHIFT_MANAGEMENT,
    ACCESS_PLAI_SERVICE,
    CROP_LANE,
    ACCESS_INTERNAL_PLAI_SERVICE,
    CONTINUOUS_MAPPING,
    REGENERATE_MAPS,
    ACCESS_MAP_QUALITY,
    PUBLISH_GRID,
    RESET_MAP_TO_MANUAL,
    ENABLE_MULTI_SELECT,
    TRIGGER_POSITION_ANALYTICS_PROCESSING,
    READ_SIGN_MARKS,
    EDIT_SIGN_MARKS,
} = permissionNames;

/**
 * Receives an array of strings or a single string, where each string is a permission name,
 * and returns whether or not the currently signed in user has all those permissions.
 * @param userPermissions
 * @param neededPermissions
 */
const hasAllPermissions = (userPermissions, ...neededPermissions) => {
    if (!userPermissions || !Array.isArray(userPermissions)) {
        return false;
    }

    return neededPermissions.every((perm) => userPermissions.includes(perm));
};

/**
 * Receives an array of strings or a single string, where each string is a permission name,
 * and returns whether or not the currently signed in user has at least one of those permissions.
 * @param userPermissions
 * @param neededPermissions
 */
const hasOneOfPermissions = (userPermissions, ...neededPermissions) => {
    if (!userPermissions || !Array.isArray(userPermissions)) {
        return false;
    }

    return neededPermissions.some((perm) => userPermissions.includes(perm));
};

export const selectCanAccessMapContent = createSelector(
    selectUserPermissions,
    (userPermissions) =>
        hasOneOfPermissions(
            userPermissions,
            READ_REGIONS,
            EDIT_REGIONS,
            READ_TRIGGERS,
            EDIT_TRIGGERS,
            READ_LOCATION_TAGS,
            EDIT_LOCATION_TAGS
        ) && hasAllPermissions(userPermissions, READ_BUILDINGS)
);

export const selectCanReadRegions = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, READ_REGIONS)
);

export const selectCanEditRegions = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, EDIT_REGIONS)
);

export const selectCanReadTriggers = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, READ_TRIGGERS)
);

export const selectCanEditTriggers = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, EDIT_TRIGGERS)
);

export const selectCanAccessBuildings = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, READ_BUILDINGS)
);

export const selectCanReadBuildings = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, READ_BUILDINGS)
);

export const selectCanEditBuildings = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, EDIT_BUILDINGS)
);

export const selectCanAccessMonitor = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, MONITORING, READ_BUILDINGS)
);

export const selectCanAccessMapping = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, SUPERVISE_MAPPING, READ_BUILDINGS)
);

export const selectCanAccessAccountManagement = createSelector(selectUserPermissions, (userPermissions) =>
    hasOneOfPermissions(userPermissions, EDIT_API_KEYS, EDIT_SPACES)
);

export const selectCanAccessAccountManagementApiKeys = createSelector(
    selectUserPermissions,
    (userPermissions) => hasAllPermissions(userPermissions, EDIT_API_KEYS)
);

export const selectCanAccessAccountManagementSpaces = createSelector(
    selectUserPermissions,
    (userPermissions) => hasAllPermissions(userPermissions, EDIT_SPACES)
);

export const selectCanAccessAnalytics = createSelector(selectUserPermissions, (userPermissions) =>
    hasOneOfPermissions(userPermissions, READ_ANALYTICS_USER_ACTIVITY, READ_ANALYTICS_BUILDING_USAGE)
);

export const selectCanAccessAnalyticsUserActivity = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, READ_ANALYTICS_USER_ACTIVITY)
);

export const selectCanAccessAnalyticsBuildingUsage = createSelector(
    selectUserPermissions,
    (userPermissions) => hasAllPermissions(userPermissions, READ_ANALYTICS_BUILDING_USAGE)
);

export const selectCanAccessKnowledgeBase = createSelector(selectUserPermissions, (userPermissions) =>
    hasOneOfPermissions(
        userPermissions,
        READ_KNOWLEDGE_BASE_REAL_TIME,
        READ_KNOWLEDGE_BASE_TRACKING,
        READ_KNOWLEDGE_BASE_MAPPING
    )
);

export const selectIsOmk = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, CREATE_AM)
);

export const selectCanEditApiKeys = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, EDIT_API_KEYS)
);

export const selectCanEditSpaces = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, EDIT_SPACES)
);

export const selectUserPermissionsThatCanBeGranted = createSelector(
    selectUserPermissions,
    (userPermissions) => userPermissions.filter((perm) => !!permissions?.[perm]?.canBeGranted)
);

export const selectCanEditLanes = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, EDIT_LANES)
);

export const selectCanSuperviseMapping = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, SUPERVISE_MAPPING)
);

export const selectCanEditEntrances = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, EDIT_BUILDINGS)
);

export const selectCanEditRegionExternalPlacements = createSelector(
    selectUserPermissions,
    (userPermissions) => hasAllPermissions(userPermissions, EDIT_BUILDINGS)
);

export const selectCanAccessShiftManagement = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, ACCESS_SHIFT_MANAGEMENT)
);

export const selectCanAccessPlai = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, ACCESS_PLAI_SERVICE)
);

export const selectCanCropLane = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, CROP_LANE)
);

export const selectCanAccessInternalPlai = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, ACCESS_INTERNAL_PLAI_SERVICE)
);

export const selectCanAccessContinuousMapping = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, CONTINUOUS_MAPPING)
);

export const selectCanAccessRegenerateMaps = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, REGENERATE_MAPS)
);

export const selectCanAccessMapQuality = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, ACCESS_MAP_QUALITY)
);

export const selectCanPublishMapGrid = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, PUBLISH_GRID)
);

export const selectCanResetMapToManual = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, RESET_MAP_TO_MANUAL)
);

export const selectCanAccessMappingMultiSelect = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, ENABLE_MULTI_SELECT)
);

export const selectCanAccessPositionAnalyticsProcessing = createSelector(
    selectUserPermissions,
    (userPermissions) => hasAllPermissions(userPermissions, TRIGGER_POSITION_ANALYTICS_PROCESSING)
);

export const selectCanAccessSignMark = createSelector(selectUserPermissions, (userPermissions) =>
    hasAllPermissions(userPermissions, READ_SIGN_MARKS)
);

export const selectCanUpsetSignMark = createSelector(selectUserPermissions, (userPermissions) => {
    return hasAllPermissions(userPermissions, EDIT_SIGN_MARKS);
});
