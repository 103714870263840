import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { selectHasError, selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { getSelectLanesInMap } from '../../../../state-management/map/mapSelectors';
import {
    fetchAllLanesInMap,
    fetchAllLaneStatusesInMap,
    fetchLane,
    fetchLaneAttachments,
} from '../../../../state-management/mapping/lane/laneActions';
import { selectLaneById } from '../../../../state-management/mapping/lane/laneSelectors';

/**
 * Hook that fetches lanes to the Redux store and returns either all or one of them, based on the provided options.
 * @param asObject - Return all entities as an object, with the IDs as the keys.
 * @param mapId - Map ID to fetch all entities from.
 * @param laneId - Specific lane ID to return.
 * @param withAttachments - Whether or not to fetch lane attachments
 * @param useEqualizedMap - Whether or not to get junctions based on the equalized form of the map
 * @return {{isLoading: undefined, data: (undefined|*), hasError: undefined}}
 */
export default function useLanes({
    asObject = false,
    mapId,
    laneId,
    withAttachments = false,
    useEqualizedMap = true,
} = {}) {
    const dispatch = useDispatch();

    const actions = useMemo(
        () => [
            ...(!!mapId && !laneId ? [{ type: fetchAllLanesInMap.typePrefix, arg: mapId }] : []),
            ...(!!mapId && !!laneId ? [{ type: fetchLane.typePrefix, arg: { mapId, laneId } }] : []),
            ...(!!mapId && !!laneId && !!withAttachments
                ? [{ type: fetchLaneAttachments.typePrefix, arg: { mapId, laneId } }]
                : []),
        ],
        [laneId, mapId, withAttachments]
    );
    const selectIsLoadingLanes = useMemo(() => selectIsLoading(actions), [actions]);
    const selectHasErrorInLanes = useMemo(() => selectHasError(actions), [actions]);
    const selectLanesInMap = useMemo(() => getSelectLanesInMap(mapId), [mapId]);

    const { asArray: lanesArray, asObject: lanesObject } = useSelector(selectLanesInMap);
    const lane = useSelector((state) => selectLaneById(state, laneId));
    const isLoading = useSelector(selectIsLoadingLanes);
    const hasError = useSelector(selectHasErrorInLanes);

    useEffect(() => {
        // If there is a provided map ID, and we haven't fetched all of its lanes yet,
        // and we are not currently fetching them, fetch them
        if (!!mapId && !laneId && !isLoading && !hasError && (!lanesArray || lanesArray.some((l) => !l))) {
            dispatch(fetchAllLanesInMap(mapId));
        }
    }, [dispatch, isLoading, hasError, lanesArray, mapId, laneId]);

    useEffect(() => {
        // If there is a provided map ID, and we haven't fetched all of its lanes yet,
        // and we are not currently fetching them, fetch them
        if (
            !!mapId &&
            !laneId &&
            !isLoading &&
            !hasError &&
            ((useEqualizedMap && lanesArray?.some((l) => !l?.equalizedStatus)) ||
                (!useEqualizedMap && lanesArray?.some((l) => !l?.unequalizedStatus)))
        ) {
            dispatch(fetchAllLaneStatusesInMap({ mapId, useEqualizedMap }));
        }
    }, [dispatch, isLoading, hasError, lanesArray, mapId, laneId, useEqualizedMap]);

    useEffect(() => {
        // If a single lane needs to be fetched and it hasn't been fetched yet, fetch it
        if (!!mapId && !!laneId && !isLoading && !hasError && !lane) {
            dispatch(fetchLane({ mapId, laneId }));
        }
    }, [dispatch, isLoading, hasError, lane, laneId, mapId]);

    useEffect(() => {
        // If a single lane's attachments are needed and they haven't been fetched yet, fetch them
        if (
            !!mapId &&
            !!laneId &&
            !!withAttachments &&
            !isLoading &&
            !hasError &&
            lane?.hasAttachments &&
            !lane?.attachments
        ) {
            dispatch(fetchLaneAttachments({ mapId, laneId }));
        }
    }, [dispatch, isLoading, hasError, lane, laneId, mapId, withAttachments]);

    return { data: laneId ? lane : asObject ? lanesObject : lanesArray?.filter(Boolean) || [], isLoading, hasError };
}
