import {
    getValidationCoordinatesDeletedTopic,
    getValidationCoordinatesUpdatedTopic,
    getValidationMapUpdatedTopic,
} from '../../../constants/eventTopics';
import { useImmer } from 'use-immer';
import mapValidationsApi from '../../../api/MapValidationsApi';
import { getId } from '../../../utils/mapValidationsUtils';
import MapViewerMapValidationsOverlay from '../../common/map-viewer/overlays/map-validations';
import {
    selectMappingAreMapValidationsShown,
    selectMappingSelectedMapId,
} from '../../../state-management/user-inputs/mappingSlice';
import MapViewerLoadingIndicatorOverlay from '../../common/map-viewer/overlays/loading-indicators';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import eventsManagerWs from '../../../ws/events-manager/eventsManagerWs';

export const MapValidationsOverlay = () => {
    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const areMapValidationsShown = useSelector(selectMappingAreMapValidationsShown);

    const [validations, setValidations] = useImmer([]);
    const [isLoading, setIsLoading] = useState(false);

    // TODO since maps (like ftMitchell in vincetestspace in US-EAST) get up to 50k validation points, we don't save them in Redux due to major performance issue
    useEffect(() => {
        if (areMapValidationsShown) {
            setIsLoading(true);

            mapValidationsApi
                .fetchAllValidationsInMap(selectedMapId, (batch) => {
                    setValidations((validations) => {
                        validations.push(
                            ...batch.map((mv) => ({
                                ...mv,
                                mapValidationId: getId(mv),
                            }))
                        );
                    });
                })
                .then(() => {
                    setIsLoading(false);

                    eventsManagerWs.addEventHandler(
                        getValidationMapUpdatedTopic(),
                        (parameters, data) => {
                            if (parameters.mapId === selectedMapId) {
                                setValidations((validations) => {
                                    validations.splice(0, validations.length);
                                    validations.push(
                                        ...data.map((v) => ({ ...v, mapValidationId: getId(v) }))
                                    );
                                });
                            }
                        },
                        { handleOwnMessages: true }
                    );

                    eventsManagerWs.addEventHandler(
                        getValidationCoordinatesUpdatedTopic(),
                        (parameters, data) => {
                            if (parameters.mapId === selectedMapId) {
                                setValidations((validations) => {
                                    const newValidations = {
                                        ...validations.reduce(
                                            (result, v) => ({ ...result, [v.mapValidationId]: v }),
                                            {}
                                        ),
                                        ...data.reduce(
                                            (result, v) => ({
                                                ...result,
                                                [getId(v)]: { ...v, mapValidationId: getId(v) },
                                            }),
                                            {}
                                        ),
                                    };

                                    validations.splice(0, validations.length);
                                    validations.push(...Object.values(newValidations));
                                });
                            }
                        },
                        { handleOwnMessages: true }
                    );

                    eventsManagerWs.addEventHandler(
                        getValidationCoordinatesDeletedTopic(),
                        (parameters, data) => {
                            if (parameters.mapId === selectedMapId) {
                                const deletedIds = data.map((v) => getId(v));
                                setValidations((validations) =>
                                    validations.filter((v) => !deletedIds.includes(v.mapValidationId))
                                );
                            }
                        },
                        { handleOwnMessages: true }
                    );
                });

            return () => {
                setValidations((validations) => {
                    validations.splice(0, validations.length);
                });
            };
        }
    }, [areMapValidationsShown, selectedMapId, setValidations]);

    return (
        areMapValidationsShown &&
        validations?.length > 0 && (
            <>
                <MapViewerMapValidationsOverlay validations={validations} />
                {isLoading && <MapViewerLoadingIndicatorOverlay label={'Loading map validations...'} />}
            </>
        )
    );
};
