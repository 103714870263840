import React, { useEffect, memo } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import Lane from './Lane';
import { getMapViewerLanesOverlayId } from './MapViewerLanesOverlay.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { selectMappingLaneIds, setSelectedLaneId, setSelectedLaneIds } from '../../../../../state-management/user-inputs/mappingSlice';
import { getLaneListEntryId } from '../../../../mapping/Mapping.selectors';

const OverlayWrapper = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    overflow: initial;
    pointer-events: none; // Prevent the whole SVG from capturing pointer events

    * {
        pointer-events: auto;
    }
`;

function MapViewerLanesOverlay(props) {
    const dispatch = useDispatch();
    const { lanes, applyTransform = true, disabled } = props;

    const selectedLaneIds = useSelector(selectMappingLaneIds);


    useEffect(() => {

    }, [selectedLaneIds]);

    if (lanes.length === 0) {
        return null;
    }

    const handleScrollingToSelectedElement = (selectedIds) => {
        if (selectedIds.length === 1) {
            const [id] = selectedIds;
            const htmlElementId = getLaneListEntryId(id);
            const element = document.getElementById(htmlElementId);
            if (element) {
                element.scrollIntoView()
            }
        }
    }

    const onLaneSelected = (laneId, isShiftPressed) => {
        let updatedLanes;
        const isLaneSelected = selectedLaneIds.includes(laneId);
        if (isShiftPressed) {
            if (isLaneSelected) {
                const filteredLanes = selectedLaneIds.filter(id => id !== laneId);
                return dispatch(setSelectedLaneIds(filteredLanes));
            } else {
                updatedLanes = [...selectedLaneIds, laneId];
            }
        } else {
            updatedLanes = [laneId]
            if (isLaneSelected) {
                updatedLanes = [];
                dispatch(setSelectedLaneId(null))
            } else {
                dispatch(setSelectedLaneId(laneId))
            }
        }
        dispatch(setSelectedLaneIds(updatedLanes));
        handleScrollingToSelectedElement(updatedLanes)
    }

    return (
        <OverlayWrapper id={getMapViewerLanesOverlayId()}>
            {lanes.map((lane) => (
                <Lane key={lane.laneId} lane={lane} applyTransform={applyTransform} onLaneSelected={onLaneSelected} disabled={disabled} isSelected={selectedLaneIds.includes(lane.laneId)} />
            ))}
        </OverlayWrapper>
    );
}

MapViewerLanesOverlay.propTypes = {
    lanes: PropTypes.arrayOf(PropTypes.object).isRequired,
    applyTransform: PropTypes.bool,
};

export default memo(MapViewerLanesOverlay);
