import React, { memo, useContext } from 'react';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Portal } from '@material-ui/core';
import { MapViewerContext } from '../../MapViewerContext';
import { RiErrorWarningFill } from 'react-icons/ri';

const OverlayWrapper = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    margin-block-start: 15px;
    margin-inline-start: 150px;
    pointer-events: none; // Prevent the whole SVG from capturing pointer events
`;

const commonIconStyles = css`
    margin-inline-end: 10px;
    width: 30px;
    height: 30px;
`;

const WarningIcon = styled(RiErrorWarningFill)`
    fill: #e74c3c; // TODO color should come from theme
    ${commonIconStyles}
`;

function MapViewerDeletedLanesWarningOverlay(props) {
    const { hasDeletedLanes = false } = props;

    const { containerRef } = useContext(MapViewerContext);

    return (
        hasDeletedLanes && (
            <Portal container={containerRef.current}>
                <OverlayWrapper>
                    <WarningIcon />
                    Old map with deleted lanes
                </OverlayWrapper>
            </Portal>
        )
    );
}

MapViewerDeletedLanesWarningOverlay.propTypes = {
    hasDeletedLanes: PropTypes.bool,
};

export default memo(MapViewerDeletedLanesWarningOverlay);
