import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { MapViewerContext } from '../../MapViewerContext';
import { Portal } from '@material-ui/core';
import { CircularPreloader } from '../../../themed';
import { useDispatch } from 'react-redux';
import {
    getMapViewerRectangularPlacementCreationOverlayId,
    getRectangularPlacementCreationInstructionSnackbarId,
    getRectangularPlacementNewCreationId,
} from './MapViewerRegionInternalRectangularPlacementCreationOverlay.selectors';
import {
    IMAGE_NATURAL_HEIGHT,
    IMAGE_NATURAL_WIDTH,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../../state-management/notification/notificationReducer';
import { isFulfilled } from '../../../../../state-management/utils';
import {
    InstructionSnackbar,
    InstructionSnackbarButton,
    InstructionSnackbarTextField,
    InstructionText,
} from '../../common/InstructionSnackbar';
import { createRegion } from '../../../../../state-management/region/regionActions';
import { createRegionInternalPlacement } from '../../../../../state-management/region-placement/regionPlacementActions';
import { unwrapResult } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

const minHeight = 0.1;
const minWidth = 0.1;

const CreationSpace = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
`;

const Rectangle = styled.rect`
    fill: #00d0ff; // TODO color should come from theme
    fill-opacity: 0.4;
    stroke: #f53700;
    stroke-width: calc(1.5px / var(${SCALE}));
`;

const INITIAL = 'INITIAL';
const FIRST_CORNER_SET = 'FIRST_CORNER_SET';
const SECOND_CORNER_SET = 'SECOND_CORNER_SET';

export default function MapViewerRegionInternalRectangularPlacementCreationOverlay(props) {
    const { spaceId, mapId, onSubmitted, onCancel } = props;

    const { imageRef, containerRef, getCSSVariable } = useContext(MapViewerContext);

    const dispatch = useDispatch();

    const [isCreating, setIsCreating] = useState(false);
    const [{ creationStep, firstCorner, secondCorner, regionName }, setCreation] = useImmer({
        creationStep: INITIAL,
        firstCorner: null,
        secondCorner: null,
        regionName: `newRegion_${uuid().substring(0, 7)}`,
    });

    const handleNameChange = (event) => {
        const { value } = event.target;
        setCreation((state) => {
            state.regionName = value?.trim() === '' ? null : value;
        });
    };

    const handleReset = (event) => {
        event.stopPropagation();
        setCreation((state) => {
            state.creationStep = INITIAL;
            state.firstCorner = null;
            state.secondCorner = null;
            state.regionName = `newRegion_${uuid().substring(0, 7)}`;
        });
    };

    const handleClick = (event) => {
        const { clientX, clientY } = event;
        const refRect = imageRef.current.getBoundingClientRect();
        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const scale = parseFloat(getCSSVariable(SCALE));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        if (creationStep === INITIAL) {
            setCreation((state) => {
                state.creationStep = FIRST_CORNER_SET;
                state.firstCorner = {
                    x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                    y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
                };
                state.secondCorner = null;
            });
        } else if (creationStep === FIRST_CORNER_SET) {
            setCreation((state) => {
                const secondCornerX = (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX;
                const secondCornerY =
                    (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY;

                state.creationStep = SECOND_CORNER_SET;
                state.secondCorner = {
                    x:
                        secondCornerX >= state.firstCorner.x
                            ? Math.max(secondCornerX, state.firstCorner.x + minWidth)
                            : Math.min(secondCornerX, state.firstCorner.x - minWidth),

                    y:
                        secondCornerY >= state.firstCorner.y
                            ? Math.max(secondCornerY, state.firstCorner.y + minHeight)
                            : Math.min(secondCornerY, state.firstCorner.y - minHeight),
                };
            });
        }
    };

    const handlePointerMove = useCallback(
        (event) => {
            const { clientX, clientY } = event;

            const refRect = imageRef.current.getBoundingClientRect();
            const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
            const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
            const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

            setCreation((state) => {
                if (creationStep === INITIAL) {
                    state.firstCorner = {
                        x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                        y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
                    };
                } else if (creationStep === FIRST_CORNER_SET) {
                    const secondCornerX = (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX;
                    const secondCornerY =
                        (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY;

                    state.secondCorner = {
                        x:
                            secondCornerX >= state.firstCorner.x
                                ? Math.max(secondCornerX, state.firstCorner.x + minWidth)
                                : Math.min(secondCornerX, state.firstCorner.x - minWidth),

                        y:
                            secondCornerY >= state.firstCorner.y
                                ? Math.max(secondCornerY, state.firstCorner.y + minHeight)
                                : Math.min(secondCornerY, state.firstCorner.y - minHeight),
                    };
                }
            });
        },
        [creationStep, getCSSVariable, imageRef, setCreation]
    );

    const handleCancel = (event) => {
        event.stopPropagation();
        onCancel();
    };

    const handleSubmit = async () => {
        let result;

        setIsCreating(true);

        result = await dispatch(createRegion({ spaceId, regionData: { regionName, regionMetadata: '' } }));

        if (isFulfilled(result)) {
            const { regionId } = unwrapResult(result);

            result = await dispatch(
                createRegionInternalPlacement({
                    regionId,
                    mapId,
                    placementData: {
                        regionType: 'rectangular',
                        bottomLeft: [
                            Math.min(firstCorner?.x, secondCorner?.x),
                            Math.min(firstCorner?.y, secondCorner?.y),
                        ],
                        topRight: [
                            Math.max(firstCorner?.x, secondCorner?.x),
                            Math.max(firstCorner?.y, secondCorner?.y),
                        ],
                    },
                })
            );

            if (isFulfilled(result)) {
                dispatch(showSuccessNotification('Region created successfully'));
            } else {
                dispatch(showErrorNotification(`Failed to create region`));
            }
        } else {
            dispatch(showErrorNotification(`Failed to create region`));
        }

        setIsCreating(false);
        onSubmitted();
    };

    useEffect(() => {
        if (creationStep === INITIAL || creationStep === FIRST_CORNER_SET) {
            window.addEventListener('pointermove', handlePointerMove);

            return () => {
                window.removeEventListener('pointermove', handlePointerMove);
            };
        }
    }, [creationStep, handlePointerMove]);

    const boundaries = useMemo(() => {
        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));
        const imageNaturalWidth = parseFloat(getCSSVariable(IMAGE_NATURAL_WIDTH));

        return {
            maxX: imageNaturalWidth / pixelToMeter + mapOffsetX,
            minX: mapOffsetX,
            maxY: imageNaturalHeight / pixelToMeter + mapOffsetY,
            minY: mapOffsetY,
        };
    }, [getCSSVariable]);

    const { x, y, width, height } = useMemo(() => {
        if (!firstCorner || !secondCorner) {
            return { x: 0, y: 0, width: 0, height: 0, bottomLeft: null, topRight: null };
        }

        const { minX, minY, maxX, maxY } = boundaries;

        const bottomLeft = {
            x: Math.min(firstCorner?.x, secondCorner?.x),
            y: Math.min(firstCorner?.y, secondCorner?.y),
        };
        const topRight = {
            x: Math.max(firstCorner?.x, secondCorner?.x),
            y: Math.max(firstCorner?.y, secondCorner?.y),
        };

        // Limit the X and Y coordinates to the boundaries of the map image
        bottomLeft.x = Math.min(Math.max(bottomLeft.x, minX), maxX);
        bottomLeft.y = Math.min(Math.max(bottomLeft.y, minY), maxY);
        topRight.x = Math.min(Math.max(topRight.x, minX), maxX);
        topRight.y = Math.min(Math.max(topRight.y, minY), maxY);

        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        return {
            x: (bottomLeft.x - mapOffsetX) * pixelToMeter,
            y: (imageNaturalHeight / pixelToMeter - (topRight.y - mapOffsetY)) * pixelToMeter,
            width: (topRight.x - bottomLeft.x) * pixelToMeter,
            height: (topRight.y - bottomLeft.y) * pixelToMeter,
        };
    }, [boundaries, firstCorner, getCSSVariable, secondCorner]);

    const instructions = {
        [INITIAL]: (
            <InstructionText>Click anywhere on the map to set the first corner of the region</InstructionText>
        ),
        [FIRST_CORNER_SET]: (
            <InstructionText>Click once more to set the second corner of the region</InstructionText>
        ),
        [SECOND_CORNER_SET]: (
            <>
                <InstructionText>Provide a name for this region:</InstructionText>
                <InstructionSnackbarTextField
                    onChange={handleNameChange}
                    autoComplete={'regionName'}
                    defaultValue={regionName}
                />
            </>
        ),
    };

    return (
        <CreationSpace id={getMapViewerRectangularPlacementCreationOverlayId()} onClick={handleClick}>
            <Portal container={containerRef.current}>
                <InstructionSnackbar
                    id={getRectangularPlacementCreationInstructionSnackbarId()}
                    open
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClick={(e) => e.stopPropagation()}
                    message={instructions[creationStep]}
                    action={
                        <>
                            <InstructionSnackbarButton variant={'text'} onClick={handleCancel}>
                                Cancel
                            </InstructionSnackbarButton>

                            {creationStep !== INITIAL && (
                                <InstructionSnackbarButton variant={'text'} onClick={handleReset}>
                                    Reset
                                </InstructionSnackbarButton>
                            )}

                            {creationStep === SECOND_CORNER_SET &&
                                (isCreating ? (
                                    <CircularPreloader />
                                ) : (
                                    <InstructionSnackbarButton
                                        variant={'text'}
                                        onClick={handleSubmit}
                                        disabled={!regionName}
                                    >
                                        Submit
                                    </InstructionSnackbarButton>
                                ))}
                        </>
                    }
                />
            </Portal>

            {firstCorner && secondCorner && (
                <Rectangle
                    id={getRectangularPlacementNewCreationId()}
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                />
            )}
        </CreationSpace>
    );
}

MapViewerRegionInternalRectangularPlacementCreationOverlay.propTypes = {
    spaceId: PropTypes.string.isRequired,
    mapId: PropTypes.string.isRequired,
    onSubmitted: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
