import { createAsyncThunk } from '../utils';
import triggersApi from '../../api/TriggersApi';

export const fetchAllTriggersInSpace = createAsyncThunk(
    'triggers/fetchAllInSelectedSpace',
    async ({ spaceId = null, withRegionIds = false }) => {
        return (await triggersApi.fetchAllTriggersInSpace(spaceId, withRegionIds)).data;
    }
);

export const createTrigger = createAsyncThunk('triggers/create', async ({ triggerData, spaceId }) => {
    return (await triggersApi.createTrigger(triggerData, spaceId)).data;
});

export const updateTrigger = createAsyncThunk('triggers/update', async ({ triggerId, triggerData }) => {
    return (await triggersApi.updateTrigger(triggerId, triggerData)).data;
});

export const deleteTrigger = createAsyncThunk('triggers/delete', async triggerId => {
    return (await triggersApi.deleteTrigger(triggerId)).data;
});

export const attachTriggerToRegion = createAsyncThunk(
    'triggers/attachToRegion',
    async ({ triggerId, regionId }) => {
        return (await triggersApi.attachTriggerToRegion(triggerId, regionId)).data;
    }
);

export const detachTriggerFromRegion = createAsyncThunk(
    'triggers/detachFromRegion',
    async ({ triggerId, regionId }) => {
        return (await triggersApi.detachTriggerFromRegion(triggerId, regionId)).data;
    }
);
