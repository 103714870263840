import { createSlice } from '@reduxjs/toolkit';
import { deleteBuilding } from '../building/buildingActions';
import { deleteFloor } from '../floor/floorActions';
import { deleteMap } from '../map/mapActions';
import { setSelectedSpaceId } from './commonSlice';
import { wipeAllUserInputs } from './userInputsActions';

const initialState = {
    isSideDrawerOpen: false,
    selectedBuildingId: null,
    selectedFloorId: null,
    selectedMapId: null,
    acceptedAccuracy: 2,
    showLocationAccuracy: true,
    areLinesOfInterestShown: false,
};

export const {
    actions: {
        setIsSideDrawerOpen,
        setSelectedBuildingId,
        setSelectedFloorId,
        setSelectedMapId,
        setAcceptedAccuracy,
        setShowLocationAccuracy,
        setAreLinesOfInterestShown,
    },
    reducer,
} = createSlice({
    name: 'userInputs/monitor',
    initialState,
    reducers: {
        setIsSideDrawerOpen: (state, action) => {
            state.isSideDrawerOpen = action.payload;
        },

        setSelectedBuildingId: (state, action) => {
            state.selectedBuildingId = action.payload;
            state.selectedFloorId = null;
            state.selectedMapId = null;
        },
        setAreLinesOfInterestShown: (state, action) => {
            state.areLinesOfInterestShown = action.payload;
        },
        setSelectedFloorId: (state, action) => {
            state.selectedFloorId = action.payload;
            state.selectedMapId = null;
        },

        setSelectedMapId: (state, action) => {
            state.selectedMapId = action.payload;
        },

        setAcceptedAccuracy: (state, action) => {
            state.acceptedAccuracy = action.payload;
        },

        setShowLocationAccuracy: (state, action) => {
            state.showLocationAccuracy = action.payload;
        },
    },
    extraReducers: {
        [setSelectedSpaceId]: (state) => {
            state.selectedBuildingId = null;
            state.selectedFloorId = null;
            state.selectedMapId = null;
        },

        [deleteBuilding.fulfilled]: (state, action) => {
            if (action.meta.arg === state.selectedBuildingId) {
                state.selectedBuildingId = action.payload;
                state.selectedFloorId = null;
                state.selectedMapId = null;
            }
        },

        [deleteFloor.fulfilled]: (state, action) => {
            if (action.meta.arg.floorId === state.selectedFloorId) {
                state.selectedFloorId = null;
                state.selectedMapId = null;
            }
        },

        [deleteMap.fulfilled]: (state, action) => {
            if (action.meta.arg.mapId === state.selectedMapId) {
                state.selectedMapId = null;
            }
        },

        [wipeAllUserInputs]: () => initialState,
    },
});

export const selectMonitorIsSideDrawerOpen = (state) => state.userInputs.monitor.isSideDrawerOpen;
export const selectMonitorSelectedBuildingId = (state) => state.userInputs.common.selectedBuildingId;
export const selectMonitorSelectedFloorId = (state) => state.userInputs.common.selectedFloorId;
export const selectMonitorSelectedMapId = (state) => state.userInputs.common.selectedMapId;
export const selectMonitorAcceptedAccuracy = (state) => state.userInputs.monitor.acceptedAccuracy;
export const selectMonitorShowLocationAccuracy = (state) => state.userInputs.monitor.showLocationAccuracy;
export const selectMonitorAreLinesOfInterestShown = (state) =>
    state.userInputs.monitor.areLinesOfInterestShown;
