import axios from 'axios';
import settings from '../clientSettings';

const {
    restApiServer,
    dashboardServer,
    knowledgeBaseUrl,
    mappingRestApiUrl,
    authServiceUrl,
    productLocationsApiUrl,
    mapQualityUrl,
    analyticsRestUrl,
} = settings;

/**
 * This class serves as an abstraction to axios as the HTTP client.
 * It also gives convenience methods to sending HTTP methods to v1, v2 and so on.
 */

class HttpClient {
    constructor() {
        this._v1 = axios.create({ baseURL: restApiServer });
        this._v2 = axios.create({
            baseURL: restApiServer.replace('v1', 'v2'),
        });
        this._v3 = axios.create({
            baseURL: restApiServer.replace('v1', 'v3'),
        });
        this._v4 = axios.create({
            baseURL: restApiServer.replace('v1', 'v4'),
        });
        this._v5 = axios.create({
            baseURL: restApiServer.replace('v1', 'v5'),
        });
        this._v6 = axios.create({
            baseURL: restApiServer.replace('v1', 'v6'),
        });
        this._v7 = axios.create({
            baseURL: restApiServer.replace('v1', 'v7'),
        });

        this._dashboard = axios.create({ baseURL: `${dashboardServer}/api` });
        this._knowledgeBase = axios.create({ baseURL: knowledgeBaseUrl });
        this.mapping = {
            v1: axios.create({ baseURL: `${mappingRestApiUrl}/api/v1` }),
            v2: axios.create({ baseURL: `${mappingRestApiUrl}/api/v2` }),
        };
        this._auth = { v1: axios.create({ baseURL: `${authServiceUrl}/api/v1` }) };
        this.productLocations = { v1: axios.create({ baseURL: `${productLocationsApiUrl}/api/v1` }) };
        this.mapQuality = { v1: axios.create({ baseURL: `${mapQualityUrl}/api/v1` }) };
        this.analyticsRestApi = { v1: axios.create({ baseURL: `${analyticsRestUrl}/api/v1` }) };
        this.isInitialized = false;
    }

    addAccessToken(accessToken, force = false) {
        if (this.isInitialized && !force) return;
        const extendAxiosInstancesWithHeadersRecursively = (property) => {
            Object.keys(property).forEach((propName) => {
                if (property[propName].defaults) {
                    property[propName].defaults.headers.Authorization = `Bearer ${accessToken?.replaceAll(
                        `"`,
                        ''
                    )}`;
                } else if (typeof property[propName] === 'object') {
                    extendAxiosInstancesWithHeadersRecursively(property[propName]);
                }
            });
        };
        extendAxiosInstancesWithHeadersRecursively(this);
        this.isInitialized = true;
    }

    get v1() {
        return this._v1;
    }

    get v2() {
        return this._v2;
    }

    get v3() {
        return this._v3;
    }

    get v4() {
        return this._v4;
    }

    get v5() {
        return this._v5;
    }
    get v6() {
        return this._v6;
    }
    get v7() {
        return this._v7;
    }
    get dashboard() {
        return this._dashboard;
    }

    get knowledgeBase() {
        return this._knowledgeBase;
    }

    get auth() {
        return this._auth;
    }
}

export default new HttpClient();
