import React, { useEffect, useState } from 'react';
import MapViewer from '../common/map-viewer';
import { useSelector } from 'react-redux';
import {
    selectMonitorAcceptedAccuracy,
    selectMonitorAreLinesOfInterestShown,
    selectMonitorSelectedBuildingId,
    selectMonitorSelectedFloorId,
    selectMonitorSelectedMapId,
    selectMonitorShowLocationAccuracy,
} from '../../state-management/user-inputs/monitorSlice';
import MapViewerLiveMonitorOverlay from '../common/map-viewer/overlays/live-monitor';
import MapViewerWebSocketStatusOverlay from '../common/map-viewer/overlays/websocket-status';
import realTimeServerWs from '../../ws/real-time-server/realTimeServerWs';
import { selectAllMonitorClients } from '../../state-management/monitor/client/monitorClientSelectors';
import { AreasOfInterestOverlay } from '../mapping/overlays/AreasOfInterestOverlay';
import { SignMarksOverlay } from '../mapping/overlays/SignMarkOverlay';
import { LinesOfInterestOverlay } from '../mapping/overlays/LinesOfInterestOverlay';

function LiveMonitorOverlay() {
    const clients = useSelector(selectAllMonitorClients);
    const acceptedAccuracy = useSelector(selectMonitorAcceptedAccuracy);
    const showLocationAccuracy = useSelector(selectMonitorShowLocationAccuracy);

    return (
        <MapViewerLiveMonitorOverlay
            clients={clients}
            acceptedAccuracy={acceptedAccuracy}
            showLocationAccuracy={showLocationAccuracy}
        />
    );
}

function WebSocketStatusOverlay() {
    const [readyState, setReadyState] = useState(realTimeServerWs.ws?.readyState ?? WebSocket.CLOSED);

    useEffect(() => {
        realTimeServerWs.setOnReadyStateChange(setReadyState);
    }, []);

    return <MapViewerWebSocketStatusOverlay readyState={readyState} />;
}

export default function MonitorMapViewer() {
    const selectedBuildingId = useSelector(selectMonitorSelectedBuildingId);
    const selectedFloorId = useSelector(selectMonitorSelectedFloorId);
    const selectedMapId = useSelector(selectMonitorSelectedMapId);
    const areLinesOfInterestShown = useSelector(selectMonitorAreLinesOfInterestShown);
    return (
        <MapViewer buildingId={selectedBuildingId} floorId={selectedFloorId} mapId={selectedMapId}>
            <LiveMonitorOverlay />
            <WebSocketStatusOverlay />
            {/*  <AreasOfInterestOverlay readOnly />*/}
            <AreasOfInterestOverlay type="element" readOnly mode="monitor" />
            <LinesOfInterestOverlay areLinesOfInterestShown={areLinesOfInterestShown} isSelectable={false} />
            <SignMarksOverlay
                buildingId={selectedBuildingId}
                floorId={selectedFloorId}
                mapId={selectedMapId}
                disabled={true}
            />
        </MapViewer>
    );
}
