import React, { useEffect, useMemo } from 'react';
import SideMenuSection from '../../../common/side-drawer/SideMenuSection';
import { Select, Skeleton } from '../../../common/themed';
import {
    selectMapContentSelectedBuildingId,
    selectMapContentSelectedFloorId,
    selectMapContentSelectedMapId,
    setSelectedMapId,
} from '../../../../state-management/user-inputs/mapContentSlice';
import { useDispatch, useSelector } from 'react-redux';
import ErrorGeneral from '../../../common/error-pages/ErrorGeneral';
import useAllMapsInFloor from '../../../common/hooks/data-fetching/useAllMapsInFloor';
import { getMapsSelectId } from '../../MapContent.selectors';

export default function MapContentSideMenuMapSection() {
    const dispatch = useDispatch();

    const selectedBuildingId = useSelector(selectMapContentSelectedBuildingId);
    const selectedFloorId = useSelector(selectMapContentSelectedFloorId);
    const selectedMapId = useSelector(selectMapContentSelectedMapId);

    const { data: maps, isLoading, hasError } = useAllMapsInFloor({
        asObject: true,
        buildingId: selectedBuildingId,
        floorId: selectedFloorId,
    });

    const mapIds = useMemo(() => Object.values(maps || {}).map((m) => m.mapId), [maps]);

    const selectedMap = maps?.[selectedMapId];

    const handleMapSelect = (map) => {
        if (map.mapId !== selectedMapId) {
            dispatch(setSelectedMapId(map.mapId));
        }
    };

    useEffect(() => {
        // If the selected floor has only one map, select it by default
        if (!selectedMapId && mapIds.length === 1) {
            dispatch(setSelectedMapId(mapIds[0]));
        }
    }, [selectedMapId, dispatch, mapIds]);

    if (hasError) {
        return <ErrorGeneral />;
    }

    if (!maps && !isLoading) {
        return null;
    }

    return (
        <>
            <SideMenuSection title={'Map'}>
                {isLoading ? (
                    <Skeleton height={30} />
                ) : (
                    <Select
                        id={getMapsSelectId()}
                        options={maps ? Object.values(maps) : []}
                        placeholder={'Select a map...'}
                        getOptionValue={(m) => m.mapId}
                        getOptionLabel={(m) => m.mapName}
                        isDisabled={!maps}
                        onChange={handleMapSelect}
                        value={selectedMap || null}
                    />
                )}
            </SideMenuSection>
        </>
    );
}
