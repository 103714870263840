import { createSlice } from '@reduxjs/toolkit';

export const {
    actions: { showDialog, closeDialog },
    reducer,
} = createSlice({
    name: 'general',
    initialState: {
        dialog: {
            title: '',
            content: '',
            show: false,
            confirmText: 'Confirm',
            onConfirm: null,
            onCancel: null,
        },
    },
    reducers: {
        showDialog: (state, { payload }) => {
            state.dialog.show = true;
            state.dialog.title = payload.title;
            state.dialog.content = payload.content;
            state.dialog.confirmText = payload.confirmText;
            state.dialog.onConfirm = payload.onConfirm;
            state.dialog.onCancel = payload.onCancel;
        },
        closeDialog: (state) => {
            state.dialog.show = false;
        },
    },
});
