import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { detachTriggerFromRegion } from '../../../../state-management/trigger/triggerActions';
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import {
    getRegionEditorAttachedTriggerId,
    getRegionEditorAttachedTriggerNameId,
    getRegionEditorDetachTriggerId,
} from './RegionEditorDialog.selectors';
import { CircularPreloader } from '../../../common/themed';
import { AiOutlineDisconnect as DetachIcon } from 'react-icons/ai';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';

export default function AttachedTrigger({ regionId, triggerId, triggerName }) {
    const dispatch = useDispatch();

    const isDetaching = useSelector(
        selectIsLoading({ type: detachTriggerFromRegion.typePrefix, arg: { triggerId, regionId } })
    );

    const handleDetach = async () => {
        const result = await dispatch(detachTriggerFromRegion({ triggerId, regionId }));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`Trigger was successfully detached from region.`));
        } else {
            dispatch(showErrorNotification(`Failed to detach trigger from region.`));
        }
    };

    return (
        <ListItem id={getRegionEditorAttachedTriggerId(triggerId)}>
            <ListItemText id={getRegionEditorAttachedTriggerNameId(triggerId)} primary={triggerName} />

            <ListItemSecondaryAction>
                {isDetaching ? (
                    <CircularPreloader size={30} />
                ) : (
                    <IconButton
                        id={getRegionEditorDetachTriggerId(triggerId)}
                        aria-label={'Detach'}
                        onClick={handleDetach}
                    >
                        <DetachIcon />
                    </IconButton>
                )}
            </ListItemSecondaryAction>
        </ListItem>
    );
}
