import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectAllBuildingsInSpace } from '../../../../state-management/space/spaceSelectors';
import useSpaces from './useSpaces';
import { fetchAllBuildingsInSpace } from '../../../../state-management/building/buildingActions';
import {
    selectHasError as getSelectHasError,
    selectIsLoading as getSelectIsLoading,
} from '../../../../state-management/status/statusSelectors';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';

/**
 * Hook that fetches all buildings in the provided space ID to the Redux store.
 * If no `spaceId` is provided in the options, the selected space ID will be used by default.
 * @param options
 * @return {{isLoading: *, data: (*), hasError: *}}
 */
export default function useAllBuildingsInSpace(options = {}) {
    const { asObject = false, spaceId: providedSpaceId } = options;

    const allSpaces = useSpaces();

    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const spaceId = providedSpaceId || selectedSpaceId;

    const selectAllBuildings = useMemo(() => getSelectAllBuildingsInSpace(spaceId), [spaceId]);
    const selectIsLoading = useMemo(
        () => getSelectIsLoading({ type: fetchAllBuildingsInSpace.typePrefix, arg: spaceId }),
        [spaceId]
    );
    const selectHasError = useMemo(
        () => getSelectHasError({ type: fetchAllBuildingsInSpace.typePrefix, arg: spaceId }),
        [spaceId]
    );

    const { asArray: buildingsArray, asObject: buildingsObject } = useSelector(selectAllBuildings);
    const isFetching = useSelector(selectIsLoading) || allSpaces.isLoading;
    const hasError = useSelector(selectHasError) || allSpaces.hasError;

    useEffect(() => {
        // If there is a selected space, and we haven't fetched all of its buildings yet,
        // and we are not currently fetching them, fetch them
        if (
            spaceId &&
            !isFetching &&
            (!buildingsArray ||
                buildingsArray.some((building) => !building || building.spaceId !== spaceId)) &&
            !hasError
        ) {
            dispatch(fetchAllBuildingsInSpace(spaceId));
        }
    }, [dispatch, spaceId, isFetching, hasError, buildingsArray]);

    return { data: asObject ? buildingsObject : buildingsArray, isLoading: isFetching, hasError };
}
