import styled from 'styled-components'

export const FlexRow = styled.div`
display: flex;
justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
align-items: ${({ alignItems = 'stretch' }) => alignItems};
width: ${(width = 'auto') => width};
column-gap: ${(props) => props.columnGap && props.columnGap};
row-gap: ${(props) => props.rowGap && props.rowGap};
`

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
    align-items: ${({ alignItems = 'stretch' }) => alignItems};
    width: ${(width = 'auto') => width};

`