import TimePeriod from './TimePeriod';

class SpecificDay extends TimePeriod {
    get label() {
        return 'Specific Day';
    }

    get value() {
        return 'SPECIFIC_DAY';
    }

    getTimeSpan() {
        return { start: null, end: null };
    }

    get isPickable() {
        return true;
    }

    get isRange() {
        return false;
    }
}

export default new SpecificDay();
