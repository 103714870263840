/**
 * This class provides an easy way to create a mapping between
 * strings and colors that is consistent.
 * If a string is given to the map() method a second time, the same
 * color as previous time will be returned, as all mappings are saved.
 */
import * as AmCore from '@amcharts/amcharts4/core';
import { IDLE, MOVING, NON_INTEREST, INACCURATE, UNKNOWN } from './chartConstants';
import { store } from '../../../state-management/store';
import { selectCommonSelectedSpaceId } from '../../../state-management/user-inputs/commonSlice';
import { selectRegionColorsInSpace } from '../../../state-management/space/spaceSelectors';

class ChartColors {
    constructor() {
        this._stringMap = {};

        // Pre-define colors for 'idle'
        this._stringMap[IDLE] = {
            fill: AmCore.color('#c5c4cd'),
            tooltipFill: AmCore.color('#c5c4cd'),
        };

        // Pre-define colors for 'moving'
        const movingFill = new AmCore.RectPattern();
        movingFill.width = 5;
        movingFill.height = 100;
        movingFill.rectWidth = 2;
        movingFill.rectHeight = 30;
        movingFill.backgroundFill = AmCore.color('#ffffff');
        movingFill.backgroundOpacity = 1;
        movingFill.strokeWidth = 0;
        movingFill.fill = AmCore.color('#c5c4cd');

        const movingBarFill = new AmCore.LinePattern();
        movingBarFill.width = 10;
        movingBarFill.height = 5;
        movingBarFill.backgroundFill = AmCore.color('#ffffff');
        movingBarFill.backgroundOpacity = 1;
        movingBarFill.strokeWidth = 3;
        movingBarFill.stroke = AmCore.color('#c5c4cd');
        movingBarFill.rotation = 0;

        this._stringMap[MOVING] = {
            fill: movingFill,
            tooltipFill: AmCore.color('#c5c4cd'),
            barFill: movingBarFill,
            barStroke: movingBarFill,
        };

        // Pre-define colors for 'Inaccurate' regions
        this._stringMap[INACCURATE] = {
            fill: AmCore.color('#ecebeb'),
            labelFill: AmCore.color('#333333'),
        };

        // Pre-define colors for gaps (unknown regions)
        const unknownFill = new AmCore.LinePattern();
        unknownFill.width = 10;
        unknownFill.height = 10;
        unknownFill.backgroundFill = AmCore.color('#ffffff');
        unknownFill.backgroundOpacity = 1;
        unknownFill.strokeWidth = 1;
        unknownFill.stroke = AmCore.color('#787780');
        unknownFill.rotation = 45;
        this._stringMap[UNKNOWN] = {
            fill: unknownFill,
            tooltipFill: AmCore.color('#787780'),
        };

        // Pre-define colors for 'Non-Interest' regions
        this._stringMap[NON_INTEREST] = {
            fill: AmCore.color('#ffffff'),
            tooltipFill: AmCore.color('#333333'),
            labelFill: AmCore.color('#ffffff'),
            stroke: AmCore.color('#cccccc'),
        };
    }

    static isPattern(color) {
        return color && (color instanceof AmCore.RectPattern || color instanceof AmCore.LinePattern);
    }

    // Returns black or white label depending on the background color
    static getPreferredLabelColor(backgroundColor) {
        if (!backgroundColor || ChartColors.isPattern(backgroundColor)) {
            return AmCore.color('#333');
        }

        // return AmCore.color('#333');

        return backgroundColor.replace('#', '0x') > 0xffffff / 2
            ? AmCore.color('#333')
            : AmCore.color('#fff');
    }

    getProperty(string, property, defaultValue) {
        // Fetch the space's region colors from the store
        const selectedSpaceId = selectCommonSelectedSpaceId(store.getState());
        const regionColors = selectRegionColorsInSpace(selectedSpaceId)(store.getState());

        // If its an actual region, its color should be defined in the space from the store
        if (regionColors[string]) {
            // For labels, unless the background color is a pattern, use the preferred
            // label color for that background
            if (property === 'labelFill') {
                return ChartColors.getPreferredLabelColor(regionColors[string]);
            }

            return AmCore.color(regionColors[string]);
        }

        // If it's a line/rect pattern, return a clone of it, otherwise when
        // charts are disposed, these patterns will be disposed as well,
        // which will cause a bug in the next rendering
        if (ChartColors.isPattern(this._stringMap[string][property])) {
            return this._stringMap[string][property].clone();
        }

        return this._stringMap[string][property] || defaultValue || this._stringMap[string].fill;
    }

    getFill(string) {
        return this.getProperty(string, 'fill');
    }

    getTooltipFill(string) {
        return this.getProperty(string, 'tooltipFill');
    }

    getStroke(string) {
        return this.getProperty(string, 'stroke');
    }

    getLabelfill(string) {
        return this.getProperty(string, 'labelFill', AmCore.color('#333333'));
    }

    getBarFill(string) {
        return this.getProperty(string, 'barFill');
    }

    getBarStroke(string) {
        return this.getProperty(string, 'barStroke');
    }
}

export default new ChartColors();
