import { useDispatch, useSelector } from 'react-redux';
import { getSelectAllTriggersInSpace } from '../../../../state-management/space/spaceSelectors';
import {
    selectHasError as getSelectHasError,
    selectIsLoading as getSelectIsLoading,
} from '../../../../state-management/status/statusSelectors';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import { useEffect, useMemo } from 'react';
import { fetchAllTriggersInSpace } from '../../../../state-management/trigger/triggerActions';
import { selectTriggerById } from '../../../../state-management/trigger/triggerSelectors';

/**
 * Hook that fetches all triggers data into the Redux store.
 *
 * @param asObject - Return all entities as an object, with the IDs as the keys.
 * @param spaceId - Space ID to fetch the triggers from. If no `spaceId` is provided, the selected space ID will be used by default.
 * @param triggerId - Specific trigger ID to return.
 * @return {{isLoading, data: *, hasError}}
 */
export default function useTriggers({ asObject = false, spaceId, triggerId } = {}) {
    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    spaceId = spaceId || selectedSpaceId;

    const selectTriggers = useMemo(() => getSelectAllTriggersInSpace(spaceId), [spaceId]);
    const selectIsLoading = useMemo(
        () =>
            getSelectIsLoading({
                type: fetchAllTriggersInSpace.typePrefix,
                arg: { spaceId, withRegionIds: true },
            }),
        [spaceId]
    );

    const selectHasError = useMemo(
        () =>
            getSelectHasError({
                type: fetchAllTriggersInSpace.typePrefix,
                arg: { spaceId, withRegionIds: true },
            }),
        [spaceId]
    );

    const { asArray: triggersArray, asObject: triggersObject } = useSelector(selectTriggers);
    const trigger = useSelector((state) => selectTriggerById(state, triggerId));
    const isFetching = useSelector(selectIsLoading);
    const hasError = useSelector(selectHasError);

    useEffect(() => {
        if (
            spaceId &&
            (!triggersArray || triggersArray.some((trigger) => !trigger)) &&
            !isFetching &&
            !hasError
        ) {
            dispatch(fetchAllTriggersInSpace({ spaceId, withRegionIds: true }));
        }
    }, [dispatch, hasError, isFetching, spaceId, triggersArray]);

    return {
        data: triggerId ? trigger : asObject ? triggersObject : triggersArray,
        isLoading: isFetching,
        hasError,
    };
}
