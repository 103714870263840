import Api from './Api';
import http from '../utils/httpClient';

class MapsApi extends Api {
    async fetchAllMapsInFloor(buildingId, floorId, filter = null) {
        return await this.callApi((params) =>
            http.v3.get(`/buildings/${buildingId}/floors/${floorId}/maps`, { params: { ...params, filter } })
        );
    }

    async fetchMapFullData(buildingId, floorId, mapId, spaceId) {
        let mapArea = { data: { mapArea: 0 } };
        try {
            mapArea = await this.callApi((params) =>
                http.mapping.v1.get(`/maps/${mapId}/map-area`, { params })
            );
        } catch (e) {}

        const mapData = await this.callApi((params) => {
            if (spaceId) {
                params.spaceId = spaceId;
            }
            return http.v3.get(`/buildings/${buildingId}/floors/${floorId}/maps/${mapId}`, { params });
        });
        return { ...mapData.data, mapArea: mapArea.data };
    }

    async createMap(buildingId, floorId, mapData) {
        return await this.callApi((params) =>
            http.v3.post(`/buildings/${buildingId}/floors/${floorId}/maps`, mapData, { params })
        );
    }
    async createMapImage(buildingId, floorId, mapId, mapData) {
        return await this.callApi((params) =>
            http.v3.post(`/buildings/${buildingId}/floors/${floorId}/maps/${mapId}/image`, mapData, {
                params,
            })
        );
    }

    async createMapGrid(mapId, version) {
        if (version === 1) {
            return await this.callApi((params) =>
                http.mapping.v1.post(`/maps/${mapId}/map-grid`, {}, { params })
            );
        }

        if (version === 2) {
            return await this.callApi((params) =>
                http.mapping.v2.post(`/maps/${mapId}/map-grid`, {}, { params })
            );
        }
    }

    async getMapGridMetadata(mapId, filter) {
        const result = await this.callApi((params) => {
            return http.mapping.v1.get(`/maps/${mapId}/map-grid/metadata`, {
                params: {
                    ...params,
                    filter,
                },
            });
        });
        return result;
    }

    async createPublishMapGrid(mapId) {
        const result = await this.callApi((params) => {
            return http.mapping.v1.post(`/maps/${mapId}/map-grid/publish`, {}, { params });
        });
        return result;
    }

    async updateMap(buildingId, floorId, mapId, mapData) {
        return await this.callApi((params) =>
            http.v3.post(`/buildings/${buildingId}/floors/${floorId}/maps/${mapId}`, mapData, {
                params,
            })
        );
    }

    async deleteMap(buildingId, floorId, mapId) {
        return await this.callApi((params) =>
            http.v3.delete(`/buildings/${buildingId}/floors/${floorId}/maps/${mapId}`, { params })
        );
    }
    async deleteMapImage(buildingId, floorId, mapId, imageId) {
        return await this.callApi((params) =>
            http.v3.delete(`/buildings/${buildingId}/floors/${floorId}/maps/${mapId}/image/${imageId}`, {
                params,
            })
        );
    }

    async resetToManualMapping({ buildingId, floorId, mapId }) {
        const result = await this.callApi((params) => {
            return http.v3.post(
                `/buildings/${buildingId}/floors/${floorId}/maps/${mapId}/reset-to-manual-mapping`,
                { buildingId, floorId, mapId },
                {
                    params,
                }
            );
        });
        return result;
    }
}

export default new MapsApi();
