import { createSelector, createSlice } from '@reduxjs/toolkit';
import { deleteBuilding } from '../building/buildingActions';
import { deleteFloor } from '../floor/floorActions';
import { deleteMap } from '../map/mapActions';
import { deleteRegion } from '../region/regionActions';
import { deleteTrigger } from '../trigger/triggerActions';
import { setSelectedSpaceId } from './commonSlice';
import { wipeAllUserInputs } from './userInputsActions';

const initialState = {
    isSideDrawerOpen: true,
    selectedBuildingId: null,
    selectedFloorId: null,
    selectedMapId: null,
    selectedRegionId: null,
    selectedInternalPlacementId: null,
    selectedTriggerId: null,
    highlightedInternalPlacementIds: [],
    regionsFilter: '',
    triggersFilter: '',
    lockRegionsInPlace: true,
    placementIdsShownInBack: [],
    placementIdsShownInFront: [],
    isCreatingRegionInternalRectangularPlacement: false,
    isCreatingRegionInternalCircularPlacement: false,
    isCreatingRegionInternalPolygonPlacement: false,
    regionEditor: { isOpen: false, regionId: null, placementId: null },
    triggerEditor: { isOpen: false, triggerId: null },
    selectedAnchorRegionId: '',
};

export const {
    actions: {
        setIsSideDrawerOpen,
        setSelectedBuildingId,
        setSelectedFloorId,
        setSelectedMapId,
        setSelectedInternalPlacementId,
        setSelectedTriggerId,
        setRegionsFilter,
        setTriggersFilter,
        setLockRegionsInPlace,
        highlightInternalPlacement,
        unhighlightInternalPlacement,
        sendPlacementToBack,
        bringPlacementToFront,
        setIsCreatingRegionInternalRectangularPlacement,
        setIsCreatingRegionInternalCircularPlacement,
        setIsCreatingRegionInternalPolygonPlacement,
        openRegionEditor,
        closeRegionEditor,
        openTriggerEditor,
        closeTriggerEditor,
        setSelectedAnchorRegionId,
    },
    reducer,
} = createSlice({
    name: 'userInputs/mapContent',
    initialState,
    reducers: {
        setIsSideDrawerOpen: (state, action) => {
            state.isSideDrawerOpen = action.payload;
        },

        setSelectedBuildingId: (state, action) => {
            state.selectedBuildingId = action.payload;
            state.selectedFloorId = null;
            state.selectedMapId = null;
            state.isCreatingRegionInternalRectangularPlacement = false;
        },

        setSelectedFloorId: (state, action) => {
            state.selectedFloorId = action.payload;
            state.selectedMapId = null;
            state.isCreatingRegionInternalRectangularPlacement = false;
        },

        setSelectedMapId: (state, action) => {
            state.selectedMapId = action.payload;
            state.isCreatingRegionInternalRectangularPlacement = false;
        },

        setSelectedInternalPlacementId: (state, action) => {
            if (!action.payload?.regionId && !action.payload?.placementId) {
                state.isSelectedInternalPlacementEditable = false;
                state.highlightedInternalPlacementIds = state.highlightedInternalPlacementIds.filter(
                    (id) => id !== state.selectedInternalPlacementId
                );
            } else {
                state.highlightedInternalPlacementIds = [];
            }

            state.selectedRegionId = action.payload?.regionId;
            state.selectedInternalPlacementId = action.payload?.placementId;
        },

        setSelectedTriggerId: (state, action) => {
            state.selectedTriggerId = action.payload;
        },

        setRegionsFilter: (state, action) => {
            state.regionsFilter = action.payload;
        },

        setTriggersFilter: (state, action) => {
            state.triggersFilter = action.payload;
        },

        setLockRegionsInPlace: (state, action) => {
            state.lockRegionsInPlace = action.payload;
        },

        highlightInternalPlacement: (state, action) => {
            state.highlightedInternalPlacementIds = [
                ...new Set([...state.highlightedInternalPlacementIds, action.payload]),
            ];
        },

        unhighlightInternalPlacement: (state, action) => {
            state.highlightedInternalPlacementIds = state.highlightedInternalPlacementIds.filter(
                (id) => id !== action.payload
            );
        },

        sendPlacementToBack: (state, action) => {
            state.placementIdsShownInBack = [
                ...state.placementIdsShownInBack.filter((id) => id !== action.payload),
                action.payload,
            ];
            state.placementIdsShownInFront = state.placementIdsShownInFront.filter(
                (id) => id !== action.payload
            );
        },

        bringPlacementToFront: (state, action) => {
            state.placementIdsShownInFront = [
                ...state.placementIdsShownInFront.filter((id) => id !== action.payload),
                action.payload,
            ];
            state.placementIdsShownInBack = state.placementIdsShownInBack.filter(
                (id) => id !== action.payload
            );
        },

        setIsCreatingRegionInternalRectangularPlacement: (state, action) => {
            state.isCreatingRegionInternalRectangularPlacement = action.payload;
            state.isCreatingRegionInternalCircularPlacement = false;
            state.isCreatingRegionInternalPolygonPlacement = false;
            state.selectedRegionId = null;
            state.selectedInternalPlacementId = null;
            state.isSelectedInternalPlacementEditable = false;
            state.highlightedInternalPlacementIds = [];
            state.isRegionEditorOpen = false;
        },

        setIsCreatingRegionInternalCircularPlacement: (state, action) => {
            state.isCreatingRegionInternalCircularPlacement = action.payload;
            state.isCreatingRegionInternalRectangularPlacement = false;
            state.isCreatingRegionInternalPolygonPlacement = false;
            state.selectedRegionId = null;
            state.selectedInternalPlacementId = null;
            state.isSelectedInternalPlacementEditable = false;
            state.highlightedInternalPlacementIds = [];
            state.isRegionEditorOpen = false;
        },
        setIsCreatingRegionInternalPolygonPlacement: (state, action) => {
            state.isCreatingRegionInternalPolygonPlacement = action.payload;
            state.isCreatingRegionInternalRectangularPlacement = false;
            state.isCreatingRegionInternalCircularPlacement = false;
            state.selectedRegionId = null;
            state.selectedInternalPlacementId = null;
            state.isSelectedInternalPlacementEditable = false;
            state.highlightedInternalPlacementIds = [];
            state.isRegionEditorOpen = false;
        },

        openRegionEditor: (state, action) => {
            state.regionEditor.isOpen = true;
            state.regionEditor.regionId = action.payload.regionId;
            state.regionEditor.placementId = action.payload.placementId;
        },

        closeRegionEditor: (state) => {
            state.regionEditor.isOpen = false;
            state.regionEditor.regionId = null;
            state.regionEditor.placementId = null;
        },

        openTriggerEditor: (state, action) => {
            state.triggerEditor.isOpen = true;
            state.triggerEditor.triggerId = action.payload.isNew ? null : action.payload.triggerId;
        },

        closeTriggerEditor: (state) => {
            state.triggerEditor.isOpen = false;
            state.triggerEditor.triggerId = null;
        },
        setSelectedAnchorRegionId: (state, action) => {
            state.selectedAnchorRegionId = action.payload;
        },
    },
    extraReducers: {
        [setSelectedSpaceId]: (state) => {
            state.selectedBuildingId = null;
            state.selectedFloorId = null;
            state.selectedMapId = null;
            state.selectedRegionId = null;
            state.selectedTriggerId = null;
            state.isCreatingRegionInternalRectangularPlacement = false;
            state.isCreatingRegionInternalCircularPlacement = false;
        },

        [deleteBuilding.fulfilled]: (state, action) => {
            if (action.meta.arg === state.selectedBuildingId) {
                state.selectedBuildingId = action.payload;
                state.selectedFloorId = null;
                state.selectedMapId = null;
                state.selectedRegionId = null;
                state.selectedInternalPlacementId = null;
                state.selectedTriggerId = null;
                state.isCreatingRegionInternalRectangularPlacement = false;
                state.isCreatingRegionInternalCircularPlacement = false;
            }
        },

        [deleteFloor.fulfilled]: (state, action) => {
            if (action.meta.arg.floorId === state.selectedFloorId) {
                state.selectedFloorId = null;
                state.selectedMapId = null;
                state.selectedRegionId = null;
                state.selectedInternalPlacementId = null;
                state.selectedTriggerId = null;
                state.isCreatingRegionInternalRectangularPlacement = false;
                state.isCreatingRegionInternalCircularPlacement = false;
            }
        },

        [deleteMap.fulfilled]: (state, action) => {
            if (action.meta.arg.mapId === state.selectedMapId) {
                state.selectedMapId = null;
                state.selectedRegionId = null;
                state.selectedInternalPlacementId = null;
                state.selectedTriggerId = null;
                state.isCreatingRegionInternalRectangularPlacement = false;
                state.isCreatingRegionInternalCircularPlacement = false;
            }
        },

        [deleteRegion.fulfilled]: (state, action) => {
            if (action.meta.arg === state.selectedRegionId) {
                state.selectedRegionId = null;
                state.selectedInternalPlacementId = null;
                state.isCreatingRegionInternalRectangularPlacement = false;
                state.isCreatingRegionInternalCircularPlacement = false;
                state.isRegionEditorOpen = false;
            }
        },

        [deleteTrigger.fulfilled]: (state, action) => {
            if (action.meta.arg === state.selectedTriggerId) {
                state.selectedTriggerId = null;
            }
        },

        [wipeAllUserInputs]: () => initialState,
    },
});

export const selectMapContentIsSideDrawerOpen = (state) => state.userInputs.mapContent.isSideDrawerOpen;
export const selectMapContentSelectedBuildingId = (state) => state.userInputs.common.selectedBuildingId;
export const selectMapContentSelectedFloorId = (state) => state.userInputs.common.selectedFloorId;
export const selectMapContentSelectedMapId = (state) => state.userInputs.common.selectedMapId;
export const selectMapContentSelectedRegionId = (state) => state.userInputs.mapContent.selectedRegionId;
export const selectMapContentSelectedInternalPlacementId = (state) =>
    state.userInputs.mapContent.selectedInternalPlacementId;
export const selectMapContentSelectedTriggerId = (state) => state.userInputs.mapContent.selectedTriggerId;
export const selectMapContentRegionsFilter = (state) => state.userInputs.mapContent.regionsFilter;
export const selectMapContentTriggersFilter = (state) => state.userInputs.mapContent.triggersFilter;
export const selectMapContentLockRegionsInPlace = (state) => state.userInputs.mapContent.lockRegionsInPlace;
export const selectMapContentHighlightedInternalPlacementIds = (state) =>
    state.userInputs.mapContent.highlightedInternalPlacementIds;
export const selectMapContentPlacementIdsShownInBack = (state) =>
    state.userInputs.mapContent.placementIdsShownInBack;
export const selectMapContentPlacementIdsShownInFront = (state) =>
    state.userInputs.mapContent.placementIdsShownInFront;
export const selectMapContentIsCreatingRegionInternalRectangularPlacement = (state) =>
    state.userInputs.mapContent.isCreatingRegionInternalRectangularPlacement;
export const selectMapContentIsCreatingRegionInternalCircularPlacement = (state) =>
    state.userInputs.mapContent.isCreatingRegionInternalCircularPlacement;
export const selectMapContentIsCreatingRegionInternalPolygonPlacement = (state) =>
    state.userInputs.mapContent.isCreatingRegionInternalPolygonPlacement;
export const selectMapContentIsRegionEditorOpen = (state) => state.userInputs.mapContent.regionEditor.isOpen;
export const selectMapContentRegionEditorRegionId = (state) =>
    state.userInputs.mapContent.regionEditor.regionId;
export const selectMapContentRegionEditorPlacementId = (state) =>
    state.userInputs.mapContent.regionEditor.placementId;
export const selectMapContentIsTriggerEditorOpen = (state) =>
    state.userInputs.mapContent.triggerEditor.isOpen;
export const selectMapContentTriggerEditorTriggerId = (state) =>
    state.userInputs.mapContent.triggerEditor.triggerId;
export const selectMapContentSelectedAnchorRegionId = (state) =>
    state.userInputs.mapContent.selectedAnchorRegionId;

export const getSelectIsPlacementHighlighted = (placementId) =>
    createSelector(selectMapContentHighlightedInternalPlacementIds, (placementIds) =>
        placementIds.includes(placementId)
    );
