import React, { useEffect, useMemo, useState } from 'react';
import SideMenuSection from '../../../common/side-drawer/SideMenuSection';
import { PulsePreloader, Select } from '../../../common/themed';
import {
    selectBuildingsSelectedBuildingId,
    selectBuildingsSelectedFloorId,
    selectBuildingsSelectedMapId,
    setSelectedMapId,
} from '../../../../state-management/user-inputs/buildingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { deleteMap } from '../../../../state-management/map/mapActions';
import useAllMapsInFloor from '../../../common/hooks/data-fetching/useAllMapsInFloor';
import CreateMapDialog from '../../dialogs/create-map';
import { MdEdit as EditIcon } from 'react-icons/md';
import { Collapse, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { isFulfilled } from '../../../../state-management/utils';
import { selectCanEditBuildings } from '../../../../state-management/auth/authSelectors';
import { getMapSelectId } from '../../buildings.selectors';
import FetchError from '../../../common/error-pages/FetchError';
import MapDetailsForm from './MapDetailsForm';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';

const ExpandToggle = styled(IconButton)`
    width: 100%;
    padding: 0;
    border-radius: 0;
    margin-block-start: 10px;
`;

export default function BuildingsSideMenuMapSection() {
    const [isCreateMapDialogOpen, setIsCreateMapDialogOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const dispatch = useDispatch();

    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const selectedFloorId = useSelector(selectBuildingsSelectedFloorId);
    const selectedMapId = useSelector(selectBuildingsSelectedMapId);
    const isDeletingMap = useSelector(selectIsLoading(deleteMap.typePrefix));
    const canEditBuildings = useSelector(selectCanEditBuildings);

    const {
        data: maps,
        isLoading,
        hasError,
    } = useAllMapsInFloor({
        asObject: true,
        buildingId: selectedBuildingId,
        floorId: selectedFloorId,
    });

    const mapIds = useMemo(() => Object.values(maps || {}).map((m) => m.mapId), [maps]);

    const selectedMap = maps?.[selectedMapId];
    const floorHasOnlyOneMap = maps && Object.values(maps).length === 1;

    const handleDelete = async () => {
        const result = await dispatch(
            deleteMap({ buildingId: selectedBuildingId, floorId: selectedFloorId, mapId: selectedMapId })
        );

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Map deleted successfully.'));
        } else {
            dispatch(showErrorNotification(`Failed to delete map`));
        }
    };

    useEffect(() => {
        // If the selected floor has only one map, select it by default
        if (!selectedMapId && mapIds.length === 1) {
            dispatch(setSelectedMapId(mapIds[0]));
        }
    }, [selectedMapId, dispatch, mapIds]);

    if (!maps && !isLoading) {
        return null;
    }

    return (
        <>
            <SideMenuSection
                title={'Map'}
                showAdd={canEditBuildings}
                showDelete={canEditBuildings && !!selectedMapId}
                onAdd={() => setIsCreateMapDialogOpen(true)}
                onDelete={handleDelete}
                isDeleting={isDeletingMap}
                deleteTooltipContent={'Cannot delete the only map in a floor'}
                isDeleteTooltipEnabled={floorHasOnlyOneMap}
                isDeleteDisabled={floorHasOnlyOneMap}
            >
                {hasError ? (
                    <FetchError />
                ) : isLoading ? (
                    <PulsePreloader fullHeight={false} />
                ) : (
                    <>
                        <Select
                            id={getMapSelectId()}
                            options={maps ? Object.values(maps) : []}
                            placeholder={'Select a map...'}
                            getOptionValue={(m) => m.mapId}
                            getOptionLabel={(m) => m.mapName}
                            isDisabled={!maps}
                            onChange={(m) => dispatch(setSelectedMapId(m.mapId))}
                            value={selectedMap || null}
                        />

                        {selectedMap && (
                            <>
                                <ExpandToggle onClick={() => setIsExpanded(!isExpanded)}>
                                    <EditIcon />
                                </ExpandToggle>

                                <Collapse in={isExpanded}>
                                    <MapDetailsForm
                                        buildingId={selectedBuildingId}
                                        floorId={selectedFloorId}
                                        map={selectedMap}
                                    />
                                </Collapse>
                            </>
                        )}
                    </>
                )}
            </SideMenuSection>

            {isCreateMapDialogOpen && (
                <CreateMapDialog
                    onClose={() => setIsCreateMapDialogOpen(false)}
                    buildingId={selectedBuildingId}
                    floorId={selectedFloorId}
                />
            )}
        </>
    );
}
