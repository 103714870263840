import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import ItemsListEntry from '../../../common/side-drawer/ItemsListEntry';
import {
    getSelectIsExternalPlacementHighlighted,
    highlightExternalPlacement,
    selectBuildingsSelectedExternalPlacementId,
    unhighlightExternalPlacement,
} from '../../../../state-management/user-inputs/buildingsSlice';
import {
    getExternalPlacementListEntryId,
    getExternalPlacementListEntryNameId,
} from '../../buildings.selectors';
import ExternalRegionActions from './ExternalRegionActions';
import Tooltip from '../../../common/themed/Tooltip';
import { attachRegionExternalPlacementToEntrance } from '../../../../state-management/region-placement/regionPlacementActions';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { GiEntryDoor as EntranceIcon } from 'react-icons/gi';

const Title = styled(Typography).attrs(() => ({ variant: 'subtitle1', noWrap: true }))`
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 200px;
    margin-inline-end: 5px;
`;

const Subtitle = styled(Typography).attrs(() => ({ variant: 'subtitle2', noWrap: true }))`
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 200px;
    display: flex;
    align-items: center;
    color: #848484;
`;

export default function ExternalRegionListEntry(props) {
    const { region, placement, ...otherProps } = props;
    const { regionId, regionName } = region ?? {};
    const { placementId, entranceIds } = placement ?? {};

    const [isAlreadyAttachedTooltipOpen, setIsAlreadyAttachedTooltipOpen] = useState(false);

    const dispatch = useDispatch();

    const selectIsExternalPlacementHighlighted = useMemo(
        () => getSelectIsExternalPlacementHighlighted(placementId),
        [placementId]
    );

    const selectedPlacementId = useSelector(selectBuildingsSelectedExternalPlacementId);
    const isHighlighted =
        useSelector(selectIsExternalPlacementHighlighted) || selectedPlacementId === placementId;

    const handleDragEnter = (event) => {
        const entranceId = event.dataTransfer.types
            .find((t) => t.startsWith('oriient/entrance_id'))
            ?.replace('oriient/entrance_id:', '');

        // If the external region is already attached to the entrance
        if (entranceIds?.includes(entranceId)) {
            setIsAlreadyAttachedTooltipOpen(true);
        } else {
            dispatch(highlightExternalPlacement(placementId));
        }
    };

    const handleDragLeave = (event) => {
        const entranceId = event.dataTransfer.types
            .find((t) => t.startsWith('oriient/entrance_id'))
            ?.replace('oriient/entrance_id:', '');

        // If the external region is already attached to the entrance
        if (entranceIds?.includes(entranceId)) {
            setIsAlreadyAttachedTooltipOpen(false);
        } else {
            dispatch(unhighlightExternalPlacement(placementId));
        }
    };

    const handleDrop = async (event) => {
        event.persist();
        event.preventDefault();

        const entrance = JSON.parse(event.dataTransfer.getData('oriient/entrance'));
        const { entranceId } = entrance;

        if (entranceIds?.includes(entranceId)) {
            setIsAlreadyAttachedTooltipOpen(false);
        } else {
            const [result] = await Promise.all([
                dispatch(attachRegionExternalPlacementToEntrance({ regionId, placementId, entranceId })),
                dispatch(unhighlightExternalPlacement(placementId)),
            ]);

            if (isFulfilled(result)) {
                await dispatch(showSuccessNotification('Successfully attached external region to entrance.'));
            } else {
                await dispatch(showErrorNotification('Failed to attach external region to entrance.'));
            }
        }
    };

    if (!region || !placement) {
        return null;
    }

    return (
        <Tooltip
            content={'This external region is already attached to this entrance.'}
            visible={isAlreadyAttachedTooltipOpen}
            placement={'left'}
            useWrapper={false}
        >
            <ItemsListEntry
                id={getExternalPlacementListEntryId(placementId)}
                onPointerEnter={() => dispatch(highlightExternalPlacement(placementId))}
                onPointerLeave={() => dispatch(unhighlightExternalPlacement(placementId))}
                acceptableDragDataTypes={['oriient/entrance']}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                isHighlighted={isHighlighted}
                content={
                    <>
                        <Grid container alignItems={'center'}>
                            <Title id={getExternalPlacementListEntryNameId(placementId)}>{regionName}</Title>

                            {entranceIds?.length > 0 && (
                                <Tooltip
                                    content={'This region has attached entrances'}
                                    placement={'bottom'}
                                    useWrapper
                                >
                                    <EntranceIcon size={25} />
                                </Tooltip>
                            )}
                        </Grid>

                        <Subtitle>{placementId}</Subtitle>
                    </>
                }
                actions={
                    <ExternalRegionActions
                        regionId={regionId}
                        placementId={placementId}
                        showEditShape={false}
                    />
                }
                {...otherProps}
            />
        </Tooltip>
    );
}

ExternalRegionListEntry.propTypes = {
    region: PropTypes.object,
    placement: PropTypes.object,
};
