import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { MdDelete as DeleteIcon, MdSave as SaveIcon } from 'react-icons/md';
import styled from 'styled-components';
import { TextField, Button, PulsePreloader } from '../../common/themed';
import CheckListWithSearch from '../common/CheckListWithSearch';
import RemoveConfirmDialog from '../common/RemoveConfirmDialog';
import ChipListWithInsert from '../common/ChipListWithInsert';
import { attachBuildingToSpace, detachBuildingFromSpace } from '../../../state-management/space/spaceActions';
import useAllBuildingsInAllSpaces from '../../common/hooks/data-fetching/useAllBuildingsInAllSpaces';
import { unwrapResult } from '@reduxjs/toolkit';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';
import { Controller, useForm } from 'react-hook-form';
import { isFulfilled } from '../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../state-management/notification/notificationReducer';
import { updateBuilding } from '../../../state-management/building/buildingActions';
import useSpaces from '../../common/hooks/data-fetching/useSpaces';

const EditorWrapper = styled.div`
    display: grid;
    grid-template-rows: 30px 250px;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    margin-block-end: 20px;
`;

const Header = styled(Typography).attrs({
    variant: 'h6',
})`
    margin-bottom: 15px;
`;

export default function BuildingEditor(props) {
    const { building, onFormSubmitted = () => {} } = props;
    const [isRemoveConfirmDialogOpen, setIsRemoveConfirmDialogOpen] = useState(false);
    const { data: spaces, isLoading: isLoadingSpaces, hasError } = useSpaces();
    const { buildingId, buildingName } = building ?? {};
    const spaceIds = useMemo(
        () =>
            spaces.reduce(
                (t, s) => (s.buildingIds.includes(building.buildingId) ? [...t, s.spaceId] : t),
                []
            ),
        [spaces, building]
    );
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        errors,
        control,
        setValue,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: {
            buildingName: buildingName ?? '',
            spaceIds: spaceIds ?? [],
        },
    });

    const submitForm = async (data) => {
        let { spaceIds: newSpacesIds } = data;

        let spacesAttachResult;
        let spacesDetachResult;

        if (newSpacesIds) {
            const oldSpacesIds = spaceIds ?? [];

            const spaceIdsToAttach = newSpacesIds.filter((m) => !oldSpacesIds.includes(m));
            const spaceIdsToDetach = oldSpacesIds.filter((m) => !newSpacesIds.includes(m));

            if (spaceIds.length + spaceIdsToAttach.length - spaceIdsToDetach.length <= 0) {
                dispatch(showErrorNotification(`Building must be attached to 1 space or more`));
                return;
            }
            spacesDetachResult = await Promise.all(
                spaceIdsToDetach.map((spaceId) => dispatch(detachBuildingFromSpace({ spaceId, buildingId })))
            );
            spacesAttachResult = await Promise.all(
                spaceIdsToAttach.map((spaceId) => dispatch(attachBuildingToSpace({ spaceId, buildingId })))
            );
        }

        if (
            (!spacesAttachResult || spacesAttachResult.every(isFulfilled)) &&
            (!spacesDetachResult || spacesDetachResult.every(isFulfilled))
        ) {
            dispatch(showSuccessNotification(`Building has been updated successfully`));
        } else {
            dispatch(showErrorNotification(`Failed to update building`));
        }

        onFormSubmitted();
    };

    useEffect(() => {
        if (spaceIds) {
            setValue('spaceIds', spaceIds);
        }
    }, [spaceIds, setValue]);

    if (hasError) {
        return <ErrorGeneral />;
    }

    return (
        <>
            <form onSubmit={handleSubmit(submitForm)}>
                <EditorWrapper>
                    <Header>Building</Header>
                    <Header>Spaces</Header>

                    <div>
                        <TextField
                            name={'buildingName'}
                            inputRef={register({
                                validate: (value) =>
                                    (value && value.trim() !== '') || 'Please provide a valid building name',
                            })}
                            margin={'normal'}
                            fullWidth
                            disabled
                            label={'Building Name'}
                            error={errors?.buildingName}
                            helperText={errors?.buildingName?.message}
                        />

                        {buildingId && (
                            <TextField
                                label={'Building ID'}
                                margin={'normal'}
                                fullWidth
                                disabled
                                defaultValue={buildingId}
                            />
                        )}
                    </div>

                    <div>
                        <Controller
                            name={'spaceIds'}
                            control={control}
                            render={({ onChange, value }) => (
                                <CheckListWithSearch
                                    isLoading={isLoadingSpaces}
                                    filterLabel={'Filter spaces...'}
                                    onChange={(e) =>
                                        onChange(
                                            e.target.checked
                                                ? [...value, e.target.value]
                                                : value.filter((id) => id !== e.target.value)
                                        )
                                    }
                                    allValues={
                                        buildingId
                                            ? spaces.sort(
                                                  (a, b) =>
                                                      b.buildingIds.includes(buildingId) -
                                                      a.buildingIds.includes(buildingId)
                                              )
                                            : spaces
                                    }
                                    checkedValues={value}
                                    getValueDesc={(s) => s.spaceDesc}
                                    getValueId={(s) => s.spaceId}
                                />
                            )}
                        />
                    </div>
                </EditorWrapper>

                <Grid container spacing={1} justifyContent={'flex-end'}>
                    <Grid item>
                        {isSubmitting ? (
                            <PulsePreloader />
                        ) : (
                            <Button type={'submit'}>
                                <SaveIcon size={20} />
                                Save
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

BuildingEditor.propTypes = {
    building: PropTypes.object,
    onFormSubmitted: PropTypes.func,
};
