import MapViewerAreaOfInterestCreationOverlay from '../../common/map-viewer/overlays/areas-of-interest-creation';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {    setIsAreaOfInterestCreationOpen,selectMappingSelectedMapId,selectMappingIsAreaOfInterestCreationOpen} from '../../../state-management/user-inputs/mappingSlice';

export const  AreaOfInterestCreationOverlay=()=> {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const isAreaOfInterestCreationOpen = useSelector(selectMappingIsAreaOfInterestCreationOpen);

    return (
        isAreaOfInterestCreationOpen && (
            <MapViewerAreaOfInterestCreationOverlay
                mapId={selectedMapId}
                onSubmitted={() => dispatch(setIsAreaOfInterestCreationOpen(false))}
                onCancel={() => dispatch(setIsAreaOfInterestCreationOpen(false))}
            />
        )
    );
        }
        
         