import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './signMarkEntityAdapter';
import { expireSession, logout } from '../../auth/authActions';
import { createSignMark, deleteSignMark, fetchAllSignMarkInMap, updateSignMark } from './signMarkActions';
import { signMarkCreated, signMarkDeleted, signMarkUpdated } from '../mappingEventsActions';
// import { SignMarkCreated, SignMarkDeleted, SignMarkUpdated } from '../mappingEventsActions';

const { getInitialState, upsertOne, setAll, removeOne } = entityAdapter;

const upsertOneSignMark = (state, action) => {
    const { signMarkId, mapId, coordinates, text, metadata, createdAt, lastUpdate } = action.payload.data;
    upsertOne(state, {
        signMarkId: signMarkId || action.meta?.arg?.signMarkId || action.payload?.parameters?.signMarkId,
        mapId,
        coordinates,
        text,
        metadata,
        createdAt,
        lastUpdate,
    });
};

export const { reducer } = createSlice({
    name: 'signMark',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchAllSignMarkInMap.fulfilled]: (state, action) => {
            setAll(
                state,
                action.payload.map(
                    ({ signMarkId, mapId, coordinates, text, metadata, createdAt, lastUpdate }) => ({
                        signMarkId,
                        mapId,
                        coordinates,
                        text,
                        metadata,
                        createdAt,
                        lastUpdate,
                    })
                )
            );
        },

        [createSignMark.fulfilled]: upsertOneSignMark,

        [updateSignMark.fulfilled]: upsertOneSignMark,

        [deleteSignMark.fulfilled]: (state, action) => {
            const { signMarkId } = action.meta.arg;
            removeOne(state, signMarkId);
        },
        [signMarkCreated]: upsertOneSignMark,
        [signMarkUpdated]: upsertOneSignMark,
        [signMarkDeleted]: (state, action) => {
            const signMarkId =
                action.payload?.parameters?.signMarkId ||
                action.payload?.data ||
                action.meta?.arg?.signMarkId;
            removeOne(state, signMarkId);
        },

        // [SignMarkCreated]: (state, action) => {
        //     const { lineId, points, mapperName, createdAt, lastUpdate } = action.payload.data;
        //     upsertOne(state, { lineId, points, mapperName, createdAt, lastUpdate });
        // },

        // [SignMarkUpdated]: (state, action) => {
        //     const { lineId, points, mapperName, createdAt, lastUpdate } = action.payload.data;
        //     upsertOne(state, { lineId, points, mapperName });
        // },

        // [SignMarkDeleted]: (state, action) => {
        //     const { lineId } = action.payload.data;
        //     removeOne(state, lineId);
        // },

        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
    },
});
