import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { MdDelete as DeleteIcon, MdSave as SaveIcon } from 'react-icons/md';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Switch, PulsePreloader, SwitchLabel as Label } from '../../common/themed';
import CheckListWithSearch from '../common/CheckListWithSearch';
import RemoveConfirmDialog from '../common/RemoveConfirmDialog';
import { permissionNames, permissionsThatCanBeGrantedByCategory } from '../../../constants/permissions';
import {
    selectIsOmk,
    selectUserPermissionsThatCanBeGranted,
} from '../../../state-management/auth/authSelectors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    createApiKey,
    deleteApiKey,
    excludeApiKeyFromTermsAcceptance,
    suspendApiKey,
    unexcludeApiKeyFromTermsAcceptance,
    unsuspendApiKey,
    updateApiKey,
} from '../../../state-management/api-key/apiKeyActions';
import { selectAllSpaces } from '../../../state-management/space/spaceSelectors';
import { unwrapResult } from '@reduxjs/toolkit';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../state-management/notification/notificationReducer';
import { isFulfilled } from '../../../state-management/utils';
import { Controller, useForm } from 'react-hook-form';
import useUsers from '../../common/hooks/data-fetching/useUsers';
import UsersList from './UsersList';
import settings from '../../../clientSettings';
import { createUser, deleteUser } from '../../../state-management/users/userActions';
import { ErrorMessage } from '@hookform/error-message';

const { ipsDomainForAuthService } = settings;

const EditorWrapper = styled.div`
    display: grid;
    grid-template-rows: 30px 250px;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 50px;
    margin-block-end: 20px;
`;

const SectionWrapper = styled.div`
    overflow-y: auto;
`;

const Header = styled(Typography).attrs({
    variant: 'h6',
})`
    margin-bottom: 15px;
`;

const permissionsOnByDefaultAlways = [
    permissionNames.READ_SPACES,
    permissionNames.READ_BUILDINGS,
    permissionNames.READ_REGIONS,
    permissionNames.READ_TRIGGERS,
    permissionNames.READ_LOCATION_TAGS,
    permissionNames.READ_SIGN_MARKS
];

const permissionsOnByDefaultIfCanBeGranted = [
    permissionNames.EDIT_REGIONS,
    permissionNames.EDIT_TRIGGERS,
    permissionNames.EDIT_LOCATION_TAGS,
    permissionNames.POSITIONING,
    permissionNames.INVOKE_TRIGGERS,
    permissionNames.MONITORING,
    permissionNames.READ_KNOWLEDGE_BASE_REAL_TIME,
    permissionNames.READ_KNOWLEDGE_BASE_TRACKING,
    permissionNames.ACCESS_DASHBOARD,
    permissionNames.READ_SIGN_MARKS
];

export default function ApiKeyEditor(props) {
    const { apiKey, onFormSubmitted = () => { } } = props;
    const { apiKeyId } = apiKey ?? {};

    const [isRemoveConfirmDialogOpen, setIsRemoveConfirmDialogOpen] = useState(false);

    const dispatch = useDispatch();

    const permissionsThatCanBeGranted = useSelector(selectUserPermissionsThatCanBeGranted);
    const spaces = useSelector(selectAllSpaces);
    const isOmk = useSelector(selectIsOmk);

    const defaultPermissions = [
        ...permissionsOnByDefaultAlways,
        ...permissionsOnByDefaultIfCanBeGranted.filter((p) => permissionsThatCanBeGranted.includes(p)),
    ];

    const { data: apiKeyUsers } = useUsers({ apiKeyId });

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { isSubmitting },
        errors,
    } = useForm({
        defaultValues: {
            apiKeyDesc: apiKey?.apiKeyDesc ?? '',
            spaces: apiKey?.spaces ?? [],
            permissions: apiKey?.permissions ?? defaultPermissions,
            isSuspended: apiKey?.isSuspended ?? false,
            isExcludedFromTermsAcceptance: apiKey?.isExcludedFromTermsAcceptance ?? false,
            users: apiKeyUsers ?? [],
        },
    });

    const submitForm = async (data) => {
        const { isSuspended, isExcludedFromTermsAcceptance, users, apiKeyId: omitted, ...apiKeyData } = data;

        let apiKeyResult;
        let suspendResult;
        let excludeResult;
        let usersCreateResult;
        let usersDeleteResult;

        apiKeyResult = await dispatch(
            apiKeyId ? updateApiKey({ apiKeyId, apiKeyData }) : createApiKey(apiKeyData)
        );
        const newApiKey = unwrapResult(apiKeyResult);

        usersCreateResult = await Promise.all(
            users
                .filter((u) => !!u.needsToCreate)
                .map(({ userName, password }) =>
                    dispatch(
                        createUser({
                            username: userName,
                            password,
                            ipsDomain: ipsDomainForAuthService?.replace('wss://', '')?.replace('ws://', ''),
                            apiKeyId: newApiKey?.apiKeyId,
                        })
                    )
                )
        );

        usersDeleteResult = await Promise.all(
            users.filter((u) => !!u.needsToDelete).map(({ userId }) => dispatch(deleteUser(userId)))
        );

        if (isSuspended !== undefined && isSuspended !== newApiKey?.isSuspended) {
            suspendResult = await dispatch(
                isSuspended ? suspendApiKey(newApiKey.apiKeyId) : unsuspendApiKey(newApiKey.apiKeyId)
            );
        }

        if (
            isExcludedFromTermsAcceptance !== undefined &&
            isExcludedFromTermsAcceptance !== newApiKey?.isExcludedFromTermsAcceptance
        ) {
            excludeResult = await dispatch(
                isExcludedFromTermsAcceptance
                    ? excludeApiKeyFromTermsAcceptance(newApiKey.apiKeyId)
                    : unexcludeApiKeyFromTermsAcceptance(newApiKey.apiKeyId)
            );
        }

        if (
            isFulfilled(apiKeyResult) &&
            (!suspendResult || isFulfilled(suspendResult)) &&
            (!excludeResult || isFulfilled(excludeResult)) &&
            (!usersCreateResult || usersCreateResult.every(isFulfilled)) &&
            (!usersDeleteResult || usersDeleteResult.every(isFulfilled))
        ) {
            dispatch(
                showSuccessNotification(`API key has been ${apiKeyId ? 'updated' : 'created'} successfully.`)
            );
        } else {
            dispatch(showErrorNotification(`Failed to ${apiKeyId ? 'update' : 'create'} API key.`));
        }

        onFormSubmitted();
    };

    const handleDelete = async () => {
        const result = await dispatch(deleteApiKey(apiKeyId));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`API key has been deleted successfully.`));
        } else {
            dispatch(showErrorNotification(`Failed to delete API key`));
        }
    };

    useEffect(() => {
        if (apiKeyUsers) {
            setValue('users', apiKeyId ? apiKeyUsers : []);
        }
    }, [apiKeyId, apiKeyUsers, setValue]);

    return (
        <>
            <form onSubmit={handleSubmit(submitForm)}>
                <EditorWrapper>
                    <Header>API Key</Header>
                    <Header>Spaces</Header>
                    <Header>Permissions</Header>
                    <Header>Users</Header>

                    <SectionWrapper>
                        <TextField
                            name={'apiKeyDesc'}
                            inputRef={register({
                                required: 'This is required',
                                validate: (value) =>
                                    (value && value.trim() !== '') || 'Please provide a valid name',
                            })}
                            margin={'normal'}
                            label={'Description'}
                            fullWidth
                            error={!!errors?.apiKeyDesc}
                            helperText={errors?.apiKeyDesc?.message}
                        />

                        {apiKeyId && (
                            <TextField
                                label={'API Key'}
                                margin={'normal'}
                                disabled
                                defaultValue={apiKey.apiKeyId}
                                fullWidth
                            />
                        )}

                        <Controller
                            name={'isSuspended'}
                            control={control}
                            render={({ onChange, value }) => (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={value}
                                            onChange={(e) => onChange(e.target.checked)}
                                        />
                                    }
                                    label={<Label>Suspended</Label>}
                                />
                            )}
                        />

                        {isOmk && (
                            <Controller
                                name={'isExcludedFromTermsAcceptance'}
                                control={control}
                                render={({ onChange, value }) => (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={value}
                                                onChange={(e) => onChange(e.target.checked)}
                                            />
                                        }
                                        label={<Label>Excluded from terms acceptance</Label>}
                                    />
                                )}
                            />
                        )}
                    </SectionWrapper>

                    <SectionWrapper>
                        <Controller
                            name={'spaces'}
                            control={control}
                            rules={{ validate: (value) => value?.length > 0 || 'Please select a space' }}
                            render={({ onChange, value }) => (
                                <CheckListWithSearch
                                    filterLabel={'Filter spaces...'}
                                    onChange={(e) =>
                                        onChange(
                                            e.target.checked
                                                ? [...value, e.target.value]
                                                : value.filter((id) => id !== e.target.value)
                                        )
                                    }
                                    allValues={
                                        apiKey
                                            ? spaces.sort(
                                                (a, b) =>
                                                    apiKey.spaces.includes(b.spaceId) -
                                                    apiKey.spaces.includes(a.spaceId)
                                            )
                                            : spaces
                                    }
                                    checkedValues={value}
                                    getValueDesc={(space) => space.spaceDesc}
                                    getValueId={(space) => space.spaceId}
                                />
                            )}
                        />
                    </SectionWrapper>

                    <SectionWrapper>
                        <Controller
                            name={'permissions'}
                            control={control}
                            render={({ onChange, value }) => (
                                <CheckListWithSearch
                                    filterLabel={'Filter permissions...'}
                                    onChange={(e) =>
                                        onChange(
                                            e.target.checked
                                                ? [...value, e.target.value]
                                                : value.filter((id) => id !== e.target.value)
                                        )
                                    }
                                    allValues={permissionsThatCanBeGrantedByCategory}
                                    checkedValues={value}
                                    getValueDesc={(perm) => perm.desc}
                                    getValueId={(perm) => perm.name}
                                    getCategoryName={(category) => category.category}
                                    getCategoryValues={(category) => category.permissions}
                                />
                            )}
                        />
                    </SectionWrapper>

                    <SectionWrapper>
                        <Controller
                            name={'users'}
                            control={control}
                            render={({ onChange, value }) => (
                                <UsersList
                                    users={value}
                                    onCreate={(user) =>
                                        onChange([...(value ?? []), { ...user, needsToCreate: true }])
                                    }
                                    onDelete={(userId) => {
                                        const index = value.findIndex((u) => u.userId === userId);
                                        onChange([
                                            ...value.slice(0, index),
                                            { ...value[index], needsToDelete: true },
                                            ...value.slice(index + 1),
                                        ]);
                                    }}
                                />
                            )}
                        />
                    </SectionWrapper>
                </EditorWrapper>

                <Grid container spacing={2} justifyContent={'flex-end'} alignItems={'center'}>
                    <ErrorMessage
                        name={'spaces'}
                        errors={errors}
                        render={({ message }) => <Typography color={'error'}>{message}</Typography>}
                    />

                    {apiKeyId && (
                        <Grid item>
                            <Button variant={'outlined'} onClick={() => setIsRemoveConfirmDialogOpen(true)}>
                                <DeleteIcon size={20} />
                                Remove
                            </Button>
                        </Grid>
                    )}

                    <Grid item>
                        {isSubmitting ? (
                            <PulsePreloader />
                        ) : (
                            <Button type={'submit'}>
                                <SaveIcon size={20} />
                                Save
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </form>

            <RemoveConfirmDialog
                open={isRemoveConfirmDialogOpen}
                onConfirm={handleDelete}
                onCancel={() => setIsRemoveConfirmDialogOpen(false)}
            />
        </>
    );
}

ApiKeyEditor.propTypes = {
    apiKey: PropTypes.object,
    onFormSubmitted: PropTypes.func,
};
