import log from '../../utils/logger';
import {
    getAreaOfInterestCreatedTopic,
    getAreaOfInterestDeletedTopic,
    getAreaOfInterestUpdatedTopic,
    getLaneCreatedTopic,
    getLaneDeletedTopic,
    getLaneUpdatedTopic,
    getMapperAliveTopic,
    getMapperExpiredTopic,
    getMapperHelpRequestedTopic,
    getMapperPositionUpdatedTopic,
    getMapperSuperMapperToggledTopic,
    getMapEqualizationStartedTopic,
    getMapEqualizationFinishedTopic,
    getClientPositionUpdatedTopic,
    getMapperHelpRequestAcknowledgedTopic,
    getExitRegionCreatedTopic,
    getExitRegionUpdatedTopic,
    getExitRegionDeletedTopic,
    getLineOfInterestCreatedTopic,
    getLineOfInterestUpdatedTopic,
    getLineOfInterestDeletedTopic,
    getSignMarkCreatedTopic,
    getSignMarkDeletedTopic,
    getSignMarkUpdatedTopic,
} from '../../constants/eventTopics';
import { isMatch } from 'micromatch';
import { store } from '../../state-management/store';
import {
    areaOfInterestCreated,
    areaOfInterestDeleted,
    areaOfInterestUpdated,
    elementsCreated,
    elementsDeleted,
    elementsUpdated,
    laneCreated,
    laneDeleted,
    laneUpdated,
    mapEqualizationFinished,
    mapEqualizationStarted,
    mapperAlive,
    mapperExpired,
    mapperHelpRequested,
    mapperPositionUpdated,
    superMapperToggled,
    mapperHelpRequestAcknowledged,
    exitRegionUpdated,
    exitRegionDeleted,
    exitRegionCreated,
    lineOfInterestCreated,
    lineOfInterestDeleted,
    signMarkCreated,
    signMarkDeleted,
    signMarkUpdated,
} from '../../state-management/mapping/mappingEventsActions';
import {
    showErrorNotification,
    showInfoNotification,
    showSuccessNotification,
} from '../../state-management/notification/notificationReducer';
import { selectMapperById } from '../../state-management/mapping/mapper/mapperSelectors';
import { selectMapById } from '../../state-management/map/mapSelectors';
import { clientPositionUpdated } from '../../state-management/monitor/monitorEventsActions';
import {
    activeHelpRequestAcknowledgeCreated,
    activeHelpRequestCreated,
} from '../../state-management/shift-management/shiftManagementActions';

const defaultTopicHandlers = {
    [getLaneCreatedTopic()]: (parameters, data) => {
        store.dispatch(laneCreated({ parameters, data }));
        store.dispatch(showInfoNotification(`A new lane has been created (${data.laneId})`));
    },

    [getLaneUpdatedTopic()]: (parameters, data) => {
        store.dispatch(laneUpdated({ parameters, data }));
        store.dispatch(showInfoNotification(`A lane has been updated (${data.laneId})`));
    },

    [getLaneDeletedTopic()]: (parameters, data) => {
        store.dispatch(laneDeleted({ parameters, data }));
        store.dispatch(showInfoNotification(`A lane has been deleted (${data.laneId})`));
    },

    [getMapperAliveTopic()]: (parameters, data) => {
        store.dispatch(mapperAlive({ parameters, data }));
    },

    [getMapperSuperMapperToggledTopic()]: (parameters, data) => {
        store.dispatch(superMapperToggled({ parameters, data }));
        const { mapperName } = selectMapperById(store.getState(), parameters.mapperId);
        store.dispatch(
            showInfoNotification(
                `Super Mapper has been ${data.isSuperMapper ? 'enabled' : 'disabled'} for ${mapperName}`
            )
        );
    },

    [getMapperExpiredTopic()]: (parameters, data) => {
        store.dispatch(mapperExpired({ parameters, data }));
    },

    [getMapperPositionUpdatedTopic()]: (parameters, data) => {
        store.dispatch(mapperPositionUpdated({ parameters, data }));
    },

    [getMapperHelpRequestedTopic()]: async (parameters) => {
        const { mapperId } = parameters;
        const mapper = selectMapperById(store.getState(), mapperId);
        const mapperName = mapper?.mapperName ?? `Mapper ${mapperId}`;
        store.dispatch(mapperHelpRequested({ parameters: { ...parameters, mapperName } }));
        store.dispatch(activeHelpRequestCreated({ ...parameters, mapperName }));
    },

    [getMapperHelpRequestAcknowledgedTopic()]: async (parameters) => {
        store.dispatch(mapperHelpRequestAcknowledged());
        store.dispatch(activeHelpRequestAcknowledgeCreated(parameters));
    },

    [getAreaOfInterestCreatedTopic()]: (parameters, data) => {
        let action;
        let message;
        switch (data.type) {
            case 'element': {
                action = elementsCreated;
                message = 'A new element has been created';
                break;
            }
            default:
                action = areaOfInterestCreated;
                message = 'A new area of interest has been created';
        }
        store.dispatch(action({ parameters, data }));
        store.dispatch(showInfoNotification(message));
    },

    [getAreaOfInterestUpdatedTopic()]: (parameters, data) => {
        let action;
        let message;
        switch (data.type) {
            case 'element': {
                action = elementsUpdated;
                message = `An element has been updated`;
                break;
            }
            default: {
                action = areaOfInterestUpdated;
                message = `An area of interest has been updated`;
            }
        }
        store.dispatch(action({ parameters, data }));
        store.dispatch(showInfoNotification(message));
    },

    [getAreaOfInterestDeletedTopic()]: (parameters, data) => {
        let action = areaOfInterestDeleted;
        let message = 'An area of interest has been deleted';
        const { elements } = store.getState().mapping;
        const isElement = data.type === 'element' || elements.ids.includes(data.areaId);
        if (isElement) {
            action = elementsDeleted;
            message = 'An element has been deleted';
        }
        store.dispatch(action({ parameters, data }));
        store.dispatch(showInfoNotification(message));
    },
    [getLineOfInterestCreatedTopic()]: (parameters, data) => {
        store.dispatch(lineOfInterestCreated({ parameters, data }));
        store.dispatch(showInfoNotification('A new line of interest has been created'));
    },
    [getLineOfInterestUpdatedTopic()]: (parameters, data) => {
        store.dispatch(lineOfInterestCreated({ parameters, data }));
        store.dispatch(showInfoNotification('A line of interest has been updated'));
    },
    [getLineOfInterestDeletedTopic()]: (parameters, data) => {
        store.dispatch(lineOfInterestDeleted({ parameters, data }));
        store.dispatch(showInfoNotification('A line of interest has been deleted'));
    },
    [getExitRegionCreatedTopic()]: (parameters, data) => {
        store.dispatch(exitRegionCreated({ parameters, data }));
        store.dispatch(showInfoNotification('An exit region has been created'));
    },
    [getExitRegionUpdatedTopic()]: (parameters, data) => {
        store.dispatch(exitRegionUpdated({ parameters, data }));
        store.dispatch(showInfoNotification('An exit region has been updated'));
    },
    [getExitRegionDeletedTopic()]: (parameters, data) => {
        store.dispatch(exitRegionDeleted({ parameters, data }));
        store.dispatch(showInfoNotification('An exit region has been deleted'));
    },

    [getMapEqualizationStartedTopic()]: (parameters, data) => {
        const { mapId } = parameters;
        const map = selectMapById(store.getState(), mapId);
        const mapName = map?.mapName ?? `Map ${mapId}`;

        store.dispatch(mapEqualizationStarted({ parameters, data }));
        store.dispatch(showInfoNotification(`Equalization has started for ${mapName}...`));
    },

    [getMapEqualizationFinishedTopic()]: (parameters, data) => {
        const { mapId } = parameters;
        const map = selectMapById(store.getState(), mapId);
        const mapName = map?.mapName ?? `Map ${mapId}`;

        store.dispatch(mapEqualizationFinished({ parameters, data }));

        if (data?.error) {
            store.dispatch(showErrorNotification(`Equalization has failed for ${mapName}`));
        } else {
            store.dispatch(showSuccessNotification(`Equalization has finished successfully for ${mapName}`));
        }
    },

    [getClientPositionUpdatedTopic()]: (parameters, data) => {
        /**
         * Converting the received data to be match for the client position update dispatch
         * location: [x,y]
         * accuracy: u
         * heading: h  // Heading should be convert from Object to be an array.
         */

        const convertedPositionStructure = {
            location: [data.x, data.y],
            locationAccuracy: data.u,
            heading: Object.values(data.h),
        };

        store.dispatch(
            clientPositionUpdated({
                parameters,
                data: {
                    ...data,
                    position: convertedPositionStructure || {},
                    clientId: parameters.senderId || '',
                    clientName: parameters.senderName || 'Unknown',
                    sessionId: parameters.sessionId || '',
                },
            })
        );
    },
    [getSignMarkCreatedTopic()]: (parameters, data) => {
        store.dispatch(signMarkCreated({ parameters, data }));
        store.dispatch(showInfoNotification('A sign mark has been created'));
    },
    [getSignMarkUpdatedTopic()]: (parameters, data) => {
        store.dispatch(signMarkUpdated({ parameters, data }));
        store.dispatch(showInfoNotification('A sign mark has been updated'));
    },
    [getSignMarkDeletedTopic()]: (parameters, data) => {
        store.dispatch(signMarkDeleted({ parameters, data }));
        store.dispatch(showInfoNotification('A sign mark has been deleted'));
    },

    // [getValidationMapUpdatedTopic()]: (parameters, data) => {
    //     store.dispatch(
    //         mapValidationsAllUpdated({
    //             parameters,
    //             data: data.map((v) => ({ ...v, mapValidationId: getId(v) })),
    //         })
    //     );
    //     store.dispatch(showInfoNotification(`All validation points have been updated`));
    // },
    //
    // [getValidationCoordinatesUpdatedTopic()]: (parameters, data) => {
    //     store.dispatch(
    //         mapValidationsSomeUpdated({
    //             parameters,
    //             data: data.map((v) => ({ ...v, mapValidationId: getId(v) })),
    //         })
    //     );
    //     store.dispatch(showInfoNotification(`Some validation points have been updated`));
    // },
    //
    // [getValidationCoordinatesDeletedTopic()]: (parameters, data) => {
    //     store.dispatch(
    //         mapValidationsDeleted({
    //             parameters,
    //             data: data.map((v) => ({ ...v, mapValidationId: getId(v) })),
    //         })
    //     );
    //     store.dispatch(showInfoNotification(`Some validation points have been deleted`));
    // },
};

class EventsManagerMessageHandler {
    constructor() {
        this.topicHandlers = Object.entries(defaultTopicHandlers).reduce(
            (result, [topic, handler]) => ({
                ...result,
                [topic]: { handler, options: { handleOwnMessages: false } },
            }),
            {}
        );
    }

    addEventHandler(eventTopic, handler, { handleOwnMessages = false } = {}) {
        this.topicHandlers[eventTopic] = { handler, options: { handleOwnMessages } };
    }

    async handleMessage(message, isOwnMessage) {
        const { topic: messageTopic = '', payload: { parameters = {}, data = {} } = {}, eventId } = message;

        const handlers = Object.entries(this.topicHandlers);

        log.debug(
            `Received event message from events manager, topic: ${messageTopic}, parameters: ${JSON.stringify(
                parameters
            )}, data: ${JSON.stringify(data)}, eventId: ${eventId}`
        );

        for (const [topic, { handler, options }] of handlers) {
            const matches = isMatch(messageTopic, topic);

            // Incase we want to publish an event from the client and listen it on the client, we will check the allowOwnMessage property in data
            options.handleOwnMessages = data?.allowOwnMessage || options.handleOwnMessages;

            if (matches) {
                try {
                    if (!isOwnMessage || options?.handleOwnMessages) {
                        await handler(parameters, data);
                    }
                } catch (e) {
                    log.error(e);
                }
            }
        }
    }
}

export default new EventsManagerMessageHandler();
