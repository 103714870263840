import React, { useState } from 'react';
import ApiKeyDetailsCard from './ApiKeyDetailsCard';
import { useDispatch, useSelector } from 'react-redux';
import ActionsToolbar from '../common/ActionsToolbar';
import { FormControlLabel } from '@material-ui/core';
import ApiKeyEditor from './ApiKeyEditor';
import useApiKeys from '../../common/hooks/data-fetching/useApiKeys';
import {
    Skeleton,
    Switch,
    Dialog,
    DialogContent,
    DialogTitle,
    SwitchLabel as Label,
} from '../../common/themed';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';
import { fetchAllApiKeysInAttachedSpaces } from '../../../state-management/api-key/apiKeyActions';
import useUsers from '../../common/hooks/data-fetching/useUsers';
import { selectUserApiKeyId } from '../../../state-management/auth/authSelectors';

export default function ApiKeysManagement() {
    const [filter, setFilter] = useState('');
    const [includeUsersInSearch, setIncludeUsersInSearch] = useState(true);
    const [isNewApiKeyDialogOpen, setIsNewApiKeyDialogOpen] = useState(false);

    const dispatch = useDispatch();

    const { data: apiKeys, isLoading: isLoadingApiKeys, hasError } = useApiKeys();
    const { data: users, isLoading: isLoadingUsers } = useUsers({ asObject: true });

    const userApiKeyId = useSelector(selectUserApiKeyId);

    if (hasError) {
        return <ErrorGeneral />;
    }

    return (
        <>
            <ActionsToolbar
                onCreate={() => setIsNewApiKeyDialogOpen(true)}
                onRefresh={() => dispatch(fetchAllApiKeysInAttachedSpaces())}
                filter={filter}
                onFilterChange={setFilter}
            >
                <FormControlLabel
                    control={
                        <Switch
                            defaultChecked={includeUsersInSearch}
                            onChange={(e) => setIncludeUsersInSearch(e.target.checked)}
                        />
                    }
                    label={<Label>Include users in search</Label>}
                />
            </ActionsToolbar>

            {isLoadingApiKeys || isLoadingUsers
                ? Array.from({ length: 10 }, (_, index) => (
                      <Skeleton
                          key={`api-key-card-skeleton-${index}`}
                          height={70}
                          style={{ marginBlockEnd: 2 }}
                      />
                  ))
                : apiKeys
                      .filter(
                          ({ apiKeyId, apiKeyDesc, userIds = [] }) =>
                              apiKeyId !== userApiKeyId &&
                              (apiKeyDesc.toLowerCase().includes(filter.toLowerCase()) ||
                                  apiKeyId.toLowerCase().includes(filter.toLowerCase()) ||
                                  (includeUsersInSearch &&
                                      userIds
                                          .map((id) => users?.[id])
                                          .some((u) =>
                                              u?.userName?.toLowerCase()?.includes(filter.toLowerCase())
                                          )))
                      )
                      .map((apiKey) => <ApiKeyDetailsCard key={apiKey?.apiKeyId} apiKey={apiKey} />)}

            <Dialog
                open={isNewApiKeyDialogOpen}
                maxWidth={'lg'}
                fullWidth
                onClose={() => setIsNewApiKeyDialogOpen(false)}
            >
                <DialogTitle>Create new API key</DialogTitle>

                <DialogContent>
                    <ApiKeyEditor onFormSubmitted={() => setIsNewApiKeyDialogOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    );
}
