import { createSelector } from '@reduxjs/toolkit';
import entityAdapter from './spaceEntityAdapter';
import { selectBuildingEntities } from '../building/buildingSelectors';
import { selectRegionEntities } from '../region/regionSelectors';
import { selectTriggerEntities } from '../trigger/triggerSelectors';
import { selectRegionPlacementEntities } from '../region-placement/regionPlacementSelectors';
import { INTERNAL } from '../../constants/regionPlacementTypes';

export const {
    selectById: selectSpaceById,
    selectIds: selectSpaceIds,
    selectEntities: selectSpaceEntities,
    selectAll: selectAllSpaces,
    selectTotal: selectTotalSpaces,
} = entityAdapter.getSelectors((state) => state.spaces);

export const selectAreSpacesFetched = (state) => state.spaces.isFetched;

export const getSelectAllBuildingsInSpace = (spaceId) =>
    createSelector(
        (state) => selectSpaceById(state, spaceId),
        selectBuildingEntities,
        (space, buildings = {}) => {
            return {
                asArray: space?.buildingIds?.map((id) => buildings?.[id]),
                asObject: space?.buildingIds?.reduce((res, id) => ({ ...res, [id]: buildings?.[id] }), {}),
            };
        }
    );

export const selectIsAllBuildingsInAllSpacesFetched = createSelector(
    selectAllSpaces,
    (spaces) => spaces?.every((s) => !!s?.buildingIds) ?? false
);

export const selectAllBuildingsInAllSpaces = createSelector(
    selectAllSpaces,
    selectBuildingEntities,
    (spaces, buildings) => {
        const buildingIds =
            spaces?.reduce((result, { buildingIds = [] }) => [...result, ...buildingIds], []) ?? [];
        const uniqueBuildingIds = [...new Set(buildingIds)];

        return {
            asArray: uniqueBuildingIds.map((id) => buildings?.[id]),
            asObject: uniqueBuildingIds.reduce((result, id) => ({ ...result, [id]: buildings?.[id] }), {}),
        };
    }
);

export const getSelectAllRegionsWithInternalPlacementsInSpace = (spaceId) =>
    createSelector(
        (state) => selectSpaceById(state, spaceId),
        selectRegionEntities,
        selectRegionPlacementEntities,
        (space, regions, placements) => {
            const regionsInSpace = space?.regionIds
                ?.map((id) => regions?.[id])
                ?.reduce(
                    (result, region) => [
                        ...result,
                        ...(region?.placementIds?.map((placementId) => ({
                            regionId: region.regionId,
                            regionName: region.regionName,
                            regionMetadata: region.regionMetadata,
                            ...placements?.[placementId],
                        })) ?? []),
                    ],
                    []
                )
                ?.filter((p) => p.type === INTERNAL);

            return {
                asArray: regionsInSpace,
                asObject: regionsInSpace?.reduce((result, r) => ({ ...result, [r.placementId]: r }), {}),
            };
        }
    );

export const getSelectAllTriggersInSpace = (spaceId) =>
    createSelector(
        (state) => selectSpaceById(state, spaceId),
        selectTriggerEntities,
        (space, triggers) => ({
            asArray: space?.triggerIds?.map((id) => triggers[id]),
            asObject: space?.triggerIds?.reduce((result, id) => ({ ...result, [id]: triggers?.[id] }), {}),
        })
    );

export const selectRegionColorsInSpace = (spaceId) =>
    createSelector(selectSpaceEntities, (spaces) => spaces?.[spaceId]?.regionColors);

export const getSelectAllTriggersAttachedToSpace = (spaceId) =>
    createSelector(
        (state) => selectSpaceById(state, spaceId),
        selectTriggerEntities,
        (space, triggers) => space?.triggerIds?.map((triggerId) => triggers?.[triggerId])
    );
