import { createSelector } from 'reselect';

export const getOccupancyDataAvailableTimeResolutions = (state) =>
    state.analytics.regionOccupancy.timeResolutions;

export const getOccupancyData = (state) => state.analytics.regionOccupancy.byTimeResolution;

export const getOccupancyStartTimestamp = (state) => state.analytics.regionOccupancy.startTimestamp;

export const getOccupancyEndTimestamp = (state) => state.analytics.regionOccupancy.endTimestamp;

export const getOccupancyTimeZoneOffset = (state) => state.analytics.regionOccupancy.timeZoneOffset;

export const getOccupancyWorkingHoursOnly = (state) => state.analytics.regionOccupancy.workingHoursOnly;

export const getOccupancyDataByTimeResolution = createSelector(
    getOccupancyData,
    getOccupancyDataAvailableTimeResolutions,
    (data, resolutions) => resolutions.map((res) => data[res])
);

export const getOccupancySubmittedFilters = (state) => state.analytics.regionOccupancy.submittedFilters;
