import React from 'react';
import { Radio } from '@material-ui/core';
import styled from 'styled-components';

export default styled(props => (
    <Radio
        classes={{
            checked: 'themed-checked',
        }}
        {...props}
    />
))`
    &.themed-checked {
        color: #ff6200; // TODO color should come from theme
    }
`;
