import React, { useEffect, useMemo } from 'react';
import SideMenuSection from '../../common/side-drawer/SideMenuSection';
import {
    Button,
    Select,
    Skeleton,
    Switch,
    SwitchLabel as Label,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '../../common/themed';
import {
    selectMappingIsMapInGrayscale,
    selectMappingIsSettingPixelToMeter,
    selectMappingIsShowingEqualizedMap,
    selectMappingSelectedBuildingId,
    selectMappingSelectedFloorId,
    selectMappingSelectedMapId,
    selectMappingIsGenerateMapGridOpen,
    setIsMapInGrayscale,
    setIsSettingPixelToMeter,
    setIsShowingEqualizedMap,
    setSelectedMapId,
    setIsGenerateMapGridOpen,
} from '../../../state-management/user-inputs/mappingSlice';
import { useDispatch, useSelector } from 'react-redux';
import useAllMapsInFloor from '../../common/hooks/data-fetching/useAllMapsInFloor';
import { getMapsSelectId } from '../Mapping.selectors';
import { FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FetchError from '../../common/error-pages/FetchError';
import { createMapGrid, getMapGridMetadata } from '../../../state-management/map/mapActions';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../state-management/notification/notificationReducer';
import { isFulfilled } from '../../../state-management/utils';
import { selectHasDeletedLanes } from '../../../state-management/mapping/lane/laneSelectors';

export default function MappingSideMenuMapSection({ disabled }) {
    const dispatch = useDispatch();

    const selectedBuildingId = useSelector(selectMappingSelectedBuildingId);
    const selectedFloorId = useSelector(selectMappingSelectedFloorId);
    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const isMapInGrayscale = useSelector(selectMappingIsMapInGrayscale);
    const isShowingEqualizedMap = useSelector(selectMappingIsShowingEqualizedMap);
    const isSettingPixelToMeter = useSelector(selectMappingIsSettingPixelToMeter);
    const selectIsGenerateMapGridOpen = useSelector(selectMappingIsGenerateMapGridOpen);
    const hasDeletedLanes = useSelector(selectHasDeletedLanes);

    const {
        data: maps,
        isLoading,
        hasError,
    } = useAllMapsInFloor({
        asObject: true,
        buildingId: selectedBuildingId,
        floorId: selectedFloorId,
    });

    const mapIds = useMemo(() => Object.values(maps || {}).map((m) => m.mapId), [maps]);
    const selectedMap = maps?.[selectedMapId];

    const handleMapSelect = (map) => {
        if (map.mapId !== selectedMapId) {
            dispatch(setSelectedMapId(map.mapId));
        }
    };

    const handleGenerateMapGrid = async () => {
        const mapGridV1Result = await dispatch(createMapGrid({ mapId: selectedMapId, version: 1 }));
        const mapGridV2Result = await dispatch(createMapGrid({ mapId: selectedMapId, version: 2 }));
        if (isFulfilled(mapGridV1Result) && isFulfilled(mapGridV2Result)) {
            dispatch(showSuccessNotification('Generated map grid successfully.'));
            await dispatch(getMapGridMetadata({ mapId: selectedMapId }));
        } else {
            dispatch(showErrorNotification('Failed to generate map grid.'));
        }
        dispatch(setIsGenerateMapGridOpen(false));
    };

    const handleGenerateMapGridDialogOpen = () => {
        dispatch(setIsGenerateMapGridOpen(true));
    };

    const handleGenerateMapGridDialogClosed = () => {
        dispatch(setIsGenerateMapGridOpen(false));
    };

    useEffect(() => {
        // If the selected floor has only one map, select it by default
        if (!selectedMapId && mapIds.length === 1) {
            dispatch(setSelectedMapId(mapIds[0]));
        }
    }, [selectedMapId, dispatch, mapIds]);

    if (!maps && !isLoading) {
        return null;
    }

    return (
        <SideMenuSection title={'Map'}>
            {hasError ? (
                <FetchError />
            ) : isLoading ? (
                <Skeleton height={30} />
            ) : (
                <>
                    <Select
                        id={getMapsSelectId()}
                        options={maps ? Object.values(maps) : []}
                        placeholder={'Select a map...'}
                        getOptionValue={(m) => m.mapId}
                        getOptionLabel={(m) => m.mapName}
                        isDisabled={!maps}
                        onChange={handleMapSelect}
                        value={selectedMap || null}
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={isMapInGrayscale}
                                onChange={() => dispatch(setIsMapInGrayscale(!isMapInGrayscale))}
                            />
                        }
                        label={<Label>Display map in grayscale</Label>}
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={isShowingEqualizedMap}
                                onChange={() => dispatch(setIsShowingEqualizedMap(!isShowingEqualizedMap))}
                            />
                        }
                        label={<Label>Display equalized form</Label>}
                    />

                    <Grid container spacing={1} justifyContent={'center'}>
                        <Grid item style={{ width: '100%', margin: '0 15px' }}>
                            <Button
                                style={{ width: '100%' }}
                                variant={'outlined'}
                                disabled={isSettingPixelToMeter}
                                onClick={() => dispatch(setIsSettingPixelToMeter(true))}
                            >
                                Change Pixel to Meter
                            </Button>
                        </Grid>
                        <Grid item style={{ width: '100%', margin: '0 15px' }}>
                            <Button
                                disabled={disabled || hasDeletedLanes}
                                style={{ width: '100%' }}
                                onClick={handleGenerateMapGridDialogOpen}
                            >
                                Generate Map Grid
                            </Button>
                        </Grid>
                    </Grid>

                    <Dialog open={selectIsGenerateMapGridOpen} onClose={handleGenerateMapGridDialogClosed}>
                        <DialogTitle>Generate map grid</DialogTitle>
                        <DialogContent>
                            It is very important to generate the map only when all the changes to the lanes
                            are done and the last equalization is finished. Would you like to proceed and
                            generate the map grid?
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleGenerateMapGridDialogClosed} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleGenerateMapGrid} color="primary">
                                Generate
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </SideMenuSection>
    );
}
