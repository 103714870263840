import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { Switch, Button } from '../../common/themed';
import {
    SpaceForm,
    Arrow,
    EnableConfigWrapper,
    FrequencyConfigWrapper,
    TimePicker,
    Label,
    Dropdown,
    MultiDropdown,
    CustomItemRenderer,
    ProductsConfigWrapper,
    ConfigWrapper,
    DetailTitle,
    DetailValue,
    SummaryWrapper,
} from './components';
import { daysOfTheWeek, timeframeOptions, productLocationOptions } from './constants';
import {
    FormControlLabel,
    FormControl,
    RootRef,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from '@material-ui/core';
import countriesAndTimezones from 'countries-and-timezones';
import {
    getSettingsDaysPickerId,
    getSettingsTimePickerId,
    getSettingsTimeZoneId,
    getSettingsTimeFrameId,
    getSettingsProductLocation,
} from '../ProductLocations.selectors';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/themes/light.css';
import { AiFillSave as SaveIcon } from 'react-icons/ai';
import { MdExpandMore as ExpandMoreIcon } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpace } from '../../../state-management/space/spaceActions';
import { selectCommonSelectedSpaceId } from '../../../state-management/user-inputs/commonSlice';
import { isFulfilled } from '../../../state-management/utils';
import {
    showSuccessNotification,
    showErrorNotification,
    showInfoNotification,
} from '../../../state-management/notification/notificationReducer';
import useSpaces from '../../common/hooks/data-fetching/useSpaces';
import cronParser from 'cron-parser';
import { selectSpaceConfig } from '../../../state-management/productLocations/productLocationsSelectors';
import { selectIsLoading, selectHasError } from '../../../state-management/status/statusSelectors';
import {
    getSpaceConfig,
    updateSpaceConfig,
} from '../../../state-management/productLocations/productLocationsActions';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';
import {
    deepEqual as hasConfigurationChanged,
    validateRequiredFields,
    formatConfigSettings,
} from '../../../utils/productLocationUtils';

let timepicker = null;

const shouldDisableSingleProductPerRegion = (settings) => {
    return !(settings.productLocationSelect?.value.includes('Multi') && settings.usePlaiRegionsEnabled);
};

export const SpaceConfig = (props) => {
    const { data: spaces, isLoading: spaceIsLoading, hasError } = useSpaces({ asObject: true });
    const timeZones = countriesAndTimezones.getAllTimezones();
    const targetRef = useRef();
    const accordionRef = useRef();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);

    const spaceConfig = useSelector(selectSpaceConfig);
    const dispatch = useDispatch();
    const [plaiEnabled, setPlaiEnabled] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const [settings, setSettings] = useState({
        days: null,
        timezone: null,
        time: null,
        timeframe: null,
        productLocationSelect: null,
        usePlaiRegionsEnabled: null,
        singleProductPerRegionEnabled: null,
    });

    const updateSpaceConfigHasError = useSelector(selectHasError(updateSpaceConfig.typePrefix));
    const spaceConfigHasError = useSelector(selectHasError(getSpaceConfig.typePrefix));

    const selectedSpace = useMemo(() => spaces?.[selectedSpaceId], [selectedSpaceId]);

    const onSave = async () => {
        if (selectedSpace) {
            if (
                plaiEnabled === selectedSpace?.plaiEnabled &&
                validateRequiredFields(settings) &&
                hasConfigurationChanged(formatConfigSettings(settings, selectedSpaceId), spaceConfig[0])
            ) {
                dispatch(showInfoNotification('No changes were made.'));
                return;
            }
            if (validateRequiredFields(settings)) {
                const result = await dispatch(
                    updateSpace({
                        spaceId: selectedSpaceId,
                        spaceData: { ...selectedSpace, plaiEnabled },
                    })
                );

                const formattedSettings = formatConfigSettings(settings, selectedSpaceId);
                dispatch(updateSpaceConfig(formattedSettings));

                if (isFulfilled(result)) {
                    dispatch(showSuccessNotification('Space updated.'));
                }
            } else {
                dispatch(showErrorNotification('All fields are required.'));
            }
        } else {
            dispatch(showErrorNotification('Something went wrong the the selected space..'));
        }
    };

    useLayoutEffect(() => {
        if (targetRef.current) {
            setWidth(targetRef.current.offsetWidth);
            setHeight(targetRef.current.offsetHeight);
        }
    }, []);

    useEffect(() => {
        if (selectedSpaceId && selectedSpace) {
            dispatch(getSpaceConfig());
            setPlaiEnabled(selectedSpace?.plaiEnabled || false);
        }
        return () => {};
    }, [selectedSpaceId, selectedSpace]);

    useEffect(() => {
        if (!isExpanded) {
            return;
        }
        const defaultDate = new Date();
        if (Array.isArray(spaceConfig) && spaceConfig.length > 0) {
            const {
                frequency,
                timezone,
                timeframe,
                isMultiLocation,
                usePlaiRegions,
                singleProductPerRegion,
            } = spaceConfig[0];
            const { hour, minute, dayOfWeek } = cronParser.parseExpression(frequency).fields;
            defaultDate.setHours(hour[0]);
            defaultDate.setMinutes(minute[0]);
            const configuredTimezone = timeZones[timezone] || timeZones['Asia/Jerusalem'];
            const timeFrame = timeframe === 'All' ? timeframe : timeframe.concat(' ', 'ago');
            const productLocation = isMultiLocation ? productLocationOptions[1] : productLocationOptions[0];

            setSettings((prevState) => ({
                ...prevState,
                time: { hours: defaultDate.getHours(), minutes: defaultDate.getMinutes() },
                days: dayOfWeek.map((d) => {
                    return { value: daysOfTheWeek[d], label: daysOfTheWeek[d] };
                }),
                timeframe: { value: timeFrame, label: timeFrame },
                productLocationSelect: { value: productLocation, label: productLocation },
                timezone: configuredTimezone,
                usePlaiRegionsEnabled: usePlaiRegions,
                singleProductPerRegionEnabled: singleProductPerRegion,
            }));
        } else {
            setSettings((prevState) => ({
                ...prevState,
                days: [],
                timeframe: null,
                timezone: null,
                time: null,
                productLocationSelect: null,
                usePlaiRegions: null,
                singleProductPerRegion: null,
            }));
        }
        // Initialize flatpickr instances for both date pickers
        timepicker = flatpickr(`#${getSettingsTimePickerId()}`, {
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i',
            onChange: ([timeSpanStart]) =>
                setSettings((prevState) => ({
                    ...prevState,
                    time: { hours: timeSpanStart.getHours(), minutes: timeSpanStart.getMinutes() },
                })),
        });
        if (timepicker && !Array.isArray(timepicker)) {
            timepicker.setDate(defaultDate);
        }
        return () => {
            if (timepicker && !Array.isArray(timepicker)) {
                timepicker.destroy();
            }
        };
    }, [spaceConfig, isExpanded]);

    // Following the combination , singleProductPerRegion enabled only when multi product location selected and 'use plai regions' checked.
    useEffect(() => {
        if (!settings.productLocationSelect?.value.includes('Multi') || !settings.usePlaiRegionsEnabled) {
            setSettings((prevState) => ({
                ...prevState,
                singleProductPerRegionEnabled: false,
            }));
        }
    }, [settings.productLocationSelect, settings.usePlaiRegionsEnabled]);

    if (updateSpaceConfigHasError || spaceConfigHasError || hasError) {
        return <ErrorGeneral />;
    }

    return (
        <RootRef rootRef={targetRef}>
            <Accordion onChange={(event, value) => setIsExpanded(value)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <SummaryWrapper>
                        <DetailTitle>PLAI Settings</DetailTitle>
                        <DetailValue>{selectedSpace?.spaceDesc}</DetailValue>
                    </SummaryWrapper>
                </AccordionSummary>
                {isExpanded && (
                    <AccordionDetails rootRef={accordionRef}>
                        <SpaceForm>
                            <ConfigWrapper>
                                <EnableConfigWrapper>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={plaiEnabled}
                                                onChange={(e) => {
                                                    setPlaiEnabled(e.target.checked);
                                                }}
                                            />
                                        }
                                        label={<Label>Enable PLAI for this space</Label>}
                                    />
                                </EnableConfigWrapper>
                            </ConfigWrapper>
                            <ConfigWrapper>
                                <Typography
                                    style={{ color: '#ff6200', marginTop: '5px' }}
                                    variant="subtitle1"
                                    component="span"
                                >
                                    Products
                                </Typography>
                                <ProductsConfigWrapper>
                                    <Dropdown
                                        id={getSettingsProductLocation()}
                                        styles={{ minWidth: '155px' }}
                                        isDisabled={!plaiEnabled}
                                        value={settings.productLocationSelect}
                                        onChange={(productLocation) =>
                                            setSettings((prevState) => ({
                                                ...prevState,
                                                productLocationSelect: productLocation,
                                            }))
                                        }
                                        placeholder={'Select location'}
                                        options={productLocationOptions.map((d) => {
                                            return { value: d, label: d };
                                        })}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={settings.usePlaiRegionsEnabled}
                                                onChange={(e) => {
                                                    setSettings((prevState) => ({
                                                        ...prevState,
                                                        usePlaiRegionsEnabled: e.target.checked,
                                                    }));
                                                }}
                                            />
                                        }
                                        label={<Label>Use PLAI regions</Label>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={shouldDisableSingleProductPerRegion(settings)}
                                                checked={settings.singleProductPerRegionEnabled}
                                                onChange={(e) => {
                                                    setSettings((prevState) => ({
                                                        ...prevState,
                                                        singleProductPerRegionEnabled: e.target.checked,
                                                    }));
                                                }}
                                            />
                                        }
                                        label={<Label>Single prodcut per region</Label>}
                                    />
                                </ProductsConfigWrapper>
                            </ConfigWrapper>
                            <ConfigWrapper>
                                <Typography style={{ color: '#ff6200' }} variant="subtitle1" component="span">
                                    Run Time
                                </Typography>
                                <FrequencyConfigWrapper>
                                    <Dropdown
                                        styles={{ minWidth: '175px', marginTop: '7px' }}
                                        id={getSettingsTimeFrameId()}
                                        isDisabled={!plaiEnabled}
                                        value={settings.timeframe}
                                        onChange={(timeframe) =>
                                            setSettings((prevState) => ({
                                                ...prevState,
                                                timeframe,
                                            }))
                                        }
                                        placeholder={'Select time frame'}
                                        options={timeframeOptions.map((d) => {
                                            return { value: d, label: d };
                                        })}
                                    />
                                    <Arrow>{'>'}</Arrow>
                                    <Label>Run Every</Label>
                                    <FormControl>
                                        <MultiDropdown
                                            id={getSettingsDaysPickerId()}
                                            options={daysOfTheWeek.map((d) => {
                                                return { value: d, label: d };
                                            })}
                                            onChange={(day) =>
                                                setSettings((prevState) => ({
                                                    ...prevState,
                                                    days: day,
                                                }))
                                            }
                                            disabled={!plaiEnabled}
                                            styles={{ maxWidth: '336px', marginTop: '10px' }}
                                            value={settings?.days || []}
                                            ItemRenderer={CustomItemRenderer}
                                        />
                                    </FormControl>
                                    <Label>At</Label>
                                    <TimePicker
                                        id={getSettingsTimePickerId()}
                                        variant={'outlined'}
                                        disabled={!plaiEnabled}
                                    />
                                    <Dropdown
                                        id={getSettingsTimeZoneId()}
                                        styles={{ width: '293px', marginTop: '10px' }}
                                        isDisabled={!plaiEnabled}
                                        placeholder={'Time Zone'}
                                        value={settings.timezone}
                                        options={Object.values(timeZones).sort(
                                            (a, b) => a.utcOffset - b.utcOffset
                                        )}
                                        getOptionLabel={(timeZone) =>
                                            `(UTC${timeZone.offsetStr}) ${timeZone.name}`
                                        }
                                        getOptionValue={(timeZone) => timeZone.name}
                                        onChange={(timeZone) =>
                                            setSettings((prevState) => ({
                                                ...prevState,
                                                timezone: timeZone,
                                            }))
                                        }
                                    />
                                </FrequencyConfigWrapper>
                            </ConfigWrapper>
                            <Button startIcon={<SaveIcon />} onClick={onSave}>
                                Save
                            </Button>
                        </SpaceForm>
                    </AccordionDetails>
                )}
            </Accordion>
        </RootRef>
    );
};
