export const getLaneCreatedTopic = (mapId = '*') => `v1.mapping.${mapId}.lane.created`;
export const getLaneUpdatedTopic = (mapId = '*') => `v1.mapping.${mapId}.lane.updated`;
export const getLaneDeletedTopic = (mapId = '*') => `v1.mapping.${mapId}.lane.deleted`;

export const getValidationPositionUpdatedTopic = (mapId = '*') =>
    `v1.mapping.${mapId}.validation.position-updated`;
export const getValidationCoordinatesUpdatedTopic = (mapId = '*') =>
    `v1.mapping.${mapId}.validation.coordinates-updated`;
export const getValidationCoordinatesDeletedTopic = (mapId = '*') =>
    `v1.mapping.${mapId}.validation.coordinates-deleted`;
export const getValidationMapUpdatedTopic = (mapId = '*') => `v1.mapping.${mapId}.validation.map-updated`;

export const getMapperAliveTopic = (mapId = '*') => `v1.mapping.${mapId}.mapper.alive`;
export const getMapperSuperMapperToggledTopic = (mapId = '*', mapperId = '*') =>
    `v1.mapping.${mapId}.mapper.${mapperId}.super-mapper-toggled`;
export const getMapperExpiredTopic = (mapId = '*', mapperId = '*') =>
    `v1.mapping.${mapId}.mapper.${mapperId}.expired`;
export const getMapperPositionUpdatedTopic = (mapId = '*', mapperId = '*') =>
    `v1.mapping.${mapId}.mapper.${mapperId}.position-updated`;
export const getMapperHelpRequestedTopic = (mapId = '*', mapperId = '*') =>
    `v1.mapping.${mapId}.mapper.${mapperId}.help-requested`;
export const getMapperHelpRequestAcknowledgedTopic = (mapId = '*', mapperId = '*') =>
    `v1.mapping.${mapId}.mapper.${mapperId}.help-request-acknowledged`;

export const getAreaOfInterestCreatedTopic = (mapId = '*') => `v1.mapping.${mapId}.area-of-interest.created`;
export const getAreaOfInterestUpdatedTopic = (mapId = '*') => `v1.mapping.${mapId}.area-of-interest.updated`;
export const getAreaOfInterestDeletedTopic = (mapId = '*') => `v1.mapping.${mapId}.area-of-interest.deleted`;

export const getExitRegionCreatedTopic = (mapId = '*') => `v1.building.${mapId}.exit-region.created`;
export const getExitRegionUpdatedTopic = (mapId = '*') => `v1.building.${mapId}.exit-region.updated`;
export const getExitRegionDeletedTopic = (mapId = '*') => `v1.building.${mapId}.exit-region.deleted`;

export const getLineOfInterestCreatedTopic = (mapId = '*') => `v1.mapping.${mapId}.line-of-interest.created`;
export const getLineOfInterestUpdatedTopic = (mapId = '*') => `v1.mapping.${mapId}.line-of-interest.updated`;
export const getLineOfInterestDeletedTopic = (mapId = '*') => `v1.mapping.${mapId}.line-of-interest.deleted`;

export const getSignMarkCreatedTopic = (mapId = '*') => `v1.mapping.${mapId}.sign-mark.created`;
export const getSignMarkUpdatedTopic = (mapId = '*') => `v1.mapping.${mapId}.sign-mark.updated`;
export const getSignMarkDeletedTopic = (mapId = '*') => `v1.mapping.${mapId}.sign-mark.deleted`;

export const getMapEqualizationStartedTopic = (mapId = '*') => `v1.mapping.${mapId}.equalization.started`;
export const getMapEqualizationFinishedTopic = (mapId = '*') => `v1.mapping.${mapId}.equalization.finished`;

export const getClientPositionUpdatedTopic = (buildingId = '*', mapId = '*') =>
    `v1.realtime.${buildingId}.${mapId}.position-updated`;
