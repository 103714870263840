import React from 'react';
import * as PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import {
    Container,
    Graphics,
    Text,
    BitmapText,
    Loader,
    Application,
    Texture,
    TilingSprite,
    Rectangle,
} from 'pixi.js';
import chartColors from './chartColors';
import { MOVING } from './chartConstants';
import Color from 'color';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Ease from 'pixi-ease';
import { Tooltip } from '../../common/themed';
import ReactResizeDetector from 'react-resize-detector';
import isEqual from 'react-fast-compare';
import { debounce } from 'debounce';
import getSelectors from '../Analytics.selectors';

const moment = extendMoment(Moment);

const TooltipStyles = createGlobalStyle`
    :root {
        --user-activity-session-tooltip-background-color: white;
        --user-activity-session-tooltip-text-color: #333333;
    }
    
    .tippy-box.tippy-box {
        background-color: var(--user-activity-session-tooltip-background-color);
        border: 1px solid #cccccc
    }
    
    .tippy-content {
        white-space: pre-line;
        color: var(--user-activity-session-tooltip-text-color);
    }
    
    .tippy-box[data-theme~='light-border'][data-placement^='top'] > .tippy-arrow::before {
        border-top-color: var(--user-activity-session-tooltip-background-color);
    }
    .tippy-box[data-theme~='light-border'][data-placement^='bottom'] > .tippy-arrow::before {
        border-bottom-color: var(--user-activity-session-tooltip-background-color);
    }
    .tippy-box[data-theme~='light-border'][data-placement^='left'] > .tippy-arrow::before {
        border-left-color: var(--user-activity-session-tooltip-background-color);
    }
    .tippy-box[data-theme~='light-border'][data-placement^='right'] > .tippy-arrow::before {
        border-right-color: var(--user-activity-session-tooltip-background-color);
    }
`;

const MOVEMENT = 'movement';
const ELEMENT_TYPE_REGION_VISIT = 'ELEMENT_TYPE_REGION_VISIT';
const ELEMENT_TYPE_MOVEMENT = 'ELEMENT_TYPE_MOVEMENT';
const ELEMENT_TYPE_FLOOR_VISIT = 'ELEMENT_TYPE_FLOOR_VISIT';

// Dimensions
const TIMELINE_X_COORDINATE = 100;
const regionLevelBar = {
    height: 15,
    borderWidth: 1,
    bottomPadding: 15,
};
const timeIndicators = {
    minPaddingFromEdges: 50,
    maxCount: 15,
};
const mainContainerPaddingTopBottom = 10;
const scrollBar = {
    height: 10,
    bottomPadding: 10,
    handles: { radius: 8 },
};
const floorIndicators = {
    bar: { height: 15, topBottomPadding: 10 },
    label: { fontSize: 14 },
};

// Settings
const tooltipTimeFormat = 'H:mm:ss';
const minDisplayedDuration = { timeUnit: 'seconds', count: 10 }; // Max zoom
const defaultZoomInMultiplier = 2;
const defaultZoomOutMultiplier = 0.5;

// Elements
const DISPLAY_RANGE_BAR = 'DISPLAY_RANGE_BAR';
const DISPLAY_RANGE_START_HANDLE = 'DISPLAY_RANGE_START_HANDLE';
const DISPLAY_RANGE_END_HANDLE = 'DISPLAY_RANGE_END_HANDLE';
const TIMELINE_CONTAINER = 'TIMELINE_CONTAINER';

const timeIntervals = [
    { timeUnit: 'second', count: 10, format: 'H:mm:ss' },
    { timeUnit: 'second', count: 30, format: 'H:mm:ss' },
    { timeUnit: 'minute', count: 1, format: 'H:mm' },
    { timeUnit: 'minute', count: 5, format: 'H:mm' },
    { timeUnit: 'minute', count: 10, format: 'H:mm' },
    { timeUnit: 'minute', count: 30, format: 'H:mm' },
    { timeUnit: 'hour', count: 1, format: 'H:mm' },
    { timeUnit: 'hour', count: 3, format: 'H:mm' },
    { timeUnit: 'hour', count: 6, format: 'H:mm' },
    { timeUnit: 'hour', count: 12, format: 'H:mm' },
    { timeUnit: 'day', count: 1, format: 'MMM Do' },
    { timeUnit: 'day', count: 2, format: 'MMM Do' },
    { timeUnit: 'day', count: 3, format: 'MMM Do' },
    { timeUnit: 'day', count: 4, format: 'MMM Do' },
    { timeUnit: 'day', count: 5, format: 'MMM Do' },
    { timeUnit: 'month', count: 1, format: 'MMM YYYY' },
    { timeUnit: 'month', count: 2, format: 'MMM YYYY' },
    { timeUnit: 'month', count: 3, format: 'MMM YYYY' },
    { timeUnit: 'month', count: 6, format: 'MMM YYYY' },
];

const TimelineContainer = styled.div`
    display: flex;
    outline: none;
    max-height: 190px;
    height: ${(props) => props.height}px;
    overflow: auto;

    & > canvas {
        overflow: auto;
    }
`;

class PositionsTimelineChart extends React.Component {
    containerRef = React.createRef();

    isInitialized = false;

    selectors = getSelectors(this.props.session.sessionId).charts;

    drawnElements = null;

    currentTimeIntervalIndex = null;
    regionLevelsYCoordinatesRanges = [];
    regionLevelContainers = {};
    scrollBarContainer = null;

    currentlyDraggingElement = null;
    isDisplayRangeBarClickedOnce = false;
    hasDraggedTimelineSincePointerDown = false;

    currentMouseOver = { element: null, showTooltip: false };

    state = {
        tooltipContent: '',
    };

    componentDidMount() {
        this.initializeTimelineContainer();
        this.initializeDrawnElementsObject();
        this.initializeTextures();
        this.initializeRegionLevelContainers();
        this.initializeScrollBarContainer();
        this.initializeFloorIndicatorsContainer();
        this.initializeRegionLevelsYCoordinatesRanges();

        const loader = new Loader();
        loader.add('timelineLabelsFont', '../timelineLabelsFont.fnt').load(() => {
            this.drawAllElements();
            this.isInitialized = true;
            this.handleContainerResize(window.innerWidth - 120);
        });
    }

    componentWillUnmount() {
        this.containerRef.current.removeEventListener('mousewheel', this.handleWheelScroll);
        this.containerRef.current.removeEventListener('DOMMouseScroll', this.handleWheelScroll);

        this.drawnElements = null;

        this.app.destroy();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedRegionId, selectedRegionLevel, session } = this.props;
        if (
            prevProps.selectedRegionId !== selectedRegionId ||
            prevProps.selectedRegionLevel !== selectedRegionLevel
        ) {
            this.drawnElements.visitsSolidBackground.forEach(
                this.setVisitRectangleTransparencyBasedOnSelectedRegion
            );
            this.drawnElements.visitsPatternBackground.forEach(
                this.setVisitRectangleTransparencyBasedOnSelectedRegion
            );
        }

        // If the session data changed, re-render the whole timeline
        if (!isEqual(prevProps.session, session) && session.isProcessed) {
            this.clearAllElements();

            this.initializeTimelineContainer();
            this.initializeDrawnElementsObject();
            this.initializeTextures();
            this.initializeRegionLevelContainers();
            this.initializeScrollBarContainer();
            this.initializeFloorIndicatorsContainer();
            this.initializeRegionLevelsYCoordinatesRanges();

            this.drawAllElements();
            this.drawAllTimeIndicators(true);
        }
    }

    /**
     * Initializes all necessary adjustments to the timeline container, like placing event listeners, etc.
     */
    initializeTimelineContainer = () => {
        const {
            session: { earliestTime, latestTime },
        } = this.props;

        this.mainContainerWidth = window.innerWidth - 120;
        this.mainContainerHeight = this.getMainContainerHeight();

        this.millisecondsPerPixel = (new Date(latestTime) - new Date(earliestTime)) / this.getTimelineWidth();

        this.app = new Application({
            width: window.innerWidth - 120,
            height: this.containerRef.current.clientHeight,
            transparent: true,
        });

        // Append the WebGL view to the canvas container
        this.containerRef.current.appendChild(this.app.view);

        this.animationList = new Ease.list();

        // TODO use React's synthetic events instead (due to chrome 73+ defining wheel events as passive, it doesn't work as of React 16.8)
        // Add event listeners on wheel scrolling
        this.containerRef.current.addEventListener('mousewheel', this.handleWheelScroll);
        this.containerRef.current.addEventListener('DOMMouseScroll', this.handleWheelScroll);

        // Create the timeline container
        this.timelineContainer = new Container();
        this.timelineContainer.x = TIMELINE_X_COORDINATE;
        this.timelineContainer.y = 0;
        this.timelineContainer.width = this.getTimelineWidth();
        this.timelineContainer.height = this.mainContainerHeight;

        // Make sure the children can be sorted with zIndex to place them behind or above others
        this.timelineContainer.sortableChildren = true;

        this.timelineContainer.interactiveChildren = true;

        this.setTimelineContainerMask();
        this.setTimelineContainerHitArea();

        this.timelineContainer.interactive = true;
        this.timelineContainer.on('pointerdown', this.handleTimelineContainerPointerDown);
        this.timelineContainer.on('pointerup', this.handleTimelineContainerPointerUp);
        this.timelineContainer.on('pointerupoutside', this.handleTimelineContainerPointerUp);
        this.timelineContainer.on('pointermove', this.handleTimelineContainerPointerMove);
        this.timelineContainer.on('pointerout', this.clearCurrentMouseOverElement);

        // Add the timeline container to the stage
        this.app.stage.addChild(this.timelineContainer);
    };

    /**
     * Add a mask to the timeline container to limit the visibility of it
     * (makes sure panning doesn't show outside the container borders)
     */
    setTimelineContainerMask = () => {
        this.timelineContainer.mask = new Graphics()
            .beginFill(0x000000) // Black mask = transparent
            .drawRect(TIMELINE_X_COORDINATE, 0, this.getTimelineWidth(), this.mainContainerHeight)
            .endFill();
    };

    setTimelineContainerHitArea = () => {
        this.timelineContainer.hitArea = new Rectangle(
            0,
            0,
            this.getTimelineWidth(),
            this.mainContainerHeight
        );
    };

    initializeDrawnElementsObject = () => {
        this.drawnElements = {
            visitsSolidBackground: [],
            visitsPatternBackground: [],
            movementsSolidBackground: [],
            movementsPatternBackground: [],
            regionLevelLabels: [],
            timeIndicatorLabels: [],
            timeIndicatorLines: [],
            scrollBar: null,
            displayRangeBar: null,
            displayRangeHandles: [],
            floorIndicatorBars: [],
            floorIndicatorLabels: [],
            floorIndicatorSeparators: [],
        };
    };

    /**
     * Initializes all needed textures
     */
    initializeTextures = () => {
        this.movingTexture = this.getVerticalLinesTexture();
        this.unknownTexture = this.getDiagonalLinesTexture();
    };

    initializeRegionLevelContainers = () => {
        const {
            session: { regionLevels, earliestTime, latestTime },
        } = this.props;

        regionLevels.forEach((level) => {
            const container = new Container();

            container.x = this.timestampToXCoordinate(earliestTime);
            container.y = this.regionLevelToYCoordinate(level, false);
            container.width = this.getTimelineWidth();
            container.height = regionLevelBar.height + 2 * regionLevelBar.borderWidth;

            // Draw the top border of the region level
            container.addChild(
                new Graphics()
                    .lineStyle(regionLevelBar.borderWidth, 0xeaeaea, 1, 0)
                    .moveTo(this.timestampToXCoordinate(earliestTime), 0)
                    .lineTo(this.timestampToXCoordinate(latestTime), 0)
            );

            // Draw the bottom border of the region level
            container.addChild(
                new Graphics()
                    .lineStyle(regionLevelBar.borderWidth, 0xeaeaea, 1, 0)
                    .moveTo(
                        this.timestampToXCoordinate(earliestTime),
                        regionLevelBar.height + regionLevelBar.borderWidth
                    )
                    .lineTo(
                        this.timestampToXCoordinate(latestTime),
                        regionLevelBar.height + regionLevelBar.borderWidth
                    )
            );

            this.timelineContainer.addChild(container);
            this.regionLevelContainers[level] = container;
        });

        const container = new Container();

        container.x = this.timestampToXCoordinate(earliestTime);
        container.y = this.getMovementYCoordinate();
        container.width = this.getTimelineWidth();
        container.height = regionLevelBar.height + 2 * regionLevelBar.borderWidth;

        this.timelineContainer.addChild(container);
        this.regionLevelContainers[MOVEMENT] = container;
    };

    initializeScrollBarContainer = () => {
        const {
            session: { earliestTime },
        } = this.props;

        const container = new Container();

        container.x = TIMELINE_X_COORDINATE + this.timestampToXCoordinate(earliestTime);
        container.y = this.getScrollBarYCoordinate();
        container.width = this.getTimelineWidth();
        container.height = scrollBar.height;

        this.app.stage.addChild(container);
        this.scrollBarContainer = container;
    };

    initializeRegionLevelsYCoordinatesRanges = () => {
        const {
            session: { regionLevels },
        } = this.props;

        regionLevels.forEach((level) => {
            const y = this.regionLevelToYCoordinate(level, false);
            this.regionLevelsYCoordinatesRanges.push({
                start: y,
                end: y + regionLevelBar.height + 2 * regionLevelBar.borderWidth,
            });
        });

        const y = this.getMovementYCoordinate(false);
        this.regionLevelsYCoordinatesRanges.push({
            start: y,
            end: y + regionLevelBar.height + 2 * regionLevelBar.borderWidth,
        });
    };

    initializeFloorIndicatorsContainer = () => {
        const {
            session: { earliestTime },
        } = this.props;

        const container = new Container();

        container.x = this.timestampToXCoordinate(earliestTime);
        container.y = this.getFloorIndicatorBarsYCoordinate();
        container.width = this.getTimelineWidth();
        container.height = floorIndicators.bar.height + floorIndicators.bar.topBottomPadding;

        this.timelineContainer.addChild(container);
        this.floorIndicatorsContainer = container;
    };

    /**
     * Returns the width of the timeline itself (not including the labels for the region levels)
     * @returns {number}
     */
    getTimelineWidth = () => {
        return window.innerWidth - 120 - TIMELINE_X_COORDINATE;
    };

    handleContainerResize = (width) => {
        if (this.isInitialized && this.mainContainerWidth !== width) {
            const {
                session: { latestTime, earliestTime },
            } = this.props;

            if (!this.initialWidth) {
                this.initialWidth = this.mainContainerWidth;
            }

            this.widthDifferenceFromInitial = this.initialWidth / width;
            this.mainContainerWidth = width;
            this.app.view.width = width;

            this.millisecondsPerPixel =
                (new Date(latestTime) - new Date(earliestTime)) / this.getTimelineWidth();

            // Resize all the region bars' width
            Object.values(this.regionLevelContainers).forEach((container) => {
                container.width = this.getTimelineWidth();
            });

            // Resize the floor label' width
            this.floorIndicatorsContainer.width = this.getTimelineWidth();
            this.drawnElements.floorIndicatorLabels.forEach((label) => {
                // Since the floor indicator labels are children of the floor label container,
                // which itself is a child of the timeline container, we need to scale it down based
                // on both of the containers' scales to make up for the zoom level
                label.scale.x =
                    (1 / this.floorIndicatorsContainer.scale.x) * (1 / this.timelineContainer.scale.x);
            });

            // Hacky fix to sometimes re-scaling the floor indicator labels changes the floor container's width
            this.floorIndicatorsContainer.width = this.getTimelineWidth();

            this.drawnElements.movementsPatternBackground.forEach((visit) => {
                visit.tileScale.x = visit.initialTileScaleX * this.widthDifferenceFromInitial;
            });

            // Resize the scroll bar
            const { xStart, xEnd } = this.getCurrentlyViewedXCoordinates();
            this.drawnElements.scrollBar.width = this.getTimelineWidth();
            this.drawnElements.displayRangeBar.x = xStart;
            this.drawnElements.displayRangeBar.width = xEnd - xStart;
            this.drawnElements.displayRangeHandles[0].x = xStart + scrollBar.handles.radius;
            this.drawnElements.displayRangeHandles[1].x = xEnd - scrollBar.handles.radius;
            this.setTimelineContainerMask();
            this.setTimelineContainerHitArea();

            this.drawAllTimeIndicators(true);
        }
    };

    /**
     * Draws all timeline and label elements
     */
    drawAllElements = () => {
        const {
            session: { visits, movements, regionLevels },
        } = this.props;

        visits.byTime.forEach((visit) => {
            this.drawVisit(visit);
        });

        movements.byTime.forEach((movement) => {
            this.drawMovement(movement);
        });

        regionLevels.forEach((level) => {
            this.drawRegionLevelLabel(level);
        });

        this.drawMovementLevelLabel();
        this.drawAllTimeIndicators();
        this.drawScrollBar();
        this.drawFloorIndicatorBars();
        this.drawFloorIndicatorLabels();
        this.drawFloorIndicatorSeparators();
    };

    clearAllElements = () => {
        this.app.stage.removeChildren();
        this.initializeDrawnElementsObject();
    };

    getMainContainerHeight = () => {
        const {
            session: { regionLevels },
        } = this.props;

        return (
            mainContainerPaddingTopBottom +
            scrollBar.height +
            scrollBar.bottomPadding +
            (regionLevels.length + 1) * (regionLevelBar.height + 2 * regionLevelBar.borderWidth) +
            regionLevels.length * regionLevelBar.bottomPadding +
            floorIndicators.bar.topBottomPadding +
            floorIndicators.bar.height +
            floorIndicators.bar.topBottomPadding +
            mainContainerPaddingTopBottom +
            20
        );
    };

    setVisitRectangleTransparencyBasedOnSelectedRegion = (visitRectangle) => {
        const { selectedRegionId, selectedRegionLevel } = this.props;

        if (selectedRegionId === null) {
            // If there's not selected region, animate the alpha to 1
            this.animationList.add(new Ease.to(visitRectangle, { alpha: 1 }, 300));
        } else {
            // If there is a selected region animate the alpha to 0.1 or 1 based on if it's the selected region or not
            const newAlpha =
                visitRectangle.data.regionId === selectedRegionId &&
                visitRectangle.data.regionCategoryLevel === selectedRegionLevel
                    ? 1
                    : 0.1;

            this.animationList.add(
                new Ease.to(
                    visitRectangle,
                    {
                        alpha: newAlpha,
                    },
                    300
                )
            );
        }
    };

    /**
     * Receives a date and returns an X coordinate where that date should fall in the timeline X axis
     * @param date
     * @returns {number}
     */
    timestampToXCoordinate = (date) => {
        const {
            session: { earliestTime },
        } = this.props;

        const timeFromStart = new Date(date) - new Date(earliestTime);

        return timeFromStart / this.millisecondsPerPixel;
    };

    /**
     * Receives an X coordinate and returns the timestamp that falls on that coordinate on the X axis
     * The x argument must be in respect to the original scale (scale=1)
     * @param x
     * @returns {Date}
     */
    xCoordinateToTimestamp = (x) => {
        const {
            session: { earliestTime },
        } = this.props;

        const millisecondsFromStart = x * this.millisecondsPerPixel;

        return new Date(new Date(earliestTime).getTime() + millisecondsFromStart);
    };

    /**
     * Receives a region level and returns a Y coordinate where the level should fall in the timeline Y axis
     * @param level
     * @param insideBorders
     * @returns {number}
     */
    regionLevelToYCoordinate = (level, insideBorders = true) => {
        const {
            session: { regionLevels },
        } = this.props;
        const levelIndex = [...regionLevels].sort((a, b) => b - a).indexOf(level);

        return (
            mainContainerPaddingTopBottom +
            scrollBar.height +
            scrollBar.bottomPadding +
            (insideBorders ? regionLevelBar.borderWidth : 0) +
            levelIndex *
                (regionLevelBar.bottomPadding + regionLevelBar.height + 2 * regionLevelBar.borderWidth)
        );
    };

    /**
     * Returns the Y coordinate where the movement level should be rendered
     * @returns {number}
     */
    getMovementYCoordinate = (insideBorders = true) => {
        const {
            session: { regionLevels },
        } = this.props;

        return (
            mainContainerPaddingTopBottom +
            scrollBar.height +
            scrollBar.bottomPadding +
            (insideBorders ? regionLevelBar.borderWidth : 0) +
            regionLevels.length *
                (regionLevelBar.bottomPadding + regionLevelBar.height + 2 * regionLevelBar.borderWidth)
        );
    };

    /**
     * Returns the Y coordinate where the building level indicator bars should be rendered
     * @returns {number}
     */
    getFloorIndicatorBarsYCoordinate = () => {
        const {
            session: { regionLevels },
        } = this.props;

        return (
            mainContainerPaddingTopBottom +
            scrollBar.height +
            scrollBar.bottomPadding +
            (regionLevels.length + 1) * (regionLevelBar.height + 2 * regionLevelBar.borderWidth) +
            regionLevels.length * regionLevelBar.bottomPadding +
            floorIndicators.bar.topBottomPadding
        );
    };

    /**
     * Returns the Y coordinate where the time label should be rendered
     * @returns {number}
     */
    getTimeIndicatorsYCoordinate = () => {
        const {
            session: { regionLevels },
        } = this.props;

        return (
            mainContainerPaddingTopBottom +
            scrollBar.height +
            scrollBar.bottomPadding +
            (regionLevels.length + 1) * (regionLevelBar.height + 2 * regionLevelBar.borderWidth) +
            regionLevels.length * regionLevelBar.bottomPadding +
            floorIndicators.bar.topBottomPadding +
            floorIndicators.bar.height +
            floorIndicators.bar.topBottomPadding
        );
    };

    /**
     * Returns a texture for the moving 'visits' on the movement level in the timeline
     * @returns {PIXI.Texture}
     */
    getVerticalLinesTexture = () => {
        const canvas = document.createElement('canvas');
        canvas.width = 6;
        canvas.height = 8;
        const context = canvas.getContext('2d');

        context.strokeStyle = '#c5c4cd';
        context.lineWidth = 2;
        context.beginPath();
        context.moveTo(3, 0);
        context.lineTo(3, 8);
        context.stroke();

        return Texture.from(canvas);
    };

    /**
     * Returns a texture for the unknown 'visits' on the timeline
     * @returns {PIXI.Texture}
     */
    getDiagonalLinesTexture = () => {
        const canvas = document.createElement('canvas');
        canvas.width = 16;
        canvas.height = 16;
        const context = canvas.getContext('2d');

        context.strokeStyle = '#FF0000';
        context.lineWidth = 2;
        context.beginPath();
        context.moveTo(0, 16);
        context.lineTo(16, 0);
        context.stroke();

        return Texture.from(canvas);
    };

    /**
     * Receives a visit and returns the region's color to render it in
     * @param visit
     * @returns {number}
     */
    getVisitColor = (visit) => {
        const { hex } = chartColors.getFill(visit.regionName);
        return Color(hex).rgbNumber();
    };

    /**
     * Receives a movement and returns its fitting color to render in
     * @param movement
     * @returns {number}
     */
    getMovementColor = (movement) => {
        const { hex } = chartColors.getFill(movement.type);
        return Color(hex).rgbNumber();
    };

    /**
     * Receives a visit object and draws it to the WebGL container where it should be
     * @param visit
     */
    drawVisit(visit) {
        const { onRegionSelect } = this.props;

        // These are all relative to the region level container
        const x = this.timestampToXCoordinate(visit.startTime);
        const y = regionLevelBar.borderWidth;
        const width = this.timestampToXCoordinate(visit.endTime) - x;
        const height = regionLevelBar.height;
        const color = this.getVisitColor(visit);

        const visitRectangle = this.getRectangle(visit, x, y, width, height, color, false);

        // Make the visit rectangle interactive and clickable
        visitRectangle.interactive = true;
        visitRectangle.buttonMode = true;

        // Set mouse & touch events
        visitRectangle
            .on('pointerup', () => {
                if (
                    this.currentlyDraggingElement === TIMELINE_CONTAINER &&
                    !this.hasDraggedTimelineSincePointerDown
                ) {
                    onRegionSelect(visit.regionId, visit.regionCategoryLevel);
                }
            })
            .on('pointerover', (event) => {
                this.animationList.tint(visitRectangle, 0xe2e2e2, 300); // Darken the background color
                this.setCurrentMouseOverElement(visitRectangle, ELEMENT_TYPE_REGION_VISIT, visit);
            })
            .on('pointerout', () => {
                this.animationList.tint(visitRectangle, 0xffffff, 300); // Restore the original background color
                this.clearCurrentMouseOverElement();
            });

        // Save the rectangle object reference for later possible use
        if (visit.regionName === 'Unknown') {
            this.drawnElements.visitsPatternBackground.push(visitRectangle);
        } else {
            this.drawnElements.visitsSolidBackground.push(visitRectangle);
        }

        this.regionLevelContainers[visit.regionCategoryLevel].addChild(visitRectangle);
    }

    setVisitTooltip = (visit) => {
        const visitRegionColor = this.getVisitColor(visit);

        this.setTooltipColor(visitRegionColor);

        this.setState({
            tooltipContent: `${visit.regionName}:
            ${moment(visit.startTime).format(tooltipTimeFormat)} - ${moment(visit.endTime).format(
                tooltipTimeFormat
            )}
            ${moment.duration(visit.duration).humanize()}`,
        });
    };

    /**
     * Receives a movement object and draws it to the WebGL container where it should be
     * @param movement
     */
    drawMovement(movement) {
        const x = this.timestampToXCoordinate(movement.startTime);
        const width = movement.duration / this.millisecondsPerPixel;

        const color = movement.type === MOVING ? this.movingTexture : this.getMovementColor(movement);

        const movementRectangle = this.getRectangle(
            movement,
            x,
            0,
            width,
            regionLevelBar.height,
            color,
            false,
            movement.type === MOVING
        );

        movementRectangle.interactive = true;

        // Set mouse & touch events
        movementRectangle
            .on('pointerover', () => {
                this.setCurrentMouseOverElement(movementRectangle, ELEMENT_TYPE_MOVEMENT, movement);
            })
            .on('pointerout', () => {
                this.clearCurrentMouseOverElement();
            });

        // Save the rectangle object reference for later possible use
        if (movement.type === MOVING) {
            movementRectangle.initialTileScaleX = movementRectangle.tileScale.x;
            this.drawnElements.movementsPatternBackground.push(movementRectangle);
        } else {
            this.drawnElements.movementsSolidBackground.push(movementRectangle);
        }

        this.regionLevelContainers[MOVEMENT].addChild(movementRectangle);
    }

    setTooltipColor = (color) => {
        document.documentElement.style.setProperty(
            '--user-activity-session-tooltip-background-color',
            Color(color).string()
        );
        document.documentElement.style.setProperty(
            '--user-activity-session-tooltip-text-color',
            Color(color).isDark() ? '#ffffff' : '#333333'
        );
    };

    setMovementTooltip = (movement) => {
        const movementColor = this.getMovementColor(movement);

        this.setTooltipColor(movementColor);

        this.setState({
            tooltipContent: `${movement.type === MOVING ? 'On-the-move' : 'Dwell'}:
            ${moment(movement.startTime).format(tooltipTimeFormat)} - ${moment(movement.endTime).format(
                tooltipTimeFormat
            )}
            ${moment.duration(movement.duration).humanize()}`,
        });
    };

    getRectangle(data, x, y, width, height, color, withBorder, hasPatternTexture = false) {
        if (hasPatternTexture) {
            const tilingSprite = new TilingSprite(color, width, height);
            tilingSprite.x = x;
            tilingSprite.y = y;
            tilingSprite.data = data;

            return tilingSprite;
        } else {
            const rectangle = new Graphics();
            if (withBorder) {
                rectangle.lineStyle(1, 0xebebeb, 1, 0, true);
            }
            rectangle.beginFill(color);
            rectangle.drawRect(0, 0, width, height);
            rectangle.x = x;
            rectangle.y = y;
            rectangle.endFill();
            rectangle.data = data;

            return rectangle;
        }
    }

    drawScrollBar = () => {
        const scrollBarElement = new Graphics()
            .beginFill(0xe2e2e2)
            .drawRoundedRect(0, 0, this.getTimelineWidth(), scrollBar.height, 5)
            .endFill();
        this.scrollBarContainer.addChild(scrollBarElement);
        this.drawnElements.scrollBar = scrollBarElement;

        const { endTimestamp } = this.getCurrentlyViewedTimeSpan();

        // Draw the display range indicator
        const displayRangeBar = new Graphics()
            .beginFill(0xff7f50)
            .drawRoundedRect(0, 0, this.timestampToXCoordinate(endTimestamp), scrollBar.height, 5)
            .endFill();

        displayRangeBar.interactive = true;
        displayRangeBar.cursor = 'grab';

        // Set mouse & touch events on the bar
        displayRangeBar
            .on('pointerdown', this.handleDisplayRangeBarPointerDown)
            .on('pointerup', this.handleDisplayRangeBarPointerUp)
            .on('pointerupoutside', this.handleDisplayRangeBarPointerUp)
            .on('pointermove', this.handleDisplayRangeBarPointerMove);

        // Draw the start handle
        const displayRangeStartHandle = this.getDisplayRangeHandle(scrollBar.handles.radius);

        // Draw the end handle
        const displayRangeEndHandle = this.getDisplayRangeHandle(
            this.getTimelineWidth() - scrollBar.handles.radius
        );

        // Set mouse & touch events on the handles
        displayRangeStartHandle
            .on('pointerdown', this.handleDisplayRangeStartHandlePointerDown)
            .on('pointerup', this.handleDisplayRangeStartHandlePointerUp)
            .on('pointerupoutside', this.handleDisplayRangeStartHandlePointerUp)
            .on('pointermove', this.handleDisplayRangeStartHandlePointerMove);

        displayRangeEndHandle
            .on('pointerdown', this.handleDisplayRangeEndHandlePointerDown)
            .on('pointerup', this.handleDisplayRangeEndHandlePointerUp)
            .on('pointerupoutside', this.handleDisplayRangeEndHandlePointerUp)
            .on('pointermove', this.handleDisplayRangeEndHandlePointerMove);

        this.scrollBarContainer.addChild(displayRangeBar);
        this.scrollBarContainer.addChild(displayRangeStartHandle);
        this.scrollBarContainer.addChild(displayRangeEndHandle);

        this.drawnElements.displayRangeBar = displayRangeBar;
        this.drawnElements.displayRangeHandles.push(displayRangeStartHandle);
        this.drawnElements.displayRangeHandles.push(displayRangeEndHandle);
    };

    drawFloorIndicatorBars = () => {
        const {
            session: { visits },
        } = this.props;

        visits.byFloor.forEach((floorVisit, index) => {
            const { endTime, startTime } = floorVisit;

            // These are all relative to the floor indicator container's dimensions
            const x = this.timestampToXCoordinate(startTime);
            const y = 0;
            const width = this.timestampToXCoordinate(endTime) - x;
            const height = floorIndicators.bar.height;
            const color = index % 2 === 0 ? '#666666' : '#2b2b2b';

            const floorIndicatorBar = this.getRectangle(
                floorVisit,
                x,
                y,
                width,
                height,
                Color(color).rgbNumber(),
                false,
                false
            );

            floorIndicatorBar.color = color;

            // Enable mouse & touch events
            floorIndicatorBar.interactive = true;

            // Set mouse & touch events
            floorIndicatorBar
                .on('pointerover', () => {
                    this.setCurrentMouseOverElement(floorIndicatorBar, ELEMENT_TYPE_FLOOR_VISIT, floorVisit);
                })
                .on('pointerout', () => {
                    this.clearCurrentMouseOverElement();
                });

            this.floorIndicatorsContainer.addChild(floorIndicatorBar);
            this.drawnElements.floorIndicatorBars.push(floorIndicatorBar);
        });
    };

    drawFloorIndicatorLabels = () => {
        const {
            session: { visits },
        } = this.props;

        visits.byFloor.forEach((floorVisit, index) => {
            const floorIndicatorBar = this.drawnElements.floorIndicatorBars[index];

            const width = floorIndicatorBar.width;
            const height = floorIndicatorBar.height;

            const floorIndicatorLabel = new Text(`Floor ${floorVisit.floorIndex}`, {
                fontSize: floorIndicators.label.fontSize,
                fill: Color(floorIndicatorBar.color).isDark() ? 'white' : 'black',
            });
            floorIndicatorLabel.x = floorIndicatorBar.x + width / 2;
            floorIndicatorLabel.y = height / 2;
            floorIndicatorLabel.anchor.set(0.5, 0.5); // Justify the text to center
            floorIndicatorLabel.resolution = 1.5; // Prevents blurry text
            floorIndicatorLabel.initialWidth = floorIndicatorLabel.width;

            this.floorIndicatorsContainer.addChild(floorIndicatorLabel);
            this.drawnElements.floorIndicatorLabels.push(floorIndicatorLabel);
        });
    };

    drawFloorIndicatorSeparators = () => {
        const {
            session: { visits },
        } = this.props;

        visits.byFloor.forEach((floorVisit, index) => {
            // First floor indicator doesn't need a Separator
            if (index > 0) {
                const { startTime } = floorVisit;

                const floorIndicatorSeparator = new Graphics()
                    .lineStyle(1, 0xffffff, 1, 0, true)
                    .moveTo(this.timestampToXCoordinate(startTime), 0)
                    .lineTo(this.timestampToXCoordinate(startTime), floorIndicators.bar.height);

                this.floorIndicatorsContainer.addChild(floorIndicatorSeparator);
                this.drawnElements.floorIndicatorSeparators.push(floorIndicatorSeparator);
            }
        });
    };

    setFloorIndicatorTooltip = (floorVisit) => {
        this.setTooltipColor('#ffffff');

        const { floorIndex, floorName, startTime, endTime, duration } = floorVisit;

        this.setState({
            tooltipContent: `${floorName} (Floor ${floorIndex}):
            ${moment(startTime).format(tooltipTimeFormat)} - ${moment(endTime).format(tooltipTimeFormat)}
            ${moment.duration(duration).humanize()}`,
        });
    };

    getDisplayRangeHandle = (x) => {
        const handle = new Graphics()
            .beginFill(0xff4800)
            .drawCircle(0, 0, scrollBar.handles.radius)
            .endFill();

        handle.x = x;
        handle.y = scrollBar.height / 2;
        handle.interactive = true;
        handle.cursor = 'col-resize';

        return handle;
    };

    handleDisplayRangeStartHandlePointerDown = (event) => {
        event.stopPropagation();

        // Left click
        if (event.data.button === 0) {
            this.currentlyDraggingElement = DISPLAY_RANGE_START_HANDLE;
        }
    };

    handleDisplayRangeEndHandlePointerDown = (event) => {
        event.stopPropagation();

        // Left click
        if (event.data.button === 0) {
            this.currentlyDraggingElement = DISPLAY_RANGE_END_HANDLE;
        }
    };

    handleDisplayRangeStartHandlePointerUp = (event) => {
        event.stopPropagation();
        this.currentlyDraggingElement = null;
    };

    handleDisplayRangeEndHandlePointerUp = (event) => {
        event.stopPropagation();
        this.currentlyDraggingElement = null;
    };

    handleDisplayRangeStartHandlePointerMove = (event) => {
        if (this.currentlyDraggingElement === DISPLAY_RANGE_START_HANDLE) {
            const startHandle = this.drawnElements.displayRangeHandles[0];
            const endHandle = this.drawnElements.displayRangeHandles[1];

            const { endTimestamp } = this.getCurrentlyViewedTimeSpan();
            const min = scrollBar.handles.radius;
            const max = this.timestampToXCoordinate(
                moment(endTimestamp).subtract(minDisplayedDuration.count, minDisplayedDuration.timeUnit)
            );

            const x = Math.min(Math.max(event.data.getLocalPosition(startHandle.parent).x, min), max);

            startHandle.x = x;

            // Adjust the display range bar
            this.drawnElements.displayRangeBar.x = x;
            this.drawnElements.displayRangeBar.width = endHandle.x - x;

            this.handleDisplayRangeIndicatorChange(x, endHandle.x);
        }
    };

    handleDisplayRangeEndHandlePointerMove = (event) => {
        const {
            session: { latestTime },
        } = this.props;

        if (this.currentlyDraggingElement === DISPLAY_RANGE_END_HANDLE) {
            const startHandle = this.drawnElements.displayRangeHandles[0];
            const endHandle = this.drawnElements.displayRangeHandles[1];

            const { startTimestamp } = this.getCurrentlyViewedTimeSpan();
            const min = this.timestampToXCoordinate(
                moment(startTimestamp).add(minDisplayedDuration.count, minDisplayedDuration.timeUnit)
            );
            const max = this.timestampToXCoordinate(latestTime) - scrollBar.handles.radius;

            const x = Math.min(Math.max(event.data.getLocalPosition(endHandle.parent).x, min), max);

            endHandle.x = x;

            // Adjust the display range bar
            this.drawnElements.displayRangeBar.width = x - startHandle.x + scrollBar.handles.radius;

            this.handleDisplayRangeIndicatorChange(startHandle.x, x);
        }
    };

    handleDisplayRangeBarPointerDown = (event) => {
        event.stopPropagation();

        // Left click
        if (event.data.button === 0) {
            this.currentlyDraggingElement = DISPLAY_RANGE_BAR;

            if (this.isDisplayRangeBarClickedOnce) {
                this.handleDisplayRangeBarDoubleClick();
            }
            this.isDisplayRangeBarClickedOnce = false;
            clearTimeout(this.displayRangeBarDoubleClickTimeout);
        }
    };

    handleDisplayRangeBarDoubleClick = () => {
        const {
            session: { earliestTime, latestTime },
        } = this.props;
        this.zoomOnTimestamps(earliestTime, latestTime, true);
    };

    handleDisplayRangeBarPointerUp = (event) => {
        event.stopPropagation();

        this.currentlyDraggingElement = null;

        this.isDisplayRangeBarClickedOnce = true;
        this.displayRangeBarDoubleClickTimeout = setTimeout(() => {
            this.isDisplayRangeBarClickedOnce = false;
        }, 500);
    };

    handleDisplayRangeBarPointerMove = (event) => {
        const {
            session: { latestTime },
        } = this.props;

        const { pageX } = event.data.originalEvent;

        const { width: barWidth } = this.drawnElements.displayRangeBar;

        if (!this.prevDisplayRangeCursorPosition) {
            this.prevDisplayRangeCursorPosition = pageX;
        }

        if (this.currentlyDraggingElement === DISPLAY_RANGE_BAR) {
            const x = Math.min(
                Math.max(
                    this.drawnElements.displayRangeBar.x - (this.prevDisplayRangeCursorPosition - pageX),
                    0
                ),
                this.timestampToXCoordinate(latestTime) - barWidth
            );

            this.drawnElements.displayRangeBar.x = x;
            this.drawnElements.displayRangeHandles[0].x = x;
            this.drawnElements.displayRangeHandles[1].x = x + barWidth;

            this.handleDisplayRangeIndicatorChange(x, x + barWidth);
        }

        this.prevDisplayRangeCursorPosition = pageX;
    };

    handleDisplayRangeIndicatorChange = (xStart, xEnd) => {
        this.zoomOnTimestamps(this.xCoordinateToTimestamp(xStart), this.xCoordinateToTimestamp(xEnd));
    };

    getScrollBarYCoordinate = () => {
        return mainContainerPaddingTopBottom;
    };

    /**
     * Draws the label for the given region level at the appropriate position ("Level 1", "Level 2", ...)
     * @param level
     */
    drawRegionLevelLabel = (level) => {
        const y = this.regionLevelToYCoordinate(level);

        const label = new Text(`Level ${level}`, {
            fontSize: 16,
        });
        label.x = 0;
        label.y = y;

        this.app.stage.addChild(label);

        // Save the label object reference for later possible use
        this.drawnElements.regionLevelLabels.push(label);
    };

    /**
     * Draws the label for the movement timeline bar at the appropriate position ("Movement")
     */
    drawMovementLevelLabel = () => {
        const label = new Text(`Movement`, {
            fontSize: 16,
        });
        label.x = 0;
        label.y = this.getMovementYCoordinate();

        this.app.stage.addChild(label);

        // Save the label object reference for later possible use
        this.drawnElements.regionLevelLabels.push(label);
    };

    /**
     * Draws all the lines and labels for the time label in their appropriate position and format
     * If force = true, it will draw them even if the time interval hasn't changed
     */
    drawAllTimeIndicators = (force = false) => {
        const {
            session: { earliestTime, latestTime },
        } = this.props;

        const { startTimestamp, endTimestamp } = this.getCurrentlyViewedTimeSpan();

        // Find the currently fitting time interval
        const fittingTimeIntervalIndex = this.getFittingTimeIntervalIndex(startTimestamp, endTimestamp);

        // If it's not the time interval that currently applied
        if (force || fittingTimeIntervalIndex !== this.currentTimeIntervalIndex) {
            this.currentTimeIntervalIndex = fittingTimeIntervalIndex;
            const timeInterval = timeIntervals[fittingTimeIntervalIndex];

            this.clearAllTimeIndicators();

            const sessionRange = moment.range(earliestTime, latestTime);

            // Calculate all the timestamps we're going to render a time indicator in
            // Filter those who either don't fall within the session range or don't have
            // the minimum distance from the edges to render
            const timestampsToRenderIn = Array.from(
                sessionRange.snapTo('hour').by(timeInterval.timeUnit, {
                    step: timeInterval.count,
                })
            ).filter((timestamp) => {
                const x = this.timestampToXCoordinate(timestamp);
                return (
                    sessionRange.contains(timestamp) &&
                    x >= timeIndicators.minPaddingFromEdges / this.timelineContainer.scale.x &&
                    x <=
                        this.getTimelineWidth() -
                            timeIndicators.minPaddingFromEdges / this.timelineContainer.scale.x
                );
            });

            timestampsToRenderIn.forEach((timestamp) => {
                const x = this.timestampToXCoordinate(timestamp);

                const label = new BitmapText(
                    moment(timestamp).format(timeIntervals[fittingTimeIntervalIndex].format),
                    {
                        font: '14px Arial',
                    }
                );
                label.x = x;
                label.y = this.getTimeIndicatorsYCoordinate() + 10;
                label.anchor.set(0.5, 0.5); // Justify the text to center
                label.resolution = 1.5; // Prevents blurry text
                label.data = { timestamp };
                label.initialWidth = label.width;

                // Create the line for the time indicator
                const line = new Graphics();
                line.lineStyle(2, 0xeaeaea, 1, 1, true);
                line.moveTo(x, this.getTimeIndicatorsYCoordinate());
                line.lineTo(x, 0);
                line.zIndex = -1; // Place the line behind the timeline bars
                line.data = { timestamp };

                this.timelineContainer.addChild(label);
                this.timelineContainer.addChild(line);

                // Save the label and line objects for later possible use
                this.drawnElements.timeIndicatorLabels.push(label);
                this.drawnElements.timeIndicatorLines.push(line);
            });
        }
    };

    /**
     * Remove all previously rendered lines and labels
     */
    clearAllTimeIndicators = () => {
        this.drawnElements.timeIndicatorLabels.forEach((label) => {
            this.timelineContainer.removeChild(label);
        });

        this.drawnElements.timeIndicatorLines.forEach((line) => {
            this.timelineContainer.removeChild(line);
        });

        this.drawnElements.timeIndicatorLabels = [];
        this.drawnElements.timeIndicatorLines = [];
    };

    /**
     * Returns the fitting time interval for the currently viewed range out of the defined time intervals
     * @param startTimestamp
     * @param endTimestamp
     * @returns {number}
     */
    getFittingTimeIntervalIndex = (startTimestamp, endTimestamp) => {
        return timeIntervals.findIndex((interval, index) => {
            // Count how many units of time are in the current time interval
            const count = moment
                .duration(moment(endTimestamp).diff(moment(startTimestamp)))
                .as(interval.timeUnit);

            // If there's enough for the number of needed time label, return it
            // If no time interval fits, return the last one
            return count / interval.count < timeIndicators.maxCount || index === timeIntervals.length - 1;
        });
    };

    /**
     * Returns the timestamps of the start and end of the currently viewed range
     * @returns {{endTimestamp: (*|Date), startTimestamp: (*|Date)}}
     */
    getCurrentlyViewedTimeSpan = () => {
        const { xStart, xEnd } = this.getCurrentlyViewedXCoordinates();

        const startTimestamp = this.xCoordinateToTimestamp(xStart);
        const endTimestamp = this.xCoordinateToTimestamp(xEnd);

        return { startTimestamp, endTimestamp };
    };

    getCurrentlyViewedTimeSpanDuration = () => {
        const { startTimestamp, endTimestamp } = this.getCurrentlyViewedTimeSpan();

        return moment(endTimestamp).diff(moment(startTimestamp));
    };

    /**
     * Returns the X coordinates of the start and end of the currently viewed range (in respect to original scale)
     * @returns {{xEnd: *, xStart: number}}
     */
    getCurrentlyViewedXCoordinates = () => {
        const { scale, position } = this.timelineContainer;
        return {
            xStart: (TIMELINE_X_COORDINATE - position.x) / scale.x,
            xEnd: (this.mainContainerWidth - position.x) / scale.x,
        };
    };

    handleWheelScroll = (event) => {
        event.stopPropagation();
        event.preventDefault();

        // Calculate the X coordinate of the mouse relative to the WebGL container
        const mouseXCoordinate =
            event.pageX - this.containerRef.current.getBoundingClientRect().left - TIMELINE_X_COORDINATE;

        // If the mouse scrolled in the timeline container and not in a non-zoomable area
        if (mouseXCoordinate > 0) {
            this.zoomOnXAxis(
                event.deltaY < 0,
                event.pageX - this.containerRef.current.getBoundingClientRect().left
            );
        }
    };

    getZoomMultiplier = (isZoomIn) => {
        const currentDurationUnitsCount = moment
            .duration(this.getCurrentlyViewedTimeSpanDuration(), 'milliseconds')
            .as(minDisplayedDuration.timeUnit);

        // If zooming in with the default multiplier goes beyond the max zoom level,
        // return a custom multiplier to get to that max zoom and not beyond
        if (isZoomIn && currentDurationUnitsCount / defaultZoomInMultiplier < minDisplayedDuration.count) {
            return currentDurationUnitsCount / minDisplayedDuration.count;
        }

        return isZoomIn ? defaultZoomInMultiplier : defaultZoomOutMultiplier;
    };

    zoomOnXAxis = (isZoomIn, x) => {
        const { scale, position } = this.timelineContainer;
        const zoomMultiplier = this.getZoomMultiplier(isZoomIn);

        // Only zoom in if possible
        if (!isZoomIn || this.canZoomIn()) {
            const newScaleX = this.timelineContainer.scale.x * zoomMultiplier;

            const cursorPosition = (x - position.x) / scale.x;

            const newXPosition = cursorPosition * newScaleX;

            const zoomAnimation = new Ease.to(this.timelineContainer, {}, 200, {
                ease: 'easeOutCubic',
            });

            const isFullyZoomedOut = newScaleX <= 1;

            zoomAnimation.goto = {
                position: {
                    x: isFullyZoomedOut
                        ? TIMELINE_X_COORDINATE
                        : Math.min(x - newXPosition, TIMELINE_X_COORDINATE),
                    y: position.y,
                },
                scale: { x: isFullyZoomedOut ? 1 : newScaleX, y: scale.y },
            };

            // This is a hack fix because pixi-ease have a bug parsing an object of "x" and "y" for "scale"
            zoomAnimation.restart();

            // Re-render and re-scale all needed elements on each update of the animation
            zoomAnimation.on('each', () => this.handleZooming());

            zoomAnimation.on('done', this.handleDisplayedRangeChange);

            this.animationList.add(zoomAnimation);
        }
    };

    zoomOnTimestamps(startTimestamp, endTimestamp, adjustScrollbar = false) {
        const {
            session: { earliestTime, latestTime },
        } = this.props;
        const { scale, position } = this.timelineContainer;

        const start = new Date(startTimestamp);
        const end = new Date(endTimestamp);
        const earliest = new Date(earliestTime);
        const latest = new Date(latestTime);

        if (start >= earliest && end <= latest) {
            const startInSeconds = start - earliest;
            const endInSeconds = end - earliest;
            const newScaleX = (latest - earliest) / (endInSeconds - startInSeconds);

            const zoomAnimation = new Ease.to(this.timelineContainer, {}, 200, {
                ease: 'easeOutCubic',
            });

            zoomAnimation.goto = {
                position: {
                    x: TIMELINE_X_COORDINATE - this.timestampToXCoordinate(startTimestamp) * newScaleX,
                    y: position.y,
                },
                scale: { x: newScaleX, y: scale.y },
            };

            // This is a hack fix because pixi-ease have a bug parsing an object of "x" and "y" for "scale"
            zoomAnimation.restart();

            // Re-render and re-scale all needed elements on each update of the animation
            zoomAnimation.on('each', () => this.handleZooming());

            zoomAnimation.on('done', () => this.handleDisplayedRangeChange(adjustScrollbar));

            this.animationList.add(zoomAnimation);
        }
    }

    handleZooming = () => {
        const newWidth = this.timelineContainer.width + TIMELINE_X_COORDINATE;
        const widthDifferenceRatio = this.initialWidth / newWidth;

        this.drawAllTimeIndicators();

        const rescaleTilingSprite = (element) => {
            element.tileScale.x = element.initialTileScaleX * widthDifferenceRatio;
        };

        // Re-scale all elements that require it to avoid them looking blurry and stretched
        this.drawnElements.visitsPatternBackground.forEach(rescaleTilingSprite);
        this.drawnElements.movementsPatternBackground.forEach(rescaleTilingSprite);

        // Rescale all labels
        this.drawnElements.timeIndicatorLabels.forEach((label) => {
            label.scale.x = 1 / this.timelineContainer.scale.x;
        });
        this.drawnElements.floorIndicatorLabels.forEach((label) => {
            // Since the floor indicator labels are children of the floor label container,
            // which itself is a child of the timeline container, we need to scale it down based
            // on both of the containers' scales to make up for the zoom level
            label.scale.x =
                (1 / this.floorIndicatorsContainer.scale.x) * (1 / this.timelineContainer.scale.x);
        });
    };

    canZoomIn = () => {
        return (
            moment.duration(this.getCurrentlyViewedTimeSpanDuration()).as(minDisplayedDuration.timeUnit) >
            minDisplayedDuration.count
        );
    };

    handleDisplayedRangeChange = (adjustScrollbar = true) => {
        const {
            onZoomEnd,
            session: { latestTime },
        } = this.props;

        const debouncedOnZoomEnd = debounce(() => {
            onZoomEnd(this.getCurrentlyViewedTimeSpan());
        }, 500);

        if (adjustScrollbar) {
            const { startTimestamp, endTimestamp } = this.getCurrentlyViewedTimeSpan();

            const xStart = Math.max(this.timestampToXCoordinate(startTimestamp), scrollBar.handles.radius);
            const xEnd = Math.min(
                this.timestampToXCoordinate(endTimestamp),
                this.timestampToXCoordinate(latestTime) - scrollBar.handles.radius
            );

            this.animationList.add(
                new Ease.to(
                    this.drawnElements.displayRangeBar,
                    {
                        x: xStart,
                        width: xEnd - xStart,
                    },
                    200
                )
            );

            this.animationList.add(
                new Ease.to(
                    this.drawnElements.displayRangeHandles[0],
                    {
                        x: xStart,
                    },
                    200
                )
            );

            this.animationList.add(
                new Ease.to(
                    this.drawnElements.displayRangeHandles[1],
                    {
                        x: xEnd,
                    },
                    200
                )
            );
        }

        debouncedOnZoomEnd();
    };

    setCurrentMouseOverElement = (element, type, data, showTooltip = true) => {
        this.currentMouseOver.element = element;
        this.currentMouseOver.showTooltip = showTooltip;

        if (showTooltip) {
            switch (type) {
                case ELEMENT_TYPE_REGION_VISIT:
                    this.setVisitTooltip(data);
                    break;
                case ELEMENT_TYPE_MOVEMENT:
                    this.setMovementTooltip(data);
                    break;
                case ELEMENT_TYPE_FLOOR_VISIT:
                    this.setFloorIndicatorTooltip(data);
                    break;
                default:
                    break;
            }

            this.tippyInstance.show();
        }
    };

    clearCurrentMouseOverElement = () => {
        this.currentMouseOver.element = null;
        this.currentMouseOver.showTooltip = false;
        this.tippyInstance.hide();
    };

    handleTimelineContainerPointerDown = (event) => {
        // Left click
        if (event.data.button === 0) {
            this.currentlyDraggingElement = TIMELINE_CONTAINER;
        }
    };

    handleTimelineContainerPointerUp = () => {
        this.currentlyDraggingElement = null;
        this.hasDraggedTimelineSincePointerDown = false;
    };

    handleTimelineContainerPointerMove = (event) => {
        const { pageX } = event.data.originalEvent;

        if (!this.prevCursorPosition) {
            this.prevCursorPosition = pageX;
        }

        if (this.currentlyDraggingElement === TIMELINE_CONTAINER) {
            this.tippyInstance.hide();
            this.handleTimelineDragMove(event);
        } else if (!this.currentMouseOver.showTooltip) {
            this.tippyInstance.hide();
        }

        this.prevCursorPosition = pageX;
    };

    handleTimelineDragMove = (event) => {
        const { scale } = this.timelineContainer;
        const { pageX } = event.data.originalEvent;

        this.hasDraggedTimelineSincePointerDown = true;

        const minXCoordinate =
            ((this.mainContainerWidth - TIMELINE_X_COORDINATE) * scale.x - this.mainContainerWidth) * -1;

        // Move the timeline according to the direction of the cursor's movement
        this.timelineContainer.x = Math.min(
            Math.max(this.timelineContainer.x - (this.prevCursorPosition - pageX), minXCoordinate),
            TIMELINE_X_COORDINATE
        );

        this.handleDisplayedRangeChange();
    };

    isMouseOverAnyRegionLevel = (event) => {
        // Handle when the timeline is not currently shown (before it's displayed)
        if (!this.containerRef.current) {
            return false;
        }

        const { pageX, pageY } = event.data.originalEvent;
        const { left, top } = this.containerRef.current.getBoundingClientRect();

        // Calculate the coordinates of the mouse relative to the WebGL container
        const mousePosition = {
            x: pageX - left,
            y: pageY - top,
        };

        if (
            mousePosition.x < TIMELINE_X_COORDINATE ||
            mousePosition.x > TIMELINE_X_COORDINATE + this.getTimelineWidth()
        ) {
            return false;
        }

        for (const range of this.regionLevelsYCoordinatesRanges) {
            if (mousePosition.y >= range.start && mousePosition.y <= range.end) {
                return true;
            }
        }

        return false;
    };

    render() {
        const { tooltipContent } = this.state;
        const { TIMELINE_REGIONS_CONTAINER_ID } = this.selectors;

        const {
            session: { isProcessed },
        } = this.props;

        if (!isProcessed) {
            return null;
        }

        return (
            <Tooltip
                content={tooltipContent}
                followCursor
                visible={true}
                onCreate={(instance) => {
                    this.tippyInstance = instance;
                }}
            >
                <TimelineContainer
                    ref={this.containerRef}
                    id={TIMELINE_REGIONS_CONTAINER_ID}
                    height={this.getMainContainerHeight()}
                >
                    <ReactResizeDetector handleWidth onResize={this.handleContainerResize} />
                </TimelineContainer>

                <TooltipStyles />
            </Tooltip>
        );
    }
}

PositionsTimelineChart.propTypes = {
    session: PropTypes.object.isRequired,
    selectedRegionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedRegionLevel: PropTypes.number,
    onRegionSelect: PropTypes.func.isRequired,
    onZoomEnd: PropTypes.func.isRequired,
};

export default PositionsTimelineChart;
