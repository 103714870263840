import entityAdapter from './laneEntityAdapter';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

export const {
    selectById: selectLaneById,
    selectIds: selectLaneIds,
    selectEntities: selectLaneEntities,
    selectAll: selectAllLanes,
    selectTotal: selectTotalLanes,
} = entityAdapter.getSelectors((state) => state.mapping.lanes);

export const selectHasDeletedLanes = createSelector(selectAllLanes, (lanes) => {
    const DELETED_RAW_DATA_DAY = '2022-06-17';
    return lanes.some(({ createdAt }) => moment(createdAt).isBefore(DELETED_RAW_DATA_DAY));
});
