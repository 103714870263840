import React from 'react';
import { Card, CardContent, Collapse, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Grid from '@material-ui/core/Grid';
import RegionDistributionPieChart from '../charts/RegionDistributionPieChart';
import RegionVisitationDetails from './RegionVisitationDetails';
import MovementDistributionBarChart from '../charts/MovementDistributionBarChart';
import getSelectors from '../Analytics.selectors';
import Select from 'react-select';
import { getSessionsAggregationsWithSessionsObject } from '../../../state-management/analytics/session-analytics/sessionAnalyticsSelectors';
import { connect } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import RegionsLegend from '../charts/RegionsLegend';
import Divider from '@material-ui/core/Divider';

const ExpandedContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    & > :nth-child(1) {
        width: 80%;
        margin-right: 15px;
        display: flex;
        flex-direction: column;
    }

    & > :nth-child(2) {
        width: 20%;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
`;

const TitleWrapper = styled.div`
    display: flex;
`;

const ExpandIconWrapper = styled.div`
    display: flex;
`;

const StyledCardContent = styled(CardContent)`
    && {
        padding: 15px 15px 0;
        min-height: 250px;
    }
`;

const MovementCard = styled(Card)`
    display: flex;
`;

const MovementCardContent = styled(CardContent)`
    && {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
    }
`;

const CardHeader = styled.div`
    height: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
`;

const PieAndLevelSelectorWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const PieWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
`;

const LevelSelectorWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LevelSelectorTitle = styled.div`
    font-size: 18px;
    margin-right: 5px;
`;

const LevelSelector = styled(Select)`
    width: 70px;

    [role='option'] {
        padding: 4px 12px;
    }
`;

const RegionVisitationDetailsWrapper = styled.div`
    max-height: 200px;
    overflow-y: auto;
    height: 100%;
`;

class SessionsGroupCard extends React.Component {
    selectors = getSelectors(this.props.aggregationId).charts;

    state = {
        isCollapsed: true,
        selectedRegionId: null,
        selectedRegionName: null,
        selectedRegionVisits: [],
        selectedRegionLevel: null,
        regionLevelOptions: [],
    };

    componentDidMount() {
        const {
            aggregation: { isProcessed, regionLevels },
        } = this.props;

        if (isProcessed && regionLevels.length > 0) {
            // First region level in the list is the default selected region
            this.handleRegionLevelSelected(regionLevels[0]);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const { aggregation } = this.props;
        const {
            isCollapsed,
            selectedRegionId,
            selectedRegionName,
            selectedRegionVisits,
            selectedRegionLevel,
            regionLevelOptions,
            pieData,
        } = this.state;

        return (
            nextProps.aggregation.isProcessed !== aggregation.isProcessed ||
            nextState.isCollapsed !== isCollapsed ||
            nextState.selectedRegionId !== selectedRegionId ||
            nextState.selectedRegionName !== selectedRegionName ||
            nextState.selectedRegionVisits !== selectedRegionVisits ||
            nextState.selectedRegionLevel !== selectedRegionLevel ||
            nextState.regionLevelOptions !== regionLevelOptions ||
            nextState.pieData !== pieData
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            aggregation: { isProcessed, regionLevels },
        } = this.props;

        // If the data changed, refresh the pie's data and its selected region
        if (
            !prevProps.aggregation.isProcessed &&
            isProcessed &&
            regionLevels.length > 0
        ) {
            this.setState(
                {
                    regionLevelOptions: regionLevels.map(level => ({
                        level,
                    })),
                },
                () => {
                    // First region level in the list is the default selected region
                    this.handleRegionLevelSelected(regionLevels[0]);
                }
            );
        }
    }

    handleRegionSelected = (regionId, regionLevel) => {
        // If the region has no ID (like when selecting a movement), do nothing
        if (!regionId) {
            return;
        }

        const {
            aggregation: { sessions, regions },
        } = this.props;
        const { selectedRegionId, selectedRegionLevel } = this.state;

        // If user clicked on the same region that's already selected
        if (
            regionId === selectedRegionId &&
            regionLevel === selectedRegionLevel
        ) {
            this.setState({
                selectedRegionId: null,
                selectedRegionName: null,
                selectedRegionVisits: [],
            });
        } else {
            const selectedRegion = regions.find(
                r =>
                    r.regionId === regionId &&
                    r.regionCategoryLevel === regionLevel
            );

            this.setState({
                selectedRegionId: regionId,
                selectedRegionName: selectedRegion.regionName,
                selectedRegionVisits: sessions.reduce(
                    (result, session) =>
                        result.concat(
                            session.visits.byTime.filter(
                                v =>
                                    v.regionId === regionId &&
                                    v.regionCategoryLevel === regionLevel
                            )
                        ),
                    []
                ),
                selectedRegionLevel: selectedRegion.regionCategoryLevel,
            });
        }
    };

    handleRegionLevelSelected = level => {
        this.setState({
            selectedRegionLevel: level,
        });
    };

    handleCollapseToggle = () => {
        const {
            aggregation: { isProcessed },
        } = this.props;

        if (isProcessed) {
            this.setState(prevState => ({
                isCollapsed: !prevState.isCollapsed,
            }));
        }
    };

    render() {
        const { aggregation, header, content } = this.props;

        if (!aggregation) {
            return null;
        }

        const {
            isCollapsed,
            selectedRegionId,
            selectedRegionName,
            selectedRegionVisits,
            selectedRegionLevel,
            regionLevelOptions,
        } = this.state;

        const { CARD_CONTAINER_ID, LEVEL_SELECTOR_ID } = this.selectors;

        const { isProcessed, aggregationId } = aggregation;

        return (
            <>
                <Card id={CARD_CONTAINER_ID}>
                    <CardContent>
                        <HeaderWrapper>
                            <TitleWrapper>{header}</TitleWrapper>

                            <ExpandIconWrapper>
                                {!isProcessed && (
                                    <ScaleLoader color={'coral'} />
                                    // TODO color should come from theme
                                )}

                                <IconButton onClick={this.handleCollapseToggle}>
                                    {isCollapsed ? (
                                        <ExpandMoreIcon />
                                    ) : (
                                        <ExpandLessIcon />
                                    )}
                                </IconButton>
                            </ExpandIconWrapper>
                        </HeaderWrapper>

                        {isProcessed && (
                            <Collapse
                                in={!isCollapsed}
                                timeout="auto"
                                unmountOnExit
                            >
                                <ExpandedContentWrapper>
                                    <Card>
                                        <Grid
                                            container
                                            component={StyledCardContent}
                                        >
                                            <Grid
                                                item
                                                xs={3}
                                                component={
                                                    PieAndLevelSelectorWrapper
                                                }
                                            >
                                                <LevelSelectorWrapper>
                                                    <LevelSelectorTitle>
                                                        Region Level:
                                                    </LevelSelectorTitle>

                                                    <LevelSelector
                                                        id={LEVEL_SELECTOR_ID}
                                                        placeholder={''}
                                                        value={regionLevelOptions.find(
                                                            l =>
                                                                l.level ===
                                                                selectedRegionLevel
                                                        )}
                                                        onChange={({ level }) =>
                                                            this.handleRegionLevelSelected(
                                                                level
                                                            )
                                                        }
                                                        options={
                                                            regionLevelOptions
                                                        }
                                                        getOptionLabel={({
                                                            level,
                                                        }) => level}
                                                        getOptionValue={({
                                                            level,
                                                        }) => level}
                                                    />
                                                </LevelSelectorWrapper>

                                                <PieWrapper>
                                                    <RegionDistributionPieChart
                                                        uniqueId={aggregationId}
                                                        data={aggregation}
                                                        selectedRegionId={
                                                            selectedRegionId
                                                        }
                                                        selectedRegionLevel={
                                                            selectedRegionLevel
                                                        }
                                                        onRegionSelected={
                                                            this
                                                                .handleRegionSelected
                                                        }
                                                    />
                                                </PieWrapper>
                                            </Grid>

                                            <Grid item xs={9}>
                                                <CardHeader>
                                                    Time Spent in Regions
                                                </CardHeader>

                                                <RegionVisitationDetailsWrapper>
                                                    <RegionVisitationDetails
                                                        regionName={
                                                            selectedRegionName
                                                        }
                                                        visits={
                                                            selectedRegionVisits
                                                        }
                                                    />
                                                </RegionVisitationDetailsWrapper>
                                            </Grid>
                                        </Grid>

                                        <Divider />

                                        <RegionsLegend
                                            data={aggregation}
                                            onRegionClick={
                                                this.handleRegionSelected
                                            }
                                        />
                                    </Card>

                                    <MovementCard>
                                        <MovementCardContent>
                                            <CardHeader>Movement</CardHeader>

                                            <MovementDistributionBarChart
                                                uniqueId={aggregationId}
                                                data={aggregation}
                                            />
                                        </MovementCardContent>
                                    </MovementCard>
                                </ExpandedContentWrapper>
                            </Collapse>
                        )}

                        {content}
                    </CardContent>
                </Card>
            </>
        );
    }
}

SessionsGroupCard.propTypes = {
    aggregationId: PropTypes.string.isRequired,
    header: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    aggregation: getSessionsAggregationsWithSessionsObject(state)[
        ownProps.aggregationId
    ],
});

export default connect(mapStateToProps)(SessionsGroupCard);
