import LogRocket from 'logrocket';
import settings from '../clientSettings';

const { logRocketAppId } = settings;

class Logger {
    init() {
        LogRocket.init(logRocketAppId, { release: process.env.REACT_APP_BUILD_VERSION });
    }

    log(...args) {
        LogRocket.log(...args);
    }

    info(...args) {
        LogRocket.info(...args);
    }

    debug(...args) {
        LogRocket.debug(...args);
    }

    warn(...args) {
        LogRocket.warn(...args);
    }

    error(...args) {
        LogRocket.error(...args);
    }
}

export default new Logger();
