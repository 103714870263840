import React, { useContext, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { MapViewerContext } from '../../MapViewerContext';
import {
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import { ReactComponent as ArrowSvg } from '../../../../../assets/oriientArrow.svg';

const Circle = styled.circle.attrs(() => ({
    style: { r: `calc(8px / var(${SCALE}))` },
}))`
    fill: ${(props) => props.$color};
`;

const Arrow = styled(({ $heading, $color, ...otherProps }) => <ArrowSvg {...otherProps} />)`
    overflow: visible;

    & > path {
        transform-origin: 50% 50%;
        transform: translate(-50%, -50%) scale(calc(0.15 / var(${SCALE})))
            rotate(${(props) => 90 - (props.$heading * 180) / Math.PI}deg);
    }

    #arrow {
        fill: ${(props) => props.$color};
    }
`;

function Mapper(props) {
    const { mapper, mapId } = props;
    const { position, color } = mapper ?? {};

    const { getCSSVariable } = useContext(MapViewerContext);

    const { x, y } = useMemo(() => {
        const mapOffsetX = getCSSVariable(OFFSET_X);
        const mapOffsetY = getCSSVariable(OFFSET_Y);
        const pixelToMeter = getCSSVariable(PIXEL_TO_METER);
        const imageNaturalHeight = getCSSVariable(IMAGE_NATURAL_HEIGHT);

        return {
            x: (position?.x - mapOffsetX) * pixelToMeter,
            y: (imageNaturalHeight / pixelToMeter - (position?.y - mapOffsetY)) * pixelToMeter,
        };
    }, [getCSSVariable, position]);

    if (!position || position?.mapId !== mapId) {
        return null;
    }

    if (position?.source === 'ar') {
        return <Circle cx={x} cy={y} $color={color} />;
    } else if (position?.source === 'user') {
        return <Arrow x={x} y={y} $heading={position?.heading} $color={color} />;
    } else {
        return null;
    }
}

Mapper.propTypes = {
    mapper: PropTypes.object.isRequired,
    mapId: PropTypes.string.isRequired,
};

export default Mapper;
