import React from 'react';
import RegionExternalPlacement from './RegionExternalPlacement';
import { useSelector } from 'react-redux';
import { selectBuildingsHighlightedExternalPlacementIds } from '../../../../../../state-management/user-inputs/buildingsSlice';

export default function GoogleMapsRegionExternalPlacements(props) {
    const { placements, selectedPlacementId, isEditable, onClick, onEdit } = props;

    const highlightedPlacementIds = useSelector(selectBuildingsHighlightedExternalPlacementIds);
    const sortedPlacements = placements.sort((a, b) => {
        return a.positioningGeoMargin - b.positioningGeoMargin;
    })

    return sortedPlacements.map(({ regionId, regionName, regionMetadata, ...placement }, i) => (
        <RegionExternalPlacement
            key={placement?.placementId}
            region={{ regionId, regionName, regionMetadata }}
            placement={placement}
            isSelected={selectedPlacementId === placement?.placementId}
            isHighlighted={highlightedPlacementIds.includes(placement?.placementId)}
            isEditable={selectedPlacementId === placement?.placementId && isEditable}
            onClick={onClick}
            onEdit={onEdit}
            order={i}
            disabled={!(placement.properties?.enableAutoStopPositioningGeo || placement.properties?.enableStartPositioningOnlyInGeo)}
        />
    ));
}
