import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { Paper, RootRef } from '@material-ui/core';
import { Skeleton } from '../common/themed';
import productLocationsApi from '../../api/ProductLocationsApi';

const ImageDetailPanelWrapper = styled(Paper)`
    min-height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
`;
const ImageList = styled.ul`
    list-style: none;
    display: flex;
    place-content: space-between;

    & *:not(:last-child) {
        margin-right: 10px;
    }
    overflow-x: auto;
`;
const ImageListItem = styled.li``;

const Image = styled.img`
    width: 300px;
    cursor: pointer;
`;

export default function ImagePanel(props) {
    const [image, setImages] = useState(null);
    const targetRef = useRef();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const fetchImages = async (spaceId, buildingId, mapId) => {
        const result = (await productLocationsApi.fetchRecordImage({spaceId, buildingId, mapId})).data;
        if(result?.isCustomError) {
            setImages([]);
        }else {
            setImages(result);
        }
        
    };

    useEffect(() => {
        const { spaceId, buildingId, mapId } = props.data;
        fetchImages(spaceId, buildingId, mapId);
    }, []);

    useLayoutEffect(() => {
        if (targetRef.current) {
            setWidth(targetRef.current.offsetWidth);
            setHeight(targetRef.current.offsetHeight);
        }
    }, []);

    if(image && image.length === 0) {
        return <div style={{height: height, width: width / 2, textAlign: 'center' }}>No images</div>
    }

    return (
        <RootRef rootRef={targetRef}>
            <ImageDetailPanelWrapper>
                <ImageList style={{ width: props?.detailWidth }}>
                    {!image ? (
                        <Skeleton height={height} width={width} />
                    ) : (
                        image.map((image, index) => {
                            return (
                                <ImageListItem key={index} onClick={() => props?.setSelectedImage(image)}>
                                    <Image src={image} />
                                </ImageListItem>
                            );
                        })
                    )}
                </ImageList>
            </ImageDetailPanelWrapper>
        </RootRef>
    );
}
