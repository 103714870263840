import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useGoogleMap, Polygon } from '@react-google-maps/api';
import { Portal } from '@material-ui/core';
import {
    InstructionSnackbar,
    InstructionSnackbarButton,
    InstructionSnackbarTextField,
    InstructionText,
} from '../../../common/InstructionSnackbar';
import { getEntranceCreationInstructionSnackbarId } from '../../entrance-creation/MapViewerEntranceCreationOverlay.selectors';
import { MapViewerContext } from '../../../MapViewerContext';
import { createRegion } from '../../../../../../state-management/region/regionActions';
import { useDispatch } from 'react-redux';
import { createRegionExternalPlacement } from '../../../../../../state-management/region-placement/regionPlacementActions';
import { unwrapResult } from '@reduxjs/toolkit';
import { isFulfilled } from '../../../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../../../state-management/notification/notificationReducer';

const INITIAL = 'INITIAL';
const THREE_POINTS_SET = 'THREE_POINTS_SET';
const SHAPE_SET = 'SHAPE_SET';

export default function GoogleMapsRegionExternalPlacementCreation(props) {
    const { spaceId, buildingId, onSubmitted, onCancel } = props;

    const [creationStep, setCreationStep] = useState(INITIAL);
    const [regionName, setRegionName] = useState(INITIAL);

    const polygonRef = useRef();

    const { containerRef } = useContext(MapViewerContext);

    const map = useGoogleMap();

    const dispatch = useDispatch();

    const handlePolygonLoad = (polygon) => {
        polygonRef.current = polygon;
    };

    const handlePolygonMouseUp = () => {
        if (creationStep === INITIAL) {
            const path = polygonRef.current.getPath().getArray();
            if (path.length >= 3) {
                setCreationStep(THREE_POINTS_SET);
            }
        }
    };

    const handleMapClick = useCallback((clc) => {
        if (polygonRef.current) {
            const path = polygonRef.current.getPath().getArray();
            polygonRef.current.setPath([...(path ?? []), { lat: clc.latLng.lat(), lng: clc.latLng.lng() }]);

            const newPath = polygonRef.current.getPath().getArray();

            if (newPath.length >= 3) {
                setCreationStep(THREE_POINTS_SET);
            }
        }
    }, []);

    const handleReset = () => {
        if (polygonRef.current) {
            polygonRef.current.setPath([]);
        }
        setCreationStep(INITIAL);
    };

    const handleSubmit = async () => {
        const path = polygonRef.current.getPath().getArray();

        const createRegionResult = await dispatch(
            createRegion({
                regionData: {
                    regionName,
                    regionMetadata: '{}',
                    regionUsePlai: false,
                    regionUseAnalytics: false,
                },
                spaceId,
            })
        );

        if (isFulfilled(createRegionResult)) {
            const { regionId } = unwrapResult(createRegionResult);
            const createPlacementResult = await dispatch(
                createRegionExternalPlacement({
                    buildingId,
                    regionId,
                    placementData: {
                        shapeType: 'polygon',
                        points: path.map((p) => ({ latitude: p.lat(), longitude: p.lng() })),
                        properties: { enableStartPositioning: true },
                        buildingId,
                    },
                })
            );

            if (isFulfilled(createPlacementResult)) {
                dispatch(showSuccessNotification('Successfully created external region.'));
            } else {
                dispatch(showErrorNotification('Failed to create external region.'));
            }
        } else {
            dispatch(showErrorNotification('Failed to create external region.'));
        }

        onSubmitted();
    };

    const instructions = {
        [INITIAL]: (
            <>
                <InstructionText>
                    Click anywhere on the map to set points to define the region shape.
                </InstructionText>
                <InstructionText>A minimum of 3 points is required to proceed.</InstructionText>
            </>
        ),
        [THREE_POINTS_SET]: (
            <>
                <InstructionText>
                    You may now freely edit the shape, add points and drag it around the map.
                </InstructionText>
                <InstructionText>Once you're done, you may proceed.</InstructionText>
            </>
        ),
        [SHAPE_SET]: (
            <>
                <InstructionText>Provide a name for this external region:</InstructionText>
                <InstructionSnackbarTextField onChange={(e) => setRegionName(e.target.value)} />
            </>
        ),
    };

    const actions = {
        [INITIAL]: null,
        [THREE_POINTS_SET]: (
            <>
                <InstructionSnackbarButton variant={'text'} onClick={handleReset}>
                    Reset
                </InstructionSnackbarButton>

                <InstructionSnackbarButton variant={'text'} onClick={() => setCreationStep(SHAPE_SET)}>
                    Next
                </InstructionSnackbarButton>
            </>
        ),
        [SHAPE_SET]: (
            <>
                <InstructionSnackbarButton variant={'text'} onClick={handleReset}>
                    Reset
                </InstructionSnackbarButton>

                <InstructionSnackbarButton variant={'text'} onClick={handleSubmit} disabled={!regionName}>
                    Submit
                </InstructionSnackbarButton>
            </>
        ),
    };

    useEffect(() => {
        if (map && creationStep === INITIAL) {
            const listener = map.addListener('click', handleMapClick);

            return () => {
                window.google.maps.event.removeListener(listener);
            };
        }
    }, [creationStep, handleMapClick, map]);

    useEffect(() => {
        if (map && creationStep === INITIAL) {
            map.setOptions({ draggableCursor: 'crosshair, auto' });

            return () => {
                map.setOptions({ draggableCursor: 'auto' });
            };
        }
    }, [creationStep, map]);

    return (
        <>
            <Portal container={containerRef.current}>
                <InstructionSnackbar
                    id={getEntranceCreationInstructionSnackbarId()}
                    message={instructions[creationStep]}
                    action={
                        <>
                            <InstructionSnackbarButton variant={'text'} onClick={onCancel}>
                                Cancel
                            </InstructionSnackbarButton>

                            {actions[creationStep]}
                        </>
                    }
                />
            </Portal>

            <Polygon
                onLoad={handlePolygonLoad}
                onMouseUp={handlePolygonMouseUp}
                draggable
                editable
                options={{
                    fillColor: '#ff6200',
                    strokeColor: '#ff4e1d',
                    strokeWeight: 2,
                }}
            />
        </>
    );
}

GoogleMapsRegionExternalPlacementCreation.propTypes = {
    spaceId: PropTypes.string.isRequired,
    buildingId: PropTypes.string.isRequired,
    onSubmitted: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
