import {
    BOUNDARY_MAX_X,
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';

export const convertToPixel = (point, getCSSVariable) => {
    const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
    const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
    const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
    const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

    return {
        x: (+point.x - mapOffsetX) * pixelToMeter,
        y: (imageNaturalHeight / pixelToMeter - (point.y - mapOffsetY)) * pixelToMeter,
    };
};

export const convertToMeterFromEvent = (event, getCSSVariable, refRect, useScale = false) => {
    const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
    const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
    const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
    const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));
    const scale = useScale ? parseFloat(getCSSVariable(SCALE)) : 1;

    return {
        x: (+event.clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
        y: (imageNaturalHeight - (+event.clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
    };
};

export const convertToMeter = (point, getCSSVariable) => {
    const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
    const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
    const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
    const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

    return {
        x: (point.x + mapOffsetX) / pixelToMeter,
        y: (imageNaturalHeight - point.y + mapOffsetY) / pixelToMeter,
    };
};

export const getMinAndMaxPoints = (placement, getCSSVariable) => {
    let maxX, maxY, minX, minY;
    switch (placement.regionType) {
        case 'rectangular': {
            maxX = placement.topRight.x;
            maxY = placement.topRight.y;
            minX = placement.bottomLeft.x;
            minY = placement.bottomLeft.y;
            break;
        }
        case 'circular': {
            maxX = placement.center.x + placement.radius;
            maxY = placement.center.y + placement.radius;
            minX = placement.center.x - placement.radius;
            minY = placement.center.y - placement.radius;
            break;
        }

        case 'polygon': {
            maxX = Math.max(...placement.points.map((point) => point.x));
            minX = Math.min(...placement.points.map((point) => point.x));
            maxY = Math.max(...placement.points.map((point) => point.y));
            minY = Math.min(...placement.points.map((point) => point.y));
            break;
        }
    }

    return {
        maxX,
        maxY,
        minX,
        minY,
    };
};
