export const colorPalette = [
    '#c9f7fa',
    '#bddaea',
    '#f9a364',
    '#f37c7c',
    '#efd9ce',
    '#f0f3bd',
    '#d6efb6',
    '#dedcdb',
    '#cbe9f8',
    '#97d0f0',
    '#f38767',
    '#ff4545',
    '#ffcdb2',
    '#75dec7',
    '#e2e0ac',
    '#dff7fa',
    '#c5cef5',
    '#9bc3d3',
    '#ef6f71',
    '#cc4545',
    '#ffb4a2',
    '#5cc7bc',
    '#96e072',
    '#8ce2ee',
    '#c9c1f1',
    '#8bb8ce',
    '#f05989',
    '#994545',
    '#e5989b',
    '#5eaeb8',
    '#96ba4e',
    '#4e78ad',
    '#c0afea',
    '#6fa7c3',
    '#cd4d8f',
    '#a35b5b',
    '#b5838d',
    '#055d81',
    '#55b946',
    '#60c7dd',
];

const hashString = str => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const charCode = str.charCodeAt(i);
        hash += charCode;
    }
    return hash;
};

export const nameToColor = name => {
    if (!name) {
        return '#000000';
    }

    const hash = hashString(name);
    const index = hash % colorPalette.length;

    return colorPalette[index];
};
