import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './regionPlacementEntityAdapter';
import { fetchAllRegionsInSpace } from '../region/regionActions';
import {
    attachRegionExternalPlacementToEntrance,
    createRegionExternalPlacement,
    createRegionInternalPlacement,
    createRegionInternalPlacements,
    deleteRegionExternalPlacement,
    deleteRegionInternalPlacement,
    detachRegionExternalPlacementFromEntrance,
    updateRegionExternalPlacement,
    updateRegionInternalPlacement,
} from './regionPlacementActions';
import { expireSession, logout } from '../auth/authActions';
import { EXTERNAL, INTERNAL } from '../../constants/regionPlacementTypes';
import { fetchBuildingFullData } from '../building/buildingActions';
import { deleteEntrance } from '../entrance/entranceActions';

const { getInitialState, getSelectors, upsertMany, upsertOne, removeOne, addMany } = entityAdapter;

export const { reducer } = createSlice({
    name: 'regionPlacements',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchAllRegionsInSpace.fulfilled]: (state, action) => {
            const { withPlacements } = action.meta.arg;
            if (withPlacements) {
                upsertMany(
                    state,
                    action.payload.reduce(
                        (result, { regionId, placements }) => [
                            ...result,
                            ...placements.map(
                                ({ mapId, topRight, bottomLeft, center, radius, regionType, points }) => {
                                    let regionData = {};
                                    switch (regionType) {
                                        case 'rectangular':
                                            regionData = {
                                                topRight: { x: topRight[0], y: topRight[1] },
                                                bottomLeft: { x: bottomLeft[0], y: bottomLeft[1] },
                                            };
                                            break;
                                        case 'circular':
                                            regionData = { center: { x: center[0], y: center[1] }, radius };
                                            break;
                                        case 'polygon':
                                            regionData = { points };
                                            break;
                                        default:
                                            break;
                                    }
                                    return {
                                        // TODO we generate the placementId to resolve some bugs when updating newly-created regions in maps.
                                        //  Since placementId isn't being properly used in the REST API, and isn't returned from most APIs yet.
                                        placementId: `${mapId}::${regionId}`,
                                        type: INTERNAL,
                                        regionType,
                                        ...regionData,
                                    };
                                }
                            ),
                        ],
                        []
                    )
                );
            }
        },

        [fetchBuildingFullData.fulfilled]: (state, action) => {
            const { externalRegions = [] } = action.payload;
            upsertMany(
                state,
                externalRegions.reduce(
                    (result, { placements }) => [
                        ...result,
                        ...placements.map((p) => ({ ...p, type: EXTERNAL })),
                    ],
                    []
                )
            );
        },
        [createRegionInternalPlacements.fulfilled]: (state, action) => {
            const { mapId } = action.meta.arg;
            const newRegions = action.payload;

            addMany(
                state,
                newRegions.map(({ newRegion, placement }) => {
                    const { regionType, topRight, bottomLeft, center, radius } = placement;
                    const { regionId } = newRegion;
                    return {
                        regionId,
                        // TODO we generate the placementId to resolve some bugs when updating newly-created regions in maps.
                        //  Since placementId isn't being properly used in the REST API, and isn't returned from most APIs yet.
                        placementId: `${mapId}::${regionId}`,
                        type: INTERNAL,
                        regionType,
                        ...(regionType === 'rectangular'
                            ? {
                                  topRight,
                                  bottomLeft,
                              }
                            : { center, radius }),
                    };
                })
            );
        },
        [createRegionInternalPlacement.fulfilled]: (state, action) => {
            const { regionId, mapId, placementData } = action.meta.arg;
            const { regionType, topRight, bottomLeft, center, radius, points } = placementData;

            let regionData = {};
            switch (regionType) {
                case 'rectangular':
                    regionData = {
                        topRight: { x: topRight[0], y: topRight[1] },
                        bottomLeft: { x: bottomLeft[0], y: bottomLeft[1] },
                    };
                    break;
                case 'circular':
                    regionData = { center: { x: center[0], y: center[1] }, radius };
                    break;
                case 'polygon':
                    regionData = { points };
                    break;
                default:
                    break;
            }
            upsertOne(state, {
                regionId,
                // TODO we generate the placementId to resolve some bugs when updating newly-created regions in maps.
                //  Since placementId isn't being properly used in the REST API, and isn't returned from most APIs yet.
                placementId: `${mapId}::${regionId}`,
                type: INTERNAL,
                regionType,
                ...regionData,
            });
        },

        [updateRegionInternalPlacement.fulfilled]: (state, action) => {
            const { mapId, regionId } = action.meta.arg;
            const { regionType, topRight, bottomLeft, center, radius, points } = action.payload;
            let regionData = {};
            switch (regionType) {
                case 'rectangular':
                    regionData = {
                        topRight: { x: topRight[0], y: topRight[1] },
                        bottomLeft: { x: bottomLeft[0], y: bottomLeft[1] },
                    };
                    break;
                case 'circular':
                    regionData = { center: { x: center[0], y: center[1] }, radius };
                    break;
                case 'polygon':
                    regionData = { points };
                    break;
                default:
                    break;
            }
            upsertOne(state, {
                regionId,
                // TODO we generate the placementId to resolve some bugs when updating newly-created regions in maps.
                //  Since placementId isn't being properly used in the REST API, and isn't returned from most APIs yet.
                placementId: `${mapId}::${regionId}`,
                type: INTERNAL,
                regionType,
                ...regionData,
            });
        },

        [deleteRegionInternalPlacement.fulfilled]: (state, action) => {
            const { regionId, mapId } = action.meta.arg;
            removeOne(state, `${mapId}::${regionId}`);
        },

        [createRegionExternalPlacement.fulfilled]: (state, action) => {
            const { placementId, shapeType, points, properties } = action.payload;
            upsertOne(state, { placementId, type: EXTERNAL, shapeType, points, properties });
        },

        [updateRegionExternalPlacement.fulfilled]: (state, action) => {
            const {
                placementId,
                shapeType,
                points,
                properties,
                positioningGeoMargin,
                stopPositioningMargin,
            } = action.payload;
            upsertOne(state, {
                placementId,
                type: EXTERNAL,
                shapeType,
                points,
                properties,
                positioningGeoMargin,
                stopPositioningMargin,
            });
        },

        [deleteRegionExternalPlacement.fulfilled]: (state, action) => {
            const { placementId } = action.meta.arg;
            removeOne(state, placementId);
        },

        [attachRegionExternalPlacementToEntrance.fulfilled]: (state, action) => {
            const { placementId, entranceId } = action.meta.arg;
            const { entranceIds = [] } = getSelectors().selectById(state, placementId);
            upsertOne(state, { placementId, entranceIds: [...entranceIds, entranceId] });
        },

        [detachRegionExternalPlacementFromEntrance.fulfilled]: (state, action) => {
            const { placementId, entranceId } = action.meta.arg;
            const { entranceIds = [] } = getSelectors().selectById(state, placementId);
            upsertOne(state, { placementId, entranceIds: entranceIds.filter((id) => id !== entranceId) });
        },

        [deleteEntrance.fulfilled]: (state, action) => {
            const { entranceId } = action.meta.arg;
            const allPlacements = getSelectors().selectAll(state);
            upsertMany(
                state,
                allPlacements.map((p) => ({
                    placementId: p.placementId,
                    entranceIds: p.entranceIds?.filter((id) => id !== entranceId),
                }))
            );
        },

        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
    },
});
