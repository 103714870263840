import Api from './Api';
import http from '../utils/httpClient';
class ProductLocationsApi extends Api {
    async fetchInternalProductLocationsData({filter, paginate, sort}) {
        return await this.callApi((params) =>
            http.productLocations.v1.post('/plai/internal', { filter, paginate, sort }, { params })
        );
    }

    async fetchExternalProductLocationsData({filter, paginate, sort}) {
        return await this.callApi((params) =>
            http.productLocations.v1.post('/plai', { filter, paginate, sort }, { params })
        );
    }

    async fetchRecordsCount({filter}) {
        return await this.callApi((params) =>
            http.productLocations.v1.post('/plai/internal/count', { filter }, { params })
        );
    }

    async fetchRecordImage({spaceId, buildingId, mapId}) {
        return await this.callApi((params) =>
            http.productLocations.v1.get('/jobs/images', {
                params: { ...params, spaceId, buildingId, mapId },
            })
        );
    }
    async getSpaceConfig() {
        return await this.callApi((params) =>
            http.productLocations.v1.get('/config', {
                params,
            })
        );
    }
    async updateSpaceConfig(
        {spaceId,
        frequency,
        timezone,
        timeframe,
        isMultiLocation,
        usePlaiRegions,
        singleProductPerRegion}
    ) {
        return await this.callApi((params) =>
            http.productLocations.v1.post(
                '/config',
                {
                    spaceId,
                    frequency,
                    timezone,
                    timeframe,
                    isMultiLocation,
                    usePlaiRegions,
                    singleProductPerRegion,
                },
                {
                    params,
                }
            )
        );
    }
}
export default new ProductLocationsApi();
