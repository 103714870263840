import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Skeleton,
} from '../../common/themed';
import {
    List as MuiList,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Grid,
} from '@material-ui/core';
import { FaUserCircle as UserIcon } from 'react-icons/fa';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import { BsPlus as PlusIcon } from 'react-icons/bs';
import { useForm } from 'react-hook-form';
import zxcvbn from 'zxcvbn';

const CreateUserButton = styled(Button)`
    margin-block-start: 10px;
`;

const FormWrapper = styled.div`
    ${TextField}:not(:last-child) {
        margin-block-end: 15px;
    }
`;

const List = styled(MuiList)`
    height: 100%;
    padding: 0;
    overflow-y: auto;
`;

export default function UsersList(props) {
    const { users, onCreate, onDelete } = props;

    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [deleteDialogUserId, setDeleteDialogUserId] = useState(null);

    const { register, handleSubmit, watch, errors } = useForm({
        defaultValues: {
            userName: '',
            password: '',
        },
    });

    const submitForm = ({ userName, password }) => {
        onCreate({ userName, password });
        setIsCreateDialogOpen(false);
    };

    const handleDelete = (userId) => {
        onDelete(userId);
        setDeleteDialogUserId(null);
    };

    if (!users) {
        return Array.from({ length: 3 }, (_, index) => (
            <Skeleton key={`api-key-user-skeleton-${index}`} height={56} style={{ marginBlockEnd: 2 }} />
        ));
    }

    return (
        <>
            <List>
                {users.map(({ userId, userName, needsToCreate, needsToDelete }, index) => (
                    <ListItem key={`${userId}-${index}`}>
                        <ListItemAvatar>
                            <Avatar>
                                <UserIcon />
                            </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                            primary={userName}
                            primaryTypographyProps={{
                                color: needsToCreate || needsToDelete ? 'textSecondary' : 'initial',
                                style: { textDecoration: needsToDelete ? 'line-through' : 'none' },
                            }}
                        />

                        <ListItemSecondaryAction>
                            {needsToCreate || needsToDelete ? (
                                <Button variant={'outlined'} size={'small'} disabled>
                                    Unsaved
                                </Button>
                            ) : (
                                <IconButton
                                    aria-label={'Delete user'}
                                    onClick={() => setDeleteDialogUserId(userId)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}

                <Grid container justifyContent={'center'}>
                    <CreateUserButton
                        variant={'outlined'}
                        aria-label={'Create user'}
                        onClick={() => setIsCreateDialogOpen(true)}
                    >
                        <PlusIcon size={30} />
                        Create new user
                    </CreateUserButton>
                </Grid>
            </List>

            <Dialog
                open={isCreateDialogOpen}
                onClose={() => setIsCreateDialogOpen(false)}
                maxWidth={'xs'}
                fullWidth
            >
                <DialogTitle>Create new user</DialogTitle>

                <form>
                    <DialogContent>
                        <Grid container direction={'column'} component={FormWrapper}>
                            <TextField
                                name={'userName'}
                                inputRef={register({
                                    required: 'This is required',
                                    validate: (value) =>
                                        !!/^[a-z0-9_-]{3,50}$/gim.test(value) ||
                                        'Username must be 3-50 characters, and include only letters, numbers, dashes and underscores',
                                })}
                                label={'Username'}
                                error={!!errors?.userName}
                                helperText={errors?.userName?.message}
                            />

                            <TextField
                                name={'password'}
                                inputRef={register({
                                    required: 'This is required',
                                    validate: (value) =>
                                        zxcvbn(value).score >= 3 || 'Password is not strong enough',
                                })}
                                label={'Password'}
                                type={'password'}
                                error={!!errors?.password}
                                helperText={errors?.password?.message}
                            />

                            <TextField
                                name={'confirmPassword'}
                                inputRef={register({
                                    validate: (value) =>
                                        value === watch('password') || 'Passwords do not match',
                                })}
                                label={'Confirm password'}
                                type={'password'}
                                error={!!errors?.confirmPassword}
                                helperText={errors?.confirmPassword?.message}
                            />
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button variant={'text'} onClick={() => setIsCreateDialogOpen(false)}>
                            Cancel
                        </Button>

                        <Button onClick={handleSubmit(submitForm)}>Submit</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={!!deleteDialogUserId} closeable={false}>
                <DialogContent>
                    <DialogContentText>
                        You are about to delete the user "
                        {users?.find((u) => u.userId === deleteDialogUserId)?.userName}". Are you sure?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button variant={'text'} onClick={() => setDeleteDialogUserId(null)}>
                        Cancel
                    </Button>

                    <Button onClick={() => handleDelete(deleteDialogUserId)}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

UsersList.propTypes = {
    apiKeyId: PropTypes.string,
    onCreate: PropTypes.func,
    onDelete: PropTypes.func,
};
