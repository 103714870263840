import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Chip, Toolbar, IconButton, Menu, MenuItem, Grid } from '@material-ui/core';
import {
    MdAccountCircle as AccountIcon,
    MdExpandMore as ArrowDownIcon,
    MdMenu as MenuIcon,
} from 'react-icons/md';
import Login from '../login';
import { ReactComponent as Logo } from '../../assets/oriientLogo.svg';
import styled from 'styled-components';
import NavigationMenu from './navigation-menu';
import SpaceSelect from './SpaceSelect';
import {
    selectUserName,
    selectIsLoggedIn,
    selectNeedsToAcceptLatestTermsAndConditions,
} from '../../state-management/auth/authSelectors';
import SessionExpirationDialog from './SessionExpirationDialog';
import { useLocation } from 'react-router-dom';
import {
    getAccountChipId,
    getAccountMenuId,
    getLogoutMenuItemId,
    getMainContentViewportId,
} from './MainLayout.selectors';
import { logout } from '../../state-management/auth/authActions';
import { selectIsLoading } from '../../state-management/status/statusSelectors';
import { PulsePreloader } from '../common/themed';
import { selectCommonIsNavbarOpen, toggleNavbar } from '../../state-management/user-inputs/commonSlice';

const MainUpperNavbar = styled(({ isSideDrawerOpen, ...otherProps }) => <AppBar {...otherProps} />)`
    background-color: white;
    width: 100%;
    transition: width 500ms ease, margin 500ms ease;
    z-index: 1400; // TODO should come from theme
`;

const NavbarLogo = styled(Logo)`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
    width: 160px;
`;

const AccountChip = styled(Chip)`
    & > span {
        padding-left: 12px;
        padding-right: 6px;
    }
`;

const AccountMenu = styled(Menu)`
    z-index: 2500 !important; // TODO should come from theme
`;

const ControlsWrapper = styled.div`
    display: flex;

    & > :not(:last-child) {
        margin-inline-end: 10px;
    }
`;

const MainContentViewport = styled.main`
    width: 100%;
    padding: 20px;
    background-color: #fafafa;
    overflow: auto;
`;

const NavigationMenuAndContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
`;

export default function MainLayout(props) {
    const { children } = props;

    const { pathname } = useLocation();

    const dispatch = useDispatch();

    const selectIsLoggingOut = useMemo(() => selectIsLoading(logout.typePrefix), []);

    const isLoggedIn = useSelector(selectIsLoggedIn);
    const needsToAcceptLatestTermsAndConditions = useSelector(selectNeedsToAcceptLatestTermsAndConditions);
    const username = useSelector(selectUserName);
    const isLoggingOut = useSelector(selectIsLoggingOut);

    const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
    // const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(true);
    const isSideDrawerOpen = useSelector(selectCommonIsNavbarOpen);

    const handleLogout = () => {
        setIsAccountMenuOpen(false);
        dispatch(logout());
    };

    if (!isLoggedIn || needsToAcceptLatestTermsAndConditions) {
        // If the user has not logged in yet, display the login screen
        // If the user logged in, but needs to accept the latest terms, display them for him to accept
        return <Login />;
    }

    const notAllowedSpaceSelect = ['/account-management', '/map-quality'];
    const isSpaceSelectDisabled = notAllowedSpaceSelect.some((url) => pathname.includes(url));
    // const isSpaceSelectDisabled = pathname.includes('/account-management');

    return (
        <>
            <MainUpperNavbar position={'sticky'} isSideDrawerOpen={isSideDrawerOpen}>
                <Toolbar>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <IconButton aria-label="Open drawer" onClick={() => dispatch(toggleNavbar())}>
                            <MenuIcon />
                        </IconButton>

                        <NavbarLogo />

                        <ControlsWrapper>
                            <SpaceSelect disabled={isSpaceSelectDisabled} />

                            <AccountChip
                                id={getAccountChipId()}
                                label={
                                    <Grid container alignItems={'center'}>
                                        {username} <ArrowDownIcon size={20} />
                                    </Grid>
                                }
                                icon={<AccountIcon size={20} />}
                                clickable
                                onClick={() => setIsAccountMenuOpen(true)}
                            />

                            <AccountMenu
                                id={getAccountMenuId()}
                                anchorEl={document.getElementById(getAccountChipId())}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                open={isAccountMenuOpen}
                                onClose={() => setIsAccountMenuOpen(false)}
                            >
                                <MenuItem
                                    id={getLogoutMenuItemId()}
                                    onClick={handleLogout}
                                    disabled={isLoggingOut}
                                >
                                    {isLoggingOut ? <PulsePreloader /> : 'Logout'}
                                </MenuItem>
                            </AccountMenu>
                        </ControlsWrapper>
                    </Grid>
                </Toolbar>
            </MainUpperNavbar>

            <NavigationMenuAndContentWrapper>
                <NavigationMenu isOpen={isSideDrawerOpen} />

                <MainContentViewport id={getMainContentViewportId()}>{children}</MainContentViewport>
            </NavigationMenuAndContentWrapper>

            <SessionExpirationDialog />
        </>
    );
}
