import React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Input from '@material-ui/core/Input/index';
import getSelectors from '../Analytics.selectors';
import InputAdornment from '@material-ui/core/InputAdornment/index';

const LegendBar = styled.div`
    width: 10px;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
    background: linear-gradient(
        0deg,
        blue ${(props) => 40 + 10 * props.valueOffset}%,
        cyan ${(props) => 60 + 10 * props.valueOffset}%,
        lime ${(props) => 70 + 10 * props.valueOffset}%,
        yellow ${(props) => 80 + 10 * props.valueOffset}%,
        red 100%
    );
`;

const MaxValueInput = styled(Input)`
    input {
        padding: 0 0 3px;
    }
`;

const HeatMapLegend = (props) => {
    const { maxValue, minValue, onMaxValueChange, valueOffset } = props;
    const { MAX_DURATION_ID } = getSelectors().heatMap;

    return (
        <>
            <LegendBar valueOffset={valueOffset} />

            <Grid container justifyContent={'space-between'} direction={'column'}>
                <Grid item>
                    <MaxValueInput
                        id={MAX_DURATION_ID}
                        defaultValue={maxValue}
                        type={'number'}
                        onChange={onMaxValueChange}
                        inputProps={{ min: 0, step: 0.1 }}
                        endAdornment={<InputAdornment position="end">Sec</InputAdornment>}
                    />
                </Grid>
                <Grid item>{minValue} Sec</Grid>
            </Grid>
        </>
    );
};

HeatMapLegend.propTypes = {
    maxValue: PropTypes.number.isRequired,
    minValue: PropTypes.number,
    onMaxValueChange: PropTypes.func.isRequired,
    valueOffset: PropTypes.number,
};

HeatMapLegend.defaultProps = {
    maxValue: 10000,
    minValue: 0,
    valueOffset: 0,
};

export default HeatMapLegend;
