import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const DetailTitle = styled(Typography).attrs({
    variant: 'subtitle1',
})``;

export const DetailValue = styled(Typography).attrs({
    variant: 'subtitle2',
})`
    color: #7a7a7a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const SummaryWrapper = styled.div``;
