import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import {
    selectHasError as getSelectHasError,
    selectIsLoading as getSelectIsLoading,
} from '../../../../state-management/status/statusSelectors';
import {
    selectAllApiKeys,
    selectApiKeyById,
    selectApiKeyEntities,
    selectAreApiKeysFetched,
} from '../../../../state-management/api-key/apiKeySelectors';
import { fetchAllApiKeysInAttachedSpaces } from '../../../../state-management/api-key/apiKeyActions';

/**
 * Hook that fetches all API keys that are attached to any of the spaces
 * that are attached to the caller API key. They are fetched into the Redux store.
 * @param asObject - Return all entities as an object, with the IDs as the keys.
 * @param apiKeyId - Specific API key ID to return.
 * @return {{isLoading, data: *, hasError}}
 */
export default function useApiKeys({ asObject = false, apiKeyId } = {}) {
    const dispatch = useDispatch();

    const selectIsLoadingApiKeys = useMemo(
        () => getSelectIsLoading(fetchAllApiKeysInAttachedSpaces.typePrefix),
        []
    );
    const selectHasErrorInApiKeys = useMemo(
        () => getSelectHasError(fetchAllApiKeysInAttachedSpaces.typePrefix),
        []
    );

    const apiKeysArray = useSelector(selectAllApiKeys);
    const apiKeysObject = useSelector(selectApiKeyEntities);
    const apiKey = useSelector((state) => selectApiKeyById(state, apiKeyId));
    const isFetched = useSelector(selectAreApiKeysFetched);
    const isLoading = useSelector(selectIsLoadingApiKeys);
    const hasError = useSelector(selectHasErrorInApiKeys);

    useEffect(() => {
        // If the API keys haven't been fetched yet, fetch them
        if (!isFetched && !isLoading && !hasError) {
            dispatch(fetchAllApiKeysInAttachedSpaces());
        }
    }, [dispatch, isFetched, isLoading, hasError]);

    return {
        data: apiKeyId ? apiKey : asObject ? apiKeysObject : apiKeysArray,
        isLoading: isLoading,
        hasError,
    };
}
