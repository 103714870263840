import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { RootRef } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { columns } from './columns';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';
import { Switch } from '../../common/themed';
import useSpaces from '../../common/hooks/data-fetching/useSpaces';
import { updateSpace } from '../../../state-management/space/spaceActions';
import { isFulfilled } from '../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../state-management/notification/notificationReducer';
import { useState } from 'react';
import { useEffect } from 'react';
import { MultiSpaceUpdateForm } from './MultiSpaceUpdateForm';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
`;

export const SpaceSettingsTable = (props) => {
    const targetRef = useRef();
    const { selectedSpace = {} } = props;
    const dispatch = useDispatch();
    const { isLoading, hasError, data } = useSpaces();
    const [spaces, setSpaces] = useState(data || []);

    useEffect(() => {
        if (!isLoading && data) {
            setSpaces(data);
        }
    }, [data, isLoading]);
    if (hasError) {
        return <ErrorGeneral />;
    }
    const onSpaceChange = async (space) => {
        const result = await dispatch(
            updateSpace({ spaceId: space.spaceId, spaceData: { ...space, bundleIds: space.bundleIds || [] } })
        );
        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Space updated'));
        } else {
            dispatch(showErrorNotification('Space did not updated'));
        }
    };
    const renderSwitch = (space, key) => {
        return (
            <Switch
                checked={space[key] ?? false}
                onChange={(e) => onSpaceChange({ ...space, [key]: e.target.checked })}
            />
        );
    };

    const onSortHandler = (index, direction) => {
        const desiredColumn = columns[index];
        if (!desiredColumn) return;
        const { field } = desiredColumn;
        spaces.sort((row1, row2) => {
            if (direction === 'asc') {
                return row1[field] - row2[field];
            }
            return row2[field] - row1[field];
        });

        setSpaces([...spaces]);
    };

    const renderSpaces = (spaces = []) => {
        return spaces.map((s) => {
            return {
                ...s,
                exportPositionData: renderSwitch(s, 'exportPositionData'),
                regionAnalyticsEnabled: renderSwitch(s, 'regionAnalyticsEnabled'),
                positionAnalyticsEnabled: renderSwitch(s, 'positionAnalyticsEnabled'),
            };
        });
    };
    const onSelectionChange = async (data, rows) => {
        rows.forEach(async (space) => {
            dispatch(
                updateSpace({
                    spaceId: space.spaceId,
                    spaceData: { ...space, bundleIds: space.bundleIds || [], ...data },
                })
            );
        });
    };

    return (
        <RootRef rootRef={targetRef}>
            <Container>
                <MaterialTable
                    isLoading={isLoading}
                    title={'Spaces'}
                    options={{
                        search: true,
                        searchFieldAlignment: 'left',
                        paging: false,
                        headerStyle: {
                            borderBottom: '2px solid #ff6200',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                        },
                        maxBodyHeight: '50vh',
                        minBodyHeight: '50vh',
                        selection: true,
                        toolbarButtonAlignment: 'left',
                    }}
                    columns={columns}
                    data={renderSpaces(spaces)}
                    onOrderChange={onSortHandler}
                    components={{
                        Actions: (props) => {
                            if (!props.data) return <></>;
                            return (
                                <MultiSpaceUpdateForm
                                    onSubmit={(formData) => onSelectionChange(formData, props.data)}
                                />
                            );
                        },
                    }}
                />
            </Container>
        </RootRef>
    );
};
