import React from 'react';
import * as PropTypes from 'prop-types';
import {
    Button,
    CircularPreloader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    PulsePreloader,
} from '../../../common/themed';
import { useDispatch } from 'react-redux';
import useAllFloorsInBuilding from '../../../common/hooks/data-fetching/useAllFloorsInBuilding';
import useAllBuildingsInSpace from '../../../common/hooks/data-fetching/useAllBuildingsInSpace';
import { createMap } from '../../../../state-management/map/mapActions';
import { isFulfilled } from '../../../../state-management/utils';
import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { setSelectedMapId } from '../../../../state-management/user-inputs/buildingsSlice';
import { FaMapMarkedAlt as MapsIcon } from 'react-icons/fa';
import { Divider, Typography } from '@material-ui/core';
import { getCreateMapDialogId, getCreateMapFormErrorId } from './CreateMapDialog.selectors';
import CreateMap from './CreateMap';
import useAllMapsInFloor from '../../../common/hooks/data-fetching/useAllMapsInFloor';

const StepContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 250px;
`;

const IconWrapper = styled.div`
    background-color: #ff6200; // TODO color should come from theme
    width: 100%;
    height: 100%;
    color: white; // TODO color should come from theme
    padding: 0 25px;
    box-sizing: border-box;

    & > svg {
        width: 100%;
        height: 100%;
    }
`;

export default function CreateMapDialog(props) {
    const { onClose, buildingId, floorId } = props;

    const dispatch = useDispatch();

    const {
        data: { [buildingId]: building },
        isLoading: isLoadingBuildings,
    } = useAllBuildingsInSpace({ asObject: true });

    const { buildingName } = building ?? {};
    const {
        data: { [floorId]: floor },
        isLoading: isLoadingFloors,
    } = useAllFloorsInBuilding({ buildingId, asObject: true });

    const { floorName, mapIds } = floor ?? {};

    const { data: mapsInFloor } = useAllMapsInFloor({ buildingId, floorId });

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'onChange',
        defaultValues: {
            mapName: `${buildingName}_${floorName}_map${mapIds?.length > 0 ? `_${mapIds?.length}` : ''}`,
            pixelToMeter: mapsInFloor?.[0]?.pixelToMeter ?? 1,
            imgBase64: '',
            mapOffset: { x: 0, y: 0 },
            mapUsage: '',
        },
    });

    const {
        handleSubmit,
        errors,
        formState: { isValid, isSubmitting },
    } = formMethods;

    const submitForm = async (data) => {
        const result = await dispatch(
            createMap({
                buildingId,
                floorId,
                mapData: {
                    mapName: data.mapName,
                    pixelToMeter: data.pixelToMeter,
                    imgBase64: data.imgBase64,
                    mapOffset: data.mapOffset,
                    mapUsage: data.mapUsage,
                },
            })
        );

        if (isFulfilled(result)) {
            await Promise.all([
                dispatch(showSuccessNotification('Map created successfully.')),
                dispatch(setSelectedMapId(result?.payload?.mapId)),
            ]);
            onClose();
        } else {
            dispatch(showErrorNotification('Failed to create map.'));
        }
    };

    if (isLoadingBuildings || isLoadingFloors) {
        return <PulsePreloader />;
    }

    return (
        <Dialog id={getCreateMapDialogId()} open fullWidth maxWidth={'lg'} onClose={onClose}>
            <DialogTitle>Create a new map</DialogTitle>

            <StepContentWrapper>
                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <DialogContent>
                            <CreateMap buildingId={buildingId} floorId={floorId} onClose={onClose} />
                        </DialogContent>

                        <Divider />

                        <DialogActions>
                            {errors?.custom && (
                                <Typography id={getCreateMapFormErrorId()} color={'error'}>
                                    {errors?.custom?.message}
                                </Typography>
                            )}

                            {isSubmitting ? (
                                <CircularPreloader />
                            ) : (
                                <Button type={'submit'} disabled={!isValid}>
                                    Submit
                                </Button>
                            )}
                        </DialogActions>
                    </form>
                </FormProvider>

                <IconWrapper>
                    <MapsIcon />
                </IconWrapper>
            </StepContentWrapper>
        </Dialog>
    );
}

CreateMapDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
};
