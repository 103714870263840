import { createSlice } from '@reduxjs/toolkit';
import { getActionKey, isFulfilledOrRejected, isPending, getActionTypePrefix } from '../utils';
import { status } from '../constants';
import { expireSession, logout } from '../auth/authActions';

const { LOADING, ERROR } = status;

export const { reducer } = createSlice({
    name: 'status',
    initialState: {},
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(expireSession.fulfilled, () => ({}))
            .addCase(logout.fulfilled, () => ({}))
            .addMatcher(isPending, (state, action) => {
                const {
                    type,
                    meta: { arg, requestId },
                } = action;

                const typePrefix = getActionTypePrefix(type);

                const key = getActionKey(typePrefix, arg);

                // Check if there's an action with the same arg already existing
                // (possible an error that occurred before)
                const actionWithSameArg = state[typePrefix]?.find((a) => a.key === key);

                const executingAction = {
                    key,
                    arg,
                    requestId,
                    currentState: LOADING,
                };

                if (state[typePrefix]) {
                    if (actionWithSameArg) {
                        actionWithSameArg.currentState = LOADING;
                        actionWithSameArg.requestId = requestId;
                    } else {
                        state[typePrefix].push(executingAction);
                    }
                } else {
                    state[typePrefix] = [executingAction];
                }
            })
            .addMatcher(isFulfilledOrRejected, (state, action) => {
                const {
                    type,
                    meta: { arg, requestId },
                    error,
                } = action;
                const typePrefix = getActionTypePrefix(type);

                const key = getActionKey(typePrefix, arg, requestId);

                const endedAction = state[typePrefix]?.find((a) => a.key === key);

                if (error) {
                    if (endedAction) {
                        endedAction.currentState = ERROR;
                        endedAction.error = error;
                    } else {
                        state[typePrefix].push({
                            key,
                            arg,
                            requestId,
                            currentState: ERROR,
                            error,
                        });
                    }
                } else {
                    if (endedAction) {
                        state[typePrefix].splice(state[typePrefix].indexOf(endedAction));

                        if (state[typePrefix].length === 0) {
                            delete state[typePrefix];
                        }
                    }
                }
            }),
});

const INITIAL_STATE = {
    isLoading: {},
    errors: {},
};

export default (state = INITIAL_STATE, action) => {
    const matches = /(.*)_(REQUEST|SUCCESS|ERROR|FAIL)/.exec(action.type);

    // If it's not a REQUEST / SUCCESS / ERROR / FAIL actions, ignore them
    if (!matches) {
        return state;
    }

    // Extract the request name and state from the action type
    const [, requestName, requestState] = matches;

    const { [requestName]: omittedFromIsLoading, ...isLoadingWithoutRequest } = state.isLoading;
    const { [requestName]: omittedFromErrors, ...errorsWithoutRequest } = state.errors;

    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_ERROR
    switch (requestState) {
        case 'REQUEST':
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [requestName]: true,
                },
                errors: errorsWithoutRequest,
            };

        case 'SUCCESS':
            return {
                ...state,
                isLoading: isLoadingWithoutRequest,
                errors: errorsWithoutRequest,
            };

        case 'ERROR':
        case 'FAIL':
            return {
                ...state,
                isLoading: isLoadingWithoutRequest,
                errors: {
                    ...state.errors,
                    [requestName]: action.error || { message: 'ERROR' },
                },
            };

        default:
            return state;
    }
};
