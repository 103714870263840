import React, { forwardRef } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { Typography, Paper, Chip } from '@material-ui/core';
import TextField from './TextField';
import CancelIcon from '@material-ui/icons/Cancel';

const CLASS_NAME_PREFIX = 'themed-select';

const inputComponent = styled(({ inputRef, ...props }) => <div ref={inputRef} {...props} />)`
    display: flex;
    padding: 0;
`;

const Control = styled((props) => (
    <TextField
        className={props.className}
        fullWidth
        InputProps={{
            inputComponent,
            inputProps: {
                inputRef: props.innerRef,
                children: props.children,
                ...props.innerProps,
            },
        }}
        {...props.innerProps}
        {...props.selectProps.textFieldProps}
    />
))`
    ::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-bottom: 1px solid ${(props) => (props.isDisabled ? '#ccc' : 'coral')};
    }

    :hover::after {
        border-bottom: ${(props) => (props.isDisabled ? '1px solid #ccc' : '2px solid coral')};
    }
`;

const Placeholder = styled((props) => (
    <Typography className={props.className} {...props.innerProps}>
        {props.children}
    </Typography>
))`
    position: absolute;
    left: 2px;
    font-size: 1rem;
    color: ${(props) => (props.isDisabled ? '#ababab' : '#808080')};
`;

const SingleValue = styled((props) => (
    <Typography className={props.className} noWrap {...props.innerProps}>
        {props.children}
    </Typography>
))`
    font-size: 1rem;
`;

const ValueContainer = styled((props) => <div className={props.className}>{props.children}</div>)`
    display: flex;
    flex-wrap: ${(props) => (props.selectProps.valueContainerWrap ? 'wrap' : 'nowrap')}
    flex: 1;
    align-items: center;
    overflow: hidden;
`;

const MultiValue = styled((props) => (
    <Chip
        className={props.className}
        classes={{ root: `${CLASS_NAME_PREFIX}-multi-value-option` }}
        tabIndex={-1}
        label={props.children}
        onDelete={props.removeProps.onClick}
        deleteIcon={<CancelIcon fontSize={'small'} {...props.removeProps} />}
    />
))`
    margin: 0 0.1rem;
    height: 1.5rem;
    z-index: 1;
`;

const Menu = styled((props) => (
    <Paper
        elevation={2}
        className={props.className}
        square
        {...props.innerProps}
        style={props.getStyles('menu', props)}
    >
        {props.children}
    </Paper>
))`
    position: absolute;
    z-index: 2 !important;
    margin-top: 0.5rem;
    left: 0;
    right: 0;
`;

const components = {
    Control,
    Menu,
    MultiValue,
    Placeholder,
    SingleValue,
    ValueContainer,
};

export default styled(forwardRef((props, ref) => {
    return (
        <Select components={components} classNamePrefix={CLASS_NAME_PREFIX} {...props} ref={ref} />
    );
}))`
    & {
        height: inherit;
        width: ${({ width = 'auto' }) => width};
        align-self: ${({ alignSelf = 'stretch' }) => alignSelf};
    }
    .${CLASS_NAME_PREFIX}--is-disabled {
        cursor: not-allowed;
    }

    .${CLASS_NAME_PREFIX}__dropdown-indicator {
        color: ${(props) => (props.isDisabled ? '#ccc' : 'coral')};
    }

    .${CLASS_NAME_PREFIX}__indicator-separator {
        background-color: ${(props) => (props.isDisabled ? '#ccc' : 'coral')};
    }

    .${CLASS_NAME_PREFIX}__clear-indicator {
        z-index: 9;
        cursor: pointer;
    }

    .${CLASS_NAME_PREFIX}-label-focused {
        color: coral;
    }

    .${CLASS_NAME_PREFIX}__option {
        background-color: white;
        color: #333;
        cursor: pointer;

        &--is-focused {
            background-color: #ffb369; // TODO color should come from theme
            color: white;
        }

        &--is-selected {
            background-color: coral; // TODO color should come from theme
            color: white;
            font-weight: bold;
        }
    }
`;
