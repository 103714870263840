import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import chartColors from './chartColors';
import { INACCURATE_ID, NON_INTEREST_ID, UNKNOWN_ID } from './chartConstants';

const RegionsLegendWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
    overflow-y: auto;

    & > :not(:last-child) {
        margin-right: 20px;
    }
`;

const RegionLegendEntry = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const ColoredSquare = styled.div`
    background-color: ${(props) => props.backgroundColor || 'black'};
    border: 1px solid #ccc;
    border-radius: 2px;
    width: 15px;
    height: 15px;
    margin-right: 5px;
`;

const RegionTitle = styled.span`
    margin-right: 10px;
`;

export default function RegionsLegend(props) {
    const {
        data: { isProcessed, regions },
        onRegionClick,
    } = props;

    if (!isProcessed) {
        return null;
    }

    const totalDuration = regions.reduce((result, region) => result + region.totalDuration, 0);

    return (
        <RegionsLegendWrapper>
            {regions.map(({ regionId, regionName, totalDuration: regionDuration, regionCategoryLevel }) => (
                <RegionLegendEntry
                    onClick={() => onRegionClick(regionId, regionCategoryLevel)}
                    key={`${regionId}-${regionCategoryLevel}`}
                >
                    <ColoredSquare backgroundColor={chartColors.getFill(regionName)} />
                    <RegionTitle>
                        {regionId === NON_INTEREST_ID || regionId === INACCURATE_ID || regionId === UNKNOWN_ID
                            ? `${regionName} (Level ${regionCategoryLevel})`
                            : regionName}
                    </RegionTitle>
                    {((regionDuration / totalDuration) * 100).toFixed(2)}%
                </RegionLegendEntry>
            ))}
        </RegionsLegendWrapper>
    );
}

RegionsLegend.propTypes = {
    data: PropTypes.object.isRequired,
    onRegionClick: PropTypes.func.isRequired,
};
