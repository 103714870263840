export const getId = (validation) => `(${validation.x},${validation.y})`;

const mismatchGoodThreshold = 1;
const mismatchBadThreshold = 6;

const lightnessDelta = 0.2;

export const getStatusColor = (status) => {
    if (status === null || status === undefined) {
        return null;
    }

    let delta = (status - mismatchGoodThreshold) / (mismatchBadThreshold - mismatchGoodThreshold);
    delta = Math.min(Math.max(delta, 0), 1) * (1 + 2 * lightnessDelta);

    let hue;
    let lightness;

    if (delta <= lightnessDelta) {
        hue = 0.33;
        lightness = 1 - lightnessDelta + delta;
    } else if (delta >= 1 + lightnessDelta) {
        hue = 0;
        lightness = 1 - (delta - 1 - lightnessDelta);
    } else {
        hue = (1 - (delta - lightnessDelta)) * 0.33;
        lightness = 1;
    }

    return `hsla(${hue * 360}deg, 100%, ${lightness * 50}% , 0.8)`;
};

export const mergeAdjacentMapValidationsIntoRows = (validations, resolution) => {
    if (validations.length === 0) {
        return [];
    }

    const rows = [];
    let currRow = null;

    const getId = (x, y) => `${x},${y}`;

    const remainingValidations = validations.reduce((result, v) => ({ ...result, [getId(v.x, v.y)]: v }), {});

    while (Object.keys(remainingValidations).length > 0) {
        if (currRow) {
            const rightAdjacentRectKey = getId(currRow.x + resolution, currRow.y);
            const leftAdjacentRectKey = getId(currRow.x - resolution, currRow.y);
            const canMergeOnRight =
                getStatusColor(remainingValidations[rightAdjacentRectKey]?.status) === currRow.color;
            const canMergeOnLeft =
                getStatusColor(remainingValidations[leftAdjacentRectKey]?.status) === currRow.color;

            if (canMergeOnRight) {
                currRow.width += resolution;
                delete remainingValidations[rightAdjacentRectKey];
            }

            if (canMergeOnLeft) {
                currRow.width += resolution;
                currRow.x -= resolution;
                delete remainingValidations[leftAdjacentRectKey];
            }

            if (!canMergeOnRight && !canMergeOnLeft) {
                rows.push(currRow);
                currRow = null;
            }
        } else {
            const firstKey = Object.keys(remainingValidations)[0];
            const { x, y, status } = remainingValidations[firstKey];
            currRow = { x, y, status, width: resolution, height: resolution, color: getStatusColor(status) };
            delete remainingValidations[firstKey];
        }
    }

    return rows;
};
