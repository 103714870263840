import React from 'react';
import styled from 'styled-components';
import { Drawer as MuiDrawer, IconButton, Typography, Grid, Divider as MuiDivider } from '@material-ui/core';
import { AiOutlineArrowRight as BackIcon } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectMappingSelectedLineId,
    selectMappingSelectedMapId,
    setSelectedLineId,
} from '../../../state-management/user-inputs/mappingSlice';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '@material-ui/icons/FileCopy';
import { showInfoNotification } from '../../../state-management/notification/notificationReducer';
import useLinesOfInterest from '../../common/hooks/data-fetching/useLinesOfInterest';
import LineOfInterestActions from './LineOfInterestActions';

const Drawer = styled(MuiDrawer)`
    z-index: 1000;
    visibility: visible;
    width: 0;

    & .side-drawer-paper {
        margin-block-start: 4rem;
        height: calc(100% - 4rem);
        width: 320px;
        overflow: visible;
        padding: 20px;
    }
`;

const DrawerContent = styled.div`
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
`;

const Divider = styled(MuiDivider)`
    margin-block-start: 15px;
    margin-block-end: 15px;
`;

const Title = styled(Typography).attrs(() => ({
    variant: 'h5',
}))`
    color: #ff6200; // TODO color should come from theme
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
`;

const BackIconWrapper = styled(IconButton)`
    position: absolute;
    right: 2%;
`;

export default function LineOfInterestDetailsSideDrawer() {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const selectedLineId = useSelector(selectMappingSelectedLineId);

    const { data: line = {} } = useLinesOfInterest({
        asObject: true,
        mapId: selectedMapId,
        lineId: selectedLineId,
        withAttachments: true,
    });

    return (
        <>
            <Drawer
                open={!!selectedLineId}
                classes={{ paper: 'side-drawer-paper' }}
                anchor={'right'}
                hideBackdrop
                disableEnforceFocus
            >
                <BackIconWrapper onClick={() => dispatch(setSelectedLineId(null))}>
                    <BackIcon />
                </BackIconWrapper>

                <Grid container alignItems={'center'}>
                    <Title>{selectedLineId}</Title>

                    <CopyToClipboard
                        text={selectedLineId}
                        onCopy={() => {
                            dispatch(showInfoNotification(`Line ID copied`));
                        }}
                    >
                        <IconButton onClick={(e) => e.stopPropagation()}>
                            <CopyIcon />
                        </IconButton>
                    </CopyToClipboard>
                </Grid>

                <Divider />

                <DrawerContent>
                    <LineOfInterestActions mapId={selectedMapId} lineId={line?.lineId} />

                    <Divider />
                </DrawerContent>
            </Drawer>
        </>
    );
}
