import TimeResolution from './TimeResolution';
import moment from 'moment';

class FifteenMinutes extends TimeResolution {
    get inMinutes() {
        return 15;
    }

    get label() {
        return '15 Minutes';
    }

    getTimeIntervalString(start, end) {
        return `${moment(start)
            .utc()
            .add(this.timeZoneOffset, 'minutes')
            .format('HH:mm')}-${moment(end)
            .utc()
            .add(1, 'millisecond')
            .add(this.timeZoneOffset, 'minutes')
            .format('HH:mm')}`;
    }

    get unitType() {
        return 'minute';
    }

    get unitCount() {
        return 15;
    }

    getFittingTimeInterval(utcTimestamp) {
        const start = moment(
            Math.floor(new Date(utcTimestamp).getTime() / this.inMilliseconds) * this.inMilliseconds
        );

        return {
            start: start.toISOString(),
            end: moment(start)
                .endOf('minute')
                .add(14, 'minutes')
                .toISOString(),
        };
    }

    getTimeIntervalsInRange(start, end) {
        return super.getTimeIntervalsInRange(start, end).filter(
            ({ start, end }) =>
                (!this.startHourUtc ||
                    moment(start)
                        .utc()
                        .add(this.timeZoneOffset, 'minutes')
                        .hour() >= this.startHourUtc) &&
                (!this.endHourUtc ||
                    moment(end)
                        .utc()
                        .add(this.timeZoneOffset, 'minutes')
                        .hour() < this.endHourUtc)
        );
    }
}

export default new FifteenMinutes();
