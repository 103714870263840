import React, { Fragment, useState } from 'react';
import { FormControl, FormGroup, FormControlLabel, Typography } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField, Switch, Skeleton, SwitchLabel as Label } from '../../common/themed';

const SwitchesWrapper = styled(FormControl).attrs({
    component: 'fieldset',
})`
    overflow-y: auto;
    width: 100%;
    max-height: 200px;
`;

const CategoryTitle = styled(Typography).attrs(() => ({
    variant: 'subtitle2',
}))`
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
`;

export default function CheckListWithSearch(props) {
    const {
        filterLabel,
        allValues,
        checkedValues = [],
        getValueDesc,
        getValueId,
        getCategoryName,
        getCategoryValues,
        onChange,
        isLoading = false,
    } = props;

    const [filterText, setFilterText] = useState('');

    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    };
    const renderValue = (value) => (
        <FormControlLabel
            key={getValueId(value)}
            control={
                <Switch
                    checked={checkedValues.includes(getValueId(value))}
                    onChange={onChange}
                    value={getValueId(value)}
                />
            }
            label={<Label>{getValueDesc(value)}</Label>}
        />
    );

    const renderValuesWithCategories = () =>
        allValues
            .filter((category) =>
                getCategoryValues(category).some((value) =>
                    getValueDesc(value).toLowerCase().includes(filterText.toLowerCase())
                )
            )
            .map((category) => (
                <Fragment key={getCategoryName(category)}>
                    <CategoryTitle>{getCategoryName(category)}</CategoryTitle>

                    {getCategoryValues(category)
                        .filter((value) =>
                            getValueDesc(value).toLowerCase().includes(filterText.toLowerCase())
                        )
                        .map(renderValue)}
                </Fragment>
            ));

    const renderValuesWithoutCategories = () =>
        allValues
            .filter((value) => getValueDesc(value).toLowerCase().includes(filterText.toLowerCase()))
            .map(renderValue);

    return (
        <>
            <TextField label={filterLabel} onChange={handleFilterChange} />

            <SwitchesWrapper>
                <FormGroup>
                    {isLoading
                        ? Array.from({ length: 5 }, (_, index) => (
                              <FormControlLabel
                                  key={`checklist-item-skeleton-${index}`}
                                  control={<Switch checked={false} disabled />}
                                  label={<Skeleton height={15} width={230} />}
                              />
                          ))
                        : getCategoryName && getCategoryValues
                        ? renderValuesWithCategories()
                        : renderValuesWithoutCategories()}
                </FormGroup>
            </SwitchesWrapper>
        </>
    );
}

CheckListWithSearch.propTypes = {
    filterLabel: PropTypes.string.isRequired,
    allValues: PropTypes.array.isRequired,
    checkedValues: PropTypes.arrayOf(PropTypes.string),
    getValueDesc: PropTypes.func.isRequired,
    getValueId: PropTypes.func.isRequired,
    getCategoryName: PropTypes.func,
    getCategoryValues: PropTypes.func,
    isLoading: PropTypes.bool,
};
