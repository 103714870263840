import React from 'react';
import styled from 'styled-components';
import SideMenuSection from '../../common/side-drawer/SideMenuSection';
import { Skeleton, Switch, SwitchLabel as Label } from '../../common/themed';
import {
    selectMappingAreMappersShown,
    selectMappingSelectedMapId,
    setAreMappersShown,
} from '../../../state-management/user-inputs/mappingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel } from '@material-ui/core';
import useMappers from '../../common/hooks/data-fetching/useMappers';
import MapperListEntry from './MapperListEntry';
import NoItemsMessage from '../../common/side-drawer/NoItemsMessage';
import FetchError from '../../common/error-pages/FetchError';

const MapperList = styled.ul`
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-block-start: 5px;
    padding: 0;
`;

export default function MappingSideMenuMappersSection() {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const areMappersShown = useSelector(selectMappingAreMappersShown);

    const {
        data: mappers = [],
        isLoading,
        hasError,
    } = useMappers({
        mapId: selectedMapId,
    });

    if (!selectedMapId && !isLoading) {
        return null;
    }

    return (
        <SideMenuSection title={'Mappers'}>
            {hasError ? (
                <FetchError />
            ) : isLoading ? (
                <Skeleton height={50} width={230} />
            ) : mappers.length === 0 ? (
                <NoItemsMessage>There are no live mappers in this map</NoItemsMessage>
            ) : (
                <>
                    <FormControlLabel
                        label={<Label>Show mappers</Label>}
                        disabled={isLoading}
                        control={
                            <Switch
                                checked={areMappersShown}
                                onChange={() => dispatch(setAreMappersShown(!areMappersShown))}
                            />
                        }
                    />

                    <MapperList>
                        {mappers.map((mapper) => (
                            <MapperListEntry key={mapper?.mapperId} mapper={mapper} />
                        ))}
                    </MapperList>
                </>
            )}
        </SideMenuSection>
    );
}
