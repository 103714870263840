import { createSelector, createSlice } from '@reduxjs/toolkit';
import { deleteBuilding } from '../building/buildingActions';
import { deleteFloor } from '../floor/floorActions';
import { deleteMap } from '../map/mapActions';
import { setSelectedSpaceId } from './commonSlice';
import { wipeAllUserInputs } from './userInputsActions';
import { deleteEntrance } from '../entrance/entranceActions';
import { deleteRegionExternalPlacement } from '../region-placement/regionPlacementActions';
import { fetchAllAreasOfInterestInMap } from '../mapping/area-of-interest/areaOfInterestActions';

const initialState = {
    isSideDrawerOpen: true,
    selectedBuildingId: null,
    selectedFloorId: null,
    selectedMapId: null,
    selectedEntranceId: null,
    selectedEntranceCoordinates: null,
    selectedRegionId: null,
    selectedExternalPlacementId: null,
    isSelectedExternalPlacementEditable: false,
    selectedExternalPlacementPoints: null,
    isSelectedEntranceEditable: false,
    isSettingPixelToMeter: false,
    calculatedPixelToMeter: null,
    areEntrancesShown: true,
    areRegionExternalPlacementsShown: true,
    highlightedEntranceIds: [],
    highlightedExternalPlacementIds: [],
    isCreatingEntrance: false,
    isCreatingRegionExternalPlacement: false,
    isGoogleMapsViewOpen: false,
    isGoogleMapsViewToggleDisabled: false,
    mapImagesChecked: {},
    isSettingMapOffset: false,
    calculatedMapOffset: null,
    isExistRegionCreationOpen: false,
    areExitRegionsShown: true,
    areExitRegionTitlesShown: true,
    selectedExitRegionId: null,
    isCoordinatesConverstionOpen: false,
    selectedExitRegionIds: [],
};

export const {
    actions: {
        setIsSideDrawerOpen,
        setSelectedBuildingId,
        setSelectedFloorId,
        setSelectedMapId,
        setSelectedEntranceId,
        setSelectedEntranceCoordinates,
        setSelectedExternalPlacementId,
        setSelectedExternalPlacementEditable,
        setSelectedExternalPlacementPoints,
        setIsSelectedEntranceEditable,
        setIsSettingPixelToMeter,
        setCalculatedPixelToMeter,
        setAreEntrancesShown,
        setAreRegionExternalPlacementsShown,
        highlightEntrance,
        unhighlightEntrance,
        highlightExternalPlacement,
        unhighlightExternalPlacement,
        setIsCreatingEntrance,
        setIsCreatingRegionExternalPlacement,
        setIsGoogleMapsViewOpen,
        setMapImageChecked,
        setIsSettingMapOffset,
        setCalculatedMapOffset,
        setIsExitRegionOpen,
        setAreExitRegionsShown,
        setAreExitRegionTitlesShown,
        setSelectBuildingSelectedExitRegionId,
        setIsCoordinatesConverstionOpen,
        setSelectedBuildingExitRegionIds,
    },
    reducer,
} = createSlice({
    name: 'userInputs/buildings',
    initialState,
    reducers: {
        setMapImageChecked: (state, action) => {
            state.mapImagesChecked[action.payload.imageId] = action.payload.isChecked;
        },
        setIsSideDrawerOpen: (state, action) => {
            state.isSideDrawerOpen = action.payload;
        },
        setIsCoordinatesConverstionOpen: (state, action) => {
            state.isCoordinatesConverstionOpen = action.payload;
        },
        setSelectedBuildingId: (state, action) => {
            state.selectedBuildingId = action.payload;
            state.selectedFloorId = null;
            state.selectedMapId = null;
            state.selectedEntranceId = null;
            state.selectedEntranceCoordinates = null;
            state.selectedRegionId = null;
            state.selectedExternalPlacementId = null;
            state.isSelectedExternalPlacementEditable = false;
            state.selectedExternalPlacementPoints = null;
            state.isSettingPixelToMeter = false;
            state.isCreatingEntrance = false;
            state.isCreatingRegionExternalPlacement = false;
            state.highlightedEntranceIds = [];
            state.highlightedExternalPlacementIds = [];
            state.isSettingMapOffset = false;
            state.isCoordinatesConverstionOpen = false;
        },
        setAreExitRegionsShown: (state, action) => {
            state.areExitRegionsShown = action.payload;
        },
        setAreExitRegionTitlesShown: (state, action) => {
            state.areExitRegionTitlesShown = action.payload;
        },
        setSelectedFloorId: (state, action) => {
            state.selectedFloorId = action.payload;
            state.selectedMapId = null;
            state.selectedEntranceId = null;
            state.selectedEntranceCoordinates = null;
            state.selectedRegionId = null;
            state.selectedExternalPlacementId = null;
            state.isSelectedExternalPlacementEditable = false;
            state.selectedExternalPlacementPoints = null;
            state.isSettingPixelToMeter = false;
            state.isCreatingEntrance = false;
            state.isCreatingRegionExternalPlacement = false;
            state.highlightedEntranceIds = [];
            state.highlightedExternalPlacementIds = [];
            state.isSettingMapOffset = false;
            state.isCoordinatesConverstionOpen = false;
        },

        setSelectedMapId: (state, action) => {
            state.selectedMapId = action.payload;
            state.selectedEntranceId = null;
            state.selectedEntranceCoordinates = null;
            state.selectedRegionId = null;
            state.selectedExternalPlacementId = null;
            state.isSelectedExternalPlacementEditable = false;
            state.selectedExternalPlacementPoints = null;
            state.isSettingPixelToMeter = false;
            state.isCreatingEntrance = false;
            state.isCreatingRegionExternalPlacement = false;
            state.highlightedEntranceIds = [];
            state.highlightedExternalPlacementIds = [];
            state.isSettingMapOffset = false;
            state.isCoordinatesConverstionOpen = false;
        },

        setSelectedEntranceId: (state, action) => {
            if (!action.payload) {
                state.highlightedEntranceIds = state.highlightedEntranceIds.filter(
                    (id) => id !== state.selectedEntranceId
                );
                state.selectedEntranceCoordinates = null;
            }

            state.selectedEntranceId = action.payload;
        },
        setSelectBuildingSelectedExitRegionId: (state, action) => {
            state.selectedExitRegionId = action.payload;
        },
        setSelectedBuildingExitRegionIds: (state, action) => {
            state.selectedExitRegionIds = [...new Set(action.payload)];
        },
        setSelectedEntranceCoordinates: (state, action) => {
            state.selectedEntranceCoordinates = action.payload;
        },

        setSelectedExternalPlacementId: (state, action) => {
            if (!action.payload?.regionId && !action.payload?.placementId) {
                state.isSelectedExternalPlacementEditable = false;
                state.selectedExternalPlacementPoints = null;
                state.highlightedExternalPlacementIds = state.highlightedExternalPlacementIds.filter(
                    (id) => id !== state.selectedExternalPlacementId
                );
            }

            state.selectedRegionId = action.payload?.regionId;
            state.selectedExternalPlacementId = action.payload?.placementId;
            state.isGoogleMapsViewOpen = true;
        },

        setSelectedExternalPlacementEditable: (state, action) => {
            state.selectedRegionId = action.payload?.regionId;
            state.selectedExternalPlacementId = action.payload?.placementId;
            state.isSelectedExternalPlacementEditable = action.payload?.isEditable;
            state.isGoogleMapsViewOpen = true;
        },

        setSelectedExternalPlacementPoints: (state, action) => {
            state.selectedExternalPlacementPoints = action.payload;
        },

        setIsSelectedEntranceEditable: (state, action) => {
            state.isSelectedEntranceEditable = action.payload;

            if (action.payload) {
                state.isGoogleMapsViewOpen = false;
            } else {
                state.selectedEntranceCoordinates = null;
            }
        },

        setIsSettingPixelToMeter: (state, action) => {
            state.isSettingPixelToMeter = action.payload;
            state.isCreatingEntrance = false;
            state.isCreatingRegionExternalPlacement = false;
            state.isGoogleMapsViewOpen = false;
            state.isSettingMapOffset = false;
        },

        setCalculatedPixelToMeter: (state, action) => {
            state.calculatedPixelToMeter = action.payload;
            state.isSettingPixelToMeter = false;
        },

        setAreEntrancesShown: (state, action) => {
            state.areEntrancesShown = action.payload;
        },

        setAreRegionExternalPlacementsShown: (state, action) => {
            state.areRegionExternalPlacementsShown = action.payload;
        },

        highlightEntrance: (state, action) => {
            state.highlightedEntranceIds = [...new Set([...state.highlightedEntranceIds, action.payload])];
        },

        unhighlightEntrance: (state, action) => {
            state.highlightedEntranceIds = state.highlightedEntranceIds.filter((id) => id !== action.payload);
        },

        highlightExternalPlacement: (state, action) => {
            state.highlightedExternalPlacementIds = [
                ...new Set([...state.highlightedExternalPlacementIds, action.payload]),
            ];
        },

        unhighlightExternalPlacement: (state, action) => {
            state.highlightedExternalPlacementIds = state.highlightedExternalPlacementIds.filter(
                (id) => id !== action.payload
            );
        },

        setIsCreatingEntrance: (state, action) => {
            state.isCreatingEntrance = action.payload;
            state.isGoogleMapsViewOpen = false;
            state.isGoogleMapsViewToggleDisabled = action.payload;
            state.isCreatingRegionExternalPlacement = false;
            state.isSettingPixelToMeter = false;
            state.isSettingMapOffset = false;
        },

        setIsCreatingRegionExternalPlacement: (state, action) => {
            state.isCreatingRegionExternalPlacement = action.payload;
            state.isGoogleMapsViewOpen = true;
            state.isGoogleMapsViewToggleDisabled = action.payload;
            state.isCreatingEntrance = false;
            state.isSettingPixelToMeter = false;
            state.isSettingMapOffset = false;
        },

        setIsGoogleMapsViewOpen: (state, action) => {
            state.isGoogleMapsViewOpen = action.payload;

            if (action.payload) {
                state.isCreatingEntrance = false;
                state.isSelectedEntranceEditable = false;
            } else {
                state.isCreatingRegionExternalPlacement = false;
                state.selectedExternalPlacementId = null;
                state.isSelectedExternalPlacementEditable = false;
                state.selectedExternalPlacementPoints = null;
            }
        },

        setIsSettingMapOffset: (state, action) => {
            state.isSettingMapOffset = action.payload;
            state.isSettingPixelToMeter = false;
            state.isCreatingEntrance = false;
            state.isCreatingRegionExternalPlacement = false;
            state.isGoogleMapsViewOpen = false;
        },

        setCalculatedMapOffset: (state, action) => {
            state.calculatedMapOffset = action.payload;
            state.isSettingMapOffset = false;
        },
        setIsExitRegionOpen: (state, action) => {
            state.isExistRegionCreationOpen = action.payload;
        },
    },
    extraReducers: {
        [setSelectedSpaceId]: (state) => {
            state.selectedBuildingId = null;
            state.selectedFloorId = null;
            state.selectedMapId = null;
            state.selectedEntranceId = null;
            state.selectedEntranceCoordinates = null;
            state.selectedRegionId = null;
            state.selectedExternalPlacementId = null;
            state.isSelectedExternalPlacementEditable = false;
            state.selectedExternalPlacementPoints = null;
            state.isSelectedExternalPlacementEditable = false;
            state.isSelectedEntranceEditable = false;
            state.isCreatingRegionExternalPlacement = false;
            state.isCreatingEntrance = false;
            state.isSettingPixelToMeter = false;
            state.highlightedEntranceIds = [];
            state.highlightedExternalPlacementIds = [];
            state.isSettingMapOffset = false;
        },

        [deleteBuilding.fulfilled]: (state, action) => {
            if (action.meta.arg === state.selectedBuildingId) {
                state.selectedBuildingId = action.payload;
                state.selectedFloorId = null;
                state.selectedMapId = null;
                state.selectedEntranceId = null;
                state.selectedEntranceCoordinates = null;
                state.selectedRegionId = null;
                state.selectedExternalPlacementId = null;
                state.isSelectedExternalPlacementEditable = false;
                state.selectedExternalPlacementPoints = null;
                state.isSelectedExternalPlacementEditable = false;
                state.isSelectedEntranceEditable = false;
                state.isCreatingRegionExternalPlacement = false;
                state.isCreatingEntrance = false;
                state.isSettingPixelToMeter = false;
                state.highlightedEntranceIds = [];
                state.highlightedExternalPlacementIds = [];
                state.isSettingMapOffset = false;
            }
        },

        [deleteFloor.fulfilled]: (state, action) => {
            if (action.meta.arg.floorId === state.selectedFloorId) {
                state.selectedFloorId = null;
                state.selectedMapId = null;
                state.selectedEntranceId = null;
                state.selectedEntranceCoordinates = null;
                state.selectedRegionId = null;
                state.selectedExternalPlacementId = null;
                state.isSelectedExternalPlacementEditable = false;
                state.selectedExternalPlacementPoints = null;
                state.isSelectedExternalPlacementEditable = false;
                state.isSelectedEntranceEditable = false;
                state.isCreatingRegionExternalPlacement = false;
                state.isCreatingEntrance = false;
                state.isSettingPixelToMeter = false;
                state.highlightedEntranceIds = [];
                state.highlightedExternalPlacementIds = [];
                state.isSettingMapOffset = false;
            }
        },

        [deleteMap.fulfilled]: (state, action) => {
            if (action.meta.arg.mapId === state.selectedMapId) {
                state.selectedMapId = null;
                state.selectedEntranceId = null;
                state.selectedEntranceCoordinates = null;
                state.selectedRegionId = null;
                state.selectedExternalPlacementId = null;
                state.isSelectedExternalPlacementEditable = false;
                state.isSelectedEntranceEditable = false;
                state.selectedExternalPlacementPoints = null;
                state.isCreatingRegionExternalPlacement = false;
                state.isCreatingEntrance = false;
                state.isSettingPixelToMeter = false;
                state.highlightedEntranceIds = [];
                state.highlightedExternalPlacementIds = [];
                state.isSettingMapOffset = false;
            }
        },

        [deleteEntrance.fulfilled]: (state, action) => {
            if (action.meta.arg.entranceId === state.selectedEntranceId) {
                state.selectedEntranceId = null;
                state.selectedEntranceCoordinates = null;
                state.highlightedEntranceIds = [];
                state.isSelectedEntranceEditable = false;
            }
        },

        [deleteRegionExternalPlacement.fulfilled]: (state, action) => {
            if (action.meta.arg.placementId === state.selectedExternalPlacementId) {
                state.selectedRegionId = null;
                state.selectedExternalPlacementId = null;
                state.isSelectedExternalPlacementEditable = false;
                state.selectedExternalPlacementPoints = null;
                state.highlightedExternalPlacementIds = [];
            }
        },

        [wipeAllUserInputs]: () => initialState,
    },
});

export const selectBuildingsIsSideDrawerOpen = (state) => state.userInputs.buildings.isSideDrawerOpen;
export const selectBuildingsSelectedBuildingId = (state) => state.userInputs.common.selectedBuildingId;
export const selectBuildingsSelectedFloorId = (state) => state.userInputs.common.selectedFloorId;
export const selectBuildingsSelectedMapId = (state) => state.userInputs.common.selectedMapId;
export const selectBuildingsSelectedEntranceId = (state) => state.userInputs.buildings.selectedEntranceId;
export const selectBuildingsSelectedEntranceCoordinates = (state) =>
    state.userInputs.buildings.selectedEntranceCoordinates;
export const selectBuildingsSelectedRegionId = (state) => state.userInputs.buildings.selectedRegionId;
export const selectBuildingsSelectedExternalPlacementId = (state) =>
    state.userInputs.buildings.selectedExternalPlacementId;
export const selectBuildingsIsSelectedExternalPlacementEditable = (state) =>
    state.userInputs.buildings.isSelectedExternalPlacementEditable;
export const selectBuildingsIsSelectedEntranceEditable = (state) =>
    state.userInputs.buildings.isSelectedEntranceEditable;
export const selectBuildingsSelectedExternalPlacementPoints = (state) =>
    state.userInputs.buildings.selectedExternalPlacementPoints;
export const selectBuildingsIsSettingPixelToMeter = (state) =>
    state.userInputs.buildings.isSettingPixelToMeter;
export const selectBuildingsCalculatedPixelToMeter = (state) =>
    state.userInputs.buildings.calculatedPixelToMeter;
export const selectBuildingsAreEntrancesShown = (state) => state.userInputs.buildings.areEntrancesShown;
export const selectBuildingsAreRegionExternalPlacementsShown = (state) =>
    state.userInputs.buildings.areRegionExternalPlacementsShown;
export const selectBuildingsHighlightedEntranceIds = (state) =>
    state.userInputs.buildings.highlightedEntranceIds;
export const selectBuildingsHighlightedExternalPlacementIds = (state) =>
    state.userInputs.buildings.highlightedExternalPlacementIds;
export const selectBuildingsIsCreatingEntrance = (state) => state.userInputs.buildings.isCreatingEntrance;
export const selectBuildingsIsCreatingRegionExternalPlacement = (state) =>
    state.userInputs.buildings.isCreatingRegionExternalPlacement;
export const selectBuildingsIsGoogleMapsViewOpen = (state) => state.userInputs.buildings.isGoogleMapsViewOpen;
export const selectBuildingsIsGoogleMapsViewToggleDisabled = (state) =>
    state.userInputs.buildings.isGoogleMapsViewToggleDisabled;

export const getSelectIsEntranceHighlighted = (entranceId) =>
    createSelector(selectBuildingsHighlightedEntranceIds, (entranceIds) => entranceIds.includes(entranceId));

export const getSelectIsExternalPlacementHighlighted = (placementId) =>
    createSelector(selectBuildingsHighlightedExternalPlacementIds, (placementIds) =>
        placementIds.includes(placementId)
    );
export const selectMapImagesChecked = (state) => state.userInputs.buildings.mapImagesChecked;
export const selectBuildingIsSettingMapOffset = (state) => state.userInputs.buildings.isSettingMapOffset;
export const selectBuildingsCalculatedMapOffset = (state) => state.userInputs.buildings.calculatedMapOffset;
export const selectBuildingsIsCreatingExitRegion = (state) =>
    state.userInputs.buildings.isExistRegionCreationOpen;
export const selectBuildingAreExitRegionsShown = (state) => state.userInputs.buildings.areExitRegionsShown;
export const selectBuildingAreExitRegionsTitleShown = (state) =>
    state.userInputs.buildings.areExitRegionTitlesShown;

export const selectBuildingSelectedExitRegionId = (state) => state.userInputs.buildings.selectedExitRegionId;

export const selectBuildingIsCoordinatesConversionOpen = (state) =>
    state.userInputs.buildings.isCoordinatesConverstionOpen;
export const selectBuildingSelectedExitRegionIds = (state) =>
    state.userInputs.buildings.selectedExitRegionIds;
