import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, FormControlLabel, Typography, InputLabel, FormControl } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import * as PropTypes from 'prop-types';
import useMapsFullData from '../../../common/hooks/data-fetching/useMapsFullData';
import ErrorGeneral from '../../../common/error-pages/ErrorGeneral';
import {
    PulsePreloader,
    Button,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    CircularPreloader,
    Select,
    SwitchLabel as Label,
} from '../../../common/themed';
import MapViewerV1 from '../../../map-content/legacy/OldMapViewer';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectDefaultReferenceMapId } from '../../../../state-management/map/mapSelectors';
import { selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { createMapImage, updateMap } from '../../../../state-management/map/mapActions';
import {
    selectBuildingIdToMapArray,
    selectBuildingIdToMapObject,
} from '../../../../state-management/building/buildingSelectors';
import Slider from '@material-ui/core/Slider';
import { showErrorNotification } from '../../../../state-management/notification/notificationReducer';
import { getDefaultMapImage } from '../../../../utils/mapImages.helper';

const OldMapViewer = styled(MapViewerV1)`
    height: 100%;
`;

const ControlsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-block-start: 10px;

    & > :not(:last-child) {
        margin-inline-end: 30px;
    }

    & > :last-child {
        margin-inline-start: auto;
    }
`;

const getDropZoneBorderColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
};

const DropZoneWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${(props) => getDropZoneBorderColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
`;

const Thumbnail = styled.img`
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #ccc;
    width: auto;
    height: 250px;
    padding: 4px;
    box-sizing: border-box;
    margin-top: 20px;
`;

const OpacityWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const OpacityTitle = styled(Typography)`
    margin-inline-end: 15px;
`;

const OpacitySlider = styled(Slider)`
    width: 300px;
`;

const DialogContentColumn = styled(DialogContent)`
    display: flex;
    flex-direction: column;
`;

const FullScreenDialog = styled(Dialog)`
    margin: 3rem;
`;

const Col = styled(Grid).attrs({
    direction: 'column',
    container: true,
})`
    height: 100%;
    flex-wrap: nowrap;
    & > :not(:last-child) {
        margin-bottom: 30px;
    }
`;
const Row = styled(Grid).attrs({
    direction: 'row',
    container: true,
})`
    height: 100%;
    & > :not(:last-child) {
        margin-right: 30px;
    }
`;

const SettingsWrapper = styled.div``;

const ListWrapper = styled(Grid).attrs({
    direction: 'column',
    item: true,
})`
    & > :not(:last-child) {
        margin-bottom: 20px;
    }
`;
export default function UploadNewMapImageDialog(props) {
    const { buildingId, floorId, mapId, onSubmit, onClose, mode } = props;

    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [imageBase64, setImageBase64] = useState(null);
    const [oldMapViewerRef, setOldMapViewerRef] = useState(null);
    const [opacity, setOpacity] = useState(0.5);
    const [lockStages, setLockStages] = useState(true);
    const [skipValidation, setSkipValidation] = useState(false);
    const [selectedReferenceMapId, setSelectedReferenceMapId] = useState(
        useSelector(getSelectDefaultReferenceMapId(buildingId))
    );

    const [selectedMapUsage, setSelectedMapUsage] = useState();

    const title = mode === 'upload' ? 'Upload New Image' : 'Replace Image';
    const mapUsages = [
        { value: 'navgraph', label: 'Nav Graph' },
        { value: 'obstacles', label: 'Obstacles' },
    ];

    const {
        data: { [mapId]: map, [selectedReferenceMapId]: referenceMap },
        isLoading,
        hasError,
    } = useMapsFullData({ buildingId, floorId, mapIds: [mapId, selectedReferenceMapId], asObject: true });

    const [selectedMapImage, setSelectedMapImage] = useState({
        label: getDefaultMapImage(map.mapImages).mapUsage,
        value: getDefaultMapImage(map.mapImages),
    });
    const dispatch = useDispatch();
    const { [buildingId]: mapsArray = [] } = useSelector(selectBuildingIdToMapArray);
    const { [buildingId]: mapsObject = {} } = useSelector(selectBuildingIdToMapObject);
    const isUpdateSubmitting = useSelector(selectIsLoading(updateMap.typePrefix));
    const isCreationSubmitting = useSelector(selectIsLoading(createMapImage.typePrefix));

    const isSubmitting = props.mode === 'upload' ? isCreationSubmitting : isUpdateSubmitting;
    const handleFileAccepted = ([file]) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            setImageBase64(event.target.result);
        };
        fileReader.readAsDataURL(file);
    };

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } = useDropzone({
        onDropAccepted: handleFileAccepted,
        accept: 'image/jpeg, image/png, image/svg+xml',
        multiple: false,
    });

    const getMapImages = () => {
        if (map.mapImages && Array.isArray(map.mapImages)) {
            return map.mapImages.map((mapImage) => {
                return {
                    value: mapImage,
                    label: mapImage.mapUsage,
                };
            });
        }
        return [];
    };
    const handleBack = () => {
        setActiveStepIndex(Math.max(activeStepIndex - 1, 0));
    };

    const handleSubmit = () => {
        if (activeStepIndex !== 1) {
            setActiveStepIndex(activeStepIndex + 1);
        } else {
            if (mode === 'upload' && !selectedMapUsage) {
                dispatch(showErrorNotification('Image usage is required'));
                return;
            }
            const { mapName, mapUsage } = map;
            const mapOffset = oldMapViewerRef?.getMapsOffset();
            const pixelToMeter = oldMapViewerRef?.getPixelToMeter();

            onSubmit({
                mapName,
                mapUsage: mode == 'upload' ? selectedMapUsage?.value : selectedMapImage.value.mapUsage,
                pixelToMeter,
                mapOffset,
                imgBase64: imageBase64,
                skipValidation,
            });
        }
    };

    if (hasError) {
        return <ErrorGeneral />;
    }

    if (isLoading) {
        return <PulsePreloader />;
    }

    const renderStep = () => {
        switch (activeStepIndex) {
            case 0:
                return (
                    <>
                        <DropZoneWrapper
                            isDragActive={isDragActive}
                            isDragAccept={isDragAccept}
                            isDragReject={isDragReject}
                            onClick={() => console.log('1')}
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                            <em>(Only *.jpeg , *.png and *.svg images will be accepted)</em>
                        </DropZoneWrapper>

                        {imageBase64 && (
                            <Grid container justifyContent={'center'}>
                                <Thumbnail alt={'thumbnail'} src={`${imageBase64}`} />
                            </Grid>
                        )}

                        <ControlsWrapper>
                            <Button onClick={() => setActiveStepIndex(1)}>Next</Button>
                        </ControlsWrapper>
                    </>
                );
            case 1:
                return (
                    <Col>
                        <Row>
                            <ListWrapper>
                                <OpacityWrapper>
                                    <OpacityTitle>Opacity:</OpacityTitle>
                                    <OpacitySlider
                                        min={0}
                                        max={1}
                                        step={0.05}
                                        value={opacity}
                                        onChange={(e, value) => setOpacity(value)}
                                    />
                                </OpacityWrapper>
                                <Select
                                    options={mapsArray}
                                    getOptionLabel={(m) => m.mapName}
                                    getOptionValue={(m) => m.mapId}
                                    onChange={(m) => setSelectedReferenceMapId(m.mapId)}
                                    value={mapsObject[selectedReferenceMapId]}
                                    menuPlacement={'bottom'}
                                />
                                {mode === 'upload' ? (
                                    <Select
                                        id="select-image-usage"
                                        options={mapUsages.filter(
                                            ({ value }) =>
                                                !map.mapImages
                                                    .map((mImage) => mImage.mapUsage)
                                                    .includes(value)
                                        )}
                                        getOptionLabel={(m) => m.label}
                                        getOptionValue={(m) => m.value}
                                        onChange={(m) => setSelectedMapUsage(m)}
                                        value={selectedMapUsage}
                                        menuPlacement={'bottom'}
                                        placeholder="Image Usage"
                                    />
                                ) : (
                                    <Select
                                        id="select-map-image"
                                        options={getMapImages()}
                                        getOptionLabel={(m) => m.label}
                                        getOptionValue={(m) => m.value}
                                        onChange={setSelectedMapImage}
                                        value={selectedMapImage}
                                        menuPlacement={'bottom'}
                                        placeholder="Map Image"
                                    />
                                )}

                                <SettingsWrapper>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={lockStages}
                                                onChange={() => setLockStages(!lockStages)}
                                            />
                                        }
                                        label={<Label>Lock position</Label>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={skipValidation}
                                                onChange={() => setSkipValidation(!skipValidation)}
                                            />
                                        }
                                        label={<Label>Skip validation</Label>}
                                    />
                                </SettingsWrapper>
                            </ListWrapper>
                            {/*// TODO move the map offset behavior into the new map viewer*/}
                            <OldMapViewer
                                selectedMap={referenceMap}
                                selectedMapData={referenceMap}
                                mapImage={selectedMapImage.value}
                                additionalMapData={{
                                    imgBase64: imageBase64,
                                    mapOffset: map.mapOffset,
                                    pixelToMeter: map.pixelToMeter,
                                }}
                                additionalMapOpacity={opacity}
                                onMount={setOldMapViewerRef}
                                lockStages={lockStages}
                            />
                        </Row>
                        <Grid container justifyContent="flex-end">
                            <Button variant={'text'} onClick={handleBack}>
                                Back
                            </Button>

                            {isSubmitting ? (
                                <CircularPreloader />
                            ) : (
                                <Button onClick={handleSubmit}>Submit</Button>
                            )}
                        </Grid>
                    </Col>
                );
            default:
                return null;
        }
    };

    return (
        <FullScreenDialog open onClose={onClose} fullScreen maxWidth={'xl'}>
            <DialogTitle>{title}</DialogTitle>

            <DialogContentColumn>{renderStep()}</DialogContentColumn>
        </FullScreenDialog>
    );
}

UploadNewMapImageDialog.propTypes = {
    buildingId: PropTypes.string.isRequired,
    floorId: PropTypes.string.isRequired,
    mapId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['upload', 'replace']),
};
