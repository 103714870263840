import React, { useState } from 'react';
import { Chip, Grid } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdAdd as AddIcon } from 'react-icons/md';
import { TextField, Fab } from '../../common/themed';

const AddTextField = styled(TextField)`
    max-width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
`;

export default function ChipListWithInsert(props) {
    const { addLabel, values = [], onAdd, onDelete } = props;
    const [newValue, setNewValue] = useState('');

    return (
        <>
            <Grid container alignItems={'center'}>
                <AddTextField
                    label={addLabel}
                    onChange={(e) => setNewValue(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            onAdd(newValue);
                        }
                    }}
                />

                <Fab size="small" aria-label="Add" onClick={() => onAdd(newValue)}>
                    <AddIcon size={20} />
                </Fab>
            </Grid>

            {values &&
                values.map((value, index) => (
                    <Chip key={`value-${index}`} label={value} onDelete={() => onDelete(value)} />
                ))}
        </>
    );
}

ChipListWithInsert.propTypes = {
    addLabel: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    values: PropTypes.array,
};
