import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog, DialogContent, Typography, IconButton, Grid, Divider } from '@material-ui/core';
import { Switch, TextField, Button, SwitchLabel as Label } from '../../common/themed';
import CloseIcon from '@material-ui/icons/Close';
import MovementDistributionBarChart from '../charts/MovementDistributionBarChart';
import SessionHeatmap from '../heat-map/SessionHeatMap';
import getSelectors from '../Analytics.selectors';
import HeatMapLegend from '../heat-map/HeatMapLegend';
import * as moment from 'moment';
import RegionVisitationDetails from './RegionVisitationDetails';
import RegionDistributionPieChart from '../charts/RegionDistributionPieChart';
import Select from 'react-select';
import FormControlLabel from '@material-ui/core/FormControlLabel/index';
import InputAdornment from '@material-ui/core/InputAdornment/index';
import { processSelectedSession } from '../../../state-management/analytics/session-analytics/sessionAnalyticsActions';
import { connect } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import RegionsLegend from '../charts/RegionsLegend';
import PositionsTimelineChart from '../charts/PositionsTimelineChart';

const heatMapValueOffset = 0.05;

const DialogWrapper = styled(({ isLoading, ...otherProps }) => <Dialog {...otherProps} />)`
    .dialog-paper {
        height: ${(props) => (props.isLoading ? 'auto' : '100%')};
    }
`;

const PreloaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 30px;
`;

const LoadingWrapper = styled.div`
    opacity: ${(props) => (props.visible ? '1' : '0')};
    position: ${(props) => (props.visible ? 'initial' : 'fixed')};
    display: flex;
    flex: 1 1 auto;
`;

const DialogContentWrapper = styled(DialogContent)`
    && {
        padding: 12px 24px 0;
        display: flex;
        flex-direction: column;
    }
`;

const Header = styled.div`
    height: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
`;

const CloseIconWrapper = styled(IconButton)`
    height: 40px;
    width: 40px;
    padding: 0;
`;

const ChartsWrapper = styled.div`
    & > * {
        padding-top: 10px;
        padding-bottom: 10px;
        height: 50%;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }
`;

const HeatMapAndChartsWrapper = styled.div`
    flex: 1 1 auto;
`;

const HeatMapWrapper = styled.div`
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const HeatMapLegendWrapper = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
`;

const HeatMapToggle = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 100;
    margin-left: 15px;
    margin-top: 10px;
`;

const RegionsVisitationDetailsWrapper = styled.div`
    margin-top: 60px;
    width: 100%;
    max-height: 550px;
    overflow-y: auto;
`;

const PieWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
`;

const LevelSelectorWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LevelSelectorTitle = styled.div`
    font-size: 18px;
    margin-right: 5px;
`;

const LevelSelector = styled(Select)`
    width: 70px;

    [role='option'] {
        padding: 4px 12px;
    }
`;

const AcceptedAccuracyTextField = styled(TextField)`
    p {
        width: 140px;
    }

    input {
        width: 30px;
    }
`;

const FiltersWrapper = styled.div`
    width: auto;

    & > div {
        padding-left: 8px;
        padding-right: 8px;
    }
`;

class SessionDialog extends React.Component {
    chartsSelectors = getSelectors(this.props.session.sessionId).charts;

    regionLevelOptions = this.props.session.regionLevels.map((level) => ({
        level,
    }));

    filters = {
        ...this.props.session.usedFilters,
        acceptedAccuracy: this.props.session.usedFilters.acceptedAccuracy,
        inaccuracySmoothing: this.props.session.usedFilters.inaccuracySmoothing,
    };

    state = {
        chartInstances: {},
        isLoading: true,
        selectedRegionId: null,
        selectedRegionName: null,
        selectedRegionVisits: [],
        selectedRegionLevel: this.props.session.regionLevels[0],
        heatMapMaxDuration: 1.1,
        displayedSessionSegmentStart: this.props.session.earliestTime,
        displayedSessionSegmentEnd: this.props.session.latestTime,
        isShowingHeatMap: true,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            session: { isProcessed, regionLevels },
        } = this.props;

        // If the processed session changed due to re-process
        if (!prevProps.session.isProcessed && isProcessed) {
            this.setState(
                {
                    isLoading: false,
                },
                () => {
                    // The first region level is the default one
                    this.handleRegionLevelSelected(regionLevels[0]);
                }
            );
        }
    }

    handleFiltersSubmit = () => {
        const { processSelectedSession } = this.props;

        this.setState({ isLoading: true }, () => {
            processSelectedSession(this.filters);
        });
    };

    handleFilterChange = (key, value) => {
        this.filters[key] = value;
    };

    handleChartInitialize = (id, chart) => {
        const {
            session: { isProcessed },
        } = this.props;

        chart.events.on('ready', () => {
            const { chartInstances } = this.state;

            if (
                Object.values(chartInstances).length > 0 &&
                Object.values(chartInstances).every((chart) => chart.isReady()) &&
                isProcessed
            ) {
                this.setState({ isLoading: false });
            }
        });

        this.setState((prevState) => ({
            chartInstances: { ...prevState.chartInstances, [id]: chart },
        }));
    };

    handleChartUnmount = (id, chart) => {
        this.setState((prevState) => {
            const { [id]: unmountedChart, ...otherInstances } = prevState.chartInstances;
            return {
                chartInstances: otherInstances,
            };
        });
    };

    handleRegionSelected = (regionId, regionLevel) => {
        // If the region has no ID (like when selecting a movement), do nothing
        if (!regionId) {
            return;
        }

        const {
            session: { visits, regions },
        } = this.props;
        const { selectedRegionId, selectedRegionLevel } = this.state;

        // If user clicked on the same region that's already selected
        if (regionId === selectedRegionId && regionLevel === selectedRegionLevel) {
            this.setState({
                selectedRegionId: null,
                selectedRegionName: null,
                selectedRegionVisits: [],
            });
        } else {
            const selectedRegion = regions.find(
                (r) => r.regionId === regionId && r.regionCategoryLevel === regionLevel
            );

            this.setState({
                selectedRegionId: regionId,
                selectedRegionName: selectedRegion.regionName,
                selectedRegionVisits: visits.byTime.filter(
                    (v) => v.regionId === regionId && v.regionCategoryLevel === regionLevel
                ),
                selectedRegionLevel: selectedRegion.regionCategoryLevel,
            });
        }
    };

    handleRegionLevelSelected = (level) => {
        this.setState({
            selectedRegionLevel: level,
        });
    };

    handleHeatMapMaxDurationChange = (event) => {
        this.setState({ heatMapMaxDuration: parseFloat(event.target.value) });
    };

    handleTimelineZoomEnd = ({ startTimestamp, endTimestamp }) => {
        const {
            session: { earliestTime, latestTime },
        } = this.props;

        this.setState({
            displayedSessionSegmentStart: moment
                .max(moment(startTimestamp), moment(earliestTime))
                .format('YYYY-MM-DD HH:mm:ss.SSS'),
            displayedSessionSegmentEnd: moment
                .min(moment(endTimestamp), moment(latestTime))
                .format('YYYY-MM-DD HH:mm:ss.SSS'),
        });
    };

    handleHeatMapToggle = (event) => {
        this.setState({ isShowingHeatMap: event.target.checked });
    };

    handleClose = (reason, onClose) => {
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    handleRegionResetSelect = () => {
        this.setState({
            selectedRegionId: null,
            selectedRegionName: null,
            selectedRegionVisits: [],
        });
    };

    render() {
        const { session, isOpen, onClose } = this.props;

        const {
            isLoading,
            selectedRegionId,
            selectedRegionName,
            selectedRegionVisits,
            selectedRegionLevel,
            heatMapMaxDuration,
            displayedSessionSegmentStart,
            displayedSessionSegmentEnd,
            isShowingHeatMap,
        } = this.state;

        const { LEVEL_SELECTOR_ID } = this.chartsSelectors;
        const { acceptedAccuracy, inaccuracySmoothing } = this.filters;
        const { sessionId } = session;

        if (!session) {
            return null;
        }

        return (
            <DialogWrapper
                classes={{ paper: 'dialog-paper' }}
                open={isOpen}
                onClose={(e, reason) => this.handleClose(reason, onClose)}
                maxWidth={'xl'}
                fullWidth
                isLoading={isLoading}
                style={{ zIndex: 1600 }} // // Overriding the default zIndex due to the MUI version upgrade
            >
                {isLoading && (
                    <PreloaderWrapper>
                        <PulseLoader color={'coral'} size={20} margin={'5px'} />
                        {/* TODO color should come from theme */}
                    </PreloaderWrapper>
                )}

                <LoadingWrapper visible={!isLoading}>
                    <DialogContentWrapper>
                        <Grid container justifyContent={'space-between'}>
                            <Grid container alignItems={'center'} component={FiltersWrapper}>
                                <Grid item>
                                    <AcceptedAccuracyTextField
                                        defaultValue={acceptedAccuracy}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    Accepted Accuracy:
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={({ target: { value } }) =>
                                            this.handleFilterChange('acceptedAccuracy', value)
                                        }
                                    />
                                </Grid>

                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                defaultChecked={inaccuracySmoothing}
                                                onChange={({ target: { checked } }) =>
                                                    this.handleFilterChange('inaccuracySmoothing', checked)
                                                }
                                                color={'primary'}
                                            />
                                        }
                                        label={<Label>Inaccuracy Smoothing</Label>}
                                    />
                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size={'small'}
                                        onClick={this.handleFiltersSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>

                            <CloseIconWrapper aria-label="Close" onClick={onClose}>
                                <CloseIcon />
                            </CloseIconWrapper>
                        </Grid>

                        <Divider />

                        <PositionsTimelineChart
                            session={session}
                            onRegionSelect={this.handleRegionSelected}
                            onZoomEnd={this.handleTimelineZoomEnd}
                            selectedRegionId={selectedRegionId}
                            selectedRegionLevel={selectedRegionLevel}
                        />

                        <Divider />

                        <Grid container component={HeatMapAndChartsWrapper}>
                            <Grid item container xs={12} md={10}>
                                <HeatMapToggle>
                                    <Typography variant={'subtitle2'}>Visits</Typography>

                                    <Switch
                                        checked={isShowingHeatMap}
                                        onChange={this.handleHeatMapToggle}
                                        color="default"
                                    />

                                    <Typography variant={'subtitle2'}>Heat Map</Typography>
                                </HeatMapToggle>

                                {isShowingHeatMap ? (
                                    <>
                                        <Grid item xs={11} component={HeatMapWrapper}>
                                            <SessionHeatmap
                                                session={session}
                                                mapIds={session.maps.map((m) => m.mapId)}
                                                maxDuration={heatMapMaxDuration}
                                                acceptedAccuracy={acceptedAccuracy}
                                                displayedSessionSegmentStart={displayedSessionSegmentStart}
                                                displayedSessionSegmentEnd={displayedSessionSegmentEnd}
                                                selectedRegionId={selectedRegionId}
                                                heatMapValueOffset={heatMapValueOffset}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            container
                                            xs={1}
                                            wrap={'nowrap'}
                                            component={HeatMapLegendWrapper}
                                        >
                                            <HeatMapLegend
                                                maxValue={heatMapMaxDuration}
                                                minValue={0}
                                                onMaxValueChange={this.handleHeatMapMaxDurationChange}
                                                valueOffset={heatMapValueOffset}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <RegionsVisitationDetailsWrapper>
                                        <RegionVisitationDetails
                                            visits={selectedRegionVisits}
                                            regionName={selectedRegionName}
                                        />
                                    </RegionsVisitationDetailsWrapper>
                                )}
                            </Grid>

                            <Grid
                                item
                                container
                                xs={12}
                                md={2}
                                direction={'column'}
                                alignItems={'center'}
                                component={ChartsWrapper}
                            >
                                <Grid item>
                                    <Header>Time Spent in Regions</Header>

                                    <PieWrapper>
                                        <RegionDistributionPieChart
                                            uniqueId={sessionId}
                                            data={session}
                                            selectedRegionId={selectedRegionId}
                                            selectedRegionLevel={selectedRegionLevel}
                                            onInitialize={this.handleChartInitialize}
                                            onUnmount={this.handleChartUnmount}
                                            onRegionSelected={this.handleRegionSelected}
                                            onRegionResetSelected={this.handleRegionResetSelect}
                                        />
                                    </PieWrapper>

                                    <LevelSelectorWrapper>
                                        <LevelSelectorTitle>Region Level:</LevelSelectorTitle>

                                        <LevelSelector
                                            id={LEVEL_SELECTOR_ID}
                                            placeholder={''}
                                            value={this.regionLevelOptions.find(
                                                (l) => l.level === selectedRegionLevel
                                            )}
                                            onChange={({ level }) => this.handleRegionLevelSelected(level)}
                                            options={this.regionLevelOptions}
                                            getOptionLabel={({ level }) => level}
                                            getOptionValue={({ level }) => level}
                                        />
                                    </LevelSelectorWrapper>
                                </Grid>

                                <Grid item>
                                    <Header>Movement</Header>

                                    <MovementDistributionBarChart uniqueId={sessionId} data={session} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Divider />

                        <RegionsLegend data={session} onRegionClick={this.handleRegionSelected} />
                    </DialogContentWrapper>
                </LoadingWrapper>
            </DialogWrapper>
        );
    }
}

SessionDialog.propTypes = {
    session: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    processSelectedSession,
};

export default connect(null, mapDispatchToProps)(SessionDialog);
