import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '../utils';
import shiftManagementApi from '../../api/shiftManagementApi';

export const fetchShiftManagementData = createAsyncThunk('shiftManagement/fetchShiftManagementData', async (threshold) => {
    return (await shiftManagementApi.fetchShiftManagementData(threshold)).data;
});

export const activeHelpRequestCreated = createAction('shiftManagement/activeHelpRequest/created');
export const activeHelpRequestAcknowledgeCreated = createAction('shiftManagement/activeHelpRequestAcknowledge/created');
export const thresholdToggledCreated = createAction('shiftManagement/thresholdToggled/created');
export const isFetchedCreated = createAction('shiftManagement/isFetched/created');