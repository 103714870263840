import React, { memo, useContext, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { MapViewerContext } from '../../MapViewerContext';
import clsx from 'clsx';
import {
    BOUNDARY_MAX_X,
    BOUNDARY_MAX_Y,
    BOUNDARY_MIN_X,
    BOUNDARY_MIN_Y,
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import { getMapViewerLineOfInterestId } from './MapViewerLinesOfInterestOverlay.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { highLightAreaOfInterest, selectMappingHighlightedAreaOfInterestId, unhilightAreaOfInterest } from '../../../../../state-management/user-inputs/mappingSlice';

const Line = styled.polyline`
    fill: none;
    stroke: #00CEF1; // TODO color should come from theme
    stroke-width: calc(2.5px / var(${SCALE}));
    stroke-linejoin: round;
    stroke-linecap: round;
    marker-start: url(#loi-marker-dot);
    marker-mid: url(#loi-marker-dot);
    marker-end: url(#loi-marker-arrow);
    cursor: pointer;

    :hover:not(.read-only),
    &.highlighted:not(.read-only) {
        stroke: #00b1f1;
        marker-start: url(#highlighted-marker-dot);
        marker-end: url(#highlighted-marker-arrow);
    }

    &&&.selected {
        stroke: #7a007a;
        marker-start: url(#selected-marker-dot);
        marker-end: url(#selected-marker-arrow);
    }

    &&&.disabled {
        stroke: #7b7b7b;
        marker-start: url(#disabled-marker-dot);
        marker-end: url(#disabled-marker-arrow);
        pointer-events: none;
        cursor: not-allowed;
    }
    
    
`;

function LineOfInterest(props) {
    const dispatch = useDispatch()
    const { line, onClick, isSelected = false, disabled = false } = props;
    const { lineId, points } = line ?? {};

    const { getCSSVariable } = useContext(MapViewerContext);

    const polylinePoints = useMemo(() => {
        const maxX = parseFloat(getCSSVariable(BOUNDARY_MAX_X));
        const minX = parseFloat(getCSSVariable(BOUNDARY_MIN_X));
        const maxY = parseFloat(getCSSVariable(BOUNDARY_MAX_Y));
        const minY = parseFloat(getCSSVariable(BOUNDARY_MIN_Y));

        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        return points.reduce((result, point) => {
            if (!point) {
                return result;
            }

            // Limit the X and Y coordinates to the boundaries of the map image
            const boundedX = Math.min(Math.max(point.x, minX), maxX);
            const boundedY = Math.min(Math.max(point.y, minY), maxY);
            const x = (boundedX - mapOffsetX) * pixelToMeter;
            const y = (imageNaturalHeight / pixelToMeter - (boundedY - mapOffsetY)) * pixelToMeter;

            return [...result, x, y];
        }, []);
    }, [getCSSVariable, points]);

    const highlightedId = useSelector(selectMappingHighlightedAreaOfInterestId);
    const isHighlighted = highlightedId === lineId;

    const onClickHandler = (e) => {
        onClick(lineId, e.shiftKey)
    }

    if (!line) {
        return null;
    }

    return (
        <Line
            id={getMapViewerLineOfInterestId(lineId)}

            onPointerEnter={() => dispatch(highLightAreaOfInterest(lineId))}
            onPointerLeave={() => dispatch(unhilightAreaOfInterest(lineId))}
            className={clsx({
                selected: isSelected,
                highlighted: isHighlighted,
                disabled

            })}
            points={polylinePoints}
            onClick={onClickHandler}
        />
    );
}

LineOfInterest.propTypes = {
    line: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    isSelected: PropTypes.bool,
};

export default memo(LineOfInterest);
