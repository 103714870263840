import Api from './Api';
import http from '../utils/httpClient';

class FloorsApi extends Api {
    async createFloor(buildingId, floorData) {
        return await this.callApi(params =>
            http.v3.post(`/buildings/${buildingId}/floors`, floorData, { params })
        );
    }

    async updateFloor(buildingId, floorId, floorData) {
        return await this.callApi(params =>
            http.v3.post(`/buildings/${buildingId}/floors/${floorId}`, floorData, { params })
        );
    }

    async deleteFloor(buildingId, floorId) {
        return await this.callApi(params =>
            http.v3.delete(`/buildings/${buildingId}/floors/${floorId}`, { params })
        );
    }
}

export default new FloorsApi();
