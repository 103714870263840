export const SCALE = '--scale';
export const TRANSLATE_X = '--translate-x';
export const TRANSLATE_Y = '--translate-y';
export const PREV_POINTER_X = '--prev-pointer-x';
export const PREV_POINTER_Y = '--prev-pointer-y';
export const IS_PANNING = '--is-panning';
export const IMAGE_NATURAL_HEIGHT = '--image-natural-height';
export const IMAGE_NATURAL_WIDTH = '--image-natural-width';
export const OFFSET_X = '--offset-x';
export const OFFSET_Y = '--offset-y';
export const PIXEL_TO_METER = '--pixel-to-meter';
export const CURSOR = '--cursor';
export const BOUNDARY_MAX_X = '--boundary-max-x';
export const BOUNDARY_MAX_Y = '--boundary-max-y';
export const BOUNDARY_MIN_X = '--boundary-min-x';
export const BOUNDARY_MIN_Y = '--boundary-min-y';

/* Google Maps overlay */
export const GMAPS_METERS_PER_PIXEL = '--gmaps-meters-per-pixel';
export const GMAPS_MAP_OPACITY = '--gmaps-map-opacity';
export const GMAPS_BUILDING_TO_ENU_ROTATION = '--gmaps-building-to-enu-rotation';
export const GMAPS_BUILDING_ORIGIN_LAT = '--gmaps-building-origin-lat';
export const GMAPS_BUILDING_ORIGIN_LNG = '--gmaps-building-origin-lng';
export const GMAPS_BUILDING_ORIGIN_ALT = '--gmaps-building-origin-alt';
