import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import eventsManagerWs from '../../ws/events-manager/eventsManagerWs';
import {
    getAreaOfInterestCreatedTopic,
    getAreaOfInterestDeletedTopic,
    getAreaOfInterestUpdatedTopic,
    getLaneCreatedTopic,
    getLaneDeletedTopic,
    getLaneUpdatedTopic,
    getMapperAliveTopic,
    getMapperExpiredTopic,
    getMapperHelpRequestedTopic,
    getMapperPositionUpdatedTopic,
    getMapperSuperMapperToggledTopic,
    getValidationCoordinatesDeletedTopic,
    getValidationCoordinatesUpdatedTopic,
    getValidationMapUpdatedTopic,
    getMapEqualizationStartedTopic,
    getMapEqualizationFinishedTopic,
    getLineOfInterestCreatedTopic,
    getLineOfInterestUpdatedTopic,
    getLineOfInterestDeletedTopic,
    getSignMarkCreatedTopic,
    getSignMarkUpdatedTopic,
    getSignMarkDeletedTopic,
} from '../../constants/eventTopics';
import LaneDetailsSideDrawer from './lane/LaneDetailsSideDrawer';
import AreaOfInterestDetailsSideDrawer from './area-of-interest/AreaOfInterestDetailsSideDrawer';
import MappingMapViewer from './MappingMapViewer';
import MappingSideDrawer from './MappingSideDrawer';
import MapperHelpRequestNotification from './MapperHelpRequestNotification';
import LineOfInterestDetailsSideDrawer from './line-of-interest/LineOfInterestDetailsSideDrawer';
import { useMappingBanner } from './hooks/useMappingBanner';
import ConfirmDialog from '../map-quality/settings/components/ConfirmDialog';
import { useEventManagerWs } from '../../ws/hooks/useEventManagerWs';

export default function Mapping() {
    const {
        show: showDialog,
        confirmText,
        onConfirm,
        onCancel,
        title,
        content,
    } = useSelector((state) => state.general.dialog);

    const { selectedMapId, bannerLoading, features = {} } = useMappingBanner();

    const topics = useMemo(() => {
        if (!selectedMapId) {
            return [];
        }

        return [
            getLaneCreatedTopic(selectedMapId),
            getLaneUpdatedTopic(selectedMapId),
            getLaneDeletedTopic(selectedMapId),
            getMapperAliveTopic(selectedMapId),
            getMapperSuperMapperToggledTopic(selectedMapId),
            getMapperExpiredTopic(selectedMapId),
            getMapperPositionUpdatedTopic(selectedMapId),
            getMapperHelpRequestedTopic(selectedMapId),
            getAreaOfInterestCreatedTopic(selectedMapId),
            getAreaOfInterestUpdatedTopic(selectedMapId),
            getAreaOfInterestDeletedTopic(selectedMapId),
            getLineOfInterestCreatedTopic(selectedMapId),
            getLineOfInterestUpdatedTopic(selectedMapId),
            getLineOfInterestDeletedTopic(selectedMapId),
            getValidationCoordinatesUpdatedTopic(selectedMapId),
            getValidationCoordinatesDeletedTopic(selectedMapId),
            getValidationMapUpdatedTopic(selectedMapId),
            getMapEqualizationStartedTopic(selectedMapId),
            getMapEqualizationFinishedTopic(selectedMapId),
            getSignMarkCreatedTopic(selectedMapId),
            getSignMarkUpdatedTopic(selectedMapId),
            getSignMarkDeletedTopic(selectedMapId)
        ];
    }, [selectedMapId]);

    useEventManagerWs({ topics })

    return (
        <>
            <ConfirmDialog
                loading={bannerLoading}
                message={title}
                content={content}
                open={showDialog}
                confirmText={confirmText}
                onConfirm={onConfirm}
                onClose={onCancel}
            />
            <MappingMapViewer selfHealingEnabled={features.selfHealingEnabled} />
            <MappingSideDrawer selfHealingEnabled={features.selfHealingEnabled} />
            <LaneDetailsSideDrawer disabled={features.selfHealingEnabled} />
            <AreaOfInterestDetailsSideDrawer type='normal' />
            <AreaOfInterestDetailsSideDrawer type='element' />
            <LineOfInterestDetailsSideDrawer />
            <MapperHelpRequestNotification />
        </>
    );
}
