import { createAsyncThunk } from '../../utils';
import lanesApi from '../../../api/LanesApi';
import eventsManagerWs from '../../../ws/events-manager/eventsManagerWs';
import { selectAllJunctionMismatches } from '../junction-mismatch/junctionMismatchSelectors';
import { createAction } from '@reduxjs/toolkit';

export const lanesFetched = createAction('lanes/fetched');

export const fetchAllLanesInMap = createAsyncThunk('lanes/fetchAllInMap', async (mapId, { dispatch }) => {
    await lanesApi.fetchAllLanesInMap(mapId, (lanes) => dispatch(lanesFetched({ mapId, data: lanes })));
});

export const fetchLane = createAsyncThunk('lanes/fetchOne', async ({ mapId, laneId }) => {
    return (await lanesApi.fetchLane(mapId, laneId)).data;
});

export const fetchAllLaneStatusesInMap = createAsyncThunk(
    'lanes/fetchAllStatusesInMap',
    async ({ mapId, useEqualizedMap }) => {
        return (await lanesApi.fetchAllLaneStatusesInMap(mapId, useEqualizedMap)).data;
    }
);

export const fetchLaneAttachments = createAsyncThunk('lanes/fetchAttachments', async ({ mapId, laneId }) => {
    return (await lanesApi.fetchLaneAttachments(mapId, laneId)).data;
});

export const updateLane = createAsyncThunk('lanes/update', async ({ mapId, laneId, laneData }, { dispatch}) => {
    const response = (await lanesApi.updateLane(mapId, laneId, laneData)).data;
    await eventsManagerWs.publish(`v1.mapping.${mapId}.lane.updated`, { mapId }, response, true);
    return response;
});

export const deleteLane = createAsyncThunk('lanes/delete', async ({ mapId, laneId }, { getState, dispatch }) => {
    // Get all the junctions that were on the deleted lane
    const allJunctions = selectAllJunctionMismatches(getState());
    const junctionIds = allJunctions
        .filter((j) => j.firstPointLaneId === laneId || j.secondPointLaneId === laneId)
        .map((j) => j.junctionId);

    await lanesApi.deleteLane(mapId, laneId);
    await eventsManagerWs.publish(`v1.mapping.${mapId}.lane.deleted`, { mapId }, { laneId }, true);
    return { junctionIds };
});

export const cropLanes = createAsyncThunk('lanes/cropLanes', async ({ mapId, areaData }, thunkAPI) => {
    try {
        return (await lanesApi.cropLanes(mapId, areaData)).data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
