import React, { useEffect, useState, useRef } from 'react';
import { FormControlLabel, Tooltip } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { FilterToolBar } from './FilterToolBar';
import { Menu } from '@material-ui/core';
import { IconButton, MenuItem } from '@material-ui/core';
import { RiListSettingsLine as SettingsIcon } from 'react-icons/ri';
import styled from 'styled-components';
import { Checkbox } from '../index';
import { checkForExpandedAllColumn, convertFiltersForRequest, menuOptions } from './utils';
import { ExportCsv } from '@material-table/exporters';
import { Portal, Snackbar as MuiSnackbar, SnackbarContent } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Button, CircularPreloader, TextField } from '..';
import { modifyNumbersToFixed } from '../../../../state-management/productLocations/productLocationsReducer'; //TO DO: Need to export it from utils
import useTable from '../../hooks/useTable';
import { ChevronRight } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { selectCommonIsNavbarOpen } from '../../../../state-management/user-inputs/commonSlice';

const reorderPosition = (arr, sourceIndex, destinationIndex) => {
    const array = [...arr];
    const source = array.splice(sourceIndex, 1)[0];
    array.splice(destinationIndex, 0, source);
    return array;
};

// The relevant style is for align the expanded chevron icon in the column level to left
const TableWrapper = styled.div`
    /* ${({ expandedTable }) =>
        expandedTable &&
        `& table > thead > tr > th:not(:first-child) , & table > tbody > tr > td:not(:first-child){
        
        &:nth-child(2){
            padding: 0;
            transform: translateX(10px);
        }
        
        transform: translateX(-40px);
    }
    & table > thead > tr > th:not(:first-child) {
        &:nth-child(2){
            transform: translateX(-38px);
        }
    }
    `} */
    ${({ expandedTable }) =>
        expandedTable &&
        `
        & .MuiTableCell-sizeSmall {
            padding-left: 0px !important;
        }
        & table > thead > tr > th:not(:first-child){
        &:nth-child(2){
            width: auto !important;
            transform: translateX(-40px);
            padding: 0px;
        }
       
    `}
`;

const CustomChevronIcon = styled(ChevronRight)`
    transform: ${({ allExpanded }) => (allExpanded ? 'rotateZ(90deg)' : 'rotateZ(0deg)')};
    transition: transform 0.3s;
`;

const Row = styled.div`
    display: flex;
    & > *:first-child {
        width: 100%;
    }
    & > *:last-child {
        align-self: center;
    }
`;

const SettingsMenu = styled(Menu)``;

const Snackbar = styled(MuiSnackbar)`
    position: absolute;
`;

const SnackbarButton = styled(Button)`
    color: white;
`;
const SnackbarTextField = styled(TextField)`
    &&& .themed-underline {
        ::before {
            border-bottom-color: white; // TODO color should come from theme
        }
        :hover::before {
            border-bottom-color: white; // TODO color should come from theme
        }
    }

    & .themed-input-label {
        color: white; // TODO color should come from theme
    }

    input {
        color: white;
    }
`;

export const Table = (props) => {
    const {
        id,
        columns,
        actions,
        dependencies = [],
        count,
        data,
        title,
        tableOffsetWidth = 0,
        tableOffsetHeight = 0,
        detailPanel,
        maxBodyHeight,
        minBodyHeight,
        options = {},
        setData,
        additionalMenuOptions = {},
        isLoading = false,
    } = props;
    const { handleFilter, handleSort, handlePaginate, tableDispatch, state, tableTheme } = useTable({
        name: id,
        columns,
        actions,
        dependencies,
    });

    const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
    const rowRef = useRef(null);
    const tableRef = useRef(null);
    const [exportFileName, setExportFileName] = useState('');
    const [open, setOpen] = useState(false);
    const [loadExportData, setLoadExportData] = useState(false);
    const [allExpanded, setAllExpanded] = useState(false);
    const isNavbarOpen = useSelector(selectCommonIsNavbarOpen);
    const [width, setWidth] = useState(window.innerWidth - tableOffsetWidth - (isNavbarOpen ? 295 : 80));
    const [height, setHeight] = useState(window.innerHeight - tableOffsetHeight);
    const onResizeHandler = (event) => {
        setWidth(window.innerWidth - tableOffsetWidth - (isNavbarOpen ? 295 : 90));
        setHeight(window.innerHeight - tableOffsetHeight);
    };

    useEffect(() => {
        onResizeHandler();
    }, [isNavbarOpen]);

    useEffect(() => {
        window.addEventListener('resize', onResizeHandler);
        return () => {
            window.removeEventListener('resize', onResizeHandler);
        };
    }, []);

    const theme = createTheme(tableTheme);
    const onToggleDetailPanel = (event, rowData) => {
        let closedPanels = 0;
        let openedPanels = 0;
        const updatedData = data.map((record) => {
            if (record.mapId === rowData.mapId) {
                if (!record.tableData) {
                    record.tableData = {
                        showDetailPanel: false,
                    };
                }
                record.tableData.showDetailPanel = !record.tableData.showDetailPanel;
            }

            if (record.tableData?.showDetailPanel) {
                openedPanels++;
            } else {
                closedPanels++;
            }
            return record;
        });
        if (openedPanels === data.length) {
            setAllExpanded(true);
        } else if (closedPanels === data.length) {
            setAllExpanded(false);
        }
        setData?.(updatedData);
    };

    useEffect(() => {
        if (!!detailPanel && tableRef.current) {
            tableRef.current.onToggleDetailPanel = ([index], render) => {
                const rowData = data[index];
                onToggleDetailPanel({}, rowData);
            };
        }
    }, [data]);

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }, [data, count]);

    const handleCancel = () => {
        setOpen(false);
        setExportFileName('');
    };

    const handleClick = async () => {
        setOpen(false);
        setLoadExportData(true);
        const paginate = { page: 0, pageSize: count };
        const data = (
            await props?.handleExportFileRequest(
                convertFiltersForRequest(state.table.filters),
                paginate,
                state.table.sort
            )
        )?.data;
        setLoadExportData(false);
        if (data) {
            const modifiedData = data?.map(modifyNumbersToFixed) || [];
            ExportCsv(state.table.columns, modifiedData, exportFileName || new Date().toLocaleDateString());
        }
        setExportFileName('');
    };

    const handleExportFileName = (event) => {
        const { value } = event.target;
        setExportFileName(value);
    };

    const handleHiddenColumns = (col) => {
        const currentColumns = state.table.columns;
        const updatedColumns = currentColumns.map((c) => {
            if (c.index === col.index) {
                return { ...col, title: c.title };
            } else {
                return c;
            }
        });
        tableDispatch({ type: 'setColumns', payload: updatedColumns });
    };

    const handleDraggedColumns = (source, destination) => {
        const currentColumns = state.table.columns;
        const visibleColumns = currentColumns.filter((col) => !col.hidden);
        if (!visibleColumns[source] || visibleColumns[destination]) return;
        // Getting the real index of the columns from the current columns
        const sourceIndexItem = visibleColumns[source].index;
        const destinationIndexItem = visibleColumns[destination].index;

        // Reorder the position and update the right index position
        const updatedColumns = reorderPosition(currentColumns, sourceIndexItem, destinationIndexItem).map(
            (val, i) => {
                return {
                    ...val,
                    index: i,
                };
            }
        );

        tableDispatch({ type: 'setColumns', payload: updatedColumns });
    };

    const renderExpandedColumnIcon = () => {
        const iconClickHandler = () => {
            setAllExpanded(!allExpanded);
            const updatedData = data.map((d) => ({ ...d, tableData: { showDetailPanel: !allExpanded } }));
            setData?.(updatedData);
        };
        return (
            <IconButton onClick={iconClickHandler}>
                <CustomChevronIcon allExpanded={allExpanded} />
            </IconButton>
        );
    };

    const renderColumns = (options = {}) => {
        const currentColumns = state.table.columns || columns;
        const updatedColumns = checkForExpandedAllColumn(!!detailPanel, currentColumns);
        return (
            updatedColumns.map((col, i) => {
                const { render } = col;
                const localStorageSort = state.table.sort;
                const sortDirection =
                    localStorageSort?.key === col.field ? localStorageSort?.direction?.toLowerCase() : null;
                const dynamicOptions = options[i] || {};
                return {
                    ...col,
                    title: col.expandedAll ? (
                        renderExpandedColumnIcon()
                    ) : (
                        <Tooltip title={col.description ?? ''}>
                            <span>{col.title}</span>
                        </Tooltip>
                    ),
                    ...dynamicOptions,
                    ...{ render: render || undefined },
                    sortDirection,
                };
            }) || []
        );
    };

    if (loadExportData)
        return (
            <CircularPreloader
                style={{ position: 'absolute', left: '50%', top: '50%', width: 70, height: 70 }}
            />
        );
    return (
        <>
            <TableWrapper ref={rowRef} expandedTable={!!detailPanel}>
                <MuiThemeProvider theme={theme}>
                    <MaterialTable
                        tableRef={tableRef}
                        components={{
                            Toolbar: ({ columns, ...restData }) => {
                                const updatedColumns = columns.filter((col) => !col.expandedAll);
                                return (
                                    <div>
                                        <Row>
                                            <MTableToolbar {...restData} columns={updatedColumns} />
                                            <IconButton
                                                onClick={(e) => {
                                                    setIsSettingsMenuOpen(true);
                                                }}
                                            >
                                                <SettingsIcon size={20} />
                                            </IconButton>
                                            <SettingsMenu
                                                open={isSettingsMenuOpen}
                                                anchorEl={rowRef.current}
                                                getContentAnchorEl={null}
                                                onClose={() => setIsSettingsMenuOpen(false)}
                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            >
                                                {menuOptions.map((option, index) => {
                                                    const moreOptions =
                                                        additionalMenuOptions[option.name] || {};
                                                    return (
                                                        <MenuItem key={index}>
                                                            <FormControlLabel
                                                                label={option.label}
                                                                control={
                                                                    <Checkbox
                                                                        checked={state[option.name]}
                                                                        onChange={(e) => {
                                                                            return tableDispatch({
                                                                                type: option.action,
                                                                                payload: e.target.checked,
                                                                            });
                                                                        }}
                                                                        {...moreOptions}
                                                                    />
                                                                }
                                                            />
                                                        </MenuItem>
                                                    );
                                                })}
                                            </SettingsMenu>
                                        </Row>
                                        {state.isFiltering && (
                                            <FilterToolBar
                                                id={id}
                                                columns={state.table.columns}
                                                defaultFilters={state.table.filters}
                                                onChange={(filters) => {
                                                    tableDispatch({ type: 'setFilters', payload: filters });
                                                    handleFilter(filters);
                                                }}
                                            />
                                        )}
                                    </div>
                                );
                            },
                        }}
                        title={title || ''}
                        columns={renderColumns()}
                        data={(query) => {
                            return new Promise((resolve, reject) => {
                                // prepare your data and then call resolve like this:
                                resolve({
                                    data,
                                    page: state.table.paginate.page,
                                    totalCount: count, // total row number
                                });
                            });
                        }}
                        style={{ width: width, height: height, transition: 'all 0.3s' }}
                        isLoading={isLoading}
                        options={{
                            minBodyHeight: height - 180,
                            maxBodyHeight: height - 180,
                            emptyRowsWhenPaging: false,
                            thirdSortClick: false,
                            headerStyle: {
                                fontWeight: '500',
                                color: 'gray',
                                borderBottom: '2px solid #ff6200',
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                            },
                            rowStyle: { fontWeight: 300 },
                            padding: 'dense',
                            pageSize: state.table.paginate.pageSize,
                            pageSizeOptions: [10, 50, 100, 200, 500, 1000],
                            grouping: state.isGrouping,
                            columnsButton: state.isShowColumns,
                            search: false,
                            exportMenu: state.isExporting && [
                                {
                                    label: 'Export CSV',
                                    exportFunc: (cols) => {
                                        if (data.length) {
                                            setOpen(true);
                                        }
                                    },
                                },
                            ],
                            ...options,
                        }}
                        detailPanel={detailPanel}
                        onPageChange={handlePaginate}
                        onOrderChange={(...args) => {
                            handleSort(...args, !!detailPanel);
                        }}
                        onChangeColumnHidden={handleHiddenColumns}
                        onColumnDragged={handleDraggedColumns}
                    />
                </MuiThemeProvider>
            </TableWrapper>
            <Portal>
                <Snackbar
                    id={'exportFileNamePopup'}
                    open={open}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <SnackbarContent
                        message={
                            <>
                                <p>Save as</p>
                                <SnackbarTextField
                                    onChange={handleExportFileName}
                                    value={exportFileName || ''}
                                />
                            </>
                        }
                        action={
                            <>
                                <SnackbarButton variant={'text'} onClick={handleCancel}>
                                    Cancel
                                </SnackbarButton>
                                <SnackbarButton variant={'text'} onClick={handleClick}>
                                    {'SAVE'}
                                </SnackbarButton>
                            </>
                        }
                    />
                </Snackbar>
            </Portal>
        </>
    );
};
