import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { PulsePreloader, Select } from '../../common/themed';
import {
    getRegionSelectId,
    getTimeResolutionButtonId,
    getTimeResolutionWrapperId,
} from './RegionOccupancy.selectors';
import { nameToColor } from '../common/RegionColors';
import { useDispatch, useSelector } from 'react-redux';
import {
    getOccupancyDataAvailableTimeResolutions,
    getOccupancySubmittedFilters,
} from '../../../state-management/analytics/region-occupancy/regionOccupancySelectors';
import { getTimeResolutionByName } from './time-resolutions';
import {
    selectAnalyticsBuildingUsageDisplaySettingsUserInputs,
    setSelectedRegionIds,
    setSelectedTimeResolution,
} from '../../../state-management/user-inputs/analyticsBuildingUsageSlice';
import useAllRegionsInSpace from '../../common/hooks/data-fetching/useAllRegionsInSpace';
import { selectBuildingIdToRegionsArray } from '../../../state-management/building/buildingSelectors';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';
import { selectFloorIdToRegionsArray } from '../../../state-management/floor/floorSelectors';

// Maximum number of regions that can be selected to display in the charts
const maxDisplayedRegions = 6;

const ToggleButton = styled.button`
    padding: 10px;
    min-width: 4rem;
    font-size: 1rem;
    background-color: ${(props) =>
        props.isSelected ? 'coral' : 'white'}; // TODO color should come from theme
    color: ${(props) => (props.isSelected ? 'white' : '#333')}; // TODO color should come from theme;
    border: 1px solid coral; // TODO color should come from theme
    border-radius: 3px;
    cursor: pointer;
    transition: all 200ms ease;
    outline: none;

    :hover {
        background-color: ${(props) => (props.isSelected ? 'coral' : '#ffd0b3')};
    }
`;

// Color every region that's selected in its matching color
const RegionSelect = styled(Select)`
    width: 700px;
    ${(props) =>
        props.value.map(
            (v, i) => `
            [class*='multi-value-option']:nth-of-type(${i + 1}) {
                margin: 3px 3px;
                background-color: ${
                    (props.value[i] && nameToColor(props.value[i].regionName)) || 'coral'
                }; // TODO color should come from theme
                color: white
            }`
        )}
`;

const ToggleButtonsWrapper = styled.div`
    ${ToggleButton}:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    ${ToggleButton}:not(:last-child) {
        border-right: none;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
`;

export default function RegionOccupancyChartsDisplaySettings() {
    const [timeResolutionOptions, setTimeResolutionOptions] = useState([]);
    const [regions, setRegions] = useState([]);

    const dispatch = useDispatch();

    const { data, isLoading: isLoadingRegions, hasError } = useAllRegionsInSpace();

    const availableTimeResolutions = useSelector(getOccupancyDataAvailableTimeResolutions);
    const { selectedBuildingId, selectedFloorId } = useSelector(getOccupancySubmittedFilters);
    const { selectedRegionIds, selectedTimeResolution } = useSelector(
        selectAnalyticsBuildingUsageDisplaySettingsUserInputs
    );
    const { [selectedBuildingId]: regionsInBuilding = [] } = useSelector(selectBuildingIdToRegionsArray);
    const { [selectedFloorId]: regionsInFloor = [] } = useSelector(selectFloorIdToRegionsArray);

    const regionOptions = selectedFloorId ? regionsInFloor : regionsInBuilding;

    useEffect(() => {
        // If the occupancy data changes, update the time resolutions and regions options
        const timeResolutionOptions = [];

        for (const timeResolution of availableTimeResolutions) {
            timeResolutionOptions.push({
                value: timeResolution,
                label: getTimeResolutionByName(timeResolution).label,
            });
        }

        setTimeResolutionOptions(
            timeResolutionOptions.sort(
                (tr1, tr2) =>
                    getTimeResolutionByName(tr1.value).inMinutes -
                    getTimeResolutionByName(tr2.value).inMinutes
            )
        );
    }, [availableTimeResolutions]);

    useEffect(() => {
        // If the region options changed, and there's no selected region,
        // or some of the selected ones are no longer among the options, select the first few by default
        if (selectedRegionIds.length === 0 && regionOptions.length > 0) {
            dispatch(
                setSelectedRegionIds(regionOptions.slice(0, maxDisplayedRegions).map((r) => r.regionId))
            );
        }
    }, [regionOptions, selectedRegionIds.length, dispatch]);

    useEffect(() => {
        // If the time resolution options changed, select the last (biggest) one by default
        if (timeResolutionOptions.length > 0) {
            dispatch(
                setSelectedTimeResolution(timeResolutionOptions[timeResolutionOptions.length - 1].value)
            );
        }
    }, [dispatch, timeResolutionOptions]);

    useEffect(() => {
        if (data && data.length) {
            const regionsToObj = data.reduce((result, r) => ({ ...result, [r.regionId]: r }), {});
            setRegions(regionsToObj);
        }
    }, [data]);

    if (hasError) {
        return <ErrorGeneral />;
    }

    if (isLoadingRegions) {
        return <PulsePreloader />;
    }

    return (
        <Grid container spacing={5}>
            <Grid item>
                <RegionSelect
                    id={getRegionSelectId()}
                    options={regionOptions}
                    valueContainerWrap={true}
                    isMulti
                    isClearable={false}
                    placeholder={'Select a Region...'}
                    getOptionLabel={(r) => r.regionName}
                    getOptionValue={(r) => r.regionId}
                    onChange={(regions) =>
                        dispatch(
                            // When no options are selected it passes null, we fallback to the current selection
                            setSelectedRegionIds(regions?.map((r) => r.regionId) || selectedRegionIds)
                        )
                    }
                    isOptionSelected={(r, values) => values.find((v) => r.regionId === v.regionId)}
                    value={selectedRegionIds.map((id) => regions?.[id])}
                />
            </Grid>

            <Grid item sm={2} xs={12} component={ToggleButtonsWrapper} id={getTimeResolutionWrapperId()}>
                {timeResolutionOptions.map(({ value, label }) => (
                    <ToggleButton
                        key={value}
                        id={getTimeResolutionButtonId(value)}
                        onClick={() => dispatch(setSelectedTimeResolution(value))}
                        isSelected={value === selectedTimeResolution}
                    >
                        {label}
                    </ToggleButton>
                ))}
            </Grid>
        </Grid>
    );
}
