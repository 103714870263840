import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './mapEntityAdapter';
import { createBuilding, fetchBuildingFullData } from '../building/buildingActions';
import {
    fetchMapFullData,
    createMap,
    updateMap,
    deleteMap,
    fetchAllMapsInFloor,
    createMapImage,
    deleteMapImage,
    getMapGridMetadata,
    createPublishMapGrid,
    resetToManualMapping,
} from './mapActions';
import { createFloor } from '../floor/floorActions';
import { deleteRegion, fetchAllRegionsInSpace } from '../region/regionActions';
import {
    createRegionInternalPlacement,
    updateRegionInternalPlacement,
    deleteRegionInternalPlacement,
    createRegionInternalPlacements,
} from '../region-placement/regionPlacementActions';
import { expireSession, logout } from '../auth/authActions';
import {
    deleteLane,
    fetchAllLanesInMap,
    fetchLane,
    fetchLaneAttachments,
    lanesFetched,
} from '../mapping/lane/laneActions';
import {
    deleteAllValidationsInMap,
    fetchAllValidationsInMap,
    mapValidationsFetched,
} from '../mapping/map-validation/mapValidationActions';
import {
    createAreaOfInterest,
    deleteAreaOfInterest,
    fetchAllAreasOfInterestInMap,
    fetchAreaOfInterestAttachments,
} from '../mapping/area-of-interest/areaOfInterestActions';
import { fetchAllJunctionMismatchesInMap } from '../mapping/junction-mismatch/junctionMismatchActions';
import { fetchAllMappersInMap } from '../mapping/mapper/mapperActions';
import {
    areaOfInterestCreated,
    areaOfInterestDeleted,
    areaOfInterestUpdated,
    elementsCreated,
    elementsDeleted,
    elementsUpdated,
    lineOfInterestCreated,
    lineOfInterestUpdated,
    lineOfInterestDeleted,
    exitRegionCreated,
    exitRegionDeleted,
    exitRegionUpdated,
    laneCreated,
    laneDeleted,
    laneUpdated,
    mapperAlive,
    mapperExpired,
    mapValidationsAllUpdated,
    mapValidationsDeleted,
    mapValidationsSomeUpdated,
    superMapperToggled,
} from '../mapping/mappingEventsActions';
import {
    createLineOfInterest,
    deleteLineOfInterest,
    fetchAllLinesOfInterestInMap,
} from '../mapping/line-of-interest/lineOfInterestActions';
import { createElement, deleteElement, fetchAllElementsInMap } from '../mapping/elements/elementsActions';
import { upsertMapFeature } from '../map-features/mapFeaturesActions';
import {
    upsertExitRegion,
    deleteExitRegion,
    fetchAllExitRegionsInMap,
} from '../mapping/exitRegion/exitRegionsActions';

const { getInitialState, getSelectors, upsertMany, upsertOne, addOne, removeOne } = entityAdapter;

export const {
    reducer,
    actions: { createCoordinatesConversionPoint },
} = createSlice({
    name: 'maps',
    initialState: getInitialState(),
    extraReducers: {
        [fetchBuildingFullData.fulfilled]: (state, action) => {
            const { floors } = action.payload;
            upsertMany(
                state,
                floors.reduce((result, { maps }) => [...result, ...maps], [])
            );
        },

        [createBuilding.fulfilled]: (state, action) => {
            upsertMany(
                state,
                action.payload.floors.reduce((result, { maps }) => [...result, ...maps], [])
            );
        },

        [createFloor.fulfilled]: (state, action) => {
            upsertMany(
                state,
                action.payload.floors.reduce((result, { maps }) => [...result, ...maps], [])
            );
        },
        [fetchMapFullData.fulfilled]: (state, { payload: mapFullData }) => {
            upsertOne(state, mapFullData);
        },
        [fetchAllMapsInFloor.fulfilled]: (state, action) => {
            upsertMany(state, action.payload);
        },

        [createMap.fulfilled]: (state, action) => {
            addOne(state, action.payload);
        },
        [createMapImage.fulfilled]: (state, action) => {
            const { mapId } = action.meta.arg;
            const map = getSelectors().selectById(state, mapId);
            upsertOne(state, { mapId, mapImages: [...map.mapImages, action.payload] });
        },
        [updateMap.fulfilled]: (state, action) => {
            upsertOne(state, action.payload);
        },

        [deleteMap.fulfilled]: (state, action) => {
            removeOne(state, action.meta.arg.mapId);
        },
        [deleteMapImage.fulfilled]: (state, action) => {
            const { mapId, imageId } = action.meta.arg;
            const map = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                mapImages: map.mapImages.filter((mapImage) => mapImage.imageId != imageId),
            });
        },
        [createRegionInternalPlacements.fulfilled]: (state, action) => {
            const { mapId } = action.meta.arg;
            const newRegions = action.payload;
            const { regionPlacementIds = [] } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                regionPlacementIds: [
                    ...regionPlacementIds,
                    ...newRegions.map(({ newRegion }) => `${mapId}::${newRegion.regionId}`),
                ],
            });
        },
        [createRegionInternalPlacement.fulfilled]: (state, action) => {
            const { mapId, regionId } = action.meta.arg;
            const { regionPlacementIds = [] } = getSelectors().selectById(state, mapId);
            upsertOne(state, { mapId, regionPlacementIds: [...regionPlacementIds, `${mapId}::${regionId}`] });
        },

        [updateRegionInternalPlacement.fulfilled]: (state, action) => {
            const { regionId, mapId } = action.meta.arg;
            const { regionPlacementIds = [] } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                regionPlacementIds: [
                    ...regionPlacementIds.filter((id) => id !== `${mapId}::${regionId}`),
                    `${mapId}::${regionId}`,
                ],
            });
        },

        [deleteRegionInternalPlacement.fulfilled]: (state, action) => {
            const { regionId, mapId } = action.meta.arg;
            const { regionPlacementIds = [] } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                regionPlacementIds: regionPlacementIds.filter((id) => id !== `${mapId}::${regionId}`),
            });
        },

        [deleteRegion.fulfilled]: (state, action) => {
            const regionId = action.meta.arg;
            const allMaps = getSelectors().selectAll(state);

            upsertMany(
                state,
                allMaps.map(({ mapId, regionPlacementIds = [] }) => ({
                    mapId,
                    regionPlacementIds: regionPlacementIds.filter((id) => id !== `${mapId}::${regionId}`),
                }))
            );
        },

        [fetchAllRegionsInSpace.fulfilled]: (state, action) => {
            const { withPlacements } = action.meta.arg;

            if (withPlacements) {
                const result = {};

                for (const { regionId, placements } of action.payload) {
                    for (const { mapId } of placements) {
                        if (result[mapId]) {
                            result[mapId].regionPlacementIds.push(`${mapId}::${regionId}`);
                        } else {
                            result[mapId] = {
                                mapId,
                                regionPlacementIds: [`${mapId}::${regionId}`],
                            };
                        }
                    }
                }

                upsertMany(state, Object.values(result));
            }
        },

        [fetchAllLanesInMap.pending]: (state) => {
            const allMaps = getSelectors().selectAll(state);
            upsertMany(
                state,
                allMaps.map((m) => ({ ...m, laneIds: null }))
            );
        },

        [lanesFetched]: (state, action) => {
            const { mapId, data } = action.payload;
            const { laneIds } = getSelectors().selectById(state, mapId) ?? {};
            upsertOne(state, {
                mapId,
                laneIds: [...new Set([...(laneIds ?? []), ...data.map((l) => l.laneId)])],
            });
        },

        [fetchLane.fulfilled]: (state, action) => {
            const { mapId, laneId } = action.meta.arg;
            const { laneIds } = getSelectors().selectById(state, mapId) ?? {};
            upsertOne(state, { mapId, laneIds: [...new Set([...(laneIds ?? []), laneId])] });
        },

        [fetchLaneAttachments.fulfilled]: (state, action) => {
            const { mapId, laneId } = action.meta.arg;
            const { laneIds } = getSelectors().selectById(state, mapId) ?? {};
            upsertOne(state, { mapId, laneIds: [...new Set([...(laneIds ?? []), laneId])] });
        },

        [deleteLane.fulfilled]: (state, action) => {
            const { mapId, laneId } = action.meta.arg;
            const { junctionIds } = action.payload;
            const { laneIds = [], junctionMismatchIds = [] } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                laneIds: laneIds.filter((id) => id !== laneId),
                junctionMismatchIds: junctionMismatchIds.filter((id) => !junctionIds.includes(id)),
            });
        },

        [fetchAllValidationsInMap.pending]: (state, action) => {
            const mapId = action.meta.arg;
            upsertOne(state, { mapId, mapValidationIds: [] });
        },

        [mapValidationsFetched]: (state, action) => {
            const { mapId, data } = action.payload;
            const { mapValidationIds } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                mapValidationIds: [
                    ...new Set([...(mapValidationIds ?? []), ...data.map((mv) => mv.mapValidationId)]),
                ],
            });
        },

        [deleteAllValidationsInMap.fulfilled]: (state, action) => {
            const mapId = action.meta.arg;
            upsertOne(state, { mapId, mapValidationIds: [] });
        },

        [fetchAllAreasOfInterestInMap.fulfilled]: (state, action) => {
            const mapId = action.meta.arg;
            const allMaps = getSelectors().selectAll(state);
            upsertMany(
                state,
                allMaps.map((m) =>
                    m.mapId === mapId
                        ? {
                              ...m,
                              areaOfInterestIds: action.payload.map((area) => area.areaId),
                          }
                        : { ...m, areaOfInterestIds: null }
                )
            );
        },
        [fetchAllElementsInMap.fulfilled]: (state, action) => {
            const mapId = action.meta.arg;
            const allMaps = getSelectors().selectAll(state);
            upsertMany(
                state,
                allMaps.map((m) =>
                    m.mapId === mapId
                        ? { ...m, elementsIds: action.payload.map((area) => area.areaId) }
                        : { ...m, elementsIds: null }
                )
            );
        },
        // [fetchAllExitRegionsInMap.fulfilled]: (state, action) => {
        //     const mapId = action.meta.arg;
        //     const allMaps = getSelectors().selectAll(state);

        //     upsertMany(
        //         state,
        //         allMaps.map((m) =>
        //             m.mapId === mapId
        //                 ? { ...m, exitRegionsIds: action.payload.map((area) => area.areaId) }
        //                 : { ...m, exitRegionsIds: null }
        //         )
        //     );
        // },

        [fetchAllLinesOfInterestInMap.fulfilled]: (state, action) => {
            const mapId = action.meta.arg;
            const allMaps = getSelectors().selectAll(state);
            upsertMany(
                state,
                allMaps.map((m) =>
                    m.mapId === mapId
                        ? { ...m, lineOfInterestIds: action.payload.map((line) => line.lineId) }
                        : { ...m, lineOfInterestIds: null }
                )
            );
        },

        [fetchAreaOfInterestAttachments.fulfilled]: (state, action) => {
            const { mapId, areaId } = action.meta.arg;
            const { areaOfInterestIds } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                areaOfInterestIds: [...new Set([...(areaOfInterestIds ?? []), areaId])],
            });
        },

        [createAreaOfInterest.fulfilled]: (state, action) => {
            const { mapId } = action.meta.arg;
            const { areaId } = action.payload;
            const { areaOfInterestIds } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                areaOfInterestIds: [...(areaOfInterestIds ?? []), areaId],
            });
        },
        [createElement.fulfilled]: (state, action) => {
            const { mapId } = action.meta.arg;
            const { areaId } = action.payload;
            const { elementsIds } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                elementsIds: [...(elementsIds ?? []), areaId],
            });
        },
        [deleteElement.fulfilled]: (state, action) => {
            const { mapId, areaId } = action.meta.arg;
            const { elementsIds = [] } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                elementsIds: elementsIds.filter((id) => id !== areaId),
            });
        },
        [deleteAreaOfInterest.fulfilled]: (state, action) => {
            const { mapId, areaId } = action.meta.arg;
            const { areaOfInterestIds = [] } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                areaOfInterestIds: areaOfInterestIds.filter((id) => id !== areaId),
            });
        },
        [upsertExitRegion.fulfilled]: (state, action) => {
            const { mapId } = action.meta.arg;
            const { exitRegionId } = action.payload;
            const { exitRegionsIds } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                exitRegionsIds: [...(exitRegionsIds ?? []), exitRegionId],
            });
        },
        [deleteExitRegion.fulfilled]: (state, action) => {
            const { mapId, areaId } = action.meta.arg;
            const { exitRegionsIds = [] } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                exitRegionsIds: exitRegionsIds.filter((id) => id !== areaId),
            });
        },
        [createLineOfInterest.fulfilled]: (state, action) => {
            const { mapId } = action.meta.arg;
            const { lineId } = action.payload;
            const { lineOfInterestIds } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                lineOfInterestIds: [...(lineOfInterestIds ?? []), lineId],
            });
        },

        [deleteLineOfInterest.fulfilled]: (state, action) => {
            const { mapId, lineId } = action.meta.arg;
            const { lineOfInterestIds = [] } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                lineOfInterestIds: lineOfInterestIds.filter((id) => id !== lineId),
            });
        },

        [fetchAllJunctionMismatchesInMap.fulfilled]: (state, action) => {
            const { mapId, useEqualizedMap } = action.meta.arg;
            const allMaps = getSelectors().selectAll(state);
            const junctionIds = action.payload.map((jm) => jm.junctionId);

            upsertMany(
                state,
                allMaps.map((m) =>
                    m.mapId === mapId
                        ? {
                              ...m,
                              ...(useEqualizedMap
                                  ? { equalizedJunctionMismatchIds: junctionIds }
                                  : { unequalizedJunctionMismatchIds: junctionIds }),
                          }
                        : { ...m, junctionMismatchIds: null }
                )
            );
        },

        [fetchAllMappersInMap.fulfilled]: (state, action) => {
            const mapId = action.meta.arg;
            const allMaps = getSelectors().selectAll(state);
            upsertMany(
                state,
                allMaps.map((m) =>
                    m.mapId === mapId
                        ? { ...m, mapperIds: action.payload.map((mapper) => mapper.mapperId) }
                        : { ...m, mapperIds: null }
                )
            );
        },

        [laneCreated]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { laneId } = action.payload.data;
            const { laneIds } = getSelectors().selectById(state, mapId);

            if (!laneIds.includes(laneId)) {
                upsertOne(state, {
                    mapId,
                    laneIds: [...(laneIds ?? []), laneId],
                });
            }
        },

        [laneUpdated]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { laneId } = action.payload.data;
            const { laneIds } = getSelectors().selectById(state, mapId);

            upsertOne(state, {
                mapId,
                laneIds: [...(laneIds ?? []).filter((id) => id !== laneId), laneId],
            });
        },

        [laneDeleted]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { laneId } = action.payload.data;
            const { laneIds = [] } = getSelectors().selectById(state, mapId);
            upsertOne(state, {
                mapId,
                laneIds: laneIds.filter((id) => id !== laneId),
            });
        },

        [mapperAlive]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { mapperId } = action.payload.data;
            const { mapperIds } = getSelectors().selectById(state, mapId);

            if (!mapperIds.includes(mapperId)) {
                upsertOne(state, { mapId, mapperIds: [...(mapperIds ?? []), mapperId] });
            }
        },

        [superMapperToggled]: (state, action) => {
            const { mapId, mapperId } = action.payload.parameters;
            const { mapperIds } = getSelectors().selectById(state, mapId);

            if (!mapperIds.includes(mapperId)) {
                upsertOne(state, { mapId, mapperIds: [...(mapperIds ?? []), mapperId] });
            }
        },

        [mapperExpired]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { mapperId } = action.payload.data;
            const { mapperIds = [] } = getSelectors().selectById(state, mapId);

            if (mapperIds.includes(mapperId)) {
                upsertOne(state, { mapId, mapperIds: mapperIds.filter((id) => id !== mapperId) });
            }
        },

        [areaOfInterestCreated]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { areaId } = action.payload.data;
            const { areaOfInterestIds } = getSelectors().selectById(state, mapId);

            if (!areaOfInterestIds.includes(areaId)) {
                upsertOne(state, { mapId, areaOfInterestIds: [...(areaOfInterestIds ?? []), areaId] });
            }
        },

        [areaOfInterestUpdated]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { areaId } = action.payload.data;
            const { areaOfInterestIds } = getSelectors().selectById(state, mapId);

            if (!areaOfInterestIds.includes(areaId)) {
                upsertOne(state, { mapId, areaOfInterestIds: [...(areaOfInterestIds ?? []), areaId] });
            }
        },

        [areaOfInterestDeleted]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { areaId } = action.payload.data;
            const { areaOfInterestIds = [] } = getSelectors().selectById(state, mapId);

            if (areaOfInterestIds.includes(areaId)) {
                upsertOne(state, {
                    mapId,
                    areaOfInterestIds: areaOfInterestIds.filter((id) => id !== areaId),
                });
            }
        },
        [elementsCreated]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { areaId } = action.payload.data;
            const { elementsIds } = getSelectors().selectById(state, mapId);

            if (!elementsIds.includes(areaId)) {
                upsertOne(state, { mapId, elementsIds: [...(elementsIds ?? []), areaId] });
            }
        },

        [elementsUpdated]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { areaId } = action.payload.data;
            const { elementsIds } = getSelectors().selectById(state, mapId);

            if (!elementsIds.includes(areaId)) {
                upsertOne(state, { mapId, elementsIds: [...(elementsIds ?? []), areaId] });
            }
        },

        [elementsDeleted]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { areaId } = action.payload.data;
            const { elementsIds = [] } = getSelectors().selectById(state, mapId);

            if (elementsIds.includes(areaId)) {
                upsertOne(state, {
                    mapId,
                    elementsIds: elementsIds.filter((id) => id !== areaId),
                });
            }
        },
        [lineOfInterestCreated]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { lineId } = action.payload.data;
            const { lineOfInterestIds } = getSelectors().selectById(state, mapId);

            if (!lineOfInterestIds.includes(lineId)) {
                upsertOne(state, { mapId, lineOfInterestIds: [...(lineOfInterestIds ?? []), lineId] });
            }
        },

        [lineOfInterestUpdated]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { lineId } = action.payload.data;
            const { lineOfInterestIds } = getSelectors().selectById(state, mapId);

            if (!lineOfInterestIds.includes(lineId)) {
                upsertOne(state, { mapId, lineOfInterestIds: [...(lineOfInterestIds ?? []), lineId] });
            }
        },

        [lineOfInterestDeleted]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { lineId } = action.payload.data;
            const { lineOfInterestIds = [] } = getSelectors().selectById(state, mapId);

            if (lineOfInterestIds.includes(lineId)) {
                upsertOne(state, {
                    mapId,
                    lineOfInterestIds: lineOfInterestIds.filter((id) => id !== lineId),
                });
            }
        },
        [exitRegionCreated]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { areaId } = action.payload.data;
            const { exitRegionsIds } = getSelectors().selectById(state, mapId);

            if (!exitRegionsIds.includes(areaId)) {
                upsertOne(state, { mapId, exitRegionsIds: [...(exitRegionsIds ?? []), areaId] });
            }
        },

        [exitRegionUpdated]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { areaId } = action.payload.data;
            const { exitRegionsIds } = getSelectors().selectById(state, mapId);

            if (!exitRegionsIds.includes(areaId)) {
                upsertOne(state, { mapId, exitRegionsIds: [...(exitRegionsIds ?? []), areaId] });
            }
        },

        [exitRegionDeleted]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { areaId } = action.payload.data;
            const { exitRegionsIds = [] } = getSelectors().selectById(state, mapId);

            if (exitRegionsIds.includes(areaId)) {
                upsertOne(state, {
                    mapId,
                    exitRegionsIds: exitRegionsIds.filter((id) => id !== areaId),
                });
            }
        },
        [mapValidationsAllUpdated]: (state, action) => {
            const { mapId } = action.payload.parameters;

            upsertOne(state, {
                mapId,
                mapValidationIds: action.payload.data.map((mv) => mv.mapValidationId),
            });
        },

        [mapValidationsSomeUpdated]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { mapValidationIds } = getSelectors().selectById(state, mapId);

            upsertOne(state, {
                mapId,
                mapValidationIds: [
                    ...new Set([
                        ...(mapValidationIds ?? []),
                        ...action.payload.data.map((mv) => mv.mapValidationId),
                    ]),
                ],
            });
        },

        [mapValidationsDeleted]: (state, action) => {
            const { mapId } = action.payload.parameters;
            const { mapValidationIds = [] } = getSelectors().selectById(state, mapId);
            const deletedIds = action.payload.data.map((mv) => mv.mapValidationId);

            upsertOne(state, {
                mapId,
                mapValidationIds: mapValidationIds.filter((id) => !deletedIds.includes(id)),
            });
        },

        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
        [createPublishMapGrid.fulfilled]: (state, action) => {
            const { mapId } = action.meta.arg;
            const { gridMetadata } = action.payload;
            upsertOne(state, {
                mapId,
                errorMessage: undefined,
                gridMetadata,
            });
        },
        [createPublishMapGrid.rejected]: (state, action) => {
            const { mapId } = action.meta.arg;
            const code = action.error.code || undefined;
            if (code === '7002') {
                upsertOne(state, {
                    mapId,
                    errorMessage: 'You have no permission to perform this action',
                });
            }
        },
        [getMapGridMetadata.fulfilled]: (state, action) => {
            const { mapId } = action.meta.arg;
            const { data: metadata = [] } = action.payload;
            upsertOne(state, {
                mapId,
                gridMetadata: metadata,
            });
        },
        [getMapGridMetadata.rejected]: (state, action) => {
            const { mapId } = action.meta.arg;
            upsertOne(state, {
                mapId,
                canPublish: false,
            });
        },
        [upsertMapFeature.fulfilled]: (state, action) => {
            const { mapFullData, gridMetadata } = action.payload;
            upsertOne(state, { ...mapFullData, gridMetadata });
        },
        [resetToManualMapping.fulfilled]: (state, action) => {
            const { mapFullData, gridMetadata } = action.payload;
            upsertOne(state, { ...mapFullData, gridMetadata });
        },
    },
});
