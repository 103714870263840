import React from 'react';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export default styled((props) => (
    <TextField
        {...props}
        InputProps={{
            classes: {
                ...(props.variant === 'outlined'
                    ? { notchedOutline: 'themed-text-field-outline' }
                    : { underline: 'themed-text-field-underline' }),
                focused: 'themed-text-field-focused',
            },
            ...props.InputProps,
        }}
        InputLabelProps={{
            classes: {
                root: 'themed-text-field-input-label',
                focused: 'themed-text-field-input-label-focused',
            },
            ...props.InputLabelProps,
        }}
    />
))`
    * .MuiInputBase-input,
    & > div > div {
        height: unset;
    }
    & .themed-text-field-underline::after {
        border-bottom-color: #ff6200; // TODO color should come from theme
    }

    & .themed-text-field-focused .themed-text-field-outline {
        border-color: #ff6200; // TODO color should come from theme
    }

    & .themed-text-field-input-label.themed-text-field-input-label-focused {
        color: #ff6200; // TODO color should come from theme
    }
`;
