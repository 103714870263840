import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showGrid: false,
};

export const {
    actions: { setShowGrid },
    reducer,
} = createSlice({
    name: 'preferences',
    initialState,
    reducers: {
        setShowGrid: (state, action) => {
            state.showGrid = action.payload;
        },
    },
});

export const selectShowGrid = (state) => state.preferences.showGrid;
