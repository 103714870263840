import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectHasError as getSelectHasError,
    selectIsLoading as getSelectIsLoading,
} from '../../../../state-management/status/statusSelectors';
import {
    fetchShiftManagementData,
    thresholdToggledCreated,
    isFetchedCreated,
} from '../../../../state-management/shift-management/shiftManagementActions';
import {
    selectShiftManagementData,
    selectShiftManagementDataFetched,
    selectShiftManagementDataThreshold,
} from '../../../../state-management/shift-management/shiftManagementSelectors';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import settings from '../../../../clientSettings';
import ExpirationStorage from '../../../../utils/expirationStorage';

const { shiftManagementInterval } = settings;

/**
 * Hook that fetches all data for shift management.
 * @param asObject - Return all entities as an object, with the IDs as the keys.
 * @return {{isLoading, data: (*), hasError, isFetched}}
 */

function modifyWithLocalStorageData(shiftManagementData) {
    return shiftManagementData.map((map) => {
        const localStorageMapItem = ExpirationStorage.getItem(`shiftManagementId:${map.mapId}`);
        return map.mapId === localStorageMapItem?.id
            ? {
                  ...map,
                  supervisorName: localStorageMapItem?.supervisorName || '',
                  comments: localStorageMapItem?.comments || '',
                  approved: localStorageMapItem?.approved || false,
              }
            : map;
    });
}

export default function useShiftManagement(options = {}) {
    const { asObject = false } = options;

    const dispatch = useDispatch();

    const [lastUpdate, setLastUpdate] = useState(new Date());
    const selectIsLoading = useMemo(() => getSelectIsLoading(fetchShiftManagementData.typePrefix), []);
    const selectHasError = useMemo(() => getSelectHasError(fetchShiftManagementData.typePrefix), []);

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const shiftManagementData = useSelector(selectShiftManagementData);
    const isLoading = useSelector(selectIsLoading);
    const hasError = useSelector(selectHasError);
    const isFetched = useSelector(selectShiftManagementDataFetched);
    const isThreshold = useSelector(selectShiftManagementDataThreshold);

    const toggleThreshold = () => {
        dispatch(thresholdToggledCreated(!isThreshold));
    };

    const getAnalytics = (withLoader = true) => {
        if (!isLoading && !hasError && selectedSpaceId) {
            if (withLoader) {
                dispatch(isFetchedCreated());
            }
            dispatch(fetchShiftManagementData(isThreshold));
            setLastUpdate(new Date());
        }
    };

    useEffect(() => {
        // Fetching the data for the first time.
        if (!isFetched) {
            getAnalytics();
        }

        // An interval for fetching the data every X minutes
        const interval = setInterval(() => {
            getAnalytics(false);
        }, shiftManagementInterval);

        return () => {
            clearInterval(interval);
        };
    }, [dispatch, isLoading, hasError, isFetched]);

    useEffect(() => {
        if (isThreshold || selectedSpaceId) {
            getAnalytics();
        }
    }, [dispatch, selectedSpaceId, isThreshold]);

    return {
        data: asObject ? {} : modifyWithLocalStorageData(shiftManagementData),
        isLoading,
        hasError,
        isFetched,
        isThreshold,
        toggleThreshold,
        getAnalytics,
        lastUpdate,
    };
}
