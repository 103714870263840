import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { MdExpandMore as ExpandMoreIcon } from 'react-icons/md';
import styled from 'styled-components';
import { Tooltip } from '../../common/themed';
import BuildingEditor from './BuildingEditor';

const SummaryWrapper = styled.div`
    display: grid;
    grid-template-rows: repeat(2, 25px);
    grid-template-columns: repeat(2, minmax(100px, 500px));
    column-gap: 50px;

    & > div {
        display: inline-flex;
        align-items: center;

        span {
            overflow: hidden;
        }
    }
`;

const DetailTitle = styled(Typography).attrs({
    variant: 'subtitle1',
})``;

const DetailValue = styled(Typography).attrs({
    variant: 'subtitle2',
})`
    color: #7a7a7a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export default function BuildingDetailsCard(props) {
    const { building } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    const buildingDetails = [
        {
            title: 'Building Name',
            value: building.buildingName,
            withTooltip: false,
        },
        { title: 'Building ID', value: building.buildingId, withTooltip: false },
    ];

    return (
        <Accordion onChange={(event, value) => setIsExpanded(value)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <SummaryWrapper>
                    {buildingDetails.map(({ title }, index) => (
                        <DetailTitle key={`${title}-${index}`}>{title}</DetailTitle>
                    ))}

                    {buildingDetails.map(({ value, withTooltip }, index) => (
                        <div key={`${value}-${index}`}>
                            {withTooltip ? (
                                <Tooltip content={value}>
                                    <DetailValue>{value}</DetailValue>
                                </Tooltip>
                            ) : (
                                <DetailValue>{value}</DetailValue>
                            )}
                        </div>
                    ))}
                </SummaryWrapper>
            </AccordionSummary>

            {isExpanded && (
                <AccordionDetails>
                    <BuildingEditor building={building} />
                </AccordionDetails>
            )}
        </Accordion>
    );
}

BuildingDetailsCard.propTypes = {
    building: PropTypes.object.isRequired,
};
