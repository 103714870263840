import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
    Paper,
    TextField,
    InputAdornment,
    Grid,
    FormControlLabel,
    Divider as MuiDivider,
} from '@material-ui/core';
import ReactSelect from 'react-select';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/themes/light.css';
import {
    Button,
    CircularPreloader,
    Skeleton,
    Switch,
    Tooltip,
    SwitchLabel as Label,
    Dialog,
    DialogTitle,
    DialogContent,
} from '../../common/themed';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsManager from '../../../api/AnalyticsManager';
import countriesAndTimezones from 'countries-and-timezones';
import {
    fetchRawSessions,
    processAllRawSessions,
} from '../../../state-management/analytics/session-analytics/sessionAnalyticsActions';
import { selectIsOmk } from '../../../state-management/auth/authSelectors';
import useAllBuildingsInSpace from '../../common/hooks/data-fetching/useAllBuildingsInSpace';
import useAllFloorsInBuilding from '../../common/hooks/data-fetching/useAllFloorsInBuilding';
import {
    selectAnalyticsUserActivityUserInputs,
    setAcceptedAccuracy,
    setInaccuracySmoothing,
    setSelectedBuildingId,
    setSelectedFloorId,
    setSelectedMapIds,
    setSessionId,
    setTimeSpanEnd,
    setTimeSpanStart,
    setTimeZone,
} from '../../../state-management/user-inputs/analyticsUserActivitySlice';
import useAllMapsInFloor from '../../common/hooks/data-fetching/useAllMapsInFloor';
import {
    getFiltersBarAcceptedAccuracyId,
    getFiltersBarBuildingSelectId,
    getFiltersBarFloorSelectId,
    getFiltersBarInaccuracySmoothingId,
    getFiltersBarMapsSelectId,
    getFiltersBarSessionIdId,
    getFiltersBarTimeSpanDialogEndPickerId,
    getFiltersBarTimeSpanDialogSpaceId,
    getFiltersBarTimeSpanDialogStartPickerId,
    getFiltersBarTimeSpanEndPickerId,
    getFiltersBarTimeSpanStartPickerId,
    getFiltersBarTimeZoneSelectId,
} from './AnalyticsUserActivity.selectors';
import moment from 'moment';
import useRegionColorsInSpace from '../../common/hooks/data-fetching/useRegionColorsInSpace';
import { useFormContext, FormProvider, useForm } from 'react-hook-form';
import { UserActivityModal } from './userActivityModal';

const timeZones = countriesAndTimezones.getAllTimezones();

const AnalyticsFiltersBarWrapper = styled(Paper)`
    padding: 15px;
    margin-bottom: 10px;
`;

const commonTextFieldStyles = css`
    input {
        height: 0;
    }
`;

const Select = styled(ReactSelect)`
    width: 100%;
    max-width: 250px;
`;

const FromDatePicker = styled(TextField)`
    ${commonTextFieldStyles};
    max-width: 200px;
`;

const ToDatePicker = styled(TextField)`
    ${commonTextFieldStyles};
    max-width: 200px;
`;

const AcceptedAccuracyTextField = styled(TextField)`
    ${commonTextFieldStyles};
    max-width: 230px;

    input {
        width: 30px;
    }
`;

const AcceptedAccuracyLabel = styled(InputAdornment)`
    p {
        width: 165px;
    }
`;

const SessionIdTextField = styled(TextField)`
    ${commonTextFieldStyles};
    width: 100%;
    max-width: 250px;
`;

const Divider = styled(MuiDivider)`
    margin-block-start: 10px;
    margin-block-end: 10px;
`;

const FilterWrapper = styled.div`
    & > * {
        padding: 9px;
    }
`;

const flatPickrCommonConfig = {
    placeholder: 'Select a date...',
    mode: 'single',
    enableTime: true,
};


let fromDateFlatpickr = null;
let toDateFlatpickr = null;



export default function UserActivityFiltersBar() {
    const dispatch = useDispatch();

    const {
        selectedBuildingId,
        selectedFloorId,
        selectedMapIds,
        acceptedAccuracy,
        timeSpanStart,
        timeSpanEnd,
        timeZone,
        sessionId,
        inaccuracySmoothing,
        fetchOnSubmit,
    } = useSelector(selectAnalyticsUserActivityUserInputs);

    // const { register, errors, setValue, watch, setError, clearErrors, getValues } = useFormContext();

    const isOmk = useSelector(selectIsOmk);

    const { data: buildings = {}, isLoading: isLoadingBuildings } = useAllBuildingsInSpace({
        asObject: true,
    });

    const { data: floors = {}, isLoading: isLoadingFloors } = useAllFloorsInBuilding({
        buildingId: selectedBuildingId,
        asObject: true,
    });

    const { data: maps = {}, isLoading: isLoadingMaps } = useAllMapsInFloor({
        buildingId: selectedBuildingId,
        floorId: selectedFloorId,
        asObject: true,
    });

    const { isLoading: isLoadingRegionColors, hasError: hasErrorInRegionColors } = useRegionColorsInSpace(); // TODO might only be need in SessionDialog, consider moving it there

    const handleSubmit = () => {
        // If the changed filters require re-fetching the data, do it, and the processing
        // will occur in componentDidUpdate. Otherwise, just re-process the data
        if (fetchOnSubmit) {
            dispatch(fetchRawSessions());
        } else {
            dispatch(processAllRawSessions());
        }
    };

    useEffect(() => {
        AnalyticsManager.updateTimezoneOffset(timeZone.utcOffset);
    }, [timeZone.utcOffset]);

    useEffect(() => {
        // Initialize flatpickr instances for both date pickers
        fromDateFlatpickr = flatpickr(`#${getFiltersBarTimeSpanStartPickerId()}`, {
            ...flatPickrCommonConfig,
            time_24hr: true,
            // defaultDate: timeSpanStart,
            onChange: ([timeSpanStart]) => dispatch(setTimeSpanStart(timeSpanStart.toISOString())),
        });

        toDateFlatpickr = flatpickr(`#${getFiltersBarTimeSpanEndPickerId()}`, {
            ...flatPickrCommonConfig,
            // defaultDate: timeSpanEnd,
            time_24hr: true,
            onChange: ([timeSpanEnd]) =>
                dispatch(setTimeSpanEnd(moment(timeSpanEnd).endOf('day').toISOString())),
        });

        return () => {
            if (fromDateFlatpickr?.destroy) {
                fromDateFlatpickr.destroy()
            }
            if (toDateFlatpickr?.destroy) {
                fromDateFlatpickr.destroy()
            }

        };
    }, [dispatch]);

    useEffect(() => {
        fromDateFlatpickr.setDate(timeSpanStart);
    }, [timeSpanStart]);

    useEffect(() => {
        toDateFlatpickr.setDate(timeSpanEnd);
    }, [timeSpanEnd]);

    useEffect(() => {
        // If there's only one floor in the selected building, select that floor by default
        if (selectedBuildingId && !selectedFloorId && Object.keys(floors).length === 1) {
            dispatch(setSelectedFloorId(Object.keys(floors)[0]));
        }
    }, [dispatch, floors, selectedBuildingId, selectedFloorId]);

    useEffect(() => {
        // If there's only one map in the selected floor, select that map by default
        if (
            selectedFloorId &&
            (!selectedMapIds || selectedMapIds.length === 0) &&
            Object.keys(maps).length === 1
        ) {
            dispatch(setSelectedMapIds([Object.keys(maps)[0]]));
        }
    }, [dispatch, maps, selectedFloorId, selectedMapIds]);

    const isSubmitDisabled = hasErrorInRegionColors;
    const submitTooltipContent = hasErrorInRegionColors
        ? 'An error has occurred. Please try again later.'
        : selectedBuildingId && selectedMapIds.length === 0
            ? 'Please either select a floor and a map to query or clear the building selection'
            : '';



    return (
        <AnalyticsFiltersBarWrapper>
            <Grid container alignItems={'center'} component={FilterWrapper}>
                {isLoadingBuildings ? (
                    <Skeleton height={35} width={250} />
                ) : (
                    <Select
                        id={getFiltersBarBuildingSelectId()}
                        options={Object.values(buildings)}
                        isClearable
                        getOptionLabel={(b) => b.buildingName}
                        getOptionValue={(b) => b.buildingId}
                        value={buildings?.[selectedBuildingId] ?? null}
                        onChange={(b) => dispatch(setSelectedBuildingId(b?.buildingId ?? null))}
                        placeholder={'Select a building'}
                    />
                )}

                {isLoadingFloors ? (
                    <Skeleton height={35} width={250} />
                ) : (
                    <Select
                        id={getFiltersBarFloorSelectId()}
                        options={Object.values(floors)}
                        isClearable={Object.values(floors).length > 1}
                        getOptionLabel={(f) => f.floorName}
                        getOptionValue={(f) => f.floorId}
                        value={floors?.[selectedFloorId] ?? null}
                        onChange={(f) => dispatch(setSelectedFloorId(f?.floorId ?? null))}
                        placeholder={'Select a floor'}
                        isDisabled={Object.values(floors).length === 0}
                    />
                )}

                {isLoadingMaps ? (
                    <Skeleton height={35} width={250} />
                ) : (
                    <Select
                        id={getFiltersBarMapsSelectId()}
                        options={Object.values(maps)}
                        isClearable={Object.values(maps).length > 1}
                        getOptionLabel={(map) => map.mapName}
                        getOptionValue={(map) => map.mapId}
                        value={maps?.[selectedMapIds[0]] ?? null}
                        onChange={(m) => dispatch(setSelectedMapIds(m ? [m.mapId] : []))}
                        placeholder={'Select a map'}
                        isDisabled={Object.values(maps).length === 0}
                    />
                )}

                <FromDatePicker
                    id={getFiltersBarTimeSpanStartPickerId()}
                    variant={'outlined'}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">From:</InputAdornment>,
                    }}
                />

                <ToDatePicker
                    id={getFiltersBarTimeSpanEndPickerId()}
                    variant={'outlined'}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">To:</InputAdornment>,
                    }}
                />

                <Select
                    id={getFiltersBarTimeZoneSelectId()}
                    placeholder={'Time Zone'}
                    defaultValue={timeZone}
                    options={Object.values(timeZones).sort((a, b) => a.utcOffset - b.utcOffset)}
                    getOptionLabel={(timeZone) => `(UTC${timeZone.offsetStr}) ${timeZone.name}`}
                    getOptionValue={(timeZone) => timeZone.name}
                    onChange={(timeZone) => {
                        AnalyticsManager.updateTimezoneOffset(timeZone.utcOffset);
                        dispatch(setTimeZone(timeZone));
                    }}
                />

                {isOmk && (
                    <SessionIdTextField
                        id={getFiltersBarSessionIdId()}
                        variant={'outlined'}
                        label={'Session ID'}
                        InputLabelProps={{ shrink: true }}
                        value={sessionId}
                        onChange={(e) => dispatch(setSessionId(e.target.value))}
                    />
                )}

                <AcceptedAccuracyTextField
                    id={getFiltersBarAcceptedAccuracyId()}
                    variant={'outlined'}
                    value={acceptedAccuracy}
                    InputProps={{
                        startAdornment: (
                            <AcceptedAccuracyLabel position="start">
                                Accepted Accuracy (m):
                            </AcceptedAccuracyLabel>
                        ),
                    }}
                    onChange={(e) => dispatch(setAcceptedAccuracy(e.target.value))}
                />

                <FormControlLabel
                    id={getFiltersBarInaccuracySmoothingId()}
                    control={
                        <Switch
                            checked={inaccuracySmoothing}
                            color="primary"
                            onChange={(e) => dispatch(setInaccuracySmoothing(e.target.checked))}
                        />
                    }
                    label={<Label>Inaccuracy Smoothing</Label>}
                />
            </Grid>

            <Divider />

            <Grid container justifyContent={'flex-end'}>
                {isLoadingRegionColors ? (
                    <CircularPreloader />
                ) : (
                    <Tooltip content={submitTooltipContent} enabled={isSubmitDisabled}>
                        <Button onClick={handleSubmit} disabled={isSubmitDisabled}>
                            Submit
                        </Button>
                    </Tooltip>
                )}
            </Grid>
        </AnalyticsFiltersBarWrapper>
    );
}
