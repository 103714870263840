import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './areaOfInterestEntityAdapter';
import { expireSession, logout } from '../../auth/authActions';
import {
    createAreaOfInterest,
    deleteAreaOfInterest,
    fetchAllAreasOfInterestInMap,
    fetchAreaOfInterestAttachments,
    updateAreaOfInterest,
} from './areaOfInterestActions';
import { areaOfInterestCreated, areaOfInterestDeleted, areaOfInterestUpdated } from '../mappingEventsActions';

const { getInitialState, upsertOne, setAll, removeOne } = entityAdapter;

export const { reducer } = createSlice({
    name: 'areasOfInterest',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchAllAreasOfInterestInMap.fulfilled]: (state, action) => {
            setAll(
                state,
                action.payload.map(
                    ({
                        areaId,
                        topRight,
                        bottomLeft,
                        comment,
                        hasAttachments,
                        attachment,
                        type,
                        mapperName,
                        createdAt,
                        lastUpdate,
                    }) => ({
                        areaId,
                        topRight,
                        bottomLeft,
                        comments: comment ? [comment] : [],
                        hasAttachments,
                        attachment,
                        type,
                        mapperName,
                        createdAt,
                        lastUpdate,
                    })
                )
            );
        },

        [fetchAreaOfInterestAttachments.fulfilled]: (state, action) => {
            const { areaId } = action.meta.arg;
            const { attachment } = action.payload;
            upsertOne(state, { areaId, attachments: [attachment] });
        },

        [createAreaOfInterest.fulfilled]: (state, action) => {
            const {
                areaId,
                topRight,
                bottomLeft,
                comment,
                attachment,
                type,
                title,
                createdAt,
                lastUpdate,
                mapperName,
            } = action.payload;
            upsertOne(state, {
                areaId,
                topRight,
                bottomLeft,
                comments: comment ? [comment] : [],
                hasAttachments: !!attachment,
                attachments: attachment ? [attachment] : [],
                type,
                title,
                createdAt,
                lastUpdate,
                mapperName,
            });
        },

        [updateAreaOfInterest.fulfilled]: (state, action) => {
            const {
                areaId,
                topRight,
                bottomLeft,
                comment,
                attachment,
                title,
                createdAt,
                lastUpdate,
                mapperName,
            } = action.payload;
            upsertOne(state, {
                areaId,
                topRight,
                bottomLeft,
                comments: comment ? [comment] : [],
                hasAttachments: !!attachment,
                attachments: attachment ? [attachment] : [],
                type: 'normal',
                title,
                createdAt,
                lastUpdate,
                mapperName,
            });
        },

        [deleteAreaOfInterest.fulfilled]: (state, action) => {
            const { areaId } = action.meta.arg;
            removeOne(state, areaId);
        },

        [areaOfInterestCreated]: (state, action) => {
            const { areaId, topRight, bottomLeft, comment, attachment, type, title, createdAt, lastUpdate } =
                action.payload.data;

            upsertOne(state, {
                areaId,
                topRight,
                bottomLeft,
                comments: comment ? [comment] : [],
                hasAttachments: !!attachment,
                attachments: attachment ? [attachment] : [],
                type,
                title,
                createdAt,
                lastUpdate,
            });
        },

        [areaOfInterestUpdated]: (state, action) => {
            const {
                areaId,
                topRight,
                bottomLeft,
                comment,
                attachment,
                title,
                type,
                createdAt,
                lastUpdate,
                mapperName,
            } = action.payload.data;

            upsertOne(state, {
                areaId,
                topRight,
                bottomLeft,
                comments: comment ? [comment] : [],
                hasAttachments: !!attachment,
                attachments: attachment ? [attachment] : [],
                type: 'normal',
                title,
                createdAt,
                lastUpdate,
                mapperName,
            });
        },

        [areaOfInterestDeleted]: (state, action) => {
            const { areaId } = action.payload.data;
            removeOne(state, areaId);
        },

        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
    },
});
