import React, { useEffect, useMemo, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    Select,
    Switch,
    TextField,
    SwitchLabel as Label,
} from '../../../common/themed';
import { useSelector } from 'react-redux';
import useMapsFullData from '../../../common/hooks/data-fetching/useMapsFullData';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import {
    getChangeOffsetId,
    getChangePixelToMeterId,
    getMapNameId,
    getMapUsageId,
    getOffsetDialogAcceptId,
    getOffsetXId,
    getOffsetYId,
    getPixelToMeterId,
} from '../create-floor/CreateFloorDialog.selectors';
import Thumbnail from '../common/Thumbnail';
import clsx from 'clsx';
import MapViewerPixelToMeterMeasureOverlay from '../../../common/map-viewer/overlays/pixel-to-meter-measure';
import { FormControlLabel, InputAdornment, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { getSelectDefaultReferenceMapId } from '../../../../state-management/map/mapSelectors';
import { useDropzone } from 'react-dropzone';
import Map from '../../../common/map-viewer';
import Slider from '@material-ui/core/Slider';
import NumberFormat from 'react-number-format';
import { selectFloorById } from '../../../../state-management/floor/floorSelectors';
import MapViewerV1 from '../../../map-content/legacy/OldMapViewer';
import { validateMapName } from '../../../../utils/general';

const newMapId = 'new-map';

const OldMapViewer = styled(MapViewerV1)`
    height: 600px !important;
`;

const Form = styled.div`
    display: grid;
    grid-template-rows: 25rem 1fr;
    grid-template-columns: 12rem 1fr;
    grid-template-areas:
        'map-thumbnails map-viewer'
        'map-thumbnails map-details';
`;

const ThumbnailsWrapper = styled.div`
    grid-area: map-thumbnails;
    overflow-y: auto;
    max-height: 35rem;

    ${Thumbnail}:not(:first-child) {
        margin-block-start: 20px;
    }
`;

const MapViewer = styled(Map)`
    grid-area: map-viewer;
`;

const MapDetailsWrapper = styled.div`
    grid-area: map-details;
    margin-block-start: 15px;
    display: grid;
    grid-template-columns: 270px 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 50px;
    row-gap: 15px;
    align-items: center;
`;

const OpacityWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const OpacityTitle = styled(Typography)`
    margin-inline-end: 15px;
`;

const OpacitySlider = styled(Slider)`
    width: 300px;
`;

const MapUsageTextField = styled(TextField)`
    max-width: 300px;
`;

const PixelToMeterTextField = styled(NumberFormat)`
    margin-inline-end: 15px;
`;

const OffsetTextField = styled(NumberFormat)`
    max-width: 120px;
    margin-inline-end: 15px;
`;

const ControlsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-block-start: 10px;

    & > :not(:last-child) {
        margin-inline-end: 30px;
    }

    & > :last-child {
        margin-inline-start: auto;
    }
`;

export default function CreateMap(props) {
    const { onClose, buildingId, floorId } = props;

    const [oldMapViewerRef, setOldMapViewerRef] = useState(null);
    const [selectedMapId, setSelectedMapId] = useState(null);
    const [lockStages, setLockStages] = useState(true);
    const [opacity, setOpacity] = useState(0.5);
    const [isMapOffsetDialogOpen, setIsMapOffsetDialogOpen] = useState(false);
    const [isSettingPixelToMeter, setIsSettingPixelToMeter] = useState(false);
    const [fileDialogOpened, setFileDialogOpened] = useState(false);

    const floor = useSelector((state) => selectFloorById(state, floorId));
    const { mapIds: mapIdsInFloor } = floor ?? {};

    const { data: allMaps } = useMapsFullData({ buildingId, asObject: true });

    const selectDefaultReferenceMapId = useMemo(
        () => getSelectDefaultReferenceMapId(buildingId),
        [buildingId]
    );

    const defaultReferenceMapId = useSelector(selectDefaultReferenceMapId);

    const [selectedReferenceMapId, setSelectedReferenceMapId] = useState(defaultReferenceMapId);

    const { register, setValue, setError, clearErrors, errors, watch } = useFormContext();
    const { imgBase64, mapName, mapUsage, pixelToMeter, mapOffset } = watch();

    const mapsInFloor = [
        ...(mapIdsInFloor ?? []).map((id) => allMaps?.[id]),
        ...(imgBase64
            ? [
                {
                    mapId: newMapId,
                    mapName,
                    mapUsage,
                    pixelToMeter,
                    mapOffset,
                    mapUrl: `data:image/jpeg;base64,${imgBase64}`,
                    imgBase64,
                },
            ]
            : []),
    ];

    const selectedMap = mapsInFloor.find((m) => m.mapId === selectedMapId);

    const handleFileAccepted = ([file]) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            setValue('imgBase64', e.target.result.split(',')[1]);
            setSelectedMapId(newMapId);
        };
        fileReader.readAsDataURL(file);
    };

    const { getInputProps, open: openFileDialog } = useDropzone({
        onDropAccepted: handleFileAccepted,
        onFileDialogCancel: onClose,
        accept: 'image/jpeg, image/png',
        multiple: false,
    });

    const handlePixelToMeterChange = (pixelToMeter) => {
        setValue(`pixelToMeter`, pixelToMeter);
        setIsSettingPixelToMeter(false);
    };

    const handleMapOffsetChange = () => {
        setValue(`mapOffset`, oldMapViewerRef?.getMapsOffset());
        setValue(`pixelToMeter`, oldMapViewerRef?.getPixelToMeter());

        setIsMapOffsetDialogOpen(false);
    };



    useEffect(() => {
        const finishPixelToMeterMessage = `Please finish setting the map's pixel to meter ratio`;

        if (isSettingPixelToMeter && errors?.custom?.message !== finishPixelToMeterMessage) {
            setError('custom', { message: finishPixelToMeterMessage });
        } else if (!isSettingPixelToMeter && errors?.custom) {
            clearErrors('custom');
        }
    }, [clearErrors, errors, isSettingPixelToMeter, setError]);

    useEffect(() => {
        // On start, immediately open up the file dialog
        if (!fileDialogOpened) {
            openFileDialog();
            setFileDialogOpened(true);
        }
    }, [fileDialogOpened, openFileDialog]);

    return (
        <>
            <Form>
                <input hidden name={'imgBase64'} ref={register} />

                <ThumbnailsWrapper>
                    <input hidden {...getInputProps()} />

                    {mapsInFloor?.map(({ mapId, mapUrl }) => (
                        <Thumbnail
                            key={`map-thumbnail-${mapId}`}
                            className={clsx({ selected: mapId === selectedMapId })}
                            onClick={() => setSelectedMapId(mapId)}
                        >
                            <img alt={'thumbnail'} src={mapUrl} />
                        </Thumbnail>
                    ))}
                </ThumbnailsWrapper>

                <MapViewer
                    buildingId={buildingId}
                    floorId={floorId}
                    mapId={selectedMapId === newMapId ? null : selectedMap?.mapId}
                    base64={selectedMapId === newMapId ? selectedMap?.imgBase64 : null}
                >
                    {isSettingPixelToMeter && (
                        <MapViewerPixelToMeterMeasureOverlay
                            isCancelable={!!selectedMap?.pixelToMeter}
                            onSubmit={handlePixelToMeterChange}
                            onCancel={() => setIsSettingPixelToMeter(true)}
                        />
                    )}
                </MapViewer>

                {mapsInFloor?.map(
                    ({ mapId }) =>
                        mapId === selectedMapId && (
                            <MapDetailsWrapper key={`map-${mapId}-details`}>
                                {mapId === newMapId ? (
                                    <>
                                        <TextField
                                            id={getMapNameId(mapId)}
                                            name={`mapName`}
                                            inputRef={register({ validate: validateMapName })}
                                            label={'Name'}
                                            error={!!errors?.mapName}
                                            helperText={errors?.mapName?.message}
                                        />

                                        <MapUsageTextField
                                            id={getMapUsageId(mapId)}
                                            name={`mapUsage`}
                                            inputRef={register}
                                            label={'Map Usage'}
                                            error={!!errors?.mapUsage}
                                            helperText={
                                                errors?.mapUsage?.message ??
                                                'This will describe the purpose of this specific map'
                                            }
                                        />

                                        {selectedMap?.pixelToMeter ? (
                                            <div>
                                                <Typography>Pixel to meter ratio:</Typography>

                                                <Controller
                                                    name={`pixelToMeter`}
                                                    defaultValue={1}
                                                    rules={{
                                                        required: 'This is required',
                                                        valueAsNumber: true,
                                                    }}
                                                    render={({ onChange }) => (
                                                        <PixelToMeterTextField
                                                            id={getPixelToMeterId(mapId)}
                                                            disabled={isSettingPixelToMeter}
                                                            error={!!errors?.pixelToMeter}
                                                            helperText={errors?.pixelToMeter?.message}
                                                            customInput={TextField}
                                                            onValueChange={({ floatValue }) =>
                                                                onChange(floatValue)
                                                            }
                                                            value={selectedMap?.pixelToMeter}
                                                            allowNegative={false}
                                                        />
                                                    )}
                                                />

                                                <Button
                                                    id={getChangePixelToMeterId(mapId)}
                                                    size={'small'}
                                                    variant={'outlined'}
                                                    onClick={() => setIsSettingPixelToMeter(true)}
                                                >
                                                    Change
                                                </Button>
                                            </div>
                                        ) : (
                                            <Button onClick={() => true}>Set the pixel to meter ratio</Button>
                                        )}

                                        <div>
                                            <Typography>Map offset:</Typography>

                                            <Grid container>
                                                <Grid item>
                                                    <Controller
                                                        name={`mapOffset.x`}
                                                        defaultValue={1}
                                                        rules={{
                                                            required: 'This is required',
                                                            valueAsNumber: true,
                                                        }}
                                                        render={({ onChange }) => (
                                                            <OffsetTextField
                                                                id={getOffsetXId(mapId)}
                                                                disabled={isSettingPixelToMeter}
                                                                error={!!errors?.mapOffset?.x}
                                                                helperText={errors?.mapOffset?.x?.message}
                                                                customInput={TextField}
                                                                onValueChange={({ floatValue }) =>
                                                                    onChange(floatValue)
                                                                }
                                                                value={selectedMap?.mapOffset?.x}
                                                                allowNegative={false}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            X:
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    <Controller
                                                        name={`mapOffset.y`}
                                                        defaultValue={1}
                                                        rules={{
                                                            required: 'This is required',
                                                            valueAsNumber: true,
                                                        }}
                                                        render={({ onChange }) => (
                                                            <OffsetTextField
                                                                id={getOffsetYId(mapId)}
                                                                disabled={isSettingPixelToMeter}
                                                                error={!!errors?.mapOffset?.y}
                                                                helperText={errors?.mapOffset?.y?.message}
                                                                customInput={TextField}
                                                                onValueChange={({ floatValue }) =>
                                                                    onChange(floatValue)
                                                                }
                                                                value={selectedMap?.mapOffset?.y}
                                                                allowNegative={false}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            Y:
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    <Button
                                                        id={getChangeOffsetId(mapId)}
                                                        size={'small'}
                                                        variant={'outlined'}
                                                        onClick={() => setIsMapOffsetDialogOpen(true)}
                                                    >
                                                        Change
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <TextField id={getMapNameId(mapId)} disabled label={'Name'} />

                                        <MapUsageTextField
                                            id={getMapUsageId(mapId)}
                                            disabled
                                            label={'Map Usage'}
                                            helperText={'This will describe the purpose of this specific map'}
                                        />

                                        {selectedMap?.pixelToMeter ? (
                                            <div>
                                                <Typography>Pixel to meter ratio:</Typography>

                                                <PixelToMeterTextField
                                                    id={getPixelToMeterId(mapId)}
                                                    disabled
                                                    customInput={TextField}
                                                    value={selectedMap?.pixelToMeter}
                                                />
                                            </div>
                                        ) : (
                                            <Button onClick={() => true}>Set the pixel to meter ratio</Button>
                                        )}

                                        <div>
                                            <Typography>Map offset:</Typography>

                                            <Grid container>
                                                <Grid item>
                                                    <OffsetTextField
                                                        id={getOffsetXId(mapId)}
                                                        disabled
                                                        customInput={TextField}
                                                        value={selectedMap?.mapOffset?.x}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    X:
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    <OffsetTextField
                                                        id={getOffsetYId(mapId)}
                                                        disabled
                                                        customInput={TextField}
                                                        value={selectedMap?.mapOffset?.y}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    Y:
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                )}
                            </MapDetailsWrapper>
                        )
                )}
            </Form>

            <Dialog open={isMapOffsetDialogOpen} closeable={false} fullWidth maxWidth={'lg'}>
                <DialogContent>
                    <DialogContentText>
                        In order to proceed, please set the map's offset by aligning it over another map that
                        was previously uploaded.
                    </DialogContentText>

                    {/*// TODO move the map offset behavior into the new map viewer*/}
                    <OldMapViewer
                        selectedMap={allMaps?.[selectedReferenceMapId]}
                        selectedMapData={allMaps?.[selectedReferenceMapId]}
                        additionalMapData={selectedMap}
                        additionalMapOpacity={opacity}
                        onMount={setOldMapViewerRef}
                        lockStages={lockStages}
                    />

                    <ControlsWrapper>
                        {Object.values(allMaps ?? {}).length > 1 && (
                            <Select
                                options={Object.values(allMaps ?? {})}
                                getOptionLabel={(m) => m.mapName}
                                getOptionValue={(m) => m.mapId}
                                defaultValue={allMaps?.[selectedReferenceMapId] ?? null}
                                onChange={(m) => setSelectedReferenceMapId(m.mapId)}
                                menuPlacement={'top'}
                            />
                        )}

                        <FormControlLabel
                            control={
                                <Switch checked={lockStages} onChange={() => setLockStages(!lockStages)} />
                            }
                            label={<Label>Lock position</Label>}
                        />

                        <OpacityWrapper>
                            <OpacityTitle>Opacity:</OpacityTitle>
                            <OpacitySlider
                                min={0}
                                max={1}
                                step={0.05}
                                value={opacity}
                                onChange={(e, value) => setOpacity(value)}
                            />
                        </OpacityWrapper>

                        <Button
                            id={getOffsetDialogAcceptId()}
                            variant={'text'}
                            onClick={handleMapOffsetChange}
                            disabled={!selectedReferenceMapId}
                        >
                            Accept
                        </Button>
                    </ControlsWrapper>
                </DialogContent>
            </Dialog>
        </>
    );
}
