import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { MapViewerContext } from '../../MapViewerContext';
import {
    getAreaOfInterestBottomRightResizeBulletId,
    getAreaOfInterestTopLeftResizeBulletId,
    getAreaOfInterestTopRightResizeBulletId,
    getAreaOfInterestMiddleLeftResizeBulletId,
    getAreaOfInterestMiddleRightResizeBulletId,
    getAreaOfInterestTopCenterResizeBulletId,
    getMapViewerAreaOfInterestId,
    getAreaOfInterestBottomLeftResizeBulletId,
    getAreaOfInterestBottomCenterResizeBulletId,
    getSignMarkPlacementTopResizeBulletId,
    getSignMarkPlacementBottomResizeBulletId,
    getSignMarkPlacementLeftResizeBulletId,
    getSignMarkPlacementRightResizeBulletId,
} from './MapViewerSignMarkOverlay.selectors';
import clsx from 'clsx';
import { useImmer } from 'use-immer';
import {
    BOUNDARY_MAX_X,
    BOUNDARY_MAX_Y,
    BOUNDARY_MIN_X,
    BOUNDARY_MIN_Y,
    IMAGE_NATURAL_HEIGHT,
    IMAGE_NATURAL_WIDTH,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import Tooltip from '../../../themed/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { deleteExitRegion, upsertExitRegion } from '../../../../../state-management/mapping/exitRegion/exitRegionsActions';
import { selectBuildingAreExitRegionsTitleShown, selectBuildingsSelectedBuildingId, selectBuildingsSelectedFloorId } from '../../../../../state-management/user-inputs/buildingsSlice';
import { selectUserName } from '../../../../../state-management/auth/authSelectors';
import { createSignMark, deleteSignMark, updateSignMark } from '../../../../../state-management/mapping/sign-mark/signMarkActions';
import { convertToMeter, convertToPixel } from '../regions/utils';
import RegionActions from '../regions/RegionActions';
import { openSignMarkEditor, selectMappingSignMarkEditor } from '../../../../../state-management/user-inputs/mappingSlice';
import { DuplicateWithOffsetDialog } from '../../../../map-content/dialogs/duplicate-offset';
import { isFulfilled, isRejected } from '@reduxjs/toolkit';
import { showErrorNotification, showSuccessNotification } from '../../../../../state-management/notification/notificationReducer';
import { isNumberString } from '../../../../../state-management/utils';

const minHeight = 1;
const minWidth = 1;

const getBulletLocation = (location, cx, cy) => {
    switch (location) {
        case 'top': {
            return {
                cy: `calc(${cy} + calc(100 / var(${SCALE})) / 2)`,
                cx
            }
        }
        case 'bottom':
            return {
                cy: `calc(${cy} - calc(4 / var(${SCALE})) / 2)`,
                cx
            }
        case 'left':
            return {
                cx: `calc(${cx} - calc(4 / var(${SCALE})))`,
                cy
            }
        case 'right':
            return {
                cx: `calc(${cx} + calc(4 / var(${SCALE})))`,
                cy
            }
    }
}

export const ResizeBullet = styled.circle.attrs((props) => ({
    style: { r: `calc(2 / var(${SCALE}))` },
    cx: props.x,
    cy: props.cy,
    ...props,
}))`
    fill: coral; // TODO color should come from theme
    stroke: #ffffff;
    stroke-width: calc(1px / var(${SCALE}));
    cursor: ${(props) => props.cursor ?? 'auto'};

`;

const ElementTitle = styled.text`
    text-anchor: middle;
    dominant-baseline: middle;
    pointer-events: none !important;
    fill: white;
    font-size: ${({ text }) => text.length > 3 ? `calc(10px / var(${SCALE}))` : `calc(12px / var(${SCALE}))`};
    transform: ${({ text }) => text.length > 3 ? `translateX(10px)` : 'unset'}
`;

const CircleDot = styled.circle.attrs({
    style: {
        r: `calc(14 / var(${SCALE}))`
    }
})`
    fill: #9c6e1a;
    color: white;

    cursor: pointer;
    &.read-only {
        cursor: inherit;
    }
    :hover:not(.read-only),
    &.highlighted:not(.read-only) {
        fill-opacity: 0.6;
    }

    &.selected:not(.read-only) {
        fill: #9c6f1ac5; // TODO color should come from theme
        stroke: #9c6f1ac5;

        &.draggable:not(.read-only) {
            cursor: grab;
        }

        &.dragging:not(.read-only) {
            cursor: grabbing;
        }
    }
    &.disabled {
        
        pointer-events: none;
        &:hover {
            fill-opacity: 1;
        }
    }
`;

function SignMark(props) {
    const {
        id,
        signMark,
        mapId,
        onClick,
        onCancel,
        onSendToBack,
        isSelected = false,
        isEditable = true,
        isDraggable,
        readOnly,
        disabled,
    } = props;
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [placementsToDuplicate, setPlacementsToDuplicate] = useState([]);
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
    const [circleRadius, setCircleRadius] = useState(5)


    const { signMarkId, text, metadata } = signMark ?? {};
    const textRef = useRef(null);
    const { containerRef, getCSSVariable, MapViewerOptionsRef } = useContext(MapViewerContext);
    const buildingId = useSelector(selectBuildingsSelectedBuildingId);
    const floorId = useSelector(selectBuildingsSelectedFloorId);
    const [{ isResizing, pointerOrigin, isDragging, coordinates, originalCoordinates }, setShapeState] =
        useImmer({
            isResizing: { leftSide: false, rightSide: false, topSide: false, bottomSide: false },
            pointerOrigin: { x: 0, y: 0 },
            coordinates: signMark.coordinates,
            originalCoordinates: signMark.coordinates,
            isDragging: false,
        });

    const { isOpen: isSignMarkEditorOpen, signMarkId: signMarkEditorId } = useSelector(selectMappingSignMarkEditor)

    /* const resizeHandles = useMemo(() => {
        const { x, y } = convertToPixel(coordinates, getCSSVariable);
        return [
            { id: getSignMarkPlacementTopResizeBulletId(signMarkId), x, y, location: 'top', cursor: 'nesw-resize' },
            { id: getSignMarkPlacementBottomResizeBulletId(signMarkId), x, y, location: 'bottom', cursor: 'nwse-resize' },
            { id: getSignMarkPlacementLeftResizeBulletId(signMarkId), x, y, location: 'left', cursor: 'nwse-resize' },
            { id: getSignMarkPlacementRightResizeBulletId(signMarkId), x, y, location: 'right', cursor: 'nesw-resize' },
        ];
    }, [coordinates]) */

    const handleCirclePointerDown = useCallback(
        (event) => {
            if (!isDraggable) {
                return;
            }
            event.stopPropagation();
            event.preventDefault();
            const { clientX, clientY } = event;

            if (isEditable) {
                setShapeState((state) => {
                    state.isDragging = true;
                    state.pointerOrigin.x = clientX;
                    state.pointerOrigin.y = clientY;
                    state.originalCoordinates.x = state.coordinates.x;
                    state.originalCoordinates.y = state.coordinates.y;
                });
            }

        },
        [isEditable, onClick, signMarkId, setShapeState]
    );

    ;
    const handleCirclePointerMove = useCallback(
        (event) => {
            if (!isDraggable && !isDragging) {
                return;
            }
            const { clientX, clientY } = event;

            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
            const maxX = parseFloat(getCSSVariable(BOUNDARY_MAX_X));
            const minX = parseFloat(getCSSVariable(BOUNDARY_MIN_X));
            const maxY = parseFloat(getCSSVariable(BOUNDARY_MAX_Y));
            const minY = parseFloat(getCSSVariable(BOUNDARY_MIN_Y));

            const xDifference = (clientX - pointerOrigin?.x) / scale / pixelToMeter;
            const yDifference = (clientY - pointerOrigin?.y) / scale / pixelToMeter;

            setShapeState((state) => {
                // Limit the X/Y differences so that they won't make the region go outside the map image boundaries
                const limitedXDiff = Math.min(
                    Math.max(xDifference, minX - state.originalCoordinates.x),
                    maxX - state.originalCoordinates.x
                );
                const limitedYDiff = Math.min(
                    Math.max(yDifference, state.originalCoordinates.y - maxY),
                    state.originalCoordinates.y - minY
                );

                state.coordinates.x = state.originalCoordinates.x + limitedXDiff;
                state.coordinates.y = state.originalCoordinates.y - limitedYDiff;
            });
        },
        [getCSSVariable, pointerOrigin, setShapeState]
    );

    const handleCirclePointerUp = useCallback(
        (event) => {

            event.stopPropagation();
            event.preventDefault();

            setShapeState((state) => {
                state.isDragging = false;
                state.pointerOrigin.x = 0;
                state.pointerOrigin.y = 0;
                state.originalCoordinates.x = state.coordinates.x;
                state.originalCoordinates.y = state.coordinates.y;
            });

        },
        [setShapeState]
    );

    useEffect(() => {
        return () => {
            onCancel();
        }
    }, [])

    useEffect(() => {
        if (textRef.current) {
            const textWidth = textRef.current.getBBox().width;
            const padding = 10; // Add some padding around the text
            setCircleRadius(textWidth / 2 + padding);
        }
    }, [text]);

    useEffect(() => {
        // Attach event listeners for rectangle dragging
        if (isDragging && isDraggable) {
            window.addEventListener('pointermove', handleCirclePointerMove);
            window.addEventListener('pointerup', handleCirclePointerUp);

            return () => {
                window.removeEventListener('pointermove', handleCirclePointerMove);
                window.removeEventListener('pointerup', handleCirclePointerUp);
            };
        }
        if (!isDraggable) {
            window.removeEventListener('pointermove', handleCirclePointerMove);
            window.removeEventListener('pointerup', handleCirclePointerUp);
        }
    }, [handleCirclePointerMove, handleCirclePointerUp, isDragging, isDraggable]);

    const handleSave = async () => {
        setIsSaving(true);
        const result = await dispatch(
            updateSignMark({
                buildingId, floorId, mapId, signMarkId, updatedSignMark: {
                    coordinates,
                    text,
                    metadata: metadata ?? undefined
                }
            })
        );
        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Sign mark updated successfully'));
            onCancel();
        } else {
            dispatch(showErrorNotification('Failed to update sign mark'));
        }
        setIsSaving(false);
    };

    const onDuplicate = async () => {
        let i = 0;
        for (const signMarkToCreate of placementsToDuplicate) {
            i++;
            const nextText = isNumberString(text) ? (Number(text) + i).toString() : `${text}_${i}`
            const result = await dispatch(createSignMark({ buildingId, floorId, mapId, createdSignMark: { coordinates: signMarkToCreate.center, text: nextText, metadata: metadata || undefined } }))
            if (isRejected(result)) {
                return dispatch(showErrorNotification(`Failed to duplicate sign mark : ${nextText}`))
            }

        }
        dispatch(showSuccessNotification(`${text} was duplicated ${placementsToDuplicate.length - 1} times.`));
        setIsDuplicateModalOpen(false);
        onCancel();
    }

    const handleDelete = async () => {
        setIsDeleting(true)
        const result = await dispatch(deleteSignMark({ buildingId, floorId, mapId, signMarkId }));
        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`Sign mark deleted successfully`));
            onCancel();
        } else {
            dispatch(showErrorNotification(`Failed to delete sign mark`));
        }
        setIsDeleting(false)

    };

    const onOpenDuplicate = () => {
        MapViewerOptionsRef.current.disablePanning = true;
        setIsDuplicateModalOpen(true);
    }

    const onCloseDuplicateOffset = (e) => {
        MapViewerOptionsRef.current.disablePanning = false;
        setPlacementsToDuplicate([]);
        setIsDuplicateModalOpen(false);
    }

    const { cx, cy } = useMemo(() => {
        if (!coordinates) {
            return {}
        }
        const { x: cx, y: cy } = convertToPixel(coordinates, getCSSVariable);
        return { cx, cy }
    }, [coordinates])

    useEffect(() => {
        if (!signMark.coordinates) return;
        setShapeState(state => {
            state.coordinates = signMark.coordinates
        })
    }, [signMark])

    const truncatedText = useMemo(() => {
        if (text.length > 3) {
            return text.slice(0, 3) + '...';
        }
        return text;
    }, [text]);

    const onOpenSignMarkEditor = () => {
        MapViewerOptionsRef.current.disablePanning = true;
        dispatch(openSignMarkEditor(signMarkId))
    }

    if (!signMark) {
        return null;
    }



    if (readOnly || disabled) {
        return text.length > 3 ? <Tooltip component='g' placement='top' content={text} wrapperProps={{ style: { outline: 'none' } }}>
            <>
                {!!coordinates && <CircleDot
                    className={clsx({
                        selected: isSelected,
                        disabled
                    })}
                    r={circleRadius}
                    cx={cx}
                    cy={cy}
                />}
                <ElementTitle ref={textRef} x={cx} y={cy} text={text}>
                    {truncatedText}
                </ElementTitle>
            </>
        </Tooltip> : (<>
            {!!coordinates && <CircleDot
                className={clsx({
                    selected: isSelected,
                    disabled
                })}
                r={circleRadius}
                cx={cx}
                cy={cy}
            />}
            <ElementTitle ref={textRef} x={cx} y={cy} text={text}>
                {truncatedText}
            </ElementTitle>
        </>)


    }


    return (
        <>
            <DuplicateWithOffsetDialog
                open={isDuplicateModalOpen}
                onClose={onCloseDuplicateOffset}
                setPlacementsToDuplicate={setPlacementsToDuplicate}
                onSubmitHandler={onDuplicate}
                placmentsToDuplicate={placementsToDuplicate}
                placment={
                    {
                        center: coordinates,
                        regionType: 'circular'
                    }
                }
            />
            <Tooltip
                key={signMark?.signMarkId}
                component={'g'}
                placement={'bottom'}
                visible={isSelected && !isSignMarkEditorOpen && !isDuplicateModalOpen}
                sticky
                interactive
                appendTo={containerRef.current}
                wrapperProps={{ style: { outline: 'none' } }}
                content={
                    <RegionActions
                        onSave={handleSave}
                        onEdit={onOpenSignMarkEditor}
                        onCancel={() => {
                            setShapeState((state) => {
                                state.coordinates = signMark.coordinates
                            })
                            return onCancel();
                        }}
                        onDelete={handleDelete}
                        onDuplicate={onOpenDuplicate}
                        isSaving={isSaving}
                        isDeleting={isDeleting}
                        onSendToBack={() => onSendToBack(signMarkId)}
                    />
                }
            >
                {
                    text.length > 3 ? <Tooltip component={'g'}
                        placement={'top'}
                        content={text}
                    >
                        <CircleDot
                            id={id}
                            r={circleRadius}
                            cx={cx}
                            cy={cy}
                            onClick={onClick}
                            className={clsx({
                                highlighted: isSelected,
                                selected: isSelected,
                            })}
                            onPointerDown={handleCirclePointerDown}
                        />
                        {text && <ElementTitle ref={textRef} x={cx} y={cy} text={text}>
                            {truncatedText}
                        </ElementTitle>}
                    </Tooltip>
                        : <>
                            <CircleDot
                                id={id}
                                r={circleRadius}
                                cx={cx}
                                cy={cy}
                                onClick={onClick}
                                className={clsx({
                                    highlighted: isSelected,
                                    selected: isSelected,
                                })}
                                onPointerDown={handleCirclePointerDown}
                            />
                            {text && <ElementTitle ref={textRef} x={cx} y={cy} text={text}>
                                {truncatedText}
                            </ElementTitle>}
                        </>}

            </Tooltip>
            {isDuplicateModalOpen && placementsToDuplicate.map(({ center }, i) => {
                const id = isNumberString(text) ? Number(text) + (i + 1) : `${text}_${i + 1}`
                const { x, y } = convertToPixel(center, getCSSVariable);
                return <>
                    <CircleDot
                        id={id}
                        r={circleRadius}
                        cx={x}
                        cy={y}
                        className={clsx({
                            highlighted: isSelected,
                            selected: isSelected,
                            disabled
                        })}
                    // onPointerDown={handleCirclePointerDown}
                    />
                    <ElementTitle text={text} x={x} y={y}>
                        {id}
                    </ElementTitle>
                </>
            })}

        </>
    );
}

SignMark.propTypes = {
    area: PropTypes.object.isRequired,
    mapId: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
    isHighlighted: PropTypes.bool,
    isSelected: PropTypes.bool,
    isDraggable: PropTypes.bool,
    isResizeable: PropTypes.bool,
    type: PropTypes.oneOf(['normal', 'element', 'exitRegion']),
    title: PropTypes.string,
};

export default memo(SignMark);
