import React from 'react';
import simpleheat from 'simpleheat';
import * as PropTypes from 'prop-types';

let heatMapValueOffset = parseFloat(localStorage.getItem('heatMapOffset')) || null;
let heatMapAlpha = parseFloat(localStorage.getItem('heatMapAlpha')) || null;

export default class Heatmap extends React.PureComponent {
    componentDidMount() {
        this.updateHeatmapData();
        window.onstorage = () => {
            heatMapValueOffset = parseFloat(localStorage.getItem('heatMapOffset')) || null;
            heatMapAlpha = parseFloat(localStorage.getItem('heatMapAlpha')) || null;
            this.forceUpdate();
        };
    }

    componentDidUpdate() {
        this.updateHeatmapData();
    }

    updateHeatmapData() {
        const { data, max, stageSize, mapOffset, pixelToMeter, width, height, valueOffset } = this.props;
        if (!stageSize.height || !stageSize.width) {
            return;
        }
        if (!this.heatmapInstance) {
            const hmElem = document.querySelector('#heatmap');
            this.heatmapInstance = simpleheat(hmElem);
        }

        const canvas = document.querySelector('#heatmap');
        canvas.width = width;
        canvas.height = height;

        // adjust the x, y positions to the map coordinates (bottom-left based)
        const convertedData = data.map(({ x, y, value }) => [
            (x - mapOffset.x) * pixelToMeter * stageSize.scale,
            (stageSize.height / pixelToMeter - (y - mapOffset.y)) * pixelToMeter * stageSize.scale,
            Math.min(value / max, 1),
        ]);


        // this.heatmapInstance.max(max / 1000);
        this.heatmapInstance.data(convertedData);
        this.heatmapInstance.resize();
        this.heatmapInstance.radius(
            0.35 * pixelToMeter * stageSize.scale,
            pixelToMeter * stageSize.scale * 0.15
        );
        this.heatmapInstance.draw();
    }

    render() {
        return <canvas id="heatmap" className="heatmap-canvas" />;
    }
}

Heatmap.propTypes = {
    data: PropTypes.array.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    stageSize: PropTypes.object.isRequired,
    pixelToMeter: PropTypes.number.isRequired,
    mapOffset: PropTypes.object,
    valueOffset: PropTypes.number,
};

Heatmap.defaultProps = {
    mapOffset: { x: 0, y: 0 },
    valueOffset: 0,
};
