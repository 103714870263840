import Api from './Api';
import http from '../utils/httpClient';

class EntrancesApi extends Api {
    async fetchAllEntrancesInBuilding(buildingId) {
        return await this.callApi((params) =>
            http.v7.get(`/buildings/${buildingId}`, { params: { ...params, includeEntrances: true } })
        );
    }

    async createEntrance(buildingId, floorId, entranceData) {
        return await this.callApi((params) =>
            http.v5.post(`/buildings/${buildingId}/floors/${floorId}/entrances`, entranceData, { params })
        );
    }

    async updateEntrance(buildingId, floorId, entranceId, entranceData) {
        return await this.callApi((params) =>
            http.v5.post(`/buildings/${buildingId}/floors/${floorId}/entrances/${entranceId}`, entranceData, {
                params,
            })
        );
    }

    async deleteEntrance(buildingId, floorId, entranceId) {
        return await this.callApi((params) =>
            http.v5.delete(`/buildings/${buildingId}/floors/${floorId}/entrances/${entranceId}`, { params })
        );
    }
}

export default new EntrancesApi();
