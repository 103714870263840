import React, { useContext, useEffect, useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { getClientMarkerClassName, getClientMarkerId } from './MapViewerLiveMonitorOverlay.selectors';
import { useDispatch } from 'react-redux';
import { MapViewerContext } from '../../MapViewerContext';
import {
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import { clientExpired } from '../../../../../state-management/monitor/monitorEventsActions';
import { ReactComponent as ArrowSvg } from '../../../../../assets/oriientArrow.svg';
import { showErrorNotification } from '../../../../../state-management/notification/notificationReducer';

const MarkerGroup = styled.g.attrs(({ $x, $y, $heading }) => ({
    style: {
        transform: `translate(${$x}px, ${$y}px) rotate(${-$heading + 90}deg)`,
    },
}))``;

const MarkerArrow = styled(ArrowSvg)`
    overflow: visible;

    path {
        :first-child {
            fill: ${(props) => props.$color};
        }

        transform: scale(calc(0.15 / var(${SCALE}))) translate(-50%, -125%);
    }
`;

const MarkerCircle = styled.circle`
    fill: ${(props) => props.$color};
    opacity: ${(props) => (props.$isAccurate ? '1' : '0.5')};
    transform: scale(calc(1 / var(${SCALE})));
`;

const AccuracyCircle = styled.circle`
    fill: ${(props) => props.$color};
    opacity: 0.25;
`;

export default function ClientMarker(props) {
    const { client, acceptedAccuracy, showLocationAccuracy } = props;
    const {
        clientId,
        position: currentPosition,
        positionAccuracy: currentAccuracy,
        heading: currentHeading,
        color,
    } = client ?? {};

    const dispatch = useDispatch();

    const { getCSSVariable } = useContext(MapViewerContext);

    const lastAccuratePosition = useRef(null);

    const isAccurate = currentAccuracy <= acceptedAccuracy;

    useEffect(() => {
            
        const timeout = setTimeout(() => {
            dispatch(clientExpired(clientId));
        }, 10000);

        if(!clientId) {
            dispatch(showErrorNotification("There something wrong with the clientId"));
            clearTimeout(timeout);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [clientId, currentPosition, dispatch]);

    useEffect(() => {
        if (isAccurate) {
            lastAccuratePosition.current = {
                position: currentPosition,
                positionAccuracy: currentAccuracy,
                heading: currentHeading,
            };
        }
    }, [currentAccuracy, currentHeading, currentPosition, isAccurate]);

    const { x, y, accuracyRadius, heading } = useMemo(() => {
        const mapOffsetX = getCSSVariable(OFFSET_X);
        const mapOffsetY = getCSSVariable(OFFSET_Y);
        const pixelToMeter = getCSSVariable(PIXEL_TO_METER);
        const imageNaturalHeight = getCSSVariable(IMAGE_NATURAL_HEIGHT);

        const position = isAccurate ? currentPosition : lastAccuratePosition.current?.position;
        const accuracy = isAccurate ? currentAccuracy : lastAccuratePosition.current?.positionAccuracy;
        const heading = isAccurate ? currentHeading : lastAccuratePosition.current?.heading;

        return {
            x: (position?.[0] - mapOffsetX) * pixelToMeter,
            y: (imageNaturalHeight / pixelToMeter - (position?.[1] - mapOffsetY)) * pixelToMeter,
            accuracyRadius: accuracy * pixelToMeter,
            heading: Math.atan2(heading?.[1], heading?.[0]) * (180 / Math.PI),
        };
    }, [currentAccuracy, currentHeading, currentPosition, getCSSVariable, isAccurate]);

    // If there hasn't been an accurate position so far, don't display the marker on the map yet
    if (!isAccurate && !lastAccuratePosition.current) {
        return null;
    }

    return (
        <MarkerGroup
            id={getClientMarkerId(clientId)}
            className={getClientMarkerClassName()}
            $x={x}
            $y={y}
            $heading={heading}
        >
            {showLocationAccuracy && <AccuracyCircle $color={color} cx={0} cy={0} r={accuracyRadius} />}

            <MarkerCircle $color={color} $isAccurate={isAccurate} cx={0} cy={0} r={10} />

            {isAccurate && <MarkerArrow $color={color} />}
        </MarkerGroup>
    );
}

ClientMarker.propTypes = {
    client: PropTypes.object.isRequired,
    acceptedAccuracy: PropTypes.number.isRequired,
    showLocationAccuracy: PropTypes.bool.isRequired,
};
