import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import OldMapViewer from '../legacy/OldMapViewer';
import { NON_INTEREST_ID } from '../charts/chartConstants';
import { Select, Skeleton, Switch, SwitchLabel as Label } from '../../common/themed';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import useMapsFullData from '../../common/hooks/data-fetching/useMapsFullData';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';

const moment = extendMoment(Moment);
const MANUAL = 'MANUAL';
const AUTO = 'AUTO';

const DisplayedMapSelectorWrapper = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    width: auto;
    padding: 5px;
`;

const HeatMapViewer = styled(OldMapViewer)`
    height: 100%;
`;

const ManualMapSelect = styled(Select)`
    width: 200px;
`;

let allPositions = [];

export default function SessionHeatMap(props) {
    const {
        session,
        maxDuration,
        heatMapValueOffset,
        displayedSessionSegmentStart,
        displayedSessionSegmentEnd,
        selectedRegionId,
        acceptedAccuracy,
    } = props;

    const [displayedPositions, setDisplayedPositions] = useState([]);
    const [displayedMap, setDisplayedMap] = useState(session?.maps[0] ?? null);
    const [mapDisplayMode, setMapDisplayMode] = useState(AUTO);

    const {
        data: { [displayedMap?.mapId]: map },
        isLoading,
        hasError,
    } = useMapsFullData({
        buildingId: session?.buildingId,
        floorId: displayedMap?.floorId,
        mapIds: [displayedMap?.mapId],
        asObject: true,
    });

    const updateDisplayedSessionSegment = useCallback(() => {
        const displayedRange = moment.range(
            new Date(displayedSessionSegmentStart),
            new Date(displayedSessionSegmentEnd)
        );

        // In Auto mode, the displayed map is determined by the start of the currently viewed session segment
        let displayedMapId = mapDisplayMode === MANUAL ? displayedMap.mapId : null;

        const positionsToDisplay = allPositions.filter((position) => {
            if (
                displayedRange.overlaps(position.dateRange) &&
                (!displayedMapId || position.mapId === displayedMapId)
            ) {
                // If the displayed map ID is undefined, then we're in Auto mode
                // The first position that 'passes' determines the displayed map
                if (!displayedMapId) {
                    displayedMapId = position.mapId;
                }

                // If a there's a selected region, filter the position if it's not in that region
                // If there's no selected region, the position 'passes'
                // This filter must come at this point, because by now we've determined the displayed map,
                // otherwise, it could lead to choosing the wrong displayed map by not passing the first 'if'
                return !selectedRegionId || position.regionIds.includes(selectedRegionId);
            }
            return false;
        });

        setDisplayedPositions(positionsToDisplay);

        if (positionsToDisplay.length > 0 && mapDisplayMode === AUTO) {
            setDisplayedMap(session.maps?.find((m) => m.mapId === displayedMapId));
        }
    }, [
        displayedMap.mapId,
        displayedSessionSegmentEnd,
        displayedSessionSegmentStart,
        mapDisplayMode,
        selectedRegionId,
        session.maps,
    ]);

    const handleNewSessionData = useCallback(() => {
        allPositions = [];

        session.positions.forEach(({ positionId, x, y, d, u, t, isAccuracySmoothed, mapId, regions }) => {
            if (u <= parseInt(acceptedAccuracy, 10) || isAccuracySmoothed) {
                allPositions.push({
                    positionId,
                    dateRange: moment.range(moment(t), moment(t).add(d, 'milliseconds')),
                    x,
                    y,
                    value: d,
                    mapId,
                    regionIds:
                        regions.length === 0
                            ? [NON_INTEREST_ID] // No regions actually means it's in non-interest
                            : regions.map((r) => r.regionId),
                });
            }
        });

        updateDisplayedSessionSegment();
    }, [acceptedAccuracy, session.positions, updateDisplayedSessionSegment]);

    useEffect(() => {
        handleNewSessionData();
    }, [handleNewSessionData]);

    useEffect(() => {
        updateDisplayedSessionSegment();
    }, [updateDisplayedSessionSegment]);

    if (hasError) {
        return <ErrorGeneral />;
    }

    return (
        <>
            <Grid container alignItems={'center'} spacing={1} component={DisplayedMapSelectorWrapper}>
                <Grid item>
                    <ManualMapSelect
                        options={session?.maps}
                        onChange={setDisplayedMap}
                        getOptionValue={(m) => m.mapId}
                        getOptionLabel={(m) => m.mapName}
                        isDisabled={mapDisplayMode === AUTO}
                        value={displayedMap}
                    />
                </Grid>

                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={mapDisplayMode === AUTO}
                                onChange={(e) => setMapDisplayMode(e.target.checked ? AUTO : MANUAL)}
                            />
                        }
                        label={<Label>Auto</Label>}
                    />
                </Grid>
            </Grid>

            {isLoading ? (
                <Skeleton height={'100%'} />
            ) : (
                <HeatMapViewer
                    disableContextMenu
                    heatmapData={{
                        positions: displayedPositions,
                        maxDuration: maxDuration * 1000,
                        valueOffset: heatMapValueOffset,
                    }}
                    selectedMapData={map}
                />
            )}
        </>
    );
}

SessionHeatMap.propTypes = {
    session: PropTypes.object.isRequired,
    maxDuration: PropTypes.number.isRequired,
    displayedSessionSegmentStart: PropTypes.string.isRequired,
    displayedSessionSegmentEnd: PropTypes.string.isRequired,
    selectedRegionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
