import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import useProductLocations from '../common/hooks/data-fetching/useProductLocations';
import ErrorGeneral from '../common/error-pages/ErrorGeneral';
import { Skeleton, DialogTitle, Dialog } from '../common/themed';
import { RootRef, Tabs, Tab } from '@material-ui/core';
import { Table } from '../common/themed/Table/newTable';
import tableColumns from './tableColumns';
import ImagePanel from './ImagePanel';
import ProductLocationsApi from '../../api/ProductLocationsApi';
import SettingsPage from './settings';
import { useDetailPanel } from '../common/hooks/useDetailPanel';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    & > *:first-child {
        margin-bottom: 20px;
    }
`;

const Image = styled.img`
    width: 300px;
    height: 300px;
    cursor: pointer;
`;

export default function ProductLocations({ type }) {
    const targetRef = useRef();
    const [currentTab, setCurrentTab] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const rowRef = useRef(null);
    const [detailWidth, setDetailWidth] = useState(0);

    const {
        data: tableData = [],
        isLoading,
        hasError,
        selectedSpaceId,
        recordsCount,
        getProductLocationData,
        getRecordsCount,
    } = useProductLocations({ type });

    const { data, setData } = useDetailPanel({ tableData });

    useLayoutEffect(() => {
        if (targetRef.current) {
            setWidth(targetRef.current.offsetWidth);
            setHeight(targetRef.current.offsetHeight);
        }
    }, []);

    useLayoutEffect(() => {
        if (rowRef.current) {
            setDetailWidth(rowRef.current.clientWidth - 80);
        }
        return () => {};
    }, []);

    const handleExportFileRequest = async (filter, paginate, sort) => {
        if (!selectedSpaceId) return false;
        const action =
            type === 'internal' ? 'fetchInternalProductLocationsData' : 'fetchExternalProductLocationsData';
        try {
            return await ProductLocationsApi[action]({ filter, paginate, sort });
        } catch (err) {
            return false;
        }
    };

    const renderTable = () => {
        return (
            <RootRef rootRef={targetRef}>
                <>
                    <Table
                        setData={setData}
                        count={Number(recordsCount)}
                        title="PLAI Dashboard"
                        id="table1"
                        actions={{ fetchData: getProductLocationData, fetchRecords: getRecordsCount }}
                        dependencies={[selectedSpaceId]}
                        columns={tableColumns}
                        data={data}
                        isLoading={isLoading}
                        tableOffsetHeight={200}
                        tableOffsetWidth={10}
                        detailPanel={(row) => {
                            return (
                                <ImagePanel
                                    data={{
                                        mapId: row.rowData.mapId,
                                        buildingId: row.rowData.buildingId,
                                        spaceId: selectedSpaceId,
                                    }}
                                    setSelectedImage={setSelectedImage}
                                    detailWidth={detailWidth}
                                />
                            );
                        }}
                        handleExportFileRequest={handleExportFileRequest}
                    />
                    {selectedImage && (
                        <Dialog
                            id="dw"
                            open
                            fullWidth
                            maxheight="lg"
                            maxWidth="lg"
                            onClose={() => {
                                setSelectedImage(null);
                            }}
                        >
                            <DialogTitle>Preview</DialogTitle>
                            <Image src={selectedImage} style={{ width: '100%', height: '100%' }} />
                        </Dialog>
                    )}
                </>
            </RootRef>
        );
    };
    const handleTabChange = (event, selectedTabIndex) => {
        setCurrentTab(selectedTabIndex);
    };

    const renderCurrentTab = () => {
        switch (currentTab) {
            case 0:
                return renderTable();
            case 1:
                return <SettingsPage />;
            default:
                return null;
        }
    };
    return (
        <Wrapper>
            <Tabs ref={rowRef} value={currentTab} onChange={handleTabChange}>
                <Tab label="DASHBOARD" />
                <Tab label="SETTINGS" />
            </Tabs>
            {renderCurrentTab()}
        </Wrapper>
    );
}
