import { createAsyncThunk } from '../utils';
import ProductLocationsApi from '../../api/ProductLocationsApi';

export const fetchInternalProductLocationsData = createAsyncThunk(
    'productLocations/fetchInternalProductLocationsData',
    async ({ filter, paginate, sort }) => {
        return (await ProductLocationsApi.fetchInternalProductLocationsData({filter, paginate, sort})).data;
    }
);
export const fetchExternalProductLocationsData = createAsyncThunk(
    'productLocations/fetchExternalProductLocationsData',
    async ({ filter, paginate, sort }) => {
        return (await ProductLocationsApi.fetchExternalProductLocationsData({filter, paginate, sort})).data;
    }
);
export const fetchRecordsCount = createAsyncThunk(
    'productLocations/fetchRecordsCount',
    async ({ filter = [] }) => {
        return (await ProductLocationsApi.fetchRecordsCount(filter)).data;
    }
);
export const getSpaceConfig = createAsyncThunk('productLocations/getSpaceConfig', async () => {
    return (await ProductLocationsApi.getSpaceConfig()).data;
});
export const updateSpaceConfig = createAsyncThunk(
    'productLocations/updateSpaceConfig',
    async ({
        spaceId,
        frequency,
        timezone,
        timeframe,
        isMultiLocation,
        usePlaiRegions,
        singleProductPerRegion,
    }) => {
        return (
            await ProductLocationsApi.updateSpaceConfig(
                {spaceId,
                frequency,
                timezone,
                timeframe,
                isMultiLocation,
                usePlaiRegions,
                singleProductPerRegion}
            )
        ).data;
    }
);
