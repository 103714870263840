import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectHasError, selectIsLoading } from '../../../../state-management/status/statusSelectors';
import useSpaces from './useSpaces';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import { selectRegionColorsInSpace } from '../../../../state-management/space/spaceSelectors';
import { fetchRegionColorsInSpace } from '../../../../state-management/region/regionActions';
import useAllRegionsInSpace from './useAllRegionsInSpace';

/**
 * Hook that fetches the provided space's region colors to the Redux store.
 * If no `spaceId` is provided in the options, the selected space ID will be used by default.
 * @returns {{isLoading: *, data, hasError: *}}
 */
export default function useRegionColorsInSpace(options = {}) {
    const { spaceId: providedSpaceId } = options;

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const spaceId = providedSpaceId || selectedSpaceId;

    const allSpaces = useSpaces();
    const allRegionsInSpace = useAllRegionsInSpace({ spaceId });

    const regionNames = allRegionsInSpace?.data?.map((r) => r.regionName);

    const dispatch = useDispatch();

    const regionColors = useSelector(selectRegionColorsInSpace(spaceId));
    const isFetching =
        useSelector(
            selectIsLoading({ type: fetchRegionColorsInSpace.typePrefix, arg: { spaceId, regionNames } })
        ) ||
        allSpaces.isLoading ||
        allRegionsInSpace.isLoading;

    const hasError =
        useSelector(
            selectHasError({ type: fetchRegionColorsInSpace.typePrefix, arg: { spaceId, regionNames } })
        ) ||
        allSpaces.hasError ||
        allRegionsInSpace.hasError;

    useEffect(() => {
        // If a selected space has been selected, fetch its region colors
        if (spaceId && !regionColors && regionNames && !isFetching && !hasError) {
            dispatch(fetchRegionColorsInSpace({ spaceId, regionNames }));
        }
    }, [dispatch, regionColors, spaceId, isFetching, hasError, regionNames]);

    return { data: regionColors, isLoading: isFetching, hasError };
}
