import React from 'react';
import styled from 'styled-components';
import { Tabs } from '@material-ui/core';

export default styled(props => (
    <Tabs
        classes={{
            indicator: 'themed-tabs-indicator',
        }}
        {...props}
    />
))`
    & .themed-tabs-indicator {
        background-color: coral; // TODO color should come from theme
    }
`;
