import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import MapImage from './OldMapImage';
import { Button as FlatButton, Paper } from '@material-ui/core';
import ContextMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as ENUM from '../../../utils/dashboardEnums';
import settings from '../../../clientSettings';
import OnlineClient from './OldOnlineClient';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import analyticsManager from '../../../api/AnalyticsManager';
import moment from 'moment';
import uuid from 'uuid/v4';
import { getDefaultMapImage } from '../../../utils/mapImages.helper';
const textFieldStyle = {
    floatingLabelStyle: { color: '#ff6600' },
    underlineFocusStyle: {
        borderColor: '#ff6600',
        borderWidth: '1px',
    },
};

const paperStyle = {
    // marginTop: 10,
    textAlign: 'left',
    overflow: 'visible',
};

class MapViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedMapData: null,
            stageSize1: {
                name: 'stageSize1',
                width: 0,
                height: 0,
                top: 4,
                left: 4,
                scale: 1,
            },
            stageSize2: {
                name: 'stageSize2',
                width: 0,
                height: 0,
                originalHeight: 0,
                originalWidth: 0,
                top: 4,
                left: 4,
                scale: 1,
            },
            positions: null,
            regionIdsOnBack: [],
        };
    }

    //noinspection JSDuplicatedDeclaration
    componentDidMount = async () => {
        const { props } = this;

        const elem = document.getElementById('component.MapViewer');
        elem.addEventListener('mousewheel', this.handleMouseWheel);
        elem.addEventListener('DOMMouseScroll', this.handleMouseWheel);

        let showSessionWalk = localStorage.getItem('showSessionWalk');
        let positions = null;
        if (showSessionWalk) {
            try {
                const sessionData = JSON.parse(showSessionWalk);
                const {
                    sessionId,
                    spaceId,
                    uFilter,
                    fromTime = moment.utc().subtract(1, 'months').toISOString(),
                    toTime = moment.utc().toISOString(),
                } = sessionData;
                if (sessionId) {
                    analyticsManager.spaceId = spaceId;
                    positions = await analyticsManager.getPositionsBySessionId(
                        moment.utc(fromTime).toISOString(),
                        moment.utc(toTime).toISOString(),
                        sessionId
                    );

                    if (uFilter) {
                        positions.data = positions.data.filter((p) => {
                            return p.position.u <= uFilter;
                        });
                    }
                }
            } catch (ex) {
                console.error('failed to parse showSessionWalk, will not show session');
                console.error(ex.message);
            }
        }

        if (positions) {
            this.setState({ positions: positions.data });
        }

        if (props.selectedMapData) {
            this.setState({});
        }

        if (this.props.parent) {
            this.props.parent.mapViewerRef = this;
        }

        if (this.props.onMount) {
            this.props.onMount(this);
        }

        return void 0;
    };

    //noinspection JSDuplicatedDeclaration
    componentWillUnmount() {
        const elem = document.getElementById('component.MapViewer');
        elem.removeEventListener('mousewheel', this.handleMouseWheel);
        elem.removeEventListener('DOMMouseScroll', this.handleMouseWheel);
    }
    //noinspection JSDuplicatedDeclaration,JSCheckFunctionSignatures
    componentWillReceiveProps(nextProps) {
        const { addZoom } = nextProps;

        if (addZoom) this.zoom(addZoom);
        else this.setState({});
    }

    getMapsOffset = () => {
        const { props, state } = this;
        const { stageSize1, stageSize2 } = state;
        const { selectedMapData } = props;
        if (stageSize2.width !== 0) {
            return {
                x:
                    selectedMapData.mapOffset.x +
                    (stageSize2.left / stageSize1.scale - stageSize1.left / stageSize1.scale) /
                        selectedMapData.pixelToMeter,
                y:
                    selectedMapData.mapOffset.y +
                    (stageSize1.top / stageSize1.scale +
                        stageSize1.height -
                        (stageSize2.top / stageSize1.scale + stageSize2.height)) /
                        selectedMapData.pixelToMeter,
            };
        }
        return { x: 0, y: 0 };
    };

    getPixelToMeter = () => {
        const { props, state } = this;
        const { stageSize2 } = state;
        const { selectedMapData } = props;
        if (stageSize2.width !== 0) {
            return selectedMapData.pixelToMeter / (stageSize2.width / stageSize2.originalWidth);
        }
        return selectedMapData.pixelToMeter;
    };

    handleMouseMove = (event) => {
        const bound = event.currentTarget.getBoundingClientRect();
        const { props, state } = this;
        const { selectedMapData, updateMouseCoordinates } = props;

        if (!selectedMapData) return;

        event.preventDefault();
        const stageScale = state.stageSize1.scale;
        const pixelToMeter = selectedMapData.pixelToMeter || 1;
        const stageHeight = state.stageSize1.height / pixelToMeter;
        let x = Number(
            (event.clientX - bound.left) / pixelToMeter / stageScale + selectedMapData.mapOffset.x
        ).toFixed(1);
        let y = Number(
            stageHeight -
                (event.clientY - bound.top) / pixelToMeter / stageScale +
                selectedMapData.mapOffset.y
        ).toFixed(1);
        updateMouseCoordinates({ currCoordinatesX: x, currCoordinatesY: y });
    };

    handleOnContextMenu = (event) => {
        if (this.props.canEditRegions) {
            //noinspection JSUnresolvedFunction
            const bound = this.refs['mapImage1'].getBoundingClientRect();
            const { props } = this;
            const { contextMenu, onNewRegionClick } = props;

            event.preventDefault();

            //Prevent context menu if a region is selected
            // if (Region.selected) return;

            let anchorEl = document.getElementsByClassName('context-menu-holder')[0];
            anchorEl.style.position = 'absolute';
            anchorEl.style.top = `${event.pageY}px`;
            anchorEl.style.left = `${event.pageX}px`;

            this.setState(
                {
                    contextMenuShow: true,
                    contextMenuPageX: event.pageX,
                    contextMenuPageY: event.pageY,
                    contextMenuOffsetX:
                        bound.left +
                        window.pageXOffset -
                        document.documentElement.clientLeft -
                        document.documentElement.scrollLeft,
                    contextMenuOffsetY:
                        bound.top +
                        window.pageYOffset -
                        document.documentElement.clientTop -
                        document.documentElement.scrollTop,
                    anchorEl: anchorEl,
                },
                () => {
                    let { center, bottomLeft, topRight, radius } = this._calcRegionDefaultLocation();

                    let defaultCommonRegion = {
                        // this is not the regionId that will be entered to the DB, this is just for identification
                        // needs with the other components
                        mapId: this.props.selectedMap.mapId,
                        regionName: `newRegion_${uuid().substring(0, 7)}`,
                        center: center,
                        regionMetadata: '',
                        radius: radius,
                        bottomLeft: bottomLeft,
                        topRight: topRight,
                        isNewRegion: true,
                    };

                    const defaultRectRegion = {
                        ...defaultCommonRegion,
                        regionType: ENUM.REGION.TYPE.RECT,
                    };
                    const defaultCircularRegion = {
                        ...defaultCommonRegion,
                        regionType: ENUM.REGION.TYPE.CIRCULAR,
                    };

                    this.setState({
                        contextMenuItems:
                            contextMenu === 'regions'
                                ? [
                                      <MenuItem
                                          key={'contextMenuItems_1'}
                                          onClick={() => {
                                              onNewRegionClick(defaultRectRegion);
                                              this.setState({ contextMenuShow: false });
                                          }}
                                      >
                                          New Rectangle Region
                                      </MenuItem>,
                                      <MenuItem
                                          key={'contextMenuItems_2'}
                                          onClick={() => {
                                              onNewRegionClick(defaultCircularRegion);
                                              this.setState({ contextMenuShow: false });
                                          }}
                                      >
                                          New Circular Region
                                      </MenuItem>,
                                  ]
                                : contextMenu === 'pixelToMeter'
                                ? [
                                      <MenuItem key={'contextMenuItems_3'} onClick={this.startPixelToMeter}>
                                          Start Measurement here
                                      </MenuItem>,
                                  ]
                                : null,
                    });
                    return false;
                }
            );
        }
    };

    setImageScale = (imageW, imageH, stageSize) => {
        const { props } = this;
        const { selectedMapData } = props;
        const { onZoomChanged } = props;

        if (selectedMapData && selectedMapData.mapId) {
            let storageStageSize = localStorage.getItem(selectedMapData.mapId);
            if (storageStageSize) {
                try {
                    storageStageSize = JSON.parse(storageStageSize);
                    if (storageStageSize.scale) {
                        stageSize.scale = storageStageSize.scale;
                        stageSize.top = storageStageSize.top;
                        stageSize.left = storageStageSize.left;
                    }
                } catch (e) {}
            }
        } else stageSize.scale = 1;

        onZoomChanged(stageSize.scale);

        stageSize.height = imageH;
        stageSize.width = imageW;
        stageSize.originalHeight = imageH;
        stageSize.originalWidth = imageW;

        this.setState({ [stageSize.name]: { ...stageSize } });
    };

    setStageSize = (stageSize) => {
        this.setState({ [stageSize.name]: { ...stageSize } });
    };

    static getEventPath(event) {
        let path = [];
        let currentElem = event.target;
        while (currentElem) {
            path.push(currentElem);
            currentElem = currentElem.parentElement;
        }
        return path;
    }

    sortRegions = (r1, r2) => {
        const { selectedRegion } = this.props;
        const { regionIdsOnBack } = this.state;

        if (selectedRegion) {
            if (r1.regionId === selectedRegion.regionId || regionIdsOnBack.includes(r2.regionId)) {
                return 1;
            }
            if (r2.regionId === selectedRegion.regionId || regionIdsOnBack.includes(r1.regionId)) {
                return -1;
            }
            return 0;
        } else {
            if (regionIdsOnBack.includes(r2.regionId) && regionIdsOnBack.includes(r1.regionId)) {
                return regionIdsOnBack.indexOf(r1.regionId) > regionIdsOnBack.indexOf(r2.regionId) ? -1 : 1;
            }
            if (regionIdsOnBack.includes(r2.regionId)) {
                return 1;
            }
            if (regionIdsOnBack.includes(r1.regionId)) {
                return -1;
            }
            return 0;
        }
    };

    handleMouseWheel = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const { state, props } = this;
        const { selectedMapData } = props;
        const path = MapViewer.getEventPath(event);
        const isInMapViewer = path.find(function (pathElement) {
            return pathElement.id === 'component.MapViewer';
        });
        if (!selectedMapData) return;
        const mapImage = document.getElementById(
            selectedMapData.mapId ? selectedMapData.mapId : 'newMapHasNoId'
        );

        if (isInMapViewer && selectedMapData && mapImage) {
            let { stageSize1, stageSize2 } = state;
            let wheelDelta = event.wheelDelta ? event.wheelDelta : -event.detail;
            let zooming = (wheelDelta / Math.abs(wheelDelta) / 20) * stageSize1.scale;
            let bound = mapImage.getBoundingClientRect();
            let top =
                bound.top +
                window.pageYOffset -
                document.documentElement.clientTop -
                document.documentElement.scrollTop;
            let left =
                bound.left +
                window.pageXOffset -
                document.documentElement.clientLeft -
                document.documentElement.scrollLeft;

            if (stageSize1.scale + zooming <= 0.1 || stageSize1.scale + zooming >= 4) return;

            let differenceLeft = stageSize1.left - stageSize2.left;
            let differenceTop = stageSize1.top - stageSize2.top;

            let top1 = stageSize1.top;
            let left1 = stageSize1.left;
            let top2 = stageSize2.top;
            let left2 = stageSize2.left;

            top1 -= ((event.pageY - top) * zooming) / stageSize1.scale;
            left1 -= ((event.pageX - left) * zooming) / stageSize1.scale;
            top2 -= ((event.pageY - top) * zooming) / stageSize2.scale;
            left2 -= ((event.pageX - left) * zooming) / stageSize2.scale;
            //Adjust stageSize2 position to keep it align with stageSize1 position;
            top2 -= (differenceTop * zooming) / stageSize1.scale;
            left2 -= (differenceLeft * zooming) / stageSize1.scale;

            this.setState(
                (prevState) => ({
                    stageSize1: { ...prevState.stageSize1, top: top1, left: left1 },
                    stageSize2: { ...prevState.stageSize2, top: top2, left: left2 },
                }),
                () => {
                    this.zoom(zooming);
                }
            );
            // event.preventDefault();
        }
    };

    zoom = (value) => {
        if (!value) return;

        let { state, props } = this;
        let { selectedMapData } = props;
        let { onZoomChanged } = props;
        let { stageSize1 } = state;

        if (!selectedMapData) return;

        let scale = stageSize1.scale;
        let left = stageSize1.left;
        let top = stageSize1.top;

        scale += value;

        scale = Math.min(scale, 4);
        scale = Math.max(scale, 0.1);

        if (selectedMapData && selectedMapData.mapId)
            localStorage.setItem(selectedMapData.mapId, JSON.stringify(stageSize1));

        if (top + scale * stageSize1.height < 40) {
            top = 40 - scale * stageSize1.height;
        }
        if (left + scale * stageSize1.width < 40) {
            left = 40 - scale * stageSize1.width;
        }
        onZoomChanged(scale);

        if (selectedMapData && selectedMapData.mapId)
            localStorage.setItem(selectedMapData.mapId, JSON.stringify(stageSize1));

        this.setState((prevState) => ({
            stageSize1: { ...prevState.stageSize1, scale, top, left },
            stageSize2: { ...prevState.stageSize2, scale },
        }));
    };
    initialiseResize = (stageSize) => {
        return (e) => {
            this.stopResizing();

            this.startResizeFn = this.rectResizing(
                stageSize,
                e.clientX,
                e.clientY,
                stageSize.left,
                stageSize.top
            );
            //noinspection JSUnresolvedVariable
            this.stopResizeFn = this.stopResizing.bind(this);

            window.addEventListener('mousemove', this.startResizeFn, false);
            window.addEventListener('mouseup', this.stopResizeFn, false);

            if (this.refs['mapImage1']) {
                //noinspection JSUnresolvedVariable
                this.refs['mapImage1'].style.cursor = 'all-scroll';
            }

            e.stopPropagation();
        };
    };
    rectResizing = (stageSize, startX, startY, originalX, originalY) => {
        const { props, state } = this;
        const { selectedMapData, lockStages } = props;
        const { stageSize1, stageSize2 } = state;

        return (e) => {
            this.resizing = true;
            let saveStageSize;

            if (lockStages) {
                saveStageSize = JSON.parse(JSON.stringify(stageSize));
            }

            stageSize.left = originalX + (e.clientX - startX);
            stageSize.top = originalY + (e.clientY - startY);

            if (stageSize.top + stageSize.scale * stageSize.height < 40) {
                stageSize.top = 40 - stageSize.scale * stageSize.height;
            }
            if (stageSize.left + stageSize.scale * stageSize.width < 10) {
                stageSize.left = 40 - stageSize.scale * stageSize.width;
            }
            if (lockStages) {
                let differenceTop = stageSize.top - saveStageSize.top;
                let differenceLeft = stageSize.left - saveStageSize.left;
                if (stageSize.name === 'stageSize1') {
                    stageSize2.top += differenceTop;
                    stageSize2.left += differenceLeft;
                } else {
                    stageSize1.top += differenceTop;
                    stageSize1.left += differenceLeft;
                }
            }
            if (selectedMapData && selectedMapData.mapId)
                localStorage.setItem(selectedMapData.mapId, JSON.stringify(stageSize1));
            this.setState({});
        };
    };
    stopResizing = () => {
        window.removeEventListener('mousemove', this.startResizeFn, false);
        window.removeEventListener('mouseup', this.stopResizeFn, false);
        this.startResizeFn = this.stopResizeFn = undefined;
        if (this.refs['mapImage1']) {
            //noinspection JSUnresolvedVariable
            this.refs['mapImage1'].style.cursor = 'pointer';
        }
        this.resizing = false;
    };
    handleContextMenuClose = () => {
        this.setState({ contextMenuShow: false });
    };

    _calcRegionDefaultLocation() {
        const { props, state } = this;
        const { selectedMapData } = props;
        const { defaultRegionWidth } = settings;
        const { contextMenuPageX, contextMenuPageY, contextMenuOffsetX, contextMenuOffsetY } = state,
            { stageSize1 } = state,
            pixelToMeter = selectedMapData.pixelToMeter || 1,
            stageHeight = stageSize1.height / pixelToMeter,
            stageScale = stageSize1.scale,
            x =
                (contextMenuPageX - contextMenuOffsetX) / pixelToMeter / stageScale +
                selectedMapData.mapOffset.x,
            y =
                (contextMenuPageY - contextMenuOffsetY) / pixelToMeter / stageScale -
                selectedMapData.mapOffset.y,
            center = [x, stageHeight - y],
            radius = defaultRegionWidth / 2,
            bottomLeft = [Math.max(x - radius, 1), Math.max(stageHeight - y - radius, 1)],
            topRight = [Math.max(x + radius, 1), Math.max(stageHeight - y + radius, 1)];

        return { center: center, bottomLeft: bottomLeft, topRight: topRight, radius: radius };
    }

    handleNewRegion = (RegionType) => {
        const { props } = this;
        const { onNewRegion, selectedMap } = props;

        return () => {
            let { center, bottomLeft, topRight, radius } = this._calcRegionDefaultLocation();

            let newRegion = {
                // this is not the regionId that will be entered to the DB, this is just for identification
                // needs with the other components
                regionId: uuid(),
                mapId: selectedMap.mapId,
                regionName: `newRegion_${uuid().substring(0, 7)}`,
                regionType: RegionType,
                center: center,
                regionMetadata: '',
                radius: radius,
                bottomLeft: bottomLeft,
                topRight: topRight,
                isNewRegion: true,
            };

            onNewRegion(newRegion);

            this.setState({ contextMenuShow: false });
        };
    };

    regionFlipToBack = (regionId) => {
        this.setState(
            (prevState) => ({
                regionIdsOnBack: [
                    ...new Set([...prevState.regionIdsOnBack.filter((id) => id !== regionId), regionId]),
                ],
            }),
            () => {
                this.props.setSelectedRegion(null);
            }
        );
    };

    onPixelToMeterMove = (event) => {
        //noinspection JSUnresolvedFunction
        const bound = this.refs['mapImage1'].getBoundingClientRect();
        const contextMenuOffsetX =
                bound.left +
                window.pageXOffset -
                document.documentElement.clientLeft -
                document.documentElement.scrollLeft,
            contextMenuOffsetY =
                bound.top +
                window.pageYOffset -
                document.documentElement.clientTop -
                document.documentElement.scrollTop;
        const { state } = this;
        const { setPixelToMeter } = state,
            { stageSize1 } = state,
            stageScale = stageSize1.scale;
        setPixelToMeter.endX = (event.pageX - contextMenuOffsetX) / stageScale;
        setPixelToMeter.endY = (event.pageY - contextMenuOffsetY) / stageScale;

        setPixelToMeter.endX =
            setPixelToMeter.endX > stageSize1.width ? stageSize1.width : setPixelToMeter.endX;
        setPixelToMeter.endY =
            setPixelToMeter.endY > stageSize1.height ? stageSize1.height : setPixelToMeter.endY;
        setPixelToMeter.endX = setPixelToMeter.endX < 0 ? 0 : setPixelToMeter.endX;
        setPixelToMeter.endY = setPixelToMeter.endY < 0 ? 0 : setPixelToMeter.endY;

        //Pythagoras
        let a = setPixelToMeter.endX - setPixelToMeter.startX;
        let b = setPixelToMeter.endY - setPixelToMeter.startY;
        //noinspection UnnecessaryLocalVariableJS
        let c = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));

        setPixelToMeter.numOfPixels = c;

        this.setState({ setPixelToMeter: setPixelToMeter });
    };

    handleOnClick = () => {
        if (!this.resizing && this.pixelToMeterInProgress) {
            window.removeEventListener('mousemove', this.onPixelToMeterMove);
            this.pixelToMeterInProgress = false;

            this.setState({ pixelToMeterShowDialog: true });
        }
    };
    startPixelToMeter = () => {
        const { state, props } = this;
        const { selectedMapData } = props;
        const { contextMenuPageX, contextMenuPageY, contextMenuOffsetX, contextMenuOffsetY, stageSize1 } =
                state,
            stageScale = stageSize1.scale,
            x = (contextMenuPageX - contextMenuOffsetX) / stageScale,
            y = (contextMenuPageY - contextMenuOffsetY) / stageScale;

        window.addEventListener('mousemove', this.onPixelToMeterMove);

        this.setState({
            setPixelToMeter: {
                startX: x,
                startY: y,
                endX: x + 100,
                endY: y + 100,
                newPixelToMeter: selectedMapData.pixelToMeter,
            },
            contextMenuShow: false,
        });

        this.pixelToMeterInProgress = true;
    };

    onPixelToMeterCancel = () => {
        this.setState({ pixelToMeterShowDialog: false, setPixelToMeter: undefined });
    };

    handleOnChangeTextField = (event) => {
        const { state } = this;
        let { setPixelToMeter } = state;

        setPixelToMeter.newPixelToMeter = setPixelToMeter.numOfPixels / event.currentTarget.value;
        this.setState({});
    };

    onPixelToMeterSave = () => {
        const { state, props } = this;
        let { selectedMapData, onMapDataSave } = props;
        let { setPixelToMeter } = state;

        selectedMapData.pixelToMeter = setPixelToMeter.newPixelToMeter;
        onMapDataSave(selectedMapData);
        this.onPixelToMeterCancel();
    };

    getScale = () => {
        const parent = document.getElementById('component.MapViewer');
        if (!parent) return;
        const { props, state } = this;
        const { selectedMapData } = props;
        const pixelToMeter = (selectedMapData && selectedMapData.pixelToMeter) || 1;
        //noinspection JSUnresolvedFunction
        const bound = parent.getBoundingClientRect();
        let textLength = 65;
        let x2 = bound.width - 50;
        let y2 = bound.height - 50;

        let l = ~~(bound.width / 5);

        let x1 = x2 - l;
        let y1 = y2;

        //noinspection HtmlUnknownAttribute
        return [
            <svg key={1} transform={`translate(${x1 - textLength},${y1})`} width={l + textLength} height={20}>
                <marker
                    id="vLine"
                    markerWidth="10"
                    markerHeight="10"
                    refX="0"
                    refY="5"
                    orient="auto"
                    markerUnits="strokeWidth"
                >
                    <rect x={0} y={0} width={1} height={10} fill="#000" />
                </marker>
                <line
                    key="scaleLine"
                    x1={textLength}
                    y1={10}
                    x2={l - 2 + textLength}
                    y2={10}
                    stroke="black"
                    strokeWidth={2}
                    markerStart="url(#vLine)"
                    markerEnd="url(#vLine)"
                ></line>
                ,
                <text
                    key="scaleText"
                    x={0}
                    y={10}
                    dy="5"
                    strokeWidth={2}
                    fill="black"
                    fontWeight="bold"
                    textAnchor="right"
                >
                    {Number(l / state.stageSize1.scale / pixelToMeter).toFixed(2)}M
                </text>
            </svg>,
        ];
    };

    render() {
        const { state, props, onPixelToMeterCancel, onPixelToMeterSave, setStageSize } = this;
        const {
            selectedMapData,
            onlineClients,
            additionalMapData,
            disableContextMenu,
            onClientDelete,
            additionalMapOpacity,
            className,
            acceptedAccuracy,
            mapImage,
        } = props;

        const defaultMapImage = mapImage || getDefaultMapImage(selectedMapData.mapImages);
        const { setPixelToMeter, pixelToMeterShowDialog } = state;
        const pixelToMeter = (selectedMapData && selectedMapData.pixelToMeter) || 1;

        let { stageSize1, stageSize2 } = state;
        if (!additionalMapData) {
            stageSize2 = {
                name: 'stageSize2',
                width: 0,
                height: 0,
                top: 4,
                left: 4,
                scale: 1,
            };
        }

        // if (!selectedRegion) {
        //     Region.selected = false;
        // }

        let actions = [
            <FlatButton
                label="Cancel"
                primary={false}
                keyboardFocused={false}
                labelStyle={{ color: '#ff6600' }}
                onClick={onPixelToMeterCancel}
            />,
            <FlatButton
                label="Save"
                primary={true}
                keyboardFocused={true}
                labelStyle={{ color: '#ff6600' }}
                onClick={onPixelToMeterSave}
            />,
        ];

        //noinspection HtmlUnknownAttribute
        return (
            <Paper
                zDepth={2}
                id="component.MapViewer"
                style={{
                    ...paperStyle,
                    display: 'flex',
                    flexGrow: 1,
                    height: '100%',
                    position: 'relative',
                    overflow: 'hidden',
                    outline: '0px solid #ff6600',
                }}
                className={`component MapViewer ${className ? className : ''}`}
                onDragStart={(e) => {
                    e.preventDefault();
                    return false;
                }}
                onClick={this.setPixelToMeter}
            >
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'flex',
                        flexGrow: 1,
                    }}
                    onMouseDown={this.initialiseResize(stageSize1)}
                >
                    <svg
                        ref="mapImage1"
                        style={{ overflow: 'hidden', position: 'absolute' }}
                        width={stageSize1.width * stageSize1.scale}
                        height={stageSize1.height * stageSize1.scale}
                        transform={`translate(${stageSize1.left},${stageSize1.top})`}
                        onContextMenu={disableContextMenu ? () => {} : this.handleOnContextMenu}
                        onClick={disableContextMenu ? () => {} : this.handleOnClick}
                        onMouseMove={props.updateMouseCoordinates ? this.handleMouseMove : null}
                    >
                        <marker
                            id="arrow"
                            markerWidth="10"
                            markerHeight="10"
                            refX="10"
                            refY="5"
                            orient="auto"
                            markerUnits="strokeWidth"
                        >
                            <path d="M0,0 L0,10 L10,5 z" fill="#000" />
                        </marker>
                        {defaultMapImage && selectedMapData && selectedMapData.mapOffset && (
                            <MapImage
                                mapImgBase64={
                                    selectedMapData
                                        ? selectedMapData['imgBase64'] || defaultMapImage.mapUrl
                                        : null
                                }
                                mapId={
                                    selectedMapData
                                        ? selectedMapData.mapId
                                            ? selectedMapData.mapId
                                            : 'newMapHasNoId'
                                        : null
                                }
                                stageSize={stageSize1}
                                setStageSize={setStageSize}
                                borderColor="#ff6600"
                                opacity={1}
                                isBase64={!!selectedMapData['imgBase64']}
                                setImageScale={this.setImageScale}
                            />
                        )}

                        {onlineClients
                            ? Object.keys(onlineClients).map((clientSessionId) => {
                                  return (
                                      <OnlineClient
                                          key={clientSessionId}
                                          clientSessionId={clientSessionId}
                                          color={onlineClients[clientSessionId].color}
                                          cursorList={onlineClients}
                                          position={onlineClients[clientSessionId]}
                                          stageSize={stageSize1}
                                          pixelToMeter={pixelToMeter || null}
                                          onClientDelete={onClientDelete}
                                          showLocationAccuracy={props.showLocationAccuracy}
                                          mapOffset={
                                              selectedMapData ? selectedMapData.mapOffset : { x: 0, y: 0 }
                                          }
                                          acceptedAccuracy={acceptedAccuracy}
                                          parent={this}
                                      />
                                  );
                              })
                            : null}

                        {setPixelToMeter ? (
                            <circle
                                fill={'black'}
                                opacity="0.75"
                                cx={setPixelToMeter.startX * stageSize1.scale}
                                cy={setPixelToMeter.startY * stageSize1.scale}
                                r={5}
                            ></circle>
                        ) : null}
                        {setPixelToMeter ? (
                            <line
                                x1={setPixelToMeter.startX * stageSize1.scale}
                                y1={setPixelToMeter.startY * stageSize1.scale}
                                x2={setPixelToMeter.endX * stageSize1.scale}
                                y2={setPixelToMeter.endY * stageSize1.scale}
                                stroke="black"
                                marker-end="url(#arrow)"
                            />
                        ) : null}
                    </svg>
                    <svg
                        id="mapImage2"
                        style={{ overflow: 'hidden', position: 'absolute' }}
                        width={stageSize2.width * stageSize2.scale}
                        height={stageSize2.height * stageSize2.scale}
                        transform={`translate(${stageSize2.left},${stageSize2.top})`}
                        onClick={disableContextMenu ? () => {} : this.handleOnClick}
                        onMouseDown={this.initialiseResize(stageSize2)}
                    >
                        <marker
                            id="arrow"
                            markerWidth="10"
                            markerHeight="10"
                            refX="10"
                            refY="5"
                            orient="auto"
                            markerUnits="strokeWidth"
                        >
                            <path d="M0,0 L0,10 L10,5 z" fill="#000" />
                        </marker>
                        {/*<MapImage*/}
                        {/*mapImgBase64={selectedMapData ? selectedMapData['imgBase64'] : null}*/}
                        {/*mapId={selectedMapData ? selectedMapData.mapId : null}*/}
                        {/*stageSize={stageSize2}*/}
                        {/*setImageScale={this.setImageScale}/>*/}
                        <MapImage
                            mapImgBase64={
                                additionalMapData
                                    ? additionalMapData['imgBase64'] || additionalMapData['mapUrl']
                                    : null
                            }
                            mapId={'additionalMap_' + (additionalMapData ? additionalMapData.mapId : '')}
                            stageSize={stageSize2}
                            setStageSize={setStageSize}
                            opacity={additionalMapOpacity}
                            borderColor="#535353"
                            isResizable={true}
                            isBase64={!!additionalMapData?.['imgBase64']}
                            setImageScale={this.setImageScale}
                        />
                    </svg>
                    {this.getScale()}
                </div>

                <ContextMenu
                    id="simple-menu"
                    anchorEl={state.anchorEl}
                    open={Boolean(state.contextMenuShow)}
                    onClose={this.handleContextMenuClose}
                >
                    {state.contextMenuItems}
                </ContextMenu>
                {setPixelToMeter ? (
                    <Dialog
                        title="Pixel To Meter"
                        actions={actions}
                        modal={true}
                        open={pixelToMeterShowDialog}
                        // onRequestClose={this.handleClose}
                        autoScrollBodyContent={true}
                    >
                        <div>
                            <TextField
                                type="number"
                                floatingLabelText="Number Of Meters"
                                defaultValue={setPixelToMeter.numOfPixels / setPixelToMeter.newPixelToMeter}
                                floatingLabelStyle={textFieldStyle.floatingLabelStyle}
                                underlineFocusStyle={textFieldStyle.underlineFocusStyle}
                                onChange={this.handleOnChangeTextField}
                            />
                        </div>
                    </Dialog>
                ) : null}
            </Paper>
        );
    }
}

MapViewer.propTypes = {
    onZoomChanged: PropTypes.func,
};

MapViewer.defaultProps = {
    onZoomChanged: () => {},
};

export default MapViewer;
