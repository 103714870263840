import log from '../../utils/logger';
import { isMatch } from 'micromatch';
import { store } from '../../state-management/store';
import { showErrorNotification } from '../../state-management/notification/notificationReducer';
import {
    clientPositionUpdated,
    loggedIn,
    monitoringMap,
} from '../../state-management/monitor/monitorEventsActions';

const defaultTopicHandlers = {
    loginAck: (ws) => {
        store.dispatch(loggedIn());

        // Send a message to monitor the provided mapId
        ws.send({ type: 'monitorMap', mapId: ws.mapId });
    },

    respMonitorMap: (ws, data) => {
        store.dispatch(monitoringMap({ parameters: {}, data: { mapId: ws.mapId } }));
    },

    positionBroadcast: (ws, data) => {
        store.dispatch(
            clientPositionUpdated({
                parameters: {},
                data: { ...data, clientId: data.sessionId, clientName: data.clientId },
            })
        );
    },

    positionUpdate: (ws, data) => {
        store.dispatch(
            clientPositionUpdated({
                parameters: {},
                data: { ...data, clientId: data.sessionId, clientName: data.clientId },
            })
        );
    },
};

class EventsManagerMessageHandler {
    constructor() {
        this.topicHandlers = Object.entries(defaultTopicHandlers).reduce(
            (result, [topic, handler]) => ({
                ...result,
                [topic]: { handler, options: { handleOwnMessages: false } },
            }),
            {}
        );
    }

    addEventHandler(eventTopic, handler, { handleOwnMessages = false } = {}) {
        this.topicHandlers[eventTopic] = { handler, options: { handleOwnMessages } };
    }

    async handleMessage(ws, message) {
        const { error, reason, type: messageTopic, ...data } = message;

        if (error) {
            store.dispatch(showErrorNotification(reason));
            return;
        }

        const handlers = Object.entries(this.topicHandlers);

        log.debug(
            `Received event message from RT server, messageTopic: ${messageTopic}, data: ${JSON.stringify(
                data
            )}`
        );

        for (const [topic, { handler }] of handlers) {
            const matches = isMatch(messageTopic, topic);

            if (matches) {
                try {
                    await handler(ws, data);
                } catch (e) {
                    log.error(e);
                }
            }
        }
    }
}

export default new EventsManagerMessageHandler();
