import { createAsyncThunk } from '../utils';
import entrancesApi from '../../api/EntrancesApi';

export const fetchAllEntrancesInBuilding = createAsyncThunk(
    'entrances/fetchAllInBuilding',
    async ({ spaceId, buildingId }) => {
        const { entrances, floors } = (await entrancesApi.fetchAllEntrancesInBuilding(buildingId)).data;
        return {
            spaceId,
            entrances,
            floors: floors.map(({ floorId, entranceIds }) => ({ floorId, entranceIds })),
        };
    }
);

export const createEntrance = createAsyncThunk(
    'entrances/create',
    async ({ buildingId, floorId, entranceData }) => {
        return (await entrancesApi.createEntrance(buildingId, floorId, entranceData)).data;
    }
);

export const updateEntrance = createAsyncThunk(
    'entrances/update',
    async ({ buildingId, floorId, entranceId, entranceData }) => {
        return (await entrancesApi.updateEntrance(buildingId, floorId, entranceId, entranceData)).data;
    }
);

export const deleteEntrance = createAsyncThunk(
    'entrances/delete',
    async ({ buildingId, floorId, entranceId }) => {
        return (await entrancesApi.deleteEntrance(buildingId, floorId, entranceId)).data;
    }
);
