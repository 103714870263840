import settings from '../../clientSettings';
import log from '../../utils/logger';
import eventsHandler from './realTimeServerMessageHandler';
import { store } from '../../state-management/store';
import { selectUserApiKeyId } from '../../state-management/auth/authSelectors';

const { realTimeServerUrl } = settings;

class RealTimeServerWebSocket {
    constructor() {
        this.ws = null;
        this.reconnectOnClose = false;
        this.mapId = null;
    }

    getReadyState() {
        return this.ws?.readyState ?? WebSocket.CLOSED;
    }

    async connect({ mapId = null, reconnectOnClose = false } = {}) {
        return new Promise((resolve, reject) => {
            this.ws = new WebSocket(realTimeServerUrl);
            this.reconnectOnClose = reconnectOnClose;
            this.mapId = mapId;

            this.ws.onopen = () => {
                if (this.onReadyStateChange) {
                    this.onReadyStateChange(this.ws.readyState);
                }

                log.info(`Successfully connected to the RT server at: ${realTimeServerUrl}`);

                if (mapId) {
                    const state = store.getState();
                    const apiKeyId = selectUserApiKeyId(state);

                    // Send a login message
                    this.send({
                        type: 'login',
                        appId: apiKeyId,
                        userId: 'monitor',
                        deviceId: 'monitorPseudoDeviceId' + Math.floor(Math.random() * 1000000 + 1),
                        isMonitor: true
                    });
                }
            };

            this.ws.onclose = (code, reason) => {
                if (this.onReadyStateChange) {
                    this.onReadyStateChange(this.ws.readyState);
                }

                log.warn(`Lost connection to RT server: ${code} ${reason}`);

                if (reconnectOnClose) {
                    this.reconnect();
                }
            };

            this.ws.onerror = (error) => {
                if (this.onReadyStateChange) {
                    this.onReadyStateChange(this.ws.readyState);
                }

                log.error(error.message);
                reject(error);
            };

            this.ws.onmessage = async (message) => {
                try {
                    const jsonMessage = JSON.parse(message?.data);
                    await eventsHandler.handleMessage(this, jsonMessage);
                } catch (e) {
                    console.error(`Failed to parse message: ${message}`);
                }
            };
        });
    }

    disconnect() {
        if (this.ws?.readyState === WebSocket.OPEN || this.ws?.readyState === WebSocket.CONNECTING) {
            this.reconnectOnClose = false;
            this.ws.close();
        }
    }

    async reconnect() {
        let i;
        const maxTries = 300;
        const sleep = async (ms) => await new Promise((resolve) => setTimeout(resolve, ms));

        for (i = 1; i <= maxTries; i++) {
            try {
                await sleep(2000);

                log.info(`Attempt #${i} to re-connect...`);
                await this.connect();

                break;
            } catch (e) {
                log.error(`Re-connection attempt #${i} failed: ${e}`);
            }
        }

        if (i === maxTries) {
            throw new Error(`Failed to connect to the RT server websocket`);
        }
    }

    async send(message) {
        log.debug(`Sending message to RT server: ${JSON.stringify(message)}`);
        this.ws.send(JSON.stringify(message));
    }

    addEventHandler(eventTopic, handler, { handleOwnMessages = false } = {}) {
        eventsHandler.addEventHandler(eventTopic, handler, { handleOwnMessages });
    }

    setOnReadyStateChange(onReadyStateChange) {
        this.onReadyStateChange = onReadyStateChange;
    }
}

export default new RealTimeServerWebSocket();
