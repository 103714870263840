export const getMapViewerAreasOfInterestOverlayId = () => `map-viewer-aoi-overlay`;
export const getMapViewerAreaOfInterestId = (areaId) => `map-viewer-aoi-overlay-aoi-${areaId}`;

export const getAreaOfInterestTopRightResizeBulletId = (areaId) =>
    `map-viewer-aoi-${areaId}-top-right-resize-bullet`;
export const getAreaOfInterestMiddleRightResizeBulletId = (areaId) =>
    `map-viewer-aoi-${areaId}-middle-right-resize-bullet`;
export const getAreaOfInterestBottomRightResizeBulletId = (areaId) =>
    `map-viewer-aoi-${areaId}-bottom-right-resize-bullet`;
export const getAreaOfInterestTopLeftResizeBulletId = (areaId) =>
    `map-viewer-aoi-${areaId}-top-left-resize-bullet`;
export const getAreaOfInterestMiddleLeftResizeBulletId = (areaId) =>
    `map-viewer-aoi-${areaId}-middle-left-resize-bullet`;
export const getAreaOfInterestBottomLeftResizeBulletId = (areaId) =>
    `map-viewer-aoi-${areaId}-bottom-left-resize-bullet`;
export const getAreaOfInterestTopCenterResizeBulletId = (areaId) =>
    `map-viewer-aoi-${areaId}-top-center-resize-bullet`;
export const getAreaOfInterestBottomCenterResizeBulletId = (areaId) =>
    `map-viewer-aoi-${areaId}-bottom-center-resize-bullet`;
