import moment from 'moment';
import TimePeriod from './TimePeriod';

class LastFourWeeks extends TimePeriod {
    get label() {
        return 'Last 4 Weeks';
    }

    get value() {
        return 'LAST_FOUR_WEEKS';
    }

    getTimeSpan() {
        return {
            start: moment()
                .subtract(4, 'week')
                .startOf('isoWeek')
                .toISOString(),
            end: moment()
                .subtract(1, 'week')
                .endOf('isoWeek')
                .toISOString(),
        };
    }

    get isPickable() {
        return false;
    }

    get isRange() {
        return true;
    }
}

export default new LastFourWeeks();
