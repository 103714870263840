import { FormControlLabel, IconButton, Portal } from '@material-ui/core';
import styled from 'styled-components';
import { AiOutlineClose as CloseIcon } from 'react-icons/ai';
import { useContext } from 'react';
import { CoordinatesConversionTable } from './CoordinatesConversionTable';
import { MapViewerContext } from '../../../common/map-viewer/MapViewerContext';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectBuildingsSelectedBuildingId,
    selectBuildingsSelectedFloorId,
    selectBuildingsSelectedMapId,
    setIsCoordinatesConverstionOpen,
} from '../../../../state-management/user-inputs/buildingsSlice';
import { Button, Checkbox, PulsePreloader } from '../../../common/themed';
import { clearAllCoordinates } from '../../../../state-management/coordinates-conversion/coordinatesConversionReducer';
import { useCoordinatesConversion } from '../../../common/hooks/data-fetching/useCoordinatesConversions';

import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdSave as SaveIcon } from 'react-icons/md';
import {
    calculateCoordinatesConversion,
    saveCoordinatesConversion,
} from '../../../../state-management/coordinates-conversion/coordinatesConversionActions';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { useEffect } from 'react';

const Container = styled.form`
    display: flex;
    flex-direction: column;
    place-content: space-between;
    position: absolute;
    top: 80px;
    left: 20px;
    width: 480px;
    height: calc(100% - 150px);
    border-radius: 5px;
    border: 1px solid #eee;
    padding: 8px;
    & > *:not(:last-child) {
        margin-bottom: 10px;
    }
    background: white;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
`;

const Row = styled.div`
    display: flex;
    ${(props) => props.space && `place-content:space-between;`}
    ${(props) => props.end && `place-content:end;`}
    ${(props) => props.padding && `padding:${props.padding};`}
    & > *:not(:last-child) {
        margin-right: 10px;
    }
`;
const Col = styled.div`
    display: flex;
    flex-direction: column;
    & label span:last-child {
        text-transform: uppercase;
        color: #9c9999;
        font-size: 14px;
    }
    & > *:not(:last-child) {
        margin-bottom: 10px;
        ${(props) => props.noSpace && 'margin-bottom:0;'}
    }
`;

const Title = styled.span`
    text-transform: uppercase;
    color: #ff6200;
    font-size: 15px;
    padding: 0 17px;
`;

const TableWrapper = styled(Col)`
    max-height: 70vh;
    overflow-y: auto;
`;
export function CoordinatesConversionDrawer() {
    const dispatch = useDispatch();

    const { containerRef, handleWheel } = useContext(MapViewerContext);
    const mapId = useSelector(selectBuildingsSelectedMapId);
    const buildingId = useSelector(selectBuildingsSelectedBuildingId);
    const floorId = useSelector(selectBuildingsSelectedFloorId);
    const {
        selectedCoordinates,
        data: coordinates,
        translation,
        scale,
        rotation,
    } = useCoordinatesConversion(mapId);
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: {
            separateXY: false,
            rotationRequired: false,
        },
    });
    const { separateXY, rotationRequired } = watch();
    const isHover = useRef(false);

    const onSave = async () => {
        const result = await dispatch(
            saveCoordinatesConversion({
                buildingId,
                floorId,
                mapId,
                data: {
                    translation,
                    rotation,
                    scale,
                },
            })
        );
        dispatch(clearAllCoordinates({ mapId }));
        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Coordinates Saved successfully.'));
        } else {
            dispatch(showErrorNotification('Could not save coordinates.'));
        }
        dispatch(setIsCoordinatesConverstionOpen(false));
    };

    const onCalculate = async (data) => {
        const result = await dispatch(
            calculateCoordinatesConversion({
                buildingId,
                floorId,
                data: {
                    ...data,
                    points: coordinates
                        .filter((c) => selectedCoordinates.includes(c.coordinateId))
                        .map((c) => ({
                            id: c.coordinateId,
                            oriient: c.oriient,
                            customer: c.customer,
                        })),
                },
            })
        );
        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Coordinates calculated successfully.'));
        } else {
            dispatch(showErrorNotification('Could not calculate coordinates.'));
        }
    };
    const handleHover = () => {
        const ref = containerRef.current;
        if (ref && !isHover.current) {
            isHover.current = true;
            ref.removeEventListener('wheel', handleWheel);
        }
    };
    const handleLeave = () => {
        const ref = containerRef.current;
        if (ref && isHover.current) {
            isHover.current = false;
            ref.addEventListener('wheel', handleWheel);
        }
    };
    useEffect(() => {
        if (separateXY) {
            setValue('rotationRequired', false);
        }
    }, [separateXY]);
    useEffect(() => {
        if (rotationRequired) {
            setValue('separateXY', false);
        }
    }, [rotationRequired]);
    return (
        <Portal container={containerRef.current}>
            <Container
                onMouseDown={(e) => e.stopPropagation()}
                onPointerEnter={handleHover}
                onPointerLeave={handleLeave}
                onSubmit={handleSubmit(onCalculate)}
            >
                <TableWrapper>
                    <Col>
                        <Row end>
                            <IconButton onClick={() => dispatch(setIsCoordinatesConverstionOpen(false))}>
                                <CloseIcon fontSize={16}></CloseIcon>
                            </IconButton>
                        </Row>

                        <CoordinatesConversionTable />
                        <Row end padding={'0 6px'}>
                            <Button onClick={() => dispatch(clearAllCoordinates({ mapId }))} variant="text">
                                clear all
                            </Button>
                            <Button type="submit" disabled={selectedCoordinates.length < 3}>
                                Calculate
                            </Button>
                        </Row>
                    </Col>
                    <Col>
                        <Title>Settings</Title>
                        <Controller
                            name="separateXY"
                            control={control}
                            defaultValue={false}
                            render={({ onChange, value }) => (
                                <FormControlLabel
                                    style={{ margin: 0 }}
                                    control={
                                        <Checkbox
                                            checked={value}
                                            onChange={(e) => onChange(e.target.checked)}
                                        />
                                    }
                                    label="Separate x/y"
                                />
                            )}
                        />
                        <Controller
                            name="rotationRequired"
                            control={control}
                            defaultValue={false}
                            render={({ onChange, value }) => (
                                <FormControlLabel
                                    style={{ margin: 0 }}
                                    control={
                                        <Checkbox
                                            checked={value}
                                            onChange={(e) => onChange(e.target.checked)}
                                        />
                                    }
                                    label="Rotation required"
                                />
                            )}
                        />
                    </Col>
                </TableWrapper>
                {isSubmitting ? (
                    <PulsePreloader />
                ) : (
                    <Button onClick={onSave}>
                        <SaveIcon size={20} />
                        Save
                    </Button>
                )}
            </Container>
        </Portal>
    );
}
