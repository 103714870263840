import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './productLocationsEntityAdapter';
import {
    fetchExternalProductLocationsData,
    fetchInternalProductLocationsData,
    fetchRecordsCount,
    getSpaceConfig,
    updateSpaceConfig,
} from './productLocationsActions';

const { getInitialState, setAll, upsertOne } = entityAdapter;

export const { reducer } = createSlice({
    name: 'productLocations',
    initialState: getInitialState({ recordsCount: 0 }),
    reducers: {},
    extraReducers: {
        [fetchExternalProductLocationsData.fulfilled]: (state, action) => {
            setAll(state, action.payload);
        },
        [fetchInternalProductLocationsData.fulfilled]: (state, action) => {
            const modifiedData = action.payload.map((row) =>
                Object.assign(modifyNumbersToFixed(row), {
                    buildingId: row.buildingId || 'N/A',
                    buildingName: row.buildingName || 'N/A',
                })
            );
            setAll(state, modifiedData);
        },
        [fetchRecordsCount.fulfilled]: (state, action) => {
            state.recordsCount = action.payload.count;
        },
        [getSpaceConfig.fulfilled]: (state, action) => {
            state.spaceConfig = action.payload;
        },
        [updateSpaceConfig.fulfilled]: (state, action) => {
            state.spaceConfig = action.payload;
        }
    },
});

export function modifyNumbersToFixed(row) {
    return Object.keys(row).reduce((result, val) => {
        const value = +row[val];
        if (!isNaN(value)) {
            result[val] = value % 1 !== 0 ? value.toFixed(2) : value;
        } else {
            result[val] = row[val];
        }

        return result;
    }, {});
}
