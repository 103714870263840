import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { MapViewerContext } from '../../MapViewerContext';
import { Portal } from '@material-ui/core';
import { CircularPreloader } from '../../../themed';
import { useDispatch } from 'react-redux';
import {
    getCircularPlacementCreationInstructionSnackbarId,
    getCircularPlacementNewCreationId,
    getMapViewerCircularPlacementCreationOverlayId,
} from './MapViewerRegionInternalCircularPlacementCreationOverlay.selectors';
import {
    BOUNDARY_MAX_X,
    BOUNDARY_MAX_Y,
    BOUNDARY_MIN_X,
    BOUNDARY_MIN_Y,
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../../state-management/notification/notificationReducer';
import { isFulfilled } from '../../../../../state-management/utils';
import {
    InstructionSnackbar,
    InstructionSnackbarButton,
    InstructionSnackbarTextField,
    InstructionText,
} from '../../common/InstructionSnackbar';
import { createRegion } from '../../../../../state-management/region/regionActions';
import { createRegionInternalPlacement } from '../../../../../state-management/region-placement/regionPlacementActions';
import { unwrapResult } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

const minRadius = 0.1;

const CreationSpace = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
`;

const Circle = styled.circle`
    fill: #00d0ff; // TODO color should come from theme
    fill-opacity: 0.4;
    stroke: #f53700;
    stroke-width: calc(1.5px / var(${SCALE}));
`;

const INITIAL = 'INITIAL';
const CENTER_SET = 'CENTER_SET';
const RADIUS_SET = 'RADIUS_SET';

export default function MapViewerRegionInternalCircularPlacementCreationOverlay(props) {
    const { spaceId, mapId, onSubmitted, onCancel } = props;

    const { imageRef, containerRef, getCSSVariable } = useContext(MapViewerContext);

    const dispatch = useDispatch();

    const [isCreating, setIsCreating] = useState(false);
    const [{ creationStep, center, radius, regionName }, setCreation] = useImmer({
        creationStep: INITIAL,
        center: null,
        radius: null,
        regionName: `newRegion_${uuid().substring(0, 7)}`,
    });

    const handleNameChange = (event) => {
        const { value } = event.target;
        setCreation((state) => {
            state.regionName = value?.trim() === '' ? null : value;
        });
    };

    const handleReset = (event) => {
        event.stopPropagation();
        setCreation((state) => {
            state.creationStep = INITIAL;
            state.center = null;
            state.radius = null;
            state.regionName = `newRegion_${uuid().substring(0, 7)}`;
        });
    };

    const handleClick = (event) => {
        const { clientX, clientY } = event;
        const refRect = imageRef.current.getBoundingClientRect();
        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const scale = parseFloat(getCSSVariable(SCALE));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        if (creationStep === INITIAL) {
            setCreation((state) => {
                state.creationStep = CENTER_SET;
                state.center = {
                    x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                    y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
                };
                state.radius = minRadius;
            });
        } else if (creationStep === CENTER_SET) {
            setCreation((state) => {
                state.creationStep = RADIUS_SET;
            });
        }
    };

    const handlePointerMove = useCallback(
        (event) => {
            const { clientX, clientY } = event;

            const refRect = imageRef.current.getBoundingClientRect();
            const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
            const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
            const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));
            const maxX = parseFloat(getCSSVariable(BOUNDARY_MAX_X));
            const minX = parseFloat(getCSSVariable(BOUNDARY_MIN_X));
            const maxY = parseFloat(getCSSVariable(BOUNDARY_MAX_Y));
            const minY = parseFloat(getCSSVariable(BOUNDARY_MIN_Y));

            // Calculate new radius of the circle based on the pointer position
            // Limit resizing so that it will maintain minimum height/width
            setCreation((state) => {
                const pointerX = (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX;
                const pointerY =
                    (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY;

                const xDifference = Math.abs(state.center.x - pointerX);
                const yDifference = Math.abs(state.center.y - pointerY);

                const newRadius = Math.sqrt(Math.pow(xDifference, 2) + Math.pow(yDifference, 2));

                state.radius = Math.min(
                    Math.max(newRadius, minRadius),
                    maxX - state.center.x,
                    state.center.x - minX,
                    maxY - state.center.y,
                    state.center.y - minY
                );
            });
        },
        [getCSSVariable, imageRef, setCreation]
    );

    const handleCancel = (event) => {
        event.stopPropagation();
        onCancel();
    };

    const handleSubmit = async () => {
        let result;

        setIsCreating(true);

        result = await dispatch(createRegion({ spaceId, regionData: { regionName, regionMetadata: '' } }));

        if (isFulfilled(result)) {
            const { regionId } = unwrapResult(result);

            result = await dispatch(
                createRegionInternalPlacement({
                    regionId,
                    mapId,
                    placementData: { regionType: 'circular', center: [center.x, center.y], radius },
                })
            );

            if (isFulfilled(result)) {
                dispatch(showSuccessNotification('Region created successfully'));
            } else {
                dispatch(showErrorNotification(`Failed to create region`));
            }
        } else {
            dispatch(showErrorNotification(`Failed to create region`));
        }

        setIsCreating(false);
        onSubmitted();
    };

    useEffect(() => {
        if (creationStep === CENTER_SET) {
            window.addEventListener('pointermove', handlePointerMove);

            return () => {
                window.removeEventListener('pointermove', handlePointerMove);
            };
        }
    }, [creationStep, handlePointerMove]);

    const { x, y, r } = useMemo(() => {
        if (!center || !radius) {
            return { x: 0, y: 0, r: 0 };
        }

        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        return {
            x: (center.x - mapOffsetX) * pixelToMeter,
            y: (imageNaturalHeight / pixelToMeter - (center.y - mapOffsetY)) * pixelToMeter,
            r: radius * pixelToMeter,
        };
    }, [center, radius, getCSSVariable]);

    const instructions = {
        [INITIAL]: (
            <InstructionText>
                Click anywhere on the map to set the center point of the region.
            </InstructionText>
        ),
        [CENTER_SET]: (
            <InstructionText>
                Move the mouse to define the radius of the region. Click once more to set the radius.
            </InstructionText>
        ),
        [RADIUS_SET]: (
            <>
                <InstructionText>Provide a name for this region:</InstructionText>
                <InstructionSnackbarTextField
                    onChange={handleNameChange}
                    autoComplete={'regionName'}
                    defaultValue={regionName}
                />
            </>
        ),
    };

    return (
        <CreationSpace id={getMapViewerCircularPlacementCreationOverlayId()} onClick={handleClick}>
            <Portal container={containerRef.current}>
                <InstructionSnackbar
                    id={getCircularPlacementCreationInstructionSnackbarId()}
                    open
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClick={(e) => e.stopPropagation()}
                    message={instructions[creationStep]}
                    action={
                        <>
                            <InstructionSnackbarButton variant={'text'} onClick={handleCancel}>
                                Cancel
                            </InstructionSnackbarButton>

                            {creationStep !== INITIAL && (
                                <InstructionSnackbarButton variant={'text'} onClick={handleReset}>
                                    Reset
                                </InstructionSnackbarButton>
                            )}

                            {creationStep === RADIUS_SET &&
                                (isCreating ? (
                                    <CircularPreloader />
                                ) : (
                                    <InstructionSnackbarButton variant={'text'} onClick={handleSubmit}>
                                        {regionName ? 'Submit' : 'Skip'}
                                    </InstructionSnackbarButton>
                                ))}
                        </>
                    }
                />
            </Portal>

            {center && radius && <Circle id={getCircularPlacementNewCreationId()} cx={x} cy={y} r={r} />}
        </CreationSpace>
    );
}

MapViewerRegionInternalCircularPlacementCreationOverlay.propTypes = {
    spaceId: PropTypes.string.isRequired,
    mapId: PropTypes.string.isRequired,
    onSubmitted: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
