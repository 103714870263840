export const MapUsages = ['navgraph', 'obstacles'];

export const getDefaultMapImage = (mapImages) => {
    if (mapImages && Array.isArray(mapImages)) {
        return mapImages.find((mapImage) => !MapUsages.includes(mapImage.mapUsage));
    }
};

export const getImagesWithoutDefault = (mapImages, mapImagesChecked) => {
    if (mapImages && Array.isArray(mapImages)) {
        return mapImages
            .filter((mapImage) => MapUsages.includes(mapImage.mapUsage))
            .map((mapImage) => {
                return { ...mapImage, isChecked: mapImagesChecked?.[mapImage.imageId] || false };
            });
    }
};
