import {FiCopy} from 'react-icons/fi'
import styled from "styled-components/macro";
import Tooltip from "./Tooltip";

const Wrapper = styled.div`
    &:hover .hide {
        display: block;
    }
    display: flex;
    align-items: center;
    position: relative;
`

const CopyIcon = styled(FiCopy).attrs({ className: 'hide'})`
&.hide {
    display: none;
}
cursor: pointer;
position: absolute;
transform: translateX(calc(100% + 25px));
&:hover {
    color: #ff6200;
}
&:active {
    background-color: lightgray;
}
`

export function CustomCellComponent({tooltip = true, text, tooltipContent = text,textStyle = {}, wrapperStyle = {}, copyIcon = false }){
    const renderContent = (
        <Wrapper style={wrapperStyle}>
            <span style={textStyle}>{text}</span>
            {copyIcon && <CopyIcon onClick={(event) => {
                navigator.clipboard.writeText(text);
                event.stopPropagation();
            }}></CopyIcon>}
        </Wrapper>
    );

        if (tooltip) return <Tooltip content={tooltipContent}>{renderContent}</Tooltip>;

        return renderContent
}
