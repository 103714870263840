import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Drawer as MuiDrawer,
    IconButton,
    Typography,
    Grid,
    Divider as MuiDivider,
    Modal as MuiModal,
} from '@material-ui/core';
import { AiOutlineArrowRight as BackIcon } from 'react-icons/ai';
import { MdEdit as EditIcon, MdDelete as DeleteIcon } from 'react-icons/md';
import useLanes from '../../common/hooks/data-fetching/useLanes';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectMappingIsShowingEqualizedMap,
    selectMappingSelectedLaneId,
    selectMappingSelectedMapId,
    setSelectedLaneId,
    setSelectedLaneIds,
} from '../../../state-management/user-inputs/mappingSlice';
import {
    Button,
    Skeleton,
    TextField,
    Tooltip,
    CircularPreloader as ThemedCircularPreloader,
} from '../../common/themed';
import { useForm } from 'react-hook-form';
import { updateLane } from '../../../state-management/mapping/lane/laneActions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '@material-ui/icons/FileCopy';
import { showInfoNotification } from '../../../state-management/notification/notificationReducer';
import { getLaneColor, getLaneStatusName } from '../../../utils/laneUtils';
import LaneActions from './LaneActions';
import laneStatus from '../../../constants/laneStatuses';

const Drawer = styled(MuiDrawer)`
    z-index: 1000;
    visibility: visible;
    width: 0;

    & .side-drawer-paper {
        margin-block-start: 4rem;
        height: calc(100% - 4rem);
        width: 320px;
        overflow: visible;
        padding: 20px;
    }
`;

const DrawerContent = styled.div`
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
`;

const Divider = styled(MuiDivider)`
    margin-block-start: 15px;
    margin-block-end: 15px;
`;

const Title = styled(Typography).attrs(() => ({
    variant: 'h5',
}))`
    color: #ff6200; // TODO color should come from theme
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
`;

const NoItemsMessage = styled(Typography)`
    color: #909090;
    text-align: center;
    margin-block-start: 10px;
    font-style: italic;
`;

const Attachment = styled.img`
    width: 100%;
    margin-block-start: 10px;
    cursor: pointer;
    outline: none;
`;

const FullSizeAttachment = styled.img`
    outline: none;
`;

const StatusColor = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background-color: ${(props) => props.$color};
    margin-inline-start: 5px;
    margin-inline-end: 5px;
`;

const StatusName = styled(({ $color, ...otherProps }) => <Typography {...otherProps} />).attrs(() => ({
    variant: 'subtitle1',
}))`
    color: ${(props) => props.$color};
`;

const Modal = styled(MuiModal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const BackIconWrapper = styled(IconButton)`
    position: absolute;
    right: 2%;
`;

const CommentControls = styled.div`
    margin-block-start: 10px;
`;

const CircularPreloader = styled(ThemedCircularPreloader)`
    padding: 0 10px;
`;

const Comment = styled(Typography).attrs(() => ({
    variant: 'subtitle1',
}))`
    white-space: pre-line;
`;

export default function LaneDetailsSideDrawer({ disabled }) {
    const [displayedAttachmentUrl, setDisplayedAttachmentUrl] = useState(null);
    const [isCommentInEditMode, setIsCommentInEditMode] = useState(false);

    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const selectedLaneId = useSelector(selectMappingSelectedLaneId);
    const isShowingEqualizedMap = useSelector(selectMappingIsShowingEqualizedMap);

    const { data: lane = {}, isLoading } = useLanes({
        asObject: true,
        mapId: selectedMapId,
        laneId: selectedLaneId,
        withAttachments: true,
        useEqualizedMap: isShowingEqualizedMap,
    });

    const {
        comments,
        attachments = [],
        hasAttachments,
        createdAt,
        mapperName,
        equalizedStatus,
        unequalizedStatus,
    } = lane;

    const status = isShowingEqualizedMap ? equalizedStatus : unequalizedStatus;

    const {
        register,
        handleSubmit,
        reset,
        formState: { isDirty, isSubmitting },
    } = useForm({
        defaultValues: { comment: comments?.length > 0 ? comments[0] : null },
    });

    const submitForm = async (data) => {
        await dispatch(
            updateLane({ mapId: selectedMapId, laneId: selectedLaneId, laneData: { comment: data.comment } })
        );
        setIsCommentInEditMode(false);
    };

    const clearComments = () => {
        dispatch(updateLane({ mapId: selectedMapId, laneId: selectedLaneId, laneData: { comment: null } }));
    };

    useEffect(() => {
        reset({ comment: comments?.length > 0 ? comments[0] : null });
        setIsCommentInEditMode(false);
    }, [comments, reset]);

    useEffect(() => {
        if (!selectedLaneId && isCommentInEditMode) {
            setIsCommentInEditMode(false);
        }
    }, [isCommentInEditMode, selectedLaneId]);

    const onCloseDrawer = () => {
        dispatch(setSelectedLaneId(null))
        dispatch(setSelectedLaneIds([]));
    }

    return (
        <>
            <Drawer
                open={!!selectedLaneId}
                classes={{ paper: 'side-drawer-paper' }}
                anchor={'right'}
                hideBackdrop
                disableEnforceFocus
            >
                <BackIconWrapper onClick={onCloseDrawer}>
                    <BackIcon />
                </BackIconWrapper>

                <Grid container alignItems={'center'}>
                    <Title>{selectedLaneId}</Title>

                    <CopyToClipboard
                        text={selectedLaneId}
                        onCopy={() => {
                            dispatch(showInfoNotification(`Lane ID copied`));
                        }}
                    >
                        <IconButton onClick={(e) => e.stopPropagation()}>
                            <CopyIcon />
                        </IconButton>
                    </CopyToClipboard>
                </Grid>

                <Divider />

                <DrawerContent>
                    <LaneActions
                        disabled={disabled}
                        mapId={selectedMapId}
                        laneId={selectedLaneId}
                    />

                    <Divider />

                    <Typography variant={'subtitle1'}>
                        Created at: {createdAt?.replace?.(/T/, ' ')?.replace?.(/\..+/, '')}
                    </Typography>

                    <Typography variant={'subtitle1'}>Mapped by: {mapperName}</Typography>

                    <Grid container alignItems={'center'}>
                        <Typography variant={'subtitle1'}>Status: </Typography>
                        <StatusColor $color={getLaneColor(lane, isShowingEqualizedMap)} />
                        <StatusName $color={getLaneColor(lane, isShowingEqualizedMap)}>
                            {getLaneStatusName(lane, isShowingEqualizedMap)}
                        </StatusName>
                    </Grid>

                    <Divider />

                    <Grid container alignItems={'center'}>
                        <Title>Comments</Title>

                        {!isCommentInEditMode && (
                            <Tooltip content={'Edit'}>
                                <IconButton disabled={disabled} onClick={() => setIsCommentInEditMode(true)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        )}

                        <Tooltip content={'Clear'}>
                            <IconButton disabled={disabled} onClick={clearComments}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid container direction={'column'}>
                        {comments?.length > 0 || isCommentInEditMode ? (
                            (comments?.length === 0 && isCommentInEditMode ? [''] : comments)?.map(
                                (comment, index) => (
                                    <Grid key={`lane-comment-${index}`} item container direction={'column'}>
                                        {isCommentInEditMode ? (
                                            <form onSubmit={handleSubmit(submitForm)}>
                                                <Grid item>
                                                    <TextField
                                                        name={'comment'}
                                                        inputRef={register}
                                                        multiline
                                                        fullWidth
                                                        rows={4}
                                                        defaultValue={comment ?? ''}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    container
                                                    justifyContent={'flex-end'}
                                                    spacing={1}
                                                    component={CommentControls}
                                                >
                                                    <Grid item>
                                                        <Button
                                                            variant={'text'}
                                                            onClick={() => setIsCommentInEditMode(false)}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Grid>

                                                    {isSubmitting ? (
                                                        <CircularPreloader size={35} />
                                                    ) : (
                                                        isDirty && (
                                                            <Grid item>
                                                                <Button type={'submit'}>Save</Button>
                                                            </Grid>
                                                        )
                                                    )}
                                                </Grid>
                                            </form>
                                        ) : (
                                            <Comment>{comment}</Comment>
                                        )}
                                    </Grid>
                                )
                            )
                        ) : isLoading ? (
                            Array.from({ length: 2 }, (_, index) => (
                                <Skeleton
                                    key={`lane-${selectedLaneId}-comment-skeleton-${index}`}
                                    height={15}
                                    width={230}
                                />
                            ))
                        ) : (
                            <NoItemsMessage>This lane has no comments.</NoItemsMessage>
                        )}

                        <Divider />

                        <Grid item>
                            <Title>Attachments</Title>
                        </Grid>

                        {hasAttachments ? (
                            <Grid item>
                                {isLoading ? (
                                    <Skeleton height={200} />
                                ) : (
                                    attachments.map((url, index) => (
                                        <Attachment
                                            key={`lane-${selectedLaneId}-attachment-${index}`}
                                            alt={'attachment'}
                                            src={url}
                                            onClick={() => setDisplayedAttachmentUrl(url)}
                                        />
                                    ))
                                )}
                            </Grid>
                        ) : (
                            <NoItemsMessage>This lane has no attachments.</NoItemsMessage>
                        )}
                    </Grid>
                </DrawerContent>
            </Drawer>

            <Modal open={!!displayedAttachmentUrl} onClose={() => setDisplayedAttachmentUrl(null)}>
                <FullSizeAttachment alt={'attachment'} src={displayedAttachmentUrl} />
            </Modal>
        </>
    );
}
