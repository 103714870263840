import specificDay from './SpecificDay';
import lastWeek from './LastWeek';
import lastFourWeeks from './LastFourWeeks';
import custom from './Custom';
import TimePeriod from './TimePeriod';

export const getTimePeriods = () => {
    return [specificDay, lastWeek, lastFourWeeks, custom];
};

export const getTimePeriodByName = timePeriodName => {
    return getTimePeriods().find(tp => tp.value === timePeriodName) || new TimePeriod();
};
