import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { getMapViewerEntrancesOverlayId } from './MapViewerEntrancesOverlay.selectors';
import Entrance from './Entrance';

const OverlayWrapper = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none; // Prevent the whole SVG from capturing pointer events

    * {
        pointer-events: auto;
    }
`;

function MapViewerEntrancesOverlay(props) {
    const { entrances, selectedEntranceId = null, highlightedEntranceIds = [], isEditable = false } = props;

    if (entrances?.length === 0) {
        return null;
    }

    return (
        <OverlayWrapper id={getMapViewerEntrancesOverlayId()}>
            {entrances?.map((entrance) => (
                <Entrance
                    key={entrance?.entranceId}
                    entrance={entrance}
                    isSelected={selectedEntranceId === entrance?.entranceId}
                    isHighlighted={highlightedEntranceIds.includes(entrance?.entranceId)}
                    isEditable={selectedEntranceId === entrance?.entranceId && isEditable}
                />
            ))}
        </OverlayWrapper>
    );
}
MapViewerEntrancesOverlay.propTypes = {
    entrances: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedEntranceId: PropTypes.string,
    highlightedEntranceIds: PropTypes.arrayOf(PropTypes.string),
    isEditable: PropTypes.bool,
};

export default memo(MapViewerEntrancesOverlay);
