import React from 'react';

export default class ErrorBoundary extends React.Component {
    state = { hasError: false };

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
    }

    render() {
        const { hasError } = this.state;

        if (hasError) {
            return (
                <h1>Something went wrong. Please try to refresh the page.</h1>
            );
        }

        return this.props.children;
    }
}
