import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ResponsiveBar } from '@nivo/bar';
import { Typography, Divider } from '@material-ui/core';
import { nameToColor } from '../common/RegionColors';
import { Tooltip } from '../../common/themed';
import InfoIcon from '@material-ui/icons/Info';
import { useSelector } from 'react-redux';
import { selectAnalyticsBuildingUsageDisplaySettingsUserInputs } from '../../../state-management/user-inputs/analyticsBuildingUsageSlice';

const TooltipContentWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ColorSquare = styled.div`
    background-color: ${(props) => props.color};
    border-radius: 2px;
    width: 12px;
    height: 12px;
    margin-right: 10px;
`;

const TooltipLabel = styled.strong`
    color: white;
`;

const TitleWrapper = styled.div`
    display: flex;
    margin-bottom: 5px;
`;

const MoreInfoIcon = styled(InfoIcon)`
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 5px;
    fill: coral; // TODO color should come from theme
`;

export default function RegionOccupancyBarChart(props) {
    const { name, description, data } = props;

    const [keys, setKeys] = useState([]);

    const { selectedRegionIds } = useSelector(selectAnalyticsBuildingUsageDisplaySettingsUserInputs);

    const hasManyTicks = data.length > 20;

    const getTickAngle = () => {
        if (data.length < 20) {
            return 0;
        } else if (data.length < 30) {
            return -20;
        } else {
            return -40;
        }
    };

    const getBottomMargin = () => {
        if (data.length < 20) {
            return 50;
        } else if (data.length < 30) {
            return 80;
        } else {
            return 100;
        }
    };

    useEffect(() => {
        const newKeys = [];

        for (let i = 0; i < selectedRegionIds.length; i++) {
            newKeys.push(`region${i}Value`);
        }

        setKeys(newKeys);
    }, [selectedRegionIds.length]);

    return (
        <>
            <TitleWrapper>
                <Typography variant={'h5'}>{name}</Typography>

                <Tooltip content={description}>
                    <MoreInfoIcon />
                </Tooltip>
            </TitleWrapper>

            <Divider variant={'fullWidth'} />

            <ResponsiveBar
                data={data}
                axisBottom={{ tickRotation: getTickAngle() }}
                indexBy={'timeInterval'}
                keys={keys}
                groupMode={'grouped'}
                margin={{ top: 30, right: 30, bottom: getBottomMargin(), left: 50 }}
                colors={(item) => nameToColor(item.data[`region${item.id.match(/\d+/g)}Name`])} // Extract region name and take the color for it in the palette
                labelSkipWidth={10}
                labelFormat={(d) => !hasManyTicks && <tspan y={-10}>{d}</tspan>} // Place label above bars
                tooltip={({ data, value, color, id }) => (
                    <TooltipContentWrapper>
                        <ColorSquare color={color} />

                        <TooltipLabel>
                            {`${data[id.replace('Value', 'Name')]} (${data.timeInterval}): ${value}`}
                        </TooltipLabel>
                    </TooltipContentWrapper>
                )}
                theme={{
                    tooltip: {
                        container: {
                            background: '#333',
                        },
                    },
                }}
            />
        </>
    );
}
