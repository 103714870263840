import TimeResolution from './TimeResolution';
import moment from 'moment';

class OneWeek extends TimeResolution {
    get inMinutes() {
        return 60 * 24 * 7;
    }

    get label() {
        return 'Week';
    }

    getTimeIntervalString(start, end) {
        const intervalStart = moment(start)
            .utc()
            .add(this.timeZoneOffset, 'minutes')
            .format('MMM D');

        const intervalEnd = moment(end)
            .utc()
            .add(this.timeZoneOffset, 'minutes')
            .format('MMM D');

        return `${intervalStart}-${intervalEnd}`;
    }

    get unitType() {
        return 'week';
    }

    get unitCount() {
        return 1;
    }

    getFittingTimeInterval(utcTimestamp) {
        // TODO Support other days than Monday as the first day of the week
        return {
            start: moment(utcTimestamp)
                .utc()
                .add(this.timeZoneOffset, 'minutes')
                .startOf('isoWeek')
                .subtract(this.timeZoneOffset, 'minutes')
                .toISOString(),
            end: moment(utcTimestamp)
                .utc()
                .add(this.timeZoneOffset, 'minutes')
                .endOf('isoWeek')
                .subtract(this.timeZoneOffset, 'minutes')
                .toISOString(),
        };
    }

    getTimeIntervalsInRange(start, end, options) {
        return super.getTimeIntervalsInRange(start, end);
    }
}

export default new OneWeek();
