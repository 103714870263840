import { createSlice } from '@reduxjs/toolkit';

export const {
    actions: { showInfoNotification, showErrorNotification, showSuccessNotification, closeNotification },
    reducer,
} = createSlice({
    name: 'notification',
    initialState: { display: false, type: 'info', message: '' },
    reducers: {
        showInfoNotification: (state, action) => {
            state.type = 'info';
            state.display = true;
            state.message = action.payload;
        },
        showSuccessNotification: (state, action) => {
            state.type = 'success';
            state.display = true;
            state.message = action.payload;
        },
        showErrorNotification: (state, action) => {
            state.type = 'error';
            state.display = true;
            state.message = action.payload;
        },
        closeNotification: (state) => {
            state.display = false;
        },
    },
});

export const selectNotification = (state) => state.notification;
