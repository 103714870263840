import { createAsyncThunk } from '../utils';
import mapFeaturesApi from '../../api/MapFeaturesApi';
import mapsApi from '../../api/MapsApi';

export const fetchAllMapFeatures = createAsyncThunk('map-features/fetchAll', async (spaceId) => {
    return (await mapFeaturesApi.fetchAllMapFeatures(spaceId)).data;
});

export const upsertMapFeature = createAsyncThunk(
    'map-features/upsert',
    async ({ spaceId, mapFeature, invalidatedData }, { dispatch, rejectWithValue }) => {
        try {
            console.log('aa');
            const { data: updatedMapFeature } = await mapFeaturesApi.upsertMapFeature(spaceId, mapFeature);
            if (!invalidatedData) {
                return { updatedMapFeature };
            }
            const mapFullData = await mapsApi.fetchMapFullData(
                invalidatedData.buildingId,
                invalidatedData.floorId,
                mapFeature[0].mapId
            );
            const { data: gridMetadata } = await mapsApi.getMapGridMetadata(mapFeature[0].mapId);
            return {
                updatedMapFeature,
                mapFullData,
                gridMetadata,
            };
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const deleteMapFeature = createAsyncThunk('map-features/delete', async ({ spaceId, mapId }) => {
    return (await mapFeaturesApi.deleteMapFeature(spaceId, mapId)).data;
});
