import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Portal } from '@material-ui/core';
import {
    InstructionSnackbar,
    InstructionSnackbarButton,
    InstructionSnackbarTextField,
    InstructionText,
} from '../../common/InstructionSnackbar';
import { getEntranceCreationInstructionSnackbarId } from '../entrance-creation/MapViewerEntranceCreationOverlay.selectors';
import { MapViewerContext } from '../../MapViewerContext';
import { createRegion } from '../../../../../state-management/region/regionActions';
import { useDispatch } from 'react-redux';
import { createRegionInternalPlacement } from '../../../../../state-management/region-placement/regionPlacementActions';
import { unwrapResult } from '@reduxjs/toolkit';
import { isFulfilled } from '../../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../../state-management/notification/notificationReducer';
import { getMapViewerPolygonPlacementCreationOverlayId } from './MapViewerRegionInternalPolygonPlacementCreationOverlay.selectors';
import PolygonDrawer from './PolygonDrawer';
import uuid from 'uuid/v4';
const INITIAL = 'INITIAL';
const SHAPE_SET = 'SHAPE_SET';

const CreationSpace = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
`;

export function MapViewerRegionInternalPolygonPlacementCreationOverlay(props) {
    const { spaceId, mapId, onSubmitted, onCancel, points: regionPoints } = props;

    const [creationStep, setCreationStep] = useState(SHAPE_SET);
    const [regionName, setRegionName] = useState(`newRegion_${uuid().substring(0, 7)}`);

    const { containerRef } = useContext(MapViewerContext);
    const [points, setPoints] = useState([]);
    const dispatch = useDispatch();

    const handleSubmit = async () => {
        const createRegionResult = await dispatch(
            createRegion({
                regionData: {
                    regionName,
                    regionMetadata: '',
                    regionUsePlai: false,
                    regionUseAnalytics: false,
                },
                spaceId,
            })
        );

        if (isFulfilled(createRegionResult)) {
            const { regionId } = unwrapResult(createRegionResult);
            const createPlacementResult = await dispatch(
                createRegionInternalPlacement({
                    regionId,
                    mapId,
                    placementData: {
                        regionType: 'polygon',
                        points,
                    },
                })
            );

            if (isFulfilled(createPlacementResult)) {
                dispatch(showSuccessNotification('Successfully created external region.'));
            } else {
                dispatch(showErrorNotification('Failed to create external region.'));
            }
        } else {
            dispatch(showErrorNotification('Failed to create external region.'));
        }

        onSubmitted();
    };

    const onFinishDrawing = (newPoints) => {
        setPoints(newPoints);
        setCreationStep(INITIAL);
    };
    const instructions = {
        [SHAPE_SET]: (
            <>
                <InstructionText>
                    Click anywhere on the map to set points to define the region's shape.
                </InstructionText>

                <InstructionText>Note: A minimum of 3 points are required.</InstructionText>
                <InstructionText $color={'yellow'}>Press Enter to complete the shape.</InstructionText>
            </>
        ),
        [INITIAL]: (
            <>
                <InstructionText>Provide a name for this external region:</InstructionText>
                <InstructionSnackbarTextField
                    value={regionName}
                    onChange={(e) => setRegionName(e.target.value)}
                />
            </>
        ),
    };

    const actions = {
        [SHAPE_SET]: null,

        [INITIAL]: (
            <>
                <InstructionSnackbarButton variant={'text'}>Reset</InstructionSnackbarButton>

                <InstructionSnackbarButton variant={'text'} onClick={handleSubmit} disabled={!regionName}>
                    Submit
                </InstructionSnackbarButton>
            </>
        ),
    };

    return (
        <CreationSpace id={getMapViewerPolygonPlacementCreationOverlayId()}>
            <Portal container={containerRef.current}>
                <InstructionSnackbar
                    id={getEntranceCreationInstructionSnackbarId()}
                    message={instructions[creationStep]}
                    action={
                        <>
                            <InstructionSnackbarButton variant={'text'} onClick={onCancel}>
                                Cancel
                            </InstructionSnackbarButton>

                            {actions[creationStep]}
                        </>
                    }
                />
            </Portal>

            <PolygonDrawer onSubmitted={onFinishDrawing} points={points} setPoints={setPoints} />
        </CreationSpace>
    );
}
