import React, { useState } from 'react';
import { Switch, CircularPreloader } from '../../common/themed';
import { Form, Label, ConfigWrapper } from '../../map-quality/settings/components';
import { FormControlLabel } from '@material-ui/core';
import { AiFillSave as SaveIcon } from 'react-icons/ai';
import { Controller, useForm } from 'react-hook-form';
import { SaveButton } from './styles';

import ConfirmDialog from './ConfirmDialog';

export const MultiSpaceUpdateForm = ({ onSubmit }) => {
    const {
        handleSubmit,
        control,
        formState: { isSubmitting, dirtyFields },
    } = useForm({
        defaultValues: {
            exportPositionData: false,
            regionAnalyticsEnabled: false,
            positionAnalyticsEnabled: false,
        },
    });

    const [formData, setFormData] = useState(null);

    const onSave = async (formData) => {
        if (onSubmit) {
            setFormData(formData);
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit(onSave)}>
                <ConfigWrapper>
                    <Controller
                        name={'exportPositionData'}
                        control={control}
                        render={({ onChange, value }) => (
                            <FormControlLabel
                                control={
                                    <Switch checked={value} onChange={(e) => onChange(e.target.checked)} />
                                }
                                label={<Label>Enable Customer data</Label>}
                            />
                        )}
                    />
                    <Controller
                        name={'regionAnalyticsEnabled'}
                        control={control}
                        render={({ onChange, value }) => (
                            <FormControlLabel
                                control={
                                    <Switch checked={value} onChange={(e) => onChange(e.target.checked)} />
                                }
                                label={<Label>Enable Regions analytics</Label>}
                            />
                        )}
                    />
                    <Controller
                        name={'positionAnalyticsEnabled'}
                        control={control}
                        render={({ onChange, value }) => (
                            <FormControlLabel
                                control={
                                    <Switch checked={value} onChange={(e) => onChange(e.target.checked)} />
                                }
                                label={<Label>Enable positions analytics</Label>}
                            />
                        )}
                    />

                    <SaveButton
                        disabled={!onSubmit && !Object.keys(dirtyFields).length}
                        type="submit"
                        startIcon={<SaveIcon />}
                    >
                        {isSubmitting ? <CircularPreloader /> : 'Save'}
                    </SaveButton>
                </ConfigWrapper>
            </Form>
            <ConfirmDialog
                open={formData !== null}
                onConfirm={async () => await onSubmit(formData)}
                onClose={() => setFormData(null)}
                message="Warning"
            />
        </>
    );
};
