import { createSelector } from '@reduxjs/toolkit';
import entityAdapter from './buildingEntityAdapter';
import { selectFloorEntities } from '../floor/floorSelectors';
import { selectRegionEntities } from '../region/regionSelectors';
import { selectMapEntities } from '../map/mapSelectors';

export const {
    selectById: selectBuildingById,
    selectIds: selectBuildingIds,
    selectEntities: selectBuildingEntities,
    selectAll: selectAllBuildings,
    selectTotal: selectTotalBuildings,
} = entityAdapter.getSelectors((state) => state.buildings);

export const selectBuildingIdToFloorsArray = createSelector(
    selectAllBuildings,
    selectFloorEntities,
    (buildings, floors) => {
        if (!buildings || buildings.length === 0) {
            return {};
        }

        return buildings.reduce(
            (result, building) => ({
                ...result,
                [building.buildingId]: building?.floorIds?.map((floorId) => floors[floorId]),
            }),
            {}
        );
    }
);

export const selectBuildingIdToFloorsObject = createSelector(
    selectAllBuildings,
    selectFloorEntities,
    (buildings, floors) => {
        if (!buildings || buildings.length === 0) {
            return {};
        }

        return buildings.reduce(
            (result, building) => ({
                ...result,
                [building.buildingId]: building?.floorIds?.reduce(
                    (res, floorId) => ({ ...res, [floorId]: floors[floorId] }),
                    {}
                ),
            }),
            {}
        );
    }
);

export const selectBuildingIdToMapArray = createSelector(
    selectAllBuildings,
    selectFloorEntities,
    selectMapEntities,
    (buildings, floors, maps) => {
        if (!buildings || buildings.length === 0) {
            return {};
        }

        return buildings.reduce((result, { buildingId, floorIds = [] }) => {
            const mapIds = floorIds.reduce(
                (result, floorId) => [...result, ...(floors?.[floorId]?.mapIds || [])],
                []
            );

            return { ...result, [buildingId]: mapIds.map((mapId) => maps?.[mapId]) };
        }, {});
    }
);

export const selectBuildingIdToMapObject = createSelector(
    selectAllBuildings,
    selectFloorEntities,
    selectMapEntities,
    (buildings, floors, maps) => {
        if (!buildings || buildings.length === 0) {
            return {};
        }

        return buildings.reduce((result, { buildingId, floorIds = [] }) => {
            const mapIds = floorIds.reduce(
                (result, floorId) => [...result, ...(floors?.[floorId]?.mapIds || [])],
                []
            );

            return {
                ...result,
                [buildingId]: mapIds.reduce(
                    (res, mapId) => ({
                        ...res,
                        [mapId]: maps?.[mapId],
                    }),
                    {}
                ),
            };
        }, {});
    }
);

export const selectBuildingIdToRegionsArray = createSelector(
    selectAllBuildings,
    selectFloorEntities,
    selectMapEntities,
    selectRegionEntities,
    (buildings, floors, maps, regions) => {
        if (!buildings || buildings.length === 0) {
            return {};
        }

        return buildings.reduce((result, { buildingId, floorIds = [] }) => {
            const mapIds = floorIds.reduce(
                (result, floorId) => [...result, ...(floors?.[floorId]?.mapIds || [])],
                []
            );
            const regionIds = [
                ...new Set(
                    mapIds.reduce(
                        (result, mapId) => [...result, ...(maps?.[mapId]?.regionPlacementIds || [])],
                        []
                    )
                ),
            ];
            return {
                ...result,
                [buildingId]: regionIds.map((regionId) => {
                    const extractedRegionId = regionId.split('::')[1];
                    return regions?.[extractedRegionId];
                }),
            };
        }, {});
    }
);

export const selectBuildingIdToRegionsObject = createSelector(
    selectAllBuildings,
    selectFloorEntities,
    selectMapEntities,
    selectRegionEntities,
    (buildings, floors, maps, regions) => {
        if (!buildings || buildings.length === 0) {
            return {};
        }

        return buildings.reduce((result, { buildingId, floorIds = [] }) => {
            const mapIds = floorIds.reduce(
                (result, floorId) => [...result, ...(floors?.[floorId]?.mapIds || [])],
                []
            );
            const regionIds = [
                ...new Set(
                    mapIds.reduce((result, mapId) => [...result, ...(maps?.[mapId]?.regionIds || [])], [])
                ),
            ];

            return {
                ...result,
                [buildingId]: regionIds.reduce(
                    (res, regionId) => ({
                        ...res,
                        [regionId]: regions?.[regionId],
                    }),
                    {}
                ),
            };
        }, {});
    }
);
