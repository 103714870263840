import { useEffect } from 'react';

export const useScrollToElement = ({ ref, selectedIds, items, predicate = () => true }) => {
    useEffect(() => {
        if (selectedIds.length) {
            const last = selectedIds.at(-1);
            const lastIndex = items.customIndexOf(last, predicate);
            if (lastIndex !== null) {
                ref.current?.scrollToItem(lastIndex);
            }
        }
    }, [selectedIds]);
};
