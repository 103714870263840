import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as ArrowSvg } from '../../../../assets/oriientArrow.svg';
import { getMonitorClientListEntryId, getMonitorClientListEntryNameId } from '../../Monitor.selectors';
import ItemsListEntry from '../../../common/side-drawer/ItemsListEntry';

const Arrow = styled(({ $heading, $color, ...otherProps }) => <ArrowSvg {...otherProps} />)`
    max-height: 25px;
    max-width: 30px;

    #arrow {
        fill: ${(props) => props.$color};
    }
`;

export default function MonitorClientListEntry(props) {
    const { client, ...otherProps } = props;
    const { clientId, clientName, color } = client ?? {};

    if (!client) {
        return null;
    }

    return (
        <ItemsListEntry
            id={getMonitorClientListEntryId(clientId)}
            content={
                <Grid container alignItems={'center'}>
                    <Arrow $color={color} />
                    <Typography id={getMonitorClientListEntryNameId(clientId)} variant={'subtitle1'}>
                        {clientName}
                    </Typography>
                </Grid>
            }
            {...otherProps}
        />
    );
}

MonitorClientListEntry.propTypes = {
    client: PropTypes.object.isRequired,
};
