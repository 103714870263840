import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '../../utils';
import mapValidationsApi from '../../../api/MapValidationsApi';
import { getId } from '../../../utils/mapValidationsUtils';

export const mapValidationsFetched = createAction('mapValidations/fetched');

export const fetchAllValidationsInMap = createAsyncThunk(
    'mapValidations/fetchAllInMap',
    async (mapId, { dispatch }) => {
        await mapValidationsApi.fetchAllValidationsInMap(mapId, (validations) => {
            dispatch(
                mapValidationsFetched({
                    mapId,
                    data: validations.map((mv) => ({
                        ...mv,
                        mapValidationId: getId(mv),
                    })),
                })
            );
        });
    }
);

export const deleteAllValidationsInMap = createAsyncThunk('mapValidations/deleteAllInMap', async (mapId) => {
    await mapValidationsApi.deleteAllValidationsInMap(mapId);
});
export const regenerateMap = createAsyncThunk('mapValidations/regenerateMap', async (mapId) => {
    await mapValidationsApi.regenerateMap(mapId);
});
