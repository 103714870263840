import React from 'react';
import * as PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

export default function ConfirmationDialog(props) {
    const {
        isOpen = false,
        title = 'Warning',
        onConfirm,
        onCancel,
        onClose = () => {},
        message,
        ...otherProps
    } = props;

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth={'md'} {...otherProps}>
            <DialogTitle>{title}</DialogTitle>

            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button color={'primary'} onClick={onCancel}>
                    Cancel
                </Button>
                <Button color={'primary'} onClick={onConfirm} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialog.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    message: PropTypes.string.isRequired,
};
