import React from 'react';
import SideMenuSection from '../../common/side-drawer/SideMenuSection';
import { Skeleton, Switch, SwitchLabel as Label } from '../../common/themed';
import {
    selectMappingAreAreasOfInterestShown,
    selectMappingAreSignMarksShown,
    selectMappingIsSignMarkLockInMap,
    selectMappingSelectedBuildingId,
    selectMappingSelectedFloorId,
    selectMappingSelectedMapId,
    setAreAreasOfInterestShown,
    setAreSignMarksShown,
    setSignMarkLockInMap,
} from '../../../state-management/user-inputs/mappingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel } from '@material-ui/core';
import NoItemsMessage from '../../common/side-drawer/NoItemsMessage';
import FetchError from '../../common/error-pages/FetchError';
import { useSignMarks } from '../../common/hooks/data-fetching/useSignMarks';
import useMapFeatures from '../../common/hooks/data-fetching/useMapFeatures';
import { selectCommonSelectedSpaceId } from '../../../state-management/user-inputs/commonSlice';
import { upsertMapFeature } from '../../../state-management/map-features/mapFeaturesActions';
import { useState } from 'react';
import { useEffect } from 'react';
import useAllFloorsInBuilding from '../../common/hooks/data-fetching/useAllFloorsInBuilding';
import ConfirmDialog from '../../analytics/settings/ConfirmDialog';

export default function MappingSideMenuSignMarkSection() {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const floorId = useSelector(selectMappingSelectedFloorId);
    const buildingId = useSelector(selectMappingSelectedBuildingId);
    const areSignMarksShown = useSelector(selectMappingAreSignMarksShown);
    const isSignMarkLockInMap = useSelector(selectMappingIsSignMarkLockInMap);
    const spaceId = useSelector(selectCommonSelectedSpaceId);
    const [dialogOpen, setDialogOpen] = useState(false);
    const { handleMapFeature, mapFeatures, mapFeatureStatus } = useMapFeatures(spaceId, 'Sign marks');
    const mapFeature = mapFeatures.find((mf) => mf.mapId === selectedMapId);

    const {
        data: signMarks = [],
        isLoading,
        hasError,
    } = useSignMarks({
        mapId: selectedMapId,
    });
    const { data: floors } = useAllFloorsInBuilding({ buildingId });
    const [returnSignMarksToClient, setReturnSignMarksToClient] = useState(
        mapFeature?.returnSignMarksToClient
    );

    useEffect(() => {
        setReturnSignMarksToClient(mapFeature?.returnSignMarksToClient);
    }, [mapFeature?.returnSignMarksToClient]);

    if (!selectedMapId && !isLoading) {
        return null;
    }

    const onShowSignMarksOnMapChange = async (value, skipMultiFloorCheck = false) => {
        if (!skipMultiFloorCheck && Array.isArray(floors) && floors.length > 1) {
            return setDialogOpen(true);
        }
        await handleMapFeature(
            {
                selfValidationEnabled: mapFeature?.selfValidationEnabled ?? false,
                selfHealingEnabled: mapFeature?.selfHealingEnabled ?? false,
                autoPublishEnabled: mapFeature?.autoPublishEnabled ?? false,
                returnSignMarksToClient: value,
                mapId: mapFeature?.mapId,
            },
            {
                floorId,
                buildingId,
            }
        );
    };
    const onConfirm = async () => {
        const value = !mapFeature?.returnSignMarksToClient;

        const newMapFeatures = floors.map((f) => {
            const mapId = f.mapIds?.[0];
            const mapFeature = mapFeatures.find((mf) => mf.mapId === mapId);
            return {
                selfValidationEnabled: mapFeature?.selfValidationEnabled ?? false,
                selfHealingEnabled: mapFeature?.selfHealingEnabled ?? false,
                autoPublishEnabled: mapFeature?.autoPublishEnabled ?? false,
                returnSignMarksToClient: value,
                mapId: mapFeature?.mapId,
            };
        });
        await handleMapFeature(newMapFeatures);

        setDialogOpen(false);
    };
    return (
        <>
            <SideMenuSection title={'Sign Marks'}>
                {mapFeatureStatus.hasError ? (
                    <FetchError />
                ) : mapFeatureStatus.isLoading ? (
                    <Skeleton height={50} width={230} />
                ) : (
                    <FormControlLabel
                        label={<Label>Show on client map</Label>}
                        control={
                            <Switch
                                checked={returnSignMarksToClient || false}
                                onChange={(e) => onShowSignMarksOnMapChange(e.target.checked)}
                            />
                        }
                    />
                )}
                {hasError ? (
                    <FetchError />
                ) : isLoading ? (
                    <Skeleton height={50} width={230} />
                ) : signMarks.length === 0 ? (
                    <NoItemsMessage>There are no sign marks in this map</NoItemsMessage>
                ) : (
                    <>
                        <FormControlLabel
                            label={<Label>Show sign marks</Label>}
                            disabled={isLoading}
                            control={
                                <Switch
                                    checked={areSignMarksShown}
                                    onChange={() => dispatch(setAreSignMarksShown(!areSignMarksShown))}
                                />
                            }
                        />
                        <FormControlLabel
                            label={<Label>Lock sign marks in map</Label>}
                            disabled={isLoading}
                            control={
                                <Switch
                                    checked={isSignMarkLockInMap}
                                    onChange={() => dispatch(setSignMarkLockInMap(!isSignMarkLockInMap))}
                                />
                            }
                        />
                    </>
                )}
            </SideMenuSection>
            <ConfirmDialog
                open={dialogOpen}
                content={'Apply the change to all of the building floors?'}
                cancelText={'Apply for this floor only'}
                onConfirm={onConfirm}
                onClose={() => {
                    onShowSignMarksOnMapChange(!mapFeature?.returnSignMarksToClient, true);
                    setDialogOpen(false);
                }}
            />
        </>
    );
}
