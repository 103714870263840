import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { FormControlLabel } from '@material-ui/core';
import SideMenuSection from '../../../common/side-drawer/SideMenuSection';
import { TextField, Skeleton, Switch, SwitchLabel as Label } from '../../../common/themed';
import {
    openTriggerEditor,
    selectMapContentSelectedMapId,
    selectMapContentSelectedRegionId,
    selectMapContentTriggersFilter,
    setTriggersFilter,
} from '../../../../state-management/user-inputs/mapContentSlice';
import { useDispatch, useSelector } from 'react-redux';
import ErrorGeneral from '../../../common/error-pages/ErrorGeneral';
import {
    selectCanEditTriggers,
    selectCanReadTriggers,
} from '../../../../state-management/auth/authSelectors';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import { MdAdd as AddIcon } from 'react-icons/md';
import useTriggers from '../../../common/hooks/data-fetching/useTriggers';
import {
    getTriggersFilterId,
    getTriggersListId,
    getTriggersListShowInSelectedMapOnlyId,
    getTriggersSectionId,
} from '../../MapContent.selectors';
import TriggerListEntry from './TriggerListEntry';
import {
    getSelectAllTriggersAttachedToMap,
    getSelectAllTriggersAttachedToRegion,
} from '../../../../state-management/trigger/triggerSelectors';
import { getSelectAllTriggersAttachedToSpace } from '../../../../state-management/space/spaceSelectors';

const TriggersListWrapper = styled.ul`
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-block-start: 5px;
    padding: 0;
`;

const ClickableSquare = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
    transition: background-color 200ms ease;
    padding: 12px;
    border: 1px solid #cccccc;

    svg {
        margin-right: 5px;
        width: 25px;
        height: 25px;
    }

    :hover {
        background-color: #ff6200; // TODO color should come from theme;
        color: white;

        svg {
            fill: white;
        }
    }
`;

export default function MapContentSideMenuTriggersSection() {
    const [showOnlyTriggersInSelectedMap, setShowOnlyTriggersInSelectedMap] = useState(false);

    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const selectedMapId = useSelector(selectMapContentSelectedMapId);
    const selectedRegionId = useSelector(selectMapContentSelectedRegionId);
    const triggersFilter = useSelector(selectMapContentTriggersFilter);

    const {
        data: triggers,
        isLoading,
        hasError,
    } = useTriggers({
        asObject: true,
        spaceId: selectedSpaceId,
    });

    const selectTriggersAttachedToSpace = useMemo(
        () => getSelectAllTriggersAttachedToSpace(selectedSpaceId),
        [selectedSpaceId]
    );
    const selectTriggersAttachedToMap = useMemo(
        () => getSelectAllTriggersAttachedToMap(selectedMapId),
        [selectedMapId]
    );
    const selectTriggersAttachedToRegion = useMemo(
        () => getSelectAllTriggersAttachedToRegion(selectedRegionId),
        [selectedRegionId]
    );

    const triggersInSpace = useSelector(selectTriggersAttachedToSpace) ?? [];
    const triggersInMap = useSelector(selectTriggersAttachedToMap) ?? [];
    const triggersInRegion = useSelector(selectTriggersAttachedToRegion) ?? [];
    const canReadTriggers = useSelector(selectCanReadTriggers);
    const canEditTriggers = useSelector(selectCanEditTriggers);

    const triggerIdsInRegion = triggersInRegion.map((t) => t.triggerId);

    if (!canReadTriggers) {
        return null;
    }

    if (hasError) {
        return <ErrorGeneral />;
    }

    if (!triggers && !isLoading) {
        return null;
    }

    return (
        <>
            <SideMenuSection id={getTriggersSectionId()} title={'Triggers'}>
                <FormControlLabel
                    id={getTriggersListShowInSelectedMapOnlyId()}
                    control={
                        <Switch
                            checked={showOnlyTriggersInSelectedMap}
                            onChange={(e) => setShowOnlyTriggersInSelectedMap(e.target.checked)}
                        />
                    }
                    label={<Label>Show only triggers of the selected map</Label>}
                    disabled={isLoading}
                />

                <TextField
                    id={getTriggersFilterId()}
                    label={'Search a trigger...'}
                    value={triggersFilter}
                    onChange={(e) => dispatch(setTriggersFilter(e.target.value))}
                    fullWidth
                    disabled={isLoading}
                />

                <TriggersListWrapper id={getTriggersListId()}>
                    {isLoading
                        ? Array.from({ length: 5 }, (_, index) => (
                              <Skeleton
                                  key={`trigger-skeleton-${index}`}
                                  height={46}
                                  style={{ marginBlockEnd: 2 }}
                              />
                          ))
                        : (showOnlyTriggersInSelectedMap ? triggersInMap : triggersInSpace)
                              ?.filter(({ triggerName }) =>
                                  triggerName.toLowerCase().includes(triggersFilter.toLowerCase())
                              )
                              ?.sort(
                                  (t1, t2) =>
                                      triggerIdsInRegion.indexOf(t2.triggerId) -
                                          triggerIdsInRegion.indexOf(t1.triggerId) ||
                                      t1.triggerName.localeCompare(t2.triggerName)
                              )
                              ?.map((trigger) => (
                                  <TriggerListEntry key={trigger.triggerId} trigger={trigger} />
                              ))}
                </TriggersListWrapper>

                {canEditTriggers && (
                    <ClickableSquare onClick={() => dispatch(openTriggerEditor({ isNew: true }))}>
                        <AddIcon />
                        Create new trigger
                    </ClickableSquare>
                )}
            </SideMenuSection>
        </>
    );
}
