import React from 'react';
import SideMenuSection from '../../common/side-drawer/SideMenuSection';
import { Button, Switch } from '../../common/themed';
import {
    selectMappingIsShowingEqualizedMap,
    selectMappingSelectedMapId,
    selectMappingShowJunctionsAboveMismatchThreshold,
    selectMappingShowJunctionsBelowMismatchThreshold,
    setShowJunctionsAboveMismatchThreshold,
    setShowJunctionsBelowMismatchThreshold,
} from '../../../state-management/user-inputs/mappingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel } from '@material-ui/core';
import useJunctionMismatches from '../../common/hooks/data-fetching/useJunctionMismatches';
import NoItemsMessage from '../../common/side-drawer/NoItemsMessage';
import FetchError from '../../common/error-pages/FetchError';
import { SwitchLabel as Label } from '../../common/themed';
import Grid from '@material-ui/core/Grid';
import {
    fetchAllJunctionMismatchesInMap,
    toggleJunctionMismatchesInMap,
} from '../../../state-management/mapping/junction-mismatch/junctionMismatchActions';
import { selectJunctionMismatchToggled } from '../../../state-management/mapping/junction-mismatch/junctionMismatchSelectors';

export default function MappingSideMenuJunctionMismatchesSection({ disabled }) {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const showJunctionsBelowMismatchThreshold = useSelector(selectMappingShowJunctionsBelowMismatchThreshold);
    const showJunctionsAboveMismatchThreshold = useSelector(selectMappingShowJunctionsAboveMismatchThreshold);
    const isShowingEqualizedMap = useSelector(selectMappingIsShowingEqualizedMap);
    const junctionMismatchToggle = useSelector(selectJunctionMismatchToggled);

    const {
        data: junctions = [],
        isLoading,
        hasError,
    } = useJunctionMismatches({
        mapId: selectedMapId,
        useEqualizedMap: isShowingEqualizedMap,
        junctionMismatchToggle,
    });

    const handleRefresh = () => {
        dispatch(
            fetchAllJunctionMismatchesInMap({ mapId: selectedMapId, useEqualizedMap: isShowingEqualizedMap })
        );
    };

    if (!selectedMapId && !isLoading) {
        return null;
    }

    return (
        <SideMenuSection title={'Junctions'}>
            <Grid container>
                <FormControlLabel
                    label={<Label>Get Junctions</Label>}
                    disabled={isLoading}
                    control={
                        <Switch
                            checked={junctionMismatchToggle}
                            onChange={() => dispatch(toggleJunctionMismatchesInMap())}
                        />
                    }
                />
                <Button
                    style={{ width: '100%' }}
                    onClick={handleRefresh}
                    disabled={disabled || !junctionMismatchToggle}
                >
                    {'Refresh Junctions'}
                </Button>
            </Grid>

            {hasError ? (
                <FetchError />
            ) : junctions.length === 0 ? (
                <NoItemsMessage>There are no junctions in this map</NoItemsMessage>
            ) : (
                <>
                    <FormControlLabel
                        label={<Label>Show junctions below mismatch threshold</Label>}
                        disabled={isLoading}
                        control={
                            <Switch
                                checked={showJunctionsBelowMismatchThreshold}
                                onChange={(e) =>
                                    dispatch(setShowJunctionsBelowMismatchThreshold(e.target.checked))
                                }
                            />
                        }
                    />

                    <FormControlLabel
                        label={<Label>Show junctions above mismatch threshold</Label>}
                        disabled={isLoading}
                        control={
                            <Switch
                                checked={showJunctionsAboveMismatchThreshold}
                                onChange={(e) =>
                                    dispatch(setShowJunctionsAboveMismatchThreshold(e.target.checked))
                                }
                            />
                        }
                    />
                </>
            )}
        </SideMenuSection>
    );
}
