import React, { useEffect } from 'react';
import MonitorMapViewer from './MonitorMapViewer';
import MonitorSideMenu from './MonitorSideMenu';
import realTimeServerWs from '../../ws/real-time-server/realTimeServerWs';
import eventsManagerWs from "../../ws/events-manager/eventsManagerWs";
import { showErrorNotification } from '../../state-management/notification/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { selectMonitorSelectedMapId, selectMonitorSelectedBuildingId } from '../../state-management/user-inputs/monitorSlice';
import { getClientPositionUpdatedTopic } from "../../constants/eventTopics";

export default function Monitor() {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMonitorSelectedMapId);
    const selectedBuildingId = useSelector(selectMonitorSelectedBuildingId);
    const clientPositionUpdatedTopic = getClientPositionUpdatedTopic(selectedBuildingId, selectedMapId);

    useEffect(() => {
        if (selectedMapId) {
            
            realTimeServerWs.connect({ mapId: selectedMapId, reconnectOnClose: true }).catch(() => {
                // dispatch(showErrorNotification('Failed to connect to live monitor'));
            });

            eventsManagerWs.connect({ subscribeToTopics: [clientPositionUpdatedTopic], reconnectOnClose: true }).catch(() => {
                dispatch(showErrorNotification('Failed to connect to event manager'));
            });

            return () => {
                console.warn('DISCONNECTING')
                realTimeServerWs.disconnect();
                eventsManagerWs.disconnect();
            };
        }
    }, [dispatch, selectedMapId]);


    return (
        <>
            <MonitorMapViewer />
            <MonitorSideMenu />
        </>
    );
}
