import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, Typography, CardContent, Fade } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, ScalePreloader } from '../common/themed';
import AnimatedLogo from './AnimatedLogo';
import {
    getApiKeyInputId,
    getLoginWithApiKeyButtonId,
    getLoginWithCredentialsButtonId,
    getPasswordInputId,
    getUsernameInputId,
} from './Login.selectors';
import { TermsAndConditionsDialog } from '@oriient/terms-and-conditions';
import {
    selectIsSuspended,
    selectNeedsToAcceptLatestTermsAndConditions,
    selectUserApiKeyId,
} from '../../state-management/auth/authSelectors';
import settings from '../../clientSettings';
import { acceptTermsAndConditions, login } from '../../state-management/auth/authActions';
import { useForm } from 'react-hook-form';
import { isFulfilled } from '../../state-management/utils';
import { showErrorNotification } from '../../state-management/notification/notificationReducer';
import { useRef } from 'react';
import { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import AuthApi from '../../api/AuthApi';
const LoginBackground = styled.main`
    background-color: #fafafa;
    height: 100%;
    width: 100%;
`;

const LoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LoginCard = styled(Card)`
    width: 400px;
`;

const LoginHeader = styled(Typography).attrs({
    variant: 'h5',
})`
    margin-bottom: 15px;
    text-align: center;
`;

const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${TextField} {
        width: 80%;
        margin-bottom: 15px;
    }
`;

const DividingLine = styled.hr`
    overflow: visible;
    padding: 0;
    border: none;
    border-top: 1px solid #ccc;
    color: #333;
    text-align: center;
    width: 100%;
    height: 0;
    margin-top: 25px;
    margin-bottom: 15px;

    :after {
        content: 'OR';
        display: inline-block;
        position: relative;
        bottom: 8px;
        color: #ccc;
        padding: 0 0.25em;
        background: white;
    }
`;

const LoginButton = styled(Button)`
    margin-top: 10px;
    margin-bottom: 10px;
`;

export default function Login() {
    const dispatch = useDispatch();

    const userApiKeyId = useSelector(selectUserApiKeyId);
    const needsToAcceptLatestTermsAndConditions = useSelector(selectNeedsToAcceptLatestTermsAndConditions);
    const isSuspended = useSelector(selectIsSuspended);
    const [attempts, setAttempts] = useState(0);
    let { current: loginAttemptsTimeout } = useRef(null);
    const captchaSettings = settings.captcha;
    const {
        register: credentialsFormRegister,
        handleSubmit: handleCredentialsSubmit,
        formState: { isSubmitting: isSubmittingCredentials },
    } = useForm({
        defaultValues: { username: '', password: '' },
    });

    const {
        register: apiKeyFormRegister,
        handleSubmit: handleApiKeySubmit,
        formState: { isSubmitting: isSubmittingApiKey },
    } = useForm({
        defaultValues: { apiKeyId: '' },
    });

    const loginUsingCredentials = async ({ username, password }) => {
        const result = await dispatch(login({ username: username.trim(), password }));

        if (!isFulfilled(result)) {
            dispatch(showErrorNotification(result.payload || 'Invalid credentials were provided.'));
            setAttempts(attempts + 1);
        }
    };

    const onChange = async (response) => {
        try {
            await AuthApi.verifyCAPTCHA(response);

            if (loginAttemptsTimeout) {
                clearTimeout(loginAttemptsTimeout);
            }
            setAttempts(0);
        } catch (error) {
            dispatch(showErrorNotification(`reCAPTCHA Failed:${error.message}`));
        }
    };
    useEffect(() => {
        if (attempts > 0 && !loginAttemptsTimeout) {
            loginAttemptsTimeout = setTimeout(() => {
                setAttempts(0);
            }, captchaSettings.timeout);
        }

        return () => {
            if (loginAttemptsTimeout) {
                clearTimeout(loginAttemptsTimeout);
            }
        };
    }, [attempts]);

    return (
        <LoginBackground>
            <Fade in timeout={1000}>
                <LoginWrapper>
                    <AnimatedLogo width={250} height={130} />
                    <LoginCard>
                        {attempts < captchaSettings.numberOfAttempts ? (
                            <CardContent>
                                <LoginHeader>Sign In</LoginHeader>
                                <LoginForm onSubmit={handleCredentialsSubmit(loginUsingCredentials)}>
                                    <TextField
                                        id={getUsernameInputId()}
                                        name={'username'}
                                        inputRef={credentialsFormRegister}
                                        label={'Username'}
                                    />

                                    <TextField
                                        id={getPasswordInputId()}
                                        name={'password'}
                                        inputRef={credentialsFormRegister}
                                        label={'Password'}
                                        type={'password'}
                                    />

                                    {isSubmittingCredentials ? (
                                        <ScalePreloader height={48} width={8} />
                                    ) : (
                                        <LoginButton id={getLoginWithCredentialsButtonId()} type={'submit'}>
                                            Login With Credentials
                                        </LoginButton>
                                    )}
                                </LoginForm>
                            </CardContent>
                        ) : (
                            <CardContent>
                                <ReCAPTCHA sitekey={captchaSettings.siteKey} onChange={onChange} />
                            </CardContent>
                        )}
                    </LoginCard>
                </LoginWrapper>
            </Fade>

            <TermsAndConditionsDialog
                isOpen={!isSuspended && needsToAcceptLatestTermsAndConditions}
                onAccepted={() => dispatch(acceptTermsAndConditions())}
                apiKeyId={userApiKeyId}
                restApiUrl={settings.restApiServer.replace('/v1/', '').replace('/v1', '')}
            />
        </LoginBackground>
    );
}
