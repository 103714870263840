import laneStatus from '../constants/laneStatuses';

export const getLaneColor = (lane, equalized = true, disabled = false) => {
    if (disabled) {
        return 'gray';
    }
    if (lane?.isMigrated) {
        return '#ab5200';
    }

    const status = equalized ? lane?.equalizedStatus : lane.unequalizedStatus;

    switch (status) {
        case laneStatus.APPROVED:
            return '#3EC300';
        case laneStatus.PENDING_APPROVAL:
            return '#EFD149';
        case laneStatus.DECLINED:
            return '#e74c3c';
        default:
            return '#333333';
    }
};

export const getLaneStatusName = (lane, equalized = true) => {
    let name;
    const status = equalized ? lane?.equalizedStatus : lane.unequalizedStatus;

    switch (status) {
        case laneStatus.APPROVED:
            name = 'Approved';
            break;
        case laneStatus.PENDING_APPROVAL:
            name = 'Pending approval';
            break;
        case laneStatus.DECLINED:
            name = 'Declined';
            break;
        default:
            name = 'Unknown';
            break;
    }

    return `${name}${lane?.isMigrated ? ' (Migrated)' : ''}`;
};
