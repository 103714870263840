import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCanPublishMapGrid } from '../../../state-management/auth/authSelectors';
import { noPermissionMessage } from '../../../state-management/constants';
import { createPublishMapGrid } from '../../../state-management/map/mapActions';
import {
    showSuccessNotification,
    showErrorNotification,
} from '../../../state-management/notification/notificationReducer';
import { selectIsLoading as getSelectIsLoading } from '../../../state-management/status/statusSelectors';
import { isFulfilled, isRejected } from '../../../state-management/utils';

export const usePublishMapGrid = (onSuccess = async () => {}) => {
    const [publishSubmitted, setPublishSubmitted] = useState();
    const hasPublishPermission = useSelector(selectCanPublishMapGrid);
    const dispatch = useDispatch();

    const selectIsLoading = useMemo(
        () =>
            getSelectIsLoading({
                type: createPublishMapGrid.typePrefix,
            }),
        []
    );
    const publishLoading = useSelector(selectIsLoading);

    const createPublishGrid = async ({ mapId }) => {
        let result;
        if (!hasPublishPermission) {
            throw new Error(noPermissionMessage);
        }
        result = await dispatch(createPublishMapGrid({ mapId }));
        if (isFulfilled(result)) {
            setPublishSubmitted(true);
            dispatch(showSuccessNotification('Published successfully'));
            await onSuccess();
        } else if (isRejected(result)) {
            const errMsg = 'Failed to publish map grid!';
            dispatch(showErrorNotification(errMsg));
        }
        return result;
    };

    return {
        createPublishGrid,
        publishLoading,
        publishSubmitted,
    };
};
