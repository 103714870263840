import React, { forwardRef, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
    Tooltip,
    CircularPreloader,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '../../../common/themed';
import { MdDelete as DeleteIcon, MdCancel as CancelIcon } from 'react-icons/md';
import { BiShapeSquare as EditShapeIcon } from 'react-icons/bi';
import { IconButton, RootRef, Grid } from '@material-ui/core';
import { selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { useDispatch, useSelector } from 'react-redux';
import {
    getExternalPlacementCancelId,
    getExternalPlacementDeleteId,
    getExternalPlacementEditShapeId,
    getExternalPlacementShowInfoId,
} from '../../buildings.selectors';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { deleteRegionExternalPlacement } from '../../../../state-management/region-placement/regionPlacementActions';
import {
    selectBuildingsIsSelectedExternalPlacementEditable,
    setSelectedExternalPlacementEditable,
    setSelectedExternalPlacementId,
} from '../../../../state-management/user-inputs/buildingsSlice';
import { AiOutlineInfoCircle as InfoIcon } from 'react-icons/ai';
import { selectCanEditRegionExternalPlacements } from '../../../../state-management/auth/authSelectors';

const IconWrapper = forwardRef((props, ref) => (
    <RootRef rootRef={ref}>
        <IconButton {...props} />
    </RootRef>
));

export default function ExternalRegionActions(props) {
    const { regionId, placementId, showEditShape = false, showShowInfo = true, additionalActions } = props;

    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

    const dispatch = useDispatch();

    const selectIsDeleting = useMemo(
        () =>
            selectIsLoading({
                type: deleteRegionExternalPlacement.typePrefix,
                arg: { regionId, placementId },
            }),
        [regionId, placementId]
    );

    const isDeleting = useSelector(selectIsDeleting);
    const isPlacementEditable = useSelector(selectBuildingsIsSelectedExternalPlacementEditable);
    const canEditRegionExternalPlacements = useSelector(selectCanEditRegionExternalPlacements);

    const handleDelete = async () => {
        const result = await dispatch(deleteRegionExternalPlacement({ regionId, placementId }));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('External region deleted successfully.'));
        } else {
            dispatch(showErrorNotification('Failed to delete external region.'));
        }

        setIsDeleteWarningOpen(false);
    };

    const handleCancel = () => {
        dispatch(setSelectedExternalPlacementEditable({ regionId, placementId, isEditable: false }));
    };

    const handleEditShape = () => {
        dispatch(setSelectedExternalPlacementEditable({ regionId, placementId, isEditable: true }));
    };

    return (
        <>
            <Grid container alignItems={'center'} justifyContent={'space-evenly'}>
                {showEditShape &&
                    (isPlacementEditable ? (
                        <Tooltip content={'Cancel edit'} placement={'bottom'} useWrapper={false}>
                            <IconWrapper
                                id={getExternalPlacementCancelId(placementId)}
                                aria-label={'Cancel edit'}
                                onClick={handleCancel}
                                disabled={!canEditRegionExternalPlacements}
                            >
                                <CancelIcon />
                            </IconWrapper>
                        </Tooltip>
                    ) : (
                        <Tooltip content={'Edit shape'} placement={'bottom'} useWrapper={false}>
                            <IconWrapper
                                id={getExternalPlacementEditShapeId(placementId)}
                                aria-label={'Edit shape'}
                                onClick={handleEditShape}
                                disabled={!canEditRegionExternalPlacements}
                            >
                                <EditShapeIcon />
                            </IconWrapper>
                        </Tooltip>
                    ))}

                {showShowInfo && (
                    <Tooltip content={'Show Info'} placement={'bottom'} useWrapper={false}>
                        <IconWrapper
                            id={getExternalPlacementShowInfoId(placementId)}
                            aria-label={'Show Info'}
                            onClick={() =>
                                dispatch(setSelectedExternalPlacementId({ regionId, placementId }))
                            }
                        >
                            <InfoIcon />
                        </IconWrapper>
                    </Tooltip>
                )}

                {additionalActions}
            </Grid>

            {isDeleteWarningOpen && (
                <Dialog open closeable={false}>
                    <DialogContent>
                        <DialogContentText>
                            You are about to delete the external region with the ID: "{placementId}". Are you
                            sure?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button variant={'text'} onClick={() => setIsDeleteWarningOpen(false)}>
                            Cancel
                        </Button>

                        {isDeleting ? <CircularPreloader /> : <Button onClick={handleDelete}>Accept</Button>}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

ExternalRegionActions.propTypes = {
    regionId: PropTypes.string,
    placementId: PropTypes.string,
    additionalActions: PropTypes.node,
};
