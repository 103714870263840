import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { CircularPreloader } from '../../common/themed';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../state-management/general/generalReducer';

const ConfirmDialog = ({
    open,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    onClose,
    onConfirm,
    message,
    content,
    loading,
}) => {
    const dispatch = useDispatch();
    const onCancel =
        onClose ||
        function () {
            dispatch(closeDialog());
        };
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>{message}</DialogTitle>
            <DialogContent>{content || `Are you sure you want to perform this action?`}</DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="default">
                    {cancelText}
                </Button>
                {loading ? (
                    <CircularPreloader />
                ) : (
                    <Button onClick={onConfirm} color="primary" autoFocus>
                        {confirmText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
