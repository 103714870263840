import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MapViewerLoadingIndicatorOverlay from '../../common/map-viewer/overlays/loading-indicators';
import {
    selectMappingAreLinesOfInterestShown,
    selectMappingSelectedMapId,
    selectMappingLineOfInterestIds,
    setSelectedLineOfInterestIds,
    selectMappingSelectedLineOfInterestsMapperName,
} from '../../../state-management/user-inputs/mappingSlice';
import useLinesOfInterest from '../../common/hooks/data-fetching/useLinesOfInterest';
import { deleteLineOfInterest } from '../../../state-management/mapping/line-of-interest/lineOfInterestActions';
import MapViewerLinesOfInterestOverlay from '../../common/map-viewer/overlays/lines-of-interest';
import { getLineOfInterestListEntryId } from '../Mapping.selectors';
export const LinesOfInterestOverlay = ({ disabled, areLinesOfInterestShown, isSelectable = true }) => {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const selectedLineIds = useSelector(selectMappingLineOfInterestIds);
    const selectedMapperName = useSelector(selectMappingSelectedLineOfInterestsMapperName);

    const { data: lines = [], isLoading } = useLinesOfInterest({ mapId: selectedMapId });

    const linesToDisplay = useMemo(() => {
        return lines.filter(
            ({ mapperName }) => !selectedMapperName || selectedMapperName.includes(mapperName)
        );
    }, [lines, selectedMapperName]);

    const handleScrollingToSelectedElement = (selectedIds) => {
        if (selectedIds.length === 1) {
            const [id] = selectedIds;
            const htmlElementId = getLineOfInterestListEntryId(id);
            const element = document.getElementById(htmlElementId);
            if (element) {
                element.scrollIntoView();
            }
        }
    };

    const handleLineClick = (lineId, isShiftSelected) => {
        if (!isSelectable) {
            return;
        }
        const lineExisted = selectedLineIds.includes(lineId);
        let updatedIds;
        if (isShiftSelected) {
            // For handling multiple ids
            updatedIds = [...selectedLineIds, lineId];
            if (lineExisted) {
                updatedIds = selectedLineIds.filter((id) => id !== lineId);
            }
        } else {
            updatedIds = [lineId];
            if (lineExisted) {
                updatedIds = [];
            }
        }
        dispatch(setSelectedLineOfInterestIds(updatedIds));
        handleScrollingToSelectedElement(updatedIds);
    };

    const handleAreaCancel = () => {
        dispatch(setSelectedLineOfInterestIds([]));
    };

    const handleAreaDelete = async (lineId) => {
        await dispatch(deleteLineOfInterest({ mapId: selectedMapId, lineId }));
    };

    return (
        areLinesOfInterestShown && (
            <>
                <MapViewerLinesOfInterestOverlay
                    mapId={selectedMapId}
                    lines={linesToDisplay}
                    onClick={handleLineClick}
                    // onSave={handleAreaSave}
                    onCancel={handleAreaCancel}
                    onDelete={handleAreaDelete}
                    selectedLineIds={selectedLineIds}
                    disabled={disabled}
                />
                {isLoading && <MapViewerLoadingIndicatorOverlay label={'Loading lines of interest...'} />}
            </>
        )
    );
};
