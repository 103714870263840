import React from 'react';
import TextField from '../TextField';
import { v4 as uuid } from 'uuid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '../Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

const TableCellFactory = ({ row, uniqueKey, onPropertyChanged, columns, className }) => {
    return columns.map((col) => {
        const key = col.id;
        const val = row[col.id]?.formattedValue || row[col.id];
        const _key = uuid();
        const { type = '', suffix = '' } = col;
        switch (type) {
            case 'input':
                return (
                    <TableCell key={_key}>
                        <TextField
                            onClick={(e) => e.stopPropagation()}
                            id={_key}
                            defaultValue={val}
                            onBlur={(e) => onPropertyChanged?.(row[uniqueKey], key, e.target.value)}
                            style={{ minWidth: '100px' }}
                        />
                    </TableCell>
                );
            case 'checkbox':
                return (
                    <TableCell key={_key}>
                        <Checkbox
                            defaultChecked={val}
                            onChange={(e, v) => onPropertyChanged?.(row[uniqueKey], key, v)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </TableCell>
                );

            default:
                return (
                    <TableCell className={className} key={_key}>
                        <Tooltip title={val}>
                            <span>{val + suffix}</span>
                        </Tooltip>
                    </TableCell>
                );
        }
    });
};

export default TableCellFactory;
