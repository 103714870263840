import { Fab } from '@material-ui/core';
import styled from 'styled-components';

export default styled(Fab)`
    background-color: #ff6200; // TODO color should come from theme
    color: white;
    
    :hover {
        background-color: #ff4d17;
    }
`;
