import React, { Fragment, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText as MuiListItemText,
    Collapse,
    Drawer,
    Divider,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import {
    selectCanAccessAccountManagement,
    selectCanAccessAnalytics,
    selectCanAccessKnowledgeBase,
    selectCanAccessBuildings,
    selectCanAccessMonitor,
    selectCanAccessAnalyticsBuildingUsage,
    selectCanAccessAnalyticsUserActivity,
    selectCanAccessMapContent,
    selectCanEditApiKeys,
    selectCanEditSpaces,
    selectUserApiKeyId,
    selectIsOmk,
    selectCanAccessMapping,
    selectCanAccessShiftManagement,
    selectCanAccessPlai,
    selectCanAccessInternalPlai,
    selectCanAccessMapQuality,
} from '../../../state-management/auth/authSelectors';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaWalking, FaBuilding, FaChartBar, FaKey } from 'react-icons/fa';
import {
    MdFormatShapes,
    MdTapAndPlay,
    MdAccountCircle,
    MdLibraryBooks,
    MdExpandLess,
    MdExpandMore,
    MdDashboard,
    MdInfoOutline,
    MdLocalGroceryStore,
    MdOutlineHighQuality,
    MdSettings,
} from 'react-icons/md';
import { BiSitemap } from 'react-icons/bi';
import { BsBuilding } from 'react-icons/bs';
import { FiExternalLink } from 'react-icons/fi';
import { FiBarChart2 } from 'react-icons/fi';
import { GiFountainPen, GiPathDistance } from 'react-icons/gi';
import settings from '../../../clientSettings';
import {
    getNavigationMenuClassName,
    getNavigationMenuClosedClassName,
    getNavigationMenuId,
    getNavigationMenuItemClassName,
    getNavigationMenuItemId,
} from './NavigationMenu.selectors';

const { knowledgeBaseUrl, enableProductLocations } = settings;

const SideDrawer = styled(({ isOpen, ...props }) => (
    <Drawer
        {...props}
        id={getNavigationMenuId()}
        classes={{
            root: `${getNavigationMenuClassName()}${isOpen ? '' : ` ${getNavigationMenuClosedClassName()}`}`,
            paper: `${getNavigationMenuClassName()}${isOpen ? '' : ` ${getNavigationMenuClosedClassName()}`}`,
        }}
    />
))`
    &.${getNavigationMenuClassName()}, .${getNavigationMenuClassName()} {
        position: relative;
        width: 16.5rem;
        flex-shrink: 0;
        white-space: nowrap;
        transition: width 500ms ease;
        overflow-x: hidden;
        border-right: 1px solid #cccccc; //TODO color should come from theme
    }

    &.${getNavigationMenuClosedClassName()}, .${getNavigationMenuClosedClassName()} {
        width: 3.5rem;
    }
`;

const MenuItemsWrapper = styled(List)`
    a {
        text-decoration: none;
        display: block;
        transition: background-color 500ms ease;

        :hover {
            background-color: #f7f7f7;
        }

        &.active {
            background-color: #e0e0e0;
        }

        svg {
            opacity: 0.5;
            color: #000000;
        }
    }
`;

const IndentedListItem = styled(ListItem)`
    padding-left: 40px;
`;

const MenuItemIcon = styled(ListItemIcon)`
    margin: 0;
    min-width: 0px;
`;

const ListItemText = styled(MuiListItemText)`
    padding: 0 16px;
`;
export default function NavigationMenu(props) {
    const { isOpen } = props;

    const { pathname } = useLocation();

    const userApiKeyId = useSelector(selectUserApiKeyId);
    const canAccessMapContent = useSelector(selectCanAccessMapContent);
    const canAccessBuildings = useSelector(selectCanAccessBuildings);
    const canAccessMonitor = useSelector(selectCanAccessMonitor);
    const canAccessMapping = useSelector(selectCanAccessMapping);
    const canAccessAccountManagement = useSelector(selectCanAccessAccountManagement);
    const canEditApiKeys = useSelector(selectCanEditApiKeys);
    const canEditSpaces = useSelector(selectCanEditSpaces);
    const canAccessAnalytics = useSelector(selectCanAccessAnalytics);
    const canAccessAnalyticsUserActivity = useSelector(selectCanAccessAnalyticsUserActivity);
    const canAccessAnalyticsBuildingUsage = useSelector(selectCanAccessAnalyticsBuildingUsage);
    const canAccessKnowledgeBase = useSelector(selectCanAccessKnowledgeBase);
    const canAccessShiftManagement = useSelector(selectCanAccessShiftManagement);
    const canAccessPlai = useSelector(selectCanAccessPlai);
    const canAccessInternalPlai = useSelector(selectCanAccessInternalPlai);
    const canAccessMapQuality = useSelector(selectCanAccessMapQuality);
    const isOmk = useSelector(selectIsOmk);

    // TODO export the routes to a separate component that gets a render prop with these route and use it to render both side menu and Router
    const menuItems = [
        { url: '/buildings', name: 'Buildings', icon: FaBuilding, isAllowed: canAccessBuildings },
        {
            url: '/map-content',
            name: 'Map Content',
            icon: MdFormatShapes,
            isAllowed: canAccessMapContent,
        },
        { url: '/monitor', name: 'Monitor', icon: MdTapAndPlay, isAllowed: canAccessMonitor },
        {
            url: '/mapping-center',
            name: 'Mapping Center',
            icon: BiSitemap,
            isAllowed: canAccessMapping,
            children: [
                {
                    url: '/mapping-center/mapping',
                    name: 'Mapping',
                    icon: GiPathDistance,
                    isAllowed: canAccessMapping,
                },
                {
                    url: '/mapping-center/map-quality',
                    name: 'Map Quality',
                    icon: MdOutlineHighQuality,
                    isAllowed: canAccessMapQuality,
                },
                {
                    url: '/mapping-center/shift-management',
                    name: 'Shift Management',
                    icon: MdInfoOutline,
                    isAllowed: canAccessShiftManagement,
                },
            ],
        },
        {
            name: 'Analytics',
            icon: FiBarChart2,
            isAllowed: canAccessAnalytics,
            children: [
                {
                    url: '/analytics/user-activity',
                    name: 'User Activity',
                    icon: FaWalking,
                    isAllowed: canAccessAnalyticsUserActivity,
                },
                // {
                //     url: '/analytics/building-usage',
                //     name: 'Building Usage',
                //     icon: FaChartBar,
                //     isAllowed: canAccessAnalyticsBuildingUsage,
                // },
                {
                    url: '/analytics/internal-plai',
                    name: 'PLAI',
                    icon: MdLocalGroceryStore,
                    isAllowed: canAccessInternalPlai && enableProductLocations,
                },
                {
                    url: '/analytics/external-plai',
                    name: 'PLAI',
                    icon: MdLocalGroceryStore,
                    isAllowed: canAccessPlai && enableProductLocations,
                },
                {
                    url: '/analytics/settings',
                    name: 'Settings',
                    icon: MdSettings,
                    isAllowed: canAccessAnalyticsUserActivity,
                },
            ],
        },
        {
            name: 'Account Management',
            icon: MdAccountCircle,
            isAllowed: canAccessAccountManagement,
            children: [
                {
                    url: '/account-management/api-keys',
                    name: 'API Keys',
                    icon: FaKey,
                    isAllowed: canEditApiKeys,
                },
                {
                    url: '/account-management/spaces',
                    name: 'Spaces',
                    icon: MdDashboard,
                    isAllowed: canEditSpaces,
                },
                {
                    url: '/account-management/buildings',
                    name: 'Buildings',
                    icon: BsBuilding,
                    isAllowed: canEditSpaces,
                },
                {
                    url: '/account-management/terms-and-conditions',
                    name: 'Terms & Conditions',
                    icon: GiFountainPen,
                    isAllowed: isOmk,
                },
            ],
        },
        {
            url: `${knowledgeBaseUrl}?api_key=${userApiKeyId}`,
            name: 'Knowledge Base',
            icon: MdLibraryBooks,
            isAllowed: canAccessKnowledgeBase,
            isExternal: true,
        },
    ];

    const [isMenuItemExpanded, setIsMenuItemExpanded] = useState(
        menuItems.reduce(
            (result, item) =>
                item.children
                    ? { ...result, [item.name]: item.children.some(({ url }) => url === pathname) }
                    : result,
            {}
        )
    );

    const renderMenuItemWithChildren = ({ name, icon: Icon, children }) => {
        return (
            <Fragment key={name}>
                <ListItem
                    id={getNavigationMenuItemId(name)}
                    className={getNavigationMenuItemClassName()}
                    button
                    onClick={() =>
                        setIsMenuItemExpanded({
                            ...isMenuItemExpanded,
                            [name]: !isMenuItemExpanded[name],
                        })
                    }
                >
                    <MenuItemIcon>
                        <Icon size={24} />
                    </MenuItemIcon>

                    <ListItemText primary={name} />

                    {isMenuItemExpanded[name] ? <MdExpandLess size={24} /> : <MdExpandMore size={24} />}
                </ListItem>

                <Collapse in={isOpen && isMenuItemExpanded[name]} timeout={'auto'} unmountOnExit>
                    <List component={'div'} disablePadding dense>
                        {children
                            .filter(({ isAllowed }) => isAllowed)
                            .map(({ url, name, icon: Icon }) => (
                                <NavLink key={name} to={url} activeClassName={'active'}>
                                    <IndentedListItem
                                        id={getNavigationMenuItemId(name)}
                                        className={getNavigationMenuItemClassName()}
                                    >
                                        <MenuItemIcon>
                                            <Icon size={16} />
                                        </MenuItemIcon>
                                        <ListItemText primary={name} />
                                    </IndentedListItem>
                                </NavLink>
                            ))}
                    </List>
                </Collapse>
            </Fragment>
        );
    };

    const renderMenuItemWithExternalLink = ({ url, name, icon: Icon }) => (
        <a key={name} href={url} target={'_blank'} rel={'noopener noreferrer'}>
            <ListItem id={getNavigationMenuItemId(name)} className={getNavigationMenuItemClassName()}>
                <MenuItemIcon>
                    <Icon size={24} />
                </MenuItemIcon>

                <ListItemText primary={name} />

                <FiExternalLink />
            </ListItem>
        </a>
    );

    const renderMenuItemWithoutChildren = ({ url, name, icon: Icon }) => (
        <NavLink key={name} to={url} activeClassName={'active'}>
            <ListItem id={getNavigationMenuItemId(name)} className={getNavigationMenuItemClassName()}>
                <MenuItemIcon>
                    <Icon size={24} />
                </MenuItemIcon>

                <ListItemText primary={name} />
            </ListItem>
        </NavLink>
    );

    const renderMenuItem = (menuItem) => {
        if (menuItem.children) {
            return renderMenuItemWithChildren(menuItem);
        } else if (menuItem.isExternal) {
            return renderMenuItemWithExternalLink(menuItem);
        }

        return renderMenuItemWithoutChildren(menuItem);
    };

    return (
        <SideDrawer variant={'permanent'} isOpen={isOpen}>
            <MenuItemsWrapper>
                {menuItems.filter(({ isAllowed }) => isAllowed).map((menuItem) => renderMenuItem(menuItem))}
            </MenuItemsWrapper>

            <Divider />
        </SideDrawer>
    );
}

NavigationMenu.propTypes = {
    isOpen: PropTypes.bool.isRequired,
};
