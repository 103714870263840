import { env } from './env';
export default {
    logLevel: window.oriientLogLevel || env.REACT_APP_LOG_LEVEL || 10,
    dashboardServer: env.REACT_APP_DASHBOARD_SERVER_URL,
    restApiServer: env.REACT_APP_REST_API_URL,
    knowledgeBaseUrl: env.REACT_APP_KNOWLEDGE_BASE_URL,
    mappingRestApiUrl: env.REACT_APP_MAPPING_REST_API_URL,
    authServiceUrl: env.REACT_APP_AUTH_SERVICE_URL,
    eventsManagerUrl: env.REACT_APP_EVENTS_MANAGER_URL,
    analyticsRestUrl: env.REACT_APP_ANALYTICS_REST_URL,
    ipsDomainForAuthService: env.REACT_APP_IPS_DOMAIN_FOR_AUTH_SERVICE || 'wss://ipsdev.oriient.me',
    realTimeServerUrl: env.REACT_APP_REAL_TIME_SERVER_URL || 'wss://api.dev.oriient.me/ips',
    defaultRegionWidth: env.DEFAULT_REGION_WIDTH || 10, //meters
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyCx-8MpHBe63RJ5bq3dpaM5F-_MbccgRF8',
    enableReduxLogger: env.REACT_APP_ENABLE_REDUX_LOGGER === 'true',
    enableLogRocket: env.REACT_APP_ENABLE_LOGROCKET === 'true',
    logRocketAppId: env.REACT_APP_LOGROCKET_APP_ID,
    shiftManagementInterval: env.REACT_APP_SHIFT_MANAGEMENT_INTERVAL || 5000000000,
    productLocationsApiUrl: env.REACT_APP_PRODUCT_LOCATIONS_URL,
    enableProductLocations: env.REACT_APP_ENABLE_PRODUCT_LOCATIONS === 'true',
    mapQualityUrl: env.REACT_APP_MAP_QUALITY_URL,
    pollingUserActivityInterval: env.POLLING_USER_ACTIVITY_INTERVAL || 10000,
    sessionsTimeoutDuration: env.SESSION_TIMEOUT_DURATION || 1000 * 60 * 1,
    captcha: {
        siteKey: env.REACT_APP_CAPTCHA_SITE_KEY || '6Leu7a4lAAAAAK4KO4c2Q8BguiZV7B4bQb_2CIql',
        timeout: Number(env.REACT_APP_CAPTCHA_TIMEOUT || 1000 * 60 * 32),
        numberOfAttempts: Number(env.REACT_APP_CAPTCHA_ATTEMPTS || 5),
    },
};
