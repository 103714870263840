import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './spaceEntityAdapter';
import {
    fetchAllSpaces,
    createSpace,
    updateSpace,
    deleteSpace,
    attachBuildingToSpace,
    detachBuildingFromSpace,
} from './spaceActions';
import { createTrigger, deleteTrigger, fetchAllTriggersInSpace } from '../trigger/triggerActions';
import {
    createRegion,
    deleteRegion,
    fetchAllRegionsInSpace,
    fetchRegionColorsInSpace,
} from '../region/regionActions';
import {
    createBuilding,
    fetchAllBuildingsInAllSpaces,
    fetchAllBuildingsInSpace,
} from '../building/buildingActions';
import { expireSession, logout } from '../auth/authActions';

const { getInitialState, getSelectors, upsertMany, upsertOne, addOne, removeOne } = entityAdapter;

export const { reducer } = createSlice({
    name: 'spaces',
    initialState: getInitialState({ isFetched: false }),
    reducers: {},
    extraReducers: {
        [fetchAllSpaces.fulfilled]: (state, action) => {
            upsertMany(state, action.payload);
            state.isFetched = true;
        },

        [createSpace.fulfilled]: (state, action) => {
            addOne(state, {
                spaceId: action.payload.spaceId,
                spaceDesc: action.payload.spaceDesc,
                bundleIds: action.payload.bundleIds,
                buildingIds: [],
            });
        },

        [updateSpace.fulfilled]: (state, action) => {
            upsertOne(state, action.payload);
        },

        [deleteSpace.fulfilled]: (state, action) => {
            removeOne(state, action.meta.arg);
        },

        [createBuilding.fulfilled]: (state, action) => {
            const { spaceId } = action.meta.arg;
            const { buildingId } = action.payload;
            const { buildingIds = [] } = getSelectors().selectById(state, spaceId);
            upsertOne(state, { spaceId, buildingIds: [...new Set([...buildingIds, buildingId])] });
        },

        [attachBuildingToSpace.fulfilled]: (state, action) => {
            const { spaceId, buildingId } = action.meta.arg;
            const { buildingIds = [] } = getSelectors().selectById(state, spaceId);
            upsertOne(state, { spaceId, buildingIds: [...new Set([...buildingIds, buildingId])] });
        },

        [detachBuildingFromSpace.fulfilled]: (state, action) => {
            const { spaceId, buildingId } = action.meta.arg;
            const { buildingIds = [] } = getSelectors().selectById(state, spaceId);
            upsertOne(state, { spaceId, buildingIds: buildingIds.filter((id) => id !== buildingId) });
        },

        [fetchAllBuildingsInSpace.fulfilled]: (state, action) => {
            const spaceId = action.meta.arg;
            upsertOne(state, { spaceId, buildingIds: action.payload.map((b) => b.buildingId) });
        },

        [fetchAllBuildingsInAllSpaces.fulfilled]: (state, action) => {
            upsertMany(
                state,
                action.payload.map(({ spaceId, buildings }) => ({
                    spaceId,
                    buildingIds: buildings.map((b) => b.buildingId),
                }))
            );
        },

        [fetchAllRegionsInSpace.fulfilled]: (state, action) => {
            const { spaceId } = action.meta.arg;
            upsertOne(state, { spaceId, regionIds: action.payload.map((r) => r.regionId) });
        },

        [fetchRegionColorsInSpace.fulfilled]: (state, action) => {
            const { spaceId } = action.meta.arg;
            upsertOne(state, { spaceId, regionColors: action.payload });
        },

        [createRegion.fulfilled]: (state, action) => {
            const { regionId } = action.payload;
            const { spaceId } = action.meta.arg;
            const { regionIds = [] } = getSelectors().selectById(state, spaceId);
            upsertOne(state, { spaceId, regionIds: [...new Set([...regionIds, regionId])] });
        },

        [deleteRegion.fulfilled]: (state, action) => {
            const regionId = action.meta.arg;
            const allSpaces = getSelectors().selectAll(state);
            upsertMany(
                state,
                allSpaces.map(({ spaceId, regionIds }) => ({
                    spaceId,
                    regionIds: regionIds?.filter((id) => id !== regionId),
                }))
            );
        },

        [fetchAllTriggersInSpace.fulfilled]: (state, action) => {
            const { spaceId } = action.meta.arg;
            upsertOne(state, { spaceId, triggerIds: action.payload.map((t) => t.triggerId) });
        },

        [createTrigger.fulfilled]: (state, action) => {
            const { triggerId } = action.payload;
            const { spaceId } = action.meta.arg;
            const { triggerIds = [] } = getSelectors().selectById(state, spaceId);
            upsertOne(state, { spaceId, triggerIds: [...new Set([...triggerIds, triggerId])] });
        },

        [deleteTrigger.fulfilled]: (state, action) => {
            const triggerId = action.meta.arg;
            const allSpaces = getSelectors().selectAll(state);
            upsertMany(
                state,
                allSpaces.map(({ spaceId, triggerIds }) => ({
                    spaceId,
                    triggerIds: triggerIds?.filter((id) => id !== triggerId),
                }))
            );
        },

        [expireSession.fulfilled]: () => getInitialState({ isFetched: false }),
        [logout.fulfilled]: () => getInitialState({ isFetched: false }),
    },
});
