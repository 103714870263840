import MaterialTable, { MTableToolbar, MTableBodyRow, MTableHeader } from '@material-table/core';
import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    deleteCoordinate,
    highlightCoordinate,
    selectCoordinate,
    unhighlightCoordinate,
    unselectCoordinate,
    updateCoordinate,
} from '../../../../state-management/coordinates-conversion/coordinatesConversionReducer';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { selectBuildingsSelectedMapId } from '../../../../state-management/user-inputs/buildingsSlice';
import { useCoordinatesConversion } from '../../../common/hooks/data-fetching/useCoordinatesConversions';
import { Checkbox } from '../../../common/themed';

const ToolbarContainer = styled.div`
    & h6 {
        text-transform: uppercase;
        color: #ff6200;
        font-size: 15px;
    }
`;

const Wrapper = styled.div`
    & table tr[mode='delete'] td {
        & h6 {
            font-size: 1.05rem;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: 0.0075em;
            padding: 0;
        }
        &:last-child > div {
            margin-left: -22px;
        }
    }
`;
const RowWrapper = styled(MTableBodyRow)`
    transition: 0.2s all ease;
    position: relative;
    font-size: 16px;
    & > td:first-child::before {
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        content: '';
        background: ${(props) => props.rowColor};
        width: 5px;
        height: 30px;
        border-radius: 5px;
        box-shadow: 2px 2px 3px 0px #00000087;
    }
    background: ${(props) => (props.selected ? '#ffa56c5e' : 'white')} !important;
    ${(props) =>
        props.isRedRow &&
        `
    background:#f36666 !important;
    & *{
        color:white !important;
    }
     `}

    ${(p) =>
        p.highlighted &&
        `
     background: ${p.isRedRow ? '#d64e4e' : '#eee'} !important;
     & td {
         color: ${p.isRedRow ? 'white' : 'black'} !important;
     }
     & svg {
         color: ${p.isRedRow ? 'white' : '#ff6200'} !important;
     }
     `}
    &:hover {
        cursor: pointer;
        background: ${(props) => (props.isRedRow ? '#d64e4e' : '#eee')} !important;
        & td {
            color: ${(props) => (props.isRedRow ? 'white' : 'black')} !important;
        }
        & svg {
            color: ${(props) => (props.isRedRow ? 'white' : '#ff6200')} !important;
        }
    }
    & > td {
        padding: 8px 16px;
        & h6 {
            color: red;
        }
    }
`;

export function CoordinatesConversionTable() {
    const columnsNames = [
        { title: 'Oriient', field: 'oriient', editable: 'never' },
        { title: 'Customer', field: 'customer' },
        { title: 'Error', field: 'errorDistance', editable: 'never' },
    ];

    const columns = columnsNames.map((c) => ({ ...c, align: 'left', width: 120 }));

    const selectedMapId = useSelector(selectBuildingsSelectedMapId);

    const { data = [], selectedCoordinates, highlightedCoordinate } = useCoordinatesConversion(selectedMapId);
    const dispatch = useDispatch();
    const handleHover = (coordinateId, isEditing) => {
        if (!isEditing) {
            dispatch(highlightCoordinate(coordinateId));
        }
    };
    const handleHoverLeave = (coordinateId, isEditing) => {
        if (!isEditing) {
            dispatch(unhighlightCoordinate(coordinateId));
        }
    };
    const selectAllRows = (e) => {
        data.forEach((c) => {
            if (e.target.checked) {
                dispatch(selectCoordinate(c.coordinateId));
            } else {
                dispatch(unselectCoordinate(c.coordinateId));
            }
        });
    };
    const coordinates = useMemo(
        () =>
            data.map((c) => ({
                oriient: `${c.oriient?.x?.toFixed(2)} , ${c.oriient?.y?.toFixed(2)}`,
                customer: `${c.customer?.x?.toFixed(2)} , ${c.customer?.y?.toFixed(2)}`,
                errorDistance: c.errorDistance?.toFixed(2),
                color: c.color,
                coordinateId: c.coordinateId,
            })),
        [selectedMapId, data]
    );

    return (
        <Wrapper>
            <MaterialTable
                title={'COORDINATES CONVERSION'}
                columns={[
                    {
                        type: 'boolean',
                        editable: false,
                        width: 50,
                        sorting: false,
                        title: (
                            <Checkbox
                                onChange={selectAllRows}
                                checked={
                                    selectedCoordinates.length === data.length &&
                                    selectedCoordinates.length > 0
                                }
                                indeterminate={
                                    selectedCoordinates.length > 0 && selectedCoordinates.length < data.length
                                }
                            />
                        ),
                        render: (row) => (
                            <Checkbox checked={!!selectedCoordinates.includes(row.coordinateId)} />
                        ),
                    },
                    ...columns,
                ]}
                components={{
                    Toolbar: (props) => (
                        <ToolbarContainer>
                            <MTableToolbar {...props} />
                        </ToolbarContainer>
                    ),
                    Row: (props) => (
                        <RowWrapper
                            {...props}
                            selected={selectedCoordinates.includes(props.data.coordinateId)}
                            highlighted={highlightedCoordinate === props.data.coordinateId}
                            isRedRow={props.data.errorDistance >= 0.3}
                            onPointerEnter={() =>
                                handleHover(props.data.coordinateId, props.hasAnyEditingRow)
                            }
                            onPointerLeave={() =>
                                handleHoverLeave(props.data.coordinateId, props.hasAnyEditingRow)
                            }
                            rowColor={props.data.color}
                        ></RowWrapper>
                    ),
                }}
                options={{
                    search: false,
                    actionsColumnIndex: 1,

                    maxBodyHeight: '360px',
                }}
                data={coordinates}
                editable={{
                    isEditable: (row) => !row.errorDistance || row.errorDistance < 0.3,
                    onRowDelete: (row) => {
                        dispatch(deleteCoordinate({ mapId: selectedMapId, coordinateId: row.coordinateId }));
                        return Promise.resolve();
                    },
                    onRowUpdate: (newRow, oldRow) => {
                        return new Promise((resolve, reject) => {
                            const { customer, coordinateId } = newRow;
                            const values = customer.split(',');
                            if (values.length === 2) {
                                const x = values[0].trim();
                                const y = values[1].trim();
                                if (!isNaN(x) && !isNaN(y)) {
                                    dispatch(
                                        updateCoordinate({
                                            mapId: selectedMapId,
                                            coordinateId,
                                            newData: { customer: { x: +x, y: +y } },
                                        })
                                    );
                                    dispatch(showSuccessNotification('Row updated'));
                                    return resolve();
                                }
                            }
                            dispatch(showErrorNotification('Format should be number,number'));
                            return reject();
                        });
                    },
                }}
                onRowClick={(e, row) => {
                    if (selectedCoordinates.includes(row.coordinateId)) {
                        dispatch(unselectCoordinate(row.coordinateId));
                    } else {
                        dispatch(selectCoordinate(row.coordinateId));
                    }
                }}
                style={{ boxShadow: 'none' }}
            ></MaterialTable>
        </Wrapper>
    );
}
