import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { IconButton, RootRef, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { selectIsLoading } from '../../../state-management/status/statusSelectors';
import { CircularPreloader } from '../../common/themed';
import { selectMappingSelectedMapId } from '../../../state-management/user-inputs/mappingSlice';
import {
    toggleSuperMapper,
    toggleSuspendMapper,
} from '../../../state-management/mapping/mapper/mapperActions';
import {
    getMapperListEntryId,
    getMapperListEntryNameId,
    getMapperListEntryToggleSuperMapperId,
    getMapperListEntryToggleSuspendMapperId,
} from '../Mapping.selectors';
import {
    CgChevronDoubleUpO as EnableSuperMapperIcon,
    CgChevronDoubleDownO as DisableSuperMapperIcon,
    CgPlayStopO as SuspendMapperIcon,
    CgPlayButtonO as ResumeMapperIcon,
} from 'react-icons/cg';
import { GiRank3, GiStopSign } from 'react-icons/gi';
import Tooltip from '../../common/themed/Tooltip';
import ItemsListEntry from '../../common/side-drawer/ItemsListEntry';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as ArrowSvg } from '../../../assets/oriientArrow.svg';

const SuperMapperIcon = styled(GiRank3)`
    display: flex;
    margin-inline-start: 5px;
`;

const SuspendedMapperIcon = styled(GiStopSign)`
    display: flex;
    margin-inline-start: 5px;
`;

const IconWrapper = forwardRef((props, ref) => (
    <RootRef rootRef={ref}>
        <IconButton {...props} />
    </RootRef>
));

const MapperCircle = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background-color: ${(props) => props.$color};
    margin-inline-start: 5px;
    margin-inline-end: 5px;
`;

const MapperArrow = styled(({ $heading, $color, ...otherProps }) => <ArrowSvg {...otherProps} />)`
    max-height: 25px;
    max-width: 30px;

    #arrow {
        fill: ${(props) => props.$color};
    }
`;

export default function MapperListEntry(props) {
    const { mapper, ...otherProps } = props;
    const { mapperId, mapperName, isSuperMapper, isMapperSuspended, color, position } = mapper ?? {};

    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const isTogglingSuperMapper = useSelector(
        selectIsLoading([
            {
                type: toggleSuperMapper.typePrefix,
                arg: { mapId: selectedMapId, mapperId, isSuperMapper: true },
            },
            {
                type: toggleSuperMapper.typePrefix,
                arg: { mapId: selectedMapId, mapperId, isSuperMapper: false },
            },
        ])
    );

    const isTogglingMapperSuspend = useSelector(
        selectIsLoading([
            {
                type: toggleSuspendMapper.typePrefix,
                arg: { mapId: selectedMapId, mapperId, isMapperSuspended: true },
            },
            {
                type: toggleSuspendMapper.typePrefix,
                arg: { mapId: selectedMapId, mapperId, isMapperSuspended: false },
            },
        ])
    );

    const handleToggleSuperMapper = () => {
        dispatch(
            toggleSuperMapper({
                mapId: selectedMapId,
                mapperId,
                isSuperMapper: !isSuperMapper,
            })
        );
    };

    const handleToggleSuspendMapper = () => {
        dispatch(
            toggleSuspendMapper({
                mapId: selectedMapId,
                mapperId,
                isMapperSuspended: !isMapperSuspended,
            })
        );
    };

    const renderMapperMarker = () => {
        if (!color || !position) {
            return null;
        }

        if (position?.source === 'ar') {
            return <MapperCircle $color={color} />;
        } else if (position?.source === 'user') {
            return <MapperArrow $color={color} />;
        } else {
            return null;
        }
    };

    if (!mapper) {
        return null;
    }

    return (
        <ItemsListEntry
            id={getMapperListEntryId(mapperId)}
            content={
                <Grid container alignItems={'center'}>
                    <Typography id={getMapperListEntryNameId(mapperId)} variant={'subtitle1'}>
                        {mapperName}
                    </Typography>

                    {renderMapperMarker()}

                    {isSuperMapper && (
                        <Tooltip content={'Super mapper'} placement={'bottom'} useWrapper>
                            <SuperMapperIcon size={25} />
                        </Tooltip>
                    )}
                    {isMapperSuspended && (
                        <Tooltip content={'Mapper suspended'} placement={'bottom'} useWrapper>
                            <SuspendedMapperIcon size={25} />
                        </Tooltip>
                    )}
                </Grid>
            }
            actions={
                <Grid container alignItems={'center'}>
                    <Tooltip
                        content={isSuperMapper ? 'Disable super mapper' : 'Enable super mapper'}
                        placement={'bottom'}
                        useWrapper={false}
                    >
                        <IconWrapper
                            id={getMapperListEntryToggleSuperMapperId(mapperId)}
                            onClick={handleToggleSuperMapper}
                            aria-label={'Toggle super mapper'}
                            disabled={isTogglingSuperMapper}
                        >
                            {isTogglingSuperMapper ? (
                                <CircularPreloader size={25} />
                            ) : isSuperMapper ? (
                                <DisableSuperMapperIcon />
                            ) : (
                                <EnableSuperMapperIcon />
                            )}
                        </IconWrapper>
                    </Tooltip>
                    <Tooltip
                        content={isMapperSuspended ? 'Resume mapper' : 'Suspend mapper'}
                        placement={'bottom'}
                        useWrapper={false}
                    >
                        <IconWrapper
                            id={getMapperListEntryToggleSuspendMapperId(mapperId)}
                            onClick={handleToggleSuspendMapper}
                            aria-label={'Toggle suspend mapper'}
                            disabled={isTogglingMapperSuspend}
                        >
                            {isTogglingMapperSuspend ? (
                                <CircularPreloader size={25} />
                            ) : isMapperSuspended ? (
                                <ResumeMapperIcon />
                            ) : (
                                <SuspendMapperIcon />
                            )}
                        </IconWrapper>
                    </Tooltip>
                </Grid>
            }
            {...otherProps}
        />
    );
}

MapperListEntry.propTypes = {
    mapper: PropTypes.object.isRequired,
};
