import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import {
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../constants/mapViewerVariables';
import { MapViewerContext } from '../../../common/map-viewer/MapViewerContext';
import { useDispatch } from 'react-redux';
import {
    highlightCoordinate,
    unhighlightCoordinate,
} from '../../../../state-management/coordinates-conversion/coordinatesConversionReducer';

const Circle = styled.circle`
    transition: 0.3s all ease;
    filter: drop-shadow(1px 4px 3px transparent);
    fill: ${(p) => (p.isHighlighted || p.isSelected ? darken(0.1, p.selectedColor) : p.selectedColor)};

    box-sha &:hover {
        fill: ${(props) => darken(0.1, props.selectedColor)};
    }
`;

const CalculatedCircle = styled.circle`
    stroke: ${(p) => lighten(0.1, p.selectedColor)};
    fill: none;
    stroke-width: 4px;
`;
export const Coordinate = (props) => {
    const {
        oriient,
        color,
        isHighlighted = false,
        isSelected = false,
        coordinateId,
        oriientCalculated,
    } = props;
    const { getCSSVariable } = useContext(MapViewerContext);
    const dispatch = useDispatch();
    const handleHover = () => {
        dispatch(highlightCoordinate(coordinateId));
    };
    const handleHoverLeave = () => {
        dispatch(unhighlightCoordinate(coordinateId));
    };
    const radius = 15;
    const { x, y } = useMemo(() => {
        const mapOffsetX = getCSSVariable(OFFSET_X);
        const mapOffsetY = getCSSVariable(OFFSET_Y);
        const pixelToMeter = getCSSVariable(PIXEL_TO_METER);
        const imageNaturalHeight = getCSSVariable(IMAGE_NATURAL_HEIGHT);

        return {
            x: (oriient?.x - mapOffsetX) * pixelToMeter,
            y: (imageNaturalHeight / pixelToMeter - (oriient?.y - mapOffsetY)) * pixelToMeter,
        };
    }, [getCSSVariable, oriient]);
    const { CalculatedX, CalculatedY } = useMemo(() => {
        const mapOffsetX = getCSSVariable(OFFSET_X);
        const mapOffsetY = getCSSVariable(OFFSET_Y);
        const pixelToMeter = getCSSVariable(PIXEL_TO_METER);
        const imageNaturalHeight = getCSSVariable(IMAGE_NATURAL_HEIGHT);

        return {
            CalculatedX: (oriientCalculated?.x - mapOffsetX) * pixelToMeter,
            CalculatedY:
                (imageNaturalHeight / pixelToMeter - (oriientCalculated?.y - mapOffsetY)) * pixelToMeter,
        };
    }, [getCSSVariable, oriientCalculated]);
    return (
        oriient && (
            <>
                {oriientCalculated && (
                    <CalculatedCircle
                        cx={CalculatedX}
                        cy={CalculatedY}
                        opacity={0.9}
                        stroke={color}
                        stroke-width="4"
                        r={radius}
                        isHighlighted={isHighlighted}
                        isSelected={isSelected}
                        selectedColor={color || 'red'}
                        onPointerEnter={handleHover}
                        onPointerLeave={handleHoverLeave}
                    />
                )}
                {(isHighlighted || isSelected) && (
                    <circle r={radius} fill="none" stroke={color} stroke-width="2">
                        <animate
                            attributeName="r"
                            values={`${radius};${radius + 3};${radius}`}
                            dur="1.5s"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="stroke-width"
                            values="3;2;3"
                            dur="1.5s"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="opacity"
                            values="0.3;1;0.3"
                            dur="1.5s"
                            repeatCount="indefinite"
                        />
                    </circle>
                )}
                <Circle
                    cx={x}
                    cy={y}
                    opacity={0.9}
                    stroke={color}
                    stroke-width="4"
                    r={radius}
                    isHighlighted={isHighlighted}
                    isSelected={isSelected}
                    selectedColor={color || 'red'}
                    onPointerEnter={handleHover}
                    onPointerLeave={handleHoverLeave}
                />
                {(isHighlighted || isSelected) && (
                    <circle cx={x} cy={y} r={radius} fill="none" stroke={color} stroke-width="2">
                        <animate
                            attributeName="r"
                            values={`${radius};${radius + 3};${radius}`}
                            dur="1.5s"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="stroke-width"
                            values="3;2;3"
                            dur="1.5s"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="opacity"
                            values="0.3;1;0.3"
                            dur="1.5s"
                            repeatCount="indefinite"
                        />
                    </circle>
                )}
            </>
        )
    );
};
