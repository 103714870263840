import { createAsyncThunk } from '../../utils';
import exitRegionsApi from '../../../api/exitRegionsApi';

export const fetchAllExitRegionsInMap = createAsyncThunk(
    'exitRegions/fetchAllInMap',
    async ({ buildingId, floorId, mapId }) => {
        return (await exitRegionsApi.fetchAllExitRegionsByMap({ buildingId, floorId, mapId })).data;
    }
);

export const upsertExitRegion = createAsyncThunk(
    'exitRegions/update',
    async ({ buildingId, floorId, mapId, exitRegion }) => {
        return (await exitRegionsApi.upsertExitRegions({ buildingId, floorId, mapId }, exitRegion)).data;
    }
);

export const deleteExitRegion = createAsyncThunk(
    'exitRegions/delete',
    async ({ buildingId, floorId, mapId, exitRegionId }) => {
        return (await exitRegionsApi.deleteExitRegion({ buildingId, floorId, mapId, exitRegionId })).data;
    }
);
