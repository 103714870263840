import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './userEntityAdapter';
import { fetchAllUsersAttachedToApiKey, createUser, deleteUser, fetchAllUsers } from './userActions';
import { expireSession, logout } from '../auth/authActions';

const { getInitialState, upsertMany, upsertOne, removeOne, setAll } = entityAdapter;

export const { reducer } = createSlice({
    name: 'users',
    initialState: getInitialState({ isFetched: false }),
    reducers: {},
    extraReducers: {
        [fetchAllUsers.fulfilled]: (state, action) => {
            setAll(
                state,
                action.payload.reduce(
                    (result, { users }) => [
                        ...result,
                        ...users.map((u) => ({ userId: u.userId, userName: u.username })),
                    ],
                    []
                )
            );
            state.isFetched = true;
        },

        [fetchAllUsersAttachedToApiKey.fulfilled]: (state, action) => {
            upsertMany(
                state,
                action.payload.map((user) => ({
                    userId: user.userId,
                    userName: user.username,
                }))
            );
        },

        [createUser.fulfilled]: (state, action) => {
            upsertOne(state, {
                userId: action.payload.userId,
                userName: action.payload.username,
            });
        },

        [deleteUser.fulfilled]: (state, action) => {
            const userId = action.meta.arg;
            removeOne(state, userId);
        },

        [expireSession.fulfilled]: () => getInitialState({ isFetched: false }),
        [logout.fulfilled]: () => getInitialState({ isFetched: false }),
    },
});
