import React, { useState } from 'react';
import styled from 'styled-components';
import SpaceDetailsCard from './SpaceDetailsCard';
import ActionsToolbar from '../common/ActionsToolbar';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { MdClose } from 'react-icons/md';
import useSpaces from '../../common/hooks/data-fetching/useSpaces';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';
import { Skeleton } from '../../common/themed';
import SpaceEditor from './SpaceEditor';
import { fetchAllSpaces } from '../../../state-management/space/spaceActions';

const DialogCloseButtonWrapper = styled(IconButton)`
    top: 8px;
    right: 8px;
    color: #9e9e9e;
    position: absolute;
`;

export default function SpacesManagement() {
    const [filter, setFilter] = useState('');
    const [isNewSpaceDialogOpen, setIsNewSpaceDialogOpen] = useState(false);

    const dispatch = useDispatch();

    const { data: spaces, isLoading, hasError } = useSpaces();

    if (hasError) {
        return <ErrorGeneral />;
    }

    return (
        <>
            <ActionsToolbar
                onCreate={() => setIsNewSpaceDialogOpen(true)}
                onRefresh={() => dispatch(fetchAllSpaces())}
                filter={filter}
                onFilterChange={setFilter}
            />

            {isLoading
                ? Array.from({ length: 10 }, (_, index) => (
                    <Skeleton
                        key={`space-card-skeleton-${index}`}
                        height={70}
                        style={{ marginBlockEnd: 2 }}
                    />
                ))
                : spaces
                    ?.filter(
                        (space) =>
                            space.spaceDesc?.toLowerCase().includes(filter.toLowerCase()) ||
                            space.spaceId?.toLowerCase().includes(filter.toLowerCase())
                    )
                    ?.map((space) => <SpaceDetailsCard key={space.spaceId} space={space} />)}

            <Dialog
                open={isNewSpaceDialogOpen}
                maxWidth={'lg'}
                fullWidth
                onClose={() => setIsNewSpaceDialogOpen(false)}
            >
                <DialogContent>
                    <DialogCloseButtonWrapper onClick={() => setIsNewSpaceDialogOpen(false)}>
                        <MdClose />
                    </DialogCloseButtonWrapper>

                    <SpaceEditor onFormSubmitted={() => setIsNewSpaceDialogOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    );
}
