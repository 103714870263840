import Flatpickr from 'react-flatpickr';
import { TextField } from './index';
import React from 'react';

export default ({ inputProps, ...otherProps }) => (
    <Flatpickr
        {...otherProps}
        render={(props, ref) => {
            return <TextField inputRef={ref} fullWidth {...inputProps} />;
        }}
    />
);
