import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Divider as MuiDivider, FormControlLabel, Grid } from '@material-ui/core';
import {
    Button,
    CircularPreloader as ThemedCircularPreloader,
    Skeleton,
    Switch,
    TextField,
    Tooltip,
} from '../common/themed';
import useEntrances from '../common/hooks/data-fetching/useEntrances';
import {
    selectBuildingsSelectedBuildingId,
    selectBuildingsSelectedEntranceCoordinates,
    selectBuildingsSelectedEntranceId,
    selectBuildingsSelectedFloorId,
    setIsSelectedEntranceEditable,
    setSelectedEntranceId,
} from '../../state-management/user-inputs/buildingsSlice';
import ItemDetailsSideDrawer from '../common/item-details-side-drawer';
import EntranceActions from './side-menu/entrances/EntranceActions';
import styled from 'styled-components';
import { getSelectMainEntranceIdInFloor } from '../../state-management/entrance/entranceSelectors';
import { isFulfilled } from '../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../state-management/notification/notificationReducer';
import { updateEntrance } from '../../state-management/entrance/entranceActions';
import { getEntranceDescriptionId, getEntranceIsMainId } from './buildings.selectors';
import { selectCanEditEntrances } from '../../state-management/auth/authSelectors';

const Divider = styled(MuiDivider)`
    margin-block-start: 15px;
    margin-block-end: 15px;
`;

const FormControls = styled.div`
    margin-block-start: 10px;
`;

const CircularPreloader = styled(ThemedCircularPreloader)`
    padding: 0 10px;
`;

export default function EntranceDetailsSideDrawer() {
    const dispatch = useDispatch();

    const canEditEntrances = useSelector(selectCanEditEntrances);
    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const selectedFloorId = useSelector(selectBuildingsSelectedFloorId);
    const selectedEntranceId = useSelector(selectBuildingsSelectedEntranceId);
    const entranceCoordinates = useSelector(selectBuildingsSelectedEntranceCoordinates);

    const selectMainEntranceInFloor = useMemo(
        () => getSelectMainEntranceIdInFloor(selectedFloorId),
        [selectedFloorId]
    );

    const mainEntranceId = useSelector(selectMainEntranceInFloor);

    const { data: entrance = {}, isLoading } = useEntrances({
        buildingId: selectedBuildingId,
        floorId: selectedFloorId,
        entranceId: selectedEntranceId,
    });
    const { coordinates, description, isMain } = entrance ?? {};

    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        formState: { isDirty, isSubmitting, errors },
    } = useForm({
        defaultValues: {
            description: description ?? '',
            isMain: isMain ?? false,
            coordinates: { x: coordinates?.x ?? 0, y: coordinates?.y ?? 0 },
        },
    });

    const submitForm = async (data) => {
        const result = await dispatch(
            updateEntrance({
                buildingId: selectedBuildingId,
                floorId: selectedFloorId,
                entranceId: selectedEntranceId,
                entranceData: {
                    coordinates: data.coordinates,
                    description: data.description,
                    isMain: data.isMain,
                },
            })
        );

        if (isFulfilled(result)) {
            dispatch(setIsSelectedEntranceEditable(false));
            dispatch(showSuccessNotification('Successfully updated entrance.'));
        } else {
            dispatch(showErrorNotification('Failed to update entrance.'));
        }
    };

    const handleCancel = () => {
        reset();
        dispatch(setIsSelectedEntranceEditable(false));
    };

    useEffect(() => {
        setValue('coordinates.x', entranceCoordinates?.x ?? coordinates?.x, { shouldDirty: true });
        setValue('coordinates.y', entranceCoordinates?.y ?? coordinates?.y, { shouldDirty: true });
    }, [coordinates, entranceCoordinates, setValue]);

    useEffect(() => {
        reset({
            description: description ?? '',
            isMain: isMain ?? false,
            coordinates: coordinates ?? { x: 0, y: 0 },
        });
    }, [description, isMain, coordinates, reset]);

    return (
        <ItemDetailsSideDrawer
            isOpen={!!selectedEntranceId}
            onBack={() => dispatch(setSelectedEntranceId(null))}
            title={selectedEntranceId ?? ''}
            isTitleCopyable
            content={
                <>
                    <EntranceActions
                        buildingId={selectedBuildingId}
                        floorId={selectedFloorId}
                        entranceId={selectedEntranceId}
                        showShowInfo={false}
                    />

                    <Divider />

                    <Grid container direction={'column'}>
                        <form onSubmit={handleSubmit(submitForm)}>
                            {isLoading ? (
                                <Skeleton height={15} width={230} />
                            ) : (
                                <>
                                    <Controller
                                        name={'coordinates.x'}
                                        control={control}
                                        render={() => null}
                                    />

                                    <Controller
                                        name={'coordinates.y'}
                                        control={control}
                                        render={() => null}
                                    />

                                    <Grid item>
                                        <Controller
                                            id={getEntranceDescriptionId()}
                                            name={'description'}
                                            control={control}
                                            defaultValue={description ?? ''}
                                            rules={{ required: 'This is required' }}
                                            render={({ onChange, value }) => (
                                                <TextField
                                                    name={'description'}
                                                    label={'Description'}
                                                    inputRef={register}
                                                    multiline
                                                    fullWidth
                                                    error={!!errors?.description}
                                                    helperText={errors.description?.message}
                                                    variant={'outlined'}
                                                    margin={'dense'}
                                                    value={value}
                                                    onChange={onChange}
                                                    disabled={!canEditEntrances}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Controller
                                        id={getEntranceIsMainId()}
                                        control={control}
                                        name={'isMain'}
                                        defaultValue={isMain ?? false}
                                        render={({ onChange, value }) => (
                                            <Tooltip
                                                content={'There is already a primary entrance on this floor'}
                                                enabled={
                                                    !!mainEntranceId && mainEntranceId !== selectedEntranceId
                                                }
                                            >
                                                <FormControlLabel
                                                    label={'Primary entrance'}
                                                    disabled={
                                                        !canEditEntrances ||
                                                        (!!mainEntranceId &&
                                                            mainEntranceId !== selectedEntranceId)
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={value}
                                                            onChange={(e) => onChange(e.target.checked)}
                                                        />
                                                    }
                                                />
                                            </Tooltip>
                                        )}
                                    />

                                    {isDirty && (
                                        <Grid
                                            item
                                            container
                                            justifyContent={'flex-end'}
                                            spacing={1}
                                            component={FormControls}
                                        >
                                            <Grid item>
                                                <Button variant={'text'} onClick={handleCancel}>
                                                    Cancel
                                                </Button>
                                            </Grid>

                                            {isSubmitting ? (
                                                <CircularPreloader size={35} />
                                            ) : (
                                                <Grid item>
                                                    <Button type={'submit'}>Save</Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                </>
                            )}
                        </form>
                    </Grid>
                </>
            }
        />
    );
}
