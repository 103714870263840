import { createAsyncThunk } from '../../utils';
import junctionMismatchesApi from '../../../api/JunctionMismatchesApi';
import uuid from 'uuid/v4';
import { createAction } from '@reduxjs/toolkit';

export const fetchAllJunctionMismatchesInMap = createAsyncThunk(
    'junctionMismatches/fetchAllInMap',
    async ({ mapId, useEqualizedMap }) => {
        const response = (await junctionMismatchesApi.fetchAllJunctionMismatchesInMap(mapId, useEqualizedMap))
            .data;
        return response.map((jm) => ({ ...jm, junctionId: uuid() }));
    }
);

export const toggleJunctionMismatchesInMap = createAction('junctionMismatches/toggle');
