import { createAsyncThunk } from '../../utils';
import linesOfInterestApi from '../../../api/LinesOfInterestApi';

export const fetchAllLinesOfInterestInMap = createAsyncThunk(
    'linesOfInterest/fetchAllInMap',
    async (mapId) => {
        return (await linesOfInterestApi.fetchAllLinesOfInterestInMap(mapId)).data;
    }
);

export const createLineOfInterest = createAsyncThunk(
    'linesOfInterest/create',
    async ({ mapId, lineData }) => {
        return (await linesOfInterestApi.createLineOfInterest(mapId, lineData)).data;
    }
);

export const updateLineOfInterest = createAsyncThunk(
    'linesOfInterest/update',
    async ({ mapId, lineId, lineData }) => {
        return (await linesOfInterestApi.updateLineOfInterest(mapId, lineId, lineData)).data;
    }
);

export const deleteLineOfInterest = createAsyncThunk('linesOfInterest/delete', async ({ mapId, lineId }) => {
    await linesOfInterestApi.deleteLineOfInterest(mapId, lineId);
});
