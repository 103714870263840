export const falsyCheckedWithoutZero = (value) => [undefined, null, ''].includes(value);

export const parseIsoDateToNormalDate = (isoDate) => isoDate.slice(0, 10);

export const isValidUUID = (uuid) => {
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return uuidRegex.test(uuid);
};

export const formatDateToYYYYMMDD = (inputDate) => {
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(inputDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export const formatDateToISO = (inputDate, options = { withSeconds: false }) => {
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(inputDate.getDate()).padStart(2, '0');
    const hour = String(inputDate.getHours()).padStart(2, '0');
    const minute = String(inputDate.getMinutes()).padStart(2, '0');

    let date = `${year}-${month}-${day} ${hour}:${minute}`;
    if (options.withSeconds) {
        const second = String(inputDate.getSeconds()).padStart(2, '0');
        date = `${date}:${second}`;
    }
    return date;
};

export const isUndefinedOrEmpty = (value) => [undefined, ''].includes(value);

export const validateMapName = (value) => {
    // Check if value starts with a numeric character
    if (/^\d/.test(value)) {
        return "Map name can't start with a numeric value";
    }

    // Check if value contains zero width space
    if (value.includes('\u200B')) {
        return "Map name can't contain zero width space";
    }

    return true; // Validation passed
};
