import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './lineOfInterestEntityAdapter';
import { expireSession, logout } from '../../auth/authActions';
import {
    createLineOfInterest,
    deleteLineOfInterest,
    fetchAllLinesOfInterestInMap,
    updateLineOfInterest,
} from './lineOfInterestActions';
import { lineOfInterestCreated, lineOfInterestDeleted, lineOfInterestUpdated } from '../mappingEventsActions';

const { getInitialState, upsertOne, setAll, removeOne } = entityAdapter;

const upsertOneLineOfInterest = (state, action) => {
    const { lineId, points, mapperName, createdAt, lastUpdate } = action.payload;
    upsertOne(state, { lineId, points, mapperName, createdAt, lastUpdate });
};

export const { reducer } = createSlice({
    name: 'linesOfInterest',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchAllLinesOfInterestInMap.fulfilled]: (state, action) => {
            setAll(
                state,
                action.payload.map(({ lineId, points, mapperName, createdAt, lastUpdate }) => ({
                    lineId,
                    points,
                    mapperName,
                    createdAt,
                    lastUpdate,
                }))
            );
        },

        [createLineOfInterest.fulfilled]: upsertOneLineOfInterest,

        [updateLineOfInterest.fulfilled]: upsertOneLineOfInterest,

        [deleteLineOfInterest.fulfilled]: (state, action) => {
            const { lineId } = action.meta.arg;
            removeOne(state, lineId);
        },

        [lineOfInterestCreated]: (state, action) => {
            const { lineId, points, mapperName, createdAt, lastUpdate } = action.payload.data;
            upsertOne(state, { lineId, points, mapperName, createdAt, lastUpdate });
        },

        [lineOfInterestUpdated]: (state, action) => {
            const { lineId, points, mapperName, createdAt, lastUpdate } = action.payload.data;
            upsertOne(state, { lineId, points, mapperName });
        },

        [lineOfInterestDeleted]: (state, action) => {
            const { lineId } = action.payload.data;
            removeOne(state, lineId);
        },

        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
    },
});
