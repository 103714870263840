import { createAsyncThunk } from '../utils';
import regionsApi from '../../api/RegionsApi';

export const fetchAllRegionsInSpace = createAsyncThunk(
    'regions/fetchAllInSpace',
    async ({ spaceId = null, withPlacements = false, withTriggerIds = false }) => {
        return (await regionsApi.fetchAllRegionsInSpace(spaceId, withPlacements, withTriggerIds)).data;
    }
);

export const fetchRegionColorsInSpace = createAsyncThunk(
    'regions/fetchColorsInSpace',
    async ({ spaceId, regionNames }) => {
        return (await regionsApi.fetchRegionColorsInSpace(spaceId, regionNames)).data;
    }
);

export const createRegion = createAsyncThunk('regions/create', async ({ regionData, spaceId }) => {
    return (await regionsApi.createRegion(regionData, spaceId)).data;
});

export const updateRegion = createAsyncThunk('regions/update', async ({ regionId, regionData }) => {
    return (await regionsApi.updateRegion(regionId, regionData)).data;
});

export const deleteRegion = createAsyncThunk('regions/delete', async (regionId) => {
    return (await regionsApi.deleteRegion(regionId)).data;
});
