import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormControlLabel, Paper } from '@material-ui/core';
import { Switch, TextField, SwitchLabel as Label } from '../../common/themed';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getLatestTermsAndConditionsGracePeriodDeadline } from '../../../state-management/api-key/apiKeySelectors';

const CardWrapper = styled(Paper)`
    padding: 1rem;
    margin-bottom: 1rem;
`;

const Warning = styled.div`
    color: #f64747; // TODO color should come from theme
`;

export default function ActionsBar(props) {
    const { filter, onFilterChange, onHideAcceptedChange, onHideExcludedChange, onHideSuspendedChange } =
        props;

    const gracePeriodDeadline = useSelector(getLatestTermsAndConditionsGracePeriodDeadline);

    return (
        <CardWrapper>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <div>
                    <TextField
                        label={'Search...'}
                        value={filter}
                        onChange={(e) => onFilterChange(e.target.value)}
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                defaultChecked={false}
                                onChange={(e) => {
                                    onHideAcceptedChange(e.target.checked);
                                }}
                            />
                        }
                        label={<Label>Hide accepted</Label>}
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                defaultChecked={false}
                                onChange={(e) => {
                                    onHideExcludedChange(e.target.checked);
                                }}
                            />
                        }
                        label={<Label>Hide excluded</Label>}
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                defaultChecked={false}
                                onChange={(e) => {
                                    onHideSuspendedChange(e.target.checked);
                                }}
                            />
                        }
                        label={<Label>Hide Suspended</Label>}
                    />
                </div>

                {gracePeriodDeadline && (
                    <Warning>
                        Grace Period Deadline:{' '}
                        {moment(gracePeriodDeadline).format('dddd, MMMM Do YYYY, HH:mm')}
                    </Warning>
                )}
            </Grid>
        </CardWrapper>
    );
}

ActionsBar.propTypes = {
    filter: PropTypes.string.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onHideAcceptedChange: PropTypes.func.isRequired,
    onHideExcludedChange: PropTypes.func.isRequired,
};
