import React, { useContext, useEffect } from 'react';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import { MapViewerContext } from '../../MapViewerContext';
import { InputAdornment, Portal, Snackbar, SnackbarContent, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { getMapViewerMapImageId } from '../../MapViewer.selectors';
import { Button, TextField } from '../../../themed';
import {
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import { useCallback } from 'react';
import {
    getCoordinatesConversionPickerOverlayWrapperId,
    getCoordinatesConversionTooltipMapOffsetId,
} from './CoordinatesConversionOverlay.selectors';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useRef } from 'react';
import { darken } from 'polished';
const X = 'data-x';
const Y = 'data-y';

const CoordinatesConversionOverlayWrapper = styled.div`
    cursor: crosshair;
    position: absolute;
    width: 100%;
    height: 100%;
`;

const ExplanationSnackbar = styled(Snackbar)`
    position: absolute;
`;

const TooltipContent = styled.div`
    position: absolute;
    width: max-content;
    ::before {
        content: 'X:' attr(${X}) ' Y:' attr(${Y}) '';
        background: #ff6200;
        border-radius: 10px;
        padding: 6px;
        box-shadow: 3px 2px 0px 2px #00000029;
        color: white;
    }
`;
const Instruction = styled(Typography)`
    color: #ffffff; //TODO color should come from theme
`;
const SnackbarButton = styled(Button)`
    color: white;
`;
const SnackbarTextField = styled(TextField)`
    &&& .themed-underline {
        ::before {
            border-bottom-color: white; // TODO color should come from theme
        }
        :hover::before {
            border-bottom-color: white; // TODO color should come from theme
        }
    }

    & .themed-input-label {
        color: white; // TODO color should come from theme
    }

    input {
        color: white;
    }
`;

const CustomerPointFieldContainer = styled.div`
    display: flex;
    width: 50%;
    & * {
        color: white;
    }
`;
export default function CoordinatesConversionPickerOverlay(props) {
    const INITIAL = 'INITIAL';
    const SET_CUSTOMER_POINT = 'SET_CUSTOMER_POINT';
    const { onSubmit, onCancel, tooltip = false } = props;
    const { imageRef, containerRef, getCSSVariable } = useContext(MapViewerContext);
    const cloneRef = document.getElementById(getMapViewerMapImageId());
    const [currentStep, setCurrentStep] = useState(INITIAL);
    const [customer, setCustomer] = useState({ x: null, y: null, multiply: 1 });
    const [oriient, setOriient] = useState({ x: 0, y: 0 });
    const { current: usedColors } = useRef([]);
    const calculateMouseCoordinates = useCallback(
        (x, y) => {
            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
            const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
            const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
            const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

            const calculatedX = Number(x / pixelToMeter / scale + mapOffsetX);
            const calculatedY = Number(
                imageNaturalHeight / pixelToMeter - y / pixelToMeter / scale + mapOffsetY
            );
            return {
                x: calculatedX,
                y: calculatedY,
            };
        },
        [getCSSVariable]
    );

    const getRandomColor = (usedColors) => {
        // Define an array of available colors
        const availableColors = [
            '#FF5733', // Red
            '#DAF7A6', // Green
            '#FFC300', // Yellow
            '#C70039', // Maroon
            '#900C3F', // Dark Red
            '#581845', // Plum
            '#154360', // Dark Blue
            '#FFC300', // Yellow
            '#F39C12', // Orange
            '#D4AC0D', // Gold
            '#7D6608', // Dark Yellow
            '#1ABC9C', // Turquoise
            '#16A085', // Dark Turquoise
            '#2E86C1', // Light Blue
            '#3498DB', // Blue
            '#8E44AD', // Purple
            '#9B59B6', // Lavender
            '#D35400', // Rust
            '#DC7633', // Copper
            '#1F618D', // Navy Blue
            '#117A65', // Dark Green
            '#27AE60', // Light Green
            '#229954', // Forest Green
            '#4A235A', // Deep Purple
        ];

        // Filter out colors that have already been used
        const availableColorsFiltered = availableColors.filter((color) => !usedColors.includes(color));
        // If all colors have been used, reset the usedColors array
        if (availableColorsFiltered.length === 0) {
            usedColors.length = 0; // Reset the array
            return getRandomColor(usedColors); // Recursively call the function
        }

        // Randomly select a color from the available colors
        const randomIndex = Math.floor(Math.random() * availableColorsFiltered.length);
        const randomColor = availableColorsFiltered[randomIndex];

        // Add the selected color to the used colors array
        usedColors.push(randomColor);

        return randomColor;
    };
    const handleClick = (event) => {
        const refRect = imageRef.current.getBoundingClientRect();
        const { x, y } = calculateMouseCoordinates(event.clientX - refRect.left, event.clientY - refRect.top);
        setOriient({ x, y });
        setCurrentStep(SET_CUSTOMER_POINT);
    };

    const handleCancel = (event) => {
        event.stopPropagation();
        onCancel();
    };

    const handleSave = (e) => {
        e.stopPropagation();
        onSubmit({
            customer: { x: +customer.x * customer.multiply, y: +customer.y * customer.multiply },
            oriient,
            color: getRandomColor(usedColors),
            coordinateId: uuid(),
        });
        setCurrentStep(INITIAL);
        setCustomer((prev) => ({ x: null, y: null, multiply: prev.multiply || 1 }));
    };

    const handleMouseMove = (event) => {
        const refRect = event.currentTarget.getBoundingClientRect();
        const { x, y } = calculateMouseCoordinates(event.clientX - refRect.left, event.clientY - refRect.top);
        const tooltipMapOffsetElement = document.getElementById(getCoordinatesConversionTooltipMapOffsetId());
        if (tooltipMapOffsetElement) {
            tooltipMapOffsetElement.setAttribute(X, x.toFixed(3));
            tooltipMapOffsetElement.setAttribute(Y, y.toFixed(3));
        }
    };

    useEffect(() => {
        const ref = cloneRef?.current;
        if (ref) {
            ref.addEventListener('mousemove', handleMouseMove);
            return () => {
                ref.removeEventListener('mousemove', handleMouseMove);
            };
        }
    }, [cloneRef, handleMouseMove]);

    const dataAttributes = { [X]: 0, [Y]: 0 };
    const handleCustomerPointChange = (event, property) => {
        const { value } = event.target;
        if (property) {
            setCustomer((prev) => ({ ...prev, [property]: value }));
        } else {
            setCustomer((prev) => ({ ...prev, multiply: value }));
        }
    };

    const steps = {
        [INITIAL]: (
            <Instruction>
                Please mark the customer given points on the map. The points will be added to the "Coordinates
                conversion" section to the left
            </Instruction>
        ),
        [SET_CUSTOMER_POINT]: (
            <>
                <Instruction>Please insert the customer coordinates</Instruction>
                <CustomerPointFieldContainer>
                    <SnackbarTextField
                        onChange={(e) => handleCustomerPointChange(e, 'x')}
                        onClick={(e) => e.stopPropagation()}
                        value={customer.x ? customer.x * customer.multiply : null}
                        type="number"
                        autoFocus
                        InputProps={{
                            startAdornment: <InputAdornment position="start">X:</InputAdornment>,
                        }}
                        style={{
                            alignSelf: 'flex-end',
                        }}
                    />
                    <SnackbarTextField
                        onChange={(e) => handleCustomerPointChange(e, 'y')}
                        onClick={(e) => e.stopPropagation()}
                        value={customer.y ? customer.y * customer.multiply : null}
                        type="number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Y:</InputAdornment>,
                        }}
                        style={{
                            alignSelf: 'flex-end',
                        }}
                    />
                    <SnackbarTextField
                        onChange={(e) => handleCustomerPointChange(e)}
                        onClick={(e) => e.stopPropagation()}
                        value={customer.multiply}
                        type="number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Multiply:</InputAdornment>,
                        }}
                        style={{
                            alignSelf: 'flex-end',
                        }}
                    />
                </CustomerPointFieldContainer>
            </>
        ),
    };
    const handleEnterPress = (e) => {
        if (e.key === 'Enter' && customer.x && customer.y) {
            handleSave(e);
        }
    };
    return (
        <>
            <CoordinatesConversionOverlayWrapper
                ref={cloneRef}
                id={getCoordinatesConversionPickerOverlayWrapperId()}
                onClick={handleClick}
            >
                <Portal container={containerRef.current}>
                    <ExplanationSnackbar
                        open
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        onKeyDown={handleEnterPress}
                    >
                        <SnackbarContent
                            action={
                                <>
                                    <SnackbarButton variant={'text'} onClick={handleCancel}>
                                        Cancel
                                    </SnackbarButton>
                                    {currentStep === SET_CUSTOMER_POINT && (
                                        <SnackbarButton
                                            onClick={handleSave}
                                            disabled={!customer.x || !customer.y}
                                        >
                                            Save
                                        </SnackbarButton>
                                    )}
                                </>
                            }
                            message={steps[currentStep]}
                        />
                    </ExplanationSnackbar>
                    <MouseTooltip visible={tooltip} offsetX={15} offsetY={10}>
                        <TooltipContent
                            {...dataAttributes}
                            id={getCoordinatesConversionTooltipMapOffsetId()}
                        />
                    </MouseTooltip>
                </Portal>
            </CoordinatesConversionOverlayWrapper>
        </>
    );
}
