import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectMappingIsSideDrawerOpen,
    setIsSideDrawerOpen,
} from '../../state-management/user-inputs/mappingSlice';
import SideDrawer from '../common/side-drawer';
import MappingSideMenuBuildingSection from './side-menu/MappingSideMenuBuildingSection';
import MappingSideMenuFloorSection from './side-menu/MappingSideMenuFloorSection';
import MappingSideMenuMapSection from './side-menu/MappingSideMenuMapSection';
import MappingSideMenuMappersSection from './side-menu/MappingSideMenuMappersSection';
import MappingSideMenuLanesSection from './side-menu/MappingSideMenuLanesSection';
import MappingSideMenuJunctionMismatchesSection from './side-menu/MappingSideMenuJunctionMismatchesSection';
import MappingSideMenuAreasOfInterestSection from './side-menu/MappingSideMenuAreasOfInterestSection';
import MappingSideMenuMapValidationsSection from './side-menu/MappingSideMenuMapValidationsSection';
import MappingSideMenuLinesOfInterestSection from './side-menu/MappingSideMenuLinesOfInterestSection';
import MappingSideMenuElementsSection from './side-menu/MappingSideMenuElementsSection';
import PreferencesSideMenuSection from '../monitor/side-menu/preferences/index';
import MappingSideMenuListSection from './side-menu/MappingSideMenuListSection';
import MappingSideMenuSignMarkSection from './side-menu/MappingSideMenuSignMarkSection';
export default function MappingSideDrawer({ selfHealingEnabled }) {
    const dispatch = useDispatch();

    const isSideDrawerOpen = useSelector(selectMappingIsSideDrawerOpen);

    return (
        <SideDrawer
            isOpen={isSideDrawerOpen}
            onToggle={() => dispatch(setIsSideDrawerOpen(!isSideDrawerOpen))}
        >
            <MappingSideMenuBuildingSection />
            <MappingSideMenuFloorSection />
            <MappingSideMenuMapSection disabled={selfHealingEnabled} />
            <MappingSideMenuMappersSection />
            <MappingSideMenuJunctionMismatchesSection disabled={selfHealingEnabled} />
            {/* <MappingSideMenuAreasOfInterestSection /> */}
            <MappingSideMenuListSection type='normal' />
            <MappingSideMenuListSection type='element' />
            <MappingSideMenuListSection type='line' />

            {/* <MappingSideMenuLinesOfInterestSection /> */}
            <MappingSideMenuMapValidationsSection />
            <MappingSideMenuLanesSection selfHealingEnabled={selfHealingEnabled} />
            <PreferencesSideMenuSection />
            <MappingSideMenuSignMarkSection />
        </SideDrawer>
    );
}
