/**
 * Receives raw data containing sessions and splits them based on their IPS client ID
 * Returns a processable object of the split sessions
 * @param data
 * @returns {{sessionsById: *, aggregationsById: *, positionsById: *}}
 */
export const splitRawSessionsByClient = data => {
    const aggregationsById = {};
    const sessionsById = {};
    const positionsById = {};

    const regionLevels = new Set();

    for (const { mapsRegionCategoryLevels, ipsClientId, sessions, ...aggProps } of data) {
        // Create a set of distinct region levels
        mapsRegionCategoryLevels.forEach(({ levels }) => {
            levels.forEach(level => {
                regionLevels.add(level);
            });
        });

        // Aggregate by IPS client
        aggregationsById[ipsClientId] = {
            ...aggProps,
            aggregationId: ipsClientId,
            isProcessed: false,
            ipsClientId,
            mapsRegionCategoryLevels,
            regionLevels: Array.from(regionLevels),
            sessionIds: sessions.map(s => s.sessionId),
        };

        for (const { sessionId, positions, ...session } of sessions) {
            sessionsById[sessionId] = {
                ...session,
                sessionId,
                isProcessed: false,
                positionIds: positions.map(p => p.positionId),
            };

            positions.forEach(position => {
                positionsById[position.positionId] = position;
            });
        }
    }

    return { aggregationsById, sessionsById, positionsById };
};

/**
 * Receives raw data containing sessions and splits them based on their building name
 * Returns a processable object of the split sessions
 * @param data
 * @returns {{sessionsById: *, aggregationsById: *, positionsById: *}}
 */
export const splitRawSessionsByBuilding = data => {
    const aggregationsById = {};
    const sessionsById = {};
    const positionsById = {};

    const regionLevels = new Set();

    for (const { mapsRegionCategoryLevels, ipsClientId, sessions, ...aggProps } of data) {
        // Create a set of distinct region levels
        mapsRegionCategoryLevels.forEach(({ levels }) => {
            levels.forEach(level => {
                regionLevels.add(level);
            });
        });

        for (const { sessionId, positions, buildingName, ...session } of sessions) {
            if (aggregationsById[buildingName]) {
                aggregationsById[buildingName].sessionIds.push(sessionId);
            } else {
                // Aggregate by building name
                aggregationsById[buildingName] = {
                    ...aggProps,
                    aggregationId: buildingName,
                    isProcessed: false,
                    buildingName,
                    mapsRegionCategoryLevels,
                    regionLevels: Array.from(regionLevels),
                    sessionIds: sessions.map(s => s.sessionId),
                };
            }

            sessionsById[sessionId] = {
                ...session,
                sessionId,
                ipsClientId,
                isProcessed: false,
                positionIds: positions.map(p => p.positionId),
            };

            positions.forEach(position => {
                positionsById[position.positionId] = position;
            });
        }
    }

    return { aggregationsById, sessionsById, positionsById };
};
