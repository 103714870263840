import metadataFieldTypes from '../common/metadata-editor/MetadataEditorFieldTypes';

export default {
    alwaysVisible: {
        type: metadataFieldTypes.BOOLEAN,
        label: 'Show on map',
        defaultValue: true,
    },
    searchable: {
        type: metadataFieldTypes.BOOLEAN,
        label: 'Searchable',
        defaultValue: true,
    },
    design: {
        type: metadataFieldTypes.CATEGORY,
        label: 'Design',
        fields: {
            color: {
                type: metadataFieldTypes.COLOR,
                label: 'Region color',
                defaultValue: '#E65100',
            },
            nameColor: {
                type: metadataFieldTypes.COLOR,
                label: 'Text color',
                defaultValue: '#484848',
            },
            nameFontSize: {
                type: metadataFieldTypes.NUMBER,
                label: 'Font size',
                defaultValue: 15,
            },
            borderColor: {
                type: metadataFieldTypes.COLOR,
                label: 'Border color',
                defaultValue: '#ffffff',
            },
            borderWidth: {
                type: metadataFieldTypes.NUMBER,
                label: 'Border width',
                defaultValue: 0,
            },
        },
    },
    billboard: {
        type: metadataFieldTypes.CATEGORY,
        label: 'Billboard',
        fields: {
            billboardImage: {
                type: metadataFieldTypes.STRING,
                label: 'Image URL',
                defaultValue: '',
            },
            billboardImageBkgColor: {
                type: metadataFieldTypes.COLOR,
                label: 'Image background color',
                defaultValue: '#00000000',
            },
            billboardSize: {
                type: metadataFieldTypes.NUMBER,
                label: 'Size',
                defaultValue: 36,
            },
            billboardImageMargin: {
                type: metadataFieldTypes.NUMBER,
                label: 'Margin',
                defaultValue: 1,
            },
        },
    },
    zoom: {
        type: metadataFieldTypes.CATEGORY,
        label: 'Zoom',
        fields: {
            minZoom: {
                type: metadataFieldTypes.NUMBER,
                label: 'Minimum zoom to show region',
                defaultValue: 0,
                display: false,
            },
            maxZoom: {
                type: metadataFieldTypes.NUMBER,
                label: 'Maximum zoom to show region',
                defaultValue: 100,
                display: false,
            },
            minZoomMeters: {
                type: metadataFieldTypes.NUMBER,
                label: 'Minimum zoom meters to show region',
                defaultValue: null,
                options: {
                    hasConstraints: true,
                    range: {
                        min: 100000,
                    },
                },
            },
            maxZoomMeters: {
                type: metadataFieldTypes.NUMBER,
                label: 'Maximum zoom meters to show region',
                defaultValue: null,
                options: {
                    hasConstraints: true,
                    range: {
                        max: 0,
                    },
                },
            },
        },
    },
};
