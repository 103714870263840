import React from 'react';
import * as PropTypes from 'prop-types';
import MapValidation from './MapValidation';
import styled from 'styled-components';
import { getMapViewerMapValidationsOverlayId } from './MapViewerMapValidationsOverlay.selectors';

const OverlayWrapper = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none; // Prevent the whole SVG from capturing pointer events

    * {
        pointer-events: auto;
    }
`;

export default function MapViewerMapValidationsOverlay(props) {
    const { validations } = props;

    if (validations.length === 0) {
        return null;
    }

    return (
        <OverlayWrapper id={getMapViewerMapValidationsOverlayId()}>
            {validations.map((validation) => (
                <MapValidation key={validation?.mapValidationId} validation={validation} />
            ))}
        </OverlayWrapper>
    );
}

MapViewerMapValidationsOverlay.propTypes = {
    validations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MapViewerMapValidationsOverlay.whyDidYouRender = true;
