import { createSlice } from '@reduxjs/toolkit';
import { deleteSpace } from '../space/spaceActions';
import { wipeAllUserInputs } from './userInputsActions';

const initialState = {
    selectedSpaceId: null,
    selectedMapId: localStorage.getItem('selectedMapId'),
    selectedBuildingId: localStorage.getItem('selectedBuildingId'),
    selectedFloorId: localStorage.getItem('selectedFloorId'),
    isNavbarOpen: true
};

export const {
    actions: { setSelectedSpaceId, toggleNavbar },
    reducer,
} = createSlice({
    name: 'userInputs/common',
    initialState,
    reducers: {
        setSelectedSpaceId: (state, action) => {
            state.selectedSpaceId = action.payload;
            state.selectedBuildingId = null;
            state.selectedFloorId = null;
            state.selectedMapId = null;
            localStorage.removeItem('selectedMapId');
            localStorage.removeItem('selectedBuildingId');
            localStorage.removeItem('selectedFloorId');
        },
        toggleNavbar: (state) => {
            state.isNavbarOpen = !state.isNavbarOpen
        }
    },
    extraReducers: {
        [deleteSpace.fulfilled]: (state, action) => {
            if (action.meta.arg === state.setSelectedSpaceId) {
                state.selectedSpaceId = null;
            }
        },
        [wipeAllUserInputs]: () => initialState,
    },
});

export const selectCommonSelectedSpaceId = (state) => state.userInputs.common.selectedSpaceId;
export const selectCommonIsNavbarOpen = (state) => state.userInputs.common.isNavbarOpen
