import React from 'react';
import styled, { css } from 'styled-components';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import { IoMdCheckmarkCircleOutline as SuccessIcon } from 'react-icons/io';
import {
    MdError as ErrorIcon,
    MdInfo as InfoIcon,
    MdWarning as WarningIcon,
    MdClose as CloseIcon,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeNotification,
    selectNotification,
} from '../../../state-management/notification/notificationReducer';
import { getNotificationId } from './Notification.selectors';

const INFO = 'info';
const SUCCESS = 'success';
const WARNING = 'warning';
const ERROR = 'error';

const iconStyles = css`
    margin-inline-end: 5px;
    width: 1.5rem;
    height: 1.5rem;
`;

const variantIcon = {
    [INFO]: styled(InfoIcon)`
        ${iconStyles}
    `,
    [SUCCESS]: styled(SuccessIcon)`
        ${iconStyles}
    `,
    [WARNING]: styled(WarningIcon)`
        ${iconStyles}
    `,
    [ERROR]: styled(ErrorIcon)`
        ${iconStyles}
    `,
};

const AlertSnackbarContent = styled(SnackbarContent)`
    flex-wrap: nowrap;
    background-color: ${(props) => {
        switch (props.type) {
            case SUCCESS:
                return '#3b953e';
            case WARNING:
                return '#ff9505';
            case ERROR:
                return '#cd2929';
            default:
                return '#333333';
        }
    }};
`;

const IconAndMessageWrapper = styled.span`
    display: flex;
    align-items: center;
`;

export default function Notification() {
    const dispatch = useDispatch();

    const { display, message, type } = useSelector(selectNotification);

    const Icon = variantIcon[type];

    const onClose = () => {
        dispatch(closeNotification());
    };

    return (
        <Snackbar
            style={{ zIndex: 1600 }}
            id={getNotificationId()}
            open={display}
            autoHideDuration={5000}
            onClose={onClose}
        >
            <AlertSnackbarContent
                type={type}
                message={
                    <IconAndMessageWrapper>
                        <Icon />
                        {message}
                    </IconAndMessageWrapper>
                }
                action={
                    <IconButton aria-label={'Close'} color={'inherit'} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
        </Snackbar>
    );
}
