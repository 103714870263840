import React, { forwardRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
    Divider as MuiDivider,
    FormControlLabel,
    Grid,
    IconButton,
    RootRef,
    Typography,
} from '@material-ui/core';
import {
    Button,
    CircularPreloader as ThemedCircularPreloader,
    Skeleton,
    Switch,
    TextField,
    Tooltip,
} from '../common/themed';
import {
    highlightEntrance,
    selectBuildingsSelectedBuildingId,
    selectBuildingsSelectedExternalPlacementId,
    selectBuildingsSelectedExternalPlacementPoints,
    selectBuildingsSelectedRegionId,
    setSelectedExternalPlacementEditable,
    setSelectedExternalPlacementId,
    unhighlightEntrance,
} from '../../state-management/user-inputs/buildingsSlice';
import ItemDetailsSideDrawer from '../common/item-details-side-drawer';
import styled from 'styled-components';
import { isFulfilled } from '../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../state-management/notification/notificationReducer';
import {
    getDetachEntranceFromExternalRegionId,
    getExternalPlacementEnableAutoStartId,
    getExternalPlacementEnableStartPositioningId,
    getExternalPlacementGeoPositioningMargin,
    getExternalPlacementRegionNameId,
    getExternalPlacementStopPositioningMargin,
} from './buildings.selectors';
import { selectCanEditRegionExternalPlacements } from '../../state-management/auth/authSelectors';
import useAllFloorsInBuilding from '../common/hooks/data-fetching/useAllFloorsInBuilding';
import { AiOutlineDisconnect as DetachIcon } from 'react-icons/ai';
import { getSelectAllRegionsWithExternalPlacementsInBuilding, selectRegionById } from '../../state-management/region/regionSelectors';
import {
    detachRegionExternalPlacementFromEntrance,
    updateRegionExternalPlacement,
} from '../../state-management/region-placement/regionPlacementActions';
import { getSelectRegionPlacementWithAttachedEntrances } from '../../state-management/region-placement/regionPlacementSelectors';
import { updateRegion } from '../../state-management/region/regionActions';
import NoItemsMessage from '../common/side-drawer/NoItemsMessage';
import NumberFormat from 'react-number-format';

const Divider = styled(MuiDivider)`
    margin-block-start: 15px;
    margin-block-end: 15px;
`;

const FormControls = styled.div`
    margin-block-start: 10px;
`;

const CircularPreloader = styled(ThemedCircularPreloader)`
    padding: 0 10px;
`;

const IconWrapper = forwardRef((props, ref) => (
    <RootRef rootRef={ref}>
        <IconButton {...props} />
    </RootRef>
));

export default function ExternalRegionDetailsSideDrawer() {
    const dispatch = useDispatch();

    const canEditPlacements = useSelector(selectCanEditRegionExternalPlacements);
    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const selectedRegionId = useSelector(selectBuildingsSelectedRegionId);
    const selectedPlacementId = useSelector(selectBuildingsSelectedExternalPlacementId);
    const placementPoints = useSelector(selectBuildingsSelectedExternalPlacementPoints);

    // This hook is being used to fetch full building data, which includes the external regions
    const { isLoading } = useAllFloorsInBuilding({ buildingId: selectedBuildingId });

    const selectPlacement = useMemo(
        () => getSelectRegionPlacementWithAttachedEntrances(selectedPlacementId),
        [selectedPlacementId]
    );

    const region = useSelector((state) => selectRegionById(state, selectedRegionId));
    const placement = useSelector(selectPlacement);
    const { regionName, regionMetadata } = region ?? {};
    const { properties, points, entrances, positioningGeoMargin, stopPositioningMargin } = placement ?? {};
    const isPgmRegion = regionName?.toLowerCase() === 'positioning geofence';


    const {
        handleSubmit,
        reset,
        control,
        setValue,
        formState: { isDirty, isSubmitting, errors, dirtyFields },
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            regionName: regionName ?? '',
            properties: properties ?? { enableStartPositioningOnlyInGeo: false, enableAutoStopPositioningGeo: false },
            positioningGeoMargin: positioningGeoMargin,
            stopPositioningMargin: stopPositioningMargin,
        },
    });

    const submitForm = async (data) => {
        let regionResult;
        let placementResult;
        const fields = ['points', 'properties', 'positioningGeoMargin', 'stopPositioningMargin']

        if (fields.some(field => dirtyFields[field])) {
            placementResult = await dispatch(
                updateRegionExternalPlacement({
                    regionId: selectedRegionId,
                    placementId: selectedPlacementId,
                    placementData: {
                        ...(dirtyFields?.points ? { points: data.points } : {}),
                        ...(dirtyFields?.properties ? { properties: data.properties } : {}),
                        ...(dirtyFields?.positioningGeoMargin
                            ? { positioningGeoMargin: data.positioningGeoMargin }
                            : {}),
                        ...(dirtyFields?.stopPositioningMargin ? { stopPositioningMargin: data.stopPositioningMargin } : {})
                    },
                })
            );
        }

        if (dirtyFields?.regionName) {
            regionResult = await dispatch(
                updateRegion({
                    regionId: selectedRegionId,
                    regionData: { regionName: data.regionName, regionMetadata: regionMetadata ?? '{}' },
                })
            );
        }

        if (
            (!placementResult || isFulfilled(placementResult)) &&
            (!regionResult || isFulfilled(regionResult))
        ) {
            dispatch(showSuccessNotification('Successfully updated external region.'));
        } else {
            dispatch(showErrorNotification('Failed to update external region.'));
        }

        dispatch(
            setSelectedExternalPlacementEditable({
                regionId: selectedRegionId,
                placementId: selectedPlacementId,
                isEditable: false,
            })
        );
    };

    const handleCancel = () => {
        reset();
        dispatch(
            setSelectedExternalPlacementEditable({
                regionId: selectedRegionId,
                placementId: selectedPlacementId,
                isEditable: false,
            })
        );
    };

    const handleDetachEntrance = async (entranceId) => {
        const result = await dispatch(
            detachRegionExternalPlacementFromEntrance({
                regionId: selectedRegionId,
                placementId: selectedPlacementId,
                entranceId,
            })
        );

        dispatch(unhighlightEntrance(entranceId));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Detached entrance from external region.'));
        } else {
            dispatch(showErrorNotification('Failed to detached entrance from external region.'));
        }
    };

    useEffect(() => {
        if (placementPoints) {
            setValue('points', placementPoints, { shouldDirty: true });
        }
    }, [placementPoints, setValue]);

    useEffect(() => {
        reset({
            regionName: regionName ?? '',
            points: points ?? [],
            properties: properties ?? { enableStartPositioningOnlyInGeo: true, enableAutoStopPositioningGeo: false },
            positioningGeoMargin: positioningGeoMargin,
            stopPositioningMargin: stopPositioningMargin
        });
    }, [regionName, properties, points, positioningGeoMargin, stopPositioningMargin, reset]);

    return (
        <ItemDetailsSideDrawer
            isOpen={!!selectedPlacementId}
            onBack={() => dispatch(setSelectedExternalPlacementId({ regionId: null, placementId: null }))}
            title={selectedPlacementId ?? ''}
            isTitleCopyable
            content={
                <>
                    {/* <ExternalRegionActions
                        regionId={selectedRegionId}
                        placementId={selectedPlacementId}
                        showShowInfo={false}
                    />

                    <Divider /> */}

                    <Grid container direction={'column'}>
                        <form onSubmit={handleSubmit(submitForm)}>
                            {isLoading ? (
                                <Skeleton height={15} width={230} />
                            ) : (
                                <>
                                    <Controller name={'points'} control={control} render={() => null} />
                                    <Grid item>
                                        <Controller
                                            id={getExternalPlacementRegionNameId()}
                                            name={'regionName'}
                                            control={control}
                                            defaultValue={regionName ?? ''}
                                            rules={{ required: 'This is required' }}
                                            render={({ onChange, value }) => (
                                                <TextField
                                                    label={'Name'}
                                                    multiline
                                                    fullWidth
                                                    error={!!errors?.regionName}
                                                    helperText={errors.regionName?.message}
                                                    variant={'outlined'}
                                                    margin={'dense'}
                                                    value={value}
                                                    onChange={onChange}
                                                    disabled={true}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    {isPgmRegion && <Controller
                                        id={getExternalPlacementEnableStartPositioningId()}
                                        control={control}
                                        name={'properties.enableStartPositioningOnlyInGeo'}
                                        defaultValue={!!properties?.enableStartPositioningOnlyInGeo}
                                        render={({ onChange, value }) => (
                                            <FormControlLabel
                                                label={'Enable positioning only when in geofence'}
                                                disabled={!canEditPlacements}
                                                control={
                                                    <Switch
                                                        checked={value}
                                                        onChange={(e) => onChange(e.target.checked)}
                                                    />
                                                }
                                            />
                                        )}
                                    />}
                                    {!isPgmRegion && <Controller
                                        id={getExternalPlacementEnableAutoStartId()}
                                        control={control}
                                        name={'properties.enableAutoStopPositioningGeo'}
                                        defaultValue={!!properties?.enableAutoStopPositioningGeo ?? false}
                                        render={({ onChange, value }) => (
                                            <FormControlLabel
                                                label={'Enable auto stop when in geofence'}
                                                disabled={!canEditPlacements}
                                                control={
                                                    <Switch
                                                        checked={value}
                                                        onChange={(e) => onChange(e.target.checked)}
                                                    />
                                                }
                                            />
                                        )}
                                    />}
                                    {isPgmRegion && <Controller
                                        id={getExternalPlacementGeoPositioningMargin()}
                                        name={'positioningGeoMargin'}
                                        control={control}
                                        defaultValue={positioningGeoMargin}
                                        render={({ onChange, value }) => (
                                            <NumberFormat
                                                customInput={TextField}
                                                label={'Positioning Geofence Margin'}
                                                fullWidth
                                                allowNegative={false}
                                                value={value}
                                                onValueChange={({ floatValue }) => onChange(floatValue)}
                                                disabled={!canEditPlacements}
                                                InputProps={{
                                                    endAdornment: 'Meters',
                                                }}

                                            />
                                        )}
                                    />}
                                    {!isPgmRegion && <Controller
                                        id={getExternalPlacementStopPositioningMargin()}
                                        name={'stopPositioningMargin'}
                                        control={control}
                                        defaultValue={stopPositioningMargin}
                                        render={({ onChange, value }) => (
                                            <NumberFormat
                                                customInput={TextField}
                                                label={'Stop positioning margin'}
                                                fullWidth
                                                allowNegative={false}
                                                value={value}
                                                onValueChange={({ floatValue }) => onChange(floatValue)}
                                                disabled={!canEditPlacements}
                                                InputProps={{
                                                    endAdornment: 'Meters',
                                                }}
                                            />
                                        )}
                                    />}
                                    {isDirty && (
                                        <Grid
                                            item
                                            container
                                            justifyContent={'flex-end'}
                                            spacing={1}
                                            component={FormControls}
                                        >
                                            <Grid item>
                                                <Button variant={'text'} onClick={handleCancel}>
                                                    Cancel
                                                </Button>
                                            </Grid>

                                            {isSubmitting ? (
                                                <CircularPreloader size={35} />
                                            ) : (
                                                <Grid item>
                                                    <Button type={'submit'}>Save</Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                </>
                            )}
                        </form>
                    </Grid>

                    {/* {entrances && (
                        <>
                            <Divider />

                            <Typography variant={'h6'}>Attached Entrances</Typography>

                            {entrances?.length === 0 ? (
                                <NoItemsMessage>
                                    There are no entrances attached to this region.
                                </NoItemsMessage>
                            ) : (
                                entrances?.map(({ entranceId, description }) => (
                                    <Grid key={entranceId} container alignItems={'center'}>
                                        <Tooltip content={'Detach'} useWrapper={false}>
                                            <IconWrapper
                                                id={getDetachEntranceFromExternalRegionId(entranceId)}
                                                disabled={!canEditPlacements}
                                                onClick={() => handleDetachEntrance(entranceId)}
                                                onPointerEnter={() => dispatch(highlightEntrance(entranceId))}
                                                onPointerLeave={() =>
                                                    dispatch(unhighlightEntrance(entranceId))
                                                }
                                            >
                                                <DetachIcon />
                                            </IconWrapper>
                                        </Tooltip>

                                        <Typography variant={'subtitle1'}>{description}</Typography>
                                    </Grid>
                                ))
                            )}
                        </>
                    )} */}
                </>
            }
        />
    );
}
