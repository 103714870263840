import React from 'react';
import styled from 'styled-components';
import { MdNotInterested as ErrorIcon } from 'react-icons/md';
import { Grid } from '@material-ui/core';

const ErrorGeneralWrapper = styled.div`
    height: 100%;
`;

const RedErrorIcon = styled(ErrorIcon)`
    fill: indianred; // TODO color should come from theme
    width: 100px;
    height: 100px;
    margin-right: 10px;
`;

export default function Error404() {
    return (
        <Grid container alignItems={'center'} justifyContent={'center'} component={ErrorGeneralWrapper}>
            <RedErrorIcon />
            <div>
                Error 404
                <br />
                Page not found
            </div>
        </Grid>
    );
}
