import styled from 'styled-components';
import { SCALE } from '../../../../../constants/mapViewerVariables';

export default styled.circle.attrs((props) => ({
    style: { r: `calc(6 / var(${SCALE}))` },
    ...props,
}))`
    fill: coral; // TODO color should come from theme
    stroke: #ffffff;
    stroke-width: calc(1px / var(${SCALE}));
    cursor: ${(props) => props.cursor ?? 'auto'};
`;
