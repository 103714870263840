import React from 'react';
import styled from 'styled-components';
import { MdError as ErrorIcon } from 'react-icons/md';
import { Grid } from '@material-ui/core';

const ErrorGeneralWrapper = styled.div`
    height: 100%;
`;

const RedErrorIcon = styled(ErrorIcon)`
    fill: indianred; // TODO color should come from theme
    width: 100px;
    height: 100px;
    margin-right: 10px;
`;

export default function ErrorGeneral({ showIcon = true }) {
    return (
        <Grid container alignItems={'center'} justifyContent={'center'} component={ErrorGeneralWrapper}>
            {showIcon && <RedErrorIcon />}

            <div>
                An error has occurred.
                <br />
                Please try to refresh the page.
            </div>
        </Grid>
    );
}
