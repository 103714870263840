import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './regionEntityAdapter';
import { fetchAllRegionsInSpace, createRegion, updateRegion, deleteRegion } from './regionActions';
import { attachTriggerToRegion, deleteTrigger, detachTriggerFromRegion } from '../trigger/triggerActions';
import { expireSession, logout } from '../auth/authActions';
import {
    createRegionExternalPlacement,
    createRegionInternalPlacement,
    createRegionInternalPlacements,
    deleteRegionExternalPlacement,
    deleteRegionInternalPlacement,
    updateRegionExternalPlacement,
    updateRegionInternalPlacement,
} from '../region-placement/regionPlacementActions';
import { fetchBuildingFullData } from '../building/buildingActions';

const { getInitialState, getSelectors, upsertMany, upsertOne, addOne, removeOne, addMany } = entityAdapter;

export const { reducer } = createSlice({
    name: 'regions',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchAllRegionsInSpace.fulfilled]: (state, action) => {
            upsertMany(
                state,
                action.payload.map((r) => ({
                    regionId: r.regionId,
                    regionName: r.regionName,
                    regionMetadata: r.regionMetadata,
                    // TODO we generate the placementId to resolve some bugs when updating newly-created regions in maps.
                    //  Since placementId isn't being properly used in the REST API, and isn't returned from most APIs yet.
                    placementIds: r?.placements?.map(({ mapId }) => `${mapId}::${r.regionId}`),
                    triggerIds: r?.triggerIds,
                    regionUseAnalytics: r.regionUseAnalytics,
                    regionUsePlai: r.regionUsePlai,
                    regionUseMapPresentation: r.regionUseMapPresentation,
                    regionUseTriggers: r.regionUseTriggers,
                }))
            );
        },

        [createRegionInternalPlacements.fulfilled]: (state, action) => {
            const { mapId } = action.meta.arg;
            const newRegions = action.payload;
            addMany(
                state,
                newRegions.map(({ newRegion }) => ({
                    ...newRegion,
                    placementIds: [`${mapId}::${newRegion.regionId}`],
                }))
            );
        },
        [createRegion.fulfilled]: (state, action) => {
            const { regionId, regionName, regionMetadata } = action.payload;
            addOne(state, { regionId, regionName, regionMetadata });
        },

        [updateRegion.fulfilled]: (state, action) => {
            const { regionMetadata } = action.payload;
            const metadata = regionMetadata ? { ...JSON.parse(regionMetadata) } : '{}';
            if (metadata.anchorPoint) {
                metadata.anchorPoint.previousX = metadata.anchorPoint.x;
                metadata.anchorPoint.previousY = metadata.anchorPoint.y;
            }
            upsertOne(state, { ...action.payload, regionMetadata: JSON.stringify(metadata) });
        },

        [deleteRegion.fulfilled]: (state, action) => {
            removeOne(state, action.meta.arg);
        },

        [attachTriggerToRegion.fulfilled]: (state, action) => {
            const { regionId, triggerId } = action.meta.arg;
            const { triggerIds = [] } = getSelectors().selectById(state, regionId);
            upsertOne(state, { regionId, triggerIds: [...new Set([...triggerIds, triggerId])] });
        },

        [detachTriggerFromRegion.fulfilled]: (state, action) => {
            const { regionId, triggerId } = action.meta.arg;
            const { triggerIds = [] } = getSelectors().selectById(state, regionId);
            upsertOne(state, { regionId, triggerIds: triggerIds.filter((id) => id !== triggerId) });
        },

        [deleteTrigger.fulfilled]: (state, action) => {
            const triggerId = action.meta.arg;
            const allRegions = getSelectors().selectAll(state);
            upsertMany(
                state,
                allRegions.map((r) => ({
                    regionId: r.regionId,
                    triggerIds: r.triggerIds.filter((id) => id !== triggerId),
                }))
            );
        },

        [fetchBuildingFullData.fulfilled]: (state, action) => {
            const { externalRegions = [] } = action.payload;
            const allRegions = getSelectors().selectEntities(state);
            upsertMany(
                state,
                externalRegions.map((r) => ({
                    regionId: r.regionId,
                    regionName: r.regionName,
                    regionMetadata: r.regionMetadata,
                    placementIds: [
                        ...(allRegions?.[r.regionId]?.placementIds ?? []),
                        ...r.placements.map((p) => p.placementId),
                    ],
                }))
            );
        },

        [createRegionInternalPlacement.fulfilled]: (state, action) => {
            const { regionId, mapId } = action.meta.arg;
            const { placementIds = [] } = getSelectors().selectById(state, regionId);
            upsertOne(state, { regionId, placementIds: [...placementIds, `${mapId}::${regionId}`] });
        },

        [updateRegionInternalPlacement.fulfilled]: (state, action) => {
            const { regionId, mapId } = action.meta.arg;
            const { placementIds = [] } = getSelectors().selectById(state, regionId);
            upsertOne(state, { regionId, placementIds: [...placementIds, `${mapId}::${regionId}`] });
        },

        [deleteRegionInternalPlacement.fulfilled]: (state, action) => {
            const { regionId, mapId } = action.meta.arg;
            const { placementIds = [] } = getSelectors().selectById(state, regionId);
            upsertOne(state, {
                regionId,
                placementIds: placementIds.filter((id) => id !== `${mapId}::${regionId}`),
            });
        },

        [createRegionExternalPlacement.fulfilled]: (state, action) => {
            const { regionId } = action.meta.arg;
            const { placementId } = action.payload;
            const { placementIds = [] } = getSelectors().selectById(state, regionId);
            upsertOne(state, { regionId, placementIds: [...placementIds, placementId] });
        },

        [updateRegionExternalPlacement.fulfilled]: (state, action) => {
            const { regionId, placementId } = action.meta.arg;
            const { placementIds = [] } = getSelectors().selectById(state, regionId);
            upsertOne(state, { regionId, placementIds: [...placementIds, placementId] });
        },

        [deleteRegionExternalPlacement.fulfilled]: (state, action) => {
            const { regionId, placementId } = action.meta.arg;
            const { placementIds = [] } = getSelectors().selectById(state, regionId);
            upsertOne(state, {
                regionId,
                placementIds: placementIds.filter((id) => id !== placementId),
            });
        },

        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
    },
});
