import styled from 'styled-components';

export const ProductsConfigWrapper = styled.div`
    display: flex;
    height: 40px;
    margin-bottom: 10px;
    & > *:not(:last-child) {
        margin-right: 20px;
    }
    @media (max-width: 1600px) {
        display: flex;
    }
`;
