import React, { useContext, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, TextField } from '../../../themed';
import { Portal, Snackbar, SnackbarContent } from '@material-ui/core';
import { MapViewerContext } from '../../MapViewerContext';
import { useImmer } from 'use-immer';
import NumberFormat from 'react-number-format';
import { PIXEL_TO_METER, SCALE } from '../../../../../constants/mapViewerVariables';
import { getMapViewerPixelToMeterMeasureOverlayId } from './MapViewePixelToMeterMeasureOverlay.selectors';

const OverlayWrapper = styled.div`
    cursor: crosshair
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

const Circle = styled.circle.attrs(() => ({
    style: { r: `calc(5px / var(${SCALE}))` },
}))`
    fill: #333333; // TODO color should come from theme
`;

const Line = styled.line`
    stroke: #333333; // TODO color should come from theme
    stroke-width: calc(2px / var(${SCALE}));
    stroke-dasharray: 10px;
    marker-end: url(#arrowHead);
`;

const ArrowHeadPath = styled.path`
    fill: #333333; // TODO color should come from theme
`;

const ArrowSvg = styled.svg`
    width: 100%;
    height: 100%;
`;

const ExplanationSnackbar = styled(Snackbar)`
    position: absolute;
`;

const SnackbarButton = styled(Button)`
    color: white;
`;

const SnackbarTextField = styled(TextField)`
    display: flex;
    align-items: flex-start;

    &&& .themed-underline {
        ::before {
            border-bottom-color: white; // TODO color should come from theme
        }
        :hover::before {
            border-bottom-color: white; // TODO color should come from theme
        }
    }

    & .themed-input-label {
        color: white; // TODO color should come from theme
    }

    & .themed-text-field-input-label {
        color: white;
    }

    input {
        color: white;
    }
`;

const MEASURE_START = 'START';
const MEASURE_END = 'END';
const INPUT_NUMBER_OF_METERS = 'INPUT_NUMBER_OF_METERS';

export default function MapViewerPixelToMeterMeasureOverlay(props) {
    const { onSubmit, onCancel, isCancelable = true } = props;

    const [meters, setMeters] = useState(0);

    const { imageRef, containerRef, getCSSVariable } = useContext(MapViewerContext);

    const [{ measureStart, measureEnd, measuringStage }, setState] = useImmer({
        measureStart: null,
        measureEnd: null,
        measuringStage: MEASURE_START,
    });

    const handleClick = (event) => {
        event.persist();
        const refRect = imageRef.current.getBoundingClientRect();
        const scale = getCSSVariable(SCALE);

        if (measuringStage === MEASURE_START) {
            setState((state) => {
                state.measureStart = {
                    x: (event.clientX - refRect.left) / scale,
                    y: (event.clientY - refRect.top) / scale,
                };
                state.measureEnd = null;
                state.measuringStage = MEASURE_END;
            });
        } else if (measuringStage === MEASURE_END) {
            setState((state) => {
                state.measureEnd = {
                    x: (event.clientX - refRect.left) / scale,
                    y: (event.clientY - refRect.top) / scale,
                };
                state.measuringStage = INPUT_NUMBER_OF_METERS;
            });
        }
    };

    const handlePointerMove = (event) => {
        if (measuringStage === MEASURE_END) {
            event.persist();

            const refRect = imageRef.current.getBoundingClientRect();
            const scale = getCSSVariable(SCALE);

            setState((state) => {
                state.measureEnd = {
                    x: (event.clientX - refRect.left) / scale,
                    y: (event.clientY - refRect.top) / scale,
                };
            });
        }
    };

    const handleReset = (event) => {
        event.stopPropagation();
        setState((state) => {
            state.measureStart = null;
            state.measureEnd = null;
            state.measuringStage = MEASURE_START;
        });
    };

    const handleCancel = (event) => {
        event.stopPropagation();
        onCancel();
    };

    const handleSubmit = () => {
        // Calculate the number of pixels using Pythagoras
        const a = measureEnd.x - measureStart.x;
        const b = measureEnd.y - measureStart.y;
        const pixels = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));

        onSubmit(pixels / meters);
    };

    const renderExplanation = () => {
        switch (measuringStage) {
            case MEASURE_START:
                return 'Click anywhere on the map image to pick a point to start measuring from.';
            case MEASURE_END:
                return 'Now, click anywhere else to determine the end of the measurement line.';
            case INPUT_NUMBER_OF_METERS:
                const a = measureEnd.x - measureStart.x;
                const b = measureEnd.y - measureStart.y;
                const pixels = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
                const pixelToMeter = getCSSVariable(PIXEL_TO_METER);

                return (
                    <>
                        Finally, set how many meters does the measurement line represent
                        <NumberFormat
                            customInput={SnackbarTextField}
                            label={'Number of meters'}
                            onValueChange={({ floatValue }) => setMeters(floatValue)}
                            suffix={'m'}
                            defaultValue={pixelToMeter ? pixels / pixelToMeter : ''}
                            decimalScale={3}
                        />
                    </>
                );
            default:
                return '';
        }
    };

    return (
        <OverlayWrapper
            id={getMapViewerPixelToMeterMeasureOverlayId()}
            onClick={handleClick}
            onPointerMove={handlePointerMove}
        >
            <Portal container={containerRef.current}>
                <ExplanationSnackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <SnackbarContent
                        message={renderExplanation()}
                        action={
                            <>
                                {isCancelable && (
                                    <SnackbarButton variant={'text'} onClick={handleCancel}>
                                        Cancel
                                    </SnackbarButton>
                                )}

                                {measuringStage !== MEASURE_START && (
                                    <SnackbarButton variant={'text'} onClick={handleReset}>
                                        Reset
                                    </SnackbarButton>
                                )}

                                {measuringStage === INPUT_NUMBER_OF_METERS && (
                                    <SnackbarButton
                                        variant={'text'}
                                        onClick={handleSubmit}
                                        disabled={meters <= 0}
                                    >
                                        Submit
                                    </SnackbarButton>
                                )}
                            </>
                        }
                    />
                </ExplanationSnackbar>
            </Portal>

            <ArrowSvg>
                <defs>
                    <marker
                        id="arrowHead"
                        viewBox="0 -5 10 10"
                        refX={9}
                        refY={0}
                        markerWidth={10}
                        markerHeight={10}
                        orient="auto"
                    >
                        <ArrowHeadPath d="M0,-5L10,0L0,5" />
                    </marker>
                </defs>

                {measureStart && <Circle cx={measureStart.x} cy={measureStart.y} />}

                {measureStart && measureEnd && (
                    <Line x1={measureStart.x} y1={measureStart.y} x2={measureEnd.x} y2={measureEnd.y} />
                )}
            </ArrowSvg>
        </OverlayWrapper>
    );
}

MapViewerPixelToMeterMeasureOverlay.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isCancelable: PropTypes.bool,
};
