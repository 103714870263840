import React, { useState, useRef, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAllBuildingsInSpace from '../../common/hooks/data-fetching/useAllBuildingsInSpace';
import { updateBuildingState } from '../../../state-management/building/buildingActions';
import { BuildingsTableWrapper, Dropdown } from './components';
import {
    getSettingsBuildingSelectStateId,
    getSettingsBuildingsFilterStateId,
} from '../ProductLocations.selectors';
import { buildingStates, buildingTableSearch, states } from './constants';
import { isFulfilled, isRejected } from '../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../state-management/notification/notificationReducer';
import { Select, Skeleton } from '../../common/themed';
import { dropdownSelectStyle } from '../../common/styles/dropdown-in-table.style';
import Typography from '@material-ui/core/Typography';
import { RootRef } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { columns } from './buildingsTableColumns';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';
import log from '../../../utils/logger';

export default function BuildingsTable(props) {
    const dispatch = useDispatch();
    const targetRef = useRef();
    const [buildingState, setBuildingState] = useState({
        value: states.ALL_STATES,
        label: states.ALL_STATES,
    });
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const { data: buildings, isLoading: isLoadingBuildings, hasError } = useAllBuildingsInSpace();

    useLayoutEffect(() => {
        if (targetRef.current) {
            setWidth(targetRef.current.offsetWidth);
            setHeight(targetRef.current.offsetHeight);
        }
    }, []);

    const handleBuildingStateEdit = async (e, buildingId) => {
        if (e.value && buildingStates.includes(e.value)) {
            const currentBuildingState = buildings.find((b) => b.buildingId === buildingId)?.buildingState;
            if (currentBuildingState && currentBuildingState.toLowerCase() !== e.value.toLowerCase()) {
                const result = await dispatch(
                    updateBuildingState({ buildingId, buildingState: e.value.toLowerCase() })
                );
                if (isFulfilled(result)) {
                    dispatch(showSuccessNotification('Building state updated'));
                } else if (isRejected(result)) {
                    dispatch(showErrorNotification('Failed to update the building state'));
                }
            }
        }
    };

    const renderBuildings = (buildings = []) => {
        const buildingsWithoutState = buildings.filter((b) => !b.buildingState).map((b) => b?.buildingId);
        log.warn('Buildings without building states', buildingsWithoutState);
        return buildings
            .filter((b) => b.buildingState)
            .filter(
                (b) =>
                    buildingState.value === buildingTableSearch[0] ||
                    b.buildingState.toLowerCase() === buildingState.value.toLowerCase()
            )
            .map((b, i) => {
                return {
                    name: b.buildingName,
                    id: b.buildingId,
                    state: (
                        <Select
                            menuPortalTarget={document.body}
                            styles={dropdownSelectStyle}
                            key={i}
                            id={getSettingsBuildingSelectStateId(i)}
                            defaultValue={{ value: b.buildingState, label: b.buildingState }}
                            onChange={(event) => handleBuildingStateEdit(event, b.buildingId)}
                            placeholder="state"
                            options={buildingStates.map((d) => {
                                return { value: d, label: d };
                            })}
                        />
                    ),
                };
            });
    };

    if (hasError) {
        return <ErrorGeneral />;
    }
    return isLoadingBuildings ? (
        <Skeleton height={height} width={width} />
    ) : (
        <RootRef rootRef={targetRef}>
            <BuildingsTableWrapper>
                <MaterialTable
                    title={
                        <Typography style={{ marginRight: 50 }} variant="h5">
                            Buildings
                        </Typography>
                    }
                    options={{
                        search: true,
                        searchFieldAlignment: 'left',
                        paging: false,
                        headerStyle: { borderBottom: '2px solid #ff6200' },
                        maxBodyHeight: '50vh',
                        minBodyHeight: '50vh',
                    }}
                    columns={columns}
                    data={renderBuildings(buildings)}
                    components={{
                        Toolbar: (props) => (
                            <div style={{ display: 'flex' }}>
                                <MTableToolbar {...props} />
                                <Dropdown
                                    id={getSettingsBuildingsFilterStateId()}
                                    styles={{ minWidth: '170px', marginTop: '12px' }}
                                    value={buildingState}
                                    onChange={(state) => setBuildingState(state)}
                                    placeholder="state"
                                    options={buildingTableSearch.map((d) => {
                                        return { value: d, label: d };
                                    })}
                                />
                            </div>
                        ),
                    }}
                />
            </BuildingsTableWrapper>
        </RootRef>
    );
}
