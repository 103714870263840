import React, { forwardRef, useRef } from 'react';
import styled from 'styled-components';
import { Grid, IconButton, RootRef, Typography } from '@material-ui/core';
import {
    getTriggersListEntryAttachToRegionButtonClassName,
    getTriggersListEntryAttachToRegionButtonId,
    getTriggersListEntryDetachFromRegionButtonClassName,
    getTriggersListEntryDetachFromRegionButtonId,
    getTriggersListEntryDragImageId,
    getTriggersListEntryId,
    getTriggersListEntryNameId,
} from '../../MapContent.selectors';
import { MdNotificationsActive as TriggerIcon } from 'react-icons/md';
import { AiOutlineDisconnect, AiOutlineLink as AttachIcon } from 'react-icons/ai';
import { selectMapContentSelectedRegionId } from '../../../../state-management/user-inputs/mapContentSlice';
import {
    attachTriggerToRegion,
    detachTriggerFromRegion,
} from '../../../../state-management/trigger/triggerActions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCanEditTriggers } from '../../../../state-management/auth/authSelectors';
import Tooltip from '../../../common/themed/Tooltip';
import * as PropTypes from 'prop-types';
import { selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { CircularPreloader } from '../../../common/themed';
import { selectRegionIdToTriggersObject } from '../../../../state-management/region/regionSelectors';
import ItemsListEntry from '../../../common/side-drawer/ItemsListEntry';
import TriggerActions from './TriggerActions';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';

const DetachIcon = styled(AiOutlineDisconnect)`
    fill: red; // TODO color should come from theme
`;

const TriggerTitleWrapper = styled.div`
    display: flex;
    align-items: center;

    & > :not(:last-child):not(button) {
        margin-inline-end: 12px;
    }
`;

const TriggerListEntryText = styled(Typography).attrs(() => ({ variant: 'subtitle1', noWrap: true }))`
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 200px;
`;

const IconWrapper = forwardRef((props, ref) => (
    <RootRef rootRef={ref}>
        <IconButton {...props} />
    </RootRef>
));

const DragImage = styled.div`
    display: none;
    background-color: #ff6200; // TODO color should come from theme
    color: #ffffff;
    padding: 12px;
    border-radius: 6px;

    &.dragged {
        display: block;
        position: absolute;
        top: -2000px;
    }

    title {
        color: #ffffff;
    }
`;

export default function TriggerListEntry(props) {
    const { trigger } = props;
    const { triggerId, triggerName } = trigger ?? {};

    const dragImageRef = useRef();

    const dispatch = useDispatch();

    const canEditTriggers = useSelector(selectCanEditTriggers);
    const selectedRegionId = useSelector(selectMapContentSelectedRegionId);
    const { [selectedRegionId]: attachedTriggers } = useSelector(selectRegionIdToTriggersObject);
    const isAttaching = useSelector(
        selectIsLoading({
            type: attachTriggerToRegion.typePrefix,
            arg: { regionId: selectedRegionId, triggerId },
        })
    );
    const isDetaching = useSelector(
        selectIsLoading({
            type: detachTriggerFromRegion.typePrefix,
            arg: { regionId: selectedRegionId, triggerId },
        })
    );

    const isAttached = !!attachedTriggers?.[triggerId];

    // TODO consider using react-dnd or similar library
    const handleDragStart = (e) => {
        dragImageRef.current = document
            .getElementById(getTriggersListEntryDragImageId(triggerId))
            .cloneNode(true);
        dragImageRef.current.classList.add('dragged');
        document.body.appendChild(dragImageRef.current);

        e.dataTransfer.setDragImage(dragImageRef.current, 0, 0);
        e.dataTransfer.setData('oriient/trigger', JSON.stringify(trigger));
        e.dataTransfer.setData(`oriient/trigger_id:${triggerId}`, '');
    };

    const handleDragEnd = (e) => {
        e.preventDefault();

        if (dragImageRef.current) {
            document.body.removeChild(dragImageRef.current);
        }

        return false;
    };

    const handleAttachTriggerToRegion = async () => {
        const result = await dispatch(attachTriggerToRegion({ triggerId, regionId: selectedRegionId }));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`Trigger was successfully attached to region.`));
        } else {
            dispatch(showErrorNotification(`Failed to attach trigger to region.`));
        }
    };

    const handleDetachTriggerFromRegion = async () => {
        const result = await dispatch(detachTriggerFromRegion({ triggerId, regionId: selectedRegionId }));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`Trigger was successfully detached from region.`));
        } else {
            dispatch(showErrorNotification(`Failed to detach trigger from region.`));
        }
    };

    if (!trigger) {
        return null;
    }

    return (
        <>
            <Tooltip
                content={'Drag to a region to attach...'}
                placement={'top'}
                enabled={canEditTriggers}
                useWrapper={false}
            >
                <ItemsListEntry
                    id={getTriggersListEntryId(triggerId)}
                    isDraggable={canEditTriggers}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onDragOver={(e) => {
                        e.preventDefault();
                    }}
                    actions={<TriggerActions triggerId={triggerId} triggerName={triggerName} />}
                    content={
                        <TriggerTitleWrapper>
                            {selectedRegionId &&
                                (isDetaching || isAttaching ? (
                                    <CircularPreloader size={30} />
                                ) : isAttached ? (
                                    <Tooltip
                                        content={'Detach from region'}
                                        placement={'bottom'}
                                        useWrapper={false}
                                    >
                                        <IconWrapper
                                            id={getTriggersListEntryDetachFromRegionButtonId(triggerId)}
                                            className={getTriggersListEntryDetachFromRegionButtonClassName()}
                                            aria-label={'Detach from region'}
                                            onClick={handleDetachTriggerFromRegion}
                                        >
                                            <DetachIcon />
                                        </IconWrapper>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        content={'Attach to region'}
                                        placement={'bottom'}
                                        useWrapper={false}
                                    >
                                        <IconWrapper
                                            id={getTriggersListEntryAttachToRegionButtonId(triggerId)}
                                            className={getTriggersListEntryAttachToRegionButtonClassName()}
                                            aria-label={'Attach to region'}
                                            onClick={handleAttachTriggerToRegion}
                                        >
                                            <AttachIcon />
                                        </IconWrapper>
                                    </Tooltip>
                                ))}

                            <TriggerListEntryText id={getTriggersListEntryNameId(triggerId)}>
                                {triggerName}
                            </TriggerListEntryText>
                        </TriggerTitleWrapper>
                    }
                />
            </Tooltip>

            <DragImage id={getTriggersListEntryDragImageId(triggerId)}>
                <Grid container alignItems={'center'} spacing={2}>
                    <Grid item>
                        <TriggerIcon size={25} />
                    </Grid>
                    <Grid item>
                        <Typography variant={'h6'}> {trigger?.triggerName}</Typography>
                    </Grid>
                </Grid>
            </DragImage>
        </>
    );
}

TriggerListEntry.propTypes = {
    trigger: PropTypes.object,
};
