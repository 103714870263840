import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { expireSession, extendSession } from '../../state-management/auth/authActions';
import { selectSessionExpirationTime } from '../../state-management/auth/authSelectors';

let initialTimeBeforeExpirationToShowDialog = 1000 * 60; // 60 Seconds

export default function SessionExpirationDialog() {
    const dispatch = useDispatch();

    const expirationTime = useSelector(selectSessionExpirationTime);

    const [isDialogOpen, setIsDialogOpen] = useState(
        expirationTime - Date.now() <= initialTimeBeforeExpirationToShowDialog
    );
    const [timeLeft, setTimeLeft] = useState(
        Math.floor(Math.min(expirationTime - Date.now(), initialTimeBeforeExpirationToShowDialog) / 1000)
    );

    const handleAccept = () => {
        dispatch(extendSession());
        setIsDialogOpen(false);
    };

    const handleDecline = (event, reason) => {
        if(reason === "backdropClick") {
            return;
        }
        dispatch(expireSession());
    };

    useEffect(() => {
        setIsDialogOpen(false);

        const timeoutToShowDialog = setTimeout(() => {
            setTimeLeft(
                Math.floor(
                    Math.min(expirationTime - Date.now(), initialTimeBeforeExpirationToShowDialog) / 1000
                )
            );
            setIsDialogOpen(true);
        }, expirationTime - Date.now() - initialTimeBeforeExpirationToShowDialog);

        return () => {
            clearTimeout(timeoutToShowDialog);
        };
    }, [expirationTime]);

    useEffect(() => {
        if (isDialogOpen) {
            const timeLeftInterval = setInterval(() => {
                setTimeLeft((timeLeft) => timeLeft - 1);
            }, 1000);

            return () => {
                clearInterval(timeLeftInterval);
            };
        }
    }, [isDialogOpen]);

    useEffect(() => {
        if (timeLeft <= 0) {
            dispatch(expireSession());
        }
    }, [dispatch, timeLeft]);

    if (!expirationTime) {
        return null;
    }

    return (
        <ConfirmationDialog
            isOpen={isDialogOpen}
            title={'Warning: Session timeout'}
            message={`Your session is about to expire in ${timeLeft} seconds, click "Confirm" to stay logged in.`}
            onConfirm={handleAccept}
            onCancel={handleDecline}
            onClose={handleDecline}
        />
    );
}
