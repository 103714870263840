import MapViewerAreaOfInterestCreationOverlay from '../../common/map-viewer/overlays/areas-of-interest-creation';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setIsElementCreationOpen,
    selectMappingSelectedMapId,
    selectMappingIsElementCreationOpen,
} from '../../../state-management/user-inputs/mappingSlice';

export const ElementsCreationOverlay = () => {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const isElementCreationOpen = useSelector(selectMappingIsElementCreationOpen);

    return (
        isElementCreationOpen && (
            <MapViewerAreaOfInterestCreationOverlay
                mapId={selectedMapId}
                onSubmitted={() => dispatch(setIsElementCreationOpen(false))}
                onCancel={() => dispatch(setIsElementCreationOpen(false))}
                type="element"
            />
        )
    );
};
