import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Divider as MuiDivider, Drawer as MuiDrawer, Grid, IconButton, Typography } from '@material-ui/core';
import { AiOutlineArrowRight as BackIcon } from 'react-icons/ai';
import { FaCopy as CopyIcon } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showInfoNotification } from '../../../state-management/notification/notificationReducer';
import { useDispatch } from 'react-redux';

const Drawer = styled(MuiDrawer)`
    z-index: 1000;
    visibility: visible;
    width: 0;

    & .side-drawer-paper {
        margin-block-start: 4rem;
        height: calc(100% - 4rem);
        width: 320px;
        overflow: visible;
        padding: 20px;
    }
`;

const DrawerContent = styled.div`
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
`;

const Divider = styled(MuiDivider)`
    margin-block-start: 15px;
    margin-block-end: 15px;
`;

const Title = styled(Typography).attrs(() => ({
    variant: 'h5',
}))`
    color: #ff6200; // TODO color should come from theme
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
`;

const BackIconWrapper = styled(IconButton)`
    position: absolute;
    right: 2%;
`;

export default function ItemDetailsSideDrawer(props) {
    const { isOpen, title, isTitleCopyable = false, content, onBack, ...otherProps } = props;

    const dispatch = useDispatch();

    return (
        <Drawer
            open={isOpen}
            classes={{ paper: 'side-drawer-paper' }}
            anchor={'right'}
            hideBackdrop
            disableEnforceFocus
            {...otherProps}
        >
            <BackIconWrapper onClick={onBack}>
                <BackIcon />
            </BackIconWrapper>

            <Grid container alignItems={'center'}>
                <Title>{title}</Title>

                {isTitleCopyable && (
                    <CopyToClipboard
                        text={title}
                        onCopy={() => {
                            dispatch(showInfoNotification(`Successfully copied`));
                        }}
                    >
                        <IconButton onClick={(e) => e.stopPropagation()}>
                            <CopyIcon />
                        </IconButton>
                    </CopyToClipboard>
                )}
            </Grid>

            <Divider />

            <DrawerContent>{content}</DrawerContent>

            <Divider />
        </Drawer>
    );
}

ItemDetailsSideDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    isTitleCopyable: PropTypes.bool,
    content: PropTypes.node.isRequired,
    onBack: PropTypes.func.isRequired,
};
