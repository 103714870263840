import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const BannerContainer = styled(Grid)`
    display: flex;
    ${({ noSpace }) => (noSpace ? `gap:15px;` : `justify-content: space-between;`)}

    background-color: ${({ bgColor }) => bgColor};
    padding: 10px;
    min-height: 35px;
`;

const IconAndContent = styled(Grid)`
    display: flex;
    column-gap: 5px;
    align-items: center;
`;

export const Banner = ({ type, content, components, loading, bannerStyle = {}, noSpace }) => {
    const { bannerIcon, bgColor } = bannerStyle;
    return (
        <BannerContainer bgColor={bgColor} noSpace={noSpace}>
            <IconAndContent>
                {bannerIcon}
                <Typography>{content}</Typography>
            </IconAndContent>
            {loading ? <CircularProgress color={bgColor} /> : components}
        </BannerContainer>
    );
};
