export default class TimePeriod {
    get label() {
        return '';
    }

    get value() {
        return '';
    }

    getTimeSpan() {
        return { start: null, end: null };
    }

    get isPickable() {
        return false;
    }

    get isRange() {
        return false;
    }
}
