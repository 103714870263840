import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import {
    selectHasError as getSelectHasError,
    selectIsLoading as getSelectIsLoading,
} from '../../../../state-management/status/statusSelectors';
import { fetchAllLinesOfInterestInMap } from '../../../../state-management/mapping/line-of-interest/lineOfInterestActions';
import { selectLineOfInterestById } from '../../../../state-management/mapping/line-of-interest/lineOfInterestSelectors';
import { getSelectLinesOfInterestInMap } from '../../../../state-management/map/mapSelectors';

/**
 * Hook that fetches lines of interest to the Redux store and returns either all or one of them, based on the provided options.
 * @param asObject - Return all entities as an object, with the IDs as the keys.
 * @param mapId - Map ID to fetch all entities from.
 * @param lineId - Specific line ID to return.
 * @param withAttachments - Whether or not to fetch line attachments
 * @return {{isLoading: undefined, data: (undefined|*), hasError: undefined}}
 */
export default function useLinesOfInterest({ asObject = false, mapId, lineId } = {}) {
    const dispatch = useDispatch();

    const selectIsLoadingLines = useMemo(
        () => getSelectIsLoading(fetchAllLinesOfInterestInMap.typePrefix),
        []
    );
    const selectHasErrorInLines = useMemo(
        () => getSelectHasError(fetchAllLinesOfInterestInMap.typePrefix),
        []
    );
    const selectAllLinesInMap = useMemo(() => getSelectLinesOfInterestInMap(mapId), [mapId]);

    const { asArray: linesArray, asObject: linesObject } = useSelector(selectAllLinesInMap);
    const line = useSelector((state) => selectLineOfInterestById(state, lineId));
    const isLoading = useSelector(selectIsLoadingLines);
    const hasError = useSelector(selectHasErrorInLines);

    useEffect(() => {
        // If there is a provided map ID, and we haven't fetched all of its validations yet,
        // and we are not currently fetching them, fetch them
        if (mapId && !isLoading && !hasError && (!linesArray || linesArray.some((m) => !m))) {
            dispatch(fetchAllLinesOfInterestInMap(mapId));
        }
    }, [dispatch, isLoading, hasError, mapId, linesArray]);

    return {
        data: lineId ? line : asObject ? linesObject : linesArray,
        isLoading,
        hasError,
    };
}
