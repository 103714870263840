import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { MapViewerContext } from '../../MapViewerContext';
import { useDispatch } from 'react-redux';
import { getMapViewerEntranceId } from './MapViewerEntrancesOverlay.selectors';
import { GiEntryDoor } from 'react-icons/gi';
import {
    BOUNDARY_MAX_X,
    BOUNDARY_MAX_Y,
    BOUNDARY_MIN_X,
    BOUNDARY_MIN_Y,
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import clsx from 'clsx';
import {
    highlightEntrance,
    setSelectedEntranceCoordinates,
    setSelectedEntranceId,
    unhighlightEntrance,
} from '../../../../../state-management/user-inputs/buildingsSlice';
import { useImmer } from 'use-immer';

const EntranceIcon = styled(GiEntryDoor)`
    fill: #ff6200; // TODO color should come from theme
    stroke: #ffffff;
    stroke-width: 10px;
    overflow: visible;
    cursor: pointer;

    & > path {
        transform: translate(-50%, -50%) scale(min(calc(3 / var(${SCALE})), 30));
        transform-origin: center;
    }

    &.highlighted,
    &.selected {
        fill: #7a007a; // TODO color should come from theme
        stroke-width: 0;
    }
`;

function Entrance(props) {
    const { entrance, isHighlighted = false, isSelected = false, isEditable = false } = props;
    const { entranceId, coordinates } = entrance ?? {};

    const dispatch = useDispatch();

    const { getCSSVariable } = useContext(MapViewerContext);

    const [{ isDragging, pointerOrigin, originalPosition, displayedPosition }, setPosition] = useImmer({
        isDragging: false,
        pointerOrigin: { x: 0, y: 0 },
        originalPosition: { x: coordinates?.x, y: coordinates?.y },
        displayedPosition: { x: coordinates?.x, y: coordinates?.y },
    });

    const handlePointerEnter = () => {
        if (!isDragging && !isSelected) {
            dispatch(highlightEntrance(entranceId));
        }
    };

    const handlePointerLeave = () => {
        if (!isDragging && !isSelected) {
            dispatch(unhighlightEntrance(entranceId));
        }
    };

    const handlePointerDown = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();
            const { clientX, clientY } = event;

            if (isEditable) {
                setPosition((state) => {
                    state.isDragging = true;
                    state.pointerOrigin.x = clientX;
                    state.pointerOrigin.y = clientY;
                    state.originalPosition.x = state.displayedPosition.x;
                    state.originalPosition.y = state.displayedPosition.y;
                });
            } else {
                dispatch(setSelectedEntranceId(isSelected ? null : entranceId));
            }
        },
        [dispatch, entranceId, isEditable, isSelected, setPosition]
    );

    const handlePointerMove = useCallback(
        (event) => {
            const { clientX, clientY } = event;

            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
            const maxX = parseFloat(getCSSVariable(BOUNDARY_MAX_X));
            const minX = parseFloat(getCSSVariable(BOUNDARY_MIN_X));
            const maxY = parseFloat(getCSSVariable(BOUNDARY_MAX_Y));
            const minY = parseFloat(getCSSVariable(BOUNDARY_MIN_Y));

            const xDifference = (clientX - pointerOrigin?.x) / scale / pixelToMeter;
            const yDifference = (clientY - pointerOrigin?.y) / scale / pixelToMeter;

            setPosition((state) => {
                // Limit the X/Y differences so that they won't make the region go outside the map image boundaries
                const limitedXDiff = Math.min(
                    Math.max(xDifference, minX - state.originalPosition.x),
                    maxX - state.originalPosition.x
                );
                const limitedYDiff = Math.min(
                    Math.max(yDifference, state.originalPosition.y - maxY),
                    state.originalPosition.y - minY
                );

                state.displayedPosition.x = state.originalPosition.x + limitedXDiff;
                state.displayedPosition.y = state.originalPosition.y - limitedYDiff;
                state.displayedPosition.x = state.originalPosition.x + limitedXDiff;
                state.displayedPosition.y = state.originalPosition.y - limitedYDiff;
            });
        },
        [getCSSVariable, pointerOrigin, setPosition]
    );

    const handlePointerUp = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();

            setPosition((state) => {
                state.isDragging = false;
                state.pointerOrigin.x = 0;
                state.pointerOrigin.y = 0;
                state.originalPosition.x = state.displayedPosition.x;
                state.originalPosition.y = state.displayedPosition.y;
            });
        },
        [setPosition]
    );

    useEffect(() => {
        // Attach event listeners for rectangle dragging
        if (isDragging) {
            window.addEventListener('pointermove', handlePointerMove);
            window.addEventListener('pointerup', handlePointerUp);

            return () => {
                window.removeEventListener('pointermove', handlePointerMove);
                window.removeEventListener('pointerup', handlePointerUp);
            };
        }
    }, [handlePointerMove, handlePointerUp, isDragging]);

    useEffect(() => {
        // If the user canceled the position edit, reset to the original position
        if (!isEditable) {
            setPosition((state) => {
                state.isDragging = false;
                state.pointerOrigin.x = 0;
                state.pointerOrigin.y = 0;
                state.originalPosition.x = coordinates?.x;
                state.originalPosition.y = coordinates?.y;
                state.displayedPosition.x = coordinates?.x;
                state.displayedPosition.y = coordinates?.y;
            });
        }
    }, [coordinates, isEditable, setPosition]);

    useEffect(() => {
        if (!isDragging) {
            dispatch(
                setSelectedEntranceCoordinates({
                    x: originalPosition.x,
                    y: originalPosition.y,
                })
            );
        }
    }, [dispatch, isDragging, originalPosition.x, originalPosition.y]);

    const { x, y } = useMemo(() => {
        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        return {
            x: (displayedPosition?.x - mapOffsetX) * pixelToMeter,
            y: (imageNaturalHeight / pixelToMeter - (displayedPosition?.y - mapOffsetY)) * pixelToMeter,
        };
    }, [displayedPosition, getCSSVariable]);

    if (!entrance) {
        return null;
    }

    return (
        <EntranceIcon
            id={getMapViewerEntranceId(entranceId)}
            x={x}
            y={y}
            className={clsx({ highlighted: isHighlighted, selected: isSelected })}
            onPointerEnter={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
            onPointerDown={handlePointerDown}
        />
    );
}

Entrance.propTypes = {
    entrance: PropTypes.object.isRequired,
    isHighlighted: PropTypes.bool,
    isSelected: PropTypes.bool,
    isEditable: PropTypes.bool,
};

export default memo(Entrance);
