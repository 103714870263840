import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card as MuiCard, Portal, Typography } from '@material-ui/core';
import { MapViewerContext } from '../../../MapViewerContext';
import { getGoogleMapsOpacityTitleId } from './GoogleMapsMapImageOpacityOverlay.selectors';
import styled from 'styled-components';
import { useGoogleMap } from '@react-google-maps/api';

const Card = styled(MuiCard)`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px;
`;

export default function GoogleMapsAddressAndCoordinatesOverlay() {
    const { containerRef } = useContext(MapViewerContext);

    const map = useGoogleMap();

    const geocoderRef = useRef(new window.google.maps.Geocoder());

    const [position, setPosition] = useState(null);

    useEffect(() => {
        const listener = map.addListener('click', (e) => {
            const lat = e.latLng.lat();
            const lng = e.latLng.lng();

            geocoderRef.current.geocode({ location: { lat, lng } }, (results, status) => {
                let address;

                if (status === 'OK') {
                    address = results?.[0]?.formatted_address;
                } else {
                    address = null;
                }

                setPosition({ address, lat, lng });
            });
        });

        return () => {
            listener.remove();
        };
    }, [map]);

    if (!position) {
        return null;
    }

    return (
        <Portal container={containerRef.current}>
            <Card>
                {position?.address && (
                    <Typography id={getGoogleMapsOpacityTitleId()}>Address: {position?.address}</Typography>
                )}

                <Typography id={getGoogleMapsOpacityTitleId()}>Latitude: {position?.lat}</Typography>

                <Typography id={getGoogleMapsOpacityTitleId()}>Longitude: {position?.lng}</Typography>
            </Card>
        </Portal>
    );
}
