import { useState, useEffect } from 'react';
import eventsManagerWs from '../events-manager/eventsManagerWs';

export const useEventManagerWs = ({ topics }) => {
    const [isConnected, setIsConnected] = useState();
    useEffect(() => {
        eventsManagerWs
            .connect({
                reconnectOnClose: true,
            })
            .then(() => setIsConnected(true));

        return () => {
            eventsManagerWs.disconnect();
            setIsConnected(false);
        };
    }, []);

    useEffect(() => {
        if (isConnected && topics.length > 0) {
            Promise.all(topics.map((topic) => eventsManagerWs.subscribe(topic, true)));

            return () => {
                if (isConnected) {
                    Promise.all(topics.map((topic) => eventsManagerWs.unsubscribe(topic, true))).catch(
                        console.log
                    );
                }
            };
        }
    }, [isConnected, topics]);
};
