import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './triggerEntityAdapter';
import {
    fetchAllTriggersInSpace,
    createTrigger,
    updateTrigger,
    deleteTrigger,
    attachTriggerToRegion,
    detachTriggerFromRegion,
} from './triggerActions';
import { expireSession, logout } from '../auth/authActions';

const { getSelectors, getInitialState, upsertMany, upsertOne, addOne, removeOne } = entityAdapter;

export const { reducer } = createSlice({
    name: 'triggers',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchAllTriggersInSpace.fulfilled]: (state, action) => {
            upsertMany(state, action.payload);
        },

        [createTrigger.fulfilled]: (state, action) => {
            addOne(state, action.payload);
        },

        [updateTrigger.fulfilled]: (state, action) => {
            upsertOne(state, action.payload);
        },

        [deleteTrigger.fulfilled]: (state, action) => {
            removeOne(state, action.meta.arg);
        },

        [attachTriggerToRegion.fulfilled]: (state, action) => {
            const { regionId, triggerId } = action.meta.arg;
            const { regionIds = [] } = getSelectors().selectById(state, triggerId);
            upsertOne(state, { triggerId, regionIds: [...new Set([...regionIds, regionId])] });
        },

        [detachTriggerFromRegion.fulfilled]: (state, action) => {
            const { regionId, triggerId } = action.meta.arg;
            const { regionIds = [] } = getSelectors().selectById(state, triggerId);
            upsertOne(state, { triggerId, regionIds: regionIds.filter((id) => id !== regionId) });
        },

        [expireSession.fulfilled]: (state) => getInitialState(),
        [logout.fulfilled]: (state) => getInitialState(),
    },
});
