import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Radio,
    CircularPreloader,
    Skeleton,
    Tooltip,
    SwitchLabel as Label,
    Checkbox,
} from '../../../common/themed';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import {
    RadioGroup,
    FormControlLabel,
    Grid,
    Typography,
    Divider,
    List,
    InputAdornment,
    IconButton,
    FormGroup,
} from '@material-ui/core';
import useTriggers from '../../../common/hooks/data-fetching/useTriggers';
import { selectRegionPlacementById } from '../../../../state-management/region-placement/regionPlacementSelectors';
import MetadataEditor from '../../../map-content/dialogs/common/metadata-editor/MetadataEditor';
import { boolean, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers';
import uuid from 'uuid/v4';
import {
    selectMapContentSelectedBuildingId,
    selectMapContentSelectedFloorId,
    selectMapContentSelectedMapId,
} from '../../../../state-management/user-inputs/mapContentSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
    getRegionEditorRegionNameId,
    getSignMarkEditorSignMarkId,
    getSignMarkEditorTextId,
} from './SignMarkEditorDialog.selectors';
import { getSelectAllTriggersAttachedToRegion } from '../../../../state-management/trigger/triggerSelectors';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showInfoNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy as CopyIcon } from 'react-icons/fa';
import { selectSignMarkById } from '../../../../state-management/mapping/sign-mark/signMarkSelectors';
import ExpandableSection from '../../../map-content/dialogs/common/ExpandableSection';
import { updateSignMark } from '../../../../state-management/mapping/sign-mark/signMarkActions';
import { selectMappingSelectedBuildingId, selectMappingSelectedFloorId, selectMappingSelectedMapId } from '../../../../state-management/user-inputs/mappingSlice';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const SIGN_MARKS_LOCATION = {
    side: 'side',
    center: 'center'
}

const Section = styled.div`
    margin-block-end: 10px;
`;

const Span = styled.span`
    display: flex;
    column-gap: 5px;
    margin-bottom: 10px;
`

const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;

    & > div {

        & > button {
            &.MuiToggleButton-root.Mui-selected {
                background-color: #ff6200;
                color: white;
            }
            padding: 6px;
            text-transform: unset;
        }
    }
`;

const StepLabel = styled.label`
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 13px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
`;

const schema = object().shape({
    text: string().trim().required('This is required'),
    metadata: string(),
});

export default function SignMarkEditorDialog(props) {
    const { signMarkId, placementId, onClose } = props;

    const [signMarkLocationSelected, setSignMarkLocationSelected] = useState('side')

    const dispatch = useDispatch();
    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const buildingId = useSelector(selectMappingSelectedBuildingId);
    const floorId = useSelector(selectMappingSelectedFloorId);
    const mapId = useSelector(selectMappingSelectedMapId);
    const signMark = useSelector((state) => selectSignMarkById(state, signMarkId)) ?? null;
    const { isLoading: isLoadingTriggers } = useTriggers({
        spaceId: selectedSpaceId,
    });

    const { register, handleSubmit, control, formState, errors, setValue, getValues } = useForm({
        defaultValues: {
            text: signMark?.text,
            metadata:
                signMark?.metadata && signMark?.metadata !== ''
                    ? signMark.metadata
                    : '{}',
        },
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const metadata = getValues('metadata');

    const submitForm = async (data) => {
        const { text, metadata } = data;
        const result = await dispatch(updateSignMark({
            buildingId, floorId, mapId, signMarkId, updatedSignMark: {
                coordinates: signMark.coordinates,
                text,
                metadata: metadata ?? ''
            }
        }));
        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`Sign mark was updated successfully`));
            onClose();
        } else {
            dispatch(showErrorNotification(`Failed to update sign mark`))
        }
    };

    const onSignMarkLocationChanged = (location) => {
        if (location === SIGN_MARKS_LOCATION.side) {
            setSignMarkLocationSelected(SIGN_MARKS_LOCATION.side)
            setValue('metadata', JSON.stringify({ "visibleRanges": [{ "belowMeters": 15 }] }))
        } else if (location === SIGN_MARKS_LOCATION.center) {
            setSignMarkLocationSelected(SIGN_MARKS_LOCATION.center)
            setValue('metadata', JSON.stringify({ "visibleRanges": [{ "aboveMeters": 15 }] }))
        }
    }

    useEffect(() => {
        const parsedMetadata = JSON.parse(signMark?.metadata || '{}');
        const visibleRanges = parsedMetadata?.visibleRanges?.[0] || {};
        if (visibleRanges.belowMeters) {
            setSignMarkLocationSelected(SIGN_MARKS_LOCATION.side)
        } else if (visibleRanges.aboveMeters) {
            setSignMarkLocationSelected(SIGN_MARKS_LOCATION.center)
        }
    }, [signMark?.metadata])

    // useEffect(() => {
    //     if (signMarkLocationSelected === SIGN_MARKS_LOCATION.side) {
    //         setValue('metadata', JSON.stringify({ "visibleRanges": [{ "belowMeters": 15 }] }))
    //     } else if (signMarkLocationSelected === SIGN_MARKS_LOCATION.center) {
    //         setValue('metadata', JSON.stringify({ "visibleRanges": [{ "aboveMeters": 15 }] }))
    //     }
    // }, [signMarkLocationSelected])

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth={'sm'}>
            <DialogTitle>Sign mark Editor</DialogTitle>
            <form onSubmit={handleSubmit(submitForm)}>
                <DialogContent>
                    <StepContainer>
                        <StepLabel>Step</StepLabel>
                        <ToggleButtonGroup
                            value={signMarkLocationSelected}
                            exclusive
                            onChange={(e, value) => onSignMarkLocationChanged(value)}
                        >
                            <ToggleButton value={'center'}>Center</ToggleButton>
                            <ToggleButton value={'side'}>Side</ToggleButton>
                        </ToggleButtonGroup>
                    </StepContainer>
                    <Grid container alignItems={'center'} component={Section} spacing={5}>
                        <Grid item>
                            <TextField
                                id={getSignMarkEditorSignMarkId()}
                                label={'ID'}
                                value={signMark?.signMarkId}
                                disabled
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CopyToClipboard
                                                text={signMark?.signMarkId}
                                                onCopy={() =>
                                                    dispatch(showInfoNotification(`Sign mark ID copied`))
                                                }
                                            >
                                                <IconButton onClick={(e) => e.stopPropagation()}>
                                                    <CopyIcon size={20} />
                                                </IconButton>
                                            </CopyToClipboard>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                id={getSignMarkEditorTextId()}
                                name={'text'}
                                label={'Text'}
                                inputRef={register}
                                defaultValue={signMark?.text}
                                error={!!errors?.text}
                                helperText={errors?.text?.message}
                            />
                        </Grid>

                    </Grid>

                    <Divider />

                    <ExpandableSection title={'Metadata'}>
                        <Controller
                            name={'metadata'}
                            control={control}
                            render={({ onChange, value }) => (
                                <MetadataEditor
                                    value={value}
                                    onChange={onChange}
                                    enableSwitching={false}
                                />
                            )}
                        />
                    </ExpandableSection>
                </DialogContent>

                <Divider />

                <DialogActions>
                    <Button variant={'text'} onClick={onClose}>
                        Cancel
                    </Button>

                    {formState.isSubmitting ? (
                        <CircularPreloader />
                    ) : (
                        <Button type={'submit'}>
                            Save
                        </Button>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
}

SignMarkEditorDialog.propTypes = {
    signMarkId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};
