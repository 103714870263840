import React, { useEffect } from 'react';
import { Button, CircularPreloader, TextField } from '../../../common/themed';
import { useDispatch, useSelector } from 'react-redux';
import { updateFloor } from '../../../../state-management/floor/floorActions';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { selectCanEditBuildings } from '../../../../state-management/auth/authSelectors';
import {
    getFloorHeightId,
    getFloorIdId,
    getFloorIndexId,
    getFloorNameId,
    getFloorRotationId,
    getFloorShortNameId,
} from '../../buildings.selectors';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showInfoNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { IconButton, InputAdornment } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy as CopyIcon } from 'react-icons/fa';

const Form = styled.form`
    display: flex;
    flex-direction: column;

    & > :not(:last-child) {
        margin-block-end: 20px;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    & > :not(:last-child) {
        margin-inline-end: 10px;
    }
`;

export default function FloorDetailsForm(props) {
    const { buildingId, floor } = props;
    const { floorId } = floor ?? {};

    const dispatch = useDispatch();

    const canEditBuildings = useSelector(selectCanEditBuildings);

    const {
        handleSubmit,
        register,
        errors,
        formState: { isDirty, isValid, isSubmitting },
        control,
        reset,
        setValue,
        watch,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            floorName: floor?.floorName ?? '',
            floorShortName: floor?.floorShortName ?? '',
            floorIndex: floor?.floorIndex ?? '',
            height: floor?.height ?? 0,
            rotation: floor?.rotation ?? 0,
            primary: floor?.primary,
        },
    });

    const primary = watch('primary');

    const submitForm = async (floorData) => {
        const result = await dispatch(updateFloor({ buildingId, floorId, floorData }));

        if (isFulfilled(result)) {
            reset(floorData);
            dispatch(showSuccessNotification('Floor updated successfully.'));
        } else {
            dispatch(showErrorNotification(`Failed to update floor`));
        }
    };

    useEffect(() => {
        if (floor) {
            reset({
                floorName: floor?.floorName ?? '',
                floorShortName: floor?.floorShortName ?? '',
                floorIndex: floor?.floorIndex ?? '',
                height: floor?.height ?? 0,
                rotation: floor?.rotation ?? 0,
                primary: floor?.primary,
            });
        }
    }, [floor, reset]);

    return (
        <Form onSubmit={handleSubmit(submitForm)}>
            <TextField
                id={getFloorIdId()}
                label={'ID'}
                value={floorId}
                disabled
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <CopyToClipboard
                                text={floorId}
                                onCopy={() => dispatch(showInfoNotification(`Floor ID copied`))}
                            >
                                <IconButton onClick={(e) => e.stopPropagation()}>
                                    <CopyIcon size={20} />
                                </IconButton>
                            </CopyToClipboard>
                        </InputAdornment>
                    ),
                }}
            />

            <Controller
                id={getFloorNameId()}
                name={'floorName'}
                control={control}
                rules={{ required: 'This is required' }}
                render={({ onChange, value }) => (
                    <TextField
                        label={'Name'}
                        error={!!errors.floorName}
                        helperText={errors.floorName?.message}
                        value={value}
                        onChange={onChange}
                        disabled={!canEditBuildings}
                    />
                )}
            />

            <Controller
                id={getFloorShortNameId()}
                name={'floorShortName'}
                control={control}
                rules={{ required: 'This is required' }}
                render={({ onChange, value }) => (
                    <TextField
                        label={'Short Name'}
                        error={!!errors.floorShortName}
                        helperText={errors.floorShortName?.message}
                        value={value}
                        onChange={onChange}
                        disabled={!canEditBuildings}
                    />
                )}
            />

            <Controller
                id={getFloorIndexId()}
                name={'floorIndex'}
                control={control}
                rules={{ required: 'This is required' }}
                render={({ onChange, value }) => (
                    <NumberFormat
                        label={'Index'}
                        error={!!errors?.floorIndex}
                        helperText={errors?.floorIndex?.message}
                        customInput={TextField}
                        onValueChange={({ floatValue }) => onChange(floatValue)}
                        value={value}
                        decimalScale={0}
                        disabled={!canEditBuildings}
                    />
                )}
            />

            <Controller
                id={getFloorHeightId()}
                name={'height'}
                control={control}
                rules={{ required: 'This is required' }}
                render={({ onChange, value }) => (
                    <NumberFormat
                        label={'Height'}
                        error={!!errors?.height}
                        helperText={errors?.height?.message}
                        customInput={TextField}
                        onValueChange={({ floatValue }) => onChange(floatValue)}
                        value={value}
                        suffix={' m'}
                        disabled={!canEditBuildings}
                    />
                )}
            />

            <Controller
                id={getFloorRotationId()}
                name={'rotation'}
                control={control}
                rules={{
                    required: 'This is required',
                    min: {
                        value: -360,
                        message: 'Please provide a value between 360 and -360 degrees',
                    },
                    max: {
                        value: 360,
                        message: 'Please provide a value between 360 and -360 degrees',
                    },
                }}
                render={({ onChange, value }) => (
                    <NumberFormat
                        label={'Rotation'}
                        error={!!errors?.rotation}
                        helperText={errors?.rotation?.message}
                        customInput={TextField}
                        onValueChange={({ floatValue }) => onChange(floatValue)}
                        value={value}
                        suffix={'°'}
                        disabled={!canEditBuildings}
                    />
                )}
            />

            <input hidden name={'primary'} ref={register} type={'checkbox'} />

            <Button
                variant={'outlined'}
                disabled={primary}
                onClick={() => setValue('primary', !primary, { shouldDirty: true })}
            >
                {primary ? 'Marked as Primary Floor' : !!primary ? 'Unmark as primary' : 'Mark as primary'}
            </Button>

            {canEditBuildings && isDirty && (
                <ButtonsWrapper>
                    <Button variant={'text'} disabled={isSubmitting} onClick={() => reset()}>
                        Reset
                    </Button>

                    {isSubmitting ? (
                        <CircularPreloader />
                    ) : (
                        <Button type={'submit'} disabled={!isValid}>
                            Save
                        </Button>
                    )}
                </ButtonsWrapper>
            )}
        </Form>
    );
}
