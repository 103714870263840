import React from 'react';
import * as PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

export default function RemoveConfirmDialog(props) {
    const { open, onConfirm, onCancel } = props;

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>{'Are you sure?'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You are about to remove this item. This action is irreversible.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

RemoveConfirmDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
