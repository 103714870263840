import React, { useEffect, useState } from 'react';
import { Button, Checkbox, CircularPreloader, TextField } from '../../../common/themed';
import {
    selectBuildingsCalculatedPixelToMeter,
    selectBuildingsIsSettingPixelToMeter,
    selectMapImagesChecked,
    selectBuildingsCalculatedMapOffset,
    selectBuildingIsSettingMapOffset,
    setIsSettingMapOffset,
    setIsSettingPixelToMeter,
    setMapImageChecked,
} from '../../../../state-management/user-inputs/buildingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { createMapImage, deleteMapImage, updateMap } from '../../../../state-management/map/mapActions';
import { Controller, useForm } from 'react-hook-form';
import { FormControlLabel, Grid, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import { getSelectDefaultReferenceMap } from '../../../../state-management/map/mapSelectors';
import { MdFileUpload as UploadIcon } from 'react-icons/md';
import { RiCameraSwitchFill as ReplaceIcon } from 'react-icons/ri';
import UploadNewMapImageDialog from '../../dialogs/upload-new-map-image';
import RegenerateMapDialog from '../../dialogs/regenrate-map';
import { isFulfilled } from '../../../../state-management/utils';
import {
    selectCanEditBuildings,
    selectCanAccessRegenerateMaps,
} from '../../../../state-management/auth/authSelectors';
import {
    getImageUsageListId,
    getMapComponenetImageRotationId,
    getMapIdId,
    getMapNameId,
    getMapOffsetId,
    getMapPixelToMeterId,
    getMapUsageId,
} from '../../buildings.selectors';
import {
    showErrorNotification,
    showInfoNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy as CopyIcon } from 'react-icons/fa';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import { BsDownload as DownloadIcon, BsFillPinFill } from 'react-icons/bs';
import { getDefaultMapImage, getImagesWithoutDefault } from '../../../../utils/mapImages.helper';
import { TooltipConfirmation } from '../../../common/side-drawer/ToolTipConfirmation';
import clsx from 'clsx';
import { regenerateMap } from '../../../../state-management/mapping/map-validation/mapValidationActions';
import NumberFormat from 'react-number-format';
import { fetchBuildingFullData } from '../../../../state-management/building/buildingActions';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import { isUndefinedOrEmpty, validateMapName } from '../../../../utils/general';
import { setNavgraphErrors } from '../../../../state-management/building/buildingReducer';

const Form = styled.form`
    display: flex;
    flex-direction: column;

    & > :not(:last-child) {
        margin-block-end: 20px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    & > :not(:last-child) {
        margin-inline-end: 10px;
    }
`;

const SpacedButton = styled(Button)`
    display: flex;
    place-content: start;
    & > span > svg {
        margin-right: 10px;
    }
`;
const ImageUsageList = styled(Grid).attrs({
    direction: 'column',
    container: true,
    spacing: 0,
    component: 'ul',
})`
    padding: 0;
    list-style: none;
    margin-block-start: -20px;
    max-height: 90px;
    overflow-y: auto;
    flex-wrap: nowrap;
`;
const ImageUsageListItem = styled(Grid).attrs({
    item: true,
    component: 'li',
})`
    display: flex;
    overflow: hidden;
    &:hover {
        transition: background 0.3s ease;
        background: #eee;

        & > div {
            display: flex;
        }
    }

    & > label {
        width: 100%;
        margin-right: 0;
    }
`;
const ImageUsageListItemActions = styled.div`
    display: none;
    animation: slideIn 0.2s ease;
    background: #ff6200;
    &.display-actions {
        display: flex !important;
    }
    @keyframes slideIn {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0%);
        }
    }
`;

const MapUsageCheckBox = styled(Checkbox)`
    padding: 12px;
`;

const OffsetInputContainer = styled.div`
    display: flex;
`;
const ImageRotationContainer = styled.div`
    display: flex;
`;
const PixelToMeterTextField = styled(NumberFormat)``;

const OffsetTextField = styled(NumberFormat)``;

const ImageRotationTextField = styled(NumberFormat)`
    width: 100%;
`;

export default function MapDetailsForm(props) {
    const { buildingId, floorId, map } = props;
    const { mapId } = map ?? {};

    const [isUploadNewImageDialogOpen, setIsUploadNewImageDialogOpen] = useState(false);
    const [isRegenerateMapDialogIsOpen, setIsRegenerateMapDialogIsOpen] = useState(false);
    const [imageMode, setimageMode] = useState('replace');

    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const p2m = useSelector(selectBuildingsCalculatedPixelToMeter);
    const calculatedMapOffset = useSelector(selectBuildingsCalculatedMapOffset);
    const isSettingPixelToMeter = useSelector(selectBuildingsIsSettingPixelToMeter);
    const isSettingMapOffset = useSelector(selectBuildingIsSettingMapOffset);
    const referenceMap = useSelector(getSelectDefaultReferenceMap(buildingId));
    const mapImagesChecked = useSelector(selectMapImagesChecked);
    const canEditBuildings = useSelector(selectCanEditBuildings);
    const canRegenerateMaps = useSelector(selectCanAccessRegenerateMaps);
    const defaultmapImage = getDefaultMapImage(map?.mapImages);
    const [mapImages, setMapImages] = useState(getImagesWithoutDefault(map?.mapImages));
    const [selectedAction, setSelectedAction] = useState('');
    const [mapComponentImageRotationEnabled, setMapComponentImageRotationEnabled] = useState(
        Boolean(map?.mapComponentImageRotation)
    );

    useEffect(() => {
        setMapImages(getImagesWithoutDefault(map?.mapImages, mapImagesChecked));
    }, [mapImagesChecked, map?.mapImages]);
    const {
        handleSubmit,
        errors,
        formState: { isDirty, isValid, isSubmitting, dirtyFields },
        control,
        getValues,
        reset,
        setValue,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            mapName: map?.mapName ?? '',
            pixelToMeter: map?.pixelToMeter ?? 1,
            mapOffsetX: map?.mapOffset?.x ?? 0,
            mapOffsetY: map?.mapOffset?.y ?? 0,
            mapUsage: defaultmapImage?.mapUsage ?? '',
            mapComponentImageRotation: map?.mapComponentImageRotation,
        },
    });

    const submitForm = async (mapData) => {
        if (!mapData.mapOffset) {
            /* 
                Incase we submit the form with the new P2M & Offset improvements, we are losing the mapOffset for mapOffsetX & mapOffsetY (due to the design constraints with react hook form ).
                In that case we are combining the mapOffsetX and mapOffsetY to be mapOffset to the request payload.
             */
            mapData = {
                ...mapData,
                mapOffset: { x: mapData.mapOffsetX, y: mapData.mapOffsetY },
                mapComponentImageRotation: mapData.mapComponentImageRotation,
            };
        }
        if (!mapData.imgBase64) {
            delete mapData.imgBase64;
        }

        if ([undefined, '', null].includes(mapData.mapComponentImageRotation)) {
            const imageRotation = getValues('mapComponentImageRotation');
            mapData.mapComponentImageRotation = imageRotation;
        }

        if ([undefined, '', null].includes(mapData.mapComponentImageRotation)) {
            delete mapData.mapComponentImageRotation;
        }

        const result = await dispatch(updateMap({ buildingId, floorId, mapId: map?.mapId, mapData }));

        if (isFulfilled(result)) {
            if (mapData.mapUsage === 'obstacles') {
                dispatch(fetchBuildingFullData({ spaceId: selectedSpaceId, buildingId }));
            }
            setIsUploadNewImageDialogOpen(false);
            dispatch(showSuccessNotification('Map updated successfully'));
        } else {
            dispatch(showErrorNotification(`Failed to update map`));
        }
    };

    const handleNewMapImageSubmit = async (mapData) => {
        const result = await dispatch(createMapImage({ buildingId, floorId, mapId: map?.mapId, mapData }));

        if (isFulfilled(result)) {
            if (mapData.mapUsage === 'obstacles') {
                dispatch(fetchBuildingFullData({ spaceId: selectedSpaceId, buildingId }));
            }
            setIsUploadNewImageDialogOpen(false);
            dispatch(showSuccessNotification('New image was uploaded successfully'));
        } else {
            if (result.meta.rejectedWithValue && result.payload && result.payload.response) {
                dispatch(showErrorNotification(result.payload.response.data?.message));
                alert(JSON.stringify(result.payload.response.data?.data));
            } else {
                dispatch(showErrorNotification(`Failed to upload new map image`));
            }
        }
    };

    const hanldeMapImageToggleClick = (e, imageId) => {
        dispatch(setMapImageChecked({ imageId, isChecked: e.target.checked }));
    };

    const handleImageModeClick = (mode) => {
        setimageMode(mode);
        setIsUploadNewImageDialogOpen(true);
    };
    const handleDeleteMapImage = async (mapImage) => {
        const result = await dispatch(
            deleteMapImage({ buildingId, floorId, mapId: map?.mapId, imageId: mapImage.imageId })
        );

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`Map image deleted successfully`));
            dispatch(setNavgraphErrors({ mapId: map?.mapId, errors: [] }));
        } else {
            dispatch(showErrorNotification(`Failed to delete map image`));
        }
    };

    const onRegenerate = async () => {
        const result = await dispatch(regenerateMap(mapId));
        return isFulfilled(result);
    };

    const handleReset = () => {
        dispatch(setIsSettingPixelToMeter(false));
        dispatch(setIsSettingMapOffset(false));
        reset();
    };

    useEffect(() => {
        if (map) {
            reset({
                mapName: map?.mapName ?? '',
                pixelToMeter: map?.pixelToMeter ?? 1,
                mapOffsetX: map?.mapOffset?.x ?? 0,
                mapOffsetY: map?.mapOffset?.y ?? 0,
                mapUsage: defaultmapImage?.mapUsage ?? '',
                mapArea: map?.mapArea?.mapArea ?? 0,
                mapComponentImageRotation: map?.mapComponentImageRotation,
            });
            setMapComponentImageRotationEnabled(map?.mapComponentImageRotation !== null);
        }
    }, [reset, map]);

    useEffect(() => {
        setValue('pixelToMeter', p2m, { shouldDirty: false });
    }, [p2m, setValue]);

    useEffect(() => {
        setValue(
            'mapComponentImageRotation',
            mapComponentImageRotationEnabled ? map?.mapComponentImageRotation || 0 : null,
            {
                shouldDirty: true,
            }
        );
    }, [mapComponentImageRotationEnabled, setValue]);
    useEffect(() => {
        setValue('mapOffsetX', calculatedMapOffset?.x, { shouldDirty: false });
        setValue('mapOffsetY', calculatedMapOffset?.y, { shouldDirty: false });
    }, [calculatedMapOffset, setValue]);

    return (
        <Form onSubmit={handleSubmit(submitForm)}>
            <TextField
                id={getMapIdId()}
                label={'ID'}
                value={mapId}
                disabled
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <CopyToClipboard
                                text={mapId}
                                onCopy={() => dispatch(showInfoNotification(`Map ID copied`))}
                            >
                                <IconButton
                                    onClick={(e) => e.stopPropagation()}
                                    onDoubleClick={(e) => setIsRegenerateMapDialogIsOpen(canRegenerateMaps)}
                                >
                                    <CopyIcon size={20} />
                                </IconButton>
                            </CopyToClipboard>
                        </InputAdornment>
                    ),
                }}
            />

            <Controller
                id={getMapNameId()}
                name={'mapName'}
                control={control}
                rules={{ required: 'This is required', validate: validateMapName }}
                render={({ onChange, value }) => (
                    <TextField
                        label={'Name'}
                        error={!!errors.mapName}
                        helperText={errors.mapName?.message}
                        value={value}
                        onChange={onChange}
                        disabled={!canEditBuildings}
                    />
                )}
            />

            <Controller
                id={getMapUsageId()}
                name={'mapUsage'}
                control={control}
                rules={{ required: 'This is required' }}
                render={({ onChange, value }) => (
                    <TextField
                        label={'Image Usage'}
                        error={!!errors.mapUsage}
                        helperText={errors.mapUsage?.message}
                        value={value}
                        onChange={onChange}
                        disabled
                    />
                )}
            />
            <Controller
                id={getImageUsageListId()}
                name={'imageUsageList'}
                control={control}
                render={(onChange, value) => {
                    return (
                        <ImageUsageList>
                            {mapImages?.map((mapImage) => {
                                return (
                                    <ImageUsageListItem key={mapImage.imageId}>
                                        <FormControlLabel
                                            control={
                                                <MapUsageCheckBox
                                                    defaultChecked={
                                                        mapImagesChecked[mapImage.imageId] || false
                                                    }
                                                    onChange={(e) =>
                                                        hanldeMapImageToggleClick(e, mapImage.imageId)
                                                    }
                                                    checked={mapImagesChecked[mapImage.imageId] || false}
                                                />
                                            }
                                            name={mapImage.mapUsage.replace(' ', '')}
                                            value={mapImage.mapUsage}
                                            label={mapImage.mapUsage}
                                        />
                                        <ImageUsageListItemActions
                                            className={clsx({
                                                'display-actions': selectedAction === mapImage.imageId,
                                            })}
                                        >
                                            <IconButton
                                                aria-label="download"
                                                component="a"
                                                href={mapImage.mapUrlGzip}
                                            >
                                                <DownloadIcon color="white" />
                                            </IconButton>

                                            <TooltipConfirmation
                                                onConfirm={() => handleDeleteMapImage(mapImage)}
                                                onOpenChange={(open) =>
                                                    setSelectedAction(open ? mapImage.imageId : '')
                                                }
                                                content="Delete Map Image"
                                            >
                                                <IconButton aria-label="delete">
                                                    <DeleteIcon color="white" />
                                                </IconButton>
                                            </TooltipConfirmation>
                                        </ImageUsageListItemActions>
                                    </ImageUsageListItem>
                                );
                            })}
                        </ImageUsageList>
                    );
                }}
            ></Controller>

            <Controller
                id={getMapPixelToMeterId() + 'l'}
                name={'mapArea'}
                control={control}
                render={({ value }) => (
                    <TextField
                        label={'Map Area'}
                        error={!!errors.mapArea}
                        helperText={errors.mapArea?.message}
                        value={value?.toFixed(2) || 0}
                        disabled
                    />
                )}
            />

            <Controller
                id={getMapPixelToMeterId()}
                name={'pixelToMeter'}
                control={control}
                render={({ onChange, value }) => (
                    <PixelToMeterTextField
                        customInput={TextField}
                        label={'Pixel To Meter Ratio'}
                        error={!!errors.pixelToMeter}
                        helperText={errors.pixelToMeter?.message}
                        value={value}
                        disabled={isSettingPixelToMeter}
                        onValueChange={({ floatValue }) => onChange(floatValue)}
                        allowNegative={false}
                        InputProps={{
                            endAdornment: (
                                <Tooltip title="Pixel 2 Meter Ratio Wizard">
                                    <IconButton onClick={() => dispatch(setIsSettingPixelToMeter(true))}>
                                        <BsFillPinFill size={20} />
                                    </IconButton>
                                </Tooltip>
                            ),
                        }}
                    />
                )}
            />

            <OffsetInputContainer>
                <Controller
                    id={`${getMapOffsetId()}-x`}
                    name={'mapOffsetX'}
                    control={control}
                    render={({ onChange, value }) => {
                        return (
                            <OffsetTextField
                                label={'Map Offset'}
                                error={!!errors.mapOffsetX}
                                helperText={errors.mapOffsetX?.message}
                                value={value?.toFixed(3)}
                                customInput={TextField}
                                disabled={isSettingMapOffset}
                                onValueChange={({ floatValue }) => onChange(floatValue)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">X:</InputAdornment>,
                                }}
                                style={{
                                    alignSelf: 'flex-end',
                                }}
                            />
                        );
                    }}
                />
                <Controller
                    id={`${getMapOffsetId()}-y`}
                    name={'mapOffsetY'}
                    control={control}
                    render={({ onChange, value }) => (
                        <OffsetTextField
                            error={!!errors.mapOffsetY}
                            helperText={errors.mapOffsetY?.message}
                            value={value?.toFixed(3)}
                            customInput={TextField}
                            disabled={isSettingMapOffset}
                            onValueChange={({ floatValue }) => onChange(floatValue)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Y:</InputAdornment>,
                                endAdornment: canEditBuildings && (
                                    <InputAdornment position="end">
                                        <Tooltip title="Setting Map Offset">
                                            <IconButton
                                                onClick={() => dispatch(setIsSettingMapOffset(true))}
                                                disabled={!referenceMap}
                                            >
                                                <BsFillPinFill size={20} />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                            style={{
                                alignSelf: 'flex-end',
                            }}
                        />
                    )}
                />
            </OffsetInputContainer>
            <ImageRotationContainer>
                <Controller
                    id={getMapComponenetImageRotationId()}
                    name={'mapComponentImageRotation'}
                    control={control}
                    render={({ onChange, value }) => {
                        return (
                            <ImageRotationTextField
                                label={'Image Rotation'}
                                error={!!errors.mapComponentImageRotation}
                                helperText={errors.mapComponentImageRotation?.message}
                                value={value}
                                customInput={TextField}
                                disabled={!mapComponentImageRotationEnabled}
                                isAllowed={(values) => {
                                    const { floatValue } = values;

                                    return !floatValue || floatValue <= 360;
                                }}
                                suffix={'°'}
                                allowNegative={false}
                                onValueChange={({ floatValue }) => onChange(floatValue)}
                            />
                        );
                    }}
                />
                <Checkbox
                    checked={mapComponentImageRotationEnabled}
                    onChange={(e) => {
                        setMapComponentImageRotationEnabled(e.target.checked);
                    }}
                />
            </ImageRotationContainer>
            {canEditBuildings && (
                <>
                    <SpacedButton variant={'outlined'} onClick={() => handleImageModeClick('replace')}>
                        <ReplaceIcon size={20} />
                        Replace Image
                    </SpacedButton>

                    <SpacedButton variant={'outlined'} onClick={() => handleImageModeClick('upload')}>
                        <UploadIcon size={20} />
                        Upload new Image
                    </SpacedButton>
                </>
            )}

            {isRegenerateMapDialogIsOpen && (
                <RegenerateMapDialog
                    mapId={mapId}
                    onRegenerate={onRegenerate}
                    onClose={() => setIsRegenerateMapDialogIsOpen(false)}
                />
            )}
            {isUploadNewImageDialogOpen && (
                <UploadNewMapImageDialog
                    buildingId={buildingId}
                    floorId={floorId}
                    mapId={map?.mapId}
                    onSubmit={imageMode === 'upload' ? handleNewMapImageSubmit : submitForm}
                    onClose={() => setIsUploadNewImageDialogOpen(false)}
                    mode={imageMode}
                />
            )}

            {canEditBuildings && isDirty && Object.keys(dirtyFields).length > 0 && (
                <ButtonsWrapper>
                    <Button variant={'text'} onClick={handleReset} disabled={isSubmitting}>
                        Reset
                    </Button>

                    {isSubmitting ? (
                        <CircularPreloader />
                    ) : (
                        <Button type={'submit'} disabled={!isValid}>
                            Save
                        </Button>
                    )}
                </ButtonsWrapper>
            )}
        </Form>
    );
}
