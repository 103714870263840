import React, { useContext, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import RectangularRegionPlacement from './RectangularRegionPlacement';
import CircularRegionPlacement from './CircularRegionPlacement';
import { useDispatch, useSelector } from 'react-redux';
import { selectCanReadRegions } from '../../../../../state-management/auth/authSelectors';
import { useMemo } from 'react';
import RegionExternalPlacement from '../google-maps/region-external-placements/RegionExternalPlacement';
import GoogleMapsRegionExternalPlacementCreation from '../google-maps/region-external-placement-creation';
import {
    selectMapContentLockRegionsInPlace,
    selectMapContentSelectedAnchorRegionId,
    selectMapContentSelectedBuildingId,
    selectMapContentSelectedFloorId,
    setSelectedAnchorRegionId,
} from '../../../../../state-management/user-inputs/mapContentSlice';
import { PolygonRegionPlacement } from './PolygonRegionPlacement';
import { MapViewerContext } from '../../MapViewerContext';
import { IMAGE_NATURAL_HEIGHT, OFFSET_X, OFFSET_Y, PIXEL_TO_METER, SCALE } from '../../../../../constants/mapViewerVariables';
import { showErrorNotification, showSuccessNotification } from '../../../../../state-management/notification/notificationReducer';
import { isFulfilled } from '../../../../../state-management/utils';
import { updateRegion } from '../../../../../state-management/region/regionActions';
import { convertToPixel, convertToMeterFromEvent, getMinAndMaxPoints } from './utils';

const OverlayWrapper = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none; // Prevent the whole SVG from capturing pointer events

    * {
        pointer-events: auto;
    }
`;

export const RegionPlacement = ({ placement, regionProps }) => {
    const {
        regionId,
        regionName,
        regionMetadata,
        regionUsePlai,
        isDraft = false,
        ...regionPlacement
    } = placement;

    const dispatch = useDispatch();

    const { imageRef, getCSSVariable } = useContext(MapViewerContext);

    const parsedRegionMetadata = JSON.parse(regionMetadata || '{}');
    const [anchorPoint, setAnchorPoint] = useState(() => {
        if (parsedRegionMetadata?.anchorPoint) {
            const { maxX, maxY, minX, minY } = getMinAndMaxPoints(placement, getCSSVariable);
            return {
                x: parsedRegionMetadata?.anchorPoint.x * (maxX - minX) + minX,
                y: parsedRegionMetadata?.anchorPoint.y * (maxY - minY) + minY,
                previousX: parsedRegionMetadata?.anchorPoint.x * (maxX - minX) + minX,
                previousY: parsedRegionMetadata?.anchorPoint.y * (maxY - minY) + minY,
            }

        }
        return null
    });

    const lockRegionsInPlace = useSelector(selectMapContentLockRegionsInPlace);

    const selectedAnchorRegionId = useSelector(selectMapContentSelectedAnchorRegionId);
    const isSelectedAnchorRegion = selectedAnchorRegionId === regionId;

    useEffect(() => {
        const parsedRegionMetadata = JSON.parse(regionMetadata || '{}');
        if (parsedRegionMetadata?.anchorPoint) {
            const { maxX, maxY, minX, minY } = getMinAndMaxPoints(placement, getCSSVariable);
            const x = parsedRegionMetadata?.anchorPoint.x * (maxX - minX) + minX;
            const y = parsedRegionMetadata?.anchorPoint.y * (maxY - minY) + minY
            setAnchorPoint({
                x,
                y,
                previousX: x,
                previousY: y,
                distanceFromCenterX: x - (minX + maxX) / 2,
                distanceFromCenterY: y - (minY + maxY) / 2,
            })

        } else {
            setAnchorPoint(null)
        }
    }, [regionMetadata])

    useEffect(() => {
        return () => {
            dispatch(setSelectedAnchorRegionId(''))
        }
    }, [])


    const onUpdateRegion = async (boundaries) => {
        if (anchorPoint) {
            const metadata = {
                ...parsedRegionMetadata,
                anchorPoint: {
                    x: (anchorPoint.x - boundaries.minX) / (boundaries.maxX - boundaries.minX),
                    y: (anchorPoint.y - boundaries.minY) / (boundaries.maxY - boundaries.minY)
                }
            }
            const updatedRegionResult = await dispatch(updateRegion({
                regionId,
                regionData: {
                    regionName, regionMetadata: JSON.stringify(metadata)
                },
            }));

            if (isFulfilled(updatedRegionResult)) {
                dispatch(showSuccessNotification(`Region updated successfully`));
                props.onCancel();

            } else {
                dispatch(showErrorNotification(`Failed to updated region`));
            }

        } else {
            props.onCancel();
        }
    }

    const handleCancel = (params) => {
        if (params && anchorPoint) { // This means that the cancel button was hit.
            if (parsedRegionMetadata?.anchorPoint) {
                const { maxX, maxY, minX, minY } = getMinAndMaxPoints(placement, getCSSVariable);
                setAnchorPoint(prevState => {
                    return ({
                        ...prevState, x: prevState.previousX, y: prevState.previousY,
                        distanceFromCenterX: prevState.previousX - (minX + maxX) / 2,
                        distanceFromCenterY: prevState.previousY - (minY + maxY) / 2,
                    });
                });
            } else {
                setAnchorPoint(null)
            }
        }

        dispatch(setSelectedAnchorRegionId(''));
        props.onCancel(params);
    }

    const props = {
        key: !isDraft ? regionPlacement?.placementId : regionPlacement?.placementId + Math.random(),
        region: { regionId, regionName, regionMetadata, regionColor: regionUsePlai ? 'pink' : '#ff6200' },
        placement: regionPlacement,
        setAnchorPoint,
        anchorPoint: anchorPoint ? convertToPixel(anchorPoint, getCSSVariable) : null,
        anchorData: {
            onEditAnchor: () => {
                if (isSelectedAnchorRegion) {
                    return dispatch(setSelectedAnchorRegionId(''));
                }
                return dispatch(setSelectedAnchorRegionId(regionId));
            },
            isSelectedAnchorRegion: isSelectedAnchorRegion,
        },
        parsedRegionMetadata: JSON.parse(regionMetadata || '{}'),
        onUpdateRegion,
        ...regionProps,
        disabled: !!selectedAnchorRegionId && !isSelectedAnchorRegion,
        lockRegionsInPlace

    };

    const onRegionClicked = (event, data) => {
        if (isSelectedAnchorRegion) {
            const refRect = imageRef.current.getBoundingClientRect();
            const { x, y } = convertToMeterFromEvent(event, getCSSVariable, refRect, true);
            let distanceFromCenterX = x - (data.maxX + data.minX) / 2;
            let distanceFromCenterY = y - (data.maxY + data.minY) / 2;
            return setAnchorPoint(prevState => ({ ...prevState, x, y, distanceFromCenterX, distanceFromCenterY }))
        }

        regionProps.onClick({ regionId, placementId: regionPlacement.placementId });
    }


    switch (regionPlacement.regionType) {
        case 'rectangular':
            return <RectangularRegionPlacement {...props} onRegionClicked={onRegionClicked} onCancel={handleCancel} />;
        case 'circular':
            return <CircularRegionPlacement {...props} onRegionClicked={onRegionClicked} onCancel={handleCancel} />;
        case 'polygon':
            return <PolygonRegionPlacement {...props} onRegionClicked={onRegionClicked} onCancel={handleCancel} />;
        default:
            return null;
    }


}

export default function MapViewerRegionsOverlay(props) {
    const {
        placements,
        isEditable = false,
        selectedPlacementIds = [],
        highlightedPlacementIds = [],
        onClick = (event, { regionId, placementId }) => { },
        onContextMenu = (event, { regionId, placementId }) => { },
        onPointerEnter = (event, { regionId, placementId }) => { },
        onPointerLeave = (event, { regionId, placementId }) => { },
        onCancel = (event, { regionId, placementId }) => { },
        onSendToBack = (event, { regionId, placementId }) => { },
        onDuplicate = (event, { regionId, placementId }) => { },
        isDuplicating = false,
    } = props;


    const buildingId = useSelector(selectMapContentSelectedBuildingId);
    const floorId = useSelector(selectMapContentSelectedFloorId);

    const canReadRegions = useSelector(selectCanReadRegions);

    const regionProps = {
        onClick,
        onContextMenu,
        onPointerEnter,
        onPointerLeave,
        onCancel,
        onSendToBack,
        onDuplicate,
        isEditable,
        isDuplicating,
    };

    if (!canReadRegions || !placements || placements?.length === 0) {
        return null;
    }

    return <OverlayWrapper>{placements.map(placement => {
        const isSelected = selectedPlacementIds.includes(placement?.placementId);
        const isHighlighted = highlightedPlacementIds.includes(placement?.placementId);
        return <RegionPlacement placement={placement} regionProps={{ ...regionProps, isSelected, isHighlighted }} />;
    })}</OverlayWrapper>;
}

MapViewerRegionsOverlay.propTypes = {
    placements: PropTypes.arrayOf(PropTypes.object).isRequired,
    isEditable: PropTypes.bool,
    selectedPlacementIds: PropTypes.arrayOf(PropTypes.string),
    highlightedPlacementIds: PropTypes.arrayOf(PropTypes.string),
    onClick: PropTypes.func,
    onPointerEnter: PropTypes.func,
    onPointerLeave: PropTypes.func,
    onCancel: PropTypes.func,
    onSendToBack: PropTypes.func,
    onDuplicate: PropTypes.func,
};
