import { createSlice } from '@reduxjs/toolkit';
import countriesAndTimezones from 'countries-and-timezones';
import { deleteBuilding } from '../building/buildingActions';
import { deleteFloor } from '../floor/floorActions';
import { setSelectedSpaceId } from './commonSlice';
import { deleteRegion } from '../region/regionActions';
import { wipeAllUserInputs } from './userInputsActions';

const timeZones = countriesAndTimezones.getAllTimezones();

const initialState = {
    filters: {
        selectedBuildingId: null,
        selectedFloorId: null,
        selectedTimePeriod: null,
        startTimestamp: null,
        endTimestamp: null,
        workingHoursOnly: true,
        timeZone: timeZones[Intl.DateTimeFormat().resolvedOptions().timeZone],
    },
    displaySettings: {
        selectedRegionIds: [],
        selectedTimeResolution: null,
    },
};

export const {
    actions: {
        setSelectedBuildingId,
        setSelectedFloorId,
        setSelectedTimePeriod,
        setTimestamps,
        setWorkingHoursOnly,
        setTimeZone,
        setSelectedRegionIds,
        setSelectedTimeResolution,
    },
    reducer,
} = createSlice({
    name: 'userInputs/analytics/buildingUsage',
    initialState,
    reducers: {
        setSelectedBuildingId: (state, action) => {
            state.filters.selectedBuildingId = action.payload;
            state.filters.selectedFloorId = null;
        },
        setSelectedFloorId: (state, action) => {
            state.filters.selectedFloorId = action.payload;
        },
        setSelectedTimePeriod: (state, action) => {
            state.filters.selectedTimePeriod = action.payload.timePeriod;
            state.filters.startTimestamp = action.payload.start;
            state.filters.endTimestamp = action.payload.end;
        },
        setTimestamps: (state, action) => {
            state.filters.startTimestamp = action.payload.start;
            state.filters.endTimestamp = action.payload.end;
        },
        setWorkingHoursOnly: (state, action) => {
            state.filters.workingHoursOnly = action.payload;
        },
        setTimeZone: (state, action) => {
            state.filters.timeZone = action.payload;
        },
        setSelectedRegionIds: (state, action) => {
            state.displaySettings.selectedRegionIds = action.payload;
        },
        setSelectedTimeResolution: (state, action) => {
            state.displaySettings.selectedTimeResolution = action.payload;
        },
    },
    extraReducers: {
        [setSelectedSpaceId]: (state) => {
            state.filters.selectedBuildingId = null;
            state.filters.selectedFloorId = null;
        },

        [deleteBuilding.fulfilled]: (state, action) => {
            if (action.meta.arg === state.selectedBuildingId) {
                state.filters.selectedBuildingId = action.payload;
                state.filters.selectedFloorId = null;
            }
        },

        [deleteFloor.fulfilled]: (state, action) => {
            if (action.meta.arg.floorId === state.selectedFloorId) {
                state.filters.selectedFloorId = null;
            }
        },

        [deleteRegion.fulfilled]: (state, action) => {
            if (state.displaySettings.selectedRegionIds.includes(action.meta.arg)) {
                state.displaySettings.selectedRegionIds = state.displaySettings.selectedRegionIds.filter(
                    (id) => id !== action.meta.arg
                );
            }
        },

        [wipeAllUserInputs]: () => initialState,
    },
});

export const selectAnalyticsBuildingUsageFiltersUserInputs = (state) =>
    state.userInputs.analytics.buildingUsage.filters;
export const selectAnalyticsBuildingUsageDisplaySettingsUserInputs = (state) =>
    state.userInputs.analytics.buildingUsage.displaySettings;
