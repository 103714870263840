import { useContext } from 'react';
import styled from 'styled-components';
import {
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
} from '../../../../../constants/mapViewerVariables';
import useValidateRouting from '../../../hooks/data-fetching/useValidateRouting';
import { MapViewerContext } from '../../MapViewerContext';

const Circle = styled.circle`
    stroke: red;
    stroke-width: 2px;
    fill: none;
`;

const Wrapper = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none; // Prevent the whole SVG from capturing pointer events

    * {
        pointer-events: auto;
    }
`;

export const NavgraphErrorsViewer = (props) => {
    const { errors = [] } = props;

    const { getCSSVariable } = useContext(MapViewerContext);
    if (errors.length === 0) return null;
    const pixelToMeter = getCSSVariable(PIXEL_TO_METER);
    const imageNaturalHeight = getCSSVariable(IMAGE_NATURAL_HEIGHT);
    const mapOffsetX = getCSSVariable(OFFSET_X);
    const mapOffsetY = getCSSVariable(OFFSET_Y);
    const radius = 25;
    return (
        <Wrapper id="navgraph-errors">
            {errors.map((p, i) => (
                <Circle
                    key={i * p.x}
                    cx={(p.x - mapOffsetX) * pixelToMeter}
                    cy={(imageNaturalHeight / pixelToMeter - (p.y - mapOffsetY)) * pixelToMeter}
                    r={radius}
                ></Circle>
            ))}
        </Wrapper>
    );
};
