import { createAction } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';
import { createAsyncThunk } from '../utils';
import authApi from '../../api/AuthApi';
import { selectLastLoggedUserApiKeyId } from './authSelectors';
import { wipeAllUserInputs } from '../user-inputs/userInputsActions';
import { permissionNames } from '../../constants/permissions';
import LogRocket from 'logrocket';
import tokenManager from '../../utils/tokenManager';
import httpClient from '../../utils/httpClient';

export const login = createAsyncThunk(
    'auth/login',
    async ({ username, password }, { dispatch, getState, rejectWithValue }) => {
        const { data: response } = await authApi.loginUsingCredentials(username, password);

        const { accessToken, accessTokenExpiry } = response;
        const {
            loginType,
            apiKeyId: userApiKeyId,
            apiKeyDesc,
            userId,
            userName,
            permissions,
            needsToAcceptLatestTermsAndConditions,
            isSuspended,
            envSuffix,
        } = jwt.decode(accessToken);

        if (isSuspended) {
            return rejectWithValue(
                'This API key is suspended. If you wish to re-activate it, please contact support@oriient.me'
            );
        } else if (!permissions.includes(permissionNames.ACCESS_DASHBOARD)) {
            return rejectWithValue('This API key lacks the necessary permissions to access the dashboard.');
        }

        const lastLoggedUserApiKeyId = selectLastLoggedUserApiKeyId(getState());

        if (lastLoggedUserApiKeyId !== userApiKeyId) {
            await dispatch(wipeAllUserInputs());
        }

        LogRocket.identify(response.apiKeyDesc, {
            apiKeyId: userApiKeyId,
            apiKeyName: apiKeyDesc,
        });

        tokenManager.handleNewToken(accessTokenExpiry);

        httpClient.addAccessToken(accessToken);

        return {
            loginType,
            apiKeyId: userApiKeyId,
            apiKeyDesc,
            userId,
            userName,
            permissions,
            spaceIds: response.spaces,
            needsToAcceptLatestTermsAndConditions,
            isSuspended,
            envSuffix,
            accessToken,
            accessTokenExpiry,
        };
    }
);

export const attemptLoginSessionRehydration = createAsyncThunk(
    'auth/attemptLoginSessionRehydration',
    async (token) => {
        try {
            const { accessToken, accessTokenExpiry } = (await authApi.renewAccessToken(token)).data;
            const {
                loginType,
                apiKeyId,
                apiKeyDesc,
                userId,
                userName,
                permissions,
                spaceIds,
                needsToAcceptLatestTermsAndConditions,
                isSuspended,
                envSuffix,
            } = jwt.decode(accessToken);

            tokenManager.handleNewToken(accessTokenExpiry);

            return {
                loginType,
                apiKeyId,
                apiKeyDesc,
                userId,
                userName,
                permissions,
                spaceIds,
                needsToAcceptLatestTermsAndConditions,
                isSuspended,
                envSuffix,
                accessToken,
                accessTokenExpiry,
                isLoginSuccessful: true,
            };
        } catch (e) {
            return { isLoginSuccessful: false };
        }
    }
);

export const renewAccessToken = createAsyncThunk('auth/renewAccessToken', async () => {
    return (await authApi.renewAccessToken()).data;
});

export const acceptTermsAndConditions = createAction('auth/acceptTermsAndConditions');

export const extendSession = createAction('auth/extendSession');

export const expireSession = createAsyncThunk('auth/expireSession', async () => {
    tokenManager.clear();
    return (await authApi.logout()).data;
});

export const logout = createAsyncThunk('auth/logout', async () => {
    tokenManager.clear();
    return (await authApi.logout()).data;
});
