import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import eventsManagerWs from '../../ws/events-manager/eventsManagerWs';
import { getMapperHelpRequestAcknowledgedTopic } from '../../constants/eventTopics';
import { selectMapperHelpRequest } from '../../state-management/mapping/mapper/mapperSelectors';
import { Howl } from 'howler';
import notificationSoundMp3 from '../../assets/notificationSound.mp3';
import { Button, Snackbar, SnackbarContent } from '@material-ui/core';
import { mapperHelpRequestAcknowledged } from '../../state-management/mapping/mappingEventsActions';
import { activeHelpRequestAcknowledgeCreated } from '../../state-management/shift-management/shiftManagementActions';

let NotificationTitleInterval;
let notificationSoundTimeout;
let notificationSound;

let notificationSoundCounter = 0;
const notificationSoundMaxInLoop = 3;
const notificationSoundPauseTime = 60000;

const defaultTitle = 'Oriient Dashboard';
const helpTitle = 'Help requested from a mapper!';

notificationSound = new Howl({
    src: notificationSoundMp3,
    preload: true,
    loop: true,
});

export default function MapperHelpRequestNotification() {
    const dispatch = useDispatch();

    const { isActive, mapId, mapperId, mapperName } = useSelector(selectMapperHelpRequest);

    useEffect(() => {
        if (isActive) {
            notificationSound.on('end', () => {
                notificationSoundCounter++;
                if (notificationSoundCounter === notificationSoundMaxInLoop) {
                    // After several plays, pause
                    notificationSound.pause();
                    notificationSoundCounter = 0;

                    // After some pause time, play again
                    notificationSoundTimeout = setTimeout(
                        () => notificationSound.play(),
                        notificationSoundPauseTime
                    );
                }
            });

            notificationSound.play();

            return () => {
                clearTimeout(notificationSoundTimeout);
                notificationSound.stop();
            };
        }
    }, [isActive]);

    useEffect(() => {
        if (isActive) {
            // Make the tab title "flash" every second with a different text
            NotificationTitleInterval = setInterval(() => {
                document.title = document.title === defaultTitle ? helpTitle : defaultTitle;
            }, 1000);

            return () => {
                clearInterval(NotificationTitleInterval);
                document.title = defaultTitle;
            };
        }
    }, [isActive]);

    const handleClose = async (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }

        await eventsManagerWs.publish(
            getMapperHelpRequestAcknowledgedTopic(mapId, mapperId),
            { mapId, mapperId },
            { allowOwnMessage: true },
            true
        );

        dispatch(mapperHelpRequestAcknowledged());
        dispatch(activeHelpRequestAcknowledgeCreated(mapId, mapperId));
    };

    return (
        <Snackbar open={isActive} autoHideDuration={null} onClose={handleClose}>
            <SnackbarContent
                message={`${mapperName} has requested assistance`}
                action={
                    <Button color="secondary" size="small" onClick={handleClose}>
                        ACKNOWLEDGE
                    </Button>
                }
            />
        </Snackbar>
    );
}
