export const getSignMarkEditorSignMarkId = () => `sign-mark-editor-sign-mark-id`;
export const getSignMarkEditorTextId = () => `sign-mark-editor-text`;
export const getRegionEditorPlacementShapeRectangularId = () => `region-editor-placement-shape-rectangular`;
export const getRegionEditorPlacementShapeCircularId = () => `region-editor-placement-shape-circular`;
export const getRegionEditorAttachedTriggerId = (triggerId) => `region-editor-attached-trigger-${triggerId}`;
export const getRegionEditorAttachedTriggerNameId = (triggerId) =>
    `region-editor-attached-trigger-name-${triggerId}`;
export const getRegionEditorDetachTriggerId = (triggerId) => `region-editor-detach-trigger-${triggerId}`;
export const getRegionEditorUseInAnalytics = () => `region-editor-use-in-analytics`;
export const getRegionEditorUseInPLAI = () => `region-editor-use-in-plai`;
