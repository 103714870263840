import React from 'react';
import SideDrawer from '../common/side-drawer';
import MonitorSideMenuBuildingSection from './side-menu/buildings';
import MonitorSideMenuFloorSection from './side-menu/floors';
import MonitorSideMenuMapSection from './side-menu/maps';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectMonitorIsSideDrawerOpen,
    setIsSideDrawerOpen,
} from '../../state-management/user-inputs/monitorSlice';
import MonitorSideMenuMonitorSettingsSection from './side-menu/monitor-settings';
import { getSideMenuDrawerId } from './Monitor.selectors';
import MonitorSideMenuClientsSection from './side-menu/clients';
import PreferencesSideMenuSection from './side-menu/preferences';
export default function MonitorSideMenu() {
    const dispatch = useDispatch();

    const isSideDrawerOpen = useSelector(selectMonitorIsSideDrawerOpen);

    return (
        <SideDrawer
            id={getSideMenuDrawerId()}
            isOpen={isSideDrawerOpen}
            onToggle={() => dispatch(setIsSideDrawerOpen(!isSideDrawerOpen))}
        >
            <MonitorSideMenuBuildingSection />
            <MonitorSideMenuFloorSection />
            <MonitorSideMenuMapSection />
            <MonitorSideMenuClientsSection />
            <MonitorSideMenuMonitorSettingsSection />
            <PreferencesSideMenuSection />
        </SideDrawer>
    );
}
