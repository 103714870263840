import React from 'react';
import SideMenuSection from '../../../common/side-drawer/SideMenuSection';
import { Skeleton, Switch, SwitchLabel as Label } from '../../../common/themed';
import {
    selectBuildingsAreEntrancesShown,
    selectBuildingsSelectedBuildingId,
    selectBuildingsSelectedFloorId,
    setAreEntrancesShown,
} from '../../../../state-management/user-inputs/buildingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import useEntrances from '../../../common/hooks/data-fetching/useEntrances';
import FetchError from '../../../common/error-pages/FetchError';
import NoItemsMessage from '../../../common/side-drawer/NoItemsMessage';
import EntranceListEntry from './EntranceListEntry';

const EntranceList = styled.ul`
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-block-start: 5px;
    padding: 0;
`;

export default function BuildingsSideMenuEntrancesSection() {
    const dispatch = useDispatch();

    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const selectedFloorId = useSelector(selectBuildingsSelectedFloorId);
    const areEntrancesShown = useSelector(selectBuildingsAreEntrancesShown);

    const {
        data: entrances,
        isLoading,
        hasError,
    } = useEntrances({
        buildingId: selectedBuildingId,
        floorId: selectedFloorId,
    });

    if (!entrances && !isLoading) {
        return null;
    }

    return (
        <>
            <SideMenuSection title={'Entrances (in floor)'}>
                {hasError ? (
                    <FetchError />
                ) : isLoading ? (
                    <Skeleton height={50} width={230} />
                ) : entrances.length === 0 ? (
                    <NoItemsMessage>There are no entrances in this floor</NoItemsMessage>
                ) : (
                    <>
                        <FormControlLabel
                            label={<Label>Show entrances</Label>}
                            disabled={isLoading}
                            control={
                                <Switch
                                    checked={areEntrancesShown}
                                    onChange={() => dispatch(setAreEntrancesShown(!areEntrancesShown))}
                                />
                            }
                        />

                        <EntranceList>
                            {entrances.map((entrance) => (
                                <EntranceListEntry key={entrance?.entranceId} entrance={entrance} />
                            ))}
                        </EntranceList>
                    </>
                )}
            </SideMenuSection>
        </>
    );
}
