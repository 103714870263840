import { CustomCellComponent } from "../common/themed/CustomCellComponent";

const defaultCellStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
};

export default [
    {
        title: 'Map ID',
        description: 'Map ID',
        'Csv header': '',
        isExternal: true,
        field: 'mapId',
        type: 'string',
        align: 'left',
        format: 'uuid',
        placeholder: 'UUID',
        index: 0,
        cellStyle: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        render: ({ mapId }) =>
            CustomCellComponent({
                text: mapId,
                textStyle: { ...defaultCellStyle, width: '50px' },
                copyIcon: true,
            }),
    },
    {
        title: 'Building ID',
        description: 'Building ID',
        'Csv header': '',
        isExternal: true,
        field: 'buildingId',
        format: 'uuid',
        placeholder: 'UUID',
        type: 'string',
        align: 'left',
        index: 1,
        cellStyle: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        render: ({ buildingId }) =>
            CustomCellComponent({
                text: buildingId,
                textStyle: { ...defaultCellStyle, width: '50px' },
                copyIcon: true,
            }),
    },
    {
        title: 'Building Name',
        description: 'Building name',
        'Csv header': '',
        isExternal: true,
        field: 'buildingName',
        type: 'string',
        align: 'left',
        placeholder: 'Free text',
        index: 2,
    },
    {
        title: 'Activity Duration',
        description: 'Number of weeks from first to last scan',
        'Csv header': 'weeks from first to last scan',
        isExternal: true,
        field: 'activityDuration',
        type: 'numeric',
        placeholder: '0.00',
        align: 'left',
        index: 3,
    },
    {
        title: 'Last Activity',
        description: 'Date of last scan',
        'Csv header': 'last scan date date',
        isExternal: true,
        field: 'lastActivity',
        type: 'date',
        index: 4,
    },
    {
        title: 'General Score',
        description: '% of scans with accuracy below 4m',
        'Csv header': '% of scans with u < 4 -or- % of scans with u < 4.00',
        isExternal: false,
        field: 'generalScore',
        type: 'numeric',
        placeholder: '0.00',
        align: 'left',
        index: 5,
    },
    {
        title: 'Tracking Score',
        description:
            '% of scans with accuracy below 4m after good lock (3 consecutive scans below 2m accuracy)',
        'Csv header': '(currently missing from table, only appears inside the graph as the average)',
        isExternal: false,
        field: 'trackingScore',
        type: 'numeric',
        placeholder: '0.00',
        align: 'left',
        index: 6,
    },
    {
        title: 'Scans',
        description: 'Total number of scans',
        'Csv header': 'number of scans -or- number of scanned positions',
        isExternal: true,
        field: 'scans',
        type: 'numeric',
        placeholder: '0',
        align: 'left',
        index: 7,
    },
    {
        title: 'Products Scanned',
        description: 'Total number of products scanned',
        'Csv header': 'number of products scanned',
        isExternal: true,
        field: 'productsScanned',
        type: 'numeric',
        placeholder: '0',
        align: 'left',
        index: 8,
    },
    {
        title: 'Products Learned',
        description: 'Total number of products learned',
        'Csv header': 'num of products learned',
        isExternal: true,
        field: 'productsLearned',
        type: 'numeric',
        placeholder: '0',
        align: 'left',
        index: 9,
    },
    {
        title: 'Products Utilization',
        description: '% of products learned from scanned',
        'Csv header': 'learned products utilization',
        isExternal: true,
        field: 'productsUtilization',
        placeholder: '0.00',
        type: 'numeric',
        align: 'left',
        index: 10,
    },
    {
        title: 'Products Pending',
        description: 'Total number of products scanned but not yet learned',
        'Csv header': 'num of pending products (disappeared from internal, still shown on external)',
        isExternal: true,
        field: 'productsPending',
        type: 'numeric',
        placeholder: '0',
        align: 'left',
        index: 11,
    },
    {
        title: 'learned by 1 scan',
        description: '% of products learned from scanned using 1 scan only',
        'Csv header': 'one scan learned percentage',
        isExternal: false,
        field: 'learnedBySingleScan',
        placeholder: '0.00',
        type: 'numeric',
        align: 'left',
        index: 12,
    },
    {
        title: 'Learned by multi scans',
        description: '% of products learned from scanned using mutiple scans',
        'Csv header': 'multi-scan learned percentage',
        isExternal: false,
        field: 'learnedByMultiScan',
        placeholder: '0.00',
        type: 'numeric',
        align: 'left',
        index: 13,
    },
    {
        title: 'Onescan Accuracy',
        description: '% of Onescan distance to GT that is below 3m',
        'Csv header': 'onescan <= 3m %',
        isExternal: false,
        field: 'oneScanAccuracy',
        placeholder: '0.00',
        type: 'numeric',
        align: 'left',
        index: 14,
    },
    {
        title: 'Twoscan Accuracy',
        description: '% of Twoscan distance to GT that is below 3m',
        'Csv header': 'twoscan <= 3m %',
        isExternal: false,
        field: 'twoScanAccuracy',
        placeholder: '0.00',
        type: 'numeric',
        align: 'left',
        index: 15,
    },
    {
        title: 'GT Size',
        description: 'Total number of products with at least 10 scans in cluster',
        'Csv header': '# products with at least 10 scans in cluster',
        isExternal: false,
        field: 'groundTruthSize',
        placeholder: '0',
        type: 'numeric',
        align: 'left',
        index: 16,
    },
    {
        title: 'Categories Scanned',
        description: '',
        'Csv header': 'num categories scanned',
        isExternal: true,
        field: 'categoriesScanned',
        placeholder: '0',
        type: 'numeric',
        align: 'left',
        index: 17,
    },
    {
        title: 'Categories Learned',
        description: '',
        'Csv header': 'num categories learned',
        isExternal: true,
        field: 'categoriesLearned',
        placeholder: '0',
        type: 'numeric',
        align: 'left',
        index: 18,
    },
    {
        title: 'Cat in appropriate size',
        description: 'Total number of categories learned with appropriate size for use',
        'Csv header': 'num categories learned with appropriate size for use',
        isExternal: true,
        field: 'categoriesInAppropriateSize',
        type: 'numeric',
        placeholder: '0',
        align: 'left',
        index: 19,
    },
    {
        title: 'Cat in appropriate size util',
        description: '% of categories in appropriate size from learned',
        'Csv header': 'appropriate size categories utilization',
        isExternal: true,
        field: 'categoriesInAppropriateSizeUtilization',
        type: 'numeric',
        placeholder: '0.00',
        align: 'left',
        index: 20,
    },
    {
        title: 'Categories Pending',
        description: 'Total number of categories scanned but not yet learned',
        'Csv header': 'num of pending categories',
        isExternal: true,
        field: 'categoriesPending',
        type: 'numeric',
        placeholder: '0',
        align: 'left',
        index: 21,
    },
    {
        title: 'Categories Confidence',
        description: 'Estimated hit rate of a product in a category',
        'Csv header': 'hit rate',
        isExternal: false,
        field: 'categoriesConfidence',
        placeholder: '0.00',
        type: 'numeric',
        align: 'left',
        index: 22,
    },
];
