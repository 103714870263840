import axios from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import MapQualityApi from '../../../../api/MapQualityApi';
import { usePublishMapGrid } from '../../../mapping/hooks/usePublishMapGrid';
import { defaultToggleStates, normalizedFetchedData } from '../../../modals/InvestigationModal/utils';

export const useMapInvestigation = ({ buildingId, mapId, fetchMapQuality }) => {
    const [showZoomImage, setShowZoomImage] = useState(null);
    const cancelToken = useRef(null);
    const [toggles, setToggles] = useState(defaultToggleStates);
    const [selectedDay, setSelectedDay] = useState(0);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [isImageLoading, setImageLoading] = useState(false);
    const [hasImageError, setHasImageError] = useState(null);

    const { createPublishGrid, publishLoading, publishSubmitted } = usePublishMapGrid(fetchMapQuality);

    const fetchData = async () => {
        try {
            const { data: investigationData = [] } = await MapQualityApi.fetchInvestigationData({
                buildingId,
                mapId,
            });
            const normalizedData = normalizedFetchedData(investigationData);
            setSelectedDay(normalizedData.length);
            setData(normalizedData);
        } catch (err) {
            setHasError(true);
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    const fetchImage = async (fileName) => {
        const source = axios.CancelToken.source();
        cancelToken.current = source;
        const createdOn = data[selectedDay - 1].date;
        const { data: signedUrl } = await MapQualityApi.fetchInvestigationImageUrl({
            buildingId,
            mapId,
            createdOn,
            fileName,
            source,
        });
        return signedUrl;
    };

    useEffect(() => {
        fetchData();
    }, []);

    const closeZoomModal = () => {
        setShowZoomImage(false);
        if (cancelToken.current) {
            cancelToken.current.cancel();
            cancelToken.current = null;
        }
    };

    const openZoomModal = async (fileName) => {
        setShowZoomImage(true);
        try {
            setImageLoading(true);
            const signedUrl = await fetchImage(fileName);
            setImageUrl(signedUrl);
        } catch (err) {
            setHasImageError(true);
            throw err;
        } finally {
            setImageLoading(false);
        }
    };

    const getDownloadUrl = async (fileName) => {
        const signedUrl = await fetchImage(fileName);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = signedUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
    };

    const checkIfShouldDisabledToggle = () => {
        const togglesArray = Object.values(toggles);
        const unCheckedToggles = togglesArray.filter((value) => !value);
        const shouldDisableToggle = unCheckedToggles.length === togglesArray.length - 1;
        return shouldDisableToggle;
    };

    const shouldDisableToggle = useMemo(() => checkIfShouldDisabledToggle(), [toggles]);

    const onToggleChanged = (event) => {
        const { name } = event.target;
        const nextValue = !toggles[name];
        setToggles((prevToggles) => ({ ...prevToggles, [name]: nextValue }));
    };

    const onSliderChanged = (_, newDay) => {
        if (newDay !== selectedDay) setSelectedDay(newDay);
    };

    return {
        toggles,
        data,
        isLoading,
        hasError,
        selectedDay,
        onToggleChanged,
        onSliderChanged,
        shouldDisableToggle,
        openZoomModal,
        closeZoomModal,
        showZoomImage,
        fetchImage,
        imageUrl,
        isImageLoading,
        hasImageError,
        getDownloadUrl,
        createPublishGrid,
        publishSubmitted,
        publishLoading,
    };
};
