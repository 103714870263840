import React, { useContext, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Card as MuiCard, Portal, Typography } from '@material-ui/core';
import { MapViewerContext } from '../../../MapViewerContext';
import MuiSlider from '@material-ui/core/Slider';
import { GMAPS_MAP_OPACITY } from '../../../../../../constants/mapViewerVariables';
import {
    getGoogleMapsOpacitySliderId,
    getGoogleMapsOpacityTitleId,
} from './GoogleMapsMapImageOpacityOverlay.selectors';
import styled from 'styled-components';

const Slider = styled(MuiSlider)`
    width: 300px;
    margin-block-start: 8px;
`;

const Card = styled(MuiCard)`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 12px 12px 18px 12px;
`;

export default function GoogleMapsMapImageOpacityOverlay(props) {
    const { defaultValue = 0.5 } = props;

    const { containerRef, setCSSVariable } = useContext(MapViewerContext);

    const [value, setValue] = useState(defaultValue);

    const handleChange = (e, newValue) => {
        const rounded = Number((Math.round(newValue / 0.01) * 0.01).toFixed(2));
        setCSSVariable(GMAPS_MAP_OPACITY, rounded);
        setValue(rounded);
    };

    return (
        <Portal container={containerRef.current}>
            <Card>
                <Typography id={getGoogleMapsOpacityTitleId()}>
                    Opacity: {(value * 100).toFixed(0)}%
                </Typography>

                <Slider
                    id={getGoogleMapsOpacitySliderId()}
                    defaultValue={defaultValue}
                    value={value}
                    onChange={handleChange}
                    min={0}
                    max={1}
                    step={0.01}
                />
            </Card>
        </Portal>
    );
}

GoogleMapsMapImageOpacityOverlay.propTypes = {
    defaultValue: PropTypes.number,
};
