import { createAsyncThunk } from '../utils';
import apiKeysApi from '../../api/ApiKeysApi';

export const fetchAllApiKeysInAttachedSpaces = createAsyncThunk(
    'apiKeys/fetchAllInAttachedSpaces',
    async () => {
        return (await apiKeysApi.fetchAllApiKeysInAttachedSpaces()).data;
    }
);

export const createApiKey = createAsyncThunk('apiKeys/create', async (apiKeyData) => {
    return (await apiKeysApi.createApiKey(apiKeyData)).data;
});

export const updateApiKey = createAsyncThunk('apiKeys/update', async ({ apiKeyId, apiKeyData }) => {
    return (await apiKeysApi.updateApiKey(apiKeyId, apiKeyData)).data;
});

export const deleteApiKey = createAsyncThunk('apiKeys/delete', async (apiKeyId) => {
    return (await apiKeysApi.deleteApiKey(apiKeyId)).data;
});

export const fetchTermsDataForAllApiKeys = createAsyncThunk(
    'apiKeys/fetchTermsAndConditionsDataForAll',
    async (apiKeyId) => {
        return (await apiKeysApi.fetchTermsDataForAllApiKeys(apiKeyId)).data;
    }
);
export const excludeApiKeyFromTermsAcceptance = createAsyncThunk(
    'apiKeys/excludeFromTermsAcceptance',
    async (apiKeyId) => {
        return (await apiKeysApi.excludeApiKeyFromTermsAcceptance(apiKeyId)).data;
    }
);
export const unexcludeApiKeyFromTermsAcceptance = createAsyncThunk(
    'apiKeys/unexcludeFromTermsAcceptance',
    async (apiKeyId) => {
        return (await apiKeysApi.unexcludeApiKeyFromTermsAcceptance(apiKeyId)).data;
    }
);
export const suspendApiKey = createAsyncThunk('apiKeys/suspend', async (apiKeyId) => {
    return (await apiKeysApi.suspendApiKey(apiKeyId)).data;
});
export const unsuspendApiKey = createAsyncThunk('apiKeys/unsuspend', async (apiKeyId) => {
    return (await apiKeysApi.unsuspendApiKey(apiKeyId)).data;
});
