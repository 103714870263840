import React, { forwardRef, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { MapViewerContext } from '../../MapViewerContext';
import { Portal, Snackbar as MuiSnackbar, SnackbarContent, Typography } from '@material-ui/core';
import { Button, TextField, CircularPreloader } from '../../../themed';
import { useDispatch, useSelector } from 'react-redux';
import { createAreaOfInterest } from '../../../../../state-management/mapping/area-of-interest/areaOfInterestActions';
import { createElement } from '../../../../../state-management/mapping/elements/elementsActions';
import {
    IMAGE_NATURAL_HEIGHT,
    IMAGE_NATURAL_WIDTH,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../../state-management/notification/notificationReducer';
import { isFulfilled } from '../../../../../state-management/utils';
import { selectUserName } from '../../../../../state-management/auth/authSelectors';
import { upsertExitRegion } from '../../../../../state-management/mapping/exitRegion/exitRegionsActions';
import { selectBuildingsSelectedBuildingId, selectBuildingsSelectedFloorId } from '../../../../../state-management/user-inputs/buildingsSlice';
import { getMapViewerSignMarkCreationOverlayId, getSignMarkCreationInstructionSnackbarId } from './mapViewerSignMarkCreation';
import { convertToPixel } from '../regions/utils';
import { createSignMark } from '../../../../../state-management/mapping/sign-mark/signMarkActions';

const CreationSpace = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
`;

const ElementTitle = styled.text`
    text-anchor: middle;
    dominant-baseline: middle;
    pointer-events: none !important;
    fill: white;
    font-size: ${() => `calc(12px / var(${SCALE}))`};
`;

const CircleDot = styled.circle.attrs({
    style: {
        r: `calc(12 / var(${SCALE}))`
    }
})`
    fill: #9c6e1a;
    color: white;
    /* &:hover {
        fill: #e28406;
        r: 10;
        cursor: move;
    } */
`;
const Snackbar = styled(MuiSnackbar)`
    position: absolute;
`;

const Instruction = styled(Typography)`
    color: #ffffff; //TODO color should come from theme
`;

const SnackbarTextField = styled(TextField)`
    &&& .themed-underline {
        ::before {
            border-bottom-color: white; // TODO color should come from theme
        }
        :hover::before {
            border-bottom-color: white; // TODO color should come from theme
        }
    }

    & .themed-input-label {
        color: white; // TODO color should come from theme
    }

    input {
        color: white;
    }
`;

const SnackbarButton = styled(Button)`
    color: white;
`;

const INITIAL = 'INITIAL';
const ADD_SIGN_MARK = 'ADD_SIGN_MARK';
const MOVE_TO_SUBMIT = 'MOVE_TO_SUBMIT';


export default function MapViewerSignMarkCreationOverlay(props) {
    const { mapId, onSubmitted, onCancel } = props;

    const type = props.type || 'normal';
    const mapperName = useSelector(selectUserName)

    const { imageRef, containerRef, getCSSVariable } = useContext(MapViewerContext);
    const buildingId = useSelector(selectBuildingsSelectedBuildingId);
    const floorId = useSelector(selectBuildingsSelectedFloorId);
    const dispatch = useDispatch();
    const textRef = useRef(null);

    const [isCreating, setIsCreating] = useState(false);
    const [{ creationStep, point, text }, setCreation] = useImmer({
        creationStep: INITIAL,
        text: '',
        point: null
    });

    useEffect(() => {
        if (textRef.current && creationStep === ADD_SIGN_MARK) {
            textRef.current.focus();
        }
    }, [creationStep, textRef]);

    const handleTitleChange = (event) => {
        const { value } = event.target;
        setCreation((state) => {
            state.text = value?.trim() === '' ? null : value;
        });
    };

    const handleClick = (event) => {
        if (creationStep === ADD_SIGN_MARK) return;
        const { clientX, clientY } = event;
        const refRect = imageRef.current.getBoundingClientRect();
        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const scale = parseFloat(getCSSVariable(SCALE));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        setCreation((state) => {
            state.creationStep = ADD_SIGN_MARK;
            state.point = {
                x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
            };
        })
    };

    const handleCancel = (event) => {
        event.stopPropagation();
        setCreation((state) => {
            state.creationStep = INITIAL;
        })
        onCancel();
    };

    const handleSubmit = async () => {
        setIsCreating(true);
        const result = await dispatch(createSignMark({ buildingId, floorId, mapId, createdSignMark: { coordinates: point, text } }));
        setIsCreating(false);

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Sign mark created successfully'));
        } else {
            dispatch(showErrorNotification(`Failed to create sign mark`));
        }

        onSubmitted();
    };


    const { cx, cy } = useMemo(() => {
        if (!point) {
            return {}
        }
        const { x: cx, y: cy } = convertToPixel(point, getCSSVariable);
        return { cx, cy }
    }, [point])

    const instructions = {
        [INITIAL]: <Instruction>Click anywhere on the map to set sign mark location</Instruction>,
        [ADD_SIGN_MARK]: (
            <>
                <Instruction>Provide sign mark text:</Instruction>
                <SnackbarTextField inputRef={textRef} onChange={handleTitleChange} value={text || ''} />
            </>
        ),
    };

    return (
        <CreationSpace id={getMapViewerSignMarkCreationOverlayId()} onClick={handleClick}>
            <Portal container={containerRef.current}>
                <Snackbar
                    id={getSignMarkCreationInstructionSnackbarId()}
                    open
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <SnackbarContent
                        message={instructions[creationStep]}
                        action={
                            <>
                                <SnackbarButton variant={'text'} onClick={handleCancel}>
                                    Cancel
                                </SnackbarButton>
                                {creationStep === ADD_SIGN_MARK &&
                                    (isCreating ? (
                                        <CircularPreloader />
                                    ) : (
                                        <>
                                            <SnackbarButton disabled={!text} variant={'text'} onClick={handleSubmit}>
                                                Save
                                            </SnackbarButton>

                                        </>

                                    ))}
                            </>
                        }
                    />
                </Snackbar>
            </Portal>

            {!!point && <CircleDot
                cx={cx}
                cy={cy}
            />}
            {text && <ElementTitle x={cx} y={cy}>
                {text}
            </ElementTitle>}
        </CreationSpace>
    );
}

// MapViewerAreaOfInterestCreationOverlay.propTypes = {
//     mapId: PropTypes.string.isRequired,
//     onSubmitted: PropTypes.func.isRequired,
//     onCancel: PropTypes.func.isRequired,
//     type: PropTypes.oneOf(['normal', 'element', 'exitRegion']),
// };
