import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import countriesAndTimezones from 'countries-and-timezones';
import { deleteBuilding } from '../building/buildingActions';
import { deleteFloor } from '../floor/floorActions';
import { deleteMap } from '../map/mapActions';
import { setSelectedSpaceId } from './commonSlice';
import { wipeAllUserInputs } from './userInputsActions';

const timeZones = countriesAndTimezones.getAllTimezones();

const initialState = {
    selectedBuildingId: null,
    selectedFloorId: null,
    selectedMapIds: [],
    acceptedAccuracy: 12,
    timeSpanStart: moment().startOf('day').toISOString(), // Today midnight
    timeSpanEnd: moment().add(1, 'day').endOf('day').toISOString(), // Tomorrow midnight
    granularity: 1000,
    timeZone: timeZones[Intl.DateTimeFormat().resolvedOptions().timeZone],
    sessionId: '',
    inaccuracySmoothing: true,
    fetchOnSubmit: true,
};

export const {
    actions: {
        setSelectedBuildingId,
        setSelectedFloorId,
        setSelectedMapIds,
        setAcceptedAccuracy,
        setTimeSpanStart,
        setTimeSpanEnd,
        setTimeZone,
        setSessionId,
        setInaccuracySmoothing,
    },
    reducer,
} = createSlice({
    name: 'userInputs/analytics/userActivity',
    initialState,
    reducers: {
        setSelectedBuildingId: (state, action) => {
            state.selectedBuildingId = action.payload;
            state.selectedFloorId = null;
            state.selectedMapIds = [];
        },
        setSelectedFloorId: (state, action) => {
            state.selectedFloorId = action.payload;
            state.selectedMapIds = [];
        },
        setSelectedMapIds: (state, action) => {
            state.selectedMapIds = action.payload;
            state.fetchOnSubmit = true;
        },
        setTimeSpanStart: (state, action) => {
            state.timeSpanStart = action.payload;
            state.fetchOnSubmit = true;

            if (moment(state.timeSpanStart).isAfter(moment(state.timeSpanEnd))) {
                state.timeSpanEnd = action.payload;
            }
        },
        setTimeSpanEnd: (state, action) => {
            state.timeSpanEnd = action.payload;
            state.fetchOnSubmit = true;
        },
        setTimeZone: (state, action) => {
            state.timeZone = action.payload;
            state.fetchOnSubmit = true;
        },
        setSessionId: (state, action) => {
            state.sessionId = action.payload;
            state.fetchOnSubmit = true;
        },
        setInaccuracySmoothing: (state, action) => {
            state.inaccuracySmoothing = action.payload;
        },
        setAcceptedAccuracy: (state, action) => {
            state.acceptedAccuracy = action.payload;
        },
    },
    extraReducers: {
        [setSelectedSpaceId]: (state) => {
            state.selectedBuildingId = null;
            state.selectedFloorId = null;
            state.selectedMapId = null;
        },

        [deleteBuilding.fulfilled]: (state, action) => {
            if (action.meta.arg === state.selectedBuildingId) {
                state.selectedBuildingId = action.payload;
                state.selectedFloorId = null;
                state.selectedMapIds = [];
            }
        },

        [deleteFloor.fulfilled]: (state, action) => {
            if (action.meta.arg.floorId === state.selectedFloorId) {
                state.selectedFloorId = null;
                state.selectedMapIds = [];
            }
        },

        [deleteMap.fulfilled]: (state, action) => {
            if (state.selectedMapIds.includes(action.meta.arg.mapId)) {
                state.selectedMapIds = state.selectedMapIds.filter((id) => id !== action.meta.arg.mapId);
            }
        },

        [wipeAllUserInputs]: () => initialState,
    },
});

export const selectAnalyticsUserActivityUserInputs = (state) => state.userInputs.analytics.userActivity;
