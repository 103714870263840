import Api from './Api';
import http from '../utils/httpClient';

class MappersApi extends Api {
    async fetchAllMappersInMap(mapId) {
        return await this.callApi(() => http.mapping.v1.get(`/maps/${mapId}/mappers`));
    }

    async updateMapper(mapId, mapperId, mapperData) {
        return await this.callApi(() =>
            http.mapping.v1.patch(`/maps/${mapId}/mappers/${mapperId}`, mapperData)
        );
    }
}

export default new MappersApi();
