import React from 'react';
import styled from 'styled-components';
import {
    Snackbar as MuiSnackbar,
    SnackbarContent as MuiSnackbarContent,
    Typography,
} from '@material-ui/core';
import { Button, Radio, TextField } from '../../themed';

const Snackbar = styled(MuiSnackbar)`
    position: absolute;
`;

const SnackbarContent = styled(MuiSnackbarContent).attrs(() => ({
    classes: { root: 'instruction-snackbar-content' },
}))`
    &.instruction-snackbar-content {
        display: flex;
        flex-direction: column;
    }
`;

export function InstructionSnackbar(props) {
    const { message, action, ...otherProps } = props;

    return (
        <Snackbar
            onClick={(e) => e.stopPropagation()}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open
            {...otherProps}
        >
            <SnackbarContent message={message} action={action} />
        </Snackbar>
    );
}

export const InstructionText = styled(Typography)`
    color: ${({ $color }) => $color || 'white'}; //TODO color should come from theme
`;

export const InstructionSnackbarTextField = styled(TextField)`
    &&& .themed-underline {
        ::before {
            border-bottom-color: white; // TODO color should come from theme
        }
        :hover::before {
            border-bottom-color: white; // TODO color should come from theme
        }
    }

    & .themed-input-label {
        color: white; // TODO color should come from theme
    }

    input {
        color: white;
    }
`;

export const InstructionSnackbarButton = styled(Button)`
    color: white;
`;

export const InstructionSnackbarRadio = styled(Radio)`
    color: white;
`;
