import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { MdAddCircle, MdRefresh } from 'react-icons/md';
import { TextField, Button } from '../../common/themed';
import Grid from '@material-ui/core/Grid';

const CardWrapper = styled(Paper)`
    padding: 1rem;
    margin-bottom: 1rem;
`;

const ButtonsWrapper = styled.div`
    ${Button} {
        svg {
            margin-right: 5px;
        }

        :not(:last-child) {
            margin-right: 1rem;
        }
    }
`;

export default function ActionsToolbar(props) {
    const { onCreate, onRefresh, filter, onFilterChange, children } = props;

    return (
        <CardWrapper elevation={2}>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <div>
                    <TextField
                        label={'Search...'}
                        value={filter}
                        onChange={(e) => onFilterChange(e.target.value)}
                    />

                    {children}
                </div>

                <ButtonsWrapper>
                    {onCreate && (
                        <Button onClick={onCreate}>
                            <MdAddCircle size={24} />
                            Create
                        </Button>
                    )}

                    <Button onClick={onRefresh}>
                        <MdRefresh size={24} />
                        Refresh
                    </Button>
                </ButtonsWrapper>
            </Grid>
        </CardWrapper>
    );
}

ActionsToolbar.propTypes = {
    onCreate: PropTypes.func,
    onRefresh: PropTypes.func.isRequired,
    filter: PropTypes.string.isRequired,
    onFilterChange: PropTypes.func.isRequired,
};
