import styled from 'styled-components';
export const Arrow = styled.span`
    font-size: 14px;
    color: #ff6200;
    height: max-content;
    margin: auto 0;

    @media (max-width: 1600px) {
        height: max-content;
        margin: auto 0;
    }
`;
