import React, { Fragment, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
    IMAGE_NATURAL_HEIGHT,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import { MapViewerContext } from '../../MapViewerContext';
import useKeypress from 'react-use-keypress';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorNotification } from '../../../../../state-management/notification/notificationReducer';
import { convertToMeterFromEvent, convertToPixel } from '../regions/utils';
const PolygonDrawerWrapper = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
`;

const CircleDot = styled.circle.attrs({
    style: {
        r: `calc(6 / var(${SCALE}))`
    }
})`
    fill: #ff6200;
    &:hover {
        fill: #e28406;
        r: 6;
        cursor: move;
    }
`;
const CircleDotMiddle = styled.circle.attrs({
    style: {
        r: `calc(5 / var(${SCALE}))`
    }
})`
    fill: #ffa42b;
    transition: fill, r 0.3s ease;
    &:hover {
        fill: #e28406;
        r: 6;
        cursor: move;
    }
`;
const Polyline = styled.polyline`
    fill: #ff620029;
    stroke: #ff6200b8;
    vector-effect: inherit;
    stroke-dasharray: 5;
    stroke-width: 3;
`;

const PolygonDrawer = (props) => {
    const { onSubmitted, points = [], editMode = false, setPoints } = props;
    const { imageRef, getCSSVariable, MapViewerOptionsRef } = useContext(MapViewerContext);

    const [originalPoints, setOriginalPoints] = useState(points);
    const [drawing, setDrawing] = useState(points.length === 0);
    const [selected, setSelected] = useState(null);
    const dispatch = useDispatch();

    useKeypress('Enter', () => {
        if (originalPoints.length >= 3) {
            let updatedPoints = [...points];
            updatedPoints[points.length - 1] = points[0];
            setPoints(updatedPoints);
            setDrawing(false);

            onSubmitted(updatedPoints);
        } else {
            dispatch(
                showErrorNotification(`A minimum of 3 points are required to define the region's shape`)
            );
        }
    });

    const getPosition = useCallback((event) => {
        const refRect = imageRef.current.getBoundingClientRect();
        const { x, y } = convertToMeterFromEvent(event, getCSSVariable, refRect, true)
        return { x, y };
    }, []);

    const onExpandClick = (e, index, isExpanding = true) => {
        setSelected(index);
        setDrawing(false);
        if (isExpanding) {
            const updatedPoints = [...points];
            // adding a new point in the middle of the line
            updatedPoints.splice(index + 1, 0, {
                x: (points[index].x + points[index + 1].x) / 2,
                y: (points[index].y + points[index + 1].y) / 2,
            });
            setPoints(updatedPoints);
        }
    };

    const handleExpandMove = (e, index) => {
        const firstPoint = points[index];
        const secondPoint = points[index + 1];

        if (selected !== null && firstPoint && secondPoint) {
            const { x, y } = getPosition(e);
            // Update the last point to follow the mouse cursor
            const updatedPoints = [...points];
            updatedPoints[index + 1] = {
                x,
                y,
            };

            setPoints(updatedPoints);
        }
    };
    const handleMouseDown = (event) => {
        if (!drawing) return;
        const { x, y } = getPosition(event);
        if (points.length === 0) {
            // adding additional point for drawing
            setPoints([
                { x, y },
                { x, y },
            ]);
        } else {
            setPoints([...points, { x, y }]);
        }

        setOriginalPoints((prev) => [...prev, { x, y }]);
    };

    const handleMouseMove = (event) => {
        if (points.length <= 1) return;
        if (selected !== null) {
            const { x, y } = getPosition(event);
            // Update the last point to follow the mouse cursor
            const updatedPoints = [...points];
            updatedPoints[selected + 1] = {
                x,
                y
            };
            if (selected === -1) {
                updatedPoints[points.length - 1] = points[0];
            }

            setPoints(updatedPoints);
        }
        if (drawing) {
            const { x, y } = getPosition(event);

            // Update the last point to follow the mouse cursor
            const updatedPoints = [...points];
            updatedPoints[points.length - 1] = {
                x: x,
                y,
            };
            setPoints(updatedPoints);
        }
    };

    const handleMouseUp = (e) => {
        e.stopPropagation();
        setSelected(null);
    };

    useEffect(() => {
        if (MapViewerOptionsRef) {
            MapViewerOptionsRef.current.disablePanning = true;
        }
        return () => {
            MapViewerOptionsRef.current.disablePanning = false;
        };
    }, []);


    const pointsInPixel = useMemo(() => {
        return points.map(point => convertToPixel(point, getCSSVariable))
    }, [points])

    return (
        <PolygonDrawerWrapper
            onMouseUp={handleMouseUp}
            onClick={handleMouseDown}
            onMouseMove={handleMouseMove}
        >
            {pointsInPixel.length >= 1 && (
                <Fragment>
                    <Polyline points={pointsInPixel.map((point) => `${point.x},${point.y}`).join(' ')} />
                    {pointsInPixel.map(
                        (point, index) =>
                            index !== pointsInPixel.length - 1 && (
                                <CircleDot
                                    key={index}
                                    cx={point.x}
                                    cy={point.y}
                                    r={6}
                                    onMouseDown={(e) => !drawing && onExpandClick(e, index - 1, false)}
                                />
                            )
                    )}

                    {pointsInPixel.map(
                        (_, index) =>
                            pointsInPixel[index + 1] && (
                                <CircleDotMiddle
                                    key={index}
                                    cx={(pointsInPixel[index].x + pointsInPixel[index + 1].x) / 2}
                                    cy={(pointsInPixel[index].y + pointsInPixel[index + 1].y) / 2}
                                    r={4}
                                    onMouseDown={(e) => onExpandClick(e, index)}
                                    onMouseMove={(e) => handleExpandMove(e, index)}
                                />
                            )
                    )}
                </Fragment>
            )}
        </PolygonDrawerWrapper>
    );
};

export default PolygonDrawer;
