export const getGoogleMapsId = () => `change-building-placement-gmaps`;
export const getBuildingOriginGMapsMarkerId = () => `change-building-placement-building-origin-marker`;
export const getGeofenceOriginGMapsMarkerId = () => `change-building-placement-geofence-origin-marker`;
export const getGeofenceRadiusGMapsCircleId = () => `change-building-placement-geofence-radius-circle`;
export const getChangeBuildingOriginButtonId = () => `change-building-placement-change-building-origin`;
export const getChangeGeofenceOriginButtonId = () => `change-building-placement-change-geofence-origin`;
export const getBuildingOriginLatitudeId = () => `change-building-placement-building-origin-lat`;
export const getBuildingOriginLongitudeId = () => `change-building-placement-building-origin-lng`;
export const getGeofenceOriginLatitudeId = () => `change-building-placement-geofence-origin-lat`;
export const getGeofenceOriginLongitudeId = () => `change-building-placement-geofence-origin-lng`;
export const getGeofenceRadiusFieldId = () => `change-building-placement-geofence-radius`;
export const getBuildingRotationId = () => `change-building-placement-rotation`;
