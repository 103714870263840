export const getFiltersBarBuildingSelectId = () => `analytics-user-activity-filters-bar-building-select`;
export const getFiltersBarFloorSelectId = () => `analytics-user-activity-filters-bar-floor-select`;
export const getFiltersBarMapsSelectId = () => `analytics-user-activity-filters-bar-maps-select`;
export const getFiltersBarTimeSpanStartPickerId = () =>
    `analytics-user-activity-filters-bar-time-span-start-picker`;
export const getFiltersBarTimeSpanEndPickerId = () =>
    `analytics-user-activity-filters-bar-time-span-end-picker`;
export const getFiltersBarTimeZoneSelectId = () => `analytics-user-activity-filters-bar-time-zone-select`;
export const getFiltersBarSessionIdId = () => `analytics-user-activity-filters-bar-session-id`;
export const getFiltersBarAcceptedAccuracyId = () => `analytics-user-activity-filters-bar-accepted-accuracy`;
export const getFiltersBarInaccuracySmoothingId = () =>
    `analytics-user-activity-filters-bar-inaccuracy-smoothing`;
export const getFiltersBarTimeSpanDialogStartPickerId = () =>
    `analytics-user-activity-filters-bar-time-span-dialog-start-picker`;
export const getFiltersBarTimeSpanDialogEndPickerId = () =>
    `analytics-user-activity-filters-bar-time-span-dialog-end-picker`;
export const getFiltersBarTimeSpanDialogSpaceId = () =>
    `analytics-user-activity-filters-bar-time-span-dialog-space`;
export const getFiltersBarTimeSpanDialogBuildingId = () =>
    `analytics-user-activity-filters-bar-time-span-dialog-building`;
export const getFiltersBarTimeSpanDialogReprocessing = () =>
    `analytics-user-activity-filters-bar-time-span-dialog-reprocessing`;
