import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectMappingIsLineOfInterestCreationOpen,
    selectMappingSelectedMapId,
    setIsLineOfInterestCreationOpen,
} from '../../../state-management/user-inputs/mappingSlice';
import MapViewerLineOfInterestCreationOverlay from '../../common/map-viewer/overlays/lines-of-interest-creation';

export const LineOfInterestCreationOverlay = () => {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const isLineOfInterestCreationOpen = useSelector(selectMappingIsLineOfInterestCreationOpen);

    return (
        isLineOfInterestCreationOpen && (
            <MapViewerLineOfInterestCreationOverlay
                mapId={selectedMapId}
                onSubmitted={() => dispatch(setIsLineOfInterestCreationOpen(false))}
                onCancel={() => dispatch(setIsLineOfInterestCreationOpen(false))}
            />
        )
    );
};
