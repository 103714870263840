import React, { forwardRef, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
    Tooltip,
    CircularPreloader,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '../../../common/themed';
import { MdDelete as DeleteIcon, MdEdit as EditIcon } from 'react-icons/md';
import { IconButton, RootRef, Grid } from '@material-ui/core';
import { selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { selectCanEditTriggers } from '../../../../state-management/auth/authSelectors';
import {
    getTriggersListEntryDeleteButtonId,
    getTriggersListEntryEditButtonId,
} from '../../MapContent.selectors';
import { deleteTrigger } from '../../../../state-management/trigger/triggerActions';
import { openTriggerEditor } from '../../../../state-management/user-inputs/mapContentSlice';

const IconWrapper = forwardRef((props, ref) => (
    <RootRef rootRef={ref}>
        <IconButton {...props} />
    </RootRef>
));

export default function TriggerActions(props) {
    const { triggerId, triggerName } = props;

    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

    const dispatch = useDispatch();

    const selectIsDeleting = useMemo(
        () => selectIsLoading({ type: deleteTrigger.typePrefix, arg: triggerId }),
        [triggerId]
    );

    const canEditTriggers = useSelector(selectCanEditTriggers);
    const isDeleting = useSelector(selectIsDeleting);

    const handleEdit = (e) => {
        e.stopPropagation();
        dispatch(openTriggerEditor({ triggerId }));
    };

    const handleDelete = async () => {
        const result = await dispatch(deleteTrigger(triggerId));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Trigger deleted successfully.'));
        } else {
            dispatch(showErrorNotification('Failed to delete trigger.'));
        }

        setIsDeleteWarningOpen(false);
    };

    return (
        <>
            <Grid container alignItems={'center'} justifyContent={'space-evenly'}>
                <Tooltip content={'Delete trigger'} placement={'bottom'} useWrapper={false}>
                    <IconWrapper
                        id={getTriggersListEntryDeleteButtonId(triggerId)}
                        aria-label={'Delete'}
                        disabled={!canEditTriggers}
                        onClick={() => setIsDeleteWarningOpen(true)}
                    >
                        <DeleteIcon />
                    </IconWrapper>
                </Tooltip>

                <Tooltip content={'Edit trigger'} placement={'bottom'} useWrapper={false}>
                    <IconWrapper
                        id={getTriggersListEntryEditButtonId(triggerId)}
                        aria-label={'Edit'}
                        disabled={!canEditTriggers}
                        onClick={handleEdit}
                    >
                        <EditIcon />
                    </IconWrapper>
                </Tooltip>
            </Grid>

            {isDeleteWarningOpen && (
                <Dialog open closeable={false}>
                    <DialogContent>
                        <DialogContentText>
                            You are about to delete the trigger "{triggerName}". Are you sure?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button variant={'text'} onClick={() => setIsDeleteWarningOpen(false)}>
                            Cancel
                        </Button>

                        {isDeleting ? <CircularPreloader /> : <Button onClick={handleDelete}>Accept</Button>}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

TriggerActions.propTypes = {
    triggerId: PropTypes.string,
    triggerName: PropTypes.string,
};
