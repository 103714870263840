import React from 'react';
import { Switch } from '@material-ui/core';
import styled from 'styled-components';

export default styled((props) => {
    return (
        <Switch
            classes={{
                checked: 'themed-checked',
                track: 'themed-bar',
            }}
            {...props} />
    );
})`
    & .themed-checked {
        color: #ff6200; // TODO color should come from theme
    }

    & .themed-checked + .themed-bar {
        background-color: #ff6200;
    }
`;
