import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './monitorClientEntityAdapter';
import { expireSession, logout } from '../../auth/authActions';
import { clientExpired, clientPositionUpdated, monitoringMap } from '../monitorEventsActions';

const availableColors = [
    'midnightblue',
    'darkgreen',
    'orangered',
    'orchid',
    'maroon',
    'steelblue',
    'purple',
    'navy',
    'lime',
    'lightsalmon',
    'hotpink',
    'gold',
    'cyan',
    'magenta',
    'gray',
    'black',
    'brown',
    'yellow',
    'red',
    'green',
    'blue',
];

const getClientColor = () => {
    if (!availableColors.length) {
        // If we're out of colors, start returning random colors
        return Math.floor(Math.random() * 16777215).toString(16);
    }
    return availableColors.pop();
};

const { getInitialState, getSelectors, upsertOne, removeOne } = entityAdapter;

export const { reducer } = createSlice({
    name: 'monitor/clients',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [monitoringMap]: () => getInitialState(),

        [clientPositionUpdated]: (state, action) => {
            const { clientId, clientName, sessionId, position } = action.payload.data;
            const { color } = getSelectors().selectById(state, clientId) ?? {};

            upsertOne(state, {
                clientId,
                clientName,
                sessionId,
                position: position?.location,
                positionAccuracy: position?.locationAccuracy,
                heading: position?.heading,
                color: color ?? getClientColor()
            });
        },

        [clientExpired]: (state, action) => {
            const clientId = typeof (action.payload) === "string" ? action.payload : action.payload.clientId;
            removeOne(state, clientId);
        },

        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
    },
});
