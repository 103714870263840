import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    Accordion,
    Typography,
    AccordionSummary,
    AccordionDetails,
    IconButton,
} from '@material-ui/core';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdExpandMore as ExpandMoreIcon } from 'react-icons/md';
import { FaCopy as CopyIcon } from 'react-icons/fa';
import { Tooltip } from '../../common/themed';
import ApiKeyEditor from './ApiKeyEditor';
import { permissionDescriptions } from '../../../constants/permissions';
import { showInfoNotification } from '../../../state-management/notification/notificationReducer';
import useSpaces from '../../common/hooks/data-fetching/useSpaces';
import useUsers from '../../common/hooks/data-fetching/useUsers';

const CopyButtonWrapper = styled(IconButton)`
    padding: 6px;

    svg {
        width: 0.7em;
        height: 0.7em;
    }
`;

const SummaryWrapper = styled.div`
    display: grid;
    grid-template-rows: repeat(2, 25px);
    grid-template-columns: repeat(5, minmax(100px, 1fr));
    column-gap: 50px;

    & > div {
        display: inline-flex;
        align-items: center;

        span {
            overflow: hidden;
        }
    }
`;

const DetailTitle = styled(Typography).attrs({
    variant: 'subtitle1',
})``;

const DetailValue = styled(Typography).attrs({
    variant: 'subtitle2',
    noWrap: true,
})`
    color: #7a7a7a;
    max-width: 20rem;
`;

export default function ApiKeyDetailsCard(props) {
    const { apiKey } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    const dispatch = useDispatch();

    const { data: spaces = {} } = useSpaces({
        asObject: true,
    });

    const { data: users = [] } = useUsers({
        apiKeyId: apiKey?.apiKeyId,
    });

    const apiKeySpacesText = apiKey.spaces.map((spaceId) => spaces?.[spaceId]?.spaceDesc).join(', ');

    const apiPermissionsText = apiKey.permissions
        .map((perm) => permissionDescriptions[perm] || perm)
        .join(', ');

    const apiKeyDetails = [
        {
            title: 'API Key Name',
            value: apiKey.apiKeyDesc,
            withTooltip: false,
            copyable: false,
        },
        { title: 'API Key', value: apiKey.apiKeyId, withTooltip: false, copyable: true },
        { title: 'Spaces', value: apiKeySpacesText, withTooltip: true, copyable: false },
        {
            title: 'Permissions',
            value: apiPermissionsText,
            withTooltip: true,
            copyable: false,
        },
        {
            title: 'Users',
            value: users?.map((u) => u.userName)?.join(', ') ?? [],
            withTooltip: false,
            copyable: false,
        },
    ];

    return (
        <Accordion onChange={(event, isExpanded) => setIsExpanded(isExpanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <SummaryWrapper>
                    {apiKeyDetails.map(({ title, copyable, value }, index) => (
                        <div key={`${title}-${index}`}>
                            <DetailTitle key={title}>{title}</DetailTitle>

                            {copyable && (
                                <CopyToClipboard
                                    text={value}
                                    onCopy={() => {
                                        dispatch(showInfoNotification(`${title} copied`));
                                    }}
                                >
                                    <CopyButtonWrapper onClick={(e) => e.stopPropagation()}>
                                        <CopyIcon />
                                    </CopyButtonWrapper>
                                </CopyToClipboard>
                            )}
                        </div>
                    ))}

                    {apiKeyDetails.map(({ value, withTooltip }, index) => (
                        <div key={`${value}-${index}`}>
                            {withTooltip ? (
                                <Tooltip content={value}>
                                    <DetailValue >{value}</DetailValue>
                                </Tooltip>
                            ) : (
                                <DetailValue >{value}</DetailValue>
                            )}
                        </div>
                    ))}
                </SummaryWrapper>
            </AccordionSummary>

            {isExpanded && (
                <AccordionDetails>
                    <ApiKeyEditor apiKey={apiKey} />
                </AccordionDetails>
            )}
        </Accordion>
    );
}

ApiKeyDetailsCard.propTypes = {
    apiKey: PropTypes.object.isRequired,
};
