import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';

export default styled((props) => (
    <Checkbox
        classes={{
            checked: 'themed-checked',
        }}
        {...props}
    />
))`
    &.themed-checked {
        color: #ff6200; // TODO color should come from theme
    }
`;
