import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeRegionEditor,
    closeTriggerEditor,
    selectMapContentIsRegionEditorOpen,
    selectMapContentIsTriggerEditorOpen,
    selectMapContentRegionEditorPlacementId,
    selectMapContentRegionEditorRegionId,
    selectMapContentTriggerEditorTriggerId,
} from '../../state-management/user-inputs/mapContentSlice';
import RegionEditorDialog from './dialogs/region-editor';
import MapContentMapViewer from './MapContentMapViewer';
import MapContentSideDrawer from './MapContentSideDrawer';
import TriggerEditorDialog from './dialogs/trigger-editor';

export default function MapContent() {
    const dispatch = useDispatch();
    const isRegionEditorOpen = useSelector(selectMapContentIsRegionEditorOpen);
    const regionEditorRegionId = useSelector(selectMapContentRegionEditorRegionId);
    const regionEditorPlacementId = useSelector(selectMapContentRegionEditorPlacementId);
    const isTriggerEditorOpen = useSelector(selectMapContentIsTriggerEditorOpen);
    const triggerEditorTriggerId = useSelector(selectMapContentTriggerEditorTriggerId);

    return (
        <>
            <MapContentMapViewer />

            <MapContentSideDrawer />

            {isRegionEditorOpen && (
                <RegionEditorDialog
                    regionId={regionEditorRegionId}
                    placementId={regionEditorPlacementId}
                    onClose={() => dispatch(closeRegionEditor())}
                />
            )}

            {isTriggerEditorOpen && (
                <TriggerEditorDialog
                    triggerId={triggerEditorTriggerId}
                    onClose={() => dispatch(closeTriggerEditor())}
                />
            )}

            {/*TODO Remove this div when usage of old map viewer is removed*/}
            <div className="context-menu-holder" />
        </>
    );
}
