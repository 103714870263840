import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import OccupancyFiltersBar from './RegionOccupancyFilters';
import { useSelector } from 'react-redux';
import {
    getOccupancyDataByTimeResolution,
    getOccupancyDataAvailableTimeResolutions,
    getOccupancySubmittedFilters,
} from '../../../state-management/analytics/region-occupancy/regionOccupancySelectors';
import RegionOccupancyChartsDisplaySettings from './RegionOccupancyChartsDisplaySettings';
import RegionOccupancyBarChart from './RegionOccupancyBarChart';
import { Divider, Paper, RootRef } from '@material-ui/core';
import { AnimatePresence, motion } from 'framer-motion';
import { FETCH_REGION_OCCUPANCY_DATA_REQUEST } from '../../../state-management/analytics/region-occupancy/regionOccupancyActions';
import { getTimeResolutionByName } from './time-resolutions';
import { getIsLoading } from '../../../state-management/status/statusSelectors';
import { ScalePreloader } from '../../common/themed';
import { selectAnalyticsBuildingUsageDisplaySettingsUserInputs } from '../../../state-management/user-inputs/analyticsBuildingUsageSlice';

const NoRecordsMessage = styled(motion.div).attrs({
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
})`
    font-size: 1.2rem;
    text-align: center;
    margin-top: 5rem;
`;

const PaperWithRef = forwardRef((props, ref) => (
    <RootRef rootRef={ref}>
        <Paper {...props} />
    </RootRef>
));

const MotionCard = motion.custom(PaperWithRef);

const FiltersCardWrapper = styled(Paper)`
    padding: 20px;
    overflow: visible;
    margin-bottom: 20px;
    transition: height 300ms ease;
`;

const FiltersDivider = styled(Divider)`
    margin-top: 15px;
    margin-bottom: 15px;
`;

const DisplaySettingsWrapper = styled(motion.div).attrs(() => ({
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
}))``;

const ChartCardWrapper = styled(MotionCard).attrs(() => ({
    variants: {
        hidden: { y: -20, opacity: 0 },
        visible: (i) => ({
            y: 0,
            opacity: 1,
            transition: { delay: i * 0.3, duration: 0.3 },
        }),
    },
    initial: 'hidden',
    animate: 'visible',
    exit: { opacity: 0, transition: { duration: 0.3 } },
}))`
    height: 300px;
    padding: 20px;
`;

const ChartsWrapper = styled.div`
    ${ChartCardWrapper}:not(:last-child) {
        margin-bottom: 20px;
    }
`;

export default function RegionOccupancy() {
    const occupancyData = useSelector(getOccupancyDataByTimeResolution);
    const availableTimeResolutions = useSelector(getOccupancyDataAvailableTimeResolutions);
    const isLoading = useSelector(getIsLoading(FETCH_REGION_OCCUPANCY_DATA_REQUEST));
    const { selectedRegionIds, selectedTimeResolution } = useSelector(
        selectAnalyticsBuildingUsageDisplaySettingsUserInputs
    );
    const submittedFilters = useSelector(getOccupancySubmittedFilters);

    const [chartData, setChartData] = useState({});

    useEffect(() => {
        // If the occupancy data changes, update the chart data
        const data = availableTimeResolutions.reduce(
            (result, resolution) => ({ ...result, [resolution]: [] }),
            {}
        );

        const { startTimestamp, endTimestamp, timeZoneOffset, workingHoursOnly } = submittedFilters;

        for (const { timeResolution, byRegion } of occupancyData) {
            const timeRes = getTimeResolutionByName(timeResolution);
            timeRes.timeZoneOffset = timeZoneOffset;

            if (workingHoursOnly) {
                timeRes.startHourUtc = 8;
                timeRes.endHourUtc = 20;
            } else {
                timeRes.startHourUtc = null;
                timeRes.endHourUtc = null;
            }

            const timeIntervals = timeRes.getTimeIntervalsInRange(startTimestamp, endTimestamp);

            const timeIntervalsTemplateReduce = (result, { start, end }) => ({
                ...result,
                [timeRes.getTimeIntervalString(start, end)]: {
                    timeInterval: timeRes.getTimeIntervalString(start, end),
                },
            });

            const countData = timeIntervals.reduce(timeIntervalsTemplateReduce, {});
            const maxData = timeIntervals.reduce(timeIntervalsTemplateReduce, {});
            const avgData = timeIntervals.reduce(timeIntervalsTemplateReduce, {});

            let hasEntryCountData = false;
            let hasMaxData = false;
            let hasAvgData = false;
            let index = 0;

            for (const regionId of selectedRegionIds) {
                if (!byRegion[regionId]) {
                    continue;
                }

                const { regionName, regionOccupation } = byRegion[regionId];

                for (const { timeIntervalStart, timeIntervalEnd, entryCount, max, avg } of regionOccupation) {
                    const timeInterval = timeRes.getTimeIntervalString(timeIntervalStart, timeIntervalEnd);

                    if (entryCount) {
                        if (!countData[timeInterval]) {
                            console.error(
                                'Could not find time interval:',
                                timeInterval,
                                timeIntervalStart,
                                timeIntervalEnd
                            );
                            continue;
                        }
                        countData[timeInterval][`region${index}Name`] = regionName;
                        countData[timeInterval][`region${index}Id`] = regionId;
                        countData[timeInterval][`region${index}Value`] = entryCount;
                        hasEntryCountData = true;
                    }
                    if (max) {
                        maxData[timeInterval][`region${index}Name`] = regionName;
                        maxData[timeInterval][`region${index}Id`] = regionId;
                        maxData[timeInterval][`region${index}Value`] = max;
                        hasMaxData = true;
                    }
                    if (avg) {
                        avgData[timeInterval][`region${index}Name`] = regionName;
                        avgData[timeInterval][`region${index}Id`] = regionId;
                        avgData[timeInterval][`region${index}Value`] =
                            Math.round((avg + Number.EPSILON) * 100) / 100; // Round to 2 decimal points
                        hasAvgData = true;
                    }
                }

                index++;
            }

            if (hasAvgData) {
                data[timeResolution].push({
                    name: 'Average Occupancy',
                    description: 'The average number of people who occupied the region',
                    data: Object.values(avgData),
                });
            }

            if (hasMaxData) {
                data[timeResolution].push({
                    name: 'Max Occupancy',
                    description: 'The largest number of people who occupied the region at the same time',
                    data: Object.values(maxData),
                });
            }

            if (hasEntryCountData) {
                data[timeResolution].push({
                    name: 'Entry Count',
                    description: 'The total number of entries to the region',
                    data: Object.values(countData),
                });
            }
        }

        setChartData(data);
    }, [occupancyData, availableTimeResolutions, selectedRegionIds, submittedFilters]);

    return (
        <>
            <FiltersCardWrapper>
                <OccupancyFiltersBar />

                <AnimatePresence>
                    {availableTimeResolutions.length > 0 && (
                        <DisplaySettingsWrapper>
                            <FiltersDivider variant={'fullWidth'} />

                            <RegionOccupancyChartsDisplaySettings />
                        </DisplaySettingsWrapper>
                    )}
                </AnimatePresence>
            </FiltersCardWrapper>

            {isLoading ? (
                <ScalePreloader height={65} width={10} margin={'4px'} />
            ) : chartData[selectedTimeResolution] && chartData[selectedTimeResolution].length > 0 ? (
                <ChartsWrapper>
                    <AnimatePresence>
                        {chartData[selectedTimeResolution].map(({ name, description, data }, index) => (
                            <ChartCardWrapper key={name} custom={index}>
                                <RegionOccupancyBarChart name={name} description={description} data={data} />
                            </ChartCardWrapper>
                        ))}
                    </AnimatePresence>
                </ChartsWrapper>
            ) : (
                <AnimatePresence>
                    <NoRecordsMessage>
                        No records to display.
                        <br /> Please try choosing different filter parameters.
                    </NoRecordsMessage>
                </AnimatePresence>
            )}
        </>
    );
}
