import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Skeleton } from '../../../../common/themed';
import { Autocomplete, GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import styled, { createGlobalStyle } from 'styled-components';
import {
    getSearchBoxId,
    getGoogleMapsId,
    getGoogleMapsWrapperId,
} from './GoogleMapsBuildingPlacement.selectors';
import settings from '../../../../../clientSettings';
import ErrorGeneral from '../../../../common/error-pages/ErrorGeneral';
import { getLocationPickerAddressCardId } from '../../location-picker/LocationPickerDialog.selectors';
import { Card } from '@material-ui/core';

const { googleMapsApiKey } = settings;

const libraries = ['places'];

const PIXEL_TO_METER = '--gmaps-pixel-to-meter';
const OFFSET_X = '--gmaps-offset-x';
const OFFSET_Y = '--gmaps-offset-y';
const METERS_PER_PIXEL = '--gmaps-meters-per-pixel';
const IMAGE_NATURAL_HEIGHT = '--gmaps-image-natural-height';
const IMAGE_NATURAL_WIDTH = '--gmaps-image-natural-width';
const MOUSE_HOVER_X = '--mouse-hover-x'
const MOUSE_HOVER_Y = '--mouse-hover-y'
const IMAGE_FOLLOW_ID = 'image-id';

// TODO this is a hacky solution to deal with the suggestions list z-index being lower than material-ui's dialog and not shown as a result
const SuggestionsListStyles = createGlobalStyle`
   .pac-container {
        z-index: 1501;
    }
`;

const GoogleMapsSearchBox = styled.input`
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 240px;
    height: 32px;
    padding: 0 12px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipsis;
    position: absolute;
    left: 50%;
    margin-left: -120px;
`;

const MapImage = styled.img`
    position: absolute;
    opacity: ${(props) => props.$opacity};
    width: calc(var(${IMAGE_NATURAL_WIDTH}) / (var(${PIXEL_TO_METER}) * var(${METERS_PER_PIXEL})) * 1px);
    height: calc(var(${IMAGE_NATURAL_HEIGHT}) / (var(${PIXEL_TO_METER}) * var(${METERS_PER_PIXEL})) * 1px);
    transform: translate(
            calc((var(${OFFSET_X}) / var(${METERS_PER_PIXEL})) * 1px),
            calc(-100% - (var(${OFFSET_Y}) / var(${METERS_PER_PIXEL})) * 1px)
        )
        rotate(${(props) => props.$rotation}deg);
    transform-origin-x: calc((var(${OFFSET_X}) / var(${METERS_PER_PIXEL})) * -1px);
    transform-origin-y: calc((var(${OFFSET_Y}) / var(${METERS_PER_PIXEL})) * 1px + 100%);
    transition: all 200ms ease;

    &.draggable {
        cursor: grab;
    }
`;

const MousePointerMapImage = styled(MapImage)`
transition: none;
pointer-events: none;
top: var(${MOUSE_HOVER_Y});
left: var(${MOUSE_HOVER_X});
`

const AddressCard = styled(Card)`
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
    left: 50%;
    padding: 10px;
    font-size: 0.9rem;
`;

export default function GoogleMapsBuildingPlacement(props) {
    const {
        mapUrl,
        location,
        defaultPixelToMeter,
        defaultOffset,
        onLocationChange,
        disableLocationPick = false,
        opacity = 0.5,
        rotation = 0,
        children,
        isSettingBuildingOrigin,
        ...otherProps
    } = props;

    const { isLoaded, loadError } = useJsApiLoader({ googleMapsApiKey, libraries });

    const [autocomplete, setAutocomplete] = useState(null);
    const [map, setMap] = useState(null);
    const [geocoder, setGeocoder] = useState(null);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [zoom, setZoom] = useState(1);
    const [displayedLocation, setDisplayedLocation] = useState(null);


    const setCSSVariable = useCallback(
        (name, value) => document.getElementById(getGoogleMapsWrapperId())?.style?.setProperty(name, value),
        []
    );

    const settCSSVariableImage = useCallback(
        (name, value) => document.getElementById(IMAGE_FOLLOW_ID)?.style?.setProperty(name, value),
        []
    );

    const onMapLoad = (map) => {
        setMap(map);
        setGeocoder(new window.google.maps.Geocoder());

        map.addListener('zoom_changed', () => setZoom(map.getZoom()));

        map.setZoom(location?.lat !== 0 || location?.lng !== 0 ? 17 : 2);
        map.setCenter(location);
    };

    const onMapClick = (clc) => {
        if (!disableLocationPick && geocoder) {
            const lat = clc.latLng.lat();
            const lng = clc.latLng.lng();

            geocoder.geocode({ location: { lat, lng } }, (results, status) => {
                let address;

                if (status === 'OK') {
                    address = results?.[0]?.formatted_address;
                } else {
                    address = null;
                }

                setDisplayedLocation({ address, lat, lng });
                onLocationChange({ address, lat, lng });
            });
        }
    };

    const onPlaceChanged = () => {
        if (autocomplete?.getPlace()?.geometry?.location) {
            map.setCenter(autocomplete.getPlace().geometry.location);
            map.setZoom(17);
        }
    };

    useEffect(() => {
        // Set default values for CSS variables
        setCSSVariable(PIXEL_TO_METER, defaultPixelToMeter ?? 1);
        setCSSVariable(OFFSET_X, defaultOffset?.x ?? 0);
        setCSSVariable(OFFSET_Y, defaultOffset?.y ?? 0);
    }, [defaultOffset, defaultPixelToMeter, setCSSVariable]);

    useEffect(() => {
        // Calculate the number of meters per pixel in GMaps
        if (location && map) {
            // https://groups.google.com/g/google-maps-js-api-v3/c/hDRO4oHVSeM/m/osOYQYXg2oUJ
            const metersPerPixel =
                (156543.03392 * Math.cos((location?.lat * Math.PI) / 180)) / Math.pow(2, zoom);
            setCSSVariable(METERS_PER_PIXEL, metersPerPixel);
        }
    }, [location, map, setCSSVariable, zoom]);

    useEffect(() => {
        // Calculate the map image dimensions
        setIsImageLoaded(false);

        let image = new Image();
        image.src = mapUrl;
        image.onload = () => {
            setCSSVariable(IMAGE_NATURAL_HEIGHT, image.height);
            setCSSVariable(IMAGE_NATURAL_WIDTH, image.width);

            setIsImageLoaded(true);
        };
    }, [defaultOffset, defaultPixelToMeter, mapUrl, setCSSVariable]);

    const handleMouseMove = (e) => {
        settCSSVariableImage(MOUSE_HOVER_X, `${e.pixel.x}px`)
        settCSSVariableImage(MOUSE_HOVER_Y, `${e.pixel.y}px`)
    }

    const renderGoogleMaps = () => {
        if (loadError) {
            return <ErrorGeneral />;
        }

        if (!isLoaded) {
            return <Skeleton width={'100%'} height={500} />;
        }

        if (isSettingBuildingOrigin) {
            otherProps.onMouseMove = handleMouseMove
        }

        const showMapImage = !isSettingBuildingOrigin && mapUrl && isImageLoaded && (location?.lat !== 0 || location?.lng !== 0)

        return (
            <GoogleMap
                id={getGoogleMapsId()}
                onLoad={onMapLoad}
                mapContainerStyle={{
                    height: 650,
                }}
                zoom={5}
                options={{
                    streetViewControl: false,
                    fullscreenControl: false,
                    mapTypeControl: false,
                }}
                onClick={onMapClick}
                {...otherProps}
            >
                <SuggestionsListStyles />

                <Autocomplete onLoad={setAutocomplete} onPlaceChanged={onPlaceChanged}>
                    <GoogleMapsSearchBox id={getSearchBoxId()} placeholder={'Search...'} />
                </Autocomplete>

                {showMapImage && (
                    <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={location}>
                        <MapImage alt={'map'} src={mapUrl} $opacity={opacity} $rotation={rotation} />
                    </OverlayView>
                )}

                {isSettingBuildingOrigin && <MousePointerMapImage id={IMAGE_FOLLOW_ID} alt={'map'} src={mapUrl} $opacity={opacity} $rotation={rotation} />}

                {displayedLocation && (
                    <AddressCard id={getLocationPickerAddressCardId()}>
                        {displayedLocation?.address && (
                            <>
                                {displayedLocation?.address}
                                <br />
                            </>
                        )}
                        Latitude: {displayedLocation?.lat}
                        <br />
                        Longitude: {displayedLocation?.lng}
                    </AddressCard>
                )}

                {children({ onMapClick })}
            </GoogleMap>
        );
    };

    return <div id={getGoogleMapsWrapperId()}>{renderGoogleMaps()}</div>;
}

GoogleMapsBuildingPlacement.propTypes = {
    cursor: PropTypes.string,
    pickRadius: PropTypes.bool,
    defaultLocation: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
    defaultRadius: PropTypes.number,
    disabled: PropTypes.bool,
};
