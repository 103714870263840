export const getCreateBuildingDialogId = () => `create-building-dialog`;
export const getFloorToggleId = (floorIndex) => `create-building-floor-toggle-${floorIndex}`;
export const getMapThumbnailId = (floorIndex, mapIndex) =>
    `create-building-floor-${floorIndex}-map-${mapIndex}-thumbnail`;
export const getCreateBuildingStepOneFormId = () => `create-building-step-one-form`;
export const getCreateBuildingStepTwoFormId = () => `create-building-step-two-form`;
export const getCreateBuildingStepThreeFormId = () => `create-building-step-three-form`;
export const getCreateBuildingStepThreeGMapsId = () => `create-building-step-three-gmaps`;
export const getCreateBuildingFormErrorId = () => `create-building-error`;

export const getBuildingNameId = () => `create-building-building-name`;
export const getBuildingDisplayNameId = () => `create-building-building-display-name`;

export const getFloorNameId = (floorIndex) => `create-building-floor-${floorIndex}-name`;
export const getFloorShortNameId = (floorIndex) => `create-building-floor-${floorIndex}-short-name`;
export const getFloorIndexId = (floorIndex) => `create-building-floor-${floorIndex}-index`;
export const getFloorHeightId = (floorIndex) => `create-building-floor-${floorIndex}-height`;
export const getFloorRotationId = (floorIndex) => `create-building-floor-${floorIndex}-rotation`;
export const getMarkFloorAsPrimaryId = (floorIndex) => `create-building-floor-${floorIndex}-mark-as-primary`;

export const getCreateNewFloorId = () => `create-building-create-new-floor`;

export const getMapNameId = (floorIndex, mapIndex) =>
    `create-building-floor-${floorIndex}-map-${mapIndex}-name`;
export const getMapUsageId = (floorIndex, mapIndex) =>
    `create-building-floor-${floorIndex}-map-${mapIndex}-usage`;
export const getPixelToMeterId = (floorIndex, mapIndex) =>
    `create-building-floor-${floorIndex}-map-${mapIndex}-p2m`;
export const getChangePixelToMeterId = (floorIndex, mapIndex) =>
    `create-building-floor-${floorIndex}-map-${mapIndex}-change-p2m`;
export const getOffsetXId = (floorIndex, mapIndex) =>
    `create-building-floor-${floorIndex}-map-${mapIndex}-offset-x`;
export const getOffsetYId = (floorIndex, mapIndex) =>
    `create-building-floor-${floorIndex}-map-${mapIndex}-offset-y`;
export const getChangeOffsetId = (floorIndex, mapIndex) =>
    `create-building-floor-${floorIndex}-map-${mapIndex}-change-offset`;

export const getPixelToMeterDialogAcceptId = () => `create-building-p2m-dialog-accept`;
export const getOffsetDialogAcceptId = () => `create-building-offset-dialog-accept`;

export const getSetBuildingOriginButtonId = () => `create-building-set-building-origin`;
export const getChangeBuildingOriginButtonId = () => `create-building-change-building-origin`;
export const getSetGeofenceOriginButtonId = () => `create-building-set-geofence-origin`;
export const getChangeGeofenceOriginButtonId = () => `create-building-change-geofence-origin`;
export const getCopyBuildingOriginToGeofenceOriginButtonId = () =>
    `create-building-copy-building-origin-to-geofence-origin`;
export const getBuildingOriginLatitudeId = () => `create-building-building-origin-lat`;
export const getBuildingOriginLongitudeId = () => `create-building-building-origin-lng`;
export const getGeofenceOriginLatitudeId = () => `create-building-geofence-origin-lat`;
export const getGeofenceOriginLongitudeId = () => `create-building-geofence-origin-lng`;
export const getGeofenceRadiusFieldId = () => `create-building-geofence-radius`;

export const getBuildingOriginGMapsMarkerId = () => `create-building-building-origin-marker`;
export const getGeofenceOriginGMapsMarkerId = () => `create-building-geofence-origin-marker`;
export const getGeofenceRadiusGMapsCircleId = () => `create-building-geofence-radius-circle`;
