import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

export default styled(props => (
    <CircularProgress
        classes={{
            root: 'themed-circular-preloader',
        }}
        {...props}
    />
))`
    &.themed-circular-preloader {
        color: #ff6200; // TODO color should come from theme
    }
`;
