import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import sessionAnalyticsReducer from './session-analytics/sessionAnalyticsReducer';
import regionOccupancyReducer from './region-occupancy/regionOccupancyReducer';
import storage from 'redux-persist/lib/storage';

const analyticsFiltersPersistConfig = {
    key: 'analytics:sessionAnalyticsFilters',
    storage,
    whitelist: ['filters'],
    timeout: null,
};

export default combineReducers({
    sessionAnalytics: persistReducer(analyticsFiltersPersistConfig, sessionAnalyticsReducer),
    regionOccupancy: regionOccupancyReducer,
});
