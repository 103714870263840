import { createAsyncThunk, isNumberString } from '../utils';
import regionPlacementsApi from '../../api/RegionPlacementsApi';
import RegionsApi from '../../api/RegionsApi';

export const createRegionInternalPlacement = createAsyncThunk(
    'regions/createInternalPlacement',
    async ({ regionId, mapId, placementData }) => {
        return (await regionPlacementsApi.createRegionInternalPlacement(regionId, mapId, placementData)).data;
    }
);
export const createRegionInternalPlacements = createAsyncThunk(
    'regions/createInternalPlacements',
    async ({ spaceId, mapId, originalRegion, placements }) => {
        const res = await Promise.all(
            placements.map(async (p, i) => {
                const regionName = !isNumberString(originalRegion.regionName)
                    ? `${originalRegion.regionName}-Copy-${i + 1}`
                    : `${+originalRegion.regionName + i + 1}`;
                const { data: newRegion } = await RegionsApi.createRegion(
                    {
                        regionName,
                        regionMetadata: originalRegion.regionMetadata,
                        regionUseAnalytics: originalRegion.regionUseAnalytics,
                        regionUseMapPresentation: originalRegion.regionUseMapPresentation,
                        regionUsePlai: originalRegion.regionUsePlai,
                        regionUseTriggers: originalRegion.regionUseTriggers,
                    },
                    spaceId
                );
                if (p.regionType === 'rectangular') {
                    await regionPlacementsApi.createRegionInternalPlacement(newRegion.regionId, mapId, {
                        regionType: p.regionType,
                        bottomLeft: [p.bottomLeft.x, p.bottomLeft.y],
                        topRight: [p.topRight.x, p.topRight.y],
                    });
                } else if (p.regionType === 'circular') {
                    await regionPlacementsApi.createRegionInternalPlacement(newRegion.regionId, mapId, {
                        regionType: p.regionType,
                        center: [p.center.x, p.center.y],
                        radius: p.radius,
                    });
                }

                return { newRegion, placement: p };
            })
        );

        return res;
    }
);
export const updateRegionInternalPlacement = createAsyncThunk(
    'regions/updateInternalPlacement',
    async ({ buildingId, floorId, mapId, regionId, placementData }) => {
        return (
            await regionPlacementsApi.updateRegionInternalPlacement(
                buildingId,
                floorId,
                mapId,
                regionId,
                placementData
            )
        ).data;
    }
);

export const deleteRegionInternalPlacement = createAsyncThunk(
    'regions/deleteInternalPlacement',
    async ({ mapId, regionId }) => {
        return (await regionPlacementsApi.deleteRegionInternalPlacement(regionId, mapId)).data;
    }
);

export const createRegionExternalPlacement = createAsyncThunk(
    'regions/createExternalPlacement',
    async ({ buildingId, regionId, placementData }) => {
        return (await regionPlacementsApi.createRegionExternalPlacement(buildingId, regionId, placementData))
            .data;
    }
);

export const updateRegionExternalPlacement = createAsyncThunk(
    'regions/updateExternalPlacement',
    async ({ regionId, placementId, placementData }) => {
        return (await regionPlacementsApi.updateRegionExternalPlacement(regionId, placementId, placementData))
            .data;
    }
);

export const deleteRegionExternalPlacement = createAsyncThunk(
    'regions/deleteExternalPlacement',
    async ({ regionId, placementId }) => {
        return (await regionPlacementsApi.deleteRegionExternalPlacement(regionId, placementId)).data;
    }
);

export const attachRegionExternalPlacementToEntrance = createAsyncThunk(
    'regions/attachExternalPlacementToEntrance',
    async ({ regionId, placementId, entranceId }) => {
        return (
            await regionPlacementsApi.attachRegionExternalPlacementToEntrance(
                regionId,
                placementId,
                entranceId
            )
        ).data;
    }
);

export const detachRegionExternalPlacementFromEntrance = createAsyncThunk(
    'regions/detachExternalPlacementFromEntrance',
    async ({ regionId, placementId, entranceId }) => {
        return (
            await regionPlacementsApi.detachRegionExternalPlacementFromEntrance(
                regionId,
                placementId,
                entranceId
            )
        ).data;
    }
);
