import React, { forwardRef, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
    Tooltip,
    CircularPreloader,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '../../common/themed';
import { getLineOfInterestDeleteId } from '../Mapping.selectors';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import { IconButton, RootRef, Grid } from '@material-ui/core';
import { selectIsLoading } from '../../../state-management/status/statusSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLineOfInterest } from '../../../state-management/mapping/line-of-interest/lineOfInterestActions';

const IconWrapper = forwardRef((props, ref) => (
    <RootRef rootRef={ref}>
        <IconButton {...props} />
    </RootRef>
));

export default function LineOfInterestActions(props) {
    const { mapId, lineId } = props;

    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

    const dispatch = useDispatch();

    const selectIsDeleting = useMemo(
        () => selectIsLoading({ type: deleteLineOfInterest.typePrefix, arg: { mapId, lineId } }),
        [lineId, mapId]
    );

    const isDeleting = useSelector(selectIsDeleting);

    const handleDelete = () => {
        dispatch(deleteLineOfInterest({ mapId, lineId }));
    };

    return (
        <>
            <Grid container alignItems={'center'} justifyContent={'space-evenly'}>
                <Tooltip content={'Delete line'} placement={'bottom'} useWrapper={false}>
                    <IconWrapper
                        id={getLineOfInterestDeleteId(lineId)}
                        aria-label={'Delete'}
                        onClick={() => setIsDeleteWarningOpen(true)}
                    >
                        <DeleteIcon />
                    </IconWrapper>
                </Tooltip>
            </Grid>

            {isDeleteWarningOpen && (
                <Dialog open closeable={false}>
                    <DialogContent>
                        <DialogContentText>
                            You are about to delete the line with the ID: "{lineId}". Are you sure?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button variant={'text'} onClick={() => setIsDeleteWarningOpen(false)}>
                            Cancel
                        </Button>

                        {isDeleting ? <CircularPreloader /> : <Button onClick={handleDelete}>Accept</Button>}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

LineOfInterestActions.propTypes = {
    mapId: PropTypes.string,
    lineId: PropTypes.string,
};
