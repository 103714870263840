import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import MapViewerLoadingIndicatorOverlay from '../../common/map-viewer/overlays/loading-indicators';
import {
    selectMappingAreLanesShown,
    selectMappingIsShowingEqualizedMap,
    selectMappingLaneIdsShownInBack,
    selectMappingLaneIdsShownInFront,
    selectMappingSelectedMapperId,
    selectMappingSelectedMapId,
} from '../../../state-management/user-inputs/mappingSlice';
import MapViewerLanesOverlay from '../../common/map-viewer/overlays/lanes';
import useLanes from '../../common/hooks/data-fetching/useLanes';

export const LanesOverlay = ({ disabled }) => {
    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const selectedMapperId = useSelector(selectMappingSelectedMapperId);
    const areLanesShown = useSelector(selectMappingAreLanesShown);
    const laneIdsShownInBack = useSelector(selectMappingLaneIdsShownInBack);
    const laneIdsShownInFront = useSelector(selectMappingLaneIdsShownInFront);
    const isShowingEqualizedMap = useSelector(selectMappingIsShowingEqualizedMap);

    const { data: lanes = [], isLoading } = useLanes({
        mapId: selectedMapId,
        useEqualizedMap: isShowingEqualizedMap,
    });

    const lanesToDisplay = useMemo(
        () =>
            lanes
                .filter(({ mapperId }) => !selectedMapperId || mapperId === selectedMapperId)
                .sort((l1, l2) => {
                    const l1BackIndex = laneIdsShownInBack.indexOf(l1.laneId);
                    const l2BackIndex = laneIdsShownInBack.indexOf(l2.laneId);
                    const l1FrontIndex = laneIdsShownInFront.indexOf(l1.laneId);
                    const l2FrontIndex = laneIdsShownInFront.indexOf(l2.laneId);

                    // Assuming there won't be more than 100000 lanes, give a big weight for being in the back or front.
                    // Based on this calculation, if l1 and l2 are both in back/front, they win by their indexes.
                    // If neither is in the back or front, their order doesn't matter.
                    const l1TotalIndex = -100000 * (l1BackIndex + 1) + 100000 * (l1FrontIndex + 1);
                    const l2TotalIndex = -100000 * (l2BackIndex + 1) + 100000 * (l2FrontIndex + 1);

                    return l1TotalIndex - l2TotalIndex;
                }),
        [laneIdsShownInBack, laneIdsShownInFront, lanes, selectedMapperId]
    );

    return (
        areLanesShown &&
        lanesToDisplay?.length > 0 && (
            <>
                <MapViewerLanesOverlay
                    disabled={disabled}
                    lanes={lanesToDisplay}
                    applyTransform={isShowingEqualizedMap}
                />
                {isLoading && <MapViewerLoadingIndicatorOverlay label={'Loading lanes...'} />}
            </>
        )
    );
};
