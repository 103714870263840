import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { MdExpandMore as ExpandMoreIcon } from 'react-icons/md';
import styled from 'styled-components';
import { Skeleton, Tooltip } from '../../common/themed';
import SpaceEditor from './SpaceEditor';
import useAllBuildingsInAllSpaces from '../../common/hooks/data-fetching/useAllBuildingsInAllSpaces';

const SummaryWrapper = styled.div`
    display: grid;
    grid-template-rows: repeat(3, 25px);
    grid-template-columns: repeat(3, minmax(100px, 500px));
    column-gap: 50px;

    & > div {
        display: inline-flex;
        align-items: center;

        span {
            overflow: hidden;
        }
    }
`;

const DetailTitle = styled(Typography).attrs({
    variant: 'subtitle1',
})``;

const DetailValue = styled(Typography).attrs({
    variant: 'subtitle2',
})`
    color: #7a7a7a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export default function SpaceDetailsCard(props) {
    const { space } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    useAllBuildingsInAllSpaces();
    const spaceDetails = [
        {
            title: 'Space Name',
            value: space.spaceDesc,
            withTooltip: false,
        },
        { title: 'Space ID', value: space.spaceId, withTooltip: false },
        {
            title: 'Buildings',
            value: space.buildingIds ? space.buildingIds.length : <Skeleton circle width={10} />,
            withTooltip: false,
        },
    ];

    return (
        <Accordion onChange={(event, value) => setIsExpanded(value)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <SummaryWrapper>
                    {spaceDetails.map(({ title }, index) => (
                        <DetailTitle key={`${title}-${index}`}>{title}</DetailTitle>
                    ))}

                    {spaceDetails.map(({ value, withTooltip }, index) => (
                        <div key={`${value}-${index}`}>
                            {withTooltip ? (
                                <Tooltip content={value}>
                                    <DetailValue>{value}</DetailValue>
                                </Tooltip>
                            ) : (
                                <DetailValue>{value}</DetailValue>
                            )}
                        </div>
                    ))}
                </SummaryWrapper>
            </AccordionSummary>

            {isExpanded && (
                <AccordionDetails>
                    <SpaceEditor space={space} />
                </AccordionDetails>
            )}
        </Accordion>
    );
}

SpaceDetailsCard.propTypes = {
    space: PropTypes.object.isRequired,
};
