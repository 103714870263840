import { createSelector } from 'reselect';
import { getActionKey } from '../utils';
import { status } from '../constants';

const { LOADING, ERROR } = status;

export const getIsLoadingObject = (state) => state.status.isLoading;

export const getErrorsObject = (state) => state.status.errors;

export const getIsLoading = (actions) =>
    createSelector(getIsLoadingObject, (isLoading) => {
        let actionsToSearch = Array.isArray(actions) ? actions : [actions];

        actionsToSearch = actionsToSearch
            .filter((a) => a) // Filter out undefined values
            .map((a) => (a.endsWith('_REQUEST') ? a.replace('_REQUEST', '') : a)); // Extract from each action only the name of the action itself (without "_REQUEST")

        return !!actionsToSearch.some((action) => isLoading[action] === true);
    });

export const getHasError = (actions) =>
    createSelector(getErrorsObject, (errors) => {
        let actionsToSearch = Array.isArray(actions) ? actions : [actions];

        actionsToSearch = actionsToSearch
            .filter((a) => a) // Filter out undefined values
            .map((a) => (a.endsWith('_REQUEST') ? a.replace('_REQUEST', '') : a)); // Extract from each action only the name of the action itself (without "_REQUEST")

        return !!actionsToSearch.some((action) => errors[action] !== undefined);
    });

export const selectStatus = (state) => state.status2;

const validateAction = (action) => {
    if (!action) {
        return { type: null, arg: null };
    } else if (typeof action === 'string') {
        return { type: action, arg: null };
    } else if (typeof action === 'object') {
        return action;
    }
};

export const selectIsLoading = (actions) =>
    createSelector(selectStatus, (status) => {
        let actionsArray = Array.isArray(actions) ? actions : [actions];

        return actionsArray
            .map(validateAction)
            .some(
                ({ type, arg }) =>
                    type &&
                    status[type]?.some(
                        (a) => (!arg || a.key === getActionKey(type, arg)) && a.currentState === LOADING
                    )
            );
    });

export const selectHasError = (actions) =>
    createSelector(selectStatus, (status) => {
        let actionsArray = Array.isArray(actions) ? actions : [actions];

        return actionsArray
            .map(validateAction)
            .some(
                ({ type, arg }) =>
                    type &&
                    status[type]?.some(
                        (a) => (!arg || a.key === getActionKey(type, arg)) && a.currentState === ERROR
                    )
            );
    });
