import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { selectHasError, selectIsLoading } from '../../../../state-management/status/statusSelectors';
import {
    getSelectAllEntrancesInFloor,
    selectEntranceById,
} from '../../../../state-management/entrance/entranceSelectors';
import { fetchAllEntrancesInBuilding } from '../../../../state-management/entrance/entranceActions';
import { getSelectAllEntrancesInBuilding } from '../../../../state-management/entrance/entranceSelectors';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';

/**
 * Hook that fetches entrances to the Redux store and returns either all or one of them, based on the provided options.
 * @param asObject - Return all entities as an object, with the IDs as the keys.
 * @param mapId - Map ID to fetch all entities from.
 * @param entranceId - Specific entrance ID to return.
 * @return {{isLoading: undefined, data: (undefined|*), hasError: undefined}}
 */
export default function useEntrances({ asObject = false, buildingId, floorId, entranceId } = {}) {
    const dispatch = useDispatch();

    const selectIsLoadingEntrances = useMemo(
        () => selectIsLoading(fetchAllEntrancesInBuilding.typePrefix),
        []
    );
    const selectHasErrorInEntrances = useMemo(
        () => selectHasError(fetchAllEntrancesInBuilding.typePrefix),
        []
    );
    const selectEntrancesInBuilding = useMemo(
        () => getSelectAllEntrancesInBuilding(buildingId),
        [buildingId]
    );
    const selectEntrancesInFloor = useMemo(() => getSelectAllEntrancesInFloor(floorId), [floorId]);
    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);

    const {
        asArray: entrancesInBuildingArray,
        asObject: entrancesInBuildingObject,
        spaceId,
    } = useSelector(selectEntrancesInBuilding);
    const { asArray: entrancesInFloorArray, asObject: entrancesInFloorObject } =
        useSelector(selectEntrancesInFloor);
    const entrance = useSelector((state) => selectEntranceById(state, entranceId));
    const isLoading = useSelector(selectIsLoadingEntrances);
    const hasError = useSelector(selectHasErrorInEntrances);

    useEffect(() => {
        // If there is a provided building ID, and we haven't fetched all of its entrances yet,
        // and we are not currently fetching them, fetch them
        if (
            !!selectedSpaceId &&
            !!buildingId &&
            !isLoading &&
            !hasError &&
            (!entrancesInBuildingArray ||
                entrancesInBuildingArray.some((m) => {
                    return !m || m.spaceId !== selectedSpaceId;
                }))
        ) {
            dispatch(fetchAllEntrancesInBuilding({ spaceId: selectedSpaceId, buildingId }));
        }
    }, [buildingId, dispatch, entrancesInBuildingArray, hasError, isLoading, spaceId]);

    let data;
    if (!!buildingId && !floorId && !entranceId) {
        data = asObject ? entrancesInBuildingObject : entrancesInBuildingArray;
    } else if (!!buildingId && !!floorId && !entranceId) {
        data = asObject ? entrancesInFloorObject : entrancesInFloorArray;
    } else if (!!buildingId && !!floorId && !!entranceId) {
        data = entrance;
    } else {
        data = null;
    }

    return { data, isLoading, hasError };
}
