import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from '../themed';
import { Button } from '../themed';

const Wrapper = styled.div``;
const DeleteWarning = styled.div`
    padding: 6px;
    display: flex;
    flex-direction: column;
`;
const ButtonsWrapper = styled.div`
    margin-block-start: 5px;

    ${Button} {
        min-width: 50px;
        padding-top: 2px;
        padding-bottom: 2px;

        :not(:last-child) {
            margin-inline-end: 8px;
        }
    }
`;
export const TooltipConfirmation = (props) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isDeleteWarningDisplayed, setIsDeleteWarningDisplayed] = useState(false);

    const tooltipProps = isDeleteWarningDisplayed
        ? {
              content: (
                  <DeleteWarning>
                      <span>Are you sure?</span>

                      <ButtonsWrapper>
                          <Button
                              size={'small'}
                              onClick={() => setIsTooltipVisible(false)}
                              variant={'outlined'}
                          >
                              No
                          </Button>

                          <Button size={'small'} onClick={props.onConfirm}>
                              Yes
                          </Button>
                      </ButtonsWrapper>
                  </DeleteWarning>
              ),
              enabled: true,
              trigger: 'manual',
              visible: isTooltipVisible,
              interactive: true,
              onHidden: () => {
                  setIsDeleteWarningDisplayed(false);
                  setIsTooltipVisible(undefined);
              },
          }
        : { content: props.content || '', trigger: 'mouseenter focus', enabled: true };

    useEffect(() => {
        if (props.onOpenChange) props.onOpenChange(isTooltipVisible);
        return () => {};
    }, [isTooltipVisible]);
    return (
        <Tooltip {...tooltipProps}>
            <Wrapper
                onClick={() => {
                    setIsDeleteWarningDisplayed(true);
                    setIsTooltipVisible(true);
                }}
            >
                {props.children}
            </Wrapper>
        </Tooltip>
    );
};
