import React from 'react';
import styled from 'styled-components';
import SideMenuSection from '../../../common/side-drawer/SideMenuSection';
import { Switch, TextField, SwitchLabel as Label } from '../../../common/themed';
import {
    selectMonitorAcceptedAccuracy,
    selectMonitorSelectedMapId,
    selectMonitorShowLocationAccuracy,
    setAcceptedAccuracy,
    setShowLocationAccuracy,
} from '../../../../state-management/user-inputs/monitorSlice';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { FormControlLabel, InputAdornment as MuiInputAdornment } from '@material-ui/core';
import { getAcceptedAccuracyId, getShowLocationAccuracyId } from '../../Monitor.selectors';

const InputAdornment = styled(MuiInputAdornment)`
    white-space: nowrap;
`;

export default function MonitorSideMenuMonitorSettingsSection() {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMonitorSelectedMapId);
    const acceptedAccuracy = useSelector(selectMonitorAcceptedAccuracy);
    const showLocationAccuracy = useSelector(selectMonitorShowLocationAccuracy);

    return (
        <>
            <SideMenuSection title={'Monitor Settings'}>
                <NumberFormat
                    id={getAcceptedAccuracyId()}
                    customInput={TextField}
                    value={acceptedAccuracy}
                    allowNegative={false}
                    onValueChange={({ floatValue }) => dispatch(setAcceptedAccuracy(floatValue ?? 1))}
                    suffix={'m'}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">Accepted Accuracy:</InputAdornment>,
                    }}
                    disabled={!selectedMapId}
                />

                <FormControlLabel
                    id={getShowLocationAccuracyId()}
                    control={
                        <Switch
                            checked={showLocationAccuracy}
                            onChange={(e) => dispatch(setShowLocationAccuracy(e.target.checked))}
                        />
                    }
                    label={<Label>Show location accuracy</Label>}
                    disabled={!selectedMapId}
                />
            </SideMenuSection>
        </>
    );
}
