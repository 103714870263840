import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Chip, Paper } from '@material-ui/core';
import Button from '../Button';
import { showErrorNotification } from '../../../../state-management/notification/notificationReducer';
import { getFilteredColumns, getFilteredOperators, getFilterType, getLabelByFilter, operators } from './utils';
import { BadgeContainer, FilterSelect, FilterTextField, Header, Row, Wrapper } from './styles';
import { getFilterToolbarColumnId, getFilterToolbarOperatorId, getFilterToolbarValueId } from './table.selectors';


export const FilterToolBar = ({ id, columns, onChange, defaultFilters: filters }) => {
    const [selectedFilter, setSelectedFilter] = React.useState();
    const [selectedOperator, setSelectedOperator] = React.useState();
    const [selectedValue, setSelectedValue] = React.useState();
    const inputValueRef = React.useRef(null);
    const dispatch = useDispatch(); 

    const filteredColumns = React.useMemo(() => getFilteredColumns(columns, filters), [columns, filters])
    const filteredOperators = getFilteredOperators(selectedFilter);

    useEffect(() => {
        if(selectedFilter && !selectedOperator && filteredOperators.length) {
            let defaultOperator = filteredOperators[0];
            if(selectedFilter.defaultOperatorId) {
                defaultOperator = operators[selectedFilter.defaultOperatorId - 1]
            }
            setSelectedOperator(defaultOperator);
        }
    }, [selectedFilter])

    useEffect(() => {
        if(inputValueRef.current && selectedOperator) {
            inputValueRef.current.focus();
        }
    } , [selectedOperator])

    const onClick = () => {
        if (!selectedFilter || !selectedOperator || !selectedValue) {
            return dispatch(showErrorNotification('please fill all fields'));
        }

        onChange([...filters, { column: selectedFilter, operator: selectedOperator, value: selectedValue }]);

        setSelectedFilter(null);
        setSelectedOperator(null);
        setSelectedValue(null);
    };
    const handleDelete = (chipToDelete) => {
        const updatedFilter = filters.filter((filter) => filter.column.field !== chipToDelete);
        onChange(updatedFilter);
    };
    

    return (
        <Wrapper>
            <Header>Filter</Header>
            <Row>
                <FilterSelect
                    id={getFilterToolbarColumnId(id)}
                    placeholder={'Select a Column...'}
                    options={filteredColumns}
                    getOptionLabel={(c) => c.title}
                    getOptionValue={(c) => c.field}
                    isClearable
                    value={selectedFilter ?? null}
                    onChange={(c) => {
                        setSelectedFilter(c);
                        setSelectedOperator(null);
                    }}
                />
                <FilterSelect
                    id={getFilterToolbarOperatorId(id)}
                    placeholder={'Operator'}
                    options={filteredOperators}
                    getOptionLabel={(c) => c.displaySign || c.sign}
                    getOptionValue={(c) => c.id}
                    isClearable
                    value={selectedOperator}
                    onChange={(c) => {
                        setSelectedOperator(c);
                        setSelectedValue(null);
                    }}
                    isDisabled={!selectedFilter}
                />
                <FilterTextField
                    id={getFilterToolbarValueId(id)}
                    name={'filter value'}
                    label={getLabelByFilter(selectedFilter)}
                    disabled={!selectedOperator}
                    value={selectedValue || ''}
                    onChange={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setSelectedValue(trimmedValue);
                    }}
                    type={getFilterType(selectedFilter)}
                    inputRef={inputValueRef}
                    placeholder={selectedFilter?.placeholder || ''}
                />
                <Button disabled={!selectedValue} onClick={onClick}>
                    Add
                </Button>
            </Row>
            <BadgeContainer style={{ flexWrap: 'wrap', width: '50%' }}>
                {filters.map(({ column, operator, value }, index) => {
                    return (
                        <Chip
                            key={index}
                            variant="outlined"
                            label={
                                <Row style={{ columnGap: '5px' }}>
                                    <strong>{column.title}</strong>
                                    <label>
                                        {operator.displaySign || operator.sign}
                                    </label>
                                    <label>{value}</label>
                                </Row>
                            }
                            onDelete={() => handleDelete(column.field)}
                        />
                    );
                })}
            </BadgeContainer>
        </Wrapper>
    );
};
