import axios from 'axios';
import logger from '../logger/logger';
import settings from '../clientSettings';

const log = logger.getLogger('AuthManager');

class AuthManager {
    constructor() {
        this._axios = axios.create({
            baseURL: settings.dashboardServer,
        });
    }

    async authenticateByCredentials(username, password) {
        return this._authenticateHelper('/api/auth/login', {
            username,
            password,
        });
    }

    async authenticateByApiKey(apiKey) {
        return this._authenticateHelper('/api/auth/loginByApiKey', {
            apiKey,
            username: 'nevermind',
        });
    }

    async _authenticateHelper(route, data) {
        try {
            log.info(`_authenticateHelper: calling ${route}`);

            const resp = await this._axios.post(route, data, {
                headers: { Accept: 'application/json' },
            });

            return { isAuthenticated: true, user: resp.data };
        } catch (ex) {
            log.error(`Failed to authenticate user against server: ${ex.message}`, ex);

            return { isAuthenticated: false, error: ex.response && ex.response.data };
        }
    }

    async logout() {
        try {
            await this._axios.post('api/auth/logout');
        } catch (ex) {
            log.error(`failed to logout from server: ${ex.message}, removing the expiredStorage anyway`);
        }
    }

    async keepSession() {
        try {
            await this._axios.get('/api/auth/keep-auth');
        } catch (ex) {
            log.error(`failed to keep session alive`);
            return { error: true, message: ex.message };
        }
    }
}

export default new AuthManager();
