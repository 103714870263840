import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './mapValidationEntityAdapter';
import { expireSession, logout } from '../../auth/authActions';
import {
    deleteAllValidationsInMap,
    fetchAllValidationsInMap,
    mapValidationsFetched,
} from './mapValidationActions';
import {
    mapValidationsAllUpdated,
    mapValidationsDeleted,
    mapValidationsSomeUpdated,
} from '../mappingEventsActions';

const { getInitialState, upsertMany, removeMany, setAll } = entityAdapter;

export const { reducer } = createSlice({
    name: 'mapValidations',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchAllValidationsInMap.pending]: (state) => {
            setAll(state, []);
        },

        [mapValidationsFetched]: (state, action) => {
            upsertMany(
                state,
                action.payload.data.map(({ mapValidationId, x, y, status, resolution }) => ({
                    mapValidationId,
                    x,
                    y,
                    status,
                    resolution,
                }))
            );
        },

        [deleteAllValidationsInMap.fulfilled]: (state) => {
            setAll(state, []);
        },

        [mapValidationsAllUpdated]: (state, action) => {
            setAll(
                state,
                action.payload.data.map(({ mapValidationId, x, y, status, resolution }) => ({
                    mapValidationId,
                    x,
                    y,
                    status,
                    resolution,
                }))
            );
        },

        [mapValidationsSomeUpdated]: (state, action) => {
            upsertMany(
                state,
                action.payload.data.map(({ mapValidationId, x, y, status, resolution }) => ({
                    mapValidationId,
                    x,
                    y,
                    status,
                    resolution,
                }))
            );
        },

        [mapValidationsDeleted]: (state, action) => {
            removeMany(
                state,
                action.payload.data.map(({ mapValidationId }) => mapValidationId)
            );
        },

        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
    },
});
