import { useEffect, useState } from "react"

export const useDetailPanel = ({tableData = [], deps = [tableData]}) => {
    const [data, setData] = useState(tableData)
    useEffect(() => {
        if(tableData) {
            setData(tableData?.map(d => ({...d, tableData: {
                showDetailPanel: !!d?.tableData?.showDetailPanel
            }})));
        }
    }, deps)

    return { data, setData };
}