import styled from 'styled-components';
import { Button, Select } from '../../common/themed';
import { InputLabel } from '@material-ui/core';

export const SpaceSelectBox = styled(Select)`
    display: inline-block;
    min-width: 250px;
    color: #333333;
`;
export const SpaceSelectedLabel = styled(InputLabel)`
    top: -10px;
`;
export const SaveButton = styled(Button)`
    margin-left: auto;
    & .themed-circular-preloader {
        color: white;
        width: 30px !important;
        height: 30px !important;
    }
`;
