import React, { memo, useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Portal } from '@material-ui/core';
import { MapViewerContext } from '../../MapViewerContext';
import { PIXEL_TO_METER, SCALE } from '../../../../../constants/mapViewerVariables';
import { getMapViewerScaleOverlayId } from './MapVieweScaleOverlay.selectors';

const SCALE_IN_METERS = 'data-scale-in-meters';
const scaleLineLength = 150;

const OverlayWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-block-end: 40px;
    margin-inline-start: 15px;
    pointer-events: none; // Prevent the whole wrapper from capturing pointer events
    position: absolute;
    bottom: 0;

    ::after {
        content: attr(${SCALE_IN_METERS});
        margin-inline-start: 10px;
    }
`;

const Scale = styled.svg`
    height: 20px;
    width: calc(${scaleLineLength}px + 10px);
`;

const ScaleLine = styled.line`
    stroke: #333333;
    stroke-width: 1.4;
    marker-start: url(#scale-end);
    marker-end: url(#scale-end);
`;

function MapViewerScaleOverlay() {
    const { containerRef, getCSSVariable } = useContext(MapViewerContext);

    const getCalculatedScale = useCallback(() => {
        const scale = parseFloat(getCSSVariable(SCALE));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));

        return `${Number(scaleLineLength / scale / pixelToMeter).toFixed(2)}M`;
    }, [getCSSVariable]);

    const handleWheel = useCallback(() => {
        const overlayContainer = document.getElementById(getMapViewerScaleOverlayId());

        if (overlayContainer) {
            overlayContainer.setAttribute(SCALE_IN_METERS, getCalculatedScale());
        }
    }, [getCalculatedScale]);

    useEffect(() => {
        const ref = containerRef.current;

        if (ref) {
            ref.addEventListener('wheel', handleWheel);

            return () => {
                ref.removeEventListener('wheel', handleWheel);
            };
        }
    }, [containerRef, handleWheel]);

    return (
        <Portal container={containerRef.current}>
            <OverlayWrapper
                id={getMapViewerScaleOverlayId()}
                {...{ [SCALE_IN_METERS]: getCalculatedScale() }}
            >
                <Scale>
                    <marker id={'scale-end'} markerWidth={10} markerHeight={10} refX={0} refY={5}>
                        <rect x={0} y={0} width={1} height={10} fill={'#333333'} />
                    </marker>

                    <ScaleLine x1={0} x2={scaleLineLength} y1={10} y2={10} />
                </Scale>
            </OverlayWrapper>
        </Portal>
    );
}

export default memo(MapViewerScaleOverlay);
