export const getMetadataEditorJsonEditorId = () => `metadata-editor-json-editor`;
export const getMetadataEditorFieldId = (fieldName) => `metadata-editor-${fieldName}-field`;
export const getMetadataEditorCategoryExpansionPanelId = (categoryName) =>
    `metadata-editor-${categoryName}-category`;
export const getMetadataEditorSwitchEditorWarningId = () => `metadata-editor-switch-editor-warning`;
export const getMetadataEditorSwitchEditorWarningCancelId = () =>
    `metadata-editor-switch-editor-warning-cancel`;
export const getMetadataEditorSwitchEditorWarningAcceptId = () =>
    `metadata-editor-switch-editor-warning-accept`;
export const getMetadataEditorSwitchToJsonEditorId = () => `metadata-editor-switch-to-json-editor`;
export const getMetadataEditorSwitchToOriientFormatId = () => `metadata-editor-switch-to-oriient-format`;
