import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { getMapViewerAreasOfInterestOverlayId } from './MapViewerAreasOfInterestOverlay.selectors';
import AreaOfInterest from './AreaOfInterest';


const OverlayWrapper = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none; // Prevent the whole SVG from capturing pointer events

    * {
        pointer-events: auto;
    }
`;

export default function MapViewerAreasOfInterestOverlay(props) {
    const {
        mapId,
        areas,
        onClick,
        onCancel,
        highlightedAreaIds = [],
        selectedAreaIds = [],
        enableDragging = false,
        enableResizing = false,
        readOnly,
        disabled,
        type
    } = props;

    return (
        <OverlayWrapper id={getMapViewerAreasOfInterestOverlayId()}>
            {areas.map((area) => (
                <AreaOfInterest
                    key={area?.areaId}
                    area={area}
                    mapId={mapId}
                    onClick={onClick}
                    onCancel={onCancel}
                    isHighlighted={selectedAreaIds.includes(area?.areaId)}
                    isSelected={selectedAreaIds.includes(area?.areaId)}
                    isDraggable={enableDragging}
                    isResizeable={enableResizing}
                    type={area?.type}
                    title={area?.title}
                    readOnly={readOnly}
                    disabled={disabled}
                    multiSelect={selectedAreaIds.length > 1}
                />
            ))}
        </OverlayWrapper>
    );
}

MapViewerAreasOfInterestOverlay.propTypes = {
    areas: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
    selectedAreaIds: PropTypes.arrayOf(PropTypes.string),
    enableDragging: PropTypes.bool,
    enableResizing: PropTypes.bool,
};
