import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectHasError, selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { fetchAllRegionsInSpace } from '../../../../state-management/region/regionActions';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import { getSelectAllRegionsWithInternalPlacementsInSpace } from '../../../../state-management/space/spaceSelectors';

/**
 * Hook that fetches all regions data into the Redux store.
 * If no `spaceId` is provided in the options, the selected space ID will be used by default.
 * @param options
 * @return {{isLoading, data: any, hasError}}
 */
export default function useAllRegionsInSpace(options = {}) {
    const { asObject = false, spaceId: providedSpaceId } = options;

    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const spaceId = providedSpaceId || selectedSpaceId;

    const selectRegionsWithPlacements = useMemo(
        () => getSelectAllRegionsWithInternalPlacementsInSpace(spaceId),
        [spaceId]
    );

    const { asArray: regionsArray, asObject: regionsObject } = useSelector(selectRegionsWithPlacements);
    const isFetching = useSelector(
        selectIsLoading({
            type: fetchAllRegionsInSpace.typePrefix,
            arg: { spaceId, withPlacements: true, withTriggerIds: true },
        })
    );
    const hasError = useSelector(
        selectHasError({
            type: fetchAllRegionsInSpace.typePrefix,
            arg: { spaceId, withPlacements: true, withTriggerIds: true },
        })
    );

    useEffect(() => {
        if (
            spaceId &&
            (!regionsArray || regionsArray.some((region) => !region)) &&
            !isFetching &&
            !hasError
        ) {
            dispatch(fetchAllRegionsInSpace({ spaceId, withPlacements: true, withTriggerIds: true }));
        }
    }, [dispatch, hasError, isFetching, spaceId, regionsArray]);

    return { data: asObject ? regionsObject : regionsArray, isLoading: isFetching, hasError };
}
