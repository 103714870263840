import React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import * as AmCore from '@amcharts/amcharts4/core';
import AmChartsThemesAnimated from '@amcharts/amcharts4/themes/animated';

//AmCore.useTheme(AmChartsThemesAnimated);


const ChartContainer = styled.div`
    height: ${props =>
        props.height.endsWith('%') ? props.height : `${props.height}px`};
    width: ${props =>
        props.width.endsWith('%') ? props.width : `${props.width}px`};
`;

export default class Chart extends React.Component {
   
    componentDidMount() {
        this.initializeChart();
    }

    shouldComponentUpdate(nextProps) {
        const currentData = JSON.stringify(this.props.config.data);
        const nextData = JSON.stringify(nextProps.config.data);

        return currentData !== nextData;
    }

    componentDidUpdate() {
        this.initializeChart();
    }

    componentWillUnmount() {
        const { id, onUnmount } = this.props;

        onUnmount(id);

        if (this.chart) {
            this.chart.dispose();
        }
    }

    initializeChart() {
        const { config, id, height, width, type, onInitialize } = this.props;
        this.chart = AmCore.createFromConfig(
            { ...config, height, width },
            id,
            type
        );

        onInitialize(id, this.chart);
    }

    render() {
        const {
            config,
            id,
            height,
            width,
            type,
            onInitialize,
            onUnmount,
            ...otherProps
        } = this.props;

        return (
            <ChartContainer
                id={id}
                height={String(height)}
                width={String(width)}
                {...otherProps}
            />
        );
    }
}

Chart.propTypes = {
    id: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.func.isRequired,
    onInitialize: PropTypes.func,
    onUnmount: PropTypes.func,
};

Chart.defaultProps = {
    height: '100%',
    width: '100%',
    onInitialize: () => {},
    onUnmount: () => {},
};
