import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    CircularPreloader,
    PulsePreloader,
} from '../../../common/themed';
import { Controller, useForm } from 'react-hook-form';
import useAllRegionsInSpace from '../../../common/hooks/data-fetching/useAllRegionsInSpace';
import ErrorGeneral from '../../../common/error-pages/ErrorGeneral';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import { Grid, Divider, InputAdornment, IconButton } from '@material-ui/core';
import useTriggers from '../../../common/hooks/data-fetching/useTriggers';
import MetadataEditor from '../common/metadata-editor/MetadataEditor';
import { object, string, array, number } from 'yup';
import { yupResolver } from '@hookform/resolvers';
import ExpandableSection from '../common/ExpandableSection';
import getTriggerMetadataOriientFormat from './TriggerMetadataOriientFormat';
import { getMetadataDefaultValues } from '../../../../utils/MetadataUtils';
import {
    getTriggerEditorDwellDurationId,
    getTriggerEditorEventTypesSelectId,
    getTriggerEditorTriggerIdId,
    getTriggerEditorTriggerNameId,
} from './TriggerEditorDialog.selectors';
import { Select } from '../../../common/themed';
import triggerEventTypes from '../../../../constants/triggerEventTypes';
import NumberFormat from 'react-number-format';
import { createTrigger, updateTrigger } from '../../../../state-management/trigger/triggerActions';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showInfoNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy as CopyIcon } from 'react-icons/fa';

const Section = styled.div`
    margin-block-end: 10px;
`;

const EventsWrapper = styled.div`
    margin-block-start: 10px;
    margin-block-end: 10px;
`;

const EventTypesSelect = styled(Select)`
    min-width: 250px;
    max-width: 420px;
`;

const DwellDurationField = styled(NumberFormat)`
    width: 110px;
`;

const schema = object().shape({
    triggerName: string().trim().required('This is required'),
    events: array()
        .of(
            object().shape({
                type: string().required(),
                msTime: number().notRequired(),
            })
        )
        .required('Please select events'),
    triggerMetadata: string(),
});

const eventTypeOptions = [
    {
        value: triggerEventTypes.ENTER_REGION,
        label: 'Enter region',
    },
    {
        value: triggerEventTypes.EXIT_REGION,
        label: 'Exit region',
    },
    {
        value: triggerEventTypes.DWELL_IN_REGION,
        label: 'Dwell in region',
    },
];

export default function TriggerEditorDialog(props) {
    const { triggerId, onClose } = props;

    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);

    const {
        data: trigger = null,
        isLoading: isLoadingTriggers,
        hasError,
    } = useTriggers({
        spaceId: selectedSpaceId,
        triggerId,
    });

    const { data: triggers = [] } = useTriggers({ spaceId: selectedSpaceId });
    const { data: regions = [] } = useAllRegionsInSpace({ spaceId: selectedSpaceId });

    const triggerMetadataOriientFormat = getTriggerMetadataOriientFormat({ triggerId, triggers, regions });

    const { register, handleSubmit, control, formState, errors } = useForm({
        defaultValues: {
            triggerName: trigger?.triggerName ?? ``,
            events: trigger?.events ?? [],
            triggerMetadata:
                trigger?.triggerMetadata && trigger?.triggerMetadata !== ''
                    ? trigger?.triggerMetadata
                    : JSON.stringify(getMetadataDefaultValues(triggerMetadataOriientFormat)),
        },
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const submitForm = async (data) => {
        const { triggerName, events, triggerMetadata } = data;

        const result = await dispatch(
            triggerId
                ? updateTrigger({ triggerId, triggerData: { triggerName, events, triggerMetadata } })
                : createTrigger({
                      triggerData: { triggerName, events, triggerMetadata },
                      spaceId: selectedSpaceId,
                  })
        );

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`Trigger ${triggerId ? 'updated' : 'created'} successfully.`));
        } else {
            dispatch(showErrorNotification(`Failed to ${triggerId ? 'update' : 'create'} trigger`));
        }

        onClose();
    };

    if (hasError) {
        return <ErrorGeneral />;
    }

    if (isLoadingTriggers) {
        return <PulsePreloader />;
    }

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth={'sm'}>
            <DialogTitle>Trigger Editor</DialogTitle>

            <form onSubmit={handleSubmit(submitForm)}>
                <DialogContent>
                    <Grid container alignItems={'center'} component={Section} spacing={5}>
                        <Grid item>
                            <TextField
                                id={getTriggerEditorTriggerIdId()}
                                label={'ID'}
                                value={trigger?.triggerId}
                                disabled
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CopyToClipboard
                                                text={trigger?.triggerId}
                                                onCopy={() =>
                                                    dispatch(showInfoNotification(`Trigger ID copied`))
                                                }
                                            >
                                                <IconButton onClick={(e) => e.stopPropagation()}>
                                                    <CopyIcon size={20} />
                                                </IconButton>
                                            </CopyToClipboard>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                id={getTriggerEditorTriggerNameId()}
                                name={'triggerName'}
                                label={'Name'}
                                inputRef={register}
                                defaultValue={trigger?.triggerName}
                                error={!!errors?.triggerName}
                                helperText={errors?.triggerName?.message}
                            />
                        </Grid>
                    </Grid>

                    <Controller
                        control={control}
                        name={'events'}
                        render={({ onChange, onBlur, value }) => {
                            const dwellDuration =
                                value?.find((e) => e.type === triggerEventTypes.DWELL_IN_REGION)?.msTime ??
                                null;

                            return (
                                <Grid container alignItems={'center'} spacing={2} component={EventsWrapper}>
                                    <Grid item>
                                        <EventTypesSelect
                                            id={getTriggerEditorEventTypesSelectId()}
                                            options={eventTypeOptions}
                                            value={eventTypeOptions.filter((opt) =>
                                                value.find((e) => e.type === opt.value)
                                            )}
                                            onChange={(events = []) => {
                                                onChange(
                                                    events?.map((e) => ({
                                                        type: e.value,
                                                        ...(e.value === triggerEventTypes.DWELL_IN_REGION
                                                            ? { msTime: dwellDuration || 1000 }
                                                            : {}),
                                                    })) ?? []
                                                );
                                            }}
                                            placeholder={'Event Types'}
                                            onBlur={onBlur}
                                            isMulti
                                            isOptionSelected={(event) =>
                                                value.find((e) => e.type === event.value)
                                            }
                                            innerProps={{
                                                error: !!errors?.events,
                                                helperText: errors?.events?.message,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <DwellDurationField
                                            id={getTriggerEditorDwellDurationId()}
                                            customInput={TextField}
                                            value={dwellDuration}
                                            label={'Dwell duration'}
                                            onValueChange={({ floatValue }) => {
                                                onChange(
                                                    value.map((e) =>
                                                        e.type === triggerEventTypes.DWELL_IN_REGION
                                                            ? { type: e.type, msTime: floatValue || 1 }
                                                            : e
                                                    )
                                                );
                                            }}
                                            suffix={'ms'}
                                            disabled={!dwellDuration}
                                        />
                                    </Grid>
                                </Grid>
                            );
                        }}
                    />

                    <Divider />

                    <ExpandableSection title={'Metadata'}>
                        <Controller
                            name={'triggerMetadata'}
                            control={control}
                            render={({ onChange, value }) => (
                                <MetadataEditor
                                    value={value}
                                    onChange={onChange}
                                    oriientFormat={triggerMetadataOriientFormat}
                                />
                            )}
                        />
                    </ExpandableSection>
                </DialogContent>

                <Divider />

                <DialogActions>
                    <Button variant={'text'} onClick={onClose}>
                        Cancel
                    </Button>

                    {formState.isSubmitting ? (
                        <CircularPreloader />
                    ) : (
                        <Button type={'submit'} disabled={false}>
                            Save
                        </Button>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
}

TriggerEditorDialog.propTypes = {
    triggerId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};
