import React from 'react';
import Chart from './Chart';
import getSelectors from '../Analytics.selectors';
import pieTimeInRegionsConfig from './config/Pie.TimeInRegions.config';
import * as AmCharts from '@amcharts/amcharts4/charts';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { MdClear as Clear } from 'react-icons/md';

const SelectedRegionsContainer = styled.div`
    text-align: center;
`;

const ResetButton = styled(IconButton)`
    color: coral;
`;

const ChartWrapper = styled.div``;

const RegionSelectedLabel = styled.label`
    color: #595959;
`;

export default class RegionDistributionPieChart extends React.Component {
    pieSlices = [];
    selectors = getSelectors(this.props.uniqueId).charts;

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const { data, selectedRegionId, selectedRegionLevel } = this.props;

        return (
            nextProps.data.isProcessed !== data.isProcessed ||
            nextProps.selectedRegionId !== selectedRegionId ||
            nextProps.selectedRegionLevel !== selectedRegionLevel
        );
    }

    componentDidUpdate(prevProps) {
        const { selectedRegionId, selectedRegionLevel, data } = this.props;

        // If the data changed
        if (prevProps.data.isProcessed !== data.isProcessed) {
            // Clear the array of slices, otherwise it will stack up with each re-process
            this.pieSlices = [];
        }

        // If user clicked on the same region that's already selected
        if (
            prevProps.selectedRegionId !== selectedRegionId ||
            prevProps.selectedRegionLevel !== selectedRegionLevel ||
            prevProps.data.isProcessed !== data.isProcessed
        ) {
            // Make sure all other pie slices than the one clicked aren't active,
            // and the clicked slice is active
            this.pieSlices.forEach((slice) => {
                slice.setState(
                    selectedRegionId &&
                        slice.dataItem.dataContext.regionId === selectedRegionId &&
                        slice.dataItem.dataContext.regionCategoryLevel === selectedRegionLevel
                        ? 'active'
                        : 'default'
                );
            });
        }
    }

    handleChartPieceClicked = (event) => {
        const { regionId, regionCategoryLevel } = event.target.dataItem.dataContext;
        const { onRegionSelected } = this.props;
        onRegionSelected(regionId, regionCategoryLevel);
    };

    handleChartPieceReady = (event) => {
        const { selectedRegionId } = this.props;

        // Save the chart piece
        this.pieSlices.push(event.target);

        // If this slice is the currently selected region, make it active
        event.target.setState(
            event.target.dataItem.dataContext.regionId === selectedRegionId ? 'active' : 'default'
        );
    };

    onChartInitialize = (id, chart) => {
        const { onInitialize } = this.props;
        this.chart = chart;
        onInitialize(id, chart);
    };

    render() {
        const { uniqueId, data, onUnmount, selectedRegionLevel, onRegionResetSelected } = this.props;
        const { PIE_CONTAINER_ID } = getSelectors(uniqueId).charts;
        const callbacks = {
            onChartPieceClicked: this.handleChartPieceClicked,
            onChartPieceReady: this.handleChartPieceReady,
        };

        const { isProcessed } = data;

        if (!isProcessed) {
            return null;
        }

        const regions = data.regions.filter((r) => r.regionCategoryLevel === selectedRegionLevel);

        const selectedRegion = data.regions.find(
            (r) => r.regionId === this.props.selectedRegionId
        )?.regionName;

        return (
            <ChartWrapper>
                <SelectedRegionsContainer>
                    <RegionSelectedLabel>
                        Selected: {this.props.selectedRegionId ? <b>{selectedRegion}</b> : 'None'}
                    </RegionSelectedLabel>
                    <ResetButton onClick={onRegionResetSelected}>
                        <Clear />
                    </ResetButton>
                </SelectedRegionsContainer>
                <Chart
                    width={'90%'}
                    height={'90%'}
                    id={PIE_CONTAINER_ID}
                    config={pieTimeInRegionsConfig({ regions }, callbacks)}
                    type={AmCharts.PieChart}
                    onInitialize={this.onChartInitialize}
                    onUnmount={onUnmount}
                />
            </ChartWrapper>
        );
    }
}

RegionDistributionPieChart.propTypes = {
    uniqueId: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    selectedRegionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedRegionLevel: PropTypes.number,
    onInitialize: PropTypes.func,
    onUnmount: PropTypes.func,
    onRegionResetSelected: PropTypes.func,
};

RegionDistributionPieChart.defaultProps = {
    onInitialize: () => {},
    onUnmount: () => {},
};
