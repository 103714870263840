import React from 'react';
import styled from 'styled-components';
import MuiSpeedDial from '@material-ui/lab/SpeedDial';
import MuiSpeedDialAction from '@material-ui/lab/SpeedDialAction';

export const SpeedDial = styled(({ classes, ...otherProps }) => (
    <MuiSpeedDial
        classes={{
            root: 'themed-speed-dial',
            fab: 'themed-speed-dial-fab',
            ...classes,
        }}
        {...otherProps}
    />
))`
    &.themed-speed-dial {
        margin-block-end: 25px;
        margin-inline-end: 25px;
        transition: all 200ms ease;

        &.pushed {
            margin-inline-end: 310px;
        }
    }

    & .themed-speed-dial-fab {
        background-color: #ff6200; // TODO color should come from theme
    }
`;

export const SpeedDialAction = MuiSpeedDialAction;
