import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { attachBanner, detachBanner, resetBanners } from '../../../state-management/banner/bannerReducer';
import {
    selectBuildingsSelectedBuildingId,
    selectBuildingsSelectedFloorId,
    selectBuildingsSelectedMapId,
} from '../../../state-management/user-inputs/buildingsSlice';
import {
    createPublishMapGrid,
    getMapGridMetadata,
    resetToManualMapping,
} from '../../../state-management/map/mapActions';
import { selectIsLoading as getSelectIsLoading } from '../../../state-management/status/statusSelectors';
import { selectMapEntities } from '../../../state-management/map/mapSelectors';
import { BANNER_IDS, getBannerByType } from '../../alert-banners/bannerTypes';
import { checkIfCanPublishMapping } from '../utils';
import { usePublishMapGrid } from './usePublishMapGrid';
import { useResetToManualMapping } from './useResetToManualMapping';
import { closeDialog } from '../../../state-management/general/generalReducer';
import { isRejected } from '../../../state-management/utils';
import { showErrorNotification } from '../../../state-management/notification/notificationReducer';
import { setIsGenerateMapGridOpen } from '../../../state-management/user-inputs/mappingSlice';

export const useMappingBanner = () => {
    const dispatch = useDispatch();
    const buildingId = useSelector(selectBuildingsSelectedBuildingId);
    const floorId = useSelector(selectBuildingsSelectedFloorId);
    const mapId = useSelector(selectBuildingsSelectedMapId);
    const mapsObject = useSelector(selectMapEntities);
    const currentMap = mapsObject[mapId] || {};
    const { gridMetadata } = currentMap;
    const currentMapFeature = currentMap?.features;
    const {
        canPublish = false,
        lastHealedDate = '',
        hasNoLatest,
    } = useMemo(() => {
        if (gridMetadata && currentMapFeature) {
            return checkIfCanPublishMapping(gridMetadata, currentMapFeature.selfHealingEnabled);
        }
        return {};
    }, [gridMetadata, currentMapFeature]);

    const onPublishSuccess = () => {
        dispatch(closeDialog());
        dispatch(detachBanner(BANNER_IDS.publish));
    };
    const { createPublishGrid } = usePublishMapGrid(onPublishSuccess);
    const onResetSuccess = async () => {
        dispatch(closeDialog());
        dispatch(detachBanner(BANNER_IDS.reset));
    };
    const { resetToManualMappingHandler } = useResetToManualMapping(onResetSuccess);

    const selectPublishLoading = useMemo(() => getSelectIsLoading(createPublishMapGrid.typePrefix), []);
    const selectResetLoading = useMemo(() => getSelectIsLoading(resetToManualMapping.typePrefix), []);
    const publishLoading = useSelector(selectPublishLoading);
    const resetLoading = useSelector(selectResetLoading);

    const handleGenerateMapGridDialogOpen = () => {
        dispatch(setIsGenerateMapGridOpen(true));
    };

    useEffect(() => {
        if (mapId) {
            dispatch(getMapGridMetadata({ mapId })).then((res) => {
                if (isRejected(res)) {
                    const errorMsg = 'Failed to get map grid metadata';
                    dispatch(showErrorNotification(errorMsg));
                }
            });
        }
        return () => {
            dispatch(resetBanners());
        };
    }, [mapId]);

    useEffect(() => {
        if (gridMetadata) {
            let bannerData;
            switch (true) {
                case currentMapFeature?.selfHealingEnabled:
                    bannerData = getBannerByType('reset', {
                        lastHealedDate,
                        buildingId,
                        floorId,
                        mapId,
                        onConfirm: async () => resetToManualMappingHandler({ buildingId, floorId, mapId }),
                    });
                    break;
                case canPublish:
                    bannerData = getBannerByType('publish', {
                        mapId,
                        onConfirm: async () => {
                            return createPublishGrid({ mapId });
                        },
                    });
                    break;
                default:
                    break;
            }
            if (bannerData) {
                dispatch(attachBanner(bannerData));
            }
            if (hasNoLatest) {
                const buttonProps = {};
                if (currentMapFeature.selfHealingEnabled) {
                    buttonProps.disabled = true;
                }
                dispatch(
                    attachBanner(
                        getBannerByType('hasNoLatest', {
                            mapId,
                            onClick: handleGenerateMapGridDialogOpen,
                            buttonProps,
                        })
                    )
                );
            }
        }
    }, [
        gridMetadata,
        buildingId,
        floorId,
        mapId,
        lastHealedDate,
        canPublish,
        currentMapFeature?.selfHealingEnabled,
    ]);

    useEffect(() => {
        if (!hasNoLatest) {
            dispatch(detachBanner(BANNER_IDS.noLatest));
        }
    }, [hasNoLatest]);

    return {
        selectedMapId: mapId,
        bannerLoading: publishLoading || resetLoading,
        features: currentMapFeature,
    };
};
