import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './floorEntityAdapter';
import { createBuilding, fetchBuildingFullData } from '../building/buildingActions';
import { createFloor, deleteFloor, updateFloor } from './floorActions';
import { createMap, deleteMap, fetchAllMapsInFloor } from '../map/mapActions';
import { expireSession, logout } from '../auth/authActions';
import { createEntrance, deleteEntrance, fetchAllEntrancesInBuilding } from '../entrance/entranceActions';

const { getInitialState, getSelectors, upsertMany, upsertOne, removeOne } = entityAdapter;

export const { reducer } = createSlice({
    name: 'floors',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchBuildingFullData.fulfilled]: (state, action) => {
            const { floors } = action.payload;
            upsertMany(
                state,
                floors.map(({ maps, ...floor }) => ({ ...floor, mapIds: maps.map((m) => m.mapId) }))
            );
        },

        [createBuilding.fulfilled]: (state, action) => {
            upsertMany(
                state,
                action.payload.floors.map(({ maps, ...floor }) => ({
                    ...floor,
                    mapIds: maps.map((m) => m.mapId),
                }))
            );
        },

        [createFloor.fulfilled]: (state, action) => {
            upsertMany(
                state,
                action.payload.floors.map(({ maps, ...floor }) => ({
                    ...floor,
                    mapIds: maps.map((m) => m.mapId),
                }))
            );
        },

        [updateFloor.fulfilled]: (state, action) => {
            upsertMany(
                state,
                action.payload.floors.map(({ maps, ...floor }) => ({
                    ...floor,
                    mapIds: maps.map((m) => m.mapId),
                }))
            );
        },

        [deleteFloor.fulfilled]: (state, action) => {
            removeOne(state, action.meta.arg.floorId);
        },

        [fetchAllMapsInFloor.fulfilled]: (state, action) => {
            const { floorId } = action.meta.arg;
            upsertOne(state, { floorId, mapIds: action.payload.map((m) => m.mapId) });
        },

        [createMap.fulfilled]: (state, action) => {
            const { floorId } = action.meta.arg;
            const { mapIds } = getSelectors().selectById(state, floorId);
            upsertOne(state, { floorId, mapIds: [...mapIds, action.payload.mapId] });
        },

        [deleteMap.fulfilled]: (state, action) => {
            const { floorId, mapId } = action.meta.arg;
            const { mapIds } = getSelectors().selectById(state, floorId);
            upsertOne(state, { floorId, mapIds: mapIds.filter((id) => id !== mapId) });
        },

        [fetchAllEntrancesInBuilding.fulfilled]: (state, action) => {
            upsertMany(
                state,
                action.payload.floors.map((f) => ({
                    floorId: f.floorId,
                    entranceIds: f.entranceIds,
                }))
            );
        },

        [createEntrance.fulfilled]: (state, action) => {
            const { floorId } = action.meta.arg;
            const { entranceId } = action.payload;
            const { entranceIds = [] } = getSelectors().selectById(state, floorId);
            upsertOne(state, { floorId, entranceIds: [...entranceIds, entranceId] });
        },

        [deleteEntrance.fulfilled]: (state, action) => {
            const { entranceId } = action.meta.arg;
            const allFloors = getSelectors().selectAll(state);
            upsertMany(
                state,
                allFloors.map((f) => ({
                    floorId: f.floorId,
                    entranceIds: f?.entranceIds?.filter((id) => id !== entranceId),
                }))
            );
        },

        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
    },
});
