import React, { useState, useLayoutEffect, useRef, useMemo } from 'react';
import { Table } from '../common/themed/Table/newTable';
import { columns } from './columns';
import useMapQuality from '../common/hooks/data-fetching/useMapQuality';
import ImagesPanel from '../common/themed/ImagesPanel';
import { Grid } from '@material-ui/core';
import { Tab, Tabs } from '../common/themed';
import { InvestigationModal } from '../modals/InvestigationModal/InvestigationModal';
import MapQualityApi from '../../api/MapQualityApi';
import styled from 'styled-components';
import SettingsPage from './settings';
import { isFulfilled } from '@reduxjs/toolkit';
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    & > *:first-child {
        margin-bottom: 20px;
    }
`;

export default function MapQuality() {
    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [currentTab, setCurrentTab] = useState(0);
    const targetRef = useRef();
    const rowRef = useRef(null);
    const [detailWidth, setDetailWidth] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const {
        records,
        count = 0,
        isLoading,
        hasError,
        getData,
        getCount,
        setData,
        publishLoading,
    } = useMapQuality({ columns });
    const rowStyleHandler = (rowData) => {
        const { trackingScore } = rowData;
        return {
            backgroundColor: trackingScore * 100 < 90 ? '#f51b1b1f' : 'white',
            fontWeight: 300,
        };
    };

    useLayoutEffect(() => {
        if (targetRef.current) {
            setWidth(targetRef.current.offsetWidth);
            setHeight(targetRef.current.offsetHeight);
        }
    }, [targetRef.current]);

    const onImageClicked = (rowData) => {
        setSelectedRow(rowData);
        openModal();
    };

    useLayoutEffect(() => {
        if (rowRef.current) {
            setDetailWidth(rowRef.current.clientWidth - 80);
        }
        return () => {};
    }, []);

    const handleExportFileRequest = async (filter, paginate, sort) => {
        const result = await getData({ filter, paginate, sort });
        if (isFulfilled(result)) {
            return { data: result.payload };
        }
        return { data: undefined };
    };

    const openModal = () => setShowModal(true);
    const closeModal = () => {
        setShowModal(false);
    };

    const renderTable = () => {
        return (
            <Grid ref={rowRef}>
                {showModal && (
                    <InvestigationModal
                        open={showModal}
                        handleClose={closeModal}
                        selectedRecord={selectedRow}
                        fetchMapQuality={getData}
                    />
                )}
                <Table
                    count={count}
                    title="Map Quality"
                    id="mapquality"
                    actions={{ fetchData: getData, fetchRecords: getCount }}
                    dependencies={[]}
                    columns={columns}
                    data={records}
                    tableOffsetHeight={205}
                    tableOffsetWidth={10}
                    options={{
                        rowStyle: rowStyleHandler,
                    }}
                    setData={setData}
                    handleExportFileRequest={handleExportFileRequest}
                    additionalMenuOptions={{
                        isGrouping: {
                            disabled: true,
                        },
                    }}
                    isLoading={isLoading || publishLoading}
                    detailPanel={({ rowData }) => {
                        return (
                            <ImagesPanel
                                data={rowData}
                                onImageClicked={() => onImageClicked(rowData)}
                                detailWidth={detailWidth}
                            />
                        );
                    }}
                />
            </Grid>
        );
    };

    const handleTabChange = (event, selectedTabIndex) => {
        setCurrentTab(selectedTabIndex);
    };

    const renderCurrentTab = () => {
        switch (currentTab) {
            case 0:
                return renderTable();
            case 1:
                return <SettingsPage />;
            default:
                return null;
        }
    };
    return (
        <Wrapper>
            <Tabs ref={rowRef} value={currentTab} onChange={handleTabChange}>
                <Tab label="DASHBOARD" />
                <Tab label="SETTINGS" />
            </Tabs>
            {renderCurrentTab()}
        </Wrapper>
    );
}
