export const getPlacementId = (placementId) => `map-viewer-region-placement-${placementId}`;

export const getRectangularPlacementTopRightResizeBulletId = (placementId) =>
    `map-viewer-region-placement-${placementId}-top-right-resize-bullet`;
export const getRectangularPlacementMiddleRightResizeBulletId = (placementId) =>
    `map-viewer-region-placement-${placementId}-middle-right-resize-bullet`;
export const getRectangularPlacementBottomRightResizeBulletId = (placementId) =>
    `map-viewer-region-placement-${placementId}-bottom-right-resize-bullet`;
export const getRectangularPlacementTopLeftResizeBulletId = (placementId) =>
    `map-viewer-region-placement-${placementId}-top-left-resize-bullet`;
export const getRectangularPlacementMiddleLeftResizeBulletId = (placementId) =>
    `map-viewer-region-placement-${placementId}-middle-left-resize-bullet`;
export const getRectangularPlacementBottomLeftResizeBulletId = (placementId) =>
    `map-viewer-region-placement-${placementId}-bottom-left-resize-bullet`;
export const getRectangularPlacementTopCenterResizeBulletId = (placementId) =>
    `map-viewer-region-placement-${placementId}-top-center-resize-bullet`;
export const getRectangularPlacementBottomCenterResizeBulletId = (placementId) =>
    `map-viewer-region-placement-${placementId}-bottom-center-resize-bullet`;

export const getCircularPlacementTopResizeBulletId = (placementId) =>
    `map-viewer-region-placement-${placementId}-top-resize-bullet`;
export const getCircularPlacementBottomResizeBulletId = (placementId) =>
    `map-viewer-region-placement-${placementId}-bottom-resize-bullet`;
export const getCircularPlacementLeftResizeBulletId = (placementId) =>
    `map-viewer-region-placement-${placementId}-left-resize-bullet`;
export const getCircularPlacementRightResizeBulletId = (placementId) =>
    `map-viewer-region-placement-${placementId}-right-resize-bullet`;
