import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { getRegionsListEntryId, getRegionsListEntryNameId } from '../../MapContent.selectors';
import {
    getSelectIsPlacementHighlighted,
    highlightInternalPlacement,
    selectMapContentSelectedInternalPlacementId,
    setSelectedInternalPlacementId,
    unhighlightInternalPlacement,
} from '../../../../state-management/user-inputs/mapContentSlice';
import { attachTriggerToRegion } from '../../../../state-management/trigger/triggerActions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCanEditRegions } from '../../../../state-management/auth/authSelectors';
import Tooltip from '../../../common/themed/Tooltip';
import ItemsListEntry from '../../../common/side-drawer/ItemsListEntry';
import RegionActions from './RegionActions';
import { getSelectAllTriggerIdsAttachedToRegion } from '../../../../state-management/trigger/triggerSelectors';
import { useImmer } from 'use-immer';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';

const RegionListEntryText = styled(Typography).attrs(() => ({ variant: 'subtitle1', noWrap: true }))`
    text-overflow: ellipsis;
    overflow-x: hidden;
`;

export default function RegionListEntry(props) {
    const { region, placement } = props;
    const { regionId, regionName } = region ?? {};
    const { placementId } = placement ?? {};

    const [{ isDraggedOver, isAllowed }, setDragState] = useImmer({
        isDraggedOver: false,
        isAllowed: false,
    });

    const dispatch = useDispatch();

    const selectIsHighlighted = useMemo(() => getSelectIsPlacementHighlighted(placementId), [placementId]);
    const selectAttachedTriggerIds = useMemo(
        () => getSelectAllTriggerIdsAttachedToRegion(regionId),
        [regionId]
    );

    const canEditRegions = useSelector(selectCanEditRegions);
    const selectedPlacementId = useSelector(selectMapContentSelectedInternalPlacementId);
    const isHighlighted = useSelector(selectIsHighlighted);
    const triggerIds = useSelector(selectAttachedTriggerIds) ?? [];

    const handleClick = () => {
        dispatch(
            setSelectedInternalPlacementId(
                placementId !== selectedPlacementId
                    ? { regionId, placementId }
                    : { regionId: null, placementId: null }
            )
        );
    };

    const handleDragEnter = (event) => {
        if (canEditRegions && event.dataTransfer.types.includes('oriient/trigger')) {
            const triggerId = event.dataTransfer.types
                .find((t) => t.startsWith('oriient/trigger_id'))
                ?.replace('oriient/trigger_id:', '');

            setDragState((state) => {
                state.isDraggedOver = true;
                state.isAllowed = !triggerIds.includes(triggerId); // If the region is already attached to the trigger
            });
        }
    };

    const handleDragLeave = (event) => {
        if (
            event.target.id === getRegionsListEntryId(regionId) &&
            canEditRegions &&
            event.dataTransfer.types.includes('oriient/trigger')
        ) {
            setDragState((state) => {
                state.isDraggedOver = false;
                state.isAllowed = false;
            });
        }
    };

    const handleDrop = async (event) => {
        if (canEditRegions && event.dataTransfer.types.includes('oriient/trigger')) {
            event.persist();
            event.preventDefault();

            setDragState((state) => {
                state.isDraggedOver = false;
                state.isAllowed = false;
            });

            const trigger = JSON.parse(event.dataTransfer.getData('oriient/trigger'));
            const triggerId = trigger?.triggerId;

            if (!triggerIds.includes(triggerId)) {
                const result = await dispatch(attachTriggerToRegion({ triggerId, regionId }));

                if (isFulfilled(result)) {
                    dispatch(showSuccessNotification(`Trigger was successfully attached to region.`));
                } else {
                    dispatch(showErrorNotification(`Failed to attach trigger to region.`));
                }
            }
        }
    };

    const handlePointerEnter = () => {
        if (selectedPlacementId !== placementId) {
            dispatch(highlightInternalPlacement(placementId));
        }
    };

    const handlePointerLeave = () => {
        if (selectedPlacementId !== placementId) {
            dispatch(unhighlightInternalPlacement(placementId));
        }
    };

    return (
        <Tooltip
            content={'This region is already attached to this trigger'}
            visible={isDraggedOver && !isAllowed}
            placement={'left'}
            useWrapper={false}
        >
            <ItemsListEntry
                id={getRegionsListEntryId(regionId)}
                isClickable
                isHighlighted={isHighlighted}
                isSelected={selectedPlacementId === placementId}
                onClick={handleClick}
                onPointerEnter={handlePointerEnter}
                onPointerLeave={handlePointerLeave}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                acceptableDragDataTypes = {['oriient/trigger']}
                onDragOver={(e) => {
                    e.preventDefault();
                }}
                onDrop={handleDrop}
                content={
                    <RegionListEntryText id={getRegionsListEntryNameId(regionId)}>
                        {regionName}
                    </RegionListEntryText>
                }
                actions={
                    <RegionActions regionId={regionId} regionName={regionName} placementId={placementId} />
                }
            />
        </Tooltip>
    );
}

RegionListEntry.propTypes = {
    region: PropTypes.object.isRequired,
    placement: PropTypes.object.isRequired,
};
