import React, { useEffect } from 'react';
import SideMenuSection from '../../common/side-drawer/SideMenuSection';
import { Skeleton, Select } from '../../common/themed';
import {
    selectMappingSelectedBuildingId,
    setSelectedBuildingId,
} from '../../../state-management/user-inputs/mappingSlice';
import { useDispatch, useSelector } from 'react-redux';
import useAllBuildingsInSpace from '../../common/hooks/data-fetching/useAllBuildingsInSpace';
import { getBuildingsSelectId } from '../Mapping.selectors';
import FetchError from '../../common/error-pages/FetchError';

export default function MappingSideMenuBuildingSection() {
    const dispatch = useDispatch();

    const selectedBuildingId = useSelector(selectMappingSelectedBuildingId);

    const { data: buildings, isLoading: isLoadingBuildings, hasError } = useAllBuildingsInSpace({
        asObject: true,
    });

    const handleBuildingSelect = (building) => {
        if (building.buildingId !== selectedBuildingId) {
            dispatch(setSelectedBuildingId(building.buildingId));
        }
    };

    useEffect(() => {
        // If the selected space has changed, select the first building by default
        if (!selectedBuildingId && buildings && Object.values(buildings).length === 1) {
            dispatch(setSelectedBuildingId(Object.keys(buildings)[0]));
        }
    }, [buildings, dispatch, selectedBuildingId]);

    return (
        <SideMenuSection title={'Building'}>
            {hasError ? (
                <FetchError />
            ) : isLoadingBuildings ? (
                <Skeleton height={30} />
            ) : (
                <Select
                    id={getBuildingsSelectId()}
                    options={buildings ? Object.values(buildings) : []}
                    placeholder={'Select a building...'}
                    getOptionValue={(b) => b.buildingId}
                    getOptionLabel={(b) => b.buildingName}
                    onChange={handleBuildingSelect}
                    value={buildings?.[selectedBuildingId] || null}
                />
            )}
        </SideMenuSection>
    );
}
