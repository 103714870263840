import React from 'react';
import SideMenuSection from '../common/side-drawer/SideMenuSection';
import FetchError from '../common/error-pages/FetchError';
import { Skeleton, SwitchLabel as Label, Switch } from '../common/themed';
import NoItemsMessage from '../common/side-drawer/NoItemsMessage';
import { FormControlLabel } from '@material-ui/core';
import { selectBuildingAreExitRegionsShown, setAreExitRegionsShown } from '../../state-management/user-inputs/buildingsSlice';
import { selectMappingSelectedMapId } from '../../state-management/user-inputs/mappingSlice';
import { useDispatch, useSelector } from 'react-redux';
import useAreasOfInterest from '../common/hooks/data-fetching/useAreasOfInterest';
import { useExitRegions } from '../common/hooks/data-fetching/useExitRegions';


export function BuildingsSideMenuExitRegionSection() {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const areExitRegionsShown = useSelector(selectBuildingAreExitRegionsShown);

    const {
        data: areas = [],
        isLoading,
        hasError,
    } = useExitRegions({
        mapId: selectedMapId,
    });

    if (!selectedMapId && !isLoading) {
        return null;
    }

    return (
        <SideMenuSection title={'Exit Regions'}>
            {hasError ? (
                <FetchError />
            ) : isLoading ? (
                <Skeleton height={50} width={230} />
            ) : areas.length === 0 ? (
                <NoItemsMessage>There are no exit regions in this map</NoItemsMessage>
            ) : (
                <FormControlLabel
                    label={<Label>Show exit regions</Label>}
                    disabled={isLoading}
                    control={
                        <Switch
                            checked={areExitRegionsShown}
                            onChange={() => dispatch(setAreExitRegionsShown(!areExitRegionsShown))}
                        />
                    }
                />
            )}
        </SideMenuSection>
    );
}
