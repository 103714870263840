import styled from 'styled-components';
export const ConfigWrapper = styled.div`
    display: flex;
    & > *:not(:last-child) {
        margin-right: 20px;
    }
    @media (max-width: 1600px) {
        display: flex;
    }
`;
