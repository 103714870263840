import { InputAdornment, makeStyles, Paper } from '@material-ui/core';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { createRegionInternalPlacements } from '../../../../state-management/region-placement/regionPlacementActions';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import { selectMapContentSelectedMapId } from '../../../../state-management/user-inputs/mapContentSlice';
import {
    Button,
    CircularPreloader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '../../../common/themed';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useState } from 'react';
import Draggable from 'react-draggable';
import { isFulfilled } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';

const DraggableDialog = (props) => (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
    </Draggable>
);
const useStyles = makeStyles({
    dialog: {
        position: 'absolute',

        top: 10,
    },
});

const OffsetTextField = styled(NumberFormat)``;

const OffsetInputContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const FormContainer = styled.div`
    display: flex;
    gap: 20px;
`;
const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > div {
        display: grid;
        grid-template-columns: 1fr 1fr;

        & > button {
            &.MuiToggleButton-root.Mui-selected {
                background-color: #ff6200;
                color: white;
            }
            padding: 6px;
            text-transform: unset;
        }
    }
`;
const StepLabel = styled.label`
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 13px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
`;
export const DuplicateWithOffsetDialog = ({
    open,
    onClose,
    onSubmitHandler,
    setPlacementsToDuplicate,
    placment,
    placmentsToDuplicate,
}) => {
    const { formState, watch, register, handleSubmit, errors, control, setValue } = useForm({
        defaultValues: { copies: 1, offsetX: 0, offsetY: 0 },
        mode: 'onChange',
    });

    const watchCopies = watch('copies');
    const watchOffsetX = watch('offsetX');
    const watchOffsetY = watch('offsetY');
    const classes = useStyles();
    const dispatch = useDispatch();
    const spaceId = useSelector(selectCommonSelectedSpaceId);
    const mapId = useSelector(selectMapContentSelectedMapId);
    const [step, setStep] = useState(0.1);

    const onSubmit = async ({ copies, offsetX, offsetY }) => {
        if (onSubmitHandler) {
            return onSubmitHandler();
        }
        const result = await dispatch(
            createRegionInternalPlacements({
                spaceId,
                mapId,
                originalRegion: placment,
                placements: placmentsToDuplicate,
            })
        );

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`${placment.regionName} was Duplicated ${watchCopies} times.`));
        } else {
            dispatch(showErrorNotification('Region was not duplicated'));
        }
        setPlacementsToDuplicate([]);
        onClose();
    };

    useEffect(() => {
        if (placment && open && placmentsToDuplicate.length === 0) {
            setPlacementsToDuplicate([
                {
                    ...placment,
                    isDraft: true,
                },
            ]);
        }
    }, [open]);

    useEffect(() => {
        if (open && placment && watchCopies > 0 && watchCopies <= 99) {
            if (placment.regionType === 'rectangular') {
                setPlacementsToDuplicate(
                    [...Array(watchCopies)].map((_, i) => ({
                        ...placment,
                        isDraft: true,
                        bottomLeft: {
                            x: placment.bottomLeft.x - watchOffsetX * (i + 1) * -1,
                            y: placment.bottomLeft.y + watchOffsetY * (i + 1) * -1,
                        },
                        topRight: {
                            x: placment.topRight.x - watchOffsetX * (i + 1) * -1,
                            y: placment.topRight.y + watchOffsetY * (i + 1) * -1,
                        },
                    }))
                );
            } else if (placment.regionType === 'circular') {
                setPlacementsToDuplicate(
                    [...Array(watchCopies)].map((_, i) => ({
                        ...placment,
                        isDraft: true,
                        center: {
                            x: placment.center.x - watchOffsetX * (i + 1) * -1,
                            y: placment.center.y + watchOffsetY * (i + 1) * -1,
                        },
                    }))
                );
            }
        }
    }, [watchCopies]);

    useEffect(() => {
        if (open && placment) {
            if (placment.regionType === 'rectangular') {
                setPlacementsToDuplicate((prev) =>
                    prev.map((_, i) => ({
                        ...placment,
                        isDraft: true,
                        bottomLeft: {
                            x: placment.bottomLeft.x - watchOffsetX * (i + 1) * -1,
                            y: placment.bottomLeft.y + watchOffsetY * (i + 1) * -1,
                        },
                        topRight: {
                            x: placment.topRight.x - watchOffsetX * (i + 1) * -1,
                            y: placment.topRight.y + watchOffsetY * (i + 1) * -1,
                        },
                    }))
                );
            } else if (placment.regionType === 'circular') {
                setPlacementsToDuplicate((prev) =>
                    prev.map((_, i) => ({
                        ...placment,
                        isDraft: true,
                        center: {
                            x: placment.center.x - (watchOffsetX * step) * (i + 1) * -1,
                            y: placment.center.y + (watchOffsetY * step) * (i + 1) * -1,
                        },
                    }))
                );
            }
        }
    }, [watchOffsetX, watchOffsetY, step]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                setPlacementsToDuplicate([]);
                onClose?.();
            }}
            fullWidth
            classes={{ paper: classes.dialog }}
            PaperComponent={DraggableDialog}
            hideBackdrop
        >
            <DialogTitle style={{ cursor: 'move' }}>Duplicate region with offset</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <FormContainer>
                        <TextField
                            type="number"
                            inputRef={register({
                                required: true,
                                min: { value: 1, message: 'Must be greater than 1' },
                                max: { value: 99, message: 'Must be under 99' },
                                setValueAs: (value) => +value,
                            })}
                            name="copies"
                            label="Number of Copies"
                            error={!!errors?.copies}
                            helperText={errors?.copies?.message}
                        />
                        <OffsetInputContainer>
                            <Controller
                                id={`region-offset-x`}
                                name={'offsetX'}
                                control={control}
                                rules={{
                                    required: true,
                                    min: { value: -99999, message: 'Must be greater than -99999' },
                                    max: { value: 99999, message: 'Must be under 99999' },
                                    setValueAs: (value) => +value,
                                }}
                                render={({ onChange, value }) => {
                                    return (
                                        <TextField
                                            type="number"
                                            label={'Region Offset'}
                                            error={!!errors.offsetX}
                                            helperText={errors.offsetX?.message}
                                            value={value}
                                            onChange={onChange}
                                            inputProps={{ step }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">X:</InputAdornment>
                                                ),
                                            }}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                id={`region-offset-y`}
                                name={'offsetY'}
                                control={control}
                                rules={{
                                    required: true,
                                    min: { value: -99999, message: 'Must be greater than -99999' },
                                    max: { value: 99999, message: 'Must be under 99999' },
                                    setValueAs: (value) => +value,
                                }}
                                render={({ onChange, value }) => (
                                    <TextField
                                        type="number"
                                        error={!!errors.offsetY}
                                        helperText={errors.offsetY?.message}
                                        value={value}
                                        onChange={onChange}
                                        inputProps={{ step }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">Y:</InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </OffsetInputContainer>
                        <StepContainer>
                            <StepLabel>Step</StepLabel>
                            <ToggleButtonGroup
                                value={step}
                                exclusive
                                onChange={(e, value) => value && setStep(value)}
                            >
                                <ToggleButton value={0.1}>10cm</ToggleButton>
                                <ToggleButton value={1}>1m</ToggleButton>
                            </ToggleButtonGroup>
                        </StepContainer>
                    </FormContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'text'}
                        onClick={(e) => {
                            setPlacementsToDuplicate([]);
                            onClose(e);
                        }}
                    >
                        Cancel
                    </Button>

                    {formState.isSubmitting ? (
                        <CircularPreloader />
                    ) : (
                        <Button type={'submit'} disabled={!formState.isValid}>
                            Duplicate
                        </Button>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
};
