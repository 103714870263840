import entityAdapter from './apiKeyEntityAdapter';

export const {
    selectById: selectApiKeyById,
    selectIds: selectApiKeyIds,
    selectEntities: selectApiKeyEntities,
    selectAll: selectAllApiKeys,
    selectTotal: selectTotalApiKeys,
} = entityAdapter.getSelectors((state) => state.apiKeys);

export const getLatestTermsAndConditionsGracePeriodDeadline = (state) => state.apiKeys.gracePeriodDeadline;

export const selectAreApiKeysFetched = (state) => state.apiKeys.isFetched;
