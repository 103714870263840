import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { selectHasError, selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { getSelectMappersInMap } from '../../../../state-management/map/mapSelectors';
import { fetchAllMappersInMap } from '../../../../state-management/mapping/mapper/mapperActions';
import { selectMapperById } from '../../../../state-management/mapping/mapper/mapperSelectors';

/**
 * Hook that fetches mappers to the Redux store and returns either all or one of them, based on the provided options.
 * @param asObject - Return all entities as an object, with the IDs as the keys.
 * @param mapId - Map ID to fetch all entities from.
 * @param mapperId - Specific mapper ID to return.
 * @return {{isLoading: *, data: *, hasError: *}}
 */
export default function useMappers({ asObject = false, mapId, mapperId } = {}) {
    const dispatch = useDispatch();

    const selectIsLoadingMappers = useMemo(
        () => selectIsLoading({ type: fetchAllMappersInMap.typePrefix, arg: mapId }),
        [mapId]
    );
    const selectHasErrorInMappers = useMemo(
        () => selectHasError({ type: fetchAllMappersInMap.typePrefix, arg: mapId }),
        [mapId]
    );
    const selectMappersInMap = useMemo(() => getSelectMappersInMap(mapId), [mapId]);

    const { asArray: mappersArray, asObject: mappersObject } = useSelector(selectMappersInMap);
    const mapper = useSelector((state) => selectMapperById(state, mapperId));
    const isFetching = useSelector(selectIsLoadingMappers);
    const hasError = useSelector(selectHasErrorInMappers);

    useEffect(() => {
        // If there is a provided map ID, and we haven't fetched all of its Mappers yet,
        // and we are not currently fetching them, fetch them
        if (mapId && !isFetching && !hasError && (!mappersArray || mappersArray.some((m) => !m))) {
            dispatch(fetchAllMappersInMap(mapId));
        }
    }, [dispatch, isFetching, hasError, mapId, mappersArray]);

    return {
        data: mapperId ? mapper : asObject ? mappersObject : mappersArray,
        isLoading: isFetching,
        hasError,
    };
}
