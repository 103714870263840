import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import GoogleMapsEntrance from './GoogleMapsEntrance';

function GoogleMapsEntrancesOverlay(props) {
    const { entrances, selectedEntranceId = null, highlightedEntranceIds = [] } = props;

    if (entrances?.length === 0) {
        return null;
    }

    return entrances?.map((entrance) => (
        <GoogleMapsEntrance
            key={entrance?.entranceId}
            entrance={entrance}
            isSelected={selectedEntranceId === entrance?.entranceId}
            isHighlighted={highlightedEntranceIds.includes(entrance?.entranceId)}
        />
    ));
}

GoogleMapsEntrancesOverlay.propTypes = {
    entrances: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedEntranceId: PropTypes.string,
    highlightedEntranceIds: PropTypes.arrayOf(PropTypes.string),
};

export default memo(GoogleMapsEntrancesOverlay);
