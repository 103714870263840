import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import SideMenuSection from '../../../common/side-drawer/SideMenuSection';
import { TextField, Skeleton, Switch, Tooltip, SwitchLabel as Label } from '../../../common/themed';
import {
    selectMapContentLockRegionsInPlace,
    selectMapContentRegionsFilter,
    selectMapContentSelectedMapId,
    selectMapContentSelectedRegionId,
    setLockRegionsInPlace,
    setRegionsFilter,
} from '../../../../state-management/user-inputs/mapContentSlice';
import { useDispatch, useSelector } from 'react-redux';
import ErrorGeneral from '../../../common/error-pages/ErrorGeneral';
import useAllRegionsInSpace from '../../../common/hooks/data-fetching/useAllRegionsInSpace';
import { selectCanReadRegions } from '../../../../state-management/auth/authSelectors';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import {
    getRegionsFilterId,
    getRegionsListId,
    getRegionsListLockRegionsSwitchId,
    getRegionsListShowInSelectedMapOnlyId,
    getRegionsSectionId,
} from '../../MapContent.selectors';
import RegionListEntry from './RegionListEntry';
import { FormControlLabel } from '@material-ui/core';
import { getSelectAllRegionsWithInternalPlacementsInMap } from '../../../../state-management/map/mapSelectors';
import { getSelectAllRegionsWithInternalPlacementsInSpace } from '../../../../state-management/space/spaceSelectors';

const RegionListWrapper = styled.ul`
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-block-start: 5px;
    margin-block-end: 0;
    padding: 0;
`;

export default function MapContentSideMenuRegionsSection() {
    const [showOnlyRegionsInSelectedMap, setShowOnlyRegionsInSelectedMap] = useState(true);

    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const selectedMapId = useSelector(selectMapContentSelectedMapId);
    const selectedRegionId = useSelector(selectMapContentSelectedRegionId);
    const regionsFilter = useSelector(selectMapContentRegionsFilter);
    const lockRegionsInPlace = useSelector(selectMapContentLockRegionsInPlace);
    const canReadRegions = useSelector(selectCanReadRegions);

    const { isLoading, hasError } = useAllRegionsInSpace({ spaceId: selectedSpaceId });

    const selectRegionsInSpace = useMemo(
        () => getSelectAllRegionsWithInternalPlacementsInSpace(selectedSpaceId),
        [selectedSpaceId]
    );

    const selectRegionsInMap = useMemo(
        () => getSelectAllRegionsWithInternalPlacementsInMap(selectedMapId),
        [selectedMapId]
    );

    const { asArray: regionsInSpace } = useSelector(selectRegionsInSpace);
    const { asArray: regionsInMap } = useSelector(selectRegionsInMap);

    if (hasError) {
        return <ErrorGeneral />;
    }

    if (!canReadRegions || (!regionsInSpace && !regionsInMap && !isLoading)) {
        return null;
    }

    return (
        <SideMenuSection id={getRegionsSectionId()} title={'Regions'}>
            <FormControlLabel
                id={getRegionsListShowInSelectedMapOnlyId()}
                control={
                    <Switch
                        checked={showOnlyRegionsInSelectedMap}
                        onChange={(e) => setShowOnlyRegionsInSelectedMap(e.target.checked)}
                    />
                }
                label={<Label>Show only regions of the selected map</Label>}
                disabled={isLoading}
            />

            <Tooltip content={'Select a map to enable this option'} enabled={!selectedMapId}>
                <FormControlLabel
                    id={getRegionsListLockRegionsSwitchId()}
                    control={
                        <Switch
                            checked={lockRegionsInPlace}
                            onChange={(e) => dispatch(setLockRegionsInPlace(e.target.checked))}
                        />
                    }
                    label={<Label>Lock regions in map</Label>}
                    disabled={isLoading || !selectedMapId}
                />
            </Tooltip>

            <TextField
                id={getRegionsFilterId()}
                label={'Search a region...'}
                value={regionsFilter}
                onChange={(e) => dispatch(setRegionsFilter(e.target.value))}
                fullWidth
                disabled={isLoading}
            />

            <RegionListWrapper id={getRegionsListId()}>
                {isLoading
                    ? Array.from({ length: 5 }, (_, index) => (
                          <Skeleton
                              key={`region-skeleton-${index}`}
                              height={46}
                              style={{ marginBlockEnd: 2 }}
                          />
                      ))
                    : (showOnlyRegionsInSelectedMap ? regionsInMap : regionsInSpace)
                          ?.filter(
                              ({ regionId, regionName }) =>
                                  regionName.toLowerCase().includes(regionsFilter.toLowerCase()) &&
                                  (!selectedRegionId || selectedRegionId === regionId)
                          )
                          ?.sort((r1, r2) => r1.regionName.localeCompare(r2.regionName))
                          ?.map(({ regionId, regionName, regionMetadata, ...placement }) => (
                              <RegionListEntry
                                  key={regionId}
                                  region={{ regionId, regionName, regionMetadata }}
                                  placement={placement}
                              />
                          ))}
            </RegionListWrapper>
        </SideMenuSection>
    );
}
