import Api from './Api';
import http from '../utils/httpClient';
import settings from '../clientSettings';
import { GET } from '../constants/httpMethods';

const { mappingRestApiUrl } = settings;

class MapValidationsApi extends Api {
    async fetchAllValidationsInMap(mapId, onData) {
        return await this.callStreamApi(
            GET,
            `${mappingRestApiUrl}/api/v1/maps/${mapId}/validation`,
            5000,
            onData
        );
    }
    async regenerateMap(mapId) {
        return await this.callApi((params) => {
            return http.mapping.v1.post(`/maps/${mapId}/regenerate`, {}, { params });
        });
    }
    async deleteAllValidationsInMap(mapId) {
        return await this.callApi((params) =>
            http.mapping.v1.delete(`/maps/${mapId}/validation`, { params })
        );
    }
}

export default new MapValidationsApi();
