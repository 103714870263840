import React from 'react';
import * as PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, Divider } from '@material-ui/core';
import { Button } from '../../common/themed';
import { useDispatch } from 'react-redux';
import { excludeApiKeyFromTermsAcceptance } from '../../../state-management/api-key/apiKeyActions';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../state-management/notification/notificationReducer';
import { isFulfilled } from '../../../state-management/utils';

export default function ApiKeyExclusionDialog(props) {
    const { isOpen, apiKeyId, apiKeyDesc, onClose } = props;

    const dispatch = useDispatch();

    const handleAccept = async () => {
        const result = await dispatch(excludeApiKeyFromTermsAcceptance(apiKeyId));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('API key has been excluded from terms acceptance.'));
        } else {
            dispatch(showErrorNotification('Failed to exclude API key from terms acceptance.'));
        }

        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogContent>
                <DialogContentText>
                    You are about to exclude the API key for {apiKeyDesc} from having to accept Oriient's
                    terms and conditions.
                    <br />
                    Are you sure?
                </DialogContentText>
            </DialogContent>

            <Divider />

            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleAccept}>Accept</Button>
            </DialogActions>
        </Dialog>
    );
}

ApiKeyExclusionDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    apiKeyId: PropTypes.string,
    apiKeyDesc: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};
