import React, { useEffect, useMemo } from 'react';
import SideMenuSection from '../../common/side-drawer/SideMenuSection';
import { Select, Skeleton } from '../../common/themed';
import {
    selectMappingSelectedBuildingId,
    selectMappingSelectedFloorId,
    setSelectedFloorId,
} from '../../../state-management/user-inputs/mappingSlice';
import { useDispatch, useSelector } from 'react-redux';
import useAllBuildingsInSpace from '../../common/hooks/data-fetching/useAllBuildingsInSpace';
import useAllFloorsInBuilding from '../../common/hooks/data-fetching/useAllFloorsInBuilding';
import { getFloorsSelectId } from '../Mapping.selectors';
import FetchError from '../../common/error-pages/FetchError';

export default function MappingSideMenuFloorSection() {
    const dispatch = useDispatch();

    const selectedBuildingId = useSelector(selectMappingSelectedBuildingId);
    const selectedFloorId = useSelector(selectMappingSelectedFloorId);

    const { data: buildings } = useAllBuildingsInSpace({
        asObject: true,
    });

    const { data: floors, isLoading, hasError } = useAllFloorsInBuilding({
        asObject: true,
        buildingId: selectedBuildingId,
    });

    const floorIds = useMemo(() => Object.values(floors || {}).map((f) => f.floorId), [floors]);

    const selectedBuilding = buildings?.[selectedBuildingId];
    const selectedFloor = floors?.[selectedFloorId];
    const primaryFloorId = selectedBuilding?.primaryFloorId;

    const handleFloorSelect = (floor) => {
        if (floor.floorId !== selectedFloorId) {
            dispatch(setSelectedFloorId(floor.floorId));
        }
    };

    useEffect(() => {
        if (!selectedFloorId) {
            if (floorIds.length === 1) {
                // If the selected building has only one floor, select it by default
                dispatch(setSelectedFloorId(floorIds[0]));
            } else if (primaryFloorId) {
                // Otherwise, select the primary floor by default
                dispatch(setSelectedFloorId(primaryFloorId));
            }
        }
    }, [selectedFloorId, floorIds, primaryFloorId, dispatch]);

    if (!floors && !isLoading) {
        return null;
    }

    return (
        <SideMenuSection title={'Floor'}>
            {hasError ? (
                <FetchError />
            ) : isLoading ? (
                <Skeleton height={30} />
            ) : (
                <Select
                    id={getFloorsSelectId()}
                    options={floors ? Object.values(floors).sort((a, b) => a.floorIndex - b.floorIndex) : []}
                    placeholder={'Select a floor...'}
                    getOptionValue={(f) => f.floorId}
                    getOptionLabel={(f) => f.floorName}
                    isDisabled={!floors}
                    onChange={handleFloorSelect}
                    value={selectedFloor || null}
                />
            )}
        </SideMenuSection>
    );
}
