export const columns = [
    { title: 'Building Name', field: 'buildingName', index: 0, fieldName: 'buildingName' },
    { title: 'Building ID', field: 'buildingId', index: 1, fieldName: 'buildingId' },
    { title: 'Map Name', field: 'mapName', index: 2, fieldName: 'mapName' },
    { title: 'Map ID', field: 'mapId', index: 3, fieldName: 'mapId' },
    { title: 'Self Validation', field: 'selfValidationEnabled', index: 4 },
    { title: 'Self Healing', field: 'selfHealingEnabled', index: 5 },
    {
        title: 'Auto Publish',
        field: 'autoPublishEnabled',
        index: 6,
    },
];
