import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    fetchBuildingFullData,
    updateBuilding,
    detachBuildingFromClient,
    attachBuildingToClient,
} from '../../../../state-management/building/buildingActions';
import { Button as MuiButton, CircularPreloader, Tooltip, Switch } from '../../../common/themed';
import { selectBuildingsSelectedBuildingId } from '../../../../state-management/user-inputs/buildingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, InputAdornment, Typography, FormControlLabel } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { TextField, SwitchLabel as Label } from '../../../common/themed';
import { MdLocationOn as LocationIcon } from 'react-icons/md';
import settings from '../../../../clientSettings';
import { selectCanEditBuildings } from '../../../../state-management/auth/authSelectors';
import ChangeBuildingPlacementDialog from '../../dialogs/change-building-placement';
import {
    getBuildingClientId,
    getBuildingDisplayNameId,
    getBuildingIdAutoFloorDetection,
    getBuildingIdId,
    getBuildingNameId,
} from '../../buildings.selectors';
import { isFulfilled, isRejected } from '../../../../state-management/utils';
import {
    showErrorNotification,
    showInfoNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import { useJsApiLoader } from '@react-google-maps/api';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy as CopyIcon } from 'react-icons/fa';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';
import { SlGraph as ValidationIcon } from 'react-icons/sl';
import useValidateRouting from '../../../common/hooks/data-fetching/useValidateRouting';
const { googleMapsApiKey } = settings;

const libraries = ['places'];

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    & > :not(:last-child) {
        margin-inline-end: 10px;
    }
`;

const Button = styled(MuiButton)`
    width: 100%;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;

    & > :not(:last-child) {
        margin-block-end: 20px;
    }
`;

export default function BuildingDetailsForm(props) {
    const { building } = props;
    const { buildingId, primaryFloorId } = building ?? {};

    const [isBuildingPlacementDialogOpen, setIsBuildingPlacementDialogOpen] = useState(false);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey,
        libraries,
    });

    const dispatch = useDispatch();
    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const canEditBuildings = useSelector(selectCanEditBuildings);
    const { onValidateRouting, isLoading: isValidating } = useValidateRouting();
    const {
        register,
        handleSubmit,
        errors,
        formState: { isValid, isSubmitting, isDirty },
        control,
        reset,
        setValue,
        getValues,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            buildingName: building?.buildingName ?? '',
            displayName: building?.displayName ?? '',
            clientBuildingId: building?.clientBuildingId ?? '',
            buildingToEnuRotation: building?.buildingToEnuRotation ?? 0,
            buildingOrigin: building?.buildingOrigin ?? { latitude: 0, longitude: 0, altitude: 0 },
            geofenceOrigin: building?.geofenceOrigin ?? { latitude: 0, longitude: 0 },
            geofenceRadius: building?.geofenceRadius ?? 100,
            autoFloorDetection: !!building.autoFloorDetection,
        },
    });

    const handleBuildingPlacementChange = ({
        buildingToEnuRotation,
        buildingOrigin,
        geofenceOrigin,
        geofenceRadius,
    }) => {
        setValue('buildingToEnuRotation', buildingToEnuRotation, { shouldDirty: true });
        setValue('buildingOrigin.latitude', buildingOrigin.latitude, { shouldDirty: true });
        setValue('buildingOrigin.longitude', buildingOrigin.longitude, { shouldDirty: true });
        setValue('buildingOrigin.altitude', 0, { shouldDirty: true });
        setValue('geofenceOrigin.latitude', geofenceOrigin.latitude, { shouldDirty: true });
        setValue('geofenceOrigin.longitude', geofenceOrigin.longitude, { shouldDirty: true });
        setValue('geofenceRadius', geofenceRadius, { shouldDirty: true, shouldValidate: true });

        setIsBuildingPlacementDialogOpen(false);
    };

    const onBuildingPlacementUpdated = async (data) => {
        const clientBuildingId = getValues('clientBuildingId');
        await submitForm({ ...data, clientBuildingId });
    };

    const submitForm = async (building) => {
        const { clientBuildingId, ...buildingData } = building;
        const result = await dispatch(
            updateBuilding({ buildingId, buildingData: { ...buildingData, primaryFloorId } })
        );

        if (clientBuildingId) {
            const attachedClientResult = await dispatch(
                attachBuildingToClient({ buildingId, clientBuildingId })
            );
            if (isRejected(attachedClientResult)) {
                return dispatch(showErrorNotification('Failed to attach building to client'));
            }
        } else {
            const detachedClientResult = await dispatch(detachBuildingFromClient({ buildingId }));
            if (isRejected(detachedClientResult)) {
                return dispatch(showErrorNotification('Failed to detach building from client'));
            }
        }

        if (isFulfilled(result)) {
            if (isBuildingPlacementDialogOpen) {
                setIsBuildingPlacementDialogOpen(false);
            }
            await dispatch(fetchBuildingFullData({ spaceId: selectedSpaceId, buildingId }));
            reset({ ...building, buildingToEnuRotation: building?.buildingToEnuRotation ?? 0 });
            dispatch(showSuccessNotification('Building updated successfully.'));
        } else {
            dispatch(showErrorNotification(`Failed to update building`));
        }
    };

    useEffect(() => {
        if (building) {
            reset({
                buildingName: building?.buildingName ?? '',
                displayName: building?.displayName ?? '',
                clientBuildingId: building?.clientBuildingId ?? '',
                buildingToEnuRotation: building?.buildingToEnuRotation ?? 0,
                buildingOrigin: building?.buildingOrigin ?? {
                    latitude: 0,
                    longitude: 0,
                    altitude: 0,
                },
                geofenceOrigin: building?.geofenceOrigin ?? {
                    latitude: 0,
                    longitude: 0,
                },
                geofenceRadius: building?.geofenceRadius ?? 100,
                autoFloorDetection: building.autoFloorDetection,
            });
        }
    }, [reset, building]);

    return (
        <>
            <Form onSubmit={handleSubmit(submitForm)}>
                <TextField
                    id={getBuildingIdId()}
                    label={'ID'}
                    value={buildingId}
                    disabled
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <CopyToClipboard
                                    text={buildingId}
                                    onCopy={() => dispatch(showInfoNotification(`Building ID copied`))}
                                >
                                    <IconButton onClick={(e) => e.stopPropagation()}>
                                        <CopyIcon size={20} />
                                    </IconButton>
                                </CopyToClipboard>
                            </InputAdornment>
                        ),
                    }}
                />

                <Controller
                    id={getBuildingNameId()}
                    name={'buildingName'}
                    control={control}
                    rules={{ required: 'This is required' }}
                    render={({ onChange, value }) => (
                        <TextField
                            label={'Name'}
                            error={!!errors.buildingName}
                            helperText={errors.buildingName?.message}
                            value={value}
                            onChange={onChange}
                            disabled={!canEditBuildings}
                        />
                    )}
                />

                <Controller
                    id={getBuildingDisplayNameId()}
                    name={'displayName'}
                    control={control}
                    rules={{ required: 'This is required' }}
                    render={({ onChange, value }) => (
                        <TextField
                            label={'Display Name'}
                            error={!!errors.displayName}
                            helperText={errors.displayName?.message}
                            value={value}
                            onChange={onChange}
                            disabled={!canEditBuildings}
                        />
                    )}
                />

                <Controller
                    id={getBuildingClientId()}
                    name={'clientBuildingId'}
                    control={control}
                    render={({ onChange, value }) => (
                        <TextField
                            label={'Client Building ID'}
                            error={!!errors.clientBuildingId}
                            helperText={errors.clientBuildingId?.message}
                            value={value}
                            onChange={onChange}
                            disabled={!canEditBuildings}
                        />
                    )}
                />
                <Controller
                    id={getBuildingIdAutoFloorDetection()}
                    name="autoFloorDetection"
                    control={control}
                    render={({ onChange, value }) => {
                        const val = value;
                        return (
                            <FormControlLabel
                                label={<Label>Auto Floor Detection</Label>}
                                control={
                                    <Switch
                                        checked={val}
                                        value={val}
                                        disabled={!canEditBuildings}
                                        onChange={(e) => {
                                            return onChange(e.target.checked);
                                        }}
                                    />
                                }
                            />
                        );
                    }}
                />

                <input hidden name={'buildingToEnuRotation'} ref={register({ valueAsNumber: true })} />
                <input hidden name={'buildingOrigin.latitude'} ref={register({ valueAsNumber: true })} />
                <input hidden name={'buildingOrigin.longitude'} ref={register({ valueAsNumber: true })} />
                <input
                    hidden
                    name={'buildingOrigin.altitude'}
                    ref={register({ valueAsNumber: true })}
                    defaultValue={0}
                />
                <input hidden name={'geofenceOrigin.latitude'} ref={register({ valueAsNumber: true })} />
                <input hidden name={'geofenceOrigin.longitude'} ref={register({ valueAsNumber: true })} />
                <input hidden name={'geofenceRadius'} ref={register({ valueAsNumber: true })} />

                <Tooltip
                    content={
                        <>
                            <Typography align={'center'}>Due to an error,</Typography>
                            <Typography align={'center'}>this is not available at the moment.</Typography>
                        </>
                    }
                    enabled={!!loadError}
                >
                    <Button
                        variant={'outlined'}
                        disabled={!canEditBuildings || !!loadError || !isLoaded}
                        onClick={() => setIsBuildingPlacementDialogOpen(true)}
                        startIcon={<LocationIcon size={20} />}
                    >
                        Change placement
                    </Button>
                </Tooltip>
                {isValidating ? (
                    <CircularPreloader />
                ) : (
                    <Button
                        startIcon={<ValidationIcon size={20} />}
                        variant={'outlined'}
                        onClick={onValidateRouting}
                    >
                        Validate Routing
                    </Button>
                )}
                {canEditBuildings && isDirty && (
                    <ButtonsWrapper>
                        <Button variant={'text'} onClick={() => reset()} disabled={isSubmitting}>
                            Reset
                        </Button>

                        {isSubmitting ? (
                            <CircularPreloader />
                        ) : (
                            <Button type={'submit'} disabled={!isValid}>
                                Save
                            </Button>
                        )}
                    </ButtonsWrapper>
                )}
            </Form>

            {isBuildingPlacementDialogOpen && (
                <ChangeBuildingPlacementDialog
                    onAccept={onBuildingPlacementUpdated}
                    onClose={() => setIsBuildingPlacementDialogOpen(false)}
                    buildingId={selectedBuildingId}
                />
            )}
        </>
    );
}
