import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import ClientMarker from './ClientMarker';
import { getMapViewerLiveMonitorOverlayId } from './MapViewerLiveMonitorOverlay.selectors';

const LiveMonitorOverlayWrapper = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
`;

export default function MapViewerLiveMonitorOverlay(props) {
    const { clients, acceptedAccuracy, showLocationAccuracy = true } = props;

    if (!clients) {
        return null;
    }

    return (
        <LiveMonitorOverlayWrapper id={getMapViewerLiveMonitorOverlayId()}>
            {clients.map((client) => (
                <ClientMarker
                    key={client?.clientId}
                    client={client}
                    acceptedAccuracy={acceptedAccuracy}
                    showLocationAccuracy={showLocationAccuracy}
                />
            ))}
        </LiveMonitorOverlayWrapper>
    );
}

MapViewerLiveMonitorOverlay.propTypes = {
    clients: PropTypes.arrayOf(PropTypes.object).isRequired,
    acceptedAccuracy: PropTypes.number.isRequired,
    showLocationAccuracy: PropTypes.bool,
};
