import React, { useMemo } from 'react';
import MapViewer from '../common/map-viewer';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectBuildingIsSettingMapOffset,
    selectBuildingsAreEntrancesShown,
    selectBuildingsAreRegionExternalPlacementsShown,
    selectBuildingsHighlightedEntranceIds,
    selectBuildingsIsCreatingEntrance,
    selectBuildingsIsCreatingRegionExternalPlacement,
    selectBuildingsIsGoogleMapsViewOpen,
    selectBuildingsIsGoogleMapsViewToggleDisabled,
    selectBuildingsIsSelectedEntranceEditable,
    selectBuildingsIsSelectedExternalPlacementEditable,
    selectBuildingsIsSettingPixelToMeter,
    selectBuildingsIsSideDrawerOpen,
    selectBuildingsSelectedBuildingId,
    selectBuildingsSelectedEntranceId,
    selectBuildingsSelectedExternalPlacementId,
    selectBuildingsSelectedFloorId,
    selectBuildingsSelectedMapId,
    selectMapImagesChecked,
    setCalculatedPixelToMeter,
    setIsCreatingEntrance,
    setIsCreatingRegionExternalPlacement,
    setIsGoogleMapsViewOpen,
    setIsSettingPixelToMeter,
    setSelectedExternalPlacementId,
    setSelectedExternalPlacementPoints,
    setCalculatedMapOffset,
    setIsSettingMapOffset,
    setIsExitRegionOpen,
    selectBuildingsIsCreatingExitRegion,
    selectBuildingIsCoordinatesConversionOpen,
    setIsCoordinatesConverstionOpen,
} from '../../state-management/user-inputs/buildingsSlice';
import MapViewerPixelToMeterMeasureOverlay from '../common/map-viewer/overlays/pixel-to-meter-measure';
import MapViewerCreateNewItemOverlay from '../common/map-viewer/overlays/create-new-item';
import { GiEntryDoor as EntranceIcon } from 'react-icons/gi';
import { ImExit as RegionIcon } from 'react-icons/im';
import { BiShapePolygon as PolygonIcon } from 'react-icons/bi';
import MapViewerEntranceCreationOverlay from '../common/map-viewer/overlays/entrance-creation';
import { getSelectIsThereMainEntranceInFloor } from '../../state-management/entrance/entranceSelectors';
import MapViewerLoadingIndicatorOverlay from '../common/map-viewer/overlays/loading-indicators';
import useEntrances from '../common/hooks/data-fetching/useEntrances';
import MapViewerEntrancesOverlay from '../common/map-viewer/overlays/entrances';
import MapViewerGoogleMapsViewToggleOverlay from '../common/map-viewer/overlays/google-maps-view-toggle';
import {
    selectCanEditEntrances,
    selectCanEditRegionExternalPlacements,
} from '../../state-management/auth/authSelectors';
import MapViewerGoogleMapsOverlay from '../common/map-viewer/overlays/google-maps';
import useMapsFullData from '../common/hooks/data-fetching/useMapsFullData';
import useAllBuildingsInSpace from '../common/hooks/data-fetching/useAllBuildingsInSpace';
import { selectCommonSelectedSpaceId } from '../../state-management/user-inputs/commonSlice';
import GoogleMapsRegionExternalPlacementCreation from '../common/map-viewer/overlays/google-maps/region-external-placement-creation';
import GoogleMapsRegionExternalPlacements from '../common/map-viewer/overlays/google-maps/region-external-placements';
import useAllFloorsInBuilding from '../common/hooks/data-fetching/useAllFloorsInBuilding';
import { getSelectAllRegionsWithExternalPlacementsInBuilding } from '../../state-management/region/regionSelectors';
import GoogleMapsEntrancesOverlay from '../common/map-viewer/overlays/google-maps/entrances';
import GoogleMapsMapImageOpacityOverlay from '../common/map-viewer/overlays/google-maps/map-image-opacity';
import GoogleMapsAddressAndCoordinatesOverlay from '../common/map-viewer/overlays/google-maps/address-and-coordinates';
import { getDefaultMapImage, getImagesWithoutDefault } from '../../utils/mapImages.helper';
import styled from 'styled-components';
import MapViewerMapOriginPickerOverlay from '../common/map-viewer/overlays/map-origin-picker';
import { selectMappingSelectedMapId } from '../../state-management/user-inputs/mappingSlice';
import MapViewerAreaOfInterestCreationOverlay from '../common/map-viewer/overlays/areas-of-interest-creation';
import { AreasOfInterestOverlay } from '../mapping/overlays/AreasOfInterestOverlay';
import { CoordinatesConversionDrawer } from './side-menu/coordinates-conversion/CoordinatesConversionDrawer';
import MouseCoordinatesAndZoom from '../common/map-viewer/overlays/mouse-coordinates-and-zoom';
import CoordinatesConversionOverlay from '../common/map-viewer/overlays/coordinates-conversion';
import {
    clearCalculate,
    createCoordinatesConversionPoints,
} from '../../state-management/coordinates-conversion/coordinatesConversionReducer';
import { CoordinatesOverlay } from './side-menu/coordinates-conversion/CoordinatesOverlay';
import { useCoordinatesConversion } from '../common/hooks/data-fetching/useCoordinatesConversions';
import { fetchAllCoordinatesConversionInMap } from '../../state-management/coordinates-conversion/coordinatesConversionActions';
import { NavgraphErrorsViewer } from '../common/map-viewer/overlays/navgraph-errors';
import useValidateRouting from '../common/hooks/data-fetching/useValidateRouting';
import { SignMarksOverlay } from '../mapping/overlays/SignMarkOverlay';

function CreateNewItemOverlay() {
    const dispatch = useDispatch();

    const canEditEntrances = useSelector(selectCanEditEntrances);
    const canEditRegionExternalPlacements = useSelector(selectCanEditRegionExternalPlacements);
    const isCreatingEntrance = useSelector(selectBuildingsIsCreatingEntrance);
    const isCreatingPlacement = useSelector(selectBuildingsIsCreatingRegionExternalPlacement);
    const isSideDrawerOpen = useSelector(selectBuildingsIsSideDrawerOpen);

    const actions = useMemo(() => {
        const actions = [];

        if (canEditEntrances) {
            actions.push({
                label: 'Entrance',
                icon: <EntranceIcon size={25} />,
                onClick: () => dispatch(setIsCreatingEntrance(true)),
            });
        }

        if (canEditRegionExternalPlacements) {
            actions.push({
                label: 'External Region',
                canAccess: false,
                icon: <PolygonIcon size={25} />,
                onClick: () => dispatch(setIsCreatingRegionExternalPlacement(true)),
            });
        }

        actions.push({
            label: 'Exit Region',
            onClick: () => dispatch(setIsExitRegionOpen(true)),
            icon: <RegionIcon size={25} />,
        });

        return actions;
    }, [canEditEntrances, canEditRegionExternalPlacements, dispatch]);

    return (
        <MapViewerCreateNewItemOverlay
            actions={actions}
            isHidden={isCreatingEntrance || isCreatingPlacement}
            isPushed={isSideDrawerOpen}
        />
    );
}

function PixelToMeterMeasureOverlay() {
    const dispatch = useDispatch();

    const isSettingPixelToMeter = useSelector(selectBuildingsIsSettingPixelToMeter);

    return (
        isSettingPixelToMeter && (
            <MapViewerPixelToMeterMeasureOverlay
                onSubmit={(p2m) => dispatch(setCalculatedPixelToMeter(p2m))}
                onCancel={() => dispatch(setIsSettingPixelToMeter(false))}
            />
        )
    );
}
function NavgraphErrorsOverlay() {
    const dispatch = useDispatch();

    const { errors, shouldShowErrors } = useValidateRouting();

    console.log(shouldShowErrors);
    if (!shouldShowErrors) return null;
    return <NavgraphErrorsViewer errors={errors} />;
}
function CoordinatesConversionPickerOverlay() {
    const dispatch = useDispatch();
    const isCoordinatesConversionOpen = useSelector(selectBuildingIsCoordinatesConversionOpen);
    const selectedMapId = useSelector(selectBuildingsSelectedMapId);
    const buildingId = useSelector(selectBuildingsSelectedBuildingId);
    const floorId = useSelector(selectBuildingsSelectedFloorId);
    const {
        data = [],
        selectedCoordinates = [],
        highlightedCoordinate,
    } = useCoordinatesConversion(selectedMapId);
    const onCancel = () => {
        dispatch(clearCalculate({ mapId: selectedMapId }));
        dispatch(fetchAllCoordinatesConversionInMap({ buildingId, floorId, mapId: selectedMapId }));

        dispatch(setIsCoordinatesConverstionOpen(false));
    };
    return (
        isCoordinatesConversionOpen && (
            <>
                <CoordinatesConversionOverlay
                    onSubmit={(points) => {
                        dispatch(createCoordinatesConversionPoints({ mapId: selectedMapId, points }));
                    }}
                    onCancel={onCancel}
                    tooltip={true}
                />
                <CoordinatesConversionDrawer />
                <CoordinatesOverlay
                    coordinates={data}
                    selectedCoordinates={selectedCoordinates}
                    highlightedCoordinate={highlightedCoordinate}
                />
            </>
        )
    );
}

function MapOriginOffsetPickerOverlay() {
    const dispatch = useDispatch();
    const isSettingOffset = useSelector(selectBuildingIsSettingMapOffset);

    return (
        isSettingOffset && (
            <MapViewerMapOriginPickerOverlay
                onSubmit={(mapOffset) => dispatch(setCalculatedMapOffset(mapOffset))}
                onCancel={() => dispatch(setIsSettingMapOffset(false))}
                tooltip={true}
            />
        )
    );
}

function EntranceCreationOverlay() {
    const dispatch = useDispatch();

    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const selectedFloorId = useSelector(selectBuildingsSelectedFloorId);
    const isEntranceCreationOpen = useSelector(selectBuildingsIsCreatingEntrance);

    const selectIsThereMainEntranceInFloor = useMemo(
        () => getSelectIsThereMainEntranceInFloor(selectedFloorId),
        [selectedFloorId]
    );
    const isThereMainEntranceInFloor = useSelector(selectIsThereMainEntranceInFloor);

    return (
        isEntranceCreationOpen && (
            <MapViewerEntranceCreationOverlay
                buildingId={selectedBuildingId}
                floorId={selectedFloorId}
                isMain={!isThereMainEntranceInFloor}
                onSubmitted={() => dispatch(setIsCreatingEntrance(false))}
                onCancel={() => dispatch(setIsCreatingEntrance(false))}
            />
        )
    );
}

function EntrancesOverlay() {
    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const selectedFloorId = useSelector(selectBuildingsSelectedFloorId);
    const selectedEntranceId = useSelector(selectBuildingsSelectedEntranceId);
    const highlightedEntranceIds = useSelector(selectBuildingsHighlightedEntranceIds);
    const areEntrancesShown = useSelector(selectBuildingsAreEntrancesShown);
    const isSelectedEntranceEditable = useSelector(selectBuildingsIsSelectedEntranceEditable);

    const { data: entrances = [], isLoading } = useEntrances({
        buildingId: selectedBuildingId,
        floorId: selectedFloorId,
    });

    if (isLoading) {
        return <MapViewerLoadingIndicatorOverlay label={'Loading entrances...'} />;
    }

    return (
        areEntrancesShown &&
        entrances?.length > 0 && (
            <>
                <MapViewerEntrancesOverlay
                    entrances={entrances}
                    selectedEntranceId={selectedEntranceId}
                    highlightedEntranceIds={highlightedEntranceIds}
                    isEditable={isSelectedEntranceEditable}
                />
            </>
        )
    );
}

function GoogleMapsViewToggleOverlay() {
    const dispatch = useDispatch();

    const isGoogleMapsViewOpen = useSelector(selectBuildingsIsGoogleMapsViewOpen);
    const isToggleOpen = useSelector(selectBuildingsIsGoogleMapsViewToggleDisabled);

    return (
        <MapViewerGoogleMapsViewToggleOverlay
            isGoogleMapsViewOpen={isGoogleMapsViewOpen}
            isDisabled={isToggleOpen}
            onChange={(value) => dispatch(setIsGoogleMapsViewOpen(value))}
        />
    );
}

function GMapsRegionExternalPlacementCreationOverlay() {
    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const isCreatingRegionExternalPlacement = useSelector(selectBuildingsIsCreatingRegionExternalPlacement);

    return (
        isCreatingRegionExternalPlacement && (
            <GoogleMapsRegionExternalPlacementCreation
                spaceId={selectedSpaceId}
                buildingId={selectedBuildingId}
                onSubmitted={() => dispatch(setIsCreatingRegionExternalPlacement(false))}
                onCancel={() => dispatch(setIsCreatingRegionExternalPlacement(false))}
            />
        )
    );
}

function GMapsRegionExternalPlacementsOverlay() {
    const dispatch = useDispatch();

    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const selectedPlacementId = useSelector(selectBuildingsSelectedExternalPlacementId);
    const areRegionExternalPlacementsShown = useSelector(selectBuildingsAreRegionExternalPlacementsShown);
    const isSelectedExternalPlacementEditable = useSelector(
        selectBuildingsIsSelectedExternalPlacementEditable
    );

    // This hook is being used to fetch full building data, which includes the external regions
    const { isLoading } = useAllFloorsInBuilding({ buildingId: selectedBuildingId });

    const selectAllRegionsWithExternalPlacementsInBuilding = useMemo(
        () => getSelectAllRegionsWithExternalPlacementsInBuilding(selectedBuildingId),
        [selectedBuildingId]
    );

    const regionsWithPlacements = useSelector(selectAllRegionsWithExternalPlacementsInBuilding) ?? [];

    const handleClick = (regionId, placementId) => {
        dispatch(
            setSelectedExternalPlacementId(
                selectedPlacementId === placementId
                    ? { regionId: null, placementId: null }
                    : { regionId, placementId }
            )
        );
    };

    const handleEdit = (points) => {
        dispatch(setSelectedExternalPlacementPoints(points));
    };

    if (isLoading) {
        return <MapViewerLoadingIndicatorOverlay label={'Loading external regions...'} />;
    }

    return (
        areRegionExternalPlacementsShown && (
            <GoogleMapsRegionExternalPlacements
                placements={regionsWithPlacements}
                buildingId={selectedBuildingId}
                selectedPlacementId={selectedPlacementId}
                isEditable={isSelectedExternalPlacementEditable}
                onClick={handleClick}
                onEdit={handleEdit}
            />
        )
    );
}

function GMapsEntrancesOverlay() {
    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const selectedFloorId = useSelector(selectBuildingsSelectedFloorId);
    const selectedEntranceId = useSelector(selectBuildingsSelectedEntranceId);
    const highlightedEntranceIds = useSelector(selectBuildingsHighlightedEntranceIds);
    const areEntrancesShown = useSelector(selectBuildingsAreEntrancesShown);

    const { data: entrances = [], isLoading } = useEntrances({
        buildingId: selectedBuildingId,
        floorId: selectedFloorId,
    });

    if (isLoading) {
        return <MapViewerLoadingIndicatorOverlay label={'Loading entrances...'} />;
    }

    return (
        areEntrancesShown &&
        entrances?.length > 0 && (
            <GoogleMapsEntrancesOverlay
                entrances={entrances}
                selectedEntranceId={selectedEntranceId}
                highlightedEntranceIds={highlightedEntranceIds}
            />
        )
    );
}

function GoogleMapsOverlay() {
    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const selectedFloorId = useSelector(selectBuildingsSelectedFloorId);
    const selectedMapId = useSelector(selectBuildingsSelectedMapId);
    const isGoogleMapsViewOpen = useSelector(selectBuildingsIsGoogleMapsViewOpen);

    const { data: { [selectedBuildingId]: building } = {} } = useAllBuildingsInSpace({
        asObject: true,
        spaceId: selectedSpaceId,
    });
    const { data: { [selectedMapId]: map } = {} } = useMapsFullData({
        asObject: true,
        buildingId: selectedBuildingId,
        floorId: selectedFloorId,
        mapIds: [selectedMapId],
    });
    const defualtMap = getDefaultMapImage(map?.mapImages);
    if (!building || !defualtMap?.mapUrl || !isGoogleMapsViewOpen) {
        return null;
    }

    return (
        <MapViewerGoogleMapsOverlay
            mapUrl={defualtMap?.mapUrl}
            center={{ lat: building?.buildingOrigin?.latitude, lng: building?.buildingOrigin?.longitude }}
            buildingOrigin={building?.buildingOrigin}
            buildingToEnuRotation={building?.buildingToEnuRotation}
        >
            <GMapsRegionExternalPlacementCreationOverlay />
            <GMapsRegionExternalPlacementsOverlay />
            <GMapsEntrancesOverlay />
            <GoogleMapsMapImageOpacityOverlay />
            <GoogleMapsAddressAndCoordinatesOverlay />
        </MapViewerGoogleMapsOverlay>
    );
}

function ExitRegionCreationOverlay() {
    const dispatch = useDispatch();

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const isExitRegionCreationOpen = useSelector(selectBuildingsIsCreatingExitRegion);

    return (
        isExitRegionCreationOpen && (
            <MapViewerAreaOfInterestCreationOverlay
                mapId={selectedMapId}
                onSubmitted={() => dispatch(setIsExitRegionOpen(false))}
                onCancel={() => dispatch(setIsExitRegionOpen(false))}
                type="exitRegion"
            />
        )
    );
}

const MapImage = styled.img`
    position: absolute;
    pointer-events: none;
`;
export default function BuildingsMapViewer() {
    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const selectedFloorId = useSelector(selectBuildingsSelectedFloorId);
    const selectedMapId = useSelector(selectBuildingsSelectedMapId);
    const { data: { [selectedMapId]: map } = {} } = useMapsFullData({
        asObject: true,
        buildingId: selectedBuildingId,
        floorId: selectedFloorId,
        mapIds: [selectedMapId],
    });
    const mapImagesChecked = useSelector(selectMapImagesChecked);
    const mapImages = getImagesWithoutDefault(map?.mapImages)?.filter(
        (mapImage) => mapImagesChecked[mapImage.imageId]
    );
    return (
        <MapViewer buildingId={selectedBuildingId} floorId={selectedFloorId} mapId={selectedMapId}>
            {/* <ExitRegionOverlay /> */}
            <AreasOfInterestOverlay
                type="exitRegion"
                buildingId={selectedBuildingId}
                floorId={selectedFloorId}
                buildingMapId={selectedMapId}
            />
            <CreateNewItemOverlay />
            <GoogleMapsViewToggleOverlay />
            <PixelToMeterMeasureOverlay />
            <EntranceCreationOverlay />
            <EntrancesOverlay />
            <ExitRegionCreationOverlay />
            <GoogleMapsOverlay />
            {mapImages?.map((mapImage) => (
                <MapImage src={mapImage.mapUrl} />
            ))}
            <MapOriginOffsetPickerOverlay />

            <MouseCoordinatesAndZoom />
            <CoordinatesConversionPickerOverlay />
            <SignMarksOverlay
                buildingId={selectedBuildingId}
                floorId={selectedFloorId}
                mapId={selectedMapId}
                disabled={true}
            />
            <NavgraphErrorsOverlay />
        </MapViewer>
    );
}
