import React from 'react';
import * as PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, Divider } from '@material-ui/core';
import { Button } from '../../common/themed';
import { useDispatch } from 'react-redux';
import { suspendApiKey } from '../../../state-management/api-key/apiKeyActions';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../state-management/notification/notificationReducer';
import { isFulfilled } from '../../../state-management/utils';

export default function ApiKeySuspensionDialog(props) {
    const { isOpen, apiKeyId, apiKeyDesc, onClose } = props;

    const dispatch = useDispatch();

    const handleAccept = async () => {
        const result = await dispatch(suspendApiKey(apiKeyId));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('API key has been suspended.'));
        } else {
            dispatch(showErrorNotification('Failed to suspend API key.'));
        }

        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogContent>
                <DialogContentText>
                    You are about to suspend the API key for {apiKeyDesc}
                    <br />
                    This will block it from accessing any APIs.
                    <br />
                    Are you sure?
                </DialogContentText>
            </DialogContent>

            <Divider />

            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleAccept}>Accept</Button>
            </DialogActions>
        </Dialog>
    );
}

ApiKeySuspensionDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    apiKeyId: PropTypes.string,
    apiKeyDesc: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};
