/**
 * Created by itaym on 22/05/2018.
 */

class Manager {
    constructor(authManager) {
        this.authManager = authManager;
    }

    getParams(toMerge) {
        const params = {};
        if (this._spaceId) {
            params.spaceId = this._spaceId;
        }
        return Object.assign(params, toMerge);
    }

    set spaceId(spaceId) {
        this._spaceId = spaceId;
    }
    get spaceId() {
        return this._spaceId;
    }
}

export default Manager;
