import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import SideMenuSection from '../../common/side-drawer/SideMenuSection';
import { Select, Skeleton, Switch, SwitchLabel as Label, Checkbox, Dialog, DialogContent, DialogContentText, DialogActions, CircularPreloader, Button } from '../../common/themed';
import {
    selectMappingAreLanesShown,
    selectMappingSelectedLaneId,
    selectMappingSelectedMapId,
    setAreLanesShown,
    selectMappingIsShowingEqualizedMap,
    selectMappingSelectedAreaId,
    setAreAreasOfInterestShown,
    selectMappingAreAreasOfInterestShown,
    setSelectedAreaOfInterestIds,
    selectMappingAreaOfInterestIds,
    selectMappingElementIds,
    selectMappingAreElementsShown,
    setSelectedElementIds,
    setAreElementsShown,
    setAreLinesOfInterestShown,
    setSelectedLineId,
    setSelectedLineOfInterestIds,
    selectMappingAreLinesOfInterestShown,
    selectMappingLineOfInterestIds,
    selectMappingSelectedAreaOfInterestsMapperName,
    selectMappingSelectedElementsMapperName,
    selectMappingSelectedLineOfInterestsMapperName,
    setSelectedElementsMapperName,
    setSelectedAreaOfInterestsMapperName,
    setSelectedLineOfInterestsMapperName,
    setAreAreasOfInterestActionsShown,
} from '../../../state-management/user-inputs/mappingSlice';
import { useDispatch, useSelector } from 'react-redux';
import useLanes from '../../common/hooks/data-fetching/useLanes';
import { FormControlLabel, IconButton, Typography } from '@material-ui/core';
import LaneListEntry from './LaneListEntry';
import { FixedSizeList as List } from 'react-window';
import FetchError from '../../common/error-pages/FetchError';
import moment from 'moment';
import useAreasOfInterest from '../../common/hooks/data-fetching/useAreasOfInterest';
import AreaListEntry from './AreaListEntry';
import { FlexRow } from '../../common/layout';
import { MdDelete } from 'react-icons/md';
import useLinesOfInterest from '../../common/hooks/data-fetching/useLinesOfInterest';
import { getAreaOfInterestListEntryId, getElementListEntryId, getLineOfInterestListEntryId } from '../Mapping.selectors';
import { selectCanAccessMappingMultiSelect } from '../../../state-management/auth/authSelectors';
import { deleteAreaOfInterest } from '../../../state-management/mapping/area-of-interest/areaOfInterestActions';
import { deleteElement } from '../../../state-management/mapping/elements/elementsActions';
import { deleteLineOfInterest } from '../../../state-management/mapping/line-of-interest/lineOfInterestActions';
import { showErrorNotification, showSuccessNotification } from '../../../state-management/notification/notificationReducer';
import { isFulfilled } from '../../../state-management/utils';
import { isRejected } from '@reduxjs/toolkit';
import { useMappers } from '../../../hooks/useMappers';
import { formatDateToISO, formatDateToYYYYMMDD } from '../../../utils/general';

const ListWrapper = styled(List)`
    margin-block-start: 5px;
    padding: 0;

    & > ul {
        margin: 0;
        padding: 0;
    }
`;

const NoListMessage = styled(Typography)`
    color: #909090;
    text-align: center;
    margin-block-start: 10px;
    font-style: italic;
`;


const ACTIONS = {
    normal: {
        selectedAreaIds: selectMappingAreaOfInterestIds,
        title: 'Area Of Interest',
        selectMappingAreAreaShown: selectMappingAreAreasOfInterestShown,
        setSelectedAreaIds: setSelectedAreaOfInterestIds,
        setAreAreaShown: setAreAreasOfInterestShown,
        useData: useAreasOfInterest,
        id: 'areaId',
        getId: getAreaOfInterestListEntryId,
        delete: deleteAreaOfInterest,
        name: 'areas of interest',
        setSelectedMapperName: setSelectedAreaOfInterestsMapperName,
        selectedMapperName: selectMappingSelectedAreaOfInterestsMapperName
    },
    element: {
        selectedAreaIds: selectMappingElementIds,
        title: 'Element',
        selectMappingAreAreaShown: selectMappingAreElementsShown,
        setSelectedAreaIds: setSelectedElementIds,
        setAreAreaShown: setAreElementsShown,
        useData: useAreasOfInterest,
        id: 'areaId',
        getId: getElementListEntryId,
        delete: deleteElement,
        name: 'elements',
        setSelectedMapperName: setSelectedElementsMapperName,
        selectedMapperName: selectMappingSelectedElementsMapperName
    },
    line: {
        selectedAreaIds: selectMappingLineOfInterestIds,
        title: 'Lines Of interest',
        selectMappingAreAreaShown: selectMappingAreLinesOfInterestShown,
        setSelectedAreaIds: setSelectedLineOfInterestIds,
        setAreAreaShown: setAreLinesOfInterestShown,
        useData: useLinesOfInterest,
        id: 'lineId',
        getId: getLineOfInterestListEntryId,
        delete: deleteLineOfInterest,
        name: 'lines of interest',
        setSelectedMapperName: setSelectedLineOfInterestsMapperName,
        selectedMapperName: selectMappingSelectedLineOfInterestsMapperName
    }
}

export default function MappingSideMenuListSection({ type = 'normal', selfHealingEnabled }) {
    const dispatch = useDispatch();
    const action = ACTIONS[type];
    const selectRef = useRef(null);
    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState();
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const selectedMapperName = useSelector(action.selectedMapperName);
    // const selectedAreaId = useSelector(selectMappingSelectedAreaId);
    const selectedAreaIds = useSelector(action.selectedAreaIds)
    const areAreasShown = useSelector(action.selectMappingAreAreaShown);
    const isShowingEqualizedMap = useSelector(selectMappingIsShowingEqualizedMap);
    const hasPermission = useSelector(selectCanAccessMappingMultiSelect);
    const showDeleteWarning = isDeleteWarningOpen && !!selectedAreaIds?.length
    const {
        data: list = [],
        isLoading,
        hasError,
    } = action.useData({
        mapId: selectedMapId,
        withAttachments: true,
        type
    });

    const { mappers, customFilterOption } = useMappers({ list })

    const listToDisplay = useMemo(
        () => {
            return list
                .filter(
                    ({ createdAt, mapperName }) => {
                        let value = mapperName;
                        if (createdAt) {
                            const dateFormatted = formatDateToYYYYMMDD(new Date(createdAt));
                            value = `${value},${dateFormatted}`;
                        }
                        return !selectedMapperName || selectedMapperName === value;
                    }
                );
        }
        , [list, selectedAreaIds, selectedMapperName]);

    useEffect(() => {
        const updatedSelectedIds = selectedAreaIds.filter(selectedId => {
            return listToDisplay.some(({ [action.id]: id }) => id === selectedId);
        });

        dispatch(action.setSelectedAreaIds(updatedSelectedIds))
    }, [selectedMapperName])

    useEffect(() => {
        if (!selectedAreaIds.length && selectAll) {
            setSelectAll(false);
        } else {
            const allAreSelected = selectedAreaIds.length && selectedAreaIds.length === list.length;
            if (allAreSelected && !selectAll) {
                setSelectAll(true)
            }
        }

    }, [selectedAreaIds])

    useEffect(() => {
        if (selectRef.current) {
            selectRef.current.select.clearValue();
        }
    }, [list])

    const onDelete = async () => {
        setIsDeleting(true);
        const result = await Promise.all(selectedAreaIds.map(id => {
            return dispatch(action.delete({ mapId: selectedMapId, [action.id]: id }))
        }));
        const atLeastOneFailed = result.some(result => isRejected(result));
        if (atLeastOneFailed) {
            dispatch(showErrorNotification(`Failed to delete ${action.name}`))
        } else {
            setIsDeleteWarningOpen(false);
            setSelectAll(false);
            dispatch(action.setSelectedMapperName(null));
            dispatch(action.setSelectedAreaIds([]));
            dispatch(showSuccessNotification(`The ${action.name} were successfully deleted`))
        }
        setIsDeleting(false);
    }

    const onItemSelected = (areaId) => {
        let updatedAreaIds = [...selectedAreaIds, areaId];
        if (selectedAreaIds.includes(areaId)) {
            updatedAreaIds = selectedAreaIds.filter(id => id !== areaId)
        }
        if (updatedAreaIds.length !== selectedAreaIds.length) {
            setSelectAll(false);
        }
        dispatch(action.setSelectedAreaIds(updatedAreaIds))
    }

    const onSelectAll = () => {
        const allAreSelected = selectedAreaIds.length === list.length
        if (allAreSelected) {
            dispatch(action.setSelectedAreaIds([]));
            setSelectAll(false);
        } else {
            dispatch(action.setSelectedAreaIds(list.map(({ [action.id]: id }) => id)));
            setSelectAll(true);
        }
    }

    const onOpenDeleteDialog = () => {
        setIsDeleteWarningOpen(true)
        dispatch(setAreAreasOfInterestActionsShown(false));
    }

    const onCloseDeleteDialog = () => {
        setIsDeleteWarningOpen(false)
        dispatch(setAreAreasOfInterestActionsShown(true));
    }

    if (!selectedMapId && !isLoading) {
        return null;
    }

    return (
        <SideMenuSection title={action.title}>
            {hasError ? (
                <FetchError />
            ) : (
                <>
                    <FormControlLabel
                        label={<Label>Show {action.title}</Label>}
                        disabled={isLoading}
                        control={
                            <Switch
                                checked={areAreasShown}
                                onChange={() => dispatch(action.setAreAreaShown(!areAreasShown))}
                            />
                        }
                    />

                    {isLoading ? (
                        Array.from({ length: 4 }, (_, index) => (
                            <Skeleton key={`area-list-entry-skeleton-${index}`} height={70} width={230} />
                        ))
                    ) : list.length === 0 ? (
                        <NoListMessage>There are no areas in this map</NoListMessage>
                    ) : (
                        <>
                            {showDeleteWarning && (
                                <Dialog open closeable={false}>
                                    <DialogContent>
                                        <DialogContentText>
                                            You are about to delete the selected {action.name}. Are you sure?
                                        </DialogContentText>
                                    </DialogContent>

                                    <DialogActions>
                                        <Button variant={'text'} onClick={onCloseDeleteDialog}>
                                            Cancel
                                        </Button>

                                        {isDeleting ? <CircularPreloader /> : <Button onClick={onDelete}>Accept</Button>}
                                    </DialogActions>
                                </Dialog>
                            )}
                            <FlexRow width='100%' alignItems='center' style={{ columnGap: '5px' }}>
                                <FlexRow alignItems='center'>
                                    <Checkbox
                                        checked={selectAll}
                                        disabled={!!selfHealingEnabled}
                                        onChange={onSelectAll}
                                        style={{ padding: 5 }}
                                    />
                                    <IconButton onClick={onOpenDeleteDialog} disabled={!selectedAreaIds.length} style={{ padding: 0 }}>
                                        <MdDelete />
                                    </IconButton>
                                </FlexRow>
                                <Select
                                    ref={selectRef}
                                    placeholder={'Filter...'}
                                    options={mappers}
                                    onChange={(m) => dispatch(action.setSelectedMapperName(m ? m.value : m))}
                                    filterOption={customFilterOption}
                                    value={mappers.find((m) => m.value === selectedMapperName)}
                                    isDisabled={isLoading}
                                    isClearable
                                    width='100%'
                                    alignSelf='center'

                                />
                            </FlexRow>

                            <ListWrapper
                                height={Math.min(list.length * 70 + 5, 350)}
                                itemCount={listToDisplay.length}
                                itemSize={70}
                                innerElementType={'ul'}
                                style={{ overflowX: 'hidden' }}
                            >
                                {({ index, style }) => {
                                    const item = listToDisplay[index];
                                    const id = item[action.id];
                                    const htmlElementId = action.getId(id);
                                    return (
                                        <AreaListEntry
                                            disabled={!!selfHealingEnabled}
                                            htmlElementId={htmlElementId}
                                            type={type}
                                            key={htmlElementId}
                                            area={item}
                                            style={{ ...style }}
                                            onItemSelected={onItemSelected}
                                            isSelected={selectedAreaIds.includes(id)} />
                                    );
                                }}
                            </ListWrapper>
                        </>
                    )}
                </>
            )}
        </SideMenuSection>
    );
}
