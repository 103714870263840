import moment from 'moment';
import TimePeriod from './TimePeriod';

class LastWeek extends TimePeriod {
    get label() {
        return 'Last Week';
    }

    get value() {
        return 'LAST_WEEK';
    }

    getTimeSpan() {
        return {
            start: moment()
                .subtract(1, 'week')
                .startOf('isoWeek')
                .toISOString(),
            end: moment()
                .subtract(1, 'week')
                .endOf('isoWeek')
                .toISOString(),
        };
    }

    get isPickable() {
        return false;
    }

    get isRange() {
        return true;
    }
}

export default new LastWeek();
