import { createAsyncThunk } from '../utils';
import mapsApi from '../../api/MapsApi';

export const fetchAllMapsInFloor = createAsyncThunk(
    'maps/fetchAllInFloor',
    async ({ buildingId, floorId, filter }) => {
        return (await mapsApi.fetchAllMapsInFloor(buildingId, floorId, filter)).data;
    }
);

export const fetchMapFullData = createAsyncThunk(
    'maps/fetchFullData',
    async ({ buildingId, floorId, mapId, spaceId }) => {
        return await mapsApi.fetchMapFullData(buildingId, floorId, mapId, spaceId);
    }
);

export const createMap = createAsyncThunk('maps/create', async ({ buildingId, floorId, mapData }) => {
    return (await mapsApi.createMap(buildingId, floorId, mapData)).data;
});

export const createMapImage = createAsyncThunk(
    'maps/image/create',
    async ({ buildingId, floorId, mapId, mapData }, thunkAPI) => {
        try {
            return (await mapsApi.createMapImage(buildingId, floorId, mapId, mapData)).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const createMapGrid = createAsyncThunk('maps/map-grid/create', async ({ mapId, version }) => {
    return (await mapsApi.createMapGrid(mapId, version)).data;
});

export const getMapGridMetadata = createAsyncThunk('maps/map-grid/metadata', async ({ mapId }) => {
    const data = await mapsApi.getMapGridMetadata(mapId);
    return data;
});

export const createPublishMapGrid = createAsyncThunk(
    'maps/map-grid/publish',
    async ({ mapId }, { rejectWithValue }) => {
        try {
            const { data: createdPublishGrid } = await mapsApi.createPublishMapGrid(mapId);
            const { data: gridMetadata } = await mapsApi.getMapGridMetadata(mapId);
            return {
                createdPublishGrid,
                gridMetadata,
            };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const resetToManualMapping = createAsyncThunk(
    'maps/map-grid/reset',
    async ({ buildingId, floorId, mapId }, { rejectWithValue }) => {
        try {
            const { data: manualMapping } = await mapsApi.resetToManualMapping({
                buildingId,
                floorId,
                mapId,
            });
            const mapFullData = await mapsApi.fetchMapFullData(buildingId, floorId, mapId);
            const { data: gridMetadata } = await mapsApi.getMapGridMetadata(mapId);
            return {
                manualMapping,
                mapFullData,
                gridMetadata,
            };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteMapImage = createAsyncThunk(
    'maps/image/delete',
    async ({ buildingId, floorId, mapId, imageId }) => {
        return (await mapsApi.deleteMapImage(buildingId, floorId, mapId, imageId)).data;
    }
);
export const updateMap = createAsyncThunk('maps/update', async ({ buildingId, floorId, mapId, mapData }) => {
    return (await mapsApi.updateMap(buildingId, floorId, mapId, mapData)).data;
});

export const deleteMap = createAsyncThunk('maps/delete', async ({ buildingId, floorId, mapId }) => {
    return (await mapsApi.deleteMap(buildingId, floorId, mapId)).data;
});
