import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Select,
    Switch,
    TextField,
    Toggle,
    ToggleGroup,
    SwitchLabel as Label,
} from '../../../common/themed';
import {
    getChangeOffsetId,
    getChangePixelToMeterId,
    getFloorToggleId,
    getMapNameId,
    getMapUsageId,
    getOffsetDialogAcceptId,
    getOffsetXId,
    getOffsetYId,
    getPixelToMeterDialogAcceptId,
    getPixelToMeterId,
} from './CreateBuildingDialog.selectors';
import { MdAdd as AddIcon } from 'react-icons/md';
import MapViewerPixelToMeterMeasureOverlay from '../../../common/map-viewer/overlays/pixel-to-meter-measure';
import MapViewerMapOriginPickerOverlay from '../../../common/map-viewer/overlays/map-origin-picker';
import { FormControlLabel, InputAdornment, Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import MapViewerV1 from '../../../map-content/legacy/OldMapViewer';
import Map from '../../../common/map-viewer';
import Slider from '@material-ui/core/Slider';
import { useDropzone } from 'react-dropzone';
import { useImmer } from 'use-immer';
import clsx from 'clsx';
import Thumbnail from '../common/Thumbnail';
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';
import DropZone from '../common/DropZone';

const OldMapViewer = styled(MapViewerV1)`
    height: 600px !important;
`;

const Form = styled.div`
    display: grid;
    grid-template-rows: 4rem 25rem 1fr;
    grid-template-columns: 12rem 1fr;
    grid-template-areas:
        'floor-select floor-select'
        'map-thumbnails map-viewer'
        'map-thumbnails map-details';
`;

const FloorsToggleGroup = styled(ToggleGroup)`
    grid-area: floor-select;
`;

const ThumbnailsWrapper = styled.div`
    grid-area: map-thumbnails;
    overflow-y: auto;
    max-height: 35rem;

    ${Thumbnail} {
        margin-block-start: 20px;
    }
`;

const MapViewer = styled(Map)`
    grid-area: map-viewer;
`;

const MapDetailsWrapper = styled.div`
    grid-area: map-details;
    margin-block-start: 15px;
    display: grid;
    grid-template-columns: 270px 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 50px;
    row-gap: 15px;
    align-items: center;
`;

const OpacityWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const OpacityTitle = styled(Typography)`
    margin-inline-end: 15px;
`;

const OpacitySlider = styled(Slider)`
    width: 300px;
`;

const MapUsageTextField = styled(TextField)`
    max-width: 300px;
`;

const PixelToMeterTextField = styled(NumberFormat)`
    margin-inline-end: 15px;
`;

const OffsetTextField = styled(NumberFormat)`
    max-width: 120px;
    margin-inline-end: 15px;
`;

const ControlsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-block-start: 10px;

    & > :not(:last-child) {
        margin-inline-end: 30px;
    }

    & > :last-child {
        margin-inline-start: auto;
    }
`;

export default function CreateBuildingStepTwo() {
    const [oldMapViewerRef, setOldMapViewerRef] = useState(null);
    const [selectedReferenceMapArrayIndex, setSelectedReferenceMapArrayIndex] = useState(0);
    const [lockStages, setLockStages] = useState(true);
    const [opacity, setOpacity] = useState(0.5);

    const { errors, register, setValue, watch, setError, clearErrors } = useFormContext();
    const { floors, primaryFloorArrayIndex, buildingName } = watch([
        'floors',
        'primaryFloorArrayIndex',
        'buildingName',
    ]);

    const [
        {
            selectedFloorArrayIndex,
            selectedMapArrayIndex,
            isPixelToMeterDialogOpen,
            isMapOffsetDialogOpen,
            isSettingPixelToMeter,
            isSettingMapOrigin,
        },
        setStepState,
    ] = useImmer({
        selectedFloorArrayIndex: primaryFloorArrayIndex ?? 0,
        selectedMapArrayIndex: 0,
        isPixelToMeterDialogOpen: false,
        isMapOffsetDialogOpen: false,
        isSettingPixelToMeter: false,
        isSettingMapOrigin: false,
    });

    const selectedFloor = floors?.[selectedFloorArrayIndex];
    const selectedMap = selectedFloor?.maps?.[selectedMapArrayIndex];
    const selectedMapFieldKey = `floors[${selectedFloorArrayIndex}].maps[${selectedMapArrayIndex}]`;
    const selectedMapErrors = errors?.floors?.[selectedFloorArrayIndex]?.maps?.[selectedMapArrayIndex];
    const isFirstMapOnPrimaryFloor =
        selectedFloorArrayIndex === primaryFloorArrayIndex && selectedMapArrayIndex === 0;
    const allMaps = floors?.reduce((result, { maps }) => [...result, ...maps], []);

    const handleFileAccepted = ([file]) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const mapsInFloorCount = selectedFloor?.maps?.length;
            const isFirstMapOnFloor = mapsInFloorCount === 0;

            setValue(`floors[${selectedFloorArrayIndex}].maps`, [
                ...selectedFloor?.maps,
                {
                    mapName: `${buildingName}_${selectedFloor?.floorName}_map${
                        isFirstMapOnFloor ? '' : `_${mapsInFloorCount + 1}`
                    }`,
                    pixelToMeter: selectedFloor?.maps?.[0]?.pixelToMeter ?? 1,
                    imgBase64: e.target.result.split(',')[1],
                    mapOffset: { x: 0, y: 0 },
                    mapUsage: isFirstMapOnFloor ? 'default' : '',
                    floorArrayIndex: selectedFloorArrayIndex,
                    mapArrayIndex: selectedFloor?.maps?.length,
                },
            ]);

            setStepState((state) => {
                state.selectedMapArrayIndex = mapsInFloorCount;

                if (isFirstMapOnFloor) {
                    state.isPixelToMeterDialogOpen = true;
                } else {
                    state.isMapOffsetDialogOpen = true;
                }
            });
        };
        fileReader.readAsDataURL(file);
    };

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDropAccepted: handleFileAccepted,
        onFileDialogCancel: () => {},
        accept: 'image/jpeg, image/png',
        multiple: false,
    });

    const handlePixelToMeterChange = (pixelToMeter) => {
        setValue(`${selectedMapFieldKey}.pixelToMeter`, pixelToMeter);

        setStepState((state) => {
            state.isSettingPixelToMeter = false;
        });
    };

    const handleMapOriginChange = (mapOffset) => {
        setValue(`${selectedMapFieldKey}.mapOffset`, mapOffset);

        setStepState((state) => {
            state.isSettingMapOrigin = false;
        });
    };

    const handleMapOffsetChange = () => {
        setValue(`${selectedMapFieldKey}.mapOffset`, oldMapViewerRef?.getMapsOffset());
        setValue(`${selectedMapFieldKey}.pixelToMeter`, oldMapViewerRef?.getPixelToMeter());

        setStepState((state) => {
            state.isSettingMapOffset = false;
            state.isMapOffsetDialogOpen = false;
        });
    };

    useEffect(() => {
        if (floors) {
            const emptyFloor = floors?.find(({ maps }) => maps?.length === 0);
            const errorMessage = `Please upload at least one map to ${emptyFloor?.floorName}`;

            if (emptyFloor && (!errors?.custom || errors?.custom?.message !== errorMessage)) {
                setError('custom', { message: errorMessage });
            } else if (
                !emptyFloor &&
                errors?.custom?.message?.startsWith('Please upload at least one map to')
            ) {
                clearErrors('custom');
            }
        }
    }, [clearErrors, setError, floors, errors]);

    useEffect(() => {
        const errorMessage = `Please finish setting the map's pixel to meter ratio`;

        if (isSettingPixelToMeter && !errors?.custom) {
            setError('custom', { message: errorMessage });
        } else if (!isSettingPixelToMeter && errors?.custom?.message === errorMessage) {
            clearErrors('custom');
        }
    }, [clearErrors, setError, errors, isSettingPixelToMeter]);

    useEffect(() => {
        const errorMessage = `Please finish setting the map's origin`;

        if (isSettingMapOrigin && !errors?.custom) {
            setError('custom', { message: errorMessage });
        } else if (!isSettingMapOrigin && errors?.custom?.message === errorMessage) {
            clearErrors('custom');
        }
    }, [clearErrors, setError, errors, isSettingMapOrigin]);

    return (
        <>
            <Form>
                <FloorsToggleGroup>
                    {floors?.map(({ floorName, floorIndex }, index) => (
                        <Toggle
                            key={floorIndex}
                            id={getFloorToggleId(floorIndex)}
                            onClick={() =>
                                setStepState((state) => {
                                    state.selectedFloorArrayIndex = index;
                                    state.selectedMapArrayIndex = 0;
                                })
                            }
                            isSelected={index === selectedFloorArrayIndex}
                            isDisabled={allMaps?.length === 0 && index !== primaryFloorArrayIndex}
                        >
                            {floorName}
                        </Toggle>
                    ))}
                </FloorsToggleGroup>

                <ThumbnailsWrapper>
                    <DropZone
                        isDragActive={isDragActive}
                        isDragAccept={isDragAccept}
                        isDragReject={isDragReject}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <AddIcon size={50} />
                        Upload files
                    </DropZone>

                    {selectedFloor?.maps
                        ?.filter((m) => m.floorArrayIndex === selectedFloorArrayIndex)
                        ?.map(({ imgBase64 }, index) => (
                            <Thumbnail
                                key={`${selectedFloorArrayIndex}-${index}-map`}
                                className={clsx({ selected: index === selectedMapArrayIndex })}
                                onClick={() =>
                                    setStepState((state) => {
                                        state.selectedMapArrayIndex = index;
                                    })
                                }
                            >
                                <img alt={'thumbnail'} src={`data:image/jpeg;base64,${imgBase64}`} />
                            </Thumbnail>
                        ))}
                </ThumbnailsWrapper>

                <MapViewer base64={selectedMap?.imgBase64}>
                    {isSettingPixelToMeter && (
                        <MapViewerPixelToMeterMeasureOverlay
                            isCancelable={!!selectedMap?.pixelToMeter}
                            onSubmit={handlePixelToMeterChange}
                            onCancel={() =>
                                setStepState((state) => {
                                    state.isSettingPixelToMeter = false;
                                })
                            }
                        />
                    )}

                    {isSettingMapOrigin && (
                        <MapViewerMapOriginPickerOverlay onSubmit={handleMapOriginChange} />
                    )}
                </MapViewer>

                {floors?.map(({ maps }, fIndex) =>
                    maps.map(
                        (m, mIndex) =>
                            fIndex === selectedFloorArrayIndex &&
                            mIndex === selectedMapArrayIndex && (
                                <MapDetailsWrapper key={`floor-${fIndex}-map-${mIndex}-details`}>
                                    <TextField
                                        id={getMapNameId(fIndex, mIndex)}
                                        name={`${selectedMapFieldKey}.mapName`}
                                        inputRef={register({
                                            validate: (value) => /^(?![0-9])[^\s]+$/.test(value), // cannot start with a number must be at least 1 char and without white spaces
                                        })}
                                        label={'Name'}
                                        error={!!selectedMapErrors?.mapName}
                                        helperText={selectedMapErrors?.mapName?.message}
                                    />

                                    <MapUsageTextField
                                        id={getMapUsageId(fIndex, mIndex)}
                                        name={`${selectedMapFieldKey}.mapUsage`}
                                        inputRef={register({ required: true })}
                                        label={'Map Usage'}
                                        error={!!selectedMapErrors?.mapUsage}
                                        helperText={
                                            selectedMapErrors?.mapUsage?.message ??
                                            'This will describe the purpose of this specific map'
                                        }
                                    />

                                    {selectedMap?.pixelToMeter ? (
                                        <div>
                                            <Typography>Pixel to meter ratio:</Typography>

                                            <Controller
                                                name={`${selectedMapFieldKey}.pixelToMeter`}
                                                defaultValue={1}
                                                rules={{ required: 'This is required', valueAsNumber: true }}
                                                render={({ onChange }) => (
                                                    <PixelToMeterTextField
                                                        id={getPixelToMeterId()}
                                                        disabled={isSettingPixelToMeter}
                                                        error={!!selectedMapErrors?.pixelToMeter}
                                                        helperText={selectedMapErrors?.pixelToMeter?.message}
                                                        customInput={TextField}
                                                        onValueChange={({ floatValue }) =>
                                                            onChange(floatValue)
                                                        }
                                                        value={selectedMap?.pixelToMeter}
                                                        allowNegative={false}
                                                    />
                                                )}
                                            />

                                            <Button
                                                id={getChangePixelToMeterId(fIndex, mIndex)}
                                                size={'small'}
                                                variant={'outlined'}
                                                onClick={() =>
                                                    setStepState((state) => {
                                                        state.isSettingPixelToMeter = true;
                                                    })
                                                }
                                            >
                                                Change
                                            </Button>
                                        </div>
                                    ) : (
                                        <Button onClick={() => true}>Set the pixel to meter ratio</Button>
                                    )}

                                    <div>
                                        <Typography>Map offset:</Typography>

                                        <Grid container>
                                            <Grid item>
                                                <Controller
                                                    name={`${selectedMapFieldKey}.mapOffset.x`}
                                                    defaultValue={1}
                                                    rules={{
                                                        required: 'This is required',
                                                        valueAsNumber: true,
                                                    }}
                                                    render={({ onChange }) => (
                                                        <OffsetTextField
                                                            id={getOffsetXId()}
                                                            disabled={isSettingPixelToMeter}
                                                            error={!!selectedMapErrors?.mapOffset?.x}
                                                            helperText={
                                                                selectedMapErrors?.mapOffset?.x?.message
                                                            }
                                                            customInput={TextField}
                                                            onValueChange={({ floatValue }) =>
                                                                onChange(floatValue)
                                                            }
                                                            value={selectedMap?.mapOffset?.x}
                                                            allowNegative={false}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        X:
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item>
                                                <Controller
                                                    name={`${selectedMapFieldKey}.mapOffset.y`}
                                                    defaultValue={1}
                                                    rules={{
                                                        required: 'This is required',
                                                        valueAsNumber: true,
                                                    }}
                                                    render={({ onChange }) => (
                                                        <OffsetTextField
                                                            id={getOffsetYId()}
                                                            disabled={isSettingPixelToMeter}
                                                            error={!!selectedMapErrors?.mapOffset?.y}
                                                            helperText={
                                                                selectedMapErrors?.mapOffset?.y?.message
                                                            }
                                                            customInput={TextField}
                                                            onValueChange={({ floatValue }) =>
                                                                onChange(floatValue)
                                                            }
                                                            value={selectedMap?.mapOffset?.y}
                                                            allowNegative={false}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        Y:
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item>
                                                <Button
                                                    id={getChangeOffsetId(fIndex, mIndex)}
                                                    size={'small'}
                                                    variant={'outlined'}
                                                    onClick={() =>
                                                        setStepState((state) => {
                                                            if (isFirstMapOnPrimaryFloor) {
                                                                state.isSettingMapOrigin = true;
                                                            } else {
                                                                state.isSettingMapOffset = true;
                                                                state.isMapOffsetDialogOpen = true;
                                                            }
                                                        })
                                                    }
                                                >
                                                    Change
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </MapDetailsWrapper>
                            )
                    )
                )}
            </Form>

            <Dialog open={isPixelToMeterDialogOpen} closeable={false}>
                <DialogContent>
                    <DialogContentText>
                        In order to proceed, please set the map image's pixel to meter ratio.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        id={getPixelToMeterDialogAcceptId()}
                        variant={'text'}
                        onClick={() =>
                            setStepState((state) => {
                                state.isSettingPixelToMeter = true;
                                state.isPixelToMeterDialogOpen = false;
                            })
                        }
                    >
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isMapOffsetDialogOpen} closeable={false} fullWidth maxWidth={'lg'}>
                <DialogContent>
                    <DialogContentText>
                        In order to proceed, please set the map's offset by aligning it over another map that
                        was previously uploaded.
                    </DialogContentText>

                    {/*// TODO move the map offset behavior into the new map viewer*/}
                    <OldMapViewer
                        selectedMap={allMaps?.[selectedReferenceMapArrayIndex]}
                        selectedMapData={allMaps?.[selectedReferenceMapArrayIndex]}
                        additionalMapData={selectedMap}
                        additionalMapOpacity={opacity}
                        onMount={setOldMapViewerRef}
                        lockStages={lockStages}
                    />

                    <ControlsWrapper>
                        {allMaps?.length > 1 && (
                            <Select
                                options={
                                    allMaps?.filter((m) => m.mapArrayIndex !== selectedMapArrayIndex) ?? []
                                }
                                getOptionLabel={(m) => m.mapName}
                                getOptionValue={(m) => m.mapName}
                                defaultValue={
                                    allMaps?.find((m) => m.floorArrayIndex === primaryFloorArrayIndex) ?? null
                                }
                                onChange={(m) => setSelectedReferenceMapArrayIndex(m.mapArrayIndex)}
                                menuPlacement={'top'}
                            />
                        )}

                        <FormControlLabel
                            control={
                                <Switch checked={lockStages} onChange={() => setLockStages(!lockStages)} />
                            }
                            label={<Label>Lock position</Label>}
                        />

                        <OpacityWrapper>
                            <OpacityTitle>Opacity:</OpacityTitle>
                            <OpacitySlider
                                min={0}
                                max={1}
                                step={0.05}
                                value={opacity}
                                onChange={(e, value) => setOpacity(value)}
                            />
                        </OpacityWrapper>

                        <Button
                            id={getOffsetDialogAcceptId()}
                            variant={'text'}
                            onClick={handleMapOffsetChange}
                            disabled={!allMaps?.[selectedReferenceMapArrayIndex]}
                        >
                            Accept
                        </Button>
                    </ControlsWrapper>
                </DialogContent>
            </Dialog>
        </>
    );
}
