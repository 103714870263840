import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, FormControlLabel } from '@material-ui/core';
import { VscRunAbove as GenerateSessionIcon } from "react-icons/vsc";

import { MdDelete as DeleteIcon, MdSave as SaveIcon } from 'react-icons/md';
import styled from 'styled-components';
import { TextField, Button, PulsePreloader, Tooltip } from '../../common/themed';
import CheckListWithSearch from '../common/CheckListWithSearch';
import RemoveConfirmDialog from '../common/RemoveConfirmDialog';
import ChipListWithInsert from '../common/ChipListWithInsert';
import { Switch } from '../../common/themed';
import {
    attachBuildingToSpace,
    createSpace,
    deleteSpace,
    detachBuildingFromSpace,
    updateSpace,
} from '../../../state-management/space/spaceActions';
import useAllBuildingsInAllSpaces from '../../common/hooks/data-fetching/useAllBuildingsInAllSpaces';
import { unwrapResult } from '@reduxjs/toolkit';
import ErrorGeneral from '../../common/error-pages/ErrorGeneral';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { isFulfilled } from '../../../state-management/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../state-management/notification/notificationReducer';
import { UserActivityModal } from '../../analytics/user-activity/userActivityModal';
import { fetchAllBuildingsInSpace } from '../../../state-management/building/buildingActions';
import { selectCanAccessPositionAnalyticsProcessing } from '../../../state-management/auth/authSelectors';
import { useMemo } from 'react';
import useAllBuildingsInSpace from '../../common/hooks/data-fetching/useAllBuildingsInSpace';

const EditorWrapper = styled.div`
    display: grid;
    grid-template-rows: 30px 250px;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
    margin-block-end: 20px;
`;

const Header = styled(Typography).attrs({
    variant: 'h6',
})`
    margin-bottom: 15px;
`;

export default function SpaceEditor(props) {
    const { space, onFormSubmitted = () => { } } = props;
    const { spaceId, spaceDesc, bundleIds, buildingIds, plaiEnabled, exportPositionData } = space ?? {};
    const [showUserActivityModal, setShowUserActivityModal] = useState(false);
    const [isRemoveConfirmDialogOpen, setIsRemoveConfirmDialogOpen] = useState(false);

    const { data: buildings, isLoading: isLoadingBuildings, hasError } = useAllBuildingsInAllSpaces();

    const { data: buildingsInSpace = {}, isLoading: isLoadingBuildingsInSpace } = useAllBuildingsInSpace({
        asObject: true,
        spaceId,
    }); const dispatch = useDispatch();
    const canTriggerPositionAnalytics = useSelector(selectCanAccessPositionAnalyticsProcessing)

    const openUserActivityModal = () => setShowUserActivityModal(true);
    const closeUserActivityModal = () => setShowUserActivityModal(false);

    const {
        register,
        handleSubmit,
        errors,
        control,
        setValue,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: {
            spaceDesc: spaceDesc ?? '',
            bundleIds: bundleIds ?? [],
            buildingIds: buildingIds ?? [],
            exportPositionData: exportPositionData ?? false,
        },
    });

    const { handleSubmit: onUserActivityModalSubmit, ...userActivityModalActions } = useForm({
        shouldUnregister: false,
        mode: 'onChange',
        defaultValues: {
            fromDate: new Date().toISOString(),
            toDate: new Date().toISOString(),
            spaceId
        },
    });


    const submitForm = async (data) => {
        let { buildingIds: newBuildingIds, ...spaceData } = data;
        let spaceResult;
        let buildingsAttachResult;
        let buildingsDetachResult;
        spaceData = { ...spaceData, plaiEnabled };

        spaceResult = await dispatch(spaceId ? updateSpace({ spaceId, spaceData }) : createSpace(spaceData));
        const space = unwrapResult(spaceResult);

        if (newBuildingIds) {
            const oldBuildingIds = buildingIds ?? [];

            const buildingIdsToAttach = newBuildingIds.filter((m) => !oldBuildingIds.includes(m));
            const buildingIdsToDetach = oldBuildingIds.filter((m) => !newBuildingIds.includes(m));

            buildingsAttachResult = await Promise.all(
                buildingIdsToAttach.map((buildingId) =>
                    dispatch(attachBuildingToSpace({ spaceId: space?.spaceId, buildingId }))
                )
            );

            buildingsDetachResult = await Promise.all(
                buildingIdsToDetach.map((buildingId) =>
                    dispatch(detachBuildingFromSpace({ spaceId: space?.spaceId, buildingId }))
                )
            );
        }

        if (
            isFulfilled(spaceResult) &&
            (!buildingsAttachResult || buildingsAttachResult.every(isFulfilled)) &&
            (!buildingsDetachResult || buildingsDetachResult.every(isFulfilled))) {
            dispatch(
                showSuccessNotification(`Space has been ${spaceId ? 'updated' : 'created'} successfully`)
            );
            const shouldFetchBuildings = !!buildingsDetachResult || !!buildingsAttachResult;
            if (shouldFetchBuildings) {
                await dispatch(fetchAllBuildingsInSpace(spaceId))
            }

        } else {
            dispatch(showErrorNotification(`Failed to ${spaceId ? 'update' : 'create'} space`));
        }

        onFormSubmitted();
    };

    const handleDelete = async () => {
        const result = await dispatch(deleteSpace(spaceId));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`Space has been deleted successfully`));
        } else {
            dispatch(showErrorNotification(`Failed to delete space`));
        }
    };

    useEffect(() => {
        if (buildingIds) {
            setValue('buildingIds', buildingIds);
        }
    }, [buildingIds, setValue]);

    if (hasError) {
        return <ErrorGeneral />;
    }

    return (
        <>
            <form onSubmit={handleSubmit(submitForm)}>
                <EditorWrapper>
                    <Header>Space</Header>
                    <Header>Buildings</Header>
                    <Header>Bundles</Header>

                    <div>
                        <TextField
                            name={'spaceDesc'}
                            inputRef={register({
                                validate: (value) =>
                                    (value && value.trim() !== '') || 'Please provide a valid space name',
                            })}
                            margin={'normal'}
                            fullWidth
                            label={'Description'}
                            error={errors?.spaceDesc}
                            helperText={errors?.spaceDesc?.message}
                        />

                        {spaceId && (
                            <TextField
                                label={'Space ID'}
                                margin={'normal'}
                                fullWidth
                                disabled
                                defaultValue={spaceId}
                            />
                        )}
                        <Controller
                            name={'exportPositionData'}
                            control={control}
                            render={({ onChange, value }) => (
                                <FormControlLabel
                                    label={'Enable exporting positioning raw data'}
                                    control={
                                        <Switch
                                            checked={value}
                                            onChange={(e) => onChange(e.target.checked)}
                                        />
                                    }
                                />
                            )}
                        />
                    </div>

                    <div>
                        <Controller
                            name={'buildingIds'}
                            control={control}
                            render={({ onChange, value }) => (
                                <CheckListWithSearch
                                    isLoading={isLoadingBuildings}
                                    filterLabel={'Filter buildings...'}
                                    onChange={(e) =>
                                        onChange(
                                            e.target.checked
                                                ? [...value, e.target.value]
                                                : value.filter((id) => id !== e.target.value)
                                        )
                                    }
                                    allValues={
                                        spaceId
                                            ? buildings.sort(
                                                (a, b) =>
                                                    space?.buildingIds?.includes(b.buildingId) -
                                                    space?.buildingIds?.includes(a.buildingId)
                                            )
                                            : buildings
                                    }
                                    checkedValues={value}
                                    getValueDesc={(b) => b.buildingName}
                                    getValueId={(b) => b.buildingId}
                                />
                            )}
                        />
                    </div>

                    <div>
                        <Controller
                            name={'bundleIds'}
                            control={control}
                            render={({ onChange, value }) => (
                                <ChipListWithInsert
                                    addLabel={'Add bundle'}
                                    values={value}
                                    onAdd={(bundle) => onChange([...(value ?? []), bundle])}
                                    onDelete={(bundle) => onChange((value ?? []).filter((b) => b !== bundle))}
                                />
                            )}
                        />
                        <div>
                            <Header>User Activity</Header>
                            <Tooltip content={canTriggerPositionAnalytics ? 'Generate User Activity' : `You do not have permission to perform this action`}>
                                <Button type='button' variant={'outlined'} onClick={openUserActivityModal} disabled={!canTriggerPositionAnalytics}>
                                    <GenerateSessionIcon style={{ marginRight: 5 }} size={20} />
                                    Generate User Activity
                                </Button>
                            </Tooltip>
                        </div>
                    </div>

                </EditorWrapper>

                <Grid container spacing={1} justifyContent={'flex-end'}>
                    {spaceId && (
                        <Grid item>
                            <Button variant={'outlined'} onClick={() => setIsRemoveConfirmDialogOpen(true)}>
                                <DeleteIcon size={20} />
                                Remove
                            </Button>
                        </Grid>
                    )}

                    <Grid item>
                        {isSubmitting ? (
                            <PulsePreloader />
                        ) : (
                            <Button type={'submit'}>
                                <SaveIcon size={20} />
                                Save
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </form>
            <FormProvider {...userActivityModalActions}>
                {showUserActivityModal && <UserActivityModal headerName={spaceDesc} spaceId={spaceId} open={showUserActivityModal} onClose={closeUserActivityModal} handleSubmit={onUserActivityModalSubmit} buildings={buildingsInSpace} control={control} />}
            </FormProvider>
            <RemoveConfirmDialog
                open={isRemoveConfirmDialogOpen}
                onConfirm={handleDelete}
                onCancel={() => setIsRemoveConfirmDialogOpen(false)}
            />
        </>
    );
}

SpaceEditor.propTypes = {
    space: PropTypes.object,
    onFormSubmitted: PropTypes.func,
};
