import React, { forwardRef, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
    Tooltip,
    CircularPreloader,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '../../common/themed';
import {
    getAreaOfInterestDeleteId,
    getAreaOfInterestChangeLayoutId,
    getAreaOfInterestChangeLayoutMenuId,
    getAreaOfInterestSendToBackId,
    getAreaOfInterestBringToFrontId,
} from '../Mapping.selectors';
import { BsLayersHalf as ChangeLayoutIcon } from 'react-icons/bs';
import { MdFlipToBack, MdFlipToFront } from 'react-icons/md';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import { IconButton, RootRef, Grid, Menu, MenuItem } from '@material-ui/core';
import { selectIsLoading } from '../../../state-management/status/statusSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { bringAreaToFront, sendAreaToBack } from '../../../state-management/user-inputs/mappingSlice';
import styled, { css } from 'styled-components';
import { deleteAreaOfInterest } from '../../../state-management/mapping/area-of-interest/areaOfInterestActions';
import { deleteElement } from '../../../state-management/mapping/elements/elementsActions';
import { selectAreaOfInterestById } from '../../../state-management/mapping/area-of-interest/areaOfInterestSelectors';
import { deleteExitRegion } from '../../../state-management/mapping/exitRegion/exitRegionsActions';
import { selectBuildingsSelectedBuildingId, selectBuildingsSelectedFloorId } from '../../../state-management/user-inputs/buildingsSlice';

const menuIconsCommonStyles = css`
    margin-inline-end: 10px;
    width: 1.5rem;
    height: 1.5rem;
`;

const SendToBackIcon = styled(MdFlipToBack)`
    ${menuIconsCommonStyles}
`;

const BringToFrontIcon = styled(MdFlipToFront)`
    ${menuIconsCommonStyles}
`;

const IconWrapper = forwardRef((props, ref) => (
    <RootRef rootRef={ref}>
        <IconButton {...props} />
    </RootRef>
));


const ACTIONS = {
    normal: {
        name: 'normal',
        delete: deleteAreaOfInterest,
    },
    element: {
        name: 'element',
        delete: deleteElement,

    },
    exitRegion: {
        name: 'exit region',
        delete: ({ areaId: exitRegionId, ...params }) => deleteExitRegion({ ...params, exitRegionId })
    },
}

export default function AreaOfInterestActions(props) {
    const { mapId, areaId, additionalActions, type = 'normal' } = props;

    const action = ACTIONS[type];
    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const selectedFloorId = useSelector(selectBuildingsSelectedFloorId);
    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
    const [layoutMenuAnchor, setLayoutMenuAnchor] = useState(null);
    const area = useSelector((state) => selectAreaOfInterestById(state, areaId));
    const dispatch = useDispatch();

    const selectIsDeleting = useMemo(
        () => selectIsLoading({ type: deleteAreaOfInterest.typePrefix, arg: { mapId, areaId } }),
        [areaId, mapId]
    );

    const isDeleting = useSelector(selectIsDeleting);

    const handleDelete = () => {
        const deleteAction = action.delete;
        dispatch(deleteAction({ buildingId: selectedBuildingId, floorId: selectedFloorId, mapId, areaId }));
    };

    const handleSendToBack = () => {
        dispatch(sendAreaToBack(areaId));
        setLayoutMenuAnchor(null);
    };

    const handleBringToFront = () => {
        dispatch(bringAreaToFront(areaId));
        setLayoutMenuAnchor(null);
    };

    return (
        <>
            <Grid container alignItems={'center'} justifyContent={'space-evenly'}>
                <Tooltip
                    content={'Delete ' + action.name}
                    placement={'bottom'}
                    useWrapper={false}
                >
                    <IconWrapper
                        id={getAreaOfInterestDeleteId(areaId)}
                        aria-label={'Delete ' + action.name}
                        onClick={() => setIsDeleteWarningOpen(true)}
                    >
                        <DeleteIcon />
                    </IconWrapper>
                </Tooltip>

                {type !== 'exitRegion' && <Tooltip content={'Change layout'} placement={'bottom'} useWrapper={false}>
                    <IconWrapper
                        id={getAreaOfInterestChangeLayoutId(areaId)}
                        aria-label={'Change layout'}
                        onClick={(e) => setLayoutMenuAnchor(e.target)}
                    >
                        <ChangeLayoutIcon />
                    </IconWrapper>
                </Tooltip>}

                <Menu
                    id={getAreaOfInterestChangeLayoutMenuId(areaId)}
                    anchorEl={layoutMenuAnchor}
                    open={!!layoutMenuAnchor}
                    onClose={() => setLayoutMenuAnchor(null)}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <MenuItem id={getAreaOfInterestSendToBackId(areaId)} onClick={handleSendToBack}>
                        <SendToBackIcon />
                        Send to back
                    </MenuItem>

                    <MenuItem id={getAreaOfInterestBringToFrontId(areaId)} onClick={handleBringToFront}>
                        <BringToFrontIcon />
                        Bring to front
                    </MenuItem>
                </Menu>

                {additionalActions}
            </Grid>

            {isDeleteWarningOpen && (
                <Dialog open closeable={false}>
                    <DialogContent>
                        <DialogContentText>
                            You are about to delete the {action.name} with the ID:
                            "{areaId}". Are you sure?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button variant={'text'} onClick={() => setIsDeleteWarningOpen(false)}>
                            Cancel
                        </Button>

                        {isDeleting ? <CircularPreloader /> : <Button onClick={handleDelete}>Accept</Button>}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

AreaOfInterestActions.propTypes = {
    mapId: PropTypes.string,
    areaId: PropTypes.string,
    additionalActions: PropTypes.node,
};
