import React from 'react';
import styled from 'styled-components';
import { Tab } from '@material-ui/core';

export default styled(props => (
    <Tab
        classes={{
            selected: 'themed-tab-selected',
        }}
        {...props}
    />
))`
    &.themed-tab-selected {
        color: coral; // TODO color should come from theme
    }
`;
