import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectHasError as getSelectHasError,
    selectIsLoading as getSelectIsLoading,
} from '../../../../state-management/status/statusSelectors';
import {
    selectPlaiData,
    selectRecordsCount,
} from '../../../../state-management/productLocations/productLocationsSelectors';
import {
    fetchExternalProductLocationsData as fetchExternal,
    fetchInternalProductLocationsData as fetchInternal,
    fetchRecordsCount,
} from '../../../../state-management/productLocations/productLocationsActions';
import { selectCommonSelectedSpaceId } from '../../../../state-management/user-inputs/commonSlice';

/**
 * Hook that fetches all data for PLAI service.
 * @param asObject - Return all entities as an object, with the IDs as the keys.
 * @return {{isLoading, data: (*), hasError}}
 */

export default function useProductLocations(options = {}) {
    const { asObject = false, type } = options;
    const action = type === 'internal' ? fetchInternal : fetchExternal;
    const dispatch = useDispatch();

    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);
    const selectIsLoading = useMemo(() => getSelectIsLoading({ type: action.typePrefix }), [selectedSpaceId]);

    const recordsCount = useSelector(selectRecordsCount);
    const plaiData = useSelector(selectPlaiData);
    const isLoading = useSelector(selectIsLoading);
    const hasError = false;

    const getProductLocationData = ({ filter, paginate, sort }) => {
        if (!isLoading && !hasError) {
            dispatch(action({ filter, paginate, sort }));
        }
    };

    const getRecordsCount = (filter) => {
        dispatch(fetchRecordsCount({ filter }));
    };

    return {
        data: asObject ? {} : plaiData,
        isLoading,
        hasError,
        selectedSpaceId,
        recordsCount,
        getProductLocationData,
        getRecordsCount,
    };
}
