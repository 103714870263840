import logger from '../logger/logger'
const log = logger.getLogger('ExpireStorage');

class ExpirationStorage {
    static setItem(key, jsonData, expireTimeMin = 30) {
        let expirationMS = expireTimeMin * 60 * 1000;
        let record = {value: JSON.stringify(jsonData), expiredAt: Date.now() + expirationMS};
        localStorage.setItem(key, JSON.stringify(record));
        return jsonData;
    }

    static getItem(key) {
        let data = localStorage.getItem(key);
        if (!data) {
            return null;
        }
        try {
            let record = JSON.parse(data);
            if (!record){ return null; }
            return Date.now() < record.expiredAt ? JSON.parse(record.value) : null;
        }
        catch (ex) {
            log.error(`getItem: failed to parse item: ${ex.message}`, ex);
            return null;
        }
    }

    static removeItem(key) {
        localStorage.removeItem(key);
    }

    static getItemExpireTime(key) {
        let data = localStorage.getItem(key);
        if (!data) {
            return null;
        }
        try {
            let record = JSON.parse(data);
            if (!record){ return null; }
            return record.expiredAt;
        }
        catch (ex) {
            log.error(`getItemExpireTime: failed to parse item: ${ex.message}`, ex);
            return null;
        }
    }
}

export default ExpirationStorage;
