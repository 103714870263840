import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Typography, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectCanEditEntrances } from '../../../../state-management/auth/authSelectors';
import * as PropTypes from 'prop-types';
import ItemsListEntry from '../../../common/side-drawer/ItemsListEntry';
import {
    getSelectIsEntranceHighlighted,
    highlightEntrance,
    selectBuildingsSelectedBuildingId,
    selectBuildingsSelectedEntranceId,
    selectBuildingsSelectedFloorId,
    unhighlightEntrance,
} from '../../../../state-management/user-inputs/buildingsSlice';
import {
    getEntranceListEntryDragImageId,
    getEntranceListEntryId,
    getEntranceListEntryNameId,
} from '../../buildings.selectors';
import EntranceActions from './EntranceActions';
import { HiStar as StarIcon } from 'react-icons/hi';
import { GiEntryDoor as EntranceIcon } from 'react-icons/gi';
import Tooltip from '../../../common/themed/Tooltip';

const Title = styled(Typography).attrs(() => ({ variant: 'subtitle1', noWrap: true }))`
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 200px;
    margin-inline-end: 5px;
`;

const Subtitle = styled(Typography).attrs(() => ({ variant: 'subtitle2', noWrap: true }))`
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 200px;
    display: flex;
    align-items: center;
    color: #848484;
`;

const DragImage = styled.div`
    display: none;
    background-color: #ff6200; // TODO color should come from theme
    color: #ffffff;
    padding: 12px;
    border-radius: 6px;

    &.dragged {
        display: block;
        position: absolute;
        top: -2000px;
    }

    title {
        color: #ffffff;
    }
`;

export default function EntranceListEntry(props) {
    const { entrance, ...otherProps } = props;
    const { entranceId, description, isMain } = entrance ?? {};

    const dispatch = useDispatch();

    const selectIsEntranceHighlighted = useMemo(
        () => getSelectIsEntranceHighlighted(entranceId),
        [entranceId]
    );

    const canEditEntrances = useSelector(selectCanEditEntrances);
    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const selectedFloorId = useSelector(selectBuildingsSelectedFloorId);
    const selectedEntranceId = useSelector(selectBuildingsSelectedEntranceId);
    const isHighlighted = useSelector(selectIsEntranceHighlighted) || selectedEntranceId === entranceId;

    const dragImageRef = useRef();

    // TODO consider using react-dnd or similar library
    const handleDragStart = (e) => {
        dragImageRef.current = document
            .getElementById(getEntranceListEntryDragImageId(entranceId))
            .cloneNode(true);
        dragImageRef.current.classList.add('dragged');
        document.body.appendChild(dragImageRef.current);

        e.dataTransfer.setDragImage(dragImageRef.current, 0, 0);
        e.dataTransfer.setData('oriient/entrance', JSON.stringify(entrance));
        e.dataTransfer.setData(`oriient/entrance_id:${entranceId}`, '');
    };

    const handleDragEnd = (e) => {
        e.preventDefault();

        if (dragImageRef.current) {
            document.body.removeChild(dragImageRef.current);
        }

        return false;
    };

    if (!entrance) {
        return null;
    }

    return (
        <>
            <Tooltip
                content={'Drag to an external region to attach...'}
                placement={'top'}
                enabled={canEditEntrances}
                useWrapper={false}
            >
                <ItemsListEntry
                    id={getEntranceListEntryId(entranceId)}
                    onPointerEnter={() => dispatch(highlightEntrance(entranceId))}
                    onPointerLeave={() => dispatch(unhighlightEntrance(entranceId))}
                    isDraggable={canEditEntrances}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    content={
                        <>
                            <Grid container alignItems={'center'}>
                                <Title id={getEntranceListEntryNameId(entranceId)}>{description}</Title>
                                {isMain && (
                                    <Tooltip content={'Primary entrance'} placement={'bottom'} useWrapper>
                                        <StarIcon size={25} />
                                    </Tooltip>
                                )}
                            </Grid>

                            <Subtitle>{entranceId}</Subtitle>
                        </>
                    }
                    actions={
                        <EntranceActions
                            buildingId={selectedBuildingId}
                            floorId={selectedFloorId}
                            entranceId={entranceId}
                            showEditPosition={false}
                        />
                    }
                    isHighlighted={isHighlighted}
                    {...otherProps}
                />
            </Tooltip>

            <DragImage id={getEntranceListEntryDragImageId(entranceId)}>
                <Grid container alignItems={'center'} spacing={2}>
                    <Grid item>
                        <EntranceIcon size={25} />
                    </Grid>
                    <Grid item>
                        <Typography variant={'h6'}> {description}</Typography>
                    </Grid>
                </Grid>
            </DragImage>
        </>
    );
}

EntranceListEntry.propTypes = {
    entrance: PropTypes.object,
};
