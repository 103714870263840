import React, { useState, useEffect, useReducer } from 'react';
import { reducer } from '../themed/Table/reducer';
import { convertFiltersForRequest, getTableState } from '../themed/Table/utils';

function getDefaultColumn(columns) {
    const defaultColumn = columns.find((col) => !!col.defaultSort);
    const defaultDirection = 'asc';
    if (defaultColumn) {
        return [
            {
                key: defaultColumn.field,
                direction: defaultColumn.defaultSort || defaultDirection,
            },
        ];
    }
    const [first, second] = columns; //first can be the expanded arrow of the table
    return [
        {
            key: first.expandedAll ? second.field : first.field,
            direction: defaultDirection,
        },
    ];
}

function initializeSort(item = null, columns = []) {
    const defaultColumn = getDefaultColumn(columns);
    return item ?? defaultColumn;
}

// The shape of the filters in the reducer is different when we send it to the server. therefore we using the convert function.

export default function useTable(options = {}) {
    const { name, columns, actions, dependencies = [] } = options;

    const deps = dependencies.includes(null) ? [] : dependencies;
    const savedState = React.useMemo(() => getTableState(columns, name), []);
    const [state, tableDispatch] = useReducer(reducer, savedState);
    const [filter, setFilter] = useState(state.table.filters);
    const [sort, setSort] = useState(initializeSort(state.table.sort, columns));
    const [paginate, setPaginate] = useState(state.table.paginate);
    const tableTheme = {
        palette: {
            primary: {
                main: '#4caf50',
            },
            secondary: {
                main: '#ff6200',
            },
        },
    };

    function handleFilter(filters) {
        setFilter(filters);
        handlePaginate(0, state.table.paginate.pageSize);
    }

    function handleSort(key, direction, expanded) {
        const updatedKey = expanded ? key - 1 : key;
        // Using the find method because the key is index based in the react material table, and we need the name of the columns for the server.
        const keyIndexFieldName = state.table.columns.find((_, i) => i === updatedKey).field;
        const updatedSort = [{ key: keyIndexFieldName, direction }];
        tableDispatch({
            type: 'setSort',
            payload: updatedSort,
        });
        setSort(updatedSort);

        //Reset paginate to the first page, and keep the current selected page-size
        handlePaginate(0, state.table.paginate.pageSize);
    }

    function handlePaginate(page, pageSize) {
        tableDispatch({ type: 'setPaginate', payload: { page, pageSize } });
        setPaginate({ page, pageSize });
    }

    // The columns and the sort are null when the initialize for the first time.
    useEffect(() => {
        if (!state.table.columns.length && !state.table.sort) {
            tableDispatch({ type: 'setColumns', payload: columns });
            const defaultColumn = getDefaultColumn(columns);
            tableDispatch({ type: 'setSort', payload: defaultColumn });
        }
    }, []);

    useEffect(() => {
        if (state && name) {
            localStorage.setItem(name, JSON.stringify(state));
        }
    }, [state]);

    useEffect(() => {
        if (state.table.filters && handleFilter) {
            handleFilter(state.table.filters);
        }
    }, []);

    useEffect(() => {
        if (actions?.fetchData && actions.fetchRecords) {
            const convertedFilters = convertFiltersForRequest(filter);
            actions.fetchData({ filter: convertedFilters, paginate, sort });
            actions.fetchRecords({ filter: convertedFilters });
        }
    }, [...deps]);

    useEffect(() => {
        if (actions?.fetchData && paginate && (filter || sort)) {
            if (filter && actions?.fetchRecords) {
                actions.fetchRecords({ filter: convertFiltersForRequest(filter) });
            }
            actions.fetchData({ filter: convertFiltersForRequest(filter), paginate, sort });
        }
    }, [...deps, filter, sort, paginate]);

    return {
        handleFilter,
        handleSort,
        handlePaginate,
        tableDispatch,
        state,
        tableTheme,
    };
}
