import styled from 'styled-components';
export const Form = styled.form`
    display: flex;
    width: 100%;

    flex-direction: column;
    flex-wrap: wrap;
    & > *:not(:last-child) {
        margin-right: 10px;
    }
    & > button {
        margin-left: auto;
    }

    & label {
        margin-left: 0;
    }
`;
