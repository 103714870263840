import signMarkApi from '../../../api/signMarkApi';
import { createAsyncThunk } from '../../utils';

export const fetchAllSignMarkInMap = createAsyncThunk('signMark/fetchAllInMap', async (mapId) => {
    return (await signMarkApi.fetchAllSignMarkInMap(mapId)).data;
});

export const createSignMark = createAsyncThunk(
    'signMarks/create',
    async ({ buildingId, floorId, mapId, createdSignMark }, thunkAPI) => {
        try {
            const data = await signMarkApi.createSignMark({ buildingId, floorId, mapId }, createdSignMark);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateSignMark = createAsyncThunk(
    'signMarks/update',
    async ({ buildingId, floorId, mapId, signMarkId, updatedSignMark }, thunkAPI) => {
        try {
            const data = await signMarkApi.updateSignMark(
                { buildingId, floorId, mapId, signMarkId },
                updatedSignMark
            );
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const deleteSignMark = createAsyncThunk(
    'signMarks/delete',
    async ({ buildingId, floorId, mapId, signMarkId }) => {
        return signMarkApi.deleteSignMark({ buildingId, floorId, mapId, signMarkId });
    }
);
