import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BsExclamationSquareFill as AreaIcon } from 'react-icons/bs';
import { IoAnalytics as LineIcon } from 'react-icons/io5';
import { AiFillBuild as ElementIcon } from 'react-icons/ai';
import { IoCropSharp as LaneCropIcon } from 'react-icons/io5';
import { FaRegBookmark } from "react-icons/fa";
import MapViewerCreateNewItemOverlay from '../../common/map-viewer/overlays/create-new-item';
import {
    selectMappingIsAreaOfInterestCreationOpen,
    selectMappingIsLineOfInterestCreationOpen,
    selectMappingIsElementCreationOpen,
    selectMappingIsSideDrawerOpen,
    setIsAreaOfInterestCreationOpen,
    setIsLineOfInterestCreationOpen,
    setIsElementCreationOpen,
    setIsMapCroppingCreationOpen,
    setIsSignMarkCreationOpen,
    selectMapCroppingCreationOpen,
    selectSignMarkCreationOpen,
} from '../../../state-management/user-inputs/mappingSlice';
import { selectCanCropLane, selectCanUpsetSignMark } from '../../../state-management/auth/authSelectors';
import { selectHasDeletedLanes } from '../../../state-management/mapping/lane/laneSelectors';

export const CreateNewItemOverlay = () => {
    const dispatch = useDispatch();

    const isAreaOfInterestCreationOpen = useSelector(selectMappingIsAreaOfInterestCreationOpen);
    const isLineOfInterestCreationOpen = useSelector(selectMappingIsLineOfInterestCreationOpen);
    const isElementsCreationOpen = useSelector(selectMappingIsElementCreationOpen);
    const isSideDrawerOpen = useSelector(selectMappingIsSideDrawerOpen);
    const isMapCroppingCreationOpen = useSelector(selectMapCroppingCreationOpen);
    const isSignMarkCreationOpen = useSelector(selectSignMarkCreationOpen);
    const canCropLane = useSelector(selectCanCropLane);
    const canUpsertSignMark = useSelector(selectCanUpsetSignMark)
    const hasDeletedMaps = useSelector(selectHasDeletedLanes);

    const creationActions = useMemo(
        () => [
            {
                label: 'Sign Mark',
                icon: <FaRegBookmark size={25} />,
                onClick: () => dispatch(setIsSignMarkCreationOpen(true)),
                canAccess: canUpsertSignMark,
            },
            {
                label: 'Area of interest',
                icon: <AreaIcon size={20} />,
                onClick: () => dispatch(setIsAreaOfInterestCreationOpen(true)),
            },
            {
                label: 'Line of interest',
                icon: <LineIcon size={25} />,
                onClick: () => dispatch(setIsLineOfInterestCreationOpen(true)),
            },
            {
                label: 'Element',
                icon: <ElementIcon size={25} />,
                onClick: () => dispatch(setIsElementCreationOpen(true)),
            },
            {
                label: 'Delete Area',
                icon: <LaneCropIcon size={25} />,
                onClick: () => dispatch(setIsMapCroppingCreationOpen(true)),
                canAccess: canCropLane && !hasDeletedMaps,
            },

        ],
        [dispatch, hasDeletedMaps]
    );

    return (
        <MapViewerCreateNewItemOverlay
            actions={creationActions}
            isHidden={
                isAreaOfInterestCreationOpen ||
                isLineOfInterestCreationOpen ||
                isElementsCreationOpen ||
                isMapCroppingCreationOpen ||
                isSignMarkCreationOpen
            }
            isPushed={isSideDrawerOpen}
        />
    );
};
