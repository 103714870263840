import http from '../utils/httpClient';
import Api from './Api';

class MapQualityApi extends Api {
    async fetchedMapQualityData({ filter = [], paginate = {}, sort }) {
        return await this.callApi((params) => {
            return http.mapQuality.v1.get('/map-quality', {
                params: { ...params, apiParams: { paginate, filter, sort } },
            });
        });
    }

    async fetchedRecordsCount({ filter }) {
        return await this.callApi((params) => {
            return http.mapQuality.v1.get('/map-quality/count', {
                params: {
                    ...params,
                    apiParams: { filter },
                },
            });
        });
    }

    fetchInvestigationData({ buildingId, mapId }) {
        return this.callApi((params) =>
            http.mapQuality.v1.get(`/map-quality/buildings/${buildingId}/maps/${mapId}`, { params })
        );
    }

    async fetchInvestigationImageUrl({ buildingId, mapId, createdOn, fileName, source }) {
        return await this.callApi((params) =>
            http.mapQuality.v1.get(`/map-quality/images/${fileName}`, {
                cancelToken: source.token,
                params: { ...params, apiParams: { buildingId, mapId, createdOn } },
            })
        );
    }

    async fetchMapQualityImages({ buildingId, mapId, createdOn }) {
        return await this.callApi((params) =>
            http.mapQuality.v1.get('/map-quality/images', {
                params: { ...params, buildingId, mapId, createdOn },
            })
        );
    }
}

export default new MapQualityApi();
