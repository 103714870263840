import { createAsyncThunk } from '../utils';
import usersApi from '../../api/UsersApi';
import { selectUserApiKeyId } from '../auth/authSelectors';

export const fetchAllUsers = createAsyncThunk('users/fetchAll', async (_, { getState }) => {
    const userApiKeyId = selectUserApiKeyId(getState());
    const response = (await usersApi.fetchAllUsers()).data;
    return response.filter(({ apiKeyId }) => apiKeyId !== userApiKeyId);
});

export const fetchAllUsersAttachedToApiKey = createAsyncThunk(
    'users/fetchAllAttachedToApiKey',
    async (apiKeyId) => {
        return (await usersApi.fetchAllUsersAttachedToApiKey(apiKeyId)).data;
    }
);

export const createUser = createAsyncThunk('users/create', async (userData) => {
    return (await usersApi.createUser(userData)).data;
});

export const deleteUser = createAsyncThunk('user/delete', async (userId) => {
    return (await usersApi.deleteUser(userId)).data;
});
