import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import SideMenuSection from '../../common/side-drawer/SideMenuSection';
import {
    Button,
    CircularPreloader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Switch,
    SwitchLabel as Label,
} from '../../common/themed';
import {
    selectMappingAreMapValidationsShown,
    selectMappingSelectedMapId,
    setAreMapValidationsShown,
} from '../../../state-management/user-inputs/mappingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel } from '@material-ui/core';
import { selectMapById } from '../../../state-management/map/mapSelectors';
import { deleteAllValidationsInMap } from '../../../state-management/mapping/map-validation/mapValidationActions';
import { selectIsLoading } from '../../../state-management/status/statusSelectors';

const ResetButton = styled(Button)`
    width: 100%;
`;

export default function MappingSideMenuMapValidationsSection() {
    const dispatch = useDispatch();

    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const selectedMap = useSelector((state) => selectMapById(state, selectedMapId));
    const areMapValidationsShown = useSelector(selectMappingAreMapValidationsShown);

    const selectIsDeleting = useMemo(
        () => selectIsLoading({ type: deleteAllValidationsInMap.typePrefix, arg: selectedMapId }),
        [selectedMapId]
    );

    const isDeleting = useSelector(selectIsDeleting);

    const handleDelete = async () => {
        await dispatch(deleteAllValidationsInMap(selectedMapId));
        setIsDeleteWarningOpen(false);
    };

    if (!selectedMapId) {
        return null;
    }

    return (
        <>
            <SideMenuSection title={'Map Validations'}>
                <FormControlLabel
                    label={<Label>Show map validations</Label>}
                    control={
                        <Switch
                            checked={areMapValidationsShown}
                            onChange={(e) => dispatch(setAreMapValidationsShown(e.target.checked))}
                        />
                    }
                />
                <ResetButton
                    variant={'outlined'}
                    disabled={!areMapValidationsShown}
                    onClick={() => setIsDeleteWarningOpen(true)}
                >
                    Reset validation
                </ResetButton>
            </SideMenuSection>

            {isDeleteWarningOpen && (
                <Dialog open closeable={false}>
                    <DialogContent>
                        <DialogContentText>
                            You are about to delete all validation points in the map "{selectedMap?.mapName}".
                            Are you sure?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button variant={'text'} onClick={() => setIsDeleteWarningOpen(false)}>
                            Cancel
                        </Button>

                        {isDeleting ? <CircularPreloader /> : <Button onClick={handleDelete}>Accept</Button>}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
