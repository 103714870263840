import { useDispatch, useSelector } from 'react-redux';
import {
    selectBuildingsSelectedBuildingId,
    selectBuildingsSelectedFloorId,
} from '../../../../state-management/user-inputs/buildingsSlice';
import { useEffect, useMemo } from 'react';
import { selectHasError, selectIsLoading } from '../../../../state-management/status/statusSelectors';
import { fetchAllSignMarkInMap } from '../../../../state-management/mapping/sign-mark/signMarkActions';
import {
    selectAllSignMarks,
    selectSignMarkById,
} from '../../../../state-management/mapping/sign-mark/signMarkSelectors';
import { selectMapFeatureById } from '../../../../state-management/map-features/mapFeaturesSelectors';

export const useSignMarks = ({ mapId, signMarkId }) => {
    const dispatch = useDispatch();
    const buildingId = useSelector(selectBuildingsSelectedBuildingId);
    const floorId = useSelector(selectBuildingsSelectedFloorId);

    const actions = useMemo(
        () => [...(mapId ? [{ type: fetchAllSignMarkInMap.typePrefix, arg: { mapId } }] : [])],
        [signMarkId, mapId]
    );

    const isLoadingSelector = useMemo(() => selectIsLoading(actions), [actions]);
    const isLoading = useSelector(isLoadingSelector);
    const hasErrorSelector = useMemo(() => selectHasError(actions), [actions]);
    const hasError = useSelector(hasErrorSelector);
    const dependencies = [dispatch, buildingId, floorId, mapId];
    const canFetch = dependencies.every(Boolean) && !isLoading;
    const signMarks = useSelector(selectAllSignMarks) || [];
    const signMark = useSelector((state) => {
        return selectSignMarkById(state, signMarkId);
    });

    useEffect(() => {
        if (canFetch) {
            dispatch(fetchAllSignMarkInMap({ buildingId, floorId, mapId }));
        }
    }, [...dependencies]);

    const data = signMarkId ? signMark : signMarks;

    return {
        data,
        isLoading,
        hasError,
    };
};
