import styled from 'styled-components';
import clsx from 'clsx';

export const Toggle = styled.button.attrs(({ isSelected, isDisabled, ...otherProps }) => ({
    className: clsx({
        selected: !!isSelected,
        disabled: !!isDisabled,
    }),
    disabled: !!isDisabled,
    ...otherProps,
}))`
    padding: 10px;
    min-width: 4rem;
    font-size: 1rem;
    background-color: white; // TODO color should come from theme
    color: #333333; // TODO color should come from theme;
    border: 1px solid #ff6200; // TODO color should come from theme
    border-radius: 3px;
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    transition: all 200ms ease;
    outline: none;

    &.selected {
        background-color: #ff6200;
        color: white;
    }

    &.disabled {
        background-color: #c4c4c4;
        color: #828282;
    }

    :hover {
        background-color: #ffd0b3;

        &.selected {
            background-color: coral;
        }

        &.disabled {
            background-color: #c4c4c4;
        }
    }
`;

export const ToggleGroup = styled.div`
    ${Toggle}:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    ${Toggle}:not(:last-child) {
        border-right: none;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
`;
