import React from 'react';
import * as PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import isEqual from 'react-fast-compare';
import { PulseLoader } from 'react-spinners';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Tooltip } from '../../common/themed';
import InfoIcon from '@material-ui/icons/Info';
import SessionDialog from './SessionDialog';
import {
    getAllSessionsObject,
    getSelectedSessionWithPositions,
} from '../../../state-management/analytics/session-analytics/sessionAnalyticsSelectors';
import { setSelectedSession } from '../../../state-management/analytics/session-analytics/sessionAnalyticsActions';
import { selectIsOmk, selectUserPermissions } from '../../../state-management/auth/authSelectors';

const SessionsTable = styled(Table)`
    td {
        justify-content: center;
    }

    th {
        width: 25%;
    }
`;

const ClickableTableRow = styled(TableRow)`
    cursor: pointer;
`;

const LoadingTableCell = styled(TableCell)`
    cursor: not-allowed;
    background-color: #ebebeb;
`;

const MoreInfoIcon = styled(InfoIcon)`
    width: 0.7em;
    height: 0.7em;
    margin-left: 5px;
    fill: orange; // TODO color should come from theme
`;

class SessionsGroupTable extends React.Component {
    state = {
        isSessionTimelineDialogOpen: false,
        selectedSession: null,
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const { sessions, selectedSession, isSessionTimelineDialogOpen } = this.props;

        const getIsProcessed = (s) => s.isProcessed;

        return (
            !isEqual(nextProps.sessions.map(getIsProcessed), sessions.map(getIsProcessed)) ||
            !isEqual(nextProps.selectedSession, selectedSession) ||
            nextProps.isSessionTimelineDialogOpen !== isSessionTimelineDialogOpen
        );
    }

    handleSessionTimelineOpen = (session) => {
        const { setSelectedSession } = this.props;

        setSelectedSession(session.sessionId);

        this.setState({
            isSessionTimelineDialogOpen: true,
        });
    };

    handleSessionTimelineClose = () => {
        const { setSelectedSession } = this.props;

        this.setState(
            {
                isSessionTimelineDialogOpen: false,
            },
            () => {
                setSelectedSession(null);
            }
        );
    };

    renderTableRow = (session, index) => {
        const { headers, valuesFunc, isOmk } = this.props;
        const { isProcessed, sessionId, engineVersion } = session;

        if (!isProcessed) {
            return (
                <TableRow key={`session-row-${index}`}>
                    <LoadingTableCell colSpan={headers.length} align={'center'}>
                        <PulseLoader color={'coral'} margin={'5px'} />
                        {/*todo color should come from theme*/}
                    </LoadingTableCell>
                </TableRow>
            );
        }

        return (
            <ClickableTableRow
                key={`session-row-${index}`}
                hover
                onClick={() => this.handleSessionTimelineOpen(session)}
            >
                {valuesFunc(session).map((value, index) => (
                    <TableCell key={value}>
                        {value}
                        {index === 0 && (
                            <Tooltip
                                content={
                                    <span>
                                        Session ID: {sessionId}
                                    </span>
                                }
                                interactive
                            >
                                <MoreInfoIcon />
                            </Tooltip>
                        )}
                    </TableCell>
                ))}
            </ClickableTableRow>
        );
    };

    render() {
        const { sessions, headers, selectedSession } = this.props;
        const { isSessionTimelineDialogOpen } = this.state;

        return (
            <>
                <SessionsTable>
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell key={header}>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>{sessions.map(this.renderTableRow)}</TableBody>
                </SessionsTable>

                {selectedSession && isSessionTimelineDialogOpen && (
                    <SessionDialog
                        isOpen={isSessionTimelineDialogOpen}
                        session={selectedSession}
                        onClose={this.handleSessionTimelineClose}
                    />
                )}
            </>
        );
    }
}

SessionsGroupTable.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    valuesFunc: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    sessions: ownProps.sessionIds.map((id) => getAllSessionsObject(state)[id]),
    selectedSession: getSelectedSessionWithPositions(state),
    permissions: selectUserPermissions(state),
    isOmk: selectIsOmk(state),
});

const mapDispatchToProps = {
    setSelectedSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionsGroupTable);
