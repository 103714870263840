import React, { useRef } from 'react';
import { DetailTitle, SummaryWrapper } from './components';
import { RootRef, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { MdExpandMore as ExpandMoreIcon } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { selectCommonSelectedSpaceId } from '../../../state-management/user-inputs/commonSlice';
import useSpaces from '../../common/hooks/data-fetching/useSpaces';
import { SpaceForm } from './SpaceForm';

export const SpaceConfig = (props) => {
    const { setSelectedSpace } = props;
    const { data: spaces, isLoading: spaceIsLoading, hasError } = useSpaces({ asObject: true });
    const spacesArray = Object.values(spaces);
    const targetRef = useRef();
    const accordionRef = useRef();
    const selectedSpaceId = useSelector(selectCommonSelectedSpaceId);

    const selectedSpace = props.selectedSpace || spaces?.[selectedSpaceId];

    return (
        <RootRef rootRef={targetRef}>
            <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <SummaryWrapper>
                        <DetailTitle>Map Quality Settings</DetailTitle>
                    </SummaryWrapper>
                </AccordionSummary>

                <AccordionDetails rootRef={accordionRef}>
                    <SpaceForm
                        setSelectedSpace={setSelectedSpace}
                        spaceIsLoading={spaceIsLoading}
                        spacesArray={spacesArray}
                        selectedSpace={selectedSpace}
                    />
                </AccordionDetails>
            </Accordion>
        </RootRef>
    );
};
