import React, { memo, useContext, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import {
    getMapViewerCreateNewItemActionId,
    getMapViewerCreateNewItemOverlayId,
    getMapViewerCreateNewItemSpeedDialButtonClassName,
    getMapViewerCreateNewItemSpeedDialClassName,
    getMapViewerCreateNewItemSpeedDialId,
} from './MapViewerCreateNewItemOverlay.selectors';
import clsx from 'clsx';
import { Portal } from '@material-ui/core';
import { MapViewerContext } from '../../MapViewerContext';

import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    staticTooltipLabel: {
        width: 'max-content',
        fontSize: '0.7rem',
        backgroundColor: '#7a7474',
        color: 'white',
    },
});

const OverlayWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;

    pointer-events: none; // Prevent the whole div from capturing pointer events

    * {
        pointer-events: auto;
    }
`;

const CreateNewItemButton = styled(SpeedDial)`
    &.${getMapViewerCreateNewItemSpeedDialClassName()} {
        margin-block-end: 25px;
        margin-inline-end: 25px;
        transition: all 200ms ease;

        &.pushed {
            margin-inline-end: 310px;
        }
        &.create-new-item-speed-dial {
            margin-block-end: ${({ marginTopEnd }) => {
                return marginTopEnd;
            }};
        }
    }

    & .${getMapViewerCreateNewItemSpeedDialButtonClassName()} {
        background-color: #ff6200; // TODO color should come from theme
    }
`;

function MapViewerCreateNewItemOverlay(props) {
    const { actions, isHidden = false, isPushed = false, ...otherProps } = props;
    const bannerExist = useSelector((state) => !!state.banner.banners.length);
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    const { containerRef } = useContext(MapViewerContext);

    if (!actions || actions?.length === 0) {
        return null;
    }

    return (
        <Portal container={containerRef.current}>
            <OverlayWrapper id={getMapViewerCreateNewItemOverlayId()} {...otherProps}>
                <CreateNewItemButton
                    id={getMapViewerCreateNewItemSpeedDialId()}
                    ariaLabel={'Create new item'}
                    classes={{
                        root: getMapViewerCreateNewItemSpeedDialClassName(),
                        fab: getMapViewerCreateNewItemSpeedDialButtonClassName(),
                    }}
                    className={clsx({ pushed: isPushed })}
                    icon={<SpeedDialIcon />}
                    hidden={isHidden}
                    onBlur={() => setIsOpen(false)}
                    onClick={() => setIsOpen(!isOpen)}
                    onClose={() => setIsOpen(false)}
                    onFocus={() => setIsOpen(true)}
                    onPointerEnter={() => setIsOpen(true)}
                    onPointerLeave={() => setIsOpen(false)}
                    open={isOpen && !isHidden}
                    marginTopEnd={bannerExist ? '72px' : '25px'}
                >
                    {actions.map(
                        ({ label, icon, onClick, canAccess = true }) =>
                            canAccess && (
                                <SpeedDialAction
                                    classes={{
                                        staticTooltipLabel: classes.staticTooltipLabel,
                                    }}
                                    key={label}
                                    id={getMapViewerCreateNewItemActionId(label)}
                                    icon={icon}
                                    tooltipTitle={label}
                                    tooltipOpen
                                    onClick={onClick}
                                />
                            )
                    )}
                </CreateNewItemButton>
            </OverlayWrapper>
        </Portal>
    );
}

MapViewerCreateNewItemOverlay.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, icon: PropTypes.node, onClick: PropTypes.func })
    ).isRequired,
    isHidden: PropTypes.bool,
};

export default memo(MapViewerCreateNewItemOverlay);
