import entityAdapter from './userEntityAdapter';
import { createSelector } from '@reduxjs/toolkit';
import { selectAllApiKeys, selectApiKeyById } from '../api-key/apiKeySelectors';

export const {
    selectById: selectUserById,
    selectIds: selectUserIds,
    selectEntities: selectUserEntities,
    selectAll: selectAllUsers,
    selectTotal: selectTotalUsers,
} = entityAdapter.getSelectors((state) => state.users);

export const selectIsFetched = (state) => state.users.isFetched;

export const selectAllUsersAttachedToAllApiKeys = createSelector(
    selectAllApiKeys,
    selectUserEntities,
    (apiKeys, users = {}) => {
        return {
            asArray: apiKeys?.reduce(
                (result, { userIds }) => [...result, ...(userIds?.map((id) => users?.[id]) ?? [])],
                []
            ),
            asObject: apiKeys?.reduce(
                (result, { userIds }) => ({
                    ...result,
                    ...(userIds?.reduce((res, id) => ({ ...res, [id]: users?.[id] }), {}) ?? {}),
                }),
                {}
            ),
        };
    }
);

export const getSelectUsersAttachedToApiKey = (apiKeyId) =>
    createSelector(
        (state) => selectApiKeyById(state, apiKeyId),
        selectUserEntities,
        (apiKey, users = {}) => ({
            asArray: apiKey?.userIds?.map((id) => users?.[id]),
            asObject: apiKey?.userIds?.reduce((res, id) => ({ ...res, [id]: users?.[id] }), {}),
        })
    );
