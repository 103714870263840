import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Drawer as MuiDrawer } from '@material-ui/core';
import { Button } from '../themed';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { getSideDrawerMenuContentId, getSideDrawerMenuId } from './SideDrawer.selectors';
import clsx from 'clsx';

const Drawer = styled(MuiDrawer)`
    z-index: 1000;
    visibility: visible;
    width: 0;

    & .side-drawer-paper {
        margin-block-start: 4rem;
        height: calc(100% - 4rem);
        width: 300px;
        overflow: visible;
    }
`;

const DrawerContent = styled.div`
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
`;

const SideDrawerToggleButton = styled(Button)`
    position: absolute;
    left: -50px;
    top: 50px;
    transform: rotate(90deg);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    visibility: visible;
`;

export default function SideDrawer(props) {
    const { isOpen = false, onToggle, children } = props;

    return (
        <Drawer
            id={getSideDrawerMenuId()}
            classes={{ paper: 'side-drawer-paper' }}
            className={clsx({ open: isOpen, closed: !isOpen })}
            open={isOpen}
            anchor={'right'}
            hideBackdrop
            keepMounted
            disableEnforceFocus
        >
            <SideDrawerToggleButton onClick={onToggle}>
                {isOpen ? <MdKeyboardArrowUp size={'1.5rem'} /> : <MdKeyboardArrowDown size={'1.5rem'} />}
            </SideDrawerToggleButton>

            <DrawerContent id={getSideDrawerMenuContentId()}>{children}</DrawerContent>
        </Drawer>
    );
}

SideDrawer.propTypes = {
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
};
