import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCanResetMapToManual } from '../../../state-management/auth/authSelectors';
import { noPermissionMessage } from '../../../state-management/constants';
import { closeDialog } from '../../../state-management/general/generalReducer';
import { resetToManualMapping } from '../../../state-management/map/mapActions';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../state-management/notification/notificationReducer';
import { isFulfilled, isRejected } from '../../../state-management/utils';

export const useResetToManualMapping = (onSuccess = () => {}) => {
    const dispatch = useDispatch();

    const hasResetPermission = useSelector(selectCanResetMapToManual);

    const resetToManualMappingHandler = async ({ buildingId, floorId, mapId }) => {
        try {
            if (!hasResetPermission) {
                throw new Error(noPermissionMessage);
            }
            const result = await dispatch(resetToManualMapping({ buildingId, floorId, mapId }));
            if (isFulfilled(result)) {
                dispatch(closeDialog());
                dispatch(showSuccessNotification('Reset successfully'));
                await onSuccess();
            } else if (isRejected(result)) {
                const errorMsg = result.payload?.message || 'Failed to reset!';
                throw new Error(errorMsg);
            }
        } catch (err) {
            dispatch(showErrorNotification(err.message));
        }
    };

    return {
        resetToManualMappingHandler,
    };
};
