import styled from 'styled-components';

const getDropZoneBorderColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#cccccc';
};

export default styled.div`
    width: 10rem;
    height: 10rem;
    display: ${(props) => (props.isHidden ? 'none' : 'flex')};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px dashed ${(props) => getDropZoneBorderColor(props)};
    border-radius: 2px;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
`;
