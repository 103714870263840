// @ts-nocheck
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    selectBuildingsSelectedBuildingId,
    selectMapImagesChecked,
    setMapImageChecked,
} from '../../../../state-management/user-inputs/buildingsSlice';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../state-management/notification/notificationReducer';
import {
    selectHasError as getSelectHasError,
    selectIsLoading as getSelectIsLoading,
} from '../../../../state-management/status/statusSelectors';
import { validateBuildingRouting } from '../../../../state-management/building/buildingActions';
import { isFulfilled } from '../../../../state-management/utils';
import {
    selectNavgraphErrorsById,
    setNavgraphErrors,
} from '../../../../state-management/building/buildingReducer';
import {
    selectBuildingById,
    selectBuildingIdToFloorsArray,
    selectBuildingIdToFloorsObject,
} from '../../../../state-management/building/buildingSelectors';
import { setSelectedFloorId } from '../../../../state-management/user-inputs/analyticsUserActivitySlice';
import { selectMappingSelectedMapId } from '../../../../state-management/user-inputs/mappingSlice';
import { selectMapById } from '../../../../state-management/map/mapSelectors';
export default function useValidateRouting() {
    const dispatch = useDispatch();

    const buildingId = useSelector(selectBuildingsSelectedBuildingId);
    const mapId = useSelector(selectMappingSelectedMapId);
    const map = useSelector((state) => selectMapById(state, mapId));
    const buildingsArray = useSelector(selectBuildingIdToFloorsArray);
    const errors = useSelector((state) => selectNavgraphErrorsById(state, mapId));
    const mapImagesChecked = useSelector(selectMapImagesChecked);
    const [shouldShowErrors, setShouldShowErrors] = useState(true);
    useEffect(() => {
        if (mapImagesChecked) {
            const image = map?.mapImages?.find((img) => img.mapUsage === 'navgraph');

            if (image) {
                setShouldShowErrors(mapImagesChecked[image.imageId] || false);
            }
        }
    }, [mapImagesChecked]);

    const selectIsLoading = useMemo(
        () =>
            getSelectIsLoading({
                type: validateBuildingRouting.typePrefix,
                arg: { buildingId },
            }),
        [buildingId]
    );

    const selectHasError = useMemo(
        () =>
            getSelectHasError({
                type: validateBuildingRouting.typePrefix,
                arg: { buildingId },
            }),
        [buildingId]
    );
    const isLoading = useSelector(selectIsLoading);
    const hasError = useSelector(selectHasError);

    const onValidateRouting = useCallback(async () => {
        const result = await dispatch(validateBuildingRouting({ buildingId }));

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification('Routing validation passed successfully, well done!!'));
        } else {
            if (result.payload && result.payload.response && result.payload.response.data) {
                const { message } = result.payload.response.data;
                dispatch(
                    showErrorNotification(
                        `Routing validation failed, please see errors marked on the map.
                        ${message}`
                    )
                );

                const coordinates = result.payload.response.data.data.err_coordinates;
                const floorIndex = result.payload.response.data.data.floorIndex;
                const badFloor = buildingsArray[buildingId]?.find((f) => f.floorIndex === floorIndex);

                if (Array.isArray(coordinates) && coordinates.length > 1 && badFloor) {
                    dispatch(setSelectedFloorId(badFloor.floorId));
                    dispatch(
                        setNavgraphErrors({
                            mapId: badFloor.mapIds?.[0],
                            errors: Array.from({ length: coordinates.length / 2 }, (_, i) => ({
                                x: coordinates[2 * i],
                                y: coordinates[2 * i + 1],
                            })),
                        })
                    );
                }
            }
        }
    }, []);

    useEffect(() => {
        if (errors && map) {
            const image = map.mapImages?.find((img) => img.mapUsage === 'navgraph');
            if (image) {
                dispatch(setMapImageChecked({ imageId: image.imageId, isChecked: true }));
            }
        }
    }, [mapId]);
    return {
        onValidateRouting,
        isLoading,
        hasError,
        errors,
        shouldShowErrors,
    };
}
