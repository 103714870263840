import Api from './Api';
import http from '../utils/httpClient';

class SpacesApi extends Api {
    async fetchAllSpaces() {
        return await this.callApi((params) => {
            return http.v1.get(`/space`, { params });
        });
    }

    async createSpace(spaceData) {
        return await this.callApi((params) => http.v1.post(`/space`, spaceData, { params }));
    }

    async updateSpace(spaceId, spaceData) {
        return await this.callApi((params) => http.v1.post(`/space/${spaceId}`, spaceData, { params }));
    }

    async deleteSpace(spaceId) {
        return await this.callApi((params) => http.v1.delete(`/space/${spaceId}`, { params }));
    }

    async attachBuildingToSpace(spaceId, buildingId) {
        return await this.callApi((params) =>
            http.v3.post(`/space/${spaceId}/attach-building`, { buildingId }, { params })
        );
    }

    async detachBuildingFromSpace(spaceId, buildingId) {
        return await this.callApi((params) =>
            http.v3.post(`/space/${spaceId}/detach-building`, { buildingId }, { params })
        );
    }
}

export default new SpacesApi();
