import TimePeriod from './TimePeriod';

class Custom extends TimePeriod {
    get label() {
        return 'Custom';
    }

    get value() {
        return 'CUSTOM';
    }

    getTimeSpan() {
        return { start: null, end: null };
    }

    get isPickable() {
        return true;
    }

    get isRange() {
        return true;
    }
}

export default new Custom();
