import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Banner } from '../../alert-banners/Banner/Banner';

const Container = styled(Grid)`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-bottom: 15px;
    transition: all 0.2s;
`;

export const BannerOverlay = () => {
    const { banners = [] } = useSelector((state) => state.banner);
    return (
        <Container>
            {banners.map(({ id, type, ...rest }) => {
                return <Banner key={id} {...rest} />;
            })}
        </Container>
    );
};
