import React, { Component } from 'react';
import * as AmCharts from '@amcharts/amcharts4/charts';
import * as PropTypes from 'prop-types';
import barMovementConfig from './config/Bar.Movement.config';
import Chart from './Chart';
import getSelectors from '../Analytics.selectors';

class MovementDistributionBarChart extends Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const { data } = this.props;

        return nextProps.data.isProcessed !== data.isProcessed;
    }

    render() {
        const { uniqueId, data, onInitialize, onUnmount } = this.props;
        const { BAR_CONTAINER_ID } = getSelectors(uniqueId).charts;

        const { isProcessed, movements } = data;

        if (!isProcessed) {
            return null;
        }

        return (
            <Chart
                id={BAR_CONTAINER_ID}
                config={barMovementConfig({ movements })}
                type={AmCharts.XYChart}
                onInitialize={onInitialize}
                onUnmount={onUnmount}
            />
        );
    }
}

MovementDistributionBarChart.propTypes = {
    uniqueId: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    onInitialize: PropTypes.func,
    onUnmount: PropTypes.func,
};

MovementDistributionBarChart.defaultProps = {
    onInitialize: () => {},
    onUnmount: () => {},
};

export default MovementDistributionBarChart;
