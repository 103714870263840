import { createSlice } from '@reduxjs/toolkit';
import entityAdapter from './elementsEntityAdapter';
import { expireSession, logout } from '../../auth/authActions';
import {
    fetchAllElementsInMap,
    fetchElementAttachments,
    updateElement,
    createElement,
    deleteElement,
} from './elementsActions';
import { elementsUpdated, elementsDeleted, elementsCreated } from '../mappingEventsActions';

const { getInitialState, upsertOne, setAll, removeOne } = entityAdapter;

const upsertOneElement = (state, action) => {
    const data = action.payload?.data || action.payload;
    const {
        areaId,
        topRight,
        bottomLeft,
        comment,
        attachment,
        type,
        title,
        mapperName,
        createdAt,
        lastUpdate,
    } = data;
    upsertOne(state, {
        areaId,
        topRight,
        bottomLeft,
        comments: comment ? [comment] : [],
        hasAttachments: !!attachment,
        attachments: attachment ? [attachment] : [],
        attachment,
        type,
        title,
        mapperName,
        createdAt,
        lastUpdate,
    });
};

export const { reducer } = createSlice({
    name: 'elements',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchAllElementsInMap.fulfilled]: (state, action) => {
            setAll(
                state,
                action.payload.map(
                    ({
                        areaId,
                        topRight,
                        bottomLeft,
                        comment,
                        hasAttachments,
                        type,
                        title,
                        mapperName,
                        createdAt,
                        lastUpdate,
                        attachment,
                    }) => ({
                        areaId,
                        topRight,
                        bottomLeft,
                        comments: comment ? [comment] : [],
                        hasAttachments,
                        type,
                        title,
                        mapperName,
                        createdAt,
                        lastUpdate,
                        attachment,
                    })
                )
            );
        },

        [fetchElementAttachments.fulfilled]: (state, action) => {
            const { areaId } = action.meta.arg;
            const { attachment } = action.payload;
            upsertOne(state, { areaId, attachments: [attachment] });
        },

        [createElement.fulfilled]: upsertOneElement,
        [updateElement.fulfilled]: upsertOneElement,
        [deleteElement.fulfilled]: (state, action) => {
            const { areaId } = action.meta.arg;
            removeOne(state, areaId);
        },
        [elementsCreated]: upsertOneElement,
        [elementsUpdated]: upsertOneElement,
        [elementsDeleted]: (state, action) => {
            const { areaId } = action.payload.data;
            removeOne(state, areaId);
        },
        [expireSession.fulfilled]: () => getInitialState(),
        [logout.fulfilled]: () => getInitialState(),
    },
});
