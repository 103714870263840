import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { MdDelete as DeleteIcon, MdCancel as CancelIcon, MdSave as SaveIcon } from 'react-icons/md';
import { Tooltip, CircularPreloader } from '../../../themed';
import { useSelector } from 'react-redux';
import { selectCanEditLanes } from '../../../../../state-management/auth/authSelectors';
import { selectMappingAreasOfInterestActionsShown } from '../../../../../state-management/user-inputs/mappingSlice';

export default function AreaOfInterestActions(props) {
    const { onCancel, onSave, onDelete, showCancel = true, showSave = true, showDelete = true } = props;

    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const canEditLanes = useSelector(selectCanEditLanes);

    const handleSave = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        setIsSaving(true);
        await onSave();
        setIsSaving(false);
    };

    const handleCancel = (event) => {
        event.stopPropagation();
        event.preventDefault();

        onCancel();
    };

    const handleDelete = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        setIsDeleting(true);
        await onDelete();
        setIsDeleting(false);
    };

    return (
        <Grid container onClick={(e) => e.stopPropagation()}>
            {showSave && (
                <Grid item>
                    {isSaving ? (
                        <CircularPreloader />
                    ) : (
                        <Tooltip content={'Save'}>
                            <IconButton onClick={handleSave} disabled={!canEditLanes}>
                                <SaveIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            )}

            {showCancel && (
                <Grid item>
                    <Tooltip content={'Cancel'}>
                        <IconButton onClick={handleCancel}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}

            {showDelete && (
                <Grid item>
                    {isDeleting ? (
                        <CircularPreloader />
                    ) : (
                        <Tooltip content={'Delete'}>
                            <IconButton onClick={handleDelete} disabled={!canEditLanes}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            )}
        </Grid>
    );
}

AreaOfInterestActions.propTypes = {
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    showCancel: PropTypes.bool,
    showSave: PropTypes.bool,
    showDelete: PropTypes.bool,
};
