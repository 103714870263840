import moment from 'moment';

export default {
    convertToUtcISOStr(timezoneOffset, timeStr) {
        const currentTimezoneOffset = moment.parseZone().utcOffset();
        return moment(timeStr)
            .subtract(timezoneOffset - currentTimezoneOffset, 'minutes')
            .toISOString();
    },

    convertToTimezoneISOStrFromUTC(timezoneOffset, timeStr) {
        const currentTimezoneOffset = moment.parseZone().utcOffset();
        return moment(timeStr)
            .add(timezoneOffset - currentTimezoneOffset, 'minutes')
            .toISOString();
    },

    convertToUtcMoment(timezoneOffset, timeStr) {
        const currentTimezoneOffset = moment.parseZone().utcOffset();
        return moment(timeStr).subtract(timezoneOffset - currentTimezoneOffset, 'minutes');
    },

    minutesDifference(now = new Date(), prev = new Date()) {
        const diff = moment(now).diff(moment(prev));
        const result = moment.duration(diff).asMinutes().toFixed();

        return Math.abs(result);
    },
    toDuration(start = new Date(), end = new Date(), withSeconds = false) {
        const duration = moment.duration(moment(end).diff(moment(start)));

        // Get Seconds
        const seconds = duration.seconds();
        const secondsFormatted = seconds <= 9 ? `0${seconds}` : `${seconds}`;

        //Get Minutes
        const minutes = duration.minutes();
        const minutesFormatted = minutes <= 9 ? `0${minutes}` : `${minutes}`;

        //Get Hours
        const hours = duration.hours();
        const hoursFormatted = hours <= 9 ? `0${hours}` : `${hours}`;

        //Get Days
        const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
        const daysFormatted = days ? `${days} Days ago` : ''; // if no full days then do not display it at all

        if (days > 0) {
            return daysFormatted;
        }

        const hoursMinutesFormat = `${hoursFormatted}:${minutesFormatted}`;

        const result = withSeconds ? `${hoursMinutesFormat}:${secondsFormatted}` : hoursMinutesFormat;

        return result;
    },
    convertToTimeStamp(date = new Date(1970,1,1)) {
        return new Date(date).getTime();
    }
};
