import React from 'react';
import { create } from 'jss';
import { StylesProvider, createGenerateClassName, jssPreset } from '@material-ui/styles';
import * as PropTypes from 'prop-types';

// Add a comment node to indicate where JSS should inject material-ui's styles
// This is to ensure that styled-components' styles will be injected after JSS's
// which will make sure styled-components' styles will always override material-ui's.
const styleNode = document.createComment('jss-insertion-point');
document.head.insertBefore(styleNode, document.head.firstChild);

const generateClassName = createGenerateClassName();
const jss = create({
    ...jssPreset(),
    // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
    insertionPoint: 'jss-insertion-point',
});

const JssProviderWrapper = (props) => {
    const { children } = props;

    return (
        <StylesProvider jss={jss} generateClassName={generateClassName}>
            {children}
        </StylesProvider>
    );
};

JssProviderWrapper.propTypes = {
    children: PropTypes.object.isRequired,
};

export default JssProviderWrapper;
