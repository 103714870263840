import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
    Drawer as MuiDrawer,
    IconButton,
    Typography,
    Grid,
    Divider as MuiDivider,
    Modal as MuiModal,
} from '@material-ui/core';
import { AiOutlineArrowRight as BackIcon } from 'react-icons/ai';
import { MdEdit as EditIcon, MdDelete as DeleteIcon } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectMappingAreaOfInterestIds,
    selectMappingElementIds,
    selectMappingSelectedAreaId,
    selectMappingSelectedElementId,
    selectMappingSelectedMapId,
    setAreAreasOfInterestActionsShown,
    setSelectedAreaId,
    setSelectedAreaOfInterestIds,
    setSelectedElementId,
    setSelectedElementIds,
} from '../../../state-management/user-inputs/mappingSlice';
import {
    Button,
    Skeleton,
    TextField,
    Tooltip,
    CircularPreloader as ThemedCircularPreloader,
    Dialog,
} from '../../common/themed';
import { useForm } from 'react-hook-form';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '@material-ui/icons/FileCopy';
import { showInfoNotification } from '../../../state-management/notification/notificationReducer';
import useAreasOfInterest from '../../common/hooks/data-fetching/useAreasOfInterest';
import { updateAreaOfInterest } from '../../../state-management/mapping/area-of-interest/areaOfInterestActions';
import AreaOfInterestActions from './AreaOfInterestActions';
import { updateElement } from '../../../state-management/mapping/elements/elementsActions';
import { selectUserName } from '../../../state-management/auth/authSelectors';

const Drawer = styled(MuiDrawer)`
    z-index: 1000;
    visibility: visible;
    width: 0;

    & .side-drawer-paper {
        margin-block-start: 4rem;
        height: calc(100% - 4rem);
        width: 320px;
        overflow: visible;
        padding: 20px;
    }
`;

const DrawerContent = styled.div`
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
`;

const Divider = styled(MuiDivider)`
    margin-block-start: 15px;
    margin-block-end: 15px;
`;

const Title = styled(Typography).attrs(() => ({
    variant: 'h5',
}))`
    color: #ff6200; // TODO color should come from theme
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
`;

const NoItemsMessage = styled(Typography)`
    color: #909090;
    text-align: center;
    margin-block-start: 10px;
    font-style: italic;
`;

const Attachment = styled.img`
    width: 100%;
    margin-block-start: 10px;
    cursor: pointer;
    outline: none;
`;

const FullSizeAttachment = styled.img`
    outline: none;
`;

const Modal = styled(MuiModal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const BackIconWrapper = styled(IconButton)`
    position: absolute;
    right: 2%;
`;

const CommentControls = styled.div`
    margin-block-start: 10px;
`;

const CircularPreloader = styled(ThemedCircularPreloader)`
    padding: 0 10px;
`;

const Comment = styled(Typography).attrs(() => ({
    variant: 'subtitle1',
}))`
    white-space: pre-line;
`;

export const CustomDialog = styled(Dialog).attrs({
    maxWidth: false,
    fullScreen: true,
    closeIconStyle: {
        style: {
            filter: 'invert(100%)',
        },
    },
})`
    padding: 30px;
`;

export const Image = styled.img`
    object-fit: contain;
    width: 100%;
    cursor: zoom-in;
    max-height: 60vh;
    height: 100%;
    ${({ disabled }) =>
        disabled &&
        `
        cursor: not-allowed;
        pointer-events: none;
    `}
`;

const ZoomImage = styled(Image)`
    height: 90vh;
    margin: 0 auto;
    cursor: default;
    max-height: unset;
`;


const ACTIONS = {
    normal: {
        update: updateAreaOfInterest,
        copy: 'Area ID copied',
        setSelectedId: setSelectedAreaId,
        setSelectedIds: setSelectedAreaOfInterestIds,
        selectedId: selectMappingSelectedAreaId,
        selectIds: selectMappingAreaOfInterestIds

    },
    element: {
        update: updateElement,
        copy: 'Element ID copied',
        setSelectedId: setSelectedElementId,
        setSelectedIds: setSelectedElementIds,
        selectedId: selectMappingSelectedElementId,
        selectIds: selectMappingElementIds

    },
}

export default function AreaOfInterestDetailsSideDrawer(props) {
    const [displayedAttachmentUrl, setDisplayedAttachmentUrl] = useState(null);
    const [isCommentInEditMode, setIsCommentInEditMode] = useState(false);
    const [isTitleInEditMode, setIsTitleInEditMode] = useState(false);
    const idRef = useRef(null);

    const dispatch = useDispatch();
    const action = ACTIONS[props.type] || ACTIONS.normal;

    const selectedMapId = useSelector(selectMappingSelectedMapId);
    const selectedId = useSelector(action.selectedId);
    const selectedIds = useSelector(action.selectIds);
    const mapperName = useSelector(selectUserName)

    const { data: area = {}, isLoading } = useAreasOfInterest({
        asObject: true,
        mapId: selectedMapId,
        areaId: selectedId,
        withAttachments: true,
        type: props.type
    });

    const { comments, attachments = [], hasAttachments, title, type } = area;

    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting },
        setValue,
    } = useForm({
        defaultValues: { comment: comments?.length > 0 ? comments[0] : null, title },
    });

    const isDrawerOpen = selectedIds?.length === 1 && !!selectedId && area.type === props.type


    const submitCommentForm = async (data) => {
        await dispatch(
            action.update({
                mapId: selectedMapId,
                areaId: selectedId,
                areaData: { comment: data.comment, mapperName },
            })
        );
        setIsCommentInEditMode(false);
    };

    const submitTitleForm = async (data) => {
        await dispatch(
            action.update({
                mapId: selectedMapId,
                areaId: selectedId,
                areaData: { title: data.title, mapperName },
            })
        );
        setIsTitleInEditMode(false);
    };
    const clearComments = () => {
        dispatch(
            action.update({
                mapId: selectedMapId,
                areaId: selectedId,
                areaData: { comment: null, mapperName },
            })
        );
    };
    const clearTitle = () => {
        dispatch(
            action.update({
                mapId: selectedMapId,
                areaId: selectedId,
                areaData: { title: null, mapperName },
            })
        );
    };

    const EditableItem = ({ data, isOnEditMode, setIsOnEditMode, type, onSubmit, multiline = true }) => {
        return data?.length > 0 || isOnEditMode ? (
            (data?.length === 0 && isOnEditMode ? [''] : data)?.map((item, index) => (
                <Grid key={`aoi-${type}-${index}`} item container direction={'column'}>
                    {isOnEditMode ? (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid item>
                                <TextField
                                    {...register(type)}
                                    autoFocus
                                    name={type}
                                    onChange={e => setValue(type, e.target.value)}
                                    multiline={multiline}
                                    fullWidth
                                    rows={item?.split?.('\n')?.length ?? 1}
                                    defaultValue={item ?? ''}
                                />
                            </Grid>

                            <Grid item container justifyContent={'flex-end'} spacing={1} component={CommentControls}>
                                <Grid item>
                                    <Button variant={'text'} onClick={() => setIsOnEditMode(false)}>
                                        Cancel
                                    </Button>
                                </Grid>

                                {isSubmitting ? (
                                    <CircularPreloader size={35} />
                                ) : (
                                    <Grid item>
                                        <Button type={'submit'}>Save</Button>
                                    </Grid>
                                )}
                            </Grid>
                        </form>
                    ) : (
                        <Comment>{data}</Comment>
                    )}
                </Grid>
            ))
        ) : isLoading ? (
            Array.from({ length: 2 }, (_, index) => (
                <Skeleton key={`area-${selectedId}-${type}-skeleton-${index}`} height={15} width={230} />
            ))
        ) : (
            <NoItemsMessage>This area has no {type}s.</NoItemsMessage>
        );
    };

    const onCloseDrawer = () => {
        dispatch(action.setSelectedId(null));
        dispatch(action.setSelectedIds([]));
    }

    useEffect(() => {
        reset({ comment: comments?.length > 0 ? comments[0] : null, title });
        setIsCommentInEditMode(false);
    }, [comments, reset, title]);

    useEffect(() => {
        if (!selectedId && isCommentInEditMode) {
            setIsCommentInEditMode(false);
        }
    }, [isCommentInEditMode, selectedId]);

    return (
        <>
            <CustomDialog open={!!displayedAttachmentUrl} onClose={() => {
                setDisplayedAttachmentUrl(null);
                dispatch(setAreAreasOfInterestActionsShown(true))

            }}>
                <ZoomImage src={displayedAttachmentUrl} alt={'zoom image'} />
            </CustomDialog>
            <Drawer
                open={isDrawerOpen}
                classes={{ paper: 'side-drawer-paper' }}
                anchor={'right'}
                hideBackdrop
                disableEnforceFocus
            >
                <BackIconWrapper onClick={onCloseDrawer}>
                    <BackIcon />
                </BackIconWrapper>

                <Grid container alignItems={'center'}>
                    <Title>{selectedId}</Title>

                    <CopyToClipboard
                        text={selectedId}
                        onCopy={() => {
                            dispatch(
                                showInfoNotification(action.copy)
                            );
                        }}
                    >
                        <IconButton onClick={(e) => e.stopPropagation()}>
                            <CopyIcon />
                        </IconButton>
                    </CopyToClipboard>
                </Grid>

                <Divider />

                <DrawerContent>
                    <AreaOfInterestActions mapId={selectedMapId} areaId={area?.areaId} type={type} />

                    <Divider />

                    <Grid container alignItems={'center'}>
                        <Title>Comments</Title>

                        {!isCommentInEditMode && (
                            <Tooltip content={'Edit'}>
                                <IconButton onClick={() => setIsCommentInEditMode(true)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        )}

                        <Tooltip content={'Clear'}>
                            <IconButton onClick={clearComments}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid container direction={'column'}>
                        {
                            <EditableItem
                                type="comment"
                                isOnEditMode={isCommentInEditMode}
                                setIsOnEditMode={setIsCommentInEditMode}
                                data={comments}
                                onSubmit={submitCommentForm}
                            ></EditableItem>
                        }

                        <Divider />
                        {['element', 'exitRegion'].includes(type) && (
                            <>
                                <Grid container alignItems={'center'}>
                                    <Title>Title</Title>

                                    {!isTitleInEditMode && (
                                        <Tooltip content={'Edit'}>
                                            <IconButton onClick={() => setIsTitleInEditMode(true)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    <Tooltip content={'Clear'}>
                                        <IconButton onClick={clearTitle}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                                <Grid item>
                                    <EditableItem
                                        type="title"
                                        isOnEditMode={isTitleInEditMode}
                                        setIsOnEditMode={setIsTitleInEditMode}
                                        data={[title]}
                                        onSubmit={submitTitleForm}
                                        multiline={false}
                                    ></EditableItem>
                                </Grid>
                                <Divider />
                            </>
                        )}

                        {!action.hideAttachments && <Grid item>
                            <Title>Attachments</Title>
                        </Grid>}
                        {hasAttachments ? (
                            <Grid item>
                                {isLoading ? (
                                    <Skeleton height={200} />
                                ) : (
                                    attachments.map((url, index) => (
                                        <Attachment
                                            key={`area-${selectedId}-attachment-${index}`}
                                            alt={'attachment'}
                                            src={url}
                                            onClick={() => {
                                                setDisplayedAttachmentUrl(url);
                                                dispatch(setAreAreasOfInterestActionsShown(false))
                                            }}
                                        />
                                    ))
                                )}
                            </Grid>
                        ) : (
                            !action.hideAttachments && <NoItemsMessage>This area has no attachments.</NoItemsMessage>
                        )}
                    </Grid>
                </DrawerContent>
            </Drawer>


        </>
    );
}
