const NOTIFICATION = {
    TYPE: {
        INFO: 'info',
        ERROR: 'error',
        WARN: 'warn'
    }
};
const REGION = {
    TYPE: {
        RECT: "rectangular",
        CIRCULAR: "circular"
    }
};
const TRIGGER = {
    EVENT_TYPE: {
        ENTER_REGION: "enterRegion",
        EXIT_REGION: "exitRegion",
        DWELL_IN_REGION: "dwellInRegion"
    },
    META_DATA: {
        formatType: {
            ORIIENT: "oriientFormat"
        },
        assets: {
            STAR: "star_icon"
        }
    },
    FILTER : {
        API_KEY : "API_KEY",
        SPACE_ID : "SPACE_ID",
        MAP_ID : "MAP_ID",
        REGION_ID : "REGION_ID"
    }
};

const PERMISSIONS = {
    POSITIONING : 'Set map position',
    READ_MAPS : 'Read maps',
    TRIGGERING : 'Enable set triggers',
    EDIT_LOCATION_CONTENT : 'Edit location content',
    MONITORING : 'Enable Monitoring users',
    EDIT_API_KEYS : 'Create and edit API keys',
    EDIT_MAPS : 'Create and edit maps',
    'Create AM user' : 'Create AM user',
    EDIT_SPACES : 'Create and edit spaces',
    READ_SPACES : 'Read spaces'
};
const enums = {
    REGION: REGION,
    TRIGGER: TRIGGER,
    NOTIFICATION: NOTIFICATION,
    PERMISSIONS: PERMISSIONS
};
export  { NOTIFICATION, REGION, TRIGGER } ;
export default enums;