import log from '../utils/logger';
import { selectAccessToken, selectUserApiKeyId } from '../state-management/auth/authSelectors';
import { store } from '../state-management/store';

export default class Api {
    async callApi(apiCaller) {
        try {
            const accessToken = selectAccessToken(store.getState());
            const params = {
                spaceId:
                    // TODO should come from selector, but currently it creates circular dependency, can be fixed by separating user inputs selectors from the reducer file
                    store.getState().userInputs.common.selectedSpaceId,
            };
            // const headers = {
            //     authorization: accessToken,
            // };

            const response = await apiCaller(params);
            return { data: response.data };
        } catch (ex) {
            if (ex.response?.data?.isCustomError) {
                const { name, message, errorCode, data } = ex.response.data;

                log.error(
                    `Failed to call API: ${errorCode} ${name}: ${message}, data: ${JSON.stringify(data)}`
                );

                const customError = new Error(message);
                customError.name = name;
                customError.code = String(errorCode);

                throw customError;
            } else {
                log.error(`Failed to call API: ${ex.message}`);
                throw ex;
            }
        }
    }

    async callStreamApi(method, url, minBatchSize, onData) {
        try {
            const accessToken = selectAccessToken(store.getState());
            const params = {
                spaceId:
                    // TODO should come from selector, but currently it creates circular dependency, can be fixed by separating user inputs selectors from the reducer file
                    store.getState().userInputs.common.selectedSpaceId,
            };

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json', // indicates what the server actually sent
                    Authorization: `Bearer ${accessToken?.replaceAll(`"`, '')}`,
                },
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            let batch = [];
            let strValue = '';

            while (true) {
                const { value, done } = await reader.read();

                if (done) {
                    if (batch.length > 0) {
                        onData(batch);
                    }

                    break;
                }

                const decodedValue = decoder.decode(value, { stream: !done });
                strValue = `${strValue}${decodedValue}`;
                const strStart = strValue.indexOf('{');
                const strEnd = strValue.lastIndexOf('}') + 1;
                const strArray = strValue.substring(strStart, strEnd);

                try {
                    const data = JSON.parse(`[${strArray}]`);

                    if (data.length === 0) {
                        onData(data);
                    }

                    strValue = strValue.substring(strEnd);
                    batch.push(...data);

                    while (batch.length >= minBatchSize) {
                        const minBatch = batch.splice(0, minBatchSize);
                        onData(minBatch);
                    }
                } catch (e) {
                    log.error(
                        `Failed to parse streaming value, method: ${method}, URL: ${url}, value: ${strValue}`
                    );
                }
            }
        } catch (ex) {
            if (ex.response?.data?.isCustomError) {
                const { name, message, errorCode, data } = ex.response.data;

                log.error(
                    `Failed to call API: ${errorCode} ${name}: ${message}, data: ${JSON.stringify(data)}`
                );

                const customError = new Error(message);
                customError.name = name;
                customError.code = String(errorCode);

                throw customError;
            } else {
                log.error(`Failed to call API: ${ex.message}`);
                throw ex;
            }
        }
    }
}
