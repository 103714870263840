import moment from 'moment';
import countriesAndTimezones from 'countries-and-timezones';
import {
    AGGREGATE_PROCESSED_SESSIONS_SUCCESS,
    AGG_TYPE_BY_CLIENT,
    FETCH_RAW_SESSIONS_REQUEST,
    FETCH_RAW_SESSIONS_SUCCESS,
    PROCESS_RAW_SESSION_REQUEST,
    PROCESS_RAW_SESSION_SUCCESS,
    SET_FILTERS,
    CALCULATE_SESSION_AGGREGATIONS,
    PROCESS_ALL_RAW_SESSIONS_REQUEST,
    PROCESS_SELECTED_SESSION_SUCCESS,
    SET_SELECTED_SESSION,
    PROCESS_SELECTED_SESSION_REQUEST,
} from './sessionAnalyticsActions';
import { setSelectedSpaceId } from '../../user-inputs/commonSlice';

const timeZones = countriesAndTimezones.getAllTimezones();

const filterSettings = {
    mapIds: { defaultValue: [], requiresRefetching: true },
    acceptedAccuracy: { defaultValue: 4, requiresRefetching: false },
    timeSpanStart: {
        defaultValue: moment().startOf('day').toDate(), // Today midnight
        requiresRefetching: true,
    },
    timeSpanEnd: {
        defaultValue: moment().add(1, 'day').startOf('day').subtract(1, 'minute').toDate(), // Tomorrow midnight
        requiresRefetching: true,
    },
    granularity: { defaultValue: 1000, requiresRefetching: false },
    timeZone: {
        defaultValue: timeZones[Intl.DateTimeFormat().resolvedOptions().timeZone],
        requiresRefetching: true,
    },
    sessionId: { defaultValue: '', requiresRefetching: true },
    inaccuracySmoothing: { defaultValue: true, requiresRefetching: false },
};

const INITIAL_STATE = {
    filters: Object.entries(filterSettings).reduce(
        (result, [key, value]) => ({ ...result, [key]: value.defaultValue }),
        {}
    ),
    fetchOnSubmit: true,
    positions: {
        byId: {},
        allIds: [],
    },
    sessions: {
        byId: {},
        allIds: [],
        selectedSession: null,
    },
    sessionAggregations: {
        aggregationType: AGG_TYPE_BY_CLIENT,
        rawData: [],
        byId: {},
        allIds: [],
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
                fetchOnSubmit:
                    state.fetchOnSubmit ||
                    Object.keys(action.payload).some((key) => filterSettings[key].requiresRefetching),
            };

        case setSelectedSpaceId.type:
            return {
                ...state,
                filters: { ...state.filters, mapIds: filterSettings.mapIds.defaultValue },
                fetchOnSubmit: true,
                positions: INITIAL_STATE.positions,
                sessions: INITIAL_STATE.sessions,
                sessionAggregations: {
                    ...INITIAL_STATE.sessionAggregations,
                    aggregationType: state.sessionAggregations.aggregationType,
                },
            };

        case FETCH_RAW_SESSIONS_REQUEST:
            return {
                ...state,
                positions: INITIAL_STATE.positions,
                sessions: INITIAL_STATE.sessions,
                sessionAggregations: {
                    ...INITIAL_STATE.sessionAggregations,
                    aggregationType: state.sessionAggregations.aggregationType,
                },
            };

        case FETCH_RAW_SESSIONS_SUCCESS:
            return {
                ...state,
                fetchOnSubmit: false,
                sessionAggregations: {
                    ...state.sessionAggregations,
                    rawData: action.payload,
                },
            };

        case CALCULATE_SESSION_AGGREGATIONS:
            const { aggregationType, aggregationsById, sessionsById, positionsById } = action.payload;

            return {
                ...state,
                positions: {
                    ...state.positions,
                    byId: positionsById,
                    allIds: Object.keys(positionsById),
                },
                sessions: {
                    ...state.sessions,
                    byId: sessionsById,
                    allIds: Object.keys(sessionsById),
                },
                sessionAggregations: {
                    ...state.sessionAggregations,
                    aggregationType,
                    byId: aggregationsById,
                    allIds: Object.keys(aggregationsById),
                },
            };

        case PROCESS_ALL_RAW_SESSIONS_REQUEST:
            return {
                ...state,
                sessions: {
                    ...state.sessions,
                    byId: Object.entries(state.sessions.byId).reduce(
                        (result, [sessionId, session]) => ({
                            ...result,
                            [sessionId]: {
                                ...session,
                                isProcessed: false,
                            },
                        }),
                        {}
                    ),
                },
                sessionAggregations: {
                    ...state.sessionAggregations,
                    byId: Object.entries(state.sessionAggregations.byId).reduce(
                        (result, [aggId, agg]) => ({
                            ...result,
                            [aggId]: {
                                ...agg,
                                isProcessed: false,
                            },
                        }),
                        {}
                    ),
                },
            };

        case PROCESS_RAW_SESSION_REQUEST:
            return {
                ...state,
                sessions: {
                    ...state.sessions,
                    byId: {
                        ...state.sessions.byId,
                        [action.payload]: {
                            ...state.sessions.byId[action.payload],
                            isProcessed: false,
                        },
                    },
                },
            };

        case PROCESS_RAW_SESSION_SUCCESS:
            return {
                ...state,
                sessions: {
                    ...state.sessions,
                    byId: {
                        ...state.sessions.byId,
                        [action.payload.sessionId]: {
                            ...action.payload,
                            isProcessed: true,
                        },
                    },
                },
            };

        case PROCESS_SELECTED_SESSION_REQUEST:
            return {
                ...state,
                sessions: {
                    ...state.sessions,
                    selectedSession: {
                        ...state.sessions.selectedSession,
                        isProcessed: false,
                    },
                },
            };

        case SET_SELECTED_SESSION:
            return {
                ...state,
                sessions: {
                    ...state.sessions,
                    selectedSession: action.payload,
                },
            };

        case PROCESS_SELECTED_SESSION_SUCCESS:
            return {
                ...state,
                sessions: {
                    ...state.sessions,
                    selectedSession: { ...action.payload, isProcessed: true },
                },
            };

        case AGGREGATE_PROCESSED_SESSIONS_SUCCESS:
            return {
                ...state,
                sessionAggregations: {
                    ...state.sessionAggregations,
                    byId: {
                        ...state.sessionAggregations.byId,
                        [action.payload.aggregationId]: {
                            ...state.sessionAggregations.byId[action.payload.aggregationId],
                            ...action.payload,
                            isProcessed: true,
                            aggregationType: action.payload.aggregationType,
                        },
                    },
                },
            };

        default:
            return state;
    }
};
