import { useDispatch, useSelector } from 'react-redux';
import {
    selectBuildingsSelectedBuildingId,
    selectBuildingsSelectedFloorId,
} from '../../../../state-management/user-inputs/buildingsSlice';
import { useEffect, useMemo } from 'react';
import { fetchAllExitRegionsInMap } from '../../../../state-management/mapping/exitRegion/exitRegionsActions';
import { selectHasError, selectIsLoading } from '../../../../state-management/status/statusSelectors';
import {
    getSelectExitRegionsInMap,
    selectAllExitRegions,
    selectExitRegionById,
    selectExitRegionEntities,
} from '../../../../state-management/mapping/exitRegion/exitRegionSelectors';

export const useExitRegions = ({ mapId, exitRegionId }) => {
    const dispatch = useDispatch();
    const buildingId = useSelector(selectBuildingsSelectedBuildingId);
    const floorId = useSelector(selectBuildingsSelectedFloorId);

    const actions = useMemo(
        () => [...(mapId ? [{ type: fetchAllExitRegionsInMap.typePrefix, arg: { mapId } }] : [])],
        [exitRegionId, mapId]
    );

    const isLoadingSelector = useMemo(() => selectIsLoading(actions), [actions]);
    const isLoading = useSelector(isLoadingSelector);
    const hasErrorSelector = useMemo(() => selectHasError(actions), [actions]);
    const hasError = useSelector(hasErrorSelector);
    const dependencies = [dispatch, buildingId, floorId, mapId];
    const canFetch = dependencies.every(Boolean) && !isLoading;
    const exitRegions = useSelector(selectAllExitRegions) || [];
    const exitRegion = useSelector((state) => {
        return selectExitRegionById(state, exitRegionId);
    });

    useEffect(() => {
        if (canFetch) {
            dispatch(fetchAllExitRegionsInMap({ buildingId, floorId, mapId }));
        }
    }, [...dependencies]);

    const data = exitRegionId ? exitRegion : exitRegions;

    return {
        data,
        isLoading,
        hasError,
    };
};
