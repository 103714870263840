import React, { useMemo } from 'react';
import SideMenuSection from '../../../common/side-drawer/SideMenuSection';
import { Skeleton, Switch } from '../../../common/themed';
import {
    selectBuildingsAreRegionExternalPlacementsShown,
    selectBuildingsSelectedBuildingId,
    setAreRegionExternalPlacementsShown,
} from '../../../../state-management/user-inputs/buildingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import FetchError from '../../../common/error-pages/FetchError';
import NoItemsMessage from '../../../common/side-drawer/NoItemsMessage';
import ExternalRegionListEntry from './ExternalRegionListEntry';
import useAllFloorsInBuilding from '../../../common/hooks/data-fetching/useAllFloorsInBuilding';
import { getSelectAllRegionsWithExternalPlacementsInBuilding } from '../../../../state-management/region/regionSelectors';

const List = styled.ul`
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-block-start: 5px;
    padding: 0;
`;

export default function BuildingsSideMenuExternalRegionsSection() {
    const dispatch = useDispatch();

    const selectedBuildingId = useSelector(selectBuildingsSelectedBuildingId);
    const arePlacementsShown = useSelector(selectBuildingsAreRegionExternalPlacementsShown);

    // This hook is being used to fetch full building data, which includes the external regions
    const { isLoading, hasError } = useAllFloorsInBuilding({ buildingId: selectedBuildingId });

    const selectAllRegionsWithExternalPlacementsInBuilding = useMemo(
        () => getSelectAllRegionsWithExternalPlacementsInBuilding(selectedBuildingId),
        [selectedBuildingId]
    );

    const regionsWithPlacements = useSelector(selectAllRegionsWithExternalPlacementsInBuilding) ?? [];

    if (!selectedBuildingId || (!regionsWithPlacements && !isLoading)) {
        return null;
    }

    return (
        <>
            <SideMenuSection title={'External Regions (in building)'}>
                {hasError ? (
                    <FetchError />
                ) : isLoading ? (
                    <Skeleton height={50} width={230} />
                ) : regionsWithPlacements.length === 0 ? (
                    <NoItemsMessage>There are no external regions in this building.</NoItemsMessage>
                ) : (
                    <>
                        <FormControlLabel
                            label={'Show external regions'}
                            disabled={isLoading}
                            control={
                                <Switch
                                    checked={arePlacementsShown}
                                    onChange={() =>
                                        dispatch(setAreRegionExternalPlacementsShown(!arePlacementsShown))
                                    }
                                />
                            }
                        />

                        <List>
                            {regionsWithPlacements.map(
                                ({ regionId, regionName, regionMetadata, ...placement }) => (
                                    <ExternalRegionListEntry
                                        key={placement?.placementId}
                                        region={{ regionId, regionName, regionMetadata }}
                                        placement={placement}
                                    />
                                )
                            )}
                        </List>
                    </>
                )}
            </SideMenuSection>
        </>
    );
}
