import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { MapViewerContext } from '../../MapViewerContext';
import { Portal, Snackbar as MuiSnackbar, SnackbarContent, Typography } from '@material-ui/core';
import { Button, CircularPreloader } from '../../../themed';
import { useDispatch } from 'react-redux';
import {
    getMapCroppingInstructionSnackbarId,
    getMapCroppingNewCreation,
    getMapViewerMapCroppingCreationOverlayId,
} from './MapViewerMapCropping.selectors';
import {
    IMAGE_NATURAL_HEIGHT,
    IMAGE_NATURAL_WIDTH,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../../state-management/notification/notificationReducer';
import { isFulfilled } from '../../../../../state-management/utils';
import { cropLanes } from '../../../../../state-management/mapping/lane/laneActions';

const CreationSpace = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
`;

const Rectangle = styled.rect.attrs((props) => ({
    // We use inline styles instead of CSS class to avoid styled-components from
    // generating a new class for every update, which is a major performance issue
    style: {
        width: props.$width,
        height: props.$height,
    },
}))`
    fill: #ff0000; // TODO color should come from theme
    fill-opacity: 0.3;
    stroke: #ff0000;

    stroke-width: calc(1.5px / var(${SCALE}));
`;

const Snackbar = styled(MuiSnackbar)`
    position: absolute;
`;

const Instruction = styled(Typography)`
    color: #ffffff; //TODO color should come from theme
`;

const SnackbarButton = styled(Button)`
    color: white;
`;

const INITIAL = 'INITIAL';
const FIRST_CORNER_SET = 'FIRST_CORNER_SET';
const SECOND_CORNER_SET = 'SECOND_CORNER_SET';
const DELETE_AREA = 'DELETE_AREA';

export default function MapViewerAreaOfInterestCreationOverlay(props) {
    const { mapId, onCropped, onCancel } = props;

    const type = props.type || 'normal';

    const { imageRef, containerRef, getCSSVariable } = useContext(MapViewerContext);

    const dispatch = useDispatch();

    const [isCreating, setIsCreating] = useState(false);
    const [{ creationStep, firstCorner, secondCorner }, setCreation] = useImmer({
        creationStep: INITIAL,
        firstCorner: null,
        secondCorner: null,
        type,
    });

    const handleClick = (event) => {
        const { clientX, clientY } = event;
        const refRect = imageRef.current.getBoundingClientRect();
        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const scale = parseFloat(getCSSVariable(SCALE));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        if (creationStep === INITIAL) {
            setCreation((state) => {
                state.creationStep = FIRST_CORNER_SET;
                state.firstCorner = {
                    x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                    y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
                };
                state.secondCorner = null;
            });
        } else if (creationStep === FIRST_CORNER_SET) {
            setCreation((state) => {
                state.creationStep = SECOND_CORNER_SET;
                state.secondCorner = {
                    x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                    y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
                };
            });
        } else if (creationStep === SECOND_CORNER_SET) {
            setCreation((state) => {
                state.creationStep = DELETE_AREA;
            });
        }
    };

    const handlePointerMove = useCallback(
        (event) => {
            const { clientX, clientY } = event;

            const refRect = imageRef.current.getBoundingClientRect();
            const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
            const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
            const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

            setCreation((state) => {
                if (creationStep === INITIAL) {
                    state.firstCorner = {
                        x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                        y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
                    };
                } else if (creationStep === FIRST_CORNER_SET) {
                    state.secondCorner = {
                        x: (clientX - refRect.left) / scale / pixelToMeter + mapOffsetX,
                        y: (imageNaturalHeight - (clientY - refRect.top) / scale) / pixelToMeter + mapOffsetY,
                    };
                }
            });
        },
        [creationStep, getCSSVariable, imageRef, setCreation]
    );

    const handleCancel = (event) => {
        event.stopPropagation();
        onCancel();
    };

    const handleCrop = async () => {
        setIsCreating(true);
        const result = await dispatch(cropLanes({ mapId, areaData: { topRight, bottomLeft } }));
        setIsCreating(false);

        if (isFulfilled(result)) {
            dispatch(showSuccessNotification(`Deleted successfully`));
        } else {
            if (result.meta.rejectedWithValue && result.payload) {
                if (result.payload?.response) {
                    dispatch(showErrorNotification(result.payload?.response));
                } else if (result.payload) {
                    try {
                        const errorMsg = result.payload.toString().split('service:')[0].trim().split('\n');
                        dispatch(showErrorNotification(errorMsg[errorMsg.length - 1].trim()));
                    } catch (err) {
                        alert(result.payload);
                    }
                } else {
                    dispatch(showErrorNotification(`Failed to delete`));
                }
            }
        }
        onCropped();
    };

    useEffect(() => {
        if (creationStep === INITIAL || creationStep === FIRST_CORNER_SET) {
            window.addEventListener('pointermove', handlePointerMove);

            return () => {
                window.removeEventListener('pointermove', handlePointerMove);
            };
        }
    }, [creationStep, handlePointerMove]);

    const boundaries = useMemo(() => {
        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));
        const imageNaturalWidth = parseFloat(getCSSVariable(IMAGE_NATURAL_WIDTH));

        return {
            maxX: imageNaturalWidth / pixelToMeter + mapOffsetX,
            minX: mapOffsetX,
            maxY: imageNaturalHeight / pixelToMeter + mapOffsetY,
            minY: mapOffsetY,
        };
    }, [getCSSVariable]);

    const { x, y, width, height, bottomLeft, topRight } = useMemo(() => {
        if (!firstCorner || !secondCorner) {
            return { x: 0, y: 0, width: 0, height: 0, bottomLeft: null, topRight: null };
        }

        const { minX, minY, maxX, maxY } = boundaries;

        const bottomLeft = {
            x: Math.min(firstCorner?.x, secondCorner?.x),
            y: Math.min(firstCorner?.y, secondCorner?.y),
        };
        const topRight = {
            x: Math.max(firstCorner?.x, secondCorner?.x),
            y: Math.max(firstCorner?.y, secondCorner?.y),
        };

        // Limit the X and Y coordinates to the boundaries of the map image
        bottomLeft.x = Math.min(Math.max(bottomLeft.x, minX), maxX);
        bottomLeft.y = Math.min(Math.max(bottomLeft.y, minY), maxY);
        topRight.x = Math.min(Math.max(topRight.x, minX), maxX);
        topRight.y = Math.min(Math.max(topRight.y, minY), maxY);

        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        return {
            x: (bottomLeft.x - mapOffsetX) * pixelToMeter,
            y: (imageNaturalHeight / pixelToMeter - (topRight.y - mapOffsetY)) * pixelToMeter,
            width: (topRight.x - bottomLeft.x) * pixelToMeter,
            height: (topRight.y - bottomLeft.y) * pixelToMeter,
            bottomLeft,
            topRight,
        };
    }, [boundaries, firstCorner, getCSSVariable, secondCorner]);
    const typeName = type === 'normal' ? 'area' : 'element';
    const instructions = {
        [INITIAL]: <Instruction>Click anywhere on the map to set the first corner of the area</Instruction>,
        [FIRST_CORNER_SET]: <Instruction>Click once more to set the second corner of the area</Instruction>,
        [SECOND_CORNER_SET]: (
            <>
                <Instruction>Are you sure?</Instruction>
            </>
        ),
        [DELETE_AREA]: (
            <>
                <Instruction>ARE YOU SURE??</Instruction>
            </>
        ),
    };

    return (
        <CreationSpace id={getMapViewerMapCroppingCreationOverlayId(type)} onClick={handleClick}>
            <Portal container={containerRef.current}>
                <Snackbar
                    id={getMapCroppingInstructionSnackbarId(type)}
                    open
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <SnackbarContent
                        message={instructions[creationStep]}
                        action={
                            <>
                                <SnackbarButton variant={'text'} onClick={handleCancel}>
                                    Cancel
                                </SnackbarButton>
                                {creationStep === SECOND_CORNER_SET &&
                                    (isCreating ? (
                                        <CircularPreloader />
                                    ) : (
                                        <SnackbarButton variant={'text'} onClick={handleClick}>
                                            {'YES'}
                                        </SnackbarButton>
                                    ))}

                                {creationStep === DELETE_AREA &&
                                    (isCreating ? (
                                        <CircularPreloader />
                                    ) : (
                                        <SnackbarButton variant={'text'} onClick={handleCrop}>
                                            {'DELETE'}
                                        </SnackbarButton>
                                    ))}
                            </>
                        }
                    />
                </Snackbar>
            </Portal>

            {firstCorner && secondCorner && (
                <Rectangle id={getMapCroppingNewCreation()} x={x} y={y} $width={width} $height={height} />
            )}
        </CreationSpace>
    );
}

MapViewerAreaOfInterestCreationOverlay.propTypes = {
    mapId: PropTypes.string.isRequired,
    onCropped: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
