import { createLogger } from 'redux-logger';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import persistedReducer from './reducers';
import settings from '../clientSettings';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import * as ReselectTools from "reselect-tools";
// import LogRocket from 'logrocket';

const middlewares = [];

if (settings.enableReduxLogger) {
    const loggerMiddleware = createLogger({
        // Neat middleware that logs actions
        predicate: (getState, action) => !action.type.startsWith('KEEP_SESSION'), // Don't log KEEP_SESSION actions
        collapsed: (getState, action) => !action.type.endsWith('ERROR'), // Collapse all non-error actions
    });
    middlewares.push(loggerMiddleware);
}

const store = configureStore({
    reducer: persistedReducer,
    preloadedState: {},
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], // Ignore serializable check on redux-persist actions
            },
            immutableCheck: false,
        }),
        ...middlewares,
        // LogRocket.reduxMiddleware(),
    ],
});

const persistor = persistStore(store);

ReselectTools.getStateWith(() => store.getState())

export { store, persistor };
