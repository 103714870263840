export const getCreateMapDialogId = () => `create-map-dialog`;
export const getCreateMapFormErrorId = () => `create-map-form-error`;

export const getMapNameId = (mapIndex) => `create-map-map-${mapIndex}-name`;
export const getMapUsageId = (mapIndex) => `create-map-map-${mapIndex}-usage`;
export const getPixelToMeterId = (mapIndex) => `create-map-map-${mapIndex}-p2m`;
export const getChangePixelToMeterId = (mapIndex) => `create-map-map-${mapIndex}-change-p2m`;
export const getOffsetXId = (mapIndex) => `create-map-map-${mapIndex}-offset-x`;
export const getOffsetYId = (mapIndex) => `create-map-map-${mapIndex}-offset-y`;
export const getChangeOffsetId = (mapIndex) => `create-map-map-${mapIndex}-change-offset`;

export const getOffsetDialogAcceptId = () => `create-map-offset-dialog-accept`;
