import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { MapViewerContext } from '../../MapViewerContext';
import {
    getAreaOfInterestBottomRightResizeBulletId,
    getAreaOfInterestTopLeftResizeBulletId,
    getAreaOfInterestTopRightResizeBulletId,
    getAreaOfInterestMiddleLeftResizeBulletId,
    getAreaOfInterestMiddleRightResizeBulletId,
    getAreaOfInterestTopCenterResizeBulletId,
    getMapViewerAreaOfInterestId,
    getAreaOfInterestBottomLeftResizeBulletId,
    getAreaOfInterestBottomCenterResizeBulletId,
} from './MapViewerAreasOfInterestOverlay.selectors';
import clsx from 'clsx';
import { useImmer } from 'use-immer';
import {
    IMAGE_NATURAL_HEIGHT,
    IMAGE_NATURAL_WIDTH,
    OFFSET_X,
    OFFSET_Y,
    PIXEL_TO_METER,
    SCALE,
} from '../../../../../constants/mapViewerVariables';
import Tooltip from '../../../themed/Tooltip';
import AreaOfInterestActions from './AreaOfInterestActions';
import {
    deleteAreaOfInterest,
    updateAreaOfInterest,
} from '../../../../../state-management/mapping/area-of-interest/areaOfInterestActions';
import { useDispatch, useSelector } from 'react-redux';
import { MdChat } from 'react-icons/md';
import { selectMappingAreElementsTitlesShown, selectMappingAreExitRegionTitlesShown, selectMappingAreasOfInterestActionsShown, selectMappingHighlightedAreaOfInterestId } from '../../../../../state-management/user-inputs/mappingSlice';
import {
    deleteElement,
    updateElement,
} from '../../../../../state-management/mapping/elements/elementsActions';
import { deleteExitRegion, upsertExitRegion } from '../../../../../state-management/mapping/exitRegion/exitRegionsActions';
import { selectBuildingAreExitRegionsTitleShown, selectBuildingsSelectedBuildingId, selectBuildingsSelectedFloorId } from '../../../../../state-management/user-inputs/buildingsSlice';
import { selectUserName } from '../../../../../state-management/auth/authSelectors';

const ACTIONS = {
    normal: {
        update: updateAreaOfInterest,
        delete: deleteAreaOfInterest,
        selectTitleShown: () => true,
        fill: '#00CEF1',
        selectedMappingHighlightedId: selectMappingHighlightedAreaOfInterestId
    },
    element: {
        update: updateElement,
        delete: deleteElement,
        canTitleShown: true,
        selectTitleShown: selectMappingAreExitRegionTitlesShown,
        fill: '#8A6748',
        selectedMappingHighlightedId: selectMappingHighlightedAreaOfInterestId
    },
    exitRegion: {
        update: ({ buildingId, floorId, mapId, areaId: exitRegionId, areaData: exitRegion }) => {
            const topRight = [exitRegion.topRight.x, exitRegion.topRight.y];
            const bottomLeft = [exitRegion.bottomLeft.x, exitRegion.bottomLeft.y];
            return upsertExitRegion({ buildingId, floorId, mapId, exitRegion: { ...exitRegion, topRight, bottomLeft, exitRegionId, shapeType: 'rectangular' } })
        },
        delete: ({ areaId: exitRegionId, ...params }) => deleteExitRegion({ ...params, exitRegionId }),
        canTitleShown: true,
        selectTitleShown: selectBuildingAreExitRegionsTitleShown,
        fill: 'red',
        selectedMappingHighlightedId: selectMappingHighlightedAreaOfInterestId
    }
}

const minHeight = 1;
const minWidth = 1;

const ElementTitle = styled.text`
    text-anchor: middle;
    dominant-baseline: middle;
    pointer-events: none !important;
`;

const Rectangle = styled.rect.attrs((props) => ({
    // We use inline styles instead of CSS class to avoid styled-components from
    // generating a new class for every update, which is a major performance issue
    style: {
        width: props.$width,
        height: props.$height,
    },
}))`
    fill: ${(p) => (ACTIONS[p.type].fill)}; // TODO color should come from theme
    fill-opacity: 0.3;
    stroke: ${(p) => (ACTIONS[p.type].fill)};
    stroke-width: calc(1.5px / var(${SCALE}));
    cursor: pointer;
    position: relative;
    &.read-only {
        cursor: inherit;
    }
    :hover:not(.read-only),
    &.highlighted:not(.read-only) {
        fill-opacity: 0.6;
    }

    &.selected:not(.read-only) {
        fill: #7a007a; // TODO color should come from theme
        stroke: #7a007a;

        &.draggable:not(.read-only) {
            cursor: grab;
        }

        &.dragging:not(.read-only) {
            cursor: grabbing;
        }
    }

    ${({ disabled }) => {
        if (disabled) {
            return css`
                fill: inherit;
                stroke: inherit;
            `;
        }
    }}
`;

const ResizeHandle = styled.circle.attrs(() => ({
    style: { r: `calc(6px / var(${SCALE}))` },
}))`
    fill: #ff6200; // TODO color should come from theme
    stroke: #ffffff;
    stroke-width: calc(1px / var(${SCALE}));
    cursor: ${(props) => (props.$cursor ? props.$cursor : 'auto')};
`;

const commentIconSize = 40;

const CommentIcon = styled(MdChat)`
    fill: #ff6200; // TODO color should come from theme
    cursor: pointer;
    transition: fill 300ms ease;
    width: calc(${commentIconSize}px / var(${SCALE}));
    height: calc(${commentIconSize}px / var(${SCALE}));

    :hover {
        fill: #ff6200; // TODO color should come from theme
    }
`;

const CommentIconWrapper = styled.foreignObject`
    transform: translateY(calc(-${commentIconSize}px / var(${SCALE})));
    width: calc(50px / var(${SCALE}));
    height: calc(50px / var(${SCALE}));
    overflow: visible;
`;

function AreaOfInterest(props) {
    const {
        area,
        mapId,
        onClick,
        onCancel,
        isHighlighted = false,
        isSelected = false,
        isDraggable = false,
        isResizeable = false,
        type = 'normal',
        title = '',
        readOnly,
        disabled,
        multiSelect = false
    } = props;
    const dispatch = useDispatch();
    const action = ACTIONS[type];
    const { areaId, topRight = {}, bottomLeft = {}, comments, hasAttachments } = area ?? {};
    const { containerRef, getCSSVariable } = useContext(MapViewerContext);
    const buildingId = useSelector(selectBuildingsSelectedBuildingId);
    const floorId = useSelector(selectBuildingsSelectedFloorId);
    const isAreaActionsShown = useSelector(selectMappingAreasOfInterestActionsShown);
    const mapperName = useSelector(selectUserName)

    const highlightedId = useSelector(action.selectedMappingHighlightedId);

    const isTitleShown = useSelector(action.selectTitleShown);
    const [{ isResizing, pointerOrigin, displayedTopRight, displayedBottomLeft, isDragging }, setShapeState] =
        useImmer({
            isResizing: { leftSide: false, rightSide: false, topSide: false, bottomSide: false },
            pointerOrigin: { x: 0, y: 0 },
            displayedTopRight: { x: topRight.x, y: topRight.y },
            displayedBottomLeft: { x: bottomLeft.x, y: bottomLeft.y },
            originalTopRight: { x: topRight.x, y: topRight.y },
            originalBottomLeft: { x: bottomLeft.x, y: bottomLeft.y },
            isDragging: false,
        });

    const handleSave = async () => {
        const update = action.update;
        await dispatch(
            update({
                buildingId, floorId, mapId, areaId, areaData: {
                    topRight: displayedTopRight,
                    bottomLeft: displayedBottomLeft,
                    mapperName
                }
            })
        );
        onCancel();
    };

    const handleDelete = async () => {
        const deleteAction = action.delete;
        await dispatch(deleteAction({ buildingId, floorId, mapId, areaId }));
        onCancel();
    };

    const handleCancel = () => {
        setShapeState((state) => {
            state.isResizing.leftSide = false;
            state.isResizing.rightSide = false;
            state.isResizing.topSide = false;
            state.isResizing.bottomSide = false;
            state.pointerOrigin.x = 0;
            state.pointerOrigin.y = 0;
            state.displayedTopRight.x = area?.topRight?.x;
            state.displayedTopRight.y = area?.topRight?.y;
            state.displayedBottomLeft.x = area?.bottomLeft?.x;
            state.displayedBottomLeft.y = area?.bottomLeft?.y;
            state.originalTopRight.x = area?.topRight?.x;
            state.originalTopRight.y = area?.topRight?.y;
            state.originalBottomLeft.x = area?.bottomLeft?.x;
            state.originalBottomLeft.y = area?.bottomLeft?.y;
            state.isDragging = false;
        });

        onCancel();
    };

    const { x, y, width, height } = useMemo(() => {
        if (!displayedTopRight || !displayedBottomLeft) {
            return { x: 0, y: 0, width: 0, height: 0 };
        }

        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));

        return {
            x: (displayedBottomLeft.x - mapOffsetX) * pixelToMeter,
            y: (imageNaturalHeight / pixelToMeter - (displayedTopRight.y - mapOffsetY)) * pixelToMeter,
            width: (displayedTopRight.x - displayedBottomLeft.x) * pixelToMeter,
            height: (displayedTopRight.y - displayedBottomLeft.y) * pixelToMeter,
        };
    }, [displayedTopRight, displayedBottomLeft, getCSSVariable]);

    const resizeHandles = [
        { id: getAreaOfInterestTopRightResizeBulletId(areaId), x: x + width, y, cursor: 'nesw-resize' },
        { id: getAreaOfInterestTopLeftResizeBulletId(areaId), x, y, cursor: 'nwse-resize' },
        {
            id: getAreaOfInterestBottomRightResizeBulletId(areaId),
            x: x + width,
            y: y + height,
            cursor: 'nwse-resize',
        },
        { id: getAreaOfInterestBottomLeftResizeBulletId(areaId), x, y: y + height, cursor: 'nesw-resize' },
        { id: getAreaOfInterestTopCenterResizeBulletId(areaId), x: x + width / 2, y, cursor: 'ns-resize' },
        {
            id: getAreaOfInterestBottomCenterResizeBulletId(areaId),
            x: x + width / 2,
            y: y + height,
            cursor: 'ns-resize',
        },
        {
            id: getAreaOfInterestMiddleLeftResizeBulletId(areaId),
            x,
            y: y + height / 2,
            cursor: 'ew-resize',
        },
        {
            id: getAreaOfInterestMiddleRightResizeBulletId(areaId),
            x: x + width,
            y: y + height / 2,
            cursor: 'ew-resize',
        },
    ];

    const boundaries = useMemo(() => {
        const mapOffsetX = parseFloat(getCSSVariable(OFFSET_X));
        const mapOffsetY = parseFloat(getCSSVariable(OFFSET_Y));
        const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));
        const imageNaturalHeight = parseFloat(getCSSVariable(IMAGE_NATURAL_HEIGHT));
        const imageNaturalWidth = parseFloat(getCSSVariable(IMAGE_NATURAL_WIDTH));

        return {
            maxX: imageNaturalWidth / pixelToMeter + mapOffsetX,
            minX: mapOffsetX,
            maxY: imageNaturalHeight / pixelToMeter + mapOffsetY,
            minY: mapOffsetY,
        };
    }, [getCSSVariable]);

    const handleRectanglePointerDown = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();


            const { clientX, clientY, shiftKey } = event;
            if (shiftKey) {
                return onClick(areaId, shiftKey)
            }

            if (isDraggable) {
                setShapeState((state) => {
                    state.isDragging = true;
                    state.pointerOrigin.x = clientX;
                    state.pointerOrigin.y = clientY;
                    state.originalBottomLeft.x = state.displayedBottomLeft.x;
                    state.originalBottomLeft.y = state.displayedBottomLeft.y;
                    state.originalTopRight.x = state.displayedTopRight.x;
                    state.originalTopRight.y = state.displayedTopRight.y;
                });
            }

            onClick(areaId);
        },
        [areaId, isDraggable, onClick, setShapeState]
    );

    const handleRectanglePointerMove = useCallback(
        (event) => {
            const { maxX, minX, maxY, minY } = boundaries;
            const { clientX, clientY } = event;

            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));

            const xDifference = (clientX - pointerOrigin?.x) / scale / pixelToMeter;
            const yDifference = (clientY - pointerOrigin?.y) / scale / pixelToMeter;

            setShapeState((state) => {
                // Limit the X/Y differences so that they won't make the region go outside the map image boundaries
                const limitedXDiff = Math.min(
                    Math.max(xDifference, minX - state.originalBottomLeft.x),
                    maxX - state.originalTopRight.x
                );
                const limitedYDiff = Math.min(
                    Math.max(yDifference, state.originalTopRight.y - maxY),
                    state.originalBottomLeft.y - minY
                );

                state.displayedBottomLeft.x = state.originalBottomLeft.x + limitedXDiff;
                state.displayedBottomLeft.y = state.originalBottomLeft.y - limitedYDiff;
                state.displayedTopRight.x = state.originalTopRight.x + limitedXDiff;
                state.displayedTopRight.y = state.originalTopRight.y - limitedYDiff;
            });
        },
        [boundaries, getCSSVariable, pointerOrigin, setShapeState]
    );

    const handleRectanglePointerUp = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();

            setShapeState((state) => {
                // TODO use a more clever way to finding the resizing sides (maybe className or other data attribute)
                state.isDragging = false;
                state.pointerOrigin.x = 0;
                state.pointerOrigin.y = 0;
                state.originalBottomLeft.x = state.displayedBottomLeft.x;
                state.originalBottomLeft.y = state.displayedBottomLeft.y;
                state.originalTopRight.x = state.displayedTopRight.x;
                state.originalTopRight.y = state.displayedTopRight.y;
            });
        },
        [setShapeState]
    );

    const handleResizeBulletPointerDown = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();

            if (isResizeable) {
                const { clientX, clientY } = event;
                const resizeBulletId = event.target.id;

                setShapeState((state) => {
                    // TODO use a more clever way to finding the resizing sides (maybe className or other data attribute)
                    state.isResizing.leftSide = resizeBulletId.includes('left');
                    state.isResizing.rightSide = resizeBulletId.includes('right');
                    state.isResizing.topSide = resizeBulletId.includes('top');
                    state.isResizing.bottomSide = resizeBulletId.includes('bottom');
                    state.pointerOrigin.x = clientX;
                    state.pointerOrigin.y = clientY;
                });
            }
        },
        [isResizeable, setShapeState]
    );

    const handleResizeBulletPointerMove = useCallback(
        (event) => {
            event.stopPropagation();
            const { clientX, clientY } = event;
            const { maxX, minX, maxY, minY } = boundaries;

            const scale = parseFloat(getCSSVariable(SCALE));
            const pixelToMeter = parseFloat(getCSSVariable(PIXEL_TO_METER));

            const xDifference = (clientX - pointerOrigin?.x) / scale / pixelToMeter;
            const yDifference = (clientY - pointerOrigin?.y) / scale / pixelToMeter;

            // Calculate new sides of the rectangle based on the pointer position
            // Limit resizing so that it will maintain minimum height/width
            setShapeState((state) => {
                if (isResizing.rightSide) {
                    state.displayedTopRight.x = Math.min(
                        Math.max(
                            state.originalTopRight.x + xDifference,
                            state.originalBottomLeft.x + minWidth
                        ),
                        maxX
                    );
                }

                if (isResizing.topSide) {
                    state.displayedTopRight.y = Math.min(
                        Math.max(
                            state.originalTopRight.y - yDifference,
                            state.originalBottomLeft.y + minHeight
                        ),
                        maxY
                    );
                }

                if (isResizing.bottomSide) {
                    state.displayedBottomLeft.y = Math.max(
                        Math.min(
                            state.originalBottomLeft.y - yDifference,
                            state.originalTopRight.y - minHeight
                        ),
                        minY
                    );
                }

                if (isResizing.leftSide) {
                    state.displayedBottomLeft.x = Math.max(
                        Math.min(
                            state.originalBottomLeft.x + xDifference,
                            state.originalTopRight.x - minWidth
                        ),
                        minX
                    );
                }
            });
        },
        [
            boundaries,
            getCSSVariable,
            isResizing.bottomSide,
            isResizing.leftSide,
            isResizing.rightSide,
            isResizing.topSide,
            pointerOrigin,
            setShapeState,
        ]
    );

    const handleResizeBulletPointerUp = useCallback(
        (event) => {
            event.stopPropagation();

            setShapeState((state) => {
                state.isResizing.leftSide = false;
                state.isResizing.rightSide = false;
                state.isResizing.topSide = false;
                state.isResizing.bottomSide = false;
                state.pointerOrigin.x = 0;
                state.pointerOrigin.y = 0;
                state.originalTopRight.x = displayedTopRight.x;
                state.originalTopRight.y = displayedTopRight.y;
                state.originalBottomLeft.x = displayedBottomLeft.x;
                state.originalBottomLeft.y = displayedBottomLeft.y;
            });
        },
        [displayedBottomLeft, displayedTopRight, setShapeState]
    );

    useEffect(() => {
        setShapeState((state) => {
            state.isResizing.leftSide = false;
            state.isResizing.rightSide = false;
            state.isResizing.topSide = false;
            state.isResizing.bottomSide = false;
            state.pointerOrigin.x = 0;
            state.pointerOrigin.y = 0;
            state.displayedTopRight.x = topRight.x;
            state.displayedTopRight.y = topRight.y;
            state.displayedBottomLeft.x = bottomLeft.x;
            state.displayedBottomLeft.y = bottomLeft.y;
            state.originalTopRight.x = topRight.x;
            state.originalTopRight.y = topRight.y;
            state.originalBottomLeft.x = bottomLeft.x;
            state.originalBottomLeft.y = bottomLeft.y;
            state.isDragging = false;
        });
    }, [bottomLeft.x, bottomLeft.y, setShapeState, topRight.x, topRight.y]);

    useEffect(() => {
        // Attach event listeners for rectangle dragging
        if (isDragging) {
            window.addEventListener('pointermove', handleRectanglePointerMove);
            window.addEventListener('pointerup', handleRectanglePointerUp);

            return () => {
                window.removeEventListener('pointermove', handleRectanglePointerMove);
                window.removeEventListener('pointerup', handleRectanglePointerUp);
            };
        }
    }, [isDragging, handleRectanglePointerMove, handleRectanglePointerUp]);

    useEffect(() => {
        // Attach event listeners for resize bullet
        if (isResizing.leftSide || isResizing.rightSide || isResizing.topSide || isResizing.bottomSide) {
            window.addEventListener('pointermove', handleResizeBulletPointerMove);
            window.addEventListener('pointerup', handleResizeBulletPointerUp);

            return () => {
                window.removeEventListener('pointermove', handleResizeBulletPointerMove);
                window.removeEventListener('pointerup', handleResizeBulletPointerUp);
            };
        }
    }, [isResizing, handleResizeBulletPointerMove, handleResizeBulletPointerUp]);

    if (!area) {
        return null;
    }

    if (readOnly || disabled) {
        return (
            <>
                <Rectangle
                    id={getMapViewerAreaOfInterestId(areaId)}
                    className="read-only"
                    disabled={disabled}
                    x={x}
                    y={y}
                    $width={width}
                    $height={height}
                    type={type}
                ></Rectangle>
                {action.canTitleShown && isTitleShown && (
                    <ElementTitle x={x + width / 2} y={y + height / 2}>
                        {title}
                    </ElementTitle>
                )}
            </>
        );
    }

    const rectangleRenderer = <><Rectangle
        id={getMapViewerAreaOfInterestId(areaId)}
        className={clsx({
            highlighted: isHighlighted || highlightedId === areaId,
            selected: isSelected,
            draggable: isDraggable,
            dragging: isDragging,
        })}
        x={x}
        y={y}
        $width={width}
        $height={height}
        type={type}
        onPointerDown={handleRectanglePointerDown}
    ></Rectangle>
        {
            action.canTitleShown && isTitleShown && (
                <ElementTitle x={x + width / 2} y={y + height / 2}>
                    {title}
                </ElementTitle>
            )
        }
        {
            (comments?.length > 0 || !!hasAttachments) && (
                <CommentIconWrapper x={x + width / 2} y={y}>
                    <CommentIcon onClick={() => onClick(areaId)} />
                </CommentIconWrapper>
            )
        }

        {
            !multiSelect &&
            isSelected &&
            isResizeable &&
            resizeHandles.map(({ id, x, y, cursor }) => (
                <ResizeHandle
                    key={id}
                    id={id}
                    cx={x}
                    cy={y}
                    $cursor={cursor}
                    onPointerDown={handleResizeBulletPointerDown}
                />
            ))
        }
    </>

    return (
        <>

            {!multiSelect && isAreaActionsShown ? <Tooltip
                key={area?.areaId}
                component={'g'}
                placement={'bottom'}
                visible={isSelected}
                sticky
                interactive
                appendTo={containerRef.current}
                content={
                    <AreaOfInterestActions onCancel={handleCancel} onSave={handleSave} onDelete={handleDelete} />
                }
            >
                {rectangleRenderer}
            </Tooltip> : rectangleRenderer}
        </>
    );
}

AreaOfInterest.propTypes = {
    area: PropTypes.object.isRequired,
    mapId: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
    isHighlighted: PropTypes.bool,
    isSelected: PropTypes.bool,
    isDraggable: PropTypes.bool,
    isResizeable: PropTypes.bool,
    type: PropTypes.oneOf(['normal', 'element', 'exitRegion']),
    title: PropTypes.string,
};

export default memo(AreaOfInterest);
