import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { getMapViewerMappersOverlayId } from './MapViewerMappersOverlay.selectors';
import Mapper from './Mapper';

const OverlayWrapper = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none; // Prevent the whole SVG from capturing pointer events

    * {
        pointer-events: auto;
    }
`;

export default function MapViewerMappersOverlay(props) {
    const { mappers, mapId } = props;

    if (mappers.length === 0) {
        return null;
    }

    return (
        <OverlayWrapper id={getMapViewerMappersOverlayId()}>
            {mappers.map((mapper) => (
                <Mapper key={mapper.mapperId} mapper={mapper} mapId={mapId} />
            ))}
        </OverlayWrapper>
    );
}

MapViewerMappersOverlay.propTypes = {
    mappers: PropTypes.arrayOf(PropTypes.object).isRequired,
    mapId: PropTypes.string.isRequired,
};
