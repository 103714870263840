import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchBuildingFullData } from '../../../../state-management/building/buildingActions';
import { selectHasError, selectIsLoading } from '../../../../state-management/status/statusSelectors';
import {
    selectFloorIdToMapsArray,
    selectFloorIdToMapsObject,
} from '../../../../state-management/floor/floorSelectors';
import { fetchAllMapsInFloor } from '../../../../state-management/map/mapActions';

/**
 * Hook that fetches all maps in the given floor ID.
 * @param options
 * @return {{isLoading, data: (*), hasError}}
 */
export default function useAllMapsInFloor(options = {}) {
    const { asObject = false, buildingId, floorId, filter = null } = options;

    const dispatch = useDispatch();

    const floorIdToMapsArray = useSelector(selectFloorIdToMapsArray);
    const floorIdToMapsObject = useSelector(selectFloorIdToMapsObject);
    const isFetching = useSelector(
        selectIsLoading([
            { type: fetchBuildingFullData.typePrefix, arg: buildingId },
            { type: fetchAllMapsInFloor.typePrefix, arg: { buildingId, floorId, filter } },
        ])
    );
    const hasError = useSelector(
        selectHasError([
            { type: fetchBuildingFullData.typePrefix, arg: buildingId },
            { type: fetchAllMapsInFloor.typePrefix, arg: { buildingId, floorId, filter } },
        ])
    );

    const mapsArray = floorIdToMapsArray[floorId];
    const mapsObject = floorIdToMapsObject[floorId];

    useEffect(() => {
        // If there is a provided building ID, and we haven't fetched all of its floors yet,
        // and we are not currently fetching them, fetch them
        if (buildingId && floorId && !isFetching && (!mapsArray || mapsArray.some(m => !m)) && !hasError) {
            dispatch(fetchAllMapsInFloor({ buildingId, floorId, filter }));
        }
    }, [dispatch, isFetching, hasError, buildingId, floorId, mapsArray, filter]);

    return { data: asObject ? mapsObject : mapsArray, isLoading: isFetching, hasError };
}
