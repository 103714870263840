import React, { useMemo } from 'react';
import Tippy from '@tippyjs/react';
import { sticky as stickyPlugin, followCursor as followCursorPlugin } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';

export default function Tooltip({
    children,
    component: Component = 'span',
    useWrapper = true,
    sticky = false,
    followCursor = false,
    wrapperProps,
    enabled = true,
    theme = 'light-border',
    arrow = true,
    ...otherProps
}) {
    const [plugins, pluginProps] = useMemo(() => {
        const plugins = [];
        const pluginProps = {};

        if (followCursor) {
            plugins.push(followCursorPlugin);
            pluginProps.followCursor = true;
        }

        if (sticky) {
            plugins.push(stickyPlugin);
            pluginProps.sticky = true;
        }

        return [plugins, pluginProps];
    }, [followCursor, sticky]);

    return (
        <Tippy
            theme={theme}
            arrow={arrow}
            disabled={!enabled}
            plugins={plugins}
            {...pluginProps}
            {...otherProps}
        >
            {useWrapper ? <Component  {...wrapperProps}>{children}</Component> : children}
        </Tippy>
    );
}
