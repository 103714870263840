import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import JunctionMismatch from './JunctionMismatch';
import { getMapViewerJunctionMismatchesOverlayId } from './MapViewerJunctionMismatchesOverlay.selectors';
import { getMismatchValueColor, getMismatchValueMarkerId } from '../../../../../utils/junctionMismatchUtils';

const OverlayWrapper = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none; // Prevent the whole SVG from capturing pointer events

    * {
        pointer-events: auto;
    }
`;

function MapViewerJunctionMismatchesOverlay(props) {
    const { junctions } = props;

    if (junctions.length === 0) {
        return null;
    }

    return (
        <OverlayWrapper id={getMapViewerJunctionMismatchesOverlayId()}>
            <defs>
                <marker
                    id={getMismatchValueMarkerId(3)}
                    viewBox={'0 0 10 10'}
                    refX={'5'}
                    refY={'5'}
                    markerWidth={'5'}
                    markerHeight={'5'}
                >
                    <circle cx={'5'} cy={'5'} r={'3'} fill={getMismatchValueColor(3)} />
                </marker>

                <marker
                    id={getMismatchValueMarkerId(5)}
                    viewBox={'0 0 10 10'}
                    refX={'5'}
                    refY={'5'}
                    markerWidth={'5'}
                    markerHeight={'5'}
                >
                    <circle cx={'5'} cy={'5'} r={'3'} fill={getMismatchValueColor(5)} />
                </marker>

                <marker
                    id={getMismatchValueMarkerId(6)}
                    viewBox={'0 0 10 10'}
                    refX={'5'}
                    refY={'5'}
                    markerWidth={'5'}
                    markerHeight={'5'}
                >
                    <circle cx={'5'} cy={'5'} r={'3'} fill={getMismatchValueColor(6)} />
                </marker>

                <marker
                    id={'jm-point-border'}
                    viewBox={'0 0 10 10'}
                    refX={'5'}
                    refY={'5'}
                    markerWidth={'5'}
                    markerHeight={'5'}
                >
                    <circle cx={'5'} cy={'5'} r={'5'} fill="#ff6200" />
                </marker>
            </defs>

            {junctions.map((junction) => (
                <JunctionMismatch key={junction.junctionId} junction={junction} />
            ))}
        </OverlayWrapper>
    );
}

MapViewerJunctionMismatchesOverlay.propTypes = {
    junctions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MapViewerJunctionMismatchesOverlay.whyDidYouRender = true;

export default memo(MapViewerJunctionMismatchesOverlay);
